import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { CreditAnalysisQuestion } from 'types/creditAnalysisQuestion';
import { useCreditAnalysisQuestions } from 'pages/credit-analysis-questions/hooks/useCreditAnalysisQuestions';
import history from 'services/history';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

type CreditAnalysisQuestionItemModuleProps = {
  question: CreditAnalysisQuestion;
};

const CreditLimitRequestItemModule: React.FC<CreditAnalysisQuestionItemModuleProps> = ({ question }) => {
  const classes = useStyles();
  const { setSelectedQuestion } = useCreditAnalysisQuestions();

  function handleClick() {
    setSelectedQuestion(question);
    history.push(`/credit-analysis-question/${question.id}`);
  }

  return (
    <ListItem button className={classes.listItem} onClick={handleClick}>
      <Typography variant="caption" color="primary">
        Questão {question.order}
      </Typography>
      <Typography variant="h6">{question.question_text}</Typography>
    </ListItem>
  );
};

export default CreditLimitRequestItemModule;
