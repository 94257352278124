import React from 'react';
import SelectBranch from 'components/select-branches/SelectBranch';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Button, TextField, styled } from '@mui/material';
import { DavReviewFilterProps } from './DavReviewPage';
import { Search } from '@mui/icons-material';

const Filter = styled('form')(({ theme }) => ({
  flexDirection: 'row',
  display: 'grid',
  gridTemplateColumns: '150px 1fr 1fr 1fr 1fr 150px',
  gap: 10,
  alignItems: 'center',
  '& > button': {
    minWidth: '100px!important',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

interface DavReviewFilterBoxProps {
  filter: DavReviewFilterProps;
  loading: boolean;
  handleSearch(): void;
  handleChangeFilter(index: keyof DavReviewFilterProps, value: any): void;
}

const DavReviewFilterBox: React.FC<DavReviewFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  handleSearch,
  loading,
}) => {
  return (
    <FilterBox>
      <Filter onSubmit={handleSearch}>
        <SelectBranch
          branch_id={filter.branch_id}
          handleChange={branchId => handleChangeFilter('branch_id', branchId)}
          viewAllBranches={false}
        />
        <KeyboardDatePicker
          label="Data inicial"
          maxDate={filter.final_date}
          value={filter.initial_date}
          onChange={date => handleChangeFilter('initial_date', date)}
        />

        <KeyboardDatePicker
          label="Data Final"
          minDate={filter.initial_date}
          value={filter.final_date}
          onChange={date => handleChangeFilter('final_date', date)}
        />
        <TextField
          value={filter.davNumber || ''}
          onChange={e => handleChangeFilter('davNumber', parseInt(e.target.value))}
          label="Número"
          autoFocus
          inputProps={{
            inputMode: 'numeric',
          }}
          placeholder="Digite o número DAV"
        />
        <TextField
          value={filter.davName}
          onChange={e => handleChangeFilter('davName', e.target.value)}
          label="Cliente"
          autoFocus
          placeholder="Digite o nome do cliente"
        />
        <Button
          type="submit"
          disabled={loading}
          onClick={handleSearch}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Search />}
        >
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default DavReviewFilterBox;
