import { Dispatch, SetStateAction, useState } from 'react';

type UseFetchViews = {
  viewList: boolean;
  viewUnity: boolean;
  viewBranch: boolean;
  dialogDate: boolean;
  viewSeller: boolean;
  excelExport: boolean;
  viewSegment: boolean;
  viewCustomer: boolean;
  viewSubgroup: boolean;
  viewCategory: boolean;
  openModalBranch: boolean;
  openModalSegment: boolean;
  viewManufacturer: boolean;
  dialogDateDesktop: boolean;
  setExcelExport: Dispatch<SetStateAction<boolean>>;
  setOpenModalBranch: Dispatch<SetStateAction<boolean>>;
  setOpenModalSegment: Dispatch<SetStateAction<boolean>>;
  setViewManufacturer: Dispatch<SetStateAction<boolean>>;
  setDialogDateDesktop: Dispatch<SetStateAction<boolean>>;
  setDialogDate: Dispatch<SetStateAction<boolean>>;
  setViewList: Dispatch<SetStateAction<boolean>>;
  setViewUnity: Dispatch<SetStateAction<boolean>>;
  setViewBranch: Dispatch<SetStateAction<boolean>>;
  setViewSeller: Dispatch<SetStateAction<boolean>>;
  setViewSegment: Dispatch<SetStateAction<boolean>>;
  setViewCustomer: Dispatch<SetStateAction<boolean>>;
  setViewSubgroup: Dispatch<SetStateAction<boolean>>;
  setViewCategory: Dispatch<SetStateAction<boolean>>;
  handleCloseDialogDesktop(): void;
  handleCloseDialog(): void;
};

export function useFetchViews(handleSearch: (searchValue: string) => void, searchText: string): UseFetchViews {
  const [viewList, setViewList] = useState(false);
  const [viewUnity, setViewUnity] = useState(false);
  const [viewBranch, setViewBranch] = useState(false);
  const [dialogDate, setDialogDate] = useState(false);
  const [viewSeller, setViewSeller] = useState(false);
  const [excelExport, setExcelExport] = useState(false);
  const [viewSegment, setViewSegment] = useState(false);
  const [viewCustomer, setViewCustomer] = useState(false);
  const [viewSubgroup, setViewSubgroup] = useState(false);
  const [viewCategory, setViewCategory] = useState(false);
  const [openModalBranch, setOpenModalBranch] = useState(false);
  const [openModalSegment, setOpenModalSegment] = useState(false);
  const [viewManufacturer, setViewManufacturer] = useState(false);
  const [dialogDateDesktop, setDialogDateDesktop] = useState(false);

  function handleCloseDialogDesktop() {
    setDialogDateDesktop(false);

    handleSearch(searchText);
  }

  function handleCloseDialog() {
    setDialogDate(false);

    handleSearch(searchText);
  }

  return {
    viewBranch,
    viewUnity,
    viewSubgroup,
    viewSeller,
    viewSegment,
    viewManufacturer,
    viewList,
    viewCustomer,
    viewCategory,
    setViewUnity,
    setViewSeller,
    setViewSegment,
    setViewManufacturer,
    setViewList,
    setViewBranch,
    setOpenModalSegment,
    setOpenModalBranch,
    setExcelExport,
    setDialogDateDesktop,
    setViewCategory,
    setViewCustomer,
    setViewSubgroup,
    setDialogDate,
    openModalSegment,
    openModalBranch,
    excelExport,
    dialogDateDesktop,
    dialogDate,
    handleCloseDialog,
    handleCloseDialogDesktop,
  };
}
