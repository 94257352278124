import React, { Dispatch, SetStateAction } from 'react';
import { Typography, styled } from '@mui/material';
import TemplateItemTable from './TemplateItemTable';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import history from 'services/history';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { Template } from 'types/template';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type TemplateListTableProps = {
  templates: Template[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const TemplateListTable: React.FC<TemplateListTableProps> = ({ templates, handleSort, orderedIndex, setAnchorEl }) => {
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();

  return (
    <>
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <HeaderItem key={item.id} onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}>
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody>
          {templates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(template => (
            <TableRow key={template.id} onClick={() => history.push(`/site/template/${template.id}`)}>
              <TemplateItemTable setAnchorEl={setAnchorEl} template={template} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default TemplateListTable;
