import React, { useState } from 'react';
import { Popover, Theme } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';

import { useInputDialog } from 'components/dialogs/DialogInput';
import MobileDatePicker from 'components/pickers/MobileDatePicker';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  paper: {
    padding: '10px 20px 20px',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

type DashboardReportCustomDateProps = {
  onClose(): void;
  anchorEl: HTMLButtonElement | null;
  handleConfirmCustomDates(initialDate: Date, finalDate: Date): void;
};

const DashboardReportCustomDate: React.FC<DashboardReportCustomDateProps> = ({
  anchorEl,
  handleConfirmCustomDates,
  onClose,
}) => {
  const classes = useStyles();
  const [initialDate, setInitialDate] = useState<Date | null>(null);
  const [finalDate, setFinalDate] = useState<Date | null>(null);
  const dialogInput = useInputDialog();

  function handleSetFinalDate(_finalDate: Date | null) {
    setFinalDate(_finalDate);
    if (initialDate && _finalDate) {
      handleConfirmCustomDates(initialDate, _finalDate);
      onClose();
      if (dialogInput.handleClose) dialogInput.handleClose();
    }
  }

  function handleSetInitialDate(_initialDate: Date | null) {
    setInitialDate(_initialDate);
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <MobileDatePicker
        value={initialDate}
        onChange={date => (date ? handleSetInitialDate(startOfDay(date)) : handleSetInitialDate(null))}
        label="Data inicial"
        textFieldProps={{ required: true, fullWidth: false, name: 'initialDt' }}
      />
      <MobileDatePicker
        value={finalDate}
        onChange={date => (date ? handleSetFinalDate(endOfDay(date)) : handleSetFinalDate(null))}
        label="Data final"
        textFieldProps={{ required: true, fullWidth: false, name: 'finalDt' }}
      />
    </Popover>
  );
};

export default DashboardReportCustomDate;
