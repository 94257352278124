import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import React from 'react';
import { Typography, styled } from '@mui/material';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import TableContainer from 'components/table/TableContainer';
import { format, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { stockItemsTableTemplate } from '../stockItemsTableTemplate';
import { StockItemList } from 'types/stockItem';
import StockItemsItemTable from './StockItemsItemTable';

interface StockItemsListTableProps {
  items: StockItemList[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

const StockItemsListTable: React.FC<StockItemsListTableProps> = ({ handleSort, orderedIndex, items }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <TableContainer tableTemplate={stockItemsTableTemplate}>
      <TableContent useMinHeight={false}>
        <TableHeader>
          {stockItemsTableTemplate.map(item => (
            <HeaderItem
              key={item.id}
              className={
                item.dataType === 'number' ? 'numericData' : item.dataType === 'quantity' ? 'quantity' : undefined
              }
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description === 'Data1'
                  ? `${format(subDays(new Date(), 30), 'P', { locale: ptBR })} até ${format(new Date(), 'P', {
                      locale: ptBR,
                    })}`
                  : item.description === 'Data2'
                  ? `${format(subDays(new Date(), 61), 'P', { locale: ptBR })} até ${format(
                      subDays(new Date(), 31),
                      'P',
                      {
                        locale: ptBR,
                      },
                    )}`
                  : item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody useMaxHeight maxHeight={400}>
          {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
            <TableRow key={index}>
              <StockItemsItemTable data={item} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default StockItemsListTable;
