import React from 'react';
import { useCreditLimitRequest } from 'pages/credit-limit-requests/hooks/useCreditLimitRequest';
import DocumentHistoryList from './DocumentHistoryList';
import NoData from 'components/no-data/NoData';

export type DocumentHistoryIndexes =
  | 'social_contract'
  | 'annual_balance'
  | 'quarterly_balance'
  | 'twelve_months_revenue'
  | 'guarantee_letter';

interface DocumentHistoriesProps {
  index: DocumentHistoryIndexes;
}

const DocumentHistories: React.FC<DocumentHistoriesProps> = ({ index }) => {
  const { limitRequests } = useCreditLimitRequest();
  return limitRequests.length > 0 ? (
    <DocumentHistoryList creditLimitRequests={limitRequests} index={index} />
  ) : (
    <NoData message="Nenhum documento para mostrar" />
  );
};

export default DocumentHistories;
