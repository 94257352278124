import {
  Project,
  ProjectActionFormContent,
  ProjectAddress,
  ProjectContactFormContent,
  ProjectCostFormContent,
  ProjectProductFormContent,
} from 'types/project';
import { ProjectActions } from './types';
import { Document } from 'types/document';

export function projectChange(index: keyof Project, value: any): ProjectActions {
  return {
    type: 'CHANGE_PROJECT',
    value,
    index,
  };
}

export function addProjectProduct(product: ProjectProductFormContent): ProjectActions {
  return {
    type: 'ADD_PROJECT_PRODUCT',
    product,
  };
}

export function changeProjectAddress(index: keyof ProjectAddress, value: string | null): ProjectActions {
  return {
    type: 'CHANGE_PROJECT_ADDRESS',
    index,
    value,
  };
}

export function addProjectDocument(document: Document): ProjectActions {
  return {
    type: 'ADD_PROJECT_DOCUMENT',
    document,
  };
}

export function addProjectContact(contact: ProjectContactFormContent): ProjectActions {
  return {
    type: 'ADD_PROJECT_CONTACT',
    contact,
  };
}

export function removeProjectDocument(documentId: number): ProjectActions {
  return {
    type: 'REMOVE_PROJECT_DOCUMENT',
    documentId,
  };
}

export function removeProjectProduct(id: string | number): ProjectActions {
  return {
    type: 'REMOVE_PROJECT_PRODUCT',
    id,
  };
}

export function removeProjectContact(id: string | number): ProjectActions {
  return {
    type: 'REMOVE_PROJECT_CONTACT',
    id,
  };
}

export function setProject(project: Project): ProjectActions {
  return {
    type: 'SET_PROJECT',
    project,
  };
}

export function addProjectCost(cost: ProjectCostFormContent): ProjectActions {
  return {
    type: 'ADD_PROJECT_COST',
    cost,
  };
}

export function removeProjectCost(id: number | string): ProjectActions {
  return {
    type: 'REMOVE_PROJECT_COST',
    id,
  };
}

export function addProjectAction(action: ProjectActionFormContent): ProjectActions {
  return {
    type: 'ADD_PROJECT_ACTION',
    action,
  };
}

export function removeProjectAction(id: string | number): ProjectActions {
  return {
    type: 'REMOVE_PROJECT_ACTION',
    id,
  };
}
