import React, { useEffect, useRef } from 'react';
import { Checkbox, FormControlLabel, TextField, styled } from '@mui/material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import { QueryParams } from './SliderBanners';

const Filter = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '220px 1fr 1fr',
  columnGap: 10,
  flex: 1,
  [props.theme.breakpoints.down('md')]: {
    flex: 1,
  },
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    flex: 1,
  },
  '& > .search-params': {
    display: 'grid',
    columnGap: 10,
    gridTemplateColumns: '1fr',
    [props.theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

type PlansFilterBoxProps = {
  queryParams: QueryParams;
  handleQueryParamsChange(index: keyof QueryParams, value: any): void;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const SiteBannersFilterBox: React.FC<PlansFilterBoxProps> = ({
  queryParams,
  handleQueryParamsChange,
  setDisplayMode,
  displayMode,
}) => {
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, []);

  return (
    <FilterBox>
      <Filter>
        <TextField
          label="Nome do banner"
          placeholder="Digite o nome do banner"
          value={queryParams.name}
          onChange={e => handleQueryParamsChange('name', e.target.value)}
          autoFocus
        />
        <div className="search-params">
          <FormControlLabel
            control={
              <Checkbox
                checked={queryParams.active}
                onChange={e => handleQueryParamsChange('active', e.target.checked)}
                color="primary"
              />
            }
            label="Ativos"
          />
        </div>
      </Filter>

      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default SiteBannersFilterBox;
