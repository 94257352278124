import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRentals } from 'pages/rentals/hooks/useRentals';
import { RentalOnList } from 'pages/rentals/types/rental';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginRight: 20,
  },
});

type RentalItemTableProps = {
  rental: RentalOnList;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const RentalItemTable: React.FC<RentalItemTableProps> = ({ rental, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedRental } = useRentals();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    setSelectedRental(rental);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton onClick={handleMoreClick} key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography variant="body2">{rental[item.id as any]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default RentalItemTable;
