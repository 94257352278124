import React, { Dispatch, SetStateAction } from 'react';
import { Typography, alpha, Grid, Theme } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { FinancialTicket } from 'types/financialReportList';
import { ticketTableTemplate } from '../../ticketTableTemplate';
import { useFinancialTicket } from '../../hooks/useFinancialTicket';
import TicketItemTable from './TicketItemTable';
import TableContainer from 'components/table/TableContainer';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
  selectedCustomer: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
  },
}));

type TicketListTableProps = {
  tickets: FinancialTicket[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const TicketListTable: React.FC<TicketListTableProps> = ({ tickets, setAnchorEl, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { setSelectedTicket } = useFinancialTicket();

  function handleClick(tickets: FinancialTicket) {
    setSelectedTicket(tickets);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={ticketTableTemplate}>
          <TableContent>
            <TableHeader>
              {ticketTableTemplate.map(item => (
                <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
                  <Typography variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </div>
              ))}
            </TableHeader>
            <TableBody>
              {tickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(ticket => (
                <TableRow onClick={() => handleClick(ticket)} key={ticket.id}>
                  <TicketItemTable setAnchorEl={setAnchorEl} ticket={ticket} />
                </TableRow>
              ))}
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TicketListTable;
