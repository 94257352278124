import { createContext, useContext } from 'react';
import { Banner } from 'types/banner';
import { Customer } from 'types/customers';

type CustomerContextValue = {
  handleAddArea(area: Banner): void;
  handleRemoveArea(areaId: number): void;
  customer: Customer;
};

const CustomerContext = createContext<CustomerContextValue>({} as CustomerContextValue);
export const CustomerProvider = CustomerContext.Provider;

export function useCustomer(): CustomerContextValue {
  const context = useContext(CustomerContext);
  return context;
}
