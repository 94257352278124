import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography, Theme } from '@mui/material';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import Pagination from 'components/pagination/Pagination';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import { StockBalanceData } from 'types/reports/stockBalance';
import Appbar from 'components/appbar/Appbar';
import StockMoreFilter from '../StockMoreFilter';
import { StockFilter } from 'types/reports/stockFilter';
import { StockIndicatorProvider } from './hooks/useStockIndicator';
import ProductListTable from '../stock-balance/list/product/table/ProductList';
import ProductListModule from '../stock-balance/list/product/module/ProductList';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import StockBalanceExport from '../StockBalanceExport';
import StockBalanceReportData from '../stock-balance/StockBalanceReportData';
import StockIndicatorFilter from '../StockIndicatorFilter';
import { useSelector } from 'store/selector';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  moreDetail: {
    marginTop: 15,
    marginBottom: 10,
    padding: '15px 0',
    position: 'relative',
    transition: 'all ease 0.3s',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  typography: {
    top: -12,
    left: 15,
    cursor: 'pointer',
    padding: '0px 5px',
    position: 'absolute',
    backgroundColor: '#fafafa',
  },
}));

const StockIndicator: React.FC = () => {
  const classes = useStyles();
  const { h2iApi, isMobile, windowWidth, handleOpenMenu, isOpenedMenu } = useApp();
  const [dataItems, setDataItems] = useState<StockBalanceData[]>([]);
  const [loading, setLoading] = useState(false);
  const [excelExport, setExcelExport] = useState(false);
  const [moreFilter, setMoreFilter] = useState(false);
  const [filteredItems, setFilteredItems] = useState<StockBalanceData[]>([]);
  const [selectedItem, setSelectedItem] = useState<StockBalanceData | null>(null);
  const [moreItem, setMoreItem] = useState<StockBalanceData | null>(null);
  const [viewItem, setViewItem] = useState(false);
  const [filter, setFilter] = useState<StockFilter>({
    branchId: [],
    manufacturerId: [],
    subgroupId: [],
    indexToSearch: 'Produto',
    searchValue: '',
    categoryId: [],
    segmentId: [],
    subcategoryId: [],
    unityId: [],
    made_by: '',
    parameter: 'T',
  });
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [orderedIndex, sort] = useTableOrder();
  const [shownReport, setShownReport] = useState(false);
  const branches = useSelector(state => state.branches);

  const handleSearch = useCallback(
    async (filter: StockFilter, isOpenedMenu?: boolean, handleOpenMenu?: () => void) => {
      if (!h2iApi) {
        return;
      }

      setMoreFilter(false);

      if (isOpenedMenu && handleOpenMenu) {
        handleOpenMenu();
      }

      setMoreItem(null);
      setSelectedItem(null);

      setLoading(true);

      await h2iApi
        .get(`/api/saldoestoque`, {
          params: {
            id_filial: filter.branchId.join(','),
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(','),
            id_subcategoria: filter.subcategoryId.join(','),
            id_segmento: filter.segmentId.join(','),
            id_unidade: filter.unityId.join(','),
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
            parametro: filter.parameter,
          },
        })
        .then(response => {
          if (response.data[0].result) {
            setDataItems([]);
            return;
          }

          const _data: StockBalanceData[] = response.data.map((item: StockBalanceData) => {
            item.formattedEstoque = numberFormat(item.Estoque);
            item.formattedCusto = moneyFormat(item.Custo);
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            item.formattedIndex = numberFormat(item.indice);
            return item;
          });

          setDataItems(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi],
  );

  const selectedBranches = useMemo(() => {
    const branch = branches.filter(branch => filter.branchId.some(item => item === branch.id));
    return branch;
  }, [branches, filter.branchId]);

  function handleSortItem(index: string) {
    const p = sort(index, filteredItems);
    setFilteredItems(p);
  }

  function handleViewItem() {
    setViewItem(!viewItem);
  }

  function handleChange(index: keyof StockFilter, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  useEffect(() => {
    setFilteredItems(dataItems);
  }, [dataItems]);

  return (
    <StockIndicatorProvider
      value={{
        moreItem,
        setMoreItem,
        setSelectedItem,
        selectedItem,
        filter,
      }}
    >
      {shownReport && (
        <StockBalanceReportData
          data={filteredItems}
          branches={selectedBranches}
          onExited={() => setShownReport(false)}
        />
      )}

      {moreFilter && (
        <StockMoreFilter
          filter={filter}
          onSearch={() => handleSearch(filter)}
          onExited={() => setMoreFilter(false)}
          handleChangeFilter={handleChange}
        />
      )}

      <Appbar title="" />

      {excelExport && <StockBalanceExport filter={filter} onExited={() => setExcelExport(false)} />}

      <StockIndicatorFilter
        filter={filter}
        setExcelExport={setExcelExport}
        openMoreFilter={() => setMoreFilter(true)}
        openReport={() => setShownReport(true)}
        displayMode={displayMode}
        handleChange={handleChange}
        onSearch={filter => handleSearch(filter, isOpenedMenu, handleOpenMenu)}
        setDisplayMode={setDisplayMode}
      />
      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filteredItems.length === 0 ? (
        <NoData message="Nenhuma informação encontrada" />
      ) : (
        <>
          <div className={classes.moreDetail}>
            <Typography onClick={handleViewItem} variant="caption" className={classes.typography}>
              + Itens
            </Typography>
            {viewItem && (
              <PaginationProvider>
                <div className={classes.container}>
                  {filteredItems.length === 0 ? (
                    <NoData message="Nenhum item encontrado." />
                  ) : displayMode === 'list' ? (
                    <ProductListTable
                      isSubgroup={false}
                      products={filteredItems}
                      handleSort={handleSortItem}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    displayMode === 'module' && <ProductListModule products={filteredItems} />
                  )}
                  <Pagination count={filteredItems.length} />
                </div>
              </PaginationProvider>
            )}
          </div>
        </>
      )}
    </StockIndicatorProvider>
  );
};

export default StockIndicator;
