import React, { useCallback, useEffect, useState } from 'react';
import { usePriceProtection } from '../hook/usePriceProtection';
import { useApp } from 'hooks/app';
import { ProductList } from 'types/product';
import { moneyFormat } from 'helpers/numberFormat';
import InsideSaving from 'components/loading/InsideSaving';
import { TextField, Typography, styled } from '@mui/material';
import TableRow from 'components/table/TableRow';
import { productPriceTableTemplate } from '../productPriceTableTemplate';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import TableBody from 'components/table/TableBody';
import TableContainer from 'components/table/TableContainer';
import FilterBox from 'components/filter-box/FilterBox';
import useSearch from 'hooks/search';

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
  border: '1px solid #eee',
  margin: '0px 0px 20px 0px',
});

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

const CustomTextField = styled(TextField)(({ theme }) => ({
  minWidth: 600,
  [theme.breakpoints.down('md')]: {
    minWidth: 300,
  },
}));

const active: React.CSSProperties = {
  transition: 'all ease 0.3s',
  backgroundColor: '#92d492',
};

let timer: NodeJS.Timeout;

const PriceProtectionStepTwo: React.FC = () => {
  const search = useSearch();
  const { h2iApi } = useApp();
  const [searchText, setSearchText] = useState('');
  const { rowsPerPage, page } = usePagination();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<ProductList[]>([]);
  const [filtered, setFiltered] = useState<ProductList[]>([]);
  const { selectedManufacturer, setSelectedProduct, selectedProduct } = usePriceProtection();

  useEffect(() => {
    setFiltered(products);
  }, [products]);

  useEffect(() => {
    if (!h2iApi || !selectedManufacturer) {
      return;
    }

    setLoading(true);

    h2iApi
      .get(`/api/produtoTodos?id_fabricante=${selectedManufacturer.id_fabricante}`)
      .then(response => {
        const p = response.data.map((item: ProductList) => {
          item.formattedEst = parseInt(item.est);
          item.formattedCusto = moneyFormat(item.custo);
          item.formattedStockValue = moneyFormat(item.custo * parseInt(item.est));
          return item;
        });

        setProducts(p);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [h2iApi, selectedManufacturer]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleChange(value: string) {
    setSearchText(value);
    clearTimeout(timer);

    timer = setTimeout(() => findProducts(value), 300);
  }

  const findProducts = useCallback(
    async (term: string) => {
      const f = search(term, 'produto', products);
      setFiltered(f);
    },
    [products, search],
  );

  return (
    <>
      {loading ? (
        <InsideSaving />
      ) : (
        <Container>
          <FilterBox>
            <CustomTextField
              label="Produto"
              placeholder="Digite o nome do produto"
              value={searchText}
              onChange={e => handleChange(e.target.value)}
            />
          </FilterBox>
          <TableContainer tableTemplate={productPriceTableTemplate}>
            <TableContent useMinHeight={false}>
              <TableHeader>
                {productPriceTableTemplate.map(item => (
                  <HeaderItem
                    key={item.id}
                    onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
                  >
                    <Typography variant="caption" color="primary">
                      {item.description}
                    </Typography>
                    {orderedIndex.index === item.originalId && (
                      <>
                        {orderedIndex.direction === 'asc' ? (
                          <ArrowUpward color="primary" />
                        ) : (
                          <ArrowDownward color="primary" />
                        )}
                      </>
                    )}
                  </HeaderItem>
                ))}
              </TableHeader>
              <TableBody maxHeight={404}>
                {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => (
                  <TableRow
                    style={product.codigo_produto === selectedProduct?.codigo_produto ? active : {}}
                    onClick={() =>
                      setSelectedProduct(selectedProduct?.codigo_produto === product.codigo_produto ? null : product)
                    }
                    key={product.codigo_produto}
                  >
                    {productPriceTableTemplate.map(item => (
                      <div key={item.id}>
                        <Typography variant="body2">{product[item.id]}</Typography>
                      </div>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </TableContent>
          </TableContainer>
          <Pagination count={filtered.length} />
        </Container>
      )}
    </>
  );
};

export default PriceProtectionStepTwo;
