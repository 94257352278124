import { useContext, createContext } from 'react';
import { Bill } from 'types/bill';

interface BillRemittanceContextValue {
  bills: Bill[];
  selectedBills: Bill[];
  handleSelect(bill: Bill): void;
  handleSelectAll(): void;
  handleRemoveSelectedAll(): void;
}

const BillRemittanceContext = createContext<BillRemittanceContextValue>({} as BillRemittanceContextValue);
export const BillRemittanceProvider = BillRemittanceContext.Provider;
export const BillRemittanceConsumer = BillRemittanceContext.Consumer;

export function useBillRemittance(): BillRemittanceContextValue {
  const context = useContext(BillRemittanceContext);
  return context;
}
