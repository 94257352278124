import React from 'react';
import { Typography, alpha, Theme } from '@mui/material';
import ExportEmailItemTable from './ExportEmailItemTable';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { Email } from 'types/email';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  selectedCustomer: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
  },
}));

type ExportEmailListTableProps = {
  emails: Email[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const ExportEmailListTable: React.FC<ExportEmailListTableProps> = ({ emails, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();

  return (
    <>
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <div
              className={classes.headerItem}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {emails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((email, index) => (
            <TableRow key={index + 1}>
              <ExportEmailItemTable email={email} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default ExportEmailListTable;
