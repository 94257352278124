import React from 'react';
import { RegisterForm } from 'types/registerForm';
import { RegisterUploadsValidation } from './validation/registerUploadsValidation';
import DocumentUpload from 'components/document-upload/DocumentUpload';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  imageUploader: {
    marginBottom: 15,
  },
});

interface RegisterFormUploadProps {
  register: RegisterForm;
  handleChange(index: keyof RegisterForm, value: any): void;
  validation: RegisterUploadsValidation;
}

const RegisterFormUpload: React.FC<RegisterFormUploadProps> = ({ register, handleChange, validation }) => {
  const classes = styles();

  return (
    <DocumentUpload
      handleRemoveImage={() => handleChange('social_contract_copy', null)}
      handleSetImage={image => handleChange('social_contract_copy', image)}
      validationError={validation.social_contract_copy}
      document={register.social_contract_copy}
      label="cópia do contrato social (png, jpg ou pdf)"
      className={classes.imageUploader}
    />
  );
};

export default RegisterFormUpload;
