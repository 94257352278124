import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

type Payload = {
  reason: string;
};

export type ResellerRejectionValidation = {
  reason?: string;
};

type UseResellerRejectionValidation = [
  ResellerRejectionValidation,
  Dispatch<SetStateAction<ResellerRejectionValidation>>,
  (payload: Payload) => Promise<void>,
];

export function useResellerRejectionValidation(): UseResellerRejectionValidation {
  async function handleValidation(payload: Payload) {
    const schema = yup.object().shape({
      reason: yup.string().required('O motivo é obrigatório'),
    });

    try {
      await schema.validate(payload);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<ResellerRejectionValidation>({});
  return [validation, setValidation, handleValidation];
}
