import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Collapsible } from '../Sidebar';

export interface SidebarContextValue {
  handleCollapseClick(collapsible: keyof Collapsible, closeOthers?: boolean): void;
  collapsible: Collapsible;
  setShownUserControl: Dispatch<SetStateAction<boolean>>;
  setShownSite: Dispatch<SetStateAction<boolean>>;
  setShownUnity: Dispatch<SetStateAction<boolean>>;
  setImageManager: Dispatch<SetStateAction<boolean>>;
}

const SidebarContext = createContext<SidebarContextValue>({} as SidebarContextValue);
export const SidebarProvider = SidebarContext.Provider;
export const SidebarConsumer = SidebarContext.Consumer;

export function useSidebar(): SidebarContextValue {
  const context = useContext(SidebarContext);
  return context;
}
