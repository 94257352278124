import React from 'react';
import { Theme } from '@mui/material';
import { ItemsSoldReportTotal } from './ItemsSoldReport';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    backgroundColor: '#fff',
    borderRadius: '8px 8px 0 0',
    padding: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  total: {
    display: 'grid',
    flex: 1,
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: 7,
    marginBottom: 5,
    '& .total': {
      fontSize: 20,
    },
    '& div': {
      background: '#fff',
      padding: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #eee',
      marginBottom: 5,
      '& .paid': {
        color: '#28a745',
        marginLeft: '1px',
      },
      '& .no-paid': {
        color: '#dc3545',
        marginLeft: '1px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

interface ItemsSoldReportTotalProps {
  formattedTotal: ItemsSoldReportTotal;
}

const ItemsSoldReportTotalPage: React.FC<ItemsSoldReportTotalProps> = ({ formattedTotal }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.total}>
        <div>
          <span className="label">Valor total</span>
          <span className="paid total">{formattedTotal.total}</span>
        </div>
        <div>
          <span className="label">Valor custo</span>
          <span className="paid total">{formattedTotal.coust}</span>
        </div>
        <div>
          <span className="label">Valor unitário</span>
          <span className="no-paid total">{formattedTotal.unitary}</span>
        </div>
      </div>
    </div>
  );
};

export default ItemsSoldReportTotalPage;
