import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

type CustomersListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const CustomersListMenu: React.FC<CustomersListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem>Aprovar cadastro</MenuItem>
    </Menu>
  );
};

export default CustomersListMenu;
