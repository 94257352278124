import React from 'react';
import { useApp } from 'hooks/app';
import { Bar } from 'react-chartjs-2';
import { BranchStockBalanceData } from 'types/reports/stockBalance';
import { externalTooltipHandler } from 'components/chart/externalTooltip';

interface SalesAppBranchChartProps {
  allData: BranchStockBalanceData[];
}

export const SalesAppBranchChart: React.FC<SalesAppBranchChartProps> = ({ allData }) => {
  const app = useApp();

  return (
    <Bar
      height={app.isMobile && app.windowWidth < 960 ? 300 : 150}
      data={{
        labels: allData.sort((a, b) => b.CustoTotal - a.CustoTotal).map(branch => [branch.filial]),
        datasets: [
          {
            label: 'Total',
            data: allData.sort((a, b) => b.CustoTotal - a.CustoTotal).map(branch => branch.CustoTotal),
            backgroundColor: '#30519f',
          },
        ],
      }}
      options={{
        plugins: {
          tooltip: {
            enabled: false,
            external: externalTooltipHandler,
          },
        },
        responsive: true,
      }}
    />
  );
};
