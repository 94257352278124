import React from 'react';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { CityMethod } from 'types/salesReport';
import { citiesTableTemplate } from '../citiesTableTemplate';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
});

type CityItemTableProps = {
  city: CityMethod;
};

const CityItemTable: React.FC<CityItemTableProps> = ({ city }) => {
  return (
    <>
      {citiesTableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <CustomIconButton key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
              <Tooltip title={city[item.id]}>
                <Typography
                  className={item.dataType === 'number' ? 'numericData' : undefined}
                  fontSize={12}
                  variant="body2"
                >
                  {city[item.id]}
                </Typography>
              </Tooltip>
            </div>
          ),
        )}
    </>
  );
};

export default CityItemTable;
