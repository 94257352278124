import React from 'react';
import { IconButton, styled } from '@mui/material';
import { FilterList, LocalShipping, Add as AddIcon } from '@mui/icons-material';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

interface BillsActionsProps {
  handleDialogFilter(): void;
  handleDialogRemittance(): void;
  handleDialogRemittances(): void;
}

const BillsActions: React.FC<BillsActionsProps> = ({
  handleDialogFilter,
  handleDialogRemittance,
  handleDialogRemittances,
}) => {
  return (
    <>
      <CustomIconButton color="inherit" onClick={handleDialogRemittance}>
        <AddIcon />
      </CustomIconButton>
      <CustomIconButton color="inherit" onClick={handleDialogFilter}>
        <FilterList />
      </CustomIconButton>

      <CustomIconButton color="inherit" onClick={handleDialogRemittances}>
        <LocalShipping />
      </CustomIconButton>
    </>
  );
};

export default BillsActions;
