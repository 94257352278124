import { TableTemplate } from 'types/tableTemplate';

export const OrdersAuthorizationTableTemplate: TableTemplate[] = [
  {
    id: 'more',
    description: '',
    originalId: 'more',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'actions',
    description: '',
    originalId: 'actions',
    width: 60,
    notSearchable: true,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 80,
  },
  {
    id: 'Cnpj',
    description: 'CNPJ',
    originalId: 'Cnpj',
    width: 200,
  },
  {
    id: 'Cliente',
    description: 'Cliente',
    originalId: 'Cliente',
    width: 350,
  },
  {
    id: 'faturamento_autorizado',
    description: 'Autorizado',
    originalId: 'faturamento_autorizado',
    width: 100,
  },
  {
    id: 'Data',
    description: 'Data',
    originalId: 'formattedDate',
    width: 120,
  },
  {
    id: 'status',
    description: 'Status',
    originalId: 'status',
    width: 150,
  },
  {
    id: 'formattedValueTotal',
    description: 'Total',
    originalId: 'valor_total',
    dataType: 'number',
    width: 150,
  },
  {
    id: 'formattedValueProductTotal',
    description: 'Total Produto',
    originalId: 'valor_total_prod',
    dataType: 'number',
    width: 150,
  },
  {
    id: 'formattedShipment',
    description: 'Frete',
    originalId: 'valor_Frete',
    dataType: 'number',
    width: 150,
  },
];
