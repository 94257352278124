import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Theme, useTheme } from '@mui/material';
import { useBranches } from 'providers/BranchesProviders';
import { Branch } from 'types/branch';

interface SelectMultiBranchesProps {
  branchIds: number[];
  handleChange(value: number[]): void;
  loading?: boolean;
  setBranch?(branch: Branch): void;
}

function getStyles(name: number, personName: readonly number[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold,
    backgroundColor: personName.indexOf(name) === -1 ? '#fff' : '#cfffe6',
  };
}

const SelectMultiBranches: React.FC<SelectMultiBranchesProps> = ({
  branchIds,
  handleChange,
  loading = false,
  setBranch,
}) => {
  const theme = useTheme();
  const { userBranches } = useBranches();

  return (
    <FormControl>
      <InputLabel>Filiais</InputLabel>
      <Select
        style={{ minWidth: 150, height: '100%' }}
        value={branchIds}
        disabled={loading}
        label="Filiais"
        onChange={e => handleChange(e.target.value as number[])}
        multiple
      >
        {userBranches.map(branch => (
          <MenuItem
            style={getStyles(branch.id, branchIds, theme)}
            onClick={() => (setBranch ? setBranch(branch) : undefined)}
            key={branch.id}
            value={branch.id}
          >
            {branch.cidade}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectMultiBranches;
