import { createContext, useContext } from 'react';

interface PrintContextValue {
  handleClose(): void;
}

const PrintContext = createContext<PrintContextValue>({} as PrintContextValue);
export const PrintProvider = PrintContext.Provider;
export const PrintConsumer = PrintContext.Consumer;

export function usePrint(): PrintContextValue {
  const context = useContext(PrintContext);
  return context;
}
