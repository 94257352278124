import { useContext, createContext, Dispatch, SetStateAction } from 'react';

interface RemittancesContextValue {
  handleDelete(remittanceId: number): void;
  saving: boolean;
  selectedId: number | null;
  setSelectedId: Dispatch<SetStateAction<number | null>>;
  setSaving: Dispatch<SetStateAction<boolean>>;
}

const RemittancesContext = createContext<RemittancesContextValue>({} as RemittancesContextValue);
export const RemittancesProvider = RemittancesContext.Provider;
export const RemittancesConsumer = RemittancesContext.Consumer;

export function useRemittances(): RemittancesContextValue {
  const context = useContext(RemittancesContext);
  return context;
}
