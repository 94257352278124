import React, { useState, useEffect } from 'react';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';

import { moneyFormat } from 'helpers/numberFormat';
import { MonthlyCategory } from 'types/monthlyComparison';
import InsideLoading from 'components/loading/InsideLoading';
import { useMonthlyComparison } from '../../hooks/useMonthlyComparison';
import CategoryListTable from '../subgroup/list/table/CategoryListTable';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { makeStyles } from '@mui/styles';

interface CategoryMoreMenuProps {
  categoryId: number;
}

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      margin: 0,
      minHeight: 0,
      overflow: 'visible',
    },
  },
});

const CategoryMoreMenu: React.FC<CategoryMoreMenuProps> = ({ categoryId }) => {
  const classes = useStyles();
  const { h2iApi } = useApp();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const { filter, searchValue } = useMonthlyComparison();
  const [categories, setCategories] = useState<MonthlyCategory[]>([]);
  const [filtered, setFiltered] = useState<MonthlyCategory[]>([]);

  useEffect(() => {
    setFiltered(categories);
  }, [categories]);

  function handleSort(index: string) {
    const filteredP = sort(index, filtered);
    setFiltered(filteredP);
  }

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    h2iApi
      .get(`/api/relcomparativomensalcategoria`, {
        params: {
          id_filial: filter.selectedBranchId || '',
          id_subgrupo: filter.productId || '',
          filtro: searchValue,
          tipo_venda: filter.typeSale,
          id_categoria: categoryId,
          id_segmento: filter.segment || '',
          id_unidade: filter.unity || '',
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
        },
      })
      .then(response => {
        const _categories = response.data.map(item => {
          item.formattedTotal = moneyFormat(item.total);
          item.formattedCoust = moneyFormat(item.custo);
          item.formattedMc = moneyFormat(item.mc);
          item.formattedSpiff = moneyFormat(item.spiff);
          item.formattedUnitary = moneyFormat(item.unitario);

          return item;
        });

        setCategories(_categories);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [h2iApi, filter, searchValue, categoryId]);

  return (
    <div className={classes.div}>
      {loading ? (
        <InsideLoading />
      ) : (
        <CategoryListTable categories={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
      )}
    </div>
  );
};

export default CategoryMoreMenu;
