import React, { useState, useEffect, useCallback } from 'react';
import { Button, IconButton, InputAdornment, TextField, Tooltip, styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import FilterBox from 'components/filter-box/FilterBox';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { priceProtectionTableTemplate } from './priceProtectionTableTemplate';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import TableContainer from 'components/table/TableContainer';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import { PriceProtection } from 'types/priceProtection';
import PriceProtectionListTable from './list/table/PriceProtectionListTable';
import Pagination from 'components/pagination/Pagination';
import PriceProtectionListModule from './list/module/PriceProtectionListModule';
import history from 'services/history';
import { moneyFormat } from 'helpers/numberFormat';
import { MdSearch } from 'react-icons/md';

const CustomTextField = styled(TextField)(({ theme }) => ({
  minWidth: 600,
  [theme.breakpoints.down('md')]: {
    minWidth: 300,
  },
}));

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

let timer: NodeJS.Timeout;

const PriceProtectionPage: React.FC = () => {
  const { h2iApi, isMobile, windowWidth } = useApp();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [orderedIndex, sort] = useTableOrder();
  const [datas, setDatas] = useState<PriceProtection[]>([]);
  const [filtered, setFiltered] = useState<PriceProtection[]>([]);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');

  const loadPrices = useCallback(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    h2iApi
      .get(`/api/getpriceprotection?PRODUTO=${searchText}`)
      .then(response => {
        if (response.data[0].result) {
          return;
        }
        setDatas(
          response.data
            .sort((a, b) => b.id - a.id)
            .map(item => ({
              ...item,
              formattedValue: moneyFormat(item.valor),
              formattedCoust: moneyFormat(item.custo_atual),
              formattedNewCoust: moneyFormat(item.novo_custo),
            })),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [h2iApi, searchText]);

  useEffect(() => {
    clearTimeout(timer);

    timer = setTimeout(loadPrices, 500);
  }, [searchText, loadPrices]);

  useEffect(() => {
    setFiltered(datas);
  }, [datas]);

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <>
      <Appbar title="Price Protection" />
      <PageHeaderActions
        title="Price Protection"
        ActionComponent={
          <Button variant="contained" onClick={() => history.push('/price-protection/new')}>
            Novo
          </Button>
        }
      />

      <FilterBox>
        <CustomTextField
          label="Produto"
          placeholder="Digite o nome do produto"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Pesquisar">
                  <IconButton disabled={loading} type="submit">
                    <MdSearch color="#888" size={20} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </FilterBox>

      <TableContainer tableTemplate={priceProtectionTableTemplate}>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nada encontrado" />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <PriceProtectionListTable datas={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <PriceProtectionListModule datas={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          </PaginationProvider>
        )}
      </TableContainer>
    </>
  );
};

export default PriceProtectionPage;
