import { MenuItem, TextField, styled } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import React from 'react';
import { useFinancialResume } from '../hooks/useFinancialResume';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import { useApp } from 'hooks/app';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 15,
  '& > .content': {
    display: 'flex',
    flexDirection: 'column',
  },
});

interface FinancialResumeMobileFilterProps {
  onExited(): void;
}

const FinancialResumeMobileFilter: React.FC<FinancialResumeMobileFilterProps> = ({ onExited }) => {
  const { filter, handleFilterChange, ordination, setOrdination } = useFinancialResume();
  const { isMobile } = useApp();

  return (
    <Dialog onExited={onExited} title="Filtros">
      <Container>
        <SelectOthersBranch
          margin="dense"
          branch_id={filter.branchId}
          handleChange={value => handleFilterChange('branchId', value)}
          className="hide-on-mobile"
        />

        <DesktopDatePicker
          label="Ano"
          views={['year']}
          value={filter.year}
          onChange={date => handleFilterChange('year', date)}
          localeText={{
            clearButtonLabel: 'Limpar',
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
          textFieldProps={{
            margin: 'normal',
            fullWidth: isMobile,
          }}
        />

        <TextField
          select
          label="Ordernação"
          value={ordination.field}
          onChange={event => setOrdination(state => ({ ...state, field: event.target.value as any }))}
          fullWidth
          margin="normal"
        >
          <MenuItem value="classificacao">Classificação</MenuItem>
          <MenuItem value="valor">Valor</MenuItem>
        </TextField>

        <TextField
          select
          label="Direção"
          value={ordination.direction}
          onChange={event => setOrdination(state => ({ ...state, direction: event.target.value as any }))}
          fullWidth
          margin="normal"
        >
          <MenuItem value="asc">Menores primeiro</MenuItem>
          <MenuItem value="desc">Maiores primeiro</MenuItem>
        </TextField>
      </Container>
    </Dialog>
  );
};

export default FinancialResumeMobileFilter;
