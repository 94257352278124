import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Done } from '@mui/icons-material';

type CourseActionsProps = {
  handleSubmit(): void;
};

const CourseActions: React.FC<CourseActionsProps> = ({ handleSubmit }) => {
  return (
    <Tooltip title="Salvar">
      <IconButton color="inherit" onClick={handleSubmit}>
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default CourseActions;
