import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { SellerCommission } from 'types/reports/sellerCommission';
import { useBranches } from '../../hooks/useSellerCommission';
import { moneyFormat } from 'helpers/numberFormat';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type SellersCommissionItemModuleProps = {
  sellerCommission: SellerCommission;
};

const SellersCommissionItemModule: React.FC<SellersCommissionItemModuleProps> = ({ sellerCommission }) => {
  const classes = useStyles();
  const { setSelectedSellerCommission } = useBranches();

  function handleClick() {
    setSelectedSellerCommission(sellerCommission);
  }

  return (
    <>
      <ListItem button className={classes.listItem} onClick={handleClick}>
        <Typography variant="caption" color="primary">
          Pedido por - {sellerCommission.pedido_por}
        </Typography>
        <Typography gutterBottom>Valor total - {moneyFormat(sellerCommission.vl_total)}</Typography>
        <Typography variant="body2" color="textSecondary">
          Vendedor - {sellerCommission.vendedor}
        </Typography>
      </ListItem>
    </>
  );
};

export default SellersCommissionItemModule;
