import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import {
  BranchStockBalanceData,
  ManufacturerStockBalanceData,
  ResponsibleStockBalanceData,
  SegmentStockBalanceData,
  UnityStockBalanceData,
} from 'types/reports/stockBalance';
import { StockFilter } from 'types/reports/stockFilter';

export type StockBalanceTotalProps = {
  coustTotal: number;
  coustStoppedStock: number;
  reserve: number;
  index: number;
  order: number;
  stock: number;
  stoppedStock: number;
  forecast: number;
};

type StockBalanceContextValue = {
  setSelectedResponsible: Dispatch<SetStateAction<ResponsibleStockBalanceData | null>>;
  setMoreBranch: Dispatch<SetStateAction<BranchStockBalanceData | null>>;
  setMoreUnity: Dispatch<SetStateAction<UnityStockBalanceData | null>>;
  setMoreSegment: Dispatch<SetStateAction<SegmentStockBalanceData | null>>;
  setMoreResponsible: Dispatch<SetStateAction<ResponsibleStockBalanceData | null>>;
  setMoreManufacturer: Dispatch<SetStateAction<ManufacturerStockBalanceData | null>>;
  setMoreStoppedStock: Dispatch<SetStateAction<BranchStockBalanceData | null>>;
  selectedResponsible: ResponsibleStockBalanceData | null;
  moreResponsible: ResponsibleStockBalanceData | null;
  moreManufacturer: ManufacturerStockBalanceData | null;
  moreBranch: BranchStockBalanceData | null;
  moreStoppedStock: BranchStockBalanceData | null;
  moreSegment: SegmentStockBalanceData | null;
  moreUnity: UnityStockBalanceData | null;
  filter: StockFilter;
};

const StockBalanceContext = createContext<StockBalanceContextValue>({} as StockBalanceContextValue);
export const StockBalanceProvider = StockBalanceContext.Provider;

export function useStockBalance(): StockBalanceContextValue {
  const context = useContext(StockBalanceContext);
  return context;
}
