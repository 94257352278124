import { ManagedSale, ManagedSaleProductFormContent } from '../types/managedSale';
import { RentalActions } from './types';

export function changeRentalAction(index: keyof ManagedSale, value: any): RentalActions {
  return {
    type: 'CHANGE_RENTAL',
    index,
    value,
  };
}

export function setRentalAction(rental: ManagedSale): RentalActions {
  return {
    type: 'SET_RENTAL',
    rental,
  };
}

export function addRentalProduct(product: ManagedSaleProductFormContent): RentalActions {
  return {
    type: 'ADD_RENTAL_PRODUCT',
    product,
  };
}

export function removeRentalProductAction(externalId: string): RentalActions {
  return {
    type: 'REMOVE_RENTAL_PRODUCT',
    externalId,
  };
}
