import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { ProductList } from 'types/product';
import { usePagination } from 'hooks/pagination';
import WasteProductItemModule from './WasteProductItemModule';

type WasteProductListModuleProps = {
  products: ProductList[];
  selectedProduct: ProductList | null;
  handleSelectProduct(product: ProductList | null): void;
};

const WasteProductListModule: React.FC<WasteProductListModuleProps> = ({
  selectedProduct,
  handleSelectProduct,
  products,
}) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => (
            <WasteProductItemModule
              selectedProduct={selectedProduct}
              handleSelectProduct={handleSelectProduct}
              key={product.codigo_produto}
              product={product}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default WasteProductListModule;
