import React from 'react';
import { Typography } from '@mui/material';
import { H2iReseller } from 'types/h2iReseller';

interface ResellerItemProps {
  reseller: H2iReseller;
  onSelect(reseller: H2iReseller): void;
}

const ResellerItem: React.FC<ResellerItemProps> = ({ reseller, onSelect }) => {
  function handleClick() {
    onSelect(reseller);
  }

  return (
    <li key={reseller.codigo} onClick={handleClick}>
      <Typography>{reseller.nome}</Typography>
      <Typography color="textSecondary" variant="body2">
        {reseller.cnpj_cpf}
      </Typography>
    </li>
  );
};

export default ResellerItem;
