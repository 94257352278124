import React, { useEffect, useRef } from 'react';
import { TextField, styled } from '@mui/material';
import PostalCodeInput from 'components/masked-input/PostalCodeInput';
import { ProjectValidation } from 'pages/projects/validation/projectValidation';
import { useProject } from '../../hook/useProject';
import { ProjectAddress } from 'types/project';
import { changeProjectAddress } from '../../reducer/action';
import { postalCodeSearch } from 'services/postalCodeSearch';
import MaskedInput from 'react-text-mask';

const Container = styled('div')({
  marginTop: 20,
});

const Row = styled('div')({
  marginBottom: 10,
  '&.two-columns': {
    display: 'grid',
    gridTemplateColumns: '1fr 100px',
    columnGap: '10px',
  },
});

let timer: NodeJS.Timeout;

interface ProjectShipmentProps {
  validation: ProjectValidation;
}

const ProjectShipment: React.FC<ProjectShipmentProps> = ({ validation }) => {
  const { project, dispatch } = useProject();

  const inputs = {
    'address.postal_code': useRef<MaskedInput>(null),
    'address.street': useRef<HTMLInputElement>(null),
    'address.street_number': useRef<HTMLInputElement>(null),
    'address.city': useRef<HTMLInputElement>(null),
    'address.state': useRef<HTMLInputElement>(null),
    'address.neighborhood': useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    if (key === 'address.postal_code') {
      inputs[key].current?.inputElement.focus();
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleChange(index: keyof ProjectAddress, value: string | null) {
    dispatch(changeProjectAddress(index, value));
  }

  function handlePostalCodeChange(value: string) {
    handleChange('postal_code', value);

    const postalCodeSanitized = value.replace(/\D/g, '');

    clearTimeout(timer);

    if (postalCodeSanitized.length < 2) {
      return;
    }

    timer = setTimeout(() => {
      postalCodeSearch(postalCodeSanitized)
        .then(response => {
          if (response.data.erro) {
            return;
          }
          const { data } = response;

          handleChange('city', data.localidade);
          handleChange('state', data.uf);
          handleChange('street', data.logradouro);
          handleChange('neighborhood', data.bairro);
        })
        .catch(err => {
          console.error(err);
        });
    }, 500);
  }

  return (
    <Container>
      <Row>
        <TextField
          autoFocus
          error={!!validation['address.postal_code']}
          helperText={validation['address.postal_code']}
          label="CEP"
          placeholder="Informe o CEP"
          value={project.address.postal_code}
          onChange={e => handlePostalCodeChange(e.target.value)}
          fullWidth
          InputProps={{
            inputComponent: PostalCodeInput as any,
          }}
          margin="normal"
          inputRef={inputs['address.postal_code']}
        />
      </Row>
      <Row className="two-columns">
        <TextField
          label="Logradouro"
          placeholder="Informe o logradouro"
          value={project.address.street}
          error={!!validation['address.street']}
          helperText={validation['address.street']}
          onChange={e => handleChange('street', e.target.value)}
          margin="normal"
          fullWidth
          inputRef={inputs['address.street']}
        />
        <TextField
          error={!!validation['address.street_number']}
          helperText={validation['address.street_number']}
          label="Número"
          placeholder="Informe o número da rua"
          value={project.address.street_number}
          onChange={e => handleChange('street_number', e.target.value)}
          margin="normal"
          fullWidth
          inputRef={inputs['address.street_number']}
        />
      </Row>
      <Row>
        <TextField
          error={!!validation['address.neighborhood']}
          helperText={validation['address.neighborhood']}
          label="Bairro"
          placeholder="Informe o bairro"
          value={project.address.neighborhood}
          onChange={e => handleChange('neighborhood', e.target.value)}
          margin="normal"
          fullWidth
          inputRef={inputs['address.neighborhood']}
        />
      </Row>
      <Row className="two-columns">
        <TextField
          error={!!validation['address.city']}
          helperText={validation['address.city']}
          label="Cidade"
          placeholder="Informe a cidade"
          value={project.address.city}
          onChange={e => handleChange('city', e.target.value)}
          margin="normal"
          fullWidth
          inputRef={inputs['address.city']}
        />
        <TextField
          error={!!validation['address.state']}
          helperText={validation['address.state']}
          label="Estado"
          placeholder="Informe o estado"
          value={project.address.state}
          onChange={e => handleChange('state', e.target.value)}
          margin="normal"
          fullWidth
          inputRef={inputs['address.state']}
        />
      </Row>
    </Container>
  );
};

export default ProjectShipment;
