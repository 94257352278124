import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Extract } from 'types/extract';
import { BankStatementFilterProps } from '../BankStatement';
import { TotalizingAccount } from 'types/totalizingAccount';
import { TotalExtracts } from './useFetchExtracts';

interface ExtractContextValue {
  filter: BankStatementFilterProps;
  handleChangeFilter(index: keyof BankStatementFilterProps, value: any): void;
  setIsReportOpen: Dispatch<SetStateAction<boolean>>;
  handleSearch(): void;
  loading: boolean;
  handleSelectAll(): void;
  selectAll: boolean;
  selectedExtracts: Extract[];
  handleToggle(extract: Extract): void;
  loadingTotalizingAccount: boolean;
  extracts: Extract[];
  total: TotalExtracts;
  showTotals: boolean;
  defaultView: boolean;
  setAppropriationDialog: Dispatch<SetStateAction<boolean>>;
  setShowTotals: Dispatch<SetStateAction<boolean>>;
  setExtract: Dispatch<SetStateAction<Extract | null>>;
  totalizingAccounts: TotalizingAccount[];
  setIsNewTransferOpen: Dispatch<SetStateAction<boolean>>;
  setIsNewExpense: Dispatch<SetStateAction<boolean>>;
  setDefaultView: Dispatch<SetStateAction<boolean>>;
  setInvoice: Dispatch<SetStateAction<{ id: number; tipo: string } | null>>;
}

const ExtractContext = createContext<ExtractContextValue>({} as ExtractContextValue);
export const ExtractConsumer = ExtractContext.Consumer;
export const ExtractProvider = ExtractContext.Provider;

export function useExtracts() {
  return useContext(ExtractContext);
}
