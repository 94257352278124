import React from 'react';
import { MonthlyBranch } from 'types/monthlyComparison';
import { Typography, ListItem } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type BranchItemModuleProps = {
  branch: MonthlyBranch;
};

const BranchItemModule: React.FC<BranchItemModuleProps> = ({ branch }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem}>
      <Typography variant="caption" color="primary">
        {branch.nome}
      </Typography>
      <Typography variant="caption" color="primary">
        Cidade - {branch.cidade}
      </Typography>
    </ListItem>
  );
};

export default BranchItemModule;
