import { useCallback, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { CategoriesQueryParams } from '../Categories';
import { Category } from 'types/category';
import { useApp } from 'hooks/app';

export function useFetchCategories() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const { h2iApi } = useApp();
  const [error, setError] = useState('');

  const fetch = useCallback(
    (params: CategoriesQueryParams) => {
      if (!h2iApi) {
        return;
      }
      setLoading(true);

      h2iApi
        .get('/categorias', { params })
        .then(response => {
          setCategories(
            response.data.data.map(item => ({
              ...item,
              formattedUpdatedAt: format(parseISO(item.updated_at), 'P', { locale: ptBR }),
              formattedCreatedAt: format(parseISO(item.created_at), 'P', { locale: ptBR }),
            })),
          );
        })
        .catch(err => {
          console.error(err);
          setError('Aconteceu um erro ao carregar a lista de categorias ' + err.message);
        })
        .finally(() => setLoading(false));
    },
    [h2iApi],
  );

  return {
    fetch,
    categories,
    setCategories,
    loading,
    error,
  };
}
