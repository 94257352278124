import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface CreatePortalProps {
  children: ReactNode;
}

const CreatePortal: React.FC<CreatePortalProps> = ({ children }) => {
  return createPortal(children, document.body);
};

export default CreatePortal;
