import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { SelloutFilterProps } from '../Sellout';

interface SelloutContextValue {
  filter: SelloutFilterProps;
  handleChangeFilter(index: keyof SelloutFilterProps, value: any): void;
  setShowTotals: Dispatch<SetStateAction<boolean>>;
  showTotals: boolean;
  handleSubmit(initialDate: Date, finalDate: Date): void;
  loading: boolean;
}

const SelloutContext = createContext<SelloutContextValue>({} as SelloutContextValue);
export const SelloutConsumer = SelloutContext.Consumer;
export const SelloutProvider = SelloutContext.Provider;

export function useSellout() {
  return useContext(SelloutContext);
}
