import { Button, Theme } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { endOfDay, format, parseISO, startOfDay, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import React, { useEffect, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import SiteBannersFilterBox from './NewsletterSubscribersFilterBox';
import { newsletterSubscribers } from './newsletterSubscriberTableTemplate';
import { NewsletterSubscribersProvider } from './hooks/useNewsletters';
import SiteBannerListModule from './list/module/NewsletterSubscriberListModule';
import SiteBannerListTable from './list/table/NewsletterSubscriberListTable';
import { NewsletterSubscriber } from 'types/newsletterSubscriber';
import { formatPhoneNumber } from 'helpers/phoneNumberFormatter';
import { makeStyles } from '@mui/styles';
import { usePermissionRules } from 'hooks/permissionRules';

export interface NewsletterSubscriberParams {
  initial_date: Date | null;
  final_date: Date | null;
  is_exported: boolean;
}

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const paramsInitialValue: NewsletterSubscriberParams = {
  initial_date: startOfDay(subDays(new Date(), 180)),
  final_date: endOfDay(new Date()),
  is_exported: false,
};

const NewsletterSubscribers: React.FC = () => {
  const app = useApp();
  const classes = useStyles();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState<NewsletterSubscriber[]>([]);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [subscribers, setSubscribers] = useState<NewsletterSubscriber[]>([]);
  const [selectedSubscriber, setSelectedSubscriber] = useState<null | NewsletterSubscriber>(null);
  const [filter, setFilter] = useState<NewsletterSubscriberParams>(paramsInitialValue);
  const { checkPermission } = usePermissionRules();

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    setLoading(true);

    api
      .get<NewsletterSubscriber[]>('/newsletter-subscribers', {
        cancelToken: source.token,
        params: { ...filter, is_exported: filter.is_exported ? 1 : 0 },
      })
      .then(response => {
        if (request)
          setSubscribers(
            response.data.map(item => {
              item.formattedCreatedAt = format(parseISO(item.created_at), 'PPp', { locale: ptBR });
              item.formattedPhone = formatPhoneNumber(item.phone);
              item.formattedIsExported = item.is_exported ? 'Sim' : 'Não';
              return item;
            }),
          );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (request) {
          setLoading(false);
        }
      });

    return () => {
      if (request) request = false;
      source.cancel();
    };
  }, [filter]);

  useEffect(() => {
    setFiltered(subscribers);
  }, [subscribers]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleChangeFilter(index: keyof NewsletterSubscriberParams, value: Date | null) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleExportExcel() {
    const token = localStorage.getItem('h2i@token');

    if (!token) return;

    const params = new URLSearchParams();

    params.append('token', token);
    params.append('initial_date', filter.initial_date?.toISOString() as any);
    params.append('final_date', filter.final_date?.toISOString() as any);
    params.append('is_exported', filter.is_exported ? '1' : '0');

    window.open(`${process.env.REACT_APP_API}newsletterSubscribers/export?${params.toString()}`);
  }

  return (
    <NewsletterSubscribersProvider value={{ selectedSubscriber, setSelectedSubscriber, filter, handleChangeFilter }}>
      <Appbar title="Site" />
      <PageHeaderActions
        title="Inscritos para newsletter"
        description="Gestão dos assinantes"
        ActionComponent={
          <>
            {checkPermission('general.excel_export_button') && (
              <Button
                disabled={loading || filtered.length === 0}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleExportExcel}
              >
                Excel
              </Button>
            )}
          </>
        }
      />
      <TableContainer tableTemplate={newsletterSubscribers}>
        <SiteBannersFilterBox setDisplayMode={setDisplayMode} displayMode={displayMode} />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum inscrito para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <SiteBannerListTable subscribers={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <SiteBannerListModule subscribers={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </NewsletterSubscribersProvider>
  );
};

export default NewsletterSubscribers;
