import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { PurchaseOrderProduct } from 'types/purchaseOrder';
import { usePurchaseOrder } from '../../hook/usePurchaseOrder';
import { removePurchaseOrderProductAction } from 'pages/purchase/order/reducer/actions';

type ProductsListMenuProps = {
  type: 'update' | 'new';
  index: number;
  disabled: boolean;
  anchorEl: HTMLButtonElement | null;
  onExited(): void;
  selectedProduct: PurchaseOrderProduct | null;
};

const ProductsListMenu: React.FC<ProductsListMenuProps> = ({
  anchorEl,
  type,
  index,
  disabled,
  onExited,
  selectedProduct,
}) => {
  const { dispatch } = usePurchaseOrder();

  function handleDeleteClick() {
    if (!selectedProduct) {
      return;
    }

    dispatch(removePurchaseOrderProductAction(selectedProduct.codigo_produto, index, type));
    onExited();
  }

  return (
    <Menu
      aria-disabled={disabled || selectedProduct?.cancelado === 'S'}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onExited}
    >
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default ProductsListMenu;
