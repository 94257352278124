import React from 'react';
import DialogInput from 'components/dialogs/DialogInput';
import KeyboardDatePicker from '../../../components/pickers/DesktopDatePicker';
import { useBranches } from './hooks/useSellerCommission';

type SellersCommissionReportFilterProps = {
  onExited(): void;
};

const SellersCommissionReportFilter: React.FC<SellersCommissionReportFilterProps> = ({ onExited }) => {
  const { filter, handleChangeFilter } = useBranches();

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <KeyboardDatePicker
        label="Data inicial"
        value={filter.initialDate}
        onChange={date => handleChangeFilter('initialDate', date)}
      />
      <KeyboardDatePicker
        label="Data Final"
        value={filter.finalDate}
        onChange={date => handleChangeFilter('finalDate', date)}
      />
    </DialogInput>
  );
};

export default SellersCommissionReportFilter;
