export const userTableTamplate = [
  { id: 'id', description: 'ID', originalId: 'id', width: 100 },
  { id: 'username', description: 'NOME DE USUÁRIO', originalId: 'username', width: 200 },
  { id: 'name', description: 'NOME', originalId: 'name', width: 300 },
  { id: 'formattedProfileName', description: 'PERFIL', originalId: 'formattedProfileName', width: 200 },
  { id: 'email', description: 'E-MAIL', originalId: 'email', width: 300 },
  { id: 'mobile_phone', description: 'CELULAR', originalId: 'mobile_phone', width: 200 },
  { id: 'fixed_phone', description: 'TELEFONE FIXO', originalId: 'fixed_phone', width: 200 },
  { id: 'role', description: 'CARGO', originalId: 'role', width: 200 },
  { id: 'formattedActive', description: 'ATIVO', originalId: 'formattedActive', width: 200 },
];
