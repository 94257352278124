import Dialog from 'components/dialogs/Dialog';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'store/selector';
import ProjectReportAppbarActions from './RentalReportAppbarButtons';
import { styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import { useRentals } from '../hooks/useRentals';
import { RentalOnList } from '../types/rental';
import { useRentalFormatter } from '../hooks/useRentalFormatter';
import { useFetchRental } from '../hooks/useFetchRental';
import InsideLoading from 'components/loading/InsideLoading';

const Container = styled('div')({
  '& .label': {
    fontSize: 10,
    color: '#888',
    marginBottom: 7,
  },
});

const Row = styled('div')({
  display: 'grid',
  gridTemplateColumns: '50px 1fr 100px 80px 80px',
  marginBottom: 1,
  paddingBottom: 1,
  alignItems: 'center',
  '& > p': {
    fontSize: 12,
  },
});

const HeaderRightContent = styled('div')({
  '& p': {
    color: '#888',
    fontSize: 12,
  },
});

const ProductsContent = styled('div')({
  marginTop: 5,
  display: 'flex',
  flexDirection: 'column',
  rowGap: 5,
  padding: 15,
  border: '1px solid #eee',
  '& .table-header': {
    fontWeight: 'bold',
  },
});

const TotalContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 10,
  border: '1px solid #eee',
  marginTop: 10,
  '& .grid': {
    display: 'grid',
    gridTemplateColumns: '120px 1fr',
    columnGap: 20,
    rowGap: 7,
    '& > p': {
      textAlign: 'right',
    },
    '& .value': {
      fontWeight: 'bold',
    },
  },
});

const CustomerContent = styled('div')({
  border: '1px solid #eee',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  '& > p, span': {
    fontSize: 12,
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
  },
  '& > p > span': {
    fontWeight: 'bold',
  },
});

interface RentalReportReportProps {
  onExited(): void;
}

const RentalReport: React.FC<RentalReportReportProps> = ({ onExited }) => {
  const user = useSelector(state => state.user);
  const { selectedRental } = useRentals();
  const [rental, setRental] = useState<RentalOnList | null>(null);
  const [loadedRental, loading] = useFetchRental(selectedRental?.id);
  const formatter = useRentalFormatter();

  const formattedId = useMemo(() => `#${rental?.sequence.toString().padStart(3, '0')}`, [rental]);

  useEffect(() => {
    setRental(formatter(loadedRental));
  }, [formatter, loadedRental]);

  const formattedTotal = useMemo(() => {
    if (!rental) {
      return moneyFormat(0);
    }
    return moneyFormat(rental?.total);
  }, [rental]);

  return (
    <Dialog
      fullScreen
      title={`Locação ${formattedId}`}
      onExited={onExited}
      ComponentActions={<ProjectReportAppbarActions />}
    >
      {loading ? (
        <InsideLoading />
      ) : (
        <Container>
          <div className="page">
            <div className="header">
              <div>
                <h3>Locação {formattedId}</h3>
              </div>
              <HeaderRightContent>
                <p>{user?.name}</p>
                <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
              </HeaderRightContent>
            </div>

            <CustomerContent>
              <p>
                <span>Nome:</span> {rental?.customer_name}
              </p>
              <p>
                <span>CNPJ/CPF:</span> {rental?.customer_document_number}
              </p>
              <p>
                <span>Finaliza em:</span> {rental?.formattedEndAt}
              </p>
              <p>
                <span>Ativo:</span> {rental?.formattedActive}
              </p>
            </CustomerContent>

            <ProductsContent>
              <p className="label">
                <strong>Produtos</strong>
              </p>

              <Row className="table-header">
                <p>Código</p>
                <p>Descrição</p>
                <p className="right">Qtd</p>
                <p className="right">Preço</p>
                <p className="right">Total</p>
              </Row>
              {rental?.products.map(product => (
                <Row key={product.external_id}>
                  <p>{product.external_id}</p>
                  <p>{product.name}</p>
                  <p className="right">{product.quantity}</p>
                  <p className="right">{product.formattedValue}</p>
                  <p className="right">{moneyFormat(product.quantity * product.value)}</p>
                </Row>
              ))}
            </ProductsContent>

            <TotalContent>
              <p className="label">
                <strong>Totais</strong>
              </p>
              <div className="grid">
                <p>Produtos</p>
                <p className="value">{formattedTotal}</p>
              </div>
            </TotalContent>
          </div>
        </Container>
      )}
    </Dialog>
  );
};

export default RentalReport;
