import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { IconButton, InputAdornment, MenuItem, TextField, Tooltip, Theme } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import { MdSearch } from 'react-icons/md';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import { FaFileExcel } from 'react-icons/fa';
import { FilterList, Print } from '@mui/icons-material';
import { StockFilter } from 'types/reports/stockFilter';
import { usePermissionRules } from 'hooks/permissionRules';
import SelectMultiBranches from 'components/select-branches/SelectMultiBranches';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '0.7fr 1fr 1fr 0.3fr',
    columnGap: 10,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gap: 10,
    },
    '& > .search-params': {
      display: 'flex',
      justifyContent: 'end',
    },
  },
  button: {
    display: 'block',
  },
}));

interface StockBalanceFilterProps {
  onSearch(filter: StockFilter): void;
  handleChange(index: keyof StockFilter, value: any): void;
  displayMode: 'list' | 'module';
  setDisplayMode: Dispatch<SetStateAction<'list' | 'module'>>;
  openReport(): void;
  openMoreFilter(): void;
  filter: StockFilter;
  setExcelExport: Dispatch<SetStateAction<boolean>>;
}

const StockBalanceFilter: React.FC<StockBalanceFilterProps> = ({
  handleChange,
  displayMode,
  setDisplayMode,
  onSearch,
  openReport,
  openMoreFilter,
  setExcelExport,
  filter,
}) => {
  const classes = useStyles();
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const { checkPermission } = usePermissionRules();

  useEffect(() => {
    inputSearchRef.current?.focus();
    inputSearchRef.current?.setSelectionRange(0, inputSearchRef.current.value.length);
  }, [filter.indexToSearch]);

  return (
    <FilterBox>
      <div className={classes.filter}>
        <SelectMultiBranches branchIds={filter.branchId} handleChange={e => handleChange('branchId', e)} />

        <TextField
          value={filter.indexToSearch}
          onChange={e => handleChange('indexToSearch', e.target.value)}
          select
          label="Pesquisar em"
        >
          <MenuItem value="Produto">Descrição</MenuItem>
          <MenuItem value="CodProd">ID</MenuItem>
        </TextField>
        <TextField
          inputRef={inputSearchRef}
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={filter.searchValue}
          onChange={e => handleChange('searchValue', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment onClick={() => onSearch(filter)} position="start">
                <IconButton type="submit">
                  <MdSearch color="#888" size={20} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className="search-params">
          {checkPermission('general.excel_export_button') && (
            <div>
              <Tooltip title="Exportar excel" className={classes.filter}>
                <IconButton className={classes.button} color="inherit" onClick={() => setExcelExport(true)}>
                  <FaFileExcel />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <div>
            <Tooltip title="Imprimir" className={classes.filter}>
              <IconButton className={classes.button} color="inherit" onClick={openReport}>
                <Print />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Outros filtros" className={classes.filter}>
              <IconButton className={classes.button} color="inherit" onClick={openMoreFilter}>
                <FilterList />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default StockBalanceFilter;
