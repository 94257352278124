import React from 'react';
import { List } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import SellerItemModule from './SellerItemModule';
import { AbcProductSeller } from 'types/abcProduct';
import { makeStyles } from '@mui/styles';

type SellerListModuleProps = {
  sellers: AbcProductSeller[];
};

const styles = makeStyles({
  list: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: 100,
    flex: 1,
  },
});

const SellerListModule: React.FC<SellerListModuleProps> = ({ sellers }) => {
  const { rowsPerPage, page } = usePagination();
  const classes = styles();

  return (
    <List className={classes.list}>
      {sellers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((seller, index) => (
        <SellerItemModule key={index + 1} seller={seller} />
      ))}
    </List>
  );
};

export default SellerListModule;
