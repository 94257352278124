import React from 'react';
import { styled } from '@mui/material';
import TopAgingList from './TopAgingList';
import TopAgingBuyersChart from './TopAgingBuyersChart';
import { usePurchaseOrder } from '../../hooks/PurchaseOrderProvider';
import InsideLoading from 'components/loading/InsideLoading';

const Container = styled('div')({
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  rowGap: 10,
  flex: 1,
  '& svg': {
    fontSize: 12,
  },
});

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const PurchaseOrderAging: React.FC = () => {
  const { agingBuyers, agingList, loading } = usePurchaseOrder();
  return (
    <Container>
      {loading.aging ? (
        <InsideLoading />
      ) : (
        <Content>
          <TopAgingBuyersChart data={agingBuyers} />
          <TopAgingList data={agingList} />
        </Content>
      )}
    </Container>
  );
};

export default PurchaseOrderAging;
