import { TableTemplate } from 'types/tableTemplate';

export const titlesTableTemplate: TableTemplate[] = [
  {
    id: 'fornecedor',
    description: 'Fornecedor',
    originalId: 'fornecedor',
    width: 190,
  },
  {
    id: 'tipo',
    description: 'Tipo',
    originalId: 'tipo',
    width: 60,
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'valor',
    width: 110,
    dataType: 'number',
  },
  {
    id: 'data_pagamento',
    description: 'Data Pag',
    originalId: 'data_pagamento',
    width: 120,
  },
  {
    id: 'vencimento',
    description: 'Vencimento',
    originalId: 'vencimento',
    width: 120,
  },
  {
    id: 'nota_fiscal',
    description: 'Nota Fiscal',
    originalId: 'nota_fiscal',
    width: 200,
  },
];
