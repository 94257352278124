import { Dispatch, SetStateAction, useState } from 'react';
import { CreditAnalysisQuestion } from 'types/creditAnalysisQuestion';
import * as yup from 'yup';

export type CreditAnalysisQuestionValidation = {
  order?: string;
  question_text?: string;
  text_answer_no?: string;
};

type UseCreditAnalysisQuestionValidation = [
  CreditAnalysisQuestionValidation,
  Dispatch<SetStateAction<CreditAnalysisQuestionValidation>>,
  (question: CreditAnalysisQuestion) => Promise<void>,
];

export function useCreditAnalysisQuestionValidation(): UseCreditAnalysisQuestionValidation {
  async function handleValidation(question: CreditAnalysisQuestion) {
    const schema = yup.object().shape({
      text_answer_no: !question.forward_on_answer_no
        ? yup.string().required('Informe uma mensagem quando não avançar na resposta NAÕ')
        : yup.string().nullable(),
      question_text: yup.string().required('A questão é obrigatória'),
      order: yup.string().required('a order é obrigatória'),
    });

    try {
      await schema.validate(question);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<CreditAnalysisQuestionValidation>({});
  return [validation, setValidation, handleValidation];
}
