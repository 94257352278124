import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { OrderAuthorization } from 'types/orderAuthorization';
import { useOrdersAuthorization } from '../../hooks/useOrdersAuthorization';
import { Typography, ListItem, IconButton } from '@mui/material';

import { makeStyles } from '@mui/styles';

type OrderItemModuleProps = {
  order: OrderAuthorization;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});

const OrderItemModule: React.FC<OrderItemModuleProps> = ({ order, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedOrder, setDialog } = useOrdersAuthorization();

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedOrder(order);
  }

  function handleOrderClick() {
    setSelectedOrder(order);
    setDialog(true);
  }

  return (
    <ListItem button className={classes.listItem} onClick={handleOrderClick}>
      <Typography variant="caption" color="primary">
        Pedido {order.id}
      </Typography>
      <Typography gutterBottom>{order.id}</Typography>
      <Typography variant="body2" color="textSecondary">
        {order.id}
      </Typography>
      <IconButton size="small" onClick={e => handleMoreClick(e)} className={classes.buttonMore}>
        <MoreHoriz />
      </IconButton>
    </ListItem>
  );
};

export default OrderItemModule;
