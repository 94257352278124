import React from 'react';
import { IconButton, Tooltip, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import history from 'services/history';

const CustomToolTip = styled(Tooltip)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

const AccessRulesActions: React.FC = () => {
  return (
    <CustomToolTip title="Adicionar" onClick={() => history.push('/access-rule')}>
      <IconButton color="inherit">
        <AddIcon />
      </IconButton>
    </CustomToolTip>
  );
};

export default AccessRulesActions;
