import React, { useState } from 'react';
import { Typography } from '@mui/material';
import ContactItemTable from './ContactItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { makeStyles } from '@mui/styles';
import { contactsTableTemplate } from '../contactsTableTemplate';
import { ProjectContact } from 'types/project';
import { useProjectContacts } from '../../hooks/useProjectContacts';
import ProjectProductsListMenu from '../ProjectContactsListMenu';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type ContactListTableProps = {
  contacts: ProjectContact[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const ContactListTable: React.FC<ContactListTableProps> = ({ contacts, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { setSelectedContact } = useProjectContacts();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  function handleClick(product: ProjectContact) {
    setSelectedContact(product);
  }

  return (
    <>
      <ProjectProductsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <TableContainer tableTemplate={contactsTableTemplate}>
        <TableContent>
          <TableHeader>
            {contactsTableTemplate.map(item => (
              <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
          </TableHeader>
          <TableBody>
            {contacts.map(contact => (
              <TableRow onClick={() => handleClick(contact)} key={contact.id}>
                <ContactItemTable setAnchorEl={setAnchorEl} contact={contact} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default ContactListTable;
