import React from 'react';
import { Typography, alpha, Theme } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableRow from 'components/table/TableRow';
import TableContent from 'components/table/TableContent';
import TableHeader from 'components/table/TableHeader';
import { OrderIndexData } from 'hooks/tableOrder';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import { SellerCommission } from 'types/reports/sellerCommission';
import CommissionSellersItemTable from './CommissionSellersItemTable';
import { SellersCommissionTableTemplate } from '../../template/SellersCommissionTableTemplate';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
  selectedCustomer: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
  },
}));

type CommissionSellersListTableProps = {
  sellersCommission: SellerCommission[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const CommissionSellersListTable: React.FC<CommissionSellersListTableProps> = ({
  sellersCommission,
  handleSort,
  orderedIndex,
}) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();

  return (
    <TableContent>
      <TableHeader>
        {SellersCommissionTableTemplate.map(item => (
          <div
            className={
              item.dataType === 'number' ? `${classes.headerItem} ${classes.headerItemNumeric}` : classes.headerItem
            }
            key={item.id}
            onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
          >
            <Typography variant="caption" color="primary">
              {item.description}
            </Typography>
            {orderedIndex.index === item.originalId && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </div>
        ))}
      </TableHeader>
      <TableBody>
        {sellersCommission.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(sellerCommission => (
          <TableRow key={sellerCommission.categoria}>
            <CommissionSellersItemTable sellerCommission={sellerCommission} />
          </TableRow>
        ))}
      </TableBody>
    </TableContent>
  );
};

export default CommissionSellersListTable;
