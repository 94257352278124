import { createContext, useContext } from 'react';
import { CommissionCategory } from '../../../../types/reports/commissionCategory';
import { CommissionCategoryFilter } from '../CommissionCategoryReport';

type CommissionCategoryContextValue = {
  selectedCommissionCategory: CommissionCategory | null;
  setSelectedCommissionCategory(partner: CommissionCategory | null): void;
  handleChangeFilter(index: keyof CommissionCategoryFilter, value: any): void;
  filter: CommissionCategoryFilter;
};

const CommissionCategoryContext = createContext<CommissionCategoryContextValue>({} as CommissionCategoryContextValue);
export const CommissionCategoryProvider = CommissionCategoryContext.Provider;

export function useBranches(): CommissionCategoryContextValue {
  const context = useContext(CommissionCategoryContext);
  return context;
}
