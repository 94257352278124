import { Dispatch, SetStateAction, useState } from 'react';
import { ProjectProductFormContent } from 'types/project';
import * as yup from 'yup';

export type ProjectProductValidation = {
  id?: string;
  name?: string;
  value?: string;
  quantity?: string;
};

type UseProjectProductValidation = [
  ProjectProductValidation,
  Dispatch<SetStateAction<ProjectProductValidation>>,
  (product: ProjectProductFormContent) => Promise<void>,
];

export function useProjectProductValidation(): UseProjectProductValidation {
  async function handleValidation(product: ProjectProductFormContent) {
    const schema = yup.object().shape({
      quantity: yup.string().required('a quantidade do produto é obrigatório'),
      value: yup.string().required('o valor do produto é obrigatório'),
      id: yup.string().required('O código do produto é obrigatório').nullable(),
      name: yup.string().required('o nome do produto é obrigatório'),
    });

    try {
      await schema.validate(product);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<ProjectProductValidation>({});
  return [validation, setValidation, handleValidation];
}
