import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'services/api';
import history from 'services/history';
import SiteBannerActions from './PromotionalBannerActions';
import SiteBannerForm from '../PromotionalBannerForm';
import { usePromotionalBannerValidation } from '../validation/promotionalBannerValidation';
import { PromotionalBanner } from 'types/promotionalBanners';

const PromotionalBannerUpdate: React.FC = () => {
  const [banner, setBanner] = useState<PromotionalBanner>({} as PromotionalBanner);
  const [validation, setValidation, validate] = usePromotionalBannerValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/promotionalBanners/${id}`)
      .then(response => {
        setBanner(response.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function handleChange(index: keyof PromotionalBanner, value: any) {
    if (index === 'image') {
      setBanner(state => ({
        ...state,
        image: value,
        image_id: value ? value.id : null,
      }));
      return;
    }

    if (index === 'mobile_image') {
      setBanner(state => ({
        ...state,
        mobile_image: value,
        mobile_image_id: value ? value.id : null,
      }));
      return;
    }

    setBanner(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(banner)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/promotionalBanners/${id}`, banner)
      .then(() => {
        setSaving(false);
        history.push('/site/promotional-banners');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete(`/promotional-banners/${id}`)
      .then(() => {
        setSaving(false);
        history.push('/site/promotional-banners');
        handleOpen('Excluído');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Site"
        ActionsComponent={
          <SiteBannerActions
            saving={saving}
            loading={loading}
            handleSubmit={handleValidation}
            handleDelete={handleDelete}
          />
        }
        backAction={() => history.push('/site/promotional-banners')}
      />
      <PageHeader title="Editar banner" backUrl="/site/promotional-banners" />
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          <form onSubmit={handleValidation}>
            <SiteBannerForm handleChange={handleChange} banner={banner} validation={validation} />
          </form>
        </Grid>
      )}
    </>
  );
};

export default PromotionalBannerUpdate;
