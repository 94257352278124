import React, { useState } from 'react';
import { IconButton, Tooltip, Menu, MenuItem } from '@mui/material';
import LoupeIcon from '@mui/icons-material/Loupe';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useApp } from 'hooks/app';
import { Image } from 'types/image';
import { FileCopy } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  upload: {
    display: 'inline-flex',
    padding: 12,
    verticalAlign: 'middle',
    cursor: 'pointer',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
});

type ImageSelectorActionsProps = {
  selectedImage: Image | null;
  openImagePreview(): void;
  handleImageDelete(): void;
  handleConfirmImage(): void;
  handleUploadFiles(image: FileList | null): void;
  handleCopyImageUrl(): void;
  showConfirmAction: boolean;
};

const ImageSelectorActions: React.FC<ImageSelectorActionsProps> = ({
  selectedImage,
  openImagePreview,
  handleImageDelete,
  handleConfirmImage,
  handleUploadFiles,
  handleCopyImageUrl,
  showConfirmAction,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const app = useApp();

  return selectedImage ? (
    <>
      {showConfirmAction && (
        <Tooltip title="Confirmar">
          <IconButton onClick={handleConfirmImage} color="inherit">
            <DoneIcon />
          </IconButton>
        </Tooltip>
      )}
      {!app.isMobile && (
        <>
          <Tooltip title="Copiar endereço da imagem">
            <IconButton onClick={handleCopyImageUrl} color="inherit">
              <FileCopy />
            </IconButton>
          </Tooltip>
          <Tooltip title="Ver imagem">
            <IconButton onClick={openImagePreview} color="inherit">
              <LoupeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Excluir imagem">
            <IconButton onClick={handleImageDelete} color="inherit">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      {app.isMobile && (
        <>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            <MenuItem onClick={openImagePreview}>Ver foto</MenuItem>
            <MenuItem onClick={handleCopyImageUrl}>Copiar endereço da image</MenuItem>
            <MenuItem onClick={handleImageDelete}>Excluir foto</MenuItem>
          </Menu>
          <IconButton onClick={e => setAnchorEl(e.currentTarget)} color="inherit">
            <MoreIcon />
          </IconButton>
        </>
      )}
    </>
  ) : (
    <>
      <input
        type="file"
        onChange={event => handleUploadFiles(event.target.files)}
        accept="image/*"
        id="new-photo"
        style={{ display: 'none' }}
        multiple
      />
      <label htmlFor="new-photo" className={classes.upload}>
        <CloudUploadIcon />
      </label>
    </>
  );
};

export default ImageSelectorActions;
