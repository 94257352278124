import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, styled, ListItemButton, IconButton } from '@mui/material';
import history from 'services/history';
import { Template } from 'types/template';
import { useTemplates } from 'pages/templates/hooks/useTemplates';
import { MoreHoriz } from '@mui/icons-material';

type TemplateItemModuleProps = {
  template: Template;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '80%',
    alignItems: 'center',
  },
});

const CustomIconButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: 0,
});

const TemplateItemModule: React.FC<TemplateItemModuleProps> = ({ template, setAnchorEl }) => {
  const { setSelectedTemplate } = useTemplates();

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedTemplate(template);
    setAnchorEl(e.currentTarget);
  }

  return (
    <CustomListItemButton onClick={() => history.push(`/site/template/${template.id}`)}>
      <Typography variant="caption" color="primary">
        Banner {template.id}
      </Typography>
      <Typography variant="h6">{template.description}</Typography>
      <Typography variant="h6">Ativo: {template.formattedActive}</Typography>
      <Typography variant="h6">{template.formattedCreatedAt}</Typography>
      <CustomIconButton size="small" onClick={e => handleMoreClick(e)}>
        <MoreHoriz />
      </CustomIconButton>
    </CustomListItemButton>
  );
};

export default TemplateItemModule;
