import { TableTemplate } from 'types/tableTemplate';

export const subgroupTableTemplate: TableTemplate[] = [
  {
    id: 'formattedName',
    description: 'Subgrupo',
    originalId: 'subgrupo',
    width: 300,
  },
  {
    id: 'formattedCoust',
    description: 'Custo',
    originalId: 'custo',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'qtd',
    description: 'QTD',
    originalId: 'qtd',
    width: 70,
    dataType: 'number',
  },
  {
    id: 'formattedMc',
    description: 'MC',
    originalId: 'mc',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'formattedUnitary',
    description: 'Unitário',
    originalId: 'unitario',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'formattedTotal',
    description: 'Total',
    originalId: 'total',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'formattedBilling',
    description: 'Faturamento',
    originalId: 'billing',
    width: 120,
    dataType: 'number',
  },
];
