import React, { FormEvent } from 'react';
import { MdSearch } from 'react-icons/md';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  styled,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import SelectBranch from 'components/select-branches/SelectBranch';
import { AbcProductFilterParams } from 'pages/reports/commercial/abc/product/AbcProduct';

const Filter = styled('div')(({ theme }) => ({
  gap: 10,
  flex: 1,
  display: 'grid',
  alignItems: 'baseline',
  gridTemplateColumns: '0.4fr 0.4fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 2fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
  '& > .search-params': {
    gap: 10,
    display: 'flex',
    '& > div': {
      width: '100%',
    },
  },
}));

const CustomSelect = styled(Select)({
  height: '100%',
});

interface AnalysisProductFilterBoxProps {
  filter: AbcProductFilterParams;
  handleChangeFilter(index: keyof AbcProductFilterParams, value: any): void;
  handleOpenDialog(): void;
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
  disabled: boolean;
}

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const AnalysisProductFilterBox: React.FC<AnalysisProductFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  searchText,
  handleSearchInputChange,
  handleSearchSubmit,
  handleOpenDialog,
}) => {
  return (
    <FilterBox>
      <Filter>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />

        <FormControl>
          <InputLabel>Tipo de venda</InputLabel>
          <CustomSelect
            fullWidth
            label="tipo de venda"
            value={filter.typeSale}
            onChange={e => handleChangeFilter('typeSale', e.target.value)}
          >
            <MenuItem value="T">Todas</MenuItem>
            <MenuItem value="C">Coligadas</MenuItem>
            <MenuItem value="V">Vendas</MenuItem>
          </CustomSelect>
        </FormControl>

        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate}
            onChange={date => handleChangeFilter('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.finalDate}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </div>

        <form onSubmit={handleSearchSubmit}>
          <TextField
            label="Produto"
            placeholder="Produto"
            autoFocus
            value={searchText}
            onChange={e => handleSearchInputChange(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <MdSearch color="#888" size={20} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </Filter>

      <Tooltip title="Outros filtros">
        <CustomIconButton onClick={handleOpenDialog} color="inherit">
          <FilterList />
        </CustomIconButton>
      </Tooltip>
    </FilterBox>
  );
};

export default AnalysisProductFilterBox;
