import { TableTemplate } from 'types/tableTemplate';

export const PaymentTableTemplate: TableTemplate[] = [
  {
    id: 'formattedPaid',
    description: 'Pago',
    originalId: 'formattedPaid',
    width: 60,
  },
  {
    id: 'forma',
    description: 'Forma PAG',
    originalId: 'forma',
    width: 150,
  },
  {
    id: 'parcelas',
    description: 'Parcelas',
    originalId: 'parcelas',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'Bandeira',
    description: 'Bandeira',
    originalId: 'Bandeira',
    width: 60,
  },
  {
    id: 'formattedTotal',
    description: 'Total',
    originalId: 'formattedTotal',
    dataType: 'number',
    width: 150,
  },
];
