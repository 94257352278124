import { TableTemplate } from 'types/tableTemplate';

export const stockItemsTableTemplate: TableTemplate[] = [
  {
    id: 'id_produto',
    description: 'ID',
    originalId: 'id_produto',
    width: 60,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 260,
  },
  {
    id: 'comprador',
    description: 'Comprador',
    originalId: 'comprador',
    width: 260,
  },
  {
    id: 'estoque',
    description: 'Estoque',
    originalId: 'estoque',
    width: 90,
    dataType: 'quantity',
  },
  {
    id: 'pedidos',
    description: 'Pedidos',
    originalId: 'pedidos',
    width: 90,
    dataType: 'quantity',
  },
  {
    id: 'PrevChegada',
    description: 'Prev. Chegada',
    originalId: 'formattedExpectedArrivalDate',
    width: 145,
    dataType: 'quantity',
  },
  {
    id: 'Data1',
    description: 'Data1',
    originalId: 'Data1',
    width: 180,
    dataType: 'quantity',
  },
  {
    id: 'Data2',
    description: 'Data2',
    originalId: 'Data2',
    width: 180,
    dataType: 'quantity',
  },
];
