import React, { useRef, useEffect } from 'react';
import { TextField, Switch, Typography, styled } from '@mui/material';
import { ProfileValidation } from './Profile';
import { UserProfile } from 'types/userProfile';
import { AccessRule } from 'types/accessRule';
import ProfileFormAccessRules from './ProfileFormAccessRuleItem';

type ProfileFormProps = {
  handleUserProfileChange(value: string): void;
  handleAccessRulesChange(id: number): void;
  profile: UserProfile | null;
  validation: ProfileValidation;
  accessRules: AccessRule[];
};

const FormControl = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: '1px solid #ccc',
  padding: '20px 0',
});

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Rules = styled('div')({
  marginTop: 20,
});

const ProfileForm: React.FC<ProfileFormProps> = ({
  handleAccessRulesChange,
  handleUserProfileChange,
  profile,
  validation,
  accessRules,
}) => {
  const nameInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (validation.name) nameInputRef.current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <>
      <TextField
        inputRef={nameInputRef}
        error={!!validation.name}
        helperText={validation.name}
        label="Nome"
        placeholder="Digite o nome do perfil"
        margin="normal"
        value={profile ? profile.name : ''}
        onChange={e => handleUserProfileChange(e.target.value)}
        fullWidth
        autoFocus
      />
      <Rules>
        {accessRules.map(
          rule =>
            !rule.access_rule_id && (
              <FormControl key={rule.id}>
                <Header>
                  <Typography textTransform="uppercase">{rule.title}</Typography>
                  <Switch color="primary" onChange={() => handleAccessRulesChange(rule.id)} checked={rule.enabled} />
                </Header>
                <Typography variant="body2" color="textSecondary">
                  {rule.description}
                </Typography>
                {rule.access_rules.length > 0 && (
                  <ProfileFormAccessRules
                    accessRules={rule.access_rules}
                    handleAccessRulesChange={handleAccessRulesChange}
                    accessRuleId={rule.id}
                  />
                )}
              </FormControl>
            ),
        )}
      </Rules>
    </>
  );
};

export default ProfileForm;
