import { ProductEdit } from 'types/product';

export const CHANGE = 'CHANGE';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PRICES = 'SET_PRICES';

interface ProductChangeAction {
  type: typeof CHANGE;
  index: keyof ProductEdit;
  value: any;
}

interface SetProductAction {
  type: typeof SET_PRODUCT;
  product: ProductEdit;
}

interface SetPricesAction {
  type: typeof SET_PRICES;
  prices: any;
}

export type ProductActionsType = ProductChangeAction | SetProductAction | SetPricesAction;
