import React, { MouseEvent } from 'react';
import { Grid, TextField, Typography, Button, darken, Theme } from '@mui/material';
import AccountImage from './AccountImage';
import { useApp } from 'hooks/app';
import { LocalUser, UserValidation } from './Account';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    margin: '10px 0 30px',
  },
  actions: {
    marginTop: 30,
    '& button': {
      marginTop: 20,
    },
    '& a': {
      fontWeight: 'normal',
    },
  },
  disconnectButton: {
    backgroundColor: theme.palette.error.main,
    width: '100%',
    color: 'white',
    height: 45,
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.1),
    },
  },
}));

type AccountFormProps = {
  localUser: LocalUser;
  handleChange(index: string, value: any): void;
  handleResetEmail(): void;
  validation: UserValidation;
};

const AccountForm: React.FC<AccountFormProps> = ({ localUser, handleChange, handleResetEmail, validation }) => {
  const classes = useStyles();
  const app = useApp();

  function handleDisconnectClick() {
    app.handleLogout();
  }

  function handleResetEmailClick(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    handleResetEmail();
  }

  return (
    <>
      <AccountImage handleChange={handleChange} localUser={localUser} />
      <Grid item xs={12} xl={4} lg={4} md={6}>
        <TextField
          error={!!validation.username}
          helperText={validation.username}
          label="Nome de usuário"
          value={localUser.username}
          disabled
          fullWidth
          margin="normal"
        />
        <TextField
          error={!!validation.email}
          helperText={validation.email}
          label="E-mail"
          value={localUser.email}
          disabled
          fullWidth
          margin="normal"
        />
        <TextField
          error={!!validation.name}
          helperText={validation.name}
          label="Nome"
          value={localUser.name}
          fullWidth
          margin="normal"
          autoFocus
          onChange={e => handleChange('name', e.target.value)}
        />
        <div className={classes.actions}>
          <a href="/" onClick={handleResetEmailClick}>
            Alterar minha senha
          </a>
          <Typography variant="body2" color="textSecondary">
            Será enviado uma mensagem de e-mail com opção para alterar a senha
          </Typography>
          <Button className={classes.disconnectButton} onClick={handleDisconnectClick}>
            Desconectar
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default AccountForm;
