import { Project, ProjectAction, ProjectProduct } from 'types/project';
import { ProjectActions } from './types';
import { useReducer } from 'react';
import { moneyFormat } from 'helpers/numberFormat';
import { uuidv4 } from 'helpers/uuidv4';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const initialState: Project = {
  id: 0,
  name: '',
  customer_name: '',
  address: {
    postal_code: '',
    street: '',
    street_number: '',
    neighborhood: '',
    complement: '',
    state: '',
    city: '',
  },
  created_at: new Date().toISOString(),
  current_status: 'accepted',
  description: '',
  observation: '',
  document_number: '',
  updated_at: '',
  value: 0,
  formattedCreatedAt: '',
  formattedCurrentStatus: '',
  formattedUpdatedAt: '',
  formattedValue: '',
  products: [],
  documents: [],
  contacts: [],
  status: [],
  costs: [],
  actions: [],
  is_registered: false,
  is_approved: false,
  ro_number: null,
  formattedIsApproved: 'Não',
  formattedIsRegistered: 'Não',
};

export const projectStatusMapping = {
  accepted: 'Aceito',
  paused: 'Pausado',
  material_arrival: 'Previsão de chegada de material',
  waiting_quotation: 'Aguardando cotação',
  waiting_visit: 'Aguardando visita',
  quotation_sent: 'Cotação enviada',
  cancelled: 'Cancelado',
};

function projectReducer(state = initialState, action: ProjectActions): Project {
  switch (action.type) {
    case 'SET_PROJECT': {
      return {
        ...state,
        ...action.project,
        formattedCurrentStatus: projectStatusMapping[action.project.current_status],
        products: action.project.products.map(product => ({
          ...product,
          formattedProductValue: moneyFormat(product.product_value),
        })),
        costs: action.project.costs.map(cost => ({
          ...cost,
          formattedReferenceDate: format(parseISO(cost.reference_date as any), 'P', { locale: ptBR }),
          formattedValue: moneyFormat(cost.value),
        })),
        actions: action.project.actions.map(cost => ({
          ...cost,
          formattedReferenceDate: format(parseISO(cost.reference_date as any), 'P', { locale: ptBR }),
        })),
      };
    }

    case 'CHANGE_PROJECT': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'ADD_PROJECT_PRODUCT': {
      const product: ProjectProduct = {
        id: uuidv4(),
        formattedProductValue: moneyFormat(action.product.value),
        product_id: action.product.id,
        product_name: action.product.name,
        product_value: parseFloat(action.product.value),
        quantity: parseInt(action.product.quantity),
      };

      return {
        ...state,
        products: [...state.products, product],
      };
    }

    case 'ADD_PROJECT_CONTACT': {
      return {
        ...state,
        contacts: [...state.contacts, { ...action.contact, id: uuidv4() }],
      };
    }

    case 'CHANGE_PROJECT_ADDRESS': {
      return {
        ...state,
        address: {
          ...state.address,
          [action.index]: action.value,
        },
      };
    }

    case 'ADD_PROJECT_DOCUMENT': {
      return {
        ...state,
        documents: [...state.documents, { ...action.document, document_id: action.document.id }],
      };
    }

    case 'REMOVE_PROJECT_DOCUMENT': {
      return {
        ...state,
        documents: state.documents.filter(document => document.id !== action.documentId),
      };
    }

    case 'REMOVE_PROJECT_PRODUCT': {
      return {
        ...state,
        products: state.products.filter(product => product.id !== action.id),
      };
    }

    case 'REMOVE_PROJECT_CONTACT': {
      return {
        ...state,
        contacts: state.contacts.filter(contact => contact.id !== action.id),
      };
    }

    case 'ADD_PROJECT_COST': {
      const cost = {
        ...action.cost,
        formattedReferenceDate: format(action.cost.referenceDate, 'P', { locale: ptBR }),
        formattedValue: moneyFormat(action.cost.value),
        reference_date: action.cost.referenceDate,
        id: uuidv4(),
      };

      return {
        ...state,
        costs: [...state.costs, cost],
      };
    }

    case 'REMOVE_PROJECT_COST': {
      return {
        ...state,
        costs: state.costs.filter(cost => cost.id !== action.id),
      };
    }

    case 'ADD_PROJECT_ACTION': {
      const _action: ProjectAction = {
        action: action.action.action,
        observation: action.action.observation,
        reference_date: action.action.referenceDate,
        formattedReferenceDate: format(action.action.referenceDate as Date, 'P', { locale: ptBR }),
        full_name: action.action.fullname,
        user_ids: action.action.userIds,
        id: uuidv4(),
      };

      return {
        ...state,
        actions: [...state.actions, _action],
      };
    }

    case 'REMOVE_PROJECT_ACTION': {
      return {
        ...state,
        actions: state.actions.filter(item => item.id !== action.id),
      };
    }

    default: {
      return state;
    }
  }
}

export function useProjectReducer() {
  return useReducer(projectReducer, initialState);
}
