import React from 'react';
import { IconButton } from '@mui/material';
import { Done } from '@mui/icons-material';
import { useDialog } from 'components/dialogs/Dialog';

type AgentsRejectConfirmActionsProps = {
  handleConfirm(handleClose: () => void): void;
};

const AgentsRejectConfirmActions: React.FC<AgentsRejectConfirmActionsProps> = ({ handleConfirm }) => {
  const { handleClose } = useDialog();

  function handleClick() {
    handleConfirm(handleClose);
  }

  return (
    <IconButton color="inherit" onClick={handleClick}>
      <Done />
    </IconButton>
  );
};

export default AgentsRejectConfirmActions;
