import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { ExpensesFilterProps } from '../Expenses';
import { Expense } from 'types/expense';

interface ExpensesContextValue {
  filter: ExpensesFilterProps;
  handleChangeFilter(index: keyof ExpensesFilterProps, value: any): void;
  setIsReportOpen: Dispatch<SetStateAction<boolean>>;
  handleSearch(): void;
  loading: boolean;
  expenses: Expense[];
  setSelectedExpense: Dispatch<SetStateAction<Expense | null>>;
  setIsNewExpenseOpen: Dispatch<SetStateAction<boolean>>;
}

const ExpensesContext = createContext<ExpensesContextValue>({} as ExpensesContextValue);
export const ExpensesConsumer = ExpensesContext.Consumer;
export const ExpensesProvider = ExpensesContext.Provider;

export function useExpenses() {
  return useContext(ExpensesContext);
}
