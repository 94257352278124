import { useApp } from 'hooks/app';
import { Dispatch, SetStateAction, useState } from 'react';
import { CfopH2i } from 'types/cfop';
import { PurchaseOrder } from 'types/purchaseOrder';
import * as yup from 'yup';

export type ThirdPurchaseOrderValidation = {
  cfop?: string;
};

type UseThirdPurchaseOrderValidation = [
  ThirdPurchaseOrderValidation,
  Dispatch<SetStateAction<ThirdPurchaseOrderValidation>>,
  (purchaseOrder: PurchaseOrder) => Promise<void>,
];

export function useThirdPurchaseOrderValidation(): UseThirdPurchaseOrderValidation {
  const { h2iApi } = useApp();
  async function handleValidation(purchaseOrder: PurchaseOrder) {
    const schema = yup.object().shape({
      cfop: yup
        .string()
        .required('O CFOP é obrigatório')
        .test('cfopValidation', 'CFOP inválido', async value => {
          if (!value) return false;

          if (value.length !== 4) return false;

          if (!h2iApi) return false;

          const response = await h2iApi.get('/api/getcfop');
          const _response: CfopH2i[] = response.data.cfop;

          if (!_response) return false;

          const cfops = _response.map(item => item.cfop.replace(',', ''));

          if (cfops.includes(value)) {
            return true;
          } else {
            return false;
          }
        }),
    });

    try {
      await schema.validate(purchaseOrder);
    } catch (err) {
      const error = err as yup.ValidationError;

      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(error.message);
    }
  }

  const [validation, setValidation] = useState<ThirdPurchaseOrderValidation>({});
  return [validation, setValidation, handleValidation];
}
