import React, { useState, useEffect } from 'react';
import Dialog from 'components/dialogs/Dialog';
import { Remittance } from 'types/remittance';
import { api, getCancelTokenSource } from 'services/api';
import InsideLoading from 'components/loading/InsideLoading';
import RemittanceList from './RemittanceList';
import { format, parseISO, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { RemittancesProvider } from './hooks/useRemittances';
import NoData from 'components/no-data/NoData';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import RemittanceDeleteConfirmation from './RemittanceDeleteConfirmation';
import InsideSaving from 'components/loading/InsideSaving';
import { useMessaging } from 'hooks/messaging';
import { MenuItem, TextField, styled } from '@mui/material';
import { BillSetting } from 'types/billSetting';

const Header = styled('div')({
  display: 'flex',
  gap: 15,
  marginTop: 10,
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 15,
  flex: 1,
});

interface RemittancesProps {
  onExited(): void;
}

type RemittanceQueryParams = {
  initial_date: Date;
  final_date: Date;
  bank_billet_setting_id: number;
};

const remittanceParamsInitialValue: RemittanceQueryParams = {
  initial_date: subDays(new Date(), 7),
  final_date: new Date(),
  bank_billet_setting_id: 1,
};

const Remittances: React.FC<RemittancesProps> = ({ onExited }) => {
  const [remittances, setRemittances] = useState<Remittance[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [params, setParams] = useState<RemittanceQueryParams>(remittanceParamsInitialValue);
  const messaging = useMessaging();
  const [settings, setSettings] = useState<BillSetting[]>([]);

  useEffect(() => {
    api
      .get<BillSetting[]>('/bank-billet-settings')
      .then(response => setSettings(response.data))
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    let request = true;
    const cancelTokenSource = getCancelTokenSource();

    setLoading(true);

    api
      .get<Remittance[]>('/bankBilletRemittances', { params, cancelToken: cancelTokenSource.token })
      .then(response => {
        if (!request) {
          return;
        }

        setRemittances(
          response.data.map(remittance => {
            remittance.formattedCreatedAt = format(parseISO(remittance.created_at), 'Pp', { locale: ptBR });
            return remittance;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => {
        if (!request) {
          return;
        }

        setLoading(false);
        request = false;
      });

    return () => {
      if (request) {
        cancelTokenSource.cancel();
        request = false;
      }
    };
  }, [params]);

  function handleDelete(remittanceId: number) {
    setSelectedId(remittanceId);
  }

  async function handleSubmitDelete() {
    setSaving(true);

    try {
      await api.delete(`/bankBilletRemittances/${selectedId}`);
      setRemittances(remittances => remittances.filter(remittance => remittance.id !== selectedId));
      setSelectedId(null);
    } catch (err) {
      console.error(err);
      messaging.handleOpen('Não foi possível excluir a remessa');
    } finally {
      setSaving(false);
    }
  }

  function handleChangeFilter(index: keyof RemittanceQueryParams, value: Date | null | number) {
    setParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <Dialog title="Remessas" onExited={onExited} maxWidth="sm">
      {saving && <InsideSaving />}

      <RemittancesProvider value={{ handleDelete, saving, selectedId, setSelectedId, setSaving }}>
        {selectedId && (
          <RemittanceDeleteConfirmation
            onExited={() => setSelectedId(null)}
            handleSubmit={handleSubmitDelete}
            remittanceId={selectedId}
          />
        )}

        <Container>
          <Header>
            <KeyboardDatePicker
              label="Data inicial"
              value={params.initial_date}
              onChange={date => handleChangeFilter('initial_date', date)}
              textFieldProps={{ margin: 'none', fullWidth: true }}
            />
            <KeyboardDatePicker
              label="Data Final"
              value={params.final_date}
              onChange={date => handleChangeFilter('final_date', date)}
              textFieldProps={{ margin: 'none', fullWidth: true }}
            />
            <TextField
              select
              fullWidth
              placeholder="Selecionar layout..."
              value={params.bank_billet_setting_id}
              onChange={e => handleChangeFilter('bank_billet_setting_id', parseInt(e.target.value))}
              label="Layout"
            >
              {settings.map(bill => (
                <MenuItem key={bill.id} value={bill.id}>
                  {bill.bank_name}
                </MenuItem>
              ))}
            </TextField>
          </Header>
          {loading ? (
            <InsideLoading />
          ) : remittances.length === 0 ? (
            <NoData message="Nenhuma remessa encontrada" />
          ) : (
            <RemittanceList remittances={remittances} />
          )}
        </Container>
      </RemittancesProvider>
    </Dialog>
  );
};

export default Remittances;
