import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { RelatedProduct } from '../types/relatedProduct';

interface RelatedProductsContextValue {
  selectedRelatedProduct: RelatedProduct | null;
  setSelectedRelatedProduct: Dispatch<SetStateAction<RelatedProduct | null>>;
  handleDelete(): void;
}

const RelatedProductContext = createContext<RelatedProductsContextValue>({} as RelatedProductsContextValue);
export const RelatedProductConsumer = RelatedProductContext.Consumer;
export const RelatedProductProvider = RelatedProductContext.Provider;

export function useRelatedProducts(): RelatedProductsContextValue {
  return useContext(RelatedProductContext);
}
