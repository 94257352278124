import { Typography, styled } from '@mui/material';
import React from 'react';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { Favorite, ThumbDown, ThumbUp } from '@mui/icons-material';
import { useSellout } from './hooks/useSellout';

const Container = styled('div')<{ showTotals: boolean }>(({ theme, showTotals }) => ({
  display: 'flex',
  transition: 'all 0.2s ease',
  flexWrap: 'wrap',
  columnGap: 25,
  rowGap: 5,
  justifyContent: 'space-between',
  marginBottom: 10,
  [theme.breakpoints.down('sm')]: {
    height: showTotals ? 420 : 0,
    opacity: showTotals ? 1 : 0,
    marginBottom: 0,
  },
  '& .item': {
    minWidth: 225,
    flex: 1,
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
    alignItems: 'center',
    gap: 15,
    backgroundColor: theme.palette.primary.light,
    '& .value': {
      fontSize: 24,
      textAlign: 'end',
      paddingRight: 5,
    },
    '& svg': {
      paddingLeft: 5,
    },
    '& .description': {
      fontWeight: 600,
      padding: '10px 5px',
      gridColumn: '1/3',
      textAlign: 'end',
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const iconStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  color: '#fff',
};

const SelloutTotal: React.FC = () => {
  const { showTotals } = useSellout();
  const formattedResales = numberFormat(323);
  const formattedSellout = moneyFormat(555);
  const formattedActiveResales = numberFormat(300);
  const formattedNewResales = numberFormat(555);
  const formattedAverageTicket = numberFormat(1711);

  return (
    <Container showTotals={showTotals}>
      <div className="item">
        <Favorite style={iconStyle} />
        <Typography className="value" fontSize={12} color="#fff" variant="subtitle2">
          {formattedSellout}
        </Typography>
        <Typography className="description" color="#fff" variant="caption">
          (R$) SellOut
        </Typography>
      </div>

      <div className="item">
        <ThumbDown style={iconStyle} />
        <Typography className="value" fontSize={12} color="#fff" variant="subtitle2">
          {formattedResales}
        </Typography>
        <Typography className="description" color="#fff" variant="caption">
          Quantitade de revendas
        </Typography>
      </div>

      <div className="item">
        <ThumbUp style={iconStyle} />
        <Typography className="value" fontSize={12} color="#fff" variant="subtitle2">
          {formattedActiveResales}
        </Typography>
        <Typography className="description" color="#fff" variant="caption">
          Revendas ativas
        </Typography>
      </div>

      <div className="item">
        <ThumbDown style={iconStyle} />
        <Typography className="value" fontSize={12} color="#fff" variant="subtitle2">
          {formattedNewResales}
        </Typography>
        <Typography className="description" color="#fff" variant="caption">
          Revendas novas
        </Typography>
      </div>

      <div className="item">
        <ThumbDown style={iconStyle} />
        <Typography className="value" fontSize={12} color="#fff" variant="subtitle2">
          {formattedAverageTicket}
        </Typography>
        <Typography className="description" color="#fff" variant="caption">
          Ticket Médio
        </Typography>
      </div>
    </Container>
  );
};

export default SelloutTotal;
