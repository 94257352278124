import React, { useEffect, useRef, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  styled,
} from '@mui/material';
import { PromotionalBannerValidation } from './validation/promotionalBannerValidation';
import ImageSelector from 'components/image-selector/ImageSelector';
import ImagePreview from 'components/image-preview/ImagePreview';
import ImageUpload from 'components/image-upload/ImageUpload';
import { PromotionalBanner } from 'types/promotionalBanners';

const PositionContainer = styled('div')({
  margin: '30px 0 0',
});

const Container = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '400px 1fr',
  marginBottom: 200,
  gap: 40,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: 10,
  },
}));

const CustomFormControlLabel = styled(FormControlLabel)({
  marginTop: 20,
});

const imageContainer = {
  marginTop: 20,
};

type PromotionalBannerFormProps = {
  banner: PromotionalBanner;
  handleChange(index: keyof PromotionalBanner, value: any): void;
  validation: PromotionalBannerValidation;
};

const PromotionalBannerForm: React.FC<PromotionalBannerFormProps> = ({ banner, handleChange, validation }) => {
  const [imageSelector, setImageSelector] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<null | string>('');

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    title: useRef<HTMLInputElement>(null),
    subtitle: useRef<HTMLInputElement>(null),
    link: useRef<HTMLInputElement>(null),
    order: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      {!!selectedImageSrc && (
        <ImagePreview
          src={selectedImageSrc}
          description="Imagem da mensagem de e-mail"
          onExited={() => setSelectedImageSrc('')}
        />
      )}
      {imageSelector && (
        <ImageSelector
          handleSetImageId={image => handleChange('image', image)}
          onExited={() => setImageSelector(false)}
        />
      )}
      <Container>
        <div>
          <ImageUpload
            validationError={validation.image}
            label="Imagem desktop 600 x 200"
            handleSetImage={image => handleChange('image', image)}
            handleRemoveImage={() => handleChange('image', null)}
            image={banner.image}
            containerClassname={imageContainer}
          />

          <ImageUpload
            validationError={validation.image}
            label="Imagem mobile 300 x 200"
            handleSetImage={image => handleChange('mobile_image', image)}
            handleRemoveImage={() => handleChange('mobile_image', null)}
            image={banner.mobile_image}
            containerClassname={imageContainer}
          />
        </div>
        <Grid item xs={12} xl={4} lg={4} md={6}>
          <TextField
            inputRef={inputs.name}
            error={!!validation.name}
            helperText={validation.name}
            label="Nome"
            placeholder="Digite um nome para o banner"
            margin="normal"
            fullWidth
            value={banner.name}
            onChange={e => handleChange('name', e.target.value)}
            autoFocus
          />

          <TextField
            inputRef={inputs.link}
            error={!!validation.link}
            helperText={validation.link}
            label="Link"
            placeholder="Informe o link"
            margin="normal"
            fullWidth
            value={banner.link}
            onChange={e => handleChange('link', e.target.value)}
          />

          <PositionContainer>
            <FormControl component="fieldset">
              <FormLabel component="legend">Posição na pagina inicial</FormLabel>
              <RadioGroup
                name="gender1"
                value={banner.position}
                onChange={e => handleChange('position', e.target.value)}
              >
                <FormControlLabel value="top" control={<Radio color="primary" />} label="Parte superior" />
                <FormControlLabel value="bottom" control={<Radio color="primary" />} label="Parte inferior" />
              </RadioGroup>
            </FormControl>
          </PositionContainer>

          <CustomFormControlLabel
            label="Ativo"
            control={
              <Switch
                color="primary"
                onChange={event => handleChange('active', event.target.checked)}
                checked={banner.active}
              />
            }
          />

          <button type="submit" style={{ display: 'none' }} />
        </Grid>
      </Container>
    </>
  );
};

export default PromotionalBannerForm;
