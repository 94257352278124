import { Dispatch, SetStateAction, useState } from 'react';
import { AppropriationDate } from 'types/appropriationDate';
import * as yup from 'yup';

export type AppropriationDateValidation = {
  date?: string;
};

type UseAppropriationDateValidation = [
  AppropriationDateValidation,
  Dispatch<SetStateAction<AppropriationDateValidation>>,
  (date: AppropriationDate) => Promise<void>,
];

export function useAppropriationDateValidation(): UseAppropriationDateValidation {
  async function handleValidation(date: AppropriationDate) {
    const schema = yup.object().shape({
      date: yup.string().required('A data é obrigatória'),
    });

    try {
      await schema.validate(date);
    } catch (err) {
      const error = err as yup.ValidationError;

      if (error.path) {
        setValidation({
          [error.path]: error.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<AppropriationDateValidation>({});
  return [validation, setValidation, handleValidation];
}
