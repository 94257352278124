import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { useApp } from 'hooks/app';
import { ptBR } from 'date-fns/locale';
import useTableOrder from 'hooks/tableOrder';
import Dialog from 'components/dialogs/Dialog';
import PaginationProvider from 'hooks/pagination';
import { moneyFormat } from 'helpers/numberFormat';
import Pagination from 'components/pagination/Pagination';
import InsideSaving from 'components/loading/InsideSaving';
import { useAbcProduct } from '../hooks/useAbcProduct';
import { useAbcProducts } from '../hooks/useAbcProducts';
import AbcProductListTable from '../list/table/AbcProductListTable';
import { AbcProduct } from 'types/abcProduct';

interface SelectedSegmentProps {
  onExited(): void;
}

const SelectedSegment: React.FC<SelectedSegmentProps> = ({ onExited }) => {
  const { h2iApi } = useApp();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const { searchValue, filter } = useAbcProduct();
  const { selectedSegment, selectedSubgroup } = useAbcProducts();
  const [products, setProducts] = useState<AbcProduct[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<AbcProduct[]>([]);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  const handleSearch = useCallback(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    h2iApi
      .get('/api/relcurvaabcprodutos', {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: selectedSubgroup?.branchId,
          id_subgrupo: selectedSubgroup?.cod,
          filtro: searchValue,
          tipo_venda: filter.typeSale,
          id_categoria: filter.categoryIds.join(','),
          id_subcategoria: filter.subCategoryIds.join(','),
          pedido_por: filter.madeBy,
          id_segmento: selectedSegment?.cod,
          id_unidade: filter.unitIds.join(','),
        },
      })
      .then(response => {
        const _products = response.data[0].produtos.map(item => {
          item.formattedTotal = moneyFormat(item.total);
          item.formattedCoust = moneyFormat(item.custo);
          item.formattedMc = moneyFormat(item.mc);
          item.formattedSpiff = moneyFormat(item.spiff);
          item.formattedUnitary = moneyFormat(item.unitario);

          return item;
        });

        setProducts(_products);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [
    h2iApi,
    filter.initialDate,
    filter.finalDate,
    filter.typeSale,
    filter.categoryIds,
    filter.subCategoryIds,
    filter.madeBy,
    filter.unitIds,
    selectedSubgroup,
    searchValue,
    selectedSegment,
  ]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  function handleSort(index: string) {
    const filtered = sort(index, filteredProducts);
    setFilteredProducts(filtered);
  }

  return (
    <Dialog onExited={onExited} maxWidth="lg" title={selectedSubgroup?.subgrupo}>
      {loading ? (
        <InsideSaving />
      ) : (
        <div style={{ display: 'grid', gap: 10 }}>
          <PaginationProvider>
            <AbcProductListTable
              isSubgroup={false}
              products={filteredProducts}
              handleSort={handleSort}
              orderedIndex={orderedIndex}
            />
            <Pagination count={filteredProducts.length} />
          </PaginationProvider>
        </div>
      )}
    </Dialog>
  );
};

export default SelectedSegment;
