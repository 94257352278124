import React from 'react';
import { Checkbox, FormControlLabel, Typography, styled } from '@mui/material';
import BankStatementItemTable from './BankStatementItemTable';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { Extract } from 'types/extract';
import { extractTableTemplate } from '../../extractTableTemplate';
import TableContainer from 'components/table/TableContainer';
import { useExtracts } from '../../hooks/useExtracts';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
  '&.headerItemNumeric': {
    justifyContent: 'flex-end',
  },
});

interface BankStatementListTableProps {
  extracts: Extract[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const BankStatementListTable: React.FC<BankStatementListTableProps> = ({ extracts, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();
  const { handleSelectAll, selectAll } = useExtracts();

  return (
    <TableContainer tableTemplate={extractTableTemplate}>
      <TableContent useMinHeight={false}>
        <TableHeader>
          {extractTableTemplate.map(item =>
            item.id === 'select' ? (
              <HeaderItem style={{ justifyContent: 'center' }} key={item.id}>
                <FormControlLabel
                  style={{ marginRight: 0 }}
                  label=""
                  key={item.id}
                  control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
                />
              </HeaderItem>
            ) : (
              <HeaderItem
                className={item.dataType === 'number' ? 'headerItemNumeric' : ''}
                key={item.id}
                onClick={() => handleSort(item.originalId)}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>

                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </HeaderItem>
            ),
          )}
        </TableHeader>
        <TableBody useMaxHeight={true} maxHeight={520}>
          {extracts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((account, index) => (
            <TableRow style={{ minHeight: 20 }} activeNthChild key={index}>
              <BankStatementItemTable account={account} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default BankStatementListTable;
