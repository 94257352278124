import React from 'react';
import { ImagesResponseDataInner } from 'openai';
import { Skeleton, Typography, styled } from '@mui/material';

interface AiArtOutputMobileProps {
  images: ImagesResponseDataInner[];
}

const Container = styled('div')(props => ({
  gap: 5,
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: 50,
  alignItems: 'center',
  borderRadius: '0.375rem',
  justifyContent: 'space-evenly',
  border: `2px solid ${props.theme.palette.primary.main}`,
}));

const ContainerSkeleton = styled('div')({
  gap: 5,
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
});

const BoxImage = styled('a')(({ theme }) => ({
  boxShadow: `1px 1px 6px 1px ${theme.palette.primary.main}`,
  transition: 'all ease 0.15s',
  '&:hover': {
    transform: 'scale(0.95)',
  },
  '& > img': {
    width: 280,
    height: 280,
  },
}));

const AiArtOutputMobile: React.FC<AiArtOutputMobileProps> = ({ images }) => {
  return (
    <>
      {images.length > 0 ? (
        <Container>
          {images.map((image, index) => (
            <BoxImage href={image.url} target="_blank" key={index}>
              <img src={image.url} />
            </BoxImage>
          ))}
        </Container>
      ) : (
        <>
          <Typography variant="caption" align="center">
            carregando suas fotos
          </Typography>
          <ContainerSkeleton>
            <Skeleton variant="rectangular" width={280} height={280} />
            <Skeleton variant="rectangular" width={280} height={280} />
            <Skeleton variant="rectangular" width={280} height={280} />
            <Skeleton variant="rectangular" width={280} height={280} />
          </ContainerSkeleton>
        </>
      )}
    </>
  );
};

export default AiArtOutputMobile;
