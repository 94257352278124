import { Collapse } from '@mui/material';
import { AttachMoney, CardMembership, Dashboard as DashboardIcon } from '@mui/icons-material';
import { usePermissionRules } from 'hooks/permissionRules';
import React from 'react';
import { useSidebar } from '../hook/useSidebar';
import SidebarItem from '../SidebarItem';
import SidebarItemExpendable from '../SidebarItemExpendable';

const Dashboard: React.FC = () => {
  const { checkPermission } = usePermissionRules();
  const { collapsible, handleCollapseClick } = useSidebar();

  return (
    <>
      <SidebarItemExpendable
        onClick={() => handleCollapseClick('dashboard')}
        icon={<DashboardIcon />}
        text="Dashboard"
        opened={collapsible.dashboard}
      />
      <Collapse in={collapsible.dashboard}>
        {checkPermission('dashboard.commercial') && (
          <SidebarItem to="/dashboard/commercial" icon={<CardMembership />} text="Comercial" nested={5} />
        )}
        <SidebarItem to="/dashboard/financial" icon={<AttachMoney />} text="Financeiro" nested={5} />
      </Collapse>
    </>
  );
};

export default Dashboard;
