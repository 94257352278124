import React from 'react';
import { usePurchaseOrder } from '../../hook/usePurchaseOrder';
import { TextField, styled } from '@mui/material';
import CpfCnpjInput from 'components/masked-input/CpfCnpjInput';
import ResellerAutoComplete from 'pages/projects/auto-complete/ResellerAutoComplete';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';

const Row = styled('div')({
  display: 'grid',
  gap: 15,
  gridTemplateColumns: '1fr',
  '& .MuiFormControl-root': {
    marginTop: '0!important',
    marginBottom: '0!important',
  },
});

const PurchaseOrderSecondStep: React.FC = () => {
  const { order, secondValidation, handleChange, inputs, handleCustomerSelect, reseller } = usePurchaseOrder();

  return (
    <Row>
      <TextField
        inputRef={inputs.cnpj}
        error={!!secondValidation.cnpj}
        helperText={secondValidation.cnpj}
        autoFocus
        label="Documento"
        placeholder="Documento"
        inputMode="numeric"
        InputProps={{
          inputComponent: CpfCnpjInput as any,
        }}
        value={order.cnpj}
        onChange={e => handleChange('cnpj', e.target.value)}
      />

      <ResellerAutoComplete
        branchId={order.id_filial}
        onSelect={handleCustomerSelect}
        label="Selecione um cliente"
        error={!!secondValidation.cliente}
        helperText={secondValidation.cliente || ''}
        placeholder="Digite o nome do cliente para pesquisar"
        customer={reseller}
        disabled={order.id_filial === 0}
      />

      <KeyboardDatePicker
        disabled={order.id_filial === 0}
        inputRef={inputs.date_forecast}
        textFieldProps={{
          margin: 'none',
          helperText: secondValidation.date_forecast,
          error: !!secondValidation.date_forecast,
        }}
        label="Previsão"
        value={order.date_forecast}
        onChange={date => handleChange('date_forecast', date)}
      />

      <KeyboardDatePicker
        disabled={order.id_filial === 0}
        inputRef={inputs.date_new_forecast}
        textFieldProps={{
          margin: 'none',
          helperText: secondValidation.date_new_forecast,
          error: !!secondValidation.date_new_forecast,
        }}
        label="Nova Previsão"
        value={order.date_new_forecast}
        onChange={date => handleChange('date_new_forecast', date)}
      />

      <KeyboardDatePicker
        disabled={order.id_filial === 0}
        label="Data Emissão"
        value={order.date_issue}
        onChange={date => handleChange('date_issue', date)}
      />
    </Row>
  );
};

export default PurchaseOrderSecondStep;
