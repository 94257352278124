import { TableTemplate } from 'types/tableTemplate';

export const branchTableTemplate: TableTemplate[] = [
  {
    id: 'more',
    description: '',
    originalId: 'more',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'cidade',
    description: 'Cidade',
    originalId: 'cidade',
    width: 250,
  },
  {
    id: 'nome',
    description: 'Nome',
    originalId: 'nome',
    width: 250,
  },
];
