import React, { useEffect, useState } from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { OverduePayment } from 'types/paymentFlow';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import NoData from 'components/no-data/NoData';
import history from 'services/history';

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  background: '#fff',
  overflow: 'auto',
  maxHeight: 550,
});

const Row = styled('li')(({ theme }) => ({
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 6,
  gridTemplateColumns: '120px 220px 90px 90px 90px',
  transition: 'all 0.3s ease',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '90px 140px 90px 90px 90px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '90px 1fr 90px 90px 90px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '90px 220px 90px 90px 90px',
  },
  '& > .numericData': {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  '& > span': {
    gap: 5,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
  rowGap: 10,
});

interface TopOverduePaymentsProps {
  data: OverduePayment[];
}

const TopOverduePayments: React.FC<TopOverduePaymentsProps> = ({ data }) => {
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<OverduePayment[]>([]);

  useEffect(() => {
    setFiltered(data);
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <Container>
      <Typography variant="subtitle2" align="center">
        Pagamentos em alerta
      </Typography>
      {filtered.length > 0 ? (
        <List>
          <Row>
            <span>
              <Typography onClick={() => handleSort('formattedDueDate')} variant="subtitle2">
                Vencimento
              </Typography>
              {orderedIndex.index === 'formattedDueDate' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('fornecedor')} variant="subtitle2">
                Fornecedor
              </Typography>
              {orderedIndex.index === 'fornecedor' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography onClick={() => handleSort('formattedReleaseDate')} align="right" variant="subtitle2">
                Lançamento
              </Typography>
              {orderedIndex.index === 'formattedReleaseDate' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography onClick={() => handleSort('formattedArrivalDate')} align="right" variant="subtitle2">
                Previsão
              </Typography>
              {orderedIndex.index === 'formattedArrivalDate' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography onClick={() => handleSort('valor')} align="right" variant="subtitle2">
                Valor
              </Typography>
              {orderedIndex.index === 'valor' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
          </Row>
          {filtered.map((item, index) => (
            <Row onClick={() => history.push(`/purchasing/order/${item.id_pedido}`)} className="content" key={index}>
              <Tooltip title={item.vencimento}>
                <Typography className="item-name" variant="caption">
                  {item.vencimento}
                </Typography>
              </Tooltip>

              <Tooltip title={item.fornecedor}>
                <Typography className="item-name" variant="caption">
                  {item.fornecedor}
                </Typography>
              </Tooltip>

              <Typography className="numericData" align="right" variant="caption">
                {item.data_lancamento}
              </Typography>

              <Typography className="numericData" align="right" variant="caption">
                {item.previsao_chegada}
              </Typography>

              <Typography className="numericData" align="right" variant="caption">
                {item.formattedValue}
              </Typography>
            </Row>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
    </Container>
  );
};

export default TopOverduePayments;
