import React from 'react';
import SegmentMoreMenu from '../SegmentMoreMenu';
import SegmentItemTable from './SegmentItemTable';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import { AbcProductSegment } from 'types/abcProduct';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography, Grid } from '@mui/material';
import { segmentTableTemplate } from '../../segmentTableTemplate';
import { useAbcCustomers } from '../../../hooks/useAbcCustomers';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  header: {
    backgroundColor: '#eee',
  },
  headerSubgroup: {
    backgroundColor: '#d9f8e5',
  },
  tableRow: {
    '& > div:nth-child(even)': {
      backgroundColor: '#eee',
    },
  },
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
});

type SegmentListTableProps = {
  segments: AbcProductSegment[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  isSubgroup: boolean;
};

const SegmentListTable: React.FC<SegmentListTableProps> = ({ segments, handleSort, orderedIndex, isSubgroup }) => {
  const classes = useStyles();
  const { moreSegment } = useAbcCustomers();
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={segmentTableTemplate}>
          <TableContent>
            <TableHeader className={`${isSubgroup ? classes.header : classes.headerSubgroup}`}>
              {segmentTableTemplate.map(item => (
                <div
                  className={
                    item.dataType === 'number'
                      ? `${classes.headerItem} ${classes.headerItemNumeric}`
                      : classes.headerItem
                  }
                  key={item.id}
                  onClick={() => handleSort(item.originalId)}
                >
                  <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </div>
              ))}
            </TableHeader>
            <TableBody>
              <div className={`${isSubgroup ? classes.tableRow : undefined}`}>
                {segments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(segment => (
                  <>
                    <TableRow style={{ minHeight: 10, padding: 0 }} key={segment.cod}>
                      <SegmentItemTable isSubgroup={isSubgroup} segment={segment} />
                    </TableRow>

                    {moreSegment?.cod === segment?.cod && <SegmentMoreMenu key={segment.cod} segmentId={segment.cod} />}
                  </>
                ))}
              </div>
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default SegmentListTable;
