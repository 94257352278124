import React from 'react';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography, styled } from '@mui/material';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import PurchaseOrderProductItemTable from './PurchaseOrderProductItemTable';
import TableContainer from 'components/table/TableContainer';
import { PurchaseOrderItem } from 'types/purchaseOrderItem';
import { purchaseOrderProductTableTemplate } from '../purchaseOrderProductTableTemplate';
import history from 'services/history';

interface PurchaseOrderProductListTableProps {
  products: PurchaseOrderItem[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

const PurchaseOrderProductListTable: React.FC<PurchaseOrderProductListTableProps> = ({
  handleSort,
  orderedIndex,
  products,
}) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <TableContainer tableTemplate={purchaseOrderProductTableTemplate}>
      <TableContent useMinHeight={false}>
        <TableHeader>
          {purchaseOrderProductTableTemplate.map(item => (
            <HeaderItem
              key={item.id}
              className={item.dataType === 'number' ? 'numericData' : undefined}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody useMaxHeight maxHeight={470}>
          {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, index) => (
            <TableRow onClick={() => history.push(`/purchasing/order/${product.id_pedido}`)} key={index}>
              <PurchaseOrderProductItemTable product={product} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default PurchaseOrderProductListTable;
