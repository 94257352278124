import React from 'react';
import { Typography } from '@mui/material';
import { useOrdersAuthorization } from '../hooks/useOrdersAuthorization';
import { moneyFormat } from 'helpers/numberFormat';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gap: 10,
  },
  list: {
    display: 'flex',
    paddingBottom: 10,
    flexDirection: 'column',
    borderBottom: '1px solid #dedede',
  },
  value: {
    fontSize: 14,
  },
});

const SelectedOrderPayment: React.FC = () => {
  const classes = useStyles();
  const { selectedOrder } = useOrdersAuthorization();

  return (
    <div className={classes.container}>
      {selectedOrder?.pagamento.map((item, index) => (
        <div className={classes.list} key={index}>
          {item.PAGO ? (
            <Typography>
              ID PAG. {item.id_pagamento} ID AUT. {item.numero_autorizacao}
            </Typography>
          ) : (
            <Typography color="error">Pagamento não efetuado</Typography>
          )}
          <Typography variant="caption">NSU {item.NSU}</Typography>
          <Typography variant="subtitle2">
            Forma de pagamento: <b>{item.forma}</b>
          </Typography>
          {item.tipo === 'CRT' && (
            <>
              <Typography variant="subtitle2">Bandeira {item.Bandeira}</Typography>
              <Typography variant="subtitle2">
                {item.parcelas}x <b>{moneyFormat(item.valor / item.parcelas)}</b>
              </Typography>
            </>
          )}

          <Typography variant="subtitle2">
            Valor total: <b>{moneyFormat(item.valor)}</b>
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default SelectedOrderPayment;
