import React from 'react';
import { Typography, styled } from '@mui/material';
import CustomerItemTable from './CustomerItemTable';
import TableHeader from 'components/new-table/TableHeader';
import TableRow from 'components/new-table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { H2iCustomer } from 'types/h2iCustomer';
import { useCustomers } from 'pages/customers/hooks/useCustomers';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type CustomerListTableProps = {
  customers: H2iCustomer[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const CustomerListTable: React.FC<CustomerListTableProps> = ({ customers, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();
  const { setSelectedCustomer, setEditCustomerDialog } = useCustomers();

  function handleClick(customer: H2iCustomer) {
    setSelectedCustomer(customer);
    setEditCustomerDialog(true);
  }

  return (
    <>
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <HeaderItem key={item.id} onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}>
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody>
          {customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(customer => (
            <TableRow
              style={{ backgroundColor: customer.situacao !== 'A' ? '#ff0000' : 'transparent' }}
              key={customer.codigo}
              onClick={() => handleClick(customer)}
            >
              <CustomerItemTable customer={customer} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default CustomerListTable;
