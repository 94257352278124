import { Dispatch, SetStateAction, useState } from 'react';
import { Branch } from 'types/siteBranch';
import * as yup from 'yup';

export type BranchValidation = {
  name?: string;
  phone?: string;
  email?: string;
  address?: string;
  embed_map?: string;
  latitude?: string;
  longitude?: string;
};

type UseBranchValidation = [
  BranchValidation,
  Dispatch<SetStateAction<BranchValidation>>,
  (branch: Branch) => Promise<void>,
];

export function useBranchValidation(): UseBranchValidation {
  async function handleValidation(branch: Branch) {
    const schema = yup.object().shape({
      longitude: yup.string().required('A longitude é obrigatória'),
      latitude: yup.string().required('A latitude é obrigatória'),
      embed_map: yup.string().url('Informe um link válido').required('O mapa é obrigatório'),
      address: yup.string().required('O endereço é obrigatório'),
      email: yup.string().email('Informe um e-mail válido').required('O e-mail é obrigatório'),
      phone: yup.string().required('O telefone é obrigatório'),
      name: yup.string().required('O nome do banner é obrigatória'),
    });

    try {
      await schema.validate(branch);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<BranchValidation>({});
  return [validation, setValidation, handleValidation];
}
