import { cpfValidator } from 'helpers/cpfValidator';
import { Dispatch, SetStateAction, useState } from 'react';
import { Justification } from 'types/justification';
import * as yup from 'yup';

export type JustificationValidation = {
  funcionario?: string;
  cpf?: string;
  justificativa?: string;
  id_filial?: string;
};

type UseJustificationValidation = [
  JustificationValidation,
  Dispatch<SetStateAction<JustificationValidation>>,
  (justification: Justification) => Promise<void>,
];

export function useJustificationValidation(): UseJustificationValidation {
  async function handleValidation(justification: Justification) {
    const schema = yup.object().shape({
      justificativa: yup.string().required('A justificativa é obrigatória'),
      funcionario: yup.string().required('O nome é obrigatório'),
      id_filial: yup.string().required('Selecione a filial').nullable(),
      cpf: yup
        .string()
        .required('CPF é obrigatório')
        .transform((value, originalValue) => {
          return originalValue ? originalValue.replace(/\D/g, '') : '';
        })
        .test('docValidation', `CPF inválido`, value => {
          if (!value) return false;

          const cpf = cpfValidator(value);

          return cpf;
        }),
    });

    try {
      await schema.validate(justification);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<JustificationValidation>({});
  return [validation, setValidation, handleValidation];
}
