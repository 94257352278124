import React from 'react';
import {
  LocalizationProvider,
  MobileDatePickerProps,
  MobileDatePicker as NativeMobileDatePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { TextFieldProps } from '@mui/material';

interface CustomMobileDatePickerProps extends MobileDatePickerProps<any> {
  value: any;
  onChange(date: any): void;
  label?: string;
  textFieldProps?: TextFieldProps;
}

const MobileDatePicker: React.FC<CustomMobileDatePickerProps> = ({
  value,
  onChange,
  label,
  textFieldProps,
  ...rest
}) => {
  return (
    <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
      <NativeMobileDatePicker
        {...rest}
        slotProps={{ textField: textFieldProps }}
        label={label}
        value={value}
        onChange={onChange}
        closeOnSelect
        orientation="portrait"
      />
    </LocalizationProvider>
  );
};

export default MobileDatePicker;
