import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import { SellerCommission } from 'types/reports/sellerCommission';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type CommissionSellersItemModuleProps = {
  sellerCommission: SellerCommission;
};

const CommissionSellersItemModule: React.FC<CommissionSellersItemModuleProps> = ({ sellerCommission }) => {
  const classes = useStyles();

  return (
    <>
      <ListItem button className={classes.listItem}>
        <Typography variant="caption" color="primary">
          Categoria - {sellerCommission.categoria}
        </Typography>
        <Typography variant="caption" color="primary">
          Pedido por - {sellerCommission.pedido_por}
        </Typography>
        <Typography gutterBottom>Valor total - {moneyFormat(sellerCommission.vl_total)}</Typography>
        <Typography gutterBottom>Valor unitário - {moneyFormat(sellerCommission.vl_unitario)}</Typography>
        <Typography variant="body2" color="textSecondary">
          Vendedor - {sellerCommission.vendedor}
        </Typography>
      </ListItem>
    </>
  );
};

export default CommissionSellersItemModule;
