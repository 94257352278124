import React from 'react';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, styled } from '@mui/material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import { UsersQueryParams } from './Users';
import { Search } from '@mui/icons-material';

const Filter = styled('form')({
  display: 'grid',
  gridTemplateColumns: '300px 1fr',
  columnGap: 20,
});

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

type UsersFilterBoxProps = {
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
  params: UsersQueryParams;
  handleQueryParamsChange(index: keyof UsersQueryParams, value: string): void;
  handleSubmit(): void;
};

const UsersFilterBox: React.FC<UsersFilterBoxProps> = ({
  setDisplayMode,
  displayMode,
  params,
  handleQueryParamsChange,
  handleSubmit,
}) => {
  return (
    <FilterBox>
      <Filter onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          onChange={e => handleQueryParamsChange('term', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSubmit}>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={params.active === '1'}
              onChange={e => handleQueryParamsChange('active', e.target.checked ? '1' : '0')}
              color="primary"
            />
          }
          label="ativos"
        />

        <button type="submit" style={{ display: 'none' }}></button>
      </Filter>
      <Actions>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </Actions>
    </FilterBox>
  );
};

export default UsersFilterBox;
