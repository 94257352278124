import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { PriceProtection } from 'types/priceProtection';
import PriceProtectionItemModule from './PriceProtectionItemModule';

type PriceProtectionListModuleProps = {
  datas: PriceProtection[];
};

const PriceProtectionListModule: React.FC<PriceProtectionListModuleProps> = ({ datas }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {datas.map(data => (
            <PriceProtectionItemModule key={data.id} data={data} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default PriceProtectionListModule;
