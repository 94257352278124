import React, { useEffect, useRef, useState } from 'react';
import { TransferAccount } from 'types/transferAccount';
import { TransferAccountValidation } from '../validation/transferAccountValidation';
import { MenuItem, TextField, Typography, styled } from '@mui/material';
import { TotalizingAccount } from 'types/totalizingAccount';
import NumberFormat from 'react-number-format';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import Loading from 'components/loading/Loading';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';

interface TransferAccountFormProps {
  loading: boolean;
  account: TransferAccount;
  accounts: TotalizingAccount[];
  handleChange(index: keyof TransferAccount, value: any): void;
  validation: TransferAccountValidation;
}

const SelectedAccount = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 5,
  color: '#fff',
  padding: 10,
  border: '2px solid #ddd',
  backgroundColor: theme.palette.primary.light,
}));

const DateContainer = styled('div')({
  '& > div': {
    marginTop: 0,
  },
});

const Flex = styled('div')({
  display: 'flex',
  color: '#fff',
  justifyContent: 'space-between',
});

const TransferAccountForm: React.FC<TransferAccountFormProps> = ({
  loading,
  account,
  handleChange,
  validation,
  accounts,
}) => {
  const [originAccount, setOriginAccount] = useState<TotalizingAccount | null>(null);
  const [targetAccount, setTargetAccount] = useState<TotalizingAccount | null>(null);
  const inputs = {
    observacao: useRef<HTMLInputElement>(null),
    id_agrupamento: useRef<HTMLInputElement>(null),
    valor: useRef<HTMLInputElement>(null),
    id_conta_origem: useRef<HTMLSelectElement>(null),
    id_conta_destino: useRef<HTMLSelectElement>(null),
  };

  useEffect(() => {
    const _originAccount = accounts.filter(item => item.id === account.id_conta_origem);
    setOriginAccount(_originAccount[0]);
  }, [account.id_conta_origem, accounts]);

  useEffect(() => {
    const _targetAccount = accounts.filter(item => item.id === account.id_conta_destino);
    setTargetAccount(_targetAccount[0]);
  }, [account.id_conta_destino, accounts]);

  return (
    <>
      <SelectOthersBranch
        fullWidth
        accounting={true}
        style={{ maxHeight: 40 }}
        viewAllBranches={false}
        branch_id={account.id_filial}
        handleChange={id => handleChange('id_filial', id)}
      />

      <TextField
        select
        value={account.id_conta_origem}
        placeholder="Selecione a conta de saída"
        label="Conta de saída (origem)"
        inputRef={inputs.id_conta_origem}
        error={!!validation.id_conta_origem}
        helperText={validation.id_conta_origem}
        onChange={e => handleChange('id_conta_origem', e.target.value)}
      >
        {account.id_conta_origem === 0 && <MenuItem value={0}>Nenhuma conta selecionada</MenuItem>}

        {loading ? (
          <Loading />
        ) : (
          accounts
            .filter(item => item.id_filial === account.id_filial)
            .map(item => (
              <MenuItem disabled={account.id_conta_destino === item.id} key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))
        )}
      </TextField>

      {originAccount && (
        <SelectedAccount>
          <Typography variant="caption">
            <b>Banco: </b>
            {originAccount.nome}
          </Typography>

          <Flex>
            <Typography variant="caption">
              <b>Filial: </b>
              {originAccount.filial}
            </Typography>

            <Typography variant="caption">
              <b>Saldo: </b>
              {originAccount.formattedSaldo}
            </Typography>
          </Flex>
        </SelectedAccount>
      )}

      <TextField
        select
        value={account.id_conta_destino}
        label="Conta de entrada (destino)"
        placeholder="Selecione a conta de entrada"
        inputRef={inputs.id_conta_destino}
        error={!!validation.id_conta_destino}
        helperText={validation.id_conta_destino}
        onChange={e => handleChange('id_conta_destino', e.target.value)}
      >
        <MenuItem value={0}>Nenhuma conta selecionada</MenuItem>
        {loading ? (
          <Loading />
        ) : (
          accounts
            .filter(item => item.id_filial === account.id_filial)
            .map(item => (
              <MenuItem disabled={account.id_conta_origem === item.id} key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))
        )}
      </TextField>

      {targetAccount && (
        <SelectedAccount>
          <Typography variant="caption">
            <b>Banco: </b>
            {targetAccount.nome}
          </Typography>

          <Flex>
            <Typography variant="caption">
              <b>Filial: </b>
              {targetAccount.filial}
            </Typography>
            <Typography variant="caption">
              <b>Saldo: </b>
              {targetAccount.formattedSaldo}
            </Typography>
          </Flex>
        </SelectedAccount>
      )}

      <DateContainer>
        <DesktopDatePicker
          label="Data"
          maxDate={new Date()}
          value={account.date}
          onChange={date => handleChange('date', date)}
          textFieldProps={{ margin: 'normal', fullWidth: true }}
        />
        {!!validation.date && (
          <Typography fontSize={13} variant="caption" color="red">
            {validation.date}
          </Typography>
        )}

        <DesktopDatePicker
          label="Mês/Ano"
          value={account.monthYear}
          onChange={date => handleChange('monthYear', date)}
          format="MMMM-yyyy"
          views={['month', 'year']}
          textFieldProps={{
            fullWidth: true,
          }}
          localeText={{
            clearButtonLabel: 'Limpar',
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </DateContainer>

      <Typography variant="caption">Valor da transferência (R$):</Typography>
      <NumberFormat
        style={{
          padding: '4px 0 5px',
          font: 'inherit',
          fontSize: 16,
          border: 'none',
          borderBottom: '1px solid #666',
          width: '100%',
        }}
        autoFocus
        thousandsGroupStyle="thousand"
        prefix="R$ "
        decimalSeparator=","
        thousandSeparator="."
        fixedDecimalScale
        decimalScale={2}
        value={account.valor}
        onValueChange={values => handleChange('valor', values.value)}
        inputMode="decimal"
        isNumericString
      />
      {!!validation.valor && (
        <Typography fontSize={13} variant="caption" color="red">
          {validation.valor}
        </Typography>
      )}

      <TextField
        inputRef={inputs.observacao}
        error={!!validation.observacao}
        helperText={validation.observacao}
        value={account.observacao}
        multiline
        label="Observação"
        placeholder="Adicione uma observação a sua transferência"
        minRows={3}
        onChange={e => handleChange('observacao', e.target.value)}
      />

      <button type="submit" style={{ display: 'none' }}></button>
    </>
  );
};

export default TransferAccountForm;
