import { TableTemplate } from 'types/tableTemplate';

export const MoreTableTemplate: TableTemplate[] = [
  {
    id: 'id_pedido',
    description: 'Código',
    originalId: 'id_pedido',
    width: 70,
    notFilterable: true,
  },
  {
    id: 'produto',
    description: 'Descrição',
    originalId: 'produto',
    width: 500,
  },
  {
    id: 'formattedUnitary',
    description: 'Vl unitário',
    originalId: 'formattedUnitary',
    dataType: 'number',
    width: 150,
  },
  {
    id: 'QTD',
    description: 'QTD',
    originalId: 'QTD',
    width: 60,
    dataType: 'quantity',
  },
  {
    id: 'formattedTotal',
    description: 'Total',
    originalId: 'formattedTotal',
    dataType: 'number',
    width: 150,
  },
];
