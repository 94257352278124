import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Typography } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import { FinancialCardBranch } from '../hooks/useFetchFinancialCard';

import { makeStyles } from '@mui/styles';
import { externalTooltipHandler } from 'components/chart/externalTooltip';

interface BranchChartProps {
  formattedTotalChart: FinancialCardBranch[];
}

const useStyles = makeStyles({
  containerChart: {
    gap: 30,
    display: 'grid',
    padding: '15px 0',
    position: 'relative',
    gridTemplateColumns: '300px 1fr',
  },
  contentChart: {},
  legend: {
    display: 'flex',
    flexDirection: 'column',
  },
  total: {
    color: '#008000',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const BranchChart: React.FC<BranchChartProps> = ({ formattedTotalChart }) => {
  const classes = useStyles();
  const pieData = useMemo(() => {
    return formattedTotalChart.sort((a, b) => b.valor_bruto - a.valor_bruto).map(item => item.valor_bruto);
  }, [formattedTotalChart]);

  return (
    <div className={classes.containerChart}>
      <div className={classes.legend}>
        <Typography variant="caption">
          <b>Legenda</b>
        </Typography>
        {formattedTotalChart
          .sort((a, b) => b.valor_bruto - a.valor_bruto)
          .map(item => (
            <Typography className={classes.item} variant="subtitle2" key={item.filial}>
              {item.filial}: <b className={classes.total}>{item.formattedValueGross}</b>
            </Typography>
          ))}
      </div>
      <div className={classes.contentChart}>
        <Bar
          data={{
            labels: formattedTotalChart.sort((a, b) => b.valor_bruto - a.valor_bruto).map(item => item.filial),
            datasets: [
              {
                label: 'Filiais',
                data: formattedTotalChart.sort((a, b) => b.valor_bruto - a.valor_bruto).map(item => item.valor_bruto),
                backgroundColor: [
                  'rgba(54, 162, 235, 0.8)',
                  'rgba(78, 217, 100, 0.8)',
                  'rgba(57, 05, 68, 0.8)',
                  'rgba(78, 89, 235, 0.8)',
                  'rgba(37, 85, 192, 0.8)',
                  'rgba(98, 99, 74, 0.8)',
                  'rgba(65, 2, 78, 0.8)',
                  'rgba(78, 95, 17, 0.8)',
                  'rgba(87, 21, 0, 0.8)',
                  'rgba(98, 178, 200, 0.8)',
                ],
                borderWidth: 1,
              },
            ],
          }}
          options={{
            plugins: {
              tooltip: {
                enabled: false,
                callbacks: {
                  label: tooltip => moneyFormat(pieData[tooltip.dataIndex as number]),
                },
                external: externalTooltipHandler,
              },
            },
            responsive: true,
          }}
        />
      </div>
    </div>
  );
};

export default BranchChart;
