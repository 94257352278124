import React, { useReducer } from 'react';

import { Settings } from 'types/settings';
import { SettingsActionsType, SETTINGS_CHANGE, SET_SETTINGS } from './types';

const SETTINGS_INITIAL_VALUE: Settings = {
  api_host: '',
  invoice_email_template_id: 0,
  receipt_email_template_id: 0,
  base_email_template_id: 0,
  new_reseller_template_id: 0,
  approved_reseller_template_id: 0,
  newsletter_subscriber_template_id: 0,
};

function settingsReducer(state = SETTINGS_INITIAL_VALUE, action: SettingsActionsType): Settings {
  switch (action.type) {
    case SETTINGS_CHANGE: {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case SET_SETTINGS: {
      return action.settings;
    }

    default: {
      return state;
    }
  }
}

type SettingsReducer = [Settings, React.Dispatch<SettingsActionsType>];

export function useSettingsReducer(): SettingsReducer {
  const [settings, dispatch] = useReducer(settingsReducer, SETTINGS_INITIAL_VALUE);
  return [settings, dispatch];
}
