import React, { MouseEvent, Dispatch, SetStateAction } from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import { productsTableTemplate } from '../productsTableTemplate';
import { MoreHoriz } from '@mui/icons-material';
import { useProducts } from '../../hooks/useProducts';
import { RentalProduct } from 'pages/rentals/types/rental';

const IconButtonStyled = styled(IconButton)({
  justifySelf: 'flex-start',
  padding: 5,
});

type ProductItemTableProps = {
  product: RentalProduct;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const ProductItemTable: React.FC<ProductItemTableProps> = ({ product, setAnchorEl }) => {
  const { setSelectedProduct } = useProducts();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedProduct(product);
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      {productsTableTemplate.map(item =>
        item.id === 'actions' ? (
          <IconButtonStyled onClick={event => handleClick(event)} size="small" key={item.id}>
            <MoreHoriz key={item.id} />
          </IconButtonStyled>
        ) : (
          <div key={item.id}>
            <Typography variant="body2">{product[item.id]}</Typography>
          </div>
        ),
      )}
    </>
  );
};

export default ProductItemTable;
