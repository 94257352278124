import { useEffect, useState } from 'react';
import { CommercialBi } from '../types/commercial-bi';
import { api } from 'services/jsonServerApi';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { moneyFormat } from 'helpers/numberFormat';

type UseFetchCommercialBi = {
  data: any;
  loading: boolean;
};

const initialValue: CommercialBi = {
  revenue: 1000000,
  gross_revenue: 1100000,
  average_ticket: 22.5,
  mc: 10,
  stock_value: 200,
  orders_value: 1000000,
  sellers: [],
  branches: [],
  products: [],
  cities: [],
  product_segments: [],
  payment_methods: [],
  account_types: [],
  formattedAverageTicket: '',
  formattedMc: '',
  formattedGrossRevenue: '',
  formattedRevenue: '',
  formattedStockValue: '',
  formattedOrdersValue: '',
};

export function useFetchCommercialBi(): UseFetchCommercialBi {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialValue);
  const { showErrorDialog } = useErrorHandler();

  useEffect(() => {
    api
      .get('/commercial_bi')
      .then(response => {
        setData({
          ...response.data,
          formattedAverageTicket: moneyFormat(response.data.average_ticket),
          formattedMc: moneyFormat(response.data.mc),
          formattedGrossRevenue: moneyFormat(response.data.gross_revenue),
          formattedRevenue: moneyFormat(response.data.revenue),
          formattedOrdersValue: moneyFormat(response.data.orders_value),
          formattedStockValue: moneyFormat(response.data.stock_value),
        });
      })
      .catch(err =>
        showErrorDialog({
          error: err,
          message: 'Não foi possível carregar os filtros',
        }),
      )
      .finally(() => setLoading(false));
  }, [showErrorDialog]);

  return {
    data,
    loading,
  };
}
