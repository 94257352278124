import React from 'react';
import { FormControlLabel, IconButton, MenuItem, Switch, TextField, Tooltip, styled } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import { Done } from '@mui/icons-material';
import { useDavReviewDetail } from '../hooks/useDavReviewDetail';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

interface DavReviewInstallmentConcessionProps {
  onExited(): void;
}

const DavReviewInstallmentConcession: React.FC<DavReviewInstallmentConcessionProps> = ({ onExited }) => {
  const installments = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const { order, handleChange, handleUpdateDav } = useDavReviewDetail();

  return (
    <Dialog
      title="concessão de parcelamento"
      onExited={onExited}
      maxWidth="sm"
      height="50vh"
      ComponentActions={
        <Tooltip onClick={handleUpdateDav} title="Salvar">
          <IconButton color="inherit">
            <Done />
          </IconButton>
        </Tooltip>
      }
    >
      <Container>
        <TextField
          select
          label="Parcelas sem juros"
          placeholder="Selecione a quantidade de parcelas sem juros"
          value={order.parcelamento_sem_juros}
          onChange={e => handleChange('parcelamento_sem_juros', parseInt(e.target.value))}
        >
          {installments.map(i => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
        </TextField>

        <FormControlLabel
          label="Checa preço"
          control={
            <Switch
              color="primary"
              onChange={event => handleChange('checa_preco', event.target.checked ? 'S' : 'N')}
              checked={order.checa_preco === 'S'}
            />
          }
        />
      </Container>
    </Dialog>
  );
};

export default DavReviewInstallmentConcession;
