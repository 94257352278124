import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTemplates } from './hooks/useTemplates';
import { Menu, MenuItem } from '@mui/material';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';

type TemplateCardMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
  handleSearch(): void;
};

const TemplateCardMenu: React.FC<TemplateCardMenuProps> = ({ anchorEl, setAnchorEl, handleSearch }) => {
  const { handleOpen } = useMessaging();
  const { selectedTemplate } = useTemplates();
  const [loading, setLoading] = useState(false);

  function handleClick() {
    setLoading(true);

    api
      .post(`/cardTemplates/${selectedTemplate?.id}/active`)
      .then(() => handleOpen('Template atualizado com sucesso!'))
      .catch(err => console.error(err))
      .finally(() => setLoading(false));

    setAnchorEl(null);
    handleSearch();
  }

  return (
    <>
      {loading && <Loading />}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleClick}>Ativar template</MenuItem>
      </Menu>
    </>
  );
};

export default TemplateCardMenu;
