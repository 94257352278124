import React, { useState, FormEvent, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import SettingsActions from './SettingsActions';
import { useSiteSettingsReducer } from './reducer/reducer';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import SettingsForm from './SettingsForm';
import { settingsChange, setSettings } from './reducer/actions';
import { SettingsIndexes } from './reducer/types';
import Loading from 'components/loading/Loading';
import { useSiteSettingValidation } from './validation/siteSettingValidation';

const SiteSettings: React.FC = () => {
  const [validation, setValidation, validate] = useSiteSettingValidation();
  const [saving, setSaving] = useState(false);
  const [settings, dispatch] = useSiteSettingsReducer();
  const messaging = useMessaging();

  useEffect(() => {
    api.get('/site-settings').then(response => {
      dispatch(setSettings(response.data));
    });
  }, [dispatch]);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});

    validate(settings)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.error(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/site-settings/update`, settings)
      .then(() => {
        messaging.handleOpen('Salvo');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleChange(index: SettingsIndexes, value: any) {
    dispatch(settingsChange(index, value));
  }

  return (
    <>
      <Appbar title="Site" ActionsComponent={<SettingsActions handleSubmit={handleValidation} />} />
      {saving && <Loading />}
      <PageHeader title="Painel de Controle" description="Configurações do site" />
      <form onSubmit={handleValidation}>
        <SettingsForm validation={validation} handleChange={handleChange} settings={settings} />
      </form>
    </>
  );
};

export default SiteSettings;
