import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { Email } from 'types/email';
import ExportEmailItemModule from './ExportEmailItemModule';

type ExportEmailListModuleProps = {
  emails: Email[];
};

const ExportEmailListModule: React.FC<ExportEmailListModuleProps> = ({ emails }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {emails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((email, index) => (
            <ExportEmailItemModule key={index + 1} email={email} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ExportEmailListModule;
