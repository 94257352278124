import { useApp } from 'hooks/app';
import { useCallback, useState } from 'react';
import { Expense } from 'types/expense';
import { ExpensesFilterProps } from '../Expenses';

type UseFetchExpenses = [Expense[], boolean, () => void];

export function useFetchExpenses(filter: ExpensesFilterProps): UseFetchExpenses {
  const [loading, setLoading] = useState(false);
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const { financialApi } = useApp();

  const fetch = useCallback(() => {
    if (!financialApi) {
      return;
    }

    setLoading(true);

    financialApi
      .get('/getcadastrodespesas', {
        params: {
          id_filial: filter.branchId === 1001 ? '' : filter.branchId,
          filtro: filter.description,
        },
      })
      .then(response => {
        if (response.data.MESSAGE) {
          setExpenses([]);
          return;
        }
        setExpenses(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, financialApi]);

  return [expenses, loading, fetch];
}
