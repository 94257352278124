import { Close, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React from 'react';

interface ProductSearchFormProps {
  searchText: string;
  handleSearch(value: string): void;
}

const ProductSearchForm: React.FC<ProductSearchFormProps> = ({ searchText, handleSearch }) => {
  return (
    <TextField
      label="Pesquisa"
      placeholder="Digite o nome do produto"
      margin="normal"
      value={searchText}
      onChange={event => handleSearch(event.target.value)}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment:
          searchText.length > 2 ? (
            <InputAdornment position="end">
              <IconButton onClick={() => handleSearch('')}>
                <Close />
              </IconButton>
            </InputAdornment>
          ) : undefined,
      }}
    />
  );
};

export default ProductSearchForm;
