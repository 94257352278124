import Dialog from 'components/dialogs/Dialog';
import { useApp } from 'hooks/app';
import React, { FormEvent, useState } from 'react';
import { useTotalizingAccountValidation } from '../../validation/totalizingAccountValidation';
import TotalizingAccountForm from '../TotalizingAccountForm';
import InsideSaving from 'components/loading/InsideSaving';
import { Button, styled } from '@mui/material';
import { useMessaging } from 'hooks/messaging';
import { TotalizingAccount } from 'types/totalizingAccount';

interface NewTotalizingAccountProps {
  onExited(): void;
  onSubmit(): void;
}

const Form = styled('form')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const NewTotalizingAccount: React.FC<NewTotalizingAccountProps> = ({ onExited, onSubmit }) => {
  const { financialApi } = useApp();
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);
  const [account, setAccount] = useState<TotalizingAccount>({
    id: 0,
    id_filial: 0,
    nome: '',
    saldo: 0,
    filial: '',
    conta_contabil: '',
  });
  const [validation, setValidation, validate] = useTotalizingAccountValidation();

  function handleValidation(e?: FormEvent<HTMLFormElement | HTMLButtonElement>) {
    e?.preventDefault();

    setValidation({});

    validate(account)
      .then(handleSubmit)
      .catch(error => {
        console.log(error);
        handleOpen('Não foi possível salvar a conta');
      });
  }

  function handleSubmit() {
    setSaving(true);

    financialApi
      .post('/savecontatotalizadora', {
        ...account,
        saldo: parseFloat(account.saldo.toString()),
      })
      .then(onSubmit)
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleChange(index: keyof TotalizingAccount, value: any) {
    setAccount(account => ({
      ...account,
      [index]: value,
    }));
  }

  return (
    <Dialog
      maxWidth="sm"
      height="70vh"
      onExited={onExited}
      title="nova conta totalizadora"
      ComponentActions={
        <Button onClick={handleValidation} variant="contained">
          Salvar
        </Button>
      }
    >
      {saving && <InsideSaving />}
      <Form onSubmit={handleValidation}>
        <TotalizingAccountForm account={account} validation={validation} handleChange={handleChange} />
      </Form>
    </Dialog>
  );
};

export default NewTotalizingAccount;
