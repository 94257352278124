import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import { BillSetting } from 'types/billSetting';
import { useBilling } from 'pages/billing/hooks/useBilling';

type BillItemModuleProps = {
  bill: BillSetting;
};

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '80%',
    alignItems: 'center',
  },
});

const BillItemModule: React.FC<BillItemModuleProps> = ({ bill }) => {
  const { setSelectedBill } = useBilling();

  return (
    <CustomListItemButton onClick={() => setSelectedBill(bill)}>
      <Typography variant="caption" gutterBottom color="primary">
        {bill.bank_name}
      </Typography>
      <Typography gutterBottom>{bill.beneficiary_name}</Typography>
      <Typography variant="body2" color="textSecondary">
        {bill.demonstrative}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {bill.days_to_fee} dias para a taxa
      </Typography>
    </CustomListItemButton>
  );
};

export default BillItemModule;
