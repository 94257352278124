import React from 'react';
import { IconButton, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import history from 'services/history';

const CustomIconButton = styled(IconButton)(props => ({
  display: 'none',
  [props.theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

const SiteBannersActions: React.FC = () => {
  return (
    <CustomIconButton color="inherit" onClick={() => history.push('/site/slider-banner')}>
      <AddIcon />
    </CustomIconButton>
  );
};

export default SiteBannersActions;
