import { createContext, useContext } from 'react';
import { H2iBuyer } from 'types/h2iBuyer';

interface BuyerContextValue {
  handleValidation(): void;
  handleChange(index: keyof H2iBuyer, value: any): void;
  buyer: H2iBuyer | null;
  registerType: 'new' | 'edit';
}

const BuyerContext = createContext<BuyerContextValue>({} as BuyerContextValue);
export const BuyerProvider = BuyerContext.Provider;
export const BuyerConsumer = BuyerContext.Consumer;

export function useBuyer(): BuyerContextValue {
  const context = useContext(BuyerContext);
  return context;
}
