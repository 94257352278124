import React, { useState } from 'react';
import CompatibilityItemTable from './CompatibilityItemTable';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import history from 'services/history';
import { useCompatibilities } from 'pages/compatibilities/hooks/useCompatibilities';
import { Compatibility } from 'types/compatibility';
import CompatibilityListMenu from '../CompatibilityListMenu';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
    marginRight: 20,
  },
});

type CompatibilityListTableProps = {
  compatibilities: Compatibility[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const CompatibilityListTable: React.FC<CompatibilityListTableProps> = ({
  compatibilities,
  handleSort,
  orderedIndex,
}) => {
  const classes = useStyles();
  const { tableTemplate } = useTable();
  const { setSelectedCompatibility } = useCompatibilities();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(compatibility: Compatibility) {
    setSelectedCompatibility(compatibility);
    history.push(`/compatibilities/${compatibility.id}`);
  }

  return (
    <>
      <CompatibilityListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <div
              className={
                item.dataType === 'number' ? `${classes.headerItem} ${classes.headerItemNumeric}` : classes.headerItem
              }
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>

        <TableBody>
          {compatibilities.map(compatibility => (
            <TableRow key={compatibility.id} onClick={() => handleClick(compatibility)}>
              <CompatibilityItemTable setAnchorEl={setAnchorEl} compatibility={compatibility} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default CompatibilityListTable;
