import React from 'react';
import { Typography, ListItem } from '@mui/material';
import history from 'services/history';
import { EmailTemplate } from 'types/emailTemplate';

import { makeStyles } from '@mui/styles';

type EmailTemplateItemModuleProps = {
  emailTemplate: EmailTemplate;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
});

const EmailTemplateItemModule: React.FC<EmailTemplateItemModuleProps> = ({ emailTemplate }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem} onClick={() => history.push(`/email-template/${emailTemplate.id}`)}>
      <Typography variant="caption" color="primary">
        Template {emailTemplate.id}
      </Typography>
      <Typography variant="h6">{emailTemplate.name}</Typography>
    </ListItem>
  );
};

export default EmailTemplateItemModule;
