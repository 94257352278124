import { useApp } from 'hooks/app';
import { useCallback, useState } from 'react';
import { TotalizingAccountFilterProps } from '../TotalizingAccount';
import { moneyFormat } from 'helpers/numberFormat';
import { TotalizingAccount } from 'types/totalizingAccount';
import { parsePtBRDate } from 'helpers/parsePtBRDate';

export function useFetchTotalizingAccount(filter: TotalizingAccountFilterProps) {
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<TotalizingAccount[]>([]);
  const { financialApi } = useApp();

  const fetch = useCallback(() => {
    if (!financialApi) {
      return;
    }

    setLoading(true);

    financialApi
      .get('/getcontastotalizadoras', {
        params: {
          id_filial: filter.branchId === 1001 ? '' : filter.branchId,
          filtro: filter.description,
        },
      })
      .then(response => {
        if (response.data.MESSAGE) {
          setAccounts([]);
          return;
        }
        setAccounts(
          response.data.map(item => {
            item.formattedSaldo = moneyFormat(item.saldo);
            item.data = new Date(parsePtBRDate(item.data_inicio));
            return item;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, financialApi]);

  return { accounts, loading, fetch };
}
