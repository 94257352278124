import React, { useState } from 'react';
import { Button, IconButton, InputAdornment, MenuItem, TextField, styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import PaginationProvider from 'hooks/pagination';
import { MdSearch } from 'react-icons/md';
import { H2iCustomer } from 'types/h2iCustomer';
import { CustomerProvider } from './hooks/useCustomers';
import CustomerListTable from './list/table/CustomerListTable';
import CustomerListModule from './list/module/CustomerListModule';
import TableContainer from 'components/table/TableContainer';
import { customerTableTemplate } from './customerTableTemplate';
import Customer from './customer/register/edit/Customer';
import NewCustomer from './customer/register/new/NewCustomer';
import { useFetchCustomers } from './hooks/useFetchCustomers';
import { useDisplayMode } from 'hooks/useDisplayMode';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 10,
  flex: 0.5,
  [theme.breakpoints.down('md')]: {
    flex: 1,
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    flex: 1,
  },
}));

const Customers: React.FC = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<H2iCustomer | null>(null);
  const [editCustomerDialog, setEditCustomerDialog] = useState(false);
  const [newCustomerDialog, setNewCustomerDialog] = useState(false);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [
    handleCustomerDelete,
    handleSort,
    handleFormatCustomer,
    loading,
    saving,
    orderedIndex,
    setCustomers,
    setSearchText,
    searchText,
    setIndexToSearch,
    indexToSearch,
    filteredCustomers,
    fetchCustomer,
  ] = useFetchCustomers();

  function handleExitEditCustomerDialog() {
    setEditCustomerDialog(false);
    fetchCustomer();
  }

  function handleExitNewCustomerDialog() {
    setNewCustomerDialog(false);
    fetchCustomer();
  }

  return (
    <CustomerProvider
      value={{
        selectedCustomer,
        setSelectedCustomer,
        setEditCustomerDialog,
        setNewCustomerDialog,
        setCustomers,
        handleFormatCustomer,
        handleCustomerDelete,
        saving,
      }}
    >
      {editCustomerDialog && (
        <Customer onSubmit={handleExitEditCustomerDialog} onExited={() => setEditCustomerDialog(false)} />
      )}

      {newCustomerDialog && <NewCustomer onExited={handleExitNewCustomerDialog} />}

      <Appbar title="Favorecidos" />
      <PageHeaderActions
        title="Favorecidos"
        description="Cadastro de favorecidos"
        ActionComponent={
          <Button variant="contained" size="medium" color="primary" onClick={() => setNewCustomerDialog(true)}>
            Adicionar
          </Button>
        }
      />
      <FilterBox>
        <Filter onSubmit={fetchCustomer}>
          <TextField value={indexToSearch} onChange={e => setIndexToSearch(e.target.value)} select label="Pesquisar em">
            <MenuItem value="nome">Nome</MenuItem>
            <MenuItem value="documento">Documento</MenuItem>
          </TextField>
          <TextField
            label="Pesquisar"
            placeholder="Pesquisar"
            autoFocus
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <MdSearch size={20} color="#888" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Filter>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </FilterBox>
      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filteredCustomers.length === 0 ? (
        <NoData message="Nenhum favorecido para mostrar" />
      ) : (
        <TableContainer tableTemplate={customerTableTemplate}>
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <CustomerListTable customers={filteredCustomers} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <CustomerListModule customers={filteredCustomers} />
              )}
              <Pagination count={filteredCustomers.length} />
            </Container>
          </PaginationProvider>
        </TableContainer>
      )}
    </CustomerProvider>
  );
};

export default Customers;
