import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { useApp } from 'hooks/app';

export type TabValues = 'cities' | 'dashboard' | 'segments' | 'paymentMethods';

type CommercialBiTabsProps = {
  handleChange(value: TabValues): void;
  tab: TabValues;
};

const CommercialBiTabs: React.FC<CommercialBiTabsProps> = ({ tab, handleChange }) => {
  const app = useApp();

  return (
    <Tabs
      indicatorColor="secondary"
      value={tab}
      onChange={(e, value) => handleChange(value)}
      variant={app.isMobile ? 'scrollable' : 'standard'}
    >
      <Tab label="Dashboard" value="dashboard" />
      <Tab label="Cidades" value="cities" />
      <Tab label="Segmentos" value="segments" />
      <Tab label="Formas de pagamento" value="paymentMethods" />
    </Tabs>
  );
};

export default CommercialBiTabs;
