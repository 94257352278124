import { CourseStudent } from 'pages/course-students/types/courseStudent';
import { Dispatch, createContext, useContext } from 'react';
import { CourseStudentActions } from '../reducer/types';

interface CourseStudentContext {
  courseStudent: CourseStudent;
  dispatch: Dispatch<CourseStudentActions>;
  handleChange(index: keyof CourseStudent, value: any): void;
}

const Context = createContext<CourseStudentContext>({} as CourseStudentContext);
export const CourseStudentProvider = Context.Provider;

export function useCourseStudent(): CourseStudentContext {
  return useContext(Context);
}
