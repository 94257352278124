import React from 'react';
import { styled } from '@mui/material';

const Container = styled('div')(props => ({
  marginTop: 20,
  display: 'grid',
  flex: 1,
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridGap: 7,
  [props.theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [props.theme.breakpoints.down('xs')]: {
    gridTemplateColumns: '1fr',
  },
}));

const Card = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  alignItems: 'center',
  padding: 15,
  '& div + div': {
    marginTop: 10,
  },
});

const CardItem = styled('div')({
  width: '100%',
  height: 15,
});

const cards = Array(3).fill('');

const UsersCardLoading: React.FC = () => {
  return (
    <Container>
      {cards.map((value, index) => (
        <Card key={index}>
          <CardItem className="animated-background" />
          <CardItem className="animated-background" />
          <CardItem className="animated-background" />
        </Card>
      ))}
    </Container>
  );
};

export default UsersCardLoading;
