import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { useBranches } from '../../hooks/useBranches';
import { ItemsSold } from 'types/reports/itemsSold';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type ItemsSoldItemModuleProps = {
  itemSold: ItemsSold;
};

const ItemsSoldItemModule: React.FC<ItemsSoldItemModuleProps> = ({ itemSold }) => {
  const classes = useStyles();
  const { setSelectedItemSold } = useBranches();

  function handleClick() {
    setSelectedItemSold(itemSold);
  }

  return (
    <>
      <ListItem button className={classes.listItem} onClick={handleClick}>
        <Typography variant="caption" color="primary">
          Código - {itemSold.codigo}
        </Typography>
        <Typography gutterBottom>Qtd - {itemSold.qtd}</Typography>
        <Typography variant="body2" color="textSecondary">
          Produto - {itemSold.produto}
        </Typography>
      </ListItem>
    </>
  );
};

export default ItemsSoldItemModule;
