import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { CreditAnalysisQuestion } from 'types/creditAnalysisQuestion';

type CreditAnalysisQuestionsContextValue = {
  questions: CreditAnalysisQuestion[];
  selectedQuestion: CreditAnalysisQuestion | null;
  setSelectedQuestion: Dispatch<SetStateAction<CreditAnalysisQuestion | null>>;
};

const CreditAnalysisQuestionsContext = createContext<CreditAnalysisQuestionsContextValue>(
  {} as CreditAnalysisQuestionsContextValue,
);
export const CreditAnalysisQuestionsProvider = CreditAnalysisQuestionsContext.Provider;

export function useCreditAnalysisQuestions(): CreditAnalysisQuestionsContextValue {
  const context = useContext(CreditAnalysisQuestionsContext);
  return context;
}
