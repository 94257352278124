import { useApp } from 'hooks/app';
import { useCallback, useState } from 'react';
import { Revenue } from 'types/revenue';
import { RevenuesFilterProps } from '../Revenues';

type UseFetchRevenues = [Revenue[], boolean, () => void];

export function useFetchRevenues(filter: RevenuesFilterProps): UseFetchRevenues {
  const [loading, setLoading] = useState(false);
  const [revenues, setRevenues] = useState<Revenue[]>([]);
  const { financialApi } = useApp();

  const fetch = useCallback(() => {
    if (!financialApi) {
      return;
    }

    setLoading(true);

    financialApi
      .get('/getcadastroreceitas', {
        params: {
          id_filial: filter.branchId || '',
          filtro: filter.description,
        },
      })
      .then(response => {
        if (response.data.MESSAGE) {
          setRevenues([]);
          return;
        }
        setRevenues(
          response.data.map(item => {
            item.formattedType =
              item.tipo_valores === 'M' ? 'Ambos' : item.tipo_valores === 'P' ? 'Positivo' : 'Negativo';
            return item;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, financialApi]);

  return [revenues, loading, fetch];
}
