import { Address } from 'types/address';
import { RegisterForm } from 'types/registerForm';
import { RegisterActions } from './types';

export function change(index: keyof RegisterForm, value: any): RegisterActions {
  return {
    type: 'REGISTER_CHANGE',
    index,
    value,
  };
}

export function addressChange(index: keyof Address, value: any): RegisterActions {
  return {
    type: 'REGISTER_ADDRESS_CHANGE',
    index,
    value,
  };
}

export function registerSetAddress(address: Address): RegisterActions {
  return {
    type: 'REGISTER_SET_ADDRESS',
    address,
  };
}

export function partnerAddressChange(index: keyof Address, value: any): RegisterActions {
  return {
    type: 'REGISTER_PARTNER_ADDRESS_CHANGE',
    index,
    value,
  };
}

export function registerSetPartnerAddress(address: Address): RegisterActions {
  return {
    type: 'REGISTER_SET_PARTNER_ADDRESS',
    address,
  };
}
