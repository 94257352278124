import { createContext, Dispatch, FormEvent, SetStateAction, useContext } from 'react';
import { Reseller, ResellerList } from 'types/reseller';
import { ResellersFilter } from '../Resellers';

type ResellersContextValue = {
  selectedReseller: Reseller | null;
  setSelectedReseller(reseller: Reseller | null): void;
  setDialogApproval: Dispatch<SetStateAction<boolean>>;
  handleRemoveFromList(resellerId: number): void;
  handleSearch(filter: ResellersFilter, e?: FormEvent<HTMLFormElement>): void;
  handleFormatReseller(reseller: Reseller): ResellerList;
  filter: ResellersFilter;
  handleChangeFilter(key: keyof ResellersFilter, value: any): void;
  handleSincronizeAgent(): void;
};

const ResellersContext = createContext<ResellersContextValue>({} as ResellersContextValue);
export const ResellersProvider = ResellersContext.Provider;

export function useResellers(): ResellersContextValue {
  const context = useContext(ResellersContext);
  return context;
}
