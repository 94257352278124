import React, { useState, useMemo, useEffect } from 'react';
import { Typography, styled } from '@mui/material';
import NoData from 'components/no-data/NoData';
import { ProductsContextProvider } from './hooks/useProducts';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import ProductListTable from './list/table/ProductListTable';
import ProductListModule from './list/module/ProductListModule';
import WasteProductForm from './WasteProductForm';
import { useWasteProductValidation } from './validation/wasteProductValidation';
import { useWaste } from '../hooks/useWaste';
import { SerialNumbers, WasteProduct, WasteProductFormContent } from 'pages/material-residues/waste/types/waste';
import { addWasteProduct } from 'pages/material-residues/waste/reducer/actions';
import { Product } from 'types/product';
import SerialNumberDialog from '../dialog/SerialNumberDialog';
import { useMessaging } from 'hooks/messaging';
import SerialNumbersDialog from '../dialog/SerialNumbersDialog';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  width: '100%',
  backgroundColor: '#fff',
  padding: 30,
  [theme.breakpoints.down('sm')]: {
    padding: '5px 15px',
  },
}));

export interface WasteSelectProduct {
  id: string;
  max_value: number;
  value: string;
  name: string;
  quantity: string;
  product: Product | null;
  serialNumbers: SerialNumbers[];
}

const productInitialState = {
  id: '',
  value: '',
  name: '',
  quantity: '',
  product: null,
  max_value: 0,
  serialNumbers: [],
};

interface WasteProductProps {
  allowProduct: boolean;
}

const WasteProducts: React.FC<WasteProductProps> = ({ allowProduct }) => {
  const { isMobile, windowWidth } = useApp();
  const { handleOpen } = useMessaging();
  const [product, setProduct] = useState<WasteSelectProduct>(productInitialState);
  const { waste, dispatch } = useWaste();
  const [filteredProducts, setFilteredProducts] = useState<WasteProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<WasteProduct | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const displayMode = useMemo(() => (isMobile || windowWidth < 930 ? 'module' : 'list'), [isMobile, windowWidth]);
  const [validation, setValidation, validate] = useWasteProductValidation(product.max_value);
  const [openSerialNumberModal, setOpenSerialNumberModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  useEffect(() => {
    setFilteredProducts(waste.produtos);
  }, [waste.produtos]);

  function handleSort(index: string) {
    const p = sort(index, filteredProducts);
    setFilteredProducts(p);
  }

  async function handleValidation(data: WasteProductFormContent) {
    setValidation({});

    await validate(data);

    const existsProduct = waste.produtos.find(item => item.id_produto === data.productId);

    if (existsProduct) {
      handleOpen('Produto já adicionado');
      return;
    }
    setOpenSerialNumberModal(true);
  }

  async function handleSubmit(data: WasteProductFormContent) {
    dispatch(addWasteProduct(data));

    setProduct(productInitialState);
    setOpenSerialNumberModal(false);
  }

  function handleChange(index: keyof WasteSelectProduct, value: any) {
    setProduct(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function onExited() {
    setOpenSerialNumberModal(false);
    handleChange('serialNumbers', []);
  }

  function onSubmit() {
    const maxId = !filteredProducts.length
      ? 0
      : filteredProducts.reduce((max, current) => {
          return max.id > current.id ? max : current;
        }, filteredProducts[0]).id;

    handleSubmit({
      id: maxId + 1,
      product: product.name,
      value: product.value,
      quantity: parseInt(product.quantity),
      productId: parseInt(product.id),
      serial_numbers: product.serialNumbers,
    });
  }

  return (
    <Container style={{ opacity: allowProduct ? 1 : 0.5 }}>
      <Typography variant="h6" className="subtitle" color="primary">
        Produtos
      </Typography>

      {selectedProduct && !anchorEl && (
        <SerialNumbersDialog product={selectedProduct} onExited={() => setSelectedProduct(null)} />
      )}

      {openSerialNumberModal && (
        <SerialNumberDialog
          quantity={product.quantity}
          onChange={handleChange}
          onExited={onExited}
          onSubmit={onSubmit}
          serialNumbers={product.serialNumbers}
        />
      )}

      <WasteProductForm
        setValidation={setValidation}
        allowProduct={allowProduct}
        product={product}
        onChange={handleChange}
        filteredProducts={waste.produtos}
        validation={validation}
        handleSubmit={handleValidation}
      />

      {filteredProducts.length === 0 ? (
        <NoData message="Nenhum produto para mostrar." />
      ) : (
        <ProductsContextProvider value={{ selectedProduct, setSelectedProduct, setAnchorEl, anchorEl }}>
          {displayMode === 'list' ? (
            <ProductListTable products={filteredProducts} handleSort={handleSort} orderedIndex={orderedIndex} />
          ) : (
            <ProductListModule products={filteredProducts} />
          )}
        </ProductsContextProvider>
      )}
    </Container>
  );
};

export default WasteProducts;
