import React, { Dispatch, SetStateAction } from 'react';
import { MoreHoriz } from '@mui/icons-material';

import { StoreProduct } from '../../SelectedBranch';
import { IconButton, Typography } from '@mui/material';
import { selectedBranchTableTemplate } from '../../selectedBranchTableTemplate';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
});

type SelectedBranchItemTableProps = {
  product: StoreProduct;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  setProduct: Dispatch<SetStateAction<StoreProduct | null>>;
};

const SelectedBranchItemTable: React.FC<SelectedBranchItemTableProps> = ({ product, setProduct, setAnchorEl }) => {
  const classes = styles();

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    setProduct(product);
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      {selectedBranchTableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <IconButton key={item.id} onClick={e => handleClick(e)} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography variant="body2">{product[item.id as keyof StoreProduct]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default SelectedBranchItemTable;
