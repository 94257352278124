import React from 'react';
import { Typography, ListItemButton, styled, IconButton } from '@mui/material';
import { Sales } from 'types/reports/sales';
import { MoreHoriz } from '@mui/icons-material';

const CustomListItemButton = styled(ListItemButton)({
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #ddd',
  backgroundColor: '#fff',
  '& .title': {
    backgroundColor: '#30519f4d',
    padding: 5,
    width: '100%',
    textTransform: 'lowercase',
  },
  '& > div': {
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr 44px',
    gap: 5,
    '& > p': {
      padding: 5,
    },
  },
});

type SalesItemModuleProps = {
  sale: Sales;
  handleSelectSale(sale: Sales): void;
};

const SalesItemModule: React.FC<SalesItemModuleProps> = ({ sale, handleSelectSale }) => {
  function handleClick() {
    handleSelectSale(sale);
  }

  return (
    <CustomListItemButton onClick={handleClick}>
      <Typography className="title" variant="subtitle2" color="#000">
        {sale.cliente}
      </Typography>
      <div>
        <Typography variant="body2" fontSize={12}>
          Número: <b style={{ color: '#30519f' }}>{sale.numero_venda}</b>
        </Typography>
        <Typography variant="body2" fontSize={12}>
          Valor total: <b style={{ color: 'green' }}>{sale.formattedTotal}</b>
        </Typography>

        <IconButton>
          <MoreHoriz />
        </IconButton>
      </div>
    </CustomListItemButton>
  );
};

export default SalesItemModule;
