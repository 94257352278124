import React, { useState, useEffect, useCallback } from 'react';
import { useApp } from 'hooks/app';
import Dialog from 'components/dialogs/Dialog';
import PaginationProvider from 'hooks/pagination';
import { moneyFormat } from 'helpers/numberFormat';
import Pagination from 'components/pagination/Pagination';
import InsideSaving from 'components/loading/InsideSaving';
import { useAbcProduct } from '../hooks/useAbcProduct';
import { useAbcProducts } from '../hooks/useAbcProducts';
import AbcProductListTable from '../list/table/AbcProductListTable';
import { AbcProduct } from 'types/abcProduct';

interface SelectedSegmentProps {
  onExited(): void;
}

const SelectedSegment: React.FC<SelectedSegmentProps> = ({ onExited }) => {
  const { h2iApi } = useApp();
  const [loading, setLoading] = useState(false);
  const { searchValue, filter } = useAbcProduct();
  const { selectedSegment, selectedSubgroup } = useAbcProducts();
  const [products, setProducts] = useState<AbcProduct[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<AbcProduct[]>([]);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  const handleSearch = useCallback(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    h2iApi
      .get('/api/relcurvaabcprodutos', {
        params: {
          data_ini: filter.dates.formattedInitialDate,
          data_fim: filter.dates.formattedFinalDate,
          id_filial: selectedSubgroup?.branchId,
          id_subgrupo: selectedSubgroup?.cod,
          filtro: searchValue,
          tipo_venda: filter.typeSale,
          id_categoria: filter.categoryIds.join(','),
          id_subcategoria: filter.subCategoryIds.join(','),
          pedido_por: filter.madeBy,
          id_segmento: selectedSegment?.cod,
          id_unidade: filter.unitIds.join(','),
        },
      })
      .then(response => {
        const _products = response.data[0].produtos.map(item => {
          item.formattedTotal = moneyFormat(item.total);
          item.formattedCoust = moneyFormat(item.custo);
          item.formattedMc = moneyFormat(item.mc);
          item.formattedSpiff = moneyFormat(item.spiff);
          item.formattedUnitary = moneyFormat(item.unitario);

          return item;
        });

        setProducts(_products);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [h2iApi, filter, selectedSubgroup, searchValue, selectedSegment]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  return (
    <Dialog onExited={onExited} maxWidth="lg" title={selectedSubgroup?.subgrupo}>
      {loading ? (
        <InsideSaving />
      ) : (
        <div style={{ display: 'grid', gap: 10 }}>
          <PaginationProvider>
            <AbcProductListTable products={filteredProducts} />
            <Pagination count={filteredProducts.length} />
          </PaginationProvider>
        </div>
      )}
    </Dialog>
  );
};

export default SelectedSegment;
