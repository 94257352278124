import { SettingsActionsType, SETTINGS_CHANGE, SettingsIndexes, SET_SETTINGS } from './types';
import { Settings } from 'types/settings';

export function settingsChange(index: SettingsIndexes, value: any): SettingsActionsType {
  return {
    type: SETTINGS_CHANGE,
    index,
    value,
  };
}

export function setSettings(settings: Settings): SettingsActionsType {
  return {
    type: SET_SETTINGS,
    settings,
  };
}
