import React from 'react';
import { List } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { CommissionCategory } from 'types/reports/commissionCategory';
import CommissionCategoriesItemModule from './CommissionCategoryItemModule';
import { makeStyles } from '@mui/styles';

type CommissionCategoriesListModuleProps = {
  commissionCategories: CommissionCategory[];
};

const styles = makeStyles({
  list: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: 100,
    flex: 1,
  },
});

const CommissionCategoriesListModule: React.FC<CommissionCategoriesListModuleProps> = ({ commissionCategories }) => {
  const { rowsPerPage, page } = usePagination();
  const classes = styles();

  return (
    <List className={classes.list}>
      {commissionCategories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(commissionCategory => (
        <CommissionCategoriesItemModule key={commissionCategory.categoria} commissionCategory={commissionCategory} />
      ))}
    </List>
  );
};

export default CommissionCategoriesListModule;
