import React, { useCallback, useState, useEffect, useRef } from 'react';
import PersonAutocompleteItem from './PersonAutocompleteItem';
import { TextField } from '@mui/material';
import styles from './autocomplete.module.css';
import { Autocomplete } from '@mui/lab';
import PersonAutocompleteAdornment from './PersonAutocompleteAdornment';
import { useApp } from 'hooks/app';

let timer: NodeJS.Timeout;

export interface Employee {
  id: number;
  nome: string;
  admissao: string;
  id_filial: number;
  cpf: string;
}

interface AutocompleteFromProps {
  onSelect(person: Employee | null): void;
  label: string;
  placeholder: string;
  favored: Employee | null;
  helperText: string;
  error: boolean;
  endpoint: string;
  disabled: boolean;
}

const PersonAutocomplete: React.FC<AutocompleteFromProps> = ({
  onSelect,
  label,
  placeholder,
  helperText,
  error,
  endpoint,
  favored,
  disabled,
}) => {
  const { financialApi } = useApp();
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState<Employee[]>([]);
  const [records, setRecords] = useState<Employee[]>([]);

  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (error) {
      input.current?.focus();
    }
  }, [error]);

  const handleSelectReseller = useCallback(
    (person: Employee) => {
      onSelect(person);
      setFiltered([]);
    },
    [onSelect],
  );

  useEffect(() => {
    setFiltered(records);
  }, [records]);

  const fetchRecords = useCallback(
    async (term: string) => {
      setLoading(true);

      financialApi
        ?.get(`${endpoint}filtro=${term}`)
        .then(response => {
          setRecords(response.data);
        })
        .finally(() => setLoading(false))
        .catch(err => console.error(err));
    },
    [financialApi, endpoint],
  );

  const clearSelected = useCallback(() => {
    onSelect(null);
  }, [onSelect]);

  function handleChange(value: string) {
    clearTimeout(timer);

    if (value.length < 3) {
      return;
    }

    timer = setTimeout(() => fetchRecords(value), 400);
  }

  return (
    <Autocomplete
      classes={{ inputRoot: styles.inputRoot }}
      id="person-autocomplete"
      open={filtered.length > 0}
      options={filtered}
      value={favored}
      fullWidth
      disabled={disabled}
      filterOptions={records => records}
      getOptionLabel={reseller => `${reseller.nome}`}
      renderOption={(props, option) => (
        <PersonAutocompleteItem handleSelect={handleSelectReseller} value={favored} favored={option} {...props} />
      )}
      autoComplete
      renderInput={params => (
        <TextField
          {...params}
          disabled={disabled}
          inputRef={input}
          label={label}
          placeholder={placeholder}
          onChange={event => handleChange(event.target.value)}
          onBlur={() => setFiltered([])}
          error={error}
          fullWidth
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: !disabled && (
              <PersonAutocompleteAdornment loading={loading} selected={favored} clearSelected={clearSelected} />
            ),
          }}
        />
      )}
    />
  );
};

export default PersonAutocomplete;
