import React, { useState, FormEvent, useEffect, useRef } from 'react';
import { Button, TextField, styled } from '@mui/material';
import { ProjectActionFormContent } from 'types/project';
import { ProjectActionValidation } from '../validation/projectActionValidation';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import UsersAutoComplete from './auto-complete/UsersAutoComplete';
import { User } from 'types/user';

const Form = styled('form')(props => ({
  display: 'flex',
  flex: 0,
  flexDirection: 'row',
  maxWidth: 1500,
  gap: 10,
  alignItems: 'flex-start',
  marginBottom: 30,
  '& > button': {
    flexShrink: 0,
    alignSelf: 'center',
  },
  [props.theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
    '& > button': {
      alignSelf: 'flex-start',
    },
  },
}));

interface ProjectCostFormProps {
  handleSubmit(data: ProjectActionFormContent): Promise<void>;
  validation: ProjectActionValidation;
}

const ProjectCostForm: React.FC<ProjectCostFormProps> = ({ handleSubmit: defaultHandleSubmit, validation }) => {
  const [observation, setObservation] = useState('');
  const [fullname, setFullname] = useState('');
  const [action, setAction] = useState('');
  const [referenceDate, setReferenceDate] = useState<Date | null>(new Date());
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    setFullname(users.map(user => user.name).join(', '));
  }, [users]);

  const inputs = {
    observation: useRef<HTMLInputElement>(null),
    fullname: useRef<HTMLInputElement>(null),
    referenceDate: useRef<HTMLInputElement>(null),
    action: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    defaultHandleSubmit({
      observation,
      fullname,
      action,
      referenceDate: referenceDate as Date,
      userIds: users.map(user => user.id as number),
    })
      .then(() => {
        setFullname('');
        setAction('');
        setReferenceDate(new Date());
        setObservation('');
        setUsers([]);
        inputs.action.current?.focus();
      })
      .catch(err => console.error(err));
  }

  function handleDeleteUser(index: number) {
    setUsers(state => state.filter((user, _index) => index !== _index));
  }

  return (
    <Form onSubmit={event => handleSubmit(event)}>
      <TextField
        label="Ação"
        placeholder="Informe a ação"
        margin="normal"
        value={action}
        onChange={event => setAction(event.target.value)}
        inputRef={inputs.action}
        error={!!validation.action}
        helperText={validation.action}
        fullWidth
        autoFocus
        rows={3}
        multiline
      />

      <UsersAutoComplete
        handleDeleteUser={handleDeleteUser}
        label="Responsável"
        placeholder=""
        selectedUsers={users}
        onSelect={_user => (!_user ? setUsers([]) : setUsers(state => [...state, _user]))}
        error={!!validation.fullname}
        helperText={validation.fullname ?? ''}
      />

      <DesktopDatePicker
        label="Data de referência"
        value={referenceDate}
        onChange={date => setReferenceDate(date)}
        inputRef={inputs.referenceDate}
        textFieldProps={{
          fullWidth: true,
          margin: 'normal',
          error: !!validation.referenceDate,
          helperText: validation.referenceDate,
          placeholder: 'Cargo do contato',
          rows: 3,
          multiline: true,
        }}
      />

      <TextField
        label="Observação"
        placeholder="Observação da ação"
        margin="normal"
        autoFocus
        value={observation}
        onChange={event => setObservation(event.target.value)}
        fullWidth
        rows={3}
        multiline
      />

      <Button type="submit" size="small" variant="contained" color="primary">
        inserir
      </Button>
    </Form>
  );
};

export default ProjectCostForm;
