import { Dispatch, useMemo, useState } from 'react';
import { useApp } from 'hooks/app';
import { Waste } from '../../types/waste';
import { useWasteReducer } from '../../reducer/reducer';
import { moneyFormat } from 'helpers/numberFormat';
import { changeWasteAction } from '../../reducer/actions';
import { WasteValidation, useWasteValidation } from '../validation/wasteValidation';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import { postalCodeSearch } from 'services/postalCodeSearch';
import { WasteActions } from '../../reducer/types';

type UseFetchWaste = {
  waste: Waste;
  validation: WasteValidation;
  dispatch: Dispatch<WasteActions>;
  disableButton: boolean;
  saving: boolean;
  loadingCustomer: boolean;
  loadingPostalCode: boolean;
  formattedTotal: string;
  handleValidation(): void;
  handleValidationProduct(): void;
  handlePostalCodeChange(value: string): void;
  handleDocumentChange(value: string): void;
  handleChange(index: keyof Waste, value: any);
};

let timer: NodeJS.Timeout;

export function useFetchWaste(): UseFetchWaste {
  const { h2iApi } = useApp();
  const [waste, dispatch] = useWasteReducer();
  const [saving, setSaving] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [loadingPostalCode, setLoadingPostalCode] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [validation, setValidation, validate] = useWasteValidation();
  const { handleOpen } = useMessaging();

  const formattedTotal = useMemo(() => {
    return moneyFormat(waste.valor_total);
  }, [waste.valor_total]);

  function handleDocumentChange(value: string) {
    handleChange('cnpj_cpf', value);

    const documentSanitized = value.replace(/\D/g, '');

    clearTimeout(timer);

    if (documentSanitized.length === 11 || documentSanitized.length === 14) {
      timer = setTimeout(() => {
        setLoadingCustomer(true);
        h2iApi
          ?.get('/api/getclientescadastrados', { params: { cnpj: documentSanitized } })
          .then(response => {
            if (response.data.MESSAGE) {
              setDisableButton(true);
              handleOpen('Cliente não encontrado');
              return;
            }

            handleChange('nome', response.data[0].nome);
            handlePostalCodeChange(response.data[0].cep);
            setDisableButton(false);
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => setLoadingCustomer(false));
      }, 500);
    } else {
      setDisableButton(true);
    }
  }

  function handleChange(index: keyof Waste, value: any) {
    setValidation({});
    dispatch(changeWasteAction(index, value));
  }

  function handleValidation() {
    setValidation({});

    validate(waste)
      .then(handleValidationProduct)
      .catch(error => console.error(error));
  }

  function handleValidationProduct() {
    if (disableButton) {
      handleOpen('Ocorreu um erro na validação');
      return;
    }
    if (!waste.produtos.length) {
      handleOpen('É necessário adicionar ao menos um produto');
      return;
    }
    handleSubmit();
  }

  function handleSubmit() {
    if (!h2iApi) return;
    setSaving(true);

    h2iApi
      .post('/api/savereee', waste)
      .then(response => {
        history.push(`/waste/print/${response.data}`);
      })
      .catch(error => {
        console.error(error);
        handleOpen('Não foi possível salvar o REEE');
      })
      .finally(() => setSaving(false));
  }

  function handlePostalCodeChange(value: string) {
    handleChange('cep', value);

    clearTimeout(timer);
    const postalCodeSanitized = value.replace(/\D/g, '');

    if (postalCodeSanitized.length === 7) {
      handleChange('bairro', '');
      handleChange('endereco', '');
      handleChange('uf', '');
      handleChange('cidade', '');
    }

    if (postalCodeSanitized.length < 8) {
      return;
    }

    timer = setTimeout(() => {
      setLoadingPostalCode(true);
      postalCodeSearch(postalCodeSanitized)
        .then(response => {
          if (response.data.erro) {
            return;
          }
          const { data } = response;

          handleChange('cidade', data.localidade);
          handleChange('uf', data.uf);
          handleChange('endereco', data.logradouro);
          handleChange('bairro', data.bairro);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => setLoadingPostalCode(false));
    }, 500);
  }

  return {
    loadingCustomer,
    loadingPostalCode,
    saving,
    formattedTotal,
    handleDocumentChange,
    handleChange,
    waste,
    disableButton,
    validation,
    dispatch,
    handlePostalCodeChange,
    handleValidation,
    handleValidationProduct,
  };
}
