import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { H2iBuyer } from 'types/h2iBuyer';

export interface BuyersContextValue {
  selectedBuyer: H2iBuyer | null;
  setSelectedBuyer(customer: H2iBuyer | null): void;
  setBuyers: Dispatch<SetStateAction<H2iBuyer[]>>;
  handleFormatBuyer(customer: H2iBuyer): H2iBuyer;
  handleBuyerDelete(customerId: number): Promise<void>;
  setEditBuyerDialog: Dispatch<SetStateAction<boolean>>;
  setNewBuyerDialog: Dispatch<SetStateAction<boolean>>;
  saving: boolean;
}

const BuyersContext = createContext<BuyersContextValue>({} as BuyersContextValue);
export const BuyersProvider = BuyersContext.Provider;
export const BuyersConsumer = BuyersContext.Consumer;

export function useBuyers(): BuyersContextValue {
  const context = useContext(BuyersContext);
  return context;
}
