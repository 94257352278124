import React, { useRef } from 'react';
import { Expense } from 'types/expense';
import { ExpenseValidation } from '../validation/expenseValidation';
import { TextField } from '@mui/material';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';

interface ExpenseFormProps {
  expense: Expense;
  handleChange(index: keyof Expense, value: any): void;
  validation: ExpenseValidation;
}

const ExpenseForm: React.FC<ExpenseFormProps> = ({ expense, handleChange, validation }) => {
  const inputs = {
    id_agrupamento: useRef<HTMLInputElement>(null),
    nome: useRef<HTMLInputElement>(null),
  };

  return (
    <>
      <SelectOthersBranch
        selectStyle={{ maxHeight: 40 }}
        viewAllBranches={false}
        branch_id={expense.id_filial}
        handleChange={branchId => handleChange('id_filial', branchId)}
      />

      <TextField
        value={expense.nome}
        placeholder="Digite o nome"
        label="Nome"
        inputRef={inputs.nome}
        error={!!validation.nome}
        helperText={validation.nome}
        onChange={e => handleChange('nome', e.target.value)}
      />

      <TextField
        value={expense.conta_contabil}
        placeholder="Digite o código contabil"
        label="Cód Contábil"
        onChange={e => handleChange('conta_contabil', e.target.value)}
      />

      <TextField
        value={expense.id_agrupamento}
        placeholder="Digite o ID agrupamento"
        label="ID Agrupamento"
        inputMode="numeric"
        type="number"
        inputRef={inputs.id_agrupamento}
        error={!!validation.id_agrupamento}
        helperText={validation.id_agrupamento}
        onChange={e => handleChange('id_agrupamento', e.target.value)}
      />

      <button type="submit" style={{ display: 'none' }}></button>
    </>
  );
};

export default ExpenseForm;
