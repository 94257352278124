import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';
import { Expense } from 'types/expense';

export interface ExpenseValidation {
  id_agrupamento?: string;
  nome?: string;
}

type UseExpenseValidation = [
  ExpenseValidation,
  Dispatch<SetStateAction<ExpenseValidation>>,
  (expense: Expense) => Promise<void>,
];

export function useExpenseValidation(): UseExpenseValidation {
  async function handleExpenseValidation(expense: Expense) {
    const schema = yup.object().shape({
      id_agrupamento: yup.string().required('O ID é obrigatório'),
      nome: yup.string().required('A unidade é obrigatória'),
    });

    try {
      await schema.validate(expense);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setExpenseValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [expenseValidation, setExpenseValidation] = useState<ExpenseValidation>({});
  return [expenseValidation, setExpenseValidation, handleExpenseValidation];
}
