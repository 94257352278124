import React from 'react';
import { MoreHoriz } from '@mui/icons-material';

import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography } from '@mui/material';
import { MonthlyManufacturer } from 'types/monthlyComparison';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
});

type ManufacturerItemTableProps = {
  manufacturer: MonthlyManufacturer;
};

const ManufacturerItemTable: React.FC<ManufacturerItemTableProps> = ({ manufacturer }) => {
  const classes = styles();
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <IconButton key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography variant="body2">{manufacturer[item.id as keyof MonthlyManufacturer]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default ManufacturerItemTable;
