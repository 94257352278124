import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import { Category } from 'types/category';
import { useCategory } from 'pages/categories/hooks/useCategory';

type CategoryItemModuleProps = {
  category: Category;
};

const CustomListItemButton = styled(ListItemButton)({
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  display: 'grid',
  gridTemplateColumns: '1fr 120px',
  alignItems: 'center',
  '& > .content': {
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

const Img = styled('img')(({ theme }) => ({
  height: 100,
  width: 100,
  borderRadius: 6,
  border: `1px solid ${theme.palette.primary.main}`,
}));

const CategoryItemModule: React.FC<CategoryItemModuleProps> = ({ category }) => {
  const { setSelectedCategory } = useCategory();

  return (
    <CustomListItemButton onClick={() => setSelectedCategory(category)}>
      <div className="content">
        <Typography gutterBottom>Categoria {category.id}</Typography>
        <Typography variant="body2" color="textSecondary">
          {category.nome}
        </Typography>
      </div>
      <Img src={category.link_foto || 'https://cdn1.staticpanvel.com.br/produtos/15/produto-sem-imagem.jpg'} />
    </CustomListItemButton>
  );
};

export default CategoryItemModule;
