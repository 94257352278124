import { Button } from '@mui/material';
import React from 'react';
import history from 'services/history';

const CompatibilitiesHeaderButtons: React.FC = () => {
  return (
    <div>
      <Button onClick={() => history.push('/compatibilities/new')} variant="contained" size="small" color="primary">
        Novo
      </Button>
    </div>
  );
};

export default CompatibilitiesHeaderButtons;
