import { TableTemplate } from 'types/tableTemplate';

export const itemsSoldTableTemplate: TableTemplate[] = [
  {
    id: 'codigo',
    description: 'Código',
    originalId: 'codigo',
    width: 100,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 400,
  },
  {
    id: 'formattedCost',
    description: 'Custo',
    originalId: 'custo',
    width: 200,
    dataType: 'number',
  },
  {
    id: 'qtd',
    description: 'Quantidade',
    originalId: 'qtd',
    width: 200,
    dataType: 'number',
  },
  {
    id: 'formattedPrice',
    description: 'Unitário',
    originalId: 'unitario',
    width: 200,
    dataType: 'number',
  },
  {
    id: 'formattedTotal',
    description: 'Total',
    originalId: 'total',
    width: 200,
    dataType: 'number',
  },
  {
    id: 'formattedMc',
    description: 'M.C',
    originalId: 'mc',
    width: 200,
    dataType: 'number',
  },
  {
    id: 'formattedSpiff',
    description: 'Spiff',
    originalId: 'spiff',
    dataType: 'number',
    width: 200,
  },
];
