import React, {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  FormEvent,
} from 'react';
import { AbcProductSegment } from 'types/abcProduct';
import { format } from 'date-fns';
import { useApp } from 'hooks/app';
import { ptBR } from 'date-fns/locale';
import useTableOrder, { OrderIndexData } from 'hooks/tableOrder';
import { AbcSegmentsFilterParams } from '../CurveAbcSegment';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { moneyFormat } from 'helpers/numberFormat';

export type AbcSegmentTotalProps = {
  total: number;
  coust: number;
  mc: number;
  spiff: number;
  unitary: number;
  quantity: number;
};

type AbcSegmentContextValue = {
  filteredSegments: AbcProductSegment[];
  handleSortSegment(index: string): void;
  moreSegment: AbcProductSegment | null;
  setMoreSegment: Dispatch<SetStateAction<AbcProductSegment | null>>;
  displayMode: 'list' | 'module';
  setDisplayMode: Dispatch<SetStateAction<'list' | 'module'>>;
  searchText: string;
  handleSearch(searchValue: string): void;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(): void;
  loading: boolean;
  orderedIndex: OrderIndexData;
  formattedTotal: AbcSegmentTotalProps;
};

const AbcSegmentContext = createContext<AbcSegmentContextValue>({} as AbcSegmentContextValue);

export function useAbcSegments(): AbcSegmentContextValue {
  const context = useContext(AbcSegmentContext);
  return context;
}

interface SegmentProviderProps {
  filter: AbcSegmentsFilterParams;
  children: React.ReactNode;
}

const SegmentProvider: React.FC<SegmentProviderProps> = ({ children, filter }) => {
  const { h2iApi } = useApp();
  const { windowWidth, isMobile, isOpenedMenu, handleOpenMenu } = useApp();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [segments, setSegments] = useState<AbcProductSegment[]>([]);
  const [moreSegment, setMoreSegment] = useState<AbcProductSegment | null>(null);
  const [filteredSegments, setFilteredSegments] = useState<AbcProductSegment[]>([]);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [formattedTotal, setFormattedTotal] = useState<AbcSegmentTotalProps>({
    total: 0,
    coust: 0,
    mc: 0,
    spiff: 0,
    unitary: 0,
    quantity: 0,
  });

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth, setDisplayMode]);

  useEffect(() => {
    setFilteredSegments(segments);
  }, [segments]);

  const handleSearch = useCallback(() => {
    if (!h2iApi) return;
    setLoading(true);
    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    h2iApi
      .get(`/api/relcurvaabcsegmento`, {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.selectedBranchId || '',
          id_subgrupo: filter.subGroupIds.join(',') || '',
          filtro: searchText,
          tipo_venda: filter.typeSale,
          id_categoria: filter.categoryIds.join(','),
          id_subcategoria: filter.subCategoryIds.join(','),
          pedido_por: filter.madeBy,
          id_segmento: filter.segmentIds.join(','),
          id_unidade: filter.unitIds.join(','),
          id_fabricante: filter.manufacturerIds.join(','),
        },
      })
      .then(response => {
        if (response.data) {
          const _segments: AbcProductSegment[] = response.data;
          setSegments(
            _segments.map(item => {
              item.formattedTotal = moneyFormat(item.total);
              item.formattedCoust = moneyFormat(item.custo);
              item.formattedMc = moneyFormat(item.mc);
              item.formattedSpiff = moneyFormat(item.spiff);
              item.formattedUnitary = moneyFormat(item.unitario);

              return item;
            }),
          );

          const total = _segments.reduce((sum, item) => sum + item.total, 0);
          const coust = _segments.reduce((sum, item) => sum + item.custo, 0);
          const mc = _segments.reduce((sum, item) => sum + item.mc, 0);
          const spiff = _segments.reduce((sum, item) => sum + item.spiff, 0);
          const unitary = _segments.reduce((sum, item) => sum + item.unitario, 0);
          const quantity = _segments.reduce((sum, item) => sum + item.qtd, 0);
          setFormattedTotal({
            total,
            coust,
            mc,
            spiff,
            quantity,
            unitary,
          });
        }
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, h2iApi, searchText]);

  function handleSortSegment(index: string) {
    const filtered = sort(index, filteredSegments);
    setFilteredSegments(filtered);
  }

  function handleSearchSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    if (isOpenedMenu) {
      handleOpenMenu();
    }

    handleSearch();
  }

  function handleSearchInputChange(value: string) {
    setSearchText(value);
  }

  return (
    <AbcSegmentContext.Provider
      value={{
        displayMode,
        setDisplayMode,
        filteredSegments,
        handleSortSegment,
        moreSegment,
        formattedTotal,
        setMoreSegment,
        handleSearch,
        handleSearchInputChange,
        handleSearchSubmit,
        loading,
        orderedIndex,
        searchText,
      }}
    >
      {children}
    </AbcSegmentContext.Provider>
  );
};

export default SegmentProvider;
