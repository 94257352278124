import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { AppBanner } from 'types/appBanner';
import { AppBannerValidation } from './validation/appBannerValidation';
import ImageSelector from 'components/image-selector/ImageSelector';
import ImagePreview from 'components/image-preview/ImagePreview';
import AppBannerImageForm from './AppBannerImageForm';

type AppBannerFormProps = {
  banner: AppBanner;
  handleChange(index: keyof AppBanner, value: any): void;
  validation: AppBannerValidation;
};

const AppBannerForm: React.FC<AppBannerFormProps> = ({ banner, handleChange, validation }) => {
  const [imageSelector, setImageSelector] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<null | string>('');

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      {!!selectedImageSrc && (
        <ImagePreview
          src={selectedImageSrc}
          description="Imagem da mensagem de e-mail"
          onExited={() => setSelectedImageSrc('')}
        />
      )}
      {imageSelector && (
        <ImageSelector
          handleSetImageId={image => handleChange('image', image)}
          onExited={() => setImageSelector(false)}
        />
      )}
      <Grid item xs={12} xl={4} lg={4} md={6}>
        <TextField
          inputRef={inputs.name}
          error={!!validation.name}
          helperText={validation.name}
          label="Nome"
          placeholder="Digite um nome para o banner"
          margin="normal"
          fullWidth
          value={banner.name}
          onChange={e => handleChange('name', e.target.value)}
          autoFocus
        />
        <TextField
          inputRef={inputs.description}
          error={!!validation.description}
          helperText={validation.description}
          label="Descrição"
          placeholder="Digite uma descrição para o banner"
          margin="normal"
          fullWidth
          value={banner.description}
          onChange={e => handleChange('description', e.target.value)}
        />

        <AppBannerImageForm validation={validation} banner={banner} handleChange={handleChange} />
        <button type="submit" style={{ display: 'none' }} />
      </Grid>
    </>
  );
};

export default AppBannerForm;
