import React, { useEffect, useRef, useState } from 'react';
import { FormControlLabel, Grid, Switch, TextField, MenuItem, styled } from '@mui/material';
import { SiteBannerValidation } from './validation/siteBannerValidation';
import ImageSelector from 'components/image-selector/ImageSelector';
import ImagePreview from 'components/image-preview/ImagePreview';
import { SliderBanner } from 'types/sliderBanner';
import ImageUpload from 'components/image-upload/ImageUpload';

const Container = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '400px 1fr',
  marginBottom: 200,
  gap: 40,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: 10,
  },
}));

const CustomFormControlLabel = styled(FormControlLabel)({
  marginTop: 20,
});

const imageContainer = {
  marginTop: 20,
};

type SiteBannerFormProps = {
  banner: SliderBanner;
  handleChange(index: keyof SliderBanner, value: any): void;
  validation: SiteBannerValidation;
};

const SiteBannerForm: React.FC<SiteBannerFormProps> = ({ banner, handleChange, validation }) => {
  const [imageSelector, setImageSelector] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<null | string>('');

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    title: useRef<HTMLInputElement>(null),
    subtitle: useRef<HTMLInputElement>(null),
    link: useRef<HTMLInputElement>(null),
    order: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      {!!selectedImageSrc && (
        <ImagePreview
          src={selectedImageSrc}
          description="Imagem da mensagem de e-mail"
          onExited={() => setSelectedImageSrc('')}
        />
      )}
      {imageSelector && (
        <ImageSelector
          handleSetImageId={image => handleChange('image', image)}
          onExited={() => setImageSelector(false)}
        />
      )}
      <Container>
        <div>
          <ImageUpload
            validationError={validation.image}
            label="Imagem desktop 1200 x 400"
            handleSetImage={image => handleChange('image', image)}
            handleRemoveImage={() => handleChange('image', null)}
            image={banner.image}
            containerClassname={imageContainer}
          />

          <ImageUpload
            validationError={validation.mobile_image}
            label="Imagem mobile 400 x 200"
            handleSetImage={image => handleChange('mobile_image', image)}
            handleRemoveImage={() => handleChange('mobile_image', null)}
            image={banner.mobile_image}
            containerClassname={imageContainer}
          />
        </div>
        <Grid item xs={12} xl={4} lg={4} md={6}>
          <TextField
            inputRef={inputs.name}
            error={!!validation.name}
            helperText={validation.name}
            label="Nome"
            placeholder="Digite um nome para o banner"
            margin="normal"
            fullWidth
            value={banner.name}
            onChange={e => handleChange('name', e.target.value)}
            autoFocus
          />
          <TextField
            inputRef={inputs.title}
            error={!!validation.title}
            helperText={validation.title}
            label="Título"
            placeholder="Digite um título para o banner"
            margin="normal"
            fullWidth
            value={banner.title}
            onChange={e => handleChange('title', e.target.value)}
          />

          <TextField
            inputRef={inputs.subtitle}
            error={!!validation.subtitle}
            helperText={validation.subtitle}
            label="Subtítulo"
            placeholder="Digite um subtítulo para o banner"
            margin="normal"
            fullWidth
            value={banner.subtitle}
            onChange={e => handleChange('subtitle', e.target.value)}
          />

          <TextField
            inputRef={inputs.link}
            error={!!validation.link}
            helperText={validation.link}
            label="Link"
            placeholder="Informe o link"
            margin="normal"
            fullWidth
            value={banner.link}
            onChange={e => handleChange('link', e.target.value)}
          />

          <TextField
            inputRef={inputs.order}
            error={!!validation.order}
            helperText={validation.order}
            label="Ordem"
            placeholder="Informe a ordem"
            margin="normal"
            fullWidth
            value={banner.order}
            onChange={e => handleChange('order', e.target.value)}
          />

          <TextField
            select
            label="Localização"
            placeholder="Escolha a localização do banner"
            margin="normal"
            fullWidth
            value={banner.location}
            onChange={e => handleChange('location', e.target.value)}
          >
            <MenuItem value="home">Home</MenuItem>
            <MenuItem value="products">Produtos</MenuItem>
            <MenuItem value="featured">Novidades</MenuItem>
            <MenuItem value="sale">Ofertas do dia</MenuItem>
          </TextField>

          <CustomFormControlLabel
            label="Ativo"
            control={
              <Switch
                color="primary"
                onChange={event => handleChange('active', event.target.checked)}
                checked={banner.active}
              />
            }
          />

          <button type="submit" style={{ display: 'none' }} />
        </Grid>
      </Container>
    </>
  );
};

export default SiteBannerForm;
