import { Dispatch, SetStateAction, useState } from 'react';

type UseFetchViews = [
  () => void,
  () => void,
  boolean,
  boolean,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  Dispatch<SetStateAction<boolean>>,
  Dispatch<SetStateAction<boolean>>,
];

export function useFetchViews(handleSearch: (searchValue: string) => void, searchText: string): UseFetchViews {
  const [dialogDate, setDialogDate] = useState(false);
  const [excelExport, setExcelExport] = useState(false);
  const [dialogDateDesktop, setDialogDateDesktop] = useState(false);

  function handleCloseDialogDesktop() {
    setDialogDateDesktop(false);

    handleSearch(searchText);
  }

  function handleCloseDialog() {
    setDialogDate(false);

    handleSearch(searchText);
  }

  return [
    handleCloseDialogDesktop,
    handleCloseDialog,
    dialogDate,
    excelExport,
    dialogDateDesktop,
    setExcelExport,
    setDialogDate,
    setDialogDateDesktop,
  ];
}
