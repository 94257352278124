import React from 'react';
import DialogInput from 'components/dialogs/DialogInput';
import { Button } from '@mui/material';
import { Search } from '@mui/icons-material';
import InsideLoading from 'components/loading/InsideLoading';
import { useFetchMoreFilters } from './hooks/useFetchMoreFilters';
import FilterMoreForm from './FilterMoreForm';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  item: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    lineClamp: 1,
    WebkitBoxOrient: 'vertical',
    width: 350,
  },
  formControl: {
    minWidth: 120,
    maxWidth: 400,
  },
  container: {
    height: 500,
    display: 'grid',
    gap: 20,
    flex: 1,
  },
  form: {
    gap: 15,
    display: 'flex',
    flexDirection: 'column',
  },
});

export interface FilterMore {
  madeBy: string;
  unitIds: number[];
  segmentIds: number[];
  categoryIds: number[];
  subCategoryIds: number[];
  subGroupIds: number[];
  manufacturerIds: number[];
}

type FilterMoreProps = {
  responsible?: boolean;
  showMadeBy?: boolean;
  showUnits?: boolean;
  showSegments?: boolean;
  showCategories?: boolean;
  onExited(): void;
  onSearch(): void;
  filter: FilterMore;
  handleChangeFilter(index: keyof FilterMore, value: any): void;
};

const FilterMorePage: React.FC<FilterMoreProps> = ({
  onExited,
  onSearch,
  handleChangeFilter,
  filter,
  showMadeBy = true,
  showUnits = true,
  showSegments = true,
  showCategories = true,
  responsible = true,
}) => {
  const classes = useStyles();
  const { units, segments, subCategories, categories, subGroups, manufacturers, loading } = useFetchMoreFilters();

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <div className={classes.container}>
        {loading ? (
          <InsideLoading />
        ) : (
          <div className={classes.form}>
            <FilterMoreForm
              showUnits={showUnits}
              showSegments={showSegments}
              showCategories={showCategories}
              showMadeBy={showMadeBy}
              manufacturers={manufacturers}
              categories={categories}
              handleChangeFilter={handleChangeFilter}
              units={units}
              subGroups={subGroups}
              segments={segments}
              filter={filter}
              subCategories={subCategories}
            />

            <div>
              <Button
                endIcon={<Search fontSize="small" />}
                type="submit"
                color="primary"
                variant="contained"
                onClick={onSearch}
                fullWidth
              >
                Pesquisar
              </Button>
            </div>
          </div>
        )}
      </div>
    </DialogInput>
  );
};

export default FilterMorePage;
