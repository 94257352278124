import { Theme, Typography } from '@mui/material';
import React from 'react';
import { useFetchViews } from '../hooks/useFetchViews';
import { useAbcProducts } from '../hooks/useAbcProducts';
import PaginationProvider from 'hooks/pagination';
import NoData from 'components/no-data/NoData';
import SellerListTable from './list/table/SellerListTable';
import Pagination from 'components/pagination/Pagination';
import SellerListModule from './list/module/SellerListModule';
import { makeStyles } from '@mui/styles';
import { arraySort } from 'helpers/sort';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  moreDetail: {
    marginTop: 15,
    marginBottom: 10,
    padding: '15px 0',
    position: 'relative',
    transition: 'all ease 0.3s',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  typography: {
    top: -12,
    left: 15,
    cursor: 'pointer',
    padding: '0px 5px',
    position: 'absolute',
    backgroundColor: '#fafafa',
  },
}));

const AbcProductSellers: React.FC = () => {
  const classes = useStyles();
  const { displayMode, searchText, handleSearch, data } = useAbcProducts();
  const { viewSeller, setViewSeller } = useFetchViews(handleSearch, searchText);

  const sorted = arraySort('pedido_por', data.sellers, 'asc');

  return (
    <div className={classes.moreDetail}>
      <Typography onClick={() => setViewSeller(state => !state)} variant="caption" className={classes.typography}>
        + Pedido por
      </Typography>

      {viewSeller && (
        <PaginationProvider>
          <div className={classes.container}>
            {data.sellers.length === 0 ? (
              <NoData message="Nenhum vendedor encontrado." />
            ) : displayMode === 'list' ? (
              <SellerListTable sellers={sorted} />
            ) : (
              <SellerListModule sellers={sorted} />
            )}
            <Pagination count={sorted.length} />
          </div>
        </PaginationProvider>
      )}
    </div>
  );
};

export default AbcProductSellers;
