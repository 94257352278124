import React from 'react';
import { ListItemButton, Typography, styled } from '@mui/material';
import { Invoice } from 'types/invoices';
import { useInvoices } from '../../hooks/useInvoices';

const StyledListItem = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 120,
  justifyContent: 'space-between',
  flexDirection: 'column',
  '& .data': {
    display: 'grid',
    flex: 1,
    width: '100%',
  },
  position: 'relative',
  '& .favored-name': {
    fontWeight: 600,
  },
});

const IsPaidBadge = styled('div')(props => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 112,
  backgroundColor: props.theme.palette.success.light,
  height: 25,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  fontSize: 12,
}));

const Row = styled('div')({
  display: 'grid',
  gridTemplateColumns: '120px 1fr',
});

interface InvoiceModuleItemProps {
  invoice: Invoice;
}

const InvoiceModuleItem: React.FC<InvoiceModuleItemProps> = ({ invoice }) => {
  const { setSelectedInvoice } = useInvoices();

  function handleClick(invoice: Invoice) {
    setSelectedInvoice(invoice);
  }

  return (
    <StyledListItem onClick={() => handleClick(invoice)}>
      {!!invoice.data_pagamento && (
        <IsPaidBadge>
          <span>pgto. {invoice.data_pagamento}</span>
        </IsPaidBadge>
      )}

      <div className="data">
        <Typography variant="caption" gutterBottom>
          {`${invoice.id} - ${invoice.filial} `}
          <Typography variant="caption" color="textSecondary">
            ({invoice.formattedType})
          </Typography>
        </Typography>

        <Typography variant="body1" gutterBottom className="favored-name">
          {invoice.fornecedor}
        </Typography>

        <Row>
          <Typography variant="caption" color="textSecondary">
            Título
          </Typography>
          <Typography>{invoice.titulo}</Typography>
        </Row>

        <Row>
          <Typography variant="caption" color="textSecondary">
            Mês/Ano
          </Typography>
          <Typography>{invoice.mes_ano}</Typography>
        </Row>

        <Row>
          <Typography variant="caption" color="textSecondary">
            Vencimento
          </Typography>
          <Typography>{invoice.vencimento}</Typography>
        </Row>

        <Row>
          <Typography variant="caption" color="textSecondary">
            Valor
          </Typography>
          <Typography>{invoice.formattedValue}</Typography>
        </Row>
      </div>
    </StyledListItem>
  );
};

export default InvoiceModuleItem;
