import { TableTemplate } from 'types/tableTemplate';

export const aiProductTableTemplate: TableTemplate[] = [
  {
    id: 'seller',
    description: 'Vendido por',
    originalId: 'seller',
    width: 200,
  },
  {
    id: 'formattedPrice',
    description: 'Preço',
    originalId: 'price',
    width: 150,
  },
];
