import React from 'react';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import { useJustification } from './hooks/useJustification';

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 20,
});

const RequirementFormStepOne: React.FC = () => {
  const { justification, handleChange } = useJustification();

  return (
    <Content>
      <TextField
        select
        label="Solicitação"
        placeholder="Solicitação"
        fullWidth
        value={justification.tipo}
        onChange={e => handleChange('tipo', e.target.value)}
      >
        <MenuItem value="p">Adesão ou cancelamento de plano de saúde e ou odontológico</MenuItem>
        <MenuItem value="v">Adesão ou cancelamento de VT e ou VR</MenuItem>
        <MenuItem value="b">Adesão ou cancelamento do programa vai de bike</MenuItem>
        <MenuItem value="c">Adesão do caixinha</MenuItem>
        <MenuItem value="h">Adesão do projeto CNH</MenuItem>
        <MenuItem value="a">Adesão do incentivo acadêmico</MenuItem>
      </TextField>

      <Button variant="contained" type="submit">
        Prosseguir
      </Button>
    </Content>
  );
};

export default RequirementFormStepOne;
