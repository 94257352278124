import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { RevenuesFilterProps } from '../Revenues';
import { Revenue } from 'types/revenue';

interface RevenuesContextValue {
  filter: RevenuesFilterProps;
  handleChangeFilter(index: keyof RevenuesFilterProps, value: any): void;
  setIsReportOpen: Dispatch<SetStateAction<boolean>>;
  handleSearch(): void;
  loading: boolean;
  revenues: Revenue[];
  setSelectedRevenue: Dispatch<SetStateAction<Revenue | null>>;
  setIsNewRevenueOpen: Dispatch<SetStateAction<boolean>>;
}

const RevenuesContext = createContext<RevenuesContextValue>({} as RevenuesContextValue);
export const RevenuesConsumer = RevenuesContext.Consumer;
export const RevenuesProvider = RevenuesContext.Provider;

export function useRevenues() {
  return useContext(RevenuesContext);
}
