import React from 'react';
import List from 'components/list/List';
import JustificationItemModule from './JustificationItemModule';
import { Justification } from 'types/justification';

type JustificationListModuleProps = {
  justifications: Justification[];
};

const JustificationListModule: React.FC<JustificationListModuleProps> = ({ justifications }) => {
  return (
    <>
      <List>
        {justifications.map(justification => (
          <JustificationItemModule key={justification.id} justification={justification} />
        ))}
      </List>
    </>
  );
};

export default JustificationListModule;
