import React from 'react';
import { List } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import ItemsSoldItemModule from './ItemsSoldItemModule';
import { ItemsSold } from 'types/reports/itemsSold';
import { makeStyles } from '@mui/styles';

type ItemsSoldListModuleProps = {
  itemsSold: ItemsSold[];
};

const styles = makeStyles({
  list: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: 100,
    flex: 1,
  },
});

const ItemsSoldListModule: React.FC<ItemsSoldListModuleProps> = ({ itemsSold }) => {
  const { rowsPerPage, page } = usePagination();
  const classes = styles();

  return (
    <List className={classes.list}>
      {itemsSold.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(itemSold => (
        <ItemsSoldItemModule key={itemSold.codigo} itemSold={itemSold} />
      ))}
    </List>
  );
};

export default ItemsSoldListModule;
