import React from 'react';
import SidebarItem from '../SidebarItem';
import { Collapse } from '@mui/material';
import { useSidebar } from '../hook/useSidebar';
import { usePermissionRules } from 'hooks/permissionRules';
import SidebarItemExpendable from '../SidebarItemExpendable';
import { AddShoppingCart, Analytics, Grade } from '@mui/icons-material';

const Shopping: React.FC = () => {
  const { checkPermission } = usePermissionRules();
  const { handleCollapseClick, collapsible } = useSidebar();

  return (
    <>
      {checkPermission('purchasing') && (
        <>
          <SidebarItemExpendable
            onClick={() => handleCollapseClick('shopping')}
            icon={<AddShoppingCart />}
            text="Compras"
            opened={collapsible.shopping}
          />
          <Collapse in={collapsible.shopping}>
            {checkPermission('purchasing.order') && (
              <SidebarItem to="/purchasing/dashboard" icon={<Grade />} text="Análise de compras" nested={5} />
            )}

            {checkPermission('purchasing.analysis_product') && (
              <SidebarItem
                to="/purchasing/analysis-product"
                icon={<Analytics />}
                text="Análise de produto"
                nested={5}
              />
            )}
          </Collapse>
        </>
      )}
    </>
  );
};

export default Shopping;
