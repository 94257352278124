import { Dispatch, FormEvent, useCallback, useEffect, useState } from 'react';
import { useApp } from 'hooks/app';
import { usePurchaseOrderReducer } from '../../reducer/reducer';
import { changePurchaseOrderAction, setPurchaseOrderAction } from '../../reducer/actions';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import { PurchaseOrderActions } from '../../reducer/types';
import { PurchaseOrderValidation, usePurchaseOrderValidation } from '../../validation/purchaseOrderValidation';
import { PurchaseOrder } from 'types/purchaseOrder';
import { H2iCustomer } from 'types/h2iCustomer';
import {
  SecondPurchaseOrderValidation,
  useSecondPurchaseOrderValidation,
} from '../steps/second/validation/secondPurchaseOrderValidation';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import { useSelector } from 'store/selector';
import {
  ThirdPurchaseOrderValidation,
  useThirdPurchaseOrderValidation,
} from '../steps/third/validation/thirdPurchaseOrderValidation';

type UseFetchPurchaseOrder = {
  order: PurchaseOrder;
  step: number;
  handleNext(): void;
  validation: PurchaseOrderValidation;
  secondValidation: SecondPurchaseOrderValidation;
  thirdValidation: ThirdPurchaseOrderValidation;
  dispatch: Dispatch<PurchaseOrderActions>;
  disabled: boolean;
  handleBack(): void;
  saving: boolean;
  handleValidation(sentBy: string, e?: FormEvent<HTMLFormElement>): void;
  handleChange(index: keyof PurchaseOrder, value: any);
  handleCustomerSelect(customer: H2iCustomer | null): void;
  reseller: H2iCustomer | null;
  loading: boolean;
};

let timer: NodeJS.Timeout;

export function useFetchPurchaseOrder(id?: string): UseFetchPurchaseOrder {
  const [order, dispatch] = usePurchaseOrderReducer();
  const user = useSelector(state => state.user);
  const [step, setStep] = useState(1);
  const { handleOpen } = useMessaging();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { h2iApi } = useApp();
  const [validation, setValidation, validate] = usePurchaseOrderValidation();
  const [secondValidation, setSecondValidation, secondValidate] = useSecondPurchaseOrderValidation();
  const [thirdValidation, setThirdValidation, thirdValidate] = useThirdPurchaseOrderValidation();
  const [reseller, setReseller] = useState<H2iCustomer | null>(null);

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    if (!id) {
      return;
    }

    setLoading(true);
    h2iApi
      .get('/api/getpedidocompras', {
        params: {
          id,
        },
      })
      .then(async response => {
        const _order: PurchaseOrder = await response.data[0];
        dispatch(setPurchaseOrderAction(_order));
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [h2iApi, id, dispatch]);

  function handleValidationProduct(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    if (!order.itens.length) {
      handleOpen('É necessário adicionar ao menos um produto');
      return;
    }
    handleSubmit('product');
  }

  const handleCustomerSelect = useCallback(
    (customer: H2iCustomer | null) => {
      setReseller(customer);
      dispatch(changePurchaseOrderAction('codigo_fornecedor', customer?.codigo ?? ''));
      dispatch(changePurchaseOrderAction('cliente', customer?.nome ?? ''));
      dispatch(changePurchaseOrderAction('cnpj', customer?.cnpj_cpf ?? ''));
    },
    [dispatch],
  );

  useEffect(() => {
    const documentSanitized = order.cnpj.replace(/\D/g, '');

    clearTimeout(timer);

    if (documentSanitized.length === 11 || documentSanitized.length === 14) {
      timer = setTimeout(() => {
        h2iApi
          ?.get('/api/getclientescadastrados', { params: { cnpj: documentSanitized, id_filial: order.id_filial } })
          .then(response => {
            if (response.data.MESSAGE) {
              setDisabled(true);
              handleOpen('Cliente não encontrado');
              return;
            }

            handleCustomerSelect(response.data[0]);
          })
          .catch(err => {
            console.error(err);
          });
      }, 500);
    } else {
      setDisabled(true);
    }
  }, [h2iApi, handleCustomerSelect, order.id_filial, order.cnpj, handleOpen]);

  function handleCfopChange(value: string) {
    if (value.length === 4) {
      h2iApi
        ?.get('/api/getcfop')
        .then(response => {
          if (!response.data.cfop) {
            handleOpen('CFOP não encontrado');
          }

          const _response = response.data.cfop.filter(item => item.cfop.replace(',', '') === value);

          handleChange('descricao_cfop', _response[0].nome);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  function handleChange(index: keyof PurchaseOrder, value: any) {
    setValidation({});
    setSecondValidation({});
    setThirdValidation({});
    if (index === 'cfop') {
      setDisabled(true);
    }

    dispatch(changePurchaseOrderAction(index, value));

    if (index === 'cfop') {
      handleCfopChange(value);
    }
  }

  function handleSubmit(sentBy: string) {
    if (!h2iApi) {
      return;
    }

    if (sentBy === 'form') {
      setDisabled(false);
      return;
    }

    if (sentBy === 'button') {
      handleValidationProduct();
      return;
    }

    const payload = {
      ...order,
      usuario: user?.name,
      nova_previsao: format(order.date_new_forecast || new Date(), 'P', { locale: ptBR }),
      previsao: format(order.date_forecast || new Date(), 'P', { locale: ptBR }),
      data_previsao: format(order.date_forecast || new Date(), 'P', { locale: ptBR }),
      data_emissao_nfe: format(order.date_issue || new Date(), 'P', { locale: ptBR }),
      data: format(order.date || new Date(), 'P', { locale: ptBR }),
      codigo_filial: order.id_filial,
      itens: order.itens.map(item => ({
        ...item,
        tipo: 'P',
        codigo_filial: order.codigo_filial.toString(),
        id_filial: order.id_filial.toString(),
        codigo_fornecedor: order.codigo_fornecedor,
        previsao: format(order.date_forecast || new Date(), 'P', { locale: ptBR }),
        usuario: user?.name,
      })),
    };

    setSaving(true);

    h2iApi
      .post('/api/savepedidocompras', payload)
      .then(() => {
        setSaving(false);
        history.push('/purchasing/registration');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  function handleValidationStepThird() {
    thirdValidate(order)
      .then(() => setStep(4))
      .catch(err => console.error(err));
  }

  function handleValidationStepTwo() {
    secondValidate(order)
      .then(() => setStep(3))
      .catch(err => console.error(err));
  }

  function handleValidationStepOne() {
    if (!order.id_filial) {
      handleOpen('Selecione a filial');
      return;
    }

    setStep(2);
  }

  function handleValidation(sentBy: string, e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});

    validate(order)
      .then(() => handleSubmit(sentBy))
      .catch(err => {
        console.log(err);
        setDisabled(true);
      });
  }

  function handleNext() {
    if (step !== 4) {
      const validations = {
        1: handleValidationStepOne,
        2: handleValidationStepTwo,
        3: handleValidationStepThird,
      };

      validations[step as keyof typeof validations]();
      return;
    }

    setStep(step => step + 1);
  }

  function handleBack() {
    if (step === 1) {
      history.push('/purchasing/registration');
      return;
    }

    setStep(step => step - 1);
  }

  return {
    saving,
    handleChange,
    order,
    disabled,
    validation,
    dispatch,
    handleValidation,
    handleBack,
    secondValidation,
    thirdValidation,
    step,
    handleNext,
    handleCustomerSelect,
    reseller,
    loading,
  };
}
