import React, { useState } from 'react';
import { Button } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSelector } from 'store/selector';
import { Branch } from 'types/branch';
import { ItemsSold } from 'types/reports/itemsSold';
import StockBalanceReportActions from '../stock/StockBalanceReportActions';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { usePermissionRules } from 'hooks/permissionRules';
import ItemsSoldExport from './ItemsSoldExport';
import { makeStyles } from '@mui/styles';

type ItemsSoldReportDataProps = {
  data: ItemsSold[];
  branch?: Branch;
  onExited(): void;
};

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '70px 1fr 50px 60px 80px 80px 60px 50px',
    marginBottom: 1,
    paddingBottom: 1,
  },
  headerInfo: {
    marginTop: 10,
  },
  rowP: {
    fontSize: '7pt',
    textAlign: 'end',
  },
  rowPL: {
    fontSize: '7pt',
    textAlign: 'start',
  },
  rowPC: {
    fontSize: '7pt',
    textAlign: 'center',
  },
});

const ItemsSoldReportData: React.FC<ItemsSoldReportDataProps> = ({ data, branch, onExited }) => {
  const classes = useStyles();
  const { isMobile } = useApp();
  const user = useSelector(state => state.user);
  const [excelExport, setExcelExport] = useState(false);
  const { checkPermission } = usePermissionRules();

  return (
    <Dialog
      fullScreen
      title="Itens vendidos"
      onExited={onExited}
      ComponentActions={
        <>
          {!isMobile && checkPermission('general.excel_export_button') && (
            <Button
              disabled={data.length === 0}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setExcelExport(true)}
            >
              Exportar excel
            </Button>
          )}
          <StockBalanceReportActions />
        </>
      }
    >
      {excelExport && <ItemsSoldExport data={data} onExited={() => setExcelExport(false)} />}

      <div className="page">
        <div className="header">
          <div>
            <h6>Relatório de itens vendidos</h6>
            {branch?.id === undefined ? <h6>Todas as filiais</h6> : <h6>Filial {branch?.nome}</h6>}
          </div>
          <div>
            <p>{user?.name}</p>
            <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
          </div>
        </div>
        <div className={`${classes.row} table-header`}>
          <p className={classes.rowPL}>Código</p>
          <p className={classes.rowPL}>Descrição</p>
          <p className={classes.rowPC}>Qtd</p>
          <p className={classes.rowP}>Custo</p>
          <p className={classes.rowP}>Total</p>
          <p className={classes.rowP}>Unitário</p>
          <p className={classes.rowP}>Spiff</p>
          <p className={classes.rowP}>Mc</p>
        </div>
        {data.map(item => (
          <div key={item.codigo} className={classes.row}>
            <p className={classes.rowPL}>{item.codigo}</p>
            <p className={classes.rowPL}>{item.produto}</p>
            <p className={classes.rowPC}>{item.qtd}</p>
            <p className={classes.rowP}>{moneyFormat(item.custo)}</p>
            <p className={classes.rowP}>{moneyFormat(item.total)}</p>
            <p className={classes.rowP}>{moneyFormat(item.unitario)}</p>
            <p className={classes.rowP}>{numberFormat(item.spiff)}</p>
            <p className={classes.rowP}>{numberFormat(item.mc)}</p>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default ItemsSoldReportData;
