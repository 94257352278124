import { useCallback, useState } from 'react';
import { api } from 'services/api';
import { ManagedSale, ManagedSaleOnList } from '../types/managedSale';
import { RentalQueryParams } from '../ManagedSales';
import { useManagedSaleFormatter } from './useManagedSaleFormatter';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Paginated } from 'types/paginated';

const queryParamsInitialValue: RentalQueryParams = {
  initial_date: startOfMonth(new Date()),
  final_date: endOfMonth(new Date()),
  page: 0,
  rows: 20,
  active: '1',
  is_paid: '2',
  term: '',
  financial_data: '',
};

type UseFetchManagedSalesOptions = {
  type: 'managed_sale' | 'rental';
};

export function useFetchManagedSales(option: UseFetchManagedSalesOptions) {
  const [queryParams, setQueryParams] = useState<RentalQueryParams>(queryParamsInitialValue);
  const [loading, setLoading] = useState(false);
  const [rentals, setRentals] = useState<ManagedSaleOnList[]>([]);
  const formatter = useManagedSaleFormatter();
  const [total, setTotal] = useState(0);
  const [error, setError] = useState('');

  const fetch = useCallback(
    (queryParams: RentalQueryParams) => {
      setLoading(true);

      api
        .get<Paginated<ManagedSale[]>>('/rentals', {
          params: { ...queryParams, type: option.type },
        })
        .then(response => {
          setRentals(response.data.data.map(item => formatter(item)));
          setTotal(response.data.total);
        })
        .catch(error => {
          console.error(error);
          setError('Não foi possível recuperar as locações');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [formatter, option],
  );

  return {
    fetch,
    loading,
    total,
    queryParams,
    setQueryParams,
    rentals,
    setRentals,
    error,
  };
}
