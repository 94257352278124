import React from 'react';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { Typography, Theme } from '@mui/material';
import { StockBalanceTotalProps } from './stock-balance/list/hooks/useStockBalance';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    padding: 15,
    marginTop: 15,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    position: 'relative',
    border: '2px solid #eee',
    borderRadius: '8px 8px 0 0',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  total: {
    gap: 15,
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr 1fr 1fr 0.6fr)',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  typography: {
    top: -10,
    left: 15,
    position: 'absolute',
    backgroundColor: '#fafafa',
  },
  column: {
    gap: 15,
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .paid': {
        color: '#28a745',
        fontWeight: 'bold',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
  },
  row: {
    height: 35,
    marginBottom: 7,
  },
  containerLoading: {
    marginTop: 10,
    backgroundColor: '#fff',
    flex: 1,
  },
}));

interface ReportStockBalanceTotalProps {
  formattedTotal: StockBalanceTotalProps;
  loading: boolean;
}

const StockBalanceTotal: React.FC<ReportStockBalanceTotalProps> = ({ formattedTotal, loading }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="caption" className={classes.typography}>
        Totalizadores
      </Typography>
      {loading ? (
        <div className={classes.containerLoading}>
          <div className={`animated-background ${classes.row}`} />
          <div className={`animated-background ${classes.row}`} />
        </div>
      ) : (
        <div className={classes.total}>
          <div className={classes.column}>
            <div>
              <span className="label">Custo total</span>
              <span className="paid">{moneyFormat(formattedTotal.coustTotal)}</span>
            </div>
            <div>
              <span className="label">Custo Sem Movimentação</span>
              <span className="paid">{moneyFormat(formattedTotal.coustStoppedStock)}</span>
            </div>
          </div>
          <div className={classes.column}>
            <div>
              <span className="label">Valor Reserva</span>
              <span className="paid">{moneyFormat(formattedTotal.reserve)}</span>
            </div>
            <div>
              <span className="label">Pedidos em transferência</span>
              <span className="paid">{moneyFormat(formattedTotal.order)}</span>
            </div>
          </div>
          <div className={classes.column}>
            <div>
              <span className="label">Estoque</span>
              <span className="paid">{formattedTotal.stock}</span>
            </div>
            <div>
              <span className="label">Estoque Sem Movimentação</span>
              <span className="paid">{formattedTotal.stoppedStock}</span>
            </div>
          </div>
          <div className={classes.column}>
            <div>
              <span className="label">Forecast</span>
              <span className="paid">{formattedTotal.forecast}</span>
            </div>
            <div>
              <span className="label">IGE</span>
              <span className="paid">{numberFormat(formattedTotal.index)}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StockBalanceTotal;
