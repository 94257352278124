import {
  AbcProduct,
  AbcProductBranch,
  AbcProductCategory,
  AbcProductCustomer,
  AbcProductManufacturer,
  AbcProductSegment,
  AbcProductSeller,
  AbcProductSubgroup,
  AbcProductUnity,
} from 'types/abcProduct';
import { AbcProductActions, AbcProductDates } from './types';

export function setProducts(
  products: AbcProduct[],
  productsToCompare: AbcProduct[],
  dates: AbcProductDates,
): AbcProductActions {
  return {
    type: 'SET_PRODUCTS',
    products,
    productsToCompare,
    dates,
  };
}

export function setSellers(
  sellers: AbcProductSeller[],
  sellersToCompare: AbcProductSeller[],
  dates: AbcProductDates,
): AbcProductActions {
  return {
    type: 'SET_SELLERS',
    sellers,
    sellersToCompare,
    dates,
  };
}

export function setCustomers(
  customers: AbcProductCustomer[],
  customersToCompare: AbcProductCustomer[],
  dates: AbcProductDates,
): AbcProductActions {
  return {
    type: 'SET_CUSTOMERS',
    customers,
    customersToCompare,
    dates,
  };
}

export function setBranches(
  branches: AbcProductBranch[],
  branchesToCompare: AbcProductBranch[],
  dates: AbcProductDates,
): AbcProductActions {
  return {
    type: 'SET_BRANCHES',
    branches,
    branchesToCompare,
    dates,
  };
}

export function setSegments(
  segments: AbcProductSegment[],
  segmentsToCompare: AbcProductSegment[],
  dates: AbcProductDates,
): AbcProductActions {
  return {
    type: 'SET_SEGMENTS',
    segments,
    segmentsToCompare,
    dates,
  };
}

export function setCategories(
  categories: AbcProductCategory[],
  categoriesToCompare: AbcProductCategory[],
  dates: AbcProductDates,
): AbcProductActions {
  return {
    type: 'SET_CATEGORIES',
    categories,
    categoriesToCompare,
    dates,
  };
}

export function setUnits(
  units: AbcProductUnity[],
  unitsToCompare: AbcProductUnity[],
  dates: AbcProductDates,
): AbcProductActions {
  return {
    type: 'SET_UNITS',
    units,
    unitsToCompare,
    dates,
  };
}

export function setManufactures(
  manufactures: AbcProductManufacturer[],
  manufacturesToCompare: AbcProductManufacturer[],
  dates: AbcProductDates,
): AbcProductActions {
  return {
    type: 'SET_MANUFACTURES',
    manufactures,
    manufacturesToCompare,
    dates,
  };
}

export function setSubGroups(
  subgroups: AbcProductSubgroup[],
  subgroupsToCompare: AbcProductSubgroup[],
  dates: AbcProductDates,
): AbcProductActions {
  return {
    type: 'SET_SUBGROUPS',
    subgroupsToCompare,
    dates,
    subgroups,
  };
}
