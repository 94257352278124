import React, { useState, useCallback, FormEvent, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import ReportLoading from 'components/loading/ReportLoading';
import FilterMorePage, { FilterMore } from 'components/filter-more/FilterMore';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { useSelector } from 'store/selector';
import AbcCustomerFilterBox from './AbcCustomerFilterBox';
import { useApp } from 'hooks/app';
import { ptBR } from 'date-fns/locale';
import CustomerListTable from './list/table/CustomerListTable';
import useTableOrder from 'hooks/tableOrder';
import CustomerListModule from './list/module/CustomerListModule';
import { Typography, styled } from '@mui/material';
import AbcCustomerDateFilter from './AbcCustomerDateFilter';
import { AbcCustomerWithoutSale } from 'types/abcCustomer';
import CustomerWithoutSaleExport from './CustomerWithoutSaleExport';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const MoreDetail = styled('div')(props => ({
  marginTop: 15,
  marginBottom: 10,
  padding: '15px 0',
  position: 'relative',
  transition: 'all ease 0.3s',
  border: `2px solid ${props.theme.palette.primary.main}`,
}));

const Description = styled(Typography)({
  top: -12,
  left: 15,
  cursor: 'pointer',
  padding: '0px 5px',
  position: 'absolute',
  backgroundColor: '#fafafa',
});

export interface AbcCustomerWithoutSaleFilterParams extends FilterMore {
  sale_initialDate: Date | null;
  sale_finalDate: Date | null;
  withoutSale_initialDate: Date | null;
  withoutSale_finalDate: Date | null;
  selectedBranchId: number;
  productId: number;
  typeSale: string;
}

const CustomerWithoutSale: React.FC = () => {
  const { h2iApi, isOpenedMenu, handleOpenMenu, isMobile, windowWidth } = useApp();
  const user = useSelector(state => state.user);
  const [orderedIndex, sort] = useTableOrder();
  const [searchText, setSearchText] = useState('');
  const [productText, setProductText] = useState('');
  const [loading, setLoading] = useState(false);
  const [excelExport, setExcelExport] = useState(false);
  const [dialogDate, setDialogDate] = useState(false);
  const [viewCustomer, setViewCustomer] = useState(false);
  const [dialogDateDesktop, setDialogDateDesktop] = useState(false);
  const withoutSale_initialDate = startOfDay(subDays(new Date(), 14));
  const withoutSale_finalDate = endOfDay(subDays(new Date(), 7));
  const sale_initialDate = startOfDay(subDays(new Date(), 8));
  const sale_finalDate = endOfDay(new Date());
  const [customers, setCustomers] = useState<AbcCustomerWithoutSale[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<AbcCustomerWithoutSale[]>([]);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(isMobile || windowWidth < 930 ? 'module' : 'list');
  const [filter, setFilter] = useState<AbcCustomerWithoutSaleFilterParams>({
    withoutSale_initialDate,
    withoutSale_finalDate,
    sale_initialDate,
    sale_finalDate,
    selectedBranchId: user
      ? user.branchList?.length !== 0
        ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0])
        : 999
      : 999,
    productId: 0,
    typeSale: 'T',
    categoryIds: [],
    subCategoryIds: [],
    madeBy: '',
    segmentIds: [],
    unitIds: [],
    subGroupIds: [],
    manufacturerIds: [],
  });

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  useEffect(() => {
    setFilteredCustomers(customers);
  }, [customers]);

  const handleSearch = useCallback(() => {
    setLoading(true);
    const formattedInitialDateSale = !filter.sale_initialDate
      ? ''
      : format(filter.sale_initialDate, 'P', { locale: ptBR });
    const formattedFinalDateSale = !filter.sale_finalDate ? '' : format(filter.sale_finalDate, 'P', { locale: ptBR });
    const formattedInitialDateWithoutSale = !filter.withoutSale_initialDate
      ? ''
      : format(filter.withoutSale_initialDate, 'P', { locale: ptBR });
    const formattedFinalDateWithoutSale = !filter.withoutSale_finalDate
      ? ''
      : format(filter.withoutSale_finalDate, 'P', { locale: ptBR });

    async function loadCustomersWithoutSales() {
      if (!h2iApi) return;

      await h2iApi
        .get('/api/relclientesemvendas', {
          params: {
            dt_ini_com_vendas: formattedInitialDateSale,
            dt_fim_com_vendas: formattedFinalDateSale,
            dt_ini_sem_vendas: formattedInitialDateWithoutSale,
            dt_fim_sem_vendas: formattedFinalDateWithoutSale,
            id_filial: filter.selectedBranchId || '',
            id_subgrupo: filter.subGroupIds.join(',') || '',
            filtro: searchText,
            tipo_venda: filter.typeSale,
            id_categoria: filter.categoryIds.join(','),
            id_subcategoria: filter.subCategoryIds.join(','),
            pedido_por: filter.madeBy,
            id_segmento: filter.segmentIds.join(','),
            id_unidade: filter.unitIds.join(','),
            id_fabricante: filter.manufacturerIds.join(','),
            produto: productText,
          },
        })
        .then(response => setCustomers(response.data))
        .catch(err => console.error(err));
    }

    Promise.all([loadCustomersWithoutSales()])
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, h2iApi, searchText, productText]);

  function handleChangeFilter(index: keyof AbcCustomerWithoutSaleFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSearchInputChange(index: 'text' | 'product', value: string) {
    if (index === 'text') {
      setSearchText(value);
    } else {
      setProductText(value);
    }
  }

  function handleCloseDialogDesktop() {
    setDialogDateDesktop(false);

    handleSearch();
  }

  function handleCloseDialog() {
    setDialogDate(false);

    handleSearch();
  }

  function handleSearchSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    if (isOpenedMenu) {
      handleOpenMenu();
    }

    handleSearch();
  }

  function handleViewCustomer() {
    setViewCustomer(!viewCustomer);
  }

  function handleSortCustomer(index: string) {
    const filtered = sort(index, filteredCustomers);
    setFilteredCustomers(filtered);
  }

  return (
    <>
      {dialogDate && (
        <AbcCustomerDateFilter
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          onSearch={handleCloseDialog}
          onExited={() => setDialogDate(false)}
        />
      )}

      {dialogDateDesktop && (
        <FilterMorePage
          responsible={false}
          onSearch={handleCloseDialogDesktop}
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          onExited={() => setDialogDateDesktop(false)}
        />
      )}

      <Appbar title="Curva Abc de Cliente" />

      <AbcCustomerFilterBox
        productText={productText}
        filter={filter}
        handleOpenExcel={() => setExcelExport(true)}
        handleOpenDialog={() => setDialogDate(true)}
        handleOpenDialogDesktop={() => setDialogDateDesktop(true)}
        searchText={searchText}
        handleSearchInputChange={handleSearchInputChange}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
      />

      {excelExport && <CustomerWithoutSaleExport data={filteredCustomers} onExited={() => setExcelExport(false)} />}

      {loading ? (
        <ReportLoading displayMode={displayMode} />
      ) : filteredCustomers.length === 0 ? (
        <NoData message="Nada para mostrar" />
      ) : (
        <div>
          <MoreDetail>
            <Description onClick={handleViewCustomer} variant="caption">
              + Cliente sem venda
            </Description>
            {viewCustomer && (
              <PaginationProvider>
                <Container>
                  {filteredCustomers.length === 0 ? (
                    <NoData message="Nenhum cliente encontrado." />
                  ) : displayMode === 'list' ? (
                    <CustomerListTable
                      customers={filteredCustomers}
                      handleSort={handleSortCustomer}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    <CustomerListModule customers={filteredCustomers} />
                  )}
                  <Pagination count={filteredCustomers.length} />
                </Container>
              </PaginationProvider>
            )}
          </MoreDetail>
        </div>
      )}
    </>
  );
};

export default CustomerWithoutSale;
