import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useApp } from './app';

export type DisplayModeOptions = 'list' | 'module';

type UseDisplayMode = [DisplayModeOptions, Dispatch<SetStateAction<DisplayModeOptions>>];

export function useDisplayMode(): UseDisplayMode {
  const { isMobile, windowWidth } = useApp();
  const [displayMode, setDisplayMode] = useState<DisplayModeOptions>(isMobile || windowWidth < 930 ? 'module' : 'list');

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  return [displayMode, setDisplayMode];
}
