import DefaultLayout from 'components/layout/DefaultLayout';
import { useAuth } from 'hooks/auth';
import { usePermissionRules } from 'hooks/permissionRules';
import React, { ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import history from 'services/history';
import { useSelector } from 'store/selector';

type Rule = string;

interface ProtectedRouteProps {
  element: ReactNode;
  rule?: Rule;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, rule }) => {
  const { checkAuth } = useAuth();
  const user = useSelector(state => state.user);
  const { checkPermission } = usePermissionRules();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (rule && !checkPermission(rule)) {
      history.push('/');
    }
  }, [rule, user, checkPermission]);

  return <>{checkAuth() ? <DefaultLayout>{element}</DefaultLayout> : <Navigate to="/login" />}</>;
};

export default ProtectedRoute;
