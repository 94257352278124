import React from 'react';
import { IconButton } from '@mui/material';
import { Done } from '@mui/icons-material';

interface AgentsApprovalActionsProps {
  handleValidation(): void;
}

const AgentsApprovalActions: React.FC<AgentsApprovalActionsProps> = ({ handleValidation }) => {
  return (
    <IconButton color="inherit" onClick={handleValidation}>
      <Done />
    </IconButton>
  );
};

export default AgentsApprovalActions;
