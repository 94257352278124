import React from 'react';
import SegmentMoreMenu from '../SegmentMoreMenu';
import SegmentItemTable from './SegmentItemTable';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import { AbcProductSegment } from 'types/abcProduct';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { useAbcProducts } from '../../../hooks/useAbcProducts';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography, Grid, styled } from '@mui/material';
import { TableTemplate } from 'types/tableTemplate';

const header = {
  backgroundColor: '#eee',
};

const headerSubgroup = {
  backgroundColor: '#d9f8e5',
};

const CustomTableRow = styled('div')({
  '&.isSubgroup': {
    '& > div:nth-child(even)': {
      backgroundColor: '#eee',
    },
  },
});

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
  '&.headerItemNumeric': {
    justifyContent: 'flex-end',
  },
});

type SegmentListTableProps = {
  segments: AbcProductSegment[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  isSubgroup: boolean;
  useMinHeight?: boolean;
  tableTemplate: TableTemplate[];
};

const SegmentListTable: React.FC<SegmentListTableProps> = ({
  segments,
  handleSort,
  orderedIndex,
  isSubgroup,
  tableTemplate,
  useMinHeight = true,
}) => {
  const { moreSegment } = useAbcProducts();
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={tableTemplate}>
          <TableContent useMinHeight={useMinHeight}>
            <TableHeader className={`${isSubgroup ? header : headerSubgroup}`}>
              {tableTemplate.map(item => (
                <HeaderItem
                  className={item.dataType === 'number' ? 'headerItemNumeric' : ''}
                  key={item.id}
                  onClick={() => handleSort(item.originalId)}
                >
                  <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </HeaderItem>
              ))}
            </TableHeader>
            <TableBody>
              <CustomTableRow className={isSubgroup ? 'isSubgroup' : undefined}>
                {segments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(segment => (
                  <>
                    <TableRow style={{ minHeight: 10, padding: 0 }} key={segment.cod}>
                      <SegmentItemTable isSubgroup={isSubgroup} segment={segment} />
                    </TableRow>

                    {moreSegment?.cod === segment?.cod && <SegmentMoreMenu key={segment.cod} segmentId={segment.cod} />}
                  </>
                ))}
              </CustomTableRow>
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default SegmentListTable;
