import { createContext, useContext } from 'react';
import { Course } from 'types/course';

type CoursesContextValue = {
  selectedCourse: Course | null;
  setSelectedCourse(partner: Course | null): void;
};

const CoursesContext = createContext<CoursesContextValue>({} as CoursesContextValue);
export const CoursesProvider = CoursesContext.Provider;

export function useCourses(): CoursesContextValue {
  const context = useContext(CoursesContext);
  return context;
}
