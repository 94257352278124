import { TableTemplate } from 'types/tableTemplate';

export const resellersTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'formattedContacted',
    description: 'CONTATADO',
    originalId: 'contacted',
    width: 100,
  },
  {
    id: 'formattedMustContact',
    description: 'CONTATAR',
    originalId: 'must_contact',
    width: 100,
  },
  {
    id: 'seller_name',
    description: 'VENDEDOR',
    originalId: 'seller_name',
    width: 200,
  },
  {
    id: 'formattedApprovedAt',
    description: 'APROVADO EM',
    originalId: 'approved_at',
    width: 100,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'created_at',
    width: 150,
    notSearchable: true,
  },
  {
    id: 'formattedCnpj',
    description: 'CNPJ',
    originalId: 'document',
    width: 170,
  },
  {
    id: 'company_name',
    description: 'RAZÃO SOCIAL',
    originalId: 'company_name',
    width: 350,
  },
  {
    id: 'company_trade_name',
    description: 'FANTASIA',
    originalId: 'company_trade_name',
    width: 350,
  },
  {
    id: 'email',
    description: 'E-MAIL',
    originalId: 'email',
    width: 350,
  },
  {
    id: 'phone',
    description: 'TELEFONE',
    originalId: 'phone',
    width: 150,
  },
  {
    id: 'street',
    description: 'LOGRADOURO',
    originalId: 'street',
    width: 300,
  },
  {
    id: 'number',
    description: 'NÚMERO',
    originalId: 'number',
    width: 100,
  },
  {
    id: 'neighborhood',
    description: 'BAIRRO',
    originalId: 'neighborhood',
    width: 150,
  },
  {
    id: 'complement',
    description: 'COMPLEMENTO',
    originalId: 'complement',
    width: 150,
  },
  {
    id: 'city',
    description: 'CIDADE',
    originalId: 'city',
    width: 150,
  },
  {
    id: 'state',
    description: 'ESTADO',
    originalId: 'state',
    width: 150,
  },
  {
    id: 'formattedUpdateRequiredAt',
    description: 'SOLICITAÇÃO DE ATUALIZAÇÃO EM',
    originalId: 'update_required_at',
    width: 150,
    notSearchable: true,
  },
];
