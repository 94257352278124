import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import Dialog, { DialogConsumer } from 'components/dialogs/Dialog';
import { QueryParams } from './ExportEmail';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { useExportEmail } from './hooks/useExportEmail';
import SelectBranch from 'components/select-branches/SelectBranch';
import { makeStyles } from '@mui/styles';

type ExportEmailSearchProps = {
  queryParams: QueryParams;
  handleQueryParamsChange(index: keyof QueryParams, value: any): void;
  onExited(): void;
};

const useStyles = makeStyles({
  filterBox: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
  },
});

const ExportEmailSearch: React.FC<ExportEmailSearchProps> = ({ onExited, handleQueryParamsChange, queryParams }) => {
  const classes = useStyles();
  const { segments } = useExportEmail();

  return (
    <Dialog maxWidth="sm" onExited={onExited} title="Buscar" height="60vh">
      <DialogConsumer>
        {() => (
          <div className={classes.filterBox}>
            <SelectBranch
              branch_id={queryParams.id_filial}
              handleChange={e => handleQueryParamsChange('id_filial', e)}
            />

            <TextField
              select
              fullWidth
              autoFocus
              value={queryParams.id_atividade}
              label="Selecione a atividade"
              onChange={e => handleQueryParamsChange('id_atividade', e.target.value)}
            >
              {segments.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.nome}
                </MenuItem>
              ))}
              <MenuItem key={0} value="">
                Todas as atividades
              </MenuItem>
            </TextField>
            <TextField
              select
              fullWidth
              autoFocus
              value={queryParams.tipo}
              onChange={e => handleQueryParamsChange('tipo', e.target.value)}
              label="Periodo de vendas"
            >
              <MenuItem key="V" value="V">
                Periodo com vendas
              </MenuItem>
              <MenuItem key="S" value="S">
                Periodo sem vendas
              </MenuItem>
              <MenuItem key="N" value="N">
                Todos os períodos
              </MenuItem>
            </TextField>
            <KeyboardDatePicker
              label="Data inicial"
              value={queryParams.data_ini}
              onChange={date => handleQueryParamsChange('data_ini', date)}
              textFieldProps={{ fullWidth: true }}
            />
            <KeyboardDatePicker
              label="Data Final"
              value={queryParams.data_fim}
              onChange={date => handleQueryParamsChange('data_fim', date)}
              textFieldProps={{ fullWidth: true }}
            />
          </div>
        )}
      </DialogConsumer>
    </Dialog>
  );
};

export default ExportEmailSearch;
