import { Dispatch, SetStateAction, useState } from 'react';
import { Compatibility } from 'types/compatibility';
import * as yup from 'yup';

export type CompatibilityValidation = {
  document_number?: string;
  'address.postal_code'?: string;
  'address.street'?: string;
  'address.street_number'?: string;
  'address.neighborhood'?: string;
  'address.city'?: string;
  'address.state'?: string;
  value?: string;
  description?: string;
  name?: string;
};

type UseCompatibilityValidation = [
  CompatibilityValidation,
  Dispatch<SetStateAction<CompatibilityValidation>>,
  (compatibility: Compatibility) => Promise<void>,
];

export function useCompatibilityValidation(): UseCompatibilityValidation {
  async function handleValidation(compatibility: Compatibility) {
    const schema = yup.object().shape({
      description: yup.string().required('A descrição é obrigatória'),
      products: yup.array().min(1).required('os produtos são obrigatórios'),
    });

    try {
      await schema.validate(compatibility);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<CompatibilityValidation>({});
  return [validation, setValidation, handleValidation];
}
