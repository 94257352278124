import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { AppropriationDate as AppropriationDateInterface } from 'types/appropriationDate';

type AppropriationDateValue = {
  selectedDate: AppropriationDateInterface | null;
  setSelectedDate: Dispatch<SetStateAction<AppropriationDateInterface | null>>;
};

const AppropriationDate = createContext<AppropriationDateValue>({} as AppropriationDateValue);
export const AppropriationDateProvider = AppropriationDate.Provider;

export function useAppropriationDate(): AppropriationDateValue {
  const context = useContext(AppropriationDate);
  return context;
}
