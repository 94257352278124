import React from 'react';
import { IconButton } from '@mui/material';
import { Done } from '@mui/icons-material';

interface CreditAnalysisQuestionActionsProps {
  handleValidation(): void;
}

const CreditAnalysisQuestionActions: React.FC<CreditAnalysisQuestionActionsProps> = ({ handleValidation }) => {
  return (
    <IconButton color="inherit" onClick={handleValidation}>
      <Done />
    </IconButton>
  );
};

export default CreditAnalysisQuestionActions;
