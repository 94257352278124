import { Dispatch, SetStateAction, useState } from 'react';
import { Partner } from 'types/partner';
import * as yup from 'yup';

export type PartnerValidation = {
  name?: string;
  image?: string;
};

type UsePartnerValidation = [
  PartnerValidation,
  Dispatch<SetStateAction<PartnerValidation>>,
  (partner: Partner) => Promise<void>,
];

export function usePartnerValidation(): UsePartnerValidation {
  async function handleValidation(partner: Partner) {
    const schema = yup.object().shape({
      image: yup.object().required('O logo do parceiro é obrigatório'),
      name: yup.string().required('O nome do parceiro é obrigatória'),
    });

    try {
      await schema.validate(partner);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<PartnerValidation>({});
  return [validation, setValidation, handleValidation];
}
