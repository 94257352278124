import { Print } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import FilterActions from 'components/filter-box/FilterActions';
import React, { Dispatch, SetStateAction } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { Sales } from 'types/reports/sales';

interface SalesReportActionsProps {
  filteredSales: Sales[];
  handleDownload(): void;
  isDownloading: boolean;
  setShownReport: Dispatch<SetStateAction<boolean>>;
  setDialogDate: Dispatch<SetStateAction<boolean>>;
}

const SalesReportActions: React.FC<SalesReportActionsProps> = ({
  filteredSales,
  handleDownload,
  isDownloading,
  setDialogDate,
  setShownReport,
}) => {
  return (
    <>
      <IconButton disabled={!filteredSales.length} color="inherit" size="small" onClick={() => setShownReport(true)}>
        <Print />
      </IconButton>
      <IconButton
        disabled={!filteredSales.length || isDownloading}
        color="inherit"
        size="small"
        onClick={handleDownload}
      >
        {isDownloading ? <CircularProgress color="secondary" size={14} /> : <FaFileExcel />}
      </IconButton>
      <FilterActions handleOpenDialog={() => setDialogDate(true)} />
    </>
  );
};

export default SalesReportActions;
