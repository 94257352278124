import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import history from 'services/history';
import { SliderBanner } from 'types/sliderBanner';

type AppBannerItemModuleProps = {
  banner: SliderBanner;
};

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '80%',
    alignItems: 'center',
  },
});

const AppBannerItemModule: React.FC<AppBannerItemModuleProps> = ({ banner }) => {
  return (
    <CustomListItemButton onClick={() => history.push(`/site/slider-banner/${banner.id}`)}>
      <Typography variant="caption" color="primary">
        Banner {banner.id}
      </Typography>
      <Typography variant="h6">{banner.name}</Typography>
    </CustomListItemButton>
  );
};

export default AppBannerItemModule;
