import React, { useState } from 'react';
import { Button, Typography, styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import AiArt from './AiArt';
import AiArtSelector from './selector/AiArtSelector';

const Container = styled('div')({
  gap: 10,
  flex: 1,
  display: 'flex',
  maxWidth: 500,
  margin: '0 auto',
  textAlign: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const AiArtPage: React.FC = () => {
  const [newArt, setNewArt] = useState(false);
  const [modifyArt, setModifyArt] = useState(false);

  return (
    <>
      {newArt ? (
        <AiArt />
      ) : (
        <Container>
          {modifyArt && <AiArtSelector onExited={() => setModifyArt(false)} />}
          <Appbar title="Artes gráficas" />
          <Button variant="outlined" onClick={() => setNewArt(true)}>
            criar uma nova arte
          </Button>
          <Typography>ou</Typography>
          <Button variant="contained" onClick={() => setModifyArt(true)}>
            usar uma arte já existente
          </Button>
        </Container>
      )}
    </>
  );
};

export default AiArtPage;
