import { Cancel } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { SerialNumbers } from 'pages/material-residues/waste/types/waste';
import React from 'react';

interface SerialNumberItemTableProps {
  onDelete?(): void;
  serialNumber: SerialNumbers;
}

const SerialNumberItemTable: React.FC<SerialNumberItemTableProps> = ({ onDelete, serialNumber }) => {
  return (
    <>
      <Typography variant="caption" fontSize={16} fontWeight={500}>
        {serialNumber.numero_serie}
      </Typography>
      {onDelete && (
        <Tooltip title="Excluir">
          <IconButton color="error" style={{ alignSelf: 'end' }} onClick={onDelete}>
            <Cancel />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default SerialNumberItemTable;
