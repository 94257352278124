import React from 'react';
import { AbcProductBranch } from 'types/abcProduct';
import { Typography, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type BranchItemModuleProps = {
  branch: AbcProductBranch;
};

const BranchItemModule: React.FC<BranchItemModuleProps> = ({ branch }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem}>
      <Typography variant="caption" color="primary">
        {branch.filial}
      </Typography>
      <Typography variant="caption" color="primary">
        Valor total - {branch.formattedTotal}
      </Typography>
      <Typography variant="caption" color="primary">
        Valor custo - {branch.formattedCoust}
      </Typography>
      <Typography variant="caption" color="primary">
        Valor unitário - {branch.formattedUnitary}
      </Typography>
      <Typography variant="caption" color="primary">
        QTD - {branch.qtd}
      </Typography>
    </ListItem>
  );
};

export default BranchItemModule;
