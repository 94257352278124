import React, { useReducer } from 'react';
import { Image } from 'types/image';

import { SiteSetting } from 'types/siteSetting';
import { SettingsActionsType, SETTINGS_CHANGE, SET_SETTINGS } from './types';

const SETTINGS_INITIAL_VALUE: SiteSetting = {
  contact_email: '',
  home_courses_title: '',
  home_products_title: '',
  facebook_profile_url: '',
  primary_color: '',
  footer_company_name: '',
  secondary_color: '',
  home_description: '',
  home_keywords: '',
  home_title: '',
  instagram_profile_url: '',
  whatsapp: '',
  youtube_channel_url: '',
  footer_logo: {} as Image,
  home_image: {} as Image,
  use_gradient_background: false,
  use_h2i_api: false,
  facebook_pixel_id: '',
  google_analytics_id: '',
  about_page: '',
  home_segments_title: '',
  bottom_banners_title: '',
  branches_page_url: '',
  data_sharing_page_url: '',
  environment_page_url: '',
  news_page_url: '',
  human_resources_page_url: '',
  organization_chart_page_url: '',
  home_segment_banners_order: 1,
  home_featured_products_order: 2,
  home_highlight_banners_order: 3,
  home_promotional_banners_order: 4,
};

function settingsReducer(state = SETTINGS_INITIAL_VALUE, action: SettingsActionsType): SiteSetting {
  switch (action.type) {
    case SETTINGS_CHANGE: {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case SET_SETTINGS: {
      return action.settings;
    }

    default: {
      return state;
    }
  }
}

type SettingsReducer = [SiteSetting, React.Dispatch<SettingsActionsType>];

export function useSiteSettingsReducer(): SettingsReducer {
  const [settings, dispatch] = useReducer(settingsReducer, SETTINGS_INITIAL_VALUE);
  return [settings, dispatch];
}
