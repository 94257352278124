import { FilterList } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';
import React from 'react';
import { useFinancialResume } from './hooks/useFinancialResume';

const Container = styled('div')(props => ({
  display: 'none',
  [props.theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

const FinancialAppbarActions: React.FC = () => {
  const { setIsOpenFilterMore } = useFinancialResume();

  return (
    <Container>
      <IconButton onClick={() => setIsOpenFilterMore(true)} color="inherit">
        <FilterList />
      </IconButton>
    </Container>
  );
};

export default FinancialAppbarActions;
