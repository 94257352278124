import { useApp } from 'hooks/app';
import { useCallback, useState, useEffect } from 'react';
import { Invoice } from 'types/invoices';
import { InvoicesFilterProps, invoiceTypes } from '../Invoices';
import { format } from 'date-fns';
import { moneyFormat } from 'helpers/numberFormat';
import { TotalizingAccount } from 'types/totalizingAccount';
import { parsePtBRDate } from 'helpers/parsePtBRDate';

type UseFetchInvoices = [Invoice[], boolean, TotalizingAccount[], () => void];

export function useFetchInvoices(filter: InvoicesFilterProps, type: string): UseFetchInvoices {
  const [accounts, setAccounts] = useState<TotalizingAccount[]>([]);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const { financialApi } = useApp();

  useEffect(() => {
    financialApi
      .get('/getcontastotalizadoras', {
        params: {
          id_filial: filter.branch_id === 1001 ? '' : filter.branch_id,
        },
      })
      .then(response => {
        if (response.data.MESSAGE) {
          setAccounts([]);
          return;
        }
        setAccounts(
          response.data.map(item => {
            item.formattedSaldo = moneyFormat(item.saldo);
            item.data = new Date(parsePtBRDate(item.data_inicio));
            return item;
          }),
        );
      })
      .catch(err => console.error(err));
  }, [financialApi, filter.branch_id]);

  const fetch = useCallback(() => {
    if (!financialApi) {
      return;
    }

    setLoading(true);

    financialApi
      .get('/getTitulos', {
        params: {
          data_ini: filter.initial_date ? format(filter.initial_date, 'dd/MM/yyyy') : '',
          data_fim: filter.final_date ? format(filter.final_date, 'dd/MM/yyyy') : '',
          id_filial: filter.branch_id === 1001 ? '' : filter.branch_id,
          tipo:
            type === 'TT'
              ? filter.type === 'DT'
                ? "'D','T'"
                : filter.type === 'TT'
                ? ''
                : `'${filter.type}'`
              : `'${type}'`,
          titulo: filter.classification,
          mes_ano: filter.month_year ? format(filter.month_year, 'MMyy') : '',
          fornecedor: filter.supplier,
          numero_nf: filter.invoice_number,
          // pago: filter.is_paid === 'T' ? '' : filter.is_paid,
          apropriado: filter.is_appropriated === 'T' ? '' : filter.is_appropriated,
          ct_informado: filter.informedAccount === 'N' ? -1 : filter.informedAccount === 'S' ? 0 : '',
          id_conta_totalizadora: filter.totalizingAccountId === 0 ? '' : filter.totalizingAccountId,
          data_apropriacao: filter.appropriation_date ? format(filter.appropriation_date, 'dd/MM/yyyy') : '',
        },
      })
      .then(response => {
        if (!response.data?.titulos) {
          setInvoices([]);
          return;
        }

        setInvoices(
          response.data.titulos.map(invoice => ({
            ...invoice,
            formattedType: invoiceTypes[invoice.tipo],
            formattedValue: moneyFormat(invoice.valor),
          })),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, financialApi, type]);

  return [invoices, loading, accounts, fetch];
}
