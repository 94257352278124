import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import { SalesItem } from 'types/reports/sales';
import { moneyFormat } from 'helpers/numberFormat';

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #ddd',
  backgroundColor: '#fff',
  '& > div': {
    gap: 10,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '&.data': {
      gap: 5,
    },
    '& > span': {
      borderRadius: 6,
      border: '1px solid #ddd',
      display: 'flex',
      flexDirection: 'column',
      '& .title': {
        backgroundColor: '#ddd',
      },
      '& > p': {
        padding: 5,
      },
    },
  },
});

type SalesItemModuleProps = {
  sale: SalesItem;
};

const SalesItemModule: React.FC<SalesItemModuleProps> = ({ sale }) => {
  return (
    <CustomListItemButton>
      <Typography variant="subtitle2" color="primary">
        {sale.produto}
      </Typography>
      <div>
        <span>
          <Typography className="title" variant="body2" fontSize={12}>
            <b>MC:</b>
          </Typography>
          <Typography color="#30519f" align="right">
            {moneyFormat(sale.mc)}
          </Typography>
        </span>

        <span>
          <Typography className="title" variant="body2" fontSize={12}>
            <b>Valor total:</b>
          </Typography>
          <Typography color="green" align="right">
            {sale.formattedTotal}
          </Typography>
        </span>
      </div>
      <div className="data">
        <Typography fontWeight={600} color="#ff0000" variant="body2" fontSize={12}>
          Spiff:
        </Typography>
        <Typography align="right" color="#ff0000" variant="body2" fontSize={12}>
          {sale.formattedSpiff}
        </Typography>

        <Typography fontWeight={600} variant="body2" fontSize={12}>
          Qtd vendas:
        </Typography>
        <Typography align="right" variant="body2" fontSize={12}>
          {sale.qtd_vendas}
        </Typography>

        <Typography fontWeight={600} variant="body2" fontSize={12}>
          Custo:
        </Typography>
        <Typography align="right" variant="body2" fontSize={12}>
          {sale.formattedCusto}
        </Typography>

        <Typography fontWeight={600} variant="body2" fontSize={12}>
          Unitário:
        </Typography>
        <Typography align="right" variant="body2" fontSize={12}>
          {sale.formattedUnitario}
        </Typography>
      </div>
    </CustomListItemButton>
  );
};

export default SalesItemModule;
