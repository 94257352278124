import React, {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  FormEvent,
} from 'react';
import { AbcProductUnity } from 'types/abcProduct';
import { format } from 'date-fns';
import { useApp } from 'hooks/app';
import { ptBR } from 'date-fns/locale';
import useTableOrder, { OrderIndexData } from 'hooks/tableOrder';
import { AbcUnitsFilterParams } from '../CurveAbcUnity';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { moneyFormat } from 'helpers/numberFormat';

export type AbcUnityTotalProps = {
  total: number;
  coust: number;
  mc: number;
  spiff: number;
  unitary: number;
  quantity: number;
};

type AbcUnityContextValue = {
  filteredUnits: AbcProductUnity[];
  handleSortUnity(index: string): void;
  moreUnity: AbcProductUnity | null;
  setMoreUnity: Dispatch<SetStateAction<AbcProductUnity | null>>;
  displayMode: 'list' | 'module';
  setDisplayMode: Dispatch<SetStateAction<'list' | 'module'>>;
  searchText: string;
  handleSearch(searchValue: string): void;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(): void;
  loading: boolean;
  orderedIndex: OrderIndexData;
  formattedTotal: AbcUnityTotalProps;
};

const AbcUnityContext = createContext<AbcUnityContextValue>({} as AbcUnityContextValue);

export function useAbcUnits(): AbcUnityContextValue {
  const context = useContext(AbcUnityContext);
  return context;
}

interface UnityProviderProps {
  filter: AbcUnitsFilterParams;
  children: React.ReactNode;
}

const UnityProvider: React.FC<UnityProviderProps> = ({ children, filter }) => {
  const { h2iApi } = useApp();
  const { windowWidth, isMobile, isOpenedMenu, handleOpenMenu } = useApp();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [manufacturers, setUnits] = useState<AbcProductUnity[]>([]);
  const [moreUnity, setMoreUnity] = useState<AbcProductUnity | null>(null);
  const [filteredUnits, setFilteredUnits] = useState<AbcProductUnity[]>([]);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [formattedTotal, setFormattedTotal] = useState<AbcUnityTotalProps>({
    total: 0,
    coust: 0,
    mc: 0,
    spiff: 0,
    unitary: 0,
    quantity: 0,
  });

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth, setDisplayMode]);

  useEffect(() => {
    setFilteredUnits(manufacturers);
  }, [manufacturers]);

  const handleSearch = useCallback(() => {
    if (!h2iApi) return;
    setLoading(true);
    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    h2iApi
      .get(`/api/relcurvaabcunidade`, {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.selectedBranchId || '',
          id_subgrupo: filter.subGroupIds.join(',') || '',
          filtro: searchText,
          tipo_venda: filter.typeSale,
          id_categoria: filter.categoryIds.join(','),
          id_subcategoria: filter.subCategoryIds.join(','),
          pedido_por: filter.madeBy,
          id_segmento: filter.segmentIds.join(','),
          id_unidade: filter.unitIds.join(','),
          id_fabricante: filter.manufacturerIds.join(','),
        },
      })
      .then(response => {
        if (response.data) {
          const _manufacturers: AbcProductUnity[] = response.data;
          setUnits(
            _manufacturers.map(item => {
              item.formattedTotal = moneyFormat(item.total);
              item.formattedCoust = moneyFormat(item.custo);
              item.formattedMc = moneyFormat(item.mc);
              item.formattedSpiff = moneyFormat(item.spiff);
              item.formattedUnitary = moneyFormat(item.unitario);

              return item;
            }),
          );

          const total = _manufacturers.reduce((sum, item) => sum + item.total, 0);
          const coust = _manufacturers.reduce((sum, item) => sum + item.custo, 0);
          const mc = _manufacturers.reduce((sum, item) => sum + item.mc, 0);
          const spiff = _manufacturers.reduce((sum, item) => sum + item.spiff, 0);
          const unitary = _manufacturers.reduce((sum, item) => sum + item.unitario, 0);
          const quantity = _manufacturers.reduce((sum, item) => sum + item.qtd, 0);
          setFormattedTotal({
            total,
            coust,
            mc,
            spiff,
            quantity,
            unitary,
          });
        }
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, h2iApi, searchText]);

  function handleSortUnity(index: string) {
    const filtered = sort(index, filteredUnits);
    setFilteredUnits(filtered);
  }

  function handleSearchSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    if (isOpenedMenu) {
      handleOpenMenu();
    }

    handleSearch();
  }

  function handleSearchInputChange(value: string) {
    setSearchText(value);
  }

  return (
    <AbcUnityContext.Provider
      value={{
        displayMode,
        setDisplayMode,
        formattedTotal,
        filteredUnits,
        handleSortUnity,
        moreUnity,
        setMoreUnity,
        handleSearch,
        handleSearchInputChange,
        handleSearchSubmit,
        loading,
        orderedIndex,
        searchText,
      }}
    >
      {children}
    </AbcUnityContext.Provider>
  );
};

export default UnityProvider;
