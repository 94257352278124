import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import BranchItemModule from './BranchItemModule';
import { AbcProductBranch } from 'types/abcProduct';

type BranchListModuleProps = {
  branches: AbcProductBranch[];
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

const BranchListModule: React.FC<BranchListModuleProps> = ({ branches }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {branches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(branch => (
        <BranchItemModule key={branch.filial} branch={branch} />
      ))}
    </CustomList>
  );
};

export default BranchListModule;
