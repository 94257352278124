import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Compatibility } from 'types/compatibility';

interface CompatibilitiesContextValue {
  selectedCompatibility: Compatibility | null;
  setSelectedCompatibility: Dispatch<SetStateAction<Compatibility | null>>;
  handleDelete(): void;
}

const CompatibilityContext = createContext<CompatibilitiesContextValue>({} as CompatibilitiesContextValue);
export const CompatibilityConsumer = CompatibilityContext.Consumer;
export const CompatibilityProvider = CompatibilityContext.Provider;

export function useCompatibilities(): CompatibilitiesContextValue {
  return useContext(CompatibilityContext);
}
