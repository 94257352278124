import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { ProductList } from 'types/product';
import { wasteProductTableTemplate } from '../wasteProductTableTemplate';

type WasteProductItemTableProps = {
  product: ProductList;
  selectedProduct: ProductList | null;
};

const WasteProductItemTable: React.FC<WasteProductItemTableProps> = ({ selectedProduct, product }) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (selectedProduct?.codigo_produto === product.codigo_produto) {
        if (event.key === 'Enter') {
          console.log('Tecla Enter pressionada na div! ' + product.codigo_produto);
        }
      }
    };

    // Adiciona o event listener quando o componente é montado
    document.addEventListener('keydown', handleKeyDown);

    // Remove o event listener quando o componente é desmontado
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedProduct, product]);

  return (
    <>
      {wasteProductTableTemplate.map(item => (
        <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
          <Typography
            style={{
              color: selectedProduct?.codigo_produto === product.codigo_produto ? '#fff' : '#000',
            }}
            variant="body2"
          >
            {product[item.id]}
          </Typography>
        </div>
      ))}
    </>
  );
};

export default WasteProductItemTable;
