import React, { useState } from 'react';
import OrderItemTable from './OrderItemTable';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { Typography } from '@mui/material';
import { OrderAuthorization } from 'types/orderAuthorization';
import OrdersAuthorizationListMenu from '../OrdersAuthorizationListMenu';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useOrdersAuthorization } from '../../hooks/useOrdersAuthorization';
import OrdersAuthorizationMoreMenu from '../OrdersAuthorizationMoreMenu';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
});

type OrderListTableProps = {
  orders: OrderAuthorization[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const OrderListTable: React.FC<OrderListTableProps> = ({ orders, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { tableTemplate } = useTable();
  const { rowsPerPage, page } = usePagination();
  const { setSelectedOrder, setDialog } = useOrdersAuthorization();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  function handleClick(order: OrderAuthorization) {
    setSelectedOrder(order);
    setDialog(true);
  }

  return (
    <>
      <OrdersAuthorizationListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <div
              className={
                item.dataType === 'number' ? `${classes.headerItem} ${classes.headerItemNumeric}` : classes.headerItem
              }
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(order => (
            <>
              <TableRow key={order.id} onClick={() => handleClick(order)}>
                <OrderItemTable order={order} setAnchorEl={setAnchorEl} />
              </TableRow>
              <OrdersAuthorizationMoreMenu order={order} />
            </>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default OrderListTable;
