import React, { SetStateAction, Dispatch, createContext, useState, useContext, ReactNode } from 'react';

interface ProductSearchProviderContextValue {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
}

export const ProductSearchContext = createContext<ProductSearchProviderContextValue>(
  {} as ProductSearchProviderContextValue,
);

interface ProductSearchProviderProps {
  children: ReactNode;
}

const ProductSearchProvider: React.FC<ProductSearchProviderProps> = ({ children }) => {
  const [search, setSearch] = useState('');

  return <ProductSearchContext.Provider value={{ search, setSearch }}>{children}</ProductSearchContext.Provider>;
};

export default ProductSearchProvider;

export function useProductSearch(): ProductSearchProviderContextValue {
  const context = useContext(ProductSearchContext);
  return context;
}
