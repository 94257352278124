import React from 'react';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import history from 'services/history';

const BranchesActions: React.FC = () => {
  return (
    <IconButton color="inherit" onClick={() => history.push('/site/branch')}>
      <AddIcon />
    </IconButton>
  );
};

export default BranchesActions;
