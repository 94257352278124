import { styled } from '@mui/material';
import NoData from 'components/no-data/NoData';
import { useProject } from 'pages/projects/registration/hook/useProject';
import React, { useEffect, useState, useMemo } from 'react';
import { ProjectCost, ProjectCostFormContent } from 'types/project';
import { ProjectCostContextProvider } from './hooks/useProjectCosts';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import CostListTable from './list/table/CostListTable';
import CostListModule from './list/module/CostListModule';
import ProjectContactForm from './ProjectCostForm';
import { addProjectCost } from 'pages/projects/registration/reducer/action';
import { useProjectCostValidation } from './validation/projectContactValidation';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const ProjectCosts: React.FC = () => {
  const { isMobile, windowWidth } = useApp();
  const { project, dispatch } = useProject();
  const [filteredCosts, setFilteredCosts] = useState<ProjectCost[]>([]);
  const [selectedProjectCost, setSelectedProjectCost] = useState<ProjectCost | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const displayMode = useMemo(() => (isMobile || windowWidth < 930 ? 'module' : 'list'), [isMobile, windowWidth]);
  const [validation, setValidation, validate] = useProjectCostValidation();

  useEffect(() => {
    setFilteredCosts(project.costs);
  }, [project.costs]);

  function handleSort(index: string) {
    const _contacts = sort(index, filteredCosts);
    setFilteredCosts(_contacts);
  }

  async function handleValidation(data: ProjectCostFormContent) {
    setValidation({});

    await validate(data);
    handleSubmit(data);
  }

  function handleSubmit(data: ProjectCostFormContent) {
    dispatch(addProjectCost(data));
  }

  return (
    <Container>
      <ProjectContactForm validation={validation} handleSubmit={handleValidation} />

      {filteredCosts.length === 0 ? (
        <NoData message="Nenhum custo para mostrar" />
      ) : (
        <ProjectCostContextProvider value={{ selectedProjectCost, setSelectedProjectCost }}>
          {displayMode === 'list' ? (
            <CostListTable costs={filteredCosts} handleSort={handleSort} orderedIndex={orderedIndex} />
          ) : (
            <CostListModule costs={filteredCosts} />
          )}
        </ProjectCostContextProvider>
      )}
    </Container>
  );
};

export default ProjectCosts;
