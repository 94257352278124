import { createContext, useContext } from 'react';
import { ProductList } from 'types/product';

export type ProductContextValue = {
  handleSelectProduct(state: ProductList | null): void;
  openImagePreview(): void;
};

export const ProductContext = createContext<ProductContextValue>({} as ProductContextValue);
export const ProductContextConsumer = ProductContext.Consumer;
export const ProductContextProvider = ProductContext.Provider;

export function useProduct(): ProductContextValue {
  const context = useContext(ProductContext);
  return context;
}
