import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useDialog } from 'components/dialogs/Dialog';

type ProductQuantityPriceFormActionsProps = {
  handleSubmit(): void;
};

const ProductQuantityPriceFormActions: React.FC<ProductQuantityPriceFormActionsProps> = ({ handleSubmit }) => {
  const { handleClose } = useDialog();

  function handleClick() {
    handleSubmit();
    handleClose();
  }

  return (
    <Tooltip title="Salvar">
      <IconButton color="inherit" onClick={handleClick}>
        <DoneIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ProductQuantityPriceFormActions;
