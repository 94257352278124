import React, { useState, useEffect, useRef } from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import { Button, TextField } from '@mui/material';
import { useDialog } from 'components/dialogs/Dialog';
import { useResellerRejectionValidation } from './useResellerRejectionValidation';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 200,
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-around',
  },
});

interface ResellerRejectionProps {
  handleReject(reason: string, handleCloseDialog: () => void): void;
  onExited(): void;
}

const ResellerRejection: React.FC<ResellerRejectionProps> = ({ handleReject, onExited }) => {
  const classes = styles();
  const [reason, setReason] = useState('');
  const dialog = useDialog();
  const [validation, setValidation, validade] = useResellerRejectionValidation();
  const inputs = {
    email: useRef<HTMLInputElement>(null),
    reason: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(inputs) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleValidation(handleCloseDialog: () => void) {
    setValidation({});

    validade({ reason })
      .then(() => handleConfirm(handleCloseDialog))
      .catch(err => console.error(err));
  }

  function handleConfirm(handleCloseDialog: () => void) {
    handleReject(reason, dialog.handleClose);
    handleCloseDialog();
  }

  return (
    <DialogInput maxWidth="sm" onExited={onExited}>
      <div className={classes.container}>
        <TextField
          label="Informe o motivo"
          fullWidth
          margin="normal"
          value={reason}
          onChange={e => setReason(e.target.value)}
          rows={5}
          multiline
          inputRef={inputs.reason}
          error={!!validation.reason}
          helperText={validation.reason}
        />
        <DialogInputConsumer>
          {context => (
            <div className={classes.actions}>
              <Button variant="text" color="primary" onClick={context.handleClose}>
                Cancelar
              </Button>
              <Button variant="contained" color="primary" onClick={() => handleValidation(context.handleClose)}>
                Confirmar
              </Button>
            </div>
          )}
        </DialogInputConsumer>
      </div>
    </DialogInput>
  );
};

export default ResellerRejection;
