import { useRentalReducer } from 'pages/rentals/reducer/reducer';
import React, { useEffect, useState } from 'react';
import { RentalProvider } from '../hooks/useRental';
import { Rental } from 'pages/rentals/types/rental';
import { changeRentalAction, setRentalAction } from 'pages/rentals/reducer/actions';
import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import RentalForm from '../RentalForm';
import { api } from 'services/api';
import history from 'services/history';
import { useRentalValidation } from '../validation/rentalValidation';
import Loading from 'components/loading/Loading';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import RentalNewAppbarButtons from '../new/RentalNewAppbarButtons';
import { useParams } from 'react-router-dom';
import { useFetchRental } from '../../hooks/useFetchRental';
import RentalTabs, { RentalTabsOptions } from '../RentalTabs';
import RentalPayments from '../payments/RentalPayments';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 40,
  marginBottom: 200,
  maxWidth: 1100,
  width: '100%',
  margin: '0 auto',
});

const RentalUpdate: React.FC = () => {
  const [rental, dispatch] = useRentalReducer();
  const [saving, setSaving] = useState(false);
  const [validation, setValidation, validate] = useRentalValidation();
  const { showErrorDialog } = useErrorHandler();
  const { id } = useParams<{ id: string }>();
  const [loadedRental] = useFetchRental(id);
  const [tab, setTab] = useState<RentalTabsOptions>('main');
  const [percentage, setPercentage] = useState('');

  useEffect(() => {
    dispatch(setRentalAction(loadedRental));
  }, [loadedRental, dispatch]);

  function handleChange(index: keyof Rental, value: any) {
    dispatch(changeRentalAction(index, value));
  }

  function handleValidation() {
    setValidation({});

    validate(rental)
      .then(handleSubmit)
      .catch(error => console.error(error));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/rentals/${id}`, rental)
      .then(() => {
        history.push('/rentals');
      })
      .catch(error => {
        console.error(error);
        showErrorDialog({
          error,
          message: 'Não foi possível salvar a nova locação',
        });
      })
      .finally(() => setSaving(false));
  }

  return (
    <RentalProvider value={{ rental, handleChange, dispatch, setPercentage, percentage }}>
      {saving && <Loading />}

      <Appbar title="Locação" ActionsComponent={<RentalNewAppbarButtons handleValidation={handleValidation} />} />

      <PageHeader title="Editar locação" backUrl="/rentals" />

      <Container>
        <RentalTabs tab={tab} handleChange={_tab => setTab(_tab)} />

        {tab === 'main' ? (
          <RentalForm validation={validation} handleChange={handleChange} rental={rental} />
        ) : (
          <RentalPayments />
        )}
      </Container>
    </RentalProvider>
  );
};

export default RentalUpdate;
