import React from 'react';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import history from 'services/history';

const EmailTemplatesActions: React.FC = () => {
  return (
    <>
      <IconButton color="inherit" onClick={() => history.push('/email-template')}>
        <AddIcon />
      </IconButton>
    </>
  );
};

export default EmailTemplatesActions;
