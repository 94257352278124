import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import history from 'services/history';
import { AccessRule } from 'types/accessRule';

type AccessRuleItemProps = {
  accessRule: AccessRule;
};

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
});

const UserItem: React.FC<AccessRuleItemProps> = ({ accessRule }) => {
  function handleClick(id?: number) {
    history.push(`/access-rule/${id}`);
  }

  return (
    <CustomListItemButton onClick={() => handleClick(accessRule.id)}>
      <Typography variant="h6">{accessRule.name}</Typography>
      <Typography variant="body1">{accessRule.title}</Typography>
      <Typography variant="caption" color="textSecondary">
        {accessRule.description}
      </Typography>
    </CustomListItemButton>
  );
};

export default UserItem;
