import React, { useState, useEffect, useRef } from 'react';
import PurchaseOrderForm from '../PurchaseOrderForm';
import Appbar from 'components/appbar/Appbar';
import { useApp } from 'hooks/app';
import InsideSaving from 'components/loading/InsideSaving';
import PageHeader from 'components/page-header/PageHeader';
import { PurchaseOrderProvider } from '../hook/usePurchaseOrder';
import { Tab, Tabs, Typography, styled } from '@mui/material';
import { useFetchPurchaseOrder } from '../hook/useFetchPurchaseOrder';
import PurchaseOrderProducts from '../product/PurchaseOrderProducts';
import StepsPurchaseOrder from '../steps/StepsPurchaseOrder';
import PurchaseOrderActions from './PurchaseOrderActions';
import PurchaseOrderPayment from '../dialog/PurchaseOrderPayment';
import PurchaseOrderCalculationForm from '../calculation-options/PurchaseOrderCalculationForm';
import PurchaseOrderTotals from '../totals/PurchaseOrderTotals';
import PurchaseOrderStepActions from '../steps/action/PurchaseOrderStepActions';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 15,
  margin: '10px auto 10px auto',
  [theme.breakpoints.up('lg')]: {
    minWidth: 1440,
  },
  [theme.breakpoints.up('md')]: {
    width: '100%',
    minWidth: 0,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const Content = styled('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: 30,
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  '& .subtitle': {
    marginBottom: 20,
  },
  '& .total-item': {
    display: 'inline-flex',
    gap: 20,
    justifyContent: 'end',
    alignItems: 'baseline',
    marginBottom: 10,
    '& > span': {
      fontSize: 16,
      fontWeight: '400',
    },
  },
  [props.theme.breakpoints.down('sm')]: {
    padding: 0,
    backgroundColor: 'unset',
    border: 'none',
    marginBottom: 30,
  },
}));

const PurchaseOrderNewPage: React.FC = () => {
  const { windowWidth } = useApp();
  const [informPayment, setInformPayment] = useState(false);
  const [tab, setTab] = useState('product');
  const {
    saving,
    handleChange,
    order,
    disabled,
    validation,
    dispatch,
    handleValidation,
    handleBack,
    secondValidation,
    thirdValidation,
    step,
    handleNext,
    handleCustomerSelect,
    reseller,
  } = useFetchPurchaseOrder();

  const inputs = {
    id: useRef<HTMLInputElement>(null),
    cnpj: useRef<HTMLInputElement>(null),
    cliente: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(step === 2 ? secondValidation : validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [secondValidation]); // eslint-disable-line

  function handleClickInformPayment() {
    setInformPayment(true);
  }

  return (
    <PurchaseOrderProvider
      value={{
        validation,
        secondValidation,
        thirdValidation,
        handleBack,
        handleNext,
        handleChange,
        order,
        step,
        inputs,
        handleCustomerSelect,
        reseller,
        disabled,
        dispatch,
        handleClickInformPayment,
      }}
    >
      <Container>
        {saving && <InsideSaving />}
        {informPayment && <PurchaseOrderPayment type="new" order={order} onExited={() => setInformPayment(false)} />}

        {windowWidth < 660 ? (
          <>
            <Appbar
              backAction={handleBack}
              title="Pedido de compras"
              ActionsComponent={<PurchaseOrderStepActions handleNext={handleNext} />}
            />
            <StepsPurchaseOrder />
          </>
        ) : (
          <>
            <Appbar
              title="Pedido de compras"
              ActionsComponent={<PurchaseOrderActions handleValidation={handleValidation} />}
            />
            <PageHeader title="Pedido de compras" backUrl="/purchasing/registration" />
            <Content>
              <Typography variant="h6" className="subtitle" color="primary">
                Dados do fornecedor
              </Typography>
              <form style={{ flex: 0 }} onSubmit={e => handleValidation('form', e)}>
                <PurchaseOrderForm />
              </form>
            </Content>

            <Content>
              <Tabs
                style={{ marginBottom: 20 }}
                value={tab}
                onChange={(e, value) => setTab(value)}
                variant="scrollable"
              >
                <Tab disabled={disabled} label="Produto" value="product" />
                <Tab disabled label="Conhecimento" value="knowledge" />
                <Tab disabled={disabled} label="Opções de cálculo" value="calculation" />
                <Tab disabled label="Inf. Pagamentos" value="payments" />
              </Tabs>

              {tab === 'product' ? <PurchaseOrderProducts type="new" /> : <PurchaseOrderCalculationForm />}
            </Content>

            <Content>
              <Typography variant="h6" className="subtitle" color="primary">
                Total
              </Typography>

              <PurchaseOrderTotals />
            </Content>
          </>
        )}
      </Container>
    </PurchaseOrderProvider>
  );
};

export default PurchaseOrderNewPage;
