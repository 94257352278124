import React from 'react';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import PaymentItemTable from './PaymentItemTable';
import TableBody from 'components/table/TableBody';
import { moneyFormat } from 'helpers/numberFormat';
import TableHeader from 'components/table/TableHeader';
import { OrderPayment } from 'types/orderAuthorization';
import TableContent from 'components/table/TableContent';
import { Typography } from '@mui/material';
import { PaymentTableTemplate } from './paymentTableTemplate';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  header: {
    background: '#eee',
    '& > div': {
      marginLeft: 80,
    },
  },
  headerItem: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
  headerItemQuantity: {
    justifyContent: 'center',
  },
  body: {
    '& > div': {
      marginLeft: 80,
      '& > div > div:nth-child(2n)': {
        background: '#eee',
      },
    },
  },
  content: {
    margin: 0,
  },
});

type PaymentListTableProps = {
  payments: OrderPayment[];
  orderedIndex: OrderIndexData;
};

const PaymentListTable: React.FC<PaymentListTableProps> = ({ payments, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();

  return (
    <>
      <TableContent>
        <div className={classes.header}>
          <TableHeader>
            {PaymentTableTemplate.map(item => (
              <div
                className={
                  item.dataType === 'number'
                    ? `${classes.headerItem} ${classes.headerItemNumeric}`
                    : item.dataType === 'quantity'
                    ? `${classes.headerItem} ${classes.headerItemQuantity}`
                    : classes.headerItem
                }
                key={item.id}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
          </TableHeader>
        </div>
        <div className={classes.body}>
          <TableBody>
            {payments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
              <TableRow key={item.id}>
                <PaymentItemTable
                  item={{
                    ...item,
                    formattedTotal: moneyFormat(item.valor),
                    formattedPaid: item.PAGO ? 'SIM' : 'NÃO',
                  }}
                />
              </TableRow>
            ))}
          </TableBody>
        </div>
      </TableContent>
    </>
  );
};

export default PaymentListTable;
