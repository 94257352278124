import React, { FormEvent, useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import CustomerForm from '../form/CustomerForm';
import { useCustomervalidation } from '../validation/customerValidation';
import PartnerActions from './CustomerActions';
import { Customer } from 'types/customers';
import { CustomerProvider } from 'pages/site-h2i/customers/hooks/useCustomer';
import { Banner } from 'types/banner';
import CustomerImport from 'pages/site-h2i/customers/import/CustomerImport';
import { Reseller } from 'types/reseller';

const INITIAL_STATE: Customer = {
  id: new Date().getTime(),
  name: '',
  whatsapp: '',
  url: '',
  stars: 5,
  instagram_profile: '',
  image: null,
  facebook_profile: '',
  created_at: '',
  areas: [],
  about: '',
  formattedCreatedAt: '',
  phone: '',
  reseller_id: null,
  active: true,
};

const CustomerNew: React.FC = () => {
  const [customer, setCustomer] = useState<Customer>(INITIAL_STATE);
  const [validation, setValidation, validate] = useCustomervalidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const [dialogImport, setDialogImport] = useState(false);

  const handleChange = useCallback((index: keyof Customer, value: any) => {
    setCustomer(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(customer)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/customers', customer)
      .then(() => {
        setSaving(false);
        history.push('/site/customers');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  function handleAddArea(area: Banner) {
    setCustomer(state => ({
      ...state,
      areas: [...state.areas, area],
    }));
  }

  function handleRemoveArea(areaId: number) {
    setCustomer(customer => ({
      ...customer,
      areas: customer.areas.filter(area => area.id !== areaId),
    }));
  }

  function handleConfirm(reseller: Reseller, handleCloseDialogImport: () => void) {
    setCustomer(customer => ({
      ...customer,
      name: reseller.company_name,
      phone: reseller.phone,
      reseller_id: reseller.id,
    }));
    handleCloseDialogImport();
  }

  return (
    <CustomerProvider value={{ handleAddArea, handleRemoveArea, customer }}>
      {saving && <Loading />}
      {dialogImport && <CustomerImport handleConfirm={handleConfirm} onExited={() => setDialogImport(false)} />}
      <Appbar
        title="Site"
        ActionsComponent={
          <PartnerActions handleSubmit={handleValidation} handleOpenDialogImport={() => setDialogImport(true)} />
        }
        backAction={() => history.push('/site/customers')}
      />
      <PageHeader title="Novo cliente parceiro" />
      <Grid container>
        <form onSubmit={handleValidation}>
          <CustomerForm handleChange={handleChange} customer={customer} validation={validation} />
        </form>
      </Grid>
    </CustomerProvider>
  );
};

export default CustomerNew;
