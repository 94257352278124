import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Compatibility } from 'types/compatibility';

type CompatibilityContextValue = {
  compatibility: Compatibility;
  handleChange(index: keyof Compatibility, value: any): void;
  setCompatibility: Dispatch<SetStateAction<Compatibility>>;
};

const CompatibilityContext = createContext<CompatibilityContextValue>({} as CompatibilityContextValue);
export const ProjectProvider = CompatibilityContext.Provider;

export function useCompatibility(): CompatibilityContextValue {
  return useContext(CompatibilityContext);
}
