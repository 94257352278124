import React, { useState } from 'react';
import Dialog from 'components/dialogs/Dialog';
import { MenuItem, Select } from '@mui/material';
import { MonthlyManufacturer, MonthlyBranch, MonthlyCategory, MonthlyPeriod } from 'types/monthlyComparison';
import ExcelButtonDownload from 'components/excel-button-download/ExcelButtonDownload';
import { api } from 'services/api';
import { uuidv4 } from 'helpers/uuidv4';
import { download } from 'helpers/download';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 30,
  },
});

interface MonthlyComparisonExportProps {
  manufacturers: MonthlyManufacturer[];
  periods: MonthlyPeriod[];
  branches: MonthlyBranch[];
  categories: MonthlyCategory[];
  onExited(): void;
}

const MonthlyComparisonExport: React.FC<MonthlyComparisonExportProps> = ({
  periods,
  branches,
  categories,
  manufacturers,
  onExited,
}) => {
  const classes = styles();
  const [tab, setTab] = useState<'manufacturer' | 'branch' | 'period' | 'category'>('branch');

  async function handleDownload() {
    const mapping = {
      periods: {
        route: '/excel-exports',
        data: periods,
      },
      branches: {
        route: '/excel-exports',
        data: branches,
      },
      categories: {
        route: '/excel-exports',
        data: categories,
      },
      manufacturers: {
        route: '/excel-exports',
        data: manufacturers,
      },
    };

    const resource = mapping[tab];

    try {
      const response = await api.post(resource.route, resource.data, { responseType: 'blob' });

      download(response.data, `${tab}-${uuidv4()}`);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Dialog onExited={onExited} title="Export para Excel" maxWidth="sm" height="60vh">
      <div className={classes.container}>
        <Select fullWidth value={tab}>
          <MenuItem value="period" onClick={() => setTab('period')}>
            Período
          </MenuItem>
          <MenuItem value="branch" onClick={() => setTab('branch')}>
            Filial
          </MenuItem>
          <MenuItem value="category" onClick={() => setTab('category')}>
            Categoria
          </MenuItem>
          <MenuItem value="manufacturer" onClick={() => setTab('manufacturer')}>
            Fabricante
          </MenuItem>
        </Select>

        <ExcelButtonDownload handleDownload={handleDownload} />
      </div>
    </Dialog>
  );
};

export default MonthlyComparisonExport;
