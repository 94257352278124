import React, { FormEvent, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import NoData from 'components/no-data/NoData';
import SelectedSale from 'components/sale/SelectedSale';
import { useSelector } from 'store/selector';
import { Sale } from 'pages/reports/commercial/abc/product/modal/branch/SelectedBranchListMenu';
import ReprintSaleFilterBox from './ReprintSaleFilterBox';
import { useApp } from 'hooks/app';
import Loading from 'components/loading/Loading';

const ReprintSale: React.FC = () => {
  const { h2iApi } = useApp();
  const user = useSelector(state => state.user);
  const [branchId, setBranchId] = useState(user?.branchList ? parseInt(user?.branchList[0]) : 0);
  const [saleId, setSaleId] = useState<number | null>(null);
  const [selectedSale, setSelectedSale] = useState<Sale | null>(null);
  const [loading, setLoading] = useState(false);

  function handleReprintSale(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    h2iApi
      .get(`/api/imprimevenda`, {
        params: {
          id_filial: branchId,
          numero_venda: saleId,
        },
      })
      .then(response => setSelectedSale(response.data[0].venda[0]))
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  return (
    <>
      <Appbar title="Reimprimir venda" />
      <PageHeader title="Venda" description="Reimpressão de vendas" />

      {loading && <Loading />}

      {selectedSale && <SelectedSale onExited={() => setSelectedSale(null)} selectedSale={selectedSale} />}

      <ReprintSaleFilterBox
        saleId={saleId}
        setSaleId={setSaleId}
        branchId={branchId}
        setBranchId={setBranchId}
        handleSearchSubmit={handleReprintSale}
      />

      <NoData message="Digite o número da venda e selecione a filial" />
    </>
  );
};

export default ReprintSale;
