import { CircularProgress, Typography, alpha, styled } from '@mui/material';
import DialogInput from 'components/dialogs/DialogInput';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import React, { DragEvent, useState } from 'react';
import { api } from 'services/api';
import ImportInvoiceXMLInput from './ImportInvoiceXMLInput';

type ImageContainerProps = {
  dragIn: boolean;
};

const Container = styled('div')<ImageContainerProps>(props => ({
  display: 'flex',
  width: '100%',
  height: 300,
  border: props.dragIn
    ? `3px dashed ${props.theme.palette.primary.main}`
    : `2px dashed ${alpha(props.theme.palette.primary.main, 0.2)}`,
  borderRadius: 4,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
  opacity: props.dragIn ? 0.5 : 1,
}));

const InputLabel = styled('label')({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  flexDirection: 'column',
  margin: 15,
});

interface ImportInvoiceXMLProps {
  onExited(): void;
  onXmlLoaded(response: any): void;
}

const ImportInvoiceXML: React.FC<ImportInvoiceXMLProps> = ({ onExited, onXmlLoaded }) => {
  const [dragIn, setDragIn] = useState(false);
  const [saving, setSaving] = useState(false);
  const { showErrorDialog } = useErrorHandler();

  async function handleUploadFiles(files: FileList | null) {
    if (!files) {
      return;
    }

    const form = new FormData();

    Array.from(files).forEach((file, index) => {
      form.append(`xml`, file);
    });

    setSaving(true);

    try {
      const response = await api.post<Document[]>('/invoice-xml-reader', form);
      onXmlLoaded(response.data);
    } catch (error) {
      showErrorDialog({
        error,
        message: 'Não foi possível importar o XML',
      });
    } finally {
      setSaving(false);
    }
  }

  function handleDropFile(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleUploadFiles(files);
    setDragIn(false);
  }

  function handleDragEnter(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  function handleDragLeave(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(false);
  }

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  return (
    <DialogInput onExited={onExited}>
      <Container
        dragIn={dragIn}
        onDrop={e => handleDropFile(e)}
        onDragLeave={e => handleDragLeave(e)}
        onDragEnter={e => handleDragEnter(e)}
        onDragOver={e => handleDragOver(e)}
        draggable
      >
        {saving ? (
          <CircularProgress />
        ) : (
          <>
            <ImportInvoiceXMLInput handleUploadFiles={handleUploadFiles} />
            <InputLabel htmlFor="xml-input">
              <Typography gutterBottom variant="body2" align="center">
                Clique para selecionar um arquivo ou arraste e solte aqui nessa área
              </Typography>
            </InputLabel>
          </>
        )}
      </Container>
    </DialogInput>
  );
};

export default ImportInvoiceXML;
