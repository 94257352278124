import React from 'react';
import { List } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { usePagination } from 'hooks/pagination';
import CommissionSellersItemModule from './CommissionSellersItemModule';
import { SellerCommission } from 'types/reports/sellerCommission';

type CommissionSellersListModuleProps = {
  sellersCommission: SellerCommission[];
};

const styles = makeStyles({
  list: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: 100,
    flex: 1,
  },
});

const CommissionSellersListModule: React.FC<CommissionSellersListModuleProps> = ({ sellersCommission }) => {
  const { rowsPerPage, page } = usePagination();
  const classes = styles();

  return (
    <List className={classes.list}>
      {sellersCommission.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(sellerCommission => (
        <CommissionSellersItemModule key={sellerCommission.vendedor} sellerCommission={sellerCommission} />
      ))}
    </List>
  );
};

export default CommissionSellersListModule;
