export type ProductTableTemplate = {
  id: string;
  description: string;
  originalId: string;
  width: number;
};

export const productTableTemplate: ProductTableTemplate[] = [
  { id: 'codigo_produto', description: 'ID', originalId: 'codigo_produto', width: 100 },
  { id: 'subgrupo', description: 'SUBGRUPO', originalId: 'subgrupo', width: 200 },
  { id: 'produto', description: 'PRODUTO', originalId: 'produto', width: 400 },
  { id: 'formattedCusto', description: 'CUSTO', originalId: 'custo', width: 100 },
  { id: 'formattedMargem', description: 'MARGEM', originalId: 'margem', width: 100 },
  { id: 'formattedP2PJ', description: 'P2PJ', originalId: 'p2pj', width: 100 },
  { id: 'formattedP2BR', description: 'P2BR', originalId: 'p2br', width: 100 },
  { id: 'formattedPromoPJ', description: 'PROMOPJ', originalId: 'promopj', width: 100 },
  { id: 'formattedMC', description: 'MC', originalId: 'mc', width: 80 },
  { id: 'formattedPercentualMC', description: 'MC%', originalId: 'mc%', width: 80 },
  { id: 'est', description: 'ESTOQUE', originalId: 'est', width: 100 },
  { id: 'grupo', description: 'GRUPO', originalId: 'grupo', width: 250 },
  { id: 'fabricante', description: 'FABRICANTE', originalId: 'fabricante', width: 100 },
  { id: 'categoria', description: 'CATEGORIA', originalId: 'categoria', width: 100 },
  { id: 'peso', description: 'PESO', originalId: 'peso', width: 100 },
  { id: 'altura', description: 'ALTURA', originalId: 'altura', width: 100 },
  { id: 'largura', description: 'LARGURA', originalId: 'largura', width: 100 },
  { id: 'formattedPmd', description: 'PMD', originalId: 'pmd', width: 100 },
  { id: 'formattedPp', description: 'PP', originalId: 'pp', width: 100 },
  { id: 'formattedPmc', description: 'PMC', originalId: 'pmc', width: 100 },
  { id: 'formattedDestaque', description: 'DESTAQUE', originalId: 'destaque', width: 100 },
];
