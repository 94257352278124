import React from 'react';
import { Person } from '@mui/icons-material';
import { ListItemButton, styled, Typography } from '@mui/material';
import { Employee } from './PersonAutocomplete';

const ListItemStyled = styled(ListItemButton)({
  alignItems: 'center',
  gap: 10,
  justifyContent: 'flex-start',
  '& svg': {
    color: '#ccc',
  },
});

interface PersonAutocompleteItemProps {
  favored: Employee;
  value: Employee | null;
  handleSelect(favored: Employee): void;
}

const PersonAutocompleteItem: React.FC<PersonAutocompleteItemProps> = ({ favored, value, handleSelect }) => {
  return (
    <ListItemStyled disabled={value?.id === favored.id} onClick={() => handleSelect(favored)}>
      <Person />
      <div>
        <Typography>{favored.nome}</Typography>
        <Typography variant="body2" color="textSecondary">
          {favored.nome}
        </Typography>
      </div>
    </ListItemStyled>
  );
};

export default PersonAutocompleteItem;
