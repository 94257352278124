import { createContext, useContext } from 'react';
import { Template } from 'types/template';

type TemplatesContextValue = {
  selectedTemplate: Template | null;
  setSelectedTemplate(template: Template | null): void;
};

const TemplateContext = createContext<TemplatesContextValue>({} as TemplatesContextValue);
export const TemplatesProvider = TemplateContext.Provider;

export function useTemplates(): TemplatesContextValue {
  const context = useContext(TemplateContext);
  return context;
}
