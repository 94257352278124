import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { useApp } from 'hooks/app';
import { usePermissionRules } from 'hooks/permissionRules';

type UserTabProps = {
  handleChange(value: string): void;
  tab: string;
};

const UserTab: React.FC<UserTabProps> = ({ tab, handleChange }) => {
  const app = useApp();
  const { checkPermission } = usePermissionRules();

  return (
    <Tabs value={tab} onChange={(e, value) => handleChange(value)} variant={app.isMobile ? 'scrollable' : 'standard'}>
      <Tab value="user" label="Usuário" />
      {checkPermission('settings.user_control.users_profiles') && <Tab value="branch" label="Filiais" />}
      <Tab value="profile" label="Perfil" />
    </Tabs>
  );
};

export default UserTab;
