import React, { useState } from 'react';
import List from 'components/list/List';
import ProjectItemModule from './RelatedProductItemModule';
import { RelatedProduct } from 'pages/related-product/types/relatedProduct';
import RelatedProductListMenu from '../RelatedProductListMenu';

type RelatedProductListModuleProps = {
  relatedProducts: RelatedProduct[];
};

const RelatedProductListModule: React.FC<RelatedProductListModuleProps> = ({ relatedProducts }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <RelatedProductListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <List>
        {relatedProducts.map(related => (
          <ProjectItemModule setAnchorEl={setAnchorEl} key={related.id} relatedProduct={related} />
        ))}
      </List>
    </>
  );
};

export default RelatedProductListModule;
