import React from 'react';
import { AbcProductCustomer } from 'types/abcProduct';
import { Typography, ListItemButton, styled } from '@mui/material';

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #ddd',
  backgroundColor: '#fff',
  '& > div': {
    gap: 10,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '&.data': {
      gap: 5,
    },
    '& > span': {
      borderRadius: 6,
      border: '1px solid #ddd',
      display: 'flex',
      flexDirection: 'column',
      '& .title': {
        backgroundColor: '#ddd',
      },
      '& > p': {
        padding: 5,
      },
    },
  },
});

type CustomerItemModuleProps = {
  customer: AbcProductCustomer;
};

const CustomerItemModule: React.FC<CustomerItemModuleProps> = ({ customer }) => {
  return (
    <CustomListItemButton>
      <Typography variant="subtitle2" color="primary">
        {customer.cliente}
      </Typography>
      <div>
        <span>
          <Typography className="title" variant="body2" fontSize={12}>
            <b>MC:</b>
          </Typography>
          <Typography color="#30519f" align="right">
            {customer.formattedMc}
          </Typography>
        </span>

        <span>
          <Typography className="title" variant="body2" fontSize={12}>
            <b>Total:</b>
          </Typography>
          <Typography color="green" align="right">
            {customer.formattedTotal}
          </Typography>
        </span>
      </div>
      <div className="data">
        <Typography fontWeight={600} variant="body2" fontSize={12}>
          Spiff:
        </Typography>
        <Typography color="#ff0000" align="right" variant="body2" fontSize={12}>
          {customer.formattedSpiff}
        </Typography>

        <Typography fontWeight={600} variant="body2" fontSize={12}>
          Custo:
        </Typography>

        <Typography align="right" variant="body2" fontSize={12}>
          {customer.formattedCoust}
        </Typography>
      </div>
    </CustomListItemButton>
  );
};

export default CustomerItemModule;
