import { Dispatch, createContext, useContext, SetStateAction } from 'react';
import { Project } from 'types/project';
import { ProjectActions } from '../reducer/types';

type ProjectContextValue = {
  project: Project;
  dispatch: Dispatch<ProjectActions>;
  handleChange(index: keyof Project, value: any): void;
  isProductSearchOpen: boolean;
  setIsProductSearchOpen: Dispatch<SetStateAction<boolean>>;
};

const ProjectContext = createContext<ProjectContextValue>({} as ProjectContextValue);
export const ProjectProvider = ProjectContext.Provider;

export function useProject(): ProjectContextValue {
  return useContext(ProjectContext);
}
