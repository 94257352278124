import { ManagedSaleProduct } from 'pages/managed-sales/types/managedSale';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export type ProductContextValue = {
  selectedProduct: ManagedSaleProduct | null;
  setSelectedProduct: Dispatch<SetStateAction<ManagedSaleProduct | null>>;
};

export const ProductsContext = createContext<ProductContextValue>({} as ProductContextValue);
export const ProductsContextConsumer = ProductsContext.Consumer;
export const ProductsContextProvider = ProductsContext.Provider;

export function useProducts(): ProductContextValue {
  return useContext(ProductsContext);
}
