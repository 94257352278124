import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField, styled } from '@mui/material';
import { TemplateValidation } from './validation/templateValidation';
import ImageSelector from 'components/image-selector/ImageSelector';
import ImagePreview from 'components/image-preview/ImagePreview';
import ImageUpload from 'components/image-upload/ImageUpload';
import { Template } from 'types/template';

const Container = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '400px 1fr',
  marginBottom: 200,
  gap: 40,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: 10,
  },
}));

const imageContainer = {
  marginTop: 20,
};

type TemplateFormProps = {
  template: Template;
  handleChange(index: keyof Template, value: any): void;
  validation: TemplateValidation;
};

const TemplateForm: React.FC<TemplateFormProps> = ({ template, handleChange, validation }) => {
  const [imageSelector, setImageSelector] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<null | string>('');

  const inputs = {
    description: useRef<HTMLInputElement>(null),
    font_color: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      {!!selectedImageSrc && (
        <ImagePreview
          src={selectedImageSrc}
          description="Imagem da mensagem de e-mail"
          onExited={() => setSelectedImageSrc('')}
        />
      )}
      {imageSelector && (
        <ImageSelector
          handleSetImageId={image => handleChange('image_id', image.id)}
          onExited={() => setImageSelector(false)}
        />
      )}
      <Container>
        <div>
          <ImageUpload
            validationError={validation.image}
            label="Template"
            handleSetImage={image => handleChange('image', image)}
            handleRemoveImage={() => handleChange('image', null)}
            image={template.image}
            containerClassname={imageContainer}
          />
        </div>
        <Grid item xs={12} xl={4} lg={4} md={6}>
          <TextField
            inputRef={inputs.description}
            error={!!validation.description}
            helperText={validation.description}
            label="Descrição"
            placeholder="Digite uma descrição para o template"
            margin="normal"
            fullWidth
            value={template.description}
            onChange={e => handleChange('description', e.target.value)}
            autoFocus
          />

          <TextField
            inputRef={inputs.font_color}
            error={!!validation.font_color}
            helperText={validation.font_color}
            label="Fonte"
            placeholder="Informe a cor da fonte"
            margin="normal"
            type="color"
            fullWidth
            value={template.font_color}
            onChange={e => handleChange('font_color', e.target.value)}
          />

          <button type="submit" style={{ display: 'none' }} />
        </Grid>
      </Container>
    </>
  );
};

export default TemplateForm;
