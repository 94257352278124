import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'services/api';
import history from 'services/history';
import PartnerActions from './CourseActions';
import PartnerForm from '../CourseForm';
import { useCourseValidation } from '../validation/courseValidation';
import { Course } from 'types/course';
import { parseISO } from 'date-fns';

const CourseUpdate: React.FC = () => {
  const [course, setCourse] = useState<Course>({} as Course);
  const [validation, setValidation, validate] = useCourseValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/courses/${id}`)
      .then(response => {
        setCourse({
          ...response.data,
          happen_at: parseISO(response.data.happen_at),
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function handleChange(index: keyof Course, value: any) {
    setCourse(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(course)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/courses/${id}`, course)
      .then(() => {
        setSaving(false);
        history.push('/site/courses');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete(`/courses/${id}`)
      .then(() => {
        setSaving(false);
        history.push('/site/courses');
        handleOpen('Excluído');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Site"
        ActionsComponent={
          <PartnerActions
            saving={saving}
            loading={loading}
            handleSubmit={handleValidation}
            handleDelete={handleDelete}
          />
        }
        backAction={() => history.push('/courses')}
      />
      <PageHeader title="Editar curso" backUrl="/courses" />
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          <form onSubmit={handleValidation}>
            <PartnerForm handleChange={handleChange} course={course} validation={validation} />
          </form>
        </Grid>
      )}
    </>
  );
};

export default CourseUpdate;
