import React, { DragEvent, HTMLAttributes, useState } from 'react';
import { Button, alpha, Theme, Typography } from '@mui/material';
import { uuidv4 } from 'helpers/uuidv4';
import { Image } from 'types/image';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import { makeStyles } from '@mui/styles';

interface MakeStyleProps {
  error: boolean;
}

const useStyles = makeStyles<Theme, MakeStyleProps>(theme => ({
  container: {
    width: '100%',
  },
  image: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#eee',
  },
  imageContainer: ({ error }: { error: boolean }) => ({
    display: 'flex',
    width: '100%',
    height: 300,
    border: `2px dashed ${error ? theme.palette.error.main : theme.palette.primary.main}`,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    background: 'url(/selfie.png) no-repeat center',
    backgroundSize: 'contain',
  }),
  inputFile: {
    display: 'flex',
    width: '100%',
    height: 300,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  inDraggableZone: {
    border: `3px dashed ${theme.palette.primary.main}`,
    opacity: 0.5,
  },
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    '& > svg': {
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
  },
  label: {
    display: 'flex',
    cursor: 'pointer',
    minWidth: 110,
  },
  icon: {
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  loading: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: 'rgba(250, 250, 250, 1)',
  },
  helperTextContainer: {
    marginTop: 20,
  },
  stepBadge: {
    width: 20,
    height: 20,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    marginRight: 7,
    color: theme.palette.primary.main,
  },
}));

interface ImageSelfieUploadProps extends HTMLAttributes<HTMLDivElement> {
  image: Image | null;
  validationError?: string;
  label: string;
  handleRemoveImage(): void;
  handleSetImage(image: Image | null): void;
  containerClassname?: string;
  disabled?: boolean;
  helperText?: string;
}

const ImageSelfieUpload: React.FC<ImageSelfieUploadProps> = ({
  image,
  handleRemoveImage,
  handleSetImage,
  validationError,
  label,
  className,
  containerClassname,
  disabled = false,
  helperText,
  ...rest
}) => {
  const classes = useStyles({ error: !!validationError });
  const [selected, setSelected] = useState(false);
  const [dragIn, setDragIn] = useState(false);
  const [saving, setSaving] = useState(false);
  const uuid = uuidv4();
  const messaging = useMessaging();

  function handleUploadFiles(files: FileList | null) {
    if (!files) return;

    const form = new FormData();
    Array.from(files).forEach((file, index) => {
      form.append(`files[${index}]`, file);
    });

    setSaving(true);

    api
      .post('/images', form)
      .then(response => {
        handleSetImage(response.data[0]);
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível carregar a imagem');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDropFile(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleUploadFiles(files);
    setDragIn(false);
  }

  function handleDragEnter(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  function handleDragLeave(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(false);
  }

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  return (
    <div className={`${classes.container} ${className}`}>
      {!image ? (
        <div
          {...rest}
          className={
            dragIn
              ? `${classes.imageContainer} ${classes.inDraggableZone} ${containerClassname}`
              : `${classes.imageContainer} ${containerClassname}`
          }
          onDrop={e => handleDropFile(e)}
          onDragLeave={e => handleDragLeave(e)}
          onDragEnter={e => handleDragEnter(e)}
          onDragOver={e => handleDragOver(e)}
          draggable
        >
          {saving ? (
            <Typography variant="body2" color="textSecondary">
              carregando...
            </Typography>
          ) : (
            <>
              <input
                type="file"
                name="photo"
                accept="image/*"
                id={uuid}
                onChange={event => handleUploadFiles(event.target.files)}
                style={{ display: 'none' }}
              />
              <label htmlFor={uuid} className={classes.inputFile}>
                <Typography gutterBottom variant="body2" align="center">
                  {helperText || 'clique para selecionar um arquivo ou arraste e solte aqui nessa área'}
                </Typography>
              </label>
            </>
          )}
        </div>
      ) : (
        <div
          className={`${classes.imageContainer} ${containerClassname}`}
          onClick={!disabled ? () => setSelected(selected => !selected) : undefined}
        >
          {selected && (
            <div className={classes.imageWrapper}>
              <Button size="small" variant="contained" color="primary" onClick={handleRemoveImage}>
                Remover
              </Button>
            </div>
          )}
          <img alt="Foto do produto" className={classes.image} src={image.imageUrl} />
        </div>
      )}
      {validationError ? (
        <Typography variant="body2" color="error">
          {validationError}
        </Typography>
      ) : (
        <Typography variant="body2">{label}</Typography>
      )}

      <div className={classes.helperTextContainer}>
        <Typography variant="body2" gutterBottom>
          Desta maneira podemos saber que ninguém está se passando por você. Por isso:
        </Typography>
        <Typography variant="body2" gutterBottom>
          <span className={classes.stepBadge}>1</span> <strong>Documento próximo ao rosto</strong> porém sem cobrí-lo
        </Typography>
        <Typography variant="body2">
          <span className={classes.stepBadge}>2</span>
          <strong>Não use acessórios</strong> como boné ou óculos de sol
        </Typography>
      </div>
    </div>
  );
};

export default ImageSelfieUpload;
