import { Typography, styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import React from 'react';
import { AIProduct } from 'types/aiProduct';
import { Product } from 'types/product';
import AiProductInfo from './AiProductInfo';
import { StockBalanceData } from 'types/reports/stockBalance';

interface AiProductH2iProps {
  products: AIProduct[];
  stockBalance: StockBalanceData;
  productH2i: Product | null;
  productName: string;
  averagePrice: number;
}

const Body = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 50,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const Container = styled('div')(({ theme }) => ({
  width: 450,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const Span = styled('span')(({ theme }) => ({
  fontSize: 13,
  '&.description': {
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
  },
  '&.lowest': {
    backgroundColor: theme.palette.error.main,
    borderBottom: `2px solid ${theme.palette.error.dark}`,
  },
  '&.high': {
    backgroundColor: theme.palette.success.main,
    borderBottom: `2px solid ${theme.palette.success.dark}`,
  },
  '&.title': {
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.dark}`,
  },
  '&.h2i': {
    backgroundColor: theme.palette.secondary.main,
    borderBottom: `2px solid ${theme.palette.secondary.dark}`,
  },
  minWidth: 100,
  minHeight: 60,
  color: '#fff',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 500,
  display: 'flex',
  height: '100%',
  width: '100%',
}));

const Content = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Header = styled('header')({
  marginBottom: 15,
  padding: '5px 15px',
  backgroundColor: '#30519f',
});

const AiProductH2i: React.FC<AiProductH2iProps> = ({
  productH2i,
  products,
  productName,
  averagePrice,
  stockBalance,
}) => {
  return (
    <Body>
      <Container>
        <Header>
          <Typography variant="body2" color="#fff">
            {productName || productH2i?.produto}
          </Typography>
        </Header>
        <Container>
          <Content>
            <Span className="title">Vendido por</Span>
            <Span className="title">Preço</Span>
            <Span className="title">Diferença</Span>
          </Content>

          <Content>
            <Span className="description">H2i</Span>
            <Span className="h2i">{productH2i?.p2pj ? moneyFormat(productH2i.p2pj) : 'Não encontrado'}</Span>
            <Span className="h2i">--</Span>
          </Content>

          {products.map((product, index) => (
            <Content key={index}>
              <Span className="description">{product.seller}</Span>
              <Span className={productH2i && product.price < productH2i.p2pj ? 'lowest' : 'high'}>
                {moneyFormat(product.price)}
              </Span>
              <Span className={productH2i && product.price < productH2i.p2pj ? 'lowest' : 'high'}>
                {moneyFormat(productH2i && product.price - productH2i.p2pj)}
              </Span>
            </Content>
          ))}
        </Container>
      </Container>
      <AiProductInfo stockBalance={stockBalance} averagePrice={averagePrice} productH2i={productH2i} />
    </Body>
  );
};

export default AiProductH2i;
