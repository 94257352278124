import Dialog from 'components/dialogs/Dialog';
import React, { FormEvent, useEffect, useState } from 'react';
import { NewInvoiceProvider } from './hooks/useNewInvoice';
import { useInvoiceReducer } from './reducer/reduce';
import NewInvoiceForm from './form/NewInvoiceForm';
import { useNewInvoiceValidation } from './validation/newInvoiceValidation';
import NewInvoiceActions from './NewInvoiceActions';
import InsideSaving from 'components/loading/InsideSaving';
import { useApp } from 'hooks/app';
import { format } from 'date-fns';
import { NewInvoiceFavored } from './reducer/types';
import { invoiceChange } from './reducer/actions';
import { moneyFormat } from 'helpers/numberFormat';
import { Invoice } from 'types/invoices';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { TotalizingAccount } from 'types/totalizingAccount';
import { useSelector } from 'store/selector';
import { invoiceColors, invoiceTypes } from '../Invoices';
import { styled } from '@mui/material';

interface NewInvoiceProps {
  onExited(): void;
  handleUpdateList(invoice: Invoice): void;
  type: string;
}

const Container = styled('div')({
  padding: '5px 20px',
});

const NewInvoice: React.FC<NewInvoiceProps> = ({ onExited, handleUpdateList, type }) => {
  const [invoice, dispatch] = useInvoiceReducer();
  const user = useSelector(state => state.user);
  const [validation, setValidation, validate] = useNewInvoiceValidation();
  const [saving, setSaving] = useState(false);
  const { financialApi } = useApp();
  const [favored, setFavored] = useState<NewInvoiceFavored | null>(null);
  const [accounts, setAccounts] = useState<TotalizingAccount[]>([]);

  useEffect(() => {
    financialApi
      .get('/getcontastotalizadoras', {
        params: {
          id_filial: invoice.branchId,
          filtro: '',
        },
      })
      .then(response => {
        if (response.data.MESSAGE) {
          setAccounts([]);
          return;
        }
        setAccounts(
          response.data.map(item => {
            item.formattedSaldo = moneyFormat(item.saldo);
            item.data = new Date(parsePtBRDate(item.data_inicio));
            return item;
          }),
        );
      })
      .catch(err => console.error(err));
  }, [financialApi, invoice.branchId]);

  useEffect(() => {
    dispatch(invoiceChange('type', type));
  }, [dispatch, type]);

  useEffect(() => {
    dispatch(invoiceChange('favoredId', favored ? favored.id : null));
  }, [favored, dispatch]);

  useEffect(() => {
    setFavored(null);
  }, [invoice.branchId, invoice.type]);

  async function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});

    await validate(invoice, favored?.tipo_valores ?? 'M');
    await handleSubmit();
  }

  async function handleSubmit() {
    if (!user) {
      return;
    }

    if (!financialApi) {
      return;
    }

    try {
      setSaving(true);

      const data = {
        usuario: user.name,
        id_conta_totalizadora: invoice.totalizingAccountId === 999 ? '' : invoice.totalizingAccountId,
        id_centro_custo: invoice.costCenterId === 999 ? '' : invoice.costCenterId,
        id_filial: invoice.branchId,
        id_favorecido: favored?.id,
        titulo: invoice.invoiceDescription,
        tipo: type === 'TT' ? invoice.type : type,
        vencimento: invoice.payday ? format(invoice.payday, 'dd/MM/yyyy') : '',
        valor: invoice.value,
        mes_ano: invoice.monthYear ? format(invoice.monthYear, 'MMyy') : '',
        numero_nf: invoice.invoiceNumber,
        chave_nf: invoice.invoiceKey,
        data_apropriacao: invoice.apropriatedAt ? format(invoice.apropriatedAt, 'dd/MM/yyyy') : '',
        data_pagamento: invoice.paidAt ? format(invoice.paidAt, 'dd/MM/yyyy') : '',
        sem_boleto: invoice.noBankBillet ? 'S' : 'N',
        credito_rma: invoice.rmaCredit ? 'S' : 'N',
        nota_validada: invoice.validatedInvoice ? 'S' : 'N',
        transportadora: invoice.isShippingCompany ? 'S' : 'N',
      };

      await financialApi.post('/savetitulo', data);

      setSaving(false);

      handleUpdateList({
        id: 0,
        cnpj: '',
        conta_totalizadora: '',
        id_conta_totalizadora: typeof data.id_conta_totalizadora === 'string' ? 0 : data.id_conta_totalizadora,
        id_centro_custo: typeof data.id_centro_custo === 'string' ? 0 : data.id_centro_custo,
        transportadora: data.transportadora,
        fornecedor: 'string',
        filial: 'string',
        data_lanc: 'string',
        hora_lanc: 'string',
        id_filial: data.id_filial,
        id_favorecido: data.id_favorecido as any,
        mes_ano: data.mes_ano,
        titulo: data.titulo,
        vencimento: data.vencimento,
        valor: data.valor,
        tipo: data.tipo,
        boleto: data.sem_boleto,
        nota_fiscal: data.numero_nf,
        apropriado: '',
        credito_rma: data.credito_rma,
        banco: '',
        validada: data.nota_validada,
        data_pagamento: data.data_pagamento,
        formattedType: '',
        formattedValue: moneyFormat(data.valor),
        data_apropriacao: data.data_apropriacao,
        chave_nfe: data.chave_nf as any,
      });
    } finally {
      setSaving(false);
    }
  }

  return (
    <NewInvoiceProvider value={{ invoice, accounts, dispatch, favored, setFavored }}>
      <Dialog
        maxWidth="md"
        onExited={onExited}
        headerbackground="#fff"
        titleColor={invoiceColors[type]}
        title={invoiceTypes[type]}
        ComponentActions={<NewInvoiceActions type={type} apropriatedAt={false} handleValidation={handleValidation} />}
      >
        {saving && <InsideSaving />}

        <Container>
          <NewInvoiceForm type={type} validation={validation} />
        </Container>
      </Dialog>
    </NewInvoiceProvider>
  );
};

export default NewInvoice;
