import { FormEvent, useCallback, useState } from 'react';
import { useApp } from 'hooks/app';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { subDays, format } from 'date-fns';
import { Justification } from 'types/justification';
import { ptBR } from 'date-fns/locale';
import { usePermissionRules } from 'hooks/permissionRules';
import { useSelector } from 'store/selector';

const occurrenceType = {
  entrada_manha: 'Entrada manhã',
  saida_manha: 'Saída manhã',
  entrada_tarde: 'Entrada tarde',
  saida_tarde: 'Saída tarde',
  dia_todo: 'Dia todo',
};

export interface JustificationQueryParams {
  nome: string;
  lancada: string;
  id_filial: number;
  data_ini: Date;
  data_fim: Date;
}

const queryParamsInitialValue: JustificationQueryParams = {
  nome: '',
  data_fim: new Date(),
  data_ini: subDays(new Date(), 7),
  id_filial: 1001,
  lancada: 'N',
};

export function useFetchJustifications() {
  const { financialApi } = useApp();
  const user = useSelector(state => state.user);
  const { checkPermission } = usePermissionRules();
  const [queryParams, setQueryParams] = useState<JustificationQueryParams>(queryParamsInitialValue);
  const [loading, setLoading] = useState(false);
  const [justifications, setJustifications] = useState<Justification[]>([]);

  const fetch = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      setLoading(true);

      financialApi
        .get('/getrhocorrencia', {
          params: {
            ...queryParams,
            tipo: "'J'",
            lancada: queryParams.lancada === 'N' ? '' : 'S',
            data_ini: format(queryParams.data_ini, 'P', { locale: ptBR }),
            data_fim: format(queryParams.data_fim, 'P', { locale: ptBR }),
            id_filial: queryParams.id_filial === 1001 ? '' : queryParams.id_filial,
            cpf: checkPermission('rh.justification.allow-query') ? '' : user?.document_number,
          },
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setJustifications([]);
            return;
          }
          setJustifications(
            response.data.rh.map(item => ({
              ...item,
              data_ocorrencia: parsePtBRDate(item.data_ocorrencia).toISOString(),
              formattedDate: format(parsePtBRDate(item.data_ocorrencia), 'P', { locale: ptBR }),
              formattedOcorrencia: occurrenceType[item.ocorrencia],
            })),
          );
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [queryParams, financialApi, checkPermission, user],
  );

  function handleChangeQueryParams(index: keyof JustificationQueryParams, value: any) {
    setQueryParams(queryParams => ({
      ...queryParams,
      [index]: value,
    }));
  }

  return {
    fetch,
    loading,
    queryParams,
    handleChangeQueryParams,
    justifications,
    setLoading,
  };
}
