import { TableTemplate } from 'types/tableTemplate';

export const customerAgentTableTemplate: TableTemplate[] = [
  { id: 'acoes', description: 'AÇÕES', originalId: 'acoes', notFilterable: true, notSearchable: true, width: 70 },
  { id: 'nome', description: 'NOME', originalId: 'nome', width: 400 },
  { id: 'cpf', description: 'CPF', originalId: 'cpf', width: 150 },
  { id: 'rg', description: 'RG/IE', originalId: 'rg', width: 150 },
  { id: 'cep', description: 'CEP', originalId: 'cep', width: 150 },
  { id: 'endereco', description: 'ENDEREÇO', originalId: 'endereco', width: 400 },
  { id: 'cidade', description: 'CIDADE', originalId: 'cidade', width: 150 },
  { id: 'telefone', description: 'TELEFONE', originalId: 'telefone', width: 150 },
  { id: 'email', description: 'E-mail', originalId: 'email', width: 150 },
];
