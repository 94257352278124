import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { ProjectAction } from 'types/project';

export type ProjectActionsContextValue = {
  selectedProjectAction: ProjectAction | null;
  setSelectedProjectAction: Dispatch<SetStateAction<ProjectAction | null>>;
};

export const ProjectActionContext = createContext<ProjectActionsContextValue>({} as ProjectActionsContextValue);
export const ProjectActionContextConsumer = ProjectActionContext.Consumer;
export const ProjectActionContextProvider = ProjectActionContext.Provider;

export function useProjectActions(): ProjectActionsContextValue {
  return useContext(ProjectActionContext);
}
