import { TableTemplate } from 'types/tableTemplate';

export const rentalPaymentsTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 70,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'external_id',
    description: 'Número de identificação',
    originalId: 'external_id',
    width: 400,
  },
  {
    id: 'formattedValue',
    description: 'VALOR R$',
    originalId: 'value',
    width: 200,
  },
  {
    id: 'formattedPaidAt',
    description: 'Data do pagamento',
    originalId: 'formattedPaidAt',
    width: 200,
  },
];
