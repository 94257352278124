import React, { useEffect, useState } from 'react';
import { useCommercialBi } from '../../hooks/useCommercialBi';
import CommercialBiCityListTable from './table/CommercialBiSegmentListTable';
import useTableOrder from 'hooks/tableOrder';
import { CommercialBiSegment } from '../../types/commercial-bi';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { styled } from '@mui/material';

const Container = styled('div')({
  padding: 20,
  // backgroundColor: '#f5f5f5',
});

const CommercialBiSegments: React.FC = () => {
  const { data } = useCommercialBi();
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<CommercialBiSegment[]>([]);

  useEffect(() => {
    setFiltered(data.product_segments);
  }, [data.product_segments]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <PaginationProvider>
      <Container>
        <CommercialBiCityListTable handleSort={handleSort} orderedIndex={orderedIndex} segments={filtered} />
        <Pagination count={filtered.length} />
      </Container>
    </PaginationProvider>
  );
};

export default CommercialBiSegments;
