import React from 'react';
import { usePriceProtection } from '../hook/usePriceProtection';
import PriceProtectionStepTwo from './PriceProtectionStepTwo';
import PaginationProvider from 'hooks/pagination';
import PriceProtectionStepThree from './PriceProtectionStepThree';
import PriceProtectionStepOne from './PriceProtectionStepOne';

const PriceProtectionSteps: React.FC = () => {
  const { step } = usePriceProtection();

  function renderPage() {
    switch (step) {
      case 1:
        return <PriceProtectionStepOne />;
      case 2:
        return (
          <PaginationProvider>
            <PriceProtectionStepTwo />
          </PaginationProvider>
        );
      case 3:
        return (
          <PaginationProvider>
            <PriceProtectionStepTwo />
            <PriceProtectionStepThree />
          </PaginationProvider>
        );
    }
  }

  return <>{renderPage()}</>;
};

export default PriceProtectionSteps;
