import { Switch, Typography, styled } from '@mui/material';
import React from 'react';
import { AccessRule } from 'types/accessRule';

type ProfileFormAccessRulesProps = {
  accessRules: AccessRule[];
  handleAccessRulesChange(id: number): void;
  accessRuleId: number;
};

const FormControl = styled('div')({
  paddingLeft: 15,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 0 0',
});

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ProfileFormAccessRules: React.FC<ProfileFormAccessRulesProps> = ({
  accessRules,
  handleAccessRulesChange,
  accessRuleId,
}) => {
  return (
    <>
      {accessRules.map(rule => (
        <FormControl key={rule.id}>
          <Header>
            <Typography textTransform="uppercase" variant="body2">
              {rule.title}
            </Typography>
            <Switch color="primary" onChange={() => handleAccessRulesChange(rule.id)} checked={rule.enabled} />
          </Header>
          <Typography variant="body2" color="textSecondary">
            {rule.description}
          </Typography>
          {rule.access_rules.length > 0 && (
            <ProfileFormAccessRules
              accessRules={rule.access_rules}
              handleAccessRulesChange={handleAccessRulesChange}
              accessRuleId={rule.id}
            />
          )}
        </FormControl>
      ))}
    </>
  );
};

export default ProfileFormAccessRules;
