import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import { moneyFormat } from 'helpers/numberFormat';
import PrintData from 'components/print/PrintData';
import { useInvoices } from '../hooks/useInvoices';
import { styled } from '@mui/material';
import InvoicesReportHeader from './InvoicesReportHeader';
import InvoicesReportFooter from './InvoicesReportFooter';

const ColumnPL = styled('p')({
  fontSize: '8pt',
  textAlign: 'start',
});

const ColumnP = styled('p')({
  fontSize: '8pt',
  textAlign: 'end',
});

const Row = styled('div')({
  display: 'grid',
  gap: 5,
  gridTemplateColumns: '120px 1fr 1fr 100px 100px 100px 100px 100px',
  marginBottom: 1,
  paddingBottom: 1,
});

interface InvoicesReportProps {
  onExited(): void;
}

const InvoicesReport: React.FC<InvoicesReportProps> = ({ onExited }) => {
  const { invoices: data } = useInvoices();

  return (
    <Dialog fullScreen title="Boletos" onExited={onExited} ComponentActions={<PrintData />}>
      <div className="page">
        <InvoicesReportHeader />
        <Row className="table-header">
          <ColumnPL>Filial</ColumnPL>
          <ColumnPL>Título</ColumnPL>
          <ColumnPL>Conta</ColumnPL>
          <ColumnPL>CNPJ</ColumnPL>
          <ColumnPL>Lançamento</ColumnPL>
          <ColumnPL>Pagamento</ColumnPL>
          <ColumnPL>Vencimento</ColumnPL>
          <ColumnP>Valor</ColumnP>
        </Row>

        {data.map(item => (
          <Row key={item.id}>
            <ColumnPL>{item.filial}</ColumnPL>
            <ColumnPL>{item.titulo}</ColumnPL>
            <ColumnPL>{item.conta_totalizadora}</ColumnPL>
            <ColumnPL>{item.cnpj}</ColumnPL>
            <ColumnPL>{item.data_lanc}</ColumnPL>
            <ColumnPL>{item.data_pagamento}</ColumnPL>
            <ColumnPL>{item.vencimento}</ColumnPL>
            <ColumnP>{moneyFormat(item.valor)}</ColumnP>
          </Row>
        ))}

        <InvoicesReportFooter />
      </div>
    </Dialog>
  );
};

export default InvoicesReport;
