import React, { useState } from 'react';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import SalesReportCustomDate from './DashboardReportCustomDate';
import { Button, IconButton, MenuItem, Select, Typography } from '@mui/material';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import { FilterDashboardCommercial } from 'pages/dashboard/commercial/hooks/useFetchCommercialReport';
import SelectBranch from 'components/select-branches/SelectBranch';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 15,
    '& button': {
      marginBottom: 10,
    },
  },
});

type DashboardReportDateFilterProps = {
  loading: boolean;
  dateConfig: number | null;
  handleDateConfig(days: number): void;
  onExited(): void;
  handleConfirmCustomDates(initialDate: Date, finalDate: Date): void;
  filter: FilterDashboardCommercial;
  handleChangeFilter(index: keyof FilterDashboardCommercial, value: any): void;
};

const DashboardReportDateFilter: React.FC<DashboardReportDateFilterProps> = ({
  loading,
  dateConfig,
  handleDateConfig,
  onExited,
  handleConfirmCustomDates,
  filter,
  handleChangeFilter,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(days: number, handleClose: () => void) {
    handleDateConfig(days);
    handleClose();
  }

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <SalesReportCustomDate
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        handleConfirmCustomDates={handleConfirmCustomDates}
      />
      <DialogInputConsumer>
        {({ handleClose }) => (
          <>
            <SelectBranch
              branch_id={filter.branch_id}
              handleChange={e => handleChangeFilter('branch_id', e)}
              loading={loading}
            />
            <Select
              disabled={loading}
              label="tipo de venda"
              value={filter.type_sale}
              onChange={e => handleChangeFilter('type_sale', e.target.value as string)}
            >
              <MenuItem value="T">Todas</MenuItem>
              <MenuItem value="C">Coligadas</MenuItem>
              <MenuItem value="V">Vendas</MenuItem>
            </Select>
            <Typography align="center">Escolha o período do relatório de vendas</Typography>
            <div className={classes.buttons}>
              <Button
                disabled={loading}
                variant="text"
                size="medium"
                color={dateConfig === 0 ? 'primary' : 'inherit'}
                onClick={() => handleClick(0, handleClose)}
              >
                Hoje
              </Button>
              <Button
                disabled={loading}
                variant="text"
                size="medium"
                color={dateConfig === 15 ? 'primary' : 'inherit'}
                onClick={() => handleClick(15, handleClose)}
              >
                15 dias
              </Button>
              <Button
                disabled={loading}
                variant="text"
                size="medium"
                color={dateConfig === 30 ? 'primary' : 'inherit'}
                onClick={() => handleClick(30, handleClose)}
              >
                30 dias
              </Button>
              <Button
                disabled={loading}
                variant="text"
                size="medium"
                color={dateConfig === 60 ? 'primary' : 'inherit'}
                onClick={() => handleClick(60, handleClose)}
              >
                60 dias
              </Button>
              <IconButton
                disabled={loading}
                color={dateConfig === null ? 'primary' : 'inherit'}
                onClick={e => setAnchorEl(e.currentTarget)}
              >
                <CalendarIcon />
              </IconButton>
            </div>
          </>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default DashboardReportDateFilter;
