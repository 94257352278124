import { useApp } from 'hooks/app';
import useTableOrder, { OrderIndexData } from 'hooks/tableOrder';
import { Dispatch, SetStateAction, useCallback, useState, FormEvent, useEffect } from 'react';
import { getCancelTokenSource } from 'services/api';
import { H2iCustomer } from 'types/h2iCustomer';

type UseFetchCustomers = [
  (customerId: number) => Promise<void>,
  (index: string) => void,
  (customer: H2iCustomer) => H2iCustomer,
  boolean,
  boolean,
  OrderIndexData,
  Dispatch<SetStateAction<H2iCustomer[]>>,
  Dispatch<SetStateAction<string>>,
  string,
  Dispatch<SetStateAction<string>>,
  string,
  H2iCustomer[],
  () => void,
];

let timer: NodeJS.Timeout;

export function useFetchCustomers(): UseFetchCustomers {
  const [saving, setSaving] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { h2iApi } = useApp();
  const [indexToSearch, setIndexToSearch] = useState('nome');
  const [customers, setCustomers] = useState<H2iCustomer[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<H2iCustomer[]>([]);

  useEffect(() => {
    setFilteredCustomers(customers);
  }, [customers]);

  const fetchCustomer = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (!h2iApi) return;
      const source = getCancelTokenSource();
      let request = true;
      if (searchText.length > 0 && searchText.length < 3) return;

      clearTimeout(timer);

      timer = setTimeout(() => {
        setLoading(true);

        h2iApi
          .get(
            `/api/getclientescadastrados?filtro=${indexToSearch === 'nome' ? searchText : ''}&cnpj=${
              indexToSearch === 'documento' ? searchText : ''
            }`,
            {
              cancelToken: source.token,
            },
          )
          .then(response => {
            if (request) {
              if (response.data.RESULT) {
                setCustomers([]);
                return;
              }
              const _customers = response.data.map(customer => {
                customer.formattedApproved = customer.aprovado ? 'Sim' : 'Não';
                return customer;
              });

              setCustomers(_customers);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            if (request) setLoading(false);
          });
      }, 500);

      return () => {
        if (request) request = false;
        source.cancel();
      };
    },
    [h2iApi, indexToSearch, searchText],
  );

  async function handleCustomerDelete(customerId: number) {
    function deleteCustomer(): Promise<void> {
      return new Promise(resolve => {
        setTimeout(() => {
          console.log('excluindo...');
          resolve();
        }, 2000);
      });
    }

    try {
      setSaving(true);
      await deleteCustomer();
      setCustomers(state => state.filter(customer => customer.codigo !== customerId));
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false);
    }
  }

  function handleSort(index: string) {
    const filtered = sort(index, filteredCustomers);
    setFilteredCustomers(filtered);
  }

  function handleFormatCustomer(customer: H2iCustomer): H2iCustomer {
    console.log(customer);
    return customer;
  }

  return [
    handleCustomerDelete,
    handleSort,
    handleFormatCustomer,
    loading,
    saving,
    orderedIndex,
    setCustomers,
    setSearchText,
    searchText,
    setIndexToSearch,
    indexToSearch,
    filteredCustomers,
    fetchCustomer,
  ];
}
