import React, { useState, useEffect } from 'react';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { ListAlt } from '@mui/icons-material';
import { useApp } from 'hooks/app';
import { format } from 'date-fns';
import { InvoiceSummary } from 'types/summary/invoiceSummary';
import InsideLoading from 'components/loading/InsideLoading';
import NoData from 'components/no-data/NoData';
import useTableOrder from 'hooks/tableOrder';
import TableContainer from 'components/table/TableContainer';
import { moneyFormat } from 'helpers/numberFormat';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { useFinancialSummary } from '../hooks/useFinancialSummary';
import { FaFileExcel } from 'react-icons/fa';
import { usePermissionRules } from 'hooks/permissionRules';
import FinancialSummaryExport from '../export/FinancialSummaryExport';
import { invoicesTableTemplate } from '../list/invoices/invoicesTableTemplate';
import SummaryListInvoices from '../list/invoices/SummaryListInvoices';

const Box = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #ddd',
});

const Title = styled('div')({
  gap: 5,
  display: 'flex',
  alignItems: 'center',
  padding: '10px 15px',
  justifyContent: 'space-between',
  borderRadius: '6px 6px 0px 0px',
  border: '1px solid #ed6c02',
  '& svg': {
    color: '#000',
  },
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const StyledIconButton = styled(IconButton)({
  '& svg': {
    height: 20,
    width: 20,
  },
});

const Description = styled('span')({
  gap: 5,
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
});

const SummaryInvoices: React.FC = () => {
  const { financialApi } = useApp();
  const { filter } = useFinancialSummary();
  const [loading, setLoading] = useState(false);
  const { checkPermission } = usePermissionRules();
  const [showTitles, setShowTitles] = useState(false);
  const [excelExport, setExcelExport] = useState(false);
  const [titles, setTitles] = useState<InvoiceSummary[]>([]);
  const [filtered, setFiltered] = useState<InvoiceSummary[]>([]);
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(titles);
  }, [titles]);

  useEffect(() => {
    setLoading(true);
    financialApi
      .get('/gettitulosfilial', {
        params: {
          data_ini: filter.initial_date ? format(filter.initial_date, 'dd/MM/yyyy') : '',
          data_fim: filter.final_date ? format(filter.final_date, 'dd/MM/yyyy') : '',
          id_filial: filter.branchIds.join(','),
          tipo: "'T'",
          mes_ano: filter.month_year ? format(filter.month_year, 'MMyy') : '',
        },
      })
      .then(response => {
        setTitles(
          response.data.titulos.map(item => {
            item.formattedValue = moneyFormat(item.valor);
            return item;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, financialApi]);

  function handleClick() {
    setShowTitles(!showTitles);
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <div>
      {excelExport && <FinancialSummaryExport onExited={() => setExcelExport(false)} data={filtered} />}
      <Title>
        <Description onClick={handleClick}>
          <ListAlt fontSize="medium" />
          <Typography fontWeight="bold" variant="body1">
            Títulos
          </Typography>
        </Description>
        {checkPermission('general.excel_export_button') && (
          <Tooltip title="Exportar excel">
            <StyledIconButton disabled={!filtered.length} onClick={() => setExcelExport(true)}>
              <FaFileExcel />
            </StyledIconButton>
          </Tooltip>
        )}
      </Title>
      {showTitles && (
        <TableContainer tableTemplate={invoicesTableTemplate}>
          <Box>
            {loading ? (
              <InsideLoading />
            ) : filtered.length > 0 ? (
              <PaginationProvider>
                <Container>
                  <SummaryListInvoices invoices={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
                  <Pagination count={filtered.length} />
                </Container>
              </PaginationProvider>
            ) : (
              <NoData message="Sem título para mostrar" />
            )}
          </Box>
        </TableContainer>
      )}
    </div>
  );
};

export default SummaryInvoices;
