import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { Cfop } from 'types/cfop';
import FiscalCodeItemModule from './FiscalCodeItemModule';

type FiscalCodeListModuleProps = {
  fiscalCodes: Cfop[];
};

const FiscalCodeListModule: React.FC<FiscalCodeListModuleProps> = ({ fiscalCodes }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {fiscalCodes.map(fiscalCode => (
            <FiscalCodeItemModule key={fiscalCode.id} fiscalCode={fiscalCode} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default FiscalCodeListModule;
