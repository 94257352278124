import React, { useEffect, useRef } from 'react';
import { RegisterForm as RegisterFormType } from 'types/registerForm';
import MaskedInput from 'react-text-mask';
import { RegisterPartnerValidation } from './validation/registerPartnerValidation';
import { TextField } from '@mui/material';
import CpfInput from 'components/masked-input/CpfInput';
import PhoneInput from 'components/masked-input/PhoneInput';
interface RegisterFormPartnerProps {
  register: RegisterFormType;
  handleChange(index: keyof RegisterFormType, value: any): void;
  validation: RegisterPartnerValidation;
}

const RegisterFormPartner: React.FC<RegisterFormPartnerProps> = ({ register, handleChange, validation }) => {
  const inputs = {
    partner_document: useRef<MaskedInput>(null),
    partner_rg: useRef<HTMLInputElement>(null),
    partner_name: useRef<HTMLInputElement>(null),
    partner_phone: useRef<MaskedInput>(null),
  };

  useEffect(() => {
    inputs.partner_name.current?.focus();
  }, [inputs.partner_name]);

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    if (key === 'partner_document' || key === 'partner_phone') {
      inputs[key].current?.inputElement.focus();
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line
  return (
    <>
      <TextField
        inputRef={inputs.partner_name}
        error={!!validation.partner_name}
        helperText={validation.partner_name}
        label="nome do sócio"
        placeholder="informe o nome do sócio"
        value={register.partner_name}
        onChange={e => handleChange('partner_name', e.target.value)}
        autoCapitalize="words"
        margin="normal"
      />

      <TextField
        inputRef={inputs.partner_phone}
        error={!!validation.partner_phone}
        helperText={validation.partner_phone}
        label="telefone / whatsapp"
        placeholder="telefone do sócio"
        value={register.partner_phone}
        onChange={e => handleChange('partner_phone', e.target.value)}
        type="phone"
        autoComplete="phone"
        InputProps={{
          inputComponent: PhoneInput as any,
        }}
        margin="normal"
        inputMode="numeric"
      />

      <TextField
        inputRef={inputs.partner_document}
        placeholder="informe seu CPF"
        label="cpf do sócio"
        value={register.partner_document}
        onChange={e => handleChange('partner_document', e.target.value)}
        error={!!validation.partner_document}
        helperText={validation.partner_document}
        InputProps={{
          inputComponent: CpfInput as any,
        }}
        margin="normal"
        inputMode="numeric"
      />

      <TextField
        inputRef={inputs.partner_rg}
        label="rg do sócio"
        placeholder="informe o rg do sócio"
        value={register.partner_rg}
        onChange={e => handleChange('partner_rg', e.target.value)}
        error={!!validation.partner_rg}
        helperText={validation.partner_rg}
        margin="normal"
        inputMode="numeric"
      />
    </>
  );
};

export default RegisterFormPartner;
