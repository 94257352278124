import { cnpjValidator } from 'helpers/cnpjValidator';
import { cpfValidator } from 'helpers/cpfValidator';
import { useApp } from 'hooks/app';
import { Dispatch, SetStateAction, useState } from 'react';
import { RegisterForm } from 'types/registerForm';
import * as yup from 'yup';

export interface RegisterCompanyValidation {
  document?: string;
  company_name?: string;
  company_trade_name?: string;
  email?: string;
  phone?: string;
  ie?: string;
}

type UseRegisterCompanyValidation = [
  RegisterCompanyValidation,
  Dispatch<SetStateAction<RegisterCompanyValidation>>,
  (register: RegisterForm, type?: 'update' | 'store') => Promise<void>,
];

export function useRegisterCompanyValidation(): UseRegisterCompanyValidation {
  const { h2iApi } = useApp();
  async function handleValidation(register: RegisterForm, type = 'store') {
    const schema = yup.object().shape({
      phone: yup.string().required('O telefone é obrigatório'),
      email: yup.string().email('Informe um e-mail válido').required('O e-mail de contato é obrigatório'),
      company_trade_name: yup.string().required('O nome fantasia é obrigatório'),
      company_name: yup.string().required('A razão social é obrigatória'),
      ie: yup.string().required('A inscrição estadual é obrigatória'),
      document: yup
        .string()
        .transform((value, originalValue) => {
          return originalValue ? originalValue.replace(/\D/g, '') : '';
        })
        .required(`CNPJ ou CPF é obrigatório`)
        .test('docValidation', `documento inválido`, value => {
          if (!value) return false;

          const cnpj = cnpjValidator(value);
          const cpf = cpfValidator(value);

          if (type === 'store') return cnpj;

          return cnpj || cpf;
        })
        .test('docExists', 'Esse documento já está registrado', async (value: string | undefined | null) => {
          if (type === 'update') return true;

          if (!value) return false;

          const response = await h2iApi?.get('/api/cliente', { params: { cnpj: value, pw: '' } });
          const user = response?.data[0];
          if (user.aprovado) return false;
          return true;
        }),
    });

    try {
      await schema.validate(register);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<RegisterCompanyValidation>({});
  return [validation, setValidation, handleValidation];
}
