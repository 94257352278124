import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Bill } from 'types/bill';

type BillsContextValue = {
  selectedBill: Bill | null;
  setSelectedBill(banner: Bill | null): void;
  handleCancel(): void;
  setBills: Dispatch<SetStateAction<Bill[]>>;
  loading: boolean;
};

const BillsContext = createContext<BillsContextValue>({} as BillsContextValue);
export const BillsProvider = BillsContext.Provider;

export function useBills(): BillsContextValue {
  const context = useContext(BillsContext);
  return context;
}
