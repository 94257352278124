import React, { useState } from 'react';
import List from 'components/list/List';
import WasteItemModule from './WasteItemModule';
import ProjectListMenu from '../WasteListMenu';
import { WasteOnList } from 'pages/material-residues/waste/types/waste';

type WasteListModuleProps = {
  wastes: WasteOnList[];
};

const WasteListModule: React.FC<WasteListModuleProps> = ({ wastes }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <ProjectListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <List>
        {wastes.map(waste => (
          <WasteItemModule key={waste.id} waste={waste} setAnchorEl={setAnchorEl} />
        ))}
      </List>
    </>
  );
};

export default WasteListModule;
