import React, { Dispatch, SetStateAction } from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { Agent } from 'types/agent';
import CustomerAgentModuleItem from './CustomerAgentModuleItem';

type CustomerAgentModuleProps = {
  agents: Agent[];
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const CustomerAgentModule: React.FC<CustomerAgentModuleProps> = ({ agents, setAnchorEl }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {agents.map(agent => (
            <CustomerAgentModuleItem setAnchorEl={setAnchorEl} key={agent.id} agent={agent} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default CustomerAgentModule;
