import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { SubgroupStockBalanceData } from 'types/reports/stockBalance';

import { makeStyles } from '@mui/styles';

type SubgroupItemModuleProps = {
  subgroup: SubgroupStockBalanceData;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
});

const SubgroupItemModule: React.FC<SubgroupItemModuleProps> = ({ subgroup }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem}>
      <Typography color="primary" variant="caption" gutterBottom>
        {subgroup.id_subgrupo}
      </Typography>
      <Typography variant="body1">{subgroup.subgrupo}</Typography>
      <div className="content">
        <Typography variant="body2" color="textSecondary">
          Estoque
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {subgroup.estoque}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Estoque total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {subgroup.estTotal}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Custo total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {subgroup.formattedCustoTotal}
        </Typography>
      </div>
    </ListItem>
  );
};

export default SubgroupItemModule;
