import React from 'react';
import { Typography, Theme } from '@mui/material';
import { SaleReport, SalesReportTotal } from './SalesReport';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { moneyFormat } from 'helpers/numberFormat';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 7,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  branch: {
    padding: 20,
    cursor: 'pointer',
    border: '1px solid #eee',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    backgroundColor: '#fff',
  },
  values: {
    margin: '5px 0',
    display: 'grid',
    gridTemplateColumns: '0.5fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    '& .yes-paid': {
      color: '#28a745',
    },
    '& .no-paid': {
      color: '#dc3545',
    },
  },
  total: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: 7,
    marginBottom: 5,
    '& .total': {
      fontSize: 20,
    },
    '& div': {
      background: '#fff',
      padding: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #eee',
      marginBottom: 5,
      '& .paid': {
        color: '#28a745',
      },
      '& .no-paid': {
        color: '#dc3545',
      },
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

type SalesReportBranchesProps = {
  salesReport: SaleReport[];
  setSelectedBranch(branch: SaleReport): void;
  formattedTotal: SalesReportTotal;
};

const SalesReportBranches: React.FC<SalesReportBranchesProps> = ({
  salesReport,
  setSelectedBranch,
  formattedTotal,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.total}>
        <div>
          <span className="label">Total pago</span>
          <span className="paid total">{formattedTotal.paidTotal}</span>
        </div>
        <div>
          <span className="label">Total não pago</span>
          <span className="no-paid total">{formattedTotal.noPaidTotal}</span>
        </div>
        <div>
          <span className="label">Total geral</span>
          <span className="general-total total">{formattedTotal.total}</span>
        </div>
      </div>
      <div className={classes.container}>
        {salesReport.map(branch => (
          <div key={branch.id_filial} className={classes.branch} onClick={() => setSelectedBranch(branch)}>
            <div>
              <Typography gutterBottom variant="h6">
                {branch.filial}
              </Typography>
              <Typography color="textSecondary">
                {branch.orders.length} {branch.orders.length === 1 ? 'pedido' : 'pedidos'}
              </Typography>
              <div className={classes.values}>
                <Typography>Ticket médio</Typography>
                <Typography variant="body1">{moneyFormat(branch.total / branch.orders.length)}</Typography>
                <Typography>Não Pagos</Typography>
                <Typography className="no-paid" variant="body1">
                  {branch.formattedNoPaid}
                </Typography>
                <Typography>Pagos</Typography>
                <Typography className="yes-paid">{branch.formattedYesPaid}</Typography>
              </div>
              <Typography variant="h5" color="secondary">
                {branch.formattedTotal}
              </Typography>
            </div>
            <ChevronRightIcon color="primary" />
          </div>
        ))}
      </div>
    </>
  );
};

export default SalesReportBranches;
