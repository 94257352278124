import { Done } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

interface ManagedSaleNewAppbarButtonsProps {
  handleValidation(): void;
}

const ManagedSaleNewAppbarButtons: React.FC<ManagedSaleNewAppbarButtonsProps> = ({ handleValidation }) => {
  return (
    <IconButton color="inherit" onClick={handleValidation}>
      <Done />
    </IconButton>
  );
};

export default ManagedSaleNewAppbarButtons;
