import { Button, TextField } from '@mui/material';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import { useMessaging } from 'hooks/messaging';
import React, { useEffect, useState } from 'react';
import { api } from 'services/api';
import { useSelector } from 'store/selector';
import { useEmailTemplates } from '../hooks/useEmailTemplates';

import { makeStyles } from '@mui/styles';

type EmailTemplateSendTestProps = {
  onExited(): void;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 200,
    justifyContent: 'space-around',
  },
});

const EmailTemplateSendTest: React.FC<EmailTemplateSendTestProps> = ({ onExited }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const { selectedEmailTemplate } = useEmailTemplates();
  const messaging = useMessaging();
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (!user) return;
    setEmail(user.email);
  }, [user]);

  function handleSendEmailTest(closeDialog: () => void) {
    if (!selectedEmailTemplate) return;
    if (!email) return;

    api
      .post(`/email-templates/test`, { id: selectedEmailTemplate.id, email })
      .then(() => {
        messaging.handleOpen('Email teste enviado');
        closeDialog();
      })
      .catch(err => {
        messaging.handleOpen('Não foi possível enviar o email teste');
        console.error(err);
      });
  }

  return (
    <DialogInput maxWidth="sm" onExited={onExited} hideBackdrop={false}>
      <DialogInputConsumer>
        {({ handleClose }) => (
          <div className={classes.container}>
            <TextField
              margin="normal"
              placeholder="Digite um endereço de e-mail"
              label="E-mail"
              value={email}
              onChange={e => setEmail(e.target.value)}
              fullWidth
              autoFocus
            />
            <Button onClick={() => handleSendEmailTest(handleClose)} color="primary" variant="contained">
              Enviar
            </Button>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default EmailTemplateSendTest;
