import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Done } from '@mui/icons-material';

type CustomerActionsProps = {
  handleSubmit(): void;
  handleOpenDialogImport(): void;
};

const CustomerActions: React.FC<CustomerActionsProps> = ({ handleSubmit, handleOpenDialogImport }) => {
  return (
    <>
      <Tooltip title="Salvar">
        <IconButton color="inherit" onClick={handleSubmit}>
          <Done />
        </IconButton>
      </Tooltip>
      <Tooltip title="Importar cliente aprovado">
        <IconButton color="inherit" onClick={handleOpenDialogImport}>
          <SaveAltIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default CustomerActions;
