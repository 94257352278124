import React, { Dispatch, SetStateAction } from 'react';
import { Button, IconButton, Tooltip, Theme } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { useExportEmail } from './hooks/useExportEmail';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));
interface ExportEmailActionsProps {
  openDialog(): void;
  setExcelExport: Dispatch<SetStateAction<boolean>>;
}

const ExportEmailActions: React.FC<ExportEmailActionsProps> = ({ setExcelExport, openDialog }) => {
  const classes = useStyles();
  const { filtered } = useExportEmail();

  return (
    <div className={classes.container}>
      <Tooltip title="Filtrar">
        <IconButton onClick={openDialog} color="inherit">
          <FilterList />
        </IconButton>
      </Tooltip>

      <Button
        disabled={filtered.length === 0}
        size="small"
        variant="contained"
        color="primary"
        onClick={() => setExcelExport(true)}
      >
        Exportar excel
      </Button>
    </div>
  );
};

export default ExportEmailActions;
