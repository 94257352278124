import React, { FormEvent, useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Done } from '@mui/icons-material';
import Dialog from 'components/dialogs/Dialog';
import InsideSaving from 'components/loading/InsideSaving';
import { useAppropriationDate } from 'pages/appropiation-date/hooks/useApproprationDate';
import NoData from 'components/no-data/NoData';
import { useApp } from 'hooks/app';
import AppropriationDateForm from '../AppropriationDateForm';
import { useAppropriationDateValidation } from 'pages/appropiation-date/validation/useAppropriationDateValidation';
import { AppropriationDate } from 'types/appropriationDate';
import { useMessaging } from 'hooks/messaging';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { parsePtBRDate } from 'helpers/parsePtBRDate';

interface UpdateAppropriationDateProps {
  onExited(): void;
}

const UpdateAppropriationDate: React.FC<UpdateAppropriationDateProps> = ({ onExited }) => {
  const { financialApi } = useApp();
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);
  const { selectedDate, setSelectedDate } = useAppropriationDate();
  const [validation, setValidation, validate] = useAppropriationDateValidation();

  useEffect(() => {
    setSelectedDate(date =>
      date
        ? {
            ...date,
            date: new Date(parsePtBRDate(date.data)),
          }
        : null,
    );
  }, [setSelectedDate]);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    if (!selectedDate) {
      return;
    }

    setValidation({});

    validate(selectedDate)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    if (!selectedDate?.date) {
      return;
    }

    const dataToSubmit = {
      data: format(selectedDate.date, 'P', { locale: ptBR }),
      inativo: selectedDate.inativo,
      id: selectedDate.id,
    };

    financialApi
      .put('/updatedataapropriacao', dataToSubmit)
      .then(() => {
        handleOpen('Salvo com sucesso!');
        onExited();
      })
      .finally(() => setSaving(false));
  }

  function handleChange(index: keyof AppropriationDate, value: any) {
    setSelectedDate(state =>
      state
        ? {
            ...state,
            [index]: value,
          }
        : null,
    );
  }

  return (
    <Dialog
      title="Editar Data Apropriação"
      onExited={onExited}
      maxWidth="sm"
      ComponentActions={
        <Tooltip title="Salvar">
          <IconButton color="inherit" onClick={() => handleValidation()}>
            <Done />
          </IconButton>
        </Tooltip>
      }
    >
      {saving && <InsideSaving />}
      {selectedDate ? (
        <form onSubmit={handleValidation}>
          <AppropriationDateForm appropriationDate={selectedDate} handleChange={handleChange} validation={validation} />
        </form>
      ) : (
        <NoData message="Data não selecionada" />
      )}
    </Dialog>
  );
};

export default UpdateAppropriationDate;
