import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { moneyFormat } from 'helpers/numberFormat';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { BankStatementFilterProps } from '../BankStatement';
import { TotalizingAccount } from 'types/totalizingAccount';
import { format, lastDayOfMonth, startOfMonth, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Extract } from 'types/extract';
import { invoiceTypes } from 'pages/moviment/query/Invoices';

export interface TotalExtracts {
  conta: string;
  id_conta: string;
  saldoinicial: number;
  saldofinal: number;
  saldocontaatual: number;
  credito: number;
  debito: number;
}

const total_initial_state = {
  conta: '',
  credito: 0,
  debito: 0,
  saldofinal: 0,
  saldoinicial: 0,
  saldocontaatual: 0,
  id_conta: '',
};

export function useFetchExtracts(filter: BankStatementFilterProps) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<TotalExtracts>(total_initial_state);
  const [loadingTotalizingAccount, setLoadingTotalizingAccount] = useState(false);
  const [extracts, setExtracts] = useState<Extract[]>([]);
  const [totalizingAccounts, setTotalizingAccounts] = useState<TotalizingAccount[]>([]);
  const { financialApi, windowWidth, isOpenedMenu, handleOpenMenu } = useApp();

  useEffect(() => {
    if (!financialApi) {
      return;
    }

    setLoadingTotalizingAccount(true);

    financialApi
      .get('/getcontastotalizadoras', {
        params: {
          id_filial: filter.branchId === 1001 ? '' : filter.branchId,
          filtro: '',
        },
      })
      .then(response => {
        if (response.data.MESSAGE) {
          setTotalizingAccounts([]);
          return;
        }

        setTotalizingAccounts(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoadingTotalizingAccount(false));
  }, [financialApi, filter.branchId]);

  const fetch = useCallback(() => {
    if (!financialApi) {
      return;
    }

    if (isOpenedMenu) handleOpenMenu();

    const formattedInitialDate = format(filter.initial_date, 'P', { locale: ptBR });
    const formattedFinalDate = format(filter.final_date, 'P', { locale: ptBR });
    const formattedLastDayOfMonth = filter.month_year
      ? format(lastDayOfMonth(filter.month_year), 'P', { locale: ptBR })
      : formattedFinalDate;

    const formattedFirstDayOfMonth = filter.month_year
      ? format(subDays(startOfMonth(filter.month_year), 1), 'P', { locale: ptBR })
      : formattedInitialDate;

    setLoading(true);
    const fetchExtracts = financialApi.get('/getextratoconta', {
      params: {
        ignora_periodo: filter.ignorePeriod,
        id_filial: filter.branchId === 1001 ? '' : filter.branchId,
        id_conta: filter.accountId.join(',') || '',
        data_fim: formattedLastDayOfMonth,
        data_ini: formattedFirstDayOfMonth,
        movimentacao: filter.movimentation,
        mes_ano: filter.month_year ? format(filter.month_year, 'MMyy') : '',
        conta_destino: filter.targetAccount,
        tipo_movimentacao: filter.type === 'DT' ? "'D','T'" : filter.type === 'TT' ? '' : `'${filter.type}'`,
      },
    });

    const fetchTotals = financialApi.get('/getresumoextratoconta', {
      params: {
        ignora_periodo: filter.ignorePeriod,
        id_filial: filter.branchId === 1001 ? '' : filter.branchId,
        id_conta: filter.accountId.join(',') || '',
        data_fim: formattedLastDayOfMonth,
        data_ini: formattedFirstDayOfMonth,
        movimentacao: filter.movimentation,
        mes_ano: filter.month_year ? format(filter.month_year, 'MMyy') : '',
        conta_destino: filter.targetAccount,
        tipo_movimentacao: filter.type === 'DT' ? "'D','T'" : filter.type === 'TT' ? '' : `'${filter.type}'`,
      },
    });

    Promise.all([fetchExtracts, fetchTotals])
      .then(([responseExtract, responseTotals]) => {
        if (responseExtract.data.MESSAGE) {
          setExtracts([]);
        } else {
          const _extracts = responseExtract.data.map(item => {
            item.formattedValue = moneyFormat(item.valor);
            item.date = new Date(parsePtBRDate(item.data));
            item.formattedType = invoiceTypes[item.tipo_movimentacao];
            item.formattedDate = parsePtBRDate(item.data).toISOString();
            return item;
          });

          if (windowWidth < 600) {
            switch (filter.sortBy) {
              case 'name':
                setExtracts(
                  _extracts.sort((a, b) => {
                    if (a.descricao < b.descricao) {
                      return -1;
                    }
                    if (a.descricao > b.descricao) {
                      return 1;
                    }
                    return 0;
                  }),
                );
                break;
              case 'higher_price':
                setExtracts(_extracts.sort((a, b) => b.valor - a.valor));
                break;
              case 'lower_price':
                setExtracts(_extracts.sort((a, b) => a.valor - b.valor));
                break;
              default:
                setExtracts(_extracts);
                break;
            }
          } else {
            setExtracts(_extracts);
          }
        }

        setTotal(responseTotals.data[0]);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, financialApi, windowWidth, isOpenedMenu, handleOpenMenu]);

  return { extracts, totalizingAccounts, loading, total, fetch, loadingTotalizingAccount };
}
