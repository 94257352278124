import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { Category } from 'types/category';
import { usePagination } from 'hooks/pagination';
import CategoryItemModule from './CategoryItemModule';

type CategoryListModuleProps = {
  categories: Category[];
};

const CategoryListModule: React.FC<CategoryListModuleProps> = ({ categories }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {categories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(category => (
            <CategoryItemModule key={category.id} category={category} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default CategoryListModule;
