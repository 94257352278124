import React, { useEffect, useState } from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { PurchaseOrderItem } from 'types/purchaseOrderItem';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import NoData from 'components/no-data/NoData';
import history from 'services/history';

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  background: '#fff',
  overflow: 'auto',
  maxHeight: 550,
});

const Row = styled('li')(({ theme }) => ({
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 6,
  gridTemplateColumns: '90px 1fr 100px 125px 125px',
  transition: 'all 0.3s ease',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '90px 220px 100px 125px 125px',
  },
  '& > .numericData': {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  '& > span': {
    gap: 5,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
  rowGap: 10,
});

interface TopProfitsProps {
  data: PurchaseOrderItem[];
}

const TopProfits: React.FC<TopProfitsProps> = ({ data }) => {
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<PurchaseOrderItem[]>([]);

  useEffect(() => {
    setFiltered(
      data.sort((a, b) => a.perc_comprou_caro - b.perc_comprou_caro).filter(item => item.perc_comprou_caro >= 5),
    );
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <Container>
      <Typography variant="subtitle2" align="center">
        Valor negociado no pedido maior que o custo do produto
      </Typography>
      {filtered.length > 0 ? (
        <List>
          <Row>
            <span>
              <Typography onClick={() => handleSort('id_produto')} variant="subtitle2">
                Cod Prod
              </Typography>
              {orderedIndex.index === 'id_produto' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
            <span>
              <Typography onClick={() => handleSort('produto')} variant="subtitle2">
                Produto
              </Typography>
              {orderedIndex.index === 'produto' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography onClick={() => handleSort('custo')} align="right" variant="subtitle2">
                Custo
              </Typography>
              {orderedIndex.index === 'custo' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography onClick={() => handleSort('valor_negociado')} variant="subtitle2" align="right">
                VL Comprado
              </Typography>
              {orderedIndex.index === 'valor_negociado' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography onClick={() => handleSort('perc_comprou_caro')} variant="subtitle2" align="right">
                % negociado
              </Typography>
              {orderedIndex.index === 'perc_comprou_caro' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
          </Row>
          {filtered.map((item, index) => (
            <Row className="content" onClick={() => history.push(`/purchasing/order/${item.id_pedido}`)} key={index}>
              <Typography variant="caption">{item.id_produto}</Typography>
              <Tooltip title={item.produto}>
                <Typography className="item-name" variant="caption">
                  {item.produto}
                </Typography>
              </Tooltip>
              <Typography className="numericData" align="right" variant="caption">
                {item.formattedCoust}
              </Typography>
              <Typography className="numericData" align="right" variant="caption">
                {item.formattedNegotiatedValue}
              </Typography>
              <Typography className="numericData" align="right" variant="caption">
                {item.formattedPercent}
              </Typography>
            </Row>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
    </Container>
  );
};

export default TopProfits;
