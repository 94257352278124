import React, { useEffect, useState, useCallback } from 'react';
import { Button, IconButton, styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import { api } from 'services/api';
import { billsTableTemplate } from './billsTableTemplate';
import { BillSetting } from 'types/billSetting';
import BillsFilterBox from './BillsFilterBox';
import BillListTable from './list/table/BillListTable';
import BillListModule from './list/module/BillListModule';
import BillingNew from './new/BillingNew';
import { Add } from '@mui/icons-material';
import { BillingProvider } from './hooks/useBilling';
import BillSettingPage from 'pages/bill-settings/BillSetting';
import { useFinancial } from 'pages/financial/hooks/useFinancial';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

const Billing: React.FC = () => {
  const { handleBack } = useFinancial();
  const [newBilling, setNewBilling] = useState(false);
  const [selectedBill, setSelectedBill] = useState<BillSetting | null>(null);
  const [bills, setBills] = useState<BillSetting[]>([]);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<BillSetting[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const app = useApp();

  useEffect(() => {
    setFiltered(bills);
  }, [bills]);

  const loadBills = useCallback(() => {
    setLoading(true);
    api
      .get<BillSetting[]>('/bank-billet-settings')
      .then(response => {
        setBills(response.data.map(i => ({ ...i, formattedActive: i.is_active ? 'Sim' : 'Não' })));
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    loadBills();
  }, [loadBills]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function onExited() {
    setSelectedBill(null);
  }

  function onSave() {
    setSelectedBill(null);
    loadBills();
  }

  return (
    <BillingProvider value={{ selectedBill, setSelectedBill }}>
      <Appbar
        backAction={handleBack}
        title="Site"
        ActionsComponent={
          <CustomIconButton onClick={() => setNewBilling(true)} color="inherit">
            <Add />
          </CustomIconButton>
        }
      />
      <PageHeaderActions
        title="Layouts de cobranças"
        description="Gestão dos layouts para geração de boletos e remessas bancárias"
        ActionComponent={
          <Button size="small" variant="contained" color="primary" onClick={() => setNewBilling(true)}>
            Criar layout
          </Button>
        }
      />
      {selectedBill && <BillSettingPage onExited={onExited} onSave={onSave} id={selectedBill.id} />}
      {newBilling && <BillingNew onExited={() => setNewBilling(false)} />}
      <TableContainer tableTemplate={billsTableTemplate}>
        <BillsFilterBox setDisplayMode={setDisplayMode} displayMode={displayMode} loadBills={loadBills} />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhuma cobrança para mostrar" />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <BillListTable bills={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <BillListModule bills={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          </PaginationProvider>
        )}
      </TableContainer>
    </BillingProvider>
  );
};

export default Billing;
