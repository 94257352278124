import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useBills } from '../hooks/useBills';

type BillListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const BillListMenu: React.FC<BillListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { handleCancel, selectedBill } = useBills();

  function handleClick() {
    setAnchorEl(null);
    handleCancel();
  }

  return (
    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
      <MenuItem
        disabled={!!selectedBill?.canceled_at || !!selectedBill?.bank_billet_remittance_id}
        onClick={handleClick}
      >
        Cancelar boleto
      </MenuItem>
    </Menu>
  );
};

export default BillListMenu;
