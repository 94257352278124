import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useCreditAnalysisQuestion } from '../hooks/useCreditAnalysisQuestion';
import { CreditAnalysisQuestionValidation } from './validation/creditAnalysisQuestionValidation';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  checkBox: {
    marginTop: 30,
  },
});

interface CreditAnalysisQuestionFormProps {
  validation: CreditAnalysisQuestionValidation;
}

const CreditAnalysisQuestionForm: React.FC<CreditAnalysisQuestionFormProps> = ({ validation }) => {
  const classes = styles();
  const inputs = {
    order: useRef<HTMLInputElement>(null),
    question_text: useRef<HTMLInputElement>(null),
    forward_on_answer_no: useRef<HTMLInputElement>(null),
    text_answer_no: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  const { question, handleChange } = useCreditAnalysisQuestion();
  return (
    <>
      <TextField
        inputRef={inputs.order}
        error={!!validation.order}
        helperText={validation.order}
        label="Ordem"
        placeholder="Informe a ordem da pergunta"
        margin="normal"
        value={question.order || ''}
        onChange={e => handleChange('order', e.target.value)}
        fullWidth
        autoFocus
      />
      <TextField
        inputRef={inputs.question_text}
        error={!!validation.question_text}
        helperText={validation.question_text}
        label="Questão"
        placeholder="Informe a pergunta"
        margin="normal"
        value={question.question_text}
        onChange={e => handleChange('question_text', e.target.value)}
        fullWidth
        multiline
        rows={3}
      />
      <FormControlLabel
        className={classes.checkBox}
        control={
          <Checkbox
            checked={question.forward_on_answer_no}
            onChange={e => handleChange('forward_on_answer_no', e.target.checked)}
            name="gilad"
          />
        }
        label={'Avançar na resposta "Não"'}
      />
      {!question.forward_on_answer_no && (
        <TextField
          inputRef={inputs.text_answer_no}
          error={!!validation.text_answer_no}
          helperText={validation.text_answer_no}
          rows={3}
          multiline
          label="Texto para resposta não"
          placeholder="Informe o texto apresentado para quando a resposta por não"
          margin="normal"
          value={question.text_answer_no}
          onChange={e => handleChange('text_answer_no', e.target.value)}
          fullWidth
        />
      )}
    </>
  );
};

export default CreditAnalysisQuestionForm;
