import React, { useState } from 'react';
import Dialog from 'components/dialogs/Dialog';
import ExcelButtonDownload from './ButtonDownload';
import { MenuItem, Select } from '@mui/material';
import { api } from 'services/api';
import { download } from 'helpers/download';
import { uuidv4 } from 'helpers/uuidv4';
import {
  AbcProduct,
  AbcProductBranch,
  AbcProductManufacturer,
  AbcProductSegment,
  AbcProductSeller,
  AbcProductSubgroup,
  AbcProductUnity,
} from 'types/abcProduct';
import { AbcCustomer } from 'types/abcCustomer';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 30,
  },
});

interface AbcProductExportProps {
  onExited(): void;
  products: AbcProduct[];
  segments: AbcProductSegment[];
  subgroups: AbcProductSubgroup[];
  branches: AbcProductBranch[];
  manufacturers: AbcProductManufacturer[];
  units: AbcProductUnity[];
  sellers: AbcProductSeller[];
  customers: AbcCustomer[];
}

type Segments =
  | 'product'
  | 'branch'
  | 'manufacturer'
  | 'made_by'
  | 'subgroup'
  | 'category'
  | 'segment'
  | 'unity'
  | 'customer';

const AbcProductExport: React.FC<AbcProductExportProps> = ({
  onExited,
  products,
  branches,
  manufacturers,
  subgroups,
  segments,
  units,
  customers,
  sellers,
}) => {
  const classes = styles();
  const [tab, setTab] = useState<Segments>('product');

  async function handleDownload() {
    const mapping = {
      product: {
        route: '/excel/abc-products',
        data: products,
      },
      branch: {
        route: '/excel/abc-branches',
        data: branches,
      },
      manufacturer: {
        route: '/excel/abc-manufacturers',
        data: manufacturers,
      },
      subgroup: {
        route: '/excel/abc-subgroups',
        data: subgroups,
      },
      segment: {
        route: '/excel/abc-segments',
        data: segments,
      },
      unity: {
        route: '/excel/abc-units',
        data: units,
      },
      customer: {
        route: '/excel/abc-customers',
        data: customers,
      },
      made_by: {
        route: '/excel/abc-sellers',
        data: sellers,
      },
    };

    const resource = mapping[tab];

    try {
      const response = await api.post(resource.route, resource.data, { responseType: 'blob' });

      download(response.data, `abc-${tab}-${uuidv4()}`);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Dialog onExited={onExited} title="Exportar para Excel" maxWidth="sm" height="60vh">
      <div className={classes.container}>
        <Select fullWidth value={tab}>
          <MenuItem value="product" onClick={() => setTab('product')}>
            Produto
          </MenuItem>
          <MenuItem value="branch" onClick={() => setTab('branch')}>
            Filial
          </MenuItem>
          <MenuItem value="category" onClick={() => setTab('category')}>
            Categoria
          </MenuItem>
          <MenuItem value="subgroup" onClick={() => setTab('subgroup')}>
            Subgrupo
          </MenuItem>
          <MenuItem value="segment" onClick={() => setTab('segment')}>
            Segmento
          </MenuItem>
          <MenuItem value="unity" onClick={() => setTab('unity')}>
            Unidade
          </MenuItem>
          <MenuItem value="manufacturer" onClick={() => setTab('manufacturer')}>
            Fabricante
          </MenuItem>
          <MenuItem value="made_by" onClick={() => setTab('made_by')}>
            Pedido por
          </MenuItem>
          <MenuItem value="customer" onClick={() => setTab('customer')}>
            Cliente
          </MenuItem>
        </Select>

        <ExcelButtonDownload handleDownload={handleDownload} />
      </div>
    </Dialog>
  );
};

export default AbcProductExport;
