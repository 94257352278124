import { IconButton, InputAdornment, useTheme } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';
import React from 'react';

interface InputWhatsAppButtonProps {
  phone: string;
}

const InputWhatsAppButton: React.FC<InputWhatsAppButtonProps> = ({ phone }) => {
  const theme = useTheme();
  function handleOpenNewTab() {
    const phoneRaw = phone.replace(/\D/g, '');
    window.open(`https://wa.me/55${phoneRaw}`);
  }

  return (
    <InputAdornment position="end">
      <IconButton onClick={handleOpenNewTab}>
        <WhatsApp style={{ color: theme.palette.success.main }} />
      </IconButton>
    </InputAdornment>
  );
};

export default InputWhatsAppButton;
