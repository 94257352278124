import { TableTemplate } from 'types/tableTemplate';

export const customerTableTemplate: TableTemplate[] = [
  {
    id: 'codigo',
    description: 'CODIGO',
    originalId: 'codigo',
    width: 100,
  },
  {
    id: 'nome',
    description: 'NOME',
    originalId: 'nome',
    width: 200,
  },
  {
    id: 'uf',
    description: 'UF',
    originalId: 'uf',
    width: 50,
  },
  {
    id: 'cidade',
    description: 'CIDADE',
    originalId: 'cidade',
    width: 150,
  },
  {
    id: 'endereco',
    description: 'ENDERECO',
    originalId: 'endereco',
    width: 400,
  },
  {
    id: 'bairro',
    description: 'BAIRRO',
    originalId: 'bairro',
    width: 250,
  },
  {
    id: 'cnpj_cpf',
    description: 'CPF/CNPJ',
    originalId: 'cnpj_cpf',
    width: 200,
  },
  {
    id: 'tipo',
    description: 'TIPO',
    originalId: 'tipo',
    width: 100,
  },
  {
    id: 'tipo_pessoa',
    description: 'TIPO PESSOA',
    originalId: 'tipo_pessoa',
    width: 100,
  },
  {
    id: 'atividade',
    description: 'ATIVIDADE',
    originalId: 'atividade',
    width: 310,
  },
  {
    id: 'situacao',
    description: 'ATIVO',
    originalId: 'situacao',
    width: 100,
  },
];
