import { UserActionsType, USER_CHANGE, User, SET_USER, SET_PROFILE } from './types';
import { Profile } from 'types/profile';

export function userChange(index: string, value: string): UserActionsType {
  return {
    type: USER_CHANGE,
    index,
    value,
  };
}

export function setUser(user: User): UserActionsType {
  return {
    type: SET_USER,
    user,
  };
}

export function setProfile(profile: Profile): UserActionsType {
  return {
    type: SET_PROFILE,
    profile,
  };
}
