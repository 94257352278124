import React from 'react';
import {
  LocalizationProvider,
  DesktopTimePickerProps,
  DesktopTimePicker as NativeDesktopTimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { pt } from 'date-fns/locale';
import { TextFieldProps } from '@mui/material';

interface CustomKeyboardDatePickerProps extends DesktopTimePickerProps<any> {
  value: any;
  onChange(date: any): void;
  label?: string;
  textFieldProps?: TextFieldProps;
}

const DesktopTimePicker: React.FC<CustomKeyboardDatePickerProps> = ({
  value,
  onChange,
  label,
  textFieldProps,
  slotProps,
  ...rest
}) => {
  return (
    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
      <NativeDesktopTimePicker
        slotProps={{ ...slotProps, textField: textFieldProps }}
        openTo="hours"
        label={label}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DesktopTimePicker;
