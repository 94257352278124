import { TableTemplate } from 'types/tableTemplate';

export const resellersTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'order',
    description: 'ORDEM',
    originalId: 'order',
    width: 100,
  },
  {
    id: 'question_text',
    description: 'QUESTÃO',
    originalId: 'question_text',
    width: 170,
  },
  {
    id: 'formattedForwardOnAnswerNo',
    description: 'AVANÇA QUANDO NÃO',
    originalId: 'forward_on_answer_no',
    width: 170,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'created_at',
    width: 170,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'created_at',
    width: 150,
  },
];
