import React from 'react';
import { Checkbox, FormControlLabel, Typography, styled } from '@mui/material';
import { Extract } from 'types/extract';
import { useExtracts } from '../../hooks/useExtracts';
import { useTable } from 'components/table/hook/useTable';

type BankStatementItemTableProps = {
  account: Extract;
};

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

const IsPaidTypography = styled(Typography)(props => ({
  color: props.theme.palette.success.main,
}));

const BankStatementItemTable: React.FC<BankStatementItemTableProps> = ({ account }) => {
  const { setInvoice, setExtract, selectedExtracts, handleToggle } = useExtracts();
  const { tableTemplate } = useTable();
  const isChecked = selectedExtracts.some(
    selectedExtract => selectedExtract.id_movimentacao === account.id_movimentacao,
  );

  return (
    <>
      {tableTemplate.map(item =>
        item.id === 'select' ? (
          <FormControlLabel
            style={{ justifyContent: 'center', marginRight: 0 }}
            label=""
            key={item.id}
            control={<Checkbox checked={isChecked} onChange={() => handleToggle(account)} />}
          />
        ) : (
          <NumericData
            onClick={() =>
              account.tipo_movimentacao !== 'S'
                ? setInvoice({ id: account.id_movimentacao, tipo: account.tipo_movimentacao })
                : setExtract(account)
            }
            key={item.id}
            className={item.dataType === 'number' ? 'numericData' : undefined}
          >
            {account.apropriado === 'S' ? (
              <IsPaidTypography
                fontWeight={item.originalId === 'formattedDate' || item.originalId === 'valor' ? 600 : 400}
                color={item.originalId === 'valor' ? (account[item.originalId] < 0 ? '#f30000' : 'green') : '#000'}
                fontSize={12}
                variant="body2"
              >
                {account[item.id]}
              </IsPaidTypography>
            ) : (
              <Typography fontSize={12} variant="body2">
                {account[item.id]}
              </Typography>
            )}
          </NumericData>
        ),
      )}
    </>
  );
};

export default BankStatementItemTable;
