import React from 'react';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Button, Checkbox, FormControlLabel, TextField, styled } from '@mui/material';
import { JustificationQueryParams } from './hooks/useFetchRequirements';
import { Search } from '@mui/icons-material';
import { usePermissionRules } from 'hooks/permissionRules';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';

type JustificationFilterProps = {
  handleChange(index: keyof JustificationQueryParams, value: any): void;
  filter: JustificationQueryParams;
  handleSearch(): void;
  loading: boolean;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'baseline',
  gridTemplateColumns: '1fr 2fr 1fr 100px 100px',
  columnGap: 10,
  flex: 1,
  '& label': {
    marginRight: 0,
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    rowGap: 10,
  },
  '& > .search-params': {
    display: 'grid',
    alignItems: 'center',
    width: '100%',
    gridTemplateColumns: '1fr 1fr',
    gap: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const RequirementFilterPage: React.FC<JustificationFilterProps> = ({
  filter,
  loading,
  setDisplayMode,
  displayMode,
  handleSearch,
  handleChange,
}) => {
  const { checkPermission } = usePermissionRules();
  return (
    <FilterBox>
      <Filter onSubmit={handleSearch}>
        <SelectOthersBranch
          branch_id={filter.id_filial}
          handleChange={e => handleChange('id_filial', e)}
          loading={loading}
        />

        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.data_ini}
            onChange={date => handleChange('data_ini', date)}
          />

          <KeyboardDatePicker
            label="Data final"
            value={filter.data_fim}
            onChange={date => handleChange('data_fim', date)}
          />
        </div>
        {checkPermission('rh.justification.allow-query') ? (
          <TextField
            label="Funcionário"
            placeholder="Digite o nome do funcionário"
            autoFocus
            value={filter.nome}
            onChange={e => handleChange('nome', e.target.value)}
          />
        ) : (
          <div></div>
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={filter.lancada === 'S'}
              onChange={e => handleChange('lancada', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Lançado"
        />

        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Search />}
        >
          Buscar
        </Button>
      </Filter>
      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default RequirementFilterPage;
