import React, { MouseEvent, SetStateAction, Dispatch } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { Bill } from 'types/bill';
import { useBills } from 'pages/bills/hooks/useBills';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type BillItemTableProps = {
  bill: Bill;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const BillItemTable: React.FC<BillItemTableProps> = ({ bill, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedBill } = useBills();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedBill(bill);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'url' ? (
            <a href={bill[item.id]} key={item.id} id={item.id} target="blank" style={{ fontSize: 14 }}>
              {bill[item.id]}
            </a>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{bill[item.id]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default BillItemTable;
