import Dialog from 'components/dialogs/Dialog';
import { useApp } from 'hooks/app';
import React, { FormEvent, useEffect, useState } from 'react';
import { useTransferAccountValidation } from '../../validation/transferAccountValidation';
import { TransferAccount } from 'types/transferAccount';
import TransferAccountForm from '../TransferAccountForm';
import InsideSaving from 'components/loading/InsideSaving';
import { IconButton, Tooltip, styled } from '@mui/material';
import { useMessaging } from 'hooks/messaging';
import { TotalizingAccount } from 'types/totalizingAccount';
import { moneyFormat } from 'helpers/numberFormat';
import { useSelector } from 'store/selector';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Done } from '@mui/icons-material';

interface NewTransferAccountProps {
  onExited(): void;
  onSubmit(): void;
  branchId: number;
}

const Form = styled('form')({
  gap: 15,
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
});

const NewTransferAccount: React.FC<NewTransferAccountProps> = ({ onExited, onSubmit, branchId }) => {
  const { financialApi } = useApp();
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<TotalizingAccount[]>([]);
  const [account, setTransferAccount] = useState<TransferAccount>({
    data: '',
    date: new Date(),
    id: 0,
    id_filial: branchId,
    id_conta_destino: 0,
    id_conta_origem: 0,
    observacao: '',
    destino: '',
    origem: '',
    usuario: '',
    mes_ano: '',
    monthYear: new Date(),
    valor: 0,
  });
  const [validation, setValidation, validate] = useTransferAccountValidation();

  useEffect(() => {
    if (!financialApi) {
      return;
    }

    setLoading(true);

    financialApi
      .get('/getcontastotalizadoras', {
        params: {
          id_filial: '',
          filtro: '',
        },
      })
      .then(response => {
        if (response.data.MESSAGE) {
          setAccounts([]);
          return;
        }
        setAccounts(
          response.data.map(item => {
            item.formattedSaldo = moneyFormat(item.saldo);
            return item;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [financialApi]);

  function handleValidation(e?: FormEvent<HTMLFormElement | HTMLButtonElement>) {
    e?.preventDefault();

    setValidation({});

    validate(account)
      .then(handleSubmit)
      .catch(error => {
        console.log(error);
        handleOpen('Não foi possível salvar a transferência');
      });
  }

  function handleSubmit() {
    if (!user) {
      return;
    }

    if (!account.date) {
      return;
    }

    setSaving(true);

    const params = {
      usuario: user.name,
      data: format(account.date, 'P', { locale: ptBR }),
      valor: parseFloat(account.valor.toString()),
      id_conta_origem: account.id_conta_origem,
      id_conta_destino: account.id_conta_destino,
      observacao: account.observacao,
      mes_ano: account.monthYear ? format(account.monthYear, 'MMyy') : '',
    };

    financialApi
      .post('/savetransferenciaconta', params)
      .then(onSubmit)
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleChange(index: keyof TransferAccount, value: any) {
    setTransferAccount(account => ({
      ...account,
      [index]: value,
    }));
  }

  return (
    <Dialog
      maxWidth="md"
      height="70vh"
      title="Nova transferência entre contas"
      onExited={onExited}
      ComponentActions={
        <Tooltip title="Salvar">
          <IconButton color="inherit" onClick={handleValidation}>
            <Done />
          </IconButton>
        </Tooltip>
      }
    >
      {saving && <InsideSaving />}
      <Form onSubmit={handleValidation}>
        <TransferAccountForm
          loading={loading}
          accounts={accounts}
          account={account}
          validation={validation}
          handleChange={handleChange}
        />
      </Form>
    </Dialog>
  );
};

export default NewTransferAccount;
