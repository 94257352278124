import InsideLoading from 'components/loading/InsideLoading';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import React from 'react';
import { useFetchSellerDashboard } from './hooks/useFetchSellerDashboard';
import SellerDashboardHeaderFilters from './SellerDashboardHeaderFilters';
import Appbar from 'components/appbar/Appbar';
import SalesReportCustomDate from 'pages/reports/sales/SalesReportCustomDate';
import SalesReportDateFilter from 'pages/reports/sales/SalesReportDateFilter';
import FilterActions from 'components/filter-box/FilterActions';
import SellerDashboardHeader from './SellerDashboardHeader';
import SellerDashboardContent from './SellerDashboardContent';
import { styled } from '@mui/material';

export interface SellerDashboardQueryParams {
  initialDate: Date;
  finalDate: Date;
  seller: string;
}

const Container = styled('div')({
  display: 'grid',
  gap: 10,
});

const SellerDashboard: React.FC = () => {
  const {
    loading,
    dialogDate,
    setDialogDate,
    dateConfig,
    anchorEl,
    handleConfirmCustomDates,
    handleDateConfig,
    setAnchorEl,
    getDescriptionPageHeader,
  } = useFetchSellerDashboard();

  return (
    <>
      {dialogDate && (
        <SalesReportDateFilter
          handleDateConfig={handleDateConfig}
          dateConfig={dateConfig}
          loading={loading}
          onExited={() => setDialogDate(false)}
          handleConfirmCustomDates={handleConfirmCustomDates}
        />
      )}
      <SalesReportCustomDate
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        handleConfirmCustomDates={handleConfirmCustomDates}
      />
      <Appbar
        title="Relatório vendedor"
        ActionsComponent={<FilterActions handleOpenDialog={() => setDialogDate(true)} />}
      />

      <PageHeaderActions
        title="Dashboard vendedor"
        description={getDescriptionPageHeader()}
        ActionComponent={
          <SellerDashboardHeaderFilters
            setAnchorEl={setAnchorEl}
            dateConfig={dateConfig}
            handleDateConfig={handleDateConfig}
            loading={loading}
          />
        }
      />
      {loading ? (
        <InsideLoading />
      ) : (
        <Container>
          <SellerDashboardHeader />

          <SellerDashboardContent />
        </Container>
      )}
    </>
  );
};

export default SellerDashboard;
