import React from 'react';
import { useTable } from 'components/table/hook/useTable';
import { Tooltip, Typography, styled } from '@mui/material';
import { Invoice } from 'types/invoices';

interface SummaryItemTitleProps {
  data: Invoice;
}

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
  width: 350,
});

const SummaryItemTitle: React.FC<SummaryItemTitleProps> = ({ data }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
            <Tooltip title={data[item.id]}>
              <Description
                color={item.originalId === 'valor' ? '#ff0000' : ''}
                fontWeight={item.originalId === 'valor' ? 600 : 0}
                variant="caption"
              >
                {data[item.id]}
              </Description>
            </Tooltip>
          </div>
        ))}
    </>
  );
};

export default SummaryItemTitle;
