import React, { useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import { moneyFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import SalesReportBranches from './SalesReportBranches';
import SalesReportBranch from './SalesReportBranch';
import SalesReportOrder from './SalesReportOrder';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import InsideLoading from 'components/loading/InsideLoading';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import SalesReportHeaderButtons from './SalesReportHeaderButtons';
import SalesReportCustomDate from './SalesReportCustomDate';
import SalesReportDateFilter from './SalesReportDateFilter';
import { ptBR } from 'date-fns/locale';
import { formatPhoneNumber } from 'helpers/phoneNumberFormatter';
import FilterActions from 'components/filter-box/FilterActions';

export type Order = {
  id: string;
  data: string;
  hora: string;
  id_filial: string;
  filial: string;
  pago: 'Sim' | 'Não';
  Cnpj: string;
  cliente: string;
  entrega: string;
  valor_Frete: number;
  valor_total: number;
  formattedValorTotal: string;
  formattedValorFrete: string;
  whatsapp: string;
  formattedWhatsApp: string;
  email: string;
  itens: Array<{
    id_pedido: string;
    id_filial: string;
    cod_produ: string;
    produto: string;
    QTD: string;
    valor_unitario: number;
    valor_frete: number;
    valor_total: number;
    formattedValorUnitario: string;
    formattedValorTotal: string;
    formattedValorFrete: string;
  }>;
  pagamento: Array<{
    id_pagamento: number;
    id: number;
    id_forma: number;
    forma: string;
    parcelas: number;
    valor: number;
    tipo: string;
    numero_autorizacao: string;
    Bandeira: string;
    NSU: string;
    PAGO: boolean;
  }>;
};

export type SaleReport = {
  id_filial: string;
  filial: string;
  total: number;
  yesPaid: number;
  noPaid: number;
  formattedTotal: string;
  formattedYesPaid: string;
  formattedNoPaid: string;
  orders: Order[];
};

export type SalesReportTotal = {
  total: string;
  noPaidTotal: string;
  paidTotal: string;
};

const SalesReport: React.FC = () => {
  const { h2iApi } = useApp();
  const [saleReport, setSaleReport] = useState<SaleReport[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<SaleReport | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [dateConfig, setDateConfig] = useState<number | null>(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [dialogDate, setDialogDate] = useState(false);
  const [formattedTotal, setFormattedTotal] = useState<SalesReportTotal>({
    total: '',
    noPaidTotal: '',
    paidTotal: '',
  });

  useEffect(() => {
    if (!h2iApi) return;

    setLoading(true);
    setSelectedBranch(null);

    const formattedInitialDate = format(initialDate, "dd'.'MM'.'yyyy");
    const formattedFinalDate = format(finalDate, "dd'.'MM'.'yyyy");

    h2iApi
      .get(`/api/pedidosapp?data1=${formattedInitialDate}&data2=${formattedFinalDate}`)
      .then(response => {
        const result: Order[] = response.data.RESULT[0];

        let newSaleReport: SaleReport[] = [];

        result.forEach(item => {
          if (!newSaleReport.some(b => b.id_filial === item.id_filial)) {
            newSaleReport.push({
              id_filial: item.id_filial,
              filial: item.filial,
              orders: [],
              total: 0,
              yesPaid: 0,
              noPaid: 0,
              formattedTotal: moneyFormat(0),
              formattedNoPaid: moneyFormat(0),
              formattedYesPaid: moneyFormat(0),
            });
          }
        });

        result.forEach(item => {
          newSaleReport = newSaleReport.map(sale => {
            if (sale.id_filial === item.id_filial) {
              sale.orders = [
                ...sale.orders,
                {
                  ...item,
                  formattedWhatsApp: formatPhoneNumber(item.whatsapp),
                  formattedValorFrete: moneyFormat(item.valor_Frete),
                  formattedValorTotal: moneyFormat(item.valor_total),
                  itens: item.itens.map(product => {
                    product.formattedValorFrete = moneyFormat(product.valor_frete);
                    product.formattedValorTotal = moneyFormat(product.valor_total);
                    product.formattedValorUnitario = moneyFormat(product.valor_unitario);
                    return product;
                  }),
                },
              ];
              sale.total = sale.orders.reduce((sum: number, item: Order) => sum + item.valor_total, 0);
              sale.noPaid = sale.orders.reduce(
                (sum: number, item: Order) => (item.pago === 'Não' ? sum + item.valor_total : sum),
                0,
              );
              sale.yesPaid = sale.orders.reduce(
                (sum: number, item: Order) => (item.pago === 'Sim' ? sum + item.valor_total : sum),
                0,
              );
              sale.formattedTotal = moneyFormat(sale.total);
              sale.formattedNoPaid = moneyFormat(sale.noPaid);
              sale.formattedYesPaid = moneyFormat(sale.yesPaid);
              return sale;
            }
            return sale;
          });
        });

        newSaleReport = newSaleReport.map(branch => {
          branch.orders = branch.orders.sort((a, b) => {
            if (a.cliente < b.cliente) {
              return -1;
            }
            if (a.cliente > b.cliente) {
              return 1;
            }
            return 0;
          });
          return branch;
        });

        setSaleReport(newSaleReport);
      })
      .catch(() => {
        console.log('Erro ao gerar o relatório');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [h2iApi, initialDate, finalDate]);

  useEffect(() => {
    const total = saleReport.reduce((sum, item) => sum + item.total, 0);
    const noPaid = saleReport.reduce((sum, item) => sum + item.noPaid, 0);
    const paid = saleReport.reduce((sum, item) => sum + item.yesPaid, 0);
    setFormattedTotal({
      total: moneyFormat(total),
      noPaidTotal: moneyFormat(noPaid),
      paidTotal: moneyFormat(paid),
    });
  }, [saleReport]);

  function getDescriptionPageHeader(): string {
    const formattedInitialDate = format(initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = format(finalDate, 'P', { locale: ptBR });

    return `Você está visualizando informações de ${formattedInitialDate} à ${formattedFinalDate}`;
  }

  function handleDateConfig(days: number) {
    setDateConfig(days);
    if (days === 0) {
      const date = new Date();
      setInitialDate(startOfDay(date));
      setFinalDate(endOfDay(date));
    } else {
      const date = new Date();
      setFinalDate(endOfDay(date));
      setInitialDate(startOfDay(subDays(date, days)));
    }
  }

  function handleConfirmCustomDates(initialDt: Date, finalDt: Date) {
    setInitialDate(initialDt);
    setFinalDate(finalDt);
    setDateConfig(null);
  }

  return (
    <>
      {dialogDate && (
        <SalesReportDateFilter
          handleDateConfig={handleDateConfig}
          dateConfig={dateConfig}
          loading={loading}
          onExited={() => setDialogDate(false)}
          handleConfirmCustomDates={handleConfirmCustomDates}
        />
      )}
      <SalesReportCustomDate
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        handleConfirmCustomDates={handleConfirmCustomDates}
      />
      <Appbar title="Relatórios" ActionsComponent={<FilterActions handleOpenDialog={() => setDialogDate(true)} />} />
      <PageHeaderActions
        title="Vendas no App"
        description={getDescriptionPageHeader()}
        ActionComponent={
          <SalesReportHeaderButtons
            setAnchorEl={setAnchorEl}
            dateConfig={dateConfig}
            handleDateConfig={handleDateConfig}
            loading={loading}
          />
        }
      />
      {loading ? (
        <InsideLoading />
      ) : (
        <>
          {selectedOrder ? (
            <SalesReportOrder selectedOrder={selectedOrder} setSelectedOrder={order => setSelectedOrder(order)} />
          ) : selectedBranch ? (
            <SalesReportBranch
              selectedBranch={selectedBranch}
              setSelectedBranch={branch => setSelectedBranch(branch)}
              setSelectedOrder={order => setSelectedOrder(order)}
            />
          ) : (
            <SalesReportBranches
              salesReport={saleReport}
              setSelectedBranch={branch => setSelectedBranch(branch)}
              formattedTotal={formattedTotal}
            />
          )}
        </>
      )}
    </>
  );
};

export default SalesReport;
