import { TableTemplate } from 'types/tableTemplate';

export const dashboardBranchTableTemplate: TableTemplate[] = [
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 250,
  },
  {
    id: 'formattedCustoTotal',
    description: 'Total',
    originalId: 'CustoTotal',
    width: 120,
    dataType: 'number',
  },
];
