import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { H2iCustomer } from 'types/h2iCustomer';

export interface CustomersContextValue {
  selectedCustomer: H2iCustomer | null;
  setSelectedCustomer(customer: H2iCustomer | null): void;
  setCustomers: Dispatch<SetStateAction<H2iCustomer[]>>;
  handleFormatCustomer(customer: H2iCustomer): H2iCustomer;
  handleCustomerDelete(customerId: number): Promise<void>;
  setEditCustomerDialog: Dispatch<SetStateAction<boolean>>;
  setNewCustomerDialog: Dispatch<SetStateAction<boolean>>;
  saving: boolean;
}

const CustomerContext = createContext<CustomersContextValue>({} as CustomersContextValue);
export const CustomerProvider = CustomerContext.Provider;
export const CustomerConsumer = CustomerContext.Consumer;

export function useCustomers(): CustomersContextValue {
  const context = useContext(CustomerContext);
  return context;
}
