import React, { FormEvent, useEffect, useState } from 'react';
import AiArtInput from './AiArtInput';
import Appbar from 'components/appbar/Appbar';
import { openai } from 'services/openAi';
import { CreateImageRequestSizeEnum, ImagesResponseDataInner } from 'openai';
import AiArtOutput from './AiArtOutput';
import { styled } from '@mui/material';
import { useApp } from 'hooks/app';
import AiArtHelper from './AiArtHelper';
import AiArtMobile from './mobile/AiArtMobile';

export interface PromptProps {
  style: string;
  referencial_art: string;
  characteristics: string;
  size: CreateImageRequestSizeEnum;
}

const initial_value: PromptProps = {
  referencial_art: '',
  size: '1024x1024',
  style: 'imagem',
  characteristics: '',
};

const Container = styled('div')({
  flex: 1,
  rowGap: 40,
  display: 'flex',
  flexDirection: 'column',
});

const Form = styled('form')({
  flex: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const AiArt: React.FC = () => {
  const [useDallE, setUseDallE] = useState(false);
  const { handleOpenMenu, isOpenedMenu, isMobile } = useApp();
  const [searchText, setSearchText] = useState('');
  const [loadingChat, setLoadingChat] = useState(false);
  const [lastSearchText, setLastSearchText] = useState('');
  const [response, setResponse] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<ImagesResponseDataInner[]>([]);
  const [prompt, setPrompt] = useState<PromptProps>(initial_value);
  const [chatOpen, setChatOpen] = useState(false);

  useEffect(() => {
    setChatOpen(!!response);
  }, [response]);

  useEffect(() => {
    setText(
      `${prompt.referencial_art} ${prompt.characteristics}${prompt.style === 'imagem' ? '' : ', ' + prompt.style}`,
    );
  }, [prompt]);

  function handleChange(index: keyof PromptProps, value: any) {
    setPrompt(prompt => ({
      ...prompt,
      [index]: value,
    }));
  }

  async function handleSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setLoading(true);

    if (isOpenedMenu) {
      handleOpenMenu();
    }

    await openai
      .createImage({
        prompt: text,
        n: 4,
        size: prompt.size,
      })
      .then(response => setImages(response.data.data))
      .catch(err => console.error(err))
      .finally(() => {
        setText('');
        setLoading(false);
      });
  }

  async function handleSearch(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setLastSearchText(searchText + ' para ser usada no dall-e');
    setLoadingChat(true);

    openai
      .createChatCompletion({
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: useDallE ? searchText + ' para ser usada no dall-e' : searchText }],
      })
      .then(response => {
        const message = response.data.choices[0].message;

        if (message?.content) {
          setResponse(message?.content.replace(/\n/g, '<br>'));
        }
      })
      .catch(err => setResponse(`<p style="color: red">Falha na requisição. \n${err}</p>`))
      .finally(() => {
        setLoadingChat(false);
      });
  }

  return (
    <Container>
      <Appbar title="Artes gráficas" />

      {isMobile ? (
        <AiArtMobile />
      ) : (
        <>
          <Form onSubmit={handleSearch}>
            <AiArtHelper
              useDallE={useDallE}
              setUseDallE={setUseDallE}
              chatOpen={chatOpen}
              setChatOpen={setChatOpen}
              lastSearchText={lastSearchText}
              response={response}
              loading={loadingChat}
              text={searchText}
              setText={setSearchText}
            />
          </Form>

          <Form onClick={() => setChatOpen(false)} onSubmit={handleSubmit}>
            <AiArtInput prompt={prompt} handleChange={handleChange} loading={loading} />
          </Form>
          <AiArtOutput loading={loading} images={images} />
        </>
      )}
    </Container>
  );
};

export default AiArt;
