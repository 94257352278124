import React from 'react';
import { Typography, styled } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { ActivityMethod } from 'types/salesReport';
import ActivityItemTable from './ActivityItemTable';
import { activitiesTableTemplate } from '../activitiesTableTemplate';
import TableContainer from 'components/table/TableContainer';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type ActivityListTableProps = {
  activities: ActivityMethod[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const ActivityListTable: React.FC<ActivityListTableProps> = ({ activities, handleSort, orderedIndex }) => {
  return (
    <TableContainer tableTemplate={activitiesTableTemplate}>
      <div>
        <TableContent useMinHeight={false}>
          <TableHeader>
            {activitiesTableTemplate.map(item => (
              <HeaderItem
                className={item.dataType === 'number' ? 'numericData' : ''}
                key={item.id}
                onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </HeaderItem>
            ))}
          </TableHeader>
          <TableBody useMaxHeight={true}>
            {activities.map(activity => (
              <TableRow style={{ minHeight: 20, padding: 0 }} key={activity.atividade}>
                <ActivityItemTable activity={activity} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </div>
    </TableContainer>
  );
};

export default ActivityListTable;
