import { DisplayModeOptions } from 'hooks/useDisplayMode';
import { createContext, useContext } from 'react';
import { H2iOrder } from 'types/h2iOrder';

type DavReviewDetailValue = {
  handleClick(): void;
  handleUpdateDav(): void;
  order: H2iOrder;
  handleChange(index: keyof H2iOrder, value: any): void;
  displayMode: DisplayModeOptions;
};

const DavReviewDetail = createContext<DavReviewDetailValue>({} as DavReviewDetailValue);
export const DavReviewDetailProvider = DavReviewDetail.Provider;

export function useDavReviewDetail(): DavReviewDetailValue {
  const context = useContext(DavReviewDetail);
  return context;
}
