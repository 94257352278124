import React, { useState, useEffect } from 'react';
import TableContainer from 'components/table/TableContainer';
import PaginationProvider from 'hooks/pagination';
import { useFetchWastes } from './hooks/useFetchWastes';
import { WasteProvider } from './hooks/useWastes';
import { WasteOnList } from './types/waste';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { styled } from '@mui/material';
import WastesHeaderButtons from './WastesHeaderButtons';
import WastesFilterBox from './WastesFilterBox';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { wastesTableTemplate } from './constants/wasteTableTemplate';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import WasteListTable from './list/table/WasteListTable';
import WasteListModule from './list/module/WasteListModule';
import useTableOrder from 'hooks/tableOrder';
import Pagination from 'components/pagination/Pagination';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const Actions = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },
}));

export interface WasteQueryParams {
  nome: string;
  data_ini: Date;
  data_fim: Date;
  id_filial: number;
}

const Wastes: React.FC = () => {
  const { wastes, queryParams, setQueryParams, loading, fetch } = useFetchWastes();
  const [selectedWaste, setSelectedWaste] = useState<WasteOnList | null>(null);
  const [displayMode] = useDisplayMode();
  const [filtered, setFiltered] = useState<WasteOnList[]>([]);
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(wastes);
  }, [wastes]);

  function handleQueryParamsChange(index: keyof WasteQueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <WasteProvider value={{ selectedWaste, setSelectedWaste }}>
      <Appbar
        title="Projetos"
        ActionsComponent={
          <Actions>
            <WastesHeaderButtons />
          </Actions>
        }
      />

      <PageHeaderActions
        title="REEE"
        description="Resíduos de Equipamentos Elétricos e Eletrônicos"
        ActionComponent={<WastesHeaderButtons />}
      />

      <WastesFilterBox
        handleSubmit={fetch}
        queryParams={queryParams}
        handleQueryParamsChange={handleQueryParamsChange}
      />

      <TableContainer tableTemplate={wastesTableTemplate}>
        <PaginationProvider>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhuma REEE para mostrar" />
          ) : (
            <Container>
              {displayMode === 'list' ? (
                <WasteListTable wastes={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <WasteListModule wastes={filtered} />
              )}
            </Container>
          )}

          <Pagination count={filtered.length} />
        </PaginationProvider>
      </TableContainer>
    </WasteProvider>
  );
};

export default Wastes;
