import { addYears } from 'date-fns';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useEffect, useState } from 'react';
import { api } from 'services/api';
import { Course } from 'types/course';
import { Paginated } from 'types/paginated';

type UseFetchCourses = [Course[], boolean];

export function useFetchCourses(): UseFetchCourses {
  const [courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState(false);
  const { showErrorDialog } = useErrorHandler();

  useEffect(() => {
    const initialDate = new Date('2022-01-01');
    const finalDate = addYears(new Date(), 5);

    const params = {
      initial_date: initialDate,
      final_date: finalDate,
      rows: 100,
      page: 1,
    };

    api
      .get<Paginated<Course[]>>('/courses', { params })
      .then(response => {
        setCourses(response.data.data);
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível carregar os cursos',
        });
      })
      .finally(() => setLoading(false));
  }, [showErrorDialog]);

  return [courses, loading];
}
