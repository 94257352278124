import React, { useState, useEffect, FormEvent } from 'react';
import Appbar from 'components/appbar/Appbar';
import { Grid } from '@mui/material';
import PageHeader from 'components/page-header/PageHeader';
import * as yup from 'yup';
import ProfileForm from './ProfileForm';
import history from 'services/history';
import ProfileActions from './ProfileActions';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import Loading from 'components/loading/Loading';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import { AccessRule } from 'types/accessRule';
import { UserProfile } from 'types/userProfile';

export type ProfileValidation = {
  name?: string;
};

const Profile: React.FC = () => {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validation, setValidation] = useState<ProfileValidation>({});
  const { id } = useParams<{ id: string }>();
  const { handleOpen } = useMessaging();
  const [accessRules, setAccessRules] = useState<AccessRule[]>([]);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    if (!id) return;

    async function loadUserProfile() {
      const up = await api.get<UserProfile>(`/user-profiles/${id}`);
      setUserProfile(up.data);

      const ar = await api.get<AccessRule[]>('/access-rules-parents');

      function updateChildren(rules: AccessRule[]) {
        return rules.map(rule => {
          rule.enabled = !!up.data.access_rules.find(accessRule => accessRule.name === rule.name);
          rule.access_rules = updateChildren(rule.access_rules);
          return rule;
        });
      }

      setAccessRules(
        ar.data.map(rule => {
          rule.enabled = !!up.data.access_rules.find(accessRule => accessRule.name === rule.name);
          rule.access_rules = updateChildren(rule.access_rules);
          return rule;
        }),
      );
    }

    loadUserProfile()
      .catch(err => {
        console.error(err);

        if (err.response) {
          handleOpen(err.response.data.error);
          return;
        }

        handleOpen('Não foi possível carregar o perfil');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, handleOpen]);

  function handleAccessRulesChange(id: number) {
    const updateChildren = (rules: AccessRule[], state: boolean) =>
      rules.map(rule => {
        rule.enabled = state;
        rule.access_rules = updateChildren(rule.access_rules, state);
        return rule;
      });

    const handleSetValue = (rules: AccessRule[], id: number) =>
      rules.map(rule => {
        if (rule.id === id) {
          rule.enabled = !rule.enabled;
          rule.access_rules = updateChildren(rule.access_rules, !!rule.enabled);
        }
        handleSetValue(rule.access_rules, id);
        return rule;
      });

    const newValue = handleSetValue(accessRules, id);
    setAccessRules(JSON.parse(JSON.stringify(newValue)));
  }

  function handleUserProfileChange(value: string) {
    setUserProfile(oldValue =>
      oldValue
        ? {
            ...oldValue,
            name: value,
          }
        : null,
    );
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
    });

    schema
      .validate(userProfile)
      .then(() => {
        handleSubmit();
        setValidation({});
      })
      .catch(err => {
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);
    api
      .put(`/user-profiles/${id}`, { userProfile, accessRules })
      .then(() => {
        setSaving(false);
        handleOpen('Salvo');
        history.push('/user-profiles');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível salvar');
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Editar perfil"
        backAction={() => history.push('/user-profiles')}
        ActionsComponent={<ProfileActions handleSubmit={handleValidation} />}
      />
      {loading ? (
        <InsideLoading />
      ) : (
        <>
          <Grid container>
            <PageHeader backUrl="/user-profiles" title="Editar perfil" />
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={4} xl={4} md={6}>
              <form onSubmit={handleValidation}>
                <ProfileForm
                  validation={validation}
                  handleUserProfileChange={handleUserProfileChange}
                  handleAccessRulesChange={handleAccessRulesChange}
                  accessRules={accessRules}
                  profile={userProfile}
                />
              </form>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Profile;
