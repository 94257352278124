import { AbcProductSubgroup, ComparedAbcProductSubgroup } from 'types/abcProduct';
import { AbcProductDates } from '../types';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { formatDates } from '../helpers/formatDates';

type HandleCompareSubgroupsPayload = {
  subgroups: AbcProductSubgroup[];
  toCompare: AbcProductSubgroup[];
  dates: AbcProductDates;
  total: number;
};

type FormatPayload = {
  subgroups: ComparedAbcProductSubgroup[];
  initialDate: string;
  finalDate: string;
  total: number;
};

export function handleCompareSubgroups({
  subgroups,
  toCompare,
  dates,
  total,
}: HandleCompareSubgroupsPayload): ComparedAbcProductSubgroup[] {
  const _dates = formatDates(dates);

  const _subgroups = format({ subgroups, initialDate: _dates.initialDate, finalDate: _dates.finalDate, total });

  const _toCompare = format({
    subgroups: toCompare,
    initialDate: _dates.toCompareInitialDate,
    finalDate: _dates.toCompareFinalDate,
    total,
  });

  return merge(_subgroups, _toCompare);
}

function format(payload: FormatPayload): AbcProductSubgroup[] {
  return payload.subgroups.map(item => ({
    ...item,
    formattedTotal: moneyFormat(item.total),
    formattedCoust: moneyFormat(item.custo),
    formattedMc: moneyFormat(item.mc),
    formattedSpiff: moneyFormat(item.spiff),
    formattedUnitary: moneyFormat(item.unitario),
    formattedBilling: percentFormat((item.total / payload.total) * 100),
    billing: (item.total / payload.total) * 100,
    formattedName: `${payload.initialDate} - ${payload.finalDate}`,
  }));
}

function merge(subgroups: AbcProductSubgroup[], toCompare: AbcProductSubgroup[]): ComparedAbcProductSubgroup[] {
  const merged = [...subgroups, ...toCompare];

  const singleNames = new Set(merged.map(item => item.subgrupo));

  const names = [...singleNames].map(item => ({
    subgrupo: item,
    type: 'subtitle',
  })) as AbcProductSubgroup[];

  return [...names, ...merged];
}
