import { useApp } from 'hooks/app';
import React, { useCallback, useContext, useState, ReactNode, FormEvent } from 'react';
import { useSelector } from 'store/selector';
import { OrderTotals, PurchaseOrder } from 'types/purchaseOrder';
import { PurchaseOrderFilter } from '../PurchaseOrder';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import {
  PurchaseOrderBuyer,
  PurchaseOrderBuyerWithoutSale,
  PurchaseOrderItem,
  PurchaseOrderSegment,
} from 'types/purchaseOrderItem';
import { Aging, AgingBuyer } from 'types/aging';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { TransferRequest } from 'types/transferRequest';
import { StockItemList } from 'types/stockItem';
import { OverduePayment, PaymentFlow, BranchPayment } from 'types/paymentFlow';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ShoppingSuggestion } from 'types/shoppingSuggestion';

const initial_total: OrderTotals = {
  estoque: 0,
  pedido: 0,
  valor_total: 0,
  aging: 0,
  compras: 0,
  transferencias: 0,
  valor_estoque: 0,
  formattedStockValue: 'R$ 0,00',
  formattedTransfers: 'R$ 0,00',
  formattedShopping: 'R$ 0,00',
  formattedTotal: 'R$ 0,00',
  formattedAging: 'R$ 0,00',
};

interface Loading {
  buyersWithoutSales: boolean;
  items: boolean;
  registration: boolean;
  dashboard: boolean;
  aging: boolean;
  segments: boolean;
  transferRequests: boolean;
  stockItems: boolean;
  shoppingSuggestion: boolean;
  paymentFlow: boolean;
}

interface PurchaseOrderContextData {
  handleSearch(query: string, event: FormEvent<HTMLFormElement>): void;
  handleChange(index: keyof PurchaseOrderFilter, value: any): void;
  orders: PurchaseOrder[];
  ordersProducts: PurchaseOrderItem[];
  agingList: Aging[];
  agingBuyers: AgingBuyer[];
  buyersWithoutSales: PurchaseOrderBuyerWithoutSale[];
  ordersSegments: PurchaseOrderSegment[];
  ordersBuyers: PurchaseOrderBuyer[];
  loading: Loading;
  filter: PurchaseOrderFilter;
  totals: OrderTotals;
  onFilterClick(query: string): void;
  stockItemsGrouped: StockItemList[];
  stockItemsUngrouped: StockItemList[];
  transfers: TransferRequest[];
  filters: string;
  payments: PaymentFlow[];
  suggestions: ShoppingSuggestion[];
  overduePayments: OverduePayment[];
  branchesPayments: BranchPayment[];
}

const PurchaseOrderContext = React.createContext({} as PurchaseOrderContextData);
interface PurchaseOrderProviderProps {
  children: ReactNode;
}

const PurchaseOrderProvider: React.FC<PurchaseOrderProviderProps> = ({ children }) => {
  const user = useSelector(state => state.user);
  const [filters, setFilters] = useState('');
  const [totals, setTotals] = useState<OrderTotals>(initial_total);
  const [payments, setPayments] = useState<PaymentFlow[]>([]);
  const [overduePayments, setOverduePayments] = useState<OverduePayment[]>([]);
  const [buyersWithoutSales, setBuyersWithoutSales] = useState<PurchaseOrderBuyerWithoutSale[]>([]);
  const [ordersBuyers, setOrdersBuyers] = useState<PurchaseOrderBuyer[]>([]);
  const [agingList, setAgingList] = useState<Aging[]>([]);
  const [stockItemsGrouped, setStockItemsGrouped] = useState<StockItemList[]>([]);
  const [stockItemsUngrouped, setStockItemsUngrouped] = useState<StockItemList[]>([]);
  const [agingBuyers, setAgingBuyers] = useState<AgingBuyer[]>([]);
  const [transfers, setTransfers] = useState<TransferRequest[]>([]);
  const [ordersSegments, setOrdersSegments] = useState<PurchaseOrderSegment[]>([]);
  const [ordersProducts, setOrdersProducts] = useState<PurchaseOrderItem[]>([]);
  const [orders, setOrders] = useState<PurchaseOrder[]>([]);
  const [branchesPayments, setBranchesPayments] = useState<BranchPayment[]>([]);
  const [suggestions, setSuggestions] = useState<ShoppingSuggestion[]>([]);
  const [loading, setLoading] = useState<Loading>({
    buyersWithoutSales: false,
    items: false,
    registration: false,
    dashboard: false,
    aging: false,
    segments: false,
    transferRequests: false,
    stockItems: false,
    shoppingSuggestion: false,
    paymentFlow: false,
  });
  const { h2iApi, handleOpenMenu, isOpenedMenu } = useApp();
  const [filter, setFilter] = useState<PurchaseOrderFilter>({
    branch_id:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
    buyer: '',
    product: '',
    aging: '30',
    forecast: '30',
  });

  const handleSearch = useCallback(
    async (query: string, e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (!h2iApi) return;
      if (isOpenedMenu) {
        handleOpenMenu();
      }
      setLoading({
        aging: true,
        buyersWithoutSales: true,
        dashboard: true,
        registration: true,
        items: true,
        segments: true,
        transferRequests: true,
        stockItems: true,
        paymentFlow: true,
        shoppingSuggestion: true,
      });

      const params = {
        id_filial: filter.branch_id || '',
        produto: filter.product,
        aging: filter.aging,
        forecast: filter.forecast,
      };

      h2iApi
        .get(`/api/getsugestaotransferencia?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setTransfers([]);
            return;
          }
          setTransfers(
            response.data.map(item => ({
              ...item,
              custo_total: parseFloat(item.custo_total),
              formattedReleaseDate: parsePtBRDate(item.data_lanc).toISOString(),
              formattedQuantity: parseInt(item.qtd),
              formattedTotalCoust: moneyFormat(item.custo_total),
              formattedTotalWeight: parseFloat(item.peso_total),
            })),
          );
        })
        .catch(() => setTransfers([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            transferRequests: false,
          })),
        );

      h2iApi
        .get(`/api/getpedidototais?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setTotals(initial_total);
            return;
          }
          setTotals({
            ...response.data[0],
            formattedTotal: moneyFormat(response.data[0].valor_total),
            formattedStockValue: moneyFormat(response.data[0].valor_estoque),
            formattedShopping: moneyFormat(response.data[0].compras),
            formattedTransfers: moneyFormat(response.data[0].transferencias),
          });
        })
        .catch(() => setTotals(initial_total));

      h2iApi
        .get(`/api/getpedidocompras?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setOrders([]);
            return;
          }
          setOrders(
            response.data.map(item => ({
              ...item,
              formattedActualDate: new Date().toISOString(),
              formattedForecast: item.previsao && parsePtBRDate(item.previsao).toISOString(),
              formattedNewForecast: parsePtBRDate(item.nova_previsao).toISOString(),
              formattedDate: item.data && parsePtBRDate(item.data).toISOString(),
              formattedDateIssue: item.data_emissao_nfe && parsePtBRDate(item.data_emissao_nfe).toISOString(),
              formattedTotal: moneyFormat(item.valor_total_pedido),
              formattedShipment: moneyFormat(item.valor_frete),
              formattedIpi: moneyFormat(item.valor_ipi),
              formattedSt: moneyFormat(item.valor_st),
              formattedExpense: moneyFormat(item.valor_despesa),
              formattedDiscount: moneyFormat(item.valor_desconto),
            })),
          );
        })
        .catch(() => setOrders([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            registration: false,
          })),
        );

      h2iApi
        .get(`/api/getestoquexforecast?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setSuggestions([]);
            return;
          }
          setSuggestions(
            response.data.map(item => ({
              ...item,
              pedidos: parseFloat(item.pedidos),
              estoque: parseFloat(item.estoque),
              forecast: parseFloat(item.forecast),
              id_produto: parseFloat(item.id_produto),
              formattedArrivalForecast: item.previsao_chegada && parsePtBRDate(item.previsao_chegada).toISOString(),
            })),
          );
        })
        .catch(() => setSuggestions([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            shoppingSuggestion: false,
          })),
        );

      h2iApi
        .get(`/api/getpedidoitens?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setOrdersProducts([]);
            return;
          }
          setOrdersProducts(
            response.data.map(item => ({
              ...item,
              formattedPercent: percentFormat(item.perc_comprou_caro),
              formattedCoust: moneyFormat(item.custo),
              formattedNegotiatedValue: moneyFormat(item.valor_negociado),
              formattedValueTotal: moneyFormat(item.valor_total),
              formattedReleaseDate: item.data_lancamento && parsePtBRDate(item.data_lancamento).toISOString(),
              formattedMinForecast: parsePtBRDate(item.previsaomin).toISOString(),
              formattedMaxForecast: parsePtBRDate(item.previsaomax).toISOString(),
            })),
          );
        })
        .catch(() => setOrdersProducts([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            items: false,
          })),
        );

      h2iApi
        .get(`/api/getpedidosegmentos?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setOrdersSegments([]);
            return;
          }
          setOrdersSegments(response.data);
        })
        .catch(() => setOrdersSegments([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            segments: false,
          })),
        );

      h2iApi
        .get(`/api/getpedidocomprador?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setOrdersBuyers([]);
            return;
          }
          setOrdersBuyers(response.data);
        })
        .catch(() => setOrdersBuyers([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            dashboard: false,
          })),
        );

      h2iApi
        .get(`/api/getpedidosemvendas?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setBuyersWithoutSales([]);
          }
          setBuyersWithoutSales(
            response.data.map(item => ({
              ...item,
              formattedValue: moneyFormat(item.valor),
              formattedQuantity: parseInt(item.qtd_itens),
            })),
          );
        })
        .catch(() => setBuyersWithoutSales([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            buyersWithoutSales: false,
          })),
        );

      const fetchGroupedProductsSoldOutStock = h2iApi
        .get(`/api/getprodutosvendeuzerou?agrupado=S&${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setStockItemsGrouped([]);
            return;
          }
          setStockItemsGrouped(
            response.data.map(item => ({
              ...item,
              formattedExpectedArrivalDate: item.PrevChega && parsePtBRDate(item.PrevChegada).toISOString(),
              pedidos: item.pedidos && parseInt(item.pedidos),
            })),
          );
        })
        .catch(() => setStockItemsGrouped([]));

      const fetchUngroupedProductsSoldOutStock = h2iApi
        .get(`/api/getprodutosvendeuzerou?agrupado=N&${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setStockItemsUngrouped([]);
            return;
          }
          setStockItemsUngrouped(
            response.data.map(item => ({
              ...item,
              formattedExpectedArrivalDate: item.PrevChega && parsePtBRDate(item.PrevChegada).toISOString(),
              pedidos: item.pedidos && parseInt(item.pedidos),
            })),
          );
        })
        .catch(() => setStockItemsUngrouped([]));

      const fetchAgingList = h2iApi.get(`/api/getpedidoaging?${query}`, {
        params,
      });

      const fetchAgingBuyer = h2iApi.get(`/api/getpedidoagingcomprador?${query}`, {
        params,
      });

      h2iApi
        .get(`/api/getpedidopagamentofilial?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setBranchesPayments([]);
            return;
          }
          setBranchesPayments(
            response.data.map(item => ({
              ...item,
              formattedDueDate: item.vencimento && parsePtBRDate(item.vencimento).toISOString(),
              formattedValue: moneyFormat(item.valor),
            })),
          );
        })
        .catch(() => setBranchesPayments([]));

      const fetchPayments = h2iApi
        .get(`/api/getpedidopagamento?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setPayments([]);
            return;
          }
          setPayments(
            response.data.map(item => ({
              ...item,
              formattedDayOfWeek: item.vencimento && format(parsePtBRDate(item.vencimento), 'EEEE', { locale: ptBR }),
              formattedDueDate: item.vencimento && parsePtBRDate(item.vencimento).toISOString(),
              formattedValue: moneyFormat(item.valor),
            })),
          );
        })
        .catch(() => setPayments([]));

      const fetchOverduePayments = h2iApi
        .get(`/api/getpedidopagamentovencido?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setOverduePayments([]);
            return;
          }
          setOverduePayments(
            response.data.map(item => ({
              ...item,
              formattedReleaseDate: parsePtBRDate(item.data_lancamento).toISOString(),
              formattedArrivalDate: parsePtBRDate(item.previsao_chegada).toISOString(),
              formattedDueDate: parsePtBRDate(item.vencimento).toISOString(),
              formattedValue: moneyFormat(item.valor),
            })),
          );
        })
        .catch(() => setOverduePayments([]));

      Promise.all([fetchPayments, fetchOverduePayments]).finally(() =>
        setLoading(state => ({
          ...state,
          paymentFlow: false,
        })),
      );

      Promise.all([fetchUngroupedProductsSoldOutStock, fetchGroupedProductsSoldOutStock]).finally(() =>
        setLoading(state => ({
          ...state,
          stockItems: false,
        })),
      );

      Promise.all([fetchAgingList, fetchAgingBuyer])
        .then(([agingResponse, agingBuyerResponse]) => {
          if (agingResponse.data.MESSAGE) {
            setAgingList([]);
            setAgingBuyers([]);
            return;
          }

          const _agingList: Aging[] = agingResponse.data;
          const _agingBuyer: AgingBuyer[] = agingBuyerResponse.data;

          setTotals(state => ({
            ...state,
            aging: _agingList.reduce((sum, item) => sum + item.custo_total, 0),
            formattedAging: moneyFormat(_agingList.reduce((sum, item) => sum + item.custo_total, 0)),
          }));

          setAgingBuyers(
            _agingBuyer.map(item => ({
              ...item,
              formattedTotalCoust: moneyFormat(item.custo_total),
              formattedStock: parseInt(item.estoque),
            })),
          );

          setAgingList(
            _agingList.map(item => ({
              ...item,
              formattedCoust: moneyFormat(item.custo),
              formattedTotalCoust: moneyFormat(item.custo_total),
              formattedStock: parseInt(item.estoque),
            })),
          );
        })
        .catch(() => {
          setAgingList([]);
          setAgingBuyers([]);
        })
        .finally(() =>
          setLoading(state => ({
            ...state,
            aging: false,
          })),
        );
    },
    [filter, h2iApi, handleOpenMenu, isOpenedMenu],
  );

  function handleChange(index: keyof PurchaseOrderFilter, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function onFilterClick(query: string) {
    handleSearch(query);
    setFilters(query);
  }

  return (
    <PurchaseOrderContext.Provider
      value={{
        handleSearch,
        handleChange,
        agingList,
        agingBuyers,
        buyersWithoutSales,
        ordersSegments,
        ordersBuyers,
        loading,
        filter,
        totals,
        onFilterClick,
        stockItemsGrouped,
        stockItemsUngrouped,
        transfers,
        filters,
        ordersProducts,
        orders,
        payments,
        overduePayments,
        branchesPayments,
        suggestions,
      }}
    >
      {children}
    </PurchaseOrderContext.Provider>
  );
};

export function usePurchaseOrder(): PurchaseOrderContextData {
  const context = useContext(PurchaseOrderContext);

  if (!context) throw new Error('This hook must be in PurchaseOrder Context Component');

  return context;
}

export default PurchaseOrderProvider;
