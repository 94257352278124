import React from 'react';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { usePagination } from 'hooks/pagination';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { dashboardUnityTableTemplate } from './dashboardUnityTableTemplate';
import UnityItemTable from './UnityItemTable';
import { AbcProductUnity } from 'types/abcProduct';
import { Grid, Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

type DashboardUnityListTableProps = {
  units: AbcProductUnity[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const useStyles = makeStyles({
  header: {
    background: 'rgb(209 253 200)',
  },
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
    marginRight: 10,
  },
  headerItemQuantity: {
    justifyContent: 'center',
  },
  body: {
    maxHeight: 'calc(100vh - 100px)',
  },
});

const DashboardUnityListTable: React.FC<DashboardUnityListTableProps> = ({ units, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <TableContainer tableTemplate={dashboardUnityTableTemplate}>
        <TableContent>
          <TableHeader className={classes.header}>
            {dashboardUnityTableTemplate.map(item => (
              <div
                className={
                  item.dataType === 'number' ? `${classes.headerItem} ${classes.headerItemNumeric}` : classes.headerItem
                }
                key={item.id}
                onClick={() => handleSort(item.originalId)}
              >
                <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
          </TableHeader>
          <TableBody className={classes.body}>
            {units.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((unity, index) => (
              <TableRow key={index}>
                <UnityItemTable unity={unity} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </Grid>
  );
};

export default DashboardUnityListTable;
