import Dialog from 'components/dialogs/Dialog';
import { useApp } from 'hooks/app';
import React, { FormEvent, useState, SetStateAction, Dispatch } from 'react';
import { useRevenueValidation } from '../../validation/revenueValidation';
import { Revenue } from 'types/revenue';
import RevenueForm from '../RevenueForm';
import InsideSaving from 'components/loading/InsideSaving';
import { Button, styled } from '@mui/material';
import { useMessaging } from 'hooks/messaging';

interface EditRevenueProps {
  onExited(): void;
  onSubmit(): void;
  selectedRevenue: Revenue;
  setSelectedRevenue: Dispatch<SetStateAction<Revenue | null>>;
}

const Form = styled('form')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const EditRevenue: React.FC<EditRevenueProps> = ({ onExited, onSubmit, selectedRevenue, setSelectedRevenue }) => {
  const { financialApi } = useApp();
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);
  const [validation, setValidation, validate] = useRevenueValidation();

  function handleValidation(e?: FormEvent<HTMLFormElement | HTMLButtonElement>) {
    e?.preventDefault();

    setValidation({});

    validate(selectedRevenue)
      .then(handleSubmit)
      .catch(error => {
        console.log(error);
        handleOpen('Não foi possível salvar a receita');
      });
  }

  function handleSubmit() {
    setSaving(true);

    financialApi
      .post('/savecadastroreceita', selectedRevenue)
      .then(onSubmit)
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleChange(index: keyof Revenue, value: any) {
    setSelectedRevenue(revenue =>
      revenue
        ? {
            ...revenue,
            [index]: value,
          }
        : null,
    );
  }

  return (
    <Dialog
      maxWidth="md"
      height="80vh"
      onExited={onExited}
      title="editar receita"
      ComponentActions={
        <Button onClick={handleValidation} variant="contained">
          Salvar
        </Button>
      }
    >
      {saving && <InsideSaving />}
      <Form onSubmit={handleValidation}>
        <RevenueForm revenue={selectedRevenue} validation={validation} handleChange={handleChange} />
      </Form>
    </Dialog>
  );
};

export default EditRevenue;
