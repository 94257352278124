import React, { useState } from 'react';
import { Typography, styled } from '@mui/material';
import ResellerItemTable from './CustomerItemTable';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import CustomersListMenu from '../CustomersListMenu';
import { Customer } from 'types/customers';
import history from 'services/history';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type CustomerListTableProps = {
  customers: Customer[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const CustomerListTable: React.FC<CustomerListTableProps> = ({ customers, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <>
      <CustomersListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <HeaderItem key={item.id} onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}>
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody>
          {customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(customer => (
            <TableRow onClick={() => history.push(`/site/customer/${customer.id}`)} key={customer.id}>
              <ResellerItemTable customer={customer} setAnchorEl={setAnchorEl} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default CustomerListTable;
