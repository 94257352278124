import { cnpjValidator } from 'helpers/cnpjValidator';
import { cpfValidator } from 'helpers/cpfValidator';
import { useApp } from 'hooks/app';
import { Dispatch, SetStateAction, useState } from 'react';
import { CfopH2i } from 'types/cfop';
import { PurchaseOrder } from 'types/purchaseOrder';
import * as yup from 'yup';

export type PurchaseOrderValidation = {
  cnpj?: string;
  cliente?: string;
  date_forecast?: string;
  date_new_forecast?: string;
  cfop?: string;
  id_filial?: string;
};

type UsePurchaseOrderValidation = [
  PurchaseOrderValidation,
  Dispatch<SetStateAction<PurchaseOrderValidation>>,
  (purchaseOrder: PurchaseOrder) => Promise<void>,
];

export function usePurchaseOrderValidation(): UsePurchaseOrderValidation {
  const { h2iApi } = useApp();
  async function handleValidation(purchaseOrder: PurchaseOrder) {
    const schema = yup.object().shape({
      cfop: yup
        .string()
        .required('O CFOP é obrigatório')
        .test('cfopValidation', 'CFOP inválido', async value => {
          if (!value) return false;

          if (value.length !== 4) return false;

          if (!h2iApi) return false;

          const response = await h2iApi.get('/api/getcfop');
          const _response: CfopH2i[] = response.data.cfop;

          if (!_response) return false;

          const cfops = _response.map(item => item.cfop.replace(',', ''));

          if (cfops.includes(value)) {
            return true;
          } else {
            return false;
          }
        }),
      date_new_forecast: yup.date().required('A nova previsão é obrigatória').typeError('A data não é válida'),
      date_forecast: yup.date().required('A data previsão é obrigatória').typeError('A data não é válida'),
      cliente: yup.string().required('O nome do cliente é obrigatório'),
      cnpj: yup
        .string()
        .transform((value, originalValue) => {
          return originalValue ? originalValue.replace(/\D/g, '') : '';
        })
        .required(`CNPJ ou CPF é obrigatório`)
        .test('docValidation', `documento inválido`, value => {
          if (!value) return false;

          const cnpj = cnpjValidator(value);
          const cpf = cpfValidator(value);

          return cnpj || cpf;
        }),
      id_filial: yup
        .string()
        .required(`Informe a filial`)
        .test('branchValidation', `Selecione uma filial`, value => {
          if (!value) return false;
          if (parseInt(value) === 0) return false;
          return true;
        }),
    });

    try {
      await schema.validate(purchaseOrder);
    } catch (err) {
      const error = err as yup.ValidationError;

      if (error.path) {
        setValidation({
          [error.path]: error.message,
        });
      }

      throw new Error(error.message);
    }
  }

  const [validation, setValidation] = useState<PurchaseOrderValidation>({});
  return [validation, setValidation, handleValidation];
}
