import React from 'react';
import { styled } from '@mui/material';
import TopSegments from './TopSegments';
import TopSegmentsChart from './TopSegmentsChart';
import { usePurchaseOrder } from '../../hooks/PurchaseOrderProvider';

const Container = styled('div')({
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  rowGap: 10,
  flex: 1,
  '& svg': {
    fontSize: 12,
  },
});

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr 2fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const PurchaseOrderSegments: React.FC = () => {
  const { ordersSegments } = usePurchaseOrder();
  return (
    <Container>
      <Content>
        <TopSegmentsChart data={ordersSegments} />
        <TopSegments data={ordersSegments} />
      </Content>
    </Container>
  );
};

export default PurchaseOrderSegments;
