import React from 'react';
import { Checkbox, FormControlLabel, Theme } from '@mui/material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { useNewsletterSubscribers } from './hooks/useNewsletters';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
    '& > .search-params': {
      display: 'grid',
      gridTemplateColumns: '200px 200px 200px',
      columnGap: 20,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}));

type NewsletterSubscribersBoxProps = {
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const NewsletterSubscribersBox: React.FC<NewsletterSubscribersBoxProps> = ({ setDisplayMode, displayMode }) => {
  const classes = useStyles();
  const { filter, handleChangeFilter } = useNewsletterSubscribers();

  return (
    <FilterBox>
      <div className={classes.filter}>
        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initial_date}
            onChange={date => handleChangeFilter('initial_date', date)}
            textFieldProps={{ margin: 'none' }}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.final_date}
            onChange={date => handleChangeFilter('final_date', date)}
            textFieldProps={{ margin: 'none' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filter.is_exported}
                onChange={e => handleChangeFilter('is_exported', e.target.checked)}
                color="primary"
              />
            }
            label="Exportados"
          />
        </div>
      </div>

      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default NewsletterSubscribersBox;
