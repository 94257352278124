import { MonthlyFilterParams } from '../MonthlyComparison';
import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import { MonthlyBranch, MonthlyCategory, MonthlyPeriod, MonthlyManufacturer } from 'types/monthlyComparison';

type MonthlyComparisonContextValue = {
  moreCategory: MonthlyCategory | null;
  setMoreCategory: Dispatch<SetStateAction<MonthlyCategory | null>>;
  selectedCategory: MonthlyCategory | null;
  setSelectedCategory(category: MonthlyCategory | null): void;
  morePeriod: MonthlyPeriod | null;
  setMorePeriod: Dispatch<SetStateAction<MonthlyPeriod | null>>;
  selectedPeriod: MonthlyPeriod | null;
  setSelectedPeriod(period: MonthlyPeriod | null): void;
  moreBranch: MonthlyBranch | null;
  setMoreBranch: Dispatch<SetStateAction<MonthlyBranch | null>>;
  selectedBranch: MonthlyBranch | null;
  setSelectedBranch(branch: MonthlyBranch | null): void;
  moreManufacturer: MonthlyManufacturer | null;
  setMoreManufacturer: Dispatch<SetStateAction<MonthlyManufacturer | null>>;
  selectedManufacturer: MonthlyManufacturer | null;
  setSelectedManufacturer(monthlyManufacturer: MonthlyManufacturer | null): void;
  searchValue: string;
  filter: MonthlyFilterParams;
  handleChangeFilter(index: keyof MonthlyFilterParams, value: any): void;
};

const MonthlyComparisonContext = createContext<MonthlyComparisonContextValue>({} as MonthlyComparisonContextValue);
export const MonthlyComparisonProvider = MonthlyComparisonContext.Provider;

export function useMonthlyComparison(): MonthlyComparisonContextValue {
  const context = useContext(MonthlyComparisonContext);
  return context;
}
