import React from 'react';
import { List } from '@mui/material';
import { usePagination } from 'hooks/pagination';

import PeriodItemModule from './PeriodItemModule';
import { MonthlyPeriod } from 'types/monthlyComparison';

import { makeStyles } from '@mui/styles';

type PeriodListModuleProps = {
  periods: MonthlyPeriod[];
};

const styles = makeStyles({
  list: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: 100,
    flex: 1,
  },
});

const PeriodListModule: React.FC<PeriodListModuleProps> = ({ periods }) => {
  const { rowsPerPage, page } = usePagination();
  const classes = styles();

  return (
    <List className={classes.list}>
      {periods.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(period => (
        <PeriodItemModule key={period.mes_ano} period={period} />
      ))}
    </List>
  );
};

export default PeriodListModule;
