import React, { MouseEvent, Dispatch, SetStateAction } from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import { costsTableTemplate } from '../costsTableTemplate';
import { ProjectCost } from 'types/project';
import { MoreHoriz } from '@mui/icons-material';
import { useProjectCosts } from '../../hooks/useProjectCosts';

const IconButtonStyled = styled(IconButton)({
  justifySelf: 'flex-start',
  padding: 5,
});

type CostItemTableProps = {
  cost: ProjectCost;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const CostItemTable: React.FC<CostItemTableProps> = ({ cost, setAnchorEl }) => {
  const { setSelectedProjectCost } = useProjectCosts();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedProjectCost(cost);
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      {costsTableTemplate.map(item =>
        item.id === 'actions' ? (
          <IconButtonStyled onClick={handleClick} size="small" key={item.id}>
            <MoreHoriz key={item.id} />
          </IconButtonStyled>
        ) : (
          <div key={item.id}>
            <Typography variant="body2">{cost[item.id]}</Typography>
          </div>
        ),
      )}
    </>
  );
};

export default CostItemTable;
