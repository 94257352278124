import { Dispatch, SetStateAction, useState } from 'react';
import { Address } from 'types/address';
import * as yup from 'yup';

export interface RegisterAddressValidation {
  postal_code?: string;
  street?: string;
  street_number?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
}

type UseRegisterAddressValidation = [
  RegisterAddressValidation,
  Dispatch<SetStateAction<RegisterAddressValidation>>,
  (address: Address) => Promise<void>,
];

export function useRegisterAddressValidation(): UseRegisterAddressValidation {
  async function handleValidation(address: Address) {
    const schema = yup.object().shape({
      state: yup.string().required('O estado é obrigatório'),
      city: yup.string().required('A cidade é obrigatória'),
      neighborhood: yup.string().required('O bairro é obrigatório'),
      street_number: yup.string().required('O número é obrigatório'),
      street: yup.string().required('O logradouro é obrigatório'),
      postal_code: yup.string().required('O CEP é obrigatório'),
    });

    try {
      await schema.validate(address);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<RegisterAddressValidation>({});
  return [validation, setValidation, handleValidation];
}
