import React from 'react';
import { useTable } from 'components/table/hook/useTable';
import { Tooltip, Typography, styled } from '@mui/material';
import { PurchaseOrderItem } from 'types/purchaseOrderItem';

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
  width: 350,
});

interface PurchaseOrderProductItemTableProps {
  product: PurchaseOrderItem;
}

const PurchaseOrderProductItemTable: React.FC<PurchaseOrderProductItemTableProps> = ({ product }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
            <Tooltip title={product[item.id]}>
              <Description variant="caption">{product[item.id]}</Description>
            </Tooltip>
          </div>
        ))}
    </>
  );
};

export default PurchaseOrderProductItemTable;
