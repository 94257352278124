import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useAgents } from '../hooks/useAgents';

type AgentsListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const AgentsListMenu: React.FC<AgentsListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { setDialogApprove } = useAgents();

  function handleClick() {
    setDialogApprove(true);
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleClick}>Aprovar cadastro</MenuItem>
    </Menu>
  );
};

export default AgentsListMenu;
