import React from 'react';

import Dialog from 'components/dialogs/Dialog';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSelector } from 'store/selector';
import { Branch } from 'types/branch';
import StockBalanceReportActions from '../stock/StockBalanceReportActions';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { Sales } from 'types/reports/sales';

import { makeStyles } from '@mui/styles';

type SalesReportDataProps = {
  data: Sales[];
  branch?: Branch;
  onExited(): void;
};

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '80px 120px 100px 100px 100px 1fr 120px 70px 1fr',
    marginBottom: 1,
    paddingBottom: 1,
  },
  headerInfo: {
    marginTop: 10,
  },
  rowP: {
    marginRight: '10px',
    fontSize: '7pt',
    textAlign: 'end',
  },
  rowPL: {
    fontSize: '7pt',
    textAlign: 'start',
  },
  rowPC: {
    fontSize: '7pt',
    textAlign: 'center',
  },
});

const SalesReportData: React.FC<SalesReportDataProps> = ({ data, branch, onExited }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);

  return (
    <Dialog fullScreen title="Relação de vendas" onExited={onExited} ComponentActions={<StockBalanceReportActions />}>
      <div className="page">
        <div className="header">
          <div>
            <h6>Relatório de vendas</h6>
            {branch?.id === undefined ? <h6>Todas as filiais</h6> : <h6>Filial {branch?.nome}</h6>}
          </div>
          <div>
            <p>{user?.name}</p>
            <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
          </div>
        </div>
        <div className={`${classes.row} table-header`}>
          <p className={classes.rowPL}>Origem</p>
          <p className={classes.rowPL}>Lançamento</p>
          <p className={classes.rowPL}>Expedição</p>
          <p className={classes.rowPL}>Pedido por</p>
          <p className={classes.rowPL}>Vendedor</p>
          <p className={classes.rowPL}>Cliente</p>
          <p className={classes.rowP}>Valor total</p>
          <p className={classes.rowP}>Spiff</p>
          <p className={classes.rowPL}>Atividade</p>
        </div>
        {data.map(item => (
          <div key={item.numero_venda} className={classes.row}>
            <p className={classes.rowPL}>{item.origem}</p>
            <p className={classes.rowPL}>{item.lancamento}</p>
            <p className={classes.rowPL}>{item.expedicao}</p>
            <p className={classes.rowPL}>{item.pedido_por}</p>
            <p className={classes.rowPL}>{item.vendedor}</p>
            <p className={classes.rowPL}>{item.cliente}</p>
            <p className={classes.rowP}>{moneyFormat(item.vl_total)}</p>
            <p className={classes.rowP}>{numberFormat(item.spiff)}</p>
            <p className={classes.rowPL}>{item.atividade}</p>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default SalesReportData;
