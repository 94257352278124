import Dialog, { useDialog } from 'components/dialogs/Dialog';
import React, { useState, useRef, useEffect, SetStateAction, Dispatch } from 'react';
import { TextField } from '@mui/material';
import AgentsRejectConfirmActions from './AgentsRejectConfirmActions';

interface AgentsRejectConfirmProps {
  onExited(): void;
  handleDecline(handleDialogClose: () => void): void;
  rejectMessage: string;
  setRejectMessage: Dispatch<SetStateAction<string>>;
}

const AgentsRejectConfirm: React.FC<AgentsRejectConfirmProps> = ({
  onExited,
  handleDecline,
  rejectMessage,
  setRejectMessage,
}) => {
  const [inputError, setInputError] = useState('');
  const input = useRef<HTMLInputElement>(null);
  const { handleClose: handleCloseParentDialog } = useDialog();

  useEffect(() => {
    if (!inputError) return;

    input.current?.focus();
  }, [inputError]);

  function handleConfirm(handleCloseThisDialog: () => void) {
    setInputError('');

    if (!rejectMessage) {
      setInputError('A justificativa é obrigatória');
      return;
    }

    handleCloseThisDialog();

    handleDecline(handleCloseParentDialog);
  }

  return (
    <Dialog
      hideBackdrop
      maxWidth="sm"
      height="70vh"
      onExited={onExited}
      title="Justifique a rejeição"
      ComponentActions={<AgentsRejectConfirmActions handleConfirm={handleConfirm} />}
    >
      <TextField
        ref={input}
        label="Motivo da rejeição"
        placeholder="Digite o motivo da rejeição"
        multiline
        rows={5}
        fullWidth
        autoFocus
        error={!!inputError}
        helperText={inputError}
        value={rejectMessage}
        onChange={e => setRejectMessage(e.target.value)}
      />
    </Dialog>
  );
};

export default AgentsRejectConfirm;
