import { RelatedProduct } from 'pages/related-product/types/relatedProduct';
import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export type RelatedProductValidation = {
  description?: string;
  products?: string;
};

type UseRelatedProductValidation = [
  RelatedProductValidation,
  Dispatch<SetStateAction<RelatedProductValidation>>,
  (relatedProduct: RelatedProduct) => Promise<void>,
];

export function useRelatedProductValidation(): UseRelatedProductValidation {
  async function handleValidation(relatedProduct: RelatedProduct) {
    const schema = yup.object().shape({
      description: yup.string().required('A descrição é obrigatória'),
      products: yup.array().min(1).required('os produtos são obrigatórios'),
    });

    try {
      await schema.validate(relatedProduct);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<RelatedProductValidation>({});
  return [validation, setValidation, handleValidation];
}
