import { Button, Typography, styled } from '@mui/material';
import React from 'react';
import { usePriceProtection } from '../hook/usePriceProtection';
import Dialog from 'components/dialogs/Dialog';

const Container = styled('div')({
  flex: 1,
  gap: 50,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
});

const ButtonActions = styled('div')({
  gap: 15,
  padding: 10,
  display: 'flex',
  justifyContent: 'space-evenly',
});

interface ConfirmSaveProps {
  handleNotConfirm(): void;
}

const ConfirmSave: React.FC<ConfirmSaveProps> = ({ handleNotConfirm }) => {
  const { handleSubmit } = usePriceProtection();

  return (
    <Dialog
      onExited={handleNotConfirm}
      title="Confirme"
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth="sm"
      height="50vh"
    >
      <Container>
        <Typography fontWeight={800}>Essa ação é irreversível, você deseja continuar?</Typography>
        <ButtonActions className="confirm-save">
          <Button color="secondary" variant="contained" onClick={handleSubmit}>
            Salvar
          </Button>
          <Button color="secondary" variant="outlined" onClick={handleNotConfirm}>
            Voltar
          </Button>
        </ButtonActions>
      </Container>
    </Dialog>
  );
};

export default ConfirmSave;
