import { TableTemplate } from 'types/tableTemplate';

export const exportEmailTableTemplate: TableTemplate[] = [
  {
    id: 'email',
    description: 'E-MAIL',
    originalId: 'email',
    width: 350,
  },
  {
    id: 'nome',
    description: 'NOME',
    originalId: 'nome',
    width: 350,
  },
];
