import { ListItem, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useReseller } from 'pages/resellers/hooks/useReseller';
import React from 'react';
import { ResellerDocument } from 'types/reseller';

const styles = makeStyles<Theme>(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '80px 80px',
    gap: 15,
  },
  linkDelete: {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
}));

interface OtherDocumentItemProps {
  document: ResellerDocument;
}

const OtherDocumentItem: React.FC<OtherDocumentItemProps> = ({ document }) => {
  const { handleDeleteDocument } = useReseller();
  const classes = styles();

  return (
    <ListItem disableGutters>
      <Typography>{document.description}</Typography>
      <div className={classes.container}>
        <a target="_blank" rel="noopener noreferrer" href={document.document.document_url}>
          visualizar
        </a>
        <span className={classes.linkDelete} onClick={() => handleDeleteDocument(document.id)}>
          excluir
        </span>
      </div>
    </ListItem>
  );
};

export default OtherDocumentItem;
