import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField, styled } from '@mui/material';
import { CourseValidation } from './validation/courseValidation';
import ImageSelector from 'components/image-selector/ImageSelector';
import ImagePreview from 'components/image-preview/ImagePreview';
import { Course } from 'types/course';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import ImageUpload from 'components/image-upload/ImageUpload';
import { Image } from 'types/image';

const ImageUploadContainer = styled('div')({
  marginTop: 30,
});

type CourseFormProps = {
  course: Course;
  handleChange(index: keyof Course, value: any): void;
  validation: CourseValidation;
};

const CourseForm: React.FC<CourseFormProps> = ({ course, handleChange, validation }) => {
  const [imageSelector, setImageSelector] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<null | string>('');

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    url: useRef<HTMLInputElement>(null),
    price: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
    happen_at: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  function handleImageChange(image: Image) {
    handleChange('image', image);
    handleChange('image_id', image.id);
  }

  return (
    <>
      {!!selectedImageSrc && (
        <ImagePreview
          src={selectedImageSrc}
          description="Imagem da mensagem de e-mail"
          onExited={() => setSelectedImageSrc('')}
        />
      )}

      {imageSelector && (
        <ImageSelector
          handleSetImageId={image => handleChange('image', image)}
          onExited={() => setImageSelector(false)}
        />
      )}

      <Grid item xs={12} xl={4} lg={4} md={6}>
        <TextField
          inputRef={inputs.name}
          error={!!validation.name}
          helperText={validation.name}
          label="Nome"
          placeholder="Nome do curso"
          margin="normal"
          fullWidth
          value={course.name}
          onChange={e => handleChange('name', e.target.value)}
          autoFocus
        />

        <TextField
          inputRef={inputs.description}
          error={!!validation.description}
          helperText={validation.description}
          label="Descrição"
          placeholder="Descrição do curso"
          margin="normal"
          fullWidth
          value={course.description}
          onChange={e => handleChange('description', e.target.value)}
          rows={5}
          multiline
        />

        <TextField
          label="Quantidade de participantes"
          placeholder="Quantidade de participantes"
          margin="normal"
          fullWidth
          value={course.students_quantity}
          onChange={e => handleChange('students_quantity', e.target.value)}
          autoFocus
        />

        <TextField
          inputRef={inputs.price}
          error={!!validation.price}
          helperText={validation.price}
          label="Preço R$"
          placeholder="Preço do curso"
          margin="normal"
          fullWidth
          value={course.price}
          onChange={e => handleChange('price', e.target.value)}
          type="number"
          inputMode="decimal"
        />

        <KeyboardDatePicker
          inputRef={inputs.happen_at}
          label="Acontence em"
          value={course.happen_at}
          onChange={date => handleChange('happen_at', date)}
          textFieldProps={{
            margin: 'normal',
            fullWidth: true,
            placeholder: 'Curso acontece em',
          }}
          localeText={{
            clearButtonLabel: 'Limpar',
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />

        <ImageUploadContainer>
          <ImageUpload
            label="Imagem do curso"
            image={course.image}
            handleRemoveImage={() => handleChange('image', null)}
            handleSetImage={handleImageChange}
          />
        </ImageUploadContainer>

        <button type="submit" style={{ display: 'none' }} />
      </Grid>
    </>
  );
};

export default CourseForm;
