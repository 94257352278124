import { createContext, useContext } from 'react';
import { NewsletterSubscriber } from 'types/newsletterSubscriber';
import { NewsletterSubscriberParams } from '../NewsletterSubscribers';

type NewsletterSubscriberValue = {
  selectedSubscriber: NewsletterSubscriber | null;
  setSelectedSubscriber(banner: NewsletterSubscriber | null): void;
  handleChangeFilter(index: keyof NewsletterSubscriberParams, value: any): void;
  filter: NewsletterSubscriberParams;
};

const NewsletterSubscribersContext = createContext<NewsletterSubscriberValue>({} as NewsletterSubscriberValue);
export const NewsletterSubscribersProvider = NewsletterSubscribersContext.Provider;

export function useNewsletterSubscribers(): NewsletterSubscriberValue {
  const context = useContext(NewsletterSubscribersContext);
  return context;
}
