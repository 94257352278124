import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useRental } from '../../hooks/useRental';
import { removeRentalProductAction } from 'pages/rentals/reducer/actions';
import { useProducts } from '../hooks/useProducts';

type ProjectProductsListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const ProjectProductsListMenu: React.FC<ProjectProductsListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedProduct } = useProducts();
  const { dispatch } = useRental();

  function handleDeleteClick() {
    if (!selectedProduct) {
      return;
    }

    dispatch(removeRentalProductAction(selectedProduct.external_id));
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default ProjectProductsListMenu;
