import React from 'react';
import { Typography, ListItem, styled } from '@mui/material';
import { FinancialCardFlag } from '../../../hooks/useFetchFinancialCard';

type FlagItemModuleProps = {
  flag: FinancialCardFlag;
};

const StyledListItem = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  position: 'relative',
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  alignItems: 'center',
  gridTemplateColumns: '110px 1fr',
});

const FlagItemModule: React.FC<FlagItemModuleProps> = ({ flag }) => {
  return (
    <StyledListItem>
      <Typography variant="h6">{flag.bandeira}</Typography>

      <Grid>
        <Typography variant="caption">Valor grosso:</Typography>
        <Typography color="#f30000" variant="body2">
          {flag.formattedValueGross}
        </Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Valor desconto:</Typography>
        <Typography color="#f30000" variant="body2">
          {flag.formattedValueDiscount}
        </Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Valor liquído:</Typography>
        <Typography color="green" fontWeight={600} fontSize={16} variant="button">
          {flag.formattedValueLiquid}
        </Typography>
      </Grid>
    </StyledListItem>
  );
};

export default FlagItemModule;
