import React, { useMemo } from 'react';
import { styled } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { externalTooltipHandler } from 'components/chart/externalTooltip';
import NoData from 'components/no-data/NoData';
import TopAgingBuyers from './TopAgingBuyers';
import { AgingBuyer } from 'types/aging';

const Container = styled('div')({
  border: '1px solid #eee',
  padding: 20,
  display: 'flex',
  borderRadius: 6,
  flexDirection: 'column',
  background: '#f9f7f2',
  alignItems: 'center',
  gap: 10,
  '& > div': {
    width: '100%',
  },
});

const ChartItem = styled('div')({
  display: 'flex',
  maxHeight: 350,
  justifyContent: 'center',
});

interface TopAgingBuyersChartProps {
  data: AgingBuyer[];
}

const TopAgingBuyersChart: React.FC<TopAgingBuyersChartProps> = ({ data }) => {
  const total = useMemo(() => {
    return data.reduce((sum, item) => sum + item.custo_total, 0);
  }, [data]);

  const pieDataBuyers = useMemo(() => {
    return data.sort((a, b) => b.custo_total - a.custo_total).map(item => item.custo_total);
  }, [data]);

  return (
    <Container>
      {data.length > 0 ? (
        <ChartItem>
          <Pie
            data={{
              labels: data.sort((a, b) => b.custo_total - a.custo_total).map(buyer => buyer.comprador),
              datasets: [
                {
                  borderWidth: 0,
                  backgroundColor: ['#56b5ab', '#45837f', '#3a4649', '#121813', '#9d1221'],
                  data: data.sort((a, b) => b.custo_total - a.custo_total).map(buyer => buyer.custo_total),
                },
              ],
            }}
            options={{
              plugins: {
                tooltip: {
                  enabled: false,
                  callbacks: {
                    label: tooltip => moneyFormat(pieDataBuyers[tooltip.dataIndex as number]),
                  },
                  external: externalTooltipHandler,
                },
                legend: {
                  display: false,
                },
                colors: {
                  enabled: true,
                },
                title: {
                  text: 'Por comprador',
                  display: true,
                  position: 'top',
                  align: 'center',
                  color: '#000',
                },
                datalabels: {
                  display: true,
                  color: '#fff',
                  font: {
                    size: 10,
                    weight: 400,
                  },
                  textAlign: 'right',
                  formatter(value) {
                    return `${percentFormat((value / total) * 100)}`;
                  },
                },
              },
              responsive: true,
            }}
          />
        </ChartItem>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}

      {data.length > 0 && <TopAgingBuyers data={data} />}
    </Container>
  );
};

export default TopAgingBuyersChart;
