import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { CreditLimitRequest } from 'types/creditLimitRequest';
import { useCreditLimitRequests } from 'pages/credit-limit-requests/hooks/useCreditLimitRequests';

import { makeStyles } from '@mui/styles';

type CreditLimitRequestItemModuleProps = {
  creditLimitRequest: CreditLimitRequest;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

const CreditLimitRequestItemModule: React.FC<CreditLimitRequestItemModuleProps> = ({ creditLimitRequest }) => {
  const classes = useStyles();
  const { setSelectedCreditLimitRequest } = useCreditLimitRequests();

  function handleClick() {
    setSelectedCreditLimitRequest(creditLimitRequest);
  }

  return (
    <ListItem button onClick={handleClick} className={classes.listItem}>
      <Typography variant="caption" color="primary">
        CNPJ {creditLimitRequest.document}
      </Typography>
      <Typography variant="h6">{creditLimitRequest.company_name}</Typography>
    </ListItem>
  );
};

export default CreditLimitRequestItemModule;
