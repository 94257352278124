import { api } from 'services/api';
import { useEffect, useState } from 'react';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { ManagedSale } from '../types/managedSale';
import { rentalInitialState } from '../reducer/reducer';

type UseFetchManagedSale = [ManagedSale, boolean];

export function useFetchManagedSale(id?: string): UseFetchManagedSale {
  const [loading, setLoading] = useState(true);
  const { showErrorDialog } = useErrorHandler();
  const [rental, setRental] = useState<ManagedSale>(rentalInitialState);

  useEffect(() => {
    api
      .get(`/rentals/${id}`)
      .then(response => {
        setRental(response.data);
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível recuperar a locação',
        });
      })
      .finally(() => setLoading(false));
  }, [id, showErrorDialog]);

  return [rental, loading];
}
