import { ArrowForward } from '@mui/icons-material';
import { IconButton, Tooltip, styled } from '@mui/material';
import React from 'react';

interface PurchaseOrderStepActionsProps {
  handleNext(): void;
}

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const PurchaseOrderStepActions: React.FC<PurchaseOrderStepActionsProps> = ({ handleNext }) => {
  return (
    <CustomIconButton onClick={handleNext} color="inherit" type="submit">
      <Tooltip title="Avançar">
        <ArrowForward />
      </Tooltip>
    </CustomIconButton>
  );
};

export default PurchaseOrderStepActions;
