import { Button, Typography, styled } from '@mui/material';
import React from 'react';
import { usePriceProtection } from '../hook/usePriceProtection';
import Dialog from 'components/dialogs/Dialog';

interface ConfirmStockProps {
  handleConfirmStock(): void;
  handleNotConfirmStock(): void;
}

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
});

const ButtonActions = styled('div')({
  gap: 15,
  padding: 10,
  display: 'flex',
  justifyContent: 'space-evenly',
});

const ConfirmStock: React.FC<ConfirmStockProps> = ({ handleConfirmStock, handleNotConfirmStock }) => {
  const { selectedProduct } = usePriceProtection();

  return (
    <Dialog
      onExited={handleNotConfirmStock}
      title="Confirme o estoque"
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth="sm"
      height="50vh"
    >
      <Container>
        <Typography>O estoque informado está correto?</Typography>
        <Typography fontWeight={600}>{selectedProduct?.est}</Typography>
        <ButtonActions>
          <Button variant="contained" onClick={handleConfirmStock}>
            Sim
          </Button>
          <Button variant="outlined" onClick={handleNotConfirmStock}>
            Não
          </Button>
        </ButtonActions>
      </Container>
    </Dialog>
  );
};

export default ConfirmStock;
