import React, { useEffect, useRef, useState } from 'react';
import { FormControlLabel, Grid, MenuItem, Switch, TextField, styled } from '@mui/material';
import { Customervalidation } from '../validation/customerValidation';
import ImageSelector from 'components/image-selector/ImageSelector';
import ImagePreview from 'components/image-preview/ImagePreview';
import { Customer } from 'types/customers';
import ImageUpload from 'components/image-upload/ImageUpload';
import CustomerAreaForm from './CustomerAreas';
import PhoneInput from 'components/masked-input/PhoneInput';
const Container = styled('div')(props => ({
  marginBottom: 100,
  display: 'grid',
  gridTemplateColumns: '350px 1fr',
  columnGap: '20px',
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const CustomFormControlLabel = styled(FormControlLabel)({
  marginTop: 20,
});

type CustomerFormProps = {
  customer: Customer;
  handleChange(index: keyof Customer, value: any): void;
  validation: Customervalidation;
};

const SiteBannerForm: React.FC<CustomerFormProps> = ({ customer, handleChange, validation }) => {
  const [imageSelector, setImageSelector] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<null | string>('');

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    about: useRef<HTMLTextAreaElement>(null),
    instagram_profile: useRef<HTMLInputElement>(null),
    facebook_profile: useRef<HTMLInputElement>(null),
    url: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <Container>
      {!!selectedImageSrc && (
        <ImagePreview
          src={selectedImageSrc}
          description="Imagem da mensagem de e-mail"
          onExited={() => setSelectedImageSrc('')}
        />
      )}
      {imageSelector && (
        <ImageSelector
          handleSetImageId={image => handleChange('image', image)}
          onExited={() => setImageSelector(false)}
        />
      )}
      <ImageUpload
        image={customer.image}
        handleRemoveImage={() => handleChange('image', null)}
        handleSetImage={image => handleChange('image', image)}
        validationError={validation.image}
        label="Logo do parceiro"
      />
      <Grid item xs={12} xl={5} lg={5} md={6}>
        <TextField
          inputRef={inputs.name}
          error={!!validation.name}
          helperText={validation.name}
          label="Nome"
          placeholder="Nome do cliente parceiro"
          margin="normal"
          fullWidth
          value={customer.name}
          onChange={e => handleChange('name', e.target.value)}
          autoFocus
        />

        <TextField
          inputRef={inputs.about}
          error={!!validation.about}
          helperText={validation.about}
          label="Sobre"
          placeholder="Digite um resumo do cliente"
          margin="normal"
          fullWidth
          value={customer.about}
          onChange={e => handleChange('about', e.target.value)}
          rows={5}
          multiline
        />

        <TextField
          inputRef={inputs.phone}
          error={!!validation.phone}
          helperText={validation.phone}
          label="Telefone"
          placeholder="Telefone do cliente parceiro"
          margin="normal"
          fullWidth
          value={customer.phone || ''}
          onChange={e => handleChange('phone', e.target.value)}
          InputProps={{
            inputComponent: PhoneInput as any,
          }}
        />

        <TextField
          label="WhatsApp"
          placeholder="WhatsApp do cliente parceiro"
          margin="normal"
          fullWidth
          value={customer.whatsapp || ''}
          onChange={e => handleChange('whatsapp', e.target.value)}
        />

        <TextField
          inputRef={inputs.url}
          error={!!validation.url}
          helperText={validation.url}
          label="URL"
          placeholder="URL para o parceiro"
          margin="normal"
          fullWidth
          value={customer.url}
          onChange={e => handleChange('url', e.target.value)}
        />

        <TextField
          inputRef={inputs.instagram_profile}
          error={!!validation.instagram_profile}
          helperText={validation.instagram_profile}
          label="Instagram"
          placeholder="Perfil no Instagram"
          margin="normal"
          fullWidth
          value={customer.instagram_profile || ''}
          onChange={e => handleChange('instagram_profile', e.target.value)}
        />

        <TextField
          inputRef={inputs.facebook_profile}
          error={!!validation.facebook_profile}
          helperText={validation.facebook_profile}
          label="Facebook"
          placeholder="Perfil no facebook"
          margin="normal"
          fullWidth
          value={customer.facebook_profile || ''}
          onChange={e => handleChange('facebook_profile', e.target.value)}
        />

        <TextField
          select
          label="Estrelas"
          margin="normal"
          fullWidth
          value={customer.stars}
          onChange={e => handleChange('stars', e.target.value)}
        >
          <MenuItem value={1}>1 estrela</MenuItem>
          <MenuItem value={2}>2 estrelas</MenuItem>
          <MenuItem value={3}>3 estrelas</MenuItem>
          <MenuItem value={4}>4 estrelas</MenuItem>
          <MenuItem value={5}>5 estrelas</MenuItem>
        </TextField>

        <CustomFormControlLabel
          label="Ativo"
          control={<Switch checked={customer.active} onChange={e => handleChange('active', e.target.checked)} />}
        />

        <CustomerAreaForm />

        <button type="submit" style={{ display: 'none' }} />
      </Grid>
    </Container>
  );
};

export default SiteBannerForm;
