import React from 'react';
import { Grid, TextField, MenuItem, Typography, styled } from '@mui/material';
import { User } from '../reducer/types';
import { Link } from 'react-router-dom';
import UserProfileRules from '../UserProfileRules';
import { UserProfile as UserProfileType } from 'types/userProfile';

type UserProfileProps = {
  userProfiles: UserProfileType[];
  user: User;
  handleChange(index: string, value: any): void;
};

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

const UserProfile: React.FC<UserProfileProps> = ({ user, handleChange, userProfiles }) => {
  return userProfiles.length > 0 ? (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography color="textSecondary" variant="body2">
            O perfil determina as permissões de acesso aos módulos do sistema
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4} md={6} xl={4}>
          <TextField
            fullWidth
            margin="normal"
            select
            label="Perfil"
            value={user.user_profile_id || ''}
            onChange={event => handleChange('user_profile_id', event.target.value)}
          >
            {userProfiles.map(profile => (
              <MenuItem key={profile.id} value={profile.id}>
                {profile.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <UserProfileRules userProfile={userProfiles.find(profile => profile.id === user.user_profile_id)} />
      </Grid>
    </>
  ) : (
    <Container>
      <Typography gutterBottom variant="h5" color="textSecondary">
        Não há perfis cadastrados
      </Typography>
      <Link to="/profile">Adicionar perfil</Link>
    </Container>
  );
};

export default UserProfile;
