import React from 'react';
import { Inventory } from '@mui/icons-material';
import { ListItemButton, styled, Typography } from '@mui/material';
import { Product } from 'types/product';

const ListItemStyled = styled(ListItemButton)({
  alignItems: 'center',
  gap: 10,
  justifyContent: 'flex-start',
  '& svg': {
    color: '#ccc',
  },
});

interface ProductItemProps {
  option: Product;
  selected: Product | null;
  handleSelect(reseller: Product): void;
}

const ProductItem: React.FC<ProductItemProps> = ({ option, selected, handleSelect }) => {
  return (
    <ListItemStyled disabled={selected?.codigo_produto === option.codigo_produto} onClick={() => handleSelect(option)}>
      <Inventory />
      <div>
        <Typography>{option.produto}</Typography>
      </div>
    </ListItemStyled>
  );
};

export default ProductItem;
