import React from 'react';
import { Tab, Tabs } from '@mui/material';

export type ResellerAprovalTabValue = 'company' | 'partner' | 'observation' | 'comercial' | 'otherDocuments';

type ResellerApprovalTabsProps = {
  handleChange(value: ResellerAprovalTabValue): void;
  tab: ResellerAprovalTabValue;
};

const ResellerApprovalTabs: React.FC<ResellerApprovalTabsProps> = ({ tab, handleChange }) => {
  return (
    <Tabs value={tab} onChange={(e, value) => handleChange(value)} variant="scrollable">
      <Tab label="Empresa" value="company" />
      <Tab label="Sócio" value="partner" />
      <Tab label="Comercial" value="comercial" />
      <Tab label="Observação" value="observation" />
      <Tab label="Outros documentos" value="otherDocuments" />
    </Tabs>
  );
};

export default ResellerApprovalTabs;
