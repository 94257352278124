import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Agent } from 'types/agent';

type AgentsContextValue = {
  selectedAgent: Agent | null;
  setSelectedAgent: Dispatch<SetStateAction<Agent | null>>;
  setDialogApprove: Dispatch<SetStateAction<boolean>>;
  handleRemoveFromList(agentId: number): void;
};

const AgentsContext = createContext<AgentsContextValue>({} as AgentsContextValue);
export const AgentsProvider = AgentsContext.Provider;

export function useAgents(): AgentsContextValue {
  const context = useContext(AgentsContext);
  return context;
}
