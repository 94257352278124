import React, { useState, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import { Grid, Button } from '@mui/material';
import history from 'services/history';
import { Profile } from 'types/profile';
import { api, getCancelTokenSource } from 'services/api';
import ProfileList from './ProfileList';
import ProfileActions from './ProfileActions';
import ProfilesLoading from './ProfileLoading';
import PageHeaderActions from 'components/page-header/PageHeaderActions';

const Profiles: React.FC = () => {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    api
      .get('/user-profiles', { cancelToken: source.token })
      .then(response => {
        if (request) setProfiles(response.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (request) setLoading(false);
      });

    return () => {
      if (request) request = false;
      source.cancel();
    };
  }, []);

  return (
    <>
      <Appbar title="Perfil de usuário" ActionsComponent={<ProfileActions />} />
      <PageHeaderActions
        title="Perfis"
        description="Perfis determinam acesso aos módulos do sistema"
        ActionComponent={
          <Button color="primary" variant="contained" size="small" onClick={() => history.push('/user-profile')}>
            Adicionar
          </Button>
        }
      />
      <Grid container>
        <Grid item xs={12}>
          {loading ? <ProfilesLoading /> : <ProfileList profiles={profiles} />}
        </Grid>
      </Grid>
    </>
  );
};

export default Profiles;
