import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

type AccessRuleActionsProps = {
  handleSubmit(): void;
};

const AccessRuleActions: React.FC<AccessRuleActionsProps> = ({ handleSubmit }) => {
  return (
    <>
      <Tooltip title="Salvar">
        <IconButton color="inherit" onClick={handleSubmit}>
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default AccessRuleActions;
