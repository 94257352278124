import React from 'react';
import { List, styled, Typography, ListItemButton } from '@mui/material';
import { alpha } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDialog } from 'components/dialogs/Dialog';
import { Image } from 'types/image';

const width = 150;

const CustomImg = styled('img')({
  width,
});

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const CustomList = styled(List)({
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fill, minmax(${width}px, 1fr))`,
  gap: '5px',
});

const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
  border: '2px solid transparent',
  borderRadius: 0,
  padding: 0,
  justifyContent: 'center',
  alignItems: 'center',
  '&.selected': {
    borderRadius: 0,
    border: `2px solid ${alpha(theme.palette.primary.main, 0.5)}`,
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ImageWrapper = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  position: 'absolute',
  display: 'flex',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: alpha(theme.palette.primary.main, 0.2),
}));

const CustomCheckCircleIcon = styled(CheckCircleIcon)({
  backgroundColor: '#fff',
  borderRadius: '50%',
});

const NoImages = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

type AiArtSelectorListProps = {
  images: Image[];
  handleSetSelectedImage(image: Image, handleClose: () => void): void;
  selectedImageId?: number;
};

const AiArtSelectorList: React.FC<AiArtSelectorListProps> = ({ images, handleSetSelectedImage, selectedImageId }) => {
  const { handleClose } = useDialog();

  function handleClick(image: Image) {
    handleSetSelectedImage(image, handleClose);
  }

  return (
    <>
      {images.length > 0 ? (
        <CustomList disablePadding>
          {images.map(image => (
            <CustomListItemButton
              key={image.id}
              disableGutters
              className={image.id === selectedImageId ? 'selected' : 'listItem'}
              onClick={() => handleClick(image)}
            >
              <Container>
                {image.id === selectedImageId && (
                  <ImageWrapper>
                    <CustomCheckCircleIcon color={'primary'} />
                  </ImageWrapper>
                )}
                <CustomImg
                  src={image.imageThumbUrl ? image.imageThumbUrl : image.imageUrl}
                  alt={`Imagem ${image.id}`}
                />
              </Container>
            </CustomListItemButton>
          ))}
        </CustomList>
      ) : (
        <NoImages>
          <Typography variant="h6" color="secondary">
            Sem imagens
          </Typography>
        </NoImages>
      )}
    </>
  );
};

export default AiArtSelectorList;
