import { Dispatch, SetStateAction, useState } from 'react';
import { ProjectContactFormContent } from 'types/project';
import * as yup from 'yup';

export type ProjectContactValidation = {
  name?: string;
  phone?: string;
  role?: string;
};

type UseProjectContactValidation = [
  ProjectContactValidation,
  Dispatch<SetStateAction<ProjectContactValidation>>,
  (contact: ProjectContactFormContent) => Promise<void>,
];

export function useProjectContactValidation(): UseProjectContactValidation {
  async function handleValidation(contact: ProjectContactFormContent) {
    const schema = yup.object().shape({
      role: yup.string().required('o cargo do contato é obrigatório'),
      phone: yup.string().required('o telefone do contato é obrigatório'),
      name: yup.string().required('o nome do contato é obrigatório'),
    });

    try {
      await schema.validate(contact);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<ProjectContactValidation>({});
  return [validation, setValidation, handleValidation];
}
