import React from 'react';
import MaskedInput from 'react-text-mask';

const mask = (rawValue: string): Array<RegExp | string> => {
  const value = rawValue.replace(/\D/g, '');

  if (value.length <= 11) {
    return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  }

  return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
};

interface TextMaskCustomProps {
  inputRef?: (ref: HTMLElement | null) => void;
}

const CpfCnpjInput: React.FC<TextMaskCustomProps> = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef ? inputRef(ref ? ref.inputElement : null) : null;
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
};

export default CpfCnpjInput;
