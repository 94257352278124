import { createContext, useContext } from 'react';
import { SliderBanner } from 'types/sliderBanner';

type SiteBannersContextValue = {
  selectedBanner: SliderBanner | null;
  setSelectedBanner(banner: SliderBanner | null): void;
};

const SiteBannersContext = createContext<SiteBannersContextValue>({} as SiteBannersContextValue);
export const SiteBannersProvider = SiteBannersContext.Provider;

export function useSiteBanners(): SiteBannersContextValue {
  const context = useContext(SiteBannersContext);
  return context;
}
