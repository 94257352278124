import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactElement, ReactNode } from 'react';

const useStyles = makeStyles<Theme>(theme => ({
  branch: {
    backgroundColor: '#fff',
    border: '1px solid #eee',
    display: 'grid',
    gap: 10,
    borderRadius: 16,
    [theme.breakpoints.down('sm')]: {
      gap: 0,
    },
  },
  typo: {
    borderRadius: '16px 16px 0 0',
    backgroundColor: theme.palette.primary.light,
    borderBottom: '1px solid #666',
    padding: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#fff',
  },
  div: {
    gap: 5,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: 10,
  },
}));

interface BoxChartProps {
  Icon?: ReactElement;
  title: string;
  subtitle?: string;
  children: ReactNode;
}

export const BoxChart: React.FC<BoxChartProps> = ({ children, title, subtitle, Icon }) => {
  const classes = useStyles();
  return (
    <div className={classes.branch}>
      <div className={classes.typo}>
        <div className={classes.div}>
          {Icon && Icon}
          <Typography style={{ fontWeight: 'bold' }} color="inherit" variant="body2">
            {title}
          </Typography>
          {subtitle && (
            <Typography style={{ marginLeft: '5px', marginRight: '5px' }} color="inherit">
              |
            </Typography>
          )}
          <Typography color="inherit" variant="body2">
            {subtitle}
          </Typography>
        </div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
