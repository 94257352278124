import React, { useEffect, useRef } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import { Button, TextField, styled } from '@mui/material';
import { WasteQueryParams } from './Wastes';
import { Search } from '@mui/icons-material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import SelectBranch from 'components/select-branches/SelectBranch';

const Filter = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '200px 200px 200px 200px 100px',
  gap: 15,
  flex: 1,
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 200px 200px 100px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
    '& .search': {
      gridColumn: '1 / 3',
    },
  },
  '& > button': {
    alignSelf: 'center',
  },
}));

type WastesFilterBoxProps = {
  queryParams: WasteQueryParams;
  handleQueryParamsChange(index: keyof WasteQueryParams, value: any): void;
  handleSubmit();
};

const WastesFilterBox: React.FC<WastesFilterBoxProps> = ({ queryParams, handleQueryParamsChange, handleSubmit }) => {
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, []);

  return (
    <FilterBox>
      <Filter>
        <SelectBranch
          branch_id={queryParams.id_filial}
          handleChange={branchId => handleQueryParamsChange('id_filial', branchId)}
        />
        <TextField
          className="search"
          label="Pesquisa"
          placeholder="Nome do cliente"
          value={queryParams.nome}
          onChange={e => handleQueryParamsChange('nome', e.target.value)}
          autoFocus
        />
        <KeyboardDatePicker
          label="Data inicial"
          value={queryParams.data_ini}
          onChange={date => handleQueryParamsChange('data_ini', date)}
        />
        <KeyboardDatePicker
          label="Data Final"
          value={queryParams.data_fim}
          onChange={date => handleQueryParamsChange('data_fim', date)}
        />
        <Button onClick={handleSubmit} variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default WastesFilterBox;
