import React, { useEffect, useRef } from 'react';
import { Checkbox, FormControlLabel, MenuItem, TextField } from '@mui/material';
import { FiscalCodeValidation } from './validation/fiscalCodeValidation';
import { Cfop } from 'types/cfop';

type FiscalCodeFormProps = {
  fiscalCode: Cfop;
  handleChange(index: keyof Cfop, value: any): void;
  validation: FiscalCodeValidation;
};

const FiscalCodeForm: React.FC<FiscalCodeFormProps> = ({ handleChange, validation, fiscalCode }) => {
  const inputs = {
    name: useRef<HTMLInputElement>(null),
    code: useRef<HTMLInputElement>(null),
    cst_cofins: useRef<HTMLInputElement>(null),
    cst_pis: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      <TextField
        inputRef={inputs.name}
        error={!!validation.name}
        helperText={validation.name}
        label="Nome"
        margin="normal"
        autoFocus
        fullWidth
        value={fiscalCode.name}
        onChange={e => handleChange('name', e.target.value)}
      />

      <TextField
        inputRef={inputs.code}
        error={!!validation.code}
        helperText={validation.code}
        label="CFOP"
        margin="normal"
        fullWidth
        inputMode="numeric"
        value={fiscalCode.code}
        onChange={e => handleChange('code', e.target.value)}
      />

      <TextField
        inputRef={inputs.cst_cofins}
        error={!!validation.cst_cofins}
        helperText={validation.cst_cofins}
        label="CST PIS COFINS"
        margin="normal"
        fullWidth
        value={fiscalCode.cst_cofins}
        onChange={e => handleChange('cst_cofins', e.target.value)}
      />

      <TextField
        inputRef={inputs.cst_pis}
        error={!!validation.cst_pis}
        helperText={validation.cst_pis}
        label="CST PIS"
        margin="normal"
        fullWidth
        value={fiscalCode.cst_pis}
        onChange={e => handleChange('cst_pis', e.target.value)}
      />

      <TextField
        select
        label="Tipo"
        fullWidth
        value={fiscalCode.type}
        onChange={e => handleChange('type', e.target.value)}
        margin="normal"
      >
        <MenuItem value="out">Saída</MenuItem>
        <MenuItem value="in">Entrada</MenuItem>
      </TextField>

      <FormControlLabel
        control={
          <Checkbox
            checked={fiscalCode?.has_icms}
            onChange={e => handleChange('has_icms', e.target.checked)}
            color="primary"
          />
        }
        label="Gera ICMS"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={fiscalCode?.has_pis_cofins}
            onChange={e => handleChange('has_pis_cofins', e.target.checked)}
            color="primary"
          />
        }
        label="Gera PIS COFINS"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={fiscalCode?.has_stock}
            onChange={e => handleChange('has_stock', e.target.checked)}
            color="primary"
          />
        }
        label="Movimenta estoque"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={fiscalCode?.must_reference_invoice}
            onChange={e => handleChange('must_reference_invoice', e.target.checked)}
            color="primary"
          />
        }
        label="NFE Referenciada"
      />
    </>
  );
};

export default FiscalCodeForm;
