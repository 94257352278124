import React, { DragEvent, useState } from 'react';
import AddPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Zoom, IconButton, Typography, Button, CircularProgress, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageSelector from 'components/image-selector/ImageSelector';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import { SiteSetting } from 'types/siteSetting';

const ImageContainer = styled('div')<{ error: boolean; dragIn: boolean }>(props => ({
  display: 'flex',
  width: '100%',
  height: 200,
  border: props.dragIn
    ? `3px dashed ${props.theme.palette.primary.main}`
    : `2px dashed ${props.error ? props.theme.palette.error.main : alpha(props.theme.palette.primary.main, 0.2)}`,
  borderRadius: 4,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  marginTop: 20,
  overflow: 'hidden',
  opacity: props.dragIn ? 0.5 : 1,
  [props.theme.breakpoints.down('sm')]: {
    marginBottom: 15,
  },
}));

const ImageWrapper = styled('div')(props => ({
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  display: 'flex',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: alpha(props.theme.palette.primary.main, 0.2),
  '& > svg': {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}));

const CustomImg = styled('img')({
  width: '100%',
  borderRadius: 4,
  backgroundColor: '#eee',
});

const CustomCheckCircleIcon = styled(CheckCircleIcon)({
  backgroundColor: '#fff',
  borderRadius: '50%',
});

type SiteSettingsFormImageProps = {
  handleChange(index: string, value: any): void;
  validationErrorText?: string;
  label: string;
  source?: string;
  index: keyof SiteSetting;
};

const SiteSettingsFormImage: React.FC<SiteSettingsFormImageProps> = ({
  handleChange,
  validationErrorText,
  label,
  source,
  index,
}) => {
  const [imageSelector, setImageSelector] = useState(false);
  const [selected, setSelected] = useState(false);
  const [dragIn, setDragIn] = useState(false);
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();

  function handleUploadFiles(files: FileList | null) {
    if (!files) return;

    const form = new FormData();
    Array.from(files).forEach((file, index) => {
      form.append(`files[${index}]`, file);
    });

    setSaving(true);

    api
      .post('/images', form)
      .then(response => {
        handleChange('image', response.data[0]);
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível carregar a imagem');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDropFile(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleUploadFiles(files);
    setDragIn(false);
  }

  function handleDragEnter(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  function handleDragLeave(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(false);
  }

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  return (
    <>
      {imageSelector && (
        <ImageSelector
          onExited={() => setImageSelector(false)}
          handleSetImageId={image => handleChange(index, image)}
        />
      )}
      {!source ? (
        <>
          <ImageContainer
            dragIn={dragIn}
            error={!!validationErrorText}
            onDrop={e => handleDropFile(e)}
            onDragLeave={e => handleDragLeave(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragOver={e => handleDragOver(e)}
            draggable
          >
            {saving ? (
              <CircularProgress />
            ) : (
              <IconButton size="medium" onClick={() => setImageSelector(true)}>
                <AddPhotoIcon color="primary" />
              </IconButton>
            )}
          </ImageContainer>
        </>
      ) : (
        <ImageContainer dragIn={false} error={false} onClick={() => setSelected(selected => !selected)}>
          <Zoom in={selected}>
            <ImageWrapper>
              <CustomCheckCircleIcon color="primary" />
              <Button size="small" variant="contained" color="primary" onClick={() => handleChange(index, null)}>
                Remover
              </Button>
            </ImageWrapper>
          </Zoom>
          <CustomImg alt="Foto do produto" src={source} />
        </ImageContainer>
      )}
      {validationErrorText ? (
        <Typography variant="caption" color="error">
          {validationErrorText}
        </Typography>
      ) : (
        <Typography variant="caption" color="primary" display="block">
          {label}
        </Typography>
      )}
    </>
  );
};

export default SiteSettingsFormImage;
