export function arraySort(index: string, source: Array<any>, direction: 'asc' | 'desc' = 'asc'): Array<any> {
  source = JSON.parse(JSON.stringify(source));

  if (direction === 'asc') {
    source.sort((a, b) => {
      if (a[index] < b[index]) return -1;
      if (a[index] > b[index]) return 1;
      return 0;
    });

    return source;
  }

  source.sort((a, b) => {
    if (a[index] < b[index]) return 1;
    if (a[index] > b[index]) return -1;
    return 0;
  });

  return source;
}
