import React, { useState, FormEvent, useEffect, useRef } from 'react';
import { Button, TextField, styled } from '@mui/material';
import { Product } from 'types/product';
import ProductsAutoComplete from './auto-complete/ProductsAutoComplete';
import { useApp } from 'hooks/app';
import { RelatedProductItemFormContent } from 'pages/related-product/types/relatedProductItemFormContent';
import { RelatedProductItemValidation } from './validation/relatedProductItemValidation';

const Form = styled('form')(props => ({
  padding: '15px 0',
  display: 'grid',
  maxWidth: 900,
  gridTemplateColumns: '80px 1fr 120px 120px 75px',
  flex: 0,
  gap: 10,
  alignItems: 'flex-start',
  marginBottom: 30,
  '& > button': {
    flexShrink: 0,
  },
  [props.theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
  },
}));

interface RelatedProductItemFormProps {
  handleSubmit(data: RelatedProductItemFormContent): Promise<void>;
  validation: RelatedProductItemValidation;
}

const RelatedProductItemForm: React.FC<RelatedProductItemFormProps> = ({
  handleSubmit: defaultHandleSubmit,
  validation,
}) => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [product, setProduct] = useState<Product | null>(null);
  const { h2iApi } = useApp();

  const inputs = {
    id: useRef<HTMLInputElement>(null),
    name: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    if (!product) {
      setName('');
      setId('');
      return;
    }

    setName(product.produto);
    setId(`${product.codigo_produto}`);
  }, [product]);

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    defaultHandleSubmit({
      id,
      name,
    })
      .then(() => {
        setId('');
        setName('');
        setProduct(null);
        inputs.id.current?.focus();
      })
      .catch(err => console.error(err));
  }

  function handleSelectProduct(product: Product | null) {
    setProduct(product);
  }

  async function handleSearchProduct(): Promise<void> {
    if (!h2iApi) {
      return;
    }

    if (!id) {
      return;
    }

    const response = await h2iApi.get('/api/produtoTodos', { params: { id } });
    const product = response.data[0];

    setProduct(product);
    setName(product.descricao);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        label="Código"
        placeholder="Código"
        autoFocus
        value={id}
        onChange={event => setId(event.target.value)}
        inputRef={inputs.id}
        error={!!validation.id}
        helperText={validation.id}
        onBlur={handleSearchProduct}
        fullWidth
      />

      <ProductsAutoComplete
        label="Pesquisa de produtos"
        onSelect={product => handleSelectProduct(product)}
        placeholder="Digite o nome do produto"
        selected={product}
        helperText={validation.name}
        error={!!validation.name}
      />

      <Button type="submit" size="small" variant="contained" color="primary">
        inserir
      </Button>
    </Form>
  );
};

export default RelatedProductItemForm;
