import React, { useState } from 'react';
import List from 'components/list/List';
import ProjectItemModule from './CompatibilityItemModule';
import { Compatibility } from 'types/compatibility';
import CompatibilityListMenu from '../CompatibilityListMenu';

type CompatibilityListModuleProps = {
  compatibilities: Compatibility[];
};

const CompatibilityListModule: React.FC<CompatibilityListModuleProps> = ({ compatibilities }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <CompatibilityListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <List>
        {compatibilities.map(compatibility => (
          <ProjectItemModule setAnchorEl={setAnchorEl} key={compatibility.id} compatibility={compatibility} />
        ))}
      </List>
    </>
  );
};

export default CompatibilityListModule;
