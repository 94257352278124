import { moneyFormat, numberFormat, percentFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useState, useCallback } from 'react';
import { ProductList } from 'types/product';

type UseProductFetch = [(query: string) => void, ProductList[], boolean];

export function useProductsFetch(): UseProductFetch {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<ProductList[]>([]);
  const { h2iApi } = useApp();

  const fetcher = useCallback(
    (query: string) => {
      if (!h2iApi) {
        return;
      }

      setLoading(true);

      h2iApi
        .get(`/api/produtoTodos`, { params: { ESTOQUE: 0, produto: query } })
        .then(response => {
          const p = response.data.map((item: ProductList) => {
            item.formattedCusto = moneyFormat(item.custo);
            item.formattedMC = numberFormat(item.mc);
            item.formattedPercentualMC = percentFormat(item['mc%']);
            item.formattedMargem = percentFormat(item.margem);
            item.formattedP2PJ = numberFormat(item.p2pj);
            item.formattedP2BR = numberFormat(item.p2br);
            item.formattedPromoPJ = item.promopj ? 'Sim' : 'Não';
            item.formattedDestaque = item.destaque ? 'Sim' : 'Não';
            item.formattedPmc = numberFormat(item.pmc);
            item.formattedPmd = numberFormat(item.pmd);
            item.formattedPp = numberFormat(item.pp);
            return item;
          });

          setProducts(p);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [h2iApi],
  );

  return [fetcher, products, loading];
}
