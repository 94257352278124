import { Typography, styled } from '@mui/material';
import React from 'react';
import { moneyFormat } from 'helpers/numberFormat';
import { useFinancialResume } from '../hooks/useFinancialResume';

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  transition: 'all 0.2s ease',
  columnGap: 10,
  rowGap: 10,
  marginBottom: 10,
  flex: 1,
  alignItems: 'flex-start',
  gridAutoRows: 'min-content',
  marginTop: 10,
  [theme.breakpoints.down('md')]: {
    marginBottom: 0,
    gridTemplateColumns: '1fr',
  },
  '& .item': {
    minWidth: 225,
    flex: 1,
    backgroundColor: '#fff',
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    gap: 15,
    boxShadow: '2px 4px 10px 5px #ccc',
    '&.expense': {
      // backgroundColor: theme.palette.error.main,
    },
    '&.personnel': {
      // backgroundColor: theme.palette.primary.main,
    },
    '&.invoice': {
      // backgroundColor: theme.palette.warning.main,
      gridColumn: '1/3',
      [theme.breakpoints.down('sm')]: {
        gridColumn: '1',
        minWidth: 100,
      },
    },
    '& .value': {
      fontSize: 24,
      textAlign: 'end',
      paddingRight: 5,
      padding: 15,
    },
    '& .description': {
      fontWeight: 600,
      padding: '10px 5px',
      textAlign: 'end',
      '&.expense': {
        backgroundColor: theme.palette.error.dark,
      },
      '&.personnel': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&.invoice': {
        backgroundColor: theme.palette.warning.dark,
      },
    },
  },
}));

const FinancialResumeTotals: React.FC = () => {
  const { totals } = useFinancialResume();

  const formattedExpense = moneyFormat(totals.expense);
  const formattedPersonnel = moneyFormat(totals.personnel);
  const formattedInvoice = moneyFormat(totals.invoice);

  return (
    <Container>
      <div className="item expense">
        <Typography className="value" fontSize={12} variant="subtitle2">
          {formattedExpense}
        </Typography>
        <Typography className="description expense" color="#fff" variant="caption">
          Despesa (R$)
        </Typography>
      </div>

      <div className="item personnel">
        <Typography className="value" fontSize={12} variant="subtitle2">
          {formattedPersonnel}
        </Typography>
        <Typography className="description personnel" color="#fff" variant="caption">
          Folha de Pagamento (R$)
        </Typography>
      </div>

      <div className="item invoice">
        <Typography className="value" fontSize={12} variant="subtitle2">
          {formattedInvoice}
        </Typography>
        <Typography className="description invoice" color="#fff" variant="caption">
          Título (R$)
        </Typography>
      </div>
    </Container>
  );
};

export default FinancialResumeTotals;
