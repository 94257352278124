import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import ModalityItemModule from './ModalityItemModule';
import { SalesMethod } from 'types/salesReport';

type ModalityListModuleProps = {
  modalities: SalesMethod[];
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

const ModalityListModule: React.FC<ModalityListModuleProps> = ({ modalities }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {modalities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(modality => (
        <ModalityItemModule key={modality.modalidade} modality={modality} />
      ))}
    </CustomList>
  );
};

export default ModalityListModule;
