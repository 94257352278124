import React, { useState } from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import { Button, IconButton, Typography, styled } from '@mui/material';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import SalesReportCustomDate from './SalesReportCustomDate';

const Buttons = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: 15,
  '& button': {
    marginBottom: 10,
  },
});

type SalesReportDateFilterProps = {
  loading: boolean;
  dateConfig: number | null;
  handleDateConfig(days: number): void;
  onExited(): void;
  handleConfirmCustomDates(initialDate: Date, finalDate: Date): void;
};

const SalesReportDateFilter: React.FC<SalesReportDateFilterProps> = ({
  loading,
  dateConfig,
  handleDateConfig,
  onExited,
  handleConfirmCustomDates,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(days: number, handleClose: () => void) {
    handleDateConfig(days);
    handleClose();
  }

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <SalesReportCustomDate
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        handleConfirmCustomDates={handleConfirmCustomDates}
      />
      <DialogInputConsumer>
        {({ handleClose }) => (
          <Buttons>
            <Typography align="center">Escolha o período do relatório de vendas</Typography>
            <Button
              disabled={loading}
              variant="text"
              size="medium"
              color={dateConfig === 0 ? 'primary' : 'inherit'}
              onClick={() => handleClick(0, handleClose)}
            >
              Hoje
            </Button>
            <Button
              disabled={loading}
              variant="text"
              size="medium"
              color={dateConfig === 15 ? 'primary' : 'inherit'}
              onClick={() => handleClick(15, handleClose)}
            >
              15 dias
            </Button>
            <Button
              disabled={loading}
              variant="text"
              size="medium"
              color={dateConfig === 30 ? 'primary' : 'inherit'}
              onClick={() => handleClick(30, handleClose)}
            >
              30 dias
            </Button>
            <Button
              disabled={loading}
              variant="text"
              size="medium"
              color={dateConfig === 60 ? 'primary' : 'inherit'}
              onClick={() => handleClick(60, handleClose)}
            >
              60 dias
            </Button>
            <IconButton
              disabled={loading}
              color={dateConfig === null ? 'primary' : 'inherit'}
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              <CalendarIcon />
            </IconButton>
          </Buttons>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default SalesReportDateFilter;
