import { cpfValidator } from 'helpers/cpfValidator';
import { Dispatch, SetStateAction, useState } from 'react';
import { Agent } from 'types/agent';
import * as yup from 'yup';

export type AgentApprovalValidation = {
  cpf?: string;
  rg?: string;
  nome?: string;
  telefone?: string;
  cep?: string;
  endereco?: string;
  numero_endereco?: string;
  cidade?: string;
};

type UseAgentApprovalValidation = [
  AgentApprovalValidation,
  Dispatch<SetStateAction<AgentApprovalValidation>>,
  (agent: Agent) => Promise<void>,
];

export function useAgentApprovalValidation(): UseAgentApprovalValidation {
  async function handleValidation(agent: Agent) {
    const schema = yup.object().shape({
      telefone: yup
        .string()
        .required('O telefone é obrigatório')
        .test('phoneValidation', 'telefone inválido', value => {
          if (!value) return false;

          const raw = value.replace(/\D/g, '');
          if (raw.length < 10) return false;

          return true;
        }),
      cidade: yup.string().required('A cidade é obrigatória'),
      numero_endereco: yup.string().required('O número do endereço é obrigatório'),
      endereco: yup.string().required('O endereço é obrigatório'),
      cep: yup.string().required('O CEP é obrigatório'),
      rg: yup.string().required('RG é obrigatório'),
      cpf: yup
        .string()
        .required('CPF é obrigatório')
        .transform((value, originalValue) => {
          return originalValue ? originalValue.replace(/\D/g, '') : '';
        })
        .test('docValidation', `CPF inválido`, value => {
          if (!value) return false;

          const cpf = cpfValidator(value);

          return cpf;
        }),
      nome: yup.string().min(10, 'O nome deve ter no mínimo 10 caracteres').required('nome é obrigatório'),
    });

    try {
      await schema.validate(agent);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [validation, setValidation] = useState<AgentApprovalValidation>({});
  return [validation, setValidation, handleValidation];
}
