import { Button, Typography, styled } from '@mui/material';
import React from 'react';

const Container = styled('div')({
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 20,
});

interface ApiErrorProps {
  handleReset(): void | Promise<void>;
  errorMessage: string;
}

const ApiError: React.FC<ApiErrorProps> = ({ handleReset, errorMessage }) => {
  return (
    <Container>
      <Typography>{errorMessage}</Typography>
      <Button variant="contained" color="primary" onClick={handleReset}>
        Tentar novamente
      </Button>
    </Container>
  );
};

export default ApiError;
