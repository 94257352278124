import { createContext, useContext } from 'react';
import { SalesFilter } from '../SalesReport';

type SalesContextValue = {
  handleChangeFilter(index: keyof SalesFilter, value: any): void;
  filter: SalesFilter;
  searchText: string;
};

const SalesContext = createContext<SalesContextValue>({} as SalesContextValue);
export const SalesProvider = SalesContext.Provider;

export function useSales(): SalesContextValue {
  const context = useContext(SalesContext);
  return context;
}
