import { TableTemplate } from 'types/tableTemplate';

export const salesItemsTableTemplate: TableTemplate[] = [
  {
    id: 'codigo',
    description: 'Cód',
    originalId: 'codigo',
    width: 70,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 240,
  },
  {
    id: 'segmento',
    description: 'Segmento',
    originalId: 'segmento',
    width: 200,
  },
  {
    id: 'unidade',
    description: 'Unidade',
    originalId: 'unidade',
    width: 200,
  },
  {
    id: 'qtd',
    description: 'QTD',
    originalId: 'qtd',
    width: 80,
  },
  {
    id: 'qtd_vendas',
    description: 'Qtd vendas',
    originalId: 'qtd_vendas',
    width: 140,
  },
  {
    id: 'formattedCusto',
    description: 'Custo',
    originalId: 'custo',
    width: 110,
  },
  {
    id: 'formattedUnitario',
    description: 'Unitário',
    originalId: 'unitario',
    width: 130,
  },
  {
    id: 'formattedTotal',
    description: 'Total',
    originalId: 'total',
    width: 110,
    dataType: 'number',
  },
  {
    id: 'mc',
    description: 'MC',
    originalId: 'mc',
    width: 70,
    dataType: 'number',
  },
  {
    id: 'formattedSpiff',
    description: 'Spiff',
    originalId: 'spiff',
    dataType: 'number',
    width: 70,
  },
];
