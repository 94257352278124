import React from 'react';
import ProductItem from './ProductItemModule';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { ProjectProduct } from 'types/project';

type ProductListModuleProps = {
  products: ProjectProduct[];
};

const ProductListModule: React.FC<ProductListModuleProps> = ({ products }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {products.map(product => (
            <ProductItem key={product.product_id} product={product} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ProductListModule;
