import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import CustomerItemModule from './CustomerItemModule';
import { H2iCustomer } from 'types/h2iCustomer';

type CustomerListModuleProps = {
  customers: H2iCustomer[];
};

const CustomerListModule: React.FC<CustomerListModuleProps> = ({ customers }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(customer => (
            <CustomerItemModule key={customer.codigo} customer={customer} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default CustomerListModule;
