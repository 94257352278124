import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import BuyerActions from '../BuyerActions';
import { BuyerProvider } from '../../hooks/useBuyer';
import InsideSaving from 'components/loading/InsideSaving';
import { useFetchEditBuyer } from './useFetchEditBuyer';
import BuyerForm from '../BuyerForm';

interface EditBuyerProps {
  onExited(): void;
  onSave(): void;
}

const EditBuyer: React.FC<EditBuyerProps> = ({ onExited, onSave }) => {
  const [handleChange, handleValidation, buyer, validation, saving] = useFetchEditBuyer(onSave);

  return (
    <BuyerProvider
      value={{
        handleChange,
        handleValidation,
        buyer,
        registerType: 'edit',
      }}
    >
      <Dialog
        onExited={onExited}
        maxWidth="sm"
        title="Comprador"
        ComponentActions={<BuyerActions handleValidation={handleValidation} />}
      >
        <>
          {saving && <InsideSaving />}

          <BuyerForm
            buyer={buyer}
            handleChange={handleChange}
            handleValidation={handleValidation}
            validation={validation}
          />
        </>
      </Dialog>
    </BuyerProvider>
  );
};

export default EditBuyer;
