import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import TransferAccountItemModule from './TransferAccountItemModule';
import { TransferAccount } from 'types/transferAccount';

type TransferAccountListModuleProps = {
  accounts: TransferAccount[];
};

const TransferAccountListModule: React.FC<TransferAccountListModuleProps> = ({ accounts }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <List>
      {accounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(account => (
        <TransferAccountItemModule key={account.id} account={account} />
      ))}
    </List>
  );
};

export default TransferAccountListModule;
