import { styled } from '@mui/material';
import NoData from 'components/no-data/NoData';
import { useProject } from 'pages/projects/registration/hook/useProject';
import React, { useEffect, useState, useMemo } from 'react';
import { ProjectContact, ProjectContactFormContent } from 'types/project';
import { ContactsContextProvider } from './hooks/useProjectContacts';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import ContactListTable from './list/table/ContactListTable';
import ContactListModule from './list/module/ContactListModule';
import ProjectContactForm from './ProjectContactForm';
import { addProjectContact } from 'pages/projects/registration/reducer/action';
import { useProjectContactValidation } from './validation/projectContactValidation';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const ProjectContacts: React.FC = () => {
  const { isMobile, windowWidth } = useApp();
  const { project, dispatch } = useProject();
  const [filteredContacts, setFilteredContacts] = useState<ProjectContact[]>([]);
  const [selectedContact, setSelectedContact] = useState<ProjectContact | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const displayMode = useMemo(() => (isMobile || windowWidth < 930 ? 'module' : 'list'), [isMobile, windowWidth]);
  const [validation, setValidation, validate] = useProjectContactValidation();

  useEffect(() => {
    setFilteredContacts(project.contacts);
  }, [project.contacts]);

  function handleSort(index: string) {
    const _contacts = sort(index, filteredContacts);
    setFilteredContacts(_contacts);
  }

  async function handleValidation(data: ProjectContactFormContent) {
    setValidation({});

    await validate(data);
    handleSubmit(data);
  }

  function handleSubmit(data: ProjectContactFormContent) {
    dispatch(addProjectContact(data));
  }

  return (
    <Container>
      <ProjectContactForm validation={validation} handleSubmit={handleValidation} />

      {filteredContacts.length === 0 ? (
        <NoData message="Nenhum produto para mostrar." />
      ) : (
        <ContactsContextProvider value={{ selectedContact, setSelectedContact }}>
          {displayMode === 'list' ? (
            <ContactListTable contacts={filteredContacts} handleSort={handleSort} orderedIndex={orderedIndex} />
          ) : (
            <ContactListModule contacts={filteredContacts} />
          )}
        </ContactsContextProvider>
      )}
    </Container>
  );
};

export default ProjectContacts;
