import React, { MouseEvent } from 'react';
import { KeyboardArrowDown, MoreHoriz } from '@mui/icons-material';
import { AbcProductManufacturer } from 'types/abcProduct';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography, styled } from '@mui/material';
import { useAbcManufacturers } from '../../../hooks/useAbcManufacturers';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

type ManufacturerItemTableProps = {
  manufacturer: AbcProductManufacturer;
  isSubgroup: boolean;
};

const ManufacturerItemTable: React.FC<ManufacturerItemTableProps> = ({ manufacturer, isSubgroup }) => {
  const { tableTemplate } = useTable();
  const { setMoreManufacturer, moreManufacturer } = useAbcManufacturers();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setMoreManufacturer(moreManufacturer ? null : manufacturer);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <CustomIconButton key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : item.id === 'more' && isSubgroup ? (
            <CustomIconButton key={item.id} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={
                  moreManufacturer?.fabricante === manufacturer.fabricante ? { transform: 'rotate(180deg)' } : undefined
                }
              />
            </CustomIconButton>
          ) : (
            <NumericData key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
              <Typography style={{ fontSize: 11 }} variant="body2">
                {manufacturer[item.id as keyof AbcProductManufacturer]}
              </Typography>
            </NumericData>
          ),
        )}
    </>
  );
};

export default ManufacturerItemTable;
