import React, { FormEvent, useEffect, useState, ReactNode } from 'react';
import { Project, ProjectStatusOptions } from 'types/project';
import { useMessaging } from 'hooks/messaging';
import { H2iCustomer } from 'types/h2iCustomer';
import ProjectTab, { ProjectTabValues } from '../tab/ProjectTab';
import { useProjectValidation } from '../../validation/projectValidation';
import { api } from 'services/api';
import InsideSaving from 'components/loading/InsideSaving';
import ProjectMain from '../tab/content/ProjectMain';
import ProjectStatus from '../tab/content/ProjectStatus';
import ProjectShipment from '../tab/content/ProjectShipment';
import ProjectDocuments from '../tab/content/ProjectDocuments';
import Appbar from 'components/appbar/Appbar';
import ProjectNewAppbarButtons from './ProjectNewAppbarButtons';
import { styled } from '@mui/material';
import ProjectObservation from '../tab/content/ProjectObservation';
import { projectStatusMapping, useProjectReducer } from '../reducer/reducer';
import { ProjectProvider } from '../hook/useProject';
import { projectChange } from '../reducer/action';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import ProjectProducts from '../tab/content/products/ProjectProducts';
import ProjectContacts from '../tab/content/contacts/ProjectContacts';
import history from 'services/history';
import ProjectCosts from '../tab/content/costs/ProjectCosts';
import ProjectActions from '../tab/content/actions/ProjectActions';
import { TAB_CONTENT_WITH_FULL_WIDTH } from 'pages/projects/constants/tabContentWithFullWidth';

type ContentProps = {
  maxWidth: string | number;
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Content = styled('div')<ContentProps>(props => ({
  display: 'flex',
  borderTop: '1px solid #eee',
  flexDirection: 'column',
  flex: 1,
  maxWidth: props.maxWidth,
  marginTop: 20,
  marginBottom: 200,
}));

const ProjectNew: React.FC = () => {
  const messaging = useMessaging();
  const [project, dispatch] = useProjectReducer();
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState<ProjectTabValues>('info');
  const [reseller, setReseller] = useState<H2iCustomer | null>(null);
  const [validation, setValidation, validate] = useProjectValidation();
  const [isProductSearchOpen, setIsProductSearchOpen] = useState(false);

  useEffect(() => {
    const main = ['name', 'value', 'description', 'document_number'];

    const [key] = Object.keys(validation);

    if (!key) {
      return;
    }

    if (main.includes(key)) {
      setTab('info');
      return;
    }

    setTab('shipment');
  }, [validation]);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});

    if (!project) {
      return;
    }

    validate(project)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.error(err);
      });
  }

  async function handleUpdateStatus(status: ProjectStatusOptions) {
    if (!project) {
      return;
    }

    await api
      .post(`/projects/${project?.id}/status`, { status })
      .then(() => {
        const formattedCurrentStatus = projectStatusMapping[project.current_status];
        dispatch(projectChange('formattedCurrentStatus', formattedCurrentStatus));

        messaging.handleOpen('Status atualizado com sucesso');
      })
      .catch(err => messaging.handleOpen(err.response?.data.error || 'Não foi possível alterar o status'));
  }

  async function handleCancelStatus() {
    if (!project) {
      return;
    }

    await api
      .post(`/projects/${project.id}/cancel`)
      .then(() => {
        const formattedCurrentStatus = projectStatusMapping[project.current_status];
        dispatch(projectChange('formattedCurrentStatus', formattedCurrentStatus));

        messaging.handleOpen('Status alterado para cancelado');
      })
      .catch(err => messaging.handleOpen(err.response?.data.error || 'Não foi possível alterar o status'));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/projects', project)
      .then(() => {
        messaging.handleOpen('Projeto criado com sucesso!');
        history.push('/projects');
      })
      .catch(err => {
        console.error(err);
        setSaving(false);
      });
  }

  function handleChange(index: keyof Project, value: any) {
    dispatch(projectChange(index, value));
  }

  function handleTabsRendering(): ReactNode {
    const tabContents: Record<ProjectTabValues, ReactNode> = {
      info: (
        <ProjectMain
          setReseller={setReseller}
          reseller={reseller}
          validation={validation}
          handleCancelStatus={handleCancelStatus}
          handleUpdateStatus={handleUpdateStatus}
        />
      ),
      products: <ProjectProducts />,
      status: <ProjectStatus handleCancelStatus={handleCancelStatus} handleUpdateStatus={handleUpdateStatus} />,
      shipment: <ProjectShipment validation={validation} />,
      documents: <ProjectDocuments />,
      costs: <ProjectCosts />,
      contacts: <ProjectContacts />,
      observation: <ProjectObservation />,
      actions: <ProjectActions />,
    };

    return tabContents[tab];
  }

  return (
    <ProjectProvider value={{ project, dispatch, handleChange, isProductSearchOpen, setIsProductSearchOpen }}>
      <Appbar
        title="Novo projeto"
        Tab={<ProjectTab tab={tab} handleChange={tab => setTab(tab)} />}
        ActionsComponent={<ProjectNewAppbarButtons handleValidation={handleValidation} />}
      />

      {saving && <InsideSaving />}

      <Container>
        <PageHeaderActions
          backUrl="/projects"
          title="Novo projeto"
          description="Cadastro para projeto de energia solar"
        />

        <Content maxWidth={TAB_CONTENT_WITH_FULL_WIDTH.includes(tab) ? '100%' : 600}>{handleTabsRendering()}</Content>
      </Container>
    </ProjectProvider>
  );
};

export default ProjectNew;
