import React, { FormEvent, useEffect, useRef, useState } from 'react';
import Dialog, { DialogConsumer } from 'components/dialogs/Dialog';
import InsideSaving from 'components/loading/InsideSaving';
import { Agent, AgentAddress } from 'types/agent';
import { useAccountAgentValidation } from '../validation/useAccountAgentValidation';
import AccountAgentForm from '../AccountAgentForm';
import AccountAgentFormAddress from '../AccountAgentFormAddress';
import { useAgentAddressValidation } from '../validation/useAgentAddressValidation';
import AccountAgentDocumentForm from '../AccountAgentDocumentUpload';
import { useAgentDocumentValidation } from '../validation/useAgentDocumentValidation';
import AccountAgentDownloadLetter from '../AccountAgentDownloadLetter';
import AccountAgentLetterUpload from '../AccountAgentLetterUpload';
import { useAgentLetterValidation } from '../validation/useAgentLetterValidation';
import { Document } from 'types/document';
import { AgentProvider } from '../../hooks/useAgent';
import AccountAgentFormActive from '../AccountAgentFormActive';
import { Typography, Button, Theme } from '@mui/material';
import { useApp } from 'hooks/app';
import { Branch } from 'types/branch';
import { H2iReseller } from 'types/h2iReseller';
import ResellerSelect from '../agent-reseller-select/ResellerSelect';
import { makeStyles } from '@mui/styles';

const styles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '40px 0',
    flexDirection: 'column',
  },
  actions: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      marginBottom: 30,
    },
    '& a': {
      alignSelf: 'center',
    },
  },
  form: {
    maxWidth: 400,
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  header: {
    textAlign: 'center',
    marginBottom: 20,
  },
}));

const INITIAL_STATE: Agent = {
  cpf: '',
  id_cliente: 0,
  id_filial: 0,
  nome: '',
  permite_alterar: 'S',
  rg: '',
  situacao: 'em análise',
  aprovado: true,
  documentos: [],
  bairro: '',
  cep: '',
  cidade: '',
  endereco: '',
  email: '',
  uf: '',
  numero_endereco: '',
  telefone: '',
  carta_validada: 'N',
  documento_validado: 'N',
  documento_enviado: 'N',
  carta_enviada: 'N',
  profissao: '',
  rg_emissor: '',
  retirada: 'S',
  rma: 'S',
  compra: 'S',
  ativo: 'S',
  id: 0,
  cnpj_revenda: '',
  data_hora: '',
  revenda: '',
  formattedDocumentSent: 'Sim',
  formattedLetterSent: 'Sim',
  socio: 'N',
};

interface AccountAgentNewProps {
  onExited(): void;
}

const AccountAgentNew: React.FC<AccountAgentNewProps> = ({ onExited }) => {
  const classes = styles();
  const [agent, setAgent] = useState(INITIAL_STATE);
  const [validation, setValidation, validate] = useAccountAgentValidation();
  const [addressValidation, setAddressValidation, validateAddress] = useAgentAddressValidation();
  const [documentValidation, setDocumentValidation, validateDocument] = useAgentDocumentValidation();
  const [letterValidation, setLetterValidation, validateLetter] = useAgentLetterValidation();
  const [saving, setSaving] = useState(false);
  const [step, setStep] = useState(1);
  const [document, setDocument] = useState<Document | null>(null);
  const [letter, setLetter] = useState<Document | null>(null);
  const { h2iApi } = useApp();
  const [reseller, setReseller] = useState<H2iReseller | null>(null);
  const [branch, setBranch] = useState<Branch | null>(null);

  const inputs = {
    nome: useRef<HTMLInputElement>(null),
    rg: useRef<HTMLInputElement>(null),
    cpf: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    if (!inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleChange(index: keyof Agent, value: any) {
    if (!agent) return;

    setAgent({
      ...agent,
      [index]: value,
    });
  }

  function handleValidationStepOne() {
    handleNext();
  }

  function handleValidationStepTwo() {
    setValidation({});
    validate(agent)
      .then(handleNext)
      .catch(err => console.error(err));
  }

  function handleValidationStepThree() {
    setAddressValidation({});
    validateAddress(agent)
      .then(handleNext)
      .catch(err => console.error(err));
  }

  function handleValidationStepFour() {
    handleSubmit();
  }

  function handleValidationStepFive() {
    setDocumentValidation({});
    validateDocument(document)
      .then(handleSubmitDocument)
      .catch(err => console.error(err));
  }

  function handleValidationStepSix() {
    handleNext();
  }

  function handleValidationStepSeven(handleDialogClose: () => void) {
    setLetterValidation({});
    validateLetter(letter)
      .then(agent.carta_validada === 'S' ? () => setStep(6) : () => handleSubmitLetter(handleDialogClose))
      .catch(err => console.error(err));
  }

  function handleValidation(handleDialogClose: () => void, e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    const validations = {
      1: handleValidationStepOne,
      2: handleValidationStepTwo,
      3: handleValidationStepThree,
      4: handleValidationStepFour,
      5: handleValidationStepFive,
      6: handleValidationStepSix,
      7: () => handleValidationStepSeven(handleDialogClose),
    };

    validations[step as keyof typeof validations]();
  }

  function handleSubmit() {
    if (!h2iApi) return;

    const data = {
      ...agent,
      cnpj_cpf_cadastro: reseller?.cnpj_cpf,
      id_filial: branch?.id,
      codigo_cliente: reseller?.codigo,
    };

    setSaving(true);

    h2iApi
      .post('/api/savepreposto', data)
      .then(handleNext)
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleSubmitDocument() {
    if (!h2iApi) return;

    if (!agent) return;

    const data = {
      cpf_preposto: agent.cpf,
      link: document?.document_url,
      tipo: 'D',
      id_cliente: reseller?.codigo,
    };

    setSaving(true);

    h2iApi
      .post('/api/saveprepostodocumento', data)
      .then(handleNext)
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleSubmitLetter(handleDialogClose: () => void) {
    if (!h2iApi) return;

    if (!agent) return;

    const data = {
      cpf_preposto: agent.cpf,
      link: letter?.document_url,
      tipo: 'C',
      id_cliente: reseller?.codigo,
    };

    setSaving(true);

    h2iApi
      .post('/api/saveprepostodocumento', data)
      .then(handleDialogClose)
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleSetAddress(address: AgentAddress) {
    setAgent(agent => ({
      ...agent,
      ...address,
    }));
  }

  function handleNext() {
    if (step === 7) return;
    setStep(step => step + 1);
  }

  function handleBack() {
    if (step === 1) return;
    setStep(step => step - 1);
  }

  function handleGetButtonText() {
    const texts = {
      1: 'continuar',
      2: 'continuar',
      3: 'continuar',
      4: 'salvar e continuar',
      5: 'continuar',
      6: 'confirmar',
      7: 'confirmar',
    };

    return texts[step as keyof typeof texts];
  }

  function handleGetSubtitles() {
    const titles = {
      1: 'revendedor',
      2: 'identificação',
      3: 'endereço',
      4: 'opções para o preposto',
      5: 'documento',
      6: 'carta de preposição',
      7: 'upload carta preposição assinada',
    };

    return titles[step as keyof typeof titles];
  }

  function handleResellerSelect(reseller: H2iReseller) {
    setReseller(reseller);
    handleNext();
  }

  return (
    <Dialog onExited={onExited} title="adicionar preposto" disableEscapeKeyDown disableBackdropClick maxWidth="sm">
      {saving && <InsideSaving />}
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography>{handleGetSubtitles()}</Typography>
          <Typography variant="body2" color="textSecondary">{`passo ${step} / 7`}</Typography>
        </div>
        <DialogConsumer>
          {dialog => (
            <AgentProvider value={{ agent, reseller, setReseller, branch, setBranch }}>
              <form onSubmit={e => handleValidation(dialog.handleClose, e)} className={classes.form}>
                {step === 1 ? (
                  <ResellerSelect onSelect={reseller => handleResellerSelect(reseller)} />
                ) : step === 2 ? (
                  <AccountAgentForm agent={agent} handleChange={handleChange} validation={validation} />
                ) : step === 3 ? (
                  <AccountAgentFormAddress
                    agent={agent}
                    validation={addressValidation}
                    handleChange={handleChange}
                    handleSetAddress={handleSetAddress}
                  />
                ) : step === 4 ? (
                  <AccountAgentFormActive handleChange={handleChange} />
                ) : step === 5 ? (
                  <AccountAgentDocumentForm
                    setDocument={setDocument}
                    document={document}
                    validation={documentValidation.document}
                  />
                ) : step === 6 ? (
                  <AccountAgentDownloadLetter />
                ) : (
                  step === 7 && (
                    <AccountAgentLetterUpload
                      setLetter={setLetter}
                      letter={letter}
                      validation={letterValidation.letter}
                    />
                  )
                )}

                <div className={classes.actions}>
                  <Button disabled={saving || !reseller} type="submit" variant="contained" color="primary">
                    {handleGetButtonText()}
                  </Button>
                  {step > 1 && (
                    <Button type="button" variant="text" onClick={handleBack}>
                      voltar
                    </Button>
                  )}
                </div>
              </form>
            </AgentProvider>
          )}
        </DialogConsumer>
      </div>
    </Dialog>
  );
};

export default AccountAgentNew;
