import React, { useEffect } from 'react';
import { Typography, Grid, styled } from '@mui/material';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { Product, ProductList } from 'types/product';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import WasteProductItemTable from './WasteProductItemTable';
import { wasteProductTableTemplate } from '../wasteProductTableTemplate';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type WasteProductListTableProps = {
  products: ProductList[];
  handleSubmit(product: Product): void;
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  selectedProduct: ProductList | null;
  handleSelectProduct(product: ProductList | null): void;
};

const WasteProductListTable: React.FC<WasteProductListTableProps> = ({
  handleSelectProduct,
  selectedProduct,
  products,
  handleSort,
  orderedIndex,
  handleSubmit,
}) => {
  const { rowsPerPage, page } = usePagination();

  function handleClick(product: ProductList) {
    if (selectedProduct?.codigo_produto === product.codigo_produto) {
      handleSelectProduct(null);
    } else {
      handleSelectProduct(product);
    }
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (selectedProduct) {
          handleSubmit(selectedProduct);
        } else {
          handleSelectProduct(products[0]);
        }
      }
    };

    // Adiciona o event listener quando o componente é montado
    document.addEventListener('keydown', handleKeyDown);

    // Remove o event listener quando o componente é desmontado
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSelectProduct, products, selectedProduct, handleSubmit]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        if (selectedProduct) {
          const index = products.findIndex(item => item.codigo_produto === selectedProduct.codigo_produto);
          const findIndex = index === 0 ? products.length - 1 : index - 1;
          const findProduct = products[findIndex];

          handleSelectProduct(findProduct);
        } else {
          handleSelectProduct(products[0]);
        }
      }
    };

    // Adiciona o event listener quando o componente é montado
    document.addEventListener('keydown', handleKeyDown);

    // Remove o event listener quando o componente é desmontado
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSelectProduct, products, selectedProduct]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        if (selectedProduct) {
          const index = products.findIndex(item => item.codigo_produto === selectedProduct.codigo_produto) + 1;
          const findIndex = index === products.length ? 0 : index;
          const findProduct = products[findIndex];

          handleSelectProduct(findProduct);
        } else {
          handleSelectProduct(products[0]);
        }
      }
    };

    // Adiciona o event listener quando o componente é montado
    document.addEventListener('keydown', handleKeyDown);

    // Remove o event listener quando o componente é desmontado
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSelectProduct, products, selectedProduct]);

  return (
    <Grid container>
      <TableContainer tableTemplate={wasteProductTableTemplate}>
        <TableContent>
          <TableHeader>
            {wasteProductTableTemplate.map(item => (
              <HeaderItem
                className={item.dataType === 'number' ? 'numericData' : ''}
                key={item.id}
                onClick={() => handleSort(item.originalId)}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </HeaderItem>
            ))}
          </TableHeader>
          <TableBody>
            {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => (
              <TableRow
                style={{
                  opacity:
                    selectedProduct?.codigo_produto === product.codigo_produto
                      ? 1
                      : parseFloat(product.est) <= 0
                      ? 0.5
                      : 1,
                  backgroundColor: selectedProduct?.codigo_produto === product.codigo_produto ? '#30519f' : '#fff',
                }}
                onClickCapture={e => handleClick(product)}
                key={product.codigo_produto}
              >
                <WasteProductItemTable selectedProduct={selectedProduct} product={product} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </Grid>
  );
};

export default WasteProductListTable;
