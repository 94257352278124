import { styled } from '@mui/material';
import NoData from 'components/no-data/NoData';
import React, { useEffect, useState, useMemo } from 'react';
import { ProjectProductFormContent } from 'types/project';
import { ProductsContextProvider } from './hooks/useProducts';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import ProductListTable from './list/table/ProductListTable';
import ProductListModule from './list/module/ProductListModule';
import ProjectProductForm from './CompatibilityProductForm';
import { useCompatibilityProductValidation } from './validation/compatiblityProductValidation';
import { useCompatibility } from '../../hook/useCompatibility';
import { CompatibilityProduct } from 'pages/compatibilities/types/compatibilityProduct';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const CompatibilityProducts: React.FC = () => {
  const { isMobile, windowWidth } = useApp();
  const { compatibility, setCompatibility } = useCompatibility();
  const [filteredProducts, setFilteredProducts] = useState<CompatibilityProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<CompatibilityProduct | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const displayMode = useMemo(() => (isMobile || windowWidth < 930 ? 'module' : 'list'), [isMobile, windowWidth]);
  const [validation, setValidation, validate] = useCompatibilityProductValidation();

  useEffect(() => {
    setFilteredProducts(compatibility.products);
  }, [compatibility.products]);

  function handleSort(index: string) {
    const p = sort(index, filteredProducts);
    setFilteredProducts(p);
  }

  async function handleValidation(data: ProjectProductFormContent) {
    setValidation({});

    await validate(data, compatibility.products);
    handleSubmit(data);
  }

  function handleSubmit(data: ProjectProductFormContent) {
    setCompatibility(state => ({
      ...state,
      products: [...state.products, data],
    }));
  }

  return (
    <Container>
      <ProjectProductForm validation={validation} handleSubmit={handleValidation} />

      {filteredProducts.length === 0 ? (
        <NoData message="Nenhum produto para mostrar." />
      ) : (
        <ProductsContextProvider value={{ selectedProduct, setSelectedProduct }}>
          {displayMode === 'list' ? (
            <ProductListTable products={filteredProducts} handleSort={handleSort} orderedIndex={orderedIndex} />
          ) : (
            <ProductListModule products={filteredProducts} />
          )}
        </ProductsContextProvider>
      )}
    </Container>
  );
};

export default CompatibilityProducts;
