import React, { useState, useEffect, FormEvent } from 'react';
import PageHeader from 'components/page-header/PageHeader';
import { BillSetting as BillSettingType } from 'types/billSetting';
import BillSettingForm from './BillSettingForm';
import InsideLoading from 'components/loading/InsideLoading';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import BillSettingActions from './BillSettingActions';
import { useMessaging } from 'hooks/messaging';
import { useBillSettingValidation } from './validation/useBillSettingValidation';
import BillTab, { BillTabValues } from './tab/BillTab';
import BillDescriptionForm from './BillDescriptionForm';
import BillSettingActionsDelete from './BillSettingActionsDelete';
import BillSettingConfirmDelete from './BillSettingConfirmDelete';
import { styled } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const Form = styled('form')({
  margin: '20px 0 50px',
  maxWidth: 500,
});

interface BillSettingProps {
  onExited(): void;
  onSave(): void;
  id: number;
}

const BillSetting: React.FC<BillSettingProps> = ({ id, onExited, onSave }) => {
  const [dialogConfirmDelete, setDialogConfirmDelete] = useState(false);
  const [tab, setTab] = useState<BillTabValues>('description');
  const [settings, setSettings] = useState<BillSettingType>({} as BillSettingType);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();
  const [validation, setValidation, validate] = useBillSettingValidation();

  useEffect(() => {
    api
      .get(`/bank-billet-settings/${id}`)
      .then(response => {
        setSettings(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [id]);

  function handleChange(index: keyof BillSettingType, value: any) {
    setSettings(settings => ({
      ...settings,
      [index]: value,
    }));
  }

  function handleConfirmDelete() {
    setDialogConfirmDelete(true);
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete(`/bank-billet-settings/${settings.id}`)
      .then(() => {
        messaging.handleOpen('Salvo');
        onSave();
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(settings)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/bank-billet-settings/${settings.id}`, settings)
      .then(() => {
        messaging.handleOpen('Salvo');
        onSave();
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  return (
    <Dialog
      title="Financeiro"
      onExited={onExited}
      Tab={<BillTab tab={tab} handleChange={tab => setTab(tab)} />}
      ComponentActions={
        <>
          <BillSettingActionsDelete handleDelete={handleConfirmDelete} />
          <BillSettingActions handleSubmit={handleValidation} />
        </>
      }
    >
      <Container>
        {saving && <Loading />}
        <PageHeader
          title="Configurações de cobrança"
          description="Configurações para emisssão de boleto e remessa de cobrança"
        />
        {dialogConfirmDelete && (
          <BillSettingConfirmDelete
            id={settings.dispatch_id}
            handleDelete={handleDelete}
            onExited={() => setDialogConfirmDelete(false)}
          />
        )}
        {loading ? (
          <InsideLoading />
        ) : (
          <Form onSubmit={handleValidation}>
            {tab === 'description' ? (
              <BillDescriptionForm settings={settings} handleChange={handleChange} validation={validation} />
            ) : (
              <BillSettingForm settings={settings} handleChange={handleChange} validation={validation} />
            )}
          </Form>
        )}
      </Container>
    </Dialog>
  );
};

export default BillSetting;
