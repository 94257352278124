import { TableTemplate } from 'types/tableTemplate';

export const groupedSegmentTableTemplate: TableTemplate[] = [
  {
    id: 'segmento',
    description: 'Segmento',
    originalId: 'segmento',
    width: 280,
  },
  {
    id: 'qtd',
    description: 'Qtd',
    originalId: 'qtd',
    width: 70,
    dataType: 'number',
  },
  {
    id: 'qtd_vendas',
    description: 'Qtd Vendas',
    originalId: 'qtd_vendas',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'formattedPercentual',
    description: '%',
    originalId: 'percentual',
    width: 60,
    dataType: 'number',
  },
  {
    id: 'formattedCusto',
    description: 'Custo',
    originalId: 'custo',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'formattedMc',
    description: 'MC',
    originalId: 'mc',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'formattedSpiff',
    description: 'Spiff',
    originalId: 'spiff',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'formattedTotal',
    description: 'Total',
    originalId: 'total',
    width: 120,
    dataType: 'number',
  },
];
