import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import history from 'services/history';
import { Cfop } from 'types/cfop';

type FiscalCodeItemModuleProps = {
  fiscalCode: Cfop;
};

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
});

const FiscalCodeItemModule: React.FC<FiscalCodeItemModuleProps> = ({ fiscalCode }) => {
  function handleClick(id?: number) {
    history.push(`/cfop/${id}`);
  }

  return (
    <CustomListItemButton onClick={() => handleClick(fiscalCode.id)}>
      <Typography variant="h6">{fiscalCode.name}</Typography>
      <Typography variant="body1">CST PIS - {fiscalCode.cst_pis}</Typography>
      <Typography variant="body1">CST COFINS - {fiscalCode.cst_cofins}</Typography>
      <Typography variant="body1">Tipo - {fiscalCode.type}</Typography>
      <Typography variant="caption" color="textSecondary">
        CFOP - {fiscalCode.code}
      </Typography>
    </CustomListItemButton>
  );
};

export default FiscalCodeItemModule;
