import React, { MouseEvent } from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import { productTableTemplate } from '../productTableTemplate';
import { MoreHoriz } from '@mui/icons-material';
import { useProducts } from '../../hooks/useProducts';
import { WasteProduct } from 'pages/material-residues/waste/types/waste';

const IconButtonStyled = styled(IconButton)({
  justifySelf: 'flex-start',
  padding: 5,
});

type ProductItemTableProps = {
  product: WasteProduct;
};

const ProductItemTable: React.FC<ProductItemTableProps> = ({ product }) => {
  const { setSelectedProduct, setAnchorEl } = useProducts();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedProduct(product);
  }

  return (
    <>
      {productTableTemplate.map(item =>
        item.id === 'actions' ? (
          <IconButtonStyled onClick={event => handleClick(event)} size="small" key={item.id}>
            <MoreHoriz key={item.id} />
          </IconButtonStyled>
        ) : (
          <div key={item.id}>
            <Typography variant="body2">{product[item.id]}</Typography>
          </div>
        ),
      )}
    </>
  );
};

export default ProductItemTable;
