import { Typography } from '@mui/material';
import React from 'react';
import { CreditLimitRequest } from 'types/creditLimitRequest';
import DocumentHistory from './DocumentHistoryItem';
import { DocumentHistoryIndexes } from './DocumentHistories';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  list: {
    width: '100%',
    '& > li, .header': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '15px',
    },
  },
});

interface DocumentHistoryListProps {
  creditLimitRequests: CreditLimitRequest[];
  index: DocumentHistoryIndexes;
}

const DocumentHistoryList: React.FC<DocumentHistoryListProps> = ({ creditLimitRequests, index }) => {
  const classes = styles();

  return (
    <ul className={classes.list}>
      <div className="header">
        <Typography variant="subtitle2">Arquivo</Typography>
        <Typography variant="subtitle2">Data</Typography>
      </div>
      {creditLimitRequests.map(creditLimitRequest => (
        <DocumentHistory creditLimitRequest={creditLimitRequest} index={index} key={creditLimitRequest.id} />
      ))}
    </ul>
  );
};

export default DocumentHistoryList;
