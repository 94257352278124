import { CourseStudent } from '../../types/courseStudent';
import { CourseStudentActions } from './types';
import { useReducer, Dispatch } from 'react';

export const rentalInitialState: CourseStudent = {
  id: '',
  student_document_number: '',
  student_name: '',
  created_at: '',
  course_id: 0,
  course: {} as any,
  is_approved: false,
  is_paid: false,
  payment_code: '',
  payment_type: 'credit_card',
};

export function courseStudentReducer(state: CourseStudent, action: CourseStudentActions): CourseStudent {
  switch (action.type) {
    case 'set_course_student': {
      return action.courseStudent;
    }

    case 'change_course_student': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    default: {
      return state;
    }
  }
}

type UseCourseStudentReducer = [CourseStudent, Dispatch<CourseStudentActions>];

export function useCourseStudentReducer(): UseCourseStudentReducer {
  const [rental, dispatch] = useReducer(courseStudentReducer, rentalInitialState);

  return [rental, dispatch];
}
