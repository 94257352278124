import React, { useMemo } from 'react';
import { externalTooltipHandler } from 'components/chart/externalTooltip';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { Pie } from 'react-chartjs-2';
import { GroupedSegment } from './table/GroupedSegmentListTable';
import { SalesMethod } from 'types/salesReport';

interface SegmentChartProps {
  segments: GroupedSegment[];
  salesMethods: SalesMethod[];
}

const SegmentChart: React.FC<SegmentChartProps> = ({ segments, salesMethods }) => {
  const pieData = useMemo(() => {
    return segments.sort((a, b) => b.total - a.total).map(item => item.total);
  }, [segments]);

  return (
    <div style={{ height: 560, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
      <Pie
        data={{
          labels: segments
            .sort((a, b) => b.total - a.total)
            .map(branch => [branch.segmento])
            .slice(0, 7),
          datasets: [
            {
              label: 'Total',
              data: segments
                .sort((a, b) => b.total - a.total)
                .map(branch => branch.total)
                .slice(0, 7),
              borderWidth: 0,
            },
          ],
        }}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
              callbacks: {
                label: tooltip => moneyFormat(pieData[tooltip.dataIndex as number]),
              },
              external: externalTooltipHandler,
            },
            legend: {
              display: true,
              position: 'top',
            },
            colors: {
              enabled: true,
            },
            title: {
              text: 'Segmentos mais atendidos',
              display: true,
              position: 'top',
              align: 'center',
            },
            datalabels: {
              display: true,
              color: '#fff',
              font: {
                size: 14,
                weight: 600,
              },
              textAlign: 'left',
              formatter(value, context) {
                return percentFormat((value / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100);
              },
            },
          },
          responsive: true,
        }}
      />
    </div>
  );
};

export default SegmentChart;
