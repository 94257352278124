import React, { useState } from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import OrderItemModule from './OrderItemModule';
import { usePagination } from 'hooks/pagination';
import { OrderAuthorization } from 'types/orderAuthorization';
import OrdersAuthorizationListMenu from '../OrdersAuthorizationListMenu';

type OrderListModuleProps = {
  orders: OrderAuthorization[];
};

const OrderListModule: React.FC<OrderListModuleProps> = ({ orders }) => {
  const { rowsPerPage, page } = usePagination();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <Grid container>
      <OrdersAuthorizationListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <Grid item xs={12}>
        <List>
          {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(order => (
            <OrderItemModule setAnchorEl={setAnchorEl} key={order.id} order={order} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default OrderListModule;
