import React, { FormEvent, useEffect, useRef, useState } from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import { Button, InputAdornment, TextField, Typography, styled } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as yup from 'yup';
import { useAuth } from 'hooks/auth';
import { useSelector } from 'store/selector';

const Container = styled('form')({
  flex: 1,
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const Actions = styled('div')({
  display: 'flex',
  gap: 10,
  maxWidth: 300,
  alignItems: 'end',
  flex: 1,
  margin: '0 auto',
});

interface ConfirmPasswordProps {
  onExited(): void;
  onValidate(): void;
}

const ConfirmPassword: React.FC<ConfirmPasswordProps> = ({ onExited, onValidate }) => {
  const auth = useAuth();
  const user = useSelector(state => state.user);
  const [password, setPassword] = useState('');
  const [passVisibility, setPassVisibility] = useState(true);
  const ref = useRef<HTMLInputElement>(null);
  const [validation, setValidation] = useState('');

  function handlePasswordVisibility() {
    setPassVisibility(!passVisibility);
  }

  useEffect(() => {
    ref.current?.focus();
  }, [validation]); // eslint-disable-line

  async function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    if (!user) return;

    setValidation('');

    const schema = await yup.object().shape({
      password: yup
        .string()
        .required('a senha é obrigatória')
        .test('confirmPassword', 'a senha está incorreta', value => {
          if (!value) return false;

          return auth
            .checkPassword(user.id, password)
            .then(() => {
              return true;
            })
            .catch(() => {
              return false;
            });
        }),
    });

    schema
      .validate({ password })
      .then(onValidate)
      .catch((err: yup.ValidationError) => {
        if (err instanceof yup.ValidationError) {
          setValidation(err.message);
        }
      });
  }

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <DialogInputConsumer>
        {({ handleClose }) => (
          <Container onSubmit={handleValidation}>
            <Typography variant="subtitle2">Para continuar, confirme sua senha</Typography>

            <TextField
              name="password"
              autoComplete="off"
              error={!!validation}
              helperText={validation}
              autoFocus
              inputRef={ref}
              margin="normal"
              label="Digite sua senha"
              placeholder="Senha"
              type={passVisibility ? 'password' : 'text'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {passVisibility ? (
                      <Visibility style={{ cursor: 'pointer' }} onClick={handlePasswordVisibility} color="primary" />
                    ) : (
                      <VisibilityOff style={{ cursor: 'pointer' }} onClick={handlePasswordVisibility} color="primary" />
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <Actions>
              <Button variant="outlined" onClick={handleClose}>
                Cancelar
              </Button>

              <Button type="submit" variant="contained">
                Confirmar
              </Button>
            </Actions>
          </Container>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default ConfirmPassword;
