import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material';
import { useProduct } from '../../Product';
import ProductGroupList from './ProductGroupList';
import ProductSubGroupList from './ProductSubGroupList';
import { useApp } from 'hooks/app';

import { makeStyles } from '@mui/styles';
import ManufacturerAutoComplete from '../../auto-complete/ManufacturerAutoComplete';

const useStyles = makeStyles({
  checkBox: {
    marginTop: 15,
  },
});

const ProductBasic: React.FC = () => {
  const { handleChange, validation, product, groups, subGroups } = useProduct();
  const classes = useStyles();
  const [dialogGroup, setDialogGroup] = useState(false);
  const [dialogSubGroup, setDialogSubGroup] = useState(false);
  const { isMobile, windowWidth } = useApp();

  const inputs = {
    descricao: useRef<HTMLInputElement>(null),
    codigo_barra: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <>
      {dialogGroup && (
        <ProductGroupList
          onExited={() => setDialogGroup(false)}
          onSelect={group => handleChange('codigo_grupo', group.CODIGO_GRUPO)}
          groups={groups}
        />
      )}
      {dialogSubGroup && (
        <ProductSubGroupList
          onExited={() => setDialogSubGroup(false)}
          onSelect={subgroup => handleChange('codigo_subgrupo', subgroup.CODIGO_SUBGRUPO)}
          subGroups={subGroups}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={6} xl={2} lg={3} md={4}>
          <TextField label="ID" margin="normal" fullWidth autoFocus value={product.codigo_produto} disabled />
        </Grid>
        <Grid item xs={6} xl={2} lg={2} md={4}>
          <TextField
            label="EAN"
            placeholder="Código EAN do produto"
            margin="normal"
            value={product.codigo_barra}
            onChange={e => handleChange('codigo_barra', e.target.value)}
            autoFocus
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={4} lg={5} md={6}>
          <TextField
            multiline={isMobile && windowWidth < 960}
            rows={5}
            inputRef={inputs.descricao}
            error={!!validation.descricao}
            helperText={validation.descricao}
            label="Descrição"
            placeholder="Digite a descrição do produto"
            margin="normal"
            autoFocus
            fullWidth
            value={product.descricao}
            onChange={e => handleChange('descricao', e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} xl={3} lg={3} md={4}>
        <ManufacturerAutoComplete />
      </Grid>
      <Grid item xs={12} xl={3} lg={3} md={4}>
        <TextField select label="Grupo" fullWidth value={product.codigo_grupo} margin="normal">
          {groups.map(group => (
            <MenuItem key={group.CODIGO_GRUPO} value={group.CODIGO_GRUPO}>
              {group.NOME}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} xl={3} lg={3} md={4}>
        <TextField label="Categoria" fullWidth value={product.categoria} margin="normal" />
      </Grid>
      <Grid item xs={12} xl={3} lg={3} md={4}>
        <TextField select label="Subgrupo" fullWidth value={product.codigo_subgrupo} margin="normal">
          {subGroups.map(subgroup => (
            <MenuItem key={subgroup.CODIGO_SUBGRUPO} value={subgroup.CODIGO_SUBGRUPO}>
              {subgroup.NOME}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} xl={2} lg={2} md={4}>
        <TextField
          label="Limitador de quantidade"
          placeholder="Digite o valor do limitador"
          fullWidth
          onChange={e => handleChange('limitador_de_quantidade', e.target.value)}
          value={product.limitador_de_quantidade}
          margin="normal"
        />
      </Grid>
      <Grid container>
        <Grid item xs={2} xl={2} lg={2} md={2}>
          <FormControlLabel
            className={classes.checkBox}
            control={
              <Checkbox
                checked={product.destaque}
                onChange={e => handleChange('destaque', e.target.checked)}
                color="primary"
              />
            }
            label="Destaque"
          />
        </Grid>
        <Grid item xs={2} xl={2} lg={2} md={2}>
          <FormControlLabel
            className={classes.checkBox}
            control={
              <Checkbox
                checked={product.lixo_eletronico === 'S'}
                onChange={e => handleChange('lixo_eletronico', e.target.checked ? 'S' : 'N')}
                color="primary"
              />
            }
            label="Lixo eletrônico"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProductBasic;
