import React, { MouseEvent, useState } from 'react';
import { Button, ListItem, Typography, IconButton, Menu, MenuItem, Theme } from '@mui/material';
import { Order, SaleReport } from './SalesReport';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArroWBackIcon from '@mui/icons-material/ArrowBack';
import MoreIcon from '@mui/icons-material/MoreVert';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import Loading from 'components/loading/Loading';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: 7,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  order: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    border: '1px solid #eee',
    padding: 15,
    position: 'relative',
    backgroundColor: '#fff',
    '& > .data': {
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
    },
  },
  payment: {
    marginBottom: 10,
  },
  action: {
    display: 'flex',
    marginBottom: 20,
  },
  badgePaid: {
    display: 'flex',
    padding: '5px 10px',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    right: 0,
    backgroundColor: theme.palette.success.main,
    fontSize: 10,
    color: '#fff',
    borderRadius: 0,
    minWidth: 70,
  },
  badgeNoPaid: {
    display: 'flex',
    padding: '5px 10px',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    right: 0,
    backgroundColor: theme.palette.error.main,
    fontSize: 10,
    color: '#fff',
    borderRadius: 0,
    minWidth: 70,
  },
  more: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'end',
    '& button': {
      marginLeft: 15,
    },
  },
}));

type SalesReportBranchProps = {
  selectedBranch: SaleReport;
  setSelectedBranch(branch: SaleReport | null): void;
  setSelectedOrder(order: Order): void;
};

const SalesReportBranch: React.FC<SalesReportBranchProps> = ({
  selectedBranch,
  setSelectedBranch,
  setSelectedOrder,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [localOrder, setSelectedLocalOrder] = useState<Order | null>(null);
  const messaging = useMessaging();
  const [loading, setLoading] = useState(false);

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>, order: Order) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedLocalOrder(order);
  }

  function handleMenuItemClick() {
    setSelectedLocalOrder(null);
    setAnchorEl(null);
    window.open(`https://api.whatsapp.com/send?phone=${localOrder?.whatsapp}`, '_blank');
  }

  function handleSendInvoiceReminder() {
    const data = {
      customer_name: localOrder?.cliente,
      order_id: '#' + localOrder?.id,
      invoice_value: localOrder?.formattedValorTotal,
      email: localOrder?.email,
    };

    setLoading(true);

    api
      .post('/invoices/send', data)
      .then(() => {
        messaging.handleOpen('Lembrete de pagamento enviado');
      })
      .finally(() => setLoading(false));
    setAnchorEl(null);
  }

  function handleSendManyInvoiceReminder() {
    const orders: Array<{
      customer_name: string;
      order_id: string;
      email: string;
      invoice_value: string;
    }> = [];

    selectedBranch.orders
      .filter(order => order.pago === 'Não')
      .forEach(order =>
        orders.push({
          customer_name: order.cliente,
          order_id: '#' + order.id,
          invoice_value: order.formattedValorTotal,
          email: order.email,
        }),
      );

    setLoading(true);

    api
      .post('/invoices/send-many', { orders })
      .then(() => {
        messaging.handleOpen('Lembrete de pagamento enviado');
      })
      .finally(() => setLoading(false));
    setAnchorEl(null);

    console.log(orders);
  }

  return (
    <>
      {loading && <Loading />}
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleMenuItemClick}>WhatsApp</MenuItem>
        {localOrder && localOrder.pago === 'Não' && (
          <MenuItem onClick={handleSendInvoiceReminder}>Enviar lembrete de pagamento</MenuItem>
        )}
      </Menu>
      <div className={classes.action}>
        <Button startIcon={<ArroWBackIcon />} onClick={() => setSelectedBranch(null)}>
          Voltar
        </Button>
      </div>
      <div className={classes.header}>
        <Typography gutterBottom variant="h6">
          {selectedBranch.filial}
        </Typography>
        <Button variant="contained" size="small" color="primary" onClick={handleSendManyInvoiceReminder}>
          Enviar lembrete de pagamento
        </Button>
      </div>
      <div className={classes.container}>
        {selectedBranch.orders.map(order => (
          <ListItem button className={classes.order} key={order.id} onClick={() => setSelectedOrder(order)}>
            {order.pago === 'Sim' && <div className={classes.badgePaid}>Pago</div>}
            {order.pago === 'Não' && <div className={classes.badgeNoPaid}>Não Pago</div>}
            <div className="data">
              <Typography variant="caption" color="primary" gutterBottom>
                {order.id} - {order.data} {order.hora}
              </Typography>
              <Typography gutterBottom>{order.cliente}</Typography>
              <Typography color="textSecondary">{order.Cnpj}</Typography>
              <Typography color="textSecondary">{order.email}</Typography>
              {order.whatsapp !== '55' && <Typography color="textSecondary">{order.formattedWhatsApp}</Typography>}
              <div className={classes.payment}>
                {order.pagamento.map(pag => (
                  <Typography key={pag.id} color="textSecondary">
                    {pag.forma}
                  </Typography>
                ))}
              </div>
              <Typography variant="h6">{order.formattedValorTotal}</Typography>
            </div>
            <ChevronRightIcon color="primary" />
            <IconButton className={classes.more} onClick={e => handleMoreClick(e, order)}>
              <MoreIcon />
            </IconButton>
          </ListItem>
        ))}
      </div>
    </>
  );
};

export default SalesReportBranch;
