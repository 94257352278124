import { User } from 'types/user';
import { UserActionsType, SET_USER } from './types';

export const INITIAL_STATE: User | null = null;

export default function userReducer(state = INITIAL_STATE, action: UserActionsType): User | null {
  switch (action.type) {
    case SET_USER: {
      if (!action.user) {
        return null;
      }

      return {
        ...action.user,
        branchList: action.user.branches.split(',').map(id => id),
      };
    }

    default: {
      return state;
    }
  }
}
