import React from 'react';
import ProductItem from './ProductItemModule';
import List from 'components/list/List';
import { RentalProduct } from 'pages/rentals/types/rental';

type ProductListModuleProps = {
  products: RentalProduct[];
};

const ProductListModule: React.FC<ProductListModuleProps> = ({ products }) => {
  return (
    <List>
      {products.map(product => (
        <ProductItem key={product.external_id} product={product} />
      ))}
    </List>
  );
};

export default ProductListModule;
