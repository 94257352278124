import React from 'react';
import { moneyFormat } from 'helpers/numberFormat';
import { Typography, styled } from '@mui/material';
import { AbcUnityTotalProps } from '../hooks/useAbcUnits';

const Total = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  columnGap: 7,
  marginBottom: 5,
  '& > div': {
    background: '#fff',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #eee',
    justifyContent: 'space-between',
    marginBottom: 5,
    '& > div > span': {
      fontWeight: 500,
      fontSize: 22,
      color: '#159f06',
    },
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

interface ReportAbcUnityTotalProps {
  formattedTotal: AbcUnityTotalProps;
}

const AbcUnityTotal: React.FC<ReportAbcUnityTotalProps> = ({ formattedTotal }) => {
  return (
    <Total>
      <div>
        <div>
          <Typography variant="body2">Valor total</Typography>
          <span>{moneyFormat(formattedTotal.total)}</span>
        </div>
      </div>

      <div>
        <div>
          <Typography variant="body2">Valor custo</Typography>
          <span>{moneyFormat(formattedTotal.coust)}</span>
        </div>
      </div>

      <div>
        <div>
          <Typography>MC</Typography>
          <span>{moneyFormat(formattedTotal.mc)}</span>
        </div>
      </div>

      <div>
        <div>
          <Typography>QTD Itens vendidos</Typography>
          <span>{formattedTotal.quantity}</span>
        </div>
      </div>
    </Total>
  );
};

export default AbcUnityTotal;
