import React from 'react';
import { Typography, styled } from '@mui/material';
import { SalesReportTotal } from './SalesReport';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { CityMethod, SalesMethod } from 'types/salesReport';

const Total = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  columnGap: 7,
  marginBottom: 5,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  '&.bottom': {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  '& > .total': {
    [theme.breakpoints.down('md')]: {
      gridColumn: '1/3',
    },
  },
  '& > div': {
    background: '#fff',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #eee',
    justifyContent: 'space-between',
    marginBottom: 5,
    '& > div > span': {
      fontWeight: 500,
      fontSize: 22,
      textAlign: 'right',
      color: '#159f06',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
    '& span, p': {
      fontSize: 12,
    },
  },
}));

interface SalesReportTotalProps {
  formattedTotal: SalesReportTotal;
  salesMethods: SalesMethod[];
  citiesMethods: CityMethod[];
}

const SalesReportTotalPage: React.FC<SalesReportTotalProps> = ({ formattedTotal, salesMethods, citiesMethods }) => {
  return (
    <>
      <Total>
        <div className="total">
          <div>
            <Typography>Valor total</Typography>
            <span>{formattedTotal.total}</span>
          </div>
        </div>

        <div>
          <div>
            <Typography variant="body2">Quantidade de vendas</Typography>
            <span>{salesMethods.reduce((sum, item) => sum + item.qtd_vendas, 0)}</span>
          </div>
        </div>

        <div>
          <div>
            <Typography variant="body2">Ticket médio</Typography>
            <span>
              {numberFormat(
                salesMethods.reduce((sum, item) => sum + item.valor, 0) /
                  salesMethods.reduce((sum, item) => sum + item.qtd_vendas, 0),
              )}
            </span>
          </div>
        </div>

        <div>
          <div>
            <Typography>Total PJ</Typography>
            <span>{formattedTotal.pj}</span>
          </div>
        </div>

        <div>
          <div>
            <Typography>Total PF</Typography>
            <span>{formattedTotal.pf}</span>
          </div>
        </div>
      </Total>

      <Total className="bottom">
        <div>
          <div>
            <Typography>MC</Typography>
            <span>{formattedTotal.mc}</span>
          </div>
        </div>
        <div>
          <div>
            <Typography variant="body2">Balcão - Cliente na Loja</Typography>
            <span style={{ color: 'orange' }}>
              {salesMethods.length > 0 &&
              salesMethods.filter(sale => sale.modalidade === 'Balcão - Cliente na Loja').length > 0
                ? salesMethods.filter(sale => sale.modalidade === 'Balcão - Cliente na Loja')[0].formattedValue
                : 'R$ 0,00'}
            </span>
          </div>
          <div>
            <Typography variant="body2">Qtd</Typography>
            <span style={{ color: 'orange' }}>
              {salesMethods.filter(sale => sale.modalidade === 'Balcão - Cliente na Loja').length > 0
                ? salesMethods.filter(sale => sale.modalidade === 'Balcão - Cliente na Loja')[0].qtd_vendas
                : 0}
            </span>
          </div>
        </div>

        <div>
          <div>
            <Typography variant="body2">Demais modalidades</Typography>
            <span style={{ color: 'orange' }}>
              {moneyFormat(
                salesMethods
                  .filter(sale => sale.modalidade !== 'Balcão - Cliente na Loja')
                  .reduce((sum, item) => sum + item.valor, 0),
              )}
            </span>
          </div>
          <div>
            <Typography variant="body2">Qtd</Typography>
            <span style={{ color: 'orange' }}>
              {salesMethods.filter(sale => sale.modalidade !== 'Balcão - Cliente na Loja').length > 0
                ? salesMethods
                    .filter(sale => sale.modalidade !== 'Balcão - Cliente na Loja')
                    .reduce((sum, item) => sum + item.qtd_vendas, 0)
                : 0}
            </span>
          </div>
        </div>

        <div>
          <div>
            <Typography variant="body2">Quantidade de cidades atendidas</Typography>
            <span style={{ color: '#30519f' }}>{citiesMethods.length}</span>
          </div>
        </div>
      </Total>
    </>
  );
};

export default SalesReportTotalPage;
