import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import UnityItemModule from './UnityItemModule';
import { AbcProductUnity } from 'types/abcProduct';

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  columnGap: 100,
  flex: 1,
});

type UnityListModuleProps = {
  units: AbcProductUnity[];
};

const UnityListModule: React.FC<UnityListModuleProps> = ({ units }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {units.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(unity => (
        <UnityItemModule key={unity.cod} unity={unity} />
      ))}
    </CustomList>
  );
};

export default UnityListModule;
