import React from 'react';
import { TextField, Typography } from '@mui/material';
import { useReseller } from '../hooks/useReseller';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    // padding: 20,
  },
  rejectionReasonContainer: {
    marginBottom: 15,
  },
});

interface ResellerRejectionReasonProps {
  reason?: string | null;
}

const ResellerRejectionReason: React.FC<ResellerRejectionReasonProps> = ({ reason }) => {
  const classes = useStyles();
  const { handleChange, reseller } = useReseller();

  return (
    <div className={classes.container}>
      {reason && (
        <div className={classes.rejectionReasonContainer}>
          <Typography color="textSecondary" variant="caption">
            Motivo da rejeição
          </Typography>
          <Typography>{reason}</Typography>
        </div>
      )}

      <TextField
        label="Observação"
        fullWidth
        multiline
        rows={5}
        value={reseller?.observation || ''}
        onChange={e => handleChange('observation', e.target.value)}
        autoFocus
      />
    </div>
  );
};

export default ResellerRejectionReason;
