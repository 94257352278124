import React from 'react';
import DialogInput from 'components/dialogs/DialogInput';
import { Button } from '@mui/material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Search } from '@mui/icons-material';
import FilterMoreForm from 'components/filter-more/FilterMoreForm';
import InsideLoading from 'components/loading/InsideLoading';
import { AbcProductFilterParams } from './AbcProduct';
import { useFetchMoreFilters } from 'components/filter-more/hooks/useFetchMoreFilters';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  item: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    lineClamp: 1,
    WebkitBoxOrient: 'vertical',
    width: 350,
  },
  formControl: {
    minWidth: 120,
    maxWidth: 400,
  },
  container: {
    height: 500,
    display: 'grid',
    gap: 20,
    flex: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

type AbcProductDateFilterProps = {
  onExited(): void;
  onSearch(): void;
  filter: AbcProductFilterParams;
  handleChangeFilter(index: keyof AbcProductFilterParams, value: any): void;
};

const AbcProductDateFilter: React.FC<AbcProductDateFilterProps> = ({
  onExited,
  onSearch,
  filter,
  handleChangeFilter,
}) => {
  const classes = useStyles();
  const { units, segments, subCategories, categories, subGroups, manufacturers, loading } = useFetchMoreFilters();

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <div className={classes.container}>
        {loading ? (
          <InsideLoading />
        ) : (
          <div className={classes.form}>
            <FilterMoreForm
              manufacturers={manufacturers}
              subCategories={subCategories}
              subGroups={subGroups}
              categories={categories}
              segments={segments}
              filter={filter}
              handleChangeFilter={handleChangeFilter}
              units={units}
            />

            <KeyboardDatePicker
              label="Data inicial"
              value={filter.initialDate}
              onChange={date => handleChangeFilter('initialDate', date)}
            />

            <KeyboardDatePicker
              label="Data Final"
              value={filter.finalDate}
              onChange={date => handleChangeFilter('finalDate', date)}
            />

            <div>
              <Button
                endIcon={<Search fontSize="small" />}
                type="submit"
                color="primary"
                variant="contained"
                onClick={onSearch}
                fullWidth
              >
                Pesquisar
              </Button>
            </div>
          </div>
        )}
      </div>
    </DialogInput>
  );
};

export default AbcProductDateFilter;
