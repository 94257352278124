import React from 'react';
import { useFinancialResume } from '../hooks/useFinancialResume';
import { Typography, styled } from '@mui/material';

const Layout = styled('div')(props => ({
  gridColumn: '2/3',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: 20,
  boxShadow: '2px 4px 10px 5px #ccc',
  backgroundColor: '#fff',
  [props.theme.breakpoints.down('sm')]: {
    padding: 10,
  },
}));

const Container = styled('div')({
  display: 'grid',
  rowGap: 5,
});

const Row = styled('div')({
  display: 'grid',
  gridTemplateColumns: '100px 1fr 1fr',
  gap: 10,
  borderTop: '1px solid #888',
  padding: '5px 20px',
  '& > .align-right': {
    textAlign: 'end',
  },
  '& > .align-left': {
    textAlign: 'start',
  },
  '& p': {
    fontSize: 11,
  },
});

const TypeContent = styled('div')({
  display: 'grid',
  '& > .title': {
    display: 'grid',
    gridTemplateColumns: '100px 1fr 1fr',
    padding: 10,
  },
  border: '1px solid #888',
});

const GroupedResumeReport: React.FC = () => {
  const { groupedTitles } = useFinancialResume();

  return (
    <Layout>
      <Container>
        <TypeContent>
          <div className="title">
            <Typography variant="body2">
              <strong>Grupo</strong>
            </Typography>
            <Typography align="left" variant="body2">
              <strong>Tipo</strong>
            </Typography>
            <Typography variant="body2" align="right">
              <strong>Valores</strong>
            </Typography>
          </div>
          {groupedTitles.map((title, index) => (
            <div key={index} className="items">
              <Row>
                <p>{title.grupo}</p>
                <p className="align-left">{title.formattedType}</p>
                <p className="align-right">{title.formattedValue}</p>
              </Row>
            </div>
          ))}
        </TypeContent>
      </Container>
    </Layout>
  );
};

export default GroupedResumeReport;
