import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { CourseStudentOnList } from '../types/courseStudent';

export interface CourseStudentsContextValue {
  selectedCourseStudent: CourseStudentOnList | null;
  setSelectedCourseStudent: Dispatch<SetStateAction<CourseStudentOnList | null>>;
}

const Context = createContext<CourseStudentsContextValue>({} as CourseStudentsContextValue);
export const CourseStudentProvider = Context.Provider;

export function useCourseStudents(): CourseStudentsContextValue {
  return useContext(Context);
}
