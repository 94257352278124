import React, { ReactNode } from 'react';
import { styled } from '@mui/material';

const Container = styled('div')(props => ({
  backgroundColor: '#fff',
  borderRadius: '8px 8px 0 0',
  padding: 15,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 15,
  marginBottom: 10,
}));

interface FilterBoxProps {
  children: ReactNode;
  style?: React.CSSProperties;
}

const FilterBox: React.FC<FilterBoxProps> = ({ children, style }) => {
  return <Container style={style}>{children}</Container>;
};

export default FilterBox;
