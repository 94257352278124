import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';

import { Email } from 'types/email';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type ExportEmailItemTableProps = {
  email: Email;
};

const ExportEmailItemTable: React.FC<ExportEmailItemTableProps> = ({ email }) => {
  const { tableTemplate } = useTable();
  const classes = styles();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <>
              <div key={item.id}>
                <Typography variant="body2">{email[item.id as keyof Email]}</Typography>
              </div>
            </>
          ),
        )}
    </>
  );
};

export default ExportEmailItemTable;
