import React from 'react';
import { ListItem, Typography, styled } from '@mui/material';
import { TransferAccount } from 'types/transferAccount';
import { useTransferAccounts } from '../../hooks/useTransferAccount';

const StyledListItem = styled(ListItem)({
  display: 'flex',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 120,
  flexDirection: 'column',
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  alignItems: 'center',
  gridTemplateColumns: '80px 1fr',
});

interface TransferAccountItemModuleProps {
  account: TransferAccount;
}

const TransferAccountItemModule: React.FC<TransferAccountItemModuleProps> = ({ account }) => {
  const { setSelectedTransferAccount } = useTransferAccounts();

  function handleClick() {
    setSelectedTransferAccount(account);
  }

  return (
    <StyledListItem onClick={handleClick}>
      <Grid>
        <Typography variant="caption">Usuário:</Typography>
        <Typography variant="button">{account.usuario}</Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Origem:</Typography>
        <Typography variant="subtitle2" color="primary">
          {account.origem}
        </Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Destino:</Typography>
        <Typography variant="subtitle2" color="primary">
          {account.destino}
        </Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Observação:</Typography>
        <Typography color="primary" variant="body2">
          {account.observacao}
        </Typography>
      </Grid>

      <Typography variant="button" marginTop={1}>
        {account.data}
      </Typography>
      <Typography color="green" fontWeight={600} fontSize={16} variant="button">
        {account.formattedValue}
      </Typography>
    </StyledListItem>
  );
};

export default TransferAccountItemModule;
