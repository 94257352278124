import React from 'react';
import { OrderIndexData } from 'hooks/tableOrder';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import SubgroupItemTable from './SubgroupItemTable';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography, Grid, styled } from '@mui/material';
import { AbcProductSubgroup } from 'types/abcProduct';
import { subgroupTableTemplate } from '../../subgroupTableTemplate';
import SubgroupMoreMenu from '../SubgroupMoreMenu';
import { useAbcProducts } from '../../../hooks/useAbcProducts';
import TableRow from 'components/new-table/TableRow';
import TableHeader from 'components/new-table/TableHeader';

const CustomTableRow = styled('div')({
  '&.isSubgroup': {
    '& > div:nth-child(even)': {
      backgroundColor: '#eee',
    },
  },
});

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
  '&.headerItemNumeric': {
    justifyContent: 'flex-end',
  },
});

type SubgroupListTableProps = {
  subgroups: AbcProductSubgroup[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  branchId?: string;
  isSubgroup: boolean;
  openModal?: boolean;
};

const SubgroupListTable: React.FC<SubgroupListTableProps> = ({
  subgroups,
  handleSort,
  orderedIndex,
  isSubgroup,
  branchId = '',
  openModal = true,
}) => {
  const { moreSubgroup } = useAbcProducts();
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={subgroupTableTemplate}>
          <TableContent useMinHeight={false}>
            <TableHeader className={`${isSubgroup ? '' : 'headerSubgroup'}`}>
              {subgroupTableTemplate.map(item => (
                <HeaderItem
                  className={item.dataType === 'number' ? 'headerItemNumeric' : ''}
                  key={item.id}
                  onClick={() => handleSort(item.originalId)}
                >
                  <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </HeaderItem>
              ))}
            </TableHeader>
            <TableBody>
              <CustomTableRow className={isSubgroup ? 'isSubgroup' : undefined}>
                {subgroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(subgroup => (
                  <>
                    <TableRow key={subgroup.cod}>
                      <SubgroupItemTable
                        openModal={openModal}
                        branchId={branchId}
                        isSubgroup={isSubgroup}
                        subgroup={subgroup}
                      />
                    </TableRow>
                    {moreSubgroup?.cod === subgroup.cod && <SubgroupMoreMenu subgroup={subgroup} />}
                  </>
                ))}
              </CustomTableRow>
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default SubgroupListTable;
