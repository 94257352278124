import React, { ReactElement } from 'react';
import { IconButton, Typography, Theme } from '@mui/material/';
import { ArrowBack } from '@mui/icons-material';
import history from 'services/history';
import { makeStyles } from '@mui/styles';

interface MakeStyleProps {
  gutterBottom: boolean;
}

const useStyles = makeStyles<Theme, MakeStyleProps>(theme => ({
  container: ({ gutterBottom }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: gutterBottom ? 20 : 0,
    '@media print': {
      display: 'none',
    },
  }),
  content: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  actions: {
    display: 'flex',
    columnGap: 7,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  backButton: {
    marginRight: 15,
  },
}));

interface PageHeaderActionsProps {
  title: string;
  description?: string;
  gutterBottom?: boolean;
  ActionComponent?: ReactElement;
  backUrl?: string;
  backAction?(): void;
}

const PageHeaderActions: React.FC<PageHeaderActionsProps> = ({
  title,
  description,
  gutterBottom = true,
  ActionComponent,
  backUrl,
  backAction,
}) => {
  const classes = useStyles({ gutterBottom });

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {backAction ? (
          <IconButton className={classes.backButton} size="small" onClick={backAction} color="inherit">
            <ArrowBack />
          </IconButton>
        ) : (
          backUrl && (
            <IconButton className={classes.backButton} size="small" onClick={() => history.push(backUrl)}>
              <ArrowBack />
            </IconButton>
          )
        )}
        <div>
          <Typography variant="h6">{title}</Typography>
          {description && (
            <Typography color="textSecondary" variant="body2">
              {description}
            </Typography>
          )}
        </div>
      </div>

      {ActionComponent && <div className={classes.actions}>{ActionComponent}</div>}
    </div>
  );
};

export default PageHeaderActions;
