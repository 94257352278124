import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import SiteBannerItemModule from './PromotionalBannerItemModule';
import { PromotionalBanner } from 'types/promotionalBanners';

type PromotionalBannerListModuleProps = {
  banners: PromotionalBanner[];
};

const PromotionalBannerListModule: React.FC<PromotionalBannerListModuleProps> = ({ banners }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {banners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(banner => (
            <SiteBannerItemModule key={banner.id} banner={banner} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default PromotionalBannerListModule;
