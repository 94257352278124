import React, { FormEvent, useEffect, useState, ReactNode } from 'react';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import InsideSaving from 'components/loading/InsideSaving';
import Appbar from 'components/appbar/Appbar';
import CompatibilityNewAppbarButtons from './CompatibilityNewAppbarButtons';
import { styled } from '@mui/material';
import { ProjectProvider } from '../hook/useCompatibility';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import history from 'services/history';
import { TAB_CONTENT_WITH_FULL_WIDTH } from 'pages/projects/constants/tabContentWithFullWidth';
import { Compatibility } from 'types/compatibility';
import { uuidv4 } from 'helpers/uuidv4';
import CompatibilityTab, { CompatibilityTabValues } from './tabs/CompatibilityTab';
import { useCompatibilityValidation } from '../validation/compatibilityValidation';
import CompatibilityProducts from '../form/products/CompatibilityProducts';
import CompatibilityMain from '../form/CompatibilityMain';

type ContentProps = {
  maxWidth: string | number;
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Content = styled('div')<ContentProps>(props => ({
  display: 'flex',
  borderTop: '1px solid #eee',
  flexDirection: 'column',
  flex: 1,
  maxWidth: props.maxWidth,
  marginTop: 20,
  marginBottom: 200,
}));

export const compatibilityInitialState: Compatibility = {
  id: uuidv4(),
  description: '',
  products: [],
  type: 'compatibility',
};

const CompatibilityNew: React.FC = () => {
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState<CompatibilityTabValues>('main');
  const [validation, setValidation, validate] = useCompatibilityValidation();
  const [compatibility, setCompatibility] = useState(compatibilityInitialState);

  useEffect(() => {
    const main = ['description'];

    const [key] = Object.keys(validation);

    if (!key) {
      return;
    }

    if (main.includes(key)) {
      setTab('main');
      return;
    }

    setTab('products');
  }, [validation]);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});

    if (!compatibility) {
      return;
    }

    validate(compatibility)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.error(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    const data = {
      description: compatibility.description,
      product_ids: compatibility.products.map(product => product.id),
      type: 'compatibility',
    };

    api
      .post('/compatibilities', data)
      .then(() => {
        messaging.handleOpen('Compatibilidade criada com sucesso!');
        history.push('/compatibilities');
      })
      .catch(err => {
        console.error(err);
        setSaving(false);
      });
  }

  function handleChange(index: keyof Compatibility, value: any) {
    setCompatibility(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleTabsRendering(): ReactNode {
    const tabContents: Record<CompatibilityTabValues, ReactNode> = {
      main: <CompatibilityMain validation={validation} />,
      products: <CompatibilityProducts />,
    };

    return tabContents[tab];
  }

  return (
    <ProjectProvider value={{ compatibility, handleChange, setCompatibility }}>
      <Appbar
        title="Nova compatibilidade"
        Tab={<CompatibilityTab tab={tab} handleChange={tab => setTab(tab)} />}
        ActionsComponent={<CompatibilityNewAppbarButtons handleValidation={handleValidation} />}
      />

      {saving && <InsideSaving />}

      <Container>
        <PageHeaderActions
          backUrl="/compatibilities"
          title="Nova compatibilidade"
          description="Cadastro para compatibilidade de produto"
        />

        <Content maxWidth={TAB_CONTENT_WITH_FULL_WIDTH.includes(tab) ? '100%' : 600}>{handleTabsRendering()}</Content>
      </Container>
    </ProjectProvider>
  );
};

export default CompatibilityNew;
