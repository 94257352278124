import React, { FormEvent } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import { useApp } from 'hooks/app';
import SelectBranch from 'components/select-branches/SelectBranch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AbcCustomerWithoutSaleFilterParams } from './CustomerWithoutSale';
import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, styled } from '@mui/material';
import { FilterList, Search } from '@mui/icons-material';
import { ptBR } from 'date-fns/locale';
import { usePermissionRules } from 'hooks/permissionRules';
import { FaFileExcel } from 'react-icons/fa';

const Filter = styled('div')(props => ({
  gap: 10,
  flex: 1,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '190px 130px 1fr',
  [props.theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr ',
  },
  '& > .search-params': {
    gap: 10,
    display: 'flex',
    alignSelf: 'end',
    [props.theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const SelectInput = styled(Select)(props => ({
  height: '100%',
  [props.theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const DatePickerContent = styled('div')({
  display: 'flex',
  position: 'relative',
  borderRadius: 4,
  flex: 1,
  '& > .label': {
    position: 'absolute',
    top: -12,
    left: 10,
    fontSize: 11,
  },
});

const DatePickerContainer = styled('div')(props => ({
  display: 'flex',
  gap: 10,
  flex: 1,
  [props.theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ButtonIcon = styled(IconButton)({
  display: 'block',
});

const Container = styled('form')(({ theme }) => ({
  flex: 1,
  gap: 10,
  display: 'grid',
  gridTemplateColumns: '1fr 0.7fr 110px',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Form = styled('div')({
  gap: 10,
  display: 'grid',
  alignItems: 'baseline',
  gridTemplateColumns: '1fr 1fr',
  minWidth: '100%',
  '& > button': {
    maxHeight: 30,
  },
});

const Actions = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'end',
  alignItems: 'end',
  [theme.breakpoints.down('sm')]: {
    gridColumn: '2/3',
  },
}));

interface AbcCustomerFilterBoxProps {
  filter: AbcCustomerWithoutSaleFilterParams;
  handleChangeFilter(index: keyof AbcCustomerWithoutSaleFilterParams, value: any): void;
  handleOpenDialog(): void;
  handleOpenDialogDesktop(): void;
  searchText: string;
  productText: string;
  handleSearchInputChange(index: 'product' | 'text', value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
  handleOpenExcel(): void;
}

const AbcCustomerFilterBox: React.FC<AbcCustomerFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  searchText,
  productText,
  handleSearchInputChange,
  handleSearchSubmit,
  handleOpenDialogDesktop,
  handleOpenDialog,
  handleOpenExcel,
}) => {
  const { isMobile, windowWidth } = useApp();
  const { checkPermission } = usePermissionRules();
  function handleChangeDateWithoutSale(dates: [Date | null, Date | null]) {
    const [start, end] = dates;
    handleChangeFilter('withoutSale_initialDate', start);
    handleChangeFilter('withoutSale_finalDate', end);
  }

  function handleChangeDateSale(dates: [Date | null, Date | null]) {
    const [start, end] = dates;
    handleChangeFilter('sale_initialDate', start);
    handleChangeFilter('sale_finalDate', end);
  }

  return (
    <FilterBox>
      <Container onSubmit={handleSearchSubmit}>
        <Filter>
          <SelectBranch
            branch_id={filter.selectedBranchId}
            handleChange={e => handleChangeFilter('selectedBranchId', e)}
          />

          <FormControl>
            <InputLabel>Tipo de venda</InputLabel>
            <SelectInput
              fullWidth
              label="tipo de venda"
              value={filter.typeSale}
              onChange={e => handleChangeFilter('typeSale', e.target.value)}
            >
              <MenuItem value="T">Todas</MenuItem>
              <MenuItem value="C">Coligadas</MenuItem>
              <MenuItem value="V">Vendas</MenuItem>
            </SelectInput>
          </FormControl>

          <div className="search-params">
            <DatePickerContainer>
              <DatePickerContent>
                <span className="label">Periodo sem venda</span>
                <DatePicker
                  selected={filter.withoutSale_initialDate}
                  onChange={dates => handleChangeDateWithoutSale(dates)}
                  startDate={filter.withoutSale_initialDate}
                  endDate={filter.withoutSale_finalDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  locale={ptBR}
                />
              </DatePickerContent>
            </DatePickerContainer>

            <DatePickerContainer>
              <DatePickerContent>
                <span className="label">Periodo com venda</span>
                <DatePicker
                  selected={filter.sale_initialDate}
                  onChange={dates => handleChangeDateSale(dates)}
                  startDate={filter.sale_initialDate}
                  endDate={filter.sale_finalDate}
                  selectsRange
                  locale={ptBR}
                  dateFormat="dd/MM/yyyy"
                />
              </DatePickerContent>
            </DatePickerContainer>
          </div>
        </Filter>
        <Form>
          <TextField
            label="Produto"
            placeholder="Produto"
            autoFocus
            value={productText}
            onChange={e => handleSearchInputChange('product', e.target.value)}
          />
          <TextField
            label="Cliente"
            placeholder="Cliente"
            autoFocus
            value={searchText}
            onChange={e => handleSearchInputChange('text', e.target.value)}
          />
        </Form>
        <Actions>
          {checkPermission('general.excel_export_button') && (
            <Tooltip title="Exportar excel">
              <ButtonIcon onClick={handleOpenExcel} style={{ color: 'green' }}>
                <FaFileExcel />
              </ButtonIcon>
            </Tooltip>
          )}
          <Tooltip title="Outros filtros">
            <ButtonIcon
              onClick={isMobile || windowWidth < 1280 ? handleOpenDialog : handleOpenDialogDesktop}
              color="inherit"
            >
              <FilterList />
            </ButtonIcon>
          </Tooltip>
          <Tooltip title="Buscar">
            <ButtonIcon type="submit">
              <Search />
            </ButtonIcon>
          </Tooltip>
        </Actions>
      </Container>
    </FilterBox>
  );
};

export default AbcCustomerFilterBox;
