import React, { useRef, useEffect, useState } from 'react';
import { TextField, Grid, Typography, Switch, MenuItem, styled } from '@mui/material';
import { SettingsIndexes } from './reducer/types';
import { SiteSettingValidation } from './validation/siteSettingValidation';
import { SiteSetting } from 'types/siteSetting';
import SiteSettingsFormImage from './SettingsImageForm';
import { Page } from 'types/page';
import { api } from 'services/api';

const InputContainer = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '20px',
  rowGap: '40px',
  flex: 1,
  marginTop: 20,
  marginBottom: 100,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

type SettingsFormProps = {
  validation: SiteSettingValidation;
  handleChange(index: SettingsIndexes, value: any): void;
  settings: SiteSetting;
};

const SettingsForm: React.FC<SettingsFormProps> = ({ validation, handleChange, settings }) => {
  const [pages, setPages] = useState<Page[]>([]);

  const inputs = {
    contact_email: useRef<HTMLInputElement>(null),
    home_products_title: useRef<HTMLInputElement>(null),
    home_courses_title: useRef<HTMLInputElement>(null),
    primary_color: useRef<HTMLInputElement>(null),
    secondary_color: useRef<HTMLInputElement>(null),
    footer_company_name: useRef<HTMLInputElement>(null),
    facebook_profile_url: useRef<HTMLInputElement>(null),
    instagram_profile_url: useRef<HTMLInputElement>(null),
    youtube_channel_url: useRef<HTMLInputElement>(null),
    home_title: useRef<HTMLInputElement>(null),
    home_description: useRef<HTMLInputElement>(null),
    home_keywords: useRef<HTMLInputElement>(null),
    whatsapp: useRef<HTMLInputElement>(null),
    about_page: useRef<HTMLInputElement>(null),
    home_segments_title: useRef<HTMLInputElement>(null),
    bottom_banners_title: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;
    if (!inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  useEffect(() => {
    api.get('/pages').then(response => {
      setPages(response.data);
    });
  }, []);

  return (
    <Grid container item xs={12} md={10} lg={8} xl={6}>
      <InputContainer>
        <div>
          <Typography variant="subtitle2">Email de contato</Typography>
          <Typography variant="caption" color="textSecondary">
            Este endereço de e-mail vai receber as mensagem enviadas pelo site
          </Typography>
        </div>
        <TextField
          inputRef={inputs.contact_email}
          error={!!validation.contact_email}
          helperText={validation.contact_email}
          label="E-mail de contato"
          placeholder="Informe o e-mail de contato"
          value={settings.contact_email}
          onChange={e => handleChange('contact_email', e.target.value)}
          fullWidth
          autoFocus
          type="email"
          autoComplete="email"
        />

        <div>
          <Typography variant="subtitle2">Fundo gradiente</Typography>
          <Typography variant="caption" color="textSecondary">
            Ativar ou desativar fundo gradiente
          </Typography>
        </div>

        <Switch
          color="primary"
          onChange={event => handleChange('use_gradient_background', event.target.checked)}
          checked={settings.use_gradient_background}
        />

        <div>
          <Typography variant="subtitle2">Usar API H2i</Typography>
          <Typography variant="caption" color="textSecondary">
            Usar API H2i para base de dados de revendedores
          </Typography>
        </div>

        <Switch
          color="primary"
          onChange={event => handleChange('use_h2i_api', event.target.checked)}
          checked={settings.use_h2i_api}
        />

        <div>
          <Typography variant="subtitle2">Página sobre</Typography>
          <Typography variant="caption" color="textSecondary">
            URL da página sobre
          </Typography>
        </div>
        <TextField
          select
          inputRef={inputs.about_page}
          error={!!validation.about_page}
          helperText={validation.about_page}
          label="Página sobre"
          placeholder="Página sobre"
          value={settings.about_page}
          onChange={e => handleChange('about_page', e.target.value)}
          fullWidth
        >
          {pages.map(page => (
            <MenuItem value={page.url} key={page.id}>
              {page.title}
            </MenuItem>
          ))}
        </TextField>

        <div>
          <Typography variant="subtitle2">Paleta de cores</Typography>
          <Typography variant="caption" color="textSecondary">
            Definição das cores primárias e secondárias
          </Typography>
        </div>
        <div>
          <TextField
            inputRef={inputs.primary_color}
            error={!!validation.primary_color}
            helperText={validation.primary_color}
            label="Cor primária"
            placeholder="Cor primária"
            value={settings.primary_color}
            onChange={e => handleChange('primary_color', e.target.value)}
            fullWidth
          />
          <TextField
            inputRef={inputs.secondary_color}
            error={!!validation.secondary_color}
            helperText={validation.secondary_color}
            label="Cor secundária"
            placeholder="Cor secundária"
            value={settings.secondary_color}
            onChange={e => handleChange('secondary_color', e.target.value)}
            fullWidth
            margin="normal"
          />
        </div>

        <div>
          <Typography variant="subtitle2">Página inicial</Typography>
        </div>
        <div>
          <TextField
            inputRef={inputs.home_segments_title}
            error={!!validation.home_segments_title}
            helperText={validation.home_segments_title}
            label="Título áreas de atuação"
            placeholder="Título áreas de atuação"
            value={settings.home_segments_title}
            onChange={e => handleChange('home_segments_title', e.target.value)}
            fullWidth
          />
          <TextField
            inputRef={inputs.bottom_banners_title}
            error={!!validation.bottom_banners_title}
            helperText={validation.bottom_banners_title}
            label="Título destaques parte inferior"
            placeholder="Título destaques parte inferior"
            value={settings.bottom_banners_title}
            onChange={e => handleChange('bottom_banners_title', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            inputRef={inputs.home_products_title}
            margin="normal"
            error={!!validation.home_products_title}
            helperText={validation.home_products_title}
            label="Título lista de produtos"
            placeholder="Título lista de produtos home page"
            value={settings.home_products_title}
            onChange={e => handleChange('home_products_title', e.target.value)}
            fullWidth
          />
          <TextField
            inputRef={inputs.home_courses_title}
            error={!!validation.home_courses_title}
            helperText={validation.home_courses_title}
            label="Título lista de cursos"
            placeholder="Título lista de cursos home page"
            value={settings.home_courses_title}
            onChange={e => handleChange('home_courses_title', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            inputRef={inputs.home_title}
            error={!!validation.home_title}
            helperText={validation.home_title}
            label="Título da página inicial"
            placeholder="Título da página inicial"
            value={settings.home_title}
            onChange={e => handleChange('home_title', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            inputRef={inputs.home_description}
            error={!!validation.home_description}
            helperText={validation.home_description}
            label="Descrição da página inicial"
            placeholder="Descrição da página inicial"
            value={settings.home_description}
            onChange={e => handleChange('home_description', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            inputRef={inputs.home_keywords}
            error={!!validation.home_keywords}
            helperText={validation.home_keywords}
            label="Keywords da página inicial"
            placeholder="Keywords da página inicial"
            value={settings.home_keywords}
            onChange={e => handleChange('home_keywords', e.target.value)}
            fullWidth
            multiline
            rows={5}
            margin="normal"
          />
          <SiteSettingsFormImage
            source={settings.home_image?.imageUrl}
            validationErrorText={validation.home_image}
            label="Imagem página inicial"
            handleChange={handleChange}
            index="home_image"
          />
        </div>

        <div>
          <Typography variant="subtitle2">Rodapé</Typography>
        </div>
        <div>
          <TextField
            inputRef={inputs.footer_company_name}
            error={!!validation.footer_company_name}
            helperText={validation.footer_company_name}
            label="Nome da empresa no rodapé"
            placeholder="Nome da empresa no rodapé"
            value={settings.footer_company_name}
            onChange={e => handleChange('footer_company_name', e.target.value)}
            fullWidth
          />
          <SiteSettingsFormImage
            source={settings.footer_logo?.imageUrl}
            validationErrorText={validation.footer_logo}
            label="Logo do rodapé"
            handleChange={handleChange}
            index="footer_logo"
          />
        </div>

        <div>
          <Typography variant="subtitle2">Mídias sociais</Typography>
          <Typography variant="caption" color="textSecondary">
            Link das mídias sociais
          </Typography>
        </div>
        <div>
          <TextField
            inputRef={inputs.facebook_profile_url}
            error={!!validation.facebook_profile_url}
            helperText={validation.facebook_profile_url}
            label="Endereço do perfil no Facebook"
            placeholder="Endereç do perfil no Facebook"
            value={settings.facebook_profile_url}
            onChange={e => handleChange('facebook_profile_url', e.target.value)}
            fullWidth
          />
          <TextField
            inputRef={inputs.instagram_profile_url}
            error={!!validation.instagram_profile_url}
            helperText={validation.instagram_profile_url}
            label="Endereço do perfil no Instagram"
            placeholder="Endereço do perfil no Instagram"
            value={settings.instagram_profile_url}
            onChange={e => handleChange('instagram_profile_url', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            inputRef={inputs.youtube_channel_url}
            error={!!validation.youtube_channel_url}
            helperText={validation.youtube_channel_url}
            label="Endereço do canal no YouTube"
            placeholder="Endereço do canal no YouTube"
            value={settings.youtube_channel_url}
            onChange={e => handleChange('youtube_channel_url', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            inputRef={inputs.whatsapp}
            error={!!validation.whatsapp}
            helperText={validation.whatsapp}
            label="WhatsApp"
            placeholder="WhatsApp"
            value={settings.whatsapp}
            onChange={e => handleChange('whatsapp', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Facebook Pixel ID"
            placeholder="Informe o ID do Facebook Pixel"
            value={settings.facebook_pixel_id}
            onChange={e => handleChange('facebook_pixel_id', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Google Analytics ID"
            placeholder="Informe o Google Analytics ID"
            value={settings.google_analytics_id}
            onChange={e => handleChange('google_analytics_id', e.target.value)}
            fullWidth
            margin="normal"
          />
        </div>
        <div>
          <Typography variant="subtitle2">Ordem dos conteúdos da pagina inicial</Typography>
        </div>
        <div>
          <TextField
            label="Banners segmentos"
            placeholder="Ordem de banners dos segmentos"
            value={settings.home_segment_banners_order}
            onChange={e => handleChange('home_segment_banners_order', e.target.value)}
            fullWidth
          />
          <TextField
            label="Produtos em destaque"
            placeholder="Ordem dos produtos em destaque"
            value={settings.home_featured_products_order}
            onChange={e => handleChange('home_featured_products_order', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Banners dos destaques"
            placeholder="Ordem dos banners de destaques"
            value={settings.home_highlight_banners_order}
            onChange={e => handleChange('home_highlight_banners_order', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Banners promocionais"
            placeholder="Ordem dos banners promocionais"
            value={settings.home_promotional_banners_order}
            onChange={e => handleChange('home_promotional_banners_order', e.target.value)}
            fullWidth
            margin="normal"
          />
        </div>
        <div>
          <Typography variant="subtitle2">Governança</Typography>
        </div>
        <div>
          <TextField
            label="Link Environment"
            placeholder="Environment"
            value={settings.environment_page_url}
            onChange={e => handleChange('environment_page_url', e.target.value)}
            margin="normal"
            fullWidth
          />

          <TextField
            label="Link Organograma"
            placeholder="Organograma"
            value={settings.organization_chart_page_url}
            onChange={e => handleChange('organization_chart_page_url', e.target.value)}
            margin="normal"
            fullWidth
          />

          <TextField
            label="Link Data Sharing"
            placeholder="Data Sharing"
            value={settings.data_sharing_page_url}
            onChange={e => handleChange('data_sharing_page_url', e.target.value)}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Link Unidades"
            placeholder="Unidades"
            value={settings.branches_page_url}
            onChange={e => handleChange('branches_page_url', e.target.value)}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Link RH e DP"
            placeholder="RH e DP"
            value={settings.human_resources_page_url}
            onChange={e => handleChange('human_resources_page_url', e.target.value)}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Link News"
            placeholder="News"
            value={settings.news_page_url}
            onChange={e => handleChange('news_page_url', e.target.value)}
            fullWidth
            margin="normal"
          />
        </div>
      </InputContainer>
    </Grid>
  );
};

export default SettingsForm;
