import { createContext, useContext } from 'react';
import { CommercialBi } from '../types/commercial-bi';
import { CommercialBiMainFilterProps } from '../CommercialBi';

export interface CommercialBiContextValue {
  isFiltersLoading: boolean;
  mainFilter: CommercialBiMainFilterProps;
  handleMainFilterChange(index: keyof CommercialBiMainFilterProps, value: any): void;
  isBiLoading: boolean;
  data: CommercialBi;
}

const Context = createContext<CommercialBiContextValue>({} as CommercialBiContextValue);
export const CommercialBiProvider = Context.Provider;

export function useCommercialBi(): CommercialBiContextValue {
  return useContext(Context);
}
