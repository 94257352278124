import React from 'react';
import { Done } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

interface CompatibilityUpdateAppbarButtonsProps {
  handleValidation(): void;
}

const CompatibilityUpdateAppbarButtons: React.FC<CompatibilityUpdateAppbarButtonsProps> = ({ handleValidation }) => {
  return (
    <Tooltip title="Salvar">
      <IconButton color="inherit" onClick={handleValidation}>
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default CompatibilityUpdateAppbarButtons;
