import { ListItemButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { AbcCustomerWithoutSale } from 'types/abcCustomer';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type CustomerItemModuleProps = {
  customer: AbcCustomerWithoutSale;
};

const CustomerItemModule: React.FC<CustomerItemModuleProps> = ({ customer }) => {
  const classes = useStyles();

  return (
    <>
      <ListItemButton className={classes.listItem}>
        <Typography variant="caption" color="primary">
          Cliente - {customer.cliente}
        </Typography>
        <Typography gutterBottom>Atividade - {customer.atividade}</Typography>
        <Typography gutterBottom>Documento - {customer.cnpj_cpf_cliente}</Typography>
      </ListItemButton>
    </>
  );
};

export default CustomerItemModule;
