import { Dispatch, SetStateAction, useState } from 'react';
import { BillSetting } from 'types/billSetting';
import * as yup from 'yup';

export type BillSettingValidation = {
  wallet?: string;
  variation?: string;
  bank_agency?: string;
  bank_id?: string;
  cnab?: string;
  bank_agency_dv?: string;
  bank_account?: string;
  bank_account_dv?: string;
  beneficiary_document?: string;
  beneficiary_name?: string;
  beneficiary_postal_code?: string;
  beneficiary_address?: string;
  beneficiary_neighborhood?: string;
  beneficiary_city?: string;
  beneficiary_state?: string;
  fee?: string;
  fine?: string;
  days_to_fee?: string;
  dispatch_id?: string;
  bank_account_id?: string;
  bank_customer_id?: string;
  bank_name?: string;
  payment_place?: string;
};

type UseBillSettingValidation = [
  BillSettingValidation,
  Dispatch<SetStateAction<BillSettingValidation>>,
  (settings: BillSetting) => Promise<void>,
];

export function useBillSettingValidation(): UseBillSettingValidation {
  async function handleValidation(settings: BillSetting) {
    const schema = yup.object().shape({
      bank_account_id: yup.string().required('O código do convênio junto ao banco é obrigatório'),
      bank_customer_id: yup.string().required('O código do cliente junto ao banco é obrigatório'),
      dispatch_id: yup
        .number()
        .typeError('Informe um número válido')
        .integer('Número inválido')
        .required('A sequência da remessa é obrigatória'),
      days_to_fee: yup.string().required('Dias para cobrar juros é obrigatório'),
      fine: yup.number().typeError('Informe um número válido').required('O percentual dos juros é obrigatório'),
      fee: yup.number().typeError('Informe um número válido').required('O percentual da multa é obrigatório'),
      beneficiary_state: yup.string().required('O estado do beneficiário é obrigatório'),
      beneficiary_city: yup.string().required('A cidade do beneficiário é obrigatório'),
      beneficiary_neighborhood: yup.string().required('O bairro do beneficiário é obrigatório'),
      beneficiary_address: yup.string().required('O endereço com número do beneficiário é obrigatório'),
      beneficiary_postal_code: yup.string().required('O CEP do beneficiário é obrigatório'),
      beneficiary_document: yup.string().required('Documento do beneficiário é obrigatório'),
      beneficiary_name: yup.string().required('Nome do beneficiário é obrigatório'),
      bank_account_dv: yup
        .number()
        .typeError('Informe um número válido')
        .integer('Número inválido')
        .required('O dígito da conta bancária é obrigatória'),
      bank_account: yup
        .number()
        .typeError('Informe um número válido')
        .integer('Número inválido')
        .required('A conta bancária é obrigatória'),
      bank_agency_dv: yup
        .number()
        .typeError('Informe um número válido')
        .integer('Número inválido')
        .required('O dígito da agência bancária é obrigatória'),
      bank_agency: yup
        .number()
        .typeError('Informe um número válido')
        .integer('Número inválido')
        .required('A agência bancária é obrigatória'),
      wallet: yup.string().required('A carteira é obrigatória'),
      cnab: yup.string().required('CNAB é obrigatória'),
      bank_id: yup.string().required('Selecione o banco'),
      bank_name: yup.string().required('O nome do banco é obrigatório'),
      payment_place: yup.string().required('O local de pagamento é obrigatório'),
    });

    try {
      await schema.validate(settings);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<BillSettingValidation>({});
  return [validation, setValidation, handleValidation];
}
