import React, { useMemo } from 'react';
import { externalTooltipHandler } from 'components/chart/externalTooltip';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { ActivityMethod, SalesMethod } from 'types/salesReport';
import { Doughnut } from 'react-chartjs-2';

interface ActivityChartProps {
  methods: ActivityMethod[];
  salesMethods: SalesMethod[];
}

const ActivityChart: React.FC<ActivityChartProps> = ({ methods, salesMethods }) => {
  const pieData = useMemo(() => {
    return methods
      .sort((a, b) => b.valor - a.valor)
      .map(item => item.valor)
      .slice(0, 5);
  }, [methods]);

  return (
    <div style={{ height: 560, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
      <Doughnut
        data={{
          labels: methods
            .sort((a, b) => b.valor - a.valor)
            .map(branch => [branch.atividade])
            .slice(0, 5),
          datasets: [
            {
              label: 'Total',
              data: methods
                .sort((a, b) => b.valor - a.valor)
                .map(branch => branch.valor)
                .slice(0, 5),
              borderWidth: 1,
            },
          ],
        }}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
              callbacks: {
                label: tooltip => moneyFormat(pieData[tooltip.dataIndex as number]),
              },
              external: externalTooltipHandler,
            },
            legend: {
              display: true,
              position: 'right',
            },
            colors: {
              enabled: true,
            },
            datalabels: {
              display: true,
              color: '#fff',
              font: {
                size: 9,
                weight: 600,
              },
              textAlign: 'left',
              formatter(value, context) {
                return `${methods.sort((a, b) => b.valor - a.valor)[context.dataIndex].atividade} ${percentFormat(
                  (value / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100,
                )}`;
              },
            },
          },
          responsive: true,
        }}
      />
    </div>
  );
};

export default ActivityChart;
