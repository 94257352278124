import React, { useState } from 'react';
import ProductItem from './ContactItemModule';
import List from 'components/list/List';
import { ProjectContact } from 'types/project';
import ProjectContactsListMenu from '../ProjectContactsListMenu';

type ContactListModuleProps = {
  contacts: ProjectContact[];
};

const ContactListModule: React.FC<ContactListModuleProps> = ({ contacts }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <>
      <ProjectContactsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <List>
        {contacts.map(contact => (
          <ProductItem setAnchorEl={setAnchorEl} key={contact.id} contact={contact} />
        ))}
      </List>
    </>
  );
};

export default ContactListModule;
