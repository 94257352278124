import React, { FormEvent } from 'react';
import { MdSearch } from 'react-icons/md';
import FilterBox from 'components/filter-box/FilterBox';
import { IconButton, InputAdornment, MenuItem, Select, TextField, Theme, Tooltip, styled } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { FaFileExcel } from 'react-icons/fa';
import { useApp } from 'hooks/app';
import SelectBranch from 'components/select-branches/SelectBranch';
import { usePermissionRules } from 'hooks/permissionRules';
import { AbcProductFilterDates, AbcProductFilterParams } from './AbcProduct';
import { makeStyles } from '@mui/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import ptBR from 'date-fns/locale/pt-BR';
// registerLocale('ptBR', ptBR);

const useStyles = makeStyles<Theme>(theme => ({
  filter: {
    gap: 10,
    flex: 1,
    display: 'grid',
    alignItems: 'flex-end',
    gridTemplateColumns: '0.5fr 0.5fr 0.8fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  select: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  button: {
    display: 'block',
  },
}));

const DatePickerContent = styled('div')(props => ({
  display: 'flex',
  position: 'relative',
  borderRadius: 4,
  flex: 1,
  '& > .label': {
    position: 'absolute',
    top: -12,
    left: 10,
    fontSize: 11,
  },
}));

const DatePickerContainer = styled('div')(props => ({
  display: 'flex',
  gap: 10,
  [props.theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Form = styled('form')(props => ({
  display: 'block',
  [props.theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

interface AbcProductFilterBoxProps {
  filter: AbcProductFilterParams;
  handleChangeFilter(index: keyof AbcProductFilterParams, value: any): void;
  handleDatesFilterChange(index: keyof AbcProductFilterDates, value: any): void;
  handleOpenDialog(): void;
  handleOpenDialogDesktop(): void;
  handleOpenExcel(): void;
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
  disabled: boolean;
}

const AbcProductFilterBox: React.FC<AbcProductFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  searchText,
  handleSearchInputChange,
  handleSearchSubmit,
  handleOpenDialogDesktop,
  handleOpenDialog,
  handleOpenExcel,
  disabled,
  handleDatesFilterChange,
}) => {
  const classes = useStyles();
  const { isMobile, windowWidth } = useApp();
  const { checkPermission } = usePermissionRules();

  function handleChangeInitialRangeDates(dates: [Date | null, Date | null]) {
    const [start, end] = dates;
    handleDatesFilterChange('initialDate', start);
    handleDatesFilterChange('finalDate', end);
  }

  function handleChangeRangeDatesToCompare(dates: [Date | null, Date | null]) {
    const [start, end] = dates;
    handleDatesFilterChange('initialDateToCompare', start);
    handleDatesFilterChange('finalDateToCompare', end);
  }

  return (
    <FilterBox>
      <div className={classes.filter}>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />

        <Select
          label="tipo de venda"
          className={classes.select}
          value={filter.typeSale}
          onChange={e => handleChangeFilter('typeSale', e.target.value)}
        >
          <MenuItem value="T">Todas</MenuItem>
          <MenuItem value="C">Coligadas</MenuItem>
          <MenuItem value="V">Vendas</MenuItem>
        </Select>

        <DatePickerContainer>
          <DatePickerContent>
            <span className="label">Periodo inicial</span>
            <DatePicker
              selected={filter.dates.initialDate}
              onChange={dates => handleChangeInitialRangeDates(dates)}
              locale={ptBR}
              startDate={filter.dates.initialDate}
              endDate={filter.dates.finalDate}
              selectsRange
              dateFormat="dd/MM/yyyy"
            />
          </DatePickerContent>
          <DatePickerContent>
            <span className="label">Periodo a comparar</span>
            <DatePicker
              selected={filter.dates.initialDateToCompare}
              onChange={dates => handleChangeRangeDatesToCompare(dates)}
              locale={ptBR}
              startDate={filter.dates.initialDateToCompare}
              endDate={filter.dates.finalDateToCompare}
              selectsRange
              dateFormat="dd/MM/yyyy"
            />
          </DatePickerContent>
        </DatePickerContainer>

        <Form onSubmit={handleSearchSubmit}>
          <TextField
            label="Produto"
            placeholder="Produto"
            autoFocus
            fullWidth
            value={searchText}
            onChange={e => handleSearchInputChange(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <MdSearch color="#888" size={20} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Form>
      </div>
      <div>
        <Tooltip title="Outros filtros">
          <IconButton
            className={classes.button}
            onClick={isMobile || windowWidth < 1280 ? handleOpenDialog : handleOpenDialogDesktop}
            color="inherit"
          >
            <FilterList />
          </IconButton>
        </Tooltip>

        {checkPermission('general.excel_export_button') && (
          <Tooltip title="Exportar excel">
            <IconButton disabled={disabled} onClick={handleOpenExcel} style={{ color: disabled ? '#eee' : 'green' }}>
              <FaFileExcel />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </FilterBox>
  );
};

export default AbcProductFilterBox;
