import { TableTemplate } from 'types/tableTemplate';

export const customersTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
    notFilterable: true,
  },
  {
    id: 'name',
    description: 'NOME SOCIAL',
    originalId: 'name',
    width: 350,
  },
  {
    id: 'whatsapp',
    description: 'WHATSAPP',
    originalId: 'whatsapp',
    width: 150,
  },
  {
    id: 'instagram_profile',
    description: 'INSTAGRAM',
    originalId: 'instagram_profile',
    width: 300,
  },
  {
    id: 'facebook_profile',
    description: 'FACEBOOK',
    originalId: 'facebook_profile',
    width: 300,
  },

  {
    id: 'formattedCreatedAt',
    description: 'DATA',
    originalId: 'formattedCreatedAt',
    width: 150,
    notSearchable: true,
  },
];
