import React from 'react';
import DialogInput from 'components/dialogs/DialogInput';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { useFinancialCard } from './hooks/useFinancialCard';
import { MenuItem, TextField } from '@mui/material';
import SelectBranch from 'components/select-branches/SelectBranch';

type FinancialCardFilterProps = {
  onExited(): void;
};

const FinancialCardFilter: React.FC<FinancialCardFilterProps> = ({ onExited }) => {
  const { filter, handleChangeFilter } = useFinancialCard();

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <div style={{ display: 'grid', gap: 10 }}>
        <SelectBranch branch_id={filter.branchId} handleChange={e => handleChangeFilter('branchId', e)} />

        <TextField
          fullWidth
          value={filter.typeDate}
          onChange={e => handleChangeFilter('typeDate', e.target.value)}
          select
          label="Tipo da data"
        >
          <MenuItem value={'V'}>Vencimento</MenuItem>
          <MenuItem value={'L'}>Lançamento</MenuItem>
        </TextField>
        <TextField
          fullWidth
          value={filter.situation}
          onChange={e => handleChangeFilter('situation', e.target.value)}
          select
          label="Situação"
        >
          <MenuItem value={'A'}>Apropriados</MenuItem>
          <MenuItem value={'N'}>Não apropriados</MenuItem>
          <MenuItem value={'T'}>Todos</MenuItem>
        </TextField>
        <KeyboardDatePicker
          label="Data inicial"
          value={filter.initialDate}
          onChange={date => handleChangeFilter('initialDate', date)}
          textFieldProps={{ fullWidth: true }}
        />
        <KeyboardDatePicker
          label="Data Final"
          value={filter.finalDate}
          onChange={date => handleChangeFilter('finalDate', date)}
          textFieldProps={{ fullWidth: true }}
        />
      </div>
    </DialogInput>
  );
};

export default FinancialCardFilter;
