import React from 'react';
import { Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { CreditLimitRequest } from 'types/creditLimitRequest';

type CreditLimitRequestItemTableProps = {
  creditLimitRequest: CreditLimitRequest;
};

const CreditLimitRequestItemTable: React.FC<CreditLimitRequestItemTableProps> = ({ creditLimitRequest }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id}>
            <Typography variant="body2">{creditLimitRequest[item.id]}</Typography>
          </div>
        ))}
    </>
  );
};

export default CreditLimitRequestItemTable;
