import React from 'react';
import { Profile } from 'types/profile';
import { ListItemButton, Typography, styled } from '@mui/material';
import history from 'services/history';

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
});

type ProfileItemProps = {
  profile: Profile;
};

const ProfileItem: React.FC<ProfileItemProps> = ({ profile }) => {
  return (
    <CustomListItemButton onClick={() => history.push(`/user-profile/${profile.id}`)}>
      <Typography variant="caption" color="primary">
        Perfil {profile.id}
      </Typography>
      <Typography variant="h6">{profile.name}</Typography>
    </CustomListItemButton>
  );
};

export default ProfileItem;
