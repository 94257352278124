import React, { MouseEvent } from 'react';
import { IconButton, Typography } from '@mui/material';
import { SegmentStockBalanceData } from 'types/reports/stockBalance';
import { segmentTableTemplate } from '../segmentTableTemplate';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useStockBalance } from '../../hooks/useStockBalance';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  quantityData: {
    display: 'flex',
    justifyContent: 'center',
  },
});

type SegmentItemTableProps = {
  segment: SegmentStockBalanceData;
};

const SegmentItemTable: React.FC<SegmentItemTableProps> = ({ segment }) => {
  const classes = styles();
  const { moreSegment, setMoreSegment } = useStockBalance();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setMoreSegment(moreSegment ? null : segment);
  }

  return (
    <>
      {segmentTableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'more' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={moreSegment?.id_segmento === segment.id_segmento ? { transform: 'rotate(180deg)' } : undefined}
              />
            </IconButton>
          ) : (
            <div
              key={item.id}
              className={
                item.dataType === 'number'
                  ? classes.numericData
                  : item.dataType === 'quantity'
                  ? classes.quantityData
                  : undefined
              }
            >
              <Typography style={{ fontSize: 11 }} variant="body2">
                {segment[item.id]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default SegmentItemTable;
