import { api } from 'services/api';
import { useEffect, useState } from 'react';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { Rental } from '../types/rental';
import { rentalInitialState } from '../reducer/reducer';

type UseFetchRental = [Rental, boolean];

export function useFetchRental(id?: string): UseFetchRental {
  const [loading, setLoading] = useState(true);
  const { showErrorDialog } = useErrorHandler();
  const [rental, setRental] = useState<Rental>(rentalInitialState);

  useEffect(() => {
    api
      .get(`/rentals/${id}`)
      .then(response => {
        setRental(response.data);
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível recuperar a locação',
        });
      })
      .finally(() => setLoading(false));
  }, [id, showErrorDialog]);

  return [rental, loading];
}
