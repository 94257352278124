import { IconButton, ListItem, Menu, MenuItem, Typography } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { MouseEvent, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import history from 'services/history';
import { ProductList } from 'types/product';
import { useProduct } from '../../productContext';

import { makeStyles } from '@mui/styles';

type ProductItemProps = {
  product: ProductList;
};

const useStyles = makeStyles({
  img: {
    width: '100%',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    minHeight: 120,
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& .data': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      '& > .product-name': {
        // maxWidth: '80%',
      },
    },
  },
  imageWrapper: {
    width: 100,
    height: 100,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    flexShrink: 0,
  },
  collapse: {
    display: 'flex',
    height: 0,
    transition: 'all 0.2s ease',
    opacity: 0,
    width: '100%',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      flex: 1,
    },
  },
  icon: {
    flexShrink: 0,
    transition: 'transform 0.2s ease',
    marginLeft: 10,
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  iconButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});

const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
  const classes = useStyles();
  const { handleSelectProduct, openImagePreview } = useProduct();
  const [collapse, setCollapse] = useState(false);
  const [selectedProduct, setSelectProduct] = useState<ProductList | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function handleClick(product: ProductList) {
    setCollapse(!collapse);
    handleSelectProduct(product);
  }

  function handleImageClick(e: MouseEvent<HTMLImageElement>, product: ProductList) {
    openImagePreview();
    handleSelectProduct(product);
    e.stopPropagation();
  }

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>, product: ProductList) {
    setSelectProduct(product);
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  }

  return (
    <>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => history.push(`/products/${selectedProduct?.codigo_produto}`)}>Editar</MenuItem>
      </Menu>
      <ListItem
        style={{ opacity: parseFloat(product.est) <= 0 ? 0.5 : 1 }}
        button
        className={classes.listItem}
        onClick={() => handleClick(product)}
      >
        <div className={classes.row}>
          <div className="data">
            <Typography color="primary" variant="caption" gutterBottom>
              {product.codigo_produto} | {product.subgrupo}
            </Typography>
            <Typography variant="body2" className="product-name">
              {product.produto}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {product.fabricante}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {product.grupo}
            </Typography>
          </div>
          <div className={classes.imageWrapper}>
            <img
              className={classes.img}
              src={product.foto_principal}
              alt="Foto produto"
              onClick={e => handleImageClick(e, product)}
            />
          </div>
          <FaChevronDown
            className={classes.icon}
            size={16}
            style={collapse ? { transform: 'rotate(180deg)' } : undefined}
            color="#888"
          />
        </div>
        <div className={classes.row}>
          <div className={classes.collapse} style={collapse ? { height: 150, opacity: 1 } : undefined}>
            <div className="content">
              <Typography variant="body2">Custo</Typography>
              <Typography variant="body2" color="secondary">
                {product.formattedCusto}
              </Typography>
              <Typography variant="body2">Margem</Typography>
              <Typography variant="body2" color="secondary">
                {product.formattedMargem}
              </Typography>
              <Typography variant="body2">P2PJ</Typography>
              <Typography variant="body2">{product.formattedP2PJ}</Typography>
              <Typography variant="body2">PromoPJ</Typography>
              <Typography variant="body2">{product.formattedPromoPJ}</Typography>
              <Typography variant="body2">MC - MC %</Typography>
              <Typography variant="body2">
                {product.formattedMC} - {product.formattedPercentualMC}
              </Typography>
              <Typography variant="body2">Estoque</Typography>
              <Typography variant="body2">{product.est}</Typography>
            </div>
          </div>
        </div>
        <IconButton className={classes.iconButton} onClick={e => handleMoreClick(e, product)}>
          <MoreVert />
        </IconButton>
      </ListItem>
    </>
  );
};

export default ProductItem;
