import React from 'react';
import { Typography, styled } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { SalesMethod } from 'types/salesReport';
import ModalityItemTable from './ModalityItemTable';
import { modalitiesTableTemplate } from '../modalitiesTableTemplate';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type ModalityListTableProps = {
  modalities: SalesMethod[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const ModalityListTable: React.FC<ModalityListTableProps> = ({ modalities, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <TableContainer tableTemplate={modalitiesTableTemplate}>
      <div>
        <TableContent useMinHeight={false}>
          <TableHeader>
            {modalitiesTableTemplate.map(item => (
              <HeaderItem
                className={item.dataType === 'number' ? 'numericData' : ''}
                key={item.id}
                onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </HeaderItem>
            ))}
          </TableHeader>
          <TableBody useMaxHeight={true}>
            {modalities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(modality => (
              <TableRow style={{ minHeight: 20, padding: 0 }} key={modality.modalidade}>
                <ModalityItemTable modality={modality} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
        <Pagination count={modalities.length} />
      </div>
    </TableContainer>
  );
};

export default ModalityListTable;
