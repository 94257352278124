import React from 'react';
import MoreItemTable from './MoreItemTable';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import { moneyFormat } from 'helpers/numberFormat';
import { OrderItems } from 'types/orderAuthorization';
import TableHeader from 'components/table/TableHeader';
import { MoreTableTemplate } from './moreTableTemplate';
import TableContent from 'components/table/TableContent';
import { Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  header: {
    background: '#eee',
    '& > div': {
      marginLeft: 80,
    },
  },
  headerItem: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
  headerItemQuantity: {
    justifyContent: 'center',
  },
  body: {
    '& > div': {
      marginLeft: 80,
      '& > div > div:nth-child(2n)': {
        background: '#eee',
      },
    },
  },
  content: {
    margin: 0,
  },
});

type MoreListTableProps = {
  items: OrderItems[];
  orderedIndex: OrderIndexData;
};

const MoreListTable: React.FC<MoreListTableProps> = ({ items, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();

  return (
    <>
      <TableContent>
        <div className={classes.header}>
          <TableHeader>
            {MoreTableTemplate.map(item => (
              <div
                className={
                  item.dataType === 'number'
                    ? `${classes.headerItem} ${classes.headerItemNumeric}`
                    : item.dataType === 'quantity'
                    ? `${classes.headerItem} ${classes.headerItemQuantity}`
                    : classes.headerItem
                }
                key={item.id}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
          </TableHeader>
        </div>
        <div className={classes.body}>
          <TableBody>
            {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
              <TableRow key={item.id_pedido}>
                <MoreItemTable
                  item={{
                    ...item,
                    formattedShipment: moneyFormat(item.valor_frete),
                    formattedTotal: moneyFormat(item.valor_total),
                    formattedUnitary: moneyFormat(item.valor_unitario),
                  }}
                />
              </TableRow>
            ))}
          </TableBody>
        </div>
      </TableContent>
    </>
  );
};

export default MoreListTable;
