import React, { createContext, FC, ReactNode, useMemo, useContext } from 'react';
import { useSelector } from 'store/selector';
import { Branch } from 'types/branch';

export interface BranchesContextValue {
  branches: Branch[];
  financialBranches: Branch[];
  accountingBranches: Branch[];
  userBranches: Branch[];
}

const BranchesContext = createContext<BranchesContextValue>({} as BranchesContextValue);

interface BranchesProviderProps {
  children: ReactNode;
  defaultBranches: Branch[];
  financialBranches: Branch[];
  accountingBranches: Branch[];
}

const BranchesProvider: FC<BranchesProviderProps> = ({
  children,
  accountingBranches,
  defaultBranches,
  financialBranches,
}) => {
  const user = useSelector(state => state.user);

  const userBranches = useMemo(() => {
    if (!user) {
      return [];
    }

    return defaultBranches.filter(branch => {
      return user.branchList.some(id => +id === branch.id);
    });
  }, [defaultBranches, user]);

  return (
    <BranchesContext.Provider
      value={{
        branches: defaultBranches,
        financialBranches,
        userBranches,
        accountingBranches,
      }}
    >
      {children}
    </BranchesContext.Provider>
  );
};

export function useBranches(): BranchesContextValue {
  return useContext(BranchesContext);
}

export default BranchesProvider;
