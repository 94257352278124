import React, { useState } from 'react';
import { Button } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import { CreditLimitRequest, CreditLimitRequestDocument } from 'types/creditLimitRequest';
import { CreditLimitRequestProvider } from '../hooks/useCreditLimitRequest';
import { useCreditLimitRequests } from '../hooks/useCreditLimitRequests';
import Tabs, { CreditLimitRequestTabs } from './Tabs';
import LimitRequest from './limit-request/LimitRequest';
import DocumentHistories from './document-histories/DocumentHistories';
import { useLoadCreditLimitRequests } from './useLoadCreditLimitRequests';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import ModalActions from './ModalActions';
import InsideSaving from 'components/loading/InsideSaving';
import ConfirmApprovement from './ConfirmApprovement';
import ConfirmRejection from './ConfirmRejection';
import OtherDocuments from './other-documents/OtherDocuments';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'space-around',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    borderTop: '1px solid #eee',
    backgroundColor: '#fff',
    height: 65,
    alignItems: 'center',
  },
  container: {
    margin: '30px 0',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 15px',
    flex: 1,
  },
});

interface CreditLimitRequestApprovalProps {
  onExited(): void;
}

const CreditLimitRequestApproval: React.FC<CreditLimitRequestApprovalProps> = ({ onExited }) => {
  const classes = useStyles();
  const { selectedCreditLimitRequest, handleUpdateList, handleRemoveFromList } = useCreditLimitRequests();
  const [tab, setTab] = useState<CreditLimitRequestTabs>('limitRequest');
  const [limitRequests] = useLoadCreditLimitRequests(selectedCreditLimitRequest);
  const [creditLimitRequest, setCreditLimitRequest] = useState<CreditLimitRequest>(
    JSON.parse(JSON.stringify(selectedCreditLimitRequest)),
  );
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);
  const [dialogApprove, setDialogApprove] = useState(false);
  const [dialogRejection, setDialogRejection] = useState(false);

  function handleChange(index: keyof CreditLimitRequest, value: any) {
    setCreditLimitRequest(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleAddDocument(document: CreditLimitRequestDocument) {
    setCreditLimitRequest(state => ({
      ...state,
      documents: [...state.documents, document],
    }));
  }

  function handleDeleteDocument(documentId: number) {
    setCreditLimitRequest(state => ({
      ...state,
      documents: state.documents.filter(document => document.id !== documentId),
    }));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/creditLimitRequests/${creditLimitRequest?.id}`, creditLimitRequest)
      .then(() => {
        messaging.handleOpen('Salvo');
        handleUpdateList(creditLimitRequest);
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleApprove(handleDialogClose: () => void) {
    setSaving(true);

    api
      .put(`/creditLimitRequests/${selectedCreditLimitRequest?.id}`, { status: 'approved' })
      .then(() => {
        if (!selectedCreditLimitRequest?.id) return;
        handleRemoveFromList(selectedCreditLimitRequest.id);
        handleDialogClose();
        messaging.handleOpen('Aprovado');
      })
      .catch(() => {
        messaging.handleOpen('Aconteceu um erro ao aprovar a solicitação de limite de crédito');
      })
      .finally(() => setSaving(false));
  }

  function handleReject(handleDialogClose: () => void) {
    setSaving(true);

    api
      .put(`/creditLimitRequests/${selectedCreditLimitRequest?.id}`, { status: 'rejected' })
      .then(() => {
        if (!selectedCreditLimitRequest?.id) return;
        handleRemoveFromList(selectedCreditLimitRequest.id);
        handleDialogClose();
        messaging.handleOpen('Rejeitado');
      })
      .catch(() => {
        messaging.handleOpen('Aconteceu um erro ao rejeitar a solicitação de limite de crédito');
      })
      .finally(() => setSaving(false));
  }

  return (
    <CreditLimitRequestProvider
      value={{ handleChange, creditLimitRequest, limitRequests, handleAddDocument, handleDeleteDocument }}
    >
      <Dialog
        title="aprovação de crédito"
        onExited={onExited}
        maxWidth="md"
        ComponentActions={<ModalActions handleSubmit={handleSubmit} />}
      >
        {saving && <InsideSaving />}

        {dialogRejection && <ConfirmRejection onExited={() => setDialogRejection(false)} handleReject={handleReject} />}

        {dialogApprove && <ConfirmApprovement onExited={() => setDialogApprove(false)} handleApprove={handleApprove} />}

        <Tabs tab={tab} handleChange={tab => setTab(tab)} />

        <div className={classes.container}>
          {tab === 'limitRequest' ? (
            <LimitRequest />
          ) : tab === 'socialContract' ? (
            <DocumentHistories index="social_contract" />
          ) : tab === 'annualBalance' ? (
            <DocumentHistories index="annual_balance" />
          ) : tab === 'quarterlyBalance' ? (
            <DocumentHistories index="quarterly_balance" />
          ) : tab === 'twelveMonthsRevenue' ? (
            <DocumentHistories index="twelve_months_revenue" />
          ) : (
            tab === 'otherDocuments' && <OtherDocuments />
          )}
        </div>

        {creditLimitRequest?.status === 'completed' && (
          <div className={classes.actions}>
            <Button variant="text" color="primary" onClick={() => setDialogRejection(true)}>
              Rejeitar
            </Button>
            <Button variant="contained" color="primary" onClick={() => setDialogApprove(true)}>
              Aprovar
            </Button>
          </div>
        )}
      </Dialog>
    </CreditLimitRequestProvider>
  );
};

export default CreditLimitRequestApproval;
