import React from 'react';
import { MonthlyCategory } from 'types/monthlyComparison';
import { Typography, ListItem } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type CategoryItemModuleProps = {
  category: MonthlyCategory;
};

const CategoryItemModule: React.FC<CategoryItemModuleProps> = ({ category }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem}>
      <Typography variant="caption" color="primary">
        {category.id}
      </Typography>
      <Typography variant="caption" color="primary">
        {category.categoria}
      </Typography>
    </ListItem>
  );
};

export default CategoryItemModule;
