import React from 'react';
import { useApp } from 'hooks/app';
import { Tab, Tabs } from '@mui/material';

export type ProjectTabValues =
  | 'info'
  | 'status'
  | 'shipment'
  | 'documents'
  | 'observation'
  | 'products'
  | 'contacts'
  | 'costs'
  | 'actions';

type ProjectTabProps = {
  handleChange(value: ProjectTabValues): void;
  tab: ProjectTabValues;
};

const ProjectTab: React.FC<ProjectTabProps> = ({ tab, handleChange }) => {
  const app = useApp();

  return (
    <Tabs value={tab} onChange={(e, value) => handleChange(value)} variant={app.isMobile ? 'scrollable' : 'standard'}>
      <Tab label="Principal" value="info" />

      <Tab label="Produtos" value="products" />

      <Tab label="Endereço" value="shipment" />

      <Tab label="Anexos" value="documents" />

      <Tab label="Custos" value="costs" />

      <Tab label="Contatos" value="contacts" />

      <Tab label="Ações" value="actions" />

      <Tab label="Observação" value="observation" />
    </Tabs>
  );
};

export default ProjectTab;
