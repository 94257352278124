import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Typography, Theme } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import { AbcProductUnity } from 'types/abcProduct';

import { makeStyles } from '@mui/styles';
import { externalTooltipHandler } from 'components/chart/externalTooltip';

interface CategoryChartProps {
  formattedTotalChart: AbcProductUnity[];
}

const useStyles = makeStyles<Theme>(theme => ({
  containerChart: {
    gap: 30,
    display: 'grid',
    padding: '15px 0',
    position: 'relative',
    gridTemplateColumns: '300px 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      display: 'none',
    },
  },
  contentChart: {},
  legend: {
    display: 'flex',
    flexDirection: 'column',
  },
  total: {
    color: '#008000',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const CategoryChart: React.FC<CategoryChartProps> = ({ formattedTotalChart }) => {
  const classes = useStyles();
  const pieData = useMemo(() => {
    return formattedTotalChart.sort((a, b) => b.total - a.total).map(item => item.total);
  }, [formattedTotalChart]);

  return (
    <div className={classes.containerChart}>
      <div className={classes.legend}>
        <Typography variant="caption">
          <b>Legenda</b>
        </Typography>
        {formattedTotalChart
          .sort((a, b) => b.total - a.total)
          .map(item => (
            <Typography className={classes.item} variant="subtitle2" key={item.cod}>
              {item.unidade}: <b className={classes.total}>{item.formattedTotal}</b>
            </Typography>
          ))}
      </div>
      <div className={classes.contentChart}>
        <Bar
          data={{
            labels: formattedTotalChart.sort((a, b) => b.total - a.total).map(item => item.unidade),
            datasets: [
              {
                label: 'Unidades',
                data: formattedTotalChart.sort((a, b) => b.total - a.total).map(item => item.total),
                stack: 'combined',
                backgroundColor: '#30519f',
                borderWidth: 1,
              },
            ],
          }}
          options={{
            plugins: {
              tooltip: {
                enabled: false,
                callbacks: {
                  label: tooltip => moneyFormat(pieData[tooltip.dataIndex as number]),
                },
                external: externalTooltipHandler,
              },
            },
            responsive: true,
          }}
        />
      </div>
    </div>
  );
};

export default CategoryChart;
