import React from 'react';
import { ArrowBackIos, ArrowForwardIos, Search } from '@mui/icons-material';
import { IconButton, Typography, styled } from '@mui/material';
import { addDays, format, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const Header = styled('div')({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
  justifyContent: 'center',
});

const Divider = styled('div')({
  width: '100%',
  height: 2,
  flex: 1,
  backgroundColor: '#ddd',
});

const RangeDateContainer = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
});

const RangeDate = styled('div')(({ theme }) => ({
  flex: 1,
  padding: '0px 40px',
  display: 'grid',
  justifyContent: 'center',
  alignItems: 'center',
  gridTemplateColumns: '100px 100px 100px 100px 100px 100px 100px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexWrap: 'nowrap',
    maxWidth: 'calc(100vw - 50px)',
  },
  '& > div:not(:last-child)': {
    borderRight: '1px solid #ddd',
  },
}));

const CustomIconButton = styled(IconButton)(props => ({
  backgroundColor: '#fff',
  position: 'absolute',
  top: 0,
  bottom: 0,
  '&.left': {
    left: 10,
  },
  '&.right': {
    right: 10,
  },
}));

const DatePicker = styled('div')(props => ({
  flexGrow: 1,
  minWidth: 100,
  width: 100,
  height: 70,
  display: 'flex',
  padding: '5px 15px',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& > .actual': {
    color: props.theme.palette.secondary.main,
  },
}));

interface CalendarPickerProps {
  onChangeRange(value: { startDate: Date; endDate: Date }): void;
  currentDate: Date;
  onChange(value: Date): void;
  lastThreeDays: Date[];
  onChangeLastThreeDays(value: Date[]): void;
  nextThreeDays: Date[];
  onChangeNextThreeDays(value: Date[]): void;
}

const CalendarPicker: React.FC<CalendarPickerProps> = ({
  currentDate,
  onChange,
  lastThreeDays,
  onChangeLastThreeDays,
  nextThreeDays,
  onChangeNextThreeDays,
  onChangeRange,
}) => {
  const date = new Date();

  function handleNextWeek() {
    const _currDate = addDays(currentDate, 1);
    onChange(_currDate);
    onChangeRange({
      endDate: addDays(_currDate, 3),
      startDate: subDays(_currDate, 3),
    });
    onChangeLastThreeDays(Array.from({ length: 3 }, (_, index) => subDays(_currDate, index + 1)).reverse());
    onChangeNextThreeDays(Array.from({ length: 3 }, (_, index) => addDays(_currDate, index + 1)));
  }

  function handleBackWeek() {
    const _currDate = subDays(currentDate, 1);
    onChange(_currDate);
    onChangeRange({
      endDate: addDays(_currDate, 3),
      startDate: subDays(_currDate, 3),
    });
    onChangeLastThreeDays(Array.from({ length: 3 }, (_, index) => subDays(_currDate, index + 1)).reverse());
    onChangeNextThreeDays(Array.from({ length: 3 }, (_, index) => addDays(_currDate, index + 1)));
  }

  return (
    <div>
      <Header>
        <Divider />

        <Typography fontSize={20} variant="button" color="secondary">
          {format(currentDate, 'MMMM', { locale: ptBR })} {format(currentDate, 'yyyy', { locale: ptBR })}
        </Typography>

        <Search color="secondary" fontSize="medium" />

        <Divider className="right" />
      </Header>

      <RangeDateContainer>
        <CustomIconButton className="left" onClick={handleBackWeek}>
          <ArrowBackIos />
        </CustomIconButton>

        <RangeDate>
          {lastThreeDays.map((_date, index) => (
            <DatePicker key={index}>
              <Typography
                className={format(date, 'P') === format(_date, 'P') ? 'actual' : ''}
                variant="h6"
                fontSize={22}
              >
                {format(_date, 'dd', { locale: ptBR })}
              </Typography>
              <Typography className={format(date, 'P') === format(_date, 'P') ? 'actual' : ''} fontSize={14}>
                {format(_date, 'EEEE', { locale: ptBR }).slice(0, 3).toUpperCase()}
              </Typography>
            </DatePicker>
          ))}

          <DatePicker>
            <Typography
              className={format(date, 'P') === format(currentDate, 'P') ? 'actual' : ''}
              variant="h6"
              fontSize={22}
            >
              {format(currentDate, 'dd', { locale: ptBR })}
            </Typography>
            <Typography className={format(date, 'P') === format(currentDate, 'P') ? 'actual' : ''} fontSize={14}>
              {format(currentDate, 'EEEE', { locale: ptBR }).slice(0, 3).toUpperCase()}
            </Typography>
          </DatePicker>

          {nextThreeDays.map((_date, index) => (
            <DatePicker key={index}>
              <Typography
                className={format(date, 'P') === format(_date, 'P') ? 'actual' : ''}
                variant="h6"
                fontSize={20}
              >
                {format(_date, 'dd', { locale: ptBR })}
              </Typography>
              <Typography className={format(date, 'P') === format(_date, 'P') ? 'actual' : ''} fontSize={14}>
                {format(_date, 'EEEE', { locale: ptBR }).slice(0, 3).toUpperCase()}
              </Typography>
            </DatePicker>
          ))}
        </RangeDate>

        <CustomIconButton className="right" onClick={handleNextWeek}>
          <ArrowForwardIos />
        </CustomIconButton>
      </RangeDateContainer>
    </div>
  );
};

export default CalendarPicker;
