import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, ListItem, IconButton } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { Agent } from 'types/agent';
import { useAgents } from 'pages/agents/hooks/useAgents';

import { makeStyles } from '@mui/styles';

type AgentItemModuleProps = {
  agent: Agent;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

const AgentItemModule: React.FC<AgentItemModuleProps> = ({ agent, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedAgent } = useAgents();

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedAgent(agent);
  }

  return (
    <ListItem button className={classes.listItem}>
      <Typography variant="caption" color="primary">
        CPF {agent.cpf}
      </Typography>
      <Typography>{agent.nome}</Typography>
      <IconButton size="small" onClick={e => handleMoreClick(e)} className={classes.buttonMore}>
        <MoreHoriz />
      </IconButton>
    </ListItem>
  );
};

export default AgentItemModule;
