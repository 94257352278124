import React, { useState, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import { Justification } from 'types/justification';
import PaginationProvider from 'hooks/pagination';
import NoData from 'components/no-data/NoData';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import TableContainer from 'components/table/TableContainer';
import JustificationActions from './actions/JustificationActions';
import JustificationFilterMobile from './JustificationFilterMobile';
import JustificationFilterPage from './JustificationFilter';
import { justificationActionTableTemplate, justificationTableTemplate } from './justificationTableTemplate';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { Button, styled } from '@mui/material';
import history from 'services/history';
import { useFetchJustifications } from './hooks/useFetchJustifications';
import { useDisplayMode } from 'hooks/useDisplayMode';
import JustificationListTable from './list/table/JustificationListTable';
import useTableOrder from 'hooks/tableOrder';
import Pagination from 'components/pagination/Pagination';
import JustificationListModule from './list/module/JustificationListModule';
import { JustificationsProvider } from './hooks/useJustifications';
import { usePermissionRules } from 'hooks/permissionRules';
import { useSelector } from 'store/selector';
import { useMessaging } from 'hooks/messaging';
import JustificationDialog from './dialog/JustificationDialog';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const JustificationPage: React.FC = () => {
  const { fetch, loading, queryParams, handleChangeQueryParams, justifications, setLoading } = useFetchJustifications();
  const [filtered, setFiltered] = useState<Justification[]>([]);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const user = useSelector(state => state.user);
  const { checkPermission } = usePermissionRules();
  const { handleOpen } = useMessaging();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const [selectedJustification, setSelectedJustification] = useState<Justification | null>(null);

  useEffect(() => {
    setFiltered(justifications);
  }, [justifications]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function onSearch() {
    setAnchorEl(null);
    fetch();
  }

  function handleClick() {
    if (!user) {
      handleOpen('Faça o login novamente');
      return;
    }

    if (!user.document_number) {
      handleOpen('Acione o suporte para cadastrar um CPF em sua conta');
      return;
    }

    if (user.document_number === '00000000000') {
      handleOpen('Acione o suporte para cadastrar um CPF válido em sua conta');
      return;
    }

    history.push('/rh/justification/new');
  }

  return (
    <JustificationsProvider
      value={{ selectedJustification, setSelectedJustification, handleSearch: fetch, setLoading }}
    >
      <Appbar
        title="Justificativas"
        ActionsComponent={
          <JustificationActions handleClick={handleClick} loading={loading} setAnchorEl={setAnchorEl} />
        }
      />
      <PageHeaderActions
        title="Justificativas"
        description="Gestão das justificativas"
        ActionComponent={
          <Button onClick={handleClick} variant="contained">
            Novo
          </Button>
        }
      />

      {selectedJustification && (
        <JustificationDialog
          onExited={() => setSelectedJustification(null)}
          selectedJustification={selectedJustification}
        />
      )}

      {anchorEl && (
        <JustificationFilterMobile
          filter={queryParams}
          handleChange={handleChangeQueryParams}
          onExited={() => setAnchorEl(null)}
          onSearch={onSearch}
        />
      )}

      <JustificationFilterPage
        displayMode={displayMode}
        setDisplayMode={setDisplayMode}
        filter={queryParams}
        handleChange={handleChangeQueryParams}
        handleSearch={fetch}
        loading={loading}
      />

      <TableContainer
        tableTemplate={
          checkPermission('rh.justification.allow-query')
            ? justificationActionTableTemplate
            : justificationTableTemplate
        }
      >
        <PaginationProvider>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhum funcionário para mostrar" />
          ) : (
            <Container>
              {displayMode === 'list' ? (
                <JustificationListTable justifications={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <JustificationListModule justifications={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          )}
        </PaginationProvider>
      </TableContainer>
    </JustificationsProvider>
  );
};

export default JustificationPage;
