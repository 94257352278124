import { useCreditLimitRequest } from '../../hooks/useCreditLimitRequest';
import React from 'react';
import UploadLink from '../UploadLink';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 100px',
    marginBottom: 15,
  },
});

interface CreditLimitRequestDocumentProps {
  description: string;
  index: 'social_contract' | 'annual_balance' | 'quarterly_balance' | 'twelve_months_revenue' | 'guarantee_letter';
}

const CreditLimitRequestDocument: React.FC<CreditLimitRequestDocumentProps> = ({ description, index }) => {
  const classes = styles();
  const { handleChange, creditLimitRequest } = useCreditLimitRequest();

  return (
    <>
      {creditLimitRequest && (
        <div className={classes.container}>
          {creditLimitRequest[index] ? (
            <a target="_blank" rel="noopener noreferrer" href={creditLimitRequest[index]?.document_url}>
              {description}
            </a>
          ) : (
            <span>{description}</span>
          )}
          <UploadLink
            document={creditLimitRequest[index]}
            handleSetDocument={document => handleChange(index, document)}
          />
        </div>
      )}
    </>
  );
};

export default CreditLimitRequestDocument;
