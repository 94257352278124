import { createContext, useContext } from 'react';
import { Branch } from 'types/branch';
import { Email } from 'types/email';
import { Segment } from 'types/segment';

export interface ExportEmailContextValue {
  branches: Branch[];
  segments: Segment[];
  filtered: Email[];
}

const ExportEmailContext = createContext<ExportEmailContextValue>({} as ExportEmailContextValue);
export const ExportEmailProvider = ExportEmailContext.Provider;
export const ExportEmailConsumer = ExportEmailContext.Consumer;

export function useExportEmail(): ExportEmailContextValue {
  const context = useContext(ExportEmailContext);
  return context;
}
