import { Typography, styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import React from 'react';

const Container = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  justifyContent: 'center',
  gridTemplateColumns: 'repeat(4, 1fr)',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 200px)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(2, 150px)',
  },
}));

const Box = styled('div')(({ theme }) => ({
  '&.blue': {
    backgroundColor: theme.palette.primary.light,
  },
  '&.green': {
    backgroundColor: theme.palette.success.light,
  },
  '&.red': {
    backgroundColor: theme.palette.error.light,
  },
  '&.yellow': {
    backgroundColor: theme.palette.warning.light,
  },
  textAlign: 'center',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}));

const SellerDashboardHeader: React.FC = () => {
  return (
    <Container>
      <div>
        <Typography align="center" variant="h6" fontSize={16}>
          Meta do mês
        </Typography>
        <Box className="blue">
          <Typography fontWeight={600} color="#fff">
            {moneyFormat(0)}
          </Typography>
        </Box>
      </div>

      <div>
        <Typography align="center" variant="h6" fontSize={16}>
          Valor vendido
        </Typography>
        <Box className="yellow">
          <Typography fontWeight={600} color="#fff">
            {moneyFormat(0)}
          </Typography>
        </Box>
      </div>

      <div>
        <Typography align="center" variant="h6" fontSize={16}>
          C. fin
        </Typography>
        <Box className="green">
          <Typography fontWeight={600} color="#fff">
            {moneyFormat(0)}
          </Typography>
        </Box>
      </div>

      <div>
        <Typography align="center" variant="h6" fontSize={16}>
          Saldo meta
        </Typography>
        <Box className="red">
          <Typography fontWeight={600} color="#fff">
            {moneyFormat(0)}
          </Typography>
        </Box>
      </div>
    </Container>
  );
};

export default SellerDashboardHeader;
