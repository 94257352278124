import { TableTemplate } from 'types/tableTemplate';

export const appropriationDateTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'document_number',
    width: 40,
  },
  {
    id: 'data',
    description: 'Data',
    originalId: 'data',
    width: 100,
  },
];
