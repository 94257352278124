import React, { MouseEvent } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import history from 'services/history';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    flexDirection: 'column',
    textAlign: 'center',
  },
  action: {
    marginTop: 20,
  },
});

const Error404: React.FC = () => {
  const classes = useStyles();

  function handleBack(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    history.back();
  }

  return (
    <div className={classes.container}>
      <Typography variant="h4" color="textSecondary">
        Pagína não encontrada
      </Typography>
      <div className={classes.action}>
        <Link onClick={handleBack} to="#">
          voltar
        </Link>
      </div>
    </div>
  );
};

export default Error404;
