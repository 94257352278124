import React from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { BranchPayment } from 'types/paymentFlow';
import NoData from 'components/no-data/NoData';
import { eachDayOfInterval, parse, format } from 'date-fns';
import { moneyFormat } from 'helpers/numberFormat';

const Container = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
});

const Row = styled('li')({
  width: 100,
  minWidth: 100,
  minHeight: 30,
  display: 'flex',
  flexDirection: 'column',
});

const List = styled('ul')(({ theme }) => ({
  flex: 1,
  padding: '0px 40px',
  display: 'grid',
  justifyContent: 'center',
  alignItems: 'center',
  gridTemplateColumns: '100px 100px 100px 100px 100px 100px 100px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexWrap: 'nowrap',
    maxWidth: 'calc(100vw - 50px)',
  },
  '& > li:not(:last-child)': {
    borderRight: '1px solid #ddd',
  },
}));

interface TopBranchesPaymentsProps {
  data: BranchPayment[];
  startDate: Date;
  lastDate: Date;
}

const TopBranchesPayments: React.FC<TopBranchesPaymentsProps> = ({ data: payments, lastDate, startDate }) => {
  const generateDateRange = (startDate: Date, endDate: Date): string[] => {
    const dates = eachDayOfInterval({ start: startDate, end: endDate });
    return dates.map(date => format(date, 'dd/MM/yyyy'));
  };

  const groupByDateWithFiliais = (
    invoices: BranchPayment[],
    startDate: Date,
    endDate: Date,
  ): { data: string; registros: { valor: number; filial: string }[] }[] => {
    const grouped: { [key: string]: { valor: number; filial: string }[] } = {};

    invoices.forEach(invoice => {
      const formattedDate = invoice.vencimento;

      if (!grouped[formattedDate]) {
        grouped[formattedDate] = [];
      }

      grouped[formattedDate].push({ valor: invoice.valor, filial: invoice.filial });
    });

    const dateRange = generateDateRange(startDate, endDate);

    dateRange.forEach(date => {
      if (!grouped[date]) {
        grouped[date] = [];
      }
    });

    const groupedData = Object.keys(grouped).map(data => ({
      data,
      registros: grouped[data],
    }));

    // Ordenar por data crescente
    groupedData.sort((a, b) => {
      const dateA = parse(a.data, 'dd/MM/yyyy', new Date());
      const dateB = parse(b.data, 'dd/MM/yyyy', new Date());
      return dateA.getTime() - dateB.getTime();
    });

    return groupedData;
  };

  const groupedData = groupByDateWithFiliais(payments, startDate, lastDate);
  console.log(groupedData);

  return (
    <Container>
      {groupedData.length > 0 ? (
        <List>
          {groupedData.map((item, index) => (
            <Row className="content" key={index}>
              {item.registros.map(register => (
                <Tooltip key={index + 3} title={`${register.filial} - ${item.data}`}>
                  <Typography fontWeight={600} align="center" color="secondary" variant="caption">
                    {moneyFormat(register.valor)}
                  </Typography>
                </Tooltip>
              ))}
            </Row>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
    </Container>
  );
};

export default TopBranchesPayments;
