import { CourseStudent } from '../../types/courseStudent';
import { CourseStudentActions } from './types';

export function changeCourseStudentAction(index: keyof CourseStudent, value: any): CourseStudentActions {
  return {
    type: 'change_course_student',
    index,
    value,
  };
}

export function setCourseStudentAction(courseStudent: CourseStudent): CourseStudentActions {
  return {
    type: 'set_course_student',
    courseStudent,
  };
}
