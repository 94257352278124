import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';
import { User } from '../reducer/types';
import { cpfValidator } from 'helpers/cpfValidator';
import { api } from 'services/api';

export type UserValidation = {
  username?: string;
  email?: string;
  name?: string;
  document_number?: string;
  password?: string;
  password_confirmation?: string;
};

type UseUserValidation = [UserValidation, Dispatch<SetStateAction<UserValidation>>, (user: User) => Promise<void>];

export function useUserValidation(type: 'edit' | 'new'): UseUserValidation {
  async function handleValidation(user: User) {
    const schema = yup.object().shape({
      password_confirmation: yup.string().oneOf([yup.ref('password'), undefined], 'Confirmação da senha incorreta'),
      password: type === 'edit' ? yup.string().nullable() : yup.string().required('A senha é obrigatória'),
      document_number: yup
        .string()
        .required('CPF é obrigatório')
        .transform((value, originalValue) => {
          return originalValue ? originalValue.replace(/\D/g, '') : '';
        })
        .test('docValidation', `CPF inválido`, value => {
          if (!value) return false;

          const cpf = cpfValidator(value);

          return cpf;
        }),
      name: yup.string().required('O nome é obrigatório'),
      email: yup.string().email('Informe um e-mail válido').required('Informe o email'),
      username: yup
        .string()
        .required('O nome é obrigatório')
        .test('usernameVerification', 'Nome de usuário não disponível', async value => {
          if (type === 'edit') {
            return true;
          }

          const response = await api.get(`/users/username/${value}`);

          if (response.data) {
            return false;
          }

          return true;
        }),
    });

    try {
      await schema.validate(user);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<UserValidation>({});
  return [validation, setValidation, handleValidation];
}
