import React, { Dispatch, SetStateAction, MouseEvent } from 'react';
import { Typography, ListItemButton, styled, IconButton } from '@mui/material';
import { Compatibility } from 'types/compatibility';
import history from 'services/history';
import { MoreVert } from '@mui/icons-material';
import { useCompatibilities } from 'pages/compatibilities/hooks/useCompatibilities';

const ListItemButtonStyled = styled(ListItemButton)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '80%',
    alignItems: 'center',
  },
  '& .project-id': {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  '& .description': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    lineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  '& .icon-button': {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});

type CompatibilityItemModuleProps = {
  compatibility: Compatibility;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const CompatibilityItemModule: React.FC<CompatibilityItemModuleProps> = ({ compatibility, setAnchorEl }) => {
  const { setSelectedCompatibility } = useCompatibilities();
  function handleClick() {
    history.push(`/compatibilities/${compatibility.id}`);
  }

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    setSelectedCompatibility(compatibility);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  }

  return (
    <ListItemButtonStyled onClick={handleClick}>
      <Typography variant="caption" color="primary" gutterBottom>
        Compatibilidade {compatibility.id}
      </Typography>
      <Typography>{compatibility.description}</Typography>
      <IconButton className="icon-button" onClick={handleMoreClick}>
        <MoreVert />
      </IconButton>
    </ListItemButtonStyled>
  );
};

export default CompatibilityItemModule;
