import React from 'react';
import { Typography, Grid } from '@mui/material';
import SegmentItemTable from './SegmentItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import { SegmentStockBalanceData } from 'types/reports/stockBalance';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { segmentTableTemplate } from '../segmentTableTemplate';
import { useStockBalance } from '../../hooks/useStockBalance';
import SegmentMoreMenu from '../SegmentMoreMenu';

import { makeStyles } from '@mui/styles';

type SegmentListTableProps = {
  segments: SegmentStockBalanceData[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
    marginRight: 10,
  },
  headerItemQuantity: {
    justifyContent: 'center',
  },
  body: {
    maxHeight: 'calc(100vh - 100px)',
  },
});

const SegmentListTable: React.FC<SegmentListTableProps> = ({ segments, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { moreSegment } = useStockBalance();

  return (
    <Grid container>
      <TableContainer tableTemplate={segmentTableTemplate}>
        <TableContent>
          <TableHeader>
            {segmentTableTemplate.map(item => (
              <div
                className={
                  item.dataType === 'number'
                    ? `${classes.headerItem} ${classes.headerItemNumeric}`
                    : item.dataType === 'quantity'
                    ? `${classes.headerItem} ${classes.headerItemQuantity}`
                    : classes.headerItem
                }
                key={item.id}
                onClick={() => handleSort(item.originalId)}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
          </TableHeader>
          <TableBody className={classes.body}>
            {segments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((segment, index) => (
              <>
                <TableRow style={{ minHeight: 10 }} key={index}>
                  <SegmentItemTable segment={segment} />
                </TableRow>
                {moreSegment?.id_segmento === segment.id_segmento && <SegmentMoreMenu items={segment.itens} />}
              </>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </Grid>
  );
};

export default SegmentListTable;
