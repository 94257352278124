import React, { useState, FormEvent } from 'react';

import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import { CreditAnalysisQuestion } from 'types/creditAnalysisQuestion';
import { CreditAnalysisQuestionProvider } from '../../hooks/useCreditAnalysisQuestion';
import CreditAnalysisQuestionForm from '../CreditAnalysisQuestionForm';
import { useCreditAnalysisQuestionValidation } from '../validation/creditAnalysisQuestionValidation';
import CreditAnalysisQuestionActions from './CreditAnalysisQuestionActions';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  form: {
    maxWidth: 400,
  },
});

const initialState: CreditAnalysisQuestion = {
  created_at: '',
  forward_on_answer_no: false,
  id: 0,
  order: 0,
  question_text: '',
  text_answer_no: '',
};

const CreditAnalysisCredit: React.FC = () => {
  const classes = styles();
  const [question, setQuestion] = useState<CreditAnalysisQuestion>(initialState);
  const [saving, setSaving] = useState(false);
  const [validation, setValidation, validate] = useCreditAnalysisQuestionValidation();
  const messaging = useMessaging();

  function handleChange(index: keyof CreditAnalysisQuestion, value: any) {
    setQuestion(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleValidation(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    setValidation({});

    validate(question)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/credit-analysis-questions', question)
      .then(() => {
        messaging.handleOpen('Salvo');
        history.push('/credit-analysis-questions');
        setSaving(false);
      })
      .catch(err => {
        console.error(err);
        setSaving(false);
        messaging.handleOpen('Não foi possível salvar');
      });
  }

  return (
    <CreditAnalysisQuestionProvider value={{ question, handleChange }}>
      {saving && <Loading />}
      <Appbar
        title="Nova questão"
        ActionsComponent={<CreditAnalysisQuestionActions handleValidation={handleValidation} />}
      />
      <PageHeader title="Nova questão" />
      <form className={classes.form} onSubmit={handleValidation}>
        <CreditAnalysisQuestionForm validation={validation} />
      </form>
    </CreditAnalysisQuestionProvider>
  );
};

export default CreditAnalysisCredit;
