import React from 'react';
import { useApp } from 'hooks/app';
import { Tab, Tabs } from '@mui/material';

export type CompatibilityTabValues = 'main' | 'products';

type CompatibilityTabProps = {
  handleChange(value: CompatibilityTabValues): void;
  tab: CompatibilityTabValues;
};

const CompatibilityTab: React.FC<CompatibilityTabProps> = ({ tab, handleChange }) => {
  const app = useApp();

  return (
    <Tabs value={tab} onChange={(e, value) => handleChange(value)} variant={app.isMobile ? 'scrollable' : 'standard'}>
      <Tab label="Principal" value="main" />
      <Tab label="Produtos" value="products" />
    </Tabs>
  );
};

export default CompatibilityTab;
