import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { ProjectProduct } from 'types/project';

export type ProductContextValue = {
  selectedProduct: ProjectProduct | null;
  setSelectedProduct: Dispatch<SetStateAction<ProjectProduct | null>>;
};

export const ProductsContext = createContext<ProductContextValue>({} as ProductContextValue);
export const ProductsContextConsumer = ProductsContext.Consumer;
export const ProductsContextProvider = ProductsContext.Provider;

export function useProjectProducts(): ProductContextValue {
  return useContext(ProductsContext);
}
