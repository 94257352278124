import { createContext, useContext } from 'react';
import { CreditAnalysisQuestion } from 'types/creditAnalysisQuestion';

type CreditAnalysisQuestionContextValue = {
  question: CreditAnalysisQuestion;
  handleChange(index: keyof CreditAnalysisQuestion, value: any): void;
};

const CreditAnalysisQuestionContext = createContext<CreditAnalysisQuestionContextValue>(
  {} as CreditAnalysisQuestionContextValue,
);
export const CreditAnalysisQuestionProvider = CreditAnalysisQuestionContext.Provider;

export function useCreditAnalysisQuestion(): CreditAnalysisQuestionContextValue {
  const context = useContext(CreditAnalysisQuestionContext);
  return context;
}
