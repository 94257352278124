import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import PrintData from 'components/print/PrintData';
import { styled } from '@mui/material';
import TotalizingAccountReportHeader from './TotalizingAccountReportHeader';
import { useTotalizingAccounts } from '../hooks/useTotalizingAccount';

const ColumnPL = styled('p')({
  fontSize: '10pt',
  textAlign: 'start',
});

const Row = styled('div')({
  display: 'grid',
  gridTemplateColumns: '50px 1fr 1fr 130px 1fr',
  marginBottom: 1,
  paddingBottom: 1,
});

interface TotalizingAccountReportProps {
  onExited(): void;
}

const TotalizingAccountReport: React.FC<TotalizingAccountReportProps> = ({ onExited }) => {
  const { accounts: data } = useTotalizingAccounts();

  return (
    <Dialog fullScreen title="Conta totalizadora" onExited={onExited} ComponentActions={<PrintData />}>
      <div className="page">
        <TotalizingAccountReportHeader />
        <Row className="table-header">
          <ColumnPL>ID</ColumnPL>
          <ColumnPL>Filial</ColumnPL>
          <ColumnPL>Nome</ColumnPL>
          <ColumnPL>Saldo</ColumnPL>
        </Row>

        {data.map(item => (
          <Row key={item.id}>
            <ColumnPL>{item.id}</ColumnPL>
            <ColumnPL>{item.filial}</ColumnPL>
            <ColumnPL>{item.nome}</ColumnPL>
            <ColumnPL>{item.formattedSaldo}</ColumnPL>
          </Row>
        ))}
      </div>
    </Dialog>
  );
};

export default TotalizingAccountReport;
