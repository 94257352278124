import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useRelatedProduct } from 'pages/related-product/registration/hook/useRelatedProduct';
import { useRelatedProductItems } from '../hooks/useProducts';

type ProjectProductsListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const ProjectProductsListMenu: React.FC<ProjectProductsListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedProduct } = useRelatedProductItems();
  const { setRelatedProduct } = useRelatedProduct();

  function handleDeleteClick() {
    console.log(selectedProduct);

    if (!selectedProduct) {
      return;
    }

    setRelatedProduct(state => ({
      ...state,
      products: state.products.filter(product => product.id !== selectedProduct.id),
    }));

    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default ProjectProductsListMenu;
