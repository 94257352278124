import React from 'react';
import { FinancialCard } from 'types/financialReportList';
import { usePagination } from 'hooks/pagination';

import { List } from '@mui/material';
import CardItemModule from './CardItemModule';

type CardListModuleProps = {
  cards: FinancialCard[];
};

const CardListModule: React.FC<CardListModuleProps> = ({ cards }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <List>
      {cards.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(card => (
        <CardItemModule key={card.Autorizacao} card={card} />
      ))}
    </List>
  );
};

export default CardListModule;
