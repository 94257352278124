import React from 'react';
import ProductItem from './ProductItemModule';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { RelatedProductItem } from 'pages/related-product/types/relatedProductItem';

type ProductListModuleProps = {
  products: RelatedProductItem[];
};

const ProductListModule: React.FC<ProductListModuleProps> = ({ products }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {products.map(product => (
            <ProductItem key={product.id} product={product} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ProductListModule;
