import React from 'react';
import ModuleLoading from './ModuleLoading';
import { Typography, styled } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
  padding: 15,
  marginTop: 15,
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  position: 'relative',
  border: '2px solid #eee',
  borderRadius: '8px 8px 0 0',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

const CustomTypography = styled(Typography)({
  top: -10,
  left: 15,
  position: 'absolute',
  backgroundColor: '#fafafa',
});

const Row = styled('div')({
  height: 35,
  marginBottom: 7,
});

const ContainerLoading = styled('div')({
  marginTop: 10,
  backgroundColor: '#fff',
  flex: 1,
});

interface ReportLoadingProps {
  displayMode: string;
}

const ReportLoading: React.FC<ReportLoadingProps> = ({ displayMode }) => {
  return (
    <>
      <Container>
        <CustomTypography variant="caption">Totalizadores</CustomTypography>

        <ContainerLoading>
          <Row className={`animated-background`} />
          <Row className={`animated-background`} />
        </ContainerLoading>
      </Container>
      <Container>
        {displayMode === 'list' ? (
          <>
            <CustomTypography variant="caption">Informações gerais</CustomTypography>
            <ContainerLoading>
              <Row className={`animated-background`} />
              <Row className={`animated-background`} />
              <Row className={`animated-background`} />
            </ContainerLoading>
          </>
        ) : (
          <ModuleLoading />
        )}
      </Container>
    </>
  );
};

export default ReportLoading;
