import { useRentalReducer } from 'pages/managed-sales/reducer/reducer';
import React, { useEffect, useState } from 'react';
import { RentalProvider } from '../hooks/useManagedSale';
import { ManagedSale } from 'pages/managed-sales/types/managedSale';
import { changeRentalAction, setRentalAction } from 'pages/managed-sales/reducer/actions';
import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import RentalForm from '../ManagedSaleForm';
import { api } from 'services/api';
import history from 'services/history';
import { useManagedSaleValidation } from '../validation/managedSaleValidation';
import Loading from 'components/loading/Loading';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import RentalNewAppbarButtons from '../new/ManagedSaleNewAppbarButtons';
import { useParams } from 'react-router-dom';
import { useFetchManagedSale } from '../../hooks/useFetchManagedSale';
import InsideLoading from 'components/loading/InsideLoading';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 40,
  flex: 1,
});

const ManagedSaleUpdate: React.FC = () => {
  const [rental, dispatch] = useRentalReducer();
  const [saving, setSaving] = useState(false);
  const [validation, setValidation, validate] = useManagedSaleValidation();
  const { showErrorDialog } = useErrorHandler();
  const { id } = useParams<{ id: string }>();
  const [loadedRental, loading] = useFetchManagedSale(id);
  const [percentage, setPercentage] = useState('');

  useEffect(() => {
    dispatch(setRentalAction(loadedRental));
  }, [loadedRental, dispatch]);

  function handleChange(index: keyof ManagedSale, value: any) {
    dispatch(changeRentalAction(index, value));
  }

  function handleValidation() {
    setValidation({});

    validate(rental)
      .then(handleSubmit)
      .catch(error => console.error(error));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/rentals/${id}`, rental)
      .then(() => {
        history.push('/managed-sales');
      })
      .catch(error => {
        console.error(error);
        showErrorDialog({
          error,
          message: 'Não foi possível salvar a nova locação',
        });
      })
      .finally(() => setSaving(false));
  }

  return (
    <RentalProvider value={{ rental, handleChange, dispatch, percentage, setPercentage }}>
      {saving && <Loading />}

      <Appbar
        title="Venda agenciada"
        ActionsComponent={<RentalNewAppbarButtons handleValidation={handleValidation} />}
      />

      <PageHeader title="Editar venda agenciada" backUrl="/managed-sales" />
      <Container>
        {loading ? (
          <InsideLoading />
        ) : (
          <RentalForm validation={validation} handleChange={handleChange} rental={rental} />
        )}
      </Container>
    </RentalProvider>
  );
};

export default ManagedSaleUpdate;
