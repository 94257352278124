import { TableTemplate } from 'types/tableTemplate';

export const productsTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 70,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'external_id',
    description: 'ID',
    originalId: 'product_id',
    width: 100,
  },
  {
    id: 'name',
    description: 'NOME',
    originalId: 'name',
    width: 400,
  },
  {
    id: 'quantity',
    description: 'QTD',
    originalId: 'quantity',
    width: 100,
  },
  {
    id: 'formattedCommissionValue',
    description: 'COMISSÃO',
    originalId: 'commission_value',
    width: 100,
  },
  {
    id: 'formattedValue',
    description: 'VALOR',
    originalId: 'value',
    width: 100,
  },
];
