import { useState, useEffect, useCallback, FormEvent } from 'react';
import { AbcProduct } from 'types/abcProduct';
import { endOfDay, format, startOfDay } from 'date-fns';
import { useApp } from 'hooks/app';
import { ptBR } from 'date-fns/locale';
import { useSelector } from 'store/selector';
import { AbcProductFilterParams } from 'pages/reports/commercial/abc/product/AbcProduct';

export function useFetchAnalysisProduct() {
  const { h2iApi } = useApp();
  const user = useSelector(state => state.user);
  const { isOpenedMenu, handleOpenMenu } = useApp();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [products, setProducts] = useState<AbcProduct[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<AbcProduct[]>([]);
  const [filter, setFilter] = useState<AbcProductFilterParams>({
    initialDate: startOfDay(new Date()),
    finalDate: endOfDay(new Date()),
    selectedBranchId:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
    productId: 0,
    typeSale: 'T',
    categoryIds: [],
    subCategoryIds: [],
    madeBy: '',
    segmentIds: [],
    unitIds: [],
    subGroupIds: [],
    manufacturerIds: [],
  });

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  function handleChangeFilter(index: keyof AbcProductFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  const handleSearch = useCallback(
    (searchValue: string) => {
      if (!h2iApi) return;

      const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
      const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

      setLoading(true);

      h2iApi
        .get(`/api/relcurvaabcprodutos`, {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.selectedBranchId || '',
            id_subgrupo: filter.subGroupIds.join(','),
            filtro: searchValue,
            tipo_venda: filter.typeSale,
            id_categoria: filter.categoryIds.join(','),
            id_subcategoria: filter.subCategoryIds.join(','),
            pedido_por: filter.madeBy,
            id_segmento: filter.segmentIds.join(','),
            id_unidade: filter.unitIds.join(','),
            id_fabricante: filter.manufacturerIds.join(','),
          },
        })
        .then(response => {
          const _response = response.data;
          setProducts(_response);
        })
        .catch(err => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    },
    [filter, h2iApi],
  );

  function handleSearchSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    if (isOpenedMenu) {
      handleOpenMenu();
    }

    handleSearch(searchText);
  }

  function handleSearchInputChange(value: string) {
    setSearchText(value);
  }

  return {
    filteredProducts,
    handleSearch,
    searchText,
    handleSearchInputChange,
    handleSearchSubmit,
    loading,
    handleChangeFilter,
    filter,
  };
}
