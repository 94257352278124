import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useWastes } from '../hooks/useWastes';
import history from 'services/history';

type WasteListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const WasteListMenu: React.FC<WasteListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedWaste } = useWastes();

  function handlePrintClick() {
    history.push(`/waste/print/${selectedWaste?.id}`);
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handlePrintClick}>imprimir</MenuItem>
    </Menu>
  );
};

export default WasteListMenu;
