import { TableTemplate } from 'types/tableTemplate';

export const productTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 70,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 100,
  },
  {
    id: 'name',
    description: 'Name',
    originalId: 'name',
    width: 400,
  },
];
