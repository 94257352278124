import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { useBranches } from '../../hooks/useCommissionCategory';
import { moneyFormat } from 'helpers/numberFormat';
import { CommissionCategory } from 'types/reports/commissionCategory';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type CommissionCategoriesItemModuleProps = {
  commissionCategory: CommissionCategory;
};

const CommissionCategoriesItemModule: React.FC<CommissionCategoriesItemModuleProps> = ({ commissionCategory }) => {
  const classes = useStyles();
  const { setSelectedCommissionCategory } = useBranches();

  function handleClick() {
    setSelectedCommissionCategory(commissionCategory);
  }

  return (
    <>
      <ListItem button className={classes.listItem} onClick={handleClick}>
        <Typography variant="caption" color="primary">
          Categoria - {commissionCategory.categoria}
        </Typography>
        <Typography variant="caption" color="primary">
          Pedido por - {commissionCategory.pedido_por}
        </Typography>
        <Typography gutterBottom>Valor total - {moneyFormat(commissionCategory.vl_total)}</Typography>
        <Typography gutterBottom>Valor unitário - {moneyFormat(commissionCategory.vl_unitario)}</Typography>
        <Typography variant="body2" color="textSecondary">
          Vendedor - {commissionCategory.vendedor}
        </Typography>
      </ListItem>
    </>
  );
};

export default CommissionCategoriesItemModule;
