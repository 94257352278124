import { Dispatch, SetStateAction, useState } from 'react';
import { ProjectActionFormContent } from 'types/project';
import * as yup from 'yup';

export type ProjectActionValidation = {
  fullname?: string;
  referenceDate?: string;
  observation?: string;
  action?: string;
};

type UseProjectActionValidation = [
  ProjectActionValidation,
  Dispatch<SetStateAction<ProjectActionValidation>>,
  (action: ProjectActionFormContent) => Promise<void>,
];

export function useProjectActionValidation(): UseProjectActionValidation {
  async function handleValidation(action: ProjectActionFormContent) {
    const schema = yup.object().shape({
      observation: yup.string().nullable(),
      referenceDate: yup.date().required('a data de referência é obrigatória'),
      fullname: yup.string().required('o valor é obrigatório'),
      action: yup.string().required('a ação é obrigatória'),
    });

    try {
      await schema.validate(action);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<ProjectActionValidation>({});
  return [validation, setValidation, handleValidation];
}
