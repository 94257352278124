import React from 'react';
import { Add, Close } from '@mui/icons-material';
import { CircularProgress, IconButton, InputAdornment, styled } from '@mui/material';
import { Product } from 'types/product';

interface ProductsAutoCompleteAdornmentProps {
  selected: Product | null;
  loading: boolean;
  clearSelected(): void;
  handleAdd?(): void;
}

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  '&.close': {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  '&.add': {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const ProductsAutoCompleteAdornment: React.FC<ProductsAutoCompleteAdornmentProps> = ({
  selected,
  loading,
  clearSelected,
  handleAdd,
}) => {
  return (
    <InputAdornment position="end">
      {loading ? (
        <CircularProgress color="primary" size={24} />
      ) : (
        selected && (
          <>
            <CustomIconButton className="close" size="small" onClick={clearSelected}>
              <Close />
            </CustomIconButton>
            <CustomIconButton className="add" onClick={handleAdd}>
              <Add fontSize="small" />
            </CustomIconButton>
          </>
        )
      )}
    </InputAdornment>
  );
};

export default ProductsAutoCompleteAdornment;
