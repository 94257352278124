import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'store/selector';
import { AbcProductBranch, AbcProductManufacturer, AbcProductUnity } from 'types/abcProduct';
import { CommercialPayment } from 'types/commercialPayment';
import { Sales } from 'types/reports/sales';

export type DashboardTotal = {
  mc: string;
  shipment: string;
  others: string;
  ticket: string;
  stock: string;
  liquid: string;
  liquid_day: string;
  others_day: string;
};

export interface FilterDashboardCommercial {
  initial_date: Date;
  final_date: Date;
  branch_id: number;
  type_sale: string;
  made_by: string;
}

export type UseFetchCommercialReport = [
  () => string,
  (days: number) => void,
  (initialDt: Date, finalDt: Date) => void,
  boolean,
  number | null,
  DashboardTotal,
  AbcProductBranch[],
  Sales[],
  AbcProductUnity[],
  AbcProductManufacturer[],
  CommercialPayment[],
  FilterDashboardCommercial,
  (index: keyof FilterDashboardCommercial, value: any) => void,
];

export function useFetchCommercialReport(): UseFetchCommercialReport {
  const { h2iApi } = useApp();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<CommercialPayment[]>([]);
  const [units, setUnits] = useState<AbcProductUnity[]>([]);
  const [manufacturers, setManufacturers] = useState<AbcProductManufacturer[]>([]);
  const [dateConfig, setDateConfig] = useState<number | null>(0);
  const [formattedTotal, setFormattedTotal] = useState<DashboardTotal>({
    mc: '',
    shipment: '',
    liquid: '',
    stock: '',
    ticket: '',
    others: '',
    liquid_day: '',
    others_day: '',
  });
  const user = useSelector(state => state.user);
  const [branches, setBranches] = useState<AbcProductBranch[]>([]);
  const [sales, setSales] = useState<Sales[]>([]);
  const [filter, setFilter] = useState<FilterDashboardCommercial>({
    branch_id:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
    final_date: currentDate,
    initial_date: new Date(currentYear, currentMonth, 1),
    made_by: '',
    type_sale: 'V',
  });

  function handleChangeFilter(index: keyof FilterDashboardCommercial, value: any) {
    setFilter(item => ({
      ...item,
      [index]: value,
    }));
  }

  const fetchRequests = useCallback(async () => {
    if (!h2iApi) return;
    if (!user?.branchList) return;

    setLoading(true);

    const formattedInitialDate = format(filter.initial_date, "dd'.'MM'.'yyyy");
    const formattedFinalDate = format(filter.final_date, "dd'.'MM'.'yyyy");

    const fetchBranches = h2iApi
      .get('/api/relcurvaabcfiliais', {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          tipo_venda: filter.type_sale,
          id_filial: filter.branch_id || '',
        },
      })
      .then(response => {
        const _branches = response.data.map(item => {
          item.formattedTotal = moneyFormat(item.total);
          item.formattedCoust = moneyFormat(item.custo);
          item.formattedMc = moneyFormat(item.mc);
          item.formattedSpiff = moneyFormat(item.spiff);
          item.formattedUnitary = moneyFormat(item.unitario);

          return item;
        });

        setBranches(_branches);
      });

    const fetchPayments = h2iApi
      .get('/api/relformapag', {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.branch_id || '',
        },
      })
      .then(response => {
        const _payments: CommercialPayment[] = response.data.relpag
          .map(item => {
            item.formattedTotal = moneyFormat(item.total);

            return item;
          })
          .filter(item => item.total > 0)
          .sort((a, b) => b.total - a.total);

        setPayments(_payments);
      });

    const fetchResume = h2iApi
      .get(
        `/api/relresumo?data_ini=${formattedInitialDate}&data_fim=${formattedFinalDate}&id_filial=${
          filter.branch_id || ''
        }&tipo_venda=${filter.type_sale}`,
      )
      .then(response => {
        const _response = response.data.relresumo[0];

        setFormattedTotal({
          liquid: moneyFormat(_response.fat_liquido),
          mc: moneyFormat(_response.mc),
          shipment: moneyFormat(_response.frete),
          stock: moneyFormat(_response.estoque),
          ticket: moneyFormat(_response.ticket),
          others: moneyFormat(_response.fat_outros),
          liquid_day: moneyFormat(_response.fat_liquido_dia),
          others_day: moneyFormat(_response.fat_outros_dia),
        });
      })
      .catch(err => console.error(err));

    const fetchUnits = h2iApi
      .get(`/api/relcurvaabcunidade`, {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.branch_id || '',
          tipo_venda: filter.type_sale,
          filtro: '',
        },
      })
      .then(response => {
        if (!response.data) {
          setUnits([]);
          return;
        }

        const _units: AbcProductUnity[] = response.data.map(item => {
          item.formattedTotal = moneyFormat(item.total);
          item.formattedCoust = moneyFormat(item.custo);
          item.formattedMc = moneyFormat(item.mc);
          item.formattedSpiff = moneyFormat(item.spiff);
          item.formattedUnitary = moneyFormat(item.unitario);

          return item;
        });

        setUnits(_units.sort((a, b) => b.mc - a.mc));
      });

    const fetchManufacturers = h2iApi
      .get(`/api/relcurvaabcfabricante`, {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.branch_id || '',
          tipo_venda: filter.type_sale,
          filtro: '',
        },
      })
      .then(response => {
        if (!response.data) {
          setManufacturers([]);
          return;
        }

        const _manufacturers: AbcProductManufacturer[] = response.data.map(item => {
          item.formattedTotal = moneyFormat(item.total);
          item.formattedCoust = moneyFormat(item.custo);
          item.formattedMc = moneyFormat(item.mc);
          item.formattedSpiff = moneyFormat(item.spiff);
          item.formattedUnitary = moneyFormat(item.unitario);

          return item;
        });

        setManufacturers(_manufacturers.sort((a, b) => b.mc - a.mc));
      })
      .catch(err => console.error(err));

    const fetchSales = h2iApi
      .get(`/api/relvendas`, {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.branch_id || '',
          tipo_venda: filter.type_sale,
          filtro: '',
        },
      })
      .then(response => {
        if (!response.data.relvendas) {
          setSales([]);
          return;
        }

        const _sales = response.data.relvendas.map(item => {
          item.formattedTotal = moneyFormat(item.vl_total);
          item.formattedSpiff = numberFormat(item.spiff);
          item.formattedDiscount = moneyFormat(item.vl_desconto);
          item.formattedDiscountPercent = percentFormat(item.perc_desconto);

          return item;
        });

        setSales(_sales);
      })
      .catch(err => console.error(err));

    Promise.all([fetchBranches, fetchPayments, fetchUnits, fetchResume, fetchManufacturers, fetchSales])
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [h2iApi, filter, user]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  function getDescriptionPageHeader(): string {
    const formattedInitialDate = format(filter.initial_date, 'P', { locale: ptBR });
    const formattedFinalDate = format(filter.final_date, 'P', { locale: ptBR });

    return `Você está visualizando informações de ${formattedInitialDate} à ${formattedFinalDate}`;
  }

  function handleDateConfig(days: number) {
    setDateConfig(days);
    if (days === 0) {
      const date = new Date();
      handleChangeFilter('initial_date', startOfDay(date));
      handleChangeFilter('final_date', endOfDay(date));
    } else {
      const date = new Date();
      handleChangeFilter('final_date', endOfDay(date));
      handleChangeFilter('initial_date', startOfDay(subDays(date, days)));
    }
  }

  function handleConfirmCustomDates(initialDt: Date, finalDt: Date) {
    handleChangeFilter('initial_date', initialDt);
    handleChangeFilter('final_date', finalDt);
    setDateConfig(null);
  }

  return [
    getDescriptionPageHeader,
    handleDateConfig,
    handleConfirmCustomDates,
    loading,
    dateConfig,
    formattedTotal,
    branches,
    sales,
    units,
    manufacturers,
    payments,
    filter,
    handleChangeFilter,
  ];
}
