import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { RentalOnList } from '../types/rental';

export interface RentalsContextValue {
  selectedRental: RentalOnList | null;
  setSelectedRental: Dispatch<SetStateAction<RentalOnList | null>>;
  handleStatus(): void;
  setReport: Dispatch<SetStateAction<boolean>>;
}

const Context = createContext<RentalsContextValue>({} as RentalsContextValue);
export const RentalProvider = Context.Provider;

export function useRentals(): RentalsContextValue {
  return useContext(Context);
}
