import React from 'react';
import { Typography, alpha, Theme } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useBranches } from '../../hooks/useBranches';
import ItemsSoldItemTable from './ItemsSoldItemTable';
import { ItemsSold } from 'types/reports/itemsSold';
import { itemsSoldTableTemplate } from '../../itemsSoldTableTemplate';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
  selectedCustomer: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
  },
}));

type ItemsSoldListTableProps = {
  itemsSold: ItemsSold[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const ItemsSoldListTable: React.FC<ItemsSoldListTableProps> = ({ itemsSold, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { setSelectedItemSold } = useBranches();

  function handleClick(itemSold: ItemsSold) {
    setSelectedItemSold(itemSold);
  }

  return (
    <>
      <TableContent>
        <TableHeader>
          {itemsSoldTableTemplate.map(item => (
            <div
              className={
                item.dataType === 'number' ? `${classes.headerItem} ${classes.headerItemNumeric}` : classes.headerItem
              }
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {itemsSold.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(itemSold => (
            <TableRow onClick={() => handleClick(itemSold)} key={itemSold.codigo}>
              <ItemsSoldItemTable itemSold={itemSold} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default ItemsSoldListTable;
