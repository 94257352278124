import { Dispatch, useReducer } from 'react';
import { AbcProductActions, AbcProductData } from './types';
import { handleCompareCategories } from './cases/handleCategories';
import { handleCompareSellers } from './cases/handleSellers';
import { handleCompareCustomers } from './cases/handleCustomers';
import { handleCompareManufactures } from './cases/handleManufactures';
import { handleCompareSegments } from './cases/handleSegments';
import { handleCompareProducts } from './cases/handleProducts';
import { handleCompareUnits } from './cases/handleUnits';
import { handleCompareSubgroups } from './cases/handleSubgroups';
import { handleCompareBranches } from './cases/handleBranches';

const INITIAL_STATE: AbcProductData = {
  branches: [],
  units: [],
  subgroups: [],
  sellers: [],
  segments: [],
  categories: [],
  customers: [],
  products: [],
  manufacturers: [],
  total: {
    coust: 0,
    mc: 0,
    quantity: 0,
    spiff: 0,
    total: 0,
    unitary: 0,
  },
};

function abcProductReducer(state = INITIAL_STATE, action: AbcProductActions): AbcProductData {
  switch (action.type) {
    case 'SET_PRODUCTS': {
      const total = action.products.reduce((sum, item) => sum + item.total, 0);
      const coust = action.products.reduce((sum, item) => sum + item.custo, 0);
      const mc = action.products.reduce((sum, item) => sum + item.mc, 0);
      const spiff = action.products.reduce((sum, item) => sum + item.spiff, 0);
      const unitary = action.products.reduce((sum, item) => sum + item.unitario, 0);
      const quantity = action.products.reduce((sum, item) => sum + item.qtd, 0);

      const products = handleCompareProducts({
        products: action.products,
        toCompare: action.productsToCompare,
        dates: action.dates,
        total: state.total.total,
      });

      return {
        ...state,
        products,
        total: {
          total,
          coust,
          mc,
          spiff,
          unitary,
          quantity,
        },
      };
    }

    case 'SET_UNITS': {
      const units = handleCompareUnits({
        units: action.units,
        toCompare: action.unitsToCompare,
        dates: action.dates,
        total: state.total.total,
      });

      return {
        ...state,
        units,
      };
    }

    case 'SET_SELLERS': {
      const sellers = handleCompareSellers({
        sellers: action.sellers,
        toCompare: action.sellersToCompare,
        dates: action.dates,
        total: state.total.total,
      });

      return {
        ...state,
        sellers,
      };
    }

    case 'SET_BRANCHES': {
      const branches = handleCompareBranches({
        branches: action.branches,
        toCompare: action.branchesToCompare,
        dates: action.dates,
        total: state.total.total,
      });

      return {
        ...state,
        branches,
      };
    }

    case 'SET_CATEGORIES': {
      const categories = handleCompareCategories({
        categories: action.categories,
        toCompare: action.categoriesToCompare,
        dates: action.dates,
        total: state.total.total,
      });

      return {
        ...state,
        categories,
      };
    }

    case 'SET_CUSTOMERS': {
      const customers = handleCompareCustomers({
        customers: action.customers,
        toCompare: action.customersToCompare,
        dates: action.dates,
        total: state.total.total,
      });

      return {
        ...state,
        customers,
      };
    }

    case 'SET_MANUFACTURES': {
      const manufacturers = handleCompareManufactures({
        manufactures: action.manufactures,
        toCompare: action.manufacturesToCompare,
        dates: action.dates,
        total: state.total.total,
      });

      return {
        ...state,
        manufacturers,
      };
    }

    case 'SET_SEGMENTS': {
      const segments = handleCompareSegments({
        segments: action.segments,
        toCompare: action.segmentsToCompare,
        dates: action.dates,
        total: state.total.total,
      });

      return {
        ...state,
        segments,
      };
    }

    case 'SET_SUBGROUPS': {
      const subgroups = handleCompareSubgroups({
        subgroups: action.subgroups,
        toCompare: action.subgroupsToCompare,
        dates: action.dates,
        total: state.total.total,
      });

      return {
        ...state,
        subgroups,
      };
    }

    default: {
      return state;
    }
  }
}

export function useAbcProductReducer(): [AbcProductData, Dispatch<AbcProductActions>] {
  return useReducer(abcProductReducer, INITIAL_STATE);
}
