import React from 'react';
import { Button, Typography, styled } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';

interface ConfirmDialogProps {
  title: string;
  handleNoClick(): void;
  handleYesClick(): void;
}

const Container = styled('div')({
  gap: 10,
  flex: 1,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const Actions = styled('div')({
  gap: 10,
  display: 'flex',
  justifyContent: 'center',
});

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ handleYesClick, handleNoClick, title }) => {
  return (
    <Dialog height="20vh" maxWidth="sm" onExited={handleNoClick} disableEscapeKeyDown disableBackdropClick>
      <Container>
        <Typography>{title}</Typography>

        <Actions>
          <Button variant="contained" onClick={handleYesClick}>
            Sim
          </Button>

          <Button variant="outlined" onClick={handleNoClick}>
            Não
          </Button>
        </Actions>
      </Container>
    </Dialog>
  );
};

export default ConfirmDialog;
