import Dialog from 'components/dialogs/Dialog';
import React, { useState } from 'react';
import InvoiceActions from './InvoiceActions';
import { MenuItem, TextField, Typography, styled } from '@mui/material';
import { useInvoices } from '../hooks/useInvoices';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import { moneyFormat } from 'helpers/numberFormat';
import { Receipt, RequestQuote } from '@mui/icons-material';
import { useInvoicePaymentValidation } from './validation/invoicePaymentValidation';

interface InvoicePaymentProps {
  onExited(): void;
}

const Form = styled('form')({
  display: 'grid',
  gap: 5,
  marginTop: 30,
  gridTemplateColumns: '1fr 1fr',
});

const Box = styled('div')(({ theme }) => ({
  minWidth: 225,
  flex: 1,
  display: 'grid',
  gridTemplateColumns: '50px 1fr',
  alignItems: 'center',
  gap: 15,
  border: `1px solid ${theme.palette.primary.dark}`,
  '& .value': {
    fontSize: 24,
    textAlign: 'end',
    paddingRight: 5,
  },
  '& svg': {
    paddingLeft: 5,
    width: '100%',
    height: '100%',
    color: theme.palette.primary.main,
  },
  '& .description': {
    fontWeight: 600,
    padding: '10px 5px',
    gridColumn: '1/3',
    textAlign: 'end',
    borderTop: `1px solid ${theme.palette.primary.dark}`,
  },
}));

const Header = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  maxHeight: 225,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    maxHeight: 430,
  },
}));

const InvoicePayment: React.FC<InvoicePaymentProps> = ({ onExited }) => {
  const [validation, setValidation, validate] = useInvoicePaymentValidation();
  const { accounts, selectedInvoices } = useInvoices();
  const [totalizingAccountId, setTotalizingAccountId] = useState(0);
  const [paymentDate, setPaymentDate] = useState(new Date());

  const totals = selectedInvoices.reduce((sum, item) => sum + item.valor, 0);
  const length = selectedInvoices.length;

  function handleValidation() {
    setValidation({});

    validate({ totalizingAccountId })
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {}

  return (
    <Dialog
      height="70vh"
      maxWidth="sm"
      onExited={onExited}
      ComponentActions={<InvoiceActions handleSubmit={handleValidation} />}
      title="Pagamento"
    >
      <Header>
        <Box>
          <Receipt />
          <Typography className="value" fontSize={12} variant="subtitle2">
            {length}
          </Typography>
          <Typography className="description" variant="caption">
            Quantidade de títulos
          </Typography>
        </Box>

        <Box>
          <RequestQuote />
          <Typography className="value" fontSize={12} variant="subtitle2">
            {moneyFormat(totals)}
          </Typography>
          <Typography className="description" variant="caption">
            (R$) Total
          </Typography>
        </Box>
      </Header>

      <Form>
        <TextField
          select
          error={!!validation.totalizingAccountId}
          helperText={validation.totalizingAccountId}
          value={totalizingAccountId}
          placeholder="Selecione a conta totalizadora"
          label="Conta totalizadora"
          onChange={e => setTotalizingAccountId(parseInt(e.target.value))}
        >
          <MenuItem value={0}>Nenhuma conta selecionada</MenuItem>

          {accounts.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.nome}
            </MenuItem>
          ))}
        </TextField>

        <DesktopDatePicker label="Data de pagamento" value={paymentDate} onChange={date => setPaymentDate(date)} />
      </Form>
    </Dialog>
  );
};

export default InvoicePayment;
