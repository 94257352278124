import React, { useState } from 'react';
import List from 'components/list/List';
import RentalItemModule from './ManagedSaleItemModule';
import ProjectListMenu from '../ManagedSaleListMenu';
import { ManagedSaleOnList } from 'pages/managed-sales/types/managedSale';

type ManagedSaleListModuleProps = {
  rentals: ManagedSaleOnList[];
};

const ManagedSaleListModule: React.FC<ManagedSaleListModuleProps> = ({ rentals }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <ProjectListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <List>
        {rentals.map(rental => (
          <RentalItemModule key={rental.id} rental={rental} setAnchorEl={setAnchorEl} />
        ))}
      </List>
    </>
  );
};

export default ManagedSaleListModule;
