import React, { FormEvent, useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import TemplateActions from './TemplateActions';
import TemplateForm from '../TemplateForm';
import { useTemplateValidation } from '../validation/templateValidation';
import { Template } from 'types/template';

const queryParamsInitialValue: Template = {
  description: '',
  font_color: '',
  image_id: 0,
  is_active: true,
  image: null,
};

const TemplateNew: React.FC = () => {
  const [template, setTemplate] = useState<Template>(queryParamsInitialValue);
  const [validation, setValidation, validate] = useTemplateValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();

  const handleChange = useCallback((index: keyof Template, value: any) => {
    if (index === 'image') {
      setTemplate(state => ({
        ...state,
        image: value,
        image_id: value ? value.id : null,
      }));
      return;
    }

    setTemplate(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(template)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/cardTemplates', template)
      .then(() => {
        setSaving(false);
        history.push('/site/templates');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Site"
        ActionsComponent={<TemplateActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/site/templates')}
      />
      <PageHeader title="Novo template" backUrl="/site/templates" />
      <Grid container>
        <form onSubmit={handleValidation}>
          <TemplateForm handleChange={handleChange} template={template} validation={validation} />
        </form>
      </Grid>
    </>
  );
};

export default TemplateNew;
