import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';
import { H2iBuyer } from 'types/h2iBuyer';
import { cpfValidator } from 'helpers/cpfValidator';

export interface BuyerValidation {
  nome?: string;
  cpf?: string;
}

type UseBuyerValidation = [
  BuyerValidation,
  Dispatch<SetStateAction<BuyerValidation>>,
  (buyer: H2iBuyer) => Promise<void>,
];

export function useBuyerValidation(): UseBuyerValidation {
  async function handleBuyerValidation(buyer: H2iBuyer) {
    const schema = yup.object().shape({
      cpf: yup
        .string()
        .required('O CPF é obrigatório')
        .transform((value, originalValue) => {
          return originalValue ? originalValue.replace(/\D/g, '') : '';
        })
        .test('docValidation', `CPF inválido`, value => {
          if (!value) return false;

          const cpf = cpfValidator(value);

          return cpf;
        }),
      nome: yup.string().required('O nome é obrigatório'),
    });

    try {
      await schema.validate(buyer);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setBuyerValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [buyerValidation, setBuyerValidation] = useState<BuyerValidation>({});
  return [buyerValidation, setBuyerValidation, handleBuyerValidation];
}
