import { Button, IconButton } from '@mui/material';
import React from 'react';
import CalendarIcon from '@mui/icons-material/CalendarToday';

import { makeStyles } from '@mui/styles';

type SalesReportHeaderButtonsProps = {
  loading: boolean;
  handleDateConfig(days: number): void;
  dateConfig: number | null;
  setAnchorEl(element: HTMLButtonElement): void;
};

const useStyles = makeStyles({
  buttons: {
    //
  },
});

const SalesReportHeaderButtons: React.FC<SalesReportHeaderButtonsProps> = ({
  loading,
  handleDateConfig,
  dateConfig,
  setAnchorEl,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.buttons}>
        <Button
          disabled={loading}
          variant="text"
          size="small"
          color={dateConfig === 0 ? 'primary' : 'inherit'}
          onClick={() => handleDateConfig(0)}
        >
          Hoje
        </Button>
        <Button
          disabled={loading}
          variant="text"
          size="small"
          color={dateConfig === 15 ? 'primary' : 'inherit'}
          onClick={() => handleDateConfig(15)}
        >
          15 dias
        </Button>
        <Button
          disabled={loading}
          variant="text"
          size="small"
          color={dateConfig === 30 ? 'primary' : 'inherit'}
          onClick={() => handleDateConfig(30)}
        >
          30 dias
        </Button>
        <Button
          disabled={loading}
          variant="text"
          size="small"
          color={dateConfig === 60 ? 'primary' : 'inherit'}
          onClick={() => handleDateConfig(60)}
        >
          60 dias
        </Button>
        <IconButton
          disabled={loading}
          color={dateConfig === null ? 'primary' : 'inherit'}
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          <CalendarIcon />
        </IconButton>
      </div>
    </>
  );
};

export default SalesReportHeaderButtons;
