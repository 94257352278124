import { Typography, styled } from '@mui/material';
import React from 'react';

const Container = styled('div')({
  display: 'grid',
  gap: 15,
  gridTemplateColumns: '1fr 1fr 1fr 1fr 2fr',
});

const Content = styled('div')({
  backgroundColor: '#eee',
  display: 'grid',
  gap: 5,
  border: '1px solid #ddd',
  '& > .subtitle': {
    backgroundColor: '#fff',
    marginBottom: 0,
  },
  '& > span, p': {
    padding: 5,
  },
});

const PurchaseOrderTotals: React.FC = () => {
  return (
    <Container>
      <Content>
        <Typography align="left" variant="body2" fontWeight={600}>
          Base de Cálculo do ICMS
        </Typography>
        <Typography className="subtitle" align="right" variant="caption">
          R$ 0,00
        </Typography>
      </Content>

      <Content>
        <Typography align="left" variant="body2" fontWeight={600}>
          Valor do ICMS
        </Typography>
        <Typography className="subtitle" align="right" variant="caption">
          R$ 0,00
        </Typography>
      </Content>

      <Content>
        <Typography align="left" variant="body2" fontWeight={600}>
          Base Cálculo ICMS Subst.
        </Typography>
        <Typography className="subtitle" align="right" variant="caption">
          R$ 0,00
        </Typography>
      </Content>

      <Content>
        <Typography align="left" variant="body2" fontWeight={600}>
          Valor do ICMS Subst.
        </Typography>
        <Typography className="subtitle" align="right" variant="caption">
          R$ 0,00
        </Typography>
      </Content>

      <Content>
        <Typography align="left" variant="body2" fontWeight={600}>
          Total Produto
        </Typography>
        <Typography fontWeight={600} className="subtitle" align="right" variant="caption">
          R$ 0,00
        </Typography>
      </Content>

      <Content>
        <Typography align="left" variant="body2" fontWeight={600}>
          Base de Cálculo PIS/COFINS
        </Typography>
        <Typography className="subtitle" align="right" variant="caption">
          R$ 0,00
        </Typography>
      </Content>

      <Content>
        <Typography align="left" variant="body2" fontWeight={600}>
          Valor PIS
        </Typography>
        <Typography className="subtitle" align="right" variant="caption">
          R$ 0,00
        </Typography>
      </Content>

      <Content>
        <Typography align="left" variant="body2" fontWeight={600}>
          Valor COFINS
        </Typography>
        <Typography className="subtitle" align="right" variant="caption">
          R$ 0,00
        </Typography>
      </Content>

      <Content>
        <Typography align="left" variant="body2" fontWeight={600}>
          Valor Total IPI
        </Typography>
        <Typography className="subtitle" align="right" variant="caption">
          R$ 0,00
        </Typography>
      </Content>

      <Content>
        <Typography align="left" variant="body2" fontWeight={600}>
          Total Nota
        </Typography>
        <Typography color="#ff0000" fontWeight={600} className="subtitle" align="right" variant="caption">
          R$ 0,00
        </Typography>
      </Content>
    </Container>
  );
};

export default PurchaseOrderTotals;
