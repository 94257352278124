import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useMemo } from 'react';
import { useSelector } from 'store/selector';
import { useInvoices } from '../hooks/useInvoices';
import { useBranches } from 'providers/BranchesProviders';
import { styled } from '@mui/material';

const Title = styled('div')({
  padding: '10px 0px',
  borderBottom: '1px solid',
  borderTop: '1px solid',
  textAlign: 'center',
  margin: '20px 0',
});

const Header = styled('div')({
  display: 'grid',
});

const Flex = styled('div')({
  gap: 5,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const InvoicesReportHeader: React.FC = () => {
  const now = format(new Date(), 'PPpp', { locale: ptBR });
  const user = useSelector(state => state.user);
  const { filter } = useInvoices();
  const { financialBranches } = useBranches();

  const selectedBranch = useMemo(
    () => financialBranches.find(branch => branch.id === filter.branch_id),
    [filter, financialBranches],
  );

  return (
    <Header>
      <Flex>
        <div>{!selectedBranch ? <h6>Todas as filiais</h6> : <h6>Filial {selectedBranch.nome}</h6>}</div>
        <div>
          <p>{user?.name}</p>
          <p>{now}</p>
        </div>
      </Flex>

      <Title>
        <h6>Relatório de títulos</h6>
      </Title>
    </Header>
  );
};

export default InvoicesReportHeader;
