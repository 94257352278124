import React from 'react';
import { IconButton, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import history from 'services/history';

const Container = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },
}));

const UserActions: React.FC = () => {
  return (
    <Container>
      <IconButton color="inherit" onClick={() => history.push('/user')}>
        <AddIcon />
      </IconButton>
    </Container>
  );
};

export default UserActions;
