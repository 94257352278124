import React from 'react';
import { usePurchaseOrder } from '../../hook/usePurchaseOrder';
import { MenuItem, TextField, styled } from '@mui/material';

const Grid = styled('div')({
  rowGap: 15,
  columnGap: 10,
  display: 'grid',
  gridTemplateColumns: '1fr',
});

const PurchaseOrderFourthStep: React.FC = () => {
  const { order, handleChange } = usePurchaseOrder();

  return (
    <Grid>
      <TextField
        disabled={order.id_filial === 0}
        label="Valor do Desconto"
        placeholder="Valor do Desconto"
        value={order.valor_desconto || ''}
        type="number"
        inputMode="decimal"
        onChange={e => handleChange('valor_desconto', e.target.value)}
      />

      <TextField
        disabled={order.id_filial === 0}
        label="Valor despesas"
        placeholder="Valor despesas"
        value={order.valor_despesa || ''}
        type="number"
        inputMode="decimal"
        onChange={e => handleChange('valor_despesa', e.target.value)}
      />

      <TextField
        disabled={order.id_filial === 0}
        label="Valor do Frete"
        placeholder="Valor do Frete"
        value={order.valor_frete || ''}
        type="number"
        inputMode="decimal"
        onChange={e => handleChange('valor_frete', e.target.value)}
      />

      <TextField
        disabled={order.id_filial === 0}
        select
        label="Frete Por Conta"
        value={order.frete_por_conta}
        onChange={e => handleChange('frete_por_conta', e.target.value)}
      >
        <MenuItem value={0}>0 - do emitente</MenuItem>
        <MenuItem value={1}>1 - do destinatário/remetente</MenuItem>
        <MenuItem value={2}>2 - de terceiros</MenuItem>
        <MenuItem value={9}>9 - sem frete</MenuItem>
      </TextField>

      <TextField
        disabled={order.id_filial === 0}
        select
        label="Tipo de Frete"
        value={order.tipo_frete}
        onChange={e => handleChange('tipo_frete', e.target.value)}
      >
        <MenuItem value={'d'}>Destacado na Nota</MenuItem>
        <MenuItem value={'c'}>Conhecimento</MenuItem>
      </TextField>
    </Grid>
  );
};

export default PurchaseOrderFourthStep;
