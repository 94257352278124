import React from 'react';
import { Typography, styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import { useDavReviewDetail } from './hooks/useDavReviewDetail';

const Container = styled('div')(({ theme }) => ({
  width: 350,
  display: 'flex',
  flexDirection: 'column',
  padding: 20,

  '&.green': {
    backgroundColor: theme.palette.success.main,
    '& > p, > div > p': {
      color: '#eee',
    },
  },
  '&.red': {
    backgroundColor: theme.palette.error.main,
    '& > p, > div > p': {
      color: '#eee',
    },
  },
  '&.yellow': {
    backgroundColor: '#fbc200',
  },
  borderRadius: 4,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const Content = styled('div')({
  marginTop: 20,
  display: 'grid',
  gridTemplateColumns: '0.5fr 1fr',
  rowGap: '5px',
});

const DavReviewDetailTotals: React.FC = () => {
  const { order } = useDavReviewDetail();

  return (
    <Container
      className={
        order && order.mcPercent ? (order.mcPercent < 5 ? 'red' : order.mcPercent > 15 ? 'green' : 'yellow') : 'red'
      }
    >
      <Typography fontSize={20}>resumo do pedido</Typography>
      <Content>
        <Typography>peso bruto</Typography>
        <Typography align="right">{order?.formattedPesoBruto}</Typography>

        <Typography style={{ minWidth: 150 }}>quantidade</Typography>
        <Typography align="right">{order?.itens.length}x</Typography>

        <Typography>spiff</Typography>
        <Typography align="right">{order?.formattedSpiff}</Typography>

        <Typography>mc (%)</Typography>
        <Typography align="right">{order?.formattedMc}</Typography>

        <Typography>mc</Typography>
        <Typography align="right">{moneyFormat(order?.mc)}</Typography>

        <Typography>produtos</Typography>
        <Typography align="right">{order?.formattedValorTotalProdutos}</Typography>

        <Typography>desc gerente</Typography>
        <Typography align="right">{order?.formattedValorDescontoGerente}</Typography>

        <Typography>desc vendedor</Typography>
        <Typography align="right">{order?.formattedValorDescontoVendedor}</Typography>

        <Typography>envio</Typography>
        <Typography align="right">{order?.formattedValorFrete}</Typography>

        <Typography>total</Typography>
        <Typography fontWeight="bold" align="right" fontSize={22}>
          {order?.formattedValorTotal}
        </Typography>
      </Content>
    </Container>
  );
};

export default DavReviewDetailTotals;
