import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';
import { TransferAccount } from 'types/transferAccount';

export interface TransferAccountValidation {
  valor?: string;
  date?: string;
  observacao?: string;
  id_conta_destino?: string;
  id_conta_origem?: string;
}

type UseTransferAccountValidation = [
  TransferAccountValidation,
  Dispatch<SetStateAction<TransferAccountValidation>>,
  (transferAccount: TransferAccount) => Promise<void>,
];

export function useTransferAccountValidation(): UseTransferAccountValidation {
  async function handleTransferAccountValidation(transferAccount: TransferAccount) {
    const schema = yup.object().shape({
      observacao: yup.string().required('Observação é obrigatória'),
      valor: yup
        .string()
        .required('O valor é obrigatório')
        .test('valueValidation', 'valor inválido', value => {
          if (!value) return false;

          if (parseInt(value) === 0) return false;

          return true;
        }),
      date: yup.string().required('Digite uma data de transação'),
      id_conta_destino: yup
        .string()
        .required('Selecione a conta de destino')
        .test('valueValidation', 'conta de destino inválida', value => {
          if (!value) return false;

          if (parseInt(value) === 0) return false;

          return true;
        }),
      id_conta_origem: yup
        .string()
        .required('Selecione a conta de origem')
        .test('valueValidation', 'conta de origem inválida', value => {
          if (!value) return false;

          if (parseInt(value) === 0) return false;

          return true;
        }),
    });

    try {
      await schema.validate(transferAccount);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setTransferAccountValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [transferAccountValidation, setTransferAccountValidation] = useState<TransferAccountValidation>({});
  return [transferAccountValidation, setTransferAccountValidation, handleTransferAccountValidation];
}
