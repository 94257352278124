import React from 'react';
import SegmentItemModule from './SegmentItemModule';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { SegmentStockBalanceData } from 'types/reports/stockBalance';

type SegmentListModuleProps = {
  segments: SegmentStockBalanceData[];
};

const SegmentListModule: React.FC<SegmentListModuleProps> = ({ segments }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {segments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(segment => (
            <SegmentItemModule key={segment.id_segmento} segment={segment} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default SegmentListModule;
