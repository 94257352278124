import React, { useState } from 'react';
import { List, ListItem, TextField, Typography } from '@mui/material';
import Dialog, { DialogConsumer } from 'components/dialogs/Dialog';
import { ProductSubGroup } from 'types/product';
import useSearch from 'hooks/search';
import { Search } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

type ProductSubGroupListProps = {
  subGroups: ProductSubGroup[];
  onExited(): void;
  onSelect(group: ProductSubGroup): void;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    minHeight: 30,
    flexDirection: 'column',
    marginBottom: 5,
  },
});

const ProductSubGroupList: React.FC<ProductSubGroupListProps> = ({ subGroups, onExited, onSelect }) => {
  const classes = useStyles();

  const search = useSearch();
  const [filtered, setFiltered] = useState<ProductSubGroup[]>(subGroups);

  function handleClick(subgroup: ProductSubGroup, handleClose: () => void) {
    onSelect(subgroup);
    handleClose();
  }

  function handleSearch(value: string) {
    setFiltered(search(value, 'NOME', subGroups));
    if (!value) setFiltered(subGroups);
  }

  return (
    <Dialog onExited={onExited} maxWidth="sm" title="Subgrupos para produto">
      <TextField
        label="Pesquisa"
        placeholder="Digite sua pesquisa"
        margin="normal"
        onChange={e => handleSearch(e.target.value)}
        autoFocus
        InputProps={{
          startAdornment: <Search color="primary" />,
        }}
      />
      <DialogConsumer>
        {({ handleClose }) => (
          <List>
            {filtered.map(subgroup => (
              <ListItem
                className={classes.listItem}
                button
                onClick={() => handleClick(subgroup, handleClose)}
                key={subgroup.CODIGO_SUBGRUPO}
              >
                <Typography variant="body2">{subgroup.NOME}</Typography>
              </ListItem>
            ))}
          </List>
        )}
      </DialogConsumer>
    </Dialog>
  );
};

export default ProductSubGroupList;
