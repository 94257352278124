import React from 'react';
import { List } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import UnityItemModule from './UnityItemModule';
import { AbcProductUnity } from 'types/abcProduct';
import { makeStyles } from '@mui/styles';

type UnityListModuleProps = {
  units: AbcProductUnity[];
};

const styles = makeStyles({
  list: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: 100,
    flex: 1,
  },
});

const UnityListModule: React.FC<UnityListModuleProps> = ({ units }) => {
  const { rowsPerPage, page } = usePagination();
  const classes = styles();

  return (
    <List className={classes.list}>
      {units.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(unity => (
        <UnityItemModule key={unity.cod} unity={unity} />
      ))}
    </List>
  );
};

export default UnityListModule;
