import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import { Typography, styled } from '@mui/material';
import ExcelButtonDownload from 'components/excel-button-download/ExcelButtonDownload';
import { useDefaultExcelExport } from 'hooks/useDefaultExcelExport';
import { AbcCustomerWithoutSale } from 'types/abcCustomer';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: 30,
});

interface CustomerWithoutSaleExportProps {
  data: AbcCustomerWithoutSale[];
  onExited(): void;
}

const CustomerWithoutSaleExport: React.FC<CustomerWithoutSaleExportProps> = ({ data, onExited }) => {
  const { handleDownload } = useDefaultExcelExport(data);

  return (
    <Dialog onExited={onExited} title="Export para Excel" maxWidth="sm" height="60vh">
      <Container>
        <Typography>Sua planilha está pronta para ser baixada.</Typography>
        <ExcelButtonDownload handleDownload={handleDownload} />
      </Container>
    </Dialog>
  );
};

export default CustomerWithoutSaleExport;
