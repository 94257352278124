import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';
import { useSelector } from 'store/selector';

interface StockItemTabsProps {
  value: string;
  handleChange(value: string): void;
}

const CustomTab = styled(Tab)({
  height: '100%',
  padding: 5,
});

const CustomTabs = styled(Tabs)({
  backgroundColor: '#fff',
});

const StockItemTabs: React.FC<StockItemTabsProps> = ({ handleChange, value }) => {
  const branches = useSelector(state => state.branches);

  return (
    <CustomTabs value={value} onChange={(e, value) => handleChange(value)} variant="scrollable">
      <CustomTab className="first" value="all" label="Todos" />
      {branches.map((branch, index) => (
        <CustomTab key={branch.id} value={branch.id} label={branch.cidade.toLowerCase()} />
      ))}
    </CustomTabs>
  );
};

export default StockItemTabs;
