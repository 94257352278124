import { IconButton, styled } from '@mui/material';
import React from 'react';
import { useTransferAccounts } from './hooks/useTransferAccount';
import { Add, Print } from '@mui/icons-material';

const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const TransferAccountActions: React.FC = () => {
  const { setIsReportOpen, setIsNewTransferAccount } = useTransferAccounts();

  return (
    <Container>
      <IconButton onClick={() => setIsNewTransferAccount(true)} color="inherit">
        <Add />
      </IconButton>
      <IconButton color="inherit" onClick={() => setIsReportOpen(true)}>
        <Print />
      </IconButton>
    </Container>
  );
};

export default TransferAccountActions;
