import React from 'react';
import { TextField } from '@mui/material';
import { useProject } from '../../hook/useProject';

const ProjectObservation: React.FC = () => {
  const { handleChange, project } = useProject();

  return (
    <TextField
      autoFocus
      label="Observação"
      placeholder="Alguma observação para projeto"
      fullWidth
      multiline
      rows={5}
      value={project.observation}
      onChange={e => handleChange('observation', e.target.value)}
      margin="normal"
    />
  );
};

export default ProjectObservation;
