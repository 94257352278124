import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Button, IconButton, Typography, styled } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import NoData from 'components/no-data/NoData';
import TableContainer from 'components/table/TableContainer';
import PaginationProvider from 'hooks/pagination';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import FinancialTicketData from './FinancialTicketData';
import { FinancialTicketProvider } from './hooks/useFinancialTicket';
import FinancialTicketFilter from './FinancialTicketFilter';
import { FinancialTicket } from 'types/financialReportList';
import { ticketTableTemplate } from './ticketTableTemplate';
import TicketListTable from './list/table/TicketListTable';
import TicketListModule from './list/module/TicketListModule';
import FinancialTicketMenu from './FinancialTicketMenu';
import FinancialTicketFilterBox from './FinancialTicketFilterBox';
import { useFetchFinancialTicketReport } from './hooks/useFetchFinancialTicketReport';
import SalesTicketReportTotal from './SalesTicketReportTotal';
import FilterActions from 'components/filter-box/FilterActions';
import ReportLoading from 'components/loading/ReportLoading';
import { useSelector } from 'store/selector';
import { usePermissionRules } from 'hooks/permissionRules';
import FinancialTicketExport from './export/FinancialTicketExport';
import { useFinancial } from 'pages/financial/hooks/useFinancial';
import { Print } from '@mui/icons-material';

let timer: NodeJS.Timeout;

export interface FinancialTicketFilterParams {
  initialDate: Date | null;
  finalDate: Date | null;
  branchId: number;
  typeDate: string;
  situation: string;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
const HeaderActions = styled('div')({
  display: 'flex',
  columnGap: '10px',
});

const MoreDetail = styled('div')(({ theme }) => ({
  marginTop: 15,
  marginBottom: 10,
  padding: '15px 0',
  position: 'relative',
  transition: 'all ease 0.3s',
  border: `2px solid ${theme.palette.primary.main}`,
  '&.info': {
    padding: 15,
    border: '2px solid #eee',
    borderRadius: '8px 8px 0 0',
  },
}));

const CustomTypography = styled(Typography)({
  top: -12,
  left: 15,
  cursor: 'pointer',
  padding: '0px 5px',
  position: 'absolute',
  backgroundColor: '#fafafa',
});

const FinancialTicketReport: React.FC = () => {
  const { handleBack } = useFinancial();
  const { windowWidth, isMobile } = useApp();
  const [viewList, setViewList] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(isMobile || windowWidth < 930 ? 'module' : 'list');
  const [dialogDate, setDialogDate] = useState(false);
  const [shownReport, setShownReport] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [searchText, setSearchText] = useState('');
  const [filterdTicket, setFilteredTicket] = useState<FinancialTicket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<FinancialTicket | null>(null);
  const [excelExport, setExcelExport] = useState(false);
  const user = useSelector(state => state.user);
  const branches = useSelector(state => state.branches);
  const [filter, setFilter] = useState<FinancialTicketFilterParams>({
    initialDate: startOfDay(subDays(new Date(), 30)),
    finalDate: endOfDay(new Date()),
    branchId:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
    situation: 'T',
    typeDate: 'V',
  });
  const [handleSearch, tickets, loading, formattedTotal] = useFetchFinancialTicketReport(filter);
  const { checkPermission } = usePermissionRules();

  const selectedBranch = useMemo(() => {
    const branch = branches.find(branch => branch.id === filter.branchId);

    if (!branch) {
      return null;
    }

    return branch;
  }, [filter, branches]);

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  useEffect(() => {
    setFilteredTicket(tickets);
  }, [tickets]);

  function handleChangeFilter(index: keyof FinancialTicketFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string) {
    const filtered = sort(index, filterdTicket);
    setFilteredTicket(filtered);
  }

  function handleSearchSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    handleSearch(searchText);
  }

  function handleSearchInputChange(value: string) {
    setSearchText(value);

    clearTimeout(timer);

    if (!value.length) return;

    timer = setTimeout(() => handleSearch(value), 800);
  }

  return (
    <FinancialTicketProvider
      value={{
        selectedTicket,
        setSelectedTicket,
        filter,
        handleChangeFilter,
      }}
    >
      {dialogDate && <FinancialTicketFilter onExited={() => setDialogDate(false)} />}

      {shownReport && (
        <FinancialTicketData data={tickets} branch={selectedBranch} onExited={() => setShownReport(false)} />
      )}

      {excelExport && <FinancialTicketExport onExited={() => setExcelExport(false)} data={tickets} />}

      <Appbar
        backAction={handleBack}
        title="Boletos"
        ActionsComponent={
          <>
            {windowWidth < 600 && isMobile && (
              <>
                <IconButton color="inherit" onClick={() => setShownReport(true)}>
                  <Print />
                </IconButton>
                <FilterActions handleOpenDialog={() => setDialogDate(true)} />
              </>
            )}
          </>
        }
      />

      <PageHeaderActions
        title="Relação de boletos"
        description="Gerencie os boletos"
        ActionComponent={
          <HeaderActions>
            <Button color="primary" size="small" variant="contained" onClick={() => setShownReport(true)}>
              Imprimir
            </Button>
            {checkPermission('general.excel_export_button') && (
              <Button
                disabled={tickets.length === 0}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => setExcelExport(true)}
              >
                Excel
              </Button>
            )}
          </HeaderActions>
        }
      />

      <FinancialTicketFilterBox
        filter={filter}
        displayMode={displayMode}
        searchText={searchText}
        handleSearchInputChange={handleSearchInputChange}
        setDisplayMode={setDisplayMode}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
      />

      {loading ? (
        <ReportLoading displayMode={displayMode} />
      ) : filterdTicket.length === 0 ? (
        <NoData message="Nenhum boleto para mostrar" />
      ) : (
        <>
          <SalesTicketReportTotal loading={loading} formattedTotal={formattedTotal} />
          <MoreDetail>
            <CustomTypography onClick={() => setViewList(!viewList)} variant="caption">
              + Detalhamento
            </CustomTypography>
            {viewList && (
              <TableContainer tableTemplate={ticketTableTemplate}>
                <PaginationProvider>
                  <Container>
                    <FinancialTicketMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                    {displayMode === 'list' ? (
                      <TicketListTable
                        setAnchorEl={setAnchorEl}
                        tickets={filterdTicket}
                        handleSort={handleSort}
                        orderedIndex={orderedIndex}
                      />
                    ) : (
                      <TicketListModule setAnchorEl={setAnchorEl} tickets={filterdTicket} />
                    )}
                    <Pagination count={filterdTicket.length} />
                  </Container>
                </PaginationProvider>
              </TableContainer>
            )}
          </MoreDetail>
        </>
      )}
    </FinancialTicketProvider>
  );
};

export default FinancialTicketReport;
