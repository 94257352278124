import { TableTemplate } from 'types/tableTemplate';

export const purchaseOrderProductTableTemplate: TableTemplate[] = [
  {
    id: 'id_produto',
    description: 'ID',
    originalId: 'id_produto',
    width: 60,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 220,
  },
  {
    id: 'formattedCoust',
    description: 'Custo',
    originalId: 'custo',
    width: 90,
    dataType: 'number',
  },
  {
    id: 'formattedNegotiatedValue',
    description: 'Vl Negociado',
    originalId: 'valor_negociado',
    width: 135,
    dataType: 'number',
  },
  {
    id: 'formattedPercent',
    description: '% negociado',
    originalId: 'perc_comprou_caro',
    width: 125,
    dataType: 'number',
  },
  {
    id: 'estoque',
    description: 'Estoque',
    originalId: 'estoque',
    width: 110,
    dataType: 'number',
  },
  {
    id: 'pedido',
    description: 'Qtd Pedido',
    originalId: 'pedido',
    width: 120,
  },
  {
    id: 'formattedValueTotal',
    description: 'Total Pedido',
    originalId: 'valor_total',
    width: 130,
    dataType: 'number',
  },
  {
    id: 'data_lancamento',
    description: 'Lançamento',
    originalId: 'formattedReleaseDate',
    width: 120,
  },
  {
    id: 'previsaomin',
    description: 'Prev min',
    originalId: 'formattedMinForecast',
    width: 115,
  },
  {
    id: 'previsaomax',
    description: 'Prev max',
    originalId: 'formattedMaxForecast',
    width: 115,
  },
  {
    id: 'dias_atraso',
    description: 'Dias atraso',
    originalId: 'dias_atraso',
    width: 115,
  },
  {
    id: 'lancamentos',
    description: 'Lançamentos',
    originalId: 'lancamentos',
    width: 125,
  },
  {
    id: 'obs',
    description: 'Obs',
    originalId: 'obs',
    width: 200,
  },
];
