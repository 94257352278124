import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import EmailTemplateItemModule from './EmailTemplateItemModule';
import { EmailTemplate } from 'types/emailTemplate';

type EmailTemaplateListModuleProps = {
  emailTemplates: EmailTemplate[];
};

const EmailTemaplateListModule: React.FC<EmailTemaplateListModuleProps> = ({ emailTemplates }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {emailTemplates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(template => (
            <EmailTemplateItemModule key={template.id} emailTemplate={template} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default EmailTemaplateListModule;
