import { Button, Typography } from '@mui/material';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import React from 'react';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    height: 200,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
});

type RemittanceDeleteConfirmationProps = {
  onExited(): void;
  handleSubmit(): Promise<void>;
  remittanceId: number;
};

const RemittanceDeleteConfirmation: React.FC<RemittanceDeleteConfirmationProps> = ({
  handleSubmit,
  onExited,
  remittanceId,
}) => {
  const classes = styles();

  function handleYesClick(handleCloseModal: () => void) {
    handleSubmit();
    handleCloseModal();
  }
  return (
    <DialogInput onExited={onExited} maxWidth="sm">
      <DialogInputConsumer>
        {context => (
          <div className={classes.container}>
            <Typography align="center">
              Deseja realmente excluir a remessa <strong>{remittanceId}</strong>?
            </Typography>
            <div className={classes.actions}>
              <Button color="primary" variant="text" onClick={context.handleClose}>
                Cancelar
              </Button>
              <Button color="primary" variant="contained" onClick={() => handleYesClick(context.handleClose)}>
                Sim, excluir
              </Button>
            </div>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default RemittanceDeleteConfirmation;
