import { TableTemplate } from 'types/tableTemplate';

export const agentsTableTemplate: TableTemplate[] = [
  {
    id: 'revenda',
    description: 'REVENDA',
    originalId: 'revenda',
    width: 350,
  },
  {
    id: 'cnpj_revenda',
    description: 'CNPJ',
    originalId: 'cnpj_revenda',
    width: 200,
  },
  {
    id: 'nome',
    description: 'PREPOSTO',
    originalId: 'nome',
    width: 350,
  },
  {
    id: 'formattedDocumentSent',
    description: 'DOCUMENTO',
    originalId: 'formattedDocumentSent',
    width: 100,
  },
  {
    id: 'formattedLetterSent',
    description: 'CARTA',
    originalId: 'formattedLetterSent',
    width: 100,
  },
  {
    id: 'cpf',
    description: 'CPF',
    originalId: 'cpf',
    width: 150,
  },
  {
    id: 'rg',
    description: 'RG',
    originalId: 'rg',
    width: 150,
  },
  {
    id: 'telefone',
    description: 'TELEFONE',
    originalId: 'telefone',
    width: 150,
  },
  {
    id: 'cidade',
    description: 'CIDADE',
    originalId: 'cidade',
    width: 300,
  },
  {
    id: 'uf',
    description: 'UF',
    originalId: 'uf',
    width: 100,
  },
  {
    id: 'data_hora',
    description: 'DATA',
    originalId: 'data_hora',
    width: 200,
  },
];
