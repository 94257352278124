import React, { FormEvent, useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import BranchForm from '../BranchForm';
import { useBranchValidation } from '../validation/branchValidation';
import PartnerActions from './BranchActions';
import { Branch } from 'types/siteBranch';

const BranchNew: React.FC = () => {
  const [branch, setBranch] = useState<Branch>({} as Branch);
  const [validation, setValidation, validate] = useBranchValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();

  const handleChange = useCallback((index: keyof Branch, value: any) => {
    setBranch(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(branch)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/branches', branch)
      .then(() => {
        setSaving(false);
        history.push('/site/branches');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Site"
        ActionsComponent={<PartnerActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/site/branches')}
      />
      <PageHeader title="Nova filial" />
      <Grid container>
        <form onSubmit={handleValidation}>
          <BranchForm handleChange={handleChange} branch={branch} validation={validation} />
        </form>
      </Grid>
    </>
  );
};

export default BranchNew;
