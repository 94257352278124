import React from 'react';
import { MoreHoriz } from '@mui/icons-material';

import { AbcProductSeller } from 'types/abcProduct';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  text: {
    fontSize: 11,
  },
  subtitle: {
    fontWeight: 'bold',
  },
  subItem: {
    paddingLeft: 10,
  },
});

type SellerItemTableProps = {
  seller: AbcProductSeller;
};

const SellerItemTable: React.FC<SellerItemTableProps> = ({ seller }) => {
  const classes = styles();
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <IconButton key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'formattedName' && seller.type === 'subtitle' ? (
            <div key={item.id}>
              <Typography className={classes.text} variant="body2">
                <strong>{seller.pedido_por}</strong>
              </Typography>
            </div>
          ) : item.id === 'formattedName' ? (
            <div key={item.id}>
              <Typography className={`${classes.text} ${classes.subItem}`} variant="body2">
                {seller.formattedName}
              </Typography>
            </div>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography className={classes.text} variant="body2">
                {seller[item.id]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default SellerItemTable;
