import React, { useState } from 'react';
import { Button, Typography, styled } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { usePermissionRules } from 'hooks/permissionRules';

type BillTabProps = {
  handleChange(value: string): void;
  tab: string;
};

const Tabs = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  backgroundColor: theme.palette.primary.light,
  left: 0,
  right: 0,
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    backgroundColor: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const CustomTab = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  position: 'relative',
  color: '#ddd',
  padding: '6px 16px',
  transition: 'all ease 0.3s',
  '&.active': {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Span = styled('span')({
  position: 'absolute',
  top: '100%',
  display: 'flex',
  width: '100%',
  boxShadow: '1px 1px 9px 1px #000',
  flexDirection: 'column',
  backgroundColor: '#fff',
  zIndex: 35,
});

const MenuItem = styled(Typography)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  '&.active': {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
  },
  textAlign: 'start',
  padding: 10,
  cursor: 'pointer',
  fontSize: 12,
  transition: 'all ease 0.3s',
  textTransform: 'none',
  borderBottom: `1px solid ${theme.palette.secondary.dark}`,
}));

const FinancialTab: React.FC<BillTabProps> = ({ tab, handleChange }) => {
  const { checkPermission } = usePermissionRules();
  const [showMoviment, setShowMoviment] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showCharge, setShowCharge] = useState(false);

  return (
    <Tabs>
      {checkPermission('financial.resume') && (
        <CustomTab onClick={() => handleChange('resume')} value="resume" className={tab === 'resume' ? 'active' : ''}>
          Resumo
        </CustomTab>
      )}

      {checkPermission('financial.registration') && (
        <CustomTab
          onMouseOver={() => setShowRegister(true)}
          onMouseLeave={() => setShowRegister(false)}
          className={
            tab === 'register_appropriation-date' ||
            tab === 'totalizing_account' ||
            tab === 'register_expenses' ||
            tab === 'register_revenues'
              ? 'active'
              : ''
          }
        >
          Cadastro <ArrowDropDown />
          {showRegister && (
            <Span>
              {checkPermission('financial.appropriation-date') && (
                <MenuItem
                  className={tab === 'register_appropriation-date' ? 'active' : ''}
                  onClick={() => handleChange('register_appropriation-date')}
                >
                  Data de Apropriação
                </MenuItem>
              )}
              {checkPermission('financial.revenues') && (
                <MenuItem
                  className={tab === 'register_revenues' ? 'active' : ''}
                  onClick={() => handleChange('register_revenues')}
                >
                  Classificação de receitas
                </MenuItem>
              )}
              {checkPermission('financial.expenses') && (
                <MenuItem
                  className={tab === 'register_expenses' ? 'active' : ''}
                  onClick={() => handleChange('register_expenses')}
                >
                  Classificação de despesas
                </MenuItem>
              )}
              {checkPermission('financial.totalizing_account') && (
                <MenuItem
                  className={tab === 'totalizing_account' ? 'active' : ''}
                  onClick={() => handleChange('totalizing_account')}
                >
                  Conta totalizadora
                </MenuItem>
              )}
            </Span>
          )}
        </CustomTab>
      )}

      {checkPermission('financial.moviment') && (
        <CustomTab
          onMouseOver={() => setShowMoviment(true)}
          onMouseLeave={() => setShowMoviment(false)}
          className={
            tab === 'moviment_transfer-account' ||
            tab === 'moviment_extract' ||
            tab === 'moviment_titles' ||
            tab === 'moviment_expenses' ||
            tab === 'moviment_revenues' ||
            tab === 'moviment_fp'
              ? 'active'
              : ''
          }
        >
          Movimentação <ArrowDropDown />
          {showMoviment && (
            <Span>
              {checkPermission('financial.moviment.titles') && (
                <MenuItem
                  className={tab === 'moviment_titles' ? 'active' : ''}
                  onClick={() => handleChange('moviment_titles')}
                >
                  Títulos
                </MenuItem>
              )}

              {checkPermission('financial.moviment.expenses') && (
                <MenuItem
                  className={tab === 'moviment_expenses' ? 'active' : ''}
                  onClick={() => handleChange('moviment_expenses')}
                >
                  Despesas
                </MenuItem>
              )}

              {checkPermission('financial.moviment.revenues') && (
                <MenuItem
                  className={tab === 'moviment_revenues' ? 'active' : ''}
                  onClick={() => handleChange('moviment_revenues')}
                >
                  Receitas
                </MenuItem>
              )}

              {checkPermission('financial.moviment.fp') && (
                <MenuItem className={tab === 'moviment_fp' ? 'active' : ''} onClick={() => handleChange('moviment_fp')}>
                  FP
                </MenuItem>
              )}

              {checkPermission('financial.moviment.transfer-account') && (
                <MenuItem
                  className={tab === 'moviment_transfer-account' ? 'active' : ''}
                  onClick={() => handleChange('moviment_transfer-account')}
                >
                  Transferência entre contas
                </MenuItem>
              )}

              {checkPermission('financial.moviment.extract') && (
                <MenuItem
                  className={tab === 'moviment_extract' ? 'active' : ''}
                  onClick={() => handleChange('moviment_extract')}
                >
                  Extrato de contas
                </MenuItem>
              )}
            </Span>
          )}
        </CustomTab>
      )}

      {checkPermission('reports') && (
        <CustomTab
          onMouseOver={() => setShowReports(true)}
          onMouseLeave={() => setShowReports(false)}
          className={
            tab === 'report_query' ||
            tab === 'report_classification' ||
            tab === 'report_ticket' ||
            tab === 'report_card'
              ? 'active'
              : ''
          }
        >
          Relatórios <ArrowDropDown />
          {showReports && (
            <Span>
              <MenuItem
                className={tab === 'report_ticket' ? 'active' : ''}
                onClick={() => handleChange('report_ticket')}
              >
                Boleto
              </MenuItem>
              <MenuItem className={tab === 'report_card' ? 'active' : ''} onClick={() => handleChange('report_card')}>
                Cartão
              </MenuItem>
              {checkPermission('financial.report.query') && (
                <MenuItem
                  className={tab === 'report_query' ? 'active' : ''}
                  onClick={() => handleChange('report_query')}
                >
                  Financeira
                </MenuItem>
              )}
              {checkPermission('financial.report.classification') && (
                <MenuItem
                  className={tab === 'report_classification' ? 'active' : ''}
                  onClick={() => handleChange('report_classification')}
                >
                  Por classificação
                </MenuItem>
              )}
            </Span>
          )}
        </CustomTab>
      )}

      {checkPermission('financial.charge') && (
        <CustomTab
          onMouseOver={() => setShowCharge(true)}
          onMouseLeave={() => setShowCharge(false)}
          className={tab === 'charge_bills' || tab === 'charge_settings' ? 'active' : ''}
        >
          Cobrança <ArrowDropDown />
          {showCharge && (
            <Span>
              {checkPermission('financial.charge.bill_settings') && (
                <MenuItem
                  className={tab === 'charge_settings' ? 'active' : ''}
                  onClick={() => handleChange('charge_settings')}
                >
                  Configurações
                </MenuItem>
              )}
              {checkPermission('financial.charge.bills') && (
                <MenuItem
                  className={tab === 'charge_bills' ? 'active' : ''}
                  onClick={() => handleChange('charge_bills')}
                >
                  Remessa
                </MenuItem>
              )}
            </Span>
          )}
        </CustomTab>
      )}
    </Tabs>
  );
};

export default FinancialTab;
