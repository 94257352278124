import React from 'react';
import { OrderItems } from 'types/orderAuthorization';
import { Typography } from '@mui/material';
import { MoreTableTemplate } from './moreTableTemplate';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  quantityData: {
    display: 'flex',
    justifyContent: 'center',
  },
});

type MoreItemTableProps = {
  item: OrderItems;
};

const MoreItemTable: React.FC<MoreItemTableProps> = ({ item: orderItem }) => {
  const classes = useStyles();

  return (
    <>
      {MoreTableTemplate.filter(item => !item.notShow).map(item => (
        <div
          key={item.id}
          className={
            item.dataType === 'number'
              ? classes.numericData
              : item.dataType === 'quantity'
              ? classes.quantityData
              : undefined
          }
        >
          <Typography variant="body2">{orderItem[item.id as keyof OrderItems]}</Typography>
        </div>
      ))}
    </>
  );
};

export default MoreItemTable;
