import { createContext, useContext } from 'react';
import { EmailTemplate } from '../../../types/emailTemplate';

type EmailTemplateContextValue = {
  setSelectedEmailTemplate(emailTemplate: EmailTemplate): void;
  selectedEmailTemplate: EmailTemplate | null;
};

const EmailTemplatesContext = createContext<EmailTemplateContextValue>({} as EmailTemplateContextValue);
export const EmailTemplatesProvider = EmailTemplatesContext.Provider;

export function useEmailTemplates(): EmailTemplateContextValue {
  const context = useContext(EmailTemplatesContext);
  return context;
}
