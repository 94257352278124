import { Button, Theme } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { format, parseISO, endOfDay, subDays, startOfDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useSearch from 'hooks/search';
import useTableOrder from 'hooks/tableOrder';
import React, { useEffect, useState, useCallback } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import ResellersFilterBox from './CreditLimitRequestsFilterBox';
import { resellersTableTemplate } from './creditLimitRequestsTableTemplate';
import { CreditLimitRequestsProvider } from './hooks/useCreditLimitRequests';
import ResellerListModule from './list/module/CreditLimitRequestListModule';
import ResellerListTable from './list/table/CreditLimitRequestListTable';
import { cnpjFormatter } from 'helpers/cnpjFormatter';
import { CreditLimitRequest, CreditLimitRequestList } from 'types/creditLimitRequest';
import CreditLimitRequestApproval from './approval/CreditLimitRequestApproval';
import { useLocation } from 'react-router';
import { arraySort } from 'helpers/sort';
import { moneyFormat } from 'helpers/numberFormat';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

export interface CreditLimitRequestsFilter {
  initialDate: Date | null;
  finalDate: Date | null;
}

const CreditLimitRequests: React.FC = () => {
  const classes = useStyles();
  const [creditLimitRequests, setCreditLimitRequests] = useState<CreditLimitRequest[]>([]);
  const [selectedCreditLimitRequest, setSelectedCreditLimitRequest] = useState<null | CreditLimitRequest>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<CreditLimitRequest[]>([]);
  const [indexToSearch, setIndexToSearch] = useState('company_name');
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const search = useSearch();
  const app = useApp();
  const [filter, setFilter] = useState<CreditLimitRequestsFilter>({
    initialDate: startOfDay(subDays(new Date(), 30)),
    finalDate: endOfDay(new Date()),
  });
  const [status, setStatus] = useState<null | string>('completed');
  const location = useLocation();

  useEffect(() => {
    console.log(location);
    const urlParams = {
      '/completed-credit-requests': 'completed',
      '/analysed-credit-requests': 'approved',
    };

    const pathname = location.pathname as keyof typeof urlParams;

    setStatus(urlParams[pathname]);
  }, [location]);

  useEffect(() => {
    const _creditLimitRequests = search(searchValue, indexToSearch, creditLimitRequests);
    setFiltered(arraySort(orderedIndex.index, _creditLimitRequests, orderedIndex.direction));
  }, [creditLimitRequests, searchValue, search, indexToSearch, orderedIndex]);

  const handleFormatList = useCallback((creditLimitRequest: CreditLimitRequest): CreditLimitRequestList => {
    return {
      ...creditLimitRequest,
      formattedCreatedAt: format(parseISO(creditLimitRequest.created_at), 'PPp', { locale: ptBR }),
      formattedMustContact: creditLimitRequest.must_contact ? 'Sim' : 'Não',
      formattedContacted: creditLimitRequest.contacted ? 'Sim' : 'Não',
      formattedLimitValue: moneyFormat(creditLimitRequest.limit_value),
      company_name: creditLimitRequest.company_name.toUpperCase(),
      formattedCnpj: cnpjFormatter(creditLimitRequest.document),
    };
  }, []);

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    setLoading(true);

    api
      .get<CreditLimitRequestList[]>('/creditLimitRequests', {
        cancelToken: source.token,
        params: { status, ...filter },
      })
      .then(response => {
        if (request) setCreditLimitRequests(response.data.map(item => handleFormatList(item)));
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (request) setLoading(false);
      });

    return () => {
      if (request) request = false;
      source.cancel();
    };
  }, [status, handleFormatList, filter]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleRemoveFromList(creditLimitRequest: string) {
    setCreditLimitRequests(requests => requests.filter(request => request.id !== creditLimitRequest));
  }

  function handleChangeFilter(index: keyof CreditLimitRequestsFilter, value: Date | null) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleUpdateList(payload: CreditLimitRequest) {
    setCreditLimitRequests(state =>
      state.map(creditLimitRequest => {
        if (payload.id === creditLimitRequest.id) {
          return handleFormatList(payload);
        }
        return creditLimitRequest;
      }),
    );
  }

  return (
    <CreditLimitRequestsProvider
      value={{
        selectedCreditLimitRequest,
        setSelectedCreditLimitRequest,
        handleRemoveFromList,
        setCreditLimitRequests,
        handleFormatList,
        filter,
        handleChangeFilter,
        handleUpdateList,
      }}
    >
      {selectedCreditLimitRequest && (
        <CreditLimitRequestApproval onExited={() => setSelectedCreditLimitRequest(null)} />
      )}
      <Appbar title="Site" />
      <PageHeaderActions
        title="Análise de crédito"
        description="Aprove ou rejeite as solicitações de crédito"
        ActionComponent={
          <>
            <Button size="small" variant="contained" color="primary">
              Adicionar
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => window.open('/manual_cadastro.docx')}
            >
              Manual de aprovação
            </Button>
          </>
        }
      />
      <TableContainer tableTemplate={resellersTableTemplate}>
        <ResellersFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          indexToSearch={indexToSearch}
          setIndexToSearch={setIndexToSearch}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum parceiro para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <ResellerListTable creditLimitRequests={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <ResellerListModule creditLimitRequests={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </CreditLimitRequestsProvider>
  );
};

export default CreditLimitRequests;
