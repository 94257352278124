import React from 'react';
import { Typography } from '@mui/material';
import { ProductList } from 'types/product';
import { productTableTemplate } from '../../productTableTemplate';

type ProductItemProps = {
  product: ProductList;
};

const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
  return (
    <>
      {productTableTemplate.map(item => (
        <div key={item.id}>
          <Typography variant="body2">{product[item.id]}</Typography>
        </div>
      ))}
    </>
  );
};

export default ProductItem;
