import React, { useState } from 'react';
import { List, ListItem, TextField, Typography } from '@mui/material';
import Dialog, { DialogConsumer } from 'components/dialogs/Dialog';
import { ProductGroup } from 'types/product';
import useSearch from 'hooks/search';
import { Search } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

type ProductGroupListProps = {
  groups: ProductGroup[];
  onExited(): void;
  onSelect(group: ProductGroup): void;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    minHeight: 30,
    flexDirection: 'column',
    marginBottom: 5,
  },
});

const ProductGroupList: React.FC<ProductGroupListProps> = ({ groups, onExited, onSelect }) => {
  const classes = useStyles();
  const search = useSearch();
  const [filtered, setFiltered] = useState<ProductGroup[]>(groups);

  function handleSearch(value: string) {
    setFiltered(search(value, 'NOME', groups));
    if (!value) setFiltered(groups);
  }

  function handleClick(group: ProductGroup, handleClose: () => void) {
    onSelect(group);
    handleClose();
  }

  return (
    <Dialog onExited={onExited} maxWidth="sm" title="Grupos para produto">
      <TextField
        label="Pesquisa"
        placeholder="Digite sua pesquisa"
        margin="normal"
        onChange={e => handleSearch(e.target.value)}
        autoFocus
        InputProps={{
          startAdornment: <Search color="primary" />,
        }}
      />
      <DialogConsumer>
        {({ handleClose }) => (
          <List>
            {filtered.map(group => (
              <ListItem
                className={classes.listItem}
                button
                onClick={() => handleClick(group, handleClose)}
                key={group.CODIGO_GRUPO}
              >
                <Typography variant="body2">{group.NOME}</Typography>
              </ListItem>
            ))}
          </List>
        )}
      </DialogConsumer>
    </Dialog>
  );
};

export default ProductGroupList;
