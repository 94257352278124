import { Checkbox, FormControlLabel, Typography, styled } from '@mui/material';
import { useBranches } from 'providers/BranchesProviders';
import React from 'react';

const BranchesContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

interface UserBranchesProps {
  selectedBranchesId: number[];
  handleChangeSelectedId(id: number): void;
}

const UserBranches: React.FC<UserBranchesProps> = ({ selectedBranchesId, handleChangeSelectedId }) => {
  const { branches } = useBranches();

  return (
    <BranchesContent>
      <Typography gutterBottom color="textSecondary">
        Acesso do usuário a filiais
      </Typography>
      {branches.map(branch => (
        <FormControlLabel
          key={branch.id}
          control={
            <Checkbox
              checked={selectedBranchesId.some(id => branch.id === id)}
              onChange={() => handleChangeSelectedId(branch.id)}
              color="primary"
            />
          }
          label={branch.nome}
        />
      ))}
    </BranchesContent>
  );
};

export default UserBranches;
