import { PurchaseOrder, PurchaseOrderPaymentFormContent, PurchaseOrderProductFormContent } from 'types/purchaseOrder';
import { PurchaseOrderActions } from './types';

export function changePurchaseOrderAction(index: keyof PurchaseOrder, value: any): PurchaseOrderActions {
  return {
    type: 'CHANGE_PURCHASE_ORDER',
    index,
    value,
  };
}

export function setPurchaseOrderAction(order: PurchaseOrder): PurchaseOrderActions {
  return {
    type: 'SET_PURCHASE_ORDER',
    order,
  };
}

export function addPurchaseOrderProduct(
  product: PurchaseOrderProductFormContent,
  overwrite: boolean,
): PurchaseOrderActions {
  return {
    type: 'ADD_PURCHASE_ORDER_PRODUCT',
    product,
    overwrite,
  };
}

export function removePurchaseOrderProductAction(
  productId: number,
  index: number,
  action: 'update' | 'new',
): PurchaseOrderActions {
  return {
    type: 'REMOVE_PURCHASE_ORDER_PRODUCT',
    productId,
    action,
    index,
  };
}

export function addPurchaseOrderPayment(payment: PurchaseOrderPaymentFormContent): PurchaseOrderActions {
  return {
    type: 'ADD_PURCHASE_ORDER_PAYMENT',
    payment,
  };
}

export function removePurchaseOrderPaymentAction(index: number, action: 'update' | 'new'): PurchaseOrderActions {
  return {
    type: 'REMOVE_PURCHASE_ORDER_PAYMENT',
    index,
    action,
  };
}
