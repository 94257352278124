import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { WasteOnList } from '../types/waste';

export interface WastesContextValue {
  selectedWaste: WasteOnList | null;
  setSelectedWaste: Dispatch<SetStateAction<WasteOnList | null>>;
}

const Context = createContext<WastesContextValue>({} as WastesContextValue);
export const WasteProvider = Context.Provider;

export function useWastes(): WastesContextValue {
  return useContext(Context);
}
