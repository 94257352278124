import { AbcProductSegment, ComparedAbcProductSegment } from 'types/abcProduct';
import { AbcProductDates } from '../types';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { formatDates } from '../helpers/formatDates';

type HandleCompareManufacturesPayload = {
  segments: AbcProductSegment[];
  toCompare: AbcProductSegment[];
  dates: AbcProductDates;
  total: number;
};

type FormatPayload = {
  segments: ComparedAbcProductSegment[];
  initialDate: string;
  finalDate: string;
  total: number;
};

export function handleCompareSegments({
  segments,
  toCompare,
  dates,
  total,
}: HandleCompareManufacturesPayload): ComparedAbcProductSegment[] {
  const _dates = formatDates(dates);

  const _sellers = format({ segments, initialDate: _dates.initialDate, finalDate: _dates.finalDate, total });

  const _toCompare = format({
    segments: toCompare,
    initialDate: _dates.toCompareInitialDate,
    finalDate: _dates.toCompareFinalDate,
    total,
  });

  return merge(_sellers, _toCompare);
}

function format(payload: FormatPayload): AbcProductSegment[] {
  return payload.segments.map(item => ({
    ...item,
    formattedTotal: moneyFormat(item.total),
    formattedCoust: moneyFormat(item.custo),
    formattedMc: moneyFormat(item.mc),
    formattedSpiff: moneyFormat(item.spiff),
    formattedUnitary: moneyFormat(item.unitario),
    formattedBilling: percentFormat((item.total / payload.total) * 100),
    billing: (item.total / payload.total) * 100,
    formattedName: `${payload.initialDate} - ${payload.finalDate}`,
  }));
}

function merge(segments: AbcProductSegment[], toCompare: AbcProductSegment[]): ComparedAbcProductSegment[] {
  const merged = [...segments, ...toCompare];

  const singleNames = new Set(merged.map(item => item.segmento));

  const names = [...singleNames].map(item => ({
    segmento: item,
    type: 'subtitle',
  })) as AbcProductSegment[];

  return [...names, ...merged];
}
