import { TableTemplate } from 'types/tableTemplate';

export const managedSalesTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'Ações',
    originalId: 'actions',
    width: 70,
  },
  {
    id: 'customer_document_number',
    description: 'CNPJ',
    originalId: 'document_number',
    width: 150,
  },
  {
    id: 'customer_name',
    description: 'Cliente',
    originalId: 'customer_name',
    width: 400,
  },
  {
    id: 'formattedCommissionTotal',
    description: 'Comissão',
    originalId: 'commission_total',
    width: 150,
    dataType: 'number',
  },
  {
    id: 'formattedTotal',
    description: 'Total',
    originalId: 'total',
    width: 150,
    dataType: 'number',
  },
  {
    id: 'formattedActive',
    description: 'Ativo',
    originalId: 'active',
    width: 100,
  },
  {
    id: 'formattedStartAt',
    description: 'Inicia em',
    originalId: 'formattedStartAt',
    width: 150,
  },
  {
    id: 'formattedEndAt',
    description: 'Termina em',
    originalId: 'formattedEndAt',
    width: 150,
  },
  {
    id: 'formattedIsPaid',
    description: 'Pago',
    originalId: 'formattedIsPaid',
    width: 80,
  },
  {
    id: 'formattedPaidAt',
    description: 'Data do pagamento',
    originalId: 'formattedPaidAt',
    width: 150,
  },
  {
    id: 'financial_data',
    description: 'Lançamento financeiro',
    originalId: 'financial_data',
    width: 200,
  },
  {
    id: 'external_id',
    description: 'número de identificação',
    originalId: 'external_id',
    width: 200,
  },
  {
    id: 'formattedCreatedAt',
    description: 'Feito em',
    originalId: 'formattedCreatedAt',
    width: 150,
  },
];
