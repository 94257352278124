import { CompatibilityProduct } from 'pages/compatibilities/types/compatibilityProduct';
import { Dispatch, SetStateAction, useState } from 'react';
import { ProjectProductFormContent } from 'types/project';
import * as yup from 'yup';

export type ProjectProductValidation = {
  id?: string;
  name?: string;
};

type UseProjectProductValidation = [
  ProjectProductValidation,
  Dispatch<SetStateAction<ProjectProductValidation>>,
  (product: ProjectProductFormContent, products: CompatibilityProduct[]) => Promise<void>,
];

export function useCompatibilityProductValidation(): UseProjectProductValidation {
  async function handleValidation(product: ProjectProductFormContent, products: CompatibilityProduct[]) {
    const schema = yup.object().shape({
      id: yup
        .string()
        .required('O código do produto é obrigatório')
        .test('exist_product', 'esse produto já está inserido', value => {
          console.log('validation');
          return !products.some(_product => _product.id === value);
        }),
      name: yup.string().required('o nome do produto é obrigatório'),
    });

    try {
      await schema.validate(product);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<ProjectProductValidation>({});
  return [validation, setValidation, handleValidation];
}
