import React from 'react';
import SidebarItem from '../SidebarItem';
import { Collapse } from '@mui/material';
import { useSidebar } from '../hook/useSidebar';
import { usePermissionRules } from 'hooks/permissionRules';
import SidebarItemExpendable from '../SidebarItemExpendable';
import { Inventory, Recycling, Sell } from '@mui/icons-material';

const Waste: React.FC = () => {
  const { checkPermission } = usePermissionRules();
  const { handleCollapseClick, collapsible } = useSidebar();

  return (
    <>
      {checkPermission('reee') && (
        <>
          <SidebarItemExpendable
            onClick={() => handleCollapseClick('waste')}
            icon={<Recycling />}
            text="REEE"
            opened={collapsible.waste}
          />
          <Collapse in={collapsible.waste}>
            <SidebarItem to="/waste" icon={<Sell />} text="Compra de lixo" nested={5} />
            <SidebarItem to="/stock/waste" icon={<Inventory />} text="Saldo de Estoque" nested={5} />
          </Collapse>
        </>
      )}
    </>
  );
};

export default Waste;
