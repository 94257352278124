import React from 'react';
import { Typography, Theme } from '@mui/material';
import { DashboardTotal } from './hooks/useFetchCommercialReport';
import { DateRange, Money, Storefront, ThumbUpAlt } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';
import { FaChartArea, FaTicketAlt, FaTruck } from 'react-icons/fa';

const useStyles = makeStyles<Theme>(theme => ({
  total: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    columnGap: 7,
    marginBottom: 5,
    '& > div': {
      background: '#fff',
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #eee',
      justifyContent: 'space-between',
      marginBottom: 5,
      '& > div > span': {
        fontWeight: 500,
        fontSize: 22,
        color: '#159f06',
      },
      '& .icon': {
        width: 50,
        height: 50,
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        backgroundColor: theme.palette.primary.light,
        color: '#fff',
      },
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

type DashboardReportTotalProps = {
  formattedTotal: DashboardTotal;
};

const DashboardReportTotal: React.FC<DashboardReportTotalProps> = ({ formattedTotal }) => {
  const classes = useStyles();

  return (
    <div className={classes.total}>
      <div>
        <div>
          <Typography>Fat. liquído mês</Typography>
          <span>{formattedTotal.liquid}</span>
        </div>
        <span className="icon">
          <ThumbUpAlt />
        </span>
      </div>
      <div>
        <div>
          <Typography>Fat. liquído dia</Typography>
          <span>{formattedTotal.liquid_day}</span>
        </div>
        <span className="icon">
          <DateRange />
        </span>
      </div>
      <div>
        <div>
          <Typography>Fat. coligado mês</Typography>
          <span>{formattedTotal.others}</span>
        </div>
        <span className="icon">
          <FaChartArea />
        </span>
      </div>
      <div>
        <div>
          <Typography>Fat. coligado dia</Typography>
          <span>{formattedTotal.others_day}</span>
        </div>
        <span className="icon">
          <DateRange />
        </span>
      </div>

      <div>
        <div>
          <Typography>MC</Typography>
          <span>{formattedTotal.mc}</span>
        </div>
        <span className="icon">
          <Money />
        </span>
      </div>
      <div>
        <div>
          <Typography>Ticket Médio</Typography>
          <span>{formattedTotal.ticket}</span>
        </div>
        <span className="icon">
          <FaTicketAlt />
        </span>
      </div>
      <div>
        <div>
          <Typography>Estoque</Typography>
          <span>{formattedTotal.stock}</span>
        </div>
        <span className="icon">
          <Storefront />
        </span>
      </div>
      <div>
        <div>
          <Typography>Frete</Typography>
          <span>{formattedTotal.shipment}</span>
        </div>
        <span className="icon">
          <FaTruck />
        </span>
      </div>
    </div>
  );
};

export default DashboardReportTotal;
