import React, { useState, useEffect } from 'react';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';

import NoData from 'components/no-data/NoData';
import { moneyFormat } from 'helpers/numberFormat';
import { MonthlyPeriod } from 'types/monthlyComparison';
import InsideLoading from 'components/loading/InsideLoading';
import PeriodListTable from '../../period/list/table/PeriodListTable';
import { useMonthlyComparison } from '../../hooks/useMonthlyComparison';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { makeStyles } from '@mui/styles';

interface BranchMoreMenuProps {
  branchId: number;
}

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      margin: 0,
      minHeight: 0,
      overflow: 'visible',
    },
  },
});

const BranchMoreMenu: React.FC<BranchMoreMenuProps> = ({ branchId }) => {
  const classes = useStyles();
  const { h2iApi } = useApp();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const { filter, searchValue } = useMonthlyComparison();
  const [periods, setPeriods] = useState<MonthlyPeriod[]>([]);
  const [filtered, setFiltered] = useState<MonthlyPeriod[]>([]);

  useEffect(() => {
    setFiltered(periods);
  }, [periods]);

  function handleSort(index: string) {
    const filteredS = sort(index, filtered);
    setFiltered(filteredS);
  }

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    h2iApi
      .get('/api/relcomparativomensal', {
        params: {
          id_subgrupo: filter.productId || '',
          filtro: searchValue,
          tipo_venda: filter.typeSale,
          id_filial: branchId,
          id_segmento: filter.segment || '',
          id_unidade: filter.unity || '',
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
        },
      })
      .then(response => {
        const _response = response.data[0];
        const periodos: MonthlyPeriod[] = _response.periodo;

        const _periods = periodos.map(item => {
          item.formattedTotal = moneyFormat(item.total);
          item.formattedCoust = moneyFormat(item.custo);
          item.formattedMc = moneyFormat(item.mc);
          item.formattedSpiff = moneyFormat(item.spiff);
          item.formattedUnitary = moneyFormat(item.unitario);

          return item;
        });

        setPeriods(_periods);
      })
      .finally(() => setLoading(false));
  }, [h2iApi, filter, searchValue, branchId]);

  return (
    <div className={classes.div}>
      {loading ? (
        <InsideLoading />
      ) : filtered.length === 0 ? (
        <NoData message="Nenhuma período encontrado." />
      ) : (
        <PeriodListTable isSubgroup={false} orderedIndex={orderedIndex} periods={filtered} handleSort={handleSort} />
      )}
    </div>
  );
};

export default BranchMoreMenu;
