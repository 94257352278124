import React from 'react';
import { List } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { SaleActivity } from 'types/reports/saleActivity';
import SaleActivityItemModule from './saleActivityItemModule';

import { makeStyles } from '@mui/styles';

type SaleActivityListModuleProps = {
  salesActivity: SaleActivity[];
};

const styles = makeStyles({
  list: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: 100,
    flex: 1,
  },
});

const SaleActivityListModule: React.FC<SaleActivityListModuleProps> = ({ salesActivity }) => {
  const { rowsPerPage, page } = usePagination();
  const classes = styles();

  return (
    <List className={classes.list}>
      {salesActivity.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(saleActivity => (
        <SaleActivityItemModule key={saleActivity.atividade} saleActivity={saleActivity} />
      ))}
    </List>
  );
};

export default SaleActivityListModule;
