import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { useBranches } from '../../hooks/useSaleActivity';
import { moneyFormat } from 'helpers/numberFormat';
import { SaleActivity } from 'types/reports/saleActivity';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type SaleActivityItemModuleProps = {
  saleActivity: SaleActivity;
};

const SaleActivityItemModule: React.FC<SaleActivityItemModuleProps> = ({ saleActivity }) => {
  const classes = useStyles();
  const { setSelectedSaleActivity } = useBranches();

  function handleClick() {
    setSelectedSaleActivity(saleActivity);
  }

  return (
    <>
      <ListItem button className={classes.listItem} onClick={handleClick}>
        <Typography variant="caption" color="primary">
          Atividade - {saleActivity.atividade}
        </Typography>
        <Typography variant="caption" color="primary">
          Quantidade - {saleActivity.qtd}
        </Typography>
        <Typography gutterBottom>Valor total - {moneyFormat(saleActivity.vl_total)}</Typography>
      </ListItem>
    </>
  );
};

export default SaleActivityItemModule;
