import React from 'react';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { Button, IconButton, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SelectBranch from 'components/select-branches/SelectBranch';
import { FilterDashboardCommercial } from 'pages/dashboard/commercial/hooks/useFetchCommercialReport';

type DashboardReportHeaderButtonsProps = {
  loading: boolean;
  handleDateConfig(days: number): void;
  dateConfig: number | null;
  setAnchorEl(element: HTMLButtonElement): void;
  filter: FilterDashboardCommercial;
  handleChangeFilter(index: keyof FilterDashboardCommercial, value: any): void;
};

const useStyles = makeStyles({
  buttons: {
    gap: 8,
    display: 'flex',
  },
});

const DashboardReportHeaderButtons: React.FC<DashboardReportHeaderButtonsProps> = ({
  loading,
  handleDateConfig,
  dateConfig,
  setAnchorEl,
  filter,
  handleChangeFilter,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.buttons}>
        <SelectBranch
          branch_id={filter.branch_id}
          handleChange={e => handleChangeFilter('branch_id', e)}
          loading={loading}
        />

        <Select
          disabled={loading}
          label="tipo de venda"
          value={filter.type_sale}
          onChange={e => handleChangeFilter('type_sale', e.target.value)}
        >
          <MenuItem value="T">Todas</MenuItem>
          <MenuItem value="C">Coligadas</MenuItem>
          <MenuItem value="V">Vendas</MenuItem>
        </Select>
        <Button
          disabled={loading}
          variant="text"
          size="small"
          color={dateConfig === 0 ? 'primary' : 'inherit'}
          onClick={() => handleDateConfig(0)}
        >
          Hoje
        </Button>
        <Button
          disabled={loading}
          variant="text"
          size="small"
          color={dateConfig === 15 ? 'primary' : 'inherit'}
          onClick={() => handleDateConfig(15)}
        >
          15 dias
        </Button>
        <Button
          disabled={loading}
          variant="text"
          size="small"
          color={dateConfig === 30 ? 'primary' : 'inherit'}
          onClick={() => handleDateConfig(30)}
        >
          30 dias
        </Button>
        <Button
          disabled={loading}
          variant="text"
          size="small"
          color={dateConfig === 60 ? 'primary' : 'inherit'}
          onClick={() => handleDateConfig(60)}
        >
          60 dias
        </Button>
        <IconButton
          disabled={loading}
          color={dateConfig === null ? 'primary' : 'inherit'}
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          <CalendarIcon />
        </IconButton>
      </div>
    </>
  );
};

export default DashboardReportHeaderButtons;
