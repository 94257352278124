import React, { useEffect, useRef, useState } from 'react';
import { FormControlLabel, InputAdornment, Switch, TextField, styled } from '@mui/material';
import { User } from './reducer/types';
import PhoneInput from 'components/masked-input/PhoneInput';
import CpfInput from 'components/masked-input/CpfInput';
import MaskedInput from 'react-text-mask';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { api } from 'services/api';
import { UserValidation } from './validation/userValidation';

const PasswordContent = styled('div')({
  display: 'flex',
  columnGap: 15,
});

type UserFormProps = {
  user: User;
  handleChange(index: string, value: string | boolean): void;
  validation: UserValidation;
  type: 'edit' | 'new';
};

const UserForm: React.FC<UserFormProps> = ({ user, handleChange, validation, type }) => {
  const [passVisibility, setPassVisibility] = useState(false);
  const [passConfirmVisibility, setPassConfimVisibility] = useState(false);
  const [usernameError, setUsernameError] = useState('');

  const inputs = {
    username: useRef<HTMLInputElement>(null),
    name: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    document_number: useRef<MaskedInput>(null),
    password: useRef<HTMLInputElement>(null),
    password_confirmation: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) {
      return;
    }

    console.log(key);

    if (key === 'document_number') {
      inputs.document_number.current?.inputElement.focus();
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  function checkUsername(username: string) {
    if (!username) return;

    api.get(`/users/username/${username}`).then(response => {
      if (response.data) {
        setUsernameError('Nome de usuário indisponível');
        return;
      }

      setUsernameError('');
    });
  }

  return (
    <>
      <TextField
        autoComplete="off"
        inputRef={inputs.username}
        error={!!validation.username || !!usernameError}
        helperText={validation.username || usernameError}
        label="Nome de usuário"
        placeholder="Digite o nome do usuário"
        margin="normal"
        autoFocus
        fullWidth
        value={user.username}
        onChange={e => handleChange('username', e.target.value)}
        onBlur={e => (type === 'new' && checkUsername ? checkUsername(e.target.value) : undefined)}
        disabled={type === 'edit'}
      />

      <TextField
        autoComplete="off"
        inputRef={inputs.email}
        error={!!validation.email}
        helperText={validation.email}
        label="E-mail"
        placeholder="Digite o e-mail"
        margin="normal"
        fullWidth
        value={user.email}
        onChange={e => handleChange('email', e.target.value)}
        disabled={type === 'edit'}
      />

      <TextField
        autoComplete="off"
        inputRef={inputs.name}
        error={!!validation.name}
        helperText={validation.name}
        label="Nome"
        placeholder="Digite o nome"
        margin="normal"
        fullWidth
        value={user.name}
        onChange={e => handleChange('name', e.target.value)}
      />

      <TextField
        autoComplete="off"
        label="Telefone fixo"
        placeholder="Digite o número do telefone fixo"
        margin="normal"
        fullWidth
        value={user.fixed_phone}
        onChange={e => handleChange('fixed_phone', e.target.value)}
        InputProps={{
          inputComponent: PhoneInput as any,
        }}
      />

      <TextField
        autoComplete="off"
        label="Celular"
        placeholder="Digite o número do celular"
        margin="normal"
        fullWidth
        value={user.mobile_phone}
        onChange={e => handleChange('mobile_phone', e.target.value)}
        InputProps={{
          inputComponent: PhoneInput as any,
        }}
      />

      <TextField
        autoComplete="off"
        label="Cargo"
        placeholder="Digite o cargo"
        margin="normal"
        fullWidth
        value={user.role}
        onChange={e => handleChange('role', e.target.value)}
      />

      <TextField
        autoComplete="off"
        inputRef={inputs.document_number}
        error={!!validation.document_number}
        helperText={validation.document_number}
        label="CPF"
        placeholder="Digite o CPF"
        margin="normal"
        fullWidth
        value={user.document_number}
        onChange={e => handleChange('document_number', e.target.value)}
        InputProps={{
          autoComplete: 'off',
          inputComponent: CpfInput as any,
        }}
      />

      <PasswordContent>
        <TextField
          autoComplete="off"
          inputRef={inputs.password}
          error={!!validation.password}
          helperText={validation.password}
          label="Senha"
          placeholder="Senha"
          margin="normal"
          fullWidth
          value={user.password}
          onChange={e => handleChange('password', e.target.value)}
          type={passVisibility ? 'text' : 'password'}
          InputProps={{
            autoComplete: 'off',
            endAdornment: (
              <InputAdornment position="start">
                {passVisibility ? (
                  <VisibilityIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setPassVisibility(state => !state)}
                    color="primary"
                  />
                ) : (
                  <VisibilityOffIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setPassVisibility(state => !state)}
                    color="primary"
                  />
                )}
              </InputAdornment>
            ),
          }}
        />

        <TextField
          autoComplete="off"
          inputRef={inputs.password_confirmation}
          error={!!validation.password_confirmation}
          helperText={validation.password_confirmation}
          label="Confirmação da senha"
          placeholder="Confirmação da senha"
          margin="normal"
          fullWidth
          value={user.password_confirmation}
          onChange={e => handleChange('password_confirmation', e.target.value)}
          type={passConfirmVisibility ? 'text' : 'password'}
          InputProps={{
            autoComplete: 'off',
            endAdornment: (
              <InputAdornment position="start">
                {passConfirmVisibility ? (
                  <VisibilityIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setPassConfimVisibility(state => !state)}
                    color="primary"
                  />
                ) : (
                  <VisibilityOffIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setPassConfimVisibility(state => !state)}
                    color="primary"
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
      </PasswordContent>

      <FormControlLabel
        control={
          <Switch
            checked={user.active}
            onChange={event => handleChange('active', event.target.checked)}
            color="primary"
          />
        }
        label="Ativo"
      />
    </>
  );
};

export default UserForm;
