import { styled } from '@mui/material';
import NoData from 'components/no-data/NoData';
import React, { useEffect, useState, useMemo } from 'react';
import { ProductsContextProvider } from './hooks/useProducts';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import ProductListTable from './list/table/ProductListTable';
import ProductListModule from './list/module/ProductListModule';
import ProjectProductForm from './ManagedSaleProductForm';
import { useRentalProductValidation } from './validation/projectProductValidation';
import { useManagedSale } from '../hooks/useManagedSale';
import { RentalProduct, RentalProductFormContent } from 'pages/rentals/types/rental';
import { addRentalProduct } from 'pages/managed-sales/reducer/actions';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const ManagedSaleProducts: React.FC = () => {
  const { isMobile, windowWidth } = useApp();
  const { rental, dispatch } = useManagedSale();
  const [filteredProducts, setFilteredProducts] = useState<RentalProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<RentalProduct | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const displayMode = useMemo(() => (isMobile || windowWidth < 930 ? 'module' : 'list'), [isMobile, windowWidth]);
  const [validation, setValidation, validate] = useRentalProductValidation();

  useEffect(() => {
    setFilteredProducts(rental.products);
  }, [rental.products]);

  function handleSort(index: string) {
    const p = sort(index, filteredProducts);
    setFilteredProducts(p);
  }

  async function handleValidation(data: RentalProductFormContent) {
    setValidation({});

    await validate(data);
    handleSubmit(data);
  }

  function handleSubmit(data: RentalProductFormContent) {
    dispatch(addRentalProduct(data));
  }

  return (
    <Container>
      <ProjectProductForm validation={validation} handleSubmit={handleValidation} />

      {filteredProducts.length === 0 ? (
        <NoData message="Nenhum produto para mostrar." />
      ) : (
        <ProductsContextProvider value={{ selectedProduct, setSelectedProduct }}>
          {displayMode === 'list' ? (
            <ProductListTable products={filteredProducts} handleSort={handleSort} orderedIndex={orderedIndex} />
          ) : (
            <ProductListModule products={filteredProducts} />
          )}
        </ProductsContextProvider>
      )}
    </Container>
  );
};

export default ManagedSaleProducts;
