import { createContext, useContext } from 'react';
import { SaleActivity } from 'types/reports/saleActivity';
import { SaleActivityFilter } from '../saleActivityReport';

type SalesActivityContextValue = {
  selectedSaleActivity: SaleActivity | null;
  setSelectedSaleActivity(partner: SaleActivity | null): void;
  handleChangeFilter(index: keyof SaleActivityFilter, value: any): void;
  filter: SaleActivityFilter;
};

const SaleActivityContext = createContext<SalesActivityContextValue>({} as SalesActivityContextValue);
export const SaleActivityProvider = SaleActivityContext.Provider;

export function useBranches(): SalesActivityContextValue {
  const context = useContext(SaleActivityContext);
  return context;
}
