import React, { useState } from 'react';
import CostItemModule from './RentalPaymentItemModule';
import List from 'components/list/List';
import ProjectCostsListMenu from '../RentalPaymentListMenu';
import { RentalPayment } from 'pages/rentals/types/rental';

type RentalListModuleProps = {
  payments: RentalPayment[];
};

const RentalListModule: React.FC<RentalListModuleProps> = ({ payments }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <>
      <ProjectCostsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <List>
        {payments.map(payment => (
          <CostItemModule setAnchorEl={setAnchorEl} key={payment.external_id} payment={payment} />
        ))}
      </List>
    </>
  );
};

export default RentalListModule;
