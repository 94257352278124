import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { FinancialResumeFilterProps, Ordination } from '../FinancialResume';
import { FinancialResume, FinancialResumeReport } from 'types/financialResume';
import { UseFinancialResumeTotal } from './useFinancialResumeTotal';
import { GroupedTitle } from 'types/groupedTitle';

export interface FinancialResumeContextValue {
  handleFilterChange(index: keyof FinancialResumeFilterProps, value: any): void;
  filter: FinancialResumeFilterProps;
  financialResume: FinancialResume[];
  groupedTitles: GroupedTitle[];
  report: FinancialResumeReport[];
  loading: boolean;
  handleSubmit(initialDate: Date, finalDate: Date): void;
  totals: UseFinancialResumeTotal;
  ordination: Ordination;
  setOrdination: Dispatch<SetStateAction<Ordination>>;
  setIsOpenFilterMore: Dispatch<SetStateAction<boolean>>;
  isOpenFilterMore: boolean;
}

const FinancialResumeContext = createContext<FinancialResumeContextValue>({} as FinancialResumeContextValue);
export const InvoicesResumeProvider = FinancialResumeContext.Provider;
export const InvoicesResumeConsumer = FinancialResumeContext.Consumer;

export function useFinancialResume(): FinancialResumeContextValue {
  return useContext(FinancialResumeContext);
}
