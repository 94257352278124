import React, { FormEvent, useState } from 'react';
import SerialNumberActions from './actions/SerialNumberActions';
import { Typography, styled } from '@mui/material';
import SerialNumberForm from './SerialNumberForm';
import Dialog from 'components/dialogs/Dialog';
import { useMessaging } from 'hooks/messaging';
import SerialNumberListTable from './list/table/SerialNumberListTable';
import NoData from 'components/no-data/NoData';
import { WasteSelectProduct } from '../products/WasteProducts';
import { SerialNumbers } from 'pages/material-residues/waste/types/waste';

interface SerialNumberDialogProps {
  onExited(): void;
  quantity: string;
  onSubmit(): void;
  serialNumbers: SerialNumbers[];
  onChange(index: keyof WasteSelectProduct, value: any): void;
}

const Header = styled('div')({
  display: 'flex',
  gap: 15,
  justifyContent: 'space-between',
});

const Box = styled('div')({
  flex: 1,
  padding: 5,
  textAlign: 'start',
});

const Form = styled('form')({
  flex: 0,
});

const Container = styled('div')({
  flex: 1,
  marginTop: 10,
  display: 'flex',
});

const SerialNumberDialog: React.FC<SerialNumberDialogProps> = ({
  onExited,
  onChange,
  serialNumbers,
  quantity,
  onSubmit,
}) => {
  const { handleOpen } = useMessaging();
  const [validation, setValidation] = useState('');
  const [serialNumber, setSerialNumber] = useState<string>('');

  async function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation('');
    if (serialNumbers.length === parseInt(quantity)) {
      setValidation('Atingido o limite de número de série');
    } else if (!serialNumber) {
      setValidation('Digite o número de série');
    } else if (serialNumbers.map(item => item.numero_serie.toString()).includes(serialNumber)) {
      setValidation('Número de série já cadastrado');
    } else {
      console.log(serialNumbers.map(item => item.numero_serie));
      await onChange('serialNumbers', [...serialNumbers, { id: 0, numero_serie: serialNumber }]);
      setSerialNumber('');
    }
  }

  function onDelete(index: number) {
    if (index < 0 || index >= serialNumbers.length) {
      handleOpen('Não é possível excluir o elemento.');
      return;
    }

    const newArray = [...serialNumbers];
    newArray.splice(index, 1);

    onChange('serialNumbers', newArray);
  }

  function handleSubmit() {
    if (parseInt(quantity) - serialNumbers.length === 0) {
      onSubmit();
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      height="70vh"
      onExited={onExited}
      title="Informe o número de série"
      ComponentActions={<SerialNumberActions handleSubmit={handleSubmit} />}
    >
      <Header>
        <Box>
          <Typography variant="subtitle2" fontWeight={400} fontSize={15}>
            Quantidade: <b>{parseInt(quantity)}</b>
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" fontWeight={400} fontSize={15}>
            Restante: <b>{parseInt(quantity) - serialNumbers.length}</b>
          </Typography>
        </Box>
      </Header>

      <Form onSubmit={handleValidation}>
        <SerialNumberForm
          validation={validation}
          onChange={value => setSerialNumber(value)}
          onSubmit={handleValidation}
          serialNumber={serialNumber}
        />
      </Form>

      <Container>
        {serialNumbers.length === 0 ? (
          <NoData message="Nenhum número de série adicionado." />
        ) : (
          <SerialNumberListTable onDelete={onDelete} serialNumbers={serialNumbers} />
        )}
      </Container>
    </Dialog>
  );
};

export default SerialNumberDialog;
