import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useManagedSale } from '../../hooks/useManagedSale';
import { removeRentalProductAction } from 'pages/managed-sales/reducer/actions';
import { useProducts } from '../hooks/useProducts';

type ManagedSaleProductsListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const ManagedSaleProductsListMenu: React.FC<ManagedSaleProductsListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedProduct } = useProducts();
  const { dispatch } = useManagedSale();

  function handleDeleteClick() {
    if (!selectedProduct) {
      return;
    }

    dispatch(removeRentalProductAction(selectedProduct.external_id));
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default ManagedSaleProductsListMenu;
