import React from 'react';
import { Typography, lighten, styled } from '@mui/material';
import { H2iOrder } from 'types/h2iOrder';
import history from 'services/history';

type UseStylesProps = {
  waitingPayment: boolean;
};

const Li = styled('li')<UseStylesProps>(props => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 15px',
  border: '1px solid #eee',
  backgroundColor: props.waitingPayment ? props.theme.palette.error.main : props.theme.palette.success.main,
  borderRadius: 0,
  transition: 'background 0.3s ease',
  cursor: 'pointer',
  position: 'relative',
  color: props.waitingPayment ? '#fff' : 'inherit',
  '&:hover': {
    backgroundColor: props.waitingPayment ? lighten(props.theme.palette.error.main, 0.1) : '#fff',
  },
  '& > .header': {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 14,
    fontWeight: 300,
    marginBottom: 10,
  },
}));

const ViewPayment = styled(Typography)({
  display: 'inline-flex',
  padding: '4px 10px',
  alignSelf: 'flex-start',
  marginTop: 10,
  borderRadius: 4,
  background: 'rgba(0,0,0,0.3)',
  fontWeight: 400,
});

const Total = styled(Typography)({
  position: 'absolute',
  bottom: 10,
  right: 15,
});

interface DavReviewItemModuleProps {
  order: H2iOrder;
}

const DavReviewItemModule: React.FC<DavReviewItemModuleProps> = ({ order }) => {
  function handleClick() {
    history.push(`/review/dav/${order.id}/${order.id_filial}`);
  }

  return (
    <Li waitingPayment={!order.pago} key={order.id} onClick={handleClick}>
      <div className="header">
        <Typography color="#fff" fontWeight={400}>
          pedido {order.id}
        </Typography>
        <Typography color="#fff">{order.formattedCreatedAt}</Typography>
      </div>
      <Typography color="#fff">
        modalidade de frete
        {order.modalidade_entrega === 'entrega' ? <strong> entrega</strong> : <strong> retirada</strong>}
      </Typography>
      <Typography color="#fff" fontSize={12}>
        {order.itens.length} produto{order.itens.length === 1 ? '' : 's'} nesse pedido
      </Typography>
      <Total color="#fff" fontSize={24}>
        {order.formattedValorTotal}
      </Total>
      <ViewPayment color="#fff" fontSize={12}>
        confira o pagamento
      </ViewPayment>
    </Li>
  );
};

export default DavReviewItemModule;
