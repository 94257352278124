import React, { useRef } from 'react';
import { TotalizingAccountValidation } from '../validation/totalizingAccountValidation';
import { TextField, Typography } from '@mui/material';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';
import { TotalizingAccount } from 'types/totalizingAccount';
import NumberFormat from 'react-number-format';

interface TotalizingAccountFormProps {
  account: TotalizingAccount;
  handleChange(index: keyof TotalizingAccount, value: any): void;
  validation: TotalizingAccountValidation;
}

const TotalizingAccountForm: React.FC<TotalizingAccountFormProps> = ({ account, handleChange, validation }) => {
  const inputs = {
    conta_contabil: useRef<HTMLInputElement>(null),
    saldo: useRef<HTMLInputElement>(null),
    nome: useRef<HTMLInputElement>(null),
  };

  return (
    <>
      <SelectOthersBranch
        selectStyle={{ maxHeight: 40 }}
        viewAllBranches={false}
        branch_id={account.id_filial}
        handleChange={branchId => handleChange('id_filial', branchId)}
      />

      <TextField
        value={account.nome}
        placeholder="Digite o nome"
        label="Nome"
        autoFocus
        inputRef={inputs.nome}
        error={!!validation.nome}
        helperText={validation.nome}
        onChange={e => handleChange('nome', e.target.value)}
      />

      <Typography variant="caption">Saldo (R$):</Typography>
      <NumberFormat
        disabled
        style={{
          padding: '4px 0 5px',
          font: 'inherit',
          fontSize: 16,
          border: 'none',
          borderBottom: '1px solid #666',
          width: '100%',
        }}
        thousandsGroupStyle="thousand"
        prefix="R$ "
        decimalSeparator=","
        thousandSeparator="."
        fixedDecimalScale
        decimalScale={2}
        value={account.saldo}
        inputMode="decimal"
        isNumericString
      />
      {!!validation.saldo && (
        <Typography fontSize={13} variant="caption" color="red">
          {validation.saldo}
        </Typography>
      )}

      <TextField
        type="number"
        inputMode="numeric"
        value={account.conta_contabil}
        onChange={e => handleChange('conta_contabil', e.target.value)}
        label="Cód Contábil"
        placeholder="Informe o código contábil"
      />
      <button type="submit" style={{ display: 'none' }}></button>
    </>
  );
};

export default TotalizingAccountForm;
