import React from 'react';
import { List } from '@mui/material';
import { usePagination } from 'hooks/pagination';

import CategoryItemModule from './CategoryItemModule';
import { MonthlyCategory } from 'types/monthlyComparison';

import { makeStyles } from '@mui/styles';

type CategoryListModuleProps = {
  categories: MonthlyCategory[];
};

const styles = makeStyles({
  list: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: 100,
    flex: 1,
  },
});

const CategoryListModule: React.FC<CategoryListModuleProps> = ({ categories }) => {
  const { rowsPerPage, page } = usePagination();
  const classes = styles();

  return (
    <List className={classes.list}>
      {categories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(category => (
        <CategoryItemModule key={category.id} category={category} />
      ))}
    </List>
  );
};

export default CategoryListModule;
