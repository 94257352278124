import { TableTemplate } from 'types/tableTemplate';

export const fiscalCodeTableTemplate: TableTemplate[] = [
  {
    id: 'code',
    description: 'CFOP',
    originalId: 'code',
    width: 60,
  },
  {
    id: 'name',
    description: 'Nome',
    originalId: 'name',
    width: 180,
  },
  {
    id: 'type',
    description: 'Tipo',
    originalId: 'type',
    width: 70,
  },
  {
    id: 'cst_pis',
    description: 'CST PIS',
    originalId: 'cst_pis',
    width: 70,
  },
  {
    id: 'cst_cofins',
    description: 'CST COFINS',
    originalId: 'cst_cofins',
    width: 90,
  },
  {
    id: 'formattedHasIcms',
    description: 'Gera ICMS',
    originalId: 'has_icms',
    width: 70,
  },
  {
    id: 'formattedHasPisCofins',
    description: 'Gera PIS Cofins',
    originalId: 'has_pis_cofins',
    width: 90,
  },
  {
    id: 'formattedReferenceInvoice',
    description: 'NFE Referenciada',
    originalId: 'must_reference_invoice',
    width: 110,
  },
  {
    id: 'formattedHasStock',
    description: 'Movimenta Estoque',
    originalId: 'has_stock',
    width: 130,
  },
];
