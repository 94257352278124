import React from 'react';
import { TextField } from '@mui/material';
import { useProduct } from '../../Product';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 500,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '10px',
  },
});

const ProductTax: React.FC = () => {
  const classes = styles();
  const { product, handleChange } = useProduct();

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <TextField
          label="Garantia H2i"
          placeholder="Informe a quantidade de dias da garantia H2i"
          margin="normal"
          fullWidth
          value={product.garantia_h2i}
          onChange={e => handleChange('garantia_h2i', e.target.value)}
          autoFocus
          type="number"
          inputProps={{ inputMode: 'numeric', step: 1 }}
        />
        <TextField
          label="Garantia total"
          placeholder="Informe a quantidade de dias da garantia total"
          margin="normal"
          fullWidth
          value={product.garantia_total}
          onChange={e => handleChange('garantia_total', e.target.value)}
          type="number"
          inputProps={{ inputMode: 'numeric', step: 1 }}
        />
        <TextField
          label="Garantia fabricante"
          placeholder="Informe a quantidade de dias da garantia do fabricante"
          margin="normal"
          fullWidth
          value={product.garantia_total - product.garantia_h2i}
          onChange={e => handleChange('garantia_fabricante', e.target.value)}
          type="number"
          inputProps={{ inputMode: 'numeric', step: 1 }}
        />
      </div>
      <TextField
        label="Site do fabricante"
        placeholder="Informe o site do fabricante"
        margin="normal"
        fullWidth
        value={product.site_fabricante}
        onChange={e => handleChange('site_fabricante', e.target.value)}
      />
      <TextField
        label="Site para suporte"
        placeholder="Informe o site para suporte"
        margin="normal"
        fullWidth
        value={product.site_suporte}
        onChange={e => handleChange('site_suporte', e.target.value)}
      />
      <TextField
        multiline
        rows={5}
        label="Observações para troca"
        placeholder="Informe as observações para troca"
        margin="normal"
        fullWidth
        value={product.obs_troca}
        onChange={e => handleChange('obs_troca', e.target.value)}
      />
      <TextField
        multiline
        rows={5}
        label="Procedimento para troca"
        placeholder="Informe o procedimento para troca"
        margin="normal"
        fullWidth
        value={product.procedimento_troca}
        onChange={e => handleChange('procedimento_troca', e.target.value)}
      />
    </div>
  );
};

export default ProductTax;
