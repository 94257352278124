import React, { useState } from 'react';
import { Typography } from '@mui/material';
import ActionItemTable from './ActionItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { makeStyles } from '@mui/styles';
import { ACTIONS_TABLE_TEMPLATE } from '../actionsTableTemplate';
import { ProjectAction } from 'types/project';
import ProjectCostsListMenu from '../ProjectActionsListMenu';
import { useProjectActions } from '../../hooks/useProjectActions';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type ActionListTableProps = {
  actions: ProjectAction[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const ActionListTable: React.FC<ActionListTableProps> = ({ actions, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { setSelectedProjectAction } = useProjectActions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  function handleClick(action: ProjectAction) {
    setSelectedProjectAction(action);
  }

  return (
    <>
      <ProjectCostsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <TableContainer tableTemplate={ACTIONS_TABLE_TEMPLATE}>
        <TableContent>
          <TableHeader>
            {ACTIONS_TABLE_TEMPLATE.map(item => (
              <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
          </TableHeader>
          <TableBody>
            {actions.map(action => (
              <TableRow onClick={() => handleClick(action)} key={action.id}>
                <ActionItemTable setAnchorEl={setAnchorEl} action={action} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default ActionListTable;
