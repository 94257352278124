import React from 'react';
import { List } from '@mui/material';
import FlagItemModule from './FlagItemModule';
import { usePagination } from 'hooks/pagination';
import { FinancialCardFlag } from '../../../hooks/useFetchFinancialCard';

type FlagListModuleProps = {
  flags: FinancialCardFlag[];
};

const FlagListModule: React.FC<FlagListModuleProps> = ({ flags }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <List>
      {flags.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(flag => (
        <FlagItemModule key={flag.bandeira} flag={flag} />
      ))}
    </List>
  );
};

export default FlagListModule;
