import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

interface ShoppingSuggestionTabsProps {
  value: string;
  handleChange(value: string): void;
}

const CustomTabs = styled(Tabs)({
  backgroundColor: '#fff',
});

const CustomTab = styled(Tab)(({ theme }) => ({
  minHeight: 30,
  '&.Mui-selected': {
    '& svg': {
      color: theme.palette.success.main,
    },
  },
}));

const ShoppingSuggestionTabs: React.FC<ShoppingSuggestionTabsProps> = ({ handleChange, value }) => {
  return (
    <CustomTabs value={value} onChange={(e, value) => handleChange(value)} variant="scrollable">
      <CustomTab value="all" label="Por filial" />
      <CustomTab value="aggregated" label="Agrupado" />
    </CustomTabs>
  );
};

export default ShoppingSuggestionTabs;
