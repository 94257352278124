import React, { Dispatch, SetStateAction } from 'react';
import { Avatar, Typography, styled } from '@mui/material';
import { useSelector } from 'store/selector';

const Container = styled('div')({
  minHeight: 300,
  display: 'flex',
  flexDirection: 'column',
});

const ContentQuestion = styled('div')({
  backgroundColor: 'hsla(0,0%,100%,.05)',
});

const Content = styled('div')({
  padding: 5,
  display: 'flex',
  flexDirection: 'column',
});

const CustomTypography = styled(Typography)({
  width: '100%',
  color: '#000',
  textAlign: 'center',
});

const CustomQuestionTypography = styled(Typography)({
  fontSize: '0.9rem',
  width: '100%',
  color: '#000',
  alignSelf: 'center',
});

const Box = styled('div')({
  maxWidth: 300,
  display: 'flex',
  cursor: 'pointer',
  padding: '0.75rem',
  alignItems: 'center',
  borderRadius: '0.375rem',
  transition: 'all 0.1s ease',
  backgroundColor: '#d0d2d5',
  '&:hover': {
    backgroundColor: '#fff',
  },
  '& > p': {
    fontSize: 11,
    width: '100%',
    fontWeight: 600,
    textAlign: 'center',
  },
});

const BoxQuestion = styled('div')({
  padding: 10,
  display: 'grid',
  gridTemplateColumns: '50px 1fr',
  '&.question': {
    backgroundColor: '#fff',
  },
  '&.answer': {
    backgroundColor: '#d0d2d5',
  },
});

const DivResponse = styled('div')({
  color: '#000',
  fontSize: '0.9rem',
  lineHeight: 2,
});

const Boxs = styled('div')(props => ({
  gap: 5,
  display: 'flex',
  margin: '15px auto auto auto',
  [props.theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

interface AiChatOutputProps {
  setText: Dispatch<SetStateAction<string>>;
  loading: boolean;
  response: string;
  lastQuestion: string;
}

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `2px solid ${theme.palette.primary.light}`,
}));

const AiChatOutput: React.FC<AiChatOutputProps> = ({ setText, response, loading, lastQuestion }) => {
  const user = useSelector(state => state.user);
  const texts = [
    'Crie 3 opções de postagens com título curto e descrição para atrair likes para a conta do instagram @h2idistribuidor falando sobre o produto Gabinete Gamer',
    'Ideias virais sobre assuntos de tecnologias com uma descrição de no máximo 500 letras',
  ];
  return (
    <Container>
      {response ? (
        <>
          <ContentQuestion>
            <BoxQuestion className="question">
              <CustomAvatar>{user?.name[0]}</CustomAvatar>
              <CustomQuestionTypography fontWeight="bold">{lastQuestion}</CustomQuestionTypography>
            </BoxQuestion>
          </ContentQuestion>
          <BoxQuestion className="answer">
            <CustomAvatar src="https://vagnerpontes.com.br/wp-content/uploads/2023/05/chat-gpt.png" />
            <DivResponse dangerouslySetInnerHTML={{ __html: response }} />
          </BoxQuestion>
        </>
      ) : loading ? (
        <>
          <ContentQuestion>
            <BoxQuestion>
              <CustomAvatar>{user?.name[0]}</CustomAvatar>
              <CustomQuestionTypography>{lastQuestion}</CustomQuestionTypography>
            </BoxQuestion>
          </ContentQuestion>
          <BoxQuestion>
            <CustomAvatar src="https://vagnerpontes.com.br/wp-content/uploads/2023/05/chat-gpt.png" />
            <CustomQuestionTypography>Gerando resposta...</CustomQuestionTypography>
          </BoxQuestion>
        </>
      ) : (
        <Content>
          <CustomTypography fontWeight="bold">Aqui você pode perguntar qualquer coisa</CustomTypography>

          <Boxs>
            {texts.map((text, index) => (
              <Box key={index} onClick={() => setText(text)}>
                <Typography>{text}</Typography>
              </Box>
            ))}
          </Boxs>
        </Content>
      )}
    </Container>
  );
};

export default AiChatOutput;
