import React, { useEffect, useRef } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import { IconButton, InputAdornment, TextField, Theme } from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { CompatibilitiesQueryParams } from './Compatibilities';

const useStyles = makeStyles<Theme>(theme => ({
  filter: {
    display: 'grid',
    gridTemplateColumns: '400px 150px 1fr',
    columnGap: 10,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '120px 100px 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      flex: 1,
    },
  },
}));

type CompatibilitiesFIlterBoxProps = {
  queryParams: CompatibilitiesQueryParams;
  handleQueryParamsChange(index: keyof CompatibilitiesQueryParams, value: any): void;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const CompatibilitiesFIlterBox: React.FC<CompatibilitiesFIlterBoxProps> = ({
  queryParams,
  handleQueryParamsChange,
  setDisplayMode,
  displayMode,
}) => {
  const classes = useStyles();
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, []);

  return (
    <FilterBox>
      <div className={classes.filter}>
        <TextField
          label="Pesquisa"
          placeholder="Pesquisa..."
          value={queryParams.term}
          onChange={e => handleQueryParamsChange('term', e.target.value)}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: queryParams.term?.length ? (
              <InputAdornment position="start">
                <IconButton onClick={() => handleQueryParamsChange('term', '')} size="small">
                  <Close />
                </IconButton>
              </InputAdornment>
            ) : undefined,
          }}
        />
      </div>

      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default CompatibilitiesFIlterBox;
