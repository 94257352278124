import { RentalPaymentFormContent } from 'pages/rentals/types/rental';
import { Rental, RentalProductFormContent } from '../types/rental';
import { RentalActions } from './types';

export function changeRentalAction(index: keyof Rental, value: any): RentalActions {
  return {
    type: 'CHANGE_RENTAL',
    index,
    value,
  };
}

export function setRentalAction(rental: Rental): RentalActions {
  return {
    type: 'SET_RENTAL',
    rental,
  };
}

export function addRentalProduct(product: RentalProductFormContent): RentalActions {
  return {
    type: 'ADD_RENTAL_PRODUCT',
    product,
  };
}

export function removeRentalProductAction(externalId: string): RentalActions {
  return {
    type: 'REMOVE_RENTAL_PRODUCT',
    externalId,
  };
}

export function addRentalPaymentAction(payment: RentalPaymentFormContent): RentalActions {
  return {
    type: 'ADD_RENTAL_PAYMENT',
    payment,
  };
}

export function removeRentalPaymentAction(externalId: string): RentalActions {
  return {
    type: 'REMOVE_RENTAL_PAYMENT',
    externalId,
  };
}
