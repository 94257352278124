import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import CityItemModule from './CityItemModule';
import { CityMethod } from 'types/salesReport';

type CityListModuleProps = {
  cities: CityMethod[];
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

const CityListModule: React.FC<CityListModuleProps> = ({ cities }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {cities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(city => (
        <CityItemModule key={city.cidade} city={city} />
      ))}
    </CustomList>
  );
};

export default CityListModule;
