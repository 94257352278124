import React from 'react';
import { ListItemButton, Typography, styled } from '@mui/material';
import { TotalizingAccount } from 'types/totalizingAccount';
import { useTotalizingAccounts } from '../../hooks/useTotalizingAccount';

const StyledListItem = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 120,
  justifyContent: 'space-between',
  flexDirection: 'column',
});

interface TotalizingItemModuleProps {
  account: TotalizingAccount;
}

const TotalizingItemModule: React.FC<TotalizingItemModuleProps> = ({ account }) => {
  const { setSelectedAccount } = useTotalizingAccounts();

  return (
    <StyledListItem onClick={() => setSelectedAccount(account)}>
      <Typography color="primary" variant="caption" gutterBottom>
        {account.nome}
      </Typography>

      <Typography color="primary" variant="caption" gutterBottom>
        {account.filial}
      </Typography>
      <Typography color="primary" variant="caption" gutterBottom>
        {account.formattedSaldo}
      </Typography>
    </StyledListItem>
  );
};

export default TotalizingItemModule;
