import React from 'react';
import { IconButton, Theme } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: 10,
    flex: 1,
    '& > .search-params': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      columnGap: 20,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  actions: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

type BillsFilterBoxProps = {
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
  loadBills(): void;
};

const BillsFilterBox: React.FC<BillsFilterBoxProps> = ({ setDisplayMode, displayMode, loadBills }) => {
  const classes = useStyles();
  return (
    <FilterBox>
      <div className={classes.filter}></div>
      <div className={classes.actions}>
        <IconButton onClick={loadBills}>
          <Refresh color="primary" />
        </IconButton>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </div>
    </FilterBox>
  );
};

export default BillsFilterBox;
