import { Done } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useDialog } from 'components/dialogs/Dialog';
import { usePermissionRules } from 'hooks/permissionRules';
import React from 'react';
import { invoiceColors } from '../Invoices';

interface NewInvoiceActionProps {
  handleValidation(): Promise<void>;
  apropriatedAt: boolean;
  type: string;
}

const NewInvoiceActions: React.FC<NewInvoiceActionProps> = ({ handleValidation, apropriatedAt, type }) => {
  const { handleClose } = useDialog();
  const { checkPermission } = usePermissionRules();

  function handleClick() {
    handleValidation()
      .then(handleClose)
      .catch(err => console.error(err));
  }

  return (
    <>
      {!apropriatedAt && checkPermission('financial.moviment.extract.save-title') ? (
        <Tooltip title="Salvar">
          <IconButton style={{ color: invoiceColors[type] }} onClick={handleClick} color="inherit">
            <Done />
          </IconButton>
        </Tooltip>
      ) : (
        checkPermission('financial.moviment.extract.save-title-master') && (
          <Tooltip title="Salvar">
            <IconButton style={{ color: invoiceColors[type] }} onClick={handleClick} color="inherit">
              <Done />
            </IconButton>
          </Tooltip>
        )
      )}
    </>
  );
};

export default NewInvoiceActions;
