import React from 'react';
import { usePurchaseOrder } from './hooks/PurchaseOrderProvider';
import SidebarFilters from 'components/sidebar-filters/SidebarFilters';

const PurchaseOrderSidebarFilters: React.FC = () => {
  const { onFilterClick } = usePurchaseOrder();

  return <SidebarFilters onFilterClick={onFilterClick} />;
};

export default PurchaseOrderSidebarFilters;
