import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import { ActivityMethod } from 'types/salesReport';

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #ddd',
  backgroundColor: '#fff',
  '& > div': {
    gap: 10,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '&.data': {
      gap: 5,
    },
    '& > span': {
      borderRadius: 6,
      border: '1px solid #ddd',
      display: 'flex',
      flexDirection: 'column',
      '& .title': {
        backgroundColor: '#ddd',
      },
      '& > p': {
        padding: 5,
      },
    },
  },
});

type ActivityItemModuleProps = {
  activity: ActivityMethod;
};

const ActivityItemModule: React.FC<ActivityItemModuleProps> = ({ activity }) => {
  return (
    <CustomListItemButton>
      <Typography variant="subtitle2" color="primary">
        {activity.atividade}
      </Typography>
      <div>
        <span>
          <Typography className="title" variant="body2" fontSize={12}>
            <b>QTD:</b>
          </Typography>
          <Typography color="#30519f" align="right">
            {activity.qtd_vendas}
          </Typography>
        </span>

        <span>
          <Typography className="title" variant="body2" fontSize={12}>
            <b>Valor:</b>
          </Typography>
          <Typography color="green" align="right">
            {activity.formattedValue}
          </Typography>
        </span>
      </div>
      <div className="data">
        <Typography fontWeight={600} variant="body2" fontSize={12}>
          Percentual:
        </Typography>

        <Typography align="right" variant="body2" fontSize={12}>
          {activity.formattedPercentual}
        </Typography>

        <Typography fontWeight={600} variant="body2" fontSize={12}>
          Ticket médio:
        </Typography>
        <Typography align="right" variant="body2" fontSize={12}>
          {activity.formattedTicketMedio}
        </Typography>
      </div>
    </CustomListItemButton>
  );
};

export default ActivityItemModule;
