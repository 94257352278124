import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';
import history from 'services/history';

type PurchaseOrderActionsProps = {
  tab: string;
};

const PurchaseOrderActions: React.FC<PurchaseOrderActionsProps> = ({ tab }) => {
  return (
    <>
      {tab === 'registration' && (
        <IconButton color="inherit" onClick={() => history.push('/purchasing/order/new')}>
          <Tooltip title="Novo">
            <Add />
          </Tooltip>
        </IconButton>
      )}
    </>
  );
};

export default PurchaseOrderActions;
