import React, { useState } from 'react';
import { styled } from '@mui/material';
import DavReviewDetailPayments from './DavReviewDetailPayments';
import DavReviewDetailProducts from './DavReviewDetailProducts';
import DavReviewDetailTotals from './DavReviewDetailTotals';
import DavReviewDetailCustomer from './DavReviewDetailCustomer';
import InsideLoading from 'components/loading/InsideLoading';
import DavReviewDetailHistoric from './DavReviewDetailHistoric';
import { useParams } from 'react-router-dom';
import { useFetchDavReviewDetail } from './hooks/useFetchDavReviewDetail';
import { DavReviewDetailProvider } from './hooks/useDavReviewDetail';
import { useDisplayMode } from 'hooks/useDisplayMode';
import Appbar from 'components/appbar/Appbar';
import DavReviewInstallmentConcession from './dialog/DavReviewInstallmentConcession';
import DavReviewDetailHeader from './DavReviewDetailHeader';
import { H2iOrder } from 'types/h2iOrder';
import InsideSaving from 'components/loading/InsideSaving';
import { useApp } from 'hooks/app';

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '3fr 1fr',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  [theme.breakpoints.down('md')]: {
    padding: 20,
    minWidth: 300,
    border: 'none',
    marginTop: 0,
    display: 'block',
  },
}));

const Center = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
});

const Main = styled('div')(({ theme }) => ({
  padding: '20px 10px',
  background: '#fff',
  [theme.breakpoints.down('md')]: {
    background: 'inherit',
    margin: '20px 0px',
    padding: 0,
  },
}));

const DavReviewDetail: React.FC = () => {
  const { h2iApi } = useApp();
  const [displayMode] = useDisplayMode();
  const [saving, setSaving] = useState(false);
  const { id, branchId } = useParams<{ id: string; branchId: string }>();
  const [showInstallmentConcession, setShowInstallmentConcession] = useState(false);
  const { loading, order, setOrder, fetch } = useFetchDavReviewDetail(id, branchId);
  // const [params, setParams] = useState<DavReviewPaymentParams>({
  //   discountValue: '',
  //   installmentQuantity: 1,
  //   methodPayment: 0,
  // });
  //   function handleChange(index: keyof DavReviewPaymentParams, value: any) {
  //   setParams(params => ({
  //     ...params,
  //     [index]: value,
  //   }));
  // }

  function handleClick() {
    setShowInstallmentConcession(true);
  }

  function handleChange(index: keyof H2iOrder, value: any) {
    setOrder(order =>
      order
        ? {
            ...order,
            [index]: value,
          }
        : null,
    );
  }

  async function handleUpdateDav() {
    if (!h2iApi) {
      return;
    }

    setSaving(true);

    await h2iApi
      .post('/api/updatedavcab', order)
      .then(response => console.log(response.data))
      .finally(() => {
        setShowInstallmentConcession(false);
        setSaving(false);
      });

    fetch();
  }

  return (
    <>
      <Appbar title="Dav Review" />
      {saving && <InsideSaving />}
      {loading ? (
        <Center>
          <InsideLoading />
        </Center>
      ) : (
        order && (
          <DavReviewDetailProvider
            value={{
              handleUpdateDav,
              handleChange,
              handleClick,
              order,
              displayMode,
            }}
          >
            {showInstallmentConcession && (
              <DavReviewInstallmentConcession onExited={() => setShowInstallmentConcession(false)} />
            )}

            <Container>
              <Main>
                <DavReviewDetailHeader />

                <DavReviewDetailCustomer />

                <DavReviewDetailProducts />

                <DavReviewDetailPayments />

                <DavReviewDetailHistoric />
              </Main>

              <DavReviewDetailTotals />
            </Container>
          </DavReviewDetailProvider>
        )
      )}
    </>
  );
};

export default DavReviewDetail;
