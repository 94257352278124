import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import PaginationProvider from 'hooks/pagination';
import { useDisplayMode } from 'hooks/useDisplayMode';
import Pagination from 'components/pagination/Pagination';
import { usePurchaseOrder } from '../../hooks/PurchaseOrderProvider';
import NoData from 'components/no-data/NoData';
import { PurchaseOrderItem } from 'types/purchaseOrderItem';
import useTableOrder from 'hooks/tableOrder';
import PurchaseOrderProductListTable from './table/PurchaseOrderProductListTable';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import PurchaseOrderTotal from '../../PurchaseOrderTotal';

const Content = styled('div')({
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
});

const PurchaseOrderProductPage: React.FC = () => {
  const [displayMode] = useDisplayMode();
  const { ordersProducts, loading } = usePurchaseOrder();
  const [filtered, setFiltered] = useState<PurchaseOrderItem[]>([]);
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(ordersProducts);
  }, [ordersProducts]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <>
      <PurchaseOrderTotal product={true} quantityItems={ordersProducts.length} />
      {loading.items ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filtered.length === 0 ? (
        <NoData message="Conteúdo indisponível" />
      ) : (
        <PaginationProvider>
          <Content>
            {displayMode === 'list' ? (
              <PurchaseOrderProductListTable products={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
            ) : (
              <>
                {/* <PurchaseOrderListTable orders={filtered} handleSort={handleSort} orderedIndex={orderedIndex} /> */}
              </>
            )}
            <Pagination count={filtered.length} />
          </Content>
        </PaginationProvider>
      )}
    </>
  );
};

export default PurchaseOrderProductPage;
