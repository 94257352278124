import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import ErrorHandlerDialog from './ErrorHandlerDialog';
import { CanceledError } from 'axios';

type HandleShowParams = {
  message: string;
  error: any;
  callback?: () => void;
};

export interface ErrorHandlerContextValue {
  showErrorDialog(params: HandleShowParams): void;
  closeErrorDialog(): void;
}

const ErrorHandlerContext = createContext<ErrorHandlerContextValue>({} as ErrorHandlerContextValue);

export function useErrorHandler(): ErrorHandlerContextValue {
  return useContext(ErrorHandlerContext);
}

interface ErrorHandlerProviderProps {
  children: ReactNode;
}

const ErrorHandlerProvider: React.FC<ErrorHandlerProviderProps> = ({ children }) => {
  const [message, setMessage] = useState('');
  const [detail, setDetail] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function getDetail(error: any) {
    if (error.response) {
      return `${error.response.data.status_code}, ${error.response.data.error}`;
    }

    return error.message;
  }

  const handleShow = useCallback((params: HandleShowParams) => {
    console.error(params.error);

    if (params.error instanceof CanceledError) {
      return;
    }

    setMessage(params.message);

    setDetail(params.error ? getDetail(params.error) : '');

    setIsDialogOpen(true);
  }, []);

  function handleClose() {
    setIsDialogOpen(false);
  }

  return (
    <ErrorHandlerContext.Provider value={{ showErrorDialog: handleShow, closeErrorDialog: handleClose }}>
      {isDialogOpen && <ErrorHandlerDialog detail={detail} message={message} onExited={() => setIsDialogOpen(false)} />}

      {children}
    </ErrorHandlerContext.Provider>
  );
};

export default ErrorHandlerProvider;
