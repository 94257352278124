import React, { useState } from 'react';
import RelatedProductItemTable from './RelatedProductItemTable';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import history from 'services/history';
import { RelatedProduct } from 'pages/related-product/types/relatedProduct';
import { useRelatedProducts } from 'pages/related-product/hooks/useRelatedProducts';
import RelatedProductListMenu from '../RelatedProductListMenu';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
    marginRight: 20,
  },
});

interface RelatedProductListTableProps {
  relatedProducts: RelatedProduct[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const RelatedProductListTable: React.FC<RelatedProductListTableProps> = ({
  relatedProducts,
  handleSort,
  orderedIndex,
}) => {
  const classes = useStyles();
  const { tableTemplate } = useTable();
  const { setSelectedRelatedProduct } = useRelatedProducts();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(related: RelatedProduct) {
    setSelectedRelatedProduct(related);
    history.push(`/related-products/${related.id}`);
  }

  return (
    <>
      <RelatedProductListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <div
              className={
                item.dataType === 'number' ? `${classes.headerItem} ${classes.headerItemNumeric}` : classes.headerItem
              }
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>

        <TableBody>
          {relatedProducts.map(related => (
            <TableRow key={related.id} onClick={() => handleClick(related)}>
              <RelatedProductItemTable setAnchorEl={setAnchorEl} relatedProduct={related} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default RelatedProductListTable;
