import { cpfValidator } from 'helpers/cpfValidator';
import { Dispatch, SetStateAction, useState } from 'react';
import { Agent } from 'types/agent';
import * as yup from 'yup';

export type AccountAgentValidation = {
  cpf?: string;
  rg?: string;
  rg_emissor?: string;
  profissao?: string;
  estado_civil?: string;
  nome?: string;
  telefone?: string;
};

type UseAccountAgentValidation = [
  AccountAgentValidation,
  Dispatch<SetStateAction<AccountAgentValidation>>,
  (agent: Agent) => Promise<void>,
];

export function useAccountAgentValidation(): UseAccountAgentValidation {
  async function handleValidation(agent: Agent) {
    const schema = yup.object().shape({
      telefone: yup
        .string()
        .required('O telefone é obrigatório')
        .test('phoneValidation', 'telefone inválido', value => {
          if (!value) return false;

          const raw = value.replace(/\D/g, '');
          if (raw.length < 10) return false;

          return true;
        }),
      rg: yup.string().required('RG é obrigatório'),
      cpf: yup
        .string()
        .required('CPF é obrigatório')
        .transform((value, originalValue) => {
          return originalValue ? originalValue.replace(/\D/g, '') : '';
        })
        .test('docValidation', `CPF inválido`, value => {
          if (!value) return false;

          const cpf = cpfValidator(value);

          return cpf;
        }),
      nome: yup.string().min(10, 'O nome deve ter no mínimo 10 caracteres').required('nome é obrigatório'),
    });

    try {
      await schema.validate(agent);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<AccountAgentValidation>({});
  return [validation, setValidation, handleValidation];
}
