import React, { useState } from 'react';
import { useDialog } from 'components/dialogs/Dialog';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { FaFileExcel } from 'react-icons/fa';

interface ExcelButtonDownloadProps extends ButtonProps {
  handleDownload(): Promise<void>;
  text?: string;
  marginTop?: number;
  width?: number;
}

const ExcelButtonDownload: React.FC<ExcelButtonDownloadProps> = ({
  handleDownload,
  text = 'Planilha',
  size,
  marginTop = 40,
  disabled,
  width = 160,
  ...rest
}) => {
  const { handleClose } = useDialog();
  const [downloading, setDownloading] = useState(false);

  function handleClick() {
    setDownloading(true);

    handleDownload()
      .then(handleClose)
      .finally(() => setDownloading(false));
  }

  return (
    <Button
      {...rest}
      style={{ marginTop, width }}
      disabled={downloading || disabled}
      onClick={handleClick}
      size={size}
      color="success"
      variant="contained"
      startIcon={<FaFileExcel />}
    >
      {downloading ? <CircularProgress size={26} /> : text}
    </Button>
  );
};

export default ExcelButtonDownload;
