import { Dispatch, SetStateAction, useState } from 'react';
import { Agent } from 'types/agent';
import * as yup from 'yup';

export interface AgentAddressValidation {
  cep?: string;
  endereco?: string;
  numero_endereco?: string;
  bairro?: string;
  cidade?: string;
  uf?: string;
}

type UseAgentAddressValidation = [
  AgentAddressValidation,
  Dispatch<SetStateAction<AgentAddressValidation>>,
  (address: Agent) => Promise<void>,
];

export function useAgentAddressValidation(): UseAgentAddressValidation {
  async function handleValidation(address: Agent) {
    const schema = yup.object().shape({
      uf: yup.string().required('O estado é obrigatório'),
      cidade: yup.string().required('A cidade é obrigatória'),
      bairro: yup.string().required('O bairro é obrigatório'),
      numero_endereco: yup.string().required('O número é obrigatório'),
      endereco: yup.string().required('O logradouro é obrigatório'),
      cep: yup.string().required('O CEP é obrigatório'),
    });

    try {
      await schema.validate(address);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<AgentAddressValidation>({});
  return [validation, setValidation, handleValidation];
}
