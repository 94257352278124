import React, { useEffect, useMemo, useRef } from 'react';
import { Rental } from '../types/rental';
import { FormControlLabel, Switch, TextField, Typography, styled } from '@mui/material';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import { RentalValidation } from './validation/rentalValidation';
import RentalProducts from './products/RentalProducts';
import CpfCnpjInput from 'components/masked-input/CpfCnpjInput';
import { moneyFormat } from 'helpers/numberFormat';
import { useRental } from './hooks/useRental';

const Content = styled('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: 30,
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  '& .subtitle': {
    marginBottom: 20,
  },
  '& .total-item': {
    display: 'inline-flex',
    gap: 20,
    justifyContent: 'end',
    alignItems: 'baseline',
    marginBottom: 10,
    '& > span': {
      fontSize: 16,
      fontWeight: '400',
    },
  },
  [props.theme.breakpoints.down('sm')]: {
    padding: 0,
    backgroundColor: 'unset',
    border: 'none',
    marginBottom: 30,
  },
}));

const Container = styled('div')({
  marginBottom: 200,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 1100,
  width: '100%',
  gap: 15,
  margin: '0 auto',
});

const FirstRow = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  gap: 15,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: 0,
  },
}));

const SecondRow = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 15,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: 0,
  },
}));

const ThirdRow = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: 15,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: 0,
  },
}));

const SwitchContent = styled('div')({
  marginTop: 20,
});

interface RentalFormProps {
  handleChange(index: keyof Rental, value: any): void;
  rental: Rental;
  validation: RentalValidation;
}

const RentalForm: React.FC<RentalFormProps> = ({ handleChange, rental, validation }) => {
  const { percentage, setPercentage } = useRental();

  const inputs = {
    customer_name: useRef<HTMLInputElement>(null),
    customer_document_number: useRef<HTMLInputElement>(null),
  };

  const formattedTotal = useMemo(() => moneyFormat(rental.total), [rental.total]);
  const formattedCommissionTotal = useMemo(() => moneyFormat(rental.commission_total), [rental.commission_total]);

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Container>
      <Content>
        <Typography variant="h6" className="subtitle" color="primary">
          Dados do cliente
        </Typography>
        <FirstRow>
          <TextField
            label="Nome do cliente"
            placeholder="Nome do cliente"
            fullWidth
            error={!!validation.customer_name}
            value={rental.customer_name}
            helperText={validation.customer_name}
            onChange={e => handleChange('customer_name', e.target.value)}
            margin="normal"
            inputRef={inputs.customer_name}
            autoFocus
          />

          <TextField
            label="CPF/CNPJ"
            placeholder="CPF ou CNPJ"
            fullWidth
            error={!!validation.customer_document_number}
            value={rental.customer_document_number}
            helperText={validation.customer_document_number}
            onChange={e => handleChange('customer_document_number', e.target.value)}
            margin="normal"
            inputRef={inputs.customer_document_number}
            InputProps={{
              inputComponent: CpfCnpjInput as any,
            }}
          />
        </FirstRow>

        <SecondRow>
          <DesktopDatePicker
            label="Começa em"
            value={rental.start_at}
            onChange={date => handleChange('start_at', date)}
            textFieldProps={{
              margin: 'normal',
            }}
            localeText={{
              clearButtonLabel: 'Limpar',
            }}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
          />

          <DesktopDatePicker
            label="Termina em"
            value={rental.end_at}
            onChange={date => handleChange('end_at', date)}
            textFieldProps={{
              margin: 'normal',
            }}
            localeText={{
              clearButtonLabel: 'Limpar',
            }}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
          />
        </SecondRow>

        <ThirdRow>
          <TextField
            label="Lançamento financeiro"
            placeholder="Lançamento financeiro"
            fullWidth
            value={rental.financial_data}
            onChange={e => handleChange('financial_data', e.target.value)}
            margin="normal"
            autoFocus
          />
          <TextField
            label="Número de identificação"
            placeholder="Número de identificação"
            fullWidth
            value={rental.external_id}
            onChange={e => handleChange('external_id', e.target.value)}
            margin="normal"
            autoFocus
          />
          <TextField
            label="Comissão %"
            placeholder="Percentual da comissão"
            fullWidth
            value={percentage}
            onChange={event => setPercentage(event.target.value)}
            margin="normal"
            autoFocus
          />
        </ThirdRow>

        <SwitchContent>
          <FormControlLabel
            label="Ativo"
            control={
              <Switch
                color="primary"
                onChange={event => handleChange('active', event.target.checked)}
                checked={rental.active}
              />
            }
          />
        </SwitchContent>
      </Content>

      <Content>
        <Typography variant="h6" className="subtitle" color="primary">
          Produtos
        </Typography>
        <RentalProducts />
      </Content>

      <Content>
        <Typography align="left" variant="h6" className="subtitle" color="primary">
          Totais
        </Typography>

        <Typography className="total-item" variant="h5" align="right">
          <span>Produtos</span>
          {formattedTotal}
        </Typography>

        <Typography className="total-item" variant="h5" align="right">
          <span>Comissão</span>
          {formattedCommissionTotal}
        </Typography>
      </Content>
    </Container>
  );
};

export default RentalForm;
