import Dialog from 'components/dialogs/Dialog';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useMemo } from 'react';
import { useSelector } from 'store/selector';
import ProjectReportAppbarActions from './ProjectReportAppbarActions';
import { styled } from '@mui/material';
import { useProjects } from '../hook/useProjects';
import { moneyFormat } from 'helpers/numberFormat';
import { projectStatusMapping } from '../registration/reducer/reducer';

const Container = styled('div')({
  '& .label': {
    fontSize: 10,
    color: '#888',
    marginBottom: 7,
  },
});

const Row = styled('div')({
  display: 'grid',
  gridTemplateColumns: '70px 1fr 100px 100px 100px',
  marginBottom: 1,
  paddingBottom: 1,
});

const HeaderRightContent = styled('div')({
  '& p': {
    color: '#888',
    fontSize: 12,
  },
});

const AddressContent = styled('div')({
  marginTop: 10,
  marginBottom: 10,
  border: '1px solid #eee',
  padding: 15,
});

const ProductsContent = styled('div')({
  marginTop: 5,
  display: 'flex',
  flexDirection: 'column',
  rowGap: 5,
  padding: 15,
  border: '1px solid #eee',
  '& .table-header': {
    fontWeight: 'bold',
  },
});

const TotalContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 10,
  border: '1px solid #eee',
  marginTop: 10,
  '& .grid': {
    display: 'grid',
    gridTemplateColumns: '120px 100px',
    columnGap: 20,
    rowGap: 7,
    '& > p': {
      textAlign: 'right',
    },
    '& .value': {
      fontWeight: 'bold',
    },
  },
});

interface ProjectReportProps {
  onExited(): void;
}

const ProjectReport: React.FC<ProjectReportProps> = ({ onExited }) => {
  const user = useSelector(state => state.user);
  const { selectedProject: project } = useProjects();

  const formattedTotal = useMemo(() => {
    const total = project?.products.reduce((carry, product) => carry + product.product_value * product.quantity, 0);
    return moneyFormat(total);
  }, [project]);

  const formattedService = useMemo(() => moneyFormat(project?.value), [project]);

  return (
    <Dialog
      fullScreen
      title={`Projeto ${project?.id}`}
      onExited={onExited}
      ComponentActions={<ProjectReportAppbarActions />}
    >
      <Container>
        <div className="page">
          <div className="header">
            <div>
              <h3>
                {project?.id} - {project?.name}
              </h3>
            </div>
            <HeaderRightContent>
              <p>{user?.name}</p>
              <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
            </HeaderRightContent>
          </div>

          <div style={{ border: '1px solid #eee', padding: 20, display: 'flex', flexDirection: 'column', gap: 4 }}>
            <p>Status: {project?.current_status ? projectStatusMapping[project.current_status] : ''}</p>
            <p>CNPJ/CPF: {project?.document_number}</p>
            <p>Número RO: {project?.ro_number ?? 'Sem número RO'}</p>
            <p>Aprovado: {project?.is_approved ? 'Sim' : 'Não'}</p>
            <p>Registrado: {project?.is_registered ? 'Sim' : 'Não'}</p>
          </div>

          <AddressContent>
            <p className="label">
              <strong>Endereço de instalação</strong>
            </p>
            <div>
              <p>
                <span>CEP:</span> {project?.address.postal_code}
              </p>
              <p>
                <span>Endereço:</span> {project?.address.street}, {project?.address.street_number}
              </p>
            </div>

            <p></p>
            <p>Bairro: {project?.address.neighborhood}</p>
            <p>{project?.address.complement}</p>
            <p>
              Cidade: {project?.address.city} - {project?.address.state}
            </p>
            <p></p>
          </AddressContent>

          <ProductsContent>
            <p className="label">
              <strong>Produtos</strong>
            </p>

            <Row className="table-header">
              <p>Código</p>
              <p>Descrição</p>
              <p className="center">Qtd</p>
              <p className="center">Preço</p>
              <p className="center">Total</p>
            </Row>
            {project?.products.map(product => (
              <Row key={project.id}>
                <p>{product.product_id}</p>
                <p>{product.product_name}</p>
                <p className="center">{product.quantity}</p>
                <p className="center">{product.formattedProductValue}</p>
                <p className="center">{moneyFormat(product.quantity * product.product_value)}</p>
              </Row>
            ))}
          </ProductsContent>

          <TotalContent>
            <p className="label">
              <strong>Totais</strong>
            </p>
            <div className="grid">
              <p>Produtos</p>
              <p className="value">{formattedTotal}</p>
              <p>Serviço</p>
              <p className="value">{formattedService}</p>
            </div>
          </TotalContent>
        </div>
      </Container>
    </Dialog>
  );
};

export default ProjectReport;
