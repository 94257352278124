import React, { ReactNode } from 'react';
import { useTable } from './hook/useTable';
import { styled } from '@mui/material';

interface StyleProps {
  width?: number;
  maxHeight?: number;
  useMaxHeight?: boolean;
}

const BodyContainer = styled('div')<StyleProps>(({ width, maxHeight, useMaxHeight }) => ({
  // overflowX: 'hidden',
  width: width ? `${width}px` : '100%',
  maxHeight: useMaxHeight ? (maxHeight ? `calc(100vh - ${maxHeight}px)` : 'calc(100vh - 374px)') : '',
}));

const Body = styled('div')<StyleProps>(({ width }) => ({
  width: width ? `${width}px` : '100%',
}));

type TableBodyProps = {
  width?: number;
  useMaxHeight?: boolean;
  maxHeight?: number;
  className?: string;
  children: ReactNode;
};

const TableBody: React.FC<TableBodyProps> = ({ children, className, maxHeight, useMaxHeight = false }) => {
  const { width } = useTable();

  return (
    <BodyContainer width={width} useMaxHeight={useMaxHeight} maxHeight={maxHeight} className={className}>
      <Body>{children}</Body>
    </BodyContainer>
  );
};

export default TableBody;
