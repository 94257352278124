import { useCallback, useState } from 'react';
import { api } from 'services/api';
import { CourseStudent, CourseStudentOnList } from '../types/courseStudent';
import { CourseStudentsQueryParams } from '../CourseStudents';
import { useCourseStudentFormatter } from './useCourseStudentFormatter';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Paginated } from 'types/paginated';
import { useErrorHandler } from 'providers/error-handler/error-handler';

const queryParamsInitialValue: CourseStudentsQueryParams = {
  initial_date: startOfMonth(new Date()),
  final_date: endOfMonth(new Date()),
  page: 0,
  rows: 20,
  term: '',
};

export function useFetchCourseStudents() {
  const [queryParams, setQueryParams] = useState<CourseStudentsQueryParams>(queryParamsInitialValue);
  const [loading, setLoading] = useState(false);
  const [courseStudents, setCourseStudents] = useState<CourseStudentOnList[]>([]);
  const formatter = useCourseStudentFormatter();
  const [total, setTotal] = useState(0);
  const { showErrorDialog } = useErrorHandler();

  const fetch = useCallback(
    (queryParams: CourseStudentsQueryParams) => {
      setLoading(true);

      api
        .get<Paginated<CourseStudent[]>>('/course-students', {
          params: { ...queryParams },
        })
        .then(response => {
          setCourseStudents(response.data.data.map(item => formatter(item)));
          setTotal(response.data.total);
        })
        .catch(error => {
          showErrorDialog({
            error,
            message: 'Não foi possível recuperar as locações',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [formatter, showErrorDialog],
  );

  return {
    fetch,
    loading,
    total,
    queryParams,
    setQueryParams,
    courseStudents,
    setCourseStudents,
  };
}
