import React from 'react';
import ResponsibleItemModule from './ResponsibleItemModule';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { ResponsibleStockBalanceData } from 'types/reports/stockBalance';

type ResponsibleListModuleProps = {
  responsibles: ResponsibleStockBalanceData[];
};

const ResponsibleListModule: React.FC<ResponsibleListModuleProps> = ({ responsibles }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {responsibles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(responsible => (
            <ResponsibleItemModule key={responsible.id_responsavel} responsible={responsible} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ResponsibleListModule;
