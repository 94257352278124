import React, { ReactNode } from 'react';
import { styled } from '@mui/material';

type StyleProps = {
  useMinHeight: boolean;
  minHeightDiff: number;
};

const Container = styled('div')<StyleProps>(props => ({
  backgroundColor: '#fff',
  width: '100%',
  minHeight: props.useMinHeight ? `calc(100vh - ${props.minHeightDiff}px)` : 'auto',
  overflow: 'auto',
  position: 'relative',
}));

interface TableContentProps {
  useMinHeight?: boolean;
  minHeightDiff?: number;
  children: ReactNode;
  style?: React.CSSProperties;
}

const TableContent: React.FC<TableContentProps> = ({ children, style, useMinHeight = true, minHeightDiff = 330 }) => {
  return (
    <Container style={style} useMinHeight={useMinHeight} minHeightDiff={minHeightDiff}>
      {children}
    </Container>
  );
};

export default TableContent;
