import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useMemo } from 'react';
import { useSelector } from 'store/selector';
import { useBranches } from 'providers/BranchesProviders';
import { useExpenses } from '../hooks/useExpenses';

const ExpensesReportHeader: React.FC = () => {
  const now = format(new Date(), 'PPpp', { locale: ptBR });
  const user = useSelector(state => state.user);
  const { filter } = useExpenses();
  const { financialBranches } = useBranches();

  const selectedBranch = useMemo(
    () => financialBranches.find(branch => branch.id === filter.branchId),
    [filter, financialBranches],
  );

  return (
    <div className="header">
      <div>
        <h6>Relatório de despesas</h6>
        {!selectedBranch?.id ? <h6>Todas as filiais</h6> : <h6>Filial {selectedBranch.nome}</h6>}
      </div>
      <div>
        <p>{user?.name}</p>
        <p>{now}</p>
      </div>
    </div>
  );
};

export default ExpensesReportHeader;
