import React from 'react';
import { Close } from '@mui/icons-material';
import { Manufacturer } from 'types/manufacturer';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';

interface ManufacturerAutoCompleteAdornmentProps {
  selected: Manufacturer | null;
  loading: boolean;
  clearSelected(): void;
}

const ManufacturerAutoCompleteAdornment: React.FC<ManufacturerAutoCompleteAdornmentProps> = ({
  selected,
  loading,
  clearSelected,
}) => {
  return (
    <InputAdornment position="end">
      {loading ? (
        <CircularProgress color="primary" size={24} />
      ) : (
        selected && (
          <IconButton onClick={clearSelected}>
            <Close />
          </IconButton>
        )
      )}
    </InputAdornment>
  );
};

export default ManufacturerAutoCompleteAdornment;
