import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { FinancialCard } from 'types/financialReportList';
import { FinancialCardFilterParams } from '../FinancialCardReport';

export type SalesReportTotal = {
  received: number;
  discount: number;
  tax: number;
  gross: number;
  credit: number;
  debit: number;
};

export interface FinancialCardFlag {
  bandeira: string;
  valor_bruto: number;
  valor_desconto: number;
  valor_liquido: number;
  formattedValueDiscount?: string;
  formattedValueGross?: string;
  formattedValueLiquid?: string;
}

export interface FinancialCardBranch {
  filial: string;
  valor_bruto: number;
  valor_desconto: number;
  valor_liquido: number;
  formattedValueDiscount?: string;
  formattedValueGross?: string;
  formattedValueLiquid?: string;
}

export type UseFetchFinancialCardReport = [
  (searchValue: string) => void,
  FinancialCard[],
  boolean,
  SalesReportTotal,
  FinancialCardFlag[],
  FinancialCardBranch[],
];

export function useFetchFinancialCardReport(filter: FinancialCardFilterParams): UseFetchFinancialCardReport {
  const [financialCardFlag, setFinancialCardFlag] = useState<FinancialCardFlag[]>([]);
  const [financialCardBranch, setFinancialCardBranch] = useState<FinancialCardBranch[]>([]);
  const [formattedTotal, setFormattedTotal] = useState<SalesReportTotal>({
    received: 0,
    discount: 0,
    tax: 0,
    gross: 0,
    credit: 0,
    debit: 0,
  });
  const [cards, setCards] = useState<FinancialCard[]>([]);
  const { h2iApi } = useApp();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const received = cards.reduce((sum, item) => sum + item.vl_recebido, 0);
    const discount = cards.reduce((sum, item) => sum + item.vl_desconto, 0);
    const tax = cards.reduce((sum, item) => sum + item.taxa, 0);
    setFormattedTotal(i => ({
      ...i,
      received,
      discount,
      tax: tax / cards.length,
      gross: received + discount,
    }));
  }, [cards]);

  const handleSearch = useCallback(
    (searchValue: string) => {
      if (!h2iApi) return;

      setLoading(true);

      const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
      const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

      h2iApi
        .get(`/api/relcartoes`, {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.branchId || '',
            filtro: searchValue,
            tipo_pesquisa: filter.typeDate,
            situacao: filter.situation,
          },
        })
        .then(response => {
          const _response = response.data[0];
          if (!_response) {
            setCards([]);
            return;
          }

          _response.tipo.map(item =>
            item.tipo === 'CREDITO'
              ? setFormattedTotal(i => ({
                  ...i,
                  credit: item.valor_bruto,
                }))
              : setFormattedTotal(i => ({
                  ...i,
                  debit: item.valor_bruto,
                })),
          );

          const _branches = _response.filiais.map(item => {
            item.formattedValueGross = moneyFormat(item.valor_bruto);
            item.formattedValueDiscount = moneyFormat(item.valor_desconto);
            item.formattedValueLiquid = moneyFormat(item.valor_liquido);

            return item;
          });

          setFinancialCardBranch(_branches);

          const _flags = _response.bandeira.map(item => {
            item.formattedValueGross = moneyFormat(item.valor_bruto);
            item.formattedValueDiscount = moneyFormat(item.valor_desconto);
            item.formattedValueLiquid = moneyFormat(item.valor_liquido);

            return item;
          });

          setFinancialCardFlag(_flags);

          const _cards = _response.lista.map(item => {
            const dueDate = new Date(item.data_venc.split('/').reverse().join('/'));
            const releaseDate = new Date(item.data_lanc.split('/').reverse().join('/'));
            item.formattedValue = moneyFormat(item.vl_recebido);
            item.formattedDiscount = moneyFormat(item.vl_desconto);
            item.formattedRate = percentFormat(item.taxa);
            item.formattedDueDate = dueDate;
            item.formattedReleaseDate = releaseDate;

            return item;
          });

          setCards(_cards);
        })
        .catch(err => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    },
    [h2iApi, filter],
  );

  return [handleSearch, cards, loading, formattedTotal, financialCardFlag, financialCardBranch];
}
