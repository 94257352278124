import React, { MouseEvent } from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import { BranchStockBalanceData } from 'types/reports/stockBalance';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useStockBalance } from '../../hooks/useStockBalance';
import { branchStockTableTemplate } from '../branchStockTableTemplate';
import { FaFileExcel } from 'react-icons/fa';
import { useApp } from 'hooks/app';
import { api } from 'services/api';
import { download } from 'helpers/download';
import { uuidv4 } from 'helpers/uuidv4';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

const Container = styled('div')({
  '&.numericData': {
    justifyContent: 'flex-end',
    marginRight: 10,
    display: 'flex',
  },
  '&.quantityData': {
    display: 'flex',
    justifyContent: 'center',
  },
});

type BranchItemTableProps = {
  branch: BranchStockBalanceData;
  isSubgroup: boolean;
};

const BranchItemTable: React.FC<BranchItemTableProps> = ({ branch, isSubgroup }) => {
  const { h2iApi } = useApp();
  const { setMoreBranch, moreBranch, filter } = useStockBalance();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setMoreBranch(moreBranch ? null : branch);
  }

  async function handleExportExcel() {
    const response = await h2iApi?.get(`/api/saldoestoque`, {
      params: {
        id_filial: branch.id_filial,
        id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
        filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
        id_categoria: filter.categoryId.join(',') || '',
        id_subcategoria: filter.subcategoryId.join(',') || '',
        id_segmento: filter.segmentId.join(',') || '',
        id_unidade: filter.unityId.join(',') || '',
        sem_movimentacao: 'S',
        id_fabricante: filter.manufacturerId.join(','),
        id_subgrupo: filter.subgroupId.join(','),
        responsavel: filter.made_by,
      },
    });

    if (!response || response.data[0].result) {
      return;
    }

    try {
      const responseExcel = await api.post('/excel-exports', response.data, { responseType: 'blob' });

      download(responseExcel.data, `sem-movimentacao-${branch.filial}-${uuidv4()}`);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      {branchStockTableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'more' && isSubgroup ? (
            <CustomIconButton key={item.id} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={moreBranch?.id_filial === branch.id_filial ? { transform: 'rotate(180deg)' } : undefined}
              />
            </CustomIconButton>
          ) : item.id === 'excel' ? (
            <CustomIconButton key={item.id} onClick={handleExportExcel}>
              <FaFileExcel />
            </CustomIconButton>
          ) : (
            <Container
              key={item.id}
              className={
                item.dataType === 'number' ? 'numericData' : item.dataType === 'quantity' ? 'quantityData' : undefined
              }
            >
              <Typography style={{ fontSize: 11 }} variant="body2">
                {branch[item.id]}
              </Typography>
            </Container>
          ),
        )}
    </>
  );
};

export default BranchItemTable;
