import React from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { TransferRequest } from 'types/transferRequest';

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
  width: 350,
});

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

interface TransferRequestsItemTableProps {
  transfer: TransferRequest;
}

const TransferRequestsItemTable: React.FC<TransferRequestsItemTableProps> = ({ transfer }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <CustomIconButton key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
              <Tooltip title={transfer[item.id]}>
                <Description variant="caption">{transfer[item.id]}</Description>
              </Tooltip>
            </div>
          ),
        )}
    </>
  );
};

export default TransferRequestsItemTable;
