import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import { AbcProductFilterParams } from '../AbcProduct';

type AbcProductContextValue = {
  setOpenModalBranch: Dispatch<SetStateAction<boolean>>;
  setOpenModalSegment: Dispatch<SetStateAction<boolean>>;
  searchValue: string;
  filter: AbcProductFilterParams;
  handleChangeFilter(index: keyof AbcProductFilterParams, value: any): void;
};

const AbcProductContext = createContext<AbcProductContextValue>({} as AbcProductContextValue);
export const AbcProductProvider = AbcProductContext.Provider;

export function useAbcProduct(): AbcProductContextValue {
  const context = useContext(AbcProductContext);
  return context;
}
