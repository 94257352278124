import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNewInvoice } from '../hooks/useNewInvoice';
import { NewInvoice, NewInvoiceFavored } from '../reducer/types';
import { invoiceChange } from '../reducer/actions';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import { Checkbox, FormControlLabel, MenuItem, TextField, Typography, styled } from '@mui/material';
import { NewInvoiceValidation } from '../validation/newInvoiceValidation';
import PersonAutocomplete from './customer-autocomplete/PersonAutocomplete';
import Autocomplete from './autocomplete/PersonAutocomplete';
import { useBranches } from 'providers/BranchesProviders';

const CheckBoxContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 20,
  flexDirection: 'column',
});

const DateContent = styled('div')({
  display: 'flex',
  gap: 15,
});

const FirstRow = styled('div')({
  display: 'grid',
  alignItems: 'end',
  gridTemplateColumns: '2fr 1fr',
  gap: 10,
});

const SecondRow = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 10,
});

const ThirdRow = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 3fr',
  gap: 10,
});

const Column = styled('div')({
  gap: 2,
  display: 'flex',
  flexDirection: 'column',
});

const Container = styled('div')({
  gap: 2,
  display: 'flex',
  flexDirection: 'column',
});

const MoneyContainer = styled('div')({
  padding: 10,
  border: '1px solid #eee',
  backgroundColor: '#eee',
});

interface NewInvoiceFormValidation {
  validation: NewInvoiceValidation;
  type: string;
}

const NewInvoiceForm: React.FC<NewInvoiceFormValidation> = ({ validation, type }) => {
  const { financialBranches } = useBranches();
  const [costCenterGenerateAccount, setCostCenterGenerateAccount] = useState('N');
  const [totalizingAccountGenerateAccount, setTotalizingAccountGenerateAccount] = useState('N');
  const { invoice, dispatch, setFavored, favored, accounts } = useNewInvoice();
  const [open, setOpen] = useState(invoice.id === 0);

  const inputs = {
    monthYear: useRef<HTMLInputElement>(null),
    personAutoComplete: useRef<HTMLInputElement>(null),
    payday: useRef<HTMLInputElement>(null),
    value: useRef<HTMLInputElement>(null),
    invoiceDescription: useRef<HTMLInputElement>(null),
    invoiceNumber: useRef<HTMLInputElement>(null),
    invoiceKey: useRef<HTMLInputElement>(null),
    apropriatedAt: useRef<HTMLInputElement>(null),
    paidAt: useRef<HTMLInputElement>(null),
    type: useRef<HTMLInputElement>(null),
  };

  const endpoint = useMemo(() => {
    const endpoints = {
      T: `getcadastrofornecedores?`,
      D: `getcadastrodespesas?id_filial=${invoice.branchId}&`,
      R: `getcadastroreceitas?id_filial=${invoice.branchId}&`,
      F: `getcadastrofuncionarios?id_filial=${invoice.branchId}&`,
    };

    return endpoints[invoice.type];
  }, [invoice.type, invoice.branchId]);

  const label = useMemo(() => {
    const endpoints = {
      T: `Fornecedor`,
      D: `Conta destino / Classificação de despesa`,
      R: `Receita`,
      F: `Conta destino / Funcionário`,
    };

    return endpoints[invoice.type];
  }, [invoice.type]);

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleInvoiceChange(index: keyof NewInvoice, value: any) {
    setOpen(false);
    dispatch(invoiceChange(index, value));
  }

  function handleAutocompleteOnSelect(_favored: NewInvoiceFavored) {
    setFavored(_favored);
  }

  useEffect(() => {
    const branch = financialBranches.filter(item => item.id === invoice.branchId);

    setCostCenterGenerateAccount(branch[0].gera_contabil);

    if (branch[0].gera_contabil === 'N') {
      dispatch(invoiceChange('costCenterId', 999));
      dispatch(invoiceChange('totalizingAccountId', 999));
    }

    if (branch[0].gera_contabil === 'S' && invoice.costCenterId === 999) {
      dispatch(invoiceChange('costCenterId', invoice.branchId));
    }
  }, [dispatch, financialBranches, invoice.branchId, invoice.costCenterId]);

  useEffect(() => {
    const branch = financialBranches.filter(item => item.id === invoice.costCenterId);

    if (!branch[0]) {
      setTotalizingAccountGenerateAccount('N');
      return;
    }

    setTotalizingAccountGenerateAccount(branch[0].gera_contabil);

    if (branch[0].gera_contabil === 'N') {
      dispatch(invoiceChange('totalizingAccountId', 999));
    }
  }, [dispatch, financialBranches, invoice.costCenterId]);

  const handleKeyDown = (e, currentInput: string) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const inputKeys = Object.keys(inputs);
      const currentIndex = inputKeys.indexOf(currentInput);

      for (let i = currentIndex + 1; i < inputKeys.length; i++) {
        const nextInputKey = inputKeys[i];
        if (inputs[nextInputKey].current) {
          inputs[nextInputKey].current.focus();
          break;
        }
      }
    }
  };

  return (
    <Container>
      <FirstRow>
        <Column>
          <Typography variant="caption">Selecione a filial</Typography>
          <SelectOthersBranch
            autoFocus
            open={open}
            label=""
            onKeyDown={e => handleKeyDown(e, 'branchId')}
            onClick={() => setOpen(!open)}
            viewAllBranches={false}
            branch_id={invoice.branchId}
            setBranch={branch => setCostCenterGenerateAccount(branch.gera_contabil)}
            handleChange={value => handleInvoiceChange('branchId', value)}
            margin="none"
          />
        </Column>

        <DesktopDatePicker
          label="Mês/Ano"
          value={invoice.monthYear}
          onChange={date => handleInvoiceChange('monthYear', date)}
          format="MMMM-yyyy"
          views={['month', 'year']}
          textFieldProps={{
            fullWidth: true,
            ref: inputs.monthYear,
            onKeyDown: e => handleKeyDown(e, 'monthYear'),
          }}
          localeText={{
            clearButtonLabel: 'Limpar',
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </FirstRow>
      <Column>
        <Typography variant="caption">Centro de custo:</Typography>
        <SelectOthersBranch
          disabled={costCenterGenerateAccount === 'N'}
          fullWidth
          label=""
          viewAllBranches={false}
          branch_id={invoice.costCenterId}
          setBranch={branch => setTotalizingAccountGenerateAccount(branch.gera_contabil)}
          handleChange={value => handleInvoiceChange('costCenterId', value)}
          margin="none"
        />
      </Column>
      <Column>
        <Typography variant="caption">Conta totalizadora:</Typography>
        <TextField
          fullWidth
          margin="none"
          value={invoice.totalizingAccountId}
          disabled={totalizingAccountGenerateAccount === 'N'}
          onChange={e => handleInvoiceChange('totalizingAccountId', e.target.value)}
          select
        >
          <MenuItem value={999}>Nenhuma conta selecionada</MenuItem>
          {accounts.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.nome} - {item.formattedSaldo}
            </MenuItem>
          ))}
        </TextField>
      </Column>

      {type === 'TT' && (
        <TextField
          ref={inputs.type}
          margin="none"
          value={invoice.type}
          onKeyDown={e => handleKeyDown(e, 'type')}
          onChange={e => handleInvoiceChange('type', e.target.value)}
          select
          label="Tipo"
        >
          <MenuItem value="D">Despesa</MenuItem>
          <MenuItem value="T">Título</MenuItem>
          <MenuItem value="F">Folha de Pagamento</MenuItem>
          <MenuItem value="R">Receita</MenuItem>
        </TextField>
      )}

      <Column>
        <Typography variant="caption">{label}:</Typography>

        {invoice.type === 'T' ? (
          <PersonAutocomplete
            label=""
            handleKeyDown={(e, currentInput) => handleKeyDown(e, currentInput)}
            endpoint={endpoint}
            placeholder="escolha um registro"
            favored={favored}
            onSelect={handleAutocompleteOnSelect}
            helperText={validation.favoredId || ''}
            error={!!validation.favoredId}
          />
        ) : (
          <Autocomplete
            label=""
            handleKeyDown={(e, currentInput) => handleKeyDown(e, currentInput)}
            endpoint={endpoint}
            placeholder="escolha um registro"
            favored={favored}
            onSelect={handleAutocompleteOnSelect}
            helperText={validation.favoredId || ''}
            error={!!validation.favoredId}
          />
        )}
      </Column>

      <SecondRow>
        <Column>
          <Typography variant="caption">Vencimento:</Typography>
          <DesktopDatePicker
            value={invoice.payday}
            onChange={date => handleInvoiceChange('payday', date)}
            textFieldProps={{
              margin: 'none',
              fullWidth: true,
              error: !!validation.payday,
              helperText: validation.payday,
              inputRef: inputs.payday,
              onKeyDown: e => handleKeyDown(e, 'payday'),
            }}
          />
        </Column>
        <Column>
          <Typography variant="caption">Valor:</Typography>

          <TextField
            margin="none"
            value={invoice.value || ''}
            onChange={event => handleInvoiceChange('value', parseFloat(event.target.value))}
            fullWidth
            color="primary"
            placeholder="Informe o valor"
            inputMode="decimal"
            type="number"
            helperText={validation.value}
            error={!!validation.value}
            inputRef={inputs.value}
            onKeyDown={e => handleKeyDown(e, 'value')}
            InputProps={{
              startAdornment: (
                <MoneyContainer>
                  <Typography variant="button">R$</Typography>
                </MoneyContainer>
              ),
            }}
          />
        </Column>
      </SecondRow>

      <Column>
        <Typography variant="caption">Título:</Typography>
        <TextField
          margin="none"
          value={invoice.invoiceDescription}
          onChange={event => handleInvoiceChange('invoiceDescription', event.target.value)}
          fullWidth
          placeholder="Informe o título"
          error={!!validation.invoiceDescription}
          helperText={validation.invoiceDescription}
          inputRef={inputs.invoiceDescription}
          onKeyDown={e => handleKeyDown(e, 'invoiceDescription')}
        />
      </Column>

      <ThirdRow>
        <Column>
          <Typography variant="caption">N da NF:</Typography>
          <TextField
            margin="none"
            value={invoice.invoiceNumber}
            onChange={event => handleInvoiceChange('invoiceNumber', event.target.value)}
            fullWidth
            placeholder="Número da NF"
            inputRef={inputs.invoiceNumber}
            onKeyDown={e => handleKeyDown(e, 'invoiceNumber')}
          />
        </Column>

        <Column>
          <Typography variant="caption">Chave da NF:</Typography>
          <TextField
            margin="none"
            value={invoice.invoiceKey}
            onChange={event => handleInvoiceChange('invoiceKey', event.target.value)}
            fullWidth
            placeholder="Informe a chave da NF"
            inputRef={inputs.invoiceKey}
            onKeyDown={e => handleKeyDown(e, 'invoiceKey')}
          />
        </Column>
      </ThirdRow>

      <DateContent>
        <Column>
          <Typography variant="caption">Apropriação:</Typography>
          <DesktopDatePicker
            value={invoice.apropriatedAt}
            onChange={date => handleInvoiceChange('apropriatedAt', date)}
            textFieldProps={{
              margin: 'none',
              inputRef: inputs.apropriatedAt,
              fullWidth: true,
              onKeyDown: e => handleKeyDown(e, 'apropriatedAt'),
            }}
          />
        </Column>

        <Column>
          <Typography variant="caption">Pago em:</Typography>
          <DesktopDatePicker
            value={invoice.paidAt}
            onChange={date => handleInvoiceChange('paidAt', date)}
            textFieldProps={{
              margin: 'none',
              fullWidth: true,
              inputRef: inputs.paidAt,
            }}
          />
        </Column>
      </DateContent>
      <CheckBoxContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={invoice.noBankBillet}
              onChange={e => handleInvoiceChange('noBankBillet', e.target.checked)}
              color="primary"
            />
          }
          label="Sem boleto"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={invoice.rmaCredit}
              onChange={e => handleInvoiceChange('rmaCredit', e.target.checked)}
              color="primary"
            />
          }
          label="Crédito RMA"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={invoice.validatedInvoice}
              onChange={e => handleInvoiceChange('validatedInvoice', e.target.checked)}
              color="primary"
            />
          }
          label="Nota fiscal validada"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={invoice.isShippingCompany}
              onChange={e => handleInvoiceChange('isShippingCompany', e.target.checked)}
              color="primary"
            />
          }
          label="Transportadora"
        />
      </CheckBoxContent>
    </Container>
  );
};

export default NewInvoiceForm;
