import React, { useEffect, useState } from 'react';
import { lighten, Typography, styled } from '@mui/material';
import { useCustomer } from 'pages/site-h2i/customers/hooks/useCustomer';
import { Banner } from 'types/banner';
import { api } from 'services/api';
import InsideLoading from 'components/loading/InsideLoading';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginTop: 30,
});

const AreaImage = styled('img')({
  width: 100,
  marginRight: 10,
});

const AreaContent = styled('div')({
  display: 'grid',
  gap: '10px',
});

const CustomArea = styled('div')<{ selected: boolean }>(props => ({
  display: 'flex',
  alignItems: 'center',
  padding: 10,
  cursor: 'pointer',
  borderRadius: 4,
  transition: 'background 0.3s ease',
  border: props.selected ? `1px solid ${props.theme.palette.secondary.main}` : '1px solid #eee',
  backgroundColor: props.selected ? lighten(props.theme.palette.secondary.main, 0.7) : '',
}));

interface Area extends Banner {
  selected: boolean;
}

const CustomerAreas: React.FC = () => {
  const { customer, handleAddArea, handleRemoveArea } = useCustomer();
  const [areas, setAreas] = useState<Area[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api
      .get<Area[]>('/banners')
      .then(response => {
        setAreas(
          response.data
            .filter(area => area.position === 'top')
            .map(area => ({
              ...area,
              selected: false,
            })),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!customer.id) return;

    if (areas.length === 0) return;

    setAreas(areas =>
      areas.map(area => {
        const selected = customer.areas.some(customerArea => customerArea.id === area.id);
        area.selected = selected;
        return area;
      }),
    );
  }, [customer, areas.length]);

  return (
    <Container>
      {loading && <InsideLoading />}
      <Typography variant="caption" gutterBottom>
        Selecione as áreas de atuação
      </Typography>
      <AreaContent>
        {areas.map(area => (
          <CustomArea
            selected={area.selected}
            onClick={area.selected ? () => handleRemoveArea(area.id) : () => handleAddArea(area)}
            key={area.id}
          >
            <AreaImage src={area.image?.imageUrl} alt={area.name} />
            <Typography color="textSecondary" variant="body2">
              {area.name}
            </Typography>
          </CustomArea>
        ))}
      </AreaContent>
    </Container>
  );
};

export default CustomerAreas;
