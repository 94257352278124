import React, { useState } from 'react';
import { Typography } from '@mui/material';
import ResellerItemTable from './AgentItemTable';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import AgentsListMenu from '../AgentsListMenu';
import { Agent } from 'types/agent';
import { useAgents } from 'pages/agents/hooks/useAgents';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type AgentListTableProps = {
  agents: Agent[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const AgentListTable: React.FC<AgentListTableProps> = ({ agents, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const { setDialogApprove, setSelectedAgent } = useAgents();

  function handleClick(agent: Agent) {
    setSelectedAgent(agent);
    setDialogApprove(true);
  }

  return (
    <>
      <AgentsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <div
              className={classes.headerItem}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {agents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(agent => (
            <TableRow onClick={() => handleClick(agent)} key={agent.id}>
              <ResellerItemTable agent={agent} setAnchorEl={setAnchorEl} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default AgentListTable;
