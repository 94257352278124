import { TableTemplate } from 'types/tableTemplate';

export const resellersTableTemplate: TableTemplate[] = [
  {
    id: 'formattedApprovedAt',
    description: 'APROVADO EM',
    originalId: 'approved_at',
    width: 150,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'created_at',
    width: 150,
    notSearchable: true,
  },
  {
    id: 'formattedLimitValue',
    description: 'LIMITE CONCEDIDO',
    originalId: 'limit_value',
    width: 150,
  },
  {
    id: 'formattedMustContact',
    description: 'CONTATAR',
    originalId: 'must_contact',
    width: 100,
  },
  {
    id: 'formattedContacted',
    description: 'CONTATADO',
    originalId: 'contacted',
    width: 100,
  },
  {
    id: 'company_id',
    description: 'ID CLIENTE',
    originalId: 'company_id',
    width: 170,
  },
  {
    id: 'branch_id',
    description: 'ID FILIAL',
    originalId: 'branch_id',
    width: 170,
  },
  {
    id: 'formattedCnpj',
    description: 'CNPJ',
    originalId: 'document',
    width: 170,
  },
  {
    id: 'company_name',
    description: 'RAZÃO SOCIAL',
    originalId: 'company_name',
    width: 350,
  },
];
