import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { H2iCustomer } from 'types/h2iCustomer';
import { useCustomers } from 'pages/customers/hooks/useCustomers';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

type CustomerItemModuleProps = {
  customer: H2iCustomer;
};

const CustomerItemModule: React.FC<CustomerItemModuleProps> = ({ customer }) => {
  const classes = useStyles();
  const { setSelectedCustomer, setEditCustomerDialog } = useCustomers();

  function handleClick() {
    setSelectedCustomer(customer);
    setEditCustomerDialog(true);
  }

  return (
    <>
      <ListItem button className={classes.listItem} onClick={handleClick}>
        <Typography variant="caption" color="primary">
          Código {customer.codigo}
        </Typography>
        <Typography gutterBottom>{customer.nome}</Typography>
        <Typography variant="body2" color="textSecondary">
          {customer.cnpj_cpf}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`${customer.cidade} - ${customer.uf}`}
        </Typography>
      </ListItem>
    </>
  );
};

export default CustomerItemModule;
