import { TableTemplate } from 'types/tableTemplate';

export const accountsTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 200,
  },
  {
    id: 'conta_contabil',
    description: 'Cod Contábil',
    originalId: 'conta_contabil',
    width: 100,
  },
  {
    id: 'nome',
    description: 'Nome',
    originalId: 'nome',
    width: 350,
  },
  {
    id: 'formattedSaldo',
    description: 'Saldo',
    originalId: 'saldo',
    width: 120,
    dataType: 'number',
  },
];
