import React, { MouseEvent } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { useBanners } from 'pages/banners/hooks/useBanners';
import { Banner } from 'types/banner';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type BannerItemTableProps = {
  banner: Banner;
};

const BannerItemTable: React.FC<BannerItemTableProps> = ({ banner }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedBanner } = useBanners();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedBanner(banner);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{banner[item.id]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default BannerItemTable;
