import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { usePurchase } from '../../hooks/usePurchase';
import history from 'services/history';

type PurchaseOrderListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  onExited(): void;
};

const PurchaseOrderListMenu: React.FC<PurchaseOrderListMenuProps> = ({ anchorEl, onExited }) => {
  const { selectedOrder } = usePurchase();

  function handlePrintClick() {
    if (!selectedOrder) {
      return;
    }

    history.push(`/purchasing/registration/print/${selectedOrder.codigo_pedido}`);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onExited}>
      <MenuItem onClick={handlePrintClick}>Imprimir</MenuItem>
    </Menu>
  );
};

export default PurchaseOrderListMenu;
