import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'services/api';
import history from 'services/history';
import PartnerActions from './PartnerActions';
import PartnerForm from '../PartnerForm';
import { usePartnerValidation } from '../validation/partnerValidation';
import { Partner } from 'types/partner';

const PartnerUpdate: React.FC = () => {
  const [partner, setPartner] = useState<Partner>({} as Partner);
  const [validation, setValidation, validate] = usePartnerValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/partners/${id}`)
      .then(response => {
        setPartner(response.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function handleChange(index: keyof Partner, value: any) {
    setPartner(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(partner)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/partners/${id}`, partner)
      .then(() => {
        setSaving(false);
        history.push('/site/partners');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete(`/partners/${id}`)
      .then(() => {
        setSaving(false);
        history.push('/site/partners');
        handleOpen('Excluído');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Site"
        ActionsComponent={
          <PartnerActions
            saving={saving}
            loading={loading}
            handleSubmit={handleValidation}
            handleDelete={handleDelete}
          />
        }
        backAction={() => history.push('/site/partners')}
      />
      <PageHeader title="Editar banner" />
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          <form onSubmit={handleValidation}>
            <PartnerForm handleChange={handleChange} partner={partner} validation={validation} />
          </form>
        </Grid>
      )}
    </>
  );
};

export default PartnerUpdate;
