import { ArrowBack, Error } from '@mui/icons-material';
import { Button, Typography, styled } from '@mui/material';
import Dialog, { DialogConsumer } from 'components/dialogs/Dialog';
import React from 'react';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,
});

const Code = styled('code')({
  padding: 20,
  border: '1px solid #ccc',
  backgroundColor: '#eee',
  marginTop: 20,
  maxWidth: 600,
  width: '100%',
});

const Actions = styled('div')({
  display: 'flex',
  gap: 10,
});

interface ErrorHandlerDialogProps {
  onExited(): void;
  message: string;
  detail?: string;
}

const ErrorHandlerDialog: React.FC<ErrorHandlerDialogProps> = ({ onExited, message, detail }) => {
  return (
    <Dialog fullScreen onExited={onExited}>
      <Container>
        <Error color="error" fontSize="large" />

        <Typography align="center" variant="h5">
          {message}
        </Typography>

        <Code>
          <Typography gutterBottom variant="caption" color="textSecondary">
            detalhe
          </Typography>
          <Typography variant="subtitle2">{detail}</Typography>
        </Code>
        <DialogConsumer>
          {context => (
            <Actions>
              <Button onClick={context.handleClose} startIcon={<ArrowBack />} color="primary" variant="contained">
                voltar
              </Button>
            </Actions>
          )}
        </DialogConsumer>
      </Container>
    </Dialog>
  );
};

export default ErrorHandlerDialog;
