import React, { MouseEvent } from 'react';
import { IconButton, Typography } from '@mui/material';
import { ManufacturerStockBalanceData } from 'types/reports/stockBalance';
import { manufacturerStockTableTemplate } from '../manufacturerStockTableTemplate';
import { useStockBalance } from '../../hooks/useStockBalance';
import { KeyboardArrowDown } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  quantityData: {
    display: 'flex',
    justifyContent: 'center',
  },
});

type ManufacturerListItemProps = {
  manufacturer: ManufacturerStockBalanceData;
};

const ManufacturerListItem: React.FC<ManufacturerListItemProps> = ({ manufacturer }) => {
  const classes = styles();
  const { setMoreManufacturer, moreManufacturer } = useStockBalance();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setMoreManufacturer(moreManufacturer ? null : manufacturer);
  }

  return (
    <>
      {manufacturerStockTableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'more' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={
                  moreManufacturer?.id_fabricante === manufacturer.id_fabricante
                    ? { transform: 'rotate(180deg)' }
                    : undefined
                }
              />
            </IconButton>
          ) : (
            <div
              key={item.id}
              className={
                item.dataType === 'number'
                  ? classes.numericData
                  : item.dataType === 'quantity'
                  ? classes.quantityData
                  : undefined
              }
            >
              <Typography style={{ fontSize: 11 }} variant="body2">
                {manufacturer[item.id]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default ManufacturerListItem;
