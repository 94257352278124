import React, { useEffect, useRef } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import { IconButton, InputAdornment, TextField, styled } from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import { CategoriesQueryParams } from './Categories';

const Filter = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '400px 150px 1fr',
  columnGap: 10,
  flex: 1,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '120px 100px 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
    flex: 1,
  },
}));

type CategoryFilterBoxProps = {
  queryParams: CategoriesQueryParams;
  handleQueryParamsChange(index: keyof CategoriesQueryParams, value: any): void;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const CategoryFilterBox: React.FC<CategoryFilterBoxProps> = ({
  queryParams,
  handleQueryParamsChange,
  setDisplayMode,
  displayMode,
}) => {
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, []);

  return (
    <FilterBox>
      <Filter>
        <TextField
          label="Pesquisa"
          placeholder="Pesquisa..."
          value={queryParams.nome}
          onChange={e => handleQueryParamsChange('nome', e.target.value)}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: queryParams.nome?.length ? (
              <InputAdornment position="start">
                <IconButton onClick={() => handleQueryParamsChange('nome', '')} size="small">
                  <Close />
                </IconButton>
              </InputAdornment>
            ) : undefined,
          }}
        />
      </Filter>

      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default CategoryFilterBox;
