import { useEffect, useState } from 'react';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { Waste } from '../types/waste';
import { wasteInitialState } from '../reducer/reducer';
import { useApp } from 'hooks/app';

type UseFetchWaste = [Waste, boolean];

export function useFetchWaste(id?: string): UseFetchWaste {
  const { h2iApi } = useApp();
  const [loading, setLoading] = useState(true);
  const { showErrorDialog } = useErrorHandler();
  const [waste, setWaste] = useState<Waste>(wasteInitialState);

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    h2iApi
      .get(`/api/getreee?id=${id}`)
      .then(response => {
        setWaste(response.data.reee[0]);
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível carregar REEE',
        });
      })
      .finally(() => setLoading(false));
  }, [id, showErrorDialog, h2iApi]);

  return [waste, loading];
}
