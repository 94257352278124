import React from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import { Typography, Button, Theme } from '@mui/material';
import { useDialog } from 'components/dialogs/Dialog';
import { makeStyles } from '@mui/styles';

const styles = makeStyles<Theme>(theme => ({
  container: {
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}));

interface ConfirmApprovementProps {
  onExited(): void;
  handleApprove(handleCloseDialog: () => void): void;
}

const ConfirmApprovement: React.FC<ConfirmApprovementProps> = ({ onExited, handleApprove }) => {
  const classes = styles();

  const { handleClose } = useDialog();

  function handleConfirm(handleCloseDialog: () => void) {
    handleApprove(handleClose);
    handleCloseDialog();
  }

  return (
    <DialogInput maxWidth="xs" onExited={onExited}>
      <div className={classes.container}>
        <Typography>Você deseja realmente aprovar essa solicitação de limite de crédito?</Typography>
        <DialogInputConsumer>
          {context => (
            <div className={classes.actions}>
              <Button variant="text" color="primary" onClick={context.handleClose}>
                Não
              </Button>
              <Button variant="contained" color="primary" onClick={() => handleConfirm(context.handleClose)}>
                Sim
              </Button>
            </div>
          )}
        </DialogInputConsumer>
      </div>
    </DialogInput>
  );
};

export default ConfirmApprovement;
