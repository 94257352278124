import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { Bill } from 'types/bill';

import { makeStyles } from '@mui/styles';

type BillItemModuleProps = {
  bill: Bill;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
});

const BillItemModule: React.FC<BillItemModuleProps> = ({ bill }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem}>
      <Typography variant="caption" gutterBottom color="primary">
        Documento {bill.document_number}
      </Typography>
      <Typography gutterBottom>{bill.beneficiary.company_name}</Typography>
      <Typography variant="body2" color="textSecondary">
        {bill.formattedValue}
      </Typography>
      <Typography variant="body2" color="textSecondary">{`Vence em ${bill.formattedPayday}`}</Typography>
    </ListItem>
  );
};

export default BillItemModule;
