import { Typography, styled } from '@mui/material';
import React from 'react';
import { moneyFormat } from 'helpers/numberFormat';
import { Favorite, ThumbDown } from '@mui/icons-material';
import { useInvoices } from './hooks/useInvoices';

const Container = styled('div')<{ showTotals: boolean }>(({ theme, showTotals }) => ({
  display: 'flex',
  transition: 'all 0.2s ease',
  flexWrap: 'wrap',
  columnGap: 25,
  rowGap: 5,
  justifyContent: 'space-between',
  marginBottom: 10,
  [theme.breakpoints.down('sm')]: {
    height: showTotals ? 420 : 0,
    opacity: showTotals ? 1 : 0,
    marginBottom: 0,
  },
  '& .item': {
    minWidth: 225,
    flex: 1,
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
    alignItems: 'center',
    border: '1px solid #000!important',
    gap: 15,
    '&.revenue': {
      color: theme.palette.success.main,
    },
    '&.expense': {
      color: theme.palette.error.main,
    },
    '&.personnel': {
      color: theme.palette.primary.main,
    },
    '&.invoice': {
      color: theme.palette.warning.main,
    },
    '& .value': {
      fontSize: 24,
      textAlign: 'end',
      paddingRight: 5,
    },
    '& svg': {
      paddingLeft: 5,
    },
    '& .description': {
      fontWeight: 600,
      padding: '10px 5px',
      gridColumn: '1/3',
      borderTop: '1px solid #000!important',
      textAlign: 'end',
    },
  },
}));

const iconStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

const InvoicesTotal: React.FC = () => {
  const { invoicesTotal, showTotals } = useInvoices();

  const formattedExpense = moneyFormat(invoicesTotal.expense);
  const formattedRevenue = moneyFormat(invoicesTotal.revenue);
  const formattedPersonnel = moneyFormat(invoicesTotal.personnel);
  const formattedInvoice = moneyFormat(invoicesTotal.invoice);

  return (
    <Container showTotals={showTotals}>
      <div className="item revenue">
        <Favorite style={iconStyle} />
        <Typography className="value" fontSize={12} variant="subtitle2">
          {formattedRevenue}
        </Typography>
        <Typography className="description revenue" variant="caption">
          Receita (R$)
        </Typography>
      </div>

      <div className="item expense">
        <ThumbDown style={iconStyle} />
        <Typography className="value" fontSize={12} variant="subtitle2">
          {formattedExpense}
        </Typography>
        <Typography className="description expense" variant="caption">
          Despesa (R$)
        </Typography>
      </div>

      <div className="item personnel">
        <ThumbDown style={iconStyle} />
        <Typography className="value" fontSize={12} variant="subtitle2">
          {formattedPersonnel}
        </Typography>
        <Typography className="description personnel" variant="caption">
          Folha de Pagamento (R$)
        </Typography>
      </div>

      <div className="item invoice">
        <ThumbDown style={iconStyle} />
        <Typography className="value" fontSize={12} variant="subtitle2">
          {formattedInvoice}
        </Typography>
        <Typography className="description invoice" variant="caption">
          Título (R$)
        </Typography>
      </div>
    </Container>
  );
};

export default InvoicesTotal;
