import { Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { useTable } from 'components/table/hook/useTable';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import { useAppropriationDate } from 'pages/appropiation-date/hooks/useApproprationDate';
import React from 'react';
import { AppropriationDate } from 'types/appropriationDate';
import AppropriationDateItemTable from './AppropriationDateItemTable';
import { makeStyles } from '@mui/styles';

interface AppropriationDateListTableProps {
  dates: AppropriationDate[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

const AppropriationDateListTable: React.FC<AppropriationDateListTableProps> = ({ dates, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { tableTemplate } = useTable();
  const { rowsPerPage, page } = usePagination();
  const { setSelectedDate } = useAppropriationDate();

  return (
    <TableContent>
      <TableHeader>
        {tableTemplate.map(item => (
          <div
            className={classes.headerItem}
            key={item.id}
            onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
          >
            <Typography variant="caption" color="primary">
              {item.description}
            </Typography>
            {orderedIndex.index === item.originalId && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </div>
        ))}
      </TableHeader>
      <TableBody>
        {dates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(date => (
          <TableRow onClick={() => setSelectedDate(date)} key={date.id}>
            <AppropriationDateItemTable date={date} />
          </TableRow>
        ))}
      </TableBody>
    </TableContent>
  );
};

export default AppropriationDateListTable;
