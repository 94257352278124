import React, { useRef, useEffect } from 'react';
import { TextField, Grid, MenuItem } from '@mui/material';
import { Settings } from 'types/settings';
import { SettingsIndexes } from './reducer/types';
import { SettingsValidation } from './Settings';
import { EmailTemplate } from 'types/emailTemplate';

type SettingsFormProps = {
  validation: SettingsValidation;
  handleChange(index: SettingsIndexes, value: any): void;
  settings: Settings;
  emailTemplates: EmailTemplate[];
};

const SettingsForm: React.FC<SettingsFormProps> = ({ validation, handleChange, settings, emailTemplates }) => {
  const inputs = {
    api_host: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;
    if (!inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <Grid item xs={12} md={6} lg={4} xl={4}>
      <TextField
        inputRef={inputs.api_host}
        error={!!validation.api_host}
        helperText={validation.api_host}
        label="API Host"
        placeholder="Digite a URL da API"
        margin="normal"
        value={settings.api_host}
        onChange={e => handleChange('api_host', e.target.value)}
        fullWidth
        autoFocus
      />
      <TextField
        select
        label="Template base para e-mails"
        margin="normal"
        fullWidth
        value={settings.base_email_template_id || ''}
        onChange={e => handleChange('base_email_template_id', e.target.value)}
      >
        {emailTemplates.map(template => (
          <MenuItem value={template.id} key={template.id}>
            {template.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Template e-mail lembrete de pagamento"
        margin="normal"
        fullWidth
        value={settings.invoice_email_template_id || ''}
        onChange={e => handleChange('invoice_email_template_id', e.target.value)}
      >
        {emailTemplates.map(template => (
          <MenuItem value={template.id} key={template.id}>
            {template.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Template e-mail nota fiscal"
        margin="normal"
        fullWidth
        value={settings.receipt_email_template_id || ''}
        onChange={e => handleChange('receipt_email_template_id', e.target.value)}
      >
        {emailTemplates.map(template => (
          <MenuItem value={template.id} key={template.id}>
            {template.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Template e-mail novo revendedor - vendedor"
        margin="normal"
        fullWidth
        value={settings.new_reseller_template_id || ''}
        onChange={e => handleChange('new_reseller_template_id', e.target.value)}
      >
        {emailTemplates.map(template => (
          <MenuItem value={template.id} key={template.id}>
            {template.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Template e-mail novo revendedor - revendedor"
        margin="normal"
        fullWidth
        value={settings.approved_reseller_template_id || ''}
        onChange={e => handleChange('approved_reseller_template_id', e.target.value)}
      >
        {emailTemplates.map(template => (
          <MenuItem value={template.id} key={template.id}>
            {template.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Template e-mail novo inscrito da newslleter"
        margin="normal"
        fullWidth
        value={settings.newsletter_subscriber_template_id || ''}
        onChange={e => handleChange('newsletter_subscriber_template_id', e.target.value)}
      >
        {emailTemplates.map(template => (
          <MenuItem value={template.id} key={template.id}>
            {template.name}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default SettingsForm;
