import React, { useState } from 'react';
import CostItemModule from './ActionItemModule';
import List from 'components/list/List';
import { ProjectAction } from 'types/project';
import ProjectCostsListMenu from '../ProjectActionsListMenu';

type ActionListModuleProps = {
  actions: ProjectAction[];
};

const ActionListModule: React.FC<ActionListModuleProps> = ({ actions }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <>
      <ProjectCostsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <List>
        {actions.map(action => (
          <CostItemModule setAnchorEl={setAnchorEl} key={action.id} action={action} />
        ))}
      </List>
    </>
  );
};

export default ActionListModule;
