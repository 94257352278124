import React, { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { Avatar, Checkbox, FormControlLabel, IconButton, TextField, Tooltip, Typography, styled } from '@mui/material';
import { ArrowDropDown, CopyAllTwoTone, Send } from '@mui/icons-material';
import { useSelector } from 'store/selector';
import { useMessaging } from 'hooks/messaging';

interface AiArtHelperProps {
  text: string;
  response: string;
  lastSearchText: string;
  loading: boolean;
  setText: Dispatch<SetStateAction<string>>;
  chatOpen: boolean;
  setChatOpen: Dispatch<SetStateAction<boolean>>;
  useDallE: boolean;
  setUseDallE: Dispatch<SetStateAction<boolean>>;
}

interface OutputProps {
  open: boolean;
}

const Container = styled('div')(props => ({
  padding: '10px 40px 10px 10px',
  display: 'flex',
  cursor: 'text',
  position: 'relative',
  borderRadius: '0.375rem',
  border: `2px solid ${props.theme.palette.primary.main}`,
}));

const CustomTextField = styled(TextField)({
  '& > div > textarea': {
    color: '#000',
    fontSize: 12,
  },
  '& > label': {
    color: '#000',
    fontSize: 12,
  },
  '& > div:before': {
    borderBottom: 'none!important',
  },
  '& > div:after': {
    borderBottom: 'none!important',
  },
});

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.success.main,
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
}));

const Output = styled('div')<OutputProps>(props => ({
  display: props.open ? 'flex' : 'none',
  width: '100%',
  flexDirection: 'column',
}));

const ContainerOutput = styled('div')<OutputProps>(props => ({
  height: props.open ? '100%' : 0,
  marginTop: -14,
  position: 'relative',
  display: 'flex',
  border: props.open ? `2px solid ${props.theme.palette.primary.main}` : '',
}));

const CustomIconButtonArrow = styled(IconButton)({
  position: 'absolute',
  right: 0,
  top: 0,
});

const CustomArrowDropDown = styled(ArrowDropDown)<OutputProps>(props => ({
  transition: 'all ease 0.3s',
  transform: props.open ? 'rotate(180deg)' : 'rotate(0deg)',
}));

const ContentQuestion = styled('div')({
  backgroundColor: '#e3e3e3',
});

const ContentAnswer = styled('div')({
  backgroundColor: '#f1f1f1',
});

const BoxQuestion = styled('div')({
  padding: 10,
  display: 'grid',
  gridTemplateColumns: '50px 1fr',
});

const DivResponse = styled('div')({
  color: '#000',
  fontSize: '0.9rem',
  lineHeight: 2,
  alignSelf: 'center',
});

const CustomQuestionTypography = styled(Typography)({
  fontSize: '0.9rem',
  width: '100%',
  color: '#000',
  alignSelf: 'center',
});

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `2px solid ${theme.palette.primary.light}`,
}));

const AiArtHelper: React.FC<AiArtHelperProps> = ({
  text,
  setText,
  loading,
  response,
  lastSearchText,
  chatOpen,
  setChatOpen,
  useDallE,
  setUseDallE,
}) => {
  const { handleOpen } = useMessaging();
  const user = useSelector(state => state.user);
  const input = useRef<HTMLInputElement>(null);

  const onFocus = useCallback(() => {
    input.current?.focus();
  }, []);

  function handleCopyToClipboard() {
    navigator.clipboard.writeText(response);
    handleOpen('Texto copiado para área de transferência');
  }

  return (
    <>
      <FormControlLabel
        label="Arte para ser usada no dall-e"
        control={<Checkbox checked={useDallE} onChange={e => setUseDallE(e.target.checked)} />}
      />
      <Container onClick={onFocus}>
        <CustomTextField
          disabled={loading}
          fullWidth
          label="assistente de ideias, fala ai o que precisa que te darei uma boa sugestão"
          multiline
          autoFocus
          placeholder="ex.: sugestão de ideia de card para o dia dos pais"
          ref={input}
          value={text}
          onChange={e => setText(e.target.value)}
        />
        <CustomIconButton type="submit" disabled={!text || loading}>
          <Send />
        </CustomIconButton>
      </Container>

      {response && (
        <ContainerOutput open={chatOpen}>
          <Output open={chatOpen}>
            <ContentQuestion>
              <BoxQuestion>
                <CustomAvatar>{user?.name[0]}</CustomAvatar>
                <CustomQuestionTypography>{lastSearchText}</CustomQuestionTypography>
              </BoxQuestion>
            </ContentQuestion>
            <ContentAnswer>
              <BoxQuestion>
                <CustomAvatar src="https://vagnerpontes.com.br/wp-content/uploads/2023/05/chat-gpt.png" />
                <DivResponse dangerouslySetInnerHTML={{ __html: loading ? 'Gerando sua resposta...' : response }} />
                <IconButton style={{ gridColumn: 'span-2' }} onClick={handleCopyToClipboard}>
                  <CopyAllTwoTone fontSize="small" color="primary" />
                </IconButton>
              </BoxQuestion>
            </ContentAnswer>
          </Output>
          <Tooltip title="Expandir">
            <CustomIconButtonArrow onClick={() => setChatOpen(!chatOpen)}>
              <CustomArrowDropDown open={chatOpen} />
            </CustomIconButtonArrow>
          </Tooltip>
        </ContainerOutput>
      )}
    </>
  );
};

export default AiArtHelper;
