import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import CreditAnalysisQuestionItemModule from './CreditAnalysisQuestionItemModule';
import { CreditAnalysisQuestion } from 'types/creditAnalysisQuestion';

type CreditAnalysisQuestionListModuleProps = {
  questions: CreditAnalysisQuestion[];
};

const CreditAnalysisQuestionListModule: React.FC<CreditAnalysisQuestionListModuleProps> = ({ questions }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {questions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(question => (
            <CreditAnalysisQuestionItemModule key={question.id} question={question} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default CreditAnalysisQuestionListModule;
