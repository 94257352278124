import React, { useState, useRef } from 'react';
import { Button, TextField, Typography, styled } from '@mui/material';
import NumberFormat from 'react-number-format';
import { usePriceProtection } from '../hook/usePriceProtection';
import Dialog from 'components/dialogs/Dialog';
import { moneyFormat } from 'helpers/numberFormat';
import SelectBranch from 'components/select-branches/SelectBranch';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import ConfirmSave from '../confirm/ConfirmSave';
import ConfirmStock from '../confirm/ConfirmStock';

const ButtonActions = styled('div')({
  padding: 10,
  display: 'flex',
  alignItems: 'end',
  backgroundColor: '#30519f',
  justifyContent: 'space-between',
});

const ContainerPrice = styled('div')({
  flex: 1,
  padding: 20,
  marginTop: 10,
  backgroundColor: '#fff',
});

const ContentPrice = styled('div')({
  maxWidth: 400,
  margin: '0 auto',
});

const Box = styled('div')(({ theme }) => ({
  padding: 10,
  minWidth: 115,
  display: 'flex',
  borderRadius: 6,
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: '#30519f',
  justifyContent: 'space-between',
  boxShadow: '0px 0px 6px 0px #000',
  '&.receive': {
    backgroundColor: '#92D492',
  },
  '&.stock': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 150,
  },
}));

const CustomInput = styled('input')({
  maxWidth: 50,
  margin: 0,
  fontSize: 16,
  color: '#fff',
  lineHeight: '1.5',
  border: 'none',
  textAlign: 'center',
  background: 'transparent',
  fontFamily:
    '"Montserrat",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Header = styled('div')({
  gap: 5,
  padding: 20,
  display: 'flex',
  flexWrap: 'wrap',
  backgroundColor: '#fff',
  justifyContent: 'space-between',
});

const Row = styled('div')({
  gap: 15,
  marginTop: 10,
  padding: 20,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',
  justifyContent: 'space-between',
  '&.branch': {
    alignItems: 'end',
    justifyContent: 'center',
  },
});

const DueContent = styled('div')({
  minWidth: 0,
  flex: 1,
  '& > div': {
    width: '100%',
    '& > div': {
      justifyContent: 'end',
      borderBottom: '2px solid #30519f',
    },
  },
});

const contentStyle: React.CSSProperties = {
  marginTop: 0,
  padding: 0,
  backgroundColor: '#eee',
};

const selectStyle: React.CSSProperties = {
  minWidth: 0,
  width: '100%',
  borderRadius: 6,
  fontSize: 13,
  borderBottom: '2px solid #30519f',
};

const PriceProtectionStepThree: React.FC = () => {
  const [confirmStock, setConfirmStock] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const [changeStock, setChangeStock] = useState(false);
  const [editManufacturerName, setEditManufacturerName] = useState(false);
  const { selectedProduct, setSelectedProduct, handleBack, priceData, handleChange, validation } = usePriceProtection();
  const ref = useRef<HTMLInputElement>();

  if (!selectedProduct) {
    return <></>;
  }

  function handleChangeProduct(value: any) {
    setSelectedProduct(selectedProduct =>
      selectedProduct
        ? {
            ...selectedProduct,
            est: value,
          }
        : null,
    );
  }

  function handleConfirmStock() {
    setConfirmSave(true);
  }

  function handleNotConfirmStock() {
    setConfirmStock(false);
  }

  return (
    <>
      {confirmSave ? (
        <ConfirmSave handleNotConfirm={() => setConfirmSave(false)} />
      ) : confirmStock ? (
        <ConfirmStock handleConfirmStock={handleConfirmStock} handleNotConfirmStock={handleNotConfirmStock} />
      ) : (
        <Dialog contentStyle={contentStyle} onExited={handleBack} maxWidth="sm">
          <Container>
            <ButtonActions>
              <Button variant="contained" onClick={() => setConfirmStock(true)}>
                Salvar
              </Button>
              <Button variant="contained" onClick={handleBack}>
                Voltar
              </Button>
            </ButtonActions>

            <Header>
              <Box className="stock" onClick={() => setChangeStock(true)}>
                <Typography color="#eee" fontWeight="bold" variant="caption">
                  Estoque total
                </Typography>
                {changeStock ? (
                  <CustomInput
                    autoFocus
                    onChange={e => handleChangeProduct(e.target.value)}
                    value={selectedProduct.est}
                    onBlur={() => setChangeStock(false)}
                  />
                ) : (
                  <Typography color="#eee">{selectedProduct.est}</Typography>
                )}
              </Box>
              <Box>
                <Typography color="#eee" fontWeight="bold" variant="caption">
                  Custo
                </Typography>
                <Typography color="#eee">{selectedProduct.formattedCusto}</Typography>
              </Box>
              <Box>
                <Typography color="#eee" fontWeight="bold" variant="caption">
                  Custo Novo
                </Typography>
                <Typography color="#eee">{moneyFormat(selectedProduct.custo - parseFloat(priceData.value))}</Typography>
              </Box>
              <Box className="receive">
                <Typography color="#000" fontWeight="bold" variant="caption">
                  Valor a receber
                </Typography>
                <Typography color="#000">
                  {moneyFormat(parseInt(selectedProduct.est) * parseFloat(priceData.value))}
                </Typography>
              </Box>
            </Header>

            <ContainerPrice>
              <ContentPrice>
                <Typography fontWeight="bold" color="#30519f">
                  Price Protection (R$):
                </Typography>
                <NumberFormat
                  style={{
                    border: '2px solid #30519f',
                    borderRadius: 6,
                    padding: '10px 5px',
                    fontSize: 18,
                    color: '#30519f',
                    width: '100%',
                  }}
                  autoFocus
                  thousandsGroupStyle="thousand"
                  prefix="R$ "
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale
                  decimalScale={2}
                  value={priceData.value}
                  onValueChange={values => handleChange('value', values.value)}
                  inputMode="decimal"
                  isNumericString
                />
                {!!validation.value && (
                  <Typography fontSize={13} variant="caption" color="red">
                    {validation.value}
                  </Typography>
                )}
              </ContentPrice>
            </ContainerPrice>

            <Row className="branch">
              <div style={{ flex: 1 }}>
                <Typography fontWeight="bold" color="#30519f">
                  Informe a filial:
                </Typography>
                <SelectBranch
                  selectStyle={selectStyle}
                  viewAllBranches={false}
                  branch_id={priceData.branchId}
                  handleChange={e => handleChange('branchId', e)}
                />
                {!!validation.branchId && (
                  <Typography fontSize={13} variant="caption" color="red">
                    {validation.branchId}
                  </Typography>
                )}
              </div>
              <DueContent>
                <Typography fontWeight="bold" color="#30519f">
                  Vencimento
                </Typography>
                <KeyboardDatePicker
                  minDate={new Date()}
                  onChange={date => handleChange('due', date)}
                  value={priceData.due}
                />
                {!!validation.due && (
                  <Typography fontSize={13} variant="caption" color="red">
                    {validation.due}
                  </Typography>
                )}
              </DueContent>
            </Row>

            <Row>
              <Typography color="#30519f" fontWeight="bold">
                Descrição do título:
              </Typography>

              {editManufacturerName ? (
                <TextField
                  inputRef={ref}
                  error={!!validation.manufacturerName}
                  helperText={validation.manufacturerName}
                  autoFocus
                  style={{ flex: 0.9 }}
                  onBlur={() => setEditManufacturerName(false)}
                  value={priceData.manufacturerName}
                  onChange={e => handleChange('manufacturerName', e.target.value)}
                />
              ) : (
                <div>
                  <Typography
                    style={{ cursor: 'pointer' }}
                    variant="caption"
                    onClick={() => setEditManufacturerName(true)}
                  >
                    {priceData.manufacturerName}
                  </Typography>
                  {!!validation.manufacturerName && (
                    <Typography
                      style={{ cursor: 'pointer' }}
                      variant="caption"
                      onClick={() => setEditManufacturerName(true)}
                      fontSize={13}
                      color="red"
                    >
                      {validation.manufacturerName}
                    </Typography>
                  )}
                </div>
              )}
            </Row>

            <Row>
              <Typography color="#30519f" fontWeight="bold">
                Produto:
              </Typography>

              <Typography variant="caption">{selectedProduct?.produto}</Typography>
            </Row>
          </Container>
        </Dialog>
      )}
    </>
  );
};

export default PriceProtectionStepThree;
