import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import BillItemModule from './BillItemModule';
import { Bill } from 'types/bill';

type BillListModuleProps = {
  bills: Bill[];
};

const BillListModule: React.FC<BillListModuleProps> = ({ bills }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {bills.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(bill => (
            <BillItemModule key={bill.id} bill={bill} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default BillListModule;
