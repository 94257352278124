import React from 'react';
import { AbcProductSeller } from 'types/abcProduct';
import { Typography, ListItem } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type SellerItemModuleProps = {
  seller: AbcProductSeller;
};

const SellerItemModule: React.FC<SellerItemModuleProps> = ({ seller }) => {
  const classes = useStyles();

  return (
    <>
      <ListItem button className={classes.listItem}>
        <Typography variant="caption" color="primary">
          Pedido por - {seller.pedido_por}
        </Typography>
        <Typography gutterBottom>Quantidade - {seller.qtd}</Typography>
        <Typography gutterBottom>Unitário - {seller.formattedUnitary}</Typography>
        <Typography gutterBottom>Valor total - {seller.formattedTotal}</Typography>
        <Typography gutterBottom>SPIFF - {seller.formattedSpiff}</Typography>
        <Typography gutterBottom>MC - {seller.formattedMc}</Typography>
        <Typography gutterBottom>Custo - {seller.formattedCoust}</Typography>
      </ListItem>
    </>
  );
};

export default SellerItemModule;
