import React from 'react';
import { ProductGroup } from 'types/product';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import DialogInput from 'components/dialogs/DialogInput';
import { SegmentFilter, UnityFilter } from 'components/filter-more/hooks/useFetchMoreFilters';
import { MonthlyFilterParams } from './MonthlyComparison';
import { Search } from '@mui/icons-material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { CategoryMonthly } from 'types/monthlyComparison';
import SelectBranch from 'components/select-branches/SelectBranch';

type MonthlyFilterMobileProps = {
  onExited(): void;
  groups: ProductGroup[];
  units: UnityFilter[];
  segments: SegmentFilter[];
  categories: CategoryMonthly[];
  filter: MonthlyFilterParams;
  handleChangeFilter(index: keyof MonthlyFilterParams, value: any): void;
  onSearch(): void;
};

const MonthlyFilterMobile: React.FC<MonthlyFilterMobileProps> = ({
  onExited,
  groups,
  segments,
  units,
  categories,
  filter,
  handleChangeFilter,
  onSearch,
}) => {
  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <div style={{ display: 'grid', gap: 10 }}>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />
        <Select
          fullWidth
          label="categoria"
          value={filter.category}
          onChange={e => handleChangeFilter('category', e.target.value as number)}
        >
          <MenuItem value={0}>Todas as categorias</MenuItem>

          {categories?.map(category => (
            <MenuItem key={category.id} value={category.id}>
              {category.categoria}
            </MenuItem>
          ))}
        </Select>
        <Select
          fullWidth
          label="Grupo"
          value={filter.productId}
          onChange={e => handleChangeFilter('productId', e.target.value)}
        >
          <MenuItem value={0}>Todos os grupos</MenuItem>

          {groups.map(group => (
            <MenuItem key={group.CODIGO_GRUPO} value={group.CODIGO_GRUPO}>
              {group.NOME}
            </MenuItem>
          ))}
        </Select>

        <Select
          fullWidth
          label="segmentos"
          value={filter.segment}
          onChange={e => handleChangeFilter('segment', e.target.value as number)}
        >
          <MenuItem value={0}>Todas os segmentos</MenuItem>

          {segments?.map(segment => (
            <MenuItem key={segment.id} value={segment.id}>
              {segment.segmento}
            </MenuItem>
          ))}
        </Select>

        <Select
          fullWidth
          label="unidades"
          value={filter.unity}
          onChange={e => handleChangeFilter('unity', e.target.value as number)}
        >
          <MenuItem value={0}>Todas as unidades</MenuItem>

          {units?.map(unity => (
            <MenuItem key={unity.id} value={unity.id}>
              {unity.unidade}
            </MenuItem>
          ))}
        </Select>
        <Select
          fullWidth
          label="tipo de venda"
          value={filter.typeSale}
          onChange={e => handleChangeFilter('typeSale', e.target.value)}
        >
          <MenuItem value="T">Todas</MenuItem>
          <MenuItem value="C">Coligadas</MenuItem>
          <MenuItem value="V">Vendas</MenuItem>
        </Select>

        <KeyboardDatePicker
          label="Data inicial"
          value={filter.initialDate}
          onChange={date => handleChangeFilter('initialDate', date)}
        />

        <KeyboardDatePicker
          label="Data Final"
          value={filter.finalDate}
          onChange={date => handleChangeFilter('finalDate', date)}
        />

        <Button type="submit" color="primary" variant="contained" onClick={onSearch}>
          <Typography style={{ marginRight: 5 }} color="inherit">
            Pesquisar
          </Typography>
          <Search fontSize="small" />
        </Button>
      </div>
    </DialogInput>
  );
};

export default MonthlyFilterMobile;
