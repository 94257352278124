import React, { MouseEvent } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { Branch } from 'types/siteBranch';
import { useBranches } from 'pages/branches/hooks/useBranches';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type BranchItemTableProps = {
  branch: Branch;
};

const BranchItemTable: React.FC<BranchItemTableProps> = ({ branch }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedBranch } = useBranches();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedBranch(branch);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{branch[item.id as keyof Branch]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default BranchItemTable;
