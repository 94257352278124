import React from 'react';
import TableContent from 'components/table/TableContent';
import TableHeader from 'components/table/TableHeader';
import { priceProtectionTableTemplate } from 'pages/price-protection/priceProtectionTableTemplate';
import { PriceProtection } from 'types/priceProtection';
import { OrderIndexData } from 'hooks/tableOrder';
import { Typography, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableRow from 'components/table/TableRow';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import PriceProtectionItemTable from './PriceProtectionItemTable';

interface PriceProtectionListTableProps {
  datas: PriceProtection[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

const PriceProtectionListTable: React.FC<PriceProtectionListTableProps> = ({ datas, handleSort, orderedIndex }) => {
  const { page, rowsPerPage } = usePagination();

  return (
    <TableContent>
      <TableHeader>
        {priceProtectionTableTemplate.map(item => (
          <HeaderItem key={item.id} onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}>
            <Typography variant="caption" color="primary">
              {item.description}
            </Typography>
            {orderedIndex.index === item.originalId && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </HeaderItem>
        ))}
      </TableHeader>
      <TableBody>
        {datas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => (
          <TableRow key={product.id}>
            <PriceProtectionItemTable data={product} />
          </TableRow>
        ))}
      </TableBody>
    </TableContent>
  );
};

export default PriceProtectionListTable;
