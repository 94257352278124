import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import PaginationProvider from 'hooks/pagination';
import { useApp } from 'hooks/app';
import { useFinancialSummary } from '../../hooks/useFinancialSummary';
import { format } from 'date-fns';
import { Invoice } from 'types/invoices';
import { moneyFormat } from 'helpers/numberFormat';
import InsideLoading from 'components/loading/InsideLoading';
import TableContainer from 'components/table/TableContainer';
import Pagination from 'components/pagination/Pagination';
import useTableOrder from 'hooks/tableOrder';
import { titlesTableTemplate } from '../titles/titlesTableTemplate';
import SummaryListTitles from '../titles/SummaryListTitles';
import { SummaryResult } from 'types/summary/summaryResult';

type SummaryResumesProps = {
  selectedResult: SummaryResult;
};

const Box = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const SummaryResumes: React.FC<SummaryResumesProps> = ({ selectedResult }) => {
  const [orderedIndex, sort] = useTableOrder();
  const { filter } = useFinancialSummary();
  const { financialApi } = useApp();
  const [loading, setLoading] = useState(true);
  const [titles, setTitles] = useState<Invoice[]>([]);
  const [filtered, setFiltered] = useState<Invoice[]>([]);

  useEffect(() => {
    setFiltered(titles);
  }, [titles]);

  useEffect(() => {
    financialApi
      .get('/getTitulos', {
        params: {
          data_ini: filter.initial_date ? format(filter.initial_date, 'dd/MM/yyyy') : '',
          data_fim: filter.final_date ? format(filter.final_date, 'dd/MM/yyyy') : '',
          id_filial: selectedResult.id_filial,
          mes_ano: filter.month_year ? format(filter.month_year, 'MMyy') : '',
        },
      })
      .then(response => {
        setTitles(
          response.data.titulos.map(invoice => ({
            ...invoice,
            boleto: invoice.boleto === 'N' ? 'Não' : 'Sim',
            formattedValue: moneyFormat(invoice.valor),
          })),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [financialApi, filter, selectedResult]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <TableContainer tableTemplate={titlesTableTemplate}>
      <Box>
        {loading ? (
          <InsideLoading />
        ) : (
          <PaginationProvider>
            <SummaryListTitles titles={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
            <Pagination count={filtered.length} />
          </PaginationProvider>
        )}
      </Box>
    </TableContainer>
  );
};

export default SummaryResumes;
