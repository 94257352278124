import { Typography } from '@mui/material';
import React from 'react';
import { Remittance } from 'types/remittance';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
});

interface BillRemittanceDownloadProps {
  remittance: Remittance;
}

const BillRemittanceDownload: React.FC<BillRemittanceDownloadProps> = ({ remittance }) => {
  const classes = styles();

  function getDownloadUrl() {
    const token = localStorage.getItem('h2i@token');

    if (!token) {
      return;
    }

    return `${remittance.url}?token=${token}`;
  }

  return (
    <div className={classes.container}>
      <Typography variant="h6" gutterBottom>
        Seu arquivo está pronto para download
      </Typography>
      <a href={getDownloadUrl()} target="blank">
        Clique para baixar o arquivo
      </a>
    </div>
  );
};

export default BillRemittanceDownload;
