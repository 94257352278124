import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { ProjectCost } from 'types/project';

export type ProjectCostContextValue = {
  selectedProjectCost: ProjectCost | null;
  setSelectedProjectCost: Dispatch<SetStateAction<ProjectCost | null>>;
};

export const ProjectCostContext = createContext<ProjectCostContextValue>({} as ProjectCostContextValue);
export const ProjectCostContextConsumer = ProjectCostContext.Consumer;
export const ProjectCostContextProvider = ProjectCostContext.Provider;

export function useProjectCosts(): ProjectCostContextValue {
  return useContext(ProjectCostContext);
}
