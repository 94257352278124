import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import GroupedUnityItemModule from './GroupedUnityItemModule';
import { GroupedUnity } from '../table/GroupedUnityListTable';

type GroupedUnityListModuleProps = {
  groupedUnits: GroupedUnity[];
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

const GroupedUnityListModule: React.FC<GroupedUnityListModuleProps> = ({ groupedUnits }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {groupedUnits.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(unity => (
        <GroupedUnityItemModule key={unity.unidade} unity={unity} />
      ))}
    </CustomList>
  );
};

export default GroupedUnityListModule;
