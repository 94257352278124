import { TableTemplate } from 'types/tableTemplate';

export const accountsTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'origem',
    description: 'Origem',
    originalId: 'origem',
    width: 250,
  },
  {
    id: 'destino',
    description: 'Destino',
    originalId: 'destino',
    width: 250,
  },
  {
    id: 'usuario',
    description: 'Usuário',
    originalId: 'usuario',
    width: 150,
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'valor',
    width: 120,
  },
  {
    id: 'data',
    description: 'Data',
    originalId: 'formattedDate',
    width: 85,
  },
  {
    id: 'observacao',
    description: 'Observação',
    originalId: 'observacao',
    width: 420,
  },
];
