import React, { useState, useEffect } from 'react';
import TableContainer from 'components/table/TableContainer';
import PaginationProvider from 'hooks/pagination';
import { RentalProvider } from './hooks/useManagedSales';
import { ManagedSaleOnList } from './types/managedSale';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { styled } from '@mui/material';
import RentalsHeaderButtons from './ManagedSalesHeaderButtons';
import RentalsFilterBox from './ManagedSalesFilterBox';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { managedSalesTableTemplate } from './constants/managedSalesTableTemplate';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import ApiPagination from 'components/pagination/ApiPagination';
import RentalListTable from './list/table/ManagedSaleListTable';
import RentalListModule from './list/module/ManagedSaleListModule';
import useTableOrder from 'hooks/tableOrder';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import RentalReport from './report/ManagedSalesReport';
import RentalsMobileFilter from './ManagedSalesMobileFilter';
import { useFetchManagedSales } from 'pages/managed-sales/hooks/useFetchManagedSales';
import ManagedSalesAppbarButtons from './ManagedSalesAppbarButtons';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

export interface RentalQueryParams {
  rows: number;
  page: number;
  initial_date: Date;
  final_date: Date;
  active: '1' | '0';
  is_paid: '1' | '0' | '2';
  term: string;
  financial_data: string;
}

const ManagedSales: React.FC = () => {
  const { rentals, queryParams, setQueryParams, loading, total, fetch } = useFetchManagedSales({
    type: 'managed_sale',
  });
  const [selectedRental, setSelectedRental] = useState<ManagedSaleOnList | null>(null);
  const [mobileFilter, setMobileFilter] = useState(false);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [filtered, setFiltered] = useState<ManagedSaleOnList[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [saving, setSaving] = useState(false);
  const [report, setReport] = useState(false);

  useEffect(() => {
    setFiltered(rentals);
  }, [rentals]);

  function handleQueryParamsChange(index: keyof RentalQueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleStatus() {
    setSaving(true);

    if (!selectedRental) {
      return;
    }

    api
      .patch(`/rentals/${selectedRental.id}/status`)
      .then(() => {
        setFiltered(state =>
          state.map(item => {
            if (selectedRental.id === item.id) {
              item.active = !item.active;
              item.formattedActive = item.active ? 'Sim' : 'Não';
              return item;
            }

            return item;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleSubmit() {
    fetch(queryParams);
  }

  return (
    <RentalProvider value={{ selectedRental, setSelectedRental, handleStatus, setReport }}>
      {saving && <Loading />}

      {report && <RentalReport onExited={() => setReport(false)} />}

      {mobileFilter && (
        <RentalsMobileFilter
          handleQueryParamsChange={handleQueryParamsChange}
          queryParams={queryParams}
          onExited={() => setMobileFilter(false)}
          handleSubmit={handleSubmit}
        />
      )}

      <Appbar
        title="Vendas agenciadas"
        ActionsComponent={<ManagedSalesAppbarButtons setMobileFilter={setMobileFilter} />}
      />

      <PageHeaderActions
        title="Vendas agenciadas"
        description="Gestão das vendas agenciadas"
        ActionComponent={<RentalsHeaderButtons />}
      />

      <RentalsFilterBox
        queryParams={queryParams}
        handleQueryParamsChange={handleQueryParamsChange}
        setDisplayMode={setDisplayMode}
        displayMode={displayMode}
        loading={loading}
        handleSubmit={handleSubmit}
      />

      <TableContainer tableTemplate={managedSalesTableTemplate}>
        <PaginationProvider>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhuma venda agenciada para mostrar" />
          ) : (
            <Container>
              {displayMode === 'list' ? (
                <RentalListTable rentals={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <RentalListModule rentals={filtered} />
              )}
            </Container>
          )}

          <ApiPagination
            onChangePage={value => handleQueryParamsChange('page', value)}
            onChangeRowsPerPage={value => handleQueryParamsChange('rows', value)}
            count={total}
          />
        </PaginationProvider>
      </TableContainer>
    </RentalProvider>
  );
};

export default ManagedSales;
