import React, { MouseEvent } from 'react';
import { Icon, Tab, Tabs, styled } from '@mui/material';
import { FaFileExcel } from 'react-icons/fa';
import { api } from 'services/api';
import { download } from 'helpers/download';
import { uuidv4 } from 'helpers/uuidv4';
import { ActivityMethod, CityMethod, PaymentMethod } from 'types/salesReport';
import { GroupedSale } from './list/order-by/list/table/GroupedSaleListTable';
import { GroupedSegment } from './list/segment/table/GroupedSegmentListTable';
import { GroupedUnity } from './list/unity/table/GroupedUnityListTable';
import { SalesItem } from 'types/reports/sales';
import { usePermissionRules } from 'hooks/permissionRules';

const CustomTabs = styled(Tabs)({
  backgroundColor: 'transparent',
  marginTop: 10,
  '& button': {
    color: '#fff',
    minHeight: 30,
    borderRadius: 32,
    textTransform: 'none',
  },
  '& > div > span': {
    bottom: 6,
    height: 0,
  },
  '& > div > div': {
    gap: 5,
  },
});

const CustomIcon = styled(Icon)({
  display: 'flex',
  alignItems: 'center',
});

interface SalesReportDashboardTabsProps {
  tab: string;
  onChange(tab: string): void;
  disabled: boolean;
  payments: PaymentMethod[];
  sales: GroupedSale[];
  activities: ActivityMethod[];
  cities: CityMethod[];
  items: SalesItem[];
  segments: GroupedSegment[];
  units: GroupedUnity[];
}

const SalesReportDashboardTabs: React.FC<SalesReportDashboardTabsProps> = ({
  onChange,
  tab,
  disabled,
  payments,
  sales,
  activities,
  cities,
  items,
  segments,
  units,
}) => {
  const { checkPermission } = usePermissionRules();

  function handleClick(title: string, event: MouseEvent<HTMLSpanElement>) {
    event.stopPropagation();

    switch (title) {
      case 'payment_methods':
        handleDownload(title, payments);
        break;
      case 'order_by':
        handleDownload(title, sales);
        break;
      case 'activities':
        handleDownload(title, activities);
        break;
      case 'cities':
        handleDownload(title, cities);
        break;
      case 'items':
        handleDownload(title, items);
        break;
      case 'segments':
        handleDownload(title, segments);
        break;
      case 'units':
        handleDownload(title, units);
        break;
    }
  }

  async function handleDownload(title: string, data: any[]) {
    try {
      const response = await api.post('/excel-exports', data, { responseType: 'blob' });

      download(response.data, `${title}-${uuidv4()}`);
    } catch (err) {
      console.error(err);
    }
  }
  return (
    <CustomTabs variant="scrollable" value={tab} onChange={(e, value) => onChange(value)}>
      <Tab
        style={{ backgroundColor: '#30519f' }}
        value="order_by"
        label="Pedido por"
        icon={
          checkPermission('general.excel_export_button') ? (
            <CustomIcon onClick={e => handleClick('order_by', e)}>
              <FaFileExcel style={{ height: 18, width: 18 }} />
            </CustomIcon>
          ) : (
            <></>
          )
        }
        iconPosition="end"
      />
      <Tab
        style={{ backgroundColor: '#30519f' }}
        value="activities"
        label="Atividades"
        icon={
          checkPermission('general.excel_export_button') ? (
            <CustomIcon onClick={e => handleClick('activities', e)}>
              <FaFileExcel style={{ height: 18, width: 18 }} />
            </CustomIcon>
          ) : (
            <></>
          )
        }
        iconPosition="end"
      />
      <Tab
        style={{ backgroundColor: '#ff0000' }}
        value="cities"
        label="Cidades"
        icon={
          checkPermission('general.excel_export_button') ? (
            <CustomIcon onClick={e => handleClick('cities', e)}>
              <FaFileExcel style={{ height: 18, width: 18 }} />
            </CustomIcon>
          ) : (
            <></>
          )
        }
        iconPosition="end"
      />
      <Tab
        style={{ backgroundColor: '#008000' }}
        value="modalities"
        label="Modalidades"
        icon={
          checkPermission('general.excel_export_button') ? (
            <CustomIcon onClick={e => handleClick('modalities', e)}>
              <FaFileExcel style={{ height: 18, width: 18 }} />
            </CustomIcon>
          ) : (
            <></>
          )
        }
        iconPosition="end"
      />
      <Tab
        style={{ backgroundColor: '#008000' }}
        value="payment_methods"
        label="Formas Pag."
        icon={
          checkPermission('general.excel_export_button') ? (
            <CustomIcon onClick={e => handleClick('payment_methods', e)}>
              <FaFileExcel style={{ height: 18, width: 18 }} />
            </CustomIcon>
          ) : (
            <></>
          )
        }
        iconPosition="end"
      />
      <Tab
        style={{ backgroundColor: '#6042b1 ' }}
        value="items"
        label="Itens"
        icon={
          checkPermission('general.excel_export_button') ? (
            <CustomIcon onClick={e => handleClick('items', e)}>
              <FaFileExcel style={{ height: 18, width: 18 }} />
            </CustomIcon>
          ) : (
            <></>
          )
        }
        iconPosition="end"
      />
      <Tab
        style={{ backgroundColor: '#6042b1 ' }}
        value="segments"
        label="Segmentos"
        icon={
          checkPermission('general.excel_export_button') ? (
            <CustomIcon onClick={e => handleClick('segments', e)}>
              <FaFileExcel style={{ height: 18, width: 18 }} />
            </CustomIcon>
          ) : (
            <></>
          )
        }
        iconPosition="end"
      />
      <Tab
        style={{ backgroundColor: '#6042b1' }}
        value="units"
        label="Unidades"
        icon={
          checkPermission('general.excel_export_button') ? (
            <CustomIcon onClick={e => handleClick('units', e)}>
              <FaFileExcel style={{ height: 18, width: 18 }} />
            </CustomIcon>
          ) : (
            <></>
          )
        }
        iconPosition="end"
      />
    </CustomTabs>
  );
};

export default SalesReportDashboardTabs;
