import React, { DragEvent, HTMLAttributes, useState } from 'react';
import { Zoom, Typography, Button, CircularProgress, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageSelector from 'components/image-selector/ImageSelector';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import { Image } from 'types/image';

const ImageContainer = styled('div')<{ error: boolean; dragIn: boolean }>(props => ({
  display: 'flex',
  width: '100%',
  height: 350,
  borderRadius: 4,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
  flexDirection: 'column',
  border: props.dragIn
    ? `3px dashed ${props.theme.palette.primary.main}`
    : `2px dashed ${props.error ? props.theme.palette.error.main : alpha(props.theme.palette.primary.main, 0.2)}`,
  opacity: props.dragIn ? 0.5 : 1,
}));

const LinkNewImage = styled('span')(props => ({
  color: props.theme.palette.primary.main,
  cursor: 'pointer',
  display: 'inline-flex',
  marginBottom: 15,
  textAlign: 'center',
  padding: 10,
  borderBottom: '1px solid #eee',
}));

const HelperText = styled(Typography)({
  position: 'absolute',
  bottom: 10,
});

const ImageWrapper = styled('div')(props => ({
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  display: 'flex',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: alpha(props.theme.palette.primary.main, 0.2),
  '& > svg': {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}));

const CustomImg = styled('img')({
  width: '100%',
  borderRadius: 4,
  maxWidth: 500,
  maxHeight: 500,
  objectFit: 'cover',
});

const CustomCheckCircleIcon = styled(CheckCircleIcon)({
  backgroundColor: '#fff',
  borderRadius: '50%',
});

interface ImageUploadProps extends HTMLAttributes<HTMLDivElement> {
  image: Image | null;
  validationError?: string;
  label: string;
  handleRemoveImage(): void;
  handleSetImage(image: Image | null): void;
  containerClassname?: React.CSSProperties;
  disabled?: boolean;
  hideImageSelectorBackdrop?: boolean;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  image,
  handleRemoveImage,
  handleSetImage,
  validationError,
  label,
  className,
  containerClassname,
  disabled = false,
  hideImageSelectorBackdrop = false,
  ...rest
}) => {
  const [imageSelector, setImageSelector] = useState(false);
  const [selected, setSelected] = useState(false);
  const [dragIn, setDragIn] = useState(false);
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();

  function handleUploadFiles(files: FileList | null) {
    if (!files) return;

    const form = new FormData();
    Array.from(files).forEach((file, index) => {
      form.append(`files[${index}]`, file);
    });

    form.append('is_public', '1');

    setSaving(true);

    api
      .post('/images', form)
      .then(response => {
        handleSetImage(response.data[0]);
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível carregar a imagem');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDropFile(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleUploadFiles(files);
    setDragIn(false);
  }

  function handleDragEnter(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  function handleDragLeave(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(false);
  }

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  return (
    <div style={{ width: '100%' }} className={className || ''}>
      {imageSelector && (
        <ImageSelector
          hideBackdrop={hideImageSelectorBackdrop}
          onExited={() => setImageSelector(false)}
          handleSetImageId={image => handleSetImage(image)}
        />
      )}
      {!image ? (
        <ImageContainer
          {...rest}
          error={!!validationError}
          dragIn={dragIn}
          style={containerClassname}
          onDrop={e => handleDropFile(e)}
          onDragLeave={e => handleDragLeave(e)}
          onDragEnter={e => handleDragEnter(e)}
          onDragOver={e => handleDragOver(e)}
          draggable
        >
          {saving ? (
            <CircularProgress />
          ) : (
            <>
              <input
                type="file"
                style={{ display: 'none' }}
                id="input-file"
                onChange={event => handleUploadFiles(event.target.files)}
                accept="image/*"
              />
              <label htmlFor="input-file">
                <LinkNewImage>Escolher uma nova imagem</LinkNewImage>
              </label>

              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => setImageSelector(true)}
                disabled={disabled}
              >
                escolher imagem do repositório
              </Button>

              <HelperText variant="caption" color="textSecondary" align="center">
                Você também pode arrastar uma imagem aqui
              </HelperText>
            </>
          )}
        </ImageContainer>
      ) : (
        <ImageContainer
          error={false}
          dragIn={false}
          style={containerClassname}
          onClick={() => setSelected(selected => !selected)}
        >
          <Zoom in={selected}>
            <ImageWrapper>
              <CustomCheckCircleIcon color="primary" />
              <Button size="small" variant="contained" color="primary" onClick={handleRemoveImage}>
                Remover
              </Button>
            </ImageWrapper>
          </Zoom>
          <CustomImg alt="Foto do produto" src={image.imageUrl} />
        </ImageContainer>
      )}
      {validationError ? (
        <Typography variant="caption" color="error">
          {validationError}
        </Typography>
      ) : (
        <Typography variant="caption" display="block">
          {label}
        </Typography>
      )}
    </div>
  );
};

export default ImageUpload;
