import React from 'react';
import { Typography } from '@mui/material';
import CreditLimitRequestItemTable from './CreditLimitRequestItemTable';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { useCreditLimitRequests } from 'pages/credit-limit-requests/hooks/useCreditLimitRequests';
import { CreditLimitRequest } from 'types/creditLimitRequest';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type CreditLimitRequestListTableProps = {
  creditLimitRequests: CreditLimitRequest[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const CreditLimitRequestListTable: React.FC<CreditLimitRequestListTableProps> = ({
  creditLimitRequests,
  handleSort,
  orderedIndex,
}) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();
  const { setSelectedCreditLimitRequest } = useCreditLimitRequests();

  function handleClick(creditLimitRequests: CreditLimitRequest) {
    setSelectedCreditLimitRequest(creditLimitRequests);
  }

  return (
    <TableContent>
      <TableHeader>
        {tableTemplate.map(item => (
          <div
            className={classes.headerItem}
            key={item.id}
            onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
          >
            <Typography variant="caption" color="primary">
              {item.description}
            </Typography>
            {orderedIndex.index === item.originalId && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </div>
        ))}
      </TableHeader>
      <TableBody>
        {creditLimitRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(creditLimitRequest => (
          <TableRow key={creditLimitRequest.id} onClick={() => handleClick(creditLimitRequest)}>
            <CreditLimitRequestItemTable creditLimitRequest={creditLimitRequest} />
          </TableRow>
        ))}
      </TableBody>
    </TableContent>
  );
};

export default CreditLimitRequestListTable;
