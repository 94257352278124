import React, { useMemo } from 'react';
import { externalTooltipHandler } from 'components/chart/externalTooltip';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { CityMethod, SalesMethod } from 'types/salesReport';
import { Bar } from 'react-chartjs-2';

interface CityChartProps {
  methods: CityMethod[];
  salesMethods: SalesMethod[];
}

const CityChart: React.FC<CityChartProps> = ({ methods, salesMethods }) => {
  const pieData = useMemo(() => {
    return methods
      .sort((a, b) => b.valor - a.valor)
      .map(item => item.valor)
      .slice(0, 7);
  }, [methods]);

  return (
    <div style={{ height: 560, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
      <Bar
        data={{
          labels: methods
            .sort((a, b) => b.valor - a.valor)
            .map(branch => [branch.cidade])
            .slice(0, 7),
          datasets: [
            {
              label: 'Total',
              data: methods
                .sort((a, b) => b.valor - a.valor)
                .map(branch => branch.valor)
                .slice(0, 7),
              borderWidth: 1,
            },
          ],
        }}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
              callbacks: {
                label: tooltip => moneyFormat(pieData[tooltip.dataIndex as number]),
              },
              external: externalTooltipHandler,
            },
            legend: {
              display: true,
              position: 'right',
            },
            colors: {
              enabled: true,
            },
            title: {
              text: 'Cidades mais atendidas',
              display: true,
              position: 'top',
              align: 'center',
            },
            datalabels: {
              display: true,
              color: '#fff',
              font: {
                size: 9,
                weight: 600,
              },
              textAlign: 'left',
              formatter(value, context) {
                return percentFormat((value / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100);
              },
            },
          },
          responsive: true,
        }}
      />
    </div>
  );
};

export default CityChart;
