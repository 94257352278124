import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Done } from '@mui/icons-material';
import { usePermissionRules } from 'hooks/permissionRules';

interface CustomerActionsProps {
  handleValidation(): void;
}

const CustomerActions: React.FC<CustomerActionsProps> = ({ handleValidation }) => {
  const { checkPermission } = usePermissionRules();
  return (
    <>
      {checkPermission('registrations.customers.save') && (
        <Tooltip title="Salvar favorecido">
          <IconButton onClick={handleValidation} color="inherit">
            <Done />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default CustomerActions;
