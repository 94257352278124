import React from 'react';
import { List } from '@mui/material';
import { usePagination } from 'hooks/pagination';

import BranchItemModule from './BranchItemModule';
import { MonthlyBranch } from 'types/monthlyComparison';

import { makeStyles } from '@mui/styles';

type BranchListModuleProps = {
  branches: MonthlyBranch[];
};

const styles = makeStyles({
  list: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: 100,
    flex: 1,
  },
});

const BranchListModule: React.FC<BranchListModuleProps> = ({ branches }) => {
  const { rowsPerPage, page } = usePagination();
  const classes = styles();

  return (
    <List className={classes.list}>
      {branches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(branch => (
        <BranchItemModule key={branch.id} branch={branch} />
      ))}
    </List>
  );
};

export default BranchListModule;
