import React from 'react';
import { Typography } from '@mui/material';
import CreditLimitRequestItemTable from './CreditAnalysisQuestionItemTable';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { CreditAnalysisQuestion } from 'types/creditAnalysisQuestion';
import history from 'services/history';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type CreditAnalysisQuestionListTableProps = {
  questions: CreditAnalysisQuestion[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const CreditAnalysisQuestionListTable: React.FC<CreditAnalysisQuestionListTableProps> = ({
  questions,
  handleSort,
  orderedIndex,
}) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();

  function handleClick(creditAnalysisQuestion: CreditAnalysisQuestion) {
    history.push(`/credit-analysis-question/${creditAnalysisQuestion.id}`);
  }

  return (
    <>
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <div
              className={classes.headerItem}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {questions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(question => (
            <TableRow key={question.id} onClick={() => handleClick(question)}>
              <CreditLimitRequestItemTable question={question} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default CreditAnalysisQuestionListTable;
