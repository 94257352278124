import { createContext, useContext } from 'react';
import { FinancialCardFilterParams } from '../FinancialCardReport';

type FinancialCardContextValue = {
  handleChangeFilter(index: keyof FinancialCardFilterParams, value: any): void;
  filter: FinancialCardFilterParams;
};

const FinancialCardContext = createContext<FinancialCardContextValue>({} as FinancialCardContextValue);
export const FinancialCardProvider = FinancialCardContext.Provider;

export function useFinancialCard(): FinancialCardContextValue {
  const context = useContext(FinancialCardContext);
  return context;
}
