import { Dispatch, SetStateAction, useState } from 'react';
import { RegisterForm } from 'types/registerForm';
import * as yup from 'yup';

export interface RegisterFormPartnerDocumentValidation {
  partner_document_copy?: string;
}

type UseRegisterFormPartnerDocumentValidation = [
  RegisterFormPartnerDocumentValidation,
  Dispatch<SetStateAction<RegisterFormPartnerDocumentValidation>>,
  (register: RegisterForm) => Promise<void>,
];

export function useRegisterFormPartnerDocumentValidation(): UseRegisterFormPartnerDocumentValidation {
  async function handleValidation(register: RegisterForm) {
    const schema = yup.object().shape({
      partner_document_copy: yup
        .object()
        .typeError('Um documento com foto é obrigatório')
        .required('Um documento com foto é obrigatório'),
    });

    try {
      await schema.validate(register);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<RegisterFormPartnerDocumentValidation>({});
  return [validation, setValidation, handleValidation];
}
