import React, { FormEvent, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import * as yup from 'yup';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import FiscalCodeForm from '../FiscalCodeForm';
import PageHeader from 'components/page-header/PageHeader';
import FiscalCodeUpdateActions from './FiscalCodeUpdateActions';
import { useFiscalCodeValidation } from '../validation/fiscalCodeValidation';
import { Cfop } from 'types/cfop';

const FiscalCodeUpdate: React.FC = () => {
  const [fiscalCode, setFiscalCode] = useState<Cfop>({
    id: 0,
    code: '',
    name: '',
    type: 'out',
    has_icms: true,
    has_stock: true,
    has_pis_cofins: true,
    cst_pis: '',
    cst_cofins: '',
    must_reference_invoice: true,
  });
  const [validation, setValidation, validate] = useFiscalCodeValidation();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!id) return;

    async function loadFiscalCode() {
      const response = await api.get(`/cfop/${id}`);
      setFiscalCode(response.data);
    }

    Promise.all([loadFiscalCode()])
      .catch(() => {
        handleOpen('Não foi possível carregar o CFOP');
        history.push('/cfop');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleOpen, id]);

  function handleChange(index: keyof Cfop, value: any) {
    setFiscalCode(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    validate(fiscalCode)
      .then(() => {
        handleSubmit();
        setValidation({});
      })
      .catch((err: yup.ValidationError) => {
        if (err instanceof yup.ValidationError) {
          setValidation({
            [err.path as string]: err.message,
          });
        }
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/cfop/${id}`, fiscalCode)
      .then(() => {
        handleOpen('Salvo');
        setLoading(false);
        history.push('/cfop');
      })
      .catch(err => {
        setSaving(false);
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível salvar o CFOP');
      });
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete(`/cfop/${id}`)
      .then(() => {
        setLoading(false);
        history.push('/cfop');
        handleOpen('CFOP excluído');
      })
      .catch(err => {
        setSaving(false);
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível excluir o CFOP');
      });
  }

  return (
    <>
      <Appbar
        backAction={() => history.push('/cfop')}
        title="CFOP"
        ActionsComponent={<FiscalCodeUpdateActions handleSubmit={handleValidation} handleDelete={handleDelete} />}
      />
      {saving && <Loading />}
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container direction="column">
          <PageHeader backUrl="/cfop" title="Editar CFOP" />
          <Grid item xs={12} lg={4} xl={4} md={6}>
            <form onSubmit={handleValidation}>
              <FiscalCodeForm handleChange={handleChange} fiscalCode={fiscalCode} validation={validation} />
              <button type="submit" style={{ display: 'none' }} />
            </form>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default FiscalCodeUpdate;
