import React, { useState } from 'react';
import Tabs, { OrderTabs } from './Tabs';
import Dialog from 'components/dialogs/Dialog';
import { useOrdersAuthorization } from '../hooks/useOrdersAuthorization';
import SelectedOrderItems from './SelectedOrderItems';
import SelectedOrderPayment from './SelectedOrderPayment';

const SelectedOrderAuthorization: React.FC = () => {
  const [tab, setTab] = useState<OrderTabs>('items');
  const { selectedOrder, setDialog } = useOrdersAuthorization();

  return (
    <Dialog
      onExited={() => setDialog(false)}
      maxWidth="sm"
      height="80vh"
      title={`Faturamento de ${selectedOrder?.Cliente}`}
    >
      <Tabs tab={tab} handleChange={tab => setTab(tab)} />

      <div style={{ marginTop: 20 }}>{tab === 'items' ? <SelectedOrderItems /> : <SelectedOrderPayment />}</div>
    </Dialog>
  );
};

export default SelectedOrderAuthorization;
