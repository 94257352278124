import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { useApp } from 'hooks/app';
import { usePermissionRules } from 'hooks/permissionRules';

export type ProductTabValues = 'product' | 'prices' | 'technical' | 'warranty';

type ProductTabProps = {
  handleChange(value: ProductTabValues): void;
  tab: ProductTabValues;
};

const ProductTab: React.FC<ProductTabProps> = ({ tab, handleChange }) => {
  const app = useApp();
  const { checkPermission } = usePermissionRules();

  return (
    <Tabs value={tab} onChange={(e, value) => handleChange(value)} variant={app.isMobile ? 'scrollable' : 'standard'}>
      {checkPermission('registrations.products.main') && <Tab label="Produto" value="product" />}
      {checkPermission('registrations_product_price') && <Tab label="Preços" value="prices" />}
      {checkPermission('registrations.products.technical_specifications') && <Tab label="Inf. Téc" value="technical" />}
      {checkPermission('registrations.products.warranty') && <Tab label="Garantia" value="warranty" />}
      {checkPermission('registrations.products.technical_specifications') && <Tab label="Imagens" value="images" />}
    </Tabs>
  );
};

export default ProductTab;
