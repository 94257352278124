import React from 'react';
import SidebarItem from '../SidebarItem';
import { Collapse } from '@mui/material';
import { useSidebar } from '../hook/useSidebar';
import { usePermissionRules } from 'hooks/permissionRules';
import SidebarItemExpendable from '../SidebarItemExpendable';
import { Diversity1, Groups, PsychologyAlt } from '@mui/icons-material';

const Rh: React.FC = () => {
  const { checkPermission } = usePermissionRules();
  const { handleCollapseClick, collapsible } = useSidebar();

  return (
    <>
      {checkPermission('rh') && (
        <>
          <SidebarItemExpendable
            onClick={() => handleCollapseClick('rh')}
            icon={<Diversity1 />}
            text="RH"
            opened={collapsible.rh}
          />
          <Collapse in={collapsible.rh}>
            <SidebarItem to="/rh/justification" icon={<PsychologyAlt />} text="Justificativa" nested={5} />

            <SidebarItem to="/rh/requirements" icon={<Groups />} text="Solicitações" nested={5} />
          </Collapse>
        </>
      )}
    </>
  );
};

export default Rh;
