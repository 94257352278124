import React from 'react';
import { useAppropriationDate } from 'pages/appropiation-date/hooks/useApproprationDate';
import { AppropriationDate } from 'types/appropriationDate';
import { ListItemButton, Typography, styled } from '@mui/material';

interface AppropriationDateItemModuleProps {
  date: AppropriationDate;
}

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '80%',
    alignItems: 'center',
  },
});

const AppropriationDateItemModule: React.FC<AppropriationDateItemModuleProps> = ({ date }) => {
  const { setSelectedDate } = useAppropriationDate();

  return (
    <CustomListItemButton onClick={() => setSelectedDate(date)}>
      <Typography variant="caption" color="primary">
        Data {date.data}
      </Typography>
      <Typography>Inativo: {date.inativo}</Typography>
    </CustomListItemButton>
  );
};

export default AppropriationDateItemModule;
