import { Button } from '@mui/material';
import React from 'react';

interface BuyersActionProps {
  onClick(): void;
}

const BuyersAction: React.FC<BuyersActionProps> = ({ onClick }) => {
  return (
    <Button variant="contained" size="medium" color="primary" onClick={onClick}>
      Adicionar
    </Button>
  );
};

export default BuyersAction;
