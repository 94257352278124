import React, { FormEvent, useEffect, useState, useCallback } from 'react';
import { Typography, Button, Theme } from '@mui/material';
import { useRegisterReducer } from './reducer/reducer';
import { useRegisterCompanyValidation } from './validation/registerCompanyValidation';
import { useRegisterAddressValidation } from './validation/registerAddressValidation';
import { RegisterForm as RegisterFormType } from 'types/registerForm';
import {
  addressChange,
  change,
  partnerAddressChange,
  registerSetAddress,
  registerSetPartnerAddress,
} from './reducer/action';
import RegisterForm from './RegisterForm';
import { Address } from 'types/address';
import RegisterFormAdress from './RegisterFormAddress';
import RegisterSuccess from './RegisterSuccess';
import RegisterFormUpload from './RegisterFormUploads';
import { useRegisterUploadsValidation } from './validation/registerUploadsValidation';
import RegisterPartner from './RegisterFormPartner';
import { useRegisterPartnerValidation } from './validation/registerPartnerValidation';
import RegisterFormProofResidence from './RegisterFormProofResidence';
import { useRegisterProofResidenceValidation } from './validation/registerProofResidenceValidation';
import RegisterFormPartnerDocument from './RegisterFormPartnerDocument';
import { useRegisterFormPartnerDocumentValidation } from './validation/registerFormPartnerDocumentValidation';
import RegisterFormPartnerAddress from './RegisterFormPartnerAddress';
import RegisterFormSelfie from './RegisterFormSelfie';
import { useRegisterSelfieValidation } from './validation/registerSelfieValidation';
import RegisterError from './RegisterError';
import { postalCodeSearch } from 'services/postalCodeSearch';
import { useApp } from 'hooks/app';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import Dialog from 'components/dialogs/Dialog';
import { useResellers } from '../hooks/useResellers';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {},
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
    maxWidth: 400,
    width: '100%',
    justifyContent: 'center',
  },
  form: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      marginBottom: 30,
    },
    '& a': {
      alignSelf: 'center',
    },
  },
  header: {
    margin: '15px 0',
  },
  subtitle: {
    fontWeight: 400,
  },
}));

type RegisterProps = {
  onExited(): void;
};

let timer: NodeJS.Timeout;
let companySearchTimer: NodeJS.Timeout;

const Register: React.FC<RegisterProps> = ({ onExited }) => {
  const classes = useStyles();
  const [register, dispatch] = useRegisterReducer();
  const [companyValidation, setCompanyValidation, validateCompany] = useRegisterCompanyValidation();
  const [addressValidation, setAddressValidation, validateAddress] = useRegisterAddressValidation();
  const [uploadsValidation, setUploadsValidation, validateUploads] = useRegisterUploadsValidation();
  const [partnerValidation, setPartnerValidation, validatePartner] = useRegisterPartnerValidation();
  const [partnerSelfieValidation, setPartnerSelfieValidation, validatePartnerSelfie] = useRegisterSelfieValidation();
  const [partnerDocumentValidation, setPartnerDocumentValidation, validatePartnerDocument] =
    useRegisterFormPartnerDocumentValidation();
  const [partnerAddressValidation, setPartnerAddressValidation, validatePartnerAddress] =
    useRegisterAddressValidation();
  const [proofResidenceValidation, setProofResidenceValidation, validateProofResidence] =
    useRegisterProofResidenceValidation();
  const [step, setStep] = useState(1);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const { h2iApi } = useApp();
  const messaging = useMessaging();
  const { handleSearch, filter } = useResellers();

  useEffect(() => dispatch(change('type', 'store')), [dispatch]);

  useEffect(() => {
    const documentRaw = register.document.replace(/\D/g, '');

    if (!documentRaw) return;

    if (documentRaw.length !== 11 && documentRaw.length !== 14) return;

    clearTimeout(timer);

    timer = setTimeout(() => {
      h2iApi?.get('/api/cliente', { params: { cnpj: documentRaw, pw: '' } }).then(response => {
        const user = response.data[0];
        if (user.aprovado)
          setCompanyValidation({
            document: 'Esse documento já está registrado',
          });
        else setCompanyValidation({});
      });
    }, 500);
  }, [register.document, setCompanyValidation, h2iApi]);

  const handleSetAddress = useCallback(
    (address: Address) => {
      dispatch(registerSetAddress(address));
    },
    [dispatch],
  );

  useEffect(() => {
    const documentRaw = register.document.replace(/\D/g, '');

    if (!documentRaw) return;

    if (documentRaw.length !== 14) return;

    clearTimeout(companySearchTimer);

    companySearchTimer = setTimeout(() => {
      api
        .get(`/company/${documentRaw}`)
        .then(response => {
          const company = response.data;
          dispatch(change('company_name', company.nome));
          dispatch(change('company_trade_name', company.fantasia));
          dispatch(change('phone', company.telefone));
          dispatch(addressChange('postal_code', company.cep));

          if (!company.cep) return;
          postalCodeSearch(company.cep.replace(/\D/g, ''))
            .then(response => {
              const { data } = response;
              handleSetAddress({
                postal_code: data.cep.replace(/\D/g, ''),
                street: data.logradouro,
                street_number: '',
                complement: data.complemento,
                state: data.uf,
                city: data.localidade,
                neighborhood: data.bairro,
              });
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    }, 500);
  }, [register.document, dispatch, handleSetAddress]);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    const validations = {
      1: handleValidationStepOne,
      2: handleValidationStepTwo,
      3: handleValidationStepThree,
      4: handleValidationStepFour,
      5: handleValidationStepFive,
      6: handleValidationStepSix,
      7: handleValidationStepSeven,
      8: handleValidationStepEight,
    };

    validations[step as keyof typeof validations]();
  }

  function handleValidationStepOne() {
    setCompanyValidation({});

    validateCompany(register)
      .then(() => setStep(2))
      .catch(err => console.error(err.message));
  }

  function handleValidationStepTwo() {
    setUploadsValidation({});

    validateUploads(register)
      .then(() => setStep(3))
      .catch(err => console.error(err));
  }

  function handleValidationStepThree() {
    setAddressValidation({});

    validateAddress(register.address)
      .then(() => setStep(4))
      .catch(err => console.error(err.message));
  }

  function handleValidationStepFour() {
    setPartnerValidation({});

    validatePartner(register)
      .then(() => setStep(5))
      .catch(err => console.error(err));
  }

  function handleValidationStepFive() {
    setPartnerAddressValidation({});

    validatePartnerAddress(register.partner_address)
      .then(() => setStep(6))
      .catch(err => console.error(err));
  }

  function handleValidationStepSix() {
    setPartnerDocumentValidation({});

    validatePartnerDocument(register)
      .then(() => setStep(7))
      .catch(err => console.error(err));
  }

  function handleValidationStepSeven() {
    setProofResidenceValidation({});

    validateProofResidence(register)
      .then(() => setStep(8))
      .catch(err => console.error(err));
  }

  function handleValidationStepEight() {
    setPartnerSelfieValidation({});

    validatePartnerSelfie(register)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/resellers', register)
      .then(() => {
        setRegisterSuccess(true);
        handleSearch(filter);
      })
      .catch(err => {
        messaging.handleOpen(err.response ? err.response.data.error : 'Acontece um erro ao processar o registro');
        setError(err.response ? err.response.data.error : 'Acontece um erro ao processar o registro');
        console.error(err);
      })
      .finally(() => setSaving(false));
  }

  function handleChange(index: keyof RegisterFormType, value: any) {
    dispatch(change(index, value));
  }

  function handleAddressChange(index: keyof Address, value: any) {
    dispatch(addressChange(index, value));
  }

  function handlePartnerAddressChange(index: keyof Address, value: any) {
    dispatch(partnerAddressChange(index, value));
  }

  function handleSetPartnerAddress(address: Address) {
    dispatch(registerSetPartnerAddress(address));
  }

  function handleRender() {
    const componentSteps = {
      1: (
        <RegisterForm
          validation={companyValidation}
          register={register}
          handleChange={handleChange}
          handleSetAddress={handleSetAddress}
          handleAddressChange={handleAddressChange}
        />
      ),
      2: <RegisterFormUpload validation={uploadsValidation} register={register} handleChange={handleChange} />,
      3: (
        <RegisterFormAdress
          validation={addressValidation}
          register={register}
          handleSetAddress={handleSetAddress}
          handleChange={handleAddressChange}
        />
      ),
      4: <RegisterPartner validation={partnerValidation} register={register} handleChange={handleChange} />,
      5: (
        <RegisterFormPartnerAddress
          validation={partnerAddressValidation}
          register={register}
          handleChange={handlePartnerAddressChange}
          handleSetPartnerAddress={handleSetPartnerAddress}
        />
      ),
      6: (
        <RegisterFormPartnerDocument
          validation={partnerDocumentValidation}
          register={register}
          handleChange={handleChange}
        />
      ),
      7: (
        <RegisterFormProofResidence
          validation={proofResidenceValidation}
          register={register}
          handleChange={handleChange}
        />
      ),
      8: <RegisterFormSelfie validation={partnerSelfieValidation} register={register} handleChange={handleChange} />,
    };

    return componentSteps[step as keyof typeof componentSteps];
  }

  function handleGetButtonText() {
    const texts = {
      1: 'continuar',
      2: 'continuar',
      3: 'continuar',
      4: 'continuar',
      5: 'continuar',
      6: 'continuar',
      7: 'continuar',
      8: 'salvar',
    };

    return texts[step as keyof typeof texts];
  }

  function handleGetSubtitles() {
    const titles = {
      1: 'Identificação da empresa',
      2: 'Precisamos do contrato social da empresa (última alteração consolidada). Caso seja MEI, fazer o upload do requerimento de MEI.',
      3: 'Favor informar o endereço da empresa',
      4: 'Precisamos de alguns dados do sócio principal da empresa',
      5: 'Favor informar o endereço do sócio',
      6: 'Precisamos de um documento com foto do sócio',
      7: 'Precisamos do comprovante de residência do sócio',
      8: 'Precisamos de uma selfie do sócio com o documento enviado',
    };

    return titles[step as keyof typeof titles];
  }

  function handleBack() {
    if (step === 1) return;

    setStep(step => step - 1);
  }

  return (
    <Dialog onExited={onExited} title="Novo revendedor" maxWidth="sm">
      <div className={classes.container}>
        {registerSuccess ? (
          <RegisterSuccess />
        ) : error ? (
          <RegisterError errorMessage={error} handleRestart={() => setError('')} />
        ) : (
          <div className={classes.content}>
            <Typography variant="h6" gutterBottom align="center">
              cadastro de revenda
            </Typography>
            <div className={classes.header}>
              <Typography align="center" className={classes.subtitle}>
                {handleGetSubtitles()}
              </Typography>
              <Typography
                align="center"
                gutterBottom
                color="textSecondary"
                variant="body2"
              >{`passo ${step} / 8`}</Typography>
            </div>
            <form className={classes.form} onSubmit={handleValidation} noValidate>
              {handleRender()}
              <div className={classes.actions}>
                <Button variant="contained" color="primary" disabled={saving} type="submit">
                  {handleGetButtonText()}
                </Button>
                {step === 1 ? (
                  <Button variant="text" color="primary" href="/login">
                    voltar
                  </Button>
                ) : (
                  <Button variant="text" type="button" onClick={handleBack}>
                    voltar
                  </Button>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default Register;
