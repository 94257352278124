import { useInputDialog } from 'components/dialogs/DialogInput';
import React from 'react';

interface ImportInvoiceXMLInputProps {
  handleUploadFiles(files: FileList | null): Promise<void>;
}

const ImportInvoiceXMLInput: React.FC<ImportInvoiceXMLInputProps> = ({ handleUploadFiles }) => {
  const { handleClose } = useInputDialog();

  function handleChange(files: FileList | null) {
    handleUploadFiles(files).then(handleClose);
  }

  return (
    <input
      type="file"
      name="photo"
      id="xml-input"
      onChange={event => handleChange(event.target.files)}
      style={{ display: 'none' }}
      accept=".xml"
    />
  );
};

export default ImportInvoiceXMLInput;
