import { TableTemplate } from 'types/tableTemplate';

export const categoryTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'Ações',
    originalId: 'actions',
    width: 70,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 70,
  },
  {
    id: 'description',
    description: 'Descrição',
    originalId: 'description',
    width: 400,
  },
  {
    id: 'formattedCreatedAt',
    description: 'Data de criação',
    originalId: 'formattedCreatedAt',
    width: 150,
  },
  {
    id: 'formattedUpdatedAt',
    description: 'Data de atualização',
    originalId: 'formattedUpdatedAt',
    width: 150,
  },
];
