import React from 'react';
import { usePurchaseOrder } from '../../hook/usePurchaseOrder';
import SelectBranch from 'components/select-branches/SelectBranch';

const PurchaseOrderFirstStep: React.FC = () => {
  const { order, handleChange, validation, inputs } = usePurchaseOrder();

  return (
    <SelectBranch
      label="Informe a filial"
      inputRef={inputs.id_filial}
      error={!!validation.id_filial}
      helperText={validation.id_filial}
      viewAllBranches={false}
      branch_id={order.id_filial}
      handleChange={e => handleChange('id_filial', e)}
    />
  );
};

export default PurchaseOrderFirstStep;
