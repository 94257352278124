import React from 'react';
import { Typography, styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useInvoices } from '../hooks/useInvoices';

const Footer = styled('div')({
  display: 'grid',
  gap: 10,
});

const Content = styled('div')({
  display: 'grid',
  maxWidth: 400,
});

const Flex = styled('div')({
  gap: 5,
  display: 'flex',
  padding: 5,
  justifyContent: 'space-between',
  alignItems: 'center',
  '&.total': {
    backgroundColor: '#ddd',
  },
});

const CustomTypography = styled(Typography)({
  fontSize: '9pt!important',
});

const Title = styled('h6')({
  textAlign: 'center',
  marginTop: 10,
});

const InvoicesReportFooter: React.FC = () => {
  const { filter, invoicesTotal } = useInvoices();

  const total = invoicesTotal.expense + invoicesTotal.revenue + invoicesTotal.personnel + invoicesTotal.invoice;

  return (
    <Footer>
      <Title>
        Resumo de {`${format(filter.initial_date, 'P', { locale: ptBR })}`} até{' '}
        {`${format(filter.final_date, 'P', { locale: ptBR })}`}
      </Title>

      <Content>
        <Flex className="total">
          <CustomTypography variant="caption">Total</CustomTypography>
          <CustomTypography fontWeight={600} variant="caption">
            {moneyFormat(total)}
          </CustomTypography>
        </Flex>
      </Content>
    </Footer>
  );
};

export default InvoicesReportFooter;
