import { Add, FilterList } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import history from 'services/history';

interface RentalsAppbarButtonsProps {
  setMobileFilter: Dispatch<SetStateAction<boolean>>;
}

const RentalsAppbarButtons: React.FC<RentalsAppbarButtonsProps> = ({ setMobileFilter }) => {
  function handleAddClick() {
    history.push('/rentals/new');
  }

  function handleFilterClick() {
    setMobileFilter(true);
  }

  return (
    <>
      <IconButton onClick={handleAddClick} color="inherit">
        <Add />
      </IconButton>
      <IconButton onClick={handleFilterClick} color="inherit">
        <FilterList />
      </IconButton>
    </>
  );
};

export default RentalsAppbarButtons;
