import React, { useState, useEffect, useMemo, useRef } from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import { Segment } from 'types/segment';
import { useApp } from 'hooks/app';
import { MenuItem, TextField, Button, Typography, styled } from '@mui/material';
import { useDialog } from 'components/dialogs/Dialog';
import { useSelector } from 'store/selector';
import { Seller } from 'types/seller';
import { useResellerApprovalValidation } from './useResellerApprovalValidation';
import { useReseller } from '../hooks/useReseller';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 400,
  width: '100%',
  justifyContent: 'space-between',
});

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
});

const Label = styled(Typography)({
  margin: '0 30px 20px',
  textAlign: 'center',
});

interface ResellerApprovalSegmentProps {
  onExited(): void;
  handleApprove(segmentId: number, handleCloseDialog: () => void, seller?: Seller): void;
}

const ResellerApprovalSegment: React.FC<ResellerApprovalSegmentProps> = ({ onExited, handleApprove }) => {
  const { reseller } = useReseller();
  const [segments, setSegments] = useState<Segment[]>([]);
  const [segmentId, setSegmentId] = useState<null | number>(null);
  const { h2iApi } = useApp();
  const [error, setError] = useState('');
  const dialog = useDialog();
  const branches = useSelector(state => state.branches);
  const [branchId, setBranchId] = useState<number | null>(null);
  const [loadingSellers, setLoadingSellers] = useState(false);
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [sellerId, setSellerId] = useState<number | null>(null);
  const [validation, setValidation, validate] = useResellerApprovalValidation();
  const inputs = {
    segmentId: useRef<HTMLSelectElement>(null),
    seller: useRef<HTMLSelectElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  const seller = useMemo(() => {
    return sellers.find(seller => seller.codigo === sellerId);
  }, [sellerId, sellers]);

  useEffect(() => {
    if (!h2iApi) return;

    if (!branchId) return;

    setLoadingSellers(true);

    h2iApi
      .get('/api/whatsappvendedor', { params: { id_filial: branchId } })
      .then(response => {
        setSellers(response.data.RESULT[0]);
      })
      .catch(err => console.error(err))
      .finally(() => setLoadingSellers(false));
  }, [branchId, h2iApi]);

  useEffect(() => {
    if (!branches.length) return;

    setBranchId(branches[0].id);
  }, [branches]);

  useEffect(() => {
    h2iApi
      ?.get('/api/getatividade')
      .then(response => {
        const _segments: Segment[] = response.data.atividade;

        if (reseller?.document.length === 14)
          setSegments(_segments.filter(segment => segment.tipo_pessoa.match('J|A')));
        else setSegments(_segments.filter(segment => segment.tipo_pessoa.match('F|A')));
      })
      .catch(err => console.error(err));
  }, [h2iApi, reseller]);

  function handleValidation(handleCloseDialog: () => void) {
    setValidation({});

    validate({ segmentId, seller }, reseller?.type)
      .then(() => handleConfirm(handleCloseDialog))
      .catch(err => console.error(err));
  }

  function handleConfirm(handleCloseDialog: () => void) {
    if (!segmentId) {
      setError('Você precisa selecionar uma atividade');
      return;
    }

    handleApprove(segmentId, dialog.handleClose, seller);
    handleCloseDialog();
  }

  return (
    <DialogInput onExited={onExited} maxWidth="sm">
      <Container>
        <TextField
          select
          label="Selecione a atividade"
          fullWidth
          margin="normal"
          value={segmentId || ''}
          onChange={e => setSegmentId(parseInt(e.target.value))}
          inputRef={inputs.segmentId}
          error={!!validation.segmentId}
          helperText={validation.segmentId}
        >
          {segments.map(segment => (
            <MenuItem key={segment.id} value={segment.id}>
              {segment.nome}
            </MenuItem>
          ))}
        </TextField>
        {reseller?.type === 'store' && (
          <div>
            <Label variant="body2" color="textSecondary">
              Informe a filial e vendedor responsável pelo contato
            </Label>
            <TextField
              select
              label="Filial do vendedor"
              fullWidth
              margin="normal"
              value={branchId || ''}
              onChange={e => setBranchId(parseInt(e.target.value))}
              error={!!error}
              helperText={error}
            >
              {branches.map(branch => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.cidade}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              disabled={loadingSellers}
              inputRef={inputs.seller}
              error={!!validation.seller}
              helperText={validation.seller}
              select
              label="Vendedor"
              fullWidth
              margin="normal"
              value={sellerId || ''}
              onChange={e => setSellerId(parseInt(e.target.value))}
            >
              {sellers.map(seller => (
                <MenuItem key={seller.codigo} value={seller.codigo}>
                  {seller.nome}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}

        <DialogInputConsumer>
          {context => (
            <Actions>
              <Button variant="text" color="primary" onClick={context.handleClose}>
                Cancelar
              </Button>
              <Button variant="contained" color="primary" onClick={() => handleValidation(context.handleClose)}>
                Confirmar
              </Button>
            </Actions>
          )}
        </DialogInputConsumer>
      </Container>
    </DialogInput>
  );
};

export default ResellerApprovalSegment;
