import React, { useMemo } from 'react';
import { styled } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { externalTooltipHandler } from 'components/chart/externalTooltip';
import { PurchaseOrderSegment } from 'types/purchaseOrderItem';
import NoData from 'components/no-data/NoData';

const Container = styled('div')({
  border: '1px solid #eee',
  padding: 20,
  display: 'flex',
  borderRadius: 6,
  flexDirection: 'column',
  background: '#f9f7f2',
  alignItems: 'center',
  gap: 10,
  '& > div': {
    width: '100%',
  },
});

const ChartItem = styled('div')({
  display: 'flex',
  maxHeight: 350,
  justifyContent: 'center',
});

interface TopSegmentsChartProps {
  data: PurchaseOrderSegment[];
}

const TopSegmentsChart: React.FC<TopSegmentsChartProps> = ({ data }) => {
  const total = useMemo(() => {
    return data.reduce((sum, item) => sum + item.valor, 0);
  }, [data]);

  const pieData = useMemo(() => {
    return data
      .sort((a, b) => b.valor - a.valor)
      .map(item => item.valor)
      .slice(0, 5);
  }, [data]);

  return (
    <Container>
      {data.length > 0 ? (
        <ChartItem>
          <Pie
            data={{
              labels: data
                .sort((a, b) => b.valor - a.valor)
                .map(segment => segment.segmento)
                .slice(0, 5),
              datasets: [
                {
                  backgroundColor: ['#56b5ab', '#45837f', '#3a4649', '#121813', '#9d1221'],
                  borderWidth: 0,
                  data: data
                    .sort((a, b) => b.valor - a.valor)
                    .map(segment => segment.valor)
                    .slice(0, 5),
                },
              ],
            }}
            options={{
              plugins: {
                tooltip: {
                  enabled: false,
                  callbacks: {
                    label: tooltip => moneyFormat(pieData[tooltip.dataIndex as number]),
                  },
                  external: externalTooltipHandler,
                },
                legend: {
                  display: false,
                },
                colors: {
                  enabled: true,
                },
                title: {
                  text: 'Por segmento',
                  display: true,
                  position: 'top',
                  align: 'center',
                  color: '#000',
                },
                datalabels: {
                  display: true,
                  color: '#fff',
                  font: {
                    size: 10,
                    weight: 400,
                  },
                  textAlign: 'right',
                  formatter(value) {
                    return `${percentFormat((value / total) * 100)}`;
                  },
                },
              },
              responsive: true,
            }}
          />
        </ChartItem>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
    </Container>
  );
};

export default TopSegmentsChart;
