import { TableTemplate } from 'types/tableTemplate';

export const purchaseOrderTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: '',
    originalId: 'actions',
    width: 60,
  },
  {
    id: 'codigo_pedido',
    description: 'ID',
    originalId: 'codigo_pedido',
    width: 60,
  },
  {
    id: 'cnpj',
    description: 'Cnpj',
    originalId: 'cnpj',
    width: 140,
  },
  {
    id: 'cliente',
    description: 'Fornecedor',
    originalId: 'cliente',
    width: 260,
  },
  {
    id: 'notafiscal',
    description: 'Nota',
    originalId: 'notafiscal',
    width: 60,
  },
  {
    id: 'numero_venda_ret',
    description: 'Venda',
    originalId: 'numero_venda_ret',
    width: 80,
  },
  {
    id: 'data',
    description: 'Cadastro',
    originalId: 'formattedDate',
    width: 120,
  },
  {
    id: 'data_emissao_nfe',
    description: 'Emissão',
    originalId: 'formattedDateIssue',
    width: 120,
  },
  {
    id: 'previsao',
    description: 'Previsão',
    originalId: 'formattedForecast',
    width: 120,
  },
  {
    id: 'nova_previsao',
    description: 'Nova Previsão',
    originalId: 'formattedNewForecast',
    width: 120,
  },
  {
    id: 'formattedTotal',
    description: 'Vl Total',
    originalId: 'valor_total_pedido',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'formattedShipment',
    description: 'Vl Frete',
    originalId: 'valor_frete',
    width: 90,
    dataType: 'number',
  },
  {
    id: 'formattedIpi',
    description: 'Vl IPI',
    originalId: 'valor_ipi',
    width: 90,
    dataType: 'number',
  },
  {
    id: 'formattedSt',
    description: 'Vl ST',
    originalId: 'valor_st',
    width: 90,
    dataType: 'number',
  },
  {
    id: 'formattedExpense',
    description: 'Vl Desp',
    originalId: 'valor_despesa',
    width: 90,
    dataType: 'number',
  },
  {
    id: 'formattedDiscount',
    description: 'Vl Desc',
    originalId: 'valor_desconto',
    width: 90,
    dataType: 'number',
  },
];
