import { TableTemplate } from 'types/tableTemplate';

export const coursesTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
    notFilterable: true,
  },
  {
    id: 'name',
    description: 'Nome',
    originalId: 'name',
    width: 200,
  },
  {
    id: 'description',
    description: 'Descrição',
    originalId: 'description',
    width: 350,
  },
  {
    id: 'formattedPrice',
    description: 'Preço',
    originalId: 'price',
    width: 100,
  },
  {
    id: 'formattedActive',
    description: 'Ativo',
    originalId: 'formattedActive',
    width: 120,
  },
  {
    id: 'formattedHappenAt',
    description: 'Acontece em',
    originalId: 'formattedHappenAt',
    width: 120,
  },
  {
    id: 'formattedCreatedAt',
    description: 'Criado em',
    originalId: 'formattedCreatedAt',
    width: 150,
    notSearchable: true,
  },
];
