import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import { Expense } from 'types/expense';
import ExpenseItemModule from './ExpenseItemModule';

type ExpenseListModuleProps = {
  expenses: Expense[];
};

const ExpenseListModule: React.FC<ExpenseListModuleProps> = ({ expenses }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <List>
      {expenses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(expense => (
        <ExpenseItemModule key={expense.id} expense={expense} />
      ))}
    </List>
  );
};

export default ExpenseListModule;
