import React from 'react';
import { Button, Typography, Theme } from '@mui/material';
import { useDialog } from 'components/dialogs/Dialog';
import { Done } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: 400,
    alignItems: 'center',
    '& > svg': {
      marginBottom: 20,
    },
  },
  link: {
    display: 'inline-block',
    marginTop: 30,
  },
  icon: {
    color: theme.palette.success.main,
    fontSize: 26,
  },
}));

const RegisterSuccess: React.FC = () => {
  const classes = useStyles();
  const dialog = useDialog();

  return (
    <div className={classes.container}>
      <Done className={classes.icon} />
      <Typography gutterBottom variant="h6">
        Registro concluído com sucesso.
      </Typography>
      <Button color="primary" onClick={dialog.handleClose}>
        Fechar
      </Button>
    </div>
  );
};

export default RegisterSuccess;
