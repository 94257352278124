import React from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { groupedUnityTableTemplate } from '../groupedUnityTableTemplate';
import { GroupedUnity } from './GroupedUnityListTable';

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

type GroupedUnityItemTableProps = {
  sale: GroupedUnity;
};

const GroupedUnityItemTable: React.FC<GroupedUnityItemTableProps> = ({ sale }) => {
  return (
    <>
      {groupedUnityTableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
            <Tooltip title={sale[item.id]}>
              <Description
                className={item.dataType === 'number' ? 'numericData' : undefined}
                fontSize={12}
                variant="body2"
              >
                {sale[item.id]}
              </Description>
            </Tooltip>
          </div>
        ))}
    </>
  );
};

export default GroupedUnityItemTable;
