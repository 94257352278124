import React, { useEffect, useRef } from 'react';
import { Typography, TextField, styled } from '@mui/material';
import { useAgentApproval } from '../hooks/useAgentApproval';
import DocumentUpload from 'components/document-upload/DocumentUpload';

const docUploadContainer = {
  width: '100%',
  height: 250,
};

const docUpload = {
  width: 180,
};

const AddressContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 100px',
  columnGap: '10px',
});

const Container = styled('div')({
  marginBottom: 100,
});

const AgentsData = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr',
  rowGap: '12px',
  marginBottom: 30,
  padding: '0 20px',
});

const ResellerData = styled('div')({
  display: 'grid',
  gridTemplateColumns: '100px 1fr',
  rowGap: '1px',
  marginBottom: 20,
  borderBottom: '1px solid #eee',
  paddingBottom: 10,
});

const DocsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '10px',
  rowGap: '10px',
  margin: '10px 0 30px',
  padding: '0 20px',
});

const AgentsApprovalData: React.FC = () => {
  const { setDocument, setLetter, letter, document, agent, handleChange, validation } = useAgentApproval();

  const inputs = {
    nome: useRef<HTMLInputElement>(null),
    cpf: useRef<HTMLInputElement>(null),
    rg: useRef<HTMLInputElement>(null),
    cep: useRef<HTMLInputElement>(null),
    endereco: useRef<HTMLInputElement>(null),
    numero_endereco: useRef<HTMLInputElement>(null),
    cidade: useRef<HTMLInputElement>(null),
    telefone: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Container>
      <ResellerData>
        <Typography color="textSecondary" variant="caption">
          Revenda
        </Typography>
        <Typography fontWeight="bold">{agent?.revenda}</Typography>
        <Typography color="textSecondary" variant="caption">
          CNPJ
        </Typography>
        <Typography>{agent?.cnpj_revenda}</Typography>
      </ResellerData>

      <AgentsData>
        <TextField
          label="Nome"
          fullWidth
          value={agent.nome}
          onChange={e => handleChange('nome', e.target.value)}
          inputRef={inputs.nome}
          error={!!validation.nome}
          helperText={validation.nome}
        />

        <TextField
          label="CPF"
          fullWidth
          value={agent.cpf}
          onChange={e => handleChange('cpf', e.target.value)}
          inputRef={inputs.cpf}
          error={!!validation.cpf}
          helperText={validation.cpf}
        />

        <TextField
          label="RG"
          fullWidth
          value={agent.rg}
          onChange={e => handleChange('rg', e.target.value)}
          inputRef={inputs.rg}
          error={!!validation.rg}
          helperText={validation.rg}
        />
      </AgentsData>

      <AgentsData>
        <TextField
          label="CEP"
          fullWidth
          value={agent.cep}
          onChange={e => handleChange('cep', e.target.value)}
          inputRef={inputs.cep}
          error={!!validation.cep}
          helperText={validation.cep}
        />

        <AddressContainer>
          <TextField
            label="Endereço"
            fullWidth
            value={agent.endereco}
            onChange={e => handleChange('endereco', e.target.value)}
            inputRef={inputs.endereco}
            error={!!validation.endereco}
            helperText={validation.endereco}
          />
          <TextField
            label="Número"
            fullWidth
            value={agent.numero_endereco}
            onChange={e => handleChange('numero_endereco', e.target.value)}
            inputRef={inputs.numero_endereco}
            error={!!validation.numero_endereco}
            helperText={validation.numero_endereco}
          />
        </AddressContainer>

        <TextField
          label="Cidade"
          fullWidth
          value={agent.cidade}
          onChange={e => handleChange('cidade', e.target.value)}
          inputRef={inputs.cidade}
          error={!!validation.cidade}
          helperText={validation.cidade}
        />
      </AgentsData>

      <AgentsData>
        <TextField
          label="Telefone"
          fullWidth
          value={agent.telefone}
          onChange={e => handleChange('telefone', e.target.value)}
          inputRef={inputs.telefone}
          error={!!validation.telefone}
          helperText={validation.telefone}
        />
      </AgentsData>

      <DocsContainer>
        <DocumentUpload
          document={document}
          label="Documento com foto"
          handleRemoveImage={() => setDocument(null)}
          handleSetImage={doc => setDocument(doc)}
          containerClassname={docUploadContainer}
          style={docUpload}
        />
        <DocumentUpload
          document={letter}
          label="Carta de preposição"
          handleRemoveImage={() => setLetter(null)}
          handleSetImage={doc => setLetter(doc)}
          containerClassname={docUploadContainer}
          style={docUpload}
        />
      </DocsContainer>
    </Container>
  );
};

export default AgentsApprovalData;
