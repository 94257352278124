import React, { Dispatch, SetStateAction } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useCustomerAgents } from './hook/useCustomerAgents';

type CustomerAgentListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const CustomerAgentListMenu: React.FC<CustomerAgentListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedAgent } = useCustomerAgents();

  function printLetter() {
    const letter = selectedAgent?.documentos.find(document => document.tipo === 'C');

    if (!letter) return;

    window.open(letter.url);

    setAnchorEl(null);
  }

  function printDocument() {
    const document = selectedAgent?.documentos.find(document => document.tipo === 'D');

    if (!document) return;

    window.open(document.url);

    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={printDocument}>Imprimir RG</MenuItem>
      <MenuItem onClick={printLetter}>Imprimir carta</MenuItem>
    </Menu>
  );
};

export default CustomerAgentListMenu;
