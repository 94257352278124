import { styled } from '@mui/material';
import React from 'react';
import SerialNumberItemTable from './SerialNumberItemTable';
import { SerialNumbers } from 'pages/material-residues/waste/types/waste';

interface SerialNumberListTableProps {
  serialNumbers: SerialNumbers[];
  onDelete?(index: number): void;
}

const TableBody = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const TableRow = styled('div')({
  display: 'grid',
  borderBottom: '1px solid #ddd',
  gridTemplateColumns: '1fr 40px',
  alignItems: 'center',
});

const SerialNumberListTable: React.FC<SerialNumberListTableProps> = ({ onDelete, serialNumbers }) => {
  return (
    <TableBody>
      {serialNumbers.map((serialNumber, index) => (
        <TableRow key={index}>
          <SerialNumberItemTable onDelete={onDelete ? () => onDelete(index) : undefined} serialNumber={serialNumber} />
        </TableRow>
      ))}
    </TableBody>
  );
};

export default SerialNumberListTable;
