import React from 'react';
import { Typography, ListItem, styled } from '@mui/material';
import { FinancialCardBranch } from '../../../hooks/useFetchFinancialCard';

const StyledListItem = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  position: 'relative',
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  alignItems: 'center',
  gridTemplateColumns: '110px 1fr',
});

type BranchItemModuleProps = {
  branch: FinancialCardBranch;
};

const BranchItemModule: React.FC<BranchItemModuleProps> = ({ branch }) => {
  return (
    <StyledListItem>
      <Typography variant="h6">{branch.filial}</Typography>

      <Grid>
        <Typography variant="caption">Valor grosso:</Typography>
        <Typography color="#f30000" variant="body2">
          {branch.formattedValueGross}
        </Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Valor desconto:</Typography>
        <Typography color="#f30000" variant="body2">
          {branch.formattedValueDiscount}
        </Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Valor liquído:</Typography>
        <Typography color="green" fontWeight={600} fontSize={16} variant="button">
          {branch.formattedValueLiquid}
        </Typography>
      </Grid>
    </StyledListItem>
  );
};

export default BranchItemModule;
