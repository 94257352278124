import React, { useState, FormEvent } from 'react';
import PageHeader from 'components/page-header/PageHeader';
import { BillSetting as BillSettingType } from 'types/billSetting';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import { useMessaging } from 'hooks/messaging';
import BillTab, { BillTabValues } from 'pages/bill-settings/tab/BillTab';
import { useBillSettingValidation } from 'pages/bill-settings/validation/useBillSettingValidation';
import BillSettingActions from 'pages/bill-settings/BillSettingActions';
import BillDescriptionForm from 'pages/bill-settings/BillDescriptionForm';
import BillSettingForm from 'pages/bill-settings/BillSettingForm';
import Dialog from 'components/dialogs/Dialog';
import { styled } from '@mui/material';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const Form = styled('form')({
  margin: '20px 0 50px',
  maxWidth: 500,
});

interface BillingNewProps {
  onExited(): void;
}

const BillingNew: React.FC<BillingNewProps> = ({ onExited }) => {
  const [tab, setTab] = useState<BillTabValues>('description');
  const [settings, setSettings] = useState<BillSettingType>({} as BillSettingType);
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();
  const [validation, setValidation, validate] = useBillSettingValidation();

  function handleChange(index: keyof BillSettingType, value: any) {
    setSettings(settings => ({
      ...settings,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(settings)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/bank-billet-settings', settings)
      .then(() => {
        messaging.handleOpen('Salvo');
        onExited();
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  return (
    <Dialog
      title="Financeiro"
      Tab={<BillTab tab={tab} handleChange={tab => setTab(tab)} />}
      onExited={onExited}
      ComponentActions={<BillSettingActions handleSubmit={handleValidation} />}
    >
      <Container>
        {saving && <Loading />}
        <PageHeader
          title="Configurações de cobrança"
          description="Configurações para emisssão de boleto e remessa de cobrança"
        />
        <Form onSubmit={handleValidation}>
          {tab === 'description' ? (
            <BillDescriptionForm settings={settings} handleChange={handleChange} validation={validation} />
          ) : (
            <BillSettingForm settings={settings} handleChange={handleChange} validation={validation} />
          )}
        </Form>
      </Container>
    </Dialog>
  );
};

export default BillingNew;
