import React, { useState } from 'react';
import List from 'components/list/List';
import CourseStudentItemModule from './CourseStudentItemModule';
import CourseStudentListMenu from '../CourseStudentListMenu';
import { CourseStudentOnList } from 'pages/course-students/types/courseStudent';

type CourseStudentListModuleProps = {
  courseStudents: CourseStudentOnList[];
};

const CourseStudentListModule: React.FC<CourseStudentListModuleProps> = ({ courseStudents }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <CourseStudentListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <List>
        {courseStudents.map(courseStudent => (
          <CourseStudentItemModule key={courseStudent.id} courseStudent={courseStudent} setAnchorEl={setAnchorEl} />
        ))}
      </List>
    </>
  );
};

export default CourseStudentListModule;
