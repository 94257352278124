import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, ListItem, IconButton } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { Customer } from 'types/customers';
import { useCustomers } from 'pages/site-h2i/customers/hooks/useCustomers';
import history from 'services/history';

import { makeStyles } from '@mui/styles';

type CustomerItemModuleProps = {
  customer: Customer;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

const CustomerItemModule: React.FC<CustomerItemModuleProps> = ({ customer, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedCustomer } = useCustomers();

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedCustomer(customer);
  }

  return (
    <ListItem button className={classes.listItem} onClick={() => history.push(`/site/customer/${customer.id}`)}>
      <Typography variant="caption" color="primary">
        Cliente {customer.id}
      </Typography>
      <Typography variant="h6">{customer.name}</Typography>
      <IconButton size="small" onClick={e => handleMoreClick(e)} className={classes.buttonMore}>
        <MoreHoriz />
      </IconButton>
    </ListItem>
  );
};

export default CustomerItemModule;
