import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import { Revenue } from 'types/revenue';
import RevenueItemModule from './RevenueItemModule';

type RevenueListModuleProps = {
  revenues: Revenue[];
};

const RevenueListModule: React.FC<RevenueListModuleProps> = ({ revenues }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <List>
      {revenues.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(revenue => (
        <RevenueItemModule key={revenue.id} revenue={revenue} />
      ))}
    </List>
  );
};

export default RevenueListModule;
