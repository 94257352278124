import { Typography, styled } from '@mui/material';
import React from 'react';

interface WasteTotalsProps {
  formattedTotal: string;
}

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '& .total-item': {
    display: 'inline-flex',
    gap: 20,
    justifyContent: 'end',
    alignItems: 'baseline',
    marginBottom: 10,
    '& > span': {
      fontSize: 16,
      fontWeight: '400',
    },
  },
  padding: 30,
  backgroundColor: '#fff',
  [theme.breakpoints.down('sm')]: {
    padding: '5px 15px',
  },
}));

const WasteTotals: React.FC<WasteTotalsProps> = ({ formattedTotal }) => {
  return (
    <Content>
      <Typography align="left" variant="h6" className="subtitle" color="primary">
        Totais
      </Typography>

      <Typography className="total-item" variant="h5" align="right">
        <span>Produtos</span>
        {formattedTotal}
      </Typography>
    </Content>
  );
};

export default WasteTotals;
