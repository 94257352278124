import React, { MouseEvent } from 'react';
import { InvoiceSummary } from 'types/summary/invoiceSummary';
import { useTable } from 'components/table/hook/useTable';
import { Typography, styled } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useFinancialSummary } from '../../hooks/useFinancialSummary';

interface SummaryItemInvoiceProps {
  data: InvoiceSummary;
}

const Icon = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  '& > svg': {
    transition: 'all ease 0.3s',
    '&.active': {
      transform: 'rotate(180deg)',
    },
  },
});

const SummaryItemInvoice: React.FC<SummaryItemInvoiceProps> = ({ data }) => {
  const { tableTemplate } = useTable();
  const { setSelectedInvoice, selectedInvoice } = useFinancialSummary();

  function handleClick(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    setSelectedInvoice(selectedInvoice === null ? data : null);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'action' ? (
            <Icon onClick={e => handleClick(e)} key={item.id}>
              <KeyboardArrowDown className={selectedInvoice?.filial === data.filial ? 'active' : ''} />
            </Icon>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
              <Typography
                color={item.originalId === 'valor' ? '#ff0000' : ''}
                fontWeight={item.originalId === 'valor' ? 600 : 0}
                variant="body2"
              >
                {data[item.id]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default SummaryItemInvoice;
