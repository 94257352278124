import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography, Theme } from '@mui/material';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import Pagination from 'components/pagination/Pagination';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import { useSelector } from 'store/selector';
import {
  BranchStockBalanceData,
  ManufacturerStockBalanceData,
  ResponsibleStockBalanceData,
  SegmentStockBalanceData,
  StockBalanceData,
  SubgroupStockBalanceData,
  UnityStockBalanceData,
} from 'types/reports/stockBalance';
import ProductListTable from './list/product/table/ProductList';
import ProductListModule from './list/product/module/ProductList';
import StockBalanceReportData from './StockBalanceReportData';
import StockBalanceFilter from '../StockBalanceFilter';
import InsideLoading from 'components/loading/InsideLoading';
import ResponsibleListTable from './list/responsible/table/ResponsibleListTable';
import ResponsibleListModule from './list/responsible/module/ResponsibleListModule';
import { StockBalanceProvider, StockBalanceTotalProps } from './list/hooks/useStockBalance';
import SubgroupListTable from './list/subgroup/table/SubgroupListTable';
import SubgroupListModule from './list/subgroup/module/SubgroupListModule';
import BranchListModule from './list/branch/module/BranchListModule';
import BranchListTable from './list/branch/table/BranchListTable';
import StockMoreFilter from '../StockMoreFilter';
import UnityListTable from './list/unity/table/UnityListTable';
import UnityListModule from './list/unity/module/UnityListModule';
import SegmentListTable from './list/segment/table/SegmentListTable';
import SegmentListModule from './list/segment/module/SegmentListModule';
import Appbar from 'components/appbar/Appbar';
import { StockFilter } from 'types/reports/stockFilter';
import StockBalanceTotal from '../StockBalanceTotal';
import StockBalanceExport from '../StockBalanceExport';
import ManufacturerListTable from './list/manufacturer/table/ManufacturerListTable';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  moreDetail: {
    marginTop: 15,
    marginBottom: 10,
    padding: '15px 0',
    position: 'relative',
    transition: 'all ease 0.3s',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  typography: {
    top: -12,
    left: 15,
    cursor: 'pointer',
    padding: '0px 5px',
    position: 'absolute',
    backgroundColor: '#fafafa',
  },
}));

const StockBalance: React.FC = () => {
  const classes = useStyles();
  const { h2iApi, isMobile, windowWidth, handleOpenMenu, isOpenedMenu } = useApp();
  const [dataItems, setDataItems] = useState<StockBalanceData[]>([]);
  const [dataSubgroups, setDataSubgroups] = useState<SubgroupStockBalanceData[]>([]);
  const [dataBranches, setDataBranches] = useState<BranchStockBalanceData[]>([]);
  const [dataResponsibles, setDataResponsibles] = useState<ResponsibleStockBalanceData[]>([]);
  const [loadingResponsible, setLoadingResponsible] = useState(false);
  const [loadingSubgroup, setLoadingSubgroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const branches = useSelector(state => state.branches);
  const [excelExport, setExcelExport] = useState(false);
  const [moreFilter, setMoreFilter] = useState(false);
  const [filteredResponsibles, setFilteredResponsibles] = useState<ResponsibleStockBalanceData[]>([]);
  const [filteredItems, setFilteredItems] = useState<StockBalanceData[]>([]);
  const [filteredSubgroups, setFilteredSubgroups] = useState<SubgroupStockBalanceData[]>([]);
  const [filteredBranches, setFilteredBranches] = useState<BranchStockBalanceData[]>([]);
  const [selectedResponsible, setSelectedResponsible] = useState<ResponsibleStockBalanceData | null>(null);
  const [moreResponsible, setMoreResponsible] = useState<ResponsibleStockBalanceData | null>(null);
  const [moreBranch, setMoreBranch] = useState<BranchStockBalanceData | null>(null);
  const [viewItem, setViewItem] = useState(false);
  const [viewResponsible, setViewResponsible] = useState(false);
  const [viewSubgroup, setViewSubgroup] = useState(false);
  const [moreUnity, setMoreUnity] = useState<UnityStockBalanceData | null>(null);
  const [dataUnits, setDataUnits] = useState<UnityStockBalanceData[]>([]);
  const [filteredUnits, setFilteredUnits] = useState<UnityStockBalanceData[]>([]);
  const [loadingUnity, setLoadingUnity] = useState(false);
  const [viewUnity, setViewUnity] = useState(false);
  const [moreSegment, setMoreSegment] = useState<SegmentStockBalanceData | null>(null);
  const [dataSegments, setDataSegments] = useState<SegmentStockBalanceData[]>([]);
  const [filteredSegments, setFilteredSegments] = useState<SegmentStockBalanceData[]>([]);
  const [loadingSegment, setLoadingSegment] = useState(false);
  const [viewSegment, setViewSegment] = useState(false);
  const [moreStoppedStock, setMoreStoppedStock] = useState<BranchStockBalanceData | null>(null);
  const [dataStoppedStocks, setDataStoppedStocks] = useState<BranchStockBalanceData[]>([]);
  const [filteredStoppedStocks, setFilteredStoppedStocks] = useState<BranchStockBalanceData[]>([]);
  const [viewStoppedStock, setViewStoppedStock] = useState(false);
  const [moreManufacturer, setMoreManufacturer] = useState<ManufacturerStockBalanceData | null>(null);
  const [viewManufacturer, setViewManufacturer] = useState(false);
  const [dataManufacturers, setDataManufacturers] = useState<ManufacturerStockBalanceData[]>([]);
  const [filteredManufacturers, setFilteredManufacturers] = useState<ManufacturerStockBalanceData[]>([]);
  const [viewBranch, setViewBranch] = useState(false);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [orderedIndex, sort] = useTableOrder();
  const [shownReport, setShownReport] = useState(false);
  const [filter, setFilter] = useState<StockFilter>({
    branchId: [],
    manufacturerId: [],
    subgroupId: [],
    indexToSearch: 'Produto',
    searchValue: '',
    categoryId: [],
    segmentId: [],
    subcategoryId: [],
    unityId: [],
    made_by: '',
  });
  const [formattedTotal, setFormattedTotal] = useState<StockBalanceTotalProps>({
    coustTotal: 0,
    coustStoppedStock: 0,
    order: 0,
    reserve: 0,
    stock: 0,
    stoppedStock: 0,
    forecast: 0,
    index: 0,
  });

  function handleViewBranch() {
    if (viewBranch) {
      setMoreBranch(null);
    }
    setViewBranch(!viewBranch);
  }

  function handleViewResponsible() {
    if (viewResponsible) {
      setMoreResponsible(null);
      setSelectedResponsible(null);
    } else {
      setLoadingResponsible(true);

      h2iApi
        ?.get(`/api/relestresponsavel`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(','),
            id_subcategoria: filter.subcategoryId.join(','),
            id_segmento: filter.segmentId.join(','),
            id_unidade: filter.unityId.join(','),
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data.estoque.result) {
            setFilteredResponsibles([]);
            return;
          }
          const _data = response.data.estoque.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            return item;
          });
          setDataResponsibles(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingResponsible(false));
    }

    setViewResponsible(!viewResponsible);
  }

  function handleViewSubgroup() {
    if (!viewSubgroup) {
      setLoadingSubgroup(true);

      h2iApi
        ?.get(`/api/relestsubgrupo`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data.estoque.result) {
            setFilteredSubgroups([]);
            return;
          }

          const _data = response.data.estoque.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            return item;
          });
          setDataSubgroups(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingSubgroup(false));
    }

    setViewSubgroup(!viewSubgroup);
  }

  function handleViewUnity() {
    if (!viewUnity) {
      setLoadingUnity(true);

      h2iApi
        ?.get(`/api/relestunidade`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          const _data = response.data.segmento.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            return item;
          });
          setDataUnits(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingUnity(false));
    }

    setViewUnity(!viewUnity);
  }

  function handleViewSegment() {
    if (!viewSegment) {
      setLoadingSegment(true);

      h2iApi
        ?.get(`/api/relestsegmento`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          const _data = response.data.segmento.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            return item;
          });
          setDataSegments(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingSegment(false));
    }

    setViewSegment(!viewSegment);
  }

  function handleViewStoppedStock() {
    setViewStoppedStock(!viewStoppedStock);
  }

  function handleViewManufacturer() {
    setViewManufacturer(!viewManufacturer);
  }

  function handleViewItem() {
    setViewItem(!viewItem);
  }

  const selectedBranches = useMemo(() => {
    const branch = branches.filter(branch => filter.branchId.some(item => item === branch.id));
    return branch;
  }, [branches, filter.branchId]);

  const handleSearch = useCallback(
    async (filter: StockFilter, isOpenedMenu?: boolean, handleOpenMenu?: () => void) => {
      if (!h2iApi) {
        return;
      }

      setMoreFilter(false);
      setMoreBranch(null);
      setMoreResponsible(null);
      setMoreStoppedStock(null);
      setMoreUnity(null);
      setMoreSegment(null);

      setViewBranch(false);
      setViewStoppedStock(false);
      setViewSegment(false);
      setViewUnity(false);
      setViewItem(false);
      setViewResponsible(false);
      setViewSubgroup(false);

      if (isOpenedMenu && handleOpenMenu) {
        handleOpenMenu();
      }

      setLoading(true);

      await h2iApi
        .get(`/api/saldoestoque`, {
          params: {
            id_filial: filter.branchId.join(','),
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(','),
            id_subcategoria: filter.subcategoryId.join(','),
            id_segmento: filter.segmentId.join(','),
            id_unidade: filter.unityId.join(','),
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data[0].result) {
            setFilteredItems([]);
            setFormattedTotal(item => ({
              ...item,
              coustTotal: 0,
              index: 0,
            }));
            return;
          }

          const coustTotal = response.data.reduce((sum, item) => sum + item.CustoTotal, 0);
          const index = response.data.reduce((sum, item) => sum + item.indice, 0);
          setFormattedTotal(item => ({
            ...item,
            coustTotal,
            index,
          }));

          const _data: StockBalanceData[] = response.data.map((item: StockBalanceData) => {
            item.formattedEstoque = numberFormat(item.Estoque);
            item.formattedCusto = moneyFormat(item.Custo);
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            item.formattedIndex = numberFormat(item.indice);
            return item;
          });

          const forecast = response.data.reduce((sum, item) => sum + item.forecast, 0);
          setFormattedTotal(item => ({
            ...item,
            forecast,
          }));
          setDataItems(_data);
        });

      await h2iApi
        .get(`/api/relestfabricante`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          const _data = response.data.filiais.map(item => {
            item.formattedEstoque = numberFormat(item.Estoque);
            item.formattedCusto = moneyFormat(item.Custo);
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            return item;
          });
          setDataManufacturers(_data);
        })
        .catch(err => console.error(err));

      await h2iApi
        .get(`/api/relestfilial`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            sem_movimentacao: 'S',
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data.estoque) {
            setFilteredStoppedStocks([]);
            setFormattedTotal(item => ({
              ...item,
              coustStoppedStock: 0,
              stoppedStock: 0,
            }));
            return;
          }

          const coustStoppedStock = response.data.filiais.reduce((sum, item) => sum + item.CustoTotal, 0);
          const stoppedStock = response.data.filiais.reduce((sum, item) => sum + item.EstTotal, 0);
          setFormattedTotal(item => ({
            ...item,
            coustStoppedStock,
            stoppedStock,
          }));

          const _data = response.data.filiais.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            return item;
          });
          setDataStoppedStocks(_data);
        })
        .catch(err => console.error(err));

      await h2iApi
        ?.get(`/api/relestfilial`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data.estoque) {
            setDataBranches([]);
            setFormattedTotal(item => ({
              ...item,
              order: 0,
              reserve: 0,
              stock: 0,
            }));
            return;
          }

          const _data = response.data.filiais.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            return item;
          });

          const reserve = response.data.filiais.reduce((sum, item) => sum + item.valor_reserva, 0);
          const order = response.data.filiais.reduce((sum, item) => sum + item.valor_pedido, 0);
          const stock = response.data.filiais.reduce((sum, item) => sum + item.EstTotal, 0);

          setFormattedTotal(item => ({
            ...item,
            order,
            reserve,
            stock,
          }));

          setDataBranches(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi],
  );

  function handleSortManufacturer(index: string) {
    const p = sort(index, filteredManufacturers);
    setFilteredManufacturers(p);
  }

  function handleSortResponsible(index: string) {
    const p = sort(index, filteredResponsibles);
    setFilteredResponsibles(p);
  }

  function handleSortBranch(index: string) {
    const p = sort(index, filteredBranches);
    setFilteredBranches(p);
  }

  function handleSortStoppedStock(index: string) {
    const p = sort(index, filteredStoppedStocks);
    setFilteredStoppedStocks(p);
  }

  function handleSortSubgroup(index: string) {
    const p = sort(index, filteredSubgroups);
    setFilteredSubgroups(p);
  }

  function handleSortUnity(index: string) {
    const p = sort(index, filteredUnits);
    setFilteredUnits(p);
  }

  function handleSortSegment(index: string) {
    const p = sort(index, filteredSegments);
    setFilteredSegments(p);
  }

  function handleSortItem(index: string) {
    const p = sort(index, filteredItems);
    setFilteredItems(p);
  }

  function handleChange(index: keyof StockFilter, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  useEffect(() => {
    setFilteredItems(dataItems);
  }, [dataItems]);

  useEffect(() => {
    setFilteredBranches(dataBranches);
  }, [dataBranches]);

  useEffect(() => {
    setFilteredStoppedStocks(dataStoppedStocks);
  }, [dataStoppedStocks]);

  useEffect(() => {
    setFilteredManufacturers(dataManufacturers);
  }, [dataManufacturers]);

  useEffect(() => {
    setFilteredResponsibles(dataResponsibles);
  }, [dataResponsibles]);

  useEffect(() => {
    setFilteredSubgroups(dataSubgroups);
  }, [dataSubgroups]);

  useEffect(() => {
    setFilteredUnits(dataUnits);
  }, [dataUnits]);

  useEffect(() => {
    setFilteredSegments(dataSegments);
  }, [dataSegments]);

  return (
    <StockBalanceProvider
      value={{
        moreSegment,
        setMoreSegment,
        moreUnity,
        setMoreUnity,
        moreBranch,
        setMoreBranch,
        moreStoppedStock,
        setMoreStoppedStock,
        moreResponsible,
        setMoreResponsible,
        moreManufacturer,
        setMoreManufacturer,
        setSelectedResponsible,
        selectedResponsible,
        filter,
      }}
    >
      {moreFilter && (
        <StockMoreFilter
          filter={filter}
          onSearch={() => handleSearch(filter)}
          onExited={() => setMoreFilter(false)}
          handleChangeFilter={handleChange}
        />
      )}

      {shownReport && (
        <StockBalanceReportData
          data={filteredItems}
          branches={selectedBranches}
          onExited={() => setShownReport(false)}
        />
      )}

      <Appbar title="" />

      <StockBalanceFilter
        filter={filter}
        setExcelExport={setExcelExport}
        openMoreFilter={() => setMoreFilter(true)}
        openReport={() => setShownReport(true)}
        displayMode={displayMode}
        handleChange={handleChange}
        onSearch={filter => handleSearch(filter, isOpenedMenu, handleOpenMenu)}
        setDisplayMode={setDisplayMode}
      />

      <StockBalanceTotal loading={loading} formattedTotal={formattedTotal} />

      {excelExport && <StockBalanceExport filter={filter} onExited={() => setExcelExport(false)} />}

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filteredItems.length === 0 ? (
        <NoData message="Nenhuma informação encontrada" />
      ) : (
        <>
          <div className={classes.moreDetail}>
            <Typography onClick={handleViewResponsible} variant="caption" className={classes.typography}>
              + Responsável
            </Typography>
            {viewResponsible && (
              <PaginationProvider>
                <div className={classes.container}>
                  {loadingResponsible ? (
                    <InsideLoading />
                  ) : filteredResponsibles.length === 0 ? (
                    <NoData message="Nenhum responsável encontrado." />
                  ) : displayMode === 'list' ? (
                    <ResponsibleListTable
                      isSubgroup
                      responsibles={filteredResponsibles}
                      handleSort={handleSortResponsible}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    displayMode === 'module' && <ResponsibleListModule responsibles={filteredResponsibles} />
                  )}
                  <Pagination count={filteredResponsibles.length} />
                </div>
              </PaginationProvider>
            )}
          </div>

          <div className={classes.moreDetail}>
            <Typography onClick={handleViewBranch} variant="caption" className={classes.typography}>
              + Filial
            </Typography>
            {viewBranch && (
              <PaginationProvider>
                <div className={classes.container}>
                  {filteredBranches.length === 0 ? (
                    <NoData message="Nenhuma filial encontrada." />
                  ) : displayMode === 'list' ? (
                    <BranchListTable
                      stoppedStock={false}
                      isSubgroup
                      branches={filteredBranches}
                      handleSort={handleSortBranch}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    displayMode === 'module' && <BranchListModule branches={filteredBranches} />
                  )}
                  <Pagination count={filteredBranches.length} />
                </div>
              </PaginationProvider>
            )}
          </div>

          <div className={classes.moreDetail}>
            <Typography onClick={handleViewUnity} variant="caption" className={classes.typography}>
              + Unidade
            </Typography>
            {viewUnity && (
              <PaginationProvider>
                <div className={classes.container}>
                  {loadingUnity ? (
                    <InsideLoading />
                  ) : filteredUnits.length === 0 ? (
                    <NoData message="Nenhuma unidade encontrada." />
                  ) : displayMode === 'list' ? (
                    <UnityListTable units={filteredUnits} handleSort={handleSortUnity} orderedIndex={orderedIndex} />
                  ) : (
                    displayMode === 'module' && <UnityListModule units={filteredUnits} />
                  )}
                  <Pagination count={filteredUnits.length} />
                </div>
              </PaginationProvider>
            )}
          </div>

          <div className={classes.moreDetail}>
            <Typography onClick={handleViewSegment} variant="caption" className={classes.typography}>
              + Segmento
            </Typography>
            {viewSegment && (
              <PaginationProvider>
                <div className={classes.container}>
                  {loadingSegment ? (
                    <InsideLoading />
                  ) : filteredSegments.length === 0 ? (
                    <NoData message="Nenhum segmento encontrado." />
                  ) : displayMode === 'list' ? (
                    <SegmentListTable
                      segments={filteredSegments}
                      handleSort={handleSortSegment}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    displayMode === 'module' && <SegmentListModule segments={filteredSegments} />
                  )}
                  <Pagination count={filteredSegments.length} />
                </div>
              </PaginationProvider>
            )}
          </div>

          <div className={classes.moreDetail}>
            <Typography onClick={handleViewSubgroup} variant="caption" className={classes.typography}>
              + Subgrupo
            </Typography>
            {viewSubgroup && (
              <PaginationProvider>
                <div className={classes.container}>
                  {loadingSubgroup ? (
                    <InsideLoading />
                  ) : filteredSubgroups.length === 0 ? (
                    <NoData message="Nenhum subgrupo encontrado." />
                  ) : displayMode === 'list' ? (
                    <SubgroupListTable
                      subgroups={filteredSubgroups}
                      handleSort={handleSortSubgroup}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    displayMode === 'module' && <SubgroupListModule subgroups={filteredSubgroups} />
                  )}
                  <Pagination count={filteredSubgroups.length} />
                </div>
              </PaginationProvider>
            )}
          </div>

          <div className={classes.moreDetail}>
            <Typography onClick={handleViewItem} variant="caption" className={classes.typography}>
              + Itens
            </Typography>
            {viewItem && (
              <PaginationProvider>
                <div className={classes.container}>
                  {filteredItems.length === 0 ? (
                    <NoData message="Nenhum item encontrado." />
                  ) : displayMode === 'list' ? (
                    <ProductListTable
                      isSubgroup={false}
                      products={filteredItems}
                      handleSort={handleSortItem}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    displayMode === 'module' && <ProductListModule products={filteredItems} />
                  )}
                  <Pagination count={filteredItems.length} />
                </div>
              </PaginationProvider>
            )}
          </div>

          <div className={classes.moreDetail}>
            <Typography onClick={handleViewManufacturer} variant="caption" className={classes.typography}>
              + Fabricante
            </Typography>
            {viewManufacturer && (
              <PaginationProvider>
                <div className={classes.container}>
                  {filteredManufacturers.length === 0 ? (
                    <NoData message="Nenhum Manufacturer encontrado." />
                  ) : displayMode === 'list' ? (
                    <ManufacturerListTable
                      manufacturers={filteredManufacturers}
                      handleSort={handleSortManufacturer}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    displayMode === 'module' && <>{/* <ManufacturerListModule products={filteredManufacturers} /> */}</>
                  )}
                  <Pagination count={filteredManufacturers.length} />
                </div>
              </PaginationProvider>
            )}
          </div>

          <div className={classes.moreDetail}>
            <Typography onClick={handleViewStoppedStock} variant="caption" className={classes.typography}>
              + Sem movimentação
            </Typography>
            {viewStoppedStock && (
              <PaginationProvider>
                <div className={classes.container}>
                  {filteredStoppedStocks.length === 0 ? (
                    <NoData message="Nenhum estoque sem movimentação encontrado." />
                  ) : displayMode === 'list' ? (
                    <BranchListTable
                      stoppedStock
                      isSubgroup
                      branches={filteredStoppedStocks}
                      handleSort={handleSortStoppedStock}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    displayMode === 'module' && <BranchListModule branches={filteredStoppedStocks} />
                  )}
                  <Pagination count={filteredStoppedStocks.length} />
                </div>
              </PaginationProvider>
            )}
          </div>
        </>
      )}
    </StockBalanceProvider>
  );
};

export default StockBalance;
