import React, { useCallback, useState, useEffect, useRef } from 'react';
import ProductItem from './ProductItem';
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { useApp } from 'hooks/app';
import styles from './autocomplete.module.css';
import ProductsAutoCompleteAdornment from './ProductsAutoCompleteAdornment';
import { Product } from 'types/product';

let timer: NodeJS.Timeout;

interface AutocompleteFromProps {
  onSelect(value: Product | null): void;
  label: string;
  placeholder: string;
  selected: Product | null;
  helperText?: string;
  error?: boolean;
}

const ProductsAutoComplete: React.FC<AutocompleteFromProps> = ({
  onSelect,
  label,
  placeholder,
  selected,
  helperText = '',
  error = false,
}) => {
  const { h2iApi } = useApp();
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState<Product[]>([]);
  const [options, setOptions] = useState<Product[]>([]);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!error) {
      return;
    }

    inputRef.current?.focus();
  }, [error]);

  const handleSelect = useCallback(
    (option: Product) => {
      onSelect(option);
      setFiltered([]);
    },
    [onSelect],
  );

  useEffect(() => {
    setFiltered(options);
  }, [options]);

  const findResellers = useCallback(
    async (term: string) => {
      setLoading(true);
      h2iApi
        ?.get(`/api/produtoTodos`, { params: { produto: term.toUpperCase() } })
        .then(response => {
          setOptions(response.data);
        })
        .finally(() => setLoading(false))
        .catch(err => console.error(err));
    },
    [h2iApi],
  );

  const clearSelected = useCallback(() => {
    onSelect(null);
  }, [onSelect]);

  function handleChange(value: string) {
    clearTimeout(timer);

    if (value.length < 3) {
      return;
    }

    timer = setTimeout(() => findResellers(value), 400);
  }

  return (
    <Autocomplete
      classes={{ inputRoot: styles.inputRoot }}
      id="reseller-autocomplete"
      open={filtered.length > 0}
      options={filtered}
      value={selected}
      fullWidth
      filterOptions={options => options}
      getOptionLabel={option => `${option.codigo_barra} - ${option.produto}`}
      renderOption={(props, option) => (
        <ProductItem handleSelect={handleSelect} selected={selected} option={option} {...props} />
      )}
      renderInput={params => (
        <TextField
          {...params}
          inputRef={inputRef}
          label={label}
          placeholder={placeholder}
          onChange={event => handleChange(event.target.value)}
          onBlur={() => setFiltered([])}
          error={error}
          helperText={helperText}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <ProductsAutoCompleteAdornment loading={loading} selected={selected} clearSelected={clearSelected} />
            ),
          }}
        />
      )}
    />
  );
};

export default ProductsAutoComplete;
