import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import { StockBalanceData } from 'types/reports/stockBalance';
import { StockFilter } from 'types/reports/stockFilter';

export type StockIndicatorTotalProps = {
  coustTotal: number;
  coustStoppedStock: number;
  reserve: number;
  index: number;
  order: number;
  stock: number;
  stoppedStock: number;
  forecast: number;
};

type StockIndicatorContextValue = {
  setSelectedItem: Dispatch<SetStateAction<StockBalanceData | null>>;
  setMoreItem: Dispatch<SetStateAction<StockBalanceData | null>>;
  selectedItem: StockBalanceData | null;
  moreItem: StockBalanceData | null;
  filter: StockFilter;
};

const StockIndicatorContext = createContext<StockIndicatorContextValue>({} as StockIndicatorContextValue);
export const StockIndicatorProvider = StockIndicatorContext.Provider;

export function useStockIndicator(): StockIndicatorContextValue {
  const context = useContext(StockIndicatorContext);
  return context;
}
