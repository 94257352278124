import { TableTemplate } from 'types/tableTemplate';

export const bannersTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'document_number',
    description: 'NÚMERO DOC',
    originalId: 'document_number',
    width: 120,
  },
  {
    id: 'number',
    description: 'NÚMERO',
    originalId: 'number',
    width: 100,
  },
  {
    id: 'bank_billet_remittance_id',
    description: 'ID DA REMESSA',
    originalId: 'bank_billet_remittance_id',
    width: 100,
  },
  {
    id: 'beneficiaryName',
    description: 'BENEFICIARIO',
    originalId: 'beneficiaryName',
    width: 300,
  },
  {
    id: 'payerName',
    description: 'CLIENTE',
    originalId: 'payerName',
    width: 300,
  },
  {
    id: 'formattedValue',
    description: 'VALOR',
    originalId: 'value',
    width: 100,
  },
  {
    id: 'formattedPayday',
    description: 'VENCIMENTO',
    originalId: 'payday',
    width: 120,
  },
  {
    id: 'url',
    description: 'URL',
    originalId: 'url',
    width: 300,
  },
  {
    id: 'formattedCanceledAt',
    description: 'CANCELADO EM',
    originalId: 'canceled_at',
    width: 160,
  },
  {
    id: 'formattedCreatedAt',
    description: 'EMISSÃO',
    originalId: 'created_at',
    width: 150,
  },
];
