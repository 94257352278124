import { FormControl, InputLabel, MenuItem, Select, TextField, Theme, useTheme } from '@mui/material';
import React from 'react';
import { FilterMore } from './FilterMore';
import { SegmentFilter, UnityFilter } from './hooks/useFetchMoreFilters';
import { MonthlyCategory } from 'types/monthlyComparison';
import { ProductGroup, ProductSubGroup } from 'types/product';
import { makeStyles } from '@mui/styles';
import { Manufacturer } from 'types/manufacturer';

const useStyles = makeStyles({
  item: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    lineClamp: 1,
    WebkitBoxOrient: 'vertical',
    width: 350,
  },
  formControl: {
    minWidth: 120,
    maxWidth: 400,
  },
});

interface FilterMoreFormProps {
  showMadeBy?: boolean;
  showUnits?: boolean;
  showSegments?: boolean;
  showCategories?: boolean;
  handleChangeFilter(index: keyof FilterMore, value: any): void;
  filter: FilterMore;
  units: UnityFilter[];
  segments: SegmentFilter[];
  categories: MonthlyCategory[];
  subCategories: ProductGroup[];
  subGroups: ProductSubGroup[];
  manufacturers: Manufacturer[];
}

function getStyles(name: number, personName: readonly number[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold,
    backgroundColor: personName.indexOf(name) === -1 ? '#fff' : '#cfffe6',
  };
}

const FilterMoreForm: React.FC<FilterMoreFormProps> = ({
  filter,
  handleChangeFilter,
  units,
  segments,
  categories,
  subCategories,
  subGroups,
  manufacturers,
  showMadeBy,
  showUnits,
  showSegments,
  showCategories,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {showMadeBy && (
        <TextField
          label="Pedido por"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={filter.madeBy}
          onChange={e => handleChangeFilter('madeBy', e.target.value)}
        />
      )}

      {showUnits && (
        <FormControl className={classes.formControl}>
          <InputLabel>unidades</InputLabel>
          <Select
            fullWidth
            value={filter.unitIds}
            onChange={e => handleChangeFilter('unitIds', e.target.value)}
            multiple
          >
            {units?.map(unity => (
              <MenuItem style={getStyles(unity.id, filter.unitIds, theme)} key={unity.id} value={unity.id}>
                {unity.unidade}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {showSegments && (
        <FormControl className={classes.formControl}>
          <InputLabel>segmentos</InputLabel>
          <Select
            fullWidth
            value={filter.segmentIds}
            onChange={e => handleChangeFilter('segmentIds', e.target.value as any)}
            multiple
          >
            {segments?.map(segment => (
              <MenuItem
                style={getStyles(segment.id, filter.segmentIds, theme)}
                className={classes.item}
                key={segment.id}
                value={segment.id}
              >
                {segment.segmento}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {showCategories && (
        <FormControl className={classes.formControl}>
          <InputLabel>categorias</InputLabel>
          <Select
            fullWidth
            multiple
            value={filter.categoryIds}
            onChange={e => handleChangeFilter('categoryIds', e.target.value as any)}
          >
            {categories?.map(category => (
              <MenuItem style={getStyles(category.id, filter.categoryIds, theme)} key={category.id} value={category.id}>
                {category.categoria}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl className={classes.formControl}>
        <InputLabel>subcategorias</InputLabel>
        <Select
          fullWidth
          label="subcategorias"
          value={filter.subCategoryIds}
          onChange={e => handleChangeFilter('subCategoryIds', e.target.value as any)}
          multiple
        >
          {subCategories.map(subcategory => (
            <MenuItem
              style={getStyles(subcategory.CODIGO_GRUPO, filter.subCategoryIds, theme)}
              key={subcategory.CODIGO_GRUPO}
              value={subcategory.CODIGO_GRUPO}
            >
              {subcategory.NOME}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Subgrupos</InputLabel>
        <Select
          fullWidth
          label="Subgrupos"
          value={filter.subGroupIds}
          onChange={e => handleChangeFilter('subGroupIds', e.target.value as any)}
          multiple
        >
          {subGroups.map(subgroup => (
            <MenuItem
              style={getStyles(subgroup.CODIGO_SUBGRUPO, filter.subGroupIds, theme)}
              key={subgroup.CODIGO_SUBGRUPO}
              value={subgroup.CODIGO_SUBGRUPO}
            >
              {subgroup.NOME}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel>fabricantes</InputLabel>
        <Select
          fullWidth
          value={filter.manufacturerIds}
          onChange={e => handleChangeFilter('manufacturerIds', e.target.value)}
          multiple
        >
          {manufacturers?.map(manufacturer => (
            <MenuItem
              style={getStyles(manufacturer.id_fabricante, filter.manufacturerIds, theme)}
              key={manufacturer.id_fabricante}
              value={manufacturer.id_fabricante}
            >
              {manufacturer.nome}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default FilterMoreForm;
