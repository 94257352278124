import React from 'react';
import { IconButton, styled } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

const CustomIconButton = styled(IconButton)(props => ({
  display: 'none',
  [props.theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

type FilterActionsProps = {
  handleOpenDialog(): void;
};

const FilterActions: React.FC<FilterActionsProps> = ({ handleOpenDialog }) => {
  return (
    <CustomIconButton onClick={handleOpenDialog} color="inherit">
      <FilterListIcon />
    </CustomIconButton>
  );
};

export default FilterActions;
