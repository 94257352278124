import { useCallback, useState } from 'react';
import { api } from 'services/api';
import { Rental, RentalOnList } from '../types/rental';
import { RentalQueryParams } from '../Rentals';
import { useRentalFormatter } from './useRentalFormatter';
import { addDays, endOfMonth, startOfMonth } from 'date-fns';
import { Paginated } from 'types/paginated';

const queryParamsInitialValue: RentalQueryParams = {
  initial_date: startOfMonth(new Date()),
  final_date: endOfMonth(addDays(new Date(), 365)),
  page: 0,
  rows: 20,
  active: '1',
  is_paid: '2',
  term: '',
  financial_data: '',
};

type UseFetchRentalsOptions = {
  type: 'managed_sale' | 'rental';
};

export function useFetchRentals(option: UseFetchRentalsOptions) {
  const [queryParams, setQueryParams] = useState<RentalQueryParams>(queryParamsInitialValue);
  const [loading, setLoading] = useState(false);
  const [rentals, setRentals] = useState<RentalOnList[]>([]);
  const formatter = useRentalFormatter();
  const [total, setTotal] = useState(0);
  const [error, setError] = useState('');

  const fetch = useCallback(
    (queryParams: RentalQueryParams) => {
      setLoading(true);

      api
        .get<Paginated<Rental[]>>('/rentals', {
          params: { ...queryParams, type: 'rental' },
        })
        .then(response => {
          setRentals(response.data.data.map(item => formatter(item)));
          setTotal(response.data.total);
        })
        .catch(error => {
          console.error(error);
          setError('Não foi possível recuperar as locações');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [formatter],
  );

  return {
    fetch,
    loading,
    total,
    queryParams,
    setQueryParams,
    rentals,
    setRentals,
    error,
  };
}
