import React, { useState, useEffect } from 'react';
import { InputAdornment, ListItem, TextField, Typography } from '@mui/material';
import Dialog, { DialogConsumer } from 'components/dialogs/Dialog';
import { api } from 'services/api';
import { Reseller } from 'types/reseller';
import InsideSaving from 'components/loading/InsideSaving';
import Search from '@mui/icons-material/Search';
import useSearch from 'hooks/search';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  searchContainer: {
    marginBottom: 10,
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

interface CustomerImportProps {
  onExited(): void;
  handleConfirm(reseller: Reseller, handleDialogClose: () => void): void;
}

const CustomerImport: React.FC<CustomerImportProps> = ({ onExited, handleConfirm }) => {
  const classes = styles();

  const [resellers, setResellers] = useState<Reseller[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const search = useSearch();
  const [filtered, setFiltered] = useState<Reseller[]>([]);

  useEffect(() => {
    api
      .get('/resellers', { params: { approved: 1 } })
      .then(response => {
        setResellers(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const _filtered = search(searchValue, 'company_name', resellers);
    setFiltered(_filtered);
  }, [searchValue, search, resellers]);

  return (
    <Dialog title="Importar cliente aprovado" onExited={onExited} maxWidth="sm">
      {loading && <InsideSaving />}
      <div className={classes.searchContainer}>
        <TextField
          label="Pesquisar"
          placeholder="Pesquisar..."
          margin="normal"
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          fullWidth
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <DialogConsumer>
        {context =>
          filtered.map(reseller => (
            <ListItem
              className={classes.listItem}
              button
              key={reseller.id}
              onClick={() => handleConfirm(reseller, context.handleClose)}
            >
              <Typography>{reseller.company_name}</Typography>
              <Typography variant="body2" color="textSecondary">
                CNPJ {reseller.document}
              </Typography>
            </ListItem>
          ))
        }
      </DialogConsumer>
    </Dialog>
  );
};

export default CustomerImport;
