import React, { useState } from 'react';
import { Button, Typography, styled } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { usePermissionRules } from 'hooks/permissionRules';
import { useDavReviewDetail } from './hooks/useDavReviewDetail';

const Ul = styled('ul')({
  marginTop: 20,
  display: 'none',
  rowGap: '10px',
  '&.open': {
    display: 'grid',
  },
});

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderLeft: `3px solid ${theme.palette.success.main}`,
  borderRadius: 6,
  paddingLeft: 10,
}));

const Li = styled('li')({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr 1fr',
  alignItems: 'center',
  borderBottom: '1px dashed #eee',
  paddingBottom: 10,
});

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 15,
  cursor: 'pointer',
  paddingBottom: 5,
  borderBottom: '1px dashed #666',
});

const CustomArrowDownward = styled(ArrowDownward)({
  fontSize: 18,
  transform: 'rotate(0deg)',
  transition: 'all 0.3s ease',
  '&.open': {
    transform: 'rotate(180deg)',
  },
});

const CustomButton = styled(Button)({
  textTransform: 'lowercase',
  marginTop: 5,
  maxWidth: 150,
  maxHeight: 26,
  borderRadius: 6,
});

const DavReviewDetailPayments: React.FC = () => {
  const { order } = useDavReviewDetail();
  const { checkPermission } = usePermissionRules();
  const [open, setOpen] = useState(false);

  return (
    <>
      {order && (
        <Container>
          <Row onClick={() => setOpen(!open)}>
            <Typography fontSize={20}>pagamentos</Typography>
            <CustomArrowDownward className={open ? 'open' : undefined} />
          </Row>
          <Ul className={open ? 'open' : undefined}>
            {order.pagamento.map(payment => (
              <Li key={payment.id}>
                <div>
                  <Typography>
                    {payment.parcelas}x {payment.forma.toLowerCase()}
                  </Typography>

                  {checkPermission('commercial.review.dav.change_payment') && (
                    <CustomButton disabled color="success" variant="contained">
                      alterar
                    </CustomButton>
                  )}
                </div>
                <Typography color="#008000">{payment.formattedValor}</Typography>
              </Li>
            ))}
          </Ul>
        </Container>
      )}
    </>
  );
};

export default DavReviewDetailPayments;
