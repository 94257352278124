import React, { useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import { Page } from 'types/page';
import PageUpdateActions from './PageUpdateActions';
import PageForm from '../PageForm';
import { useParams } from 'react-router-dom';
import history from 'services/history';

const PageUpdate: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();
  const [page, setPage] = useState<Page>({
    id: 0,
    created_at: '',
    title: '',
    description: '',
    html: '',
    page_id: '',
    keywords: '',
    url: '',
  });
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    api
      .get(`/pages/${id}`)
      .then(response => {
        setPage(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [id]);

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/pages/${id}`, page)
      .then(() => {
        messaging.handleOpen('Salvo');
        history.push('/site/pages');
      })
      .catch(err => {
        messaging.handleOpen(err.response ? err.response.data.error : err.message);
      })
      .finally(() => setSaving(false));
  }

  function handleChange(index: string, value: string) {
    setPage(state => ({
      ...state,
      [index as keyof typeof state]: value,
    }));
  }

  function handleDelete() {
    //
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Site"
        ActionsComponent={
          <PageUpdateActions
            handleSubmit={handleSubmit}
            loading={loading}
            saving={saving}
            handleDelete={handleDelete}
          />
        }
      />
      <PageHeader title="Página sobre" description="Gestão do conteúdo do página sobre" />
      {loading ? (
        <InsideLoading />
      ) : (
        <form onSubmit={handleSubmit}>
          <PageForm page={page} handleChange={handleChange} />
          <button type="submit" style={{ display: 'none' }} />
        </form>
      )}
    </>
  );
};

export default PageUpdate;
