import React from 'react';
import { List } from '@mui/material';
import BranchItemModule from './BranchItemModule';
import { usePagination } from 'hooks/pagination';
import { FinancialCardBranch } from '../../../hooks/useFetchFinancialCard';

type BranchListModuleProps = {
  branches: FinancialCardBranch[];
};

const BranchListModule: React.FC<BranchListModuleProps> = ({ branches }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <List>
      {branches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(branch => (
        <BranchItemModule key={branch.filial} branch={branch} />
      ))}
    </List>
  );
};

export default BranchListModule;
