import React, { useRef } from 'react';
import { Revenue } from 'types/revenue';
import { RevenueValidation } from '../validation/revenueValidation';
import { MenuItem, TextField } from '@mui/material';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';

interface RevenueFormProps {
  revenue: Revenue;
  handleChange(index: keyof Revenue, value: any): void;
  validation: RevenueValidation;
}

const RevenueForm: React.FC<RevenueFormProps> = ({ revenue, handleChange, validation }) => {
  const inputs = {
    nome: useRef<HTMLInputElement>(null),
  };

  return (
    <>
      <SelectOthersBranch
        selectStyle={{ maxHeight: 40 }}
        viewAllBranches={false}
        branch_id={revenue.id_filial}
        handleChange={branchId => handleChange('id_filial', branchId)}
      />

      <TextField
        value={revenue.nome}
        placeholder="Digite o nome"
        label="Nome"
        inputRef={inputs.nome}
        error={!!validation.nome}
        helperText={validation.nome}
        onChange={e => handleChange('nome', e.target.value)}
      />

      <TextField
        select
        value={revenue.tipo_valores}
        placeholder="Digite o tipo do valor"
        label="Tipo valores"
        inputMode="numeric"
        type="number"
        onChange={e => handleChange('tipo_valores', e.target.value)}
      >
        <MenuItem value="N">Negativo</MenuItem>
        <MenuItem value="M">Ambos</MenuItem>
        <MenuItem value="P">Positivo</MenuItem>
      </TextField>

      <TextField
        value={revenue.conta_contabil}
        placeholder="Digite o código contabil"
        label="Cód Contábil"
        onChange={e => handleChange('conta_contabil', e.target.value)}
      />

      <button type="submit" style={{ display: 'none' }}></button>
    </>
  );
};

export default RevenueForm;
