import React, { useRef, useEffect } from 'react';
import { FormControlLabel, MenuItem, Switch, TextField } from '@mui/material';
import { BillSetting } from 'types/billSetting';
import { BillSettingValidation } from './validation/useBillSettingValidation';

interface BillDescriptionFormProps {
  settings: BillSetting;
  handleChange(index: keyof BillSetting, value: any): void;
  validation: BillSettingValidation;
}

const BillDescriptionForm: React.FC<BillDescriptionFormProps> = ({ settings, handleChange, validation }) => {
  const inputs = {
    bank_name: useRef<HTMLInputElement>(null),
    bank_id: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    if (!inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <>
      <TextField
        inputRef={inputs.bank_name}
        error={!!validation.bank_name}
        label="Nome do banco"
        placeholder="Informe o nome do banco"
        helperText={validation.bank_name}
        fullWidth
        margin="normal"
        value={settings.bank_name}
        onChange={e => handleChange('bank_name', e.target.value)}
        autoFocus
        inputProps={{
          inputMode: 'numeric',
        }}
      />

      <TextField
        select
        inputRef={inputs.bank_id}
        error={!!validation.bank_id}
        label="Layout"
        placeholder="Informe a identificação do banco"
        helperText={validation.bank_id}
        fullWidth
        margin="normal"
        value={settings.bank_id}
        onChange={e => handleChange('bank_id', e.target.value)}
        autoFocus
      >
        <MenuItem value="bb_bank">Banco do Brasil</MenuItem>
        <MenuItem value="bradesco_bank">Bradesco</MenuItem>
        <MenuItem value="santander_bank">Santander</MenuItem>
      </TextField>

      <FormControlLabel
        label="Ativo"
        control={
          <Switch
            color="primary"
            onChange={event => handleChange('is_active', event.target.checked)}
            checked={settings.is_active}
          />
        }
      />

      <button style={{ display: 'none' }} type="submit" />
    </>
  );
};

export default BillDescriptionForm;
