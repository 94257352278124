import React, { Dispatch, FormEvent, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button, TextField, styled } from '@mui/material';
import { WasteProductValidation } from './validation/wasteProductValidation';
import { Product } from 'types/product';
import ProductsAutoComplete from './auto-complete/ProductsAutoComplete';
import { useApp } from 'hooks/app';
import { WasteProduct, WasteProductFormContent } from 'pages/material-residues/waste/types/waste';
import { WasteSelectProduct } from './WasteProducts';
import { useMessaging } from 'hooks/messaging';
import WasteProductsModal from './dialog/WasteProductsModal';

const Form = styled('form')(props => ({
  padding: '15px 0',
  display: 'grid',
  gridTemplateColumns: '110px 1fr 120px 120px 120px 75px',
  flex: 0,
  gap: 10,
  alignItems: 'start',
  justifyItems: 'center',
  marginBottom: 30,
  [props.theme.breakpoints.down('md')]: {
    display: 'flex',
    gap: 20,
    flexDirection: 'column',
    marginBottom: 15,
  },
}));

interface WasteProductFormProps {
  allowProduct: boolean;
  filteredProducts: WasteProduct[];
  handleSubmit(data: WasteProductFormContent): Promise<void>;
  validation: WasteProductValidation;
  product: WasteSelectProduct;
  setValidation: Dispatch<SetStateAction<WasteProductValidation>>;
  onChange(index: keyof WasteSelectProduct, value: any): void;
}

const WasteProductForm: React.FC<WasteProductFormProps> = ({
  handleSubmit: defaultHandleSubmit,
  validation,
  filteredProducts,
  onChange,
  product,
  allowProduct,
  setValidation,
}) => {
  const { h2iApi } = useApp();
  const { handleOpen } = useMessaging();
  const [openModal, setOpenModal] = useState(false);

  const inputs = {
    id: useRef<HTMLInputElement>(null),
    name: useRef<HTMLInputElement>(null),
    value: useRef<HTMLInputElement>(null),
    quantity: useRef<HTMLInputElement>(null),
    commission: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    inputs.id.current?.focus();
  }, [allowProduct, inputs.id]);

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    const maxId = !filteredProducts.length
      ? 0
      : filteredProducts.reduce((max, current) => {
          return max.id > current.id ? max : current;
        }, filteredProducts[0]).id;

    defaultHandleSubmit({
      id: maxId + 1,
      product: product.name,
      value: product.value,
      quantity: parseInt(product.quantity),
      productId: parseInt(product.id),
      serial_numbers: product.serialNumbers,
    });
  }

  function handleSelectProduct(product: Product | null) {
    setOpenModal(false);
    onChange('product', product);
    onChange('value', product?.preco_maximo_reee || '');
    onChange('max_value', product?.preco_maximo_reee || '');
    onChange('id', product?.codigo_produto || '');
    onChange('name', product?.produto || '');
    inputs.quantity.current?.focus();
  }

  function handleSearchProduct() {
    if (!h2iApi) {
      return;
    }

    if (!product.id) {
      return;
    }

    h2iApi
      .get('/api/produtoTodos', { params: { id: product.id, reee: 'S' } })
      .then(response => {
        setValidation({});
        if (response.data[0].Mensagem) {
          handleOpen(response.data[0].Mensagem);
          handleSelectProduct(null);
          return;
        }
        const _product = response.data[0];
        onChange('product', _product);
        onChange('name', _product.produto);
        onChange('value', _product.preco_maximo_reee);
        onChange('max_value', _product.preco_maximo_reee);
        inputs.quantity.current?.focus();
      })
      .catch(err => console.error(err));
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'F2') {
      setOpenModal(true);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {openModal && <WasteProductsModal handleSubmit={handleSelectProduct} onClose={() => setOpenModal(false)} />}
      <TextField
        disabled={!allowProduct}
        label="Código"
        placeholder="Código"
        autoFocus
        onKeyDown={handleKeyDown}
        value={product.id}
        onChange={event => onChange('id', event.target.value)}
        inputRef={inputs.id}
        error={!!validation.id}
        helperText={validation.id}
        onBlur={handleSearchProduct}
        fullWidth
      />

      <ProductsAutoComplete
        disabled={!allowProduct}
        label="Pesquisa de produtos"
        onSelect={product => handleSelectProduct(product)}
        placeholder="Digite o nome do produto"
        selected={product.product}
        helperText={validation.product}
        error={!!validation.product}
      />

      <TextField
        disabled={!allowProduct}
        label="Valor R$"
        placeholder="Valor do produto"
        value={product.value}
        aria-valuemax={product.max_value}
        onChange={event => onChange('value', event.target.value)}
        inputRef={inputs.value}
        error={!!validation.value}
        helperText={validation.value}
        type="number"
        inputMode="decimal"
        fullWidth
      />

      <TextField
        disabled={!allowProduct}
        label="Quantidade"
        placeholder="Quantidade do produto"
        value={product.quantity}
        onChange={e => onChange('quantity', parseInt(e.target.value))}
        inputRef={inputs.quantity}
        error={!!validation.quantity}
        helperText={validation.quantity}
        type="number"
        fullWidth
        inputMode="numeric"
      />

      <Button type="submit" disabled={!allowProduct} variant="contained" color="primary">
        Adicionar
      </Button>
    </Form>
  );
};

export default WasteProductForm;
