import React from 'react';
import { Typography } from '@mui/material';
import { useOrdersAuthorization } from '../hooks/useOrdersAuthorization';
import { moneyFormat } from 'helpers/numberFormat';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gap: 10,
  },
  list: {
    display: 'flex',
    paddingBottom: 10,
    flexDirection: 'column',
    borderBottom: '1px solid #dedede',
    '& > a': {
      textAlign: 'end',
      fontSize: 13,
      marginTop: 2,
    },
  },
  value: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const SelectedOrderItems: React.FC = () => {
  const classes = useStyles();
  const { selectedOrder } = useOrdersAuthorization();

  return (
    <div className={classes.container}>
      {selectedOrder?.itens.map((item, index) => (
        <div className={classes.list} key={index}>
          <Typography>COD. {item.cod_prod}</Typography>
          <Typography variant="caption">
            {item.QTD}x {item.produto}
          </Typography>
          <Typography className={classes.value}>
            Valor unitário: <b>{moneyFormat(item.valor_unitario)}</b>
          </Typography>
          <Typography className={classes.value}>
            Valor frete: <b>{moneyFormat(item.valor_frete)}</b>
          </Typography>
          <Typography className={classes.value}>
            Valor total: <b>{moneyFormat(item.valor_total)}</b>
          </Typography>
          <a target="blank" href={`https://www.h2i.com.br/produtos/${item.cod_prod}`}>
            ir até o produto
          </a>
        </div>
      ))}
    </div>
  );
};

export default SelectedOrderItems;
