import React, { MouseEvent } from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { SliderBanner } from 'types/sliderBanner';
import { useSiteBanners } from 'pages/slider-banners/hooks/useSiteBanners';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
});

type SiteBannerItemTableProps = {
  banner: SliderBanner;
};

const SiteBannerItemTable: React.FC<SiteBannerItemTableProps> = ({ banner }) => {
  const { tableTemplate } = useTable();
  const { setSelectedBanner } = useSiteBanners();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedBanner(banner);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <CustomIconButton key={item.id} onClick={handleClick}>
              <MoreHoriz />
            </CustomIconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{banner[item.id]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default SiteBannerItemTable;
