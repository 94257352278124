import { addDays, format, parseISO } from 'date-fns';
import { Rental } from '../types/rental';
import { RentalActions } from './types';
import { useReducer, Dispatch } from 'react';
import { moneyFormat } from 'helpers/numberFormat';
import { ptBR } from 'date-fns/locale';

export const rentalInitialState: Rental = {
  sequence: 1,
  end_at: addDays(new Date(), 7),
  customer_document_number: '',
  customer_name: '',
  active: true,
  created_at: new Date(),
  products: [],
  id: '',
  total: 0,
  commission_total: 0,
  type: 'rental',
  start_at: new Date(),
  paid_at: null,
  financial_data: null,
  external_id: '',
  payments: [],
};

export function rentalReducer(state: Rental, action: RentalActions): Rental {
  switch (action.type) {
    case 'SET_RENTAL': {
      return {
        ...action.rental,
        products: action.rental.products.map(product => ({
          ...product,
          formattedValue: moneyFormat(product.value),
          formattedCommissionValue: moneyFormat(product.commission_value),
        })),
        payments: action.rental.payments.map(product => ({
          ...product,
          formattedValue: moneyFormat(product.value),
          formattedPaidAt: format(parseISO(product.paid_at as any), 'P', { locale: ptBR }),
        })),
        end_at: parseISO(action.rental.end_at as string),
        start_at: parseISO(action.rental.end_at as string),
        paid_at: action.rental.paid_at ? parseISO(action.rental.end_at as string) : null,
      };
    }

    case 'CHANGE_RENTAL': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'ADD_RENTAL_PRODUCT': {
      const products = [
        ...state.products,
        {
          ...action.product,
          external_id: action.product.id,
          quantity: parseInt(action.product.quantity),
          value: parseFloat(action.product.value),
          commission_value: parseFloat(action.product.commission),
          formattedValue: moneyFormat(action.product.value),
          formattedCommissionValue: moneyFormat(action.product.commission),
        },
      ];

      const total = products.reduce((carry, product) => carry + product.quantity * product.value, 0);
      const commission_total = products.reduce((carry, product) => carry + product.commission_value, 0);

      return {
        ...state,
        products,
        total,
        commission_total,
      };
    }

    case 'REMOVE_RENTAL_PRODUCT': {
      const products = state.products.filter(product => product.external_id !== action.externalId);
      const total = products.reduce((carry, product) => carry + product.quantity * product.value, 0);
      const commission_total = products.reduce((carry, product) => carry + product.commission_value, 0);

      return {
        ...state,
        products,
        total,
        commission_total,
      };
    }

    case 'ADD_RENTAL_PAYMENT': {
      const payments = [
        ...state.payments,
        {
          value: parseFloat(action.payment.value),
          external_id: action.payment.externalId,
          paid_at: action.payment.paidAt,
          formattedValue: moneyFormat(action.payment.value),
          formattedPaidAt: format(action.payment.paidAt, 'P', { locale: ptBR }),
        },
      ];

      return {
        ...state,
        payments,
      };
    }

    case 'REMOVE_RENTAL_PAYMENT': {
      const payments = state.payments.filter(payment => payment.external_id !== action.externalId);

      return {
        ...state,
        payments,
      };
    }

    default: {
      return state;
    }
  }
}

type UseRentalReducer = [Rental, Dispatch<RentalActions>];

export function useRentalReducer(): UseRentalReducer {
  const [rental, dispatch] = useReducer(rentalReducer, rentalInitialState);

  return [rental, dispatch];
}
