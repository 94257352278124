import React, { FormEvent, useEffect, useRef } from 'react';
import { Checkbox, FormControlLabel, TextField, styled } from '@mui/material';
import { H2iBuyer } from 'types/h2iBuyer';
import { BuyerValidation } from './validation/BuyerValidation';
import CpfInput from 'components/masked-input/CpfInput';

const Form = styled('form')({
  rowGap: 15,
  paddingTop: 10,
  display: 'flex',
  flexDirection: 'column',
});

interface BuyerFormProps {
  handleValidation(e: FormEvent<HTMLFormElement>): void;
  buyer: H2iBuyer | null;
  handleChange(index: keyof H2iBuyer, value: any): void;
  validation: BuyerValidation;
}

const BuyerForm: React.FC<BuyerFormProps> = ({ buyer, handleChange, handleValidation, validation }) => {
  const inputs = {
    nome: useRef<HTMLInputElement>(null),
    cpf: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Form onSubmit={handleValidation}>
      <TextField
        autoFocus
        ref={inputs.nome}
        value={buyer?.nome}
        onChange={e => handleChange('nome', e.target.value)}
        error={!!validation.nome}
        helperText={validation.nome}
        placeholder="Digite o nome do comprador"
        label="Nome"
      />

      <TextField
        autoFocus
        label="CPF"
        placeholder="CPF"
        fullWidth
        ref={inputs.cpf}
        value={buyer?.cpf}
        onChange={e => handleChange('cpf', e.target.value)}
        error={!!validation.cpf}
        helperText={validation.cpf}
        InputProps={{
          inputComponent: CpfInput as any,
        }}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={buyer?.ativo === 'S'}
            onChange={e => handleChange('ativo', e.target.checked ? 'S' : 'N')}
            color="primary"
          />
        }
        label="Ativo"
      />

      <button type="submit" style={{ display: 'none' }} />
    </Form>
  );
};

export default BuyerForm;
