import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import CourseItemModule from './CourseItemModule';
import { Course } from 'types/course';

type CourseListModuleProps = {
  courses: Course[];
};

const CourseListModule: React.FC<CourseListModuleProps> = ({ courses }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {courses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(course => (
            <CourseItemModule key={course.id} course={course} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default CourseListModule;
