import React from 'react';
import ProductItem from './ProductItem';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { StockBalanceData } from 'types/reports/stockBalance';

type ProductListProps = {
  products: StockBalanceData[];
};

const ProductStockListModule: React.FC<ProductListProps> = ({ products }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => (
            <ProductItem key={product.codigo_produto} product={product} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ProductStockListModule;
