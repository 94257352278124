import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import React, { useState } from 'react';
import { useFetchSidebarFilters } from '../../../components/sidebar-filters/hooks/useFetchSidebarFilters';
import { CommercialBiProvider } from './hooks/useCommercialBi';
import SidebarFilters from '../../../components/sidebar-filters/SidebarFilters';
import { useFetchCommercialBi } from './hooks/useFetchCommercialBi';
import CommercialBiMain from './CommercialBiMain';
import { SidebarFiltersProvider } from 'components/sidebar-filters/hooks/useSidebarFilters';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  background: '#fff',
  padding: 0,
  position: 'relative',
});

export interface CommercialBiMainFilterProps {
  product: string;
  customer: string;
  initialDate: Date;
  finalDate: Date;
  branchId: number;
}

const initialMainFilter: CommercialBiMainFilterProps = {
  product: '',
  customer: '',
  initialDate: new Date(),
  finalDate: new Date(),
  branchId: 0,
};

const CommercialBi: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [filters, isFiltersLoading] = useFetchSidebarFilters();
  const [mainFilter, setMainFilter] = useState<CommercialBiMainFilterProps>(initialMainFilter);
  const { loading: isBiLoading, data } = useFetchCommercialBi();

  function handleMainFilterChange(index: keyof CommercialBiMainFilterProps, value: any) {
    setMainFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <CommercialBiProvider
      value={{
        isFiltersLoading,
        mainFilter,
        handleMainFilterChange,
        isBiLoading,
        data,
      }}
    >
      <SidebarFiltersProvider
        value={{
          filters,
          isSidebarOpen,
          setIsSidebarOpen,
        }}
      >
        <Appbar title="BI Comercial" />
        <Container>
          <SidebarFilters onFilterClick={query => console.log(query)} />
          <CommercialBiMain />
        </Container>
      </SidebarFiltersProvider>
    </CommercialBiProvider>
  );
};

export default CommercialBi;
