import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { GroupedSale } from '../table/GroupedSaleListTable';
import GroupedSaleItemModule from './GroupedSaleItemModule';

type GroupedSaleListModuleProps = {
  groupedSales: GroupedSale[];
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

const GroupedSaleListModule: React.FC<GroupedSaleListModuleProps> = ({ groupedSales }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {groupedSales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(groupedSale => (
        <GroupedSaleItemModule key={groupedSale.pedido_por} groupedSale={groupedSale} />
      ))}
    </CustomList>
  );
};

export default GroupedSaleListModule;
