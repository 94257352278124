import { RentalPaymentFormContent } from 'pages/rentals/types/rental';
import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export type RentalPaymentValidation = {
  description?: string;
  value?: string;
  referenceDate?: string;
};

type UseRentalPaymentValidation = [
  RentalPaymentValidation,
  Dispatch<SetStateAction<RentalPaymentValidation>>,
  (cost: RentalPaymentFormContent) => Promise<void>,
];

export function useRentalPaymentValidation(): UseRentalPaymentValidation {
  async function handleValidation(cost: RentalPaymentFormContent) {
    const schema = yup.object().shape({
      externalId: yup.string().required('a identificação é obrigatória'),
      value: yup.string().required('o valor é obrigatório'),
      paidAt: yup.date().required('a data do pagamento é obrigatória'),
    });

    try {
      await schema.validate(cost);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<RentalPaymentValidation>({});
  return [validation, setValidation, handleValidation];
}
