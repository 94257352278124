import { createContext, useContext } from 'react';
import { Partner } from 'types/partner';

type PartnersContextValue = {
  selectedPartner: Partner | null;
  setSelectedPartner(partner: Partner | null): void;
};

const PartnersContext = createContext<PartnersContextValue>({} as PartnersContextValue);
export const PartnersProvider = PartnersContext.Provider;

export function usePartners(): PartnersContextValue {
  const context = useContext(PartnersContext);
  return context;
}
