import { TableTemplate } from 'types/tableTemplate';

export const templatesTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: '',
    originalId: 'actions',
    width: 60,
    notFilterable: true,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'description',
    description: 'Descrição',
    originalId: 'description',
    width: 200,
  },

  {
    id: 'formattedActive',
    description: 'ATIVO',
    originalId: 'formattedActive',
    width: 100,
  },
  {
    id: 'formattedCreatedAt',
    description: 'DATA',
    originalId: 'formattedCreatedAt',
    width: 150,
    notSearchable: true,
  },
];
