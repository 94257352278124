import { Dispatch, SetStateAction, useState } from 'react';
import { Customer } from 'types/customers';
import * as yup from 'yup';

export type Customervalidation = {
  name?: string;
  about?: string;
  image?: string;
  instagram_profile?: string;
  facebook_profile?: string;
  url?: string;
  phone?: string;
};

type UseCustomervalidation = [
  Customervalidation,
  Dispatch<SetStateAction<Customervalidation>>,
  (customer: Customer) => Promise<void>,
];

export function useCustomervalidation(): UseCustomervalidation {
  async function handleValidation(partner: Customer) {
    const schema = yup.object().shape({
      image: yup.object().required('A imagem é obrigatória'),
      facebook_profile: yup.string().url('Informe uma URL válida').nullable(),
      instagram_profile: yup.string().url('Informe uma URL válida').nullable(),
      url: yup.string().required('A URL é obrigatória'),
      phone: yup.string().required('O telefone é obrigatório'),
      about: yup.string().required('O resumo do parceiro é obrigatório'),
      name: yup.string().required('O nome do parceiro é obrigatório'),
    });

    try {
      await schema.validate(partner);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<Customervalidation>({});
  return [validation, setValidation, handleValidation];
}
