import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { Agent } from 'types/agent';
import { useAgents } from 'pages/agents/hooks/useAgents';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type AgentItemTableProps = {
  agent: Agent;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const AgentItemTable: React.FC<AgentItemTableProps> = ({ agent, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedAgent } = useAgents();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedAgent(agent);
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <Typography variant="body2" key={item.id}>
              {agent[item.id]}
            </Typography>
          ),
        )}
    </>
  );
};

export default AgentItemTable;
