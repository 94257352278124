import { TableTemplate } from 'types/tableTemplate';

export const responsibleStockTableTemplate: TableTemplate[] = [
  {
    id: 'more',
    description: '',
    originalId: 'more',
    width: 40,
  },
  {
    id: 'responsavel',
    description: 'Responsável',
    originalId: 'responsavel',
    width: 270,
  },
  {
    id: 'formattedCustoTotal',
    description: 'Custo Total',
    originalId: 'CustoTotal',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'Estoque',
    description: 'Estoque',
    originalId: 'Estoque',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'Reserva',
    description: 'Reserva',
    originalId: 'Reserva',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'EstTotal',
    description: 'Est Total',
    originalId: 'EstTotal',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'pedido',
    description: 'Pedidos',
    originalId: 'pedido',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'formattedValueOrder',
    description: 'Vl. Pedido',
    originalId: 'valor_pedido',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'formattedValueReserve',
    description: 'Vl. Reserva',
    originalId: 'valor_reserva',
    width: 100,
    dataType: 'number',
  },
];
