import { Print } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

const RentalReportAppbarButtons: React.FC = () => {
  function handleClick() {
    window.print();
  }

  return (
    <div>
      <IconButton onClick={handleClick} color="inherit">
        <Print />
      </IconButton>
    </div>
  );
};

export default RentalReportAppbarButtons;
