import React from 'react';
import { styled } from '@mui/material';

const Container = styled('div')({
  marginTop: 20,
  backgroundColor: '#fff',
  flex: 1,
});

const Row = styled('div')({
  height: 35,
  marginBottom: 7,
});

const UsersTableLoading: React.FC = () => {
  return (
    <Container>
      <Row className="animated-background" />
      <Row className="animated-background" />
      <Row className="animated-background" />
      <Row className="animated-background" />
    </Container>
  );
};

export default UsersTableLoading;
