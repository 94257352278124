import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { moneyFormat } from 'helpers/numberFormat';
import { FinancialResumeFilterProps, Ordination } from '../FinancialResume';
import { FinancialResume, FinancialResumeReport } from 'types/financialResume';
import { GroupedTitle } from 'types/groupedTitle';
import { arraySort } from 'helpers/sort';

export const invoiceTypes = {
  T: 'Título',
  D: 'Despesa',
  R: 'Receita',
  F: 'Folha',
};

type UseFetchFinancialResume = {
  financialResume: FinancialResume[];
  groupedTitles: GroupedTitle[];
  loading: boolean;
  fetch: (initialDate: Date, finalDate: Date) => void;
  report: FinancialResumeReport[];
};

export function useFetchFinancialResume(
  filter: FinancialResumeFilterProps,
  ordination: Ordination,
): UseFetchFinancialResume {
  const [loading, setLoading] = useState(false);
  const [financialResume, setFinancialResume] = useState<FinancialResume[]>([]);
  const [groupedTitles, setGroupedTitles] = useState<GroupedTitle[]>([]);
  const [report, setReport] = useState<FinancialResumeReport[]>([]);
  const { financialApi } = useApp();

  useEffect(() => {
    setReport(state =>
      state.map(item => {
        return {
          ...item,
          items: arraySort(ordination.field, item.items, ordination.direction),
        };
      }),
    );
  }, [ordination]);

  useEffect(() => {
    setGroupedTitles(state =>
      arraySort(ordination.field === 'classificacao' ? 'grupo' : 'valor', state, ordination.direction),
    );
  }, [ordination]);

  const fetch = useCallback(
    (initialDate: Date, finalDate: Date) => {
      if (!financialApi) {
        return;
      }

      const params = {
        data_ini: initialDate ? format(initialDate, 'dd/MM/yyyy') : '',
        data_fim: finalDate ? format(finalDate, 'dd/MM/yyyy') : '',
        id_filial: filter.branchId === 1001 ? '' : filter.branchId,
        tipo: "'T','D','F'",
      };

      setLoading(true);

      const titleClassification = financialApi.get('/getTitulosClassificacao', { params });
      const titleAgroup = financialApi.get('/getTitulosAgrupamento', { params });

      Promise.all([titleClassification, titleAgroup])
        .then(([classificationRes, groupRes]) => {
          if (!classificationRes.data?.titulos) {
            setFinancialResume([]);
            return;
          }
          const _financialResume = classificationRes.data.titulos.map(invoice => ({
            ...invoice,
            formattedValue: moneyFormat(invoice.valor),
            formattedType: invoiceTypes[invoice.tipo],
          }));

          const invoice = _financialResume.filter(item => item.tipo === 'T');
          const revenue = _financialResume.filter(item => item.tipo === 'R');
          const personnel = _financialResume.filter(item => item.tipo === 'F');
          const expense = _financialResume.filter(item => item.tipo === 'D');

          const _report = [
            { title: 'Títulos', type: 'T', items: invoice },
            { title: 'Despesas', type: 'D', items: expense },
            { title: 'Receitas', type: 'R', items: revenue },
            { title: 'Folha de pagamento', type: 'F', items: personnel },
          ]
            .filter(type => type.items.length)
            .map(item => ({
              ...item,
              items: arraySort(ordination.field, item.items, ordination.direction),
            }));

          setReport(_report);
          setFinancialResume(_financialResume);

          if (!groupRes.data?.titulos) {
            setGroupedTitles([]);
            return;
          }

          const _groupedTitles = groupRes.data.titulos.map(invoice => ({
            ...invoice,
            formattedValue: moneyFormat(invoice.valor),
            formattedType: invoiceTypes[invoice.tipo],
          }));

          setGroupedTitles(
            arraySort(ordination.field === 'classificacao' ? 'grupo' : 'valor', _groupedTitles, ordination.direction),
          );
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [filter, financialApi, ordination],
  );

  return {
    financialResume,
    groupedTitles,
    fetch,
    loading,
    report,
  };
}
