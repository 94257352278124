import { Dispatch, SetStateAction, useState } from 'react';
import { Project } from 'types/project';
import * as yup from 'yup';

export type ProjectValidation = {
  customer_name?: string;
  'address.postal_code'?: string;
  'address.street'?: string;
  'address.street_number'?: string;
  'address.neighborhood'?: string;
  'address.city'?: string;
  'address.state'?: string;
  value?: string;
  description?: string;
  name?: string;
};

type UseProjectValidation = [
  ProjectValidation,
  Dispatch<SetStateAction<ProjectValidation>>,
  (project: Project) => Promise<void>,
];

export function useProjectValidation(): UseProjectValidation {
  async function handleValidation(project: Project) {
    const schema = yup.object().shape({
      address: yup.object().shape({
        state: yup.string().nullable(),
        city: yup.string().nullable(),
        neighborhood: yup.string().nullable(),
        street: yup.string().nullable(),
        street_number: yup.string().nullable(),
        postal_code: yup.string().nullable(),
      }),
      value: yup
        .number()
        .typeError('O valor é obrigatório')
        .min(0.1, 'O valor é obrigatório')
        .required('O valor é obrigatório'),
      description: yup.string().required('A descrição é obrigatória'),
      name: yup.string().required('O nome do projeto é obrigatório'),
      customer_name: yup.string().required('o nome do cliente é obrigatório'),
    });

    try {
      await schema.validate(project);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<ProjectValidation>({});
  return [validation, setValidation, handleValidation];
}
