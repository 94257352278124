import React, { useEffect, useState } from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { PurchaseOrderSegment } from 'types/purchaseOrderItem';
import { moneyFormat } from 'helpers/numberFormat';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import NoData from 'components/no-data/NoData';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  background: '#fff',
  overflow: 'auto',
  maxHeight: 400,
});

const Row = styled('li')(({ theme }) => ({
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 6,
  gridTemplateColumns: '2fr 1fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '220px 100px 100px',
  },
  '&.header': {
    '& > span': {
      cursor: 'pointer',
    },
  },
  '& > span': {
    gap: 5,
    display: 'flex',
    alignItems: 'center',
  },
  '& .segment-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
  rowGap: 10,
});

interface TopSegmentsProps {
  data: PurchaseOrderSegment[];
}

const TopSegments: React.FC<TopSegmentsProps> = ({ data }) => {
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<PurchaseOrderSegment[]>([]);

  useEffect(() => {
    setFiltered(
      data.map(item => ({
        ...item,
        formattedQuantity: parseInt(item.qtd_itens),
      })),
    );
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <Container>
      {filtered.length > 0 ? (
        <List>
          <Row className="header">
            <span>
              <Typography onClick={() => handleSort('segmento')} variant="subtitle2">
                Segmento
              </Typography>
              {orderedIndex.index === 'segmento' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('valor')} align="right" variant="subtitle2">
                Valor
              </Typography>
              {orderedIndex.index === 'valor' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('formattedQuantity')} variant="subtitle2" align="right">
                Qtd
              </Typography>
              {orderedIndex.index === 'formattedQuantity' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
          </Row>
          {filtered.map(segment => (
            <Row key={segment.id}>
              <Tooltip title={segment.segmento}>
                <Typography className="segment-name" variant="caption">
                  {segment.segmento}
                </Typography>
              </Tooltip>
              <Typography align="right" variant="caption">
                {moneyFormat(segment.valor)}
              </Typography>
              <Typography align="right" variant="caption">
                {segment.qtd_itens}
              </Typography>
            </Row>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
    </Container>
  );
};

export default TopSegments;
