import { TableTemplate } from 'types/tableTemplate';

export const abcProductTableTemplate: TableTemplate[] = [
  {
    id: 'more',
    description: '',
    originalId: 'more',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'cod',
    description: 'Código',
    originalId: 'cod',
    width: 80,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 250,
  },
  {
    id: 'subgrupo',
    description: 'Subgrupo',
    originalId: 'subgrupo',
    width: 150,
  },
  {
    id: 'formattedCoust',
    description: 'Custo',
    originalId: 'custo',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'qtd',
    description: 'QTD',
    originalId: 'qtd',
    width: 90,
    dataType: 'number',
  },
  {
    id: 'formattedMc',
    description: 'MC',
    originalId: 'mc',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'formattedUnitary',
    description: 'Unitário',
    originalId: 'unitario',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'formattedTotal',
    description: 'Total',
    originalId: 'total',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'formattedBilling',
    description: 'Faturamento',
    originalId: 'billing',
    width: 120,
    dataType: 'number',
  },
];
