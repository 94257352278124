import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material';
import { usePurchaseOrder } from '../../hooks/PurchaseOrderProvider';
import InsideLoading from 'components/loading/InsideLoading';
import TopPayments from '../flow/TopPayments';
import TopOverduePayments from '../flow/TopOverduePayments';
import { addDays, parse, subDays } from 'date-fns';
import CalendarPicker from 'components/pickers/CalendarPicker';
import TopBranchesPayments from './TopBranchesPayments';

const Container = styled('div')(({ theme }) => ({
  padding: 10,
  gap: 10,
  backgroundColor: '#fff',
  display: 'grid',
  gridTemplateColumns: '0.4fr 0.6fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const Content = styled('div')(({ theme }) => ({
  maxWidth: 960,
  margin: '0 auto',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    gridColumn: '1/3',
  },
}));

const PurchaseOrderFlow: React.FC = () => {
  const { payments, overduePayments, branchesPayments, loading } = usePurchaseOrder();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [lastThreeDays, setLastThreeDays] = useState(
    Array.from({ length: 3 }, (_, index) => subDays(currentDate, index + 1)).reverse(),
  );
  const [nextThreeDays, setNextThreeDays] = useState(
    Array.from({ length: 3 }, (_, index) => addDays(currentDate, index + 1)),
  );
  const [range, setRange] = useState({
    startDate: subDays(currentDate, 3),
    endDate: addDays(currentDate, 3),
  });

  const filterByDateRange = useCallback(() => {
    return branchesPayments.filter(invoice => {
      const invoiceDate = parse(invoice.vencimento, 'dd/MM/yyyy', new Date());
      return invoiceDate >= subDays(range.startDate, 1) && invoiceDate <= range.endDate;
    });
  }, [branchesPayments, range]);

  return (
    <Container>
      {loading.paymentFlow ? (
        <InsideLoading />
      ) : (
        <>
          <Content>
            <CalendarPicker
              lastThreeDays={lastThreeDays}
              onChangeRange={range => setRange(range)}
              onChangeLastThreeDays={value => setLastThreeDays(value)}
              onChangeNextThreeDays={value => setNextThreeDays(value)}
              nextThreeDays={nextThreeDays}
              currentDate={currentDate}
              onChange={value => setCurrentDate(value)}
            />
            <TopBranchesPayments lastDate={range.endDate} startDate={range.startDate} data={filterByDateRange()} />
          </Content>
          <TopPayments data={payments} />
          <TopOverduePayments data={overduePayments} />
        </>
      )}
    </Container>
  );
};

export default PurchaseOrderFlow;
