import React from 'react';
import { Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { CommercialBiSegment } from 'pages/bi/commercial/types/commercial-bi';

type CommercialBiSegmentItemTableProps = {
  segment: CommercialBiSegment;
};

const CommercialBiSegmentItemTable: React.FC<CommercialBiSegmentItemTableProps> = ({ segment }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id}>
            <Typography variant="body2">{segment[item.id]}</Typography>
          </div>
        ))}
    </>
  );
};

export default CommercialBiSegmentItemTable;
