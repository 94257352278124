import React, { useEffect, useState } from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { PurchaseOrderBuyer } from 'types/purchaseOrderItem';
import { moneyFormat } from 'helpers/numberFormat';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  maxHeight: 400,
});

const Row = styled('li')({
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 6,
  gridTemplateColumns: '2fr 1fr 1fr',
  '& > span': {
    gap: 5,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  '& > .quantity': {
    justifyContent: 'center',
    textAlign: 'center',
  },
  '& > .numericData': {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  '& .segment-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

const List = styled('ul')({
  display: 'grid',
  rowGap: 10,
});

interface TopBuyersProps {
  data: PurchaseOrderBuyer[];
}

const TopBuyers: React.FC<TopBuyersProps> = ({ data }) => {
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<PurchaseOrderBuyer[]>([]);

  useEffect(() => {
    setFiltered(
      data.map(item => ({
        ...item,
        formattedQuantity: parseInt(item.qtd_itens),
      })),
    );
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <Container>
      <List>
        <Row>
          <span>
            <Typography onClick={() => handleSort('nome')} variant="subtitle2">
              Comprador
            </Typography>
            {orderedIndex.index === 'nome' && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </span>

          <span className="numericData">
            <Typography onClick={() => handleSort('valor')} align="right" variant="subtitle2">
              Compras + Transf
            </Typography>
            {orderedIndex.index === 'valor' && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </span>

          <span className="quantity">
            <Typography onClick={() => handleSort('formattedQuantity')} variant="subtitle2" align="right">
              Qtd pedidos
            </Typography>
            {orderedIndex.index === 'formattedQuantity' && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </span>
        </Row>
        {filtered.map(segment => (
          <Row key={segment.id_comprador}>
            <Tooltip title={segment.nome}>
              <Typography className="segment-name" variant="caption">
                {segment.nome}
              </Typography>
            </Tooltip>
            <Typography className="numericData" align="right" variant="caption">
              {moneyFormat(segment.valor)}
            </Typography>
            <Typography className="quantity" align="right" variant="caption">
              {segment.qtd_itens}
            </Typography>
          </Row>
        ))}
      </List>
    </Container>
  );
};

export default TopBuyers;
