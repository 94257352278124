import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography } from '@mui/material';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import React from 'react';
import TableHeader from 'components/new-table/TableHeader';
import TableRow from 'components/new-table/TableRow';
import { useFinancialSummary } from '../../hooks/useFinancialSummary';
import { SummaryResult } from 'types/summary/summaryResult';
import SummaryItemResult from './SummaryItemResult';
import SummaryResumes from './SummaryResumes';

type SummaryListResultsProps = {
  results: SummaryResult[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const SummaryListResults: React.FC<SummaryListResultsProps> = ({ handleSort, results, orderedIndex }) => {
  const { selectedResult } = useFinancialSummary();
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();

  return (
    <TableContent>
      <TableHeader smtemplatecolumns="50px 120px 120px">
        {tableTemplate.map(item => (
          <div
            className={item.dataType === 'number' ? 'numericData' : undefined}
            key={item.id}
            onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
          >
            <Typography fontSize={16} variant="subtitle2" color="#000">
              {item.description}
            </Typography>
            {orderedIndex.index === item.originalId && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </div>
        ))}
      </TableHeader>
      <TableBody>
        {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(result => (
          <span key={result.id_filial}>
            <TableRow smtemplatecolumns="50px 120px 120px">
              <SummaryItemResult data={result} />
            </TableRow>
            {selectedResult?.id_filial === result.id_filial && <SummaryResumes selectedResult={result} />}
          </span>
        ))}
      </TableBody>
    </TableContent>
  );
};

export default SummaryListResults;
