import React from 'react';
import SidebarItem from '../SidebarItem';
import { Collapse } from '@mui/material';
import { useSidebar } from '../hook/useSidebar';
import { MdBrush, MdChat, MdMonetizationOn } from 'react-icons/md';
import { usePermissionRules } from 'hooks/permissionRules';
import SidebarItemExpendable from '../SidebarItemExpendable';
import { Psychology } from '@mui/icons-material';

const Ai: React.FC = () => {
  const { checkPermission } = usePermissionRules();
  const { handleCollapseClick, collapsible } = useSidebar();

  return (
    <>
      {checkPermission('ai') && (
        <>
          <SidebarItemExpendable
            onClick={() => handleCollapseClick('ai')}
            icon={<Psychology />}
            text="IA"
            opened={collapsible.ai}
          />
          <Collapse in={collapsible.ai}>
            {checkPermission('ai.art') && (
              <SidebarItem to="/ai/art" icon={<MdBrush />} text="Criação de artes gráficas" nested={5} />
            )}
            {checkPermission('ai.chat') && <SidebarItem to="/ai/chat" icon={<MdChat />} text="ChatGPT" nested={5} />}
            {checkPermission('ai.product') && (
              <SidebarItem to="/ai/product" icon={<MdMonetizationOn />} text="Comparativo de preços" nested={5} />
            )}
          </Collapse>
        </>
      )}
    </>
  );
};

export default Ai;
