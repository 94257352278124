import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Done } from '@mui/icons-material';

interface BuyerActionsProps {
  handleValidation(): void;
}

const BuyerActions: React.FC<BuyerActionsProps> = ({ handleValidation }) => {
  return (
    <Tooltip title="Salvar comprador">
      <IconButton onClick={handleValidation} color="inherit">
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default BuyerActions;
