import { TableTemplate } from 'types/tableTemplate';

export const invoicesTableTemplate: TableTemplate[] = [
  {
    id: 'select',
    description: 'Selecionar',
    originalId: 'select',
    width: 40,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 80,
  },
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 250,
  },
  {
    id: 'fornecedor',
    description: 'Fornecedor / Despesas',
    originalId: 'fornecedor',
    width: 250,
  },
  {
    id: 'mes_ano',
    description: 'Mês / Ano',
    originalId: 'mes_ano',
    width: 90,
  },
  {
    id: 'nota_fiscal',
    description: 'NF',
    originalId: 'nota_fiscal',
    width: 80,
  },
  {
    id: 'titulo',
    description: 'Título',
    originalId: 'titulo',
    width: 400,
  },
  {
    id: 'vencimento',
    description: 'Vencimento',
    originalId: 'vencimento',
    width: 120,
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'valor',
    width: 120,
  },
  {
    id: 'conta_totalizadora',
    description: 'Conta totalizadora',
    originalId: 'conta_totalizadora',
    width: 220,
  },
  {
    id: 'formattedType',
    description: 'Tipo',
    originalId: 'formattedType',
    width: 90,
  },
  {
    id: 'boleto',
    description: 'Sem Boleto',
    originalId: 'boleto',
    width: 120,
  },
  {
    id: 'apropriado',
    description: 'Apropriado',
    originalId: 'apropriado',
    width: 120,
  },
  {
    id: 'data_pagamento',
    description: 'Pagamento',
    originalId: 'data_pagamento',
    width: 100,
  },
];
