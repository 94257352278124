import { createContext, useContext } from 'react';
import { Address } from 'types/address';
import { NewResellerDocument, Reseller } from 'types/reseller';

type ResellerContextValue = {
  handleChange(index: keyof Reseller, value: any): void;
  handlePartnerAddressChange(index: keyof Address, value: any): void;
  reseller: Reseller | null;
  sintegraBaRequest(): void;
  isSelfEmployed: boolean;
  handleDeleteDocument(documentId: number): void;
  handleAddDocument(document: NewResellerDocument): void;
};

const ResellerContext = createContext<ResellerContextValue>({} as ResellerContextValue);
export const ResellerProvider = ResellerContext.Provider;

export function useReseller(): ResellerContextValue {
  const context = useContext(ResellerContext);
  return context;
}
