import React from 'react';
import { Grid, TextField } from '@mui/material';
import { useCustomer } from '../../hooks/useCustomer';

const CustomerSeveral: React.FC = () => {
  const { customer, handleChange } = useCustomer();

  return (
    <Grid container spacing={0}>
      <Grid container direction={'row'} spacing={1}>
        <Grid item xs={6} md={12}>
          <TextField
            fullWidth
            label="Prazo Entrega fornecedor"
            placeholder="Prazo Entrega fornecedor"
            margin="normal"
            value={customer?.prazo_fornecedor}
            onChange={e => handleChange('prazo_fornecedor', e.target.value)}
            type="number"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerSeveral;
