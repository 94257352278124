import React, { useState } from 'react';
import { useSelector } from 'store/selector';
import Appbar from 'components/appbar/Appbar';
import FinancialCharts from './FinancialCharts';
import InsideLoading from 'components/loading/InsideLoading';
import FilterActions from 'components/filter-box/FilterActions';
import { useFetchDashboardReport } from './hooks/useFetchDashboard';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import DashboardReportCustomDate from 'components/chart/dashboard/DashboardReportCustomDate';
import DashboardReportDateFilter from 'components/chart/dashboard/DashboardReportDateFilter';
import DashboardReportHeaderButtons from 'components/chart/dashboard/DashboardReportHeaderButtons';

export type FinancialSalesReportTotal = {
  total: number;
};

const DashboardFinancial: React.FC = () => {
  const user = useSelector(state => state.user);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dialogDate, setDialogDate] = useState(false);

  const [
    getDescriptionPageHeader,
    handleDateConfig,
    handleConfirmCustomDates,
    loading,
    dateConfig,
    formattedTotalChart,
    commissionCategory,
    filter,
    handleChangeFilter,
  ] = useFetchDashboardReport();

  return (
    <>
      {dialogDate && (
        <DashboardReportDateFilter
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          handleDateConfig={handleDateConfig}
          dateConfig={dateConfig}
          loading={loading}
          onExited={() => setDialogDate(false)}
          handleConfirmCustomDates={handleConfirmCustomDates}
        />
      )}
      <DashboardReportCustomDate
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        handleConfirmCustomDates={handleConfirmCustomDates}
      />
      <Appbar
        title={`Olá, ${user?.name}`}
        ActionsComponent={<FilterActions handleOpenDialog={() => setDialogDate(true)} />}
      />
      <PageHeaderActions
        title={`Olá, ${user?.name}`}
        description={getDescriptionPageHeader()}
        ActionComponent={
          <DashboardReportHeaderButtons
            filter={filter}
            handleChangeFilter={handleChangeFilter}
            setAnchorEl={setAnchorEl}
            dateConfig={dateConfig}
            handleDateConfig={handleDateConfig}
            loading={loading}
          />
        }
      />
      {loading ? (
        <InsideLoading />
      ) : (
        <FinancialCharts formattedTotalChart={formattedTotalChart} commissionCategory={commissionCategory} />
      )}
    </>
  );
};

export default DashboardFinancial;
