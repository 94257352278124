import React from 'react';
import DialogInput from 'components/dialogs/DialogInput';
import { Button, MenuItem, Select, Typography, styled } from '@mui/material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Search } from '@mui/icons-material';
import SelectBranch from 'components/select-branches/SelectBranch';
import { AbcManufacturersFilterParams } from './CurveAbcManufacturer';
import { useFetchMoreFilters } from 'components/filter-more/hooks/useFetchMoreFilters';
import FilterMoreForm from 'components/filter-more/FilterMoreForm';
import InsideLoading from 'components/loading/InsideLoading';

const Container = styled('div')({
  height: 500,
  display: 'grid',
  gap: 20,
  flex: 1,
});

const Form = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

type AbcManufacturerDateFilterProps = {
  onExited(): void;
  onSearch(): void;
  filter: AbcManufacturersFilterParams;
  handleChangeFilter(index: keyof AbcManufacturersFilterParams, value: any): void;
};

const AbcManufacturerDateFilter: React.FC<AbcManufacturerDateFilterProps> = ({
  onExited,
  onSearch,
  filter,
  handleChangeFilter,
}) => {
  const { units, segments, categories, subCategories, subGroups, manufacturers, loading } = useFetchMoreFilters();

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <Container>
        {loading ? (
          <InsideLoading />
        ) : (
          <Form>
            <FilterMoreForm
              manufacturers={manufacturers}
              categories={categories}
              filter={filter}
              handleChangeFilter={handleChangeFilter}
              segments={segments}
              subCategories={subCategories}
              subGroups={subGroups}
              units={units}
            />

            <Select
              fullWidth
              label="tipo de venda"
              style={{ height: '100%' }}
              value={filter.typeSale}
              onChange={e => handleChangeFilter('typeSale', e.target.value)}
            >
              <MenuItem value="T">Todas</MenuItem>
              <MenuItem value="C">Coligadas</MenuItem>
              <MenuItem value="V">Vendas</MenuItem>
            </Select>

            <SelectBranch
              branch_id={filter.selectedBranchId}
              handleChange={e => handleChangeFilter('selectedBranchId', e)}
            />

            <KeyboardDatePicker
              label="Data inicial"
              value={filter.initialDate}
              onChange={date => handleChangeFilter('initialDate', date)}
            />

            <KeyboardDatePicker
              label="Data Final"
              value={filter.finalDate}
              onChange={date => handleChangeFilter('finalDate', date)}
            />
            <Button type="submit" color="primary" variant="contained" onClick={onSearch}>
              <Typography style={{ marginRight: 5 }} color="inherit">
                Pesquisar
              </Typography>
              <Search fontSize="small" />
            </Button>
          </Form>
        )}
      </Container>
    </DialogInput>
  );
};

export default AbcManufacturerDateFilter;
