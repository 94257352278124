import { styled } from '@mui/material';
import NoData from 'components/no-data/NoData';
import React, { useEffect, useState, useMemo } from 'react';
import { ProductsContextProvider } from './hooks/useProducts';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import ProductListTable from './list/table/ProductListTable';
import ProductListModule from './list/module/ProductListModule';
import ProjectProductForm from './RelatedProductItemForm';
import { useRelatedProductItemValidation } from './validation/relatedProductItemValidation';
import { useRelatedProduct } from '../../hook/useRelatedProduct';
import { RelatedProductItem } from 'pages/related-product/types/relatedProductItem';
import { RelatedProductItemFormContent } from 'pages/related-product/types/relatedProductItemFormContent';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const RelatedProductItems: React.FC = () => {
  const { isMobile, windowWidth } = useApp();
  const { relatedProduct, setRelatedProduct } = useRelatedProduct();
  const [filteredProducts, setFilteredProducts] = useState<RelatedProductItem[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<RelatedProductItem | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const displayMode = useMemo(() => (isMobile || windowWidth < 930 ? 'module' : 'list'), [isMobile, windowWidth]);
  const [validation, setValidation, validate] = useRelatedProductItemValidation();

  useEffect(() => {
    setFilteredProducts(relatedProduct.products);
  }, [relatedProduct.products]);

  function handleSort(index: string) {
    const p = sort(index, filteredProducts);
    setFilteredProducts(p);
  }

  async function handleValidation(data: RelatedProductItemFormContent) {
    setValidation({});

    await validate(data, relatedProduct.products);
    handleSubmit(data);
  }

  function handleSubmit(data: RelatedProductItemFormContent) {
    setRelatedProduct(state => ({
      ...state,
      products: [...state.products, data],
    }));
  }

  return (
    <Container>
      <ProjectProductForm validation={validation} handleSubmit={handleValidation} />

      {filteredProducts.length === 0 ? (
        <NoData message="Nenhum produto para mostrar." />
      ) : (
        <ProductsContextProvider value={{ selectedProduct, setSelectedProduct }}>
          {displayMode === 'list' ? (
            <ProductListTable products={filteredProducts} handleSort={handleSort} orderedIndex={orderedIndex} />
          ) : (
            <ProductListModule products={filteredProducts} />
          )}
        </ProductsContextProvider>
      )}
    </Container>
  );
};

export default RelatedProductItems;
