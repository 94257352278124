import React, { useState } from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import AgentItemModule from './AgentItemModule';
import AgentsListMenu from '../AgentsListMenu';
import { Agent } from 'types/agent';

type AgentListModuleProps = {
  agents: Agent[];
};

const AgentListModule: React.FC<AgentListModuleProps> = ({ agents }) => {
  const { rowsPerPage, page } = usePagination();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <Grid container>
      <AgentsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <Grid item xs={12}>
        <List>
          {agents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(agent => (
            <AgentItemModule setAnchorEl={setAnchorEl} key={agent.id} agent={agent} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default AgentListModule;
