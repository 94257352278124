import React, { useEffect, useMemo, useRef } from 'react';
import { ManagedSale } from '../types/managedSale';
import { FormControlLabel, Switch, TextField, Typography, styled } from '@mui/material';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import { ManagedSaleValidation } from './validation/managedSaleValidation';
import ManagedSaleProducts from './products/ManagedSaleProducts';
import CpfCnpjInput from 'components/masked-input/CpfCnpjInput';
import { moneyFormat } from 'helpers/numberFormat';
import { useManagedSale } from './hooks/useManagedSale';

const Content = styled('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: 30,
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  '& .subtitle': {
    marginBottom: 20,
  },
  '& .total-item': {
    display: 'inline-flex',
    gap: 20,
    justifyContent: 'end',
    alignItems: 'baseline',
    marginBottom: 10,
    '& > span': {
      fontSize: 16,
      fontWeight: '400',
    },
  },
  [props.theme.breakpoints.down('sm')]: {
    padding: 0,
    backgroundColor: 'unset',
    border: 'none',
    marginBottom: 30,
  },
}));

const Container = styled('div')({
  marginBottom: 200,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 1100,
  width: '100%',
  gap: 15,
  margin: '0 auto',
});

const Row = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 15,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: 0,
  },
}));

const SwitchContent = styled('div')({
  marginTop: 20,
});

interface ManagedSaleFormProps {
  handleChange(index: keyof ManagedSale, value: any): void;
  rental: ManagedSale;
  validation: ManagedSaleValidation;
}

const ManagedSaleForm: React.FC<ManagedSaleFormProps> = ({ handleChange, rental, validation }) => {
  const { percentage, setPercentage } = useManagedSale();

  const inputs = {
    customer_name: useRef<HTMLInputElement>(null),
    customer_document_number: useRef<HTMLInputElement>(null),
  };

  const formattedTotal = useMemo(() => moneyFormat(rental.total), [rental.total]);
  const formattedCommissionTotal = useMemo(() => moneyFormat(rental.commission_total), [rental.commission_total]);

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Container>
      <Content>
        <Typography variant="h6" className="subtitle" color="primary">
          Dados do cliente
        </Typography>
        <Row>
          <TextField
            label="Nome do cliente"
            placeholder="Nome do cliente"
            fullWidth
            error={!!validation.customer_name}
            value={rental.customer_name}
            helperText={validation.customer_name}
            onChange={e => handleChange('customer_name', e.target.value)}
            margin="normal"
            inputRef={inputs.customer_name}
            autoFocus
            variant="outlined"
          />

          <TextField
            label="CPF/CNPJ"
            placeholder="CPF ou CNPJ"
            fullWidth
            error={!!validation.customer_document_number}
            value={rental.customer_document_number}
            helperText={validation.customer_document_number}
            onChange={e => handleChange('customer_document_number', e.target.value)}
            margin="normal"
            inputRef={inputs.customer_document_number}
            InputProps={{
              inputComponent: CpfCnpjInput as any,
            }}
          />
        </Row>

        <Row>
          <TextField
            label="Lançamento financeiro"
            placeholder="Lançamento financeiro"
            fullWidth
            value={rental.financial_data}
            onChange={e => handleChange('financial_data', e.target.value)}
            margin="normal"
            autoFocus
          />
          <TextField
            label="Número de identificação"
            placeholder="Número de identificação"
            fullWidth
            value={rental.external_id}
            onChange={e => handleChange('external_id', e.target.value)}
            margin="normal"
            autoFocus
          />
        </Row>

        <Row>
          <DesktopDatePicker
            label="Data do pagamento"
            value={rental.paid_at}
            onChange={date => handleChange('paid_at', date)}
            textFieldProps={{
              margin: 'normal',
            }}
            localeText={{
              clearButtonLabel: 'Limpar',
            }}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
          />
          <TextField
            label="Comissão %"
            placeholder="Percentual da comissão"
            fullWidth
            value={percentage}
            onChange={event => setPercentage(event.target.value)}
            margin="normal"
            autoFocus
          />
        </Row>

        <SwitchContent>
          <FormControlLabel
            label="Ativo"
            control={
              <Switch
                color="primary"
                onChange={event => handleChange('active', event.target.checked)}
                checked={rental.active}
              />
            }
          />
        </SwitchContent>
      </Content>
      <Content>
        <Typography variant="h6" className="subtitle" color="primary">
          Produtos
        </Typography>
        <ManagedSaleProducts />
      </Content>

      <Content>
        <Typography align="left" variant="h6" className="subtitle" color="primary">
          Totais
        </Typography>

        <Typography className="total-item" variant="h5" align="right">
          <span>Produtos</span>
          {formattedTotal}
        </Typography>

        <Typography className="total-item" variant="h5" align="right">
          <span>Comissão</span>
          {formattedCommissionTotal}
        </Typography>
      </Content>
    </Container>
  );
};

export default ManagedSaleForm;
