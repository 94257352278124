import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Agent } from 'types/agent';
import { Document } from 'types/document';
import { AgentApprovalValidation } from '../approval/validation/useAgentApprovalValidation';

export interface AgentApprovalContextValue {
  agent: Agent;
  handleChange(index: keyof Agent, value: any): void;
  letter: Document | null;
  document: Document | null;
  setDocument: Dispatch<SetStateAction<Document | null>>;
  setLetter: Dispatch<SetStateAction<Document | null>>;
  validation: AgentApprovalValidation;
}

const Context = createContext<AgentApprovalContextValue>({} as AgentApprovalContextValue);
export const AgentApprovalProvider = Context.Provider;
export const AgentaApprovalConsumer = Context.Consumer;

export function useAgentApproval(): AgentApprovalContextValue {
  const context = useContext(Context);
  return context;
}
