import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useRentalPayments } from '../hooks/useRentalPayment';
import { useRental } from '../../hooks/useRental';
import { removeRentalPaymentAction } from 'pages/rentals/reducer/actions';

type RentalPaymentListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const RentalPaymentListMenu: React.FC<RentalPaymentListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { dispatch } = useRental();
  const { selectedRentalPayment } = useRentalPayments();

  function handleDeleteClick() {
    if (!selectedRentalPayment) {
      return;
    }

    dispatch(removeRentalPaymentAction(selectedRentalPayment.external_id));
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default RentalPaymentListMenu;
