import React from 'react';
import { Person } from '@mui/icons-material';
import { H2iCustomer } from 'types/h2iCustomer';
import { ListItemButton, styled, Typography } from '@mui/material';

const ListItemStyled = styled(ListItemButton)({
  alignItems: 'center',
  gap: 10,
  justifyContent: 'flex-start',
  '& svg': {
    color: '#ccc',
  },
});

interface ResellerItemProps {
  reseller: H2iCustomer;
  value: H2iCustomer | null;
  handleSelectReseller(reseller: H2iCustomer): void;
}

const ResellerItem: React.FC<ResellerItemProps> = ({ reseller, value, handleSelectReseller }) => {
  return (
    <ListItemStyled disabled={value?.codigo === reseller.codigo} onClick={() => handleSelectReseller(reseller)}>
      <Person />
      <div>
        <Typography>{reseller.nome}</Typography>
        <Typography variant="body2" color="textSecondary">
          {reseller.cnpj_cpf} - {reseller.codigo_vendedor}
        </Typography>
      </div>
    </ListItemStyled>
  );
};

export default ResellerItem;
