import { Dispatch, useReducer } from 'react';
import { Address } from 'types/address';
import { Financial } from 'types/financial';
import { RegisterForm } from 'types/registerForm';
import { RegisterActions } from './types';

const INITIAL_STATE: RegisterForm = {
  address: {} as Address,
  financial: {} as Financial,
  company_name: '',
  document: '',
  email: '',
  phone: '',
  company_trade_name: '',
  ie: '',
  partner_document: '',
  partner_document_copy: null,
  partner_rg: '',
  social_contract_copy: null,
  partner_proof_residence_copy: null,
  partner_address: {} as Address,
  partner_name: '',
  partner_phone: '',
  partner_selfie: null,
  type: 'store',
};

function registerReducer(state = INITIAL_STATE, action: RegisterActions): RegisterForm {
  switch (action.type) {
    case 'REGISTER_CHANGE': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'REGISTER_ADDRESS_CHANGE': {
      return {
        ...state,
        address: {
          ...state.address,
          [action.index]: action.value,
        },
      };
    }

    case 'REGISTER_SET_ADDRESS': {
      return {
        ...state,
        address: action.address,
      };
    }

    case 'REGISTER_SET_PARTNER_ADDRESS': {
      return {
        ...state,
        partner_address: action.address,
      };
    }

    case 'REGISTER_PARTNER_ADDRESS_CHANGE': {
      return {
        ...state,
        partner_address: {
          ...state.partner_address,
          [action.index]: action.value,
        },
      };
    }

    default: {
      return state;
    }
  }
}

type UseRegisterReducerType = [RegisterForm, Dispatch<RegisterActions>];

export function useRegisterReducer(): UseRegisterReducerType {
  const [register, dispatch] = useReducer(registerReducer, INITIAL_STATE);

  return [register, dispatch];
}
