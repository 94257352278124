import { Dispatch, SetStateAction, useState } from 'react';
import { Document } from 'types/document';
import * as yup from 'yup';

export interface AgentDocumentsValidation {
  document?: string;
}

type UseAgentDocumentsValidation = [
  AgentDocumentsValidation,
  Dispatch<SetStateAction<AgentDocumentsValidation>>,
  (document: Document | null) => Promise<void>,
];

export function useAgentDocumentValidation(): UseAgentDocumentsValidation {
  async function handleValidation(document: Document | null) {
    const schema = yup.object().typeError('O documento é obrigatório').required('O documento é obrigatório');

    try {
      await schema.validate(document);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<AgentDocumentsValidation>({});
  return [validation, setValidation, handleValidation];
}
