import React, { useState } from 'react';
import { useDialog } from 'components/dialogs/Dialog';
import { Button } from '@mui/material';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  downloadButton: {
    marginTop: 40,
  },
});

interface ExcelButtonDownloadProps {
  handleDownload(): Promise<void>;
}

const ExcelButtonDownload: React.FC<ExcelButtonDownloadProps> = ({ handleDownload }) => {
  const classes = styles();
  const { handleClose } = useDialog();
  const [downloading, setDownloading] = useState(false);

  function handleClick() {
    setDownloading(true);

    handleDownload()
      .then(handleClose)
      .finally(() => setDownloading(false));
  }

  return (
    <Button
      disabled={downloading}
      onClick={handleClick}
      className={classes.downloadButton}
      size="large"
      color="primary"
      variant="contained"
    >
      Baixar planilha
    </Button>
  );
};

export default ExcelButtonDownload;
