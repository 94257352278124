import { WasteActions } from 'pages/material-residues/waste/reducer/types';
import { Waste } from 'pages/material-residues/waste/types/waste';
import { Dispatch, createContext, useContext } from 'react';

interface WasteContext {
  waste: Waste;
  dispatch: Dispatch<WasteActions>;
  handleChange(index: keyof Waste, value: any): void;
  handleDocumentChange(value: string): void;
  handlePostalCodeChange(value: string): void;
  loadingCustomer: boolean;
  loadingPostalCode: boolean;
}

const Context = createContext<WasteContext>({} as WasteContext);
export const WasteProvider = Context.Provider;

export function useWaste(): WasteContext {
  return useContext(Context);
}
