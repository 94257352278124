import { Menu, MenuItem } from '@mui/material';
import { useProject } from 'pages/projects/registration/hook/useProject';
import { removeProjectAction } from 'pages/projects/registration/reducer/action';
import React, { Dispatch, SetStateAction } from 'react';
import { useProjectActions } from '../hooks/useProjectActions';

type ProjectActionsListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const ProjectActionsListMenu: React.FC<ProjectActionsListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { dispatch } = useProject();
  const { selectedProjectAction } = useProjectActions();

  function handleDeleteClick() {
    if (!selectedProjectAction) {
      return;
    }

    dispatch(removeProjectAction(selectedProjectAction.id));
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default ProjectActionsListMenu;
