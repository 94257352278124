import React, { useState, FormEvent, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import { useMessaging } from 'hooks/messaging';
import { useApp } from 'hooks/app';
import history from 'services/history';
import PageHeader from 'components/page-header/PageHeader';
import { styled } from '@mui/material';
import JustificationNewAppbarButtons from './JustificationNewAppbarButtons';
import Loading from 'components/loading/Loading';
import { Justification } from 'types/justification';
import { JustificationProvider } from '../hooks/useJustification';
import JustificationForm from '../JustificationForm';
import { useJustificationValidation } from '../validation/justificationValidation';
import { useSelector } from 'store/selector';
import { Document } from 'types/document';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Employee } from '../customer-autocomplete/PersonAutocomplete';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 200px)',
  width: '100%',
  padding: 20,
  maxWidth: 500,
  margin: '20px auto',
  border: '1px solid #666',
});

const initial_state: Justification = {
  id: 0,
  tipo: 'J',
  data_ocorrencia: new Date(),
  id_filial: 0,
  funcionario: '',
  justificativa: '',
  cpf: '',
  id_funcionario: 0,
  link_arquivo: '',
  lancada: 'N',
  aceita: 'N',
  ocorrencia: '',
  hora: ``,
};

let timer: NodeJS.Timeout;

const JustificationNew: React.FC = () => {
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const { financialApi } = useApp();
  const user = useSelector(state => state.user);
  const [validation, setValidation, validate] = useJustificationValidation();
  const [justification, setJustification] = useState<Justification>(initial_state);
  const [document, setDocument] = useState<Document | null>(null);
  const [favored, setFavored] = useState<Employee | null>(null);

  useEffect(() => {
    setJustification(value => ({ ...value, cpf: user?.document_number || '' }));
  }, [user]);

  useEffect(() => {
    clearTimeout(timer);
    if (!justification.cpf) {
      setFavored(null);
      return;
    }

    if (justification.cpf.replace(/\D/g, '').length < 11) {
      return;
    }

    if (justification.funcionario) {
      return;
    }

    timer = setTimeout(() => {
      financialApi
        ?.get(`getcadastrofuncionarios?cpf=${justification.cpf}`)
        .then(response => {
          if (response.data[0].MESSAGE) {
            return;
          }
          setJustification(item => ({
            ...item,
            id_funcionario: response.data[0].id,
          }));

          setJustification(item => ({
            ...item,
            id_filial: response.data[0].id_filial,
          }));

          setJustification(item => ({
            ...item,
            funcionario: response.data[0].nome,
          }));

          setFavored(response.data[0]);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    }, 400);
  }, [justification, financialApi]);

  function handleChange(index: keyof Justification, value: any) {
    setValidation({});
    setJustification(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSubmit() {
    const data = {
      tipo: 'J',
      ocorrencia: justification.ocorrencia,
      id_funcionario: justification.id_funcionario,
      justificativa: justification.justificativa,
      data_ocorrencia: format(justification.data_ocorrencia, 'P', { locale: ptBR }),
      link_arquivo: document?.document_url,
      hora: justification.ocorrencia === 'dia_todo' ? '' : justification.hora,
    };

    setSaving(true);

    financialApi
      .post('/saverhocorrencia', data)
      .then(() => {
        history.push('/rh/justification');
      })
      .catch(() => handleOpen('Aconteceu um erro no processamento'))
      .finally(() => setSaving(false));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});

    validate(justification)
      .then(handleSubmit)
      .catch(err => {
        console.log(err);
      });
  }

  return (
    <JustificationProvider
      value={{
        validation,
        handleChange,
        justification,
        document,
        setDocument,
      }}
    >
      {saving && <Loading />}
      {loading && <Loading />}

      <Appbar
        title="Nova justificativa"
        ActionsComponent={<JustificationNewAppbarButtons handleValidation={handleValidation} />}
      />

      <PageHeader title="Folha de justificativa" backUrl="/rh/justification" />
      <Container>
        <form onSubmit={handleValidation}>
          <JustificationForm favored={favored} setFavored={setFavored} />
          <button style={{ display: 'none' }} type="submit"></button>
        </form>
      </Container>
    </JustificationProvider>
  );
};

export default JustificationNew;
