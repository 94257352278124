import { Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import React from 'react';
import { AppropriationDate } from 'types/appropriationDate';

interface AppropriationDateItemTableProps {
  date: AppropriationDate;
}

const AppropriationDateItemTable: React.FC<AppropriationDateItemTableProps> = ({ date }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <Typography variant="body2" key={item.id}>
            {date[item.id]}
          </Typography>
        ))}
    </>
  );
};

export default AppropriationDateItemTable;
