import React, { ForwardRefRenderFunction, forwardRef } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

const mask = (rawValue: string): Array<RegExp | string> => {
  const value = rawValue.replace(/\D/g, '');

  if (value.length <= 10) {
    return [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }

  return [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
};

interface TextMaskCustomProps extends MaskedInputProps {
  inputRef: (ref: HTMLElement | null) => void;
}

const PhoneInput: ForwardRefRenderFunction<MaskedInput, TextMaskCustomProps> = ({ inputRef, ...props }, ref) => {
  return <MaskedInput {...props} ref={ref} mask={mask} placeholderChar={'\u2000'} showMask={false} />;
};

export default forwardRef(PhoneInput);
