import React, { useState, FormEvent } from 'react';
import { Typography, Button, CircularProgress, Theme } from '@mui/material';
import PassowordResetForm from './PassowordResetForm';
import * as yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      backgroundColor: '#fff',
    },
  },
  content: {
    display: 'flex',
    padding: 30,
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: 4,
    minWidth: 400,
    margin: 20,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 300,
      padding: 10,
    },
  },
  header: {
    padding: '40px 0',
  },
  actions: {
    display: 'flex',
    padding: '50px 0',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '& > .login': {
      marginTop: 30,
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
}));

export type PasswordResetValidation = {
  email?: string;
  password?: string;
  password_confirmation?: string;
};

export type ResetData = {
  email: string;
  password: string;
  password_confirmation: string;
};

const PasswordReset: React.FC = () => {
  const classes = useStyles();
  const [validation, setValidation] = useState<PasswordResetValidation>({});
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState<ResetData>({
    email: '',
    password: '',
    password_confirmation: '',
  });
  const messaging = useMessaging();
  const { token } = useParams<{ token: string }>();

  function handleChange(index: 'email' | 'password' | 'password_confirmation', value: string) {
    setReset(oldValue => ({
      ...oldValue,
      [index]: value.trim(),
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    const schema = yup.object().shape({
      email: yup.string().email('Informe um e-mail válido').required('Informe o email'),
      password: yup.string().min(8, 'A senha deve ter no mínimo 8 caracteres').required('Informe a nova senha'),
      password_confirmation: yup.string().oneOf([yup.ref('password'), undefined], 'Nova senha não confere'),
    });

    schema
      .validate(reset)
      .then(() => {
        handleSubmit();
        setValidation({});
      })
      .catch((err: yup.ValidationError) => {
        if (err instanceof yup.ValidationError) {
          setValidation({
            [err.path as string]: err.message,
          });
        }

        throw err;
      });
  }

  function handleSubmit() {
    setLoading(true);

    if (!token) return;

    api
      .post(`/password/reset`, { ...reset, token })
      .then(response => {
        messaging.handleOpen('Nova senha criada');
        setReset({
          email: '',
          password: '',
          password_confirmation: '',
        });
      })
      .catch(err => {
        if (err.response && err.response.data.error) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível criar uma nova senha');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {loading && (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        )}
        <div className={classes.header}>
          <Typography align="center" variant="h6">
            Nova senha
          </Typography>
        </div>
        <form onSubmit={handleValidation}>
          <PassowordResetForm validation={validation} reset={reset} handleChange={handleChange} />
        </form>
        <div className={classes.actions}>
          <Button
            onClick={() => handleValidation()}
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            disabled={loading}
          >
            Confirmar
          </Button>
          <div className="login">
            <Link to="/login">Ir ao Login</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
