import { Done } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';
import React from 'react';
import { useWaste } from '../hooks/useWaste';

interface WasteNewAppButtonsProps {
  handleValidation(): void;
  step: number;
}

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const WasteNewAppbarButtons: React.FC<WasteNewAppButtonsProps> = ({ step, handleValidation }) => {
  const { waste } = useWaste();

  return (
    <>
      {step === 3 && waste.produtos.length > 0 ? (
        <IconButton color="inherit" onClick={handleValidation}>
          <Done />
        </IconButton>
      ) : (
        <CustomIconButton color="inherit" onClick={handleValidation}>
          <Done />
        </CustomIconButton>
      )}
    </>
  );
};

export default WasteNewAppbarButtons;
