import React, { useMemo } from 'react';
import { styled } from '@mui/material';
import { LineAxis } from '@mui/icons-material';
import { moneyFormat } from 'helpers/numberFormat';
import { usePurchaseOrder } from './hooks/PurchaseOrderProvider';

interface BlockProps {
  backgroundColor?: string;
}

const Col1 = styled('div')(({ theme }) => ({
  flex: 1,
  gap: 5,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Row = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  display: 'flex',
  padding: 10,
  columnGap: 10,
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    rowGap: 15,
  },
}));

const Block = styled('div')<BlockProps>(props => ({
  border: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  flex: 1,
  '& .title': {
    background: props.backgroundColor ?? 'transparent',
    fontSize: 12,
    borderBottom: '1px solid #f5f5f5',
    padding: '20px 20px 8px 20px',
  },
  '& .main': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 16,
    [props.theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}));

const PurchaseOrderTotal: React.FC<{
  aging?: boolean;
  segment?: boolean;
  shopping?: boolean;
  product?: boolean;
  quantityItems?: number;
  quantityOrder?: number;
  quantityOrderExpired?: number;
}> = ({
  quantityItems = 0,
  quantityOrder = 0,
  quantityOrderExpired = 0,
  product = false,
  shopping = false,
  aging = false,
  segment = false,
}) => {
  const { totals, ordersSegments, ordersProducts, orders } = usePurchaseOrder();

  const formattedTotal = useMemo(() => {
    if (aging) {
      return totals.formattedAging;
    }
    if (segment) {
      return moneyFormat(ordersSegments.reduce((sum, item) => sum + item.valor, 0));
    }
    if (product) {
      return moneyFormat(ordersProducts.reduce((sum, item) => sum + item.valor_total, 0));
    }
    if (shopping) {
      return moneyFormat(orders.reduce((sum, item) => sum + item.valor_total_pedido, 0));
    }
  }, [aging, ordersSegments, segment, totals, product, ordersProducts, orders, shopping]);
  return (
    <Row>
      <Col1>
        <Block>
          <span className="title">Valor Total</span>
          <div className="main">
            <span className="value">{formattedTotal}</span>
            <LineAxis color="secondary" />
          </div>
        </Block>

        {quantityItems > 0 && (
          <Block>
            <span className="title">Qtd Itens</span>
            <div className="main">
              <span className="value">{quantityItems}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>
        )}

        {quantityOrder > 0 && (
          <Block>
            <span className="title">Qtd Pedido</span>
            <div className="main">
              <span className="value">{quantityOrder}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>
        )}

        {quantityOrderExpired > 0 && (
          <Block>
            <span className="title">Pedidos em atraso</span>
            <div className="main">
              <span className="value">{quantityOrderExpired}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>
        )}
      </Col1>
    </Row>
  );
};

export default PurchaseOrderTotal;
