import { Collapse, List } from '@mui/material';
import { Category, Computer, Shop2 } from '@mui/icons-material';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import People from '@mui/icons-material/People';
import { usePermissionRules } from 'hooks/permissionRules';
import React from 'react';
import { FaBoxes, FaCode } from 'react-icons/fa';
import { MdAttachMoney, MdGrade, MdPermIdentity } from 'react-icons/md';
import { useSidebar } from '../hook/useSidebar';
import SidebarItem from '../SidebarItem';
import SidebarItemExpendable from '../SidebarItemExpendable';

const Registration: React.FC = () => {
  const { checkPermission } = usePermissionRules();
  const { handleCollapseClick, collapsible } = useSidebar();

  return (
    <>
      {checkPermission('registrations') && (
        <>
          <SidebarItemExpendable
            onClick={() => handleCollapseClick('registration')}
            icon={<LibraryBooks />}
            text="Cadastros"
            opened={collapsible.registration}
          />
          <Collapse in={collapsible.registration}>
            {checkPermission('registrations_product') && (
              <SidebarItem to="/products" icon={<FaBoxes />} text="Produtos" nested={5} />
            )}
            {checkPermission('registrations.price-protection') && (
              <SidebarItem to="/price-protection" icon={<MdAttachMoney />} text="Price Protection" nested={5} />
            )}
            {checkPermission('registrations.customers') && (
              <SidebarItem to="/customers" icon={<MdGrade />} text="Favorecidos" nested={5} />
            )}
            {checkPermission('registrations.buyers') && (
              <SidebarItem to="/buyers" icon={<Shop2 />} text="Comprador" nested={5} />
            )}
            {checkPermission('registrations.cfop') && (
              <SidebarItem to="/cfop" icon={<FaCode />} text="CFOP" nested={5} />
            )}
            {checkPermission('registrations.ncm') && (
              <SidebarItem to="/ncm" icon={<MdPermIdentity />} text="NCM" nested={5} />
            )}

            {checkPermission('registrations.categories') && (
              <SidebarItem to="/categories" icon={<Category />} text="Categorias" nested={5} />
            )}

            {checkPermission('registrations_product') && (
              <SidebarItem to="/compatibilities" icon={<MdGrade />} text="Compatibilidades" nested={5} />
            )}

            {checkPermission('registrations_product') && (
              <SidebarItem to="/related-products" icon={<MdGrade />} text="Produtos similares" nested={5} />
            )}

            {checkPermission('registrations.site') && (
              <>
                <SidebarItemExpendable
                  onClick={() => handleCollapseClick('site', false)}
                  icon={<Computer />}
                  text="h2i.com.br"
                  opened={collapsible.site}
                  nested={5}
                />
                <Collapse in={collapsible.site}>
                  <List disablePadding>
                    <SidebarItemExpendable
                      onClick={() => handleCollapseClick('customers', false)}
                      icon={<LibraryBooks />}
                      text="Novos clientes"
                      opened={collapsible.customers}
                      nested={8}
                    />
                    <Collapse in={collapsible.customers}>
                      {checkPermission('registrations.resellers.waiting_approvement') && (
                        <SidebarItem to="/resellers" icon={<People />} text="Aguardando aprovação" nested={12} />
                      )}
                      {checkPermission('registrations.resellers.approved') && (
                        <SidebarItem to="/approved-resellers" icon={<People />} text="Aprovados" nested={12} />
                      )}
                      {checkPermission('registrations.resellers.rejected') && (
                        <SidebarItem to="/rejected-resellers" icon={<People />} text="Rejeitados" nested={12} />
                      )}
                    </Collapse>
                    <SidebarItemExpendable
                      onClick={() => handleCollapseClick('updatedCustomers', false)}
                      icon={<LibraryBooks />}
                      text="Atualização cadastral"
                      opened={collapsible.updatedCustomers}
                      nested={8}
                    />
                    <Collapse in={collapsible.updatedCustomers}>
                      {checkPermission('registrations.resellers.waiting_approvement') && (
                        <SidebarItem
                          to="/updated-resellers"
                          icon={<People />}
                          text="Aguardando aprovação"
                          nested={12}
                        />
                      )}
                      {checkPermission('registrations.resellers.approved') && (
                        <SidebarItem to="/updated-approved-resellers" icon={<People />} text="Aprovados" nested={12} />
                      )}
                      {checkPermission('registrations.resellers.rejected') && (
                        <SidebarItem to="/updated-rejected-resellers" icon={<People />} text="Rejeitados" nested={12} />
                      )}
                    </Collapse>
                    <SidebarItemExpendable
                      onClick={() => handleCollapseClick('creditAnalysis', false)}
                      icon={<LibraryBooks />}
                      text="Análise de crédito"
                      opened={collapsible.creditAnalysis}
                      nested={8}
                    />
                    <Collapse in={collapsible.creditAnalysis}>
                      <SidebarItem
                        to="/completed-credit-requests"
                        icon={<People />}
                        text="Aguardando análise"
                        nested={12}
                      />
                      <SidebarItem to="/analysed-credit-requests" icon={<People />} text="Analisados" nested={12} />
                    </Collapse>
                    {checkPermission('registrations.agents') && (
                      <SidebarItem to="/agents" icon={<MdGrade />} text="Prepostos" nested={8} />
                    )}
                  </List>
                </Collapse>
              </>
            )}
          </Collapse>
        </>
      )}
    </>
  );
};

export default Registration;
