import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { TransferAccount } from 'types/transferAccount';
import { TransferAccountFilterProps } from '../TransferAccountPage';
import { TotalizingAccount } from 'types/totalizingAccount';

interface TransferAccountContextValue {
  filter: TransferAccountFilterProps;
  handleChangeFilter(index: keyof TransferAccountFilterProps, value: any): void;
  setIsReportOpen: Dispatch<SetStateAction<boolean>>;
  handleSearch(): void;
  loading: boolean;
  accounts: TransferAccount[];
  setIsNewTransferAccount: Dispatch<SetStateAction<boolean>>;
  setSelectedTransferAccount: Dispatch<SetStateAction<TransferAccount | null>>;
  totalizingAccounts: TotalizingAccount[];
}

const TransferAccountContext = createContext<TransferAccountContextValue>({} as TransferAccountContextValue);
export const TransferAccountConsumer = TransferAccountContext.Consumer;
export const TransferAccountProvider = TransferAccountContext.Provider;

export function useTransferAccounts() {
  return useContext(TransferAccountContext);
}
