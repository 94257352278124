import { useApp } from 'hooks/app';
import { useCallback, useState } from 'react';
import { Invoice } from 'types/invoices';
import { format } from 'date-fns';
import { moneyFormat } from 'helpers/numberFormat';
import { FinancialSummaryFilterParams } from '../FinancialSummary';
import { invoiceTypes } from 'pages/moviment/query/Invoices';

type UseFetchFinancialSummary = [Invoice[], boolean, () => void];

export function useFetchFinancialSummary(filter: FinancialSummaryFilterParams): UseFetchFinancialSummary {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const { financialApi } = useApp();

  const fetch = useCallback(() => {
    if (!financialApi) {
      return;
    }

    setLoading(true);
    const titles = financialApi.get('/getTitulos', {
      params: {
        data_ini: filter.initial_date ? format(filter.initial_date, 'dd/MM/yyyy') : '',
        data_fim: filter.final_date ? format(filter.final_date, 'dd/MM/yyyy') : '',
        id_filial: filter.branchIds.join(','),
        tipo: '',
        titulo: '',
        mes_ano: filter.month_year ? format(filter.month_year, 'MMyy') : '',
        empresa: filter.company === 'all' ? '' : filter.company,
        fornecedor: '',
        numero_nf: '',
        data_apropriacao: '',
      },
    });

    Promise.all([titles])
      .then(([titlesRes]) => {
        if (titlesRes.data.RESULT) {
          setInvoices([]);
          return;
        }
        setInvoices(
          titlesRes.data.titulos.map(invoice => ({
            ...invoice,
            boleto: invoice.boleto === 'N' ? 'Não' : 'Sim',
            formattedType: invoiceTypes[invoice.tipo],
            formattedValue: moneyFormat(invoice.valor),
          })),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, financialApi]);

  return [invoices, loading, fetch];
}
