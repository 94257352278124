import { TableTemplate } from 'types/tableTemplate';

export const priceProtectionTableTemplate: TableTemplate[] = [
  {
    id: 'data_lanc',
    description: 'Data Lanc',
    originalId: 'data_lanc',
    width: 80,
  },
  {
    id: 'vencimento',
    description: 'Data Venc',
    originalId: 'vencimento',
    width: 80,
  },
  {
    id: 'id_produto',
    description: 'Cod Prod',
    originalId: 'id_produto',
    width: 60,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 450,
  },
  {
    id: 'estoque',
    description: 'Estoque',
    originalId: 'estoque',
    width: 80,
  },
  {
    id: 'formattedValue',
    description: 'Price Protection',
    originalId: 'valor',
    width: 120,
  },
  {
    id: 'formattedCoust',
    description: 'Custo Atual',
    originalId: 'formattedCoust',
    width: 80,
  },
  {
    id: 'formattedNewCoust',
    description: 'Novo Custo',
    originalId: 'formattedNewCoust',
    width: 80,
  },
  {
    id: 'fabricante',
    description: 'Fabricante',
    originalId: 'fabricante',
    width: 110,
  },
  {
    id: 'usuario',
    description: 'Usuário',
    originalId: 'usuario',
    width: 160,
  },
];
