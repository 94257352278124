import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { PartnerValidation } from './validation/partnerValidation';
import ImageSelector from 'components/image-selector/ImageSelector';
import ImagePreview from 'components/image-preview/ImagePreview';
import PartnerImageForm from './PartnerImageForm';
import { Partner } from 'types/partner';

type PartnerFormProps = {
  partner: Partner;
  handleChange(index: keyof Partner, value: any): void;
  validation: PartnerValidation;
};

const SiteBannerForm: React.FC<PartnerFormProps> = ({ partner, handleChange, validation }) => {
  const [imageSelector, setImageSelector] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<null | string>('');

  const inputs = {
    name: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      {!!selectedImageSrc && (
        <ImagePreview
          src={selectedImageSrc}
          description="Imagem da mensagem de e-mail"
          onExited={() => setSelectedImageSrc('')}
        />
      )}
      {imageSelector && (
        <ImageSelector
          handleSetImageId={image => handleChange('image', image)}
          onExited={() => setImageSelector(false)}
        />
      )}
      <Grid item xs={12} xl={4} lg={4} md={6}>
        <TextField
          inputRef={inputs.name}
          error={!!validation.name}
          helperText={validation.name}
          label="Nome"
          placeholder="Digite um nome para o banner"
          margin="normal"
          fullWidth
          value={partner.name}
          onChange={e => handleChange('name', e.target.value)}
          autoFocus
        />

        <PartnerImageForm validation={validation} partner={partner} handleChange={handleChange} />
        <button type="submit" style={{ display: 'none' }} />
      </Grid>
    </>
  );
};

export default SiteBannerForm;
