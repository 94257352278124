import React, { useState } from 'react';
import { Popover, styled } from '@mui/material';
import MobileDatePicker from 'components/pickers/MobileDatePicker';
import { endOfDay, startOfDay } from 'date-fns';
import { useInputDialog } from 'components/dialogs/DialogInput';

const Paper = styled(Popover)(({ theme }) => ({
  '& > div': {
    padding: '10px 20px 20px',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

type SalesReportCustomDateProps = {
  onClose(): void;
  anchorEl: HTMLButtonElement | null;
  handleConfirmCustomDates(initialDate: Date, finalDate: Date): void;
};

const SalesReportCustomDate: React.FC<SalesReportCustomDateProps> = ({
  anchorEl,
  handleConfirmCustomDates,
  onClose,
}) => {
  const [initialDate, setInitialDate] = useState<Date | null>(null);
  const [finalDate, setFinalDate] = useState<Date | null>(null);
  const dialogInput = useInputDialog();

  function handleSetFinalDate(_finalDate: Date | null) {
    setFinalDate(_finalDate);
    if (initialDate && _finalDate) {
      handleConfirmCustomDates(initialDate, _finalDate);
      onClose();
      if (dialogInput.handleClose) dialogInput.handleClose();
    }
  }

  function handleSetInitialDate(_initialDate: Date | null) {
    setInitialDate(_initialDate);
  }

  return (
    <Paper
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <MobileDatePicker
        value={initialDate}
        onChange={date => (date ? handleSetInitialDate(startOfDay(date)) : handleSetInitialDate(null))}
        label="Data inicial"
        textFieldProps={{ required: true }}
      />
      <MobileDatePicker
        value={finalDate}
        onChange={date => (date ? handleSetFinalDate(endOfDay(date)) : handleSetFinalDate(null))}
        label="Data final"
        textFieldProps={{ required: true }}
      />
    </Paper>
  );
};

export default SalesReportCustomDate;
