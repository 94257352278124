import React from 'react';
import BranchItemModule from './BranchItemModule';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { BranchStockBalanceData } from 'types/reports/stockBalance';

type BranchListModuleProps = {
  branches: BranchStockBalanceData[];
};

const BranchListModule: React.FC<BranchListModuleProps> = ({ branches }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {branches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(branch => (
            <BranchItemModule key={branch.id_filial} branch={branch} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default BranchListModule;
