import { IconButton, ListItemButton, Typography, styled } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { MouseEvent, useState, Dispatch, SetStateAction } from 'react';
import { useProjectActions } from '../../hooks/useProjectActions';
import { ProjectAction } from 'types/project';

const IconButtonStyled = styled(IconButton)({
  position: 'absolute',
  bottom: 0,
  right: 0,
});

const ListItem = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 120,
  flexDirection: 'column',
  '& .data': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

type ActionItemModuleProps = {
  action: ProjectAction;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const ActionItemModule: React.FC<ActionItemModuleProps> = ({ action, setAnchorEl }) => {
  const { setSelectedProjectAction } = useProjectActions();
  const [collapse, setCollapse] = useState(false);

  function handleClick() {
    setCollapse(!collapse);
    setSelectedProjectAction(action);
  }

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    setSelectedProjectAction(action);
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  }

  return (
    <>
      <ListItem onClick={() => handleClick()}>
        <div className="data">
          <Typography variant="body2" color="textSecondary">
            {action.full_name}
          </Typography>
          <Typography variant="body2" className="product-name">
            {action.observation}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {action.formattedReferenceDate}
          </Typography>
        </div>

        <IconButtonStyled onClick={e => handleMoreClick(e)}>
          <MoreVert />
        </IconButtonStyled>
      </ListItem>
    </>
  );
};

export default ActionItemModule;
