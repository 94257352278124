import React from 'react';
import { Typography } from '@mui/material';
import { SubgroupStockBalanceData } from 'types/reports/stockBalance';
import { subgroupStockTableTemplate } from '../subgroupStockTableTemplate';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  quantityData: {
    display: 'flex',
    justifyContent: 'center',
  },
});

type SubgroupItemTableProps = {
  subgroup: SubgroupStockBalanceData;
};

const SubgroupItemTable: React.FC<SubgroupItemTableProps> = ({ subgroup }) => {
  const classes = styles();

  return (
    <>
      {subgroupStockTableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div
            key={item.id}
            className={
              item.dataType === 'number'
                ? classes.numericData
                : item.dataType === 'quantity'
                ? classes.quantityData
                : undefined
            }
          >
            <Typography style={{ fontSize: 11 }} variant="body2">
              {subgroup[item.id as keyof SubgroupStockBalanceData]}
            </Typography>
          </div>
        ))}
    </>
  );
};

export default SubgroupItemTable;
