import React, { Dispatch, SetStateAction, useState } from 'react';
import List from 'components/list/List';
import ProductsListMenu from '../ProductsListMenu';
import ProductItemModule from './ProductItemModule';
import { PurchaseOrderProduct } from 'types/purchaseOrder';

type ProductListModuleProps = {
  disabled: boolean;
  type: 'update' | 'new';
  products: PurchaseOrderProduct[];
  selectedProduct: PurchaseOrderProduct | null;
  setSelectedProduct: Dispatch<SetStateAction<PurchaseOrderProduct | null>>;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
  anchorEl: null | HTMLButtonElement;
};

const ProductListModule: React.FC<ProductListModuleProps> = ({
  products,
  type,
  setSelectedProduct,
  setAnchorEl,
  anchorEl,
  selectedProduct,
  disabled,
}) => {
  const [index, setIndex] = useState(-1);

  function handleClick(product: PurchaseOrderProduct, index: number) {
    setIndex(index);
    setSelectedProduct(product);
  }

  function onExited() {
    setSelectedProduct(null);
    setIndex(-1);
    setAnchorEl(null);
  }

  return (
    <List>
      {selectedProduct && (
        <ProductsListMenu
          index={index}
          type={type}
          disabled={disabled}
          selectedProduct={selectedProduct}
          anchorEl={anchorEl}
          onExited={onExited}
        />
      )}

      {products.map((product, index) => (
        <ProductItemModule
          disabled={disabled}
          setAnchorEl={setAnchorEl}
          handleClick={() => handleClick(product, index)}
          key={index}
          product={product}
        />
      ))}
    </List>
  );
};

export default ProductListModule;
