import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, Typography, ListItemButton, styled } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { CourseStudentOnList } from 'pages/course-students/types/courseStudent';
import { useCourseStudents } from 'pages/course-students/hooks/useCourseStudents';

const ListItemButtonStyled = styled(ListItemButton)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '80%',
    alignItems: 'center',
  },
  '& .project-id': {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  '& .description': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    lineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
});

const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  bottom: 5,
  right: 5,
});

type CourseStudentItemModuleProps = {
  courseStudent: CourseStudentOnList;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const CourseStudentItemModule: React.FC<CourseStudentItemModuleProps> = ({ courseStudent, setAnchorEl }) => {
  const { setSelectedCourseStudent } = useCourseStudents();

  function handleClick() {
    setSelectedCourseStudent(courseStudent);
  }

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    setSelectedCourseStudent(courseStudent);
  }

  return (
    <ListItemButtonStyled onClick={() => handleClick()}>
      <Typography gutterBottom>{courseStudent.student_name}</Typography>

      <Typography>{courseStudent.student_document_number}</Typography>

      <StyledIconButton onClick={handleMoreClick}>
        <MoreVert />
      </StyledIconButton>
    </ListItemButtonStyled>
  );
};

export default CourseStudentItemModule;
