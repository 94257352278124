import { TableTemplate } from 'types/tableTemplate';

export const categoryTableTemplate: TableTemplate[] = [
  {
    id: 'more',
    description: '',
    originalId: 'more',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 100,
  },
  {
    id: 'categoria',
    description: 'Categoria',
    originalId: 'categoria',
    width: 200,
  },
];
