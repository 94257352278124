import React from 'react';
import {
  AllInbox,
  Assessment,
  Calculate,
  CalendarMonth,
  Class,
  CreditCard,
  Favorite,
  MoneyOff,
  Moving,
  Newspaper,
  ReceiptLong,
  Savings,
  Settings,
  Summarize,
  SwitchCamera,
  ThumbDown,
  Title,
} from '@mui/icons-material';
import { Typography, styled } from '@mui/material';
import { usePermissionRules } from 'hooks/permissionRules';

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const Box = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 5,
  flexGrow: 'auto',
  maxWidth: 80,
  width: '100%',
});

const Icon = styled('div')({
  display: 'flex',
  borderRadius: '100%',
  alignItems: 'center',
  padding: 10,
  width: 70,
  height: 70,
  justifyContent: 'center',
});

const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
});

const SectionItem = styled('div')(({ theme }) => ({
  gap: 10,
  paddingBottom: 10,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'end',
  borderBottom: '1px solid #666',
  '&.primary-main': {
    '& > div > div': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
  },
  '&.purple': {
    '& > div > div': {
      border: `1px solid purple`,
      color: 'purple',
    },
  },
  '&.brown': {
    '& > div > div': {
      border: `1px solid brown`,
      color: 'brown',
    },
  },
  '&.success': {
    '& > div > div': {
      border: `1px solid ${theme.palette.success.dark}`,
      color: theme.palette.success.dark,
    },
  },
  '&.error': {
    '& > div > div': {
      border: `1px solid ${theme.palette.error.light}`,
      color: theme.palette.error.light,
    },
  },
}));

interface FinancialHomeProps {
  handleChange(value: string): void;
}

const FinancialHome: React.FC<FinancialHomeProps> = ({ handleChange }) => {
  const { checkPermission } = usePermissionRules();

  return (
    <Content>
      <ContentWrapper>
        <SectionItem className="primary-main">
          {checkPermission('financial.moviment.extract') && (
            <Box onClick={() => handleChange('moviment_extract')} className="content">
              <Typography variant="body1" fontSize={12} fontWeight={600}>
                Extrato
              </Typography>
              <Icon>
                <Assessment />
              </Icon>
            </Box>
          )}

          {checkPermission('financial.moviment.transfer-account') && (
            <Box onClick={() => handleChange('moviment_transfer-account')} className="content">
              <Typography variant="body1" fontSize={12} fontWeight={600}>
                Transferência
              </Typography>
              <Icon>
                <SwitchCamera />
              </Icon>
            </Box>
          )}
        </SectionItem>

        <Typography align="center" fontWeight={600} variant="body2" fontSize={14}>
          Cadastro
        </Typography>
        <SectionItem className="success">
          {checkPermission('financial.appropriation-date') && (
            <Box onClick={() => handleChange('register_appropriation-date')} className="content">
              <Typography variant="body1" fontSize={12} fontWeight={600}>
                Dt. Aprop.
              </Typography>
              <Icon>
                <CalendarMonth />
              </Icon>
            </Box>
          )}

          {checkPermission('financial.revenues') && (
            <Box onClick={() => handleChange('register_revenues')} className="content">
              <Typography variant="body1" fontSize={12} fontWeight={600}>
                Receitas
              </Typography>
              <Icon>
                <Savings />
              </Icon>
            </Box>
          )}

          {checkPermission('financial.expenses') && (
            <Box onClick={() => handleChange('register_expenses')} className="content">
              <Typography variant="body1" fontSize={12} fontWeight={600}>
                Despesas
              </Typography>
              <Icon>
                <MoneyOff />
              </Icon>
            </Box>
          )}

          {checkPermission('financial.totalizing_account') && (
            <Box onClick={() => handleChange('totalizing_account')} className="content">
              <Typography variant="body1" fontSize={12} fontWeight={600}>
                Conta
              </Typography>
              <Icon>
                <Calculate />
              </Icon>
            </Box>
          )}
        </SectionItem>

        <Typography align="center" fontWeight={600} variant="body2" fontSize={14}>
          Movimentação
        </Typography>
        <SectionItem className="error">
          {checkPermission('financial.moviment.titles') && (
            <Box onClick={() => handleChange('moviment_titles')} className="content">
              <Typography variant="body1" fontSize={12} fontWeight={600}>
                Títulos
              </Typography>
              <Icon>
                <Title />
              </Icon>
            </Box>
          )}

          {checkPermission('financial.moviment.revenues') && (
            <Box onClick={() => handleChange('moviment_revenues')} className="content">
              <Typography variant="body1" fontSize={12} fontWeight={600}>
                Receitas
              </Typography>
              <Icon>
                <Favorite />
              </Icon>
            </Box>
          )}

          {checkPermission('financial.moviment.expenses') && (
            <Box onClick={() => handleChange('moviment_expenses')} className="content">
              <Typography variant="body1" fontSize={12} fontWeight={600}>
                Despesas
              </Typography>
              <Icon>
                <ThumbDown />
              </Icon>
            </Box>
          )}

          {checkPermission('financial.moviment.fp') && (
            <Box onClick={() => handleChange('moviment_fp')} className="content">
              <Typography variant="body1" fontSize={12} fontWeight={600}>
                FP
              </Typography>
              <Icon>
                <Newspaper />
              </Icon>
            </Box>
          )}
        </SectionItem>

        <Typography align="center" fontWeight={600} variant="body2" fontSize={14}>
          Relatórios
        </Typography>
        <SectionItem className="purple">
          {checkPermission('reports') && (
            <>
              <Box onClick={() => handleChange('report_ticket')} className="content">
                <Typography variant="body1" fontSize={12} fontWeight={600}>
                  Boleto
                </Typography>
                <Icon>
                  <ReceiptLong />
                </Icon>
              </Box>

              <Box onClick={() => handleChange('report_card')} className="content">
                <Typography variant="body1" fontSize={12} fontWeight={600}>
                  Cartão
                </Typography>
                <Icon>
                  <CreditCard />
                </Icon>
              </Box>

              {checkPermission('financial.report.classification') && (
                <Box onClick={() => handleChange('report_classification')} className="content">
                  <Typography variant="body1" fontSize={12} fontWeight={600}>
                    Classificação
                  </Typography>
                  <Icon>
                    <Class />
                  </Icon>
                </Box>
              )}
              {checkPermission('financial.report.query') && (
                <Box onClick={() => handleChange('report_query')} className="content">
                  <Typography variant="body1" fontSize={12} fontWeight={600}>
                    Mov finan
                  </Typography>
                  <Icon>
                    <Moving />
                  </Icon>
                </Box>
              )}
            </>
          )}
          {checkPermission('financial.resume') && (
            <Box onClick={() => handleChange('resume')} className="content">
              <Typography variant="body1" fontSize={12} fontWeight={600}>
                Resumo
              </Typography>
              <Icon>
                <Summarize />
              </Icon>
            </Box>
          )}
        </SectionItem>

        <SectionItem className="brown">
          {checkPermission('financial.charge.bills') && (
            <Box onClick={() => handleChange('charge_bills')} className="content">
              <Typography variant="body1" fontSize={12} fontWeight={600}>
                Remessa
              </Typography>
              <Icon>
                <AllInbox />
              </Icon>
            </Box>
          )}

          {checkPermission('financial.charge.bill_settings') && (
            <Box onClick={() => handleChange('charge_settings')} className="content">
              <Typography variant="body1" fontSize={12} fontWeight={600}>
                Configurações
              </Typography>
              <Icon>
                <Settings />
              </Icon>
            </Box>
          )}
        </SectionItem>
      </ContentWrapper>
    </Content>
  );
};

export default FinancialHome;
