import { Dispatch, SetStateAction, useState } from 'react';
import { ProjectCostFormContent } from 'types/project';
import * as yup from 'yup';

export type ProjectCostValidation = {
  description?: string;
  value?: string;
  referenceDate?: string;
};

type UseProjectCostValidation = [
  ProjectCostValidation,
  Dispatch<SetStateAction<ProjectCostValidation>>,
  (cost: ProjectCostFormContent) => Promise<void>,
];

export function useProjectCostValidation(): UseProjectCostValidation {
  async function handleValidation(cost: ProjectCostFormContent) {
    const schema = yup.object().shape({
      description: yup.string().required('a descrição é obrigatória'),
      value: yup.string().required('o valor é obrigatório'),
      referenceDate: yup.date().required('a data de referência é obrigatória'),
    });

    try {
      await schema.validate(cost);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<ProjectCostValidation>({});
  return [validation, setValidation, handleValidation];
}
