import React from 'react';
import { LinearProgress } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    alignItems: 'center',
    position: 'fixed',
    backgroundColor: '#fafafa',
    zIndex: 2000,
    flexDirection: 'column',
  },
  logo: {
    width: 120,
  },
  progress: {
    width: 200,
    marginTop: 50,
  },
  content: {
    top: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
});

const SplashScreen: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <img
          className={classes.logo}
          src="https://h2i.s3-sa-east-1.amazonaws.com/upload/images/logo.png"
          alt="H2I logo"
        />
        <div className={classes.progress}>
          <LinearProgress color="secondary" />
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
