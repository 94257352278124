import React from 'react';
import { IconButton, Tooltip, Theme } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { FaFileExcel } from 'react-icons/fa';
import { usePermissionRules } from 'hooks/permissionRules';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  button: {
    display: 'block',
  },
}));

type MonthlyComparisonActionsProps = {
  handleOpenDialog(): void;
  handleOpenExcelExport(): void;
};

const MonthlyComparisonActions: React.FC<MonthlyComparisonActionsProps> = ({
  handleOpenDialog,
  handleOpenExcelExport,
}) => {
  const classes = useStyles();
  const { checkPermission } = usePermissionRules();

  return (
    <>
      <Tooltip title="Outros filtros">
        <IconButton className={classes.button} onClick={handleOpenDialog} color="inherit">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      {checkPermission('general.excel_export_button') && (
        <Tooltip title="Exportar excel">
          <IconButton className={classes.button} onClick={handleOpenExcelExport} color="inherit">
            <FaFileExcel />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default MonthlyComparisonActions;
