import React, { useState } from 'react';
import { CourseStudentProvider } from '../hooks/useCourseStudent';
import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import RentalForm from '../CourseStudentForm';
import { api } from 'services/api';
import history from 'services/history';
import CourseStudentAppbarButtons from './CourseStudentAppbarButtons';
import { useCourseStudentValidation } from '../validation/couseStudentValidation';
import Loading from 'components/loading/Loading';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useCourseStudentReducer } from '../reducer/reducer';
import { changeCourseStudentAction } from '../reducer/actions';
import { CourseStudent } from 'pages/course-students/types/courseStudent';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 20,
  marginBottom: 200,
});

const CourseStudentNew: React.FC = () => {
  const [courseStudent, dispatch] = useCourseStudentReducer();
  const [saving, setSaving] = useState(false);
  const [validation, setValidation, validate] = useCourseStudentValidation();
  const { showErrorDialog } = useErrorHandler();

  function handleChange(index: keyof CourseStudent, value: any) {
    dispatch(changeCourseStudentAction(index, value));
  }

  function handleValidation() {
    setValidation({});

    validate(courseStudent)
      .then(handleSubmit)
      .catch(error => console.error(error));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/course-students', courseStudent)
      .then(() => {
        history.push('/course-students');
      })
      .catch(error => {
        console.error(error);
        showErrorDialog({
          error,
          message: 'Não foi possível salvar a nova locação',
        });
      })
      .finally(() => setSaving(false));
  }

  return (
    <CourseStudentProvider value={{ courseStudent, handleChange, dispatch }}>
      {saving && <Loading />}

      <Appbar
        title="Participantes"
        ActionsComponent={<CourseStudentAppbarButtons handleValidation={handleValidation} />}
      />

      <PageHeader title="Novo participante" backUrl="/course-students" />

      <Container>
        <RentalForm validation={validation} handleChange={handleChange} courseStudent={courseStudent} />
      </Container>
    </CourseStudentProvider>
  );
};

export default CourseStudentNew;
