import { TableTemplate } from 'types/tableTemplate';

export const paymentMethodsTableTemplate: TableTemplate[] = [
  {
    id: 'forma',
    description: 'Forma',
    originalId: 'forma_pagamento',
    width: 300,
  },
  {
    id: 'formattedPercentual',
    description: '%',
    originalId: 'percentual',
    width: 60,
    dataType: 'number',
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'valor',
    width: 220,
    dataType: 'number',
  },
];
