import React from 'react';
import { Done } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

interface CompatibilityNewAppbarButtonsProps {
  handleValidation(): void;
}

const CompatibilityNewAppbarButtons: React.FC<CompatibilityNewAppbarButtonsProps> = ({ handleValidation }) => {
  return (
    <Tooltip title="Salvar">
      <IconButton color="inherit" onClick={handleValidation}>
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default CompatibilityNewAppbarButtons;
