import React, { useEffect, useRef } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { AccessRule } from 'types/accessRule';
import { AccessRuleValidation } from './validation/accessRuleValidation';

type AccessRuleFormProps = {
  accessRule: AccessRule;
  handleChange(index: string, value: string): void;
  validation: AccessRuleValidation;
  accessRules: AccessRule[];
};

const AccessRuleForm: React.FC<AccessRuleFormProps> = ({ accessRule, handleChange, validation, accessRules }) => {
  const inputs = {
    name: useRef<HTMLInputElement>(null),
    title: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      <TextField
        select
        label="Pertence a"
        fullWidth
        value={accessRule.access_rule_id || ''}
        onChange={e => handleChange('access_rule_id', e.target.value)}
        margin="normal"
      >
        {accessRules.map(ar => (
          <MenuItem value={ar.id} key={ar.id}>
            {ar.title}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        inputRef={inputs.name}
        error={!!validation.name}
        helperText={validation.name}
        label="Nome da regra"
        placeholder={'Ex: "registrations.resellers.approved"'}
        margin="normal"
        autoFocus
        fullWidth
        value={accessRule.name}
        onChange={e => handleChange('name', e.target.value)}
      />
      <TextField
        inputRef={inputs.title}
        error={!!validation.title}
        helperText={validation.title}
        label="Título"
        placeholder={'Ex: "Cadastro > Clientes > Aprovados"'}
        margin="normal"
        fullWidth
        value={accessRule.title}
        onChange={e => handleChange('title', e.target.value)}
        autoComplete="none"
      />
      <TextField
        inputRef={inputs.description}
        error={!!validation.description}
        helperText={validation.description}
        label="Nome"
        placeholder={'Ex: "Gestão dos revendedores aprovados"'}
        margin="normal"
        fullWidth
        value={accessRule.description}
        onChange={e => handleChange('description', e.target.value)}
      />
    </>
  );
};

export default AccessRuleForm;
