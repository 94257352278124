import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useCustomer } from '../hooks/useCustomer';
import CustomerAgentTable from './table/CustomerAgentTable';
import CustomerAgentListMenu from './CustomerAgentListMenu';
import { Agent } from 'types/agent';
import { CustomerAgentProvider } from './hook/useCustomerAgents';
import PaginationProvider from 'hooks/pagination';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import { useApp } from 'hooks/app';
import FilterBox from 'components/filter-box/FilterBox';
import Pagination from 'components/pagination/Pagination';
import useTableOrder from 'hooks/tableOrder';
import CustomerAgentModule from './module/CustomerAgentModule';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  noData: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    background: '#fff',
    height: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
    marginTop: '150px',
  },
});

const CustomerAgents: React.FC = () => {
  const { agents } = useCustomer();
  const classes = styles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const app = useApp();
  const [filtered, setFiltered] = useState(agents);
  const [orderedIndex, sort] = useTableOrder();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(
    app.isMobile || app.windowWidth < 930 ? 'module' : 'list',
  );

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <>
      {filtered.length < 1 ? (
        <div className={classes.noData}>
          <Typography color="textSecondary" variant="h6">
            Nenhum preposto encontrado para esse favorecido
          </Typography>
        </div>
      ) : (
        <CustomerAgentProvider value={{ selectedAgent, setSelectedAgent }}>
          <PaginationProvider>
            <div className={classes.container}>
              <CustomerAgentListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
              {displayMode === 'list' ? (
                <>
                  <CustomerAgentTable
                    setAnchorEl={setAnchorEl}
                    agents={filtered}
                    handleSort={handleSort}
                    orderedIndex={orderedIndex}
                  />
                  <FilterBox>
                    <Grid item xs={app.isMobile || app.windowWidth < 960 ? 12 : 4}></Grid>
                    <DisplayModeButtons
                      displayMode={displayMode}
                      handleShowList={() => setDisplayMode('list')}
                      handleShowModule={() => setDisplayMode('module')}
                    />
                  </FilterBox>
                </>
              ) : (
                <>
                  <CustomerAgentModule setAnchorEl={setAnchorEl} agents={filtered} />
                  <FilterBox>
                    <Grid item xs={app.isMobile || app.windowWidth < 960 ? 12 : 4}></Grid>
                    <DisplayModeButtons
                      displayMode={displayMode}
                      handleShowList={() => setDisplayMode('list')}
                      handleShowModule={() => setDisplayMode('module')}
                    />
                  </FilterBox>
                </>
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        </CustomerAgentProvider>
      )}
    </>
  );
};

export default CustomerAgents;
