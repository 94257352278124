import React, { useEffect, useState } from 'react';
import { useCommercialBi } from '../../hooks/useCommercialBi';
import CommercialBiCityListTable from './table/CommercialBiCityListTable';
import useTableOrder from 'hooks/tableOrder';
import { CommercialBiCity } from '../../types/commercial-bi';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { styled } from '@mui/material';

const Container = styled('div')({
  padding: 20,
  // backgroundColor: '#f5f5f5',
});

const CommercialBiCities: React.FC = () => {
  const { data } = useCommercialBi();
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<CommercialBiCity[]>([]);

  useEffect(() => {
    setFiltered(data.cities);
  }, [data.cities]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <PaginationProvider>
      <Container>
        <CommercialBiCityListTable handleSort={handleSort} orderedIndex={orderedIndex} cities={filtered} />
        <Pagination count={filtered.length} />
      </Container>
    </PaginationProvider>
  );
};

export default CommercialBiCities;
