import React from 'react';

import Dialog from 'components/dialogs/Dialog';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSelector } from 'store/selector';
import { Branch } from 'types/branch';
import StockBalanceReportActions from '../stock/StockBalanceReportActions';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { CommissionCategory } from 'types/reports/commissionCategory';

import { makeStyles } from '@mui/styles';

type CommissionCategoriesReportDataProps = {
  data: CommissionCategory[];
  branch?: Branch;
  onExited(): void;
};

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 90px 90px',
    marginBottom: 1,
    paddingBottom: 1,
  },
  headerInfo: {
    marginTop: 10,
  },
  rowP: {
    fontSize: '7pt',
    textAlign: 'end',
  },
  rowPL: {
    fontSize: '7pt',
    textAlign: 'start',
  },
});

const CommissionCategoriesReportData: React.FC<CommissionCategoriesReportDataProps> = ({ data, branch, onExited }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);

  return (
    <Dialog
      fullScreen
      title="Comissão de vendedor por categoria"
      onExited={onExited}
      ComponentActions={<StockBalanceReportActions />}
    >
      <div className="page">
        <div className="header">
          <div>
            <h6>Relatório de comissão de vendedores por categoria</h6>
            {branch?.id === undefined ? <h6>Todas as filiais</h6> : <h6>Filial {branch?.nome}</h6>}
          </div>
          <div>
            <p>{user?.name}</p>
            <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
          </div>
        </div>
        <div className={`${classes.row} table-header`}>
          <p className={classes.rowPL}>Categoria</p>
          <p className={classes.rowP}>Custo</p>
          <p className={classes.rowP}>Valor unitário</p>
          <p className={classes.rowP}>Valor total</p>
          <p className={classes.rowP}>Spiff</p>
          <p className={classes.rowP}>Mc</p>
        </div>
        {data.map(item => (
          <div key={item.categoria} className={classes.row}>
            <p className={classes.rowPL}>{item.categoria}</p>
            <p className={classes.rowP}>{moneyFormat(item.custo)}</p>
            <p className={classes.rowP}>{moneyFormat(item.vl_unitario)}</p>
            <p className={classes.rowP}>{moneyFormat(item.vl_total)}</p>
            <p className={classes.rowP}>{numberFormat(item.spiff)}</p>
            <p className={classes.rowP}>{numberFormat(item.mc)}</p>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default CommissionCategoriesReportData;
