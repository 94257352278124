import React, { useEffect, useRef } from 'react';
import { CourseStudent } from '../types/courseStudent';
import { Checkbox, FormControlLabel, MenuItem, TextField, styled } from '@mui/material';
import { CourseStudentValidation } from './validation/couseStudentValidation';
import CpfCnpjInput from 'components/masked-input/CpfCnpjInput';
import { useFetchCourses } from '../hooks/useFetchCourses';

const Content = styled('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: 30,
  [props.theme.breakpoints.down('sm')]: {
    padding: 0,
    backgroundColor: 'unset',
    border: 'none',
    marginBottom: 30,
  },
}));

const Container = styled('div')({
  marginBottom: 200,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 800,
  width: '100%',
  gap: 15,
});

const FirstRow = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 15,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: 0,
  },
}));

const SecondRow = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 15,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: 0,
  },
  alignItems: 'baseline',
}));

const ThirdRow = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 15,
  marginTop: 20,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: 0,
  },
}));

interface CourseStudentFormProps {
  handleChange(index: keyof CourseStudent, value: any): void;
  courseStudent: CourseStudent;
  validation: CourseStudentValidation;
}

const CourseStudentForm: React.FC<CourseStudentFormProps> = ({ handleChange, courseStudent, validation }) => {
  const [courses, loading] = useFetchCourses();
  const inputs = {
    student_name: useRef<HTMLInputElement>(null),
    student_document_number: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Container>
      <Content>
        <TextField
          select
          label="Curso"
          margin="normal"
          fullWidth
          value={courseStudent.course_id}
          onChange={event => handleChange('course_id', event.target.value)}
          disabled={loading}
        >
          {courses.map(course => (
            <MenuItem key={course.id} value={course.id}>
              {course.name}
            </MenuItem>
          ))}
        </TextField>

        <FirstRow>
          <TextField
            label="Nome do aluno"
            placeholder="Nome do aluno"
            fullWidth
            error={!!validation.student_name}
            value={courseStudent.student_name}
            helperText={validation.student_name}
            onChange={e => handleChange('student_name', e.target.value)}
            margin="normal"
            inputRef={inputs.student_name}
            autoFocus
          />

          <TextField
            label="CPF/CNPJ"
            placeholder="CPF ou CNPJ"
            fullWidth
            error={!!validation.student_document_number}
            value={courseStudent.student_document_number}
            helperText={validation.student_document_number}
            onChange={e => handleChange('student_document_number', e.target.value)}
            margin="normal"
            inputRef={inputs.student_document_number}
            InputProps={{
              inputComponent: CpfCnpjInput as any,
            }}
          />
        </FirstRow>

        <SecondRow>
          <TextField
            label="Forma de pagamento"
            select
            value={courseStudent.payment_type}
            onChange={event => handleChange('payment_type', event.target.value)}
            margin="normal"
            fullWidth
          >
            <MenuItem value="pix">PIX</MenuItem>
            <MenuItem value="credit_card">Cartão de crédito</MenuItem>
          </TextField>

          <TextField
            label="Número de identificação"
            placeholder="Número de identificação"
            fullWidth
            onChange={e => handleChange('payment_code', e.target.value)}
            margin="normal"
            value={courseStudent.payment_code}
          />
        </SecondRow>

        <ThirdRow>
          <FormControlLabel
            control={
              <Checkbox
                checked={courseStudent.is_paid}
                onChange={e => handleChange('is_paid', e.target.checked)}
                color="primary"
              />
            }
            label="Pago"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={courseStudent.is_approved}
                onChange={e => handleChange('is_approved', e.target.checked)}
                color="primary"
              />
            }
            label="Aprovado"
          />
        </ThirdRow>
      </Content>
    </Container>
  );
};

export default CourseStudentForm;
