import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { InvoicesFilterProps } from '../Invoices';
import { UseInvoicesTotal } from './useInvoicesTotal';
import { Invoice } from 'types/invoices';
import { TotalizingAccount } from 'types/totalizingAccount';

interface InvoicesContextValue {
  filter: InvoicesFilterProps;
  handleChangeFilter(index: keyof InvoicesFilterProps, value: any): void;
  setIsOpenFilterMore: Dispatch<SetStateAction<boolean>>;
  setIsReportOpen: Dispatch<SetStateAction<boolean>>;
  handleSearch(): void;
  loading: boolean;
  invoicesTotal: UseInvoicesTotal;
  invoices: Invoice[];
  setIsNewInvoiceOpen: Dispatch<SetStateAction<boolean>>;
  selectedInvoice: Invoice | null;
  setSelectedInvoice: Dispatch<SetStateAction<Invoice | null>>;
  setSelectedInvoices: Dispatch<SetStateAction<Invoice[]>>;
  selectedInvoices: Invoice[];
  setPaymentDialog: Dispatch<SetStateAction<boolean>>;
  setAppropriationDialog: Dispatch<SetStateAction<boolean>>;
  handleToggle(invoice: Invoice): void;
  handleSelectAll(): void;
  selectAll: boolean;
  handleUpdateList(invoice: Invoice): void;
  setShowTotals: Dispatch<SetStateAction<boolean>>;
  accounts: TotalizingAccount[];
  showTotals: boolean;
  type: string;
}

const InvoicesContext = createContext<InvoicesContextValue>({} as InvoicesContextValue);
export const InvoicesConsumer = InvoicesContext.Consumer;
export const InvoicesProvider = InvoicesContext.Provider;

export function useInvoices() {
  return useContext(InvoicesContext);
}
