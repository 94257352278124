import React, { useEffect, useRef } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import { InputAdornment, MenuItem, TextField, styled } from '@mui/material';
import { QueryParams } from 'types/queryParams';
import { Search } from '@mui/icons-material';

const Filter = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '400px 170px 1fr',
  columnGap: 10,
  flex: 1,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 2fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
    flex: 1,
  },
  '& > .search-params': {
    display: 'grid',
    gridTemplateColumns: '170px 170px',
    columnGap: 20,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

type ProjectsFilterBoxProps = {
  validation: string;
  queryParams: QueryParams;
  handleQueryParamsChange(index: keyof QueryParams, value: any): void;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const ProjectsFilterBox: React.FC<ProjectsFilterBoxProps> = ({
  queryParams,
  validation,
  handleQueryParamsChange,
  setDisplayMode,
  displayMode,
}) => {
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, []);

  return (
    <FilterBox>
      <Filter>
        <TextField
          error={!!validation}
          helperText={validation}
          label="Projeto"
          placeholder="Busque pelo documento"
          value={queryParams.term}
          onChange={e => handleQueryParamsChange('term', e.target.value)}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          select
          label="Status"
          placeholder="Busque pelo status"
          value={queryParams.status}
          onChange={e => handleQueryParamsChange('status', e.target.value)}
          autoFocus
        >
          <MenuItem value="all">Todos os status</MenuItem>
          <MenuItem value="accepted">Aceito</MenuItem>
          <MenuItem value="cancelled">Cancelado</MenuItem>
          <MenuItem value="paused">Projeto pausado</MenuItem>
          <MenuItem value="waiting_visit">Aguardando visita</MenuItem>
          <MenuItem value="material_arrival">Previsão de chegada do material</MenuItem>
          <MenuItem value="waiting_quotation">Aguardando cotação</MenuItem>
          <MenuItem value="quotation_sent">Cotação enviada</MenuItem>
        </TextField>
        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={queryParams.initial_date || null}
            onChange={date => handleQueryParamsChange('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data Final"
            value={queryParams.final_date || null}
            onChange={date => handleQueryParamsChange('final_date', date)}
          />
        </div>
      </Filter>

      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default ProjectsFilterBox;
