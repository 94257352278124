import React, { MouseEvent } from 'react';
import { KeyboardArrowDown, MoreHoriz } from '@mui/icons-material';
import { AbcProductSubgroup } from 'types/abcProduct';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography, styled } from '@mui/material';
import { useAbcProducts } from '../../../hooks/useAbcProducts';
import { useAbcProduct } from '../../../hooks/useAbcProduct';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

type SubgroupItemTableProps = {
  subgroup: AbcProductSubgroup;
  branchId?: string;
  isSubgroup: boolean;
  openModal: boolean;
};

const SubgroupItemTable: React.FC<SubgroupItemTableProps> = ({ subgroup, openModal, isSubgroup, branchId }) => {
  const { tableTemplate } = useTable();
  const { setOpenModalSegment } = useAbcProduct();
  const { setSelectedSubgroup, setMoreSubgroup, moreSubgroup } = useAbcProducts();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedSubgroup(subgroup);
    setMoreSubgroup(moreSubgroup ? null : subgroup);
  }

  function handleClick() {
    if (openModal) {
      setOpenModalSegment(true);
      setSelectedSubgroup({ ...subgroup, branchId });
    }
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <CustomIconButton key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : item.id === 'more' && isSubgroup ? (
            <CustomIconButton key={item.id} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={moreSubgroup?.subgrupo === subgroup.subgrupo ? { transform: 'rotate(180deg)' } : undefined}
              />
            </CustomIconButton>
          ) : (
            <NumericData
              onClick={() => (!isSubgroup ? handleClick() : undefined)}
              key={item.id}
              className={item.dataType === 'number' ? 'numericData' : undefined}
            >
              <Typography style={{ fontSize: 11 }} variant="body2">
                {subgroup[item.id as keyof AbcProductSubgroup]}
              </Typography>
            </NumericData>
          ),
        )}
    </>
  );
};

export default SubgroupItemTable;
