import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import { PurchaseOrder } from 'types/purchaseOrder';

type PurchaseContextValue = {
  setSelectedOrder: Dispatch<SetStateAction<PurchaseOrder | null>>;
  selectedOrder: PurchaseOrder | null;
};

const PurchaseContext = createContext<PurchaseContextValue>({} as PurchaseContextValue);
export const PurchaseProvider = PurchaseContext.Provider;

export function usePurchase(): PurchaseContextValue {
  const context = useContext(PurchaseContext);
  return context;
}
