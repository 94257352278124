import { Theme, Typography } from '@mui/material';
import React from 'react';
import { useFetchViews } from '../hooks/useFetchViews';
import { useAbcProducts } from '../hooks/useAbcProducts';
import PaginationProvider from 'hooks/pagination';
import NoData from 'components/no-data/NoData';
import UnityListTable from './list/table/UnityListTable';
import Pagination from 'components/pagination/Pagination';
import UnityListModule from './list/module/UnityListModule';
import { makeStyles } from '@mui/styles';
import { arraySort } from 'helpers/sort';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  moreDetail: {
    marginTop: 15,
    marginBottom: 10,
    padding: '15px 0',
    position: 'relative',
    transition: 'all ease 0.3s',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  typography: {
    top: -12,
    left: 15,
    cursor: 'pointer',
    padding: '0px 5px',
    position: 'absolute',
    backgroundColor: '#fafafa',
  },
}));

const AbcProductUnits: React.FC = () => {
  const classes = useStyles();
  const { displayMode, searchText, handleSearch, data } = useAbcProducts();
  const { viewUnity, setViewUnity } = useFetchViews(handleSearch, searchText);

  const sorted = arraySort('unidade', data.units, 'asc');

  return (
    <div className={classes.moreDetail}>
      <Typography onClick={() => setViewUnity(state => !state)} variant="caption" className={classes.typography}>
        + Unidades
      </Typography>

      {viewUnity && (
        <PaginationProvider>
          <div className={classes.container}>
            {data.units.length === 0 ? (
              <NoData message="Nenhuma unidade encontrada." />
            ) : displayMode === 'list' ? (
              <UnityListTable units={sorted} />
            ) : (
              <UnityListModule units={sorted} />
            )}
            <Pagination count={sorted.length} />
          </div>
        </PaginationProvider>
      )}
    </div>
  );
};

export default AbcProductUnits;
