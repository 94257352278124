import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { FilterDashboardCommercial } from 'pages/dashboard/commercial/hooks/useFetchCommercialReport';
import { useEffect, useState } from 'react';
import { CommissionCategory } from 'types/reports/commissionCategory';
import { Sales } from 'types/reports/sales';

export type UseFetchDashboardReport = [
  () => string,
  (days: number) => void,
  (initialDt: Date, finalDt: Date) => void,
  boolean,
  number | null,
  FinancialSalesReportTotal,
  CommissionCategory[],
  FilterDashboardCommercial,
  (index: keyof FilterDashboardCommercial, value: any) => void,
];

export type FinancialSalesReportTotal = {
  total: number;
};

export function useFetchDashboardReport(): UseFetchDashboardReport {
  const { h2iApi } = useApp();
  const [sales, setSales] = useState<Sales[]>([]);
  const [loading, setLoading] = useState(true);
  const [dateConfig, setDateConfig] = useState<number | null>(0);
  const [commissionCategory, setCommissionCategory] = useState<CommissionCategory[]>([]);
  const [formattedTotalChart, setFormattedTotalChart] = useState<FinancialSalesReportTotal>({
    total: 0,
  });
  const [filter, setFilter] = useState<FilterDashboardCommercial>({
    branch_id: 0,
    final_date: new Date(),
    initial_date: new Date(),
    made_by: '',
    type_sale: 'T',
  });
  const formattedInitialDate = format(filter.initial_date, "dd'/'MM'/'yyyy");
  const formattedFinalDate = format(filter.final_date, "dd'/'MM'/'yyyy");

  useEffect(() => {
    if (!h2iApi) return;

    h2iApi
      .get(`/api/relcomissaocategoria`, {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          filtro: '',
          tipo: 'V',
        },
      })
      .then(response => {
        if (!response.data.relcomissao) {
          setCommissionCategory([]);
          return;
        }

        const _commissionCategories = response.data.relcomissao.map(item => {
          item.formattedTotal = moneyFormat(item.vl_total);
          item.formattedPrice = moneyFormat(item.vl_unitario);
          item.formattedCost = moneyFormat(item.custo);
          item.formattedMc = numberFormat(item.mc);
          item.formattedSpiff = numberFormat(item.spiff);

          return item;
        });

        setCommissionCategory(_commissionCategories);
      })
      .catch(err => console.error(err));
  }, [h2iApi, formattedInitialDate, formattedFinalDate]);

  useEffect(() => {
    if (!h2iApi) return;

    setLoading(true);

    h2iApi
      .get(`/api/relvendas`, {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
        },
      })
      .then(response => {
        if (!response.data.relvendas) {
          setSales([]);
          return;
        }

        const _sales = response.data.relvendas.map(item => {
          item.formattedTotal = moneyFormat(item.vl_total);
          return item;
        });

        setSales(_sales);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [h2iApi, filter, formattedInitialDate, formattedFinalDate]);

  useEffect(() => {
    const total = sales.reduce((sum, item) => sum + item.vl_total, 0);
    setFormattedTotalChart({
      total,
    });
  }, [sales]);

  function getDescriptionPageHeader(): string {
    return `Você está visualizando informações de ${formattedInitialDate} à ${formattedFinalDate}`;
  }

  function handleChangeFilter(index: keyof FilterDashboardCommercial, value: any) {
    setFilter(item => ({
      ...item,
      [index]: value,
    }));
  }

  function handleDateConfig(days: number) {
    setDateConfig(days);
    if (days === 0) {
      const date = new Date();
      handleChangeFilter('initial_date', startOfDay(date));
      handleChangeFilter('final_date', endOfDay(date));
    } else {
      const date = new Date();
      handleChangeFilter('final_date', endOfDay(date));
      handleChangeFilter('initial_date', startOfDay(subDays(date, days)));
    }
  }

  function handleConfirmCustomDates(initialDt: Date, finalDt: Date) {
    handleChangeFilter('initial_date', initialDt);
    handleChangeFilter('final_date', finalDt);
    setDateConfig(null);
  }

  return [
    getDescriptionPageHeader,
    handleDateConfig,
    handleConfirmCustomDates,
    loading,
    dateConfig,
    formattedTotalChart,
    commissionCategory,
    filter,
    handleChangeFilter,
  ];
}
