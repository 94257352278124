import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useOrdersAuthorization } from '../hooks/useOrdersAuthorization';

type OrdersAuthorizationListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const OrdersAuthorizationListMenu: React.FC<OrdersAuthorizationListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedOrder, handleApproveOrder, handleDenyOrder } = useOrdersAuthorization();

  function handleVerifyClick() {
    if (!selectedOrder) {
      return;
    }
    handleApproveOrder(selectedOrder);
    setAnchorEl(null);
  }

  function handleSincronizeClick() {
    if (!selectedOrder) {
      return;
    }
    handleDenyOrder(selectedOrder);
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleVerifyClick}>Autorizar faturamento</MenuItem>
      <MenuItem onClick={handleSincronizeClick}>Negar faturamento</MenuItem>
    </Menu>
  );
};

export default OrdersAuthorizationListMenu;
