import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { AbcCustomer } from 'types/abcCustomer';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type CustomerItemModuleProps = {
  customer: AbcCustomer;
};

const CustomerItemModule: React.FC<CustomerItemModuleProps> = ({ customer }) => {
  const classes = useStyles();

  return (
    <>
      <ListItem button className={classes.listItem}>
        <Typography variant="caption" color="primary">
          Cliente - {customer.cliente}
        </Typography>
        <Typography gutterBottom>Quantidade - {customer.qtd}</Typography>
        <Typography gutterBottom>Unitário - {customer.formattedUnitary}</Typography>
        <Typography gutterBottom>Valor total - {customer.formattedTotal}</Typography>
        <Typography gutterBottom>SPIFF - {customer.formattedSpiff}</Typography>
        <Typography gutterBottom>MC - {customer.formattedMc}</Typography>
        <Typography gutterBottom>Custo - {customer.formattedCoust}</Typography>
      </ListItem>
    </>
  );
};

export default CustomerItemModule;
