import React from 'react';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import ManufacturerMoreMenu from '../ManufacturerMoreMenu';
import ManufacturerItemTable from './ManufacturerItemTable';
import TableContainer from 'components/table/TableContainer';
import { MonthlyManufacturer } from 'types/monthlyComparison';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography, Grid } from '@mui/material';
import { manufacturerTableTemplate } from '../manufacturerTableTemplate';
import { useMonthlyComparison } from 'pages/reports/monthly-comparison/hooks/useMonthlyComparison';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  header: {
    backgroundColor: '#eee',
  },
  headerSubgroup: {
    backgroundColor: '#d9f8e5',
  },
  tableRow: {
    '& > div:nth-child(even)': {
      backgroundColor: '#eee',
    },
  },
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
});

type ManufacturerListTableProps = {
  manufacturers: MonthlyManufacturer[];
  handleSort(index: string, _sort: string): void;
  orderedIndex: OrderIndexData;
  isSubgroup: boolean;
};

const ManufacturerListTable: React.FC<ManufacturerListTableProps> = ({
  manufacturers,
  handleSort,
  orderedIndex,
  isSubgroup,
}) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { moreManufacturer } = useMonthlyComparison();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={manufacturerTableTemplate}>
          <TableContent>
            <TableHeader className={`${isSubgroup ? classes.header : classes.headerSubgroup}`}>
              {manufacturerTableTemplate.map(item => (
                <div
                  className={
                    item.dataType === 'number'
                      ? `${classes.headerItem} ${classes.headerItemNumeric}`
                      : classes.headerItem
                  }
                  key={item.id}
                  onClick={() => handleSort(item.originalId, 'manufacturer')}
                >
                  <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </div>
              ))}
            </TableHeader>
            <TableBody>
              <div className={`${isSubgroup ? classes.tableRow : undefined}`}>
                {manufacturers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(manufacturer => (
                  <>
                    <TableRow key={manufacturer.id_fabricante}>
                      <ManufacturerItemTable isSubgroup={isSubgroup} manufacturer={manufacturer} />
                    </TableRow>

                    {moreManufacturer?.id_fabricante === manufacturer.id_fabricante && (
                      <ManufacturerMoreMenu
                        key={manufacturer.id_fabricante}
                        manufacturerId={manufacturer.id_fabricante}
                      />
                    )}
                  </>
                ))}
              </div>
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ManufacturerListTable;
