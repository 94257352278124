import { useCallback, useState } from 'react';
import { AbcProductFilterParams } from '../AbcProduct';
import { useApp } from 'hooks/app';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { AxiosResponse } from 'axios';

type UseFetchAbcProductsParams = {
  filter: AbcProductFilterParams;
  searchText: string;
};

type AbcProductFetchResponse = {
  data: any;
  dataToCompare: any;
};

type UseFetchAbcProducts = [() => Promise<AbcProductFetchResponse | null>, boolean, string];

export function useFetchAbcProducts(params: UseFetchAbcProductsParams): UseFetchAbcProducts {
  const [loading, setLoading] = useState(false);
  const { h2iApi } = useApp();
  const [error, setError] = useState('');

  const getParams = useCallback(
    (initialDate: Date | null, finalDate: Date | null) => {
      const _initialDate = !initialDate ? '' : format(initialDate, 'P', { locale: ptBR });
      const _finalDate = !finalDate ? '' : format(finalDate, 'P', { locale: ptBR });

      return {
        data_ini: _initialDate,
        data_fim: _finalDate,
        id_filial: params.filter.selectedBranchId || '',
        id_subgrupo: params.filter.productId || '',
        filtro: params.searchText,
        tipo_venda: params.filter.typeSale,
        id_categoria: params.filter.categoryIds.join(','),
        id_subcategoria: params.filter.subCategoryIds.join(','),
        pedido_por: params.filter.madeBy,
        id_segmento: params.filter.segmentIds.join(','),
        id_unidade: params.filter.unitIds.join(','),
        id_fabricante: params.filter.manufacturerIds.join(','),
      };
    },
    [params],
  );

  const sendRequests = useCallback(
    async (request: Promise<AxiosResponse<any>>, requestToCompare: Promise<AxiosResponse<any>>) => {
      const [response, responseToCompare] = await Promise.all([request, requestToCompare]);
      return {
        data: response.data[0],
        dataToCompare: responseToCompare.data[0],
      };
    },
    [],
  );

  const fetch = useCallback(async () => {
    if (!h2iApi) {
      return null;
    }

    setLoading(true);

    const paramsRequest = getParams(params.filter.dates.initialDate, params.filter.dates.finalDate);

    const paramsRequestToCompare = getParams(
      params.filter.dates.initialDateToCompare,
      params.filter.dates.finalDateToCompare,
    );

    const request = h2iApi.get(`/api/relcurvaabcprodutos`, {
      params: paramsRequest,
    });

    const requestToCompare = h2iApi.get(`/api/relcurvaabcprodutos`, {
      params: paramsRequestToCompare,
    });

    try {
      return await sendRequests(request, requestToCompare);
    } catch (err) {
      console.error(err);
      setError('Não foi possível completar a requisição para criar o relatório abc de produtos');
      return null;
    } finally {
      setLoading(false);
    }
  }, [getParams, h2iApi, params, sendRequests]);

  return [fetch, loading, error];
}
