import React from 'react';
import { Typography, Grid } from '@mui/material';
import BranchItemTable from './BranchItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import { BranchStockBalanceData } from 'types/reports/stockBalance';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { branchStockTableTemplate } from '../branchStockTableTemplate';
import { useStockBalance } from '../../hooks/useStockBalance';
import ResponsibleMoreMenu from '../BranchMoreMenu';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
    marginRight: 10,
  },
  headerItemQuantity: {
    justifyContent: 'center',
  },
  body: {
    maxHeight: 'calc(100vh - 100px)',
  },
});

type BranchListTableProps = {
  branches: BranchStockBalanceData[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  isSubgroup: boolean;
  stoppedStock: boolean;
};

const BranchListTable: React.FC<BranchListTableProps> = ({
  branches,
  handleSort,
  orderedIndex,
  isSubgroup,
  stoppedStock,
}) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { moreBranch } = useStockBalance();

  return (
    <Grid container>
      <TableContainer tableTemplate={branchStockTableTemplate}>
        <TableContent>
          <TableHeader>
            {branchStockTableTemplate.map(item => (
              <div
                className={
                  item.dataType === 'number'
                    ? `${classes.headerItem} ${classes.headerItemNumeric}`
                    : item.dataType === 'quantity'
                    ? `${classes.headerItem} ${classes.headerItemQuantity}`
                    : classes.headerItem
                }
                key={item.id}
                onClick={() => handleSort(item.originalId)}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
          </TableHeader>
          <TableBody className={classes.body}>
            {branches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((branch, index) => (
              <>
                <TableRow style={{ minHeight: 10 }} key={index}>
                  <BranchItemTable isSubgroup={isSubgroup} branch={branch} />
                </TableRow>
                {moreBranch?.id_filial === branch.id_filial && (
                  <ResponsibleMoreMenu stoppedStock={stoppedStock} key={branch.id_filial} branchId={branch.id_filial} />
                )}
              </>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </Grid>
  );
};

export default BranchListTable;
