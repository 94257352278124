import React, { useState } from 'react';
import ProjectItemTable from './CourseStudentItemTable';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import history from 'services/history';
import ProjectListMenu from '../CourseStudentListMenu';
import { CourseStudentOnList } from 'pages/course-students/types/courseStudent';
import { useCourseStudents } from 'pages/course-students/hooks/useCourseStudents';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
    marginRight: 20,
  },
});

type CourseStudentListTableProps = {
  courseStudents: CourseStudentOnList[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const CourseStudentListTable: React.FC<CourseStudentListTableProps> = ({
  courseStudents,
  handleSort,
  orderedIndex,
}) => {
  const classes = useStyles();
  const { tableTemplate } = useTable();
  const { setSelectedCourseStudent } = useCourseStudents();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(courseStudent: CourseStudentOnList) {
    setSelectedCourseStudent(courseStudent);
    history.push(`/course-students/${courseStudent.id}`);
  }

  return (
    <>
      <ProjectListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <div
              className={
                item.dataType === 'number' ? `${classes.headerItem} ${classes.headerItemNumeric}` : classes.headerItem
              }
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {courseStudents.map(courseStudent => (
            <TableRow key={courseStudent.id} onClick={() => handleClick(courseStudent)}>
              <ProjectItemTable courseStudent={courseStudent} setAnchorEl={setAnchorEl} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default CourseStudentListTable;
