import React, { useRef } from 'react';
import { AppropriationDate } from 'types/appropriationDate';
import { AppropriationDateValidation } from '../validation/useAppropriationDateValidation';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { FormControlLabel, Switch } from '@mui/material';

interface AppropriationDateFormProps {
  validation: AppropriationDateValidation;
  appropriationDate: AppropriationDate;
  handleChange(index: keyof AppropriationDate, value: any): void;
}

const AppropriationDateForm: React.FC<AppropriationDateFormProps> = ({
  appropriationDate,
  handleChange,
  validation,
}) => {
  const input = useRef<HTMLInputElement>(null);

  return (
    <>
      <KeyboardDatePicker
        label="Emissão inicial"
        value={appropriationDate.date}
        onChange={e => handleChange('date', e)}
        textFieldProps={{ ref: input, margin: 'none', helperText: validation.date, error: !!validation.date }}
      />

      <FormControlLabel
        label="Inativo"
        control={
          <Switch
            color="primary"
            onChange={event => handleChange('inativo', event.target.checked ? 'S' : 'N')}
            checked={appropriationDate.inativo === 'S'}
          />
        }
      />
    </>
  );
};

export default AppropriationDateForm;
