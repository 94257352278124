import React, { useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { StockItemList } from 'types/stockItem';
import StockItemTabs from './tab/StockItemTabs';
import StockItems from './StockItems';
import { usePurchaseOrder } from '../../hooks/PurchaseOrderProvider';
import InsideLoading from 'components/loading/InsideLoading';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const StockItem: React.FC = () => {
  const [tab, setTab] = useState('all');
  const { loading, stockItemsGrouped, stockItemsUngrouped } = usePurchaseOrder();

  const filtered: StockItemList[] = useMemo(() => {
    const f = stockItemsUngrouped.filter(item => item.id_filial === parseInt(tab));

    return f;
  }, [stockItemsUngrouped, tab]);

  return (
    <Container>
      <StockItemTabs handleChange={tab => setTab(tab)} value={tab} />

      {loading.stockItems ? (
        <InsideLoading />
      ) : tab === 'all' ? (
        <StockItems items={stockItemsGrouped} />
      ) : (
        <StockItems items={filtered} />
      )}
    </Container>
  );
};

export default StockItem;
