import React, { useState, MouseEvent } from 'react';
import AddPhotoIcon from '@mui/icons-material/AddAPhoto';
import { CircularProgress, Zoom, Button, Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import { LocalUser } from './Account';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  image: {
    width: '100%',
    borderRadius: 4,
    maxWidth: 500,
    maxHeight: 500,
    objectFit: 'cover',
  },
  imageContainer: {
    border: `2px dashed ${alpha(theme.palette.primary.main, 0.2)}`,
    display: 'flex',
    width: '100%',
    height: 300,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    flexDirection: 'column',
    marginTop: 19,
  },
  imageWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
  label: {
    display: 'flex',
    cursor: 'pointer',
    minWidth: 110,
  },
  icon: {
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
}));

type AccountImageProps = {
  handleChange(index: string, value: any): void;
  localUser: LocalUser;
};

const AccountImage: React.FC<AccountImageProps> = ({ handleChange, localUser }) => {
  const messaging = useMessaging();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  function handleImageChange(files: FileList | null) {
    if (!files) return;

    const form = new FormData();

    Array.from(files).forEach((file, index) => {
      form.append(`files[${index}]`, file);
    });

    setLoading(true);

    api
      .post('/images', form)
      .then(response => {
        handleChange('image', response.data[0]);
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível carregar a imagem');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleImageDeleteClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    handleChange('image', null);
  }

  function handleIsImageSelected() {
    handleChange('isImageSelected', !localUser.isImageSelected);
  }

  return (
    <>
      {loading ? (
        <div className={classes.imageContainer}>
          <CircularProgress color="primary" />
        </div>
      ) : !localUser.image ? (
        <div>
          <input
            type="file"
            name="photo"
            onChange={event => handleImageChange(event.target.files)}
            accept="image/*"
            id="photo-file"
            style={{ display: 'none' }}
          />
          <label htmlFor="photo-file">
            <div className={classes.imageContainer}>
              <AddPhotoIcon color="primary" />
            </div>
          </label>
        </div>
      ) : (
        <>
          <div className={classes.imageContainer} onClick={handleIsImageSelected}>
            <Zoom in={localUser.isImageSelected}>
              <div className={classes.imageWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={event => handleImageDeleteClick(event)}
                >
                  Remover
                </Button>
              </div>
            </Zoom>
            <img alt="Foto" className={classes.image} src={localUser.image.imageUrl} />
          </div>
        </>
      )}
    </>
  );
};

export default AccountImage;
