import React, { useState } from 'react';
import WasteItemTable from './WasteItemTable';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { Typography, styled } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import history from 'services/history';
import ProjectListMenu from '../WasteListMenu';
import { WasteOnList } from 'pages/material-residues/waste/types/waste';
import { useWastes } from 'pages/material-residues/waste/hooks/useWastes';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
  '&.headerItemNumeric': {
    justifyContent: 'flex-end',
  },
});

type WasteListTableProps = {
  wastes: WasteOnList[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const WasteListTable: React.FC<WasteListTableProps> = ({ wastes, handleSort, orderedIndex }) => {
  const { tableTemplate } = useTable();
  const { setSelectedWaste } = useWastes();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(waste: WasteOnList) {
    setSelectedWaste(waste);
    history.push(`/waste/${waste.id}`);
  }

  return (
    <>
      <ProjectListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <HeaderItem
              className={item.dataType === 'number' ? 'headerItemNumeric' : ''}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody>
          {wastes.map(waste => (
            <TableRow key={waste.id} onClick={() => handleClick(waste)}>
              <WasteItemTable waste={waste} setAnchorEl={setAnchorEl} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default WasteListTable;
