import { createContext, useContext } from 'react';
import { AbcManufacturersFilterParams } from '../CurveAbcManufacturer';

type AbcManufacturerContextValue = {
  searchValue: string;
  filter: AbcManufacturersFilterParams;
  handleChangeFilter(index: keyof AbcManufacturersFilterParams, value: any): void;
};

const AbcManufacturerContext = createContext<AbcManufacturerContextValue>({} as AbcManufacturerContextValue);
export const AbcManufacturerProvider = AbcManufacturerContext.Provider;

export function useAbcManufacturer(): AbcManufacturerContextValue {
  const context = useContext(AbcManufacturerContext);
  return context;
}
