import { Dispatch, SetStateAction, useState } from 'react';
import { RegisterForm } from 'types/registerForm';
import * as yup from 'yup';

export interface RegisterUploadsValidation {
  social_contract_copy?: string;
}

interface RegisterUploadsValidationOptions {
  imagesRequired: boolean;
}

type UseRegisterUploadsValidation = [
  RegisterUploadsValidation,
  Dispatch<SetStateAction<RegisterUploadsValidation>>,
  (register: RegisterForm, options?: RegisterUploadsValidationOptions) => Promise<void>,
];

export function useRegisterUploadsValidation(): UseRegisterUploadsValidation {
  async function handleValidation(
    register: RegisterForm,
    options: RegisterUploadsValidationOptions = { imagesRequired: true },
  ) {
    const schema = yup.object().shape({
      social_contract_copy: yup
        .object()
        .nullable()
        .test('documentImageTest', 'cópia do contrato social é obrigatório', value => {
          if (options.imagesRequired && !value) return false;
          return true;
        }),
    });

    try {
      await schema.validate(register);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<RegisterUploadsValidation>({});
  return [validation, setValidation, handleValidation];
}
