import React from 'react';
import { Typography, Grid, styled } from '@mui/material';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import history from 'services/history';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { OrderIndexData } from 'hooks/tableOrder';
import { usePagination } from 'hooks/pagination';
import TableContent from 'components/table/TableContent';
import { Cfop } from 'types/cfop';
import { fiscalCodeTableTemplate } from '../fiscalCodeTableTemplate';
import FiscalCodeItemTable from './FiscalCodeItemTable';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type FiscalCodeListTableProps = {
  fiscalCodes: Cfop[];
  handleSort(id: string): void;
  orderedIndex: OrderIndexData;
};

const FiscalCodeListTable: React.FC<FiscalCodeListTableProps> = ({ fiscalCodes, handleSort, orderedIndex }) => {
  const { page, rowsPerPage } = usePagination();

  function handleClick(id?: number) {
    history.push(`/cfop/${id}`);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={fiscalCodeTableTemplate}>
          <TableContent>
            <TableHeader>
              {fiscalCodeTableTemplate.map(item => (
                <HeaderItem key={item.id} onClick={() => handleSort(item.originalId)}>
                  <Typography variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </HeaderItem>
              ))}
            </TableHeader>
            {fiscalCodes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(fiscalCode => (
              <TableRow onClick={() => handleClick(fiscalCode.id)} key={fiscalCode.id}>
                <FiscalCodeItemTable fiscalCode={fiscalCode} />
              </TableRow>
            ))}
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default FiscalCodeListTable;
