import React from 'react';
import { alpha, ListItemButton, Typography, styled } from '@mui/material';
import { Bill } from 'types/bill';
import { useBillRemittance } from './hooks/useBillRemitance';

const ListItemStyled = styled(ListItemButton)(props => ({
  justifyContent: 'space-between',
  boxShadow: '1px 1px 9px 1px #eee',
  '&.selected': {
    backgroundColor: alpha(props.theme.palette.primary.main, 0.2),
  },
}));

interface BillRemittanceItemProps {
  bill: Bill;
}

const BillRemittanceItem: React.FC<BillRemittanceItemProps> = ({ bill }) => {
  const { handleSelect, selectedBills } = useBillRemittance();

  function isSelected(bill: Bill): boolean {
    const selected = selectedBills.some(_bill => _bill.id === bill.id);
    return selected;
  }

  return (
    <ListItemStyled className={isSelected(bill) ? `selected` : undefined} onClick={() => handleSelect(bill)}>
      <div>
        <Typography variant="body2">{bill.document_number}</Typography>
        <Typography>{bill.payer.company_name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {bill.formattedCreatedAt}
        </Typography>
      </div>
      <Typography>{bill.formattedValue}</Typography>
    </ListItemStyled>
  );
};

export default BillRemittanceItem;
