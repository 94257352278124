import React, { useState, useEffect, FormEvent } from 'react';
import { styled } from '@mui/material';
import NoData from 'components/no-data/NoData';
import useTableOrder from 'hooks/tableOrder';
import { usePurchaseOrderProductValidation } from './validation/purchaseOrderProductValidation';
import { PurchaseOrderProduct, PurchaseOrderProductFormContent } from 'types/purchaseOrder';
import { usePurchaseOrder } from '../hook/usePurchaseOrder';
import PurchaseOrderProductForm from './PurchaseOrderProductForm';
import { Product } from 'types/product';
import { addPurchaseOrderProduct } from '../../reducer/actions';
import { useDisplayMode } from 'hooks/useDisplayMode';
import ProductListTable from './list/table/ProductListTable';
import ProductListModule from './list/module/ProductListModule';
import ConfirmDialog from 'components/confirm-dialog/ConfirmDialog';
import PurchaseOrderProductDialog from '../dialog/PurchaseOrderProduct';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  gap: 10,
  flexDirection: 'column',
  width: '100%',
  backgroundColor: '#fff',
});

export interface PurchaseOrderSelectProduct {
  product: Product | null;
  id: number;
  productId: number;
  discount: number;
  name: string;
  value: number;
  quantity: number;
  ipi: number;
  cfop: string;
}

const productInitialState = {
  productId: 0,
  quantity: 0,
  name: '',
  product: null,
  value: 0,
  id: 0,
  discount: 0,
  ipi: 0,
  cfop: '',
};

interface PurchaseOrderProductsProps {
  type: 'update' | 'new';
}

const PurchaseOrderProducts: React.FC<PurchaseOrderProductsProps> = ({ type }) => {
  const [existsProduct, setExistsProduct] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const { order, dispatch, disabled } = usePurchaseOrder();
  const [product, setProduct] = useState<PurchaseOrderSelectProduct>(productInitialState);
  const [filteredProducts, setFilteredProducts] = useState<PurchaseOrderProduct[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [displayMode] = useDisplayMode();
  const [validation, setValidation, validate] = usePurchaseOrderProductValidation();
  const [selectedProduct, setSelectedProduct] = useState<PurchaseOrderProduct | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  useEffect(() => {
    if (!disabled) {
      setProduct(_product => ({ ..._product, cfop: order.cfop }));
    }
  }, [disabled, order.cfop]);

  useEffect(() => {
    setFilteredProducts(order.itens.filter(item => item.cancelado !== 'S'));
  }, [order.itens]);

  function handleSort(index: string) {
    const p = sort(index, filteredProducts);
    setFilteredProducts(p);
  }

  async function handleValidation() {
    setOpenProductModal(false);
    const _existsProduct = order.itens
      .filter(item => item.cancelado !== 'S')
      .find(item => item.codigo_produto === product.productId);

    if (_existsProduct) {
      setExistsProduct(true);
      return;
    }

    onSubmit(false);
  }

  async function handleSubmit(data: PurchaseOrderProductFormContent, overwrite: boolean) {
    dispatch(addPurchaseOrderProduct(data, overwrite));

    setProduct(productInitialState);
  }

  function handleChange(index: keyof PurchaseOrderSelectProduct, value: any) {
    setProduct(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function onSubmit(overwrite: boolean) {
    setExistsProduct(false);
    handleSubmit(
      {
        id: 0,
        product: product.name,
        value: product.value,
        quantity: product.quantity,
        productId: product.productId,
        cfop: product.cfop,
      },
      overwrite,
    );
  }

  async function handleAddProduct(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    const data = {
      id: 0,
      product: product.name,
      value: product.value,
      quantity: product.quantity,
      productId: product.productId,
      cfop: product.cfop,
    };

    await validate(data);

    setOpenProductModal(true);
  }

  return (
    <Container style={{ opacity: !disabled ? 1 : 0.5 }}>
      {existsProduct && (
        <ConfirmDialog
          title="Produto já adicionado, deseja sobrescrever?"
          handleNoClick={() => onSubmit(false)}
          handleYesClick={() => onSubmit(true)}
        />
      )}

      {openProductModal && (
        <PurchaseOrderProductDialog
          onSubmit={handleValidation}
          product={product}
          onExited={() => setOpenProductModal(false)}
          handleChange={handleChange}
        />
      )}
      <PurchaseOrderProductForm
        disabled={disabled}
        product={product}
        onChange={handleChange}
        validation={validation}
        handleAddProduct={handleAddProduct}
      />

      {filteredProducts.length === 0 ? (
        <NoData message="Nenhum produto para mostrar." />
      ) : (
        <>
          {displayMode === 'list' ? (
            <ProductListTable
              type={type}
              disabled={disabled}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              products={filteredProducts}
              handleSort={handleSort}
              orderedIndex={orderedIndex}
            />
          ) : (
            <ProductListModule
              type={type}
              disabled={disabled}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              products={filteredProducts}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default PurchaseOrderProducts;
