import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, ListItem, IconButton } from '@mui/material';
import { Agent } from 'types/agent';
import { MoreHoriz } from '@mui/icons-material';
import { useCustomerAgents } from '../hook/useCustomerAgents';

import { makeStyles } from '@mui/styles';

type CustomerAgentModuleItemProps = {
  agent: Agent;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

const CustomerAgentModuleItem: React.FC<CustomerAgentModuleItemProps> = ({ agent, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedAgent } = useCustomerAgents();

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedAgent(agent);
  }

  return (
    <ListItem button className={classes.listItem}>
      <IconButton onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Typography variant="h6">{agent.nome}</Typography>
      <Typography variant="body1">{agent.cpf}</Typography>
      <Typography variant="caption" color="textSecondary">
        {agent.rg}
      </Typography>
    </ListItem>
  );
};

export default CustomerAgentModuleItem;
