import React from 'react';
import { Typography } from '@mui/material';
import { StockBalanceData } from 'types/reports/stockBalance';
import { stockTableTemplate } from '../stockTableTemplate';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  quantityData: {
    display: 'flex',
    justifyContent: 'center',
  },
});

type ProductItemProps = {
  product: StockBalanceData;
};

const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
  const classes = styles();

  return (
    <>
      {stockTableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div
            key={item.id}
            className={
              item.dataType === 'number'
                ? classes.numericData
                : item.dataType === 'quantity'
                ? classes.quantityData
                : undefined
            }
          >
            <Typography style={{ fontSize: 11 }} variant="body2">
              {product[item.id as keyof StockBalanceData]}
            </Typography>
          </div>
        ))}
    </>
  );
};

export default ProductItem;
