import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import BankStatementItemModule from './BankStatementItemModule';
import { Extract } from 'types/extract';

type BankStatementListModuleProps = {
  extracts: Extract[];
};

const BankStatementListModule: React.FC<BankStatementListModuleProps> = ({ extracts }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <List>
      {extracts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(extract => (
        <BankStatementItemModule key={extract.id_movimentacao} extract={extract} />
      ))}
    </List>
  );
};

export default BankStatementListModule;
