import { Box, Grid, TextField } from '@mui/material';
import React from 'react';
import { useCustomer } from '../../hooks/useCustomer';

const CustomerObservation: React.FC = () => {
  const { customer, handleChange } = useCustomer();

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid container direction={'row'} spacing={1}>
          <Grid item xs={12} md={12}>
            <TextField
              label="Observação"
              onChange={e => handleChange('obs', e.target.value)}
              fullWidth
              multiline
              value={customer?.obs}
              rows={10}
              inputProps={{ style: { textTransform: 'uppercase' } }}
              type="text"
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerObservation;
