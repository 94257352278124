import React, { useState } from 'react';
import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import PaginationProvider from 'hooks/pagination';
import { H2iBuyer } from 'types/h2iBuyer';
import TableContainer from 'components/table/TableContainer';
import { buyerTableTemplate } from './buyerTableTemplate';
import { useDisplayMode } from 'hooks/useDisplayMode';
import BuyersAction from './BuyersAction';
import BuyerFilterBox from './BuyerFilterBox';
import { useFetchBuyers } from './hooks/useFetchBuyers';
import { BuyersProvider } from './hooks/useBuyers';
import NewBuyer from './buyer/register/new/NewBuyer';
import EditBuyer from './buyer/register/edit/EditBuyer';
import BuyerListTable from './list/table/BuyerListTable';
import BuyerListModule from './list/module/BuyerListModule';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Buyers: React.FC = () => {
  const [selectedBuyer, setSelectedBuyer] = useState<H2iBuyer | null>(null);
  const [editBuyerDialog, setEditBuyerDialog] = useState(false);
  const [newBuyerDialog, setNewBuyerDialog] = useState(false);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [
    handleBuyerDelete,
    handleSort,
    handleFormatBuyer,
    loading,
    saving,
    orderedIndex,
    setBuyers,
    setSearchText,
    searchText,
    filteredBuyers,
    fetchBuyers,
  ] = useFetchBuyers();

  function handleExitEditBuyerDialog() {
    setEditBuyerDialog(false);
    fetchBuyers();
  }

  function handleExitNewBuyerDialog() {
    setNewBuyerDialog(false);
    fetchBuyers();
  }

  return (
    <BuyersProvider
      value={{
        selectedBuyer,
        setSelectedBuyer,
        setEditBuyerDialog,
        setNewBuyerDialog,
        setBuyers,
        handleFormatBuyer,
        handleBuyerDelete,
        saving,
      }}
    >
      {editBuyerDialog && <EditBuyer onSave={handleExitEditBuyerDialog} onExited={() => setEditBuyerDialog(false)} />}

      {newBuyerDialog && <NewBuyer onSave={handleExitNewBuyerDialog} onExited={() => setNewBuyerDialog(false)} />}

      <Appbar title="Compradores" />
      <PageHeaderActions
        title="Compradores"
        description="Cadastro de compradores"
        ActionComponent={<BuyersAction onClick={() => setNewBuyerDialog(true)} />}
      />

      <BuyerFilterBox
        searchText={searchText}
        setDisplayMode={setDisplayMode}
        displayMode={displayMode}
        handleChangeText={value => setSearchText(value)}
        onSubmit={fetchBuyers}
      />
      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filteredBuyers.length === 0 ? (
        <NoData message="Nenhum comprador para mostrar" />
      ) : (
        <TableContainer tableTemplate={buyerTableTemplate}>
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <BuyerListTable buyers={filteredBuyers} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <BuyerListModule buyers={filteredBuyers} />
              )}
              <Pagination count={filteredBuyers.length} />
            </Container>
          </PaginationProvider>
        </TableContainer>
      )}
    </BuyersProvider>
  );
};

export default Buyers;
