import { Grid, TextField } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import React, { useState } from 'react';
import ProductQuantityPriceFormActions from './ProductQuantityPriceFormActions';

type ProductQuantityPriceFormProps = {
  maxWidth?: 'sm' | 'md';
  onExited(): void;
};

const ProductQuantityPriceForm: React.FC<ProductQuantityPriceFormProps> = ({ maxWidth = 'sm', onExited }) => {
  const [amount, setAmount] = useState('');
  const [percentual, setPercentual] = useState('');

  function handleConfirm() {
    console.log('submit');
  }

  return (
    <Dialog
      title="Preços por quantidade"
      maxWidth={maxWidth}
      onExited={onExited}
      ComponentActions={<ProductQuantityPriceFormActions handleSubmit={handleConfirm} />}
      height="70vh"
    >
      <Grid item xs={12} xl={5} lg={5} md={9}>
        <TextField
          label="Quantidade"
          placeholder="Digite a quantidade"
          margin="normal"
          value={amount}
          onChange={e => setAmount(e.target.value)}
          autoFocus
          fullWidth
        />
        <TextField
          label="Percentual"
          placeholder="Digite o percentual"
          margin="normal"
          value={percentual}
          onChange={e => setPercentual(e.target.value)}
          fullWidth
        />
      </Grid>
    </Dialog>
  );
};

export default ProductQuantityPriceForm;
