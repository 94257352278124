import { useApp } from 'hooks/app';
import { Agent } from 'types/agent';
import { FormEvent, useState, useEffect } from 'react';
import { H2iCustomer } from 'types/h2iCustomer';
import { CustomerInfosValidation, useCustomerInfosValidation } from '../validation/CustomerInfosValidation';
import { CustomerAddressValidation, useCustomerAddressValidation } from '../validation/CustomerAddressValidation';
import { CustomerContactsValidation, useCustomerContactsValidation } from '../validation/CustomerContactsValidation';
import { postalCodeSearch } from 'services/postalCodeSearch';
import { api } from 'services/api';
import { Segment } from 'types/segment';

export const initialState: H2iCustomer = {
  aprovado: true,
  atividade: '',
  autonomo: 'N',
  bairro: '',
  boleto: 'N',
  carta_fianca: 'N',
  cartao: 'N',
  carteira: 'N',
  cep: '',
  cheque: 'N',
  cidade: '',
  cnpj_cpf: '',
  codigo: 0,
  codigo_atividade: '',
  codigo_vendedor: '0',
  coligado: 'N',
  comissao: '0',
  comissao_representante: '0',
  complemento: '',
  credito: 'N',
  deposito: 'N',
  dinheiro: 'N',
  ecommerce: 'N',
  email: '',
  email_cobranca: '',
  email_nfe: '',
  emite_doc_fiscal: 'S',
  endereco: '',
  fantasia: '',
  limite_credito: 0,
  link_contrato_social: '',
  link_declaracao: '',
  link_documento: '',
  link_residencia: '',
  link_selfie: '',
  nome: '',
  obs: '',
  os: 'N',
  participa_promocao: 'S',
  prazo_boleto: '0',
  prazo_boleto_informatica: '0',
  prazo_fornecedor: 0,
  rg_ie: '',
  situacao: 'A',
  telefone: '',
  tipo: '',
  tipo_pessoa: 'F',
  uf: '',
  valor_carta_fianca: 0,
  venda_interna: 'N',
  whatsapp: '',
};

type UseFetchNewCustomer = [
  (index: keyof H2iCustomer, value: any) => void,
  (e?: FormEvent<HTMLFormElement>) => void,
  H2iCustomer | null,
  Agent[],
  Segment[],
  CustomerInfosValidation,
  CustomerAddressValidation,
  CustomerContactsValidation,
  boolean,
  boolean,
  boolean,
];

let timer: NodeJS.Timeout;

export function useFetchNewCustomer(onExited: () => void): UseFetchNewCustomer {
  const [customer, setCustomer] = useState<H2iCustomer>(initialState);
  const [saving, setSaving] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [error, setError] = useState(false);
  const [agents] = useState<Agent[]>([]);
  const [segments, setSegments] = useState<Segment[]>([]);
  const { h2iApi } = useApp();
  const [customerInfoValidation, setCustomerInfoValidation, validateCustomerInfo] = useCustomerInfosValidation();
  const [customerAddressValidation, setCustomerAddressValidation, validateCustomerAddress] =
    useCustomerAddressValidation();
  const [customerContactValidation, setCustomerContactValidation, validateCustomerContact] =
    useCustomerContactsValidation();

  useEffect(() => {
    if (!h2iApi) return;

    h2iApi
      .get('/api/getatividade')
      .then(response => {
        const _segments: Segment[] = response.data.atividade;
        if (customer?.tipo_pessoa === 'J') setSegments(_segments.filter(segment => segment.tipo_pessoa.match('J|A')));
        else setSegments(_segments.filter(segment => segment.tipo_pessoa.match('F|A')));
      })
      .catch(err => console.error(err));
  }, [customer?.tipo_pessoa, h2iApi]);

  function handleChangeDocument(value: any) {
    setCustomer(customer => ({
      ...customer,
      cnpj_cpf: value,
    }));

    const newDocument = value.replace(/\D/g, '');

    clearTimeout(timer);

    if (newDocument.length === 0) return false;

    if (customer.tipo_pessoa === 'J') {
      if (newDocument.length === 14)
        h2iApi?.get('/api/cliente', { params: { cnpj: newDocument, pw: '' } }).then(_response => {
          const user = _response.data[0];
          if (user.aprovado) {
            setCustomerInfoValidation(validation => ({ ...validation, cnpj_cpf: 'Esse documento já está registrado' }));
            return false;
          } else {
            api.get(`/company/${newDocument}`).then(response => {
              const company = response.data;
              handleChange('fantasia', company.fantasia);
              handleChange('nome', company.nome);
              handleChange('email', company.email);
              handleChange('email_cobranca', company.email);
              handleChange('email_nfe', company.email);
              handleChange('cep', company.cep);
            });
          }
        });
    } else {
      if (newDocument.length === 11) {
        h2iApi?.get('/api/cliente', { params: { cnpj: newDocument, pw: '' } }).then(_response => {
          const user = _response.data[0];
          if (user.aprovado) {
            setCustomerInfoValidation(validation => ({ ...validation, cnpj_cpf: 'Esse documento já está registrado' }));
            return false;
          } else {
            setCustomerInfoValidation(validation => ({ ...validation, cnpj_cpf: '' }));
          }
        });
      }
    }
  }

  function handleChangeCep(value: any) {
    setCustomer(customer => ({
      ...customer,
      cep: value,
    }));
    setCustomerAddressValidation(validation => ({ ...validation, cep: '' }));

    const newPostalCode = value.replace(/\D/g, '');

    clearTimeout(timer);

    if (newPostalCode.length === 8) {
      timer = setTimeout(() => {
        setLoadingCep(true);
        postalCodeSearch(newPostalCode)
          .then(response => {
            if (response.data.erro) {
              setCustomerAddressValidation(validation => ({ ...validation, cep: 'CEP inexistente' }));
              return;
            }
            const { data } = response;
            setCustomerAddressValidation(validation => ({ ...validation, cep: '' }));
            handleChange('endereco', data.logradouro);
            handleChange('complemento', data.complemento);
            handleChange('cidade', data.localidade);
            handleChange('bairro', data.bairro);
            handleChange('uf', data.uf);
          })
          .catch(err => {
            setCustomerAddressValidation(validation => ({ ...validation, cep: err.message }));
          })
          .finally(() => {
            setLoadingCep(false);
          });
      }, 500);
    }
  }

  function handleChange(index: keyof H2iCustomer, value: any) {
    if (index === 'cnpj_cpf') {
      handleChangeDocument(value);
      return;
    }

    if (index === 'cep') {
      handleChangeCep(value);
      return;
    }

    setCustomer(customer => ({
      ...customer,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    if (!customer) return;

    e?.preventDefault();

    setCustomerInfoValidation({});

    validateCustomerInfo(customer)
      .then(() => {
        handleAddressValidation();
      })
      .catch(error => {
        console.log(error);
        setError(true);
      });
  }

  function handleAddressValidation(e?: FormEvent<HTMLFormElement>) {
    if (!customer) return;
    e?.preventDefault();

    setCustomerAddressValidation({});

    validateCustomerAddress(customer)
      .then(() => {
        handleContactValidation();
      })
      .catch(error => {
        console.log(error);
        setError(true);
      });
  }

  function handleContactValidation(e?: FormEvent<HTMLFormElement>) {
    if (!customer) return;
    if (customer.codigo_atividade === '0') return;
    e?.preventDefault();

    setCustomerContactValidation({});

    validateCustomerContact(customer)
      .then(handleSubmit)
      .catch(error => {
        console.log(error);
        setError(true);
      });
  }

  function handleSubmit() {
    if (!h2iApi) return;
    setError(false);
    setSaving(true);

    h2iApi
      .post(`api/savefavorecido`, customer)
      .then(response => {
        console.log(response);
        onExited();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return [
    handleChange,
    handleValidation,
    customer,
    agents,
    segments,
    customerInfoValidation,
    customerAddressValidation,
    customerContactValidation,
    saving,
    error,
    loadingCep,
  ];
}
