import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useRentals } from '../hooks/useRentals';

type RentalListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const RentalListMenu: React.FC<RentalListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedRental, handleStatus, setReport } = useRentals();

  function handleStatusClick() {
    if (!selectedRental) {
      return;
    }

    setAnchorEl(null);

    handleStatus();
  }

  function handlePrintClick() {
    setReport(true);
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleStatusClick}>{selectedRental?.active ? 'inativar' : 'ativar'}</MenuItem>
      <MenuItem onClick={handlePrintClick}>imprimir</MenuItem>
    </Menu>
  );
};

export default RentalListMenu;
