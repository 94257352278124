import React, { Dispatch, SetStateAction, useState } from 'react';
import { Typography, styled } from '@mui/material';
import ProductItemTable from './ProductItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableContent from 'components/table/TableContent';
import { productTableTemplate } from '../productTableTemplate';
import ProductsListMenu from '../ProductsListMenu';
import { PurchaseOrderProduct } from 'types/purchaseOrder';
import TableBody from 'components/table/TableBody';
import { moneyFormat } from 'helpers/numberFormat';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
  '&.numericData': {
    justifyContent: 'flex-end',
  },
});

type ProductListTableProps = {
  products: PurchaseOrderProduct[];
  type: 'update' | 'new';
  disabled: boolean;
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  selectedProduct: PurchaseOrderProduct | null;
  setSelectedProduct: Dispatch<SetStateAction<PurchaseOrderProduct | null>>;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
  anchorEl: null | HTMLButtonElement;
};

const ProductListTable: React.FC<ProductListTableProps> = ({
  products,
  handleSort,
  orderedIndex,
  type,
  anchorEl,
  setAnchorEl,
  setSelectedProduct,
  selectedProduct,
  disabled,
}) => {
  const [index, setIndex] = useState(-1);

  function handleClick(product: PurchaseOrderProduct, index: number) {
    setIndex(index);
    setSelectedProduct(product);
  }

  function onExited() {
    setIndex(-1);
    setSelectedProduct(null);
    setAnchorEl(null);
  }

  return (
    <>
      {selectedProduct && (
        <ProductsListMenu
          index={index}
          type={type}
          disabled={disabled}
          selectedProduct={selectedProduct}
          anchorEl={anchorEl}
          onExited={onExited}
        />
      )}
      <TableContainer tableTemplate={productTableTemplate}>
        <TableContent useMinHeight={false}>
          <TableHeader>
            {productTableTemplate.map(item => (
              <HeaderItem
                className={item.dataType === 'number' ? 'numericData' : ''}
                key={item.id}
                onClick={() => handleSort(item.originalId)}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </HeaderItem>
            ))}
          </TableHeader>
          <TableBody>
            {products.map((product, index) => (
              <TableRow
                disabled={disabled || product.cancelado === 'S'}
                onClick={() => handleClick(product, index)}
                key={index}
              >
                <ProductItemTable
                  setAnchorEl={setAnchorEl}
                  handleClick={() => handleClick(product, index)}
                  product={product}
                />
              </TableRow>
            ))}
            <TableRow disabled={disabled}>
              <div style={{ borderRight: 'none' }}></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div className="numericData"></div>
              <div className="numericData">
                <Typography fontWeight={600} variant="body2">
                  {moneyFormat(products.reduce((sum, item) => sum + item.valor_subtotal, 0))}
                </Typography>
              </div>
            </TableRow>
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default ProductListTable;
