import React from 'react';
import { FilterList } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

interface AnalysisProductActionsProps {
  handleOpenDialog(): void;
}

const AnalysisProductActions: React.FC<AnalysisProductActionsProps> = ({ handleOpenDialog }) => {
  return (
    <CustomIconButton onClick={handleOpenDialog} color="inherit">
      <FilterList />
    </CustomIconButton>
  );
};

export default AnalysisProductActions;
