import React, { FormEvent, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import { styled } from '@mui/material';
import AiChatInput from './AiChatInput';
import AiChatOutput from './AiChatOutput';
import { openai } from 'services/openAi';

const Container = styled('form')({
  gap: 15,
  display: 'flex',
  backgroundColor: '#eceef2',
  flexDirection: 'column',
});

const AiChat: React.FC = () => {
  const [text, setText] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [lastQuestion, setLastQuestion] = useState('');

  async function handleSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setLastQuestion(text);
    setLoading(true);

    openai
      .createChatCompletion({
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: text }],
      })
      .then(response => {
        const message = response.data.choices[0].message;

        if (message?.content) {
          setResponse(message?.content.replace(/\n/g, '<br>'));
        }
      })
      .catch(err => setResponse(`<p style="color: red">Falha na requisição. \n${err}</p>`))
      .finally(() => {
        setText('');

        setLoading(false);
      });
  }

  return (
    <>
      <Appbar title="ChatGPT" />

      <Container onSubmit={handleSubmit}>
        <AiChatInput loading={loading} text={text} setText={setText} />
        <AiChatOutput loading={loading} lastQuestion={lastQuestion} setText={setText} response={response} />
      </Container>
    </>
  );
};

export default AiChat;
