import { Typography, styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import React from 'react';
import { Product } from 'types/product';
import { StockBalanceData } from 'types/reports/stockBalance';

interface AiProductInfoProps {
  productH2i: Product | null;
  stockBalance: StockBalanceData;
  averagePrice: number;
}

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  [theme.breakpoints.down('md')]: {
    gridTemplateColums: '1fr',
  },
}));

const BoxContainer = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const BoxHeader = styled('div')(({ theme }) => ({
  height: '100%',
  maxHeight: 100,
  display: 'flex',
  alignItems: 'center',
  padding: 20,
  '&.coust': {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '6px 0 0 0',
    [theme.breakpoints.down('md')]: {
      borderRadius: '6px 6px 0 0',
    },
  },
  '&.stock': {
    backgroundColor: '#79309f96',
  },
  '&.mc': {
    backgroundColor: '#3b7e75b0',
  },
  '&.average': {
    borderRadius: '0 6px 0 0',
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.down('md')]: {
      borderRadius: '0',
    },
  },
  '&.forecast': {
    backgroundColor: '#843c3c8a',
  },
}));

const BoxContent = styled('div')(({ theme }) => ({
  '&.coust': {
    backgroundColor: theme.palette.secondary.main,
  },
  '&.stock': {
    backgroundColor: '#79309f',
  },
  '&.mc': {
    backgroundColor: '#3b7e75',
  },
  '&.average': {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      borderRadius: '0 0 6px 6px',
    },
  },
  '&.forecast': {
    backgroundColor: '#843c3c',
  },
  padding: 20,
}));

const AiProductInfo: React.FC<AiProductInfoProps> = ({ productH2i, averagePrice, stockBalance }) => {
  return (
    <Container>
      <BoxContainer>
        <BoxHeader className="coust">
          <Typography fontWeight="bold" fontSize={12} align="center" variant="caption" color="#fff">
            Custo médio
          </Typography>
        </BoxHeader>
        <BoxContent className="coust">
          <Typography align="center" color="#fff" variant="body1" fontWeight="bold">
            {moneyFormat(productH2i?.custo)}
          </Typography>
        </BoxContent>
      </BoxContainer>

      <BoxContainer>
        <BoxHeader className="stock">
          <Typography fontWeight="bold" fontSize={12} align="center" variant="caption" color="#fff">
            Estoque total
          </Typography>
        </BoxHeader>
        <BoxContent className="stock">
          <Typography align="center" color="#fff" variant="body1" fontWeight="bold">
            {productH2i?.est || 0}
          </Typography>
        </BoxContent>
      </BoxContainer>

      <BoxContainer>
        <BoxHeader className="forecast">
          <Typography fontWeight="bold" fontSize={12} align="center" variant="caption" color="#fff">
            Forecast
          </Typography>
        </BoxHeader>
        <BoxContent className="forecast">
          <Typography align="center" color="#fff" variant="body1" fontWeight="bold">
            {stockBalance.forecast || 0}
          </Typography>
        </BoxContent>
      </BoxContainer>

      <BoxContainer>
        <BoxHeader className="mc">
          <Typography fontWeight="bold" fontSize={12} align="center" variant="caption" color="#fff">
            Margem de Contribuição
          </Typography>
        </BoxHeader>
        <BoxContent className="mc">
          <Typography align="center" color="#fff" variant="body1" fontWeight="bold">
            {productH2i?.mc || 0}
          </Typography>
        </BoxContent>
      </BoxContainer>

      <BoxContainer>
        <BoxHeader className="average">
          <Typography fontWeight="bold" fontSize={12} align="center" variant="caption" color="#fff">
            Preço médio da internet
          </Typography>
        </BoxHeader>
        <BoxContent className="average">
          <Typography align="center" color="#fff" variant="body1" fontWeight="bold">
            {moneyFormat(averagePrice)}
          </Typography>
        </BoxContent>
      </BoxContainer>
    </Container>
  );
};

export default AiProductInfo;
