import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import { GroupedUnity } from '../table/GroupedUnityListTable';

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #ddd',
  backgroundColor: '#fff',
  '& > div': {
    gap: 10,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '&.data': {
      gap: 5,
    },
    '& > span': {
      borderRadius: 6,
      border: '1px solid #ddd',
      display: 'flex',
      flexDirection: 'column',
      '& .title': {
        backgroundColor: '#ddd',
      },
      '& > p': {
        padding: 5,
      },
    },
  },
});

type GroupedUnityItemModuleProps = {
  unity: GroupedUnity;
};

const GroupedUnityItemModule: React.FC<GroupedUnityItemModuleProps> = ({ unity }) => {
  return (
    <CustomListItemButton>
      <Typography variant="subtitle2" color="primary">
        {unity.unidade}
      </Typography>
      <div>
        <span>
          <Typography className="title" variant="body2" fontSize={12}>
            <b>MC:</b>
          </Typography>
          <Typography color="#30519f" align="right">
            {unity.formattedMc}
          </Typography>
        </span>

        <span>
          <Typography className="title" variant="body2" fontSize={12}>
            <b>Valor total:</b>
          </Typography>
          <Typography color="green" align="right">
            {unity.formattedTotal}
          </Typography>
        </span>
      </div>
      <div className="data">
        <Typography fontWeight={600} color="#ff0000" variant="body2" fontSize={12}>
          Spiff:
        </Typography>
        <Typography align="right" color="#ff0000" variant="body2" fontSize={12}>
          {unity.formattedSpiff}
        </Typography>

        <Typography fontWeight={600} variant="body2" fontSize={12}>
          Qtd:
        </Typography>
        <Typography align="right" variant="body2" fontSize={12}>
          {unity.qtd}
        </Typography>

        <Typography fontWeight={600} variant="body2" fontSize={12}>
          Percentual:
        </Typography>

        <Typography align="right" variant="body2" fontSize={12}>
          {unity.formattedPercentual}
        </Typography>

        <Typography fontWeight={600} variant="body2" fontSize={12}>
          Custo:
        </Typography>
        <Typography align="right" variant="body2" fontSize={12}>
          {unity.formattedCusto}
        </Typography>
      </div>
    </CustomListItemButton>
  );
};

export default GroupedUnityItemModule;
