import React from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import { RentalQueryParams } from './ManagedSales';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '& .actions': {
    marginTop: 20,
    justifyContent: 'flex-end',
    display: 'flex',
    gap: 10,
  },
});

type ManagedSalesMobileFilterProps = {
  onExited(): void;
  queryParams: RentalQueryParams;
  handleQueryParamsChange(index: keyof RentalQueryParams, value: any): void;
  handleSubmit(): void;
};

const ManagedSalesMobileFilter: React.FC<ManagedSalesMobileFilterProps> = ({
  onExited,
  handleQueryParamsChange,
  queryParams,
  handleSubmit,
}) => {
  function handleSearchClick(closeModal: () => void) {
    handleSubmit();
    closeModal();
  }

  return (
    <DialogInput onExited={onExited}>
      <Container>
        <TextField
          fullWidth
          select
          value={queryParams.active}
          onChange={event => handleQueryParamsChange('active', event.target.value)}
          label="Status"
          margin="normal"
        >
          <MenuItem value="1">ativo</MenuItem>
          <MenuItem value="0">inativo</MenuItem>
        </TextField>
        <KeyboardDatePicker
          label="Data inicial"
          value={queryParams.initial_date || null}
          onChange={date => handleQueryParamsChange('initial_date', date)}
          textFieldProps={{
            fullWidth: true,
            margin: 'normal',
          }}
        />

        <KeyboardDatePicker
          label="Data Final"
          value={queryParams.final_date || null}
          onChange={date => handleQueryParamsChange('final_date', date)}
          textFieldProps={{
            fullWidth: true,
            margin: 'normal',
          }}
        />

        <div className="actions">
          <DialogInputConsumer>
            {context => (
              <>
                <Button onClick={context.handleClose} color="primary" variant="text">
                  fechar
                </Button>
                <Button onClick={() => handleSearchClick(context.handleClose)} color="primary" variant="contained">
                  buscar
                </Button>
              </>
            )}
          </DialogInputConsumer>
        </div>
      </Container>
    </DialogInput>
  );
};

export default ManagedSalesMobileFilter;
