import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import PaginationProvider from 'hooks/pagination';
import { useDisplayMode } from 'hooks/useDisplayMode';
import Pagination from 'components/pagination/Pagination';
import { usePurchaseOrder } from '../../hooks/PurchaseOrderProvider';
import NoData from 'components/no-data/NoData';
import useTableOrder from 'hooks/tableOrder';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import PurchaseOrderTotal from '../../PurchaseOrderTotal';
import { subDays } from 'date-fns';
import { PurchaseOrder } from 'types/purchaseOrder';
import PurchaseOrderListTable from './table/PurchaseOrderListTable';

const Content = styled('div')({
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
});

const PurchaseOrderShopping: React.FC = () => {
  const [displayMode] = useDisplayMode();
  const { orders, loading } = usePurchaseOrder();
  const [filtered, setFiltered] = useState<PurchaseOrder[]>([]);
  const [orderedIndex, sort] = useTableOrder();

  const filteredExpired = useCallback(() => {
    const today = subDays(new Date(), 1);
    const _filtered = filtered.filter(item => item.formattedNewForecast < today.toISOString());

    return _filtered.length;
  }, [filtered]);

  useEffect(() => {
    setFiltered(orders);
  }, [orders]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <>
      {loading.registration ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filtered.length === 0 ? (
        <NoData message="Nenhum pedido de compras em aberto" />
      ) : (
        <PaginationProvider>
          <Content>
            <PurchaseOrderTotal
              quantityOrderExpired={filteredExpired()}
              quantityOrder={filtered.length}
              shopping={true}
            />
            {displayMode === 'list' ? (
              <PurchaseOrderListTable orders={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
            ) : (
              <>
                {/* <PurchaseOrderListTable orders={filtered} handleSort={handleSort} orderedIndex={orderedIndex} /> */}
              </>
            )}
            <Pagination count={filtered.length} />
          </Content>
        </PaginationProvider>
      )}
    </>
  );
};

export default PurchaseOrderShopping;
