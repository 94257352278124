import React, { useRef } from 'react';
import { Button, CircularProgress, InputAdornment, TextField, styled } from '@mui/material';
import { WasteValidation } from '../../validation/wasteValidation';
import { useWaste } from '../../hooks/useWaste';
import CpfCnpjInput from 'components/masked-input/CpfCnpjInput';

interface WasteSelectCustomerProps {
  handleValidation(): void;
  disableButton: boolean;
  validation: WasteValidation;
}

const Content = styled('div')({
  gap: 15,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
});

const CustomButton = styled(Button)({
  alignSelf: 'center',
  justifySelf: 'end',
  maxWidth: 200,
});

const WasteSelectCustomer: React.FC<WasteSelectCustomerProps> = ({ validation, disableButton, handleValidation }) => {
  const { handleDocumentChange, loadingCustomer, handleChange, waste } = useWaste();

  const inputs = {
    cnpj_cpf: useRef<HTMLInputElement>(null),
    nome: useRef<HTMLInputElement>(null),
  };

  return (
    <Content>
      <TextField
        label="CPF/CNPJ"
        placeholder="CPF ou CNPJ"
        fullWidth
        error={!!validation?.cnpj_cpf}
        value={waste.cnpj_cpf}
        helperText={validation?.cnpj_cpf}
        onChange={e => handleDocumentChange(e.target.value)}
        margin="normal"
        inputRef={inputs.cnpj_cpf}
        InputProps={{
          inputComponent: CpfCnpjInput as any,
        }}
      />

      <TextField
        label="Nome do cliente"
        placeholder="Nome do cliente"
        fullWidth
        disabled={disableButton}
        error={!!validation?.nome}
        value={waste.nome}
        helperText={validation?.nome}
        onChange={e => handleChange('nome', e.target.value)}
        margin="normal"
        inputRef={inputs.nome}
        InputProps={{
          endAdornment: loadingCustomer && (
            <InputAdornment position="start">
              <CircularProgress size={20} />
            </InputAdornment>
          ),
        }}
      />

      <CustomButton disabled={disableButton} variant="contained" onClick={handleValidation}>
        Avançar
      </CustomButton>
    </Content>
  );
};

export default WasteSelectCustomer;
