import { Dispatch, SetStateAction, useState } from 'react';
import { H2iCustomer } from 'types/h2iCustomer';
import * as yup from 'yup';

export interface CustomerContactsValidation {
  email?: string;
  telefone?: string;
}

type UseCustomerContactsValidation = [
  CustomerContactsValidation,
  Dispatch<SetStateAction<CustomerContactsValidation>>,
  (customer: H2iCustomer) => Promise<void>,
];

export function useCustomerContactsValidation(): UseCustomerContactsValidation {
  async function handleCustomerContactValidation(customer: H2iCustomer) {
    const schema = yup.object().shape({
      email: yup.string().email('Informe um e-mail válido').required('O e-mail de contato é obrigatório'),
      telefone: yup.string().required('O telefone é obrigatório'),
    });

    try {
      await schema.validate(customer);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setCustomerContactValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [customerContactValidation, setCustomerContactValidation] = useState<CustomerContactsValidation>({});
  return [customerContactValidation, setCustomerContactValidation, handleCustomerContactValidation];
}
