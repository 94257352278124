import React from 'react';
import JustificationItemTable from './JustificationItemTable';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { Typography, styled } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Justification } from 'types/justification';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
  '&.headerItemNumeric': {
    justifyContent: 'flex-end',
    marginRight: 20,
  },
});

type JustificationListTableProps = {
  justifications: Justification[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const JustificationListTable: React.FC<JustificationListTableProps> = ({
  justifications,
  handleSort,
  orderedIndex,
}) => {
  const { tableTemplate } = useTable();

  return (
    <>
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <HeaderItem
              className={item.dataType === 'number' ? 'headerItemNumeric' : ''}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody>
          {justifications.map(justification => (
            <TableRow key={justification.id}>
              <JustificationItemTable justification={justification} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default JustificationListTable;
