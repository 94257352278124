import React from 'react';
import { Typography, Grid } from '@mui/material';
import ResponsibleItemTable from './ResponsibleItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import { ResponsibleStockBalanceData } from 'types/reports/stockBalance';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { responsibleStockTableTemplate } from '../responsibleStockTableTemplate';
import { useStockBalance } from '../../hooks/useStockBalance';
import ResponsibleMoreMenu from '../ResponsibleMoreMenu';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
    marginRight: 10,
  },
  headerItemQuantity: {
    justifyContent: 'center',
  },
  body: {
    maxHeight: 'calc(100vh - 100px)',
  },
});

type ResponsibleListTableProps = {
  responsibles: ResponsibleStockBalanceData[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  isSubgroup: boolean;
};

const ResponsibleListTable: React.FC<ResponsibleListTableProps> = ({
  responsibles,
  handleSort,
  orderedIndex,
  isSubgroup,
}) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { moreResponsible } = useStockBalance();

  return (
    <Grid container>
      <TableContainer tableTemplate={responsibleStockTableTemplate}>
        <TableContent>
          <TableHeader>
            {responsibleStockTableTemplate.map(item => (
              <div
                className={
                  item.dataType === 'number'
                    ? `${classes.headerItem} ${classes.headerItemNumeric}`
                    : item.dataType === 'quantity'
                    ? `${classes.headerItem} ${classes.headerItemQuantity}`
                    : classes.headerItem
                }
                key={item.id}
                onClick={() => handleSort(item.originalId)}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
          </TableHeader>
          <TableBody className={classes.body}>
            {responsibles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((responsible, index) => (
              <>
                <TableRow style={{ minHeight: 10 }} key={index}>
                  <ResponsibleItemTable isSubgroup={isSubgroup} responsible={responsible} />
                </TableRow>
                {moreResponsible?.id_responsavel === responsible.id_responsavel && (
                  <ResponsibleMoreMenu key={responsible.id_responsavel} responsible={responsible.responsavel} />
                )}
              </>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </Grid>
  );
};

export default ResponsibleListTable;
