import { CHANGE, ProductActionsType, SET_PRICES, SET_PRODUCT } from './types';
import { ProductEdit } from 'types/product';

export function productChange(index: keyof ProductEdit, value: any): ProductActionsType {
  return {
    type: CHANGE,
    index,
    value,
  };
}

export function setProduct(product: ProductEdit): ProductActionsType {
  return {
    type: SET_PRODUCT,
    product,
  };
}

export function setPrices(prices: any): ProductActionsType {
  return {
    type: SET_PRICES,
    prices,
  };
}
