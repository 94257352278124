import React from 'react';
import DialogInput from 'components/dialogs/DialogInput';
import { Button, styled } from '@mui/material';
import { Search } from '@mui/icons-material';
import FilterMoreForm from 'components/filter-more/FilterMoreForm';
import InsideLoading from 'components/loading/InsideLoading';
import { AbcProductFilterDates, AbcProductFilterParams } from './AbcProduct';
import { useFetchMoreFilters } from 'components/filter-more/hooks/useFetchMoreFilters';
import { makeStyles } from '@mui/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import { ptBR } from 'date-fns/locale';

const useStyles = makeStyles({
  item: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    lineClamp: 1,
    WebkitBoxOrient: 'vertical',
    width: 350,
  },
  formControl: {
    minWidth: 120,
    maxWidth: 400,
  },
  container: {
    height: 500,
    display: 'grid',
    gap: 20,
    flex: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

const DatePickerContainer = styled('div')(props => ({
  marginTop: 15,
  display: 'flex',
  gap: 5,
}));

type AbcProductDateFilterProps = {
  onExited(): void;
  onSearch(): void;
  filter: AbcProductFilterParams;
  handleChangeFilter(index: keyof AbcProductFilterParams, value: any): void;
  handleDatesFilterChange(index: keyof AbcProductFilterDates, value: any): void;
};

const AbcProductDateFilter: React.FC<AbcProductDateFilterProps> = ({
  onExited,
  onSearch,
  filter,
  handleChangeFilter,
  handleDatesFilterChange,
}) => {
  const classes = useStyles();
  const { units, segments, subCategories, categories, subGroups, manufacturers, loading } = useFetchMoreFilters();

  function handleChangeInitialRangeDates(dates: [Date | null, Date | null]) {
    const [start, end] = dates;
    handleDatesFilterChange('initialDate', start);
    handleDatesFilterChange('finalDate', end);
  }

  function handleChangeRangeDatesToCompare(dates: [Date | null, Date | null]) {
    const [start, end] = dates;
    handleDatesFilterChange('initialDateToCompare', start);
    handleDatesFilterChange('finalDateToCompare', end);
  }

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <div className={classes.container}>
        {loading ? (
          <InsideLoading />
        ) : (
          <div className={classes.form}>
            <FilterMoreForm
              manufacturers={manufacturers}
              subCategories={subCategories}
              subGroups={subGroups}
              categories={categories}
              segments={segments}
              filter={filter}
              handleChangeFilter={handleChangeFilter}
              units={units}
            />

            <DatePickerContainer>
              <DatePicker
                selected={filter.dates.initialDate}
                onChange={dates => handleChangeInitialRangeDates(dates)}
                startDate={filter.dates.initialDate}
                endDate={filter.dates.finalDate}
                selectsRange
                locale={ptBR}
              />
            </DatePickerContainer>

            <DatePickerContainer>
              <DatePicker
                selected={filter.dates.initialDateToCompare}
                onChange={dates => handleChangeRangeDatesToCompare(dates)}
                startDate={filter.dates.initialDateToCompare}
                endDate={filter.dates.finalDateToCompare}
                selectsRange
                locale={ptBR}
              />
            </DatePickerContainer>

            <div>
              <Button
                endIcon={<Search fontSize="small" />}
                type="submit"
                color="primary"
                variant="contained"
                onClick={onSearch}
                fullWidth
              >
                Pesquisar
              </Button>
            </div>
          </div>
        )}
      </div>
    </DialogInput>
  );
};

export default AbcProductDateFilter;
