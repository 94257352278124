import React, { useState } from 'react';
import Dialog from 'components/dialogs/Dialog';
import { TransferAccount } from 'types/transferAccount';
import { Button, Typography, styled } from '@mui/material';
import { useApp } from 'hooks/app';
import { useMessaging } from 'hooks/messaging';
import { usePermissionRules } from 'hooks/permissionRules';
import ConfirmPassword from 'components/confirm-password/ConfirmPassword';

interface EditTransferAccountProps {
  onExited(): void;
  onSave(): void;
  selectedTransferAccount: TransferAccount;
}

const Container = styled('div')({
  flex: 1,
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled('div')({
  gap: 10,
  display: 'grid',
  gridTemplateColumns: '100px 1fr',
});

const Divider = styled('div')({
  height: 10,
  width: '100%',
  borderTop: '2px dashed #000',
  borderBottom: '2px dashed #000',
});

const Actions = styled('div')({
  display: 'flex',
  gap: 10,
  maxWidth: 300,
  alignItems: 'end',
  flex: 1,
  margin: '0 auto',
});

const EditTransferAccount: React.FC<EditTransferAccountProps> = ({ onExited, onSave, selectedTransferAccount }) => {
  const { financialApi } = useApp();
  const { checkPermission } = usePermissionRules();
  const [showConfirmAction, setShowConfirmAction] = useState(false);
  const { handleOpen } = useMessaging();

  function onDeleteTransfer() {
    setShowConfirmAction(false);

    financialApi
      .get('/excluirtransferencia', { params: { id: selectedTransferAccount.id } })
      .then(() => {
        handleOpen('Transferência excluída com sucesso');
        onSave();
      })
      .catch(err => {
        handleOpen('Operação não concluída');
        console.error(err);
      });
  }

  return (
    <Dialog maxWidth="md" height="70vh" onExited={onExited} title="H2i - Transferência">
      <Container>
        {showConfirmAction && (
          <ConfirmPassword onExited={() => setShowConfirmAction(false)} onValidate={onDeleteTransfer} />
        )}
        <Typography align="center" variant="h6">
          H2i - Transferência entre contas
        </Typography>

        <Content>
          <Typography variant="button" fontSize={12}>
            Cliente:
          </Typography>
          <Typography>{selectedTransferAccount.usuario}</Typography>

          <Typography variant="button" fontSize={12}>
            Origem:
          </Typography>
          <Typography>{selectedTransferAccount.origem}</Typography>
        </Content>

        <Divider></Divider>

        <Content>
          <Typography variant="button" fontSize={12}>
            Destino:
          </Typography>
          <Typography>{selectedTransferAccount.destino}</Typography>

          <Typography variant="button" fontSize={12}>
            Valor:
          </Typography>
          <Typography>{selectedTransferAccount.formattedValue}</Typography>

          <Typography variant="button" fontSize={12}>
            Data:
          </Typography>
          <Typography>{selectedTransferAccount.data}</Typography>

          <Typography variant="button" fontSize={12}>
            Observação:
          </Typography>
          <Typography>{selectedTransferAccount.observacao}</Typography>
        </Content>

        {checkPermission('financial.moviment.transfer-account.remove-transfer-button') && (
          <Actions>
            <Button variant="outlined" color="error" onClick={() => setShowConfirmAction(true)}>
              Excluir transferência
            </Button>
          </Actions>
        )}
      </Container>
    </Dialog>
  );
};

export default EditTransferAccount;
