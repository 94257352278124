import React, { FormEvent } from 'react';
import { IconButton, TextField, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import { Search } from '@mui/icons-material';

interface WasteProductsModalFilterProps {
  searchText: string;
  disabled: boolean;
  onChange(value: string): void;
  handleSearch(e?: FormEvent<HTMLFormElement>): void;
}

const Filter = styled('form')({
  display: 'flex',
  maxWidth: 400,
});

const WasteProductsModalFilter: React.FC<WasteProductsModalFilterProps> = ({
  onChange,
  handleSearch,
  disabled,
  searchText,
}) => {
  return (
    <FilterBox>
      <Filter onSubmit={handleSearch}>
        <TextField
          disabled={disabled}
          value={searchText}
          onChange={e => onChange(e.target.value)}
          label="Filtro"
          placeholder="Digite a descrição do produto"
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => handleSearch()}>
                <Search />
              </IconButton>
            ),
          }}
        />

        <button type="submit" style={{ display: 'none' }} />
      </Filter>
    </FilterBox>
  );
};

export default WasteProductsModalFilter;
