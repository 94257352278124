import React, { useEffect, useState } from 'react';
import { CourseStudentProvider } from '../hooks/useCourseStudent';
import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import RentalForm from '../CourseStudentForm';
import { api } from 'services/api';
import history from 'services/history';
import { useCourseStudentValidation } from '../validation/couseStudentValidation';
import Loading from 'components/loading/Loading';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import RentalNewAppbarButtons from '../new/CourseStudentAppbarButtons';
import { useParams } from 'react-router-dom';
import { useFetchCourseStudent } from '../../hooks/useFetchCourseStudent';
import { useCourseStudentReducer } from '../reducer/reducer';
import { changeCourseStudentAction, setCourseStudentAction } from '../reducer/actions';
import { CourseStudent } from 'pages/course-students/types/courseStudent';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 20,
  marginBottom: 200,
});

const CourseStudentUpdate: React.FC = () => {
  const [courseStudent, dispatch] = useCourseStudentReducer();
  const [saving, setSaving] = useState(false);
  const [validation, setValidation, validate] = useCourseStudentValidation();
  const { showErrorDialog } = useErrorHandler();
  const { id } = useParams<{ id: string }>();
  const [loadedCourseStudent] = useFetchCourseStudent(id);

  useEffect(() => {
    dispatch(setCourseStudentAction(loadedCourseStudent));
  }, [loadedCourseStudent, dispatch]);

  function handleChange(index: keyof CourseStudent, value: any) {
    dispatch(changeCourseStudentAction(index, value));
  }

  function handleValidation() {
    setValidation({});

    validate(courseStudent)
      .then(handleSubmit)
      .catch(error => console.error(error));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/course-students/${id}`, courseStudent)
      .then(() => {
        history.push('/course-students');
      })
      .catch(error => {
        console.error(error);
        showErrorDialog({
          error,
          message: 'Não foi possível registrar aluno para curso',
        });
      })
      .finally(() => setSaving(false));
  }

  return (
    <CourseStudentProvider value={{ courseStudent, handleChange, dispatch }}>
      {saving && <Loading />}

      <Appbar title="Participantes" ActionsComponent={<RentalNewAppbarButtons handleValidation={handleValidation} />} />

      <PageHeader title="Editar participante" backUrl="/course-students" />

      <Container>
        <RentalForm validation={validation} handleChange={handleChange} courseStudent={courseStudent} />
      </Container>
    </CourseStudentProvider>
  );
};

export default CourseStudentUpdate;
