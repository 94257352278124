import { AbcProductUnity, ComparedAbcProductUnity } from 'types/abcProduct';
import { AbcProductDates } from '../types';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { formatDates } from '../helpers/formatDates';
import { uuidv4 } from 'helpers/uuidv4';

type HandleCompareManufacturesPayload = {
  units: AbcProductUnity[];
  toCompare: AbcProductUnity[];
  dates: AbcProductDates;
  total: number;
};

type FormatPayload = {
  units: ComparedAbcProductUnity[];
  initialDate: string;
  finalDate: string;
  total: number;
};

export function handleCompareUnits({
  units,
  toCompare,
  dates,
  total,
}: HandleCompareManufacturesPayload): ComparedAbcProductUnity[] {
  const _dates = formatDates(dates);

  const _units = format({ units, initialDate: _dates.initialDate, finalDate: _dates.finalDate, total });

  const _toCompare = format({
    units: toCompare,
    initialDate: _dates.toCompareInitialDate,
    finalDate: _dates.toCompareFinalDate,
    total,
  });

  return merge(_units, _toCompare);
}

function format(payload: FormatPayload): AbcProductUnity[] {
  return payload.units.map(item => ({
    ...item,
    formattedTotal: moneyFormat(item.total),
    formattedCoust: moneyFormat(item.custo),
    formattedMc: moneyFormat(item.mc),
    formattedSpiff: moneyFormat(item.spiff),
    formattedUnitary: moneyFormat(item.unitario),
    formattedBilling: percentFormat((item.total / payload.total) * 100),
    billing: (item.total / payload.total) * 100,
    formattedName: `${payload.initialDate} - ${payload.finalDate}`,
    uuid: uuidv4(),
  }));
}

function merge(units: AbcProductUnity[], toCompare: AbcProductUnity[]): ComparedAbcProductUnity[] {
  const merged = [...units, ...toCompare];

  const singleNames = new Set(merged.map(item => item.unidade));

  const names = [...singleNames].map(item => ({
    unidade: item,
    type: 'subtitle',
    uuid: uuidv4(),
  })) as AbcProductUnity[];

  return [...names, ...merged];
}
