import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import ManufacturerItemModule from './ManufacturerItemModule';
import { AbcProductManufacturer } from 'types/abcProduct';

type ManufacturerListModuleProps = {
  manufacturers: AbcProductManufacturer[];
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  columnGap: 100,
  flex: 1,
});

const ManufacturerListModule: React.FC<ManufacturerListModuleProps> = ({ manufacturers }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {manufacturers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((manufacturer, index) => (
        <ManufacturerItemModule key={index + 1} manufacturer={manufacturer} />
      ))}
    </CustomList>
  );
};

export default ManufacturerListModule;
