import React, { useEffect, useRef } from 'react';
import { QueryParams } from './ExportEmail';
import { useExportEmail } from './hooks/useExportEmail';
import FilterBox from 'components/filter-box/FilterBox';
import { MenuItem, TextField, Theme } from '@mui/material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import SelectBranch from 'components/select-branches/SelectBranch';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '190px 200px 1fr 1fr 1fr',
    gap: 10,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '& > .search-params': {
      display: 'grid',
      gap: 10,
      gridTemplateColumns: '300px 300px',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr 1fr',
      },
    },
  },
  filterBox: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

type ExportEmailFilterBoxProps = {
  queryParams: QueryParams;
  handleQueryParamsChange(index: keyof QueryParams, value: any): void;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const ExportEmailFilterBox: React.FC<ExportEmailFilterBoxProps> = ({
  queryParams,
  handleQueryParamsChange,
  setDisplayMode,
  displayMode,
}) => {
  const classes = useStyles();
  const { segments } = useExportEmail();
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
    inputSearchRef.current?.setSelectionRange(0, inputSearchRef.current.value.length);
  }, []);

  return (
    <div className={classes.filterBox}>
      <FilterBox>
        <div className={classes.filter}>
          <SelectBranch branch_id={queryParams.id_filial} handleChange={e => handleQueryParamsChange('id_filial', e)} />
          <TextField
            select
            autoFocus
            value={queryParams.id_atividade}
            label="Selecione a atividade"
            onChange={e => handleQueryParamsChange('id_atividade', e.target.value)}
          >
            {segments.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
            <MenuItem key="" value="">
              Todas as atividades
            </MenuItem>
          </TextField>
          <TextField
            select
            autoFocus
            value={queryParams.tipo}
            onChange={e => handleQueryParamsChange('tipo', e.target.value)}
            label="Periodo de vendas"
          >
            <MenuItem key="C" value="C">
              Cadastrados sem compras
            </MenuItem>
            <MenuItem key="P" value="P">
              Cadastrados com compras
            </MenuItem>
            <MenuItem key="V" value="V">
              Periodo com vendas
            </MenuItem>
            <MenuItem key="S" value="S">
              Periodo sem vendas
            </MenuItem>
            <MenuItem key="N" value="N">
              Todos os períodos
            </MenuItem>
          </TextField>
          <KeyboardDatePicker
            label="Data inicial"
            value={queryParams.data_ini}
            onChange={date => handleQueryParamsChange('data_ini', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={queryParams.data_fim}
            onChange={date => handleQueryParamsChange('data_fim', date)}
          />
        </div>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </FilterBox>
    </div>
  );
};

export default ExportEmailFilterBox;
