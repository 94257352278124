import React, { MouseEvent } from 'react';
import { AbcProductBranch } from 'types/abcProduct';
import { Typography, styled, ListItemButton } from '@mui/material';
import { useAbcProducts } from '../../../hooks/useAbcProducts';
import Dialog from 'components/dialogs/Dialog';
import BranchMoreMenu from '../BranchMoreMenu';

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #ddd',
  backgroundColor: '#fff',
  '& > div': {
    gap: 10,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '&.data': {
      gap: 5,
    },
    '& > span': {
      borderRadius: 6,
      border: '1px solid #ddd',
      display: 'flex',
      flexDirection: 'column',
      '& .title': {
        backgroundColor: '#ddd',
      },
      '& > p': {
        padding: 5,
      },
    },
  },
});

type BranchItemModuleProps = {
  branch: AbcProductBranch;
};

const BranchItemModule: React.FC<BranchItemModuleProps> = ({ branch }) => {
  const { setSelectedBranch, setMoreBranch, moreBranch } = useAbcProducts();

  function handleClickMore(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    setSelectedBranch(branch);
    setMoreBranch(moreBranch ? null : branch);
  }
  return (
    <>
      <CustomListItemButton onClick={handleClickMore}>
        <Typography variant="subtitle2" color="primary">
          {branch.filial}
        </Typography>
        <div>
          <span>
            <Typography className="title" variant="body2" fontSize={12}>
              <b>MC:</b>
            </Typography>
            <Typography color="#30519f" align="right">
              {branch.formattedMc}
            </Typography>
          </span>

          <span>
            <Typography className="title" variant="body2" fontSize={12}>
              <b>Total:</b>
            </Typography>
            <Typography color="green" align="right">
              {branch.formattedTotal}
            </Typography>
          </span>
        </div>
        <div className="data">
          <Typography fontWeight={600} variant="body2" fontSize={12}>
            Spiff:
          </Typography>
          <Typography color="#ff0000" align="right" variant="body2" fontSize={12}>
            {branch.formattedSpiff}
          </Typography>

          <Typography fontWeight={600} variant="body2" fontSize={12}>
            Custo:
          </Typography>

          <Typography align="right" variant="body2" fontSize={12}>
            {branch.formattedCoust}
          </Typography>
        </div>
      </CustomListItemButton>
      {moreBranch?.id_filial === branch.id_filial && (
        <Dialog title={moreBranch.filial} onExited={() => setMoreBranch(null)}>
          <BranchMoreMenu key={branch.filial} branch={branch} />
        </Dialog>
      )}
    </>
  );
};

export default BranchItemModule;
