import { IconButton, styled } from '@mui/material';
import React from 'react';
import { Add, Print } from '@mui/icons-material';
import { useExpenses } from './hooks/useExpenses';

const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const ExpensesActions: React.FC = () => {
  const { setIsReportOpen, setIsNewExpenseOpen } = useExpenses();

  return (
    <Container>
      <IconButton onClick={() => setIsNewExpenseOpen(true)} color="inherit">
        <Add />
      </IconButton>
      <IconButton color="inherit" onClick={() => setIsReportOpen(true)}>
        <Print />
      </IconButton>
    </Container>
  );
};

export default ExpensesActions;
