import { useReducer, Dispatch } from 'react';
import { moneyFormat } from 'helpers/numberFormat';
import { PurchaseOrder, PurchaseOrderPayment, PurchaseOrderProduct } from 'types/purchaseOrder';
import { PurchaseOrderActions } from './types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { parsePtBRDate } from 'helpers/parsePtBRDate';

export const orderInitialState: PurchaseOrder = {
  base_icms: 0,
  base_pis_cofins: 0,
  base_st: 0,
  caminho_xml: '',
  cfop: '',
  chave_nfe: '',
  cliente: '',
  cnpj: '',
  confirmado: 'N',
  frete_por_conta: '',
  xml_informado: 'N',
  descricao_cfop: '',
  filial: '',
  codigo_fornecedor: 0,
  data_previsao: '',
  data: '',
  data_emissao_nfe: '',
  nova_previsao: '',
  id_filial: 0,
  modelo_nf: 'N',
  modelo_serie: '',
  notafiscal: '',
  numero_venda_ret: '',
  serie_nfe: '',
  tipo_frete: '',
  formattedNewForecast: '',
  valor_cofins: 0,
  valor_pis: 0,
  valor_total_pedido: 0,
  valor_desconto: 0,
  valor_despesa: 0,
  valor_frete: 0,
  valor_icms: 0,
  valor_ipi: 0,
  valor_pago: 0,
  valor_st: 0,
  obs: '',
  xml: '',
  soma_frete_total: '',
  status: '',
  calcula_imposto: 'S',
  codigo_barra_fisico: '',
  codigo_cfop: '',
  codigo_filial: 0,
  codigo_filial_sint: '',
  codigo_pedido: 0,
  data_cancelamento: '',
  hora_cancelamento: '',
  prazo_dias_fornecedor: '',
  previsao: '',
  soma_desconto_bcicms: 'N',
  soma_desconto_bcst: 'N',
  soma_desconto_ipi: 'N',
  soma_desp_bcicms: 'N',
  soma_desp_bcst: 'N',
  soma_frete_bcicms: 'N',
  soma_frete_ipi: 'N',
  soma_frete_bst: 'N',
  soma_ipi_bcicms: 'N',
  soma_ipi_bcst: 'N',
  soma_ipi_frete: 'N',
  validado_exp: '',
  valor_frete_ipi: '',
  valor_produtos: 0,
  rateio_desconto: '',
  recebimento_confirmado: 'N',
  reduz_base_frete: 'N',
  serie_informada: 'N',
  itens: [],
  pagamentos: [],
  date_forecast: new Date(),
  date_issue: new Date(),
  date_new_forecast: new Date(),
  date: new Date(),
};

export function orderReducer(state: PurchaseOrder, action: PurchaseOrderActions): PurchaseOrder {
  switch (action.type) {
    case 'SET_PURCHASE_ORDER': {
      return {
        ...action.order,
        valor_pago: action.order.pagamentos ? action.order.pagamentos.reduce((sum, item) => sum + item.valor, 0) : 0,
        cfop: action.order.cfop.replace(',', ''),
        date_forecast: parsePtBRDate(action.order.previsao),
        date: parsePtBRDate(action.order.data),
        date_issue: parsePtBRDate(action.order.data_emissao_nfe),
        date_new_forecast: parsePtBRDate(action.order.nova_previsao),
        itens: action.order.itens
          ? action.order.itens.map(product => ({
              ...product,
              formattedValue: moneyFormat(product.valor),
              formattedTotal: moneyFormat(product.valor * product.quantidade),
            }))
          : [],
        pagamentos: action.order.pagamentos
          ? action.order.pagamentos.map(product => ({
              ...product,
              formattedValue: moneyFormat(product.valor),
            }))
          : [],
      };
    }

    case 'CHANGE_PURCHASE_ORDER': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'ADD_PURCHASE_ORDER_PRODUCT': {
      if (!action.overwrite) {
        const products: PurchaseOrderProduct[] = [
          ...state.itens,
          {
            cancelado: 'N',
            id: '',
            codigo_produto: action.product.productId,
            produto: action.product.product,
            valor: action.product.value,
            quantidade: action.product.quantity,
            valor_subtotal: action.product.quantity * action.product.value,
            formattedValue: moneyFormat(action.product.value),
            formattedTotal: moneyFormat(action.product.quantity * action.product.value),
            base_icms_desconto: 0,
            base_st: 0,
            cfop: action.product.cfop,
            codigo_barra: '',
            cst: '',
            icms: 0,
            vl_desc_unitario: 0,
            valor_st_despesa: 0,
            vl_frete_unitario: 0,
            valor_icms_desconto: 0,
            vl_ipi_unitario: 0,
            vl_st_unitario: 0,
            base_icms_despesa: 0,
            base_icms_frete: 0,
            base_icms_ipi: 0,
            base_icms_prod: 0,
            base_st_desconto: 0,
            base_st_despesa: 0,
            base_st_frete: 0,
            base_st_prod: 0,
            codigo_cfop: '',
            codigo_filial: '', // igual codigo_filial do pedido
            codigo_fornecedor: 0, // igual codigo_fornecedor do pedido
            codigo_pedido: 0, // igual codigo_pedido do pedido
            codigo_prod_nfe: '',
            codigo_produto_sint: '',
            codigo_usuario: '',
            cst_cofins: '',
            cst_ipi: '',
            cst_pis: '',
            custo_final: 0,
            data: '',
            hora: '',
            icms_st: 0,
            id_filial: 0, // igual id_filial do pedido
            movimenta_estoque: 'S',
            mva: 0,
            ncm: '',
            previsao: '', // igual previsao do pedido
            qtd_serie: '',
            red_icms: 0,
            reduz_base_frete: '',
            revenda: '',
            serie_informada: '',
            soma_frete_ipi: '',
            tipo: '',
            usuario: '',
            validado_nfe: '',
            valor_frete_ipi: 0,
            valor_icms_despesa: 0,
            valor_icms_frete: 0,
            valor_icms_ipi: 0,
            valor_icms_prod: 0,
            valor_ipi_item: 0,
            valor_ipi_total: 0,
            valor_st_custo: 0,
            valor_st_desconto: 0,
            valor_st_frete: 0,
            valor_st_prod: 0,
            vl_acr_unitario: 0,
          },
        ];
        const total = products.reduce((carry, product) => carry + product.quantidade * product.valor, 0);

        return {
          ...state,
          itens: products,
          valor_total_pedido: total,
        };
      }

      const existingItemIndex = state.itens
        .filter(item => item.cancelado !== 'S')
        .findIndex(item => item.codigo_produto === action.product.productId);

      const products = [...state.itens];
      products[existingItemIndex].valor = action.product.value;
      products[existingItemIndex].quantidade = action.product.quantity;
      products[existingItemIndex].cfop = action.product.cfop;
      products[existingItemIndex].formattedValue = moneyFormat(action.product.value);
      products[existingItemIndex].formattedTotal = moneyFormat(action.product.quantity * action.product.value);
      products[existingItemIndex].valor_subtotal = action.product.quantity * action.product.value;
      const total = products.reduce((carry, product) => carry + product.quantidade * product.valor, 0);

      return {
        ...state,
        itens: products,
        valor_total_pedido: total,
      };
    }

    case 'REMOVE_PURCHASE_ORDER_PRODUCT': {
      if (action.action === 'update') {
        const products = [...state.itens];
        products[action.index].cancelado = 'S';

        const total = products
          .filter(item => item.cancelado !== 'S')
          .reduce((carry, product) => carry + product.quantidade * product.valor, 0);

        return {
          ...state,
          valor_total_pedido: total,
          itens: products,
        };
      } else {
        const products = state.itens.filter((item, index) => index !== action.index);
        const total = products.reduce((carry, product) => carry + product.quantidade * product.valor, 0);

        return {
          ...state,
          itens: products,
          valor_total_pedido: total,
        };
      }
    }

    case 'ADD_PURCHASE_ORDER_PAYMENT': {
      const payments: PurchaseOrderPayment[] = [
        ...state.pagamentos,
        {
          id: '',
          cancelado: 'N',
          vencimento: format(action.payment.date, 'P', { locale: ptBR }),
          valor: action.payment.value,
          formattedValue: moneyFormat(action.payment.value),
        },
      ];
      const total = payments.reduce((carry, payment) => carry + 1 * payment.valor, 0);

      return {
        ...state,
        pagamentos: payments,
        valor_pago: total,
      };
    }

    case 'REMOVE_PURCHASE_ORDER_PAYMENT': {
      if (action.action === 'update') {
        const payments = [...state.pagamentos];
        payments[action.index].cancelado = 'S';

        const total = payments
          .filter(item => item.cancelado !== 'S')
          .reduce((carry, product) => carry + product.valor, 0);

        return {
          ...state,
          valor_pago: total,
          pagamentos: payments,
        };
      } else {
        const payments = state.pagamentos.filter((payment, index) => index !== action.index);
        const total = payments.reduce((carry, payment) => carry + 1 * payment.valor, 0);

        return {
          ...state,
          pagamentos: payments,
          valor_pago: total,
        };
      }
    }

    default: {
      return state;
    }
  }
}

type UsePurchaseOrderReducer = [PurchaseOrder, Dispatch<PurchaseOrderActions>];

export function usePurchaseOrderReducer(): UsePurchaseOrderReducer {
  const [order, dispatch] = useReducer(orderReducer, orderInitialState);

  return [order, dispatch];
}
