import React, { Dispatch, SetStateAction } from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { Template } from 'types/template';
import { usePagination } from 'hooks/pagination';
import TemplateItemModule from './TemplateItemModule';

type TemplateListModuleProps = {
  templates: Template[];
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const TemplateListModule: React.FC<TemplateListModuleProps> = ({ templates, setAnchorEl }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {templates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(template => (
            <TemplateItemModule setAnchorEl={setAnchorEl} key={template.id} template={template} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default TemplateListModule;
