import React, { useEffect, useState } from 'react';
import { useApp } from 'hooks/app';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import useTableOrder from 'hooks/tableOrder';
import NoData from 'components/no-data/NoData';
import { moneyFormat } from 'helpers/numberFormat';
import InsideLoading from 'components/loading/InsideLoading';
import { AbcProductManufacturer } from 'types/abcProduct';
import Pagination from 'components/pagination/Pagination';
import PaginationProvider from 'hooks/pagination';
import { AbcCustomer } from 'types/abcCustomer';
import CustomerListTable from 'pages/reports/commercial/abc/customer/list/table/CustomerListTable';
import { styled } from '@mui/material';
import { useAbcManufacturer } from '../../hooks/useAbcManufacturer';

interface ManufacturerMoreMenuProps {
  manufacturer: AbcProductManufacturer;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& > div': {
    margin: 0,
    minHeight: 0,
    overflow: 'visible',
  },
});

const ManufacturerMoreMenu: React.FC<ManufacturerMoreMenuProps> = ({ manufacturer }) => {
  const { h2iApi } = useApp();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const { filter, searchValue } = useAbcManufacturer();
  const [products, setCustomers] = useState<AbcCustomer[]>([]);
  const [filtered, setFiltered] = useState<AbcCustomer[]>([]);

  useEffect(() => {
    setFiltered(products);
  }, [products]);

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    h2iApi
      .get('/api/relcurvaabccliente', {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.selectedBranchId === 0 ? '' : filter.selectedBranchId,
          id_subgrupo: filter.productId || '',
          cliente: searchValue,
          tipo_venda: filter.typeSale,
          id_categoria: filter.categoryIds.join(','),
          id_subcategoria: filter.subCategoryIds.join(','),
          pedido_por: filter.madeBy,
          id_segmento: filter.segmentIds.join(','),
          id_unidade: filter.unitIds.join(','),
          id_fabricante: manufacturer.id,
        },
      })
      .then(response => {
        const _products = response.data.map(item => {
          item.formattedTotal = moneyFormat(item.total);
          item.formattedCoust = moneyFormat(item.custo);
          item.formattedMc = moneyFormat(item.mc);
          item.formattedSpiff = moneyFormat(item.spiff);
          item.formattedUnitary = moneyFormat(item.unitario);

          return item;
        });

        setCustomers(_products);
      })
      .finally(() => setLoading(false));
  }, [h2iApi, filter, manufacturer, searchValue]);

  function handleSort(index: string) {
    const filteredB = sort(index, filtered);
    setFiltered(filteredB);
  }

  return (
    <PaginationProvider>
      <Container>
        {loading ? (
          <InsideLoading />
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum cliente encontrado." />
        ) : (
          <CustomerListTable customers={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
        )}
        <Pagination count={filtered.length} />
      </Container>
    </PaginationProvider>
  );
};

export default ManufacturerMoreMenu;
