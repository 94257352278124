import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { OrderAuthorization } from 'types/orderAuthorization';
import { OrdersAuthorizationFilter } from '../billing/InvoiceBilling';

type OrdersAuthorizationContextValue = {
  more: OrderAuthorization | null;
  filter: OrdersAuthorizationFilter;
  selectedOrder: OrderAuthorization | null;
  setMore: Dispatch<SetStateAction<OrderAuthorization | null>>;
  setDialog: Dispatch<SetStateAction<boolean>>;
  handleDenyOrder(order: OrderAuthorization): void;
  handleApproveOrder(order: OrderAuthorization): void;
  setSelectedOrder(order: OrderAuthorization | null): void;
  setOrders: Dispatch<SetStateAction<OrderAuthorization[]>>;
  handleFormatOrder(order: OrderAuthorization): OrderAuthorization;
  handleChangeFilter(key: keyof OrdersAuthorizationFilter, value: Date | null): void;
};

const OrdersAuthorizationContext = createContext<OrdersAuthorizationContextValue>(
  {} as OrdersAuthorizationContextValue,
);
export const OrdersAuthorizationProvider = OrdersAuthorizationContext.Provider;

export function useOrdersAuthorization(): OrdersAuthorizationContextValue {
  const context = useContext(OrdersAuthorizationContext);
  return context;
}
