import React, { MouseEvent } from 'react';

import { MonthlyBranch } from 'types/monthlyComparison';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography } from '@mui/material';
import { KeyboardArrowDown, MoreHoriz } from '@mui/icons-material';
import { useMonthlyComparison } from 'pages/reports/monthly-comparison/hooks/useMonthlyComparison';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
});

type BranchItemTableProps = {
  branch: MonthlyBranch;
  isSubgroup: boolean;
};

const BranchItemTable: React.FC<BranchItemTableProps> = ({ branch, isSubgroup }) => {
  const classes = styles();
  const { tableTemplate } = useTable();
  const { setSelectedBranch, setMoreBranch, moreBranch } = useMonthlyComparison();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedBranch(branch);
    setMoreBranch(moreBranch ? null : branch);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <IconButton key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'more' && isSubgroup ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClickMore}>
              <KeyboardArrowDown style={moreBranch?.id === branch.id ? { transform: 'rotate(180deg)' } : undefined} />
            </IconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography variant="body2">{branch[item.id as keyof MonthlyBranch]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default BranchItemTable;
