import React from 'react';
import { Typography, alpha, Theme } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useBranches } from '../../hooks/useSaleActivity';
import { SaleActivity } from 'types/reports/saleActivity';
import SaleActivityItemTable from './saleActivityItemTable';
import { saleActivityTableTemplate } from './../../saleActivityTableTemplate';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
  selectedCustomer: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
  },
}));

type SaleActivityListTableProps = {
  salesActivity: SaleActivity[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const SaleActivityListTable: React.FC<SaleActivityListTableProps> = ({ salesActivity, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { setSelectedSaleActivity } = useBranches();

  function handleClick(saleActivity: SaleActivity) {
    setSelectedSaleActivity(saleActivity);
  }

  return (
    <>
      <TableContent>
        <TableHeader>
          {saleActivityTableTemplate.map(item => (
            <div
              className={
                item.dataType === 'number' ? `${classes.headerItem} ${classes.headerItemNumeric}` : classes.headerItem
              }
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {salesActivity.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(saleActivity => (
            <TableRow onClick={() => handleClick(saleActivity)} key={saleActivity.atividade}>
              <SaleActivityItemTable saleActivity={saleActivity} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default SaleActivityListTable;
