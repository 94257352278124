import React from 'react';
import { Typography, styled } from '@mui/material';
import { TotalizingAccount } from 'types/totalizingAccount';
import { accountsTableTemplate } from '../../accountsTableTemplate';

type TotalizingItemTableProps = {
  account: TotalizingAccount;
};

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

const TotalizingItemTable: React.FC<TotalizingItemTableProps> = ({ account }) => {
  return (
    <>
      {accountsTableTemplate.map(item => (
        <NumericData key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
          <Typography
            variant="body2"
            fontWeight={item.originalId === 'saldo' ? 600 : 400}
            color={item.originalId === 'saldo' ? (account[item.originalId] < 0 ? '#f30000' : 'green') : '#000'}
          >
            {account[item.id]}
          </Typography>
        </NumericData>
      ))}
    </>
  );
};

export default TotalizingItemTable;
