import React from 'react';
import { IconButton } from '@mui/material';
import { Done } from '@mui/icons-material';

interface ResellerActionProps {
  handleSubmit(): void;
}

const ResellerActions: React.FC<ResellerActionProps> = ({ handleSubmit }) => {
  return (
    <IconButton color="inherit" onClick={handleSubmit}>
      <Done />
    </IconButton>
  );
};

export default ResellerActions;
