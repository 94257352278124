import React, { ReactNode, useEffect, useState } from 'react';
import CreatePortal from 'components/portal/CreatePortal';
import { PrintProvider } from './hook/usePrint';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#fff',
    transition: 'transform 0.2s ease',
    transform: 'translateY(100vh)',
    display: 'flex',
    top: 0,
    bottom: 0,
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 1300,
    overflow: 'auto',
    justifyContent: 'center',
  },
});

interface PrintProps {
  onExited?(): void;
  children: ReactNode;
}

const Print: React.FC<PrintProps> = ({ children, onExited }) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (open === undefined) setTimeout(() => setOpen(true), 100);
  }, [open, onExited]);

  function handleClose() {
    setOpen(false);
  }

  return (
    <CreatePortal>
      <PrintProvider value={{ handleClose }}>
        <div
          onTransitionEnd={!open && !!onExited ? onExited : undefined}
          style={open ? { transform: 'translateY(0)' } : undefined}
          className={classes.container}
        >
          <div className="print">{children}</div>
        </div>
      </PrintProvider>
    </CreatePortal>
  );
};

export default Print;
