import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import { ItemsSold } from 'types/reports/itemsSold';
import { Typography } from '@mui/material';
import ExcelButtonDownload from 'components/excel-button-download/ExcelButtonDownload';
import { useDefaultExcelExport } from 'hooks/useDefaultExcelExport';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 30,
  },
});

interface ItemsSoldExportProps {
  data: ItemsSold[];
  onExited(): void;
}

const ItemsSoldExport: React.FC<ItemsSoldExportProps> = ({ data, onExited }) => {
  const classes = styles();
  const { handleDownload } = useDefaultExcelExport(data);

  return (
    <Dialog onExited={onExited} title="Export para Excel" maxWidth="sm" height="60vh">
      <div className={classes.container}>
        <Typography>Sua planilha está pronta.</Typography>
        <ExcelButtonDownload handleDownload={handleDownload} />
      </div>
    </Dialog>
  );
};

export default ItemsSoldExport;
