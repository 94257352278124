import { Grid, TextField } from '@mui/material';
import PhoneInput from 'components/masked-input/PhoneInput';
import { usePermissionRules } from 'hooks/permissionRules';
import React, { useEffect, useRef } from 'react';
import { useCustomer } from '../../hooks/useCustomer';
import { CustomerContactsValidation } from '../validation/CustomerContactsValidation';
import MaskedInput from 'react-text-mask';

interface CustomerContactsProps {
  validation: CustomerContactsValidation;
}

const CustomerContacts: React.FC<CustomerContactsProps> = ({ validation }) => {
  const { customer, handleChange } = useCustomer();
  const { checkPermission } = usePermissionRules();

  const inputs = {
    telefone: useRef<MaskedInput>(null),
    email: useRef<HTMLInputElement>(null),
    email_nfe: useRef<HTMLInputElement>(null),
  };
  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    if (key === 'telefone') {
      inputs.telefone.current?.inputElement.focus();
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <>
      <Grid container direction={'column'} spacing={0}>
        <Grid container direction={'row'} spacing={1}>
          <Grid item xs={6} md={4}>
            <TextField
              autoFocus
              fullWidth
              label="Telefone"
              placeholder="Telefone"
              margin="normal"
              inputRef={inputs.telefone}
              error={!!validation.telefone}
              helperText={validation.telefone}
              value={customer?.telefone}
              type="phone"
              autoComplete="phone"
              InputProps={{
                inputComponent: PhoneInput as any,
              }}
              inputMode="numeric"
              onChange={e => handleChange('telefone', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              label="WhatsApp"
              placeholder="WhatsApp"
              margin="normal"
              value={customer?.whatsapp}
              type="phone"
              autoComplete="phone"
              InputProps={{
                inputComponent: PhoneInput as any,
              }}
              inputMode="numeric"
              onChange={e => handleChange('whatsapp', e.target.value)}
            />
          </Grid>
          {checkPermission('registrations.customers.email') && (
            <>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  label="E-mail"
                  placeholder="E-mail"
                  margin="normal"
                  value={customer?.email}
                  onChange={e => handleChange('email', e.target.value)}
                  inputRef={inputs.email}
                  error={!!validation.email}
                  helperText={validation.email}
                  autoComplete="email"
                  type="email"
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  disabled
                  fullWidth
                  label="E-mail NFE"
                  placeholder="E-mail NFE"
                  margin="normal"
                  value={customer?.email}
                  onChange={e => handleChange('email_nfe', e.target.value)}
                  inputRef={inputs.email_nfe}
                  autoComplete="email"
                  type="email"
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  disabled
                  fullWidth
                  label="E-mail Cobrança"
                  placeholder="E-mail Cobrança"
                  margin="normal"
                  value={customer?.email}
                  onChange={e => handleChange('email_cobranca', e.target.value)}
                  type="email"
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default CustomerContacts;
