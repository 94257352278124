import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import React, { useState } from 'react';
import { Typography, styled } from '@mui/material';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import { PurchaseOrder } from 'types/purchaseOrder';
import PurchaseOrderItemTable from './PurchaseOrderItemTable';
import history from 'services/history';
import PurchaseOrderListMenu from '../PurchaseOrderListMenu';
import { purchaseOrderTableTemplate } from 'pages/purchase/order/purchaseOrderTableTemplate';
import TableContainer from 'components/table/TableContainer';

interface PurchaseOrderListTableProps {
  orders: PurchaseOrder[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

const PurchaseOrderListTable: React.FC<PurchaseOrderListTableProps> = ({ handleSort, orderedIndex, orders }) => {
  const { rowsPerPage, page } = usePagination();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <TableContainer tableTemplate={purchaseOrderTableTemplate}>
      <PurchaseOrderListMenu anchorEl={anchorEl} onExited={() => setAnchorEl(null)} />
      <TableContent useMinHeight={false}>
        <TableHeader>
          {purchaseOrderTableTemplate.map(item => (
            <HeaderItem
              key={item.id}
              className={item.dataType === 'number' ? 'numericData' : undefined}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody useMaxHeight={true} maxHeight={480}>
          {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, index) => (
            <TableRow onClick={() => history.push(`/purchasing/order/${order.codigo_pedido}`)} key={index}>
              <PurchaseOrderItemTable setAnchorEl={setAnchorEl} order={order} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default PurchaseOrderListTable;
