import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import CreditLimitRequestItemModule from './CreditLimitRequestItemModule';
import { CreditLimitRequest } from 'types/creditLimitRequest';

type CreditLimitRequestListModuleProps = {
  creditLimitRequests: CreditLimitRequest[];
};

const CreditLimitRequestListModule: React.FC<CreditLimitRequestListModuleProps> = ({ creditLimitRequests }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {creditLimitRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(creditLimitRequest => (
            <CreditLimitRequestItemModule key={creditLimitRequest.id} creditLimitRequest={creditLimitRequest} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default CreditLimitRequestListModule;
