import React, { MouseEvent, useState } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { Justification } from 'types/justification';
import { useJustifications } from '../../hooks/useJustifications';
import JustificationListMenu from '../JustificationListMenu';

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
  width: 350,
});

type JustificationItemTableProps = {
  justification: Justification;
};

const JustificationItemTable: React.FC<JustificationItemTableProps> = ({ justification }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { tableTemplate } = useTable();
  const { setSelectedJustification } = useJustifications();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  }

  function handleClick() {
    setSelectedJustification(justification);
  }

  return (
    <>
      <JustificationListMenu
        key={justification.id}
        justification={justification}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />

      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <CustomIconButton size="small" onClick={handleMoreClick} key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : item.id === 'link_arquivo' ? (
            <a href={justification[item.id]} target="_blank" key={item.id} rel="noreferrer">
              <Tooltip title={justification[item.id]}>
                <Description color="primary" variant="caption">
                  {justification[item.id]}
                </Description>
              </Tooltip>
            </a>
          ) : (
            <NumericData
              onClick={handleClick}
              className={item.dataType === 'number' ? 'numericData' : undefined}
              key={item.id}
            >
              <Tooltip title={justification[item.id]}>
                <Description variant="caption">{justification[item.id]}</Description>
              </Tooltip>
            </NumericData>
          ),
        )}
    </>
  );
};

export default JustificationItemTable;
