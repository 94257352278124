import React from 'react';
import { Typography, styled } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { UserProfile } from 'types/userProfile';

type UserProfileRulesProps = {
  userProfile?: UserProfile;
};

const Rules = styled('div')({
  display: 'flex',
  padding: '20px 0',
  flexDirection: 'column',
  '& div': {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 7,
    },
  },
});

const UserProfileRules: React.FC<UserProfileRulesProps> = ({ userProfile }) => {
  return (
    <Rules>
      <Typography gutterBottom>Permissões do usuário</Typography>
      {userProfile &&
        userProfile.access_rules.map(rule => (
          <div key={rule.id}>
            <DoneIcon color="secondary" />
            <Typography>{rule.title}</Typography>
          </div>
        ))}
    </Rules>
  );
};

export default UserProfileRules;
