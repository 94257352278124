import { Invoice } from 'types/invoices';
import { NewInvoice, NewInvoiceActions } from './types';

export function invoiceChange(index: keyof NewInvoice, value: any): NewInvoiceActions {
  return {
    type: 'INVOICE_CHANGE',
    index,
    value,
  };
}

export function setInvoice(invoice: Invoice): NewInvoiceActions {
  return {
    type: 'SET_INVOICE',
    invoice,
  };
}
