import React from 'react';
import Dialog from 'components/dialogs/Dialog';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

interface ResellerSintegraBAProps {
  onExited(): void;
  html: string;
}

const ResellerSintegraBA: React.FC<ResellerSintegraBAProps> = ({ onExited, html }) => {
  const classes = styles();

  return (
    <Dialog onExited={onExited} fullScreen title="Consulta Sintegra BA">
      <div className={classes.container} dangerouslySetInnerHTML={{ __html: html }} />
    </Dialog>
  );
};

export default ResellerSintegraBA;
