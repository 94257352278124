import React from 'react';
import { Grid, List } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { AppropriationDate } from 'types/appropriationDate';
import AppropriationDateItemModule from './AppropriationDateItemModule';

interface AppropriationDateListModuleProps {
  dates: AppropriationDate[];
}

const AppropriationDateListModule: React.FC<AppropriationDateListModuleProps> = ({ dates }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {dates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(date => (
            <AppropriationDateItemModule key={date.id} date={date} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default AppropriationDateListModule;
