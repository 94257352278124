import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { SegmentStockBalanceData } from 'types/reports/stockBalance';

import { makeStyles } from '@mui/styles';

type SegmentItemModuleProps = {
  segment: SegmentStockBalanceData;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
});

const SegmentItemModule: React.FC<SegmentItemModuleProps> = ({ segment }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem}>
      <Typography color="primary" variant="caption" gutterBottom>
        {segment.id_segmento}
      </Typography>
      <Typography variant="body1">{segment.segmento}</Typography>
      <div className="content">
        <Typography variant="body2" color="textSecondary">
          Estoque
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {segment.estoque}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Estoque total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {segment.estTotal}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Custo total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {segment.formattedCustoTotal}
        </Typography>
      </div>
    </ListItem>
  );
};

export default SegmentItemModule;
