import { ManagedSale } from 'pages/managed-sales/types/managedSale';
import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export type ManagedSaleValidation = {
  customer_name?: string;
  customer_document_number?: string;
};

type UseManagedSaleValidation = [
  ManagedSaleValidation,
  Dispatch<SetStateAction<ManagedSaleValidation>>,
  (rental: ManagedSale) => Promise<void>,
];

export function useManagedSaleValidation(): UseManagedSaleValidation {
  async function handleValidation(rental: ManagedSale) {
    const schema = yup.object().shape({
      customer_document_number: yup.string().required('o documento do cliente é obrigatório'),
      customer_name: yup.string().required('o nome do cliente é obrigatório'),
      products: yup.array().required('os produtos são obrigatórios'),
    });

    try {
      await schema.validate(rental);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<ManagedSaleValidation>({});
  return [validation, setValidation, handleValidation];
}
