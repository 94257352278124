import { CompatibilityProduct } from 'pages/compatibilities/types/compatibilityProduct';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export type ProductContextValue = {
  selectedProduct: CompatibilityProduct | null;
  setSelectedProduct: Dispatch<SetStateAction<CompatibilityProduct | null>>;
};

export const ProductsContext = createContext<ProductContextValue>({} as ProductContextValue);
export const ProductsContextConsumer = ProductsContext.Consumer;
export const ProductsContextProvider = ProductsContext.Provider;

export function useCompatibilityProducts(): ProductContextValue {
  return useContext(ProductsContext);
}
