import React from 'react';
import { RegisterForm } from 'types/registerForm';
import DocumentUpload from 'components/document-upload/DocumentUpload';
import { RegisterProofResidenceValidation } from './validation/registerProofResidenceValidation';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  imageUploader: {
    marginBottom: 15,
  },
});

interface RegisterFormProofResidenceProps {
  register: RegisterForm;
  handleChange(index: keyof RegisterForm, value: any): void;
  validation: RegisterProofResidenceValidation;
}

const RegisterFormProofResidence: React.FC<RegisterFormProofResidenceProps> = ({
  register,
  handleChange,
  validation,
}) => {
  const classes = styles();

  return (
    <DocumentUpload
      handleRemoveImage={() => handleChange('partner_proof_residence_copy', null)}
      handleSetImage={image => handleChange('partner_proof_residence_copy', image)}
      validationError={validation.partner_proof_residence_copy}
      document={register.partner_proof_residence_copy}
      label="cópia do comprovante de residência (png, jpg ou pdf)"
      className={classes.imageUploader}
    />
  );
};

export default RegisterFormProofResidence;
