import React from 'react';
import CustomNavLink from 'components/sidebar/CustomNavLink';
import { ListItemIcon, ListItemText, ListItemButton, ListItemButtonProps, styled } from '@mui/material';
import { useApp } from 'hooks/app';

interface StyledListItemProps {
  nested: number;
}

const StyledListItem = styled(ListItemButton)(props => ({
  padding: 0,
  paddingTop: 4,
  paddingBottom: 4,
  flex: 0,
}));

const StyledLink = styled(CustomNavLink)<StyledListItemProps>(props => ({
  paddingLeft: props.nested ? props.theme.spacing(props.nested) : 16,
  borderLeft: '2px solid transparent',
  minHeight: 30,
  display: 'flex',
  cursor: 'pointer',
  flex: 1,
  alignItems: 'center',
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  '& > svg': {
    fontSize: 22,
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  flex: 0.9,
  '& > span': {
    fontSize: 13,
  },
}));

interface SidebarItemProps extends ListItemButtonProps {
  to?: string;
  text: string;
  nested?: number;
  icon: React.ReactElement;
  onClick?: () => void | null;
  isLink?: boolean;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  to,
  icon,
  text,
  nested = 0,
  onClick,
  isLink = true,
  className,
  ...rest
}) => {
  const app = useApp();

  function handleClick() {
    if (app.isMobile || app.windowWidth <= 1280) {
      app.handleOpenMenu();
    }
  }

  return (
    <StyledListItem {...rest} onClick={onClick || handleClick} className={className}>
      <StyledLink nested={nested} to={to}>
        <StyledListItemIcon>{icon}</StyledListItemIcon>
        <StyledListItemText primary={text} />
      </StyledLink>
    </StyledListItem>
  );
};

export default SidebarItem;
