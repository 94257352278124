import { Menu } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

type CourseStudentListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const CourseStudentListMenu: React.FC<CourseStudentListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  return <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}></Menu>;
};

export default CourseStudentListMenu;
