import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import PartnerItemModule from './PartnerItemModule';
import { Partner } from 'types/partner';

type SiteBannerListModuleProps = {
  partners: Partner[];
};

const SiteBannerListModule: React.FC<SiteBannerListModuleProps> = ({ partners }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {partners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(partner => (
            <PartnerItemModule key={partner.id} partner={partner} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default SiteBannerListModule;
