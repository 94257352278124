import { createContext, useContext } from 'react';
import { Branch } from 'types/siteBranch';

type BranchesContextValue = {
  selectedBranch: Branch | null;
  setSelectedBranch(partner: Branch | null): void;
};

const BranchesContext = createContext<BranchesContextValue>({} as BranchesContextValue);
export const BranchesProvider = BranchesContext.Provider;

export function useBranches(): BranchesContextValue {
  const context = useContext(BranchesContext);
  return context;
}
