import React, { useState, FormEvent, useEffect, useRef } from 'react';
import { Button, TextField, styled } from '@mui/material';
import { ProjectContactFormContent } from 'types/project';
import { ProjectContactValidation } from './validation/projectContactValidation';
import PhoneInput from 'components/masked-input/PhoneInput';
import MaskedInput from 'react-text-mask';

const Form = styled('form')(props => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 0,
  maxWidth: 900,
  gap: 10,
  alignItems: 'baseline',
  marginBottom: 30,
  '& > button': {
    flexShrink: 0,
  },
  [props.theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
  },
}));

interface ProjectContactFormProps {
  handleSubmit(data: ProjectContactFormContent): Promise<void>;
  validation: ProjectContactValidation;
}

const ProjectContactForm: React.FC<ProjectContactFormProps> = ({ handleSubmit: defaultHandleSubmit, validation }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    phone: useRef<MaskedInput>(null),
    role: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    if (key === 'phone') {
      inputs[key].current?.inputElement.focus();
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    defaultHandleSubmit({
      name,
      role,
      phone,
    })
      .then(() => {
        setPhone('');
        setName('');
        setRole('');
        inputs.name.current?.focus();
      })
      .catch(err => console.error(err));
  }

  return (
    <Form onSubmit={event => handleSubmit(event)}>
      <TextField
        label="Nome"
        placeholder="Nome do contato"
        margin="normal"
        autoFocus
        value={name}
        onChange={event => setName(event.target.value)}
        inputRef={inputs.name}
        error={!!validation.name}
        helperText={validation.name}
        fullWidth
      />

      <TextField
        label="Telefone"
        placeholder="Telefone do contato"
        margin="normal"
        value={phone}
        onChange={event => setPhone(event.target.value)}
        inputRef={inputs.phone}
        error={!!validation.phone}
        helperText={validation.phone}
        fullWidth
        InputProps={{
          inputComponent: PhoneInput as any,
        }}
      />

      <TextField
        label="Cargo"
        placeholder="Cargo do contato"
        margin="normal"
        value={role}
        onChange={event => setRole(event.target.value)}
        inputRef={inputs.role}
        error={!!validation.role}
        helperText={validation.role}
        fullWidth
      />

      <Button type="submit" size="small" variant="contained" color="primary">
        inserir
      </Button>
    </Form>
  );
};

export default ProjectContactForm;
