import React from 'react';
import { IconButton } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

interface ModalActionsProps {
  handleSubmit(): void;
}

const ModalActions: React.FC<ModalActionsProps> = ({ handleSubmit }) => {
  return (
    <IconButton color="inherit" onClick={handleSubmit}>
      <DoneIcon />
    </IconButton>
  );
};

export default ModalActions;
