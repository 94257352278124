import { Dispatch, SetStateAction, useState } from 'react';
import { PriceProtectionNew } from 'types/priceProtection';
import * as yup from 'yup';

export type PriceProtectionValidation = {
  branchId?: string;
  due?: string;
  value?: string;
  manufacturerName?: string;
};

type UsePriceProtectionValidation = [
  PriceProtectionValidation,
  Dispatch<SetStateAction<PriceProtectionValidation>>,
  (price: PriceProtectionNew) => Promise<void>,
];

export function usePriceProtectionValidation(): UsePriceProtectionValidation {
  async function handleValidation(price: PriceProtectionNew) {
    const schema = yup.object().shape({
      manufacturerName: yup.string().required('A descrição do título é obrigatória'),
      due: yup.string().required('A data é obrigatório'),
      branchId: yup.string().required('Selecione a filial').nullable(),
      value: yup
        .string()
        .required('O preço é obrigatório')
        .test('valueValidation', 'O preço precisa ser maior que R$ 0,00', value => {
          if (!value) return false;

          if (parseFloat(value) <= 0) return false;

          return true;
        }),
    });

    try {
      await schema.validate(price);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<PriceProtectionValidation>({});
  return [validation, setValidation, handleValidation];
}
