import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';
import { Revenue } from 'types/revenue';

export interface RevenueValidation {
  nome?: string;
}

type UseRevenueValidation = [
  RevenueValidation,
  Dispatch<SetStateAction<RevenueValidation>>,
  (revenue: Revenue) => Promise<void>,
];

export function useRevenueValidation(): UseRevenueValidation {
  async function handleRevenueValidation(revenue: Revenue) {
    const schema = yup.object().shape({
      nome: yup.string().required('O nome é obrigatório'),
    });

    try {
      await schema.validate(revenue);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setRevenueValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [RevenueValidation, setRevenueValidation] = useState<RevenueValidation>({});
  return [RevenueValidation, setRevenueValidation, handleRevenueValidation];
}
