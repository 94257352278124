import React, { useState, useEffect } from 'react';
import useTableOrder from 'hooks/tableOrder';

import ProductList from '../product/table/ProductList';
import { StockBalanceData } from 'types/reports/stockBalance';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';

import { makeStyles } from '@mui/styles';

interface UnityMoreMenuProps {
  items: StockBalanceData[];
}

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      margin: 0,
      minHeight: 0,
      overflow: 'visible',
    },
  },
});

const UnityMoreMenu: React.FC<UnityMoreMenuProps> = ({ items }) => {
  const classes = useStyles();
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<StockBalanceData[]>([]);

  useEffect(() => {
    setFiltered(
      items.map(item => {
        item.formattedCusto = moneyFormat(item.Custo);
        item.formattedCustoTotal = moneyFormat(item.CustoTotal);
        item.formattedEstoque = numberFormat(item.Estoque);
        item.formattedIndex = numberFormat(item.indice);
        return item;
      }),
    );
  }, [items]);

  function handleSort(index: string) {
    const filteredS = sort(index, filtered);
    setFiltered(filteredS);
  }

  return (
    <div className={classes.div}>
      <ProductList isSubgroup={false} products={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
    </div>
  );
};

export default UnityMoreMenu;
