import React from 'react';
import { styled } from '@mui/material';
import { SalesChart } from './charts/SalesChart';
import { ModalityChart } from './charts/ModalityChart';
import { FinancialSalesReportTotal } from './DashboardFinancial';
import { CommissionCategory } from 'types/reports/commissionCategory';
import { Chart, ArcElement } from 'chart.js';

Chart.register(ArcElement);

interface FinancialChartsProps {
  formattedTotalChart: FinancialSalesReportTotal;
  commissionCategory: CommissionCategory[];
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

const FinancialCharts: React.FC<FinancialChartsProps> = ({ commissionCategory, formattedTotalChart }) => {
  return (
    <>
      <Container>
        <SalesChart formattedTotalChart={formattedTotalChart} />
      </Container>
      <ModalityChart commissionCategory={commissionCategory} />
    </>
  );
};

export default FinancialCharts;
