import { TableTemplate } from 'types/tableTemplate';

export const manufacturerTableTemplate: TableTemplate[] = [
  {
    id: 'more',
    description: '',
    originalId: 'more',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'id_fabricante',
    description: 'ID',
    originalId: 'id_fabricante',
    width: 60,
  },
  {
    id: 'fabricante',
    description: 'Fabricante',
    originalId: 'fabricante',
    width: 250,
  },
];
