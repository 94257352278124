import React from 'react';
import { Typography } from '@mui/material';
import { priceProtectionTableTemplate } from 'pages/price-protection/priceProtectionTableTemplate';
import { PriceProtection } from 'types/priceProtection';

type PriceProtectionItemTableProps = {
  data: PriceProtection;
};

const PriceProtectionItemTable: React.FC<PriceProtectionItemTableProps> = ({ data }) => {
  return (
    <>
      {priceProtectionTableTemplate.map(item => (
        <div key={item.id}>
          <Typography variant="body2">{data[item.id]}</Typography>
        </div>
      ))}
    </>
  );
};

export default PriceProtectionItemTable;
