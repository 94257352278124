import React, { FormEvent, useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import { useApp } from 'hooks/app';
import PriceProtectionSteps from './steps/PriceProtectionSteps';
import { Manufacturer } from 'types/manufacturer';
import { PriceProtectionProvider } from './hook/usePriceProtection';
import { useMessaging } from 'hooks/messaging';
import { Button, styled } from '@mui/material';
import { ProductList } from 'types/product';
import { usePriceProtectionValidation } from './validation/priceProtectionValidation';
import { PriceProtectionNew } from 'types/priceProtection';
import { useSelector } from 'store/selector';
import InsideSaving from 'components/loading/InsideSaving';
import history from 'services/history';

interface FormProps {
  step: number;
}

const Form = styled('form')<FormProps>(props => ({
  maxWidth: props.step === 1 ? '500px' : '100%',
  width: '100%',
  margin: '0 auto',
}));

const ButtonActions = styled('div')({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'space-between',
});

const NextButton = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'end',
});

const PriceProtectionNewPage: React.FC = () => {
  const { h2iApi } = useApp();
  const [step, setStep] = useState(1);
  const [priceData, setPriceData] = useState<PriceProtectionNew>({
    branchId: 1,
    due: null,
    manufacturerName: '',
    value: '',
  });
  const user = useSelector(state => state.user);
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [validation, setValidation, validate] = usePriceProtectionValidation();
  const [selectedProduct, setSelectedProduct] = useState<ProductList | null>(null);
  const [selectedManufacturer, setSelectedManufacturer] = useState<Manufacturer | null>(null);

  const getFutureDate = (): Date => {
    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + 30);

    return futureDate;
  };

  useEffect(() => {
    const date = getFutureDate();
    setPriceData(data => ({ ...data, due: date }));
  }, []);

  function handleNext() {
    if (step === 2 && !selectedProduct) {
      handleOpen('Selecione um produto');
      return;
    }

    if (!selectedManufacturer) {
      handleOpen('Selecione um fabricante');
      return;
    }

    setStep(step => step + 1);
  }

  function handleBack() {
    if (step === 2) {
      setSelectedProduct(null);
    }
    if (step === 3) {
      setPriceData(item => ({ ...item, value: '' }));
    }
    setStep(step => step - 1);
  }

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    h2iApi
      .get('/api/getfabricantes')
      .then(response => setManufacturers(response.data))
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [h2iApi]);

  function handleChange(index: keyof PriceProtectionNew, value: any) {
    setValidation({});
    setPriceData(data => ({
      ...data,
      [index]: value,
    }));
  }

  const onSelect = (manufacturer: Manufacturer | null) => {
    setSelectedManufacturer(manufacturer);
    setPriceData(manufacturer ? item => ({ ...item, manufacturerName: manufacturer.nome }) : priceData);
  };

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    e?.preventDefault();
    setValidation({});
    validate(priceData)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    if (!h2iApi || !priceData.due || !selectedProduct || !selectedManufacturer || !user) {
      return;
    }

    if (parseInt(selectedProduct.est) === 0) {
      handleOpen('Não foi possível criar pois o produto não possui estoque');
      return;
    }

    setSaving(true);

    h2iApi
      .post('/api/savepriceprotection', {
        descricao_titulo: priceData.manufacturerName,
        valor: parseFloat(priceData.value) * parseInt(selectedProduct.est),
        id_produto: selectedProduct.codigo_produto,
        vencimento: priceData.due.toLocaleDateString('pt-br'),
        fabricante: selectedManufacturer.nome,
        usuario: user.name,
        id_filial: priceData.branchId,
        estoque: parseInt(selectedProduct.est),
      })
      .then(() => {
        handleOpen('Price protection criado com sucesso!');
        history.push('/price-protection');
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  return (
    <PriceProtectionProvider
      value={{
        validation,
        priceData,
        handleChange,
        manufacturers,
        step,
        loading,
        setSelectedManufacturer,
        selectedManufacturer,
        onSelect,
        setSelectedProduct,
        selectedProduct,
        handleBack,
        handleSubmit: handleValidation,
      }}
    >
      <Appbar title="Price Protection" />

      <Form step={step} onSubmit={handleValidation}>
        {saving && <InsideSaving />}
        <PriceProtectionSteps />

        <ButtonActions>
          {step !== 1 && (
            <Button variant="outlined" onClick={handleBack}>
              Voltar
            </Button>
          )}
          {step !== 3 && (
            <NextButton>
              <Button variant="contained" onClick={handleNext}>
                Avançar
              </Button>
            </NextButton>
          )}
        </ButtonActions>
      </Form>
    </PriceProtectionProvider>
  );
};

export default PriceProtectionNewPage;
