import { Branch } from 'types/branch';
import { BranchActionsType, SET_BRANCHES } from './types';

const INITIAL_STATE: Branch[] = [];

export default function branches(state = INITIAL_STATE, action: BranchActionsType): Branch[] {
  switch (action.type) {
    case SET_BRANCHES: {
      return action.branches;
    }

    default: {
      return state;
    }
  }
}
