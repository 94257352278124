import { Button, styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useSearch from 'hooks/search';
import useTableOrder from 'hooks/tableOrder';
import React, { useEffect, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import history from 'services/history';
import PagesFilterBox from './PagesFilterBox';
import { pagesTableTemplate } from './pagesTableTemplate';
import { PagesProvider } from './hooks/usePages';
import PageListModule from './list/module/PageListModule';
import PageListTable from './list/table/PageListTable';
import BannersActions from './PagesActions';
import { Page } from 'types/page';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const SitePages: React.FC = () => {
  const [pages, setPages] = useState<Page[]>([]);
  const [selectedPage, setSelectedPage] = useState<null | Page>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Page[]>([]);
  const [indexToSearch, setIndexToSearch] = useState('title');
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const search = useSearch();
  const app = useApp();

  useEffect(() => {
    const p = search(searchValue, indexToSearch, pages);

    setFiltered(p);
  }, [pages, searchValue, search, indexToSearch]);

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    api
      .get<Page[]>('/pages', { cancelToken: source.token })
      .then(response => {
        if (request)
          setPages(
            response.data.map(item => {
              item.formattedCreatedAt = format(parseISO(item.created_at), 'P', { locale: ptBR });
              return item;
            }),
          );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (request) setLoading(false);
      });

    return () => {
      if (request) request = false;
      source.cancel();
    };
  }, []);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <PagesProvider value={{ selectedPage, setSelectedPage }}>
      <Appbar title="Site" ActionsComponent={<BannersActions />} />
      <PageHeaderActions
        title="Páginas"
        description="Gestão das páginas site H2i"
        ActionComponent={
          <>
            <Button size="small" variant="contained" color="primary" onClick={() => history.push('/site/page')}>
              Adicionar
            </Button>
          </>
        }
      />
      <TableContainer tableTemplate={pagesTableTemplate}>
        <PagesFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          indexToSearch={indexToSearch}
          setIndexToSearch={setIndexToSearch}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum banner para mostrar" />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <PageListTable pages={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <PageListModule pages={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          </PaginationProvider>
        )}
      </TableContainer>
    </PagesProvider>
  );
};

export default SitePages;
