import { RelatedProductItem } from 'pages/related-product/types/relatedProductItem';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export type ProductContextValue = {
  selectedProduct: RelatedProductItem | null;
  setSelectedProduct: Dispatch<SetStateAction<RelatedProductItem | null>>;
};

export const ProductsContext = createContext<ProductContextValue>({} as ProductContextValue);
export const ProductsContextConsumer = ProductsContext.Consumer;
export const ProductsContextProvider = ProductsContext.Provider;

export function useRelatedProductItems(): ProductContextValue {
  return useContext(ProductsContext);
}
