import { createContext, useContext } from 'react';
import { SellerCommission } from 'types/reports/sellerCommission';
import { SellerCommissionFilter } from '../SellerCommissionReport';

type SellerCommissionContextValue = {
  selectedSellerCommission: SellerCommission | null;
  setSelectedSellerCommission(partner: SellerCommission | null): void;
  handleChangeFilter(index: keyof SellerCommissionFilter, value: any): void;
  filter: SellerCommissionFilter;
};

const SellerCommissionContext = createContext<SellerCommissionContextValue>({} as SellerCommissionContextValue);
export const SellerCommissionProvider = SellerCommissionContext.Provider;

export function useBranches(): SellerCommissionContextValue {
  const context = useContext(SellerCommissionContext);
  return context;
}
