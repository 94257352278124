import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import * as yup from 'yup';
import ieValidator from 'inscricaoestadual';
import { Reseller } from 'types/reseller';

export interface ResellerValidation {
  email?: string;
  ie?: string;
  company_name?: string;
  company_trade_name?: string;
  phone?: string;
}

type UseResellerValidation = [
  ResellerValidation,
  Dispatch<SetStateAction<ResellerValidation>>,
  (reseller: Reseller, isSelfEmployed?: boolean) => Promise<void>,
];

export function useResellerValidation(): UseResellerValidation {
  const handleValidation = useCallback(async (reseller: Reseller, isSelfEmployed = false) => {
    const schema = yup.object().shape({
      email: yup.string().email('Informe um e-mail válido').required('O e-mail de contato é obrigatório'),
      ie: yup
        .string()
        .required(isSelfEmployed ? 'O RG é obrigatório' : 'A inscrição estadual é obrigatória')
        .test('ieValidation', isSelfEmployed ? 'inscrição estadual inválida' : 'O RG é obrigatório', value => {
          if (!value) return false;

          if (isSelfEmployed) return true;

          if (value.toLocaleLowerCase() === 'isento') return true;

          if (!reseller.address.state) return true;

          return ieValidator(value, reseller.address.state);
        }),
      phone: yup.string().required('O telefone é obrigatório'),
      company_name: yup.string().required('O nome é obrigatório'),
      company_trade_name: yup.string().required('O nome fantasia é obrigatório'),
    });

    try {
      await schema.validate(reseller);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }, []);

  const [validation, setValidation] = useState<ResellerValidation>({});
  return [validation, setValidation, handleValidation];
}
