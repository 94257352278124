import React from 'react';
import { OrderPayment } from 'types/orderAuthorization';
import { Typography } from '@mui/material';
import { PaymentTableTemplate } from './paymentTableTemplate';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  quantityData: {
    display: 'flex',
    justifyContent: 'center',
  },
});

type PaymentItemTableProps = {
  item: OrderPayment;
};

const PaymentItemTable: React.FC<PaymentItemTableProps> = ({ item: orderItem }) => {
  const classes = useStyles();

  return (
    <>
      {PaymentTableTemplate.filter(item => !item.notShow).map(item => (
        <div
          key={item.id}
          className={
            item.dataType === 'number'
              ? classes.numericData
              : item.dataType === 'quantity'
              ? classes.quantityData
              : undefined
          }
        >
          <Typography variant="body2">{orderItem[item.id as keyof OrderPayment]}</Typography>
        </div>
      ))}
    </>
  );
};

export default PaymentItemTable;
