import Dialog from 'components/dialogs/Dialog';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'store/selector';
import ProjectReportAppbarActions from './WasteReportAppbarButtons';
import { styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import { WasteOnList } from '../types/waste';
import { useWasteFormatter } from '../hooks/useWasteFormatter';
import { useFetchWaste } from '../hooks/useFetchWaste';
import InsideLoading from 'components/loading/InsideLoading';
import history from 'services/history';
import { useParams } from 'react-router-dom';

const Container = styled('div')({
  '& .label': {
    fontSize: 10,
    color: '#888',
    marginBottom: 7,
  },
});

const Row = styled('div')({
  display: 'grid',
  gridTemplateColumns: '50px 1fr 80px 80px',
  alignItems: 'center',
  '& > p': {
    fontSize: 12,
  },
  '& > .serial_number': {
    gridColumn: '1 / 5',
  },
});

const HeaderRightContent = styled('div')({
  '& p': {
    color: '#888',
    fontSize: 12,
  },
});

const ProductsContent = styled('div')({
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
  rowGap: 5,
  padding: 20,
  border: '1px solid #eee',
  '& .table-header': {
    fontWeight: 'bold',
  },
});

const TotalContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 10,
  border: '1px solid #eee',
  marginTop: 10,
  '& .grid': {
    display: 'grid',
    gridTemplateColumns: '120px 1fr',
    columnGap: 20,
    rowGap: 7,
    '& > p': {
      textAlign: 'right',
    },
    '& .value': {
      fontWeight: 'bold',
    },
  },
});

const Content = styled('div')({
  border: '1px solid #eee',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  marginTop: 10,
  '& > p, span': {
    fontSize: 12,
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
  },
  '& > p > span': {
    fontWeight: 'bold',
  },
});

const HeaderContent = styled('div')({
  backgroundColor: '#eee',
  margin: -20,
  padding: 5,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  marginBottom: 10,
  '& > p': {
    fontSize: 12,
    fontWeight: 'bold',
  },
});

const WasteReport: React.FC = () => {
  const user = useSelector(state => state.user);
  const { id } = useParams<{ id: string }>();
  const [waste, setWaste] = useState<WasteOnList | null>(null);
  const [loadedWaste, loading] = useFetchWaste(id);
  const formatter = useWasteFormatter();

  useEffect(() => {
    setWaste(formatter(loadedWaste));
  }, [formatter, loadedWaste]);

  const formattedTotal = useMemo(() => {
    if (!waste) {
      return moneyFormat(0);
    }
    return moneyFormat(waste?.valor_total);
  }, [waste]);

  return (
    <Dialog
      fullScreen
      title={`REEE #${waste?.id}`}
      onExited={() => history.push('/waste')}
      ComponentActions={<ProjectReportAppbarActions />}
    >
      {loading ? (
        <InsideLoading />
      ) : (
        <Container>
          <div className="page">
            <div className="header">
              <div>
                <h3>Número: {waste?.id}</h3>
              </div>
              <HeaderRightContent>
                <p>{user?.name}</p>
                <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
              </HeaderRightContent>
            </div>

            <Content>
              <HeaderContent>
                <p>Informações da empresa</p>
              </HeaderContent>
              <p>
                <span>Razão social:</span> Eltecno Distribuição de Informática
              </p>
              <p>
                <span>CNPJ:</span> 07.482.781/0001-47
              </p>
              <p>
                <span>Telefone:</span> (71) 3038-7757
              </p>
            </Content>

            <Content>
              <HeaderContent>
                <p>Informações do cliente</p>
              </HeaderContent>
              <p>
                <span>Nome:</span> {waste?.nome}
              </p>
              <p>
                <span>CNPJ/CPF:</span> {waste?.cnpj_cpf}
              </p>

              <p>
                <span>Cidade:</span> {waste?.cidade}, {waste?.uf}
              </p>
              <p>
                <span>Endereço:</span> {waste?.endereco}, {waste?.bairro}
              </p>
              <p>
                <span>Filial:</span> {waste?.filial}
              </p>
            </Content>

            <ProductsContent>
              <HeaderContent>
                <p>Produtos</p>
              </HeaderContent>

              <Row className="table-header">
                <p>Código</p>
                <p>Descrição</p>
                <p className="right">Qtd</p>
                <p className="right">Preço</p>
              </Row>
              {waste?.produtos.map((product, index) => (
                <Row key={index}>
                  <p>{product.id_produto}</p>
                  <p>{product.produto}</p>
                  <p className="right">{product.qtd}</p>
                  <p className="right">{product.formattedValue}</p>
                  <p className="serial_number">
                    Nº série {product.numeros_serie.map(item => item.numero_serie).join(', ')}
                  </p>
                </Row>
              ))}
            </ProductsContent>

            <TotalContent>
              <p className="label">
                <strong>Totais</strong>
              </p>
              <div className="grid">
                <p>Produtos</p>
                <p className="value">{formattedTotal}</p>
              </div>
            </TotalContent>
          </div>
        </Container>
      )}
    </Dialog>
  );
};

export default WasteReport;
