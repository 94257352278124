import { cpfValidator } from 'helpers/cpfValidator';
import { Dispatch, SetStateAction, useState } from 'react';
import { RegisterForm } from 'types/registerForm';
import * as yup from 'yup';

export interface RegisterPartnerValidation {
  partner_rg?: string;
  partner_document?: string;
  partner_name?: string;
  partner_phone?: string;
}

type UseRegisterPartnerValidation = [
  RegisterPartnerValidation,
  Dispatch<SetStateAction<RegisterPartnerValidation>>,
  (register: RegisterForm) => Promise<void>,
];

export function useRegisterPartnerValidation(): UseRegisterPartnerValidation {
  async function handleValidation(register: RegisterForm) {
    const schema = yup.object().shape({
      partner_rg: yup.string().required('O RG é obrigatório'),
      partner_document: yup
        .string()
        .transform((value, originalValue) => {
          return originalValue ? originalValue.replace(/\D/g, '') : '';
        })
        .required(`CPF é obrigatório`)
        .test('docValidation', `documento inválido`, value => {
          if (!value) return false;

          const cnpj = cpfValidator(value);

          return cnpj;
        }),
      partner_phone: yup.string().required('O telefone do sócio é obrigátorio'),
      partner_name: yup.string().required('O nome do sócio é obrigatório'),
    });

    try {
      await schema.validate(register);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<RegisterPartnerValidation>({});
  return [validation, setValidation, handleValidation];
}
