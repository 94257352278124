import React, { useEffect, useRef } from 'react';
import MaskedInput from 'react-text-mask';
import { Agent } from 'types/agent';
import { AccountAgentValidation } from './validation/useAccountAgentValidation';
import { TextField } from '@mui/material';
import CpfInput from 'components/masked-input/CpfInput';
import PhoneInput from 'components/masked-input/PhoneInput';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  row: {
    // display: 'flex',
    columnGap: 10,
    alignItems: 'flex-start',
  },
});

interface AccountAgentFormProps {
  agent: Agent;
  handleChange(index: keyof Agent, value: any): void;
  validation: AccountAgentValidation;
}

const AccountAgentForm: React.FC<AccountAgentFormProps> = ({ agent, handleChange, validation }) => {
  const classes = styles();

  const inputs = {
    nome: useRef<HTMLInputElement>(null),
    rg: useRef<HTMLInputElement>(null),
    rg_emissor: useRef<HTMLInputElement>(null),
    profissao: useRef<HTMLInputElement>(null),
    estado_civil: useRef<HTMLInputElement>(null),
    cpf: useRef<MaskedInput>(null),
    telefone: useRef<MaskedInput>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    if (!inputs[key]) return;

    if (key === 'cpf' || key === 'telefone') {
      inputs[key].current?.inputElement.focus();
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <div>
      <TextField
        inputRef={inputs.nome}
        error={!!validation.nome}
        helperText={validation.nome}
        label="nome"
        placeholder="nome do preposto"
        value={agent.nome}
        onChange={e => handleChange('nome', e.target.value)}
        autoFocus
        margin="normal"
        fullWidth
      />

      <TextField
        inputRef={inputs.cpf}
        error={!!validation.cpf}
        helperText={validation.cpf}
        label="cpf"
        placeholder="cpf do preposto"
        value={agent.cpf}
        onChange={e => handleChange('cpf', e.target.value)}
        inputMode="numeric"
        InputProps={{
          inputComponent: CpfInput as any,
        }}
        margin="normal"
        fullWidth
      />

      <div className={classes.row}>
        <TextField
          inputRef={inputs.rg}
          error={!!validation.rg}
          helperText={validation.rg}
          label="rg"
          placeholder="rg do preposto"
          value={agent.rg}
          onChange={e => handleChange('rg', e.target.value)}
          margin="normal"
          fullWidth
        />
      </div>

      <TextField
        inputRef={inputs.telefone}
        error={!!validation.telefone}
        helperText={validation.telefone}
        label="telefone"
        placeholder="telefone do preposto"
        value={agent.telefone}
        onChange={e => handleChange('telefone', e.target.value)}
        type="phone"
        autoComplete="phone"
        InputProps={{
          inputComponent: PhoneInput as any,
        }}
        margin="normal"
        fullWidth
      />
    </div>
  );
};

export default AccountAgentForm;
