import React from 'react';
import { IconButton } from '@mui/material';
import { Done } from '@mui/icons-material';

type EmailTemplateActionsProps = {
  handleSubmit(): void;
};

const EmailTemplateActions: React.FC<EmailTemplateActionsProps> = ({ handleSubmit }) => {
  return (
    <>
      <IconButton color="inherit" onClick={handleSubmit}>
        <Done />
      </IconButton>
    </>
  );
};

export default EmailTemplateActions;
