import React from 'react';
import DialogInput from 'components/dialogs/DialogInput';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Search } from '@mui/icons-material';
import SelectBranch from 'components/select-branches/SelectBranch';
import { AbcCustomersFilterParams } from './CurveAbcCustomer';
import { useFetchMoreFilters } from 'components/filter-more/hooks/useFetchMoreFilters';
import FilterMoreForm from 'components/filter-more/FilterMoreForm';
import InsideLoading from 'components/loading/InsideLoading';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  item: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    lineClamp: 1,
    WebkitBoxOrient: 'vertical',
    width: 350,
  },
  formControl: {
    minWidth: 120,
    maxWidth: 400,
  },
  container: {
    height: 500,
    display: 'grid',
    gap: 20,
    flex: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

type AbcCustomerDateFilterProps = {
  onExited(): void;
  onSearch(): void;
  filter: AbcCustomersFilterParams;
  handleChangeFilter(index: keyof AbcCustomersFilterParams, value: any): void;
};

const AbcCustomerDateFilter: React.FC<AbcCustomerDateFilterProps> = ({
  onExited,
  onSearch,
  filter,
  handleChangeFilter,
}) => {
  const classes = styles();
  const { units, segments, categories, subCategories, subGroups, manufacturers, loading } = useFetchMoreFilters();

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <div className={classes.container}>
        {loading ? (
          <InsideLoading />
        ) : (
          <div className={classes.form}>
            <FilterMoreForm
              manufacturers={manufacturers}
              categories={categories}
              filter={filter}
              handleChangeFilter={handleChangeFilter}
              segments={segments}
              subCategories={subCategories}
              subGroups={subGroups}
              units={units}
            />

            <Select
              fullWidth
              label="tipo de venda"
              style={{ height: '100%' }}
              value={filter.typeSale}
              onChange={e => handleChangeFilter('typeSale', e.target.value)}
            >
              <MenuItem value="T">Todas</MenuItem>
              <MenuItem value="C">Coligadas</MenuItem>
              <MenuItem value="V">Vendas</MenuItem>
            </Select>

            <SelectBranch
              branch_id={filter.selectedBranchId}
              handleChange={e => handleChangeFilter('selectedBranchId', e)}
            />

            <KeyboardDatePicker
              label="Data inicial"
              value={filter.initialDate}
              onChange={date => handleChangeFilter('initialDate', date)}
            />

            <KeyboardDatePicker
              label="Data Final"
              value={filter.finalDate}
              onChange={date => handleChangeFilter('finalDate', date)}
            />
            <Button type="submit" color="primary" variant="contained" onClick={onSearch}>
              <Typography style={{ marginRight: 5 }} color="inherit">
                Pesquisar
              </Typography>
              <Search fontSize="small" />
            </Button>
          </div>
        )}
      </div>
    </DialogInput>
  );
};

export default AbcCustomerDateFilter;
