import React from 'react';
import { Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { H2iOrder } from 'types/h2iOrder';

type DavReviewItemTableProps = {
  order: H2iOrder;
};

const DavReviewItemTable: React.FC<DavReviewItemTableProps> = ({ order }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id}>
            <Typography variant="body2">{order[item.id]}</Typography>
          </div>
        ))}
    </>
  );
};

export default DavReviewItemTable;
