import React from 'react';
import { OrderIndexData } from 'hooks/tableOrder';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import UnityItemTable from './UnityItemTable';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography, Grid } from '@mui/material';
import { AbcProductUnity } from 'types/abcProduct';
import { unityTableTemplate } from '../../unityTableTemplate';
import UnityMoreMenu from '../UnityMoreMenu';
import { useAbcUnits } from '../../../hooks/useAbcUnits';
import TableHeader from 'components/new-table/TableHeader';
import TableRow from 'components/new-table/TableRow';

type UnityListTableProps = {
  units: AbcProductUnity[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  isSubgroup: boolean;
};

const UnityListTable: React.FC<UnityListTableProps> = ({ units, handleSort, orderedIndex, isSubgroup }) => {
  const { moreUnity } = useAbcUnits();
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={unityTableTemplate}>
          <TableContent>
            <TableHeader className={`${isSubgroup ? '' : 'headerSubgroup'}`}>
              {unityTableTemplate.map(item => (
                <div
                  className={item.dataType === 'number' ? `numericData` : ''}
                  key={item.id}
                  onClick={() => handleSort(item.originalId)}
                >
                  <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </div>
              ))}
            </TableHeader>
            <TableBody>
              {units.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(unity => (
                <>
                  <TableRow style={{ minHeight: 10, padding: 0 }} key={unity.cod}>
                    <UnityItemTable isSubgroup={isSubgroup} unity={unity} />
                  </TableRow>

                  {moreUnity?.unidade === unity?.unidade && <UnityMoreMenu key={unity.cod} unityId={unity.cod} />}
                </>
              ))}
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default UnityListTable;
