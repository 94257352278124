import React from 'react';
import { Typography, alpha, Theme } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useBranches } from '../../hooks/useCommissionCategory';
import CommissionCategoriesItemTable from './CommissionCategoryItemTable';
import TableRow from 'components/table/TableRow';
import TableContent from 'components/table/TableContent';
import TableHeader from 'components/table/TableHeader';
import { CommissionCategory } from 'types/reports/commissionCategory';
import { OrderIndexData } from 'hooks/tableOrder';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import { commissionCategoriesTableTemplate } from '../../template/CommissionCategoryTableTemplate';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
  selectedCustomer: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
  },
}));

type CommissionCategoriesListTableProps = {
  commissionCategories: CommissionCategory[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const CommissionCategoriesListTable: React.FC<CommissionCategoriesListTableProps> = ({
  commissionCategories,
  handleSort,
  orderedIndex,
}) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { setSelectedCommissionCategory } = useBranches();

  function handleClick(commissionCategory: CommissionCategory) {
    setSelectedCommissionCategory(commissionCategory);
  }

  return (
    <TableContent>
      <TableHeader>
        {commissionCategoriesTableTemplate.map(item => (
          <div
            className={
              item.dataType === 'number' ? `${classes.headerItem} ${classes.headerItemNumeric}` : classes.headerItem
            }
            key={item.id}
            onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
          >
            <Typography variant="caption" color="primary">
              {item.description}
            </Typography>
            {orderedIndex.index === item.originalId && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </div>
        ))}
      </TableHeader>
      <TableBody>
        {commissionCategories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(commissionCategory => (
          <TableRow onClick={() => handleClick(commissionCategory)} key={commissionCategory.categoria}>
            <CommissionCategoriesItemTable commissionCategory={commissionCategory} />
          </TableRow>
        ))}
      </TableBody>
    </TableContent>
  );
};

export default CommissionCategoriesListTable;
