import React, { useState, useEffect } from 'react';
import { useSelector } from 'store/selector';
import Appbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import FilterActions from 'components/filter-box/FilterActions';
import DashboardReportTotal from './DashboardReportTotal';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { useFetchCommercialReport } from './hooks/useFetchCommercialReport';
import DashboardReportDateFilter from 'components/chart/dashboard/DashboardReportDateFilter';
import DashboardReportCustomDate from 'components/chart/dashboard/DashboardReportCustomDate';
import DashboardReportHeaderButtons from 'components/chart/dashboard/DashboardReportHeaderButtons';
import { Sales } from 'types/reports/sales';
import useTableOrder from 'hooks/tableOrder';
import Pagination from 'components/pagination/Pagination';
import PaginationProvider from 'hooks/pagination';
import TableContainer from 'components/table/TableContainer';
import { SalesTableTemplate } from 'pages/reports/orders-list/report/SalesTableTemplate';
import Loading from 'components/loading/Loading';
import { useApp } from 'hooks/app';
import { Sale } from 'pages/reports/commercial/abc/product/modal/branch/SelectedBranchListMenu';
import SelectedSale from 'components/sale/SelectedSale';
import BoxReport from 'components/chart/box/BoxReport';
import { BranchStockBalanceData } from 'types/reports/stockBalance';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { SalesAppPercentChart } from './charts/SalesAppPercentChart';
import { SalesAppBranchChart } from './charts/SalesAppBranchChart';
import { AbcProductBranch, AbcProductManufacturer, AbcProductUnity } from 'types/abcProduct';
import DashboardUnityListTable from './unity/UnityListTable';
import DashboardManufacturerListTable from './manufacturer/ManufacturerListTable';
import DashboardBranchListTable from './branch/BranchListTable';
import DashboardSaleListTable from './report-sale/SaleListTable';
import DashboardPaymentListTable from './payment/BranchListTable';
import { CommercialPayment } from 'types/commercialPayment';
import { PaymentsChart } from './charts/PaymentsChart';
import { makeStyles } from '@mui/styles';
import { Chart, ArcElement, CategoryScale, registerables } from 'chart.js';
import SalesListTable from 'pages/reports/orders-list/report/list/table/SalesListTable';
import SalesListModule from 'pages/reports/orders-list/report/list/module/SalesListModule';

Chart.register([ArcElement, CategoryScale], ...registerables);

const useStyles = makeStyles({
  container: {
    gap: 20,
    marginTop: 20,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyContent: 'space-between',
  },
  grid: {
    gap: 20,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyContent: 'space-between',
  },
});

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const [viewSales, setViewSales] = useState(false);
  const [viewStock, setViewStock] = useState(false);
  const [viewPayment, setViewPayment] = useState(false);
  const [viewReportSale, setViewReportSale] = useState(false);
  const [viewProfitability, setViewProfitability] = useState(false);
  const { h2iApi, windowWidth, isMobile, handleOpenMenu, isOpenedMenu } = useApp();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(isMobile || windowWidth < 930 ? 'module' : 'list');
  const [orderedIndex, sort] = useTableOrder();
  const [selectedSale, setSelectedSale] = useState<Sale | null>(null);
  const [loadingSale, setLoadingSale] = useState(false);
  const user = useSelector(state => state.user);
  const [dialogDate, setDialogDate] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterdSales, setFilteredSales] = useState<Sales[]>([]);
  const [filteredBranches, setFilteredBranches] = useState<AbcProductBranch[]>([]);
  const [filteredReportSales, setFilteredReportSales] = useState<BranchStockBalanceData[]>([]);
  const [filteredPayments, setFilteredPayments] = useState<CommercialPayment[]>([]);
  const [filteredUnits, setFilteredUnits] = useState<AbcProductUnity[]>([]);
  const [filteredManufacturers, setFilteredManufacturers] = useState<AbcProductManufacturer[]>([]);
  const [allData, setAllData] = useState<BranchStockBalanceData[]>([]);
  const [
    getDescriptionPageHeader,
    handleDateConfig,
    handleConfirmCustomDates,
    loading,
    dateConfig,
    formattedTotal,
    branches,
    sales,
    units,
    manufacturers,
    payments,
    filter,
    handleChangeFilter,
  ] = useFetchCommercialReport();

  useEffect(() => {
    if (!h2iApi) return;

    h2iApi.get(`/api/relestfilial?id_filial=${filter.branch_id || ''}`).then(response => {
      if (response.data.estoque) {
        setAllData([]);
        return;
      }
      const _data = response.data.filiais.map(item => {
        item.formattedEstoque = numberFormat(item.Estoque);
        item.formattedCusto = moneyFormat(item.Custo);
        item.formattedCustoTotal = moneyFormat(item.CustoTotal);
        return item;
      });
      setAllData(_data);
    });
  }, [h2iApi, filter.branch_id]);

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  function handleSort(index: string) {
    const filtered = sort(index, filterdSales);
    setFilteredSales(filtered);
  }

  function handleSortBranch(index: string) {
    const filtered = sort(index, filteredBranches);
    setFilteredBranches(filtered);
  }

  function handleSortReportSale(index: string) {
    const filtered = sort(index, filteredReportSales);
    setFilteredReportSales(filtered);
  }

  function handleSortPayment(index: string) {
    const filtered = sort(index, filteredPayments);
    setFilteredPayments(filtered);
  }

  function handleSortUnity(index: string) {
    const filtered = sort(index, filteredUnits);
    setFilteredUnits(filtered);
  }

  function handleSortManufacturer(index: string) {
    const filtered = sort(index, filteredManufacturers);
    setFilteredManufacturers(filtered);
  }

  function handleSelectSale(sale: Sales) {
    if (!h2iApi) {
      return;
    }

    setLoadingSale(true);

    h2iApi
      .get(`/api/imprimevenda`, {
        params: {
          id_filial: sale.id_filial,
          numero_venda: sale.numero_venda,
        },
      })
      .then(response => setSelectedSale(response.data[0].venda[0]))
      .catch(err => console.error(err))
      .finally(() => setLoadingSale(false));
  }

  function handleViewStock() {
    setViewStock(!viewStock);
    if (isOpenedMenu) {
      handleOpenMenu();
    }
  }

  function handleViewReportSale() {
    setViewReportSale(!viewReportSale);
    if (isOpenedMenu) {
      handleOpenMenu();
    }
  }

  function handleViewPayment() {
    setViewPayment(!viewPayment);
    if (isOpenedMenu) {
      handleOpenMenu();
    }
  }

  function handleViewProfitability() {
    setViewProfitability(!viewProfitability);
    if (isOpenedMenu) {
      handleOpenMenu();
    }
  }

  function handleViewSales() {
    setViewSales(!viewSales);
    if (isOpenedMenu) {
      handleOpenMenu();
    }
  }

  useEffect(() => {
    setFilteredManufacturers(manufacturers);
  }, [manufacturers]);

  useEffect(() => {
    setFilteredUnits(units);
  }, [units]);

  useEffect(() => {
    setFilteredSales(sales);
  }, [sales]);

  useEffect(() => {
    setFilteredBranches(branches);
  }, [branches]);

  useEffect(() => {
    setFilteredReportSales(allData);
  }, [allData]);

  useEffect(() => {
    setFilteredPayments(payments);
  }, [payments]);

  return (
    <>
      {loadingSale && <Loading />}
      {selectedSale && <SelectedSale onExited={() => setSelectedSale(null)} selectedSale={selectedSale} />}

      {dialogDate && (
        <DashboardReportDateFilter
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          handleDateConfig={handleDateConfig}
          dateConfig={dateConfig}
          loading={loading}
          onExited={() => setDialogDate(false)}
          handleConfirmCustomDates={handleConfirmCustomDates}
        />
      )}
      <DashboardReportCustomDate
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        handleConfirmCustomDates={handleConfirmCustomDates}
      />
      <Appbar
        title={`Olá, ${user?.name}`}
        ActionsComponent={<FilterActions handleOpenDialog={() => setDialogDate(true)} />}
      />
      <PageHeaderActions
        title={`Olá, ${user?.name}`}
        description={getDescriptionPageHeader()}
        ActionComponent={
          <DashboardReportHeaderButtons
            filter={filter}
            handleChangeFilter={handleChangeFilter}
            setAnchorEl={setAnchorEl}
            dateConfig={dateConfig}
            handleDateConfig={handleDateConfig}
            loading={loading}
          />
        }
      />
      {loading ? (
        <InsideLoading />
      ) : (
        <>
          <DashboardReportTotal formattedTotal={formattedTotal} />
          <div className={classes.container}>
            <BoxReport handleClick={handleViewPayment} title="Formas de recebimento" open={viewPayment}>
              {viewPayment && (
                <div className={classes.grid}>
                  <PaginationProvider>
                    <div>
                      <DashboardPaymentListTable
                        payments={filteredPayments}
                        orderedIndex={orderedIndex}
                        handleSort={handleSortPayment}
                      />
                      <Pagination count={filteredPayments.length} />
                    </div>
                  </PaginationProvider>
                  <div style={{ position: 'relative' }}>
                    <PaymentsChart payments={filteredPayments} />
                  </div>
                </div>
              )}
            </BoxReport>

            <BoxReport handleClick={handleViewReportSale} title="Relação de vendas" open={viewReportSale}>
              {viewReportSale && (
                <div className={classes.grid}>
                  <PaginationProvider>
                    <div>
                      <DashboardSaleListTable
                        branches={filteredReportSales}
                        orderedIndex={orderedIndex}
                        handleSort={handleSortReportSale}
                      />
                      <Pagination count={filteredReportSales.length} />
                    </div>
                  </PaginationProvider>
                  <div style={{ position: 'relative' }}>
                    <SalesAppBranchChart allData={allData} />
                  </div>
                </div>
              )}
            </BoxReport>

            <BoxReport handleClick={handleViewStock} title="Estoque" open={viewStock}>
              {viewStock && (
                <div className={classes.grid}>
                  <PaginationProvider>
                    <div>
                      <DashboardBranchListTable
                        branches={filteredBranches}
                        orderedIndex={orderedIndex}
                        handleSort={handleSortBranch}
                      />
                      <Pagination count={filteredBranches.length} />
                    </div>
                  </PaginationProvider>
                  <div style={{ position: 'relative' }}>
                    <SalesAppPercentChart formattedTotalChart={branches} />
                  </div>
                </div>
              )}
            </BoxReport>

            <BoxReport handleClick={handleViewProfitability} title="Rentabilidade" open={viewProfitability}>
              {viewProfitability && (
                <div className={classes.grid}>
                  <PaginationProvider>
                    <div>
                      <DashboardUnityListTable
                        units={filteredUnits}
                        handleSort={handleSortUnity}
                        orderedIndex={orderedIndex}
                      />
                      <Pagination count={filteredUnits.length} />
                    </div>
                  </PaginationProvider>

                  <PaginationProvider>
                    <div>
                      <DashboardManufacturerListTable
                        manufacturers={filteredManufacturers}
                        handleSort={handleSortManufacturer}
                        orderedIndex={orderedIndex}
                      />
                      <Pagination count={filteredManufacturers.length} />
                    </div>
                  </PaginationProvider>
                </div>
              )}
            </BoxReport>

            <TableContainer tableTemplate={SalesTableTemplate}>
              <PaginationProvider>
                <BoxReport handleClick={handleViewSales} title="Vendas" subtitle="Todas as vendas" open={viewSales}>
                  {viewSales && (
                    <>
                      {displayMode === 'list' ? (
                        <SalesListTable
                          handleSelectSale={sale => handleSelectSale(sale)}
                          sales={filterdSales}
                          handleSort={handleSort}
                          orderedIndex={orderedIndex}
                        />
                      ) : (
                        <SalesListModule handleSelectSale={sale => handleSelectSale(sale)} sales={filterdSales} />
                      )}
                      <Pagination count={filterdSales.length} />
                    </>
                  )}
                </BoxReport>
              </PaginationProvider>
            </TableContainer>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
