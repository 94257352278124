import React, { useEffect, useMemo, useRef, Dispatch, SetStateAction } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, styled } from '@mui/material';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';
import { useJustification } from './hooks/useJustification';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import PersonAutocomplete, { Employee } from './customer-autocomplete/PersonAutocomplete';
import CpfInput from 'components/masked-input/CpfInput';
import DocumentUpload from 'components/document-upload/DocumentUpload';
import { usePermissionRules } from 'hooks/permissionRules';
import DesktopTimePicker from 'components/pickers/DesktopTimePicker';

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 20,
});

const docUploadContainer = {
  width: '100%',
  height: 250,
};

const docUpload = {
  width: 180,
};

interface JustificationFormProps {
  favored: Employee | null;
  setFavored: Dispatch<SetStateAction<Employee | null>>;
}

const JustificationForm: React.FC<JustificationFormProps> = ({ favored, setFavored }) => {
  const { checkPermission } = usePermissionRules();
  const { validation, justification, handleChange, document, setDocument } = useJustification();
  const inputs = {
    funcionario: useRef<HTMLInputElement>(null),
    cpf: useRef<HTMLInputElement>(null),
    justificativa: useRef<HTMLInputElement>(null),
    id_filial: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  const endpoint = useMemo(() => {
    return `getcadastrofuncionarios?id_filial=${justification.id_filial}&`;
  }, [justification.id_filial]);

  return (
    <Content>
      <TextField
        label="CPF"
        placeholder="CPF"
        fullWidth
        ref={inputs.cpf}
        value={justification.cpf}
        onChange={e => handleChange('cpf', e.target.value)}
        error={!!validation.cpf}
        helperText={validation.cpf}
        disabled={!checkPermission('rh.justification.allow-query')}
        InputProps={{
          inputComponent: CpfInput as any,
        }}
      />

      <SelectOthersBranch
        autoFocus
        error={!!validation.id_filial}
        helperText={validation.id_filial}
        viewAllBranches={false}
        disabled={!checkPermission('rh.justification.allow-query')}
        branch_id={justification.id_filial}
        handleChange={value => handleChange('id_filial', value)}
        margin="none"
      />

      <PersonAutocomplete
        label="Funcionário"
        favored={favored}
        endpoint={endpoint}
        placeholder="Digite o nome do funcionário"
        disabled={!checkPermission('rh.justification.allow-query')}
        onSelect={value => {
          setFavored(value);
          handleChange('id_funcionario', value?.id || '');
          handleChange('id_filial', value?.id_filial);
          handleChange('funcionario', value?.nome || '');
          handleChange('cpf', value?.cpf || '');
        }}
        helperText={validation.funcionario || ''}
        error={!!validation.funcionario}
      />

      <KeyboardDatePicker
        label="Data da ocorrência"
        value={justification.data_ocorrencia}
        onChange={date => handleChange('data_ocorrencia', date)}
      />

      {justification.ocorrencia !== 'dia_todo' && (
        <DesktopTimePicker
          label="Hora da ocorrência"
          value={justification.hora}
          onChange={date => handleChange('hora', date)}
        />
      )}

      <FormControl>
        <FormLabel id="ocorrencia">Ocorrência</FormLabel>
        <RadioGroup
          aria-labelledby="ocorrencia"
          value={justification.ocorrencia}
          onChange={e => handleChange('ocorrencia', e.target.value)}
        >
          <FormControlLabel value="entrada_manha" control={<Radio />} label="Ponto entrada manhã" />
          <FormControlLabel value="saida_manha" control={<Radio />} label="Ponto saída manhã" />
          <FormControlLabel value="entrada_tarde" control={<Radio />} label="Ponto entrada tarde" />
          <FormControlLabel value="saida_tarde" control={<Radio />} label="Ponto saída tarde" />
          <FormControlLabel value="dia_todo" control={<Radio />} label="Dia todo" />
        </RadioGroup>
      </FormControl>

      <TextField
        label="Ocorrência"
        placeholder="Ocorrência"
        fullWidth
        multiline
        rows={2}
        ref={inputs.justificativa}
        helperText={validation.justificativa}
        error={!!validation.justificativa}
        value={justification.justificativa}
        onChange={e => handleChange('justificativa', e.target.value)}
      />
      <DocumentUpload
        document={document}
        label="Arquivo"
        handleRemoveImage={() => setDocument(null)}
        handleSetImage={doc => setDocument(doc)}
        containerClassname={docUploadContainer}
        style={docUpload}
      />
    </Content>
  );
};

export default JustificationForm;
