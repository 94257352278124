import React, { MouseEvent } from 'react';
import { AbcProduct } from 'types/abcProduct';
import { KeyboardArrowDown, MoreHoriz } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography } from '@mui/material';
import { useAbcCustomers } from '../../../hooks/useAbcCustomers';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
});

type AbcProductItemTableProps = {
  product: AbcProduct;
  isSubgroup: boolean;
};

const AbcProductItemTable: React.FC<AbcProductItemTableProps> = ({ product, isSubgroup }) => {
  const classes = styles();
  const { tableTemplate } = useTable();
  const { setMoreProduct, moreProduct } = useAbcCustomers();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setMoreProduct(moreProduct ? null : product);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <IconButton key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'more' && isSubgroup ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={moreProduct?.cod === product.cod ? { transform: 'rotate(180deg)' } : undefined}
              />
            </IconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography style={{ fontSize: 11 }} variant="body2">
                {product[item.id]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default AbcProductItemTable;
