import React, { FormEvent } from 'react';
import { MdSearch } from 'react-icons/md';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  styled,
  Theme,
  useTheme,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { FaFileExcel } from 'react-icons/fa';
import { useApp } from 'hooks/app';
import SelectBranch from 'components/select-branches/SelectBranch';
import { usePermissionRules } from 'hooks/permissionRules';
import { AbcProductFilterParams } from './AbcProduct';
import { useFetchMoreFilters } from 'components/filter-more/hooks/useFetchMoreFilters';

const Filter = styled('div')(({ theme }) => ({
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '0.4fr 0.4fr 1fr 1fr ',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
  '& > .search-params': {
    columnGap: 10,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Actions = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const CustomFormControl = styled(FormControl)({
  height: '100%',
});

function getStyles(name: number, personName: readonly number[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold,
    backgroundColor: personName.indexOf(name) === -1 ? '#fff' : '#cfffe6',
  };
}

interface AbcProductFilterBoxProps {
  filter: AbcProductFilterParams;
  handleChangeFilter(index: keyof AbcProductFilterParams, value: any): void;
  handleOpenDialog(): void;
  handleOpenDialogDesktop(): void;
  handleOpenExcel(): void;
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
  disabled: boolean;
}

const AbcProductFilterBox: React.FC<AbcProductFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  searchText,
  handleSearchInputChange,
  handleSearchSubmit,
  handleOpenDialogDesktop,
  handleOpenDialog,
  handleOpenExcel,
  disabled,
}) => {
  const { isMobile, windowWidth } = useApp();
  const { units, segments, categories } = useFetchMoreFilters();
  const { checkPermission } = usePermissionRules();
  const theme = useTheme();

  return (
    <FilterBox>
      <Filter>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />

        <CustomFormControl>
          <InputLabel>Tipo de venda</InputLabel>
          <Select
            fullWidth
            label="tipo de venda"
            value={filter.typeSale}
            onChange={e => handleChangeFilter('typeSale', e.target.value)}
          >
            <MenuItem value="T">Todas</MenuItem>
            <MenuItem value="C">Coligadas</MenuItem>
            <MenuItem value="V">Vendas</MenuItem>
          </Select>
        </CustomFormControl>

        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate}
            onChange={date => handleChangeFilter('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.finalDate}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </div>

        <form onSubmit={handleSearchSubmit}>
          <TextField
            label="Produto"
            placeholder="Produto"
            autoFocus
            value={searchText}
            onChange={e => handleSearchInputChange(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <MdSearch color="#888" size={20} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>

        <FormControl>
          <InputLabel>unidades</InputLabel>
          <Select
            fullWidth
            value={filter.unitIds}
            onChange={e => handleChangeFilter('unitIds', e.target.value)}
            multiple
          >
            {units?.map(unity => (
              <MenuItem style={getStyles(unity.id, filter.unitIds, theme)} key={unity.id} value={unity.id}>
                {unity.unidade}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel>segmentos</InputLabel>
          <Select
            fullWidth
            value={filter.segmentIds}
            onChange={e => handleChangeFilter('segmentIds', e.target.value as any)}
            multiple
          >
            {segments?.map(segment => (
              <MenuItem style={getStyles(segment.id, filter.segmentIds, theme)} key={segment.id} value={segment.id}>
                {segment.segmento}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel>categorias</InputLabel>
          <Select
            fullWidth
            multiple
            value={filter.categoryIds}
            onChange={e => handleChangeFilter('categoryIds', e.target.value as any)}
          >
            {categories?.map(category => (
              <MenuItem style={getStyles(category.id, filter.categoryIds, theme)} key={category.id} value={category.id}>
                {category.categoria}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Pedido por"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={filter.madeBy}
          onChange={e => handleChangeFilter('madeBy', e.target.value)}
        />
      </Filter>

      <Actions>
        <Tooltip title="Outros filtros">
          <IconButton
            onClick={isMobile || windowWidth < 1280 ? handleOpenDialog : handleOpenDialogDesktop}
            color="inherit"
          >
            <FilterList />
          </IconButton>
        </Tooltip>

        {checkPermission('general.excel_export_button') && (
          <Tooltip title="Exportar excel">
            <IconButton disabled={disabled} onClick={handleOpenExcel} style={{ color: disabled ? '#eee' : 'green' }}>
              <FaFileExcel />
            </IconButton>
          </Tooltip>
        )}
      </Actions>
    </FilterBox>
  );
};

export default AbcProductFilterBox;
