import React, { useEffect, useRef } from 'react';
import { TextField, Switch, Typography } from '@mui/material';
import { ProfileValidation } from './Profile';
import { Profile } from 'types/profile';

import { makeStyles } from '@mui/styles';

type ProfileFormProps = {
  handleChange(index: keyof Profile, value: any): void;
  profile: Profile;
  validation: ProfileValidation;
};

const useStyles = makeStyles({
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #ccc',
    padding: '20px 0',
    '& div': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
  },
  rules: {
    marginTop: 20,
  },
});

const ProfileForm: React.FC<ProfileFormProps> = ({ handleChange, profile, validation }) => {
  const classes = useStyles();
  const nameInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (validation.name) nameInputRef.current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <>
      <TextField
        inputRef={nameInputRef}
        error={!!validation.name}
        helperText={validation.name}
        label="Nome"
        placeholder="Digite o nome do perfil"
        margin="normal"
        value={profile.name}
        onChange={e => handleChange('name', e.target.value)}
        fullWidth
        autoFocus
      />
      <div className={classes.rules}>
        <div className={classes.formControl}>
          <div>
            <span>DASHBOARD</span>
            <Switch
              color="primary"
              onChange={event => handleChange('dashboard', event.target.checked)}
              checked={profile.dashboard}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir acesso ao Dashboard
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>CADASTROS</span>
            <Switch
              color="primary"
              onChange={event => handleChange('registrations', event.target.checked)}
              checked={profile.registrations}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir acesso aos cadastros básicos do sistema
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>CADASTROS {'>'} PRODUTOS</span>
            <Switch
              color="primary"
              onChange={event => handleChange('product_registration', event.target.checked)}
              checked={profile.product_registration}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir acesso ao cadastro de produtos
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>ESTOQUE</span>
            <Switch
              color="primary"
              onChange={event => handleChange('stock', event.target.checked)}
              checked={profile.stock}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir acesso ao módulo estoque
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>COMERCIAL</span>
            <Switch
              color="primary"
              onChange={event => handleChange('trade', event.target.checked)}
              checked={profile.trade}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir acesso ao módulo comercial
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>COMPRAS</span>
            <Switch
              color="primary"
              onChange={event => handleChange('purchasing', event.target.checked)}
              checked={profile.purchasing}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir acesso ao módulo de compras
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>FINANCEIRO</span>
            <Switch
              color="primary"
              onChange={event => handleChange('financial', event.target.checked)}
              checked={profile.financial}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir acesso ao módulo financeiro
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>FISCAL</span>
            <Switch
              color="primary"
              onChange={event => handleChange('taxes', event.target.checked)}
              checked={profile.taxes}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir acesso ao módulo fiscal
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>RELATÓRIOS</span>
            <Switch
              color="primary"
              onChange={event => handleChange('reports', event.target.checked)}
              checked={profile.reports}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir acesso ao módulo de relatórios
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>CONFIGURAÇÕES</span>
            <Switch
              color="primary"
              onChange={event => handleChange('settings', event.target.checked)}
              checked={profile.settings}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir acesso ao módulo de configurações do sistema
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>CONFIGURAÇÕES {'>'} USUÁRIOS</span>
            <Switch
              color="primary"
              onChange={event => handleChange('users', event.target.checked)}
              checked={profile.users}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir acesso ao módulo de usuários
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>CONFIGURAÇÕES {'>'} PERFIS</span>
            <Switch
              color="primary"
              onChange={event => handleChange('profiles', event.target.checked)}
              checked={profile.profiles}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir acesso ao módulo de perfils de usuários
          </Typography>
        </div>
      </div>
    </>
  );
};

export default ProfileForm;
