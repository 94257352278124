import { TableTemplate } from 'types/tableTemplate';

export const modalitiesTableTemplate: TableTemplate[] = [
  {
    id: 'modalidade',
    description: 'Modalidade',
    originalId: 'modalidade',
    width: 300,
  },
  {
    id: 'formattedTicketMedio',
    description: 'Ticket médio',
    originalId: 'ticket_medio',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'qtd_vendas',
    description: 'Qtd Vendas',
    originalId: 'qtd_vendas',
    width: 140,
  },
  {
    id: 'formattedPercentual',
    description: '%',
    originalId: 'percentual',
    width: 60,
    dataType: 'number',
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'valor',
    width: 220,
    dataType: 'number',
  },
];
