import React from 'react';
import { ListItemButton, styled, ListItemButtonProps } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';

interface StyleProps {
  templateColumns: string;
  width?: number;
  smtemplatecolumns?: string;
}

const Row = styled(ListItemButton, { shouldForwardProp: prop => prop !== 'templateColumns' })<StyleProps>(
  ({ templateColumns, smtemplatecolumns, width, theme }) => ({
    display: 'grid',
    gridTemplateColumns: templateColumns,
    minHeight: 40,
    gridAutoFlow: 'row',
    padding: 0,
    flexShrink: 0,
    borderBottom: '1px solid #ddd',
    width: width ? `${width}px` : '100%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: smtemplatecolumns || '',
    },
    '& div': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px',
      justifyContent: 'start',
      textAlign: 'start',
      '&.numericData': {
        justifyContent: 'flex-end',
        textAlign: 'end',
      },
    },
  }),
);

interface TableRowProps extends ListItemButtonProps {
  onClick?(): void;
  style?: React.CSSProperties;
  smtemplatecolumns?: string;
}

const TableRow: React.FC<TableRowProps> = ({
  children,
  onClick,
  style,
  className,
  smtemplatecolumns = '',
  ...rest
}) => {
  const { width, templateColumns } = useTable();

  return (
    <Row
      style={style}
      templateColumns={templateColumns}
      smtemplatecolumns={smtemplatecolumns}
      width={width}
      {...rest}
      onClick={onClick}
    >
      {children}
    </Row>
  );
};

export default TableRow;
