import { TableTemplate } from 'types/tableTemplate';

export const branchesTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
    notFilterable: true,
  },
  {
    id: 'name',
    description: 'NOME',
    originalId: 'name',
    width: 350,
  },
  {
    id: 'phone',
    description: 'TELEFONE',
    originalId: 'phone',
    width: 200,
  },
  {
    id: 'email',
    description: 'E-MAIL',
    originalId: 'email',
    width: 300,
  },
  {
    id: 'address',
    description: 'ENDEREÇO',
    originalId: 'address',
    width: 300,
  },
  {
    id: 'latitude',
    description: 'LATITUDE',
    originalId: 'latitude',
    width: 150,
  },
  {
    id: 'longitude',
    description: 'LONGITUDE',
    originalId: 'longitude',
    width: 150,
  },
  {
    id: 'formattedCreatedAt',
    description: 'DATA',
    originalId: 'formattedCreatedAt',
    width: 150,
    notSearchable: true,
  },
];
