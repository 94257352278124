import React, { useState, useEffect } from 'react';
import { useApp } from 'hooks/app';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import useTableOrder from 'hooks/tableOrder';
import { moneyFormat } from 'helpers/numberFormat';
import { AbcProductSubgroup } from 'types/abcProduct';
import { useAbcSegment } from '../../hooks/useAbcSegment';
import NoData from 'components/no-data/NoData';
import InsideLoading from 'components/loading/InsideLoading';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { styled } from '@mui/material';
import SubgroupListTable from 'pages/reports/commercial/abc/product/subgroup/list/table/SubgroupListTable';

interface SegmentMoreMenuProps {
  segmentId: number;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& > div': {
    margin: 0,
    minHeight: 0,
    overflow: 'visible',
  },
});

const SegmentMoreMenu: React.FC<SegmentMoreMenuProps> = ({ segmentId }) => {
  const { h2iApi } = useApp();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const { filter, searchValue } = useAbcSegment();
  const [filtered, setFiltered] = useState<AbcProductSubgroup[]>([]);
  const [subgroups, setSubgroups] = useState<AbcProductSubgroup[]>([]);

  useEffect(() => {
    setFiltered(subgroups);
  }, [subgroups]);

  function handleSort(index: string) {
    const filteredS = sort(index, filtered);
    setFiltered(filteredS);
  }

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    h2iApi
      .get('/api/relcurvaabcsubgrupo', {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.selectedBranchId || '',
          id_subgrupo: filter.subGroupIds.join(',') || '',
          filtro: searchValue,
          tipo_venda: filter.typeSale,
          id_categoria: filter.categoryIds.join(','),
          id_subcategoria: filter.subCategoryIds.join(','),
          pedido_por: filter.madeBy,
          id_segmento: segmentId,
          id_unidade: filter.unitIds.join(','),
        },
      })
      .then(response => {
        const _subgroups = response.data.map(item => {
          item.formattedTotal = moneyFormat(item.total);
          item.formattedCoust = moneyFormat(item.custo);
          item.formattedMc = moneyFormat(item.mc);
          item.formattedSpiff = moneyFormat(item.spiff);
          item.formattedUnitary = moneyFormat(item.unitario);

          return item;
        });

        setSubgroups(_subgroups);
      })
      .finally(() => setLoading(false));
  }, [h2iApi, filter, segmentId, searchValue]);

  return (
    <PaginationProvider>
      <Container>
        {loading ? (
          <InsideLoading />
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum subgrupo encontrado." />
        ) : (
          <SubgroupListTable
            openModal={false}
            isSubgroup={false}
            orderedIndex={orderedIndex}
            subgroups={filtered}
            handleSort={handleSort}
          />
        )}
        <Pagination count={filtered.length} />
      </Container>
    </PaginationProvider>
  );
};

export default SegmentMoreMenu;
