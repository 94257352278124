import React from 'react';
import { AbcProductUnity } from 'types/abcProduct';
import { Typography, ListItemButton, styled } from '@mui/material';

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
});

type UnityItemModuleProps = {
  unity: AbcProductUnity;
};

const UnityItemModule: React.FC<UnityItemModuleProps> = ({ unity }) => {
  return (
    <CustomListItemButton>
      <Typography variant="caption" color="primary">
        {unity.unidade}
      </Typography>
      <Typography variant="caption" color="primary">
        Valor total - {unity.formattedTotal}
      </Typography>
      <Typography variant="caption" color="primary">
        Valor custo - {unity.formattedCoust}
      </Typography>
      <Typography variant="caption" color="primary">
        Valor unitário - {unity.formattedUnitary}
      </Typography>
      <Typography variant="caption" color="primary">
        QTD - {unity.qtd}
      </Typography>
    </CustomListItemButton>
  );
};

export default UnityItemModule;
