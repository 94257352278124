import React, { FormEvent, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Done } from '@mui/icons-material';
import Dialog from 'components/dialogs/Dialog';
import InsideSaving from 'components/loading/InsideSaving';
import { useApp } from 'hooks/app';
import AppropriationDateForm from '../AppropriationDateForm';
import { useAppropriationDateValidation } from 'pages/appropiation-date/validation/useAppropriationDateValidation';
import { AppropriationDate } from 'types/appropriationDate';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useMessaging } from 'hooks/messaging';

interface NewAppropriationDateProps {
  onExited(): void;
}

const initialValue: AppropriationDate = {
  data: '',
  date: new Date(),
  id: 0,
  inativo: 'N',
};

const NewAppropriationDate: React.FC<NewAppropriationDateProps> = ({ onExited }) => {
  const { financialApi } = useApp();
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);
  const [validation, setValidation, validate] = useAppropriationDateValidation();
  const [appropriationDate, setAppropriationDate] = useState<AppropriationDate>(initialValue);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    if (!appropriationDate) {
      return;
    }

    setValidation({});

    validate(appropriationDate)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    if (!appropriationDate?.date) {
      return;
    }

    const dataToSubmit = {
      data: format(appropriationDate.date, 'P', { locale: ptBR }),
      inativo: appropriationDate.inativo,
      id: appropriationDate.id,
    };

    setSaving(true);

    financialApi
      .post('/createdataapropriacao', dataToSubmit)
      .then(response => {
        handleOpen(response.data.result);
        onExited();
      })
      .finally(() => setSaving(false));
  }

  function handleChange(index: keyof AppropriationDate, value: any) {
    setAppropriationDate(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <Dialog
      title="Cadastro Data Apropriação"
      onExited={onExited}
      maxWidth="sm"
      ComponentActions={
        <Tooltip title="Salvar">
          <IconButton color="inherit" onClick={() => handleValidation()}>
            <Done />
          </IconButton>
        </Tooltip>
      }
    >
      {saving && <InsideSaving />}
      <form onSubmit={handleValidation}>
        <AppropriationDateForm
          appropriationDate={appropriationDate}
          handleChange={handleChange}
          validation={validation}
        />
      </form>
    </Dialog>
  );
};

export default NewAppropriationDate;
