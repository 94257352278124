import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import { useExtracts } from './hooks/useExtracts';
import { Search } from '@mui/icons-material';

interface BankStatementFilterMobileProps {
  onExited(): void;
  onSearch(): void;
}

const Container = styled('div')({
  gap: 10,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const Actions = styled('div')({
  flex: 1,
  display: 'flex',
  alignItems: 'end',
});

const BankStatementFilterMobile: React.FC<BankStatementFilterMobileProps> = ({ onSearch, onExited }) => {
  const { filter, handleChangeFilter } = useExtracts();

  return (
    <Dialog title="Filtro" onExited={onExited} maxWidth="sm">
      <Container>
        <TextField
          value={filter.movimentation}
          onChange={e => handleChangeFilter('movimentation', e.target.value)}
          placeholder="Pesquise pela descrição financeira"
          label="Descrição financeira"
        />

        <TextField
          value={filter.targetAccount}
          onChange={e => handleChangeFilter('targetAccount', e.target.value)}
          placeholder="Pesquise pela conta destino"
          label="Conta destino"
        />

        <TextField value={filter.type} onChange={e => handleChangeFilter('type', e.target.value)} select label="Tipo">
          <MenuItem value="TT">Todos</MenuItem>
          <MenuItem value="D">Despesas</MenuItem>
          <MenuItem value="T">Títulos</MenuItem>
          <MenuItem value="F">Folha de Pagamento</MenuItem>
          <MenuItem value="DT">Títulos e Despesas</MenuItem>
          <MenuItem value="S">Transferência</MenuItem>
        </TextField>

        <TextField
          select
          fullWidth
          value={filter.sortBy}
          label="Ordenar por"
          placeholder="Ordene por"
          onChange={e => handleChangeFilter('sortBy', e.target.value)}
        >
          <MenuItem value="name">Nome</MenuItem>
          <MenuItem value="lower_price">Menor valor</MenuItem>
          <MenuItem value="higher_price">Maior valor</MenuItem>
        </TextField>

        <Actions>
          <Button
            endIcon={<Search fontSize="small" />}
            type="submit"
            color="primary"
            variant="contained"
            onClick={onSearch}
            fullWidth
          >
            Pesquisar
          </Button>
        </Actions>
      </Container>
    </Dialog>
  );
};

export default BankStatementFilterMobile;
