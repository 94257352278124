import React, { useState } from 'react';
import { Alert } from '@mui/lab';
import CustomerTabs from '../CustomerTabs';
import Dialog from 'components/dialogs/Dialog';
import PropTypes from 'prop-types';
import CustomerActions from '../CustomerActions';
import { CustomerProvider } from '../../hooks/useCustomer';
import InsideSaving from 'components/loading/InsideSaving';
import { useFetchEditCustomer } from './useFetchEditCustomer';
import CustomerAddress from '../forms/CustomerAddress';
import CustomerInfos from '../forms/CustomerInfos';
import CustomerContacts from '../forms/CustomerContacts';
import CustomerFinancial from '../forms/CustomerFinancial';
import CustomerAgents from '../../agents/CustomerAgents';
import CustomerObservation from '../forms/CustomerObservation';
import CustomerSeveral from '../forms/CustomerSeveral';
import CustomerDocuments from '../forms/CustomerDocuments';
import { Box, Typography, styled } from '@mui/material';

interface CustomerProps {
  onExited(): void;
  onSubmit(): void;
}

const AlertError = styled(Alert)({
  textAlign: 'center',
  verticalAlign: 'middle',
  justifyContent: 'center',
  marginBottom: '50px',
});

const Container = styled('div')({
  padding: '10px',
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Customer: React.FC<CustomerProps> = ({ onExited, onSubmit }) => {
  const [value, setValue] = useState(0);

  const [
    handleChange,
    handleValidation,
    customer,
    agents,
    segments,
    customerInfoValidation,
    customerAddressValidation,
    customerContactValidation,
    saving,
    error,
    loadingCep,
  ] = useFetchEditCustomer(onSubmit);

  return (
    <CustomerProvider
      value={{
        handleChange,
        handleValidation,
        customer,
        agents,
        segments,
        registerType: 'edit',
      }}
    >
      <Dialog
        onExited={onExited}
        maxWidth="md"
        title={`Favorecido`}
        ComponentActions={<CustomerActions handleValidation={handleValidation} />}
      >
        <div>
          <CustomerTabs value={value} handleChange={value => setValue(value)} />
          {saving && <InsideSaving />}

          <form onSubmit={handleValidation}>
            <Container>
              <TabPanel value={value} index={0}>
                <CustomerInfos validation={customerInfoValidation} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CustomerAddress loading={loadingCep} validation={customerAddressValidation} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CustomerContacts validation={customerContactValidation} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <CustomerFinancial />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <CustomerAgents />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <CustomerObservation />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <CustomerDocuments />
              </TabPanel>
              <TabPanel value={value} index={7}>
                <CustomerSeveral />
              </TabPanel>
            </Container>
          </form>

          {error && <AlertError severity="error">Existem campos obrigatórios</AlertError>}
        </div>
      </Dialog>
    </CustomerProvider>
  );
};

export default Customer;
