import { TableTemplate } from 'types/tableTemplate';

export const subgroupStockTableTemplate: TableTemplate[] = [
  {
    id: 'subgrupo',
    description: 'SUBGRUPO',
    originalId: 'subgrupo',
    width: 250,
  },
  {
    id: 'formattedCustoTotal',
    description: 'Custo Total',
    originalId: 'CustoTotal',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'Estoque',
    description: 'Estoque',
    originalId: 'Estoque',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'Reserva',
    description: 'Reserva',
    originalId: 'Reserva',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'EstTotal',
    description: 'Est Total',
    originalId: 'EstTotal',
    width: 80,
    dataType: 'quantity',
  },
];
