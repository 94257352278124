import React, { ReactNode, useCallback, useContext } from 'react';
import { useSelector } from 'store/selector';

type PermissionRulesValue = {
  checkPermission(name: string): boolean;
};

export function usePermissionRules(): PermissionRulesValue {
  const context = useContext(PermissionRulesContext);
  return context;
}

const PermissionRulesContext = React.createContext<PermissionRulesValue>({} as PermissionRulesValue);

interface PermissionRulesProviderProps {
  children: ReactNode;
}

const PermissionRulesProvider: React.FC<PermissionRulesProviderProps> = ({ children }) => {
  const user = useSelector(state => state.user);

  const checkPermission = useCallback(
    (name: string) => !!user?.user_profile.access_rules.some(rule => rule.name === name && rule.enabled),
    [user],
  );

  return <PermissionRulesContext.Provider value={{ checkPermission }}>{children}</PermissionRulesContext.Provider>;
};

export default PermissionRulesProvider;
