import React from 'react';
import { IconButton, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import history from 'services/history';

const CustomIconButton = styled(IconButton)(props => ({
  button: {
    display: 'none',
    [props.theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const PagesActions: React.FC = () => {
  return (
    <CustomIconButton color="inherit" onClick={() => history.push('/site/pages')}>
      <AddIcon />
    </CustomIconButton>
  );
};

export default PagesActions;
