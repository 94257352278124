import React from 'react';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { H2iCustomer } from 'types/h2iCustomer';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
});

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
  width: 350,
});

type CustomerItemTableProps = {
  customer: H2iCustomer;
};

const CustomerItemTable: React.FC<CustomerItemTableProps> = ({ customer }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <CustomIconButton key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
              <Tooltip title={customer[item.id]}>
                <Description color={customer.situacao !== 'A' ? '#fff' : '#000'} variant="caption">
                  {customer[item.id as keyof H2iCustomer]}
                </Description>
              </Tooltip>
            </div>
          ),
        )}
    </>
  );
};

export default CustomerItemTable;
