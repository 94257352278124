import React from 'react';
import {
  Category,
  Compare,
  CompareArrows,
  Dashboard,
  Email,
  Factory,
  InsertEmoticon,
  Inventory,
  PhoneIphone,
  Print,
  RadioButtonChecked,
  Segment,
  Summarize,
  TrendingDown,
  Troubleshoot,
} from '@mui/icons-material';
import { Typography, styled } from '@mui/material';
import { usePermissionRules } from 'hooks/permissionRules';

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const Box = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 5,
  flexGrow: 'auto',
  maxWidth: 80,
  width: '100%',
});

const Icon = styled('div')({
  display: 'flex',
  borderRadius: '100%',
  alignItems: 'center',
  padding: 10,
  width: 70,
  height: 70,
  justifyContent: 'center',
});

const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
});

const SectionItem = styled('div')(({ theme }) => ({
  gap: 10,
  paddingBottom: 10,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'end',
  borderBottom: '1px solid #666',
  '&.primary-main': {
    '& > div > div': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
  },
  '&.purple': {
    '& > div > div': {
      border: `1px solid purple`,
      color: 'purple',
    },
  },
  '&.success': {
    '& > div > div': {
      border: `1px solid ${theme.palette.success.dark}`,
      color: theme.palette.success.dark,
    },
  },
  '&.error': {
    '& > div > div': {
      border: `1px solid ${theme.palette.error.light}`,
      color: theme.palette.error.light,
    },
  },
}));

interface ReportHomeProps {
  handleChange(value: string): void;
}

const ReportHome: React.FC<ReportHomeProps> = ({ handleChange }) => {
  const { checkPermission } = usePermissionRules();

  return (
    <Content>
      <ContentWrapper>
        {checkPermission('reports_stock') && (
          <>
            <Typography align="center" fontWeight={600} variant="body2" fontSize={14}>
              Estoque
            </Typography>
            <SectionItem className="primary-main">
              <Box onClick={() => handleChange('stock_balance')} className="content">
                <Typography variant="body1" fontSize={12} fontWeight={600}>
                  Saldo
                </Typography>
                <Icon>
                  <Inventory />
                </Icon>
              </Box>

              <Box onClick={() => handleChange('stock_indicator')} className="content">
                <Typography variant="body1" fontSize={12} fontWeight={600}>
                  Indicador
                </Typography>
                <Icon>
                  <Inventory />
                </Icon>
              </Box>
            </SectionItem>
          </>
        )}
        {checkPermission('reports_curve-abc') && (
          <>
            <Typography align="center" fontWeight={600} variant="body2" fontSize={14}>
              Curva Abc
            </Typography>
            <SectionItem className="success">
              {checkPermission('reports_curve-abc_product') && (
                <Box onClick={() => handleChange('curve_abc-product')} className="content">
                  <Typography variant="body1" fontSize={12} fontWeight={600}>
                    Produto
                  </Typography>
                  <Icon>
                    <Category />
                  </Icon>
                </Box>
              )}

              {checkPermission('reports_curve-abc_customer') && (
                <Box onClick={() => handleChange('curve_abc-customer')} className="content">
                  <Typography variant="body1" fontSize={12} fontWeight={600}>
                    Cliente
                  </Typography>
                  <Icon>
                    <InsertEmoticon />
                  </Icon>
                </Box>
              )}

              {checkPermission('reports_curve-abc_segment') && (
                <Box onClick={() => handleChange('curve_abc-segment')} className="content">
                  <Typography variant="body1" fontSize={12} fontWeight={600}>
                    Segmento
                  </Typography>
                  <Icon>
                    <Segment />
                  </Icon>
                </Box>
              )}

              {checkPermission('reports_curve-abc_unity') && (
                <Box onClick={() => handleChange('curve_abc-unity')} className="content">
                  <Typography variant="body1" fontSize={12} fontWeight={600}>
                    Unidade
                  </Typography>
                  <Icon>
                    <RadioButtonChecked />
                  </Icon>
                </Box>
              )}

              {checkPermission('reports_curve-abc_manufacturer') && (
                <Box onClick={() => handleChange('curve_abc-manufacturer')} className="content">
                  <Typography variant="body1" fontSize={12} fontWeight={600}>
                    Fabricante
                  </Typography>
                  <Icon>
                    <Factory />
                  </Icon>
                </Box>
              )}
            </SectionItem>
          </>
        )}

        {checkPermission('reports_sales') && (
          <>
            <Typography align="center" fontWeight={600} variant="body2" fontSize={14}>
              Vendas
            </Typography>
            <SectionItem className="error">
              {checkPermission('reports_sales_app') && (
                <Box onClick={() => handleChange('sales_app')} className="content">
                  <Typography variant="body1" fontSize={12} fontWeight={600}>
                    APP
                  </Typography>
                  <Icon>
                    <PhoneIphone />
                  </Icon>
                </Box>
              )}

              {checkPermission('reports_sales_itemssold') && (
                <Box onClick={() => handleChange('sales_report')} className="content">
                  <Typography variant="body1" fontSize={12} fontWeight={600}>
                    Relação
                  </Typography>
                  <Icon>
                    <Summarize />
                  </Icon>
                </Box>
              )}

              <Box onClick={() => handleChange('sales_reprint-sale')} className="content">
                <Typography variant="body1" fontSize={12} fontWeight={600}>
                  Reimpressão
                </Typography>
                <Icon>
                  <Print />
                </Icon>
              </Box>

              {checkPermission('reports_sales_compared-curve-abc-product') && (
                <Box onClick={() => handleChange('sales_compared-product')} className="content">
                  <Typography variant="body1" fontSize={12} fontWeight={600}>
                    Comparativo de produto
                  </Typography>
                  <Icon>
                    <Compare />
                  </Icon>
                </Box>
              )}

              <Box onClick={() => handleChange('sales_monthly-comparison')} className="content">
                <Typography variant="body1" fontSize={12} fontWeight={600}>
                  Comparativo mensal
                </Typography>
                <Icon>
                  <CompareArrows />
                </Icon>
              </Box>
            </SectionItem>
          </>
        )}

        {checkPermission('reports_commercial') && (
          <>
            <Typography align="center" fontWeight={600} variant="body2" fontSize={14}>
              Comercial
            </Typography>
            <SectionItem className="purple">
              <Box onClick={() => handleChange('commercial_export-email')} className="content">
                <Typography variant="body1" fontSize={12} fontWeight={600}>
                  Exportação de e-mail
                </Typography>
                <Icon>
                  <Email />
                </Icon>
              </Box>

              <Box onClick={() => handleChange('commercial_customer-without-sale')} className="content">
                <Typography variant="body1" fontSize={12} fontWeight={600}>
                  Clientes sem venda
                </Typography>
                <Icon>
                  <TrendingDown />
                </Icon>
              </Box>

              {checkPermission('commercial.sellout') && (
                <Box onClick={() => handleChange('commercial_sellout')} className="content">
                  <Typography variant="body1" fontSize={12} fontWeight={600}>
                    Análise de Sell Out
                  </Typography>
                  <Icon>
                    <Troubleshoot />
                  </Icon>
                </Box>
              )}

              <Box onClick={() => handleChange('commercial_seller_dashboard')} className="content">
                <Typography variant="body1" fontSize={12} fontWeight={600}>
                  Dashboard de vendedor
                </Typography>
                <Icon>
                  <Dashboard />
                </Icon>
              </Box>
            </SectionItem>
          </>
        )}
      </ContentWrapper>
    </Content>
  );
};

export default ReportHome;
