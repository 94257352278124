import { Branch } from 'types/branch';

export const SET_BRANCHES = 'SET_BRANCHES';

interface SetBranches {
  type: typeof SET_BRANCHES;
  branches: Branch[];
}

export type BranchActionsType = SetBranches;
