import React from 'react';
import { Person } from '@mui/icons-material';
import { ListItemButton, styled, Typography } from '@mui/material';
import { Manufacturer } from 'types/manufacturer';

const ListItemStyled = styled(ListItemButton)({
  alignItems: 'center',
  gap: 10,
  justifyContent: 'flex-start',
  '& svg': {
    color: '#ccc',
  },
});

interface ManufacturerItemProps {
  manufacturer: Manufacturer;
  value: Manufacturer | null;
  handleSelectManufacturer(manufacturer: Manufacturer): void;
}

const ManufacturerItem: React.FC<ManufacturerItemProps> = ({ manufacturer, value, handleSelectManufacturer }) => {
  return (
    <ListItemStyled
      disabled={value?.id_fabricante === manufacturer.id_fabricante}
      onClick={() => handleSelectManufacturer(manufacturer)}
    >
      <Person />
      <div>
        <Typography>{manufacturer.nome}</Typography>
        <Typography variant="body2" color="textSecondary">
          {manufacturer.id_fabricante}
        </Typography>
      </div>
    </ListItemStyled>
  );
};

export default ManufacturerItem;
