import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { MenuItem, TextField, Typography, Theme } from '@mui/material';
import ResellerList from './ResellerList';
import { useApp } from 'hooks/app';
import { useAgent } from 'pages/agents/hooks/useAgent';
import { H2iReseller } from 'types/h2iReseller';
import { useSelector } from 'store/selector';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {},
    marginBottom: 20,
  },
  buttonClose: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  content: {
    margin: '20px 0',
  },
  selectedReseller: {
    borderWidth: 1,
    borderColor: '#eee',
    borderStyle: 'solid',
    padding: 15,
    borderRadius: 4,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
    display: 'flex',
    '& a': {
      color: theme.palette.error.main,
    },
  },
}));

interface ResellerSelectProps {
  onSelect(reseller: H2iReseller): void;
}

let timer: NodeJS.Timeout;

const ResellerSelect: React.FC<ResellerSelectProps> = ({ onSelect }) => {
  const classes = useStyles();
  const [resellers, setResellers] = useState<H2iReseller[]>([]);
  const [document, setDocument] = useState('');
  const [loading, setLoading] = useState(false);
  const input = useRef<HTMLInputElement>(null);
  const { h2iApi } = useApp();
  const { reseller, setReseller, branch, setBranch } = useAgent();
  const branches = useSelector(state => state.branches);
  const [branchId, setBranchId] = useState(1);

  useEffect(() => {
    if (branches.length === 0) return;
    const _branch = branches[0];
    if (!_branch) return;
    setBranch(_branch);
  }, [branches, setBranch]);

  useEffect(() => {
    input.current?.focus();
  }, []);

  useEffect(() => {
    if (!h2iApi) return;

    clearTimeout(timer);

    if (document.length === 0) setResellers([]);

    if (document.length < 4) return;

    timer = setTimeout(() => {
      setLoading(true);
      h2iApi
        .get('/api/clientevendedor', { params: { filtro: document.toUpperCase(), id_filial: branchId } })
        .then(response => {
          const _resellers: H2iReseller[] = response.data.RESULT[0];
          setResellers(_resellers);
        })
        .catch(err => console.error(err))
        .finally(() => {
          setLoading(false);
          input.current?.focus();
        });
    }, 500);
  }, [document, branch, h2iApi, branchId]);

  function handleRemoveSellerCustomer(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    setReseller(null);
  }

  return (
    <div className={classes.container}>
      {reseller ? (
        <div className={classes.selectedReseller}>
          <div>
            <Typography gutterBottom variant="body2">
              cadastrar preposto para
            </Typography>
            <Typography>{reseller.nome}</Typography>
            <Typography variant="body2" color="textSecondary">
              {reseller.cnpj_cpf}
            </Typography>
          </div>
          <a href="/" onClick={handleRemoveSellerCustomer}>
            trocar
          </a>
        </div>
      ) : (
        <div className={classes.content}>
          <TextField
            select
            label="Selecione a filial"
            value={branchId}
            onChange={e => setBranchId(parseInt(e.target.value))}
            fullWidth
            margin="normal"
          >
            {branches.map(branch => (
              <MenuItem value={branch.id} key={branch.id}>
                {branch.cidade}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            inputRef={input}
            label="pesquisa"
            placeholder="informe o nome do cliente"
            onChange={e => setDocument(e.target.value)}
            value={document}
            helperText={loading ? 'buscando...' : ''}
            inputMode="search"
            autoFocus
            fullWidth
            margin="normal"
          />
          <ResellerList onSelect={onSelect} resellers={resellers} />
        </div>
      )}
    </div>
  );
};

export default ResellerSelect;
