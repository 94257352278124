import React from 'react';
import { Typography, Grid } from '@mui/material';
import ManufacturerItemTable from './ManufacturerItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import { manufacturerStockTableTemplate } from '../manufacturerStockTableTemplate';
import { ManufacturerStockBalanceData } from 'types/reports/stockBalance';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { useStockBalance } from '../../hooks/useStockBalance';
import ManufacturerMoreMenu from '../ManufacturerMoreMenu';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
    marginRight: 10,
  },
  headerItemQuantity: {
    justifyContent: 'center',
  },
  body: {
    maxHeight: 'calc(100vh - 100px)',
  },
});

type ManufacturerListTableProps = {
  manufacturers: ManufacturerStockBalanceData[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const ManufacturerListTable: React.FC<ManufacturerListTableProps> = ({ manufacturers, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { moreManufacturer } = useStockBalance();

  return (
    <Grid container>
      <TableContainer tableTemplate={manufacturerStockTableTemplate}>
        <TableContent>
          <TableHeader>
            {manufacturerStockTableTemplate.map(item => (
              <div
                className={
                  item.dataType === 'number'
                    ? `${classes.headerItem} ${classes.headerItemNumeric}`
                    : item.dataType === 'quantity'
                    ? `${classes.headerItem} ${classes.headerItemQuantity}`
                    : classes.headerItem
                }
                key={item.id}
                onClick={() => handleSort(item.originalId)}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
          </TableHeader>
          <TableBody className={classes.body}>
            {manufacturers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((manufacturer, index) => (
              <>
                <TableRow style={{ minHeight: 10, padding: 0 }} key={index}>
                  <ManufacturerItemTable manufacturer={manufacturer} />
                </TableRow>
                {moreManufacturer?.id_fabricante === manufacturer.id_fabricante && (
                  <ManufacturerMoreMenu key={manufacturer.id_fabricante} manufacturerId={manufacturer.id_fabricante} />
                )}
              </>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </Grid>
  );
};

export default ManufacturerListTable;
