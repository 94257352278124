import React, { DragEvent, HTMLAttributes, useState } from 'react';
import { Button, CircularProgress, Typography, Zoom, Theme, alpha } from '@mui/material';
import { Document } from 'types/document';
import { uuidv4 } from 'helpers/uuidv4';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import { makeStyles } from '@mui/styles';

interface MakeStyleProps {
  error: boolean;
}

const useStyles = makeStyles<Theme, MakeStyleProps>(theme => ({
  container: {
    width: '100%',
  },
  image: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#eee',
  },
  imageContainer: ({ error }: MakeStyleProps) => ({
    display: 'flex',
    width: '100%',
    height: 300,
    border: `2px dashed ${error ? theme.palette.error.main : alpha(theme.palette.primary.main, 0.2)}`,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
  }),
  inputFile: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    flexDirection: 'column',
    margin: 15,
  },
  inDraggableZone: {
    border: `3px dashed ${theme.palette.primary.main}`,
    opacity: 0.5,
  },
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    '& > svg': {
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
  },
  label: {
    display: 'flex',
    cursor: 'pointer',
    minWidth: 110,
  },
  icon: {
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  loading: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: 'rgba(250, 250, 250, 1)',
  },
  thumb: {
    textAlign: 'center',
    padding: 10,
  },
  filename: {
    wordBreak: 'break-all',
  },
}));

interface DocumentUploadProps extends HTMLAttributes<HTMLDivElement> {
  document: Document | null;
  validationError?: string;
  label: string;
  handleRemoveImage(): void;
  handleSetImage(document: Document | null): void;
  containerClassname?: React.CSSProperties;
  disabled?: boolean;
}

const DocumentUpload: React.FC<DocumentUploadProps> = ({
  document,
  handleRemoveImage,
  handleSetImage,
  validationError,
  label,
  className,
  containerClassname,
  disabled,
  ...rest
}) => {
  const classes = useStyles({ error: !!validationError });
  const [selected, setSelected] = useState(false);
  const [dragIn, setDragIn] = useState(false);
  const [saving, setSaving] = useState(false);
  const uuid = uuidv4();
  const messaging = useMessaging();

  function handleUploadFiles(files: FileList | null) {
    if (!files) return;

    const form = new FormData();
    Array.from(files).forEach((file, index) => {
      form.append(`files[${index}]`, file);
    });

    setSaving(true);

    api
      .post<Document[]>('/documents', form)
      .then(response => {
        const _document = response.data[0];
        handleSetImage({
          ..._document,
          formattedSize: `${Math.ceil(_document.size / 1024)} KB`,
        });
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível carregar a imagem');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDropFile(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleUploadFiles(files);
    setDragIn(false);
  }

  function handleDragEnter(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  function handleDragLeave(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(false);
  }

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  return (
    <div className={`${classes.container} ${className}`}>
      {!document ? (
        <div
          {...rest}
          className={
            dragIn
              ? `${classes.imageContainer} ${classes.inDraggableZone} ${containerClassname}`
              : `${classes.imageContainer} ${containerClassname}`
          }
          onDrop={e => handleDropFile(e)}
          onDragLeave={e => handleDragLeave(e)}
          onDragEnter={e => handleDragEnter(e)}
          onDragOver={e => handleDragOver(e)}
          draggable
        >
          {saving ? (
            <CircularProgress />
          ) : (
            <>
              <input
                type="file"
                name="photo"
                id={uuid}
                onChange={event => handleUploadFiles(event.target.files)}
                style={{ display: 'none' }}
                accept=".png, .jpg, .pdf, .jpeg, .pdf"
              />
              <label htmlFor={uuid} className={classes.inputFile}>
                <Typography gutterBottom variant="body2" align="center">
                  Clique para selecionar um arquivo ou arraste e solte aqui nessa área
                </Typography>
              </label>
            </>
          )}
        </div>
      ) : (
        <div
          className={`${classes.imageContainer} ${containerClassname}`}
          onClick={!disabled ? () => setSelected(selected => !selected) : undefined}
        >
          <Zoom in={selected}>
            <div className={classes.imageWrapper}>
              <Button size="small" variant="contained" color="primary" onClick={handleRemoveImage}>
                Remover
              </Button>
            </div>
          </Zoom>
          <div className={classes.thumb}>
            <Typography className={classes.filename}>{document.original_filename}</Typography>
            <Typography>{document.formattedSize}</Typography>
            <a
              href={document.document_url}
              onClick={e => e.stopPropagation()}
              target="_blank"
              rel="noopener noreferrer"
            >
              ver documento
            </a>
          </div>
        </div>
      )}
      {validationError ? (
        <Typography variant="caption" color="error">
          {validationError}
        </Typography>
      ) : (
        <Typography variant="caption" display="block">
          {label}
        </Typography>
      )}
    </div>
  );
};

export default DocumentUpload;
