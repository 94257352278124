import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Agent } from 'types/agent';
import { Branch } from 'types/branch';
import { H2iReseller } from 'types/h2iReseller';

type AgentContextValue = {
  agent: Agent;
  reseller: H2iReseller | null;
  setReseller: Dispatch<SetStateAction<H2iReseller | null>>;
  branch: Branch | null;
  setBranch: Dispatch<SetStateAction<Branch | null>>;
};

const AgentContext = createContext<AgentContextValue>({} as AgentContextValue);
export const AgentProvider = AgentContext.Provider;

export function useAgent(): AgentContextValue {
  const context = useContext(AgentContext);
  return context;
}
