import React from 'react';
import { Typography, ListItem, styled } from '@mui/material';
import { FinancialCard } from 'types/financialReportList';

type CardItemModuleProps = {
  card: FinancialCard;
};

const StyledListItem = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  position: 'relative',
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  alignItems: 'center',
  gridTemplateColumns: '110px 1fr',
});

const CardItemModule: React.FC<CardItemModuleProps> = ({ card }) => {
  return (
    <StyledListItem>
      <Grid>
        <Typography variant="caption">Autorização:</Typography>
        <Typography variant="button">{card.Autorizacao}</Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Filial:</Typography>
        <Typography variant="button">{card.filial}</Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Data lanc:</Typography>
        <Typography variant="button">{card.data_lanc}</Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Data venc:</Typography>
        <Typography variant="button">{card.data_venc}</Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Forma pag:</Typography>
        <Typography variant="button">{card.forma_pagamento}</Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Desconto:</Typography>
        <Typography color="#f30000" variant="body2">
          {card.formattedDiscount}
        </Typography>
      </Grid>

      <Grid>
        <Typography variant="caption">Taxa:</Typography>
        <Typography color="#f30000" variant="body2">
          {card.formattedRate}
        </Typography>
      </Grid>

      <Typography color="green" fontWeight={600} fontSize={16} variant="button">
        {card.posicao_parcela} = {card.formattedValue}
      </Typography>
    </StyledListItem>
  );
};

export default CardItemModule;
