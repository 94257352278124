import { createContext, useContext } from 'react';

interface ReportContextValue {
  handleBack(): void;
}

const ReportContext = createContext<ReportContextValue>({} as ReportContextValue);
export const ReportConsumer = ReportContext.Consumer;
export const ReportProvider = ReportContext.Provider;

export function useReport() {
  return useContext(ReportContext);
}
