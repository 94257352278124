import React from 'react';
import { Typography } from '@mui/material';
import ExpenseItemTable from './ExpenseItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { makeStyles } from '@mui/styles';
import { expensesTableTemplate } from '../../expensesTableTemplate';
import { Expense } from 'types/expense';
import { useExpenses } from '../../hooks/useExpenses';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

interface ExpenseListTableProps {
  expenses: Expense[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const ExpenseListTable: React.FC<ExpenseListTableProps> = ({ expenses, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { setSelectedExpense } = useExpenses();

  return (
    <TableContainer tableTemplate={expensesTableTemplate}>
      <TableContent>
        <TableHeader>
          {expensesTableTemplate.map(item => (
            <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>

              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>
        <TableBody useMaxHeight={false}>
          {expenses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(expense => (
            <TableRow onClick={() => setSelectedExpense(expense)} key={expense.id}>
              <ExpenseItemTable expense={expense} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default ExpenseListTable;
