import { Grid, TextField, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import React, { useRef } from 'react';
import CnpjInput from 'components/masked-input/CnpjInput';
import CpfInput from 'components/masked-input/CpfInput';
import { useCustomer } from '../../hooks/useCustomer';
import { CustomerInfosValidation } from '../validation/CustomerInfosValidation';
import { usePermissionRules } from 'hooks/permissionRules';

interface CustomerInfosProps {
  validation: CustomerInfosValidation;
}

const CustomerInfos: React.FC<CustomerInfosProps> = ({ validation }) => {
  const { checkPermission } = usePermissionRules();
  const { customer, handleChange, registerType, segments } = useCustomer();

  const inputs = {
    fantasia: useRef<HTMLInputElement>(null),
    codigo_atividade: useRef<HTMLInputElement>(null),
    nome: useRef<HTMLInputElement>(null),
    cnpj_cpf: useRef<HTMLInputElement>(null),
    tipo_pessoa: useRef<HTMLInputElement>(null),
    tipo: useRef<HTMLInputElement>(null),
  };

  return (
    <Grid container spacing={0}>
      <Grid container direction={'row'} spacing={1}>
        <Grid item xs={2} md={2}>
          <TextField
            disabled
            fullWidth
            margin="normal"
            label="Código"
            placeholder="Código"
            defaultValue={customer?.codigo || ''}
            InputProps={{
              readOnly: true,
              style: { textTransform: 'uppercase' },
            }}
          />
        </Grid>
        <Grid item xs={5} md={2}>
          <TextField
            select
            fullWidth
            margin="normal"
            value={customer?.tipo}
            error={!!validation.tipo}
            helperText={validation.tipo}
            inputRef={inputs.tipo}
            onChange={e => handleChange('tipo', e.target.value)}
            label="Tipo"
          >
            <MenuItem value={'F'}>Fornecedor</MenuItem>
            <MenuItem value={'C'}>Cliente</MenuItem>
            <MenuItem value={'V'}>Vendedor</MenuItem>
            <MenuItem value={'PCF'}>Prestador de serviço</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={5} md={2}>
          <TextField
            select
            fullWidth
            margin="normal"
            value={customer?.tipo_pessoa}
            error={!!validation.tipo_pessoa}
            helperText={validation.tipo_pessoa}
            inputRef={inputs.tipo_pessoa}
            onChange={e => handleChange('tipo_pessoa', e.target.value)}
            label="Tipo Pessoa"
            disabled={registerType === 'edit'}
          >
            <MenuItem value={'J'}>Jurídica</MenuItem>
            <MenuItem value={'F'}>Física</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            margin="normal"
            label={customer?.tipo_pessoa === 'J' ? 'CNPJ' : 'CPF'}
            placeholder={customer?.tipo_pessoa === 'J' ? 'CNPJ' : 'CPF'}
            inputRef={inputs.cnpj_cpf}
            error={!!validation.cnpj_cpf}
            helperText={validation.cnpj_cpf}
            value={customer?.cnpj_cpf}
            onChange={e => handleChange('cnpj_cpf', e.target.value)}
            inputMode="numeric"
            InputProps={{
              readOnly: registerType === 'edit',
              inputComponent: customer?.tipo_pessoa === 'J' ? (CnpjInput as any) : (CpfInput as any),
            }}
            disabled={registerType === 'edit'}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            margin="normal"
            label="RG/IE"
            placeholder={'Se não possuir, informar "isento"'}
            value={customer?.rg_ie}
            onChange={e => handleChange('rg_ie', e.target.value)}
            inputMode="numeric"
            inputProps={{ style: { textTransform: 'lowercase' } }}
          />
        </Grid>
      </Grid>
      <Grid container direction={'row'} spacing={1}>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            margin="normal"
            label="Nome"
            placeholder="Nome"
            value={customer?.nome}
            inputRef={inputs.nome}
            error={!!validation.nome}
            helperText={validation.nome}
            onChange={e => handleChange('nome', e.target.value)}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            autoCapitalize="words"
            type="text"
          />
        </Grid>
      </Grid>
      <Grid container direction={'row'} spacing={1}>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            margin="normal"
            label="Fantasia"
            placeholder="Fantasia"
            value={customer?.fantasia}
            inputRef={inputs.fantasia}
            error={!!validation.fantasia}
            helperText={validation.fantasia}
            onChange={e => handleChange('fantasia', e.target.value)}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            autoCapitalize="words"
            type="text"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          select
          fullWidth
          margin="normal"
          disabled={!checkPermission('registrations.customers.activity') && registerType === 'edit'}
          value={customer?.codigo_atividade}
          error={!!validation.codigo_atividade}
          inputRef={inputs.codigo_atividade}
          helperText={validation.codigo_atividade}
          onChange={e => handleChange('codigo_atividade', e.target.value)}
          label="Atividade"
        >
          {segments.map(segment => (
            <MenuItem value={segment.id} onClick={() => handleChange('atividade', segment.nome)} key={segment.id}>
              {segment.nome}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={customer?.situacao === 'A'}
              onChange={e => handleChange('situacao', e.target.checked ? 'A' : 'I')}
              color="primary"
            />
          }
          label="Ativo"
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={customer?.autonomo === 'S'}
              onChange={e => handleChange('autonomo', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Declaração de Autônomo"
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={customer?.ecommerce === 'S'}
              onChange={e => handleChange('ecommerce', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="E-commerce"
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={customer?.emite_doc_fiscal === 'S'}
              onChange={e => handleChange('emite_doc_fiscal', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Emite Doc. Fiscal"
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={customer?.participa_promocao === 'S'}
              onChange={e => handleChange('participa_promocao', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Participa Promoção"
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={customer?.venda_interna === 'S'}
              onChange={e => handleChange('venda_interna', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Venda interna"
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={customer?.coligado === 'S'}
              onChange={e => handleChange('coligado', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Coligado"
        />
      </Grid>
    </Grid>
  );
};

export default CustomerInfos;
