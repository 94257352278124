import React from 'react';
import { PriceProtection } from 'types/priceProtection';
import { Typography, styled, ListItemButton } from '@mui/material';

type PriceProtectionItemModuleProps = {
  data: PriceProtection;
};

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
});

const PriceProtectionItemModule: React.FC<PriceProtectionItemModuleProps> = ({ data }) => {
  return (
    <CustomListItemButton>
      <Typography variant="h6">{data.produto}</Typography>
      <Typography variant="h6">{data.descricao_titulo}</Typography>
      <Typography variant="body1">ID Produto {data.id_produto}</Typography>
      <Typography variant="body1">{data.fabricante}</Typography>
      <Typography variant="caption" color="textSecondary">
        Usuário {data.usuario}
      </Typography>
    </CustomListItemButton>
  );
};

export default PriceProtectionItemModule;
