import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { useApp } from 'hooks/app';

export type CommissionCategoryTabValues = 'by_seller' | 'by_category';
type CommissionCategoryTabProps = {
  handleChange(value: CommissionCategoryTabValues): void;
  tab: CommissionCategoryTabValues;
};

const CommissionCategoryTab: React.FC<CommissionCategoryTabProps> = ({ tab, handleChange }) => {
  const app = useApp();

  return (
    <Tabs value={tab} onChange={(e, value) => handleChange(value)} variant={app.isMobile ? 'scrollable' : 'standard'}>
      <Tab label="Por vendedor" value="by_seller" />
      <Tab label="Por categoria" value="by_category" />
    </Tabs>
  );
};

export default CommissionCategoryTab;
