import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import PurchaseOrderFilterPage from './PurchaseOrderFilter';
import PurchaseOrderActions from './PurchaseOrderActions';
import { styled } from '@mui/material';
import { useDisplayMode } from 'hooks/useDisplayMode';
import PurchaseOrderTabs from './PurchaseOrderTabs';
import { Chart, ArcElement } from 'chart.js';
import PurchaseOrderDashboard from './list/dashboard/PurchaseOrderDashboard';
import { useParams } from 'react-router-dom';
import history from 'services/history';
import { PurchaseProvider } from './hooks/usePurchase';
import { PurchaseOrder } from 'types/purchaseOrder';
import { SidebarFiltersProvider } from 'components/sidebar-filters/hooks/useSidebarFilters';
import { useFetchSidebarFilters } from 'components/sidebar-filters/hooks/useFetchSidebarFilters';
import PurchaseOrderSegments from './list/segments/PurchaseOrderSegments';
import PurchaseOrderDashboardTotal from './list/dashboard/PurchaseOrderDashboardTotal';
import PurchaseOrderAging from './list/aging/PurchaseOrderAging';
import PurchaseOrderTotal from './PurchaseOrderTotal';
import TransferRequests from './list/transfer-requests/TransferRequests';
import StockItem from './list/stock-items/StockItem';
import PurchaseOrderProvider from './hooks/PurchaseOrderProvider';
import PurchaseOrderProductPage from './list/items/PurchaseOrderProduct';
import PurchaseOrderShopping from './list/registration/PurchaseOrderShopping';
import PurchaseOrderSidebarFilters from './PurchaseOrderSidebarFilters';
import PurchaseOrderFlow from './list/flow/PurchaseOrderFlow';
import ShoppingSuggestionPage from './list/shopping-suggestion/ShoppingSuggestion';

Chart.register(ArcElement);

interface ContentProps {
  open: boolean;
}

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  position: 'relative',
});

const Content = styled('div')<ContentProps>(({ open, theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  width: `calc(100% - ${open ? '300px' : '0px'})`,
  [theme.breakpoints.down('md')]: {
    width: `calc(100% - ${open ? '200px' : '0px'})`,
  },
}));

export interface PurchaseOrderFilter {
  branch_id: number;
  buyer: string;
  product: string;
  forecast: string;
  aging: string;
}

const PurchaseOrderPage: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [filters] = useFetchSidebarFilters();
  const { route } = useParams<{ route: string }>();
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [selectedOrder, setSelectedOrder] = useState<PurchaseOrder | null>(null);
  const [tab, setTab] = useState(route || '');

  useEffect(() => {
    if (!route) {
      setTab('');
      return;
    }
    setTab(route);
  }, [route]);

  const label = useCallback(() => {
    const labels = {
      registration: 'Cadastro',
      items: 'Itens',
      dashboard: 'Gráficos',
      segments: 'Segmentos',
      aging: 'Aging',
    };

    return labels[tab];
  }, [tab]);

  function getContent(): ReactNode {
    const mapping = {
      dashboard: (
        <>
          <PurchaseOrderDashboardTotal />
          <PurchaseOrderDashboard />
        </>
      ),
      registration: <PurchaseOrderShopping />,
      items: <PurchaseOrderProductPage />,
      segments: (
        <>
          <PurchaseOrderTotal segment={true} />
          <PurchaseOrderSegments />
        </>
      ),
      aging: (
        <>
          <PurchaseOrderTotal aging={true} />
          <PurchaseOrderAging />
        </>
      ),
      stockItems: <StockItem />,
      shoppingSuggestion: <ShoppingSuggestionPage />,
      transferRequests: <TransferRequests />,
      flow: <PurchaseOrderFlow />,
    };

    return mapping[tab];
  }

  return (
    <PurchaseOrderProvider>
      <PurchaseProvider value={{ selectedOrder, setSelectedOrder }}>
        <SidebarFiltersProvider
          value={{
            filters,
            isSidebarOpen,
            setIsSidebarOpen,
          }}
        >
          <Appbar title={`Pedido de compras - ${label()}`} ActionsComponent={<PurchaseOrderActions tab={tab} />} />

          <Container>
            <PurchaseOrderSidebarFilters />
            <Content open={isSidebarOpen}>
              <PurchaseOrderFilterPage tab={tab} displayMode={displayMode} setDisplayMode={setDisplayMode} />
              <PurchaseOrderTabs value={tab} handleChange={value => history.push(`/purchasing/${value}`)} />
              {getContent()}
            </Content>
          </Container>
        </SidebarFiltersProvider>
      </PurchaseProvider>
    </PurchaseOrderProvider>
  );
};

export default PurchaseOrderPage;
