import Dialog from 'components/dialogs/Dialog';
import { useApp } from 'hooks/app';
import React, { FormEvent, useState } from 'react';
import { useExpenseValidation } from '../../validation/expenseValidation';
import { Expense } from 'types/expense';
import ExpenseForm from '../ExpenseForm';
import InsideSaving from 'components/loading/InsideSaving';
import { Button, styled } from '@mui/material';
import { useMessaging } from 'hooks/messaging';
import { useExpenses } from '../../hooks/useExpenses';

interface NewExpenseProps {
  onExited(): void;
  onSubmit(): void;
}

const Form = styled('form')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const NewExpense: React.FC<NewExpenseProps> = ({ onExited, onSubmit }) => {
  const { financialApi } = useApp();
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);
  const { filter } = useExpenses();
  const [expense, setExpense] = useState<Expense>({
    conta_contabil: '',
    id: 0,
    id_filial: filter.branchId,
    nome: '',
    id_agrupamento: '',
  });
  const [validation, setValidation, validate] = useExpenseValidation();

  function handleValidation(e?: FormEvent<HTMLFormElement | HTMLButtonElement>) {
    e?.preventDefault();

    setValidation({});

    validate(expense)
      .then(handleSubmit)
      .catch(error => {
        console.log(error);
        handleOpen('Não foi possível salvar a despesa');
      });
  }

  function handleSubmit() {
    setSaving(true);

    financialApi
      .post('/savecadastrodespesa', expense)
      .then(onSubmit)
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleChange(index: keyof Expense, value: any) {
    setExpense(expense => ({
      ...expense,
      [index]: value,
    }));
  }

  return (
    <Dialog
      maxWidth="sm"
      height="70vh"
      onExited={onExited}
      title="Nova classificação de despesa"
      ComponentActions={
        <Button onClick={handleValidation} variant="contained">
          Salvar
        </Button>
      }
    >
      {saving && <InsideSaving />}
      <Form onSubmit={handleValidation}>
        <ExpenseForm expense={expense} validation={validation} handleChange={handleChange} />
      </Form>
    </Dialog>
  );
};

export default NewExpense;
