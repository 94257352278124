import React, { FormEvent, useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import SiteBannerActions from './PromotionaBannerActions';
import SiteBannerForm from '../PromotionalBannerForm';
import { usePromotionalBannerValidation } from '../validation/promotionalBannerValidation';
import { PromotionalBanner } from 'types/promotionalBanners';

const PromotionalBannerNew: React.FC = () => {
  const [banner, setBanner] = useState<PromotionalBanner>({} as PromotionalBanner);
  const [validation, setValidation, validate] = usePromotionalBannerValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();

  const handleChange = useCallback((index: keyof PromotionalBanner, value: any) => {
    if (index === 'image') {
      setBanner(state => ({
        ...state,
        image: value,
        image_id: value ? value.id : null,
      }));
      return;
    }

    if (index === 'mobile_image') {
      setBanner(state => ({
        ...state,
        mobile_image: value,
        mobile_image_id: value ? value.id : null,
      }));
      return;
    }

    setBanner(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(banner)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/promotionalBanners', banner)
      .then(() => {
        setSaving(false);
        history.push('/site/promotional-banners');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Site"
        ActionsComponent={<SiteBannerActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/site/promotional-banners')}
      />
      <PageHeader title="Novo banner" backUrl="/site/promotional-banners" />
      <Grid container>
        <form onSubmit={handleValidation}>
          <SiteBannerForm handleChange={handleChange} banner={banner} validation={validation} />
        </form>
      </Grid>
    </>
  );
};

export default PromotionalBannerNew;
