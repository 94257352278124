import { useApp } from 'hooks/app';
import { Agent } from 'types/agent';
import { H2iCustomer } from 'types/h2iCustomer';
import { FormEvent, useEffect, useState } from 'react';
import { useCustomers } from 'pages/customers/hooks/useCustomers';
import { CustomerInfosValidation, useCustomerInfosValidation } from '../validation/CustomerInfosValidation';
import { CustomerAddressValidation, useCustomerAddressValidation } from '../validation/CustomerAddressValidation';
import { CustomerContactsValidation, useCustomerContactsValidation } from '../validation/CustomerContactsValidation';
import { postalCodeSearch } from 'services/postalCodeSearch';
import { api } from 'services/api';
import { Segment } from 'types/segment';

type UseFetchEditCustomer = [
  (index: keyof H2iCustomer, value: any) => void,
  (e?: FormEvent<HTMLFormElement>) => void,
  H2iCustomer | null,
  Agent[],
  Segment[],
  CustomerInfosValidation,
  CustomerAddressValidation,
  CustomerContactsValidation,
  boolean,
  boolean,
  boolean,
];

let timer: NodeJS.Timeout;

export function useFetchEditCustomer(onExit: () => void): UseFetchEditCustomer {
  const { selectedCustomer } = useCustomers();
  const [customer, setCustomer] = useState<H2iCustomer | null>(JSON.parse(JSON.stringify(selectedCustomer)));
  const [segments, setSegments] = useState<Segment[]>([]);
  const [saving, setSaving] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [error, setError] = useState(false);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [showAgent, setShowAgent] = useState(false);
  const { h2iApi } = useApp();
  const [customerInfoValidation, setCustomerInfoValidation, validateCustomerInfo] = useCustomerInfosValidation();
  const [customerAddressValidation, setCustomerAddressValidation, validateCustomerAddress] =
    useCustomerAddressValidation();
  const [customerContactValidation, setCustomerContactValidation, validateCustomerContact] =
    useCustomerContactsValidation();

  useEffect(() => {
    if (!h2iApi) return;

    h2iApi
      .get('/api/getatividade')
      .then(response => {
        const _segments: Segment[] = response.data.atividade;
        if (customer?.tipo_pessoa === 'J') setSegments(_segments.filter(segment => segment.tipo_pessoa.match('J|A')));
        else setSegments(_segments.filter(segment => segment.tipo_pessoa.match('F|A')));
      })
      .catch(err => console.error(err));
  }, [customer?.tipo_pessoa, h2iApi]);

  useEffect(() => {
    if (!customer) return;
    if (!h2iApi) return;
    if (showAgent) return;
    h2iApi
      .get(`/api/getprepostocliente?cnpj=${customer?.cnpj_cpf}`)
      .then(response => {
        setAgents(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setShowAgent(true));
  }, [agents, customer, h2iApi, showAgent]);

  function handleChangeDocument(value: any) {
    setCustomer(customer =>
      customer
        ? {
            ...customer,
            cnpj_cpf: value,
          }
        : null,
    );

    const newDocument = value.replace(/\D/g, '');

    clearTimeout(timer);

    if (newDocument.length === 0) return false;

    if (customer?.tipo_pessoa === 'J') {
      if (newDocument.length === 14)
        h2iApi?.get('/api/cliente', { params: { cnpj: newDocument, pw: '' } }).then(_response => {
          const user = _response.data[0];
          if (user.aprovado) {
            setCustomerInfoValidation(validation => ({ ...validation, cnpj_cpf: 'Esse documento já está registrado' }));
            return false;
          } else {
            api.get(`/company/${newDocument}`).then(response => {
              const company = response.data;
              handleChange('fantasia', company.fantasia);
              handleChange('nome', company.nome);
              handleChange('email', company.email);
              handleChange('email_cobranca', company.email);
              handleChange('email_nfe', company.email);
              handleChange('cep', company.cep);
            });
          }
        });
    } else {
      if (newDocument.length === 11) {
        h2iApi?.get('/api/cliente', { params: { cnpj: newDocument, pw: '' } }).then(_response => {
          const user = _response.data[0];
          if (user.aprovado) {
            setCustomerInfoValidation(validation => ({ ...validation, cnpj_cpf: 'Esse documento já está registrado' }));
            return false;
          } else {
            setCustomerInfoValidation(validation => ({ ...validation, cnpj_cpf: '' }));
          }
        });
      }
    }
  }

  function handleChangeCep(value: any) {
    setCustomer(customer =>
      customer
        ? {
            ...customer,
            cep: value,
          }
        : null,
    );
    setCustomerAddressValidation(validation => ({ ...validation, cep: '' }));

    const newPostalCode = value.replace(/\D/g, '');

    clearTimeout(timer);

    if (newPostalCode.length === 8) {
      timer = setTimeout(() => {
        setLoadingCep(true);
        postalCodeSearch(newPostalCode)
          .then(response => {
            if (response.data.erro) {
              setCustomerAddressValidation(validation => ({ ...validation, cep: 'CEP inexistente' }));
              return;
            }
            const { data } = response;
            setCustomerAddressValidation(validation => ({ ...validation, cep: '' }));
            handleChange('endereco', data.logradouro);
            handleChange('complemento', data.complemento);
            handleChange('cidade', data.localidade);
            handleChange('bairro', data.bairro);
            handleChange('uf', data.uf);
          })
          .catch(err => {
            setCustomerAddressValidation(validation => ({ ...validation, cep: err.message }));
          })
          .finally(() => {
            setLoadingCep(false);
          });
      }, 500);
    }
  }

  function handleChange(index: keyof H2iCustomer, value: any) {
    if (index === 'cnpj_cpf') {
      handleChangeDocument(value);
      return;
    }

    if (index === 'cep') {
      handleChangeCep(value);
      return;
    }

    setCustomer(customer =>
      customer
        ? {
            ...customer,
            [index]: value,
          }
        : null,
    );
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    if (!customer) return;
    e?.preventDefault();

    setCustomerInfoValidation({});

    validateCustomerInfo(customer)
      .then(() => handleAddressValidation())
      .catch(error => {
        console.log(error);
        setError(true);
      });
  }

  function handleAddressValidation(e?: FormEvent<HTMLFormElement>) {
    if (!customer) return;
    e?.preventDefault();

    setCustomerAddressValidation({});

    validateCustomerAddress(customer)
      .then(() => handleContactValidation())
      .catch(error => {
        console.log(error);
        setError(true);
      });
  }

  function handleContactValidation(e?: FormEvent<HTMLFormElement>) {
    if (!customer) return;
    e?.preventDefault();

    setCustomerContactValidation({});

    validateCustomerContact(customer)
      .then(handleSubmit)
      .catch(error => {
        console.log(error);
        setError(true);
      });
  }

  function handleSubmit() {
    if (!h2iApi) return;
    setError(false);
    setSaving(true);

    h2iApi
      .post(`api/savefavorecido/${customer?.codigo}`, customer)
      .then(response => {
        console.log(response);
        onExit();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setSaving(false));
  }

  return [
    handleChange,
    handleValidation,
    customer,
    agents,
    segments,
    customerInfoValidation,
    customerAddressValidation,
    customerContactValidation,
    saving,
    error,
    loadingCep,
  ];
}
