import React from 'react';
import { IconButton, Tooltip, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import history from 'services/history';
import { usePermissionRules } from 'hooks/permissionRules';

const CustomToolTip = styled(Tooltip)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

const FiscalCodeActions: React.FC = () => {
  const { checkPermission } = usePermissionRules();

  return (
    <>
      {checkPermission('registrations.cfop.new') && (
        <CustomToolTip title="Adicionar" onClick={() => history.push('/cfop/new')}>
          <IconButton color="inherit">
            <AddIcon />
          </IconButton>
        </CustomToolTip>
      )}
    </>
  );
};

export default FiscalCodeActions;
