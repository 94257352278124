import React, { useEffect, useRef } from 'react';
import { QueryParams } from './Templates';
import FilterBox from 'components/filter-box/FilterBox';
import { TextField, styled } from '@mui/material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';

const Filter = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 10,
  flex: 1,
  [props.theme.breakpoints.down('md')]: {
    flex: 1,
  },
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    flex: 1,
  },
  '& > .search-params': {
    display: 'grid',
    gridTemplateColumns: '200px 200px 200px',
    columnGap: 10,
    [props.theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [props.theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

type TemplateFilterBoxProps = {
  queryParams: QueryParams;
  handleQueryParamsChange(index: keyof QueryParams, value: any): void;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const TemplateFilterBox: React.FC<TemplateFilterBoxProps> = ({
  queryParams,
  handleQueryParamsChange,
  setDisplayMode,
  displayMode,
}) => {
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, []);

  return (
    <FilterBox>
      <Filter>
        <TextField
          label="Nome do template"
          placeholder="Digite o nome do template"
          value={queryParams.term}
          onChange={e => handleQueryParamsChange('term', e.target.value)}
          autoFocus
        />
      </Filter>

      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default TemplateFilterBox;
