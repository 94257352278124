import { IconButton, ListItemButton, Menu, MenuItem, Typography, styled } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { MouseEvent, useState } from 'react';
import { useRelatedProductItems } from '../../hooks/useProducts';
import { RelatedProductItem } from 'pages/related-product/types/relatedProductItem';

const IconButtonStyled = styled(IconButton)({
  position: 'absolute',
  bottom: 0,
  right: 0,
});

const ListItem = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 120,
  flexDirection: 'column',
  '& .data': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

type ProductItemModuleProps = {
  product: RelatedProductItem;
};

const ProductItemModule: React.FC<ProductItemModuleProps> = ({ product }) => {
  const { setSelectedProduct } = useRelatedProductItems();
  const [collapse, setCollapse] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function handleClick() {
    setCollapse(!collapse);
    setSelectedProduct(product);
  }

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    setSelectedProduct(product);
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  }

  return (
    <>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem>Excluir</MenuItem>
      </Menu>
      <ListItem onClick={handleClick}>
        <div className="data">
          <Typography color="primary" variant="caption" gutterBottom>
            {product.id}
          </Typography>

          <Typography>{product.name}</Typography>
        </div>

        <IconButtonStyled onClick={e => handleMoreClick(e)}>
          <MoreVert />
        </IconButtonStyled>
      </ListItem>
    </>
  );
};

export default ProductItemModule;
