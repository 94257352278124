import React, { useEffect, useRef, useState } from 'react';
import { postalCodeSearch } from 'services/postalCodeSearch';
import MaskedInput from 'react-text-mask';
import { RegisterAddressValidation } from './validation/registerAddressValidation';
import { RegisterForm as RegisterFormType } from 'types/registerForm';
import { Address } from 'types/address';
import { TextField } from '@mui/material';
import PostalCodeInput from 'components/masked-input/PostalCodeInput';

let timer: NodeJS.Timeout;

interface RegisterFormPartnerAddressProps {
  register: RegisterFormType;
  handleChange(index: keyof Address, value: any): void;
  validation: RegisterAddressValidation;
  handleSetPartnerAddress(address: Address): void;
}

const RegisterFormPartnerAddress: React.FC<RegisterFormPartnerAddressProps> = ({
  register,
  handleChange,
  validation,
  handleSetPartnerAddress,
}) => {
  const [postalCode, setPostalCode] = useState(register.partner_address.postal_code);
  const [loading, setLoading] = useState(false);
  const [postalCodeValidation, setPostalCodeValidation] = useState({
    error: !register.partner_address.postal_code,
    message: '',
    hasData: !!register.partner_address.postal_code,
  });

  const inputs = {
    postal_code: useRef<MaskedInput>(null),
    street: useRef<HTMLInputElement>(null),
    street_number: useRef<HTMLInputElement>(null),
    neighborhood: useRef<HTMLInputElement>(null),
    complement: useRef<HTMLInputElement>(null),
    city: useRef<HTMLInputElement>(null),
    state: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    inputs.postal_code.current?.inputElement?.focus();
  }, [inputs.postal_code]);

  useEffect(() => {
    if (!postalCodeValidation.error && postalCodeValidation.hasData) inputs.street_number.current?.focus();
  }, [postalCodeValidation]); //eslint-disable-line

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    if (key === 'postal_code') {
      inputs.postal_code.current?.inputElement.focus();
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleChangeCep(value: string) {
    setPostalCode(value);
    setPostalCodeValidation({ error: false, message: '', hasData: false });

    const newPostalCode = value.replace(/\D/g, '');

    clearTimeout(timer);

    if (newPostalCode.length === 0) return false;

    if (newPostalCode.length < 8) {
      setPostalCodeValidation({
        error: true,
        message: 'CEP inválido',
        hasData: false,
      });
    }

    if (newPostalCode.length === 8)
      timer = setTimeout(() => {
        setLoading(true);
        postalCodeSearch(newPostalCode)
          .then(response => {
            if (response.data.erro) {
              setPostalCodeValidation({
                error: true,
                message: 'CEP inexistente',
                hasData: false,
              });
              return;
            }
            const { data } = response;
            setPostalCodeValidation({ error: false, message: '', hasData: true });
            handleSetPartnerAddress({
              postal_code: data.cep.replace(/\D/g, ''),
              street: data.logradouro,
              street_number: '',
              complement: data.complemento,
              state: data.uf,
              city: data.localidade,
              neighborhood: data.bairro,
            });
          })
          .catch(err => {
            setPostalCodeValidation({
              error: true,
              message: err.message,
              hasData: false,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }, 500);
  }

  return (
    <>
      <TextField
        inputRef={inputs.postal_code}
        value={postalCode}
        onChange={e => handleChangeCep(e.target.value)}
        placeholder="CEP"
        label="CEP"
        error={postalCodeValidation.error || !!validation.postal_code}
        helperText={postalCodeValidation.message || validation.postal_code}
        disabled={loading}
        InputProps={{
          inputComponent: PostalCodeInput as any,
        }}
        inputMode="numeric"
      />
      {!postalCodeValidation.error && postalCodeValidation.hasData && (
        <>
          <TextField
            inputRef={inputs.street}
            placeholder="Endereço"
            label="Endereço"
            value={register.partner_address.street}
            onChange={e => handleChange('street', e.target.value)}
            error={!!validation.street}
            helperText={validation.street}
            margin="normal"
          />
          <TextField
            inputRef={inputs.street_number}
            placeholder="Número"
            label="Número"
            value={register.partner_address.street_number}
            onChange={e => handleChange('street_number', e.target.value)}
            error={!!validation.street_number}
            helperText={validation.street_number}
            margin="normal"
          />
          <TextField
            inputRef={inputs.neighborhood}
            placeholder="Bairro"
            label="Bairro"
            value={register.partner_address.neighborhood}
            onChange={e => handleChange('neighborhood', e.target.value)}
            error={!!validation.neighborhood}
            helperText={validation.neighborhood}
            margin="normal"
          />
          <TextField
            inputRef={inputs.complement}
            placeholder="Complemento do endereço"
            label="Complemento"
            value={register.partner_address.complement}
            onChange={e => handleChange('complement', e.target.value)}
            margin="normal"
          />
          <TextField
            inputRef={inputs.city}
            placeholder="Cidade"
            label="Cidade"
            value={register.partner_address.city}
            error={!!validation.city}
            helperText={validation.city}
            onChange={e => handleChange('city', e.target.value)}
            disabled
            autoCapitalize="words"
            margin="normal"
          />
          <TextField
            inputRef={inputs.state}
            placeholder="Estado"
            label="Estado"
            value={register.partner_address.state}
            error={!!validation.state}
            helperText={validation.state}
            onChange={e => handleChange('state', e.target.value)}
            disabled
            margin="normal"
          />
        </>
      )}
    </>
  );
};

export default RegisterFormPartnerAddress;
