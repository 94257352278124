import React from 'react';
import { Typography } from '@mui/material';
import { Cfop } from 'types/cfop';
import { fiscalCodeTableTemplate } from '../fiscalCodeTableTemplate';

type FiscalCodeItemTableProps = {
  fiscalCode: Cfop;
};

const FiscalCodeItemTable: React.FC<FiscalCodeItemTableProps> = ({ fiscalCode }) => {
  return (
    <>
      {fiscalCodeTableTemplate.map(item => (
        <div key={item.id}>
          <Typography variant="body2">{fiscalCode[item.id]}</Typography>
        </div>
      ))}
    </>
  );
};

export default FiscalCodeItemTable;
