import React from 'react';
import { Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { CommercialBiPaymentMethod } from 'pages/bi/commercial/types/commercial-bi';

type CommercialBiPaymentMethodItemTableProps = {
  paymentMethod: CommercialBiPaymentMethod;
};

const CommercialBiPaymentMethodItemTable: React.FC<CommercialBiPaymentMethodItemTableProps> = ({ paymentMethod }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id}>
            <Typography variant="body2">{paymentMethod[item.id]}</Typography>
          </div>
        ))}
    </>
  );
};

export default CommercialBiPaymentMethodItemTable;
