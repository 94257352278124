import { createContext, useContext, SetStateAction, Dispatch } from 'react';
import { FinancialSummaryFilterParams } from '../FinancialSummary';
import { UseInvoicesTotal } from 'pages/moviment/query/hooks/useInvoicesTotal';
import { InvoiceSummary } from 'types/summary/invoiceSummary';
import { SummaryResult } from 'types/summary/summaryResult';

type FinancialSummaryContextValue = {
  handleSearch(): void;
  loading: boolean;
  showTotals: boolean;
  handleChangeFilter(index: keyof FinancialSummaryFilterParams, value: any): void;
  filter: FinancialSummaryFilterParams;
  invoicesTotal: UseInvoicesTotal;
  selectedInvoice: InvoiceSummary | null;
  setSelectedInvoice: Dispatch<SetStateAction<InvoiceSummary | null>>;
  selectedResult: SummaryResult | null;
  setSelectedResult: Dispatch<SetStateAction<SummaryResult | null>>;
};

const FinancialSummaryContext = createContext<FinancialSummaryContextValue>({} as FinancialSummaryContextValue);
export const FinancialSummaryProvider = FinancialSummaryContext.Provider;

export function useFinancialSummary(): FinancialSummaryContextValue {
  const context = useContext(FinancialSummaryContext);
  return context;
}
