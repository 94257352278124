import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { Sales } from 'types/reports/sales';
import SalesItemModule from './SalesItemModule';

type SalesListModuleProps = {
  sales: Sales[];
  handleSelectSale(sale: Sales): void;
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

const SalesListModule: React.FC<SalesListModuleProps> = ({ sales, handleSelectSale }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {sales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(sale => (
        <SalesItemModule handleSelectSale={sale => handleSelectSale(sale)} key={sale.numero_venda} sale={sale} />
      ))}
    </CustomList>
  );
};

export default SalesListModule;
