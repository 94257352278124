import React, { MouseEvent, Dispatch, SetStateAction } from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import { ACTIONS_TABLE_TEMPLATE } from '../actionsTableTemplate';
import { ProjectAction } from 'types/project';
import { MoreHoriz } from '@mui/icons-material';
import { useProjectActions } from '../../hooks/useProjectActions';

const IconButtonStyled = styled(IconButton)({
  justifySelf: 'flex-start',
  padding: 5,
});

type ActionItemTableProps = {
  action: ProjectAction;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const ActionItemTable: React.FC<ActionItemTableProps> = ({ action, setAnchorEl }) => {
  const { setSelectedProjectAction } = useProjectActions();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedProjectAction(action);
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      {ACTIONS_TABLE_TEMPLATE.map(item =>
        item.id === 'actions' ? (
          <IconButtonStyled onClick={handleClick} size="small" key={item.id}>
            <MoreHoriz key={item.id} />
          </IconButtonStyled>
        ) : (
          <div key={item.id}>
            <Typography variant="body2">{action[item.id]}</Typography>
          </div>
        ),
      )}
    </>
  );
};

export default ActionItemTable;
