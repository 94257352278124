import React, { useCallback, useEffect, useState } from 'react';
import { api } from 'services/api';
import { useApp } from 'hooks/app';
import { ptBR } from 'date-fns/locale';
import { Project } from 'types/project';
import { Paginated } from 'types/paginated';
import useTableOrder from 'hooks/tableOrder';
import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import { QueryParams } from 'types/queryParams';
import PaginationProvider from 'hooks/pagination';
import { moneyFormat } from 'helpers/numberFormat';
import ProjectsFIlterBox from './ProjectsFIlterBox';
import ProjectDateFilter from './ProjectDateFilter';
import { format, parseISO, subDays } from 'date-fns';
import { ProjectsProvider } from './hook/useProjects';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableContainer from 'components/table/TableContainer';
import ProjectListTable from './list/table/ProjectListTable';
import { Button, Theme } from '@mui/material';
import FilterActions from 'components/filter-box/FilterActions';
import ApiPagination from 'components/pagination/ApiPagination';
import ProjectListModule from './list/module/ProjectListModule';
import { projectsTableTemplate } from './projectsTableTemplate';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import useSearch from 'hooks/search';
import { makeStyles } from '@mui/styles';
import history from 'services/history';
import { projectStatusMapping } from './registration/reducer/reducer';
import ProjectReport from './report/ProjectReport';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const queryParamsInitialValue: QueryParams = {
  initial_date: subDays(new Date(), 30),
  final_date: new Date(),
  term: '',
  status: 'all',
};

let timer: NodeJS.Timeout;

const Projects: React.FC = () => {
  const app = useApp();
  const search = useSearch();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(24);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [orderedIndex, sort] = useTableOrder();
  const [validation, setValidation] = useState('');
  const [dialogDate, setDialogDate] = useState(false);
  const [filtered, setFiltered] = useState<Project[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [queryParams, setQueryParams] = useState<QueryParams>(queryParamsInitialValue);
  const [shownPrintDialog, setShownPrintDialog] = useState(false);

  function handleQueryParamsChange(index: keyof QueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));

    const query = {
      ...queryParams,
      [index]: value,
    };

    clearTimeout(timer);

    if (index === 'status') {
      const _projects = search(value === 'all' ? '' : value, 'current_status', projects);
      setFiltered(_projects);
      return;
    }

    setValidation('');

    if (value.length < 3 && value.length > 0) {
      setValidation('Digite mais que 2 caracteres');
      return;
    }

    timer = setTimeout(() => fetchProjects(query), 500);
  }

  const fetchProjects = useCallback(
    (query?: QueryParams) => {
      setLoading(true);

      api
        .get<Paginated<Project[]>>('/projects', {
          params: { ...query, page: page + 1, rows },
        })
        .then(_response => {
          const response = _response.data;
          setProjects(
            response.data.map(item => {
              item.formattedCreatedAt = format(parseISO(item.created_at), 'P', { locale: ptBR });
              item.formattedValue = moneyFormat(item.value);
              item.formattedUpdatedAt = format(parseISO(item.updated_at), 'P', { locale: ptBR });
              item.formattedCurrentStatus = projectStatusMapping[item.current_status];
              item.formattedIsApproved = item.is_approved ? 'Sim' : 'Não';
              item.formattedIsRegistered = item.is_registered ? 'Sim' : 'Não';
              item.products = item.products.map(product => ({
                ...product,
                formattedProductValue: moneyFormat(product.product_value),
              }));
              return item;
            }),
          );
          setTotal(response.data.length);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [page, rows],
  );

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  useEffect(() => {
    setFiltered(projects);
  }, [projects]);

  useEffect(() => {
    fetchProjects(queryParamsInitialValue);
  }, [fetchProjects]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  return (
    <ProjectsProvider value={{ selectedProject, setSelectedProject, shownPrintDialog, setShownPrintDialog }}>
      {dialogDate && (
        <ProjectDateFilter
          handleQueryParamsChange={handleQueryParamsChange}
          onExited={() => setDialogDate(false)}
          queryParams={queryParams}
        />
      )}

      {shownPrintDialog && <ProjectReport onExited={() => setShownPrintDialog(false)} />}

      <Appbar title="Projetos" ActionsComponent={<FilterActions handleOpenDialog={() => setDialogDate(true)} />} />

      <PageHeaderActions
        title="Projetos"
        description="Gestão dos projetos"
        ActionComponent={
          <Button size="small" onClick={() => history.push('/projects/new')} variant="contained" color="primary">
            Criar projeto
          </Button>
        }
      />

      <ProjectsFIlterBox
        validation={validation}
        queryParams={queryParams}
        handleQueryParamsChange={handleQueryParamsChange}
        setDisplayMode={setDisplayMode}
        displayMode={displayMode}
      />

      <TableContainer tableTemplate={projectsTableTemplate}>
        <PaginationProvider>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhum projeto" />
          ) : (
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <ProjectListTable projects={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <ProjectListModule projects={filtered} />
              )}
            </div>
          )}

          <ApiPagination
            onChangePage={value => setPage(value)}
            onChangeRowsPerPage={value => setRows(value)}
            count={total}
          />
        </PaginationProvider>
      </TableContainer>
    </ProjectsProvider>
  );
};

export default Projects;
