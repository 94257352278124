import { CourseStudent } from 'pages/course-students/types/courseStudent';
import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export type CourseStudentValidation = {
  student_name?: string;
  student_document_number?: string;
};

type UseCourseStudentValidation = [
  CourseStudentValidation,
  Dispatch<SetStateAction<CourseStudentValidation>>,
  (couseStudent: CourseStudent) => Promise<void>,
];

export function useCourseStudentValidation(): UseCourseStudentValidation {
  async function handleValidation(couseStudent: CourseStudent) {
    const schema = yup.object().shape({
      student_document_number: yup.string().required('o documento do cliente é obrigatório'),
      student_name: yup.string().required('o nome do cliente é obrigatório'),
    });

    try {
      await schema.validate(couseStudent);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<CourseStudentValidation>({});
  return [validation, setValidation, handleValidation];
}
