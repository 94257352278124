import { CHANGE, ProductActionsType, SET_PRICES, SET_PRODUCT } from './types';
import { ProductEdit } from 'types/product';

export const PRODUCT_INITIAL_STATE: ProductEdit = {
  produto: '',
  custo: 0,
  codigo_barra: '',
  lixo_eletronico: 'N',
  altura: '',
  codigo_fabricante: '',
  main_image: null,
  image1: null,
  image2: null,
  image3: null,
  profundidade: '',
  caixa_master: '',
  destaque: false,
  margem: 0,
  preco_maximo_reee: 0,
  peso: '',
  largura: '',
  caracteristicas: '',
  est: '',
  mc_p2pf: '',
  mc_p2pj: '',
  mc_p2pjbr: '',
  mc_promopj: '',
  p1: 0,
  p2pf: 0,
  p2pj: 0,
  p2pjbr: 0,
  preco_promopj: 0,
  promo_pj: false,
  foto_principal: '',
  foto_1: '',
  foto_2: '',
  foto_3: '',
  pmc: 0,
  pmd: 0,
  pp: 0,
  fabricante: '',
  grupo: '',
  mc: 0,
  p2br: 0,
  subgrupo: '',
  categoria: '',
  codigo_produto: '',
  percentualmc: 0,
  codigo_grupo: '',
  codigo_subgrupo: '',
  limitador_de_quantidade: '',
  precoquantidade: [],
  descricao: '',
  margem_lucro: 0,
  mc_p2mc: '',
  promopj: false,
  link_youtube: '',
  nome_coringa: '',
  garantia_fabricante: 0,
  garantia_h2i: 0,
  garantia_total: 0,
  obs_troca: '',
  procedimento_troca: '',
  site_fabricante: '',
  site_suporte: '',
};

export default function productReducer(state = PRODUCT_INITIAL_STATE, action: ProductActionsType): ProductEdit {
  switch (action.type) {
    case CHANGE: {
      let foto_principal = state.foto_principal;
      let foto_1 = state.foto_1;
      let foto_2 = state.foto_2;
      let foto_3 = state.foto_3;

      if (action.index === 'main_image') {
        foto_principal = action.value ? action.value.imageUrl : '';
      }

      if (action.index === 'image1') {
        foto_1 = action.value ? action.value.imageUrl : '';
      }

      if (action.index === 'image2') {
        foto_2 = action.value ? action.value.imageUrl : '';
      }

      if (action.index === 'image3') {
        foto_3 = action.value ? action.value.imageUrl : '';
      }

      return {
        ...state,
        [action.index]: action.value,
        foto_principal,
        foto_1,
        foto_2,
        foto_3,
      };
    }

    case SET_PRICES: {
      return {
        ...state,
        ...action.prices,
      };
    }

    case SET_PRODUCT: {
      return action.product;
    }

    default: {
      return state;
    }
  }
}
