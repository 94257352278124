import { Grid, Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableContent from '../template/TableContent';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import React, { Dispatch, SetStateAction } from 'react';
import { Agent } from 'types/agent';
import { useCustomerAgents } from '../hook/useCustomerAgents';
import { customerAgentTableTemplate } from '../template/customerAgentTableTemplate';
import CustomerAgentTableItem from './CustomerAgentTableItem';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

interface CustomerAgentTableProps {
  agents: Agent[];
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
  handleSort(id: string): void;
  orderedIndex: OrderIndexData;
}

const CustomerAgentTable: React.FC<CustomerAgentTableProps> = ({ agents, setAnchorEl, handleSort, orderedIndex }) => {
  const classes = styles();
  const { page, rowsPerPage } = usePagination();
  const { setSelectedAgent } = useCustomerAgents();

  function handleClick(agent: Agent) {
    setSelectedAgent(agent);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={customerAgentTableTemplate}>
          <TableContent>
            <TableHeader>
              {customerAgentTableTemplate.map(item => (
                <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
                  <Typography variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </div>
              ))}
            </TableHeader>
            {agents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(agent => (
              <TableRow onClick={() => handleClick(agent)} key={agent.id}>
                <CustomerAgentTableItem setAnchorEl={setAnchorEl} agent={agent} />
              </TableRow>
            ))}
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default CustomerAgentTable;
