import React, { useMemo } from 'react';
import { useApp } from 'hooks/app';
import { Pie } from 'react-chartjs-2';
import { moneyFormat } from 'helpers/numberFormat';
import { ContentChart } from 'components/chart/box/ContentChart';
import { FinancialSalesReportTotal } from '../DashboardFinancial';
import { externalTooltipHandler } from 'components/chart/externalTooltip';

interface SalesChartProps {
  formattedTotalChart: FinancialSalesReportTotal;
}

export const SalesChart: React.FC<SalesChartProps> = ({ formattedTotalChart }) => {
  const app = useApp();

  const pieData = useMemo(() => {
    return [formattedTotalChart.total];
  }, [formattedTotalChart]);

  return (
    <ContentChart title="Vendas">
      <Pie
        height={app.isMobile && app.windowWidth < 960 ? 350 : 200}
        data={{
          labels: ['Total'],
          datasets: [
            {
              label: 'Total',
              data: [formattedTotalChart.total],
              backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
              borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
              borderWidth: 1,
            },
          ],
        }}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
              callbacks: {
                label: tooltip => moneyFormat(pieData[tooltip.dataIndex as number]),
              },
              external: externalTooltipHandler,
            },
          },
          responsive: true,
        }}
      />
    </ContentChart>
  );
};
