import Appbar from 'components/appbar/Appbar';
import React, { useEffect, useState } from 'react';
import CategoryFilterBox from './CategoryFilterBox';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { useFetchCategories } from './hooks/useFetchCategories';
import { Category } from 'types/category';
import useTableOrder from 'hooks/tableOrder';
import { styled } from '@mui/material';
import CategoryHeaderButtons from './CategoryHeaderButtons';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { CategoryProvider } from './hooks/useCategory';
import { categoryTableTemplate } from './template/categoryTableTemplate';
import PaginationProvider from 'hooks/pagination';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableContainer from 'components/table/TableContainer';
import NoData from 'components/no-data/NoData';
import Pagination from 'components/pagination/Pagination';
import CategoryListTable from './list/table/CategoryListTable';
import CategoryListModule from './list/module/CategoryListModule';

export type CategoriesQueryParams = {
  nome: string;
};

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const initialQueryParams = {
  nome: '',
};

let timer: NodeJS.Timeout;

const Categories: React.FC = () => {
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const [queryParams, setQueryParams] = useState<CategoriesQueryParams>(initialQueryParams);
  const { categories, loading, fetch } = useFetchCategories();
  const [filtered, setFiltered] = useState<Category[]>([]);

  useEffect(() => {
    fetch(initialQueryParams);
  }, [fetch]);

  useEffect(() => {
    setFiltered(categories);
  }, [categories]);

  function handleQueryParamsChange(index: keyof CategoriesQueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));

    const query = {
      ...queryParams,
      [index]: value,
    };

    clearTimeout(timer);

    timer = setTimeout(() => fetch(query), 500);
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <>
      <Appbar title="Categorias" />
      <PageHeaderActions
        title="Gestão das categorias do site"
        description="Crie e edite as categorias do site H2i"
        ActionComponent={<CategoryHeaderButtons />}
      />

      <CategoryFilterBox
        displayMode={displayMode}
        setDisplayMode={setDisplayMode}
        handleQueryParamsChange={handleQueryParamsChange}
        queryParams={queryParams}
      />

      <CategoryProvider value={{ selectedCategory, setSelectedCategory }}>
        <TableContainer tableTemplate={categoryTableTemplate}>
          <PaginationProvider>
            {loading ? (
              displayMode === 'list' ? (
                <TableLoading />
              ) : (
                <ModuleLoading />
              )
            ) : filtered.length === 0 ? (
              <NoData message="Nenhum categoria para listar" />
            ) : (
              <Container>
                {displayMode === 'list' ? (
                  <CategoryListTable categories={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
                ) : (
                  displayMode === 'module' && <CategoryListModule categories={filtered} />
                )}
              </Container>
            )}

            <Pagination count={filtered.length} />
          </PaginationProvider>
        </TableContainer>
      </CategoryProvider>
    </>
  );
};

export default Categories;
