import React from 'react';
import { Button, ListItem, Typography, styled } from '@mui/material';
import { Remittance } from 'types/remittance';
import { useRemittances } from './hooks/useRemittances';

const ListItemStyled = styled(ListItem)({
  justifyContent: 'space-between',
  boxShadow: '1px 1px 9px 1px #eee',
  display: 'flex',
});

interface RemittanceItemProps {
  remittance: Remittance;
}

const RemittanceItem: React.FC<RemittanceItemProps> = ({ remittance }) => {
  const { handleDelete, selectedId, saving } = useRemittances();

  function handleDownload() {
    const token = localStorage.getItem('h2i@token');

    window.open(`${remittance.url}?token=${token}`);
  }

  return (
    <ListItemStyled>
      <div>
        <Typography variant="subtitle2">remessa {remittance.id}</Typography>
        <Typography variant="body2" color="textSecondary">
          {remittance.formattedCreatedAt}
        </Typography>
      </div>
      <div>
        <Button
          disabled={selectedId === remittance.id && saving}
          variant="text"
          size="small"
          color="primary"
          onClick={() => handleDelete(remittance.id)}
        >
          Excluir
        </Button>
        <Button variant="text" size="small" color="primary" onClick={handleDownload}>
          Download
        </Button>
      </div>
    </ListItemStyled>
  );
};

export default RemittanceItem;
