import { User, UserActionsType, USER_CHANGE, SET_USER, SET_PROFILE } from './types';

export const USER_INITIAL_STATE: User = {
  name: '',
  email: '',
  username: '',
  mobile_phone: '',
  fixed_phone: '',
  role: '',
  branches: '',
  document_number: '',
  active: true,
  password: '',
  password_confirmation: '',
  formattedActive: 'Sim',
};

export default function userReducer(state = USER_INITIAL_STATE, action: UserActionsType): User {
  switch (action.type) {
    case USER_CHANGE: {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case SET_USER: {
      return {
        ...action.user,
        password: '',
        password_confirmation: '',
        formattedActive: action.user.active ? 'Sim' : 'Não',
      };
    }

    case SET_PROFILE: {
      return {
        ...state,
        user_profile_id: action.profile.id,
        profile: action.profile,
      };
    }

    default: {
      return state;
    }
  }
}
