import React, { MouseEvent } from 'react';
import { useTable } from 'components/table/hook/useTable';
import { Typography, styled } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useFinancialSummary } from '../../hooks/useFinancialSummary';
import { SummaryResult } from 'types/summary/summaryResult';

interface SummaryItemResultProps {
  data: SummaryResult;
}

const Icon = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  '& > svg': {
    transition: 'all ease 0.3s',
    '&.active': {
      transform: 'rotate(180deg)',
    },
  },
});

const SummaryItemResult: React.FC<SummaryItemResultProps> = ({ data }) => {
  const { tableTemplate } = useTable();
  const { setSelectedResult, selectedResult } = useFinancialSummary();

  function handleClick(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    setSelectedResult(selectedResult === null ? data : null);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'action' ? (
            <Icon onClick={e => handleClick(e)} key={item.id}>
              <KeyboardArrowDown className={selectedResult?.filial === data.filial ? 'active' : ''} />
            </Icon>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
              <Typography
                color={item.originalId === 'valor' ? (data[item.id] < 0 ? '#ff0000' : 'green') : ''}
                fontWeight={item.originalId === 'valor' ? 600 : 0}
                variant="body2"
              >
                {data[item.id]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default SummaryItemResult;
