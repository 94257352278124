import React, { useState, FormEvent } from 'react';
import Appbar from 'components/appbar/Appbar';
import { IconButton, InputAdornment, TextField, Tooltip, styled } from '@mui/material';
import { AIProduct, AiProductPrices } from 'types/aiProduct';
import { moneyFormat } from 'helpers/numberFormat';
import { MdSearch } from 'react-icons/md';
import NoData from 'components/no-data/NoData';
import FilterBox from 'components/filter-box/FilterBox';
import TableContainer from 'components/table/TableContainer';
import { aiProductTableTemplate } from './aiProductTableTemplate';
import PaginationProvider from 'hooks/pagination';
import { crawlerApi } from 'services/crawlerApi';
import InsideLoading from 'components/loading/InsideLoading';
import { useApp } from 'hooks/app';
import { Product } from 'types/product';
import AiProductH2i from './AiProductH2i';
import { StockBalanceData } from 'types/reports/stockBalance';
import { useMessaging } from 'hooks/messaging';

const Container = styled('div')({
  display: 'flex',
});

const AiProduct: React.FC = () => {
  const { h2iApi } = useApp();
  const { handleOpen } = useMessaging();
  const [ean, setEan] = useState('');
  const [stockBalance, setStockBalance] = useState<StockBalanceData>({} as StockBalanceData);
  const [productName, setProductName] = useState('');
  const [averagePrice, setAveragePrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState('');
  const [products, setProducts] = useState<AIProduct[]>([]);
  const [productH2i, setProductH2i] = useState<Product | null>(null);

  async function handleSearch(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation('');
    if (!ean) {
      setValidation('Digite o EAN do produto');
      return;
    }

    if (!h2iApi) {
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const _productsH2i = await h2iApi.get(`/api/produtoTodos?ean=${ean}`);
      const _stock = await h2iApi.get(`/api/saldoestoque?id_produto=${_productsH2i.data[0].codigo_produto}`);
      setProductH2i(_productsH2i.data[0]);
      setStockBalance(_stock.data[0]);
    } catch (err) {
      handleOpen('Produto não encontrado na base da H2i');
      setProductH2i(null);
      console.error(err);
    }

    try {
      const _products = await crawlerApi.get<AiProductPrices>(`/products/${ean}`);
      setProductName(_products.data.name);
      setAveragePrice(_products.data.prices.reduce((sum, item) => sum + item.price / _products.data.prices.length, 0));
      setProducts(
        _products.data.prices
          .filter(product => product.price > 0)
          .map(product => ({
            ...product,
            formattedPrice: moneyFormat(product.price),
          })),
      );
    } catch (err) {
      handleOpen('Produto não encontrado na internet');
      console.error(err);
    }

    setLoading(false);
  }

  return (
    <>
      <Appbar title="Comparar produto" />

      <FilterBox style={{ maxWidth: 450 }}>
        <form onSubmit={handleSearch}>
          <TextField
            value={ean}
            error={!!validation}
            helperText={validation}
            label="EAN"
            placeholder="Digite o EAN do produto"
            onChange={e => setEan(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Pesquisar">
                    <IconButton disabled={loading} type="submit">
                      <MdSearch color="#888" size={20} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </FilterBox>

      <TableContainer tableTemplate={aiProductTableTemplate}>
        {loading ? (
          <InsideLoading />
        ) : productH2i || products.length > 0 ? (
          <Container>
            <PaginationProvider>
              <AiProductH2i
                stockBalance={stockBalance}
                averagePrice={averagePrice}
                productName={productName}
                products={products}
                productH2i={productH2i}
              />
            </PaginationProvider>
          </Container>
        ) : (
          <NoData message="Nenhum produto encontrado" />
        )}
      </TableContainer>
    </>
  );
};

export default AiProduct;
