import React, { useEffect, useState, useCallback, FormEvent } from 'react';
import { Button, Typography, styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import { api } from 'services/api';
import { User } from 'types/user';
import UserListTable from './table/UserList';
import UserListCard from './card/UserList';
import UsersTableLoading from './table/UsersTableLoading';
import UserActions from './UserActions';
import UsersCardLoading from './card/UsersCardLoading';
import history from 'services/history';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import Pagination from 'components/pagination/Pagination';
import UsersFilterBox from './UsersFilterBox';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { usePermissionRules } from 'hooks/permissionRules';

const CustomNoUsers = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
  marginTop: 10,
});

const NoUsers: React.FC = () => {
  return (
    <CustomNoUsers>
      <Typography variant="h6" color="textSecondary">
        Nenhum usuário
      </Typography>
    </CustomNoUsers>
  );
};

export type UsersQueryParams = {
  term: string;
  active: string;
};

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [indexFiltered, sort] = useTableOrder();
  const [params, setParams] = useState<UsersQueryParams>({ term: '', active: '1' });
  const { checkPermission } = usePermissionRules();

  const fetch = useCallback((term: string, active: string) => {
    setLoading(true);

    api
      .get<User[]>('/users', { params: { term, active } })
      .then(response => {
        const _users = response.data.map(user => ({
          ...user,
          formattedProfileName: user.user_profile ? user.user_profile.name : '',
          formattedActive: user.active ? 'Sim' : 'Não',
        }));
        setUsers(_users);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetch('', '1');
  }, [fetch]);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  function handleSort(id: string) {
    setFilteredUsers(sort(id, filteredUsers));
  }

  function handleQueryParamsChange(index: keyof UsersQueryParams, value: string) {
    setParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    fetch(params.term, params.active);
  }

  return (
    <>
      <Appbar title="Configurações" ActionsComponent={<UserActions />} />
      <PageHeaderActions
        title="Usuários"
        ActionComponent={
          <Button color="primary" variant="contained" size="small" onClick={() => history.push('/user')}>
            Adicionar
          </Button>
        }
      />

      {checkPermission('settings.user_control.users.list') && (
        <>
          <UsersFilterBox
            handleQueryParamsChange={handleQueryParamsChange}
            params={params}
            displayMode={displayMode}
            setDisplayMode={setDisplayMode}
            handleSubmit={handleSubmit}
          />

          <PaginationProvider>
            {displayMode === 'list' ? (
              loading ? (
                <UsersTableLoading />
              ) : filteredUsers.length > 0 ? (
                <UserListTable users={filteredUsers} handleSort={handleSort} orderedIndex={indexFiltered} />
              ) : (
                <NoUsers />
              )
            ) : loading ? (
              <UsersCardLoading />
            ) : filteredUsers.length > 0 ? (
              <UserListCard users={filteredUsers} />
            ) : (
              <NoUsers />
            )}
            <Pagination count={filteredUsers.length} />
          </PaginationProvider>
        </>
      )}
    </>
  );
};

export default Users;
