import React, { HTMLAttributes } from 'react';
import { IconButton, styled } from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

const Container = styled('div')(props => ({
  minWidth: 80,
  [props.theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

interface DisplayModeButtonsProps extends HTMLAttributes<HTMLDivElement> {
  handleShowList(): void;
  handleShowModule(): void;
  displayMode: 'list' | 'module';
}

const DisplayModeButtons: React.FC<DisplayModeButtonsProps> = ({
  handleShowList,
  handleShowModule,
  displayMode,
  className,
  ...rest
}) => {
  return (
    <Container className={className} {...rest}>
      <IconButton onClick={handleShowList}>
        <ViewListIcon color={displayMode === 'list' ? 'primary' : undefined} />
      </IconButton>
      <IconButton onClick={handleShowModule}>
        <ViewModuleIcon color={displayMode === 'module' ? 'primary' : undefined} />
      </IconButton>
    </Container>
  );
};

export default DisplayModeButtons;
