import React from 'react';
import { RegisterForm } from 'types/registerForm';

import { RegisterSelfieValidation } from './validation/registerSelfieValidation';
import ImageSelfieUpload from 'components/image-upload/ImageSelfieUpload';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  imageUploader: {
    marginBottom: 15,
  },
});

interface RegisterFormSelfieProps {
  register: RegisterForm;
  handleChange(index: keyof RegisterForm, value: any): void;
  validation: RegisterSelfieValidation;
}

const RegisterFormSelfie: React.FC<RegisterFormSelfieProps> = ({ register, handleChange, validation }) => {
  const classes = styles();

  return (
    <ImageSelfieUpload
      handleRemoveImage={() => handleChange('partner_selfie', null)}
      handleSetImage={image => handleChange('partner_selfie', image)}
      validationError={validation.partner_selfie}
      image={register.partner_selfie}
      label="selfie do sócio"
      className={classes.imageUploader}
      helperText="clique aqui para enviar"
    />
  );
};

export default RegisterFormSelfie;
