import { createContext, useContext } from 'react';
import { AppBanner } from 'types/appBanner';

type AppBannersContextValue = {
  selectedBanner: AppBanner | null;
  setSelectedBanner(banner: AppBanner | null): void;
};

const AppBannersContext = createContext<AppBannersContextValue>({} as AppBannersContextValue);
export const AppBannersProvider = AppBannersContext.Provider;

export function useAppBanners(): AppBannersContextValue {
  const context = useContext(AppBannersContext);
  return context;
}
