import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

interface BillingSettingActionsProps {
  handleDelete(): void;
}

const BillSettingActionsDelete: React.FC<BillingSettingActionsProps> = ({ handleDelete }) => {
  return (
    <Tooltip title="Excluir">
      <IconButton color="inherit" onClick={handleDelete}>
        <Delete />
      </IconButton>
    </Tooltip>
  );
};

export default BillSettingActionsDelete;
