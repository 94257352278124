import React from 'react';
import { useCustomer } from '../../hooks/useCustomer';

interface CustomerDocumentProps {
  linkText: string;
  index: 'link_contrato_social' | 'link_declaracao' | 'link_documento' | 'link_residencia' | 'link_selfie';
}

const CustomerDocument: React.FC<CustomerDocumentProps> = ({ linkText, index }) => {
  const { customer } = useCustomer();

  return (
    <>
      {customer && customer[index] && (
        <>
          <a href={customer[index]} target="_blank" rel="noopener noreferrer">
            {linkText}
          </a>
          <br />
        </>
      )}
    </>
  );
};

export default CustomerDocument;
