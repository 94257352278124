import React, { Dispatch, MouseEvent, SetStateAction, useCallback } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { PurchaseOrder } from 'types/purchaseOrder';
import { subDays } from 'date-fns';
import { usePurchase } from 'pages/purchase/order/hooks/usePurchase';

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
  width: 350,
});

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

interface PurchaseOrderItemTableProps {
  order: PurchaseOrder;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

const PurchaseOrderItemTable: React.FC<PurchaseOrderItemTableProps> = ({ setAnchorEl, order }) => {
  const { tableTemplate } = useTable();
  const { setSelectedOrder } = usePurchase();
  const expired = useCallback((order: PurchaseOrder) => {
    const today = subDays(new Date(), 1);

    if (!order.formattedNewForecast) return false;

    return order.formattedNewForecast < today.toISOString();
  }, []);

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedOrder(order);
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <CustomIconButton onClick={handleClick} key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
              <Tooltip title={order[item.id]}>
                <Description style={{ color: expired(order) ? '#ff0000' : '' }} variant="caption">
                  {order[item.id]}
                </Description>
              </Tooltip>
            </div>
          ),
        )}
    </>
  );
};

export default PurchaseOrderItemTable;
