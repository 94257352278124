import React from 'react';
import { List } from '@mui/material';
import { Remittance } from 'types/remittance';
import RemittanceItem from './RemittanceItem';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 5,
  },
});

interface RemittanceListProps {
  remittances: Remittance[];
}

const RemittanceList: React.FC<RemittanceListProps> = ({ remittances }) => {
  const classes = styles();

  return (
    <List disablePadding className={classes.list}>
      {remittances.map(remittance => (
        <RemittanceItem remittance={remittance} key={remittance.id} />
      ))}
    </List>
  );
};

export default RemittanceList;
