import React, { useEffect, useState } from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import GroupedBankStatementItemModule from './GroupedBankStatementItemModule';
import { Extract } from 'types/extract';
import { Typography, styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';

type GroupedBankStatementListModuleProps = {
  extracts: Extract[];
};

interface GroupedData {
  total: number;
  result: Extract[];
  date: string;
}

const Description = styled(Typography)({
  fontSize: '11pt',
  fontWeight: 600,
  marginLeft: 10,
  color: '#30519f',
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Flex = styled('div')({
  display: 'flex',
  alignItems: 'center',
  background: '#eee',
  border: '1px solid #ddd',
  borderBottom: 'none',
  justifyContent: 'space-between',
});

const Total = styled(Typography)({
  textAlign: 'end',
  fontSize: '11pt',
  gridColumn: '3/4',
  padding: '5px 10px',
  fontWeight: 600,
});

const GroupedBankStatementListModule: React.FC<GroupedBankStatementListModuleProps> = ({ extracts }) => {
  const { rowsPerPage, page } = usePagination();
  const [groupedData, setGroupedData] = useState<GroupedData[]>([]);

  useEffect(() => {
    const groupDataByDate = (data: Extract[]) => {
      const grouped: GroupedData[] = [];

      data.forEach(item => {
        const dateKey = item.data;

        const existingGroup = grouped.find(group => group.date === dateKey);

        if (existingGroup) {
          existingGroup.result.push(item);
          existingGroup.total = existingGroup.total + item.valor;
        } else {
          grouped.push({
            date: dateKey,
            result: [item],
            total: item.valor,
          });
        }
      });

      return grouped;
    };

    setGroupedData(groupDataByDate(extracts));
  }, [extracts]);

  return (
    <List>
      {groupedData.map((data, index) => (
        <Container key={index}>
          <Flex>
            <Description>{data.date}</Description>
            <Total>{moneyFormat(data.total)}</Total>
          </Flex>
          {data.result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((extract, _index) => (
            <GroupedBankStatementItemModule key={_index + groupedData.length} extract={extract} />
          ))}
        </Container>
      ))}
    </List>
  );
};

export default GroupedBankStatementListModule;
