import { useCallback } from 'react';
import { Rental, RentalOnList } from '../types/rental';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/numberFormat';

export function useRentalFormatter() {
  const formatDate = useCallback((date: string | Date | null): string => {
    if (!date) {
      return '';
    }

    if (date instanceof Date) {
      return format(date, 'P', { locale: ptBR });
    }

    return format(parseISO(date), 'P', { locale: ptBR });
  }, []);

  const formatter = useCallback(
    (rental: Rental): RentalOnList => {
      return {
        ...rental,
        formattedEndAt: formatDate(rental.end_at),
        formattedCreatedAt: formatDate(rental.created_at),
        formattedPaidAt: formatDate(rental.paid_at),
        formattedStartAt: formatDate(rental.start_at),
        formattedActive: rental.active ? 'Sim' : 'Não',
        formattedTotal: moneyFormat(rental.total),
        formattedCommissionTotal: moneyFormat(rental.commission_total),
        products: rental.products
          ? rental.products.map(product => ({
              ...product,
              formattedValue: moneyFormat(product.value),
            }))
          : [],
      };
    },
    [formatDate],
  );

  return formatter;
}
