import { IconButton, ListItemButton, Typography, styled } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { MouseEvent, useState, Dispatch, SetStateAction } from 'react';
import { useRentalPayments } from '../../hooks/useRentalPayment';
import { RentalPayment } from 'pages/rentals/types/rental';

const IconButtonStyled = styled(IconButton)({
  position: 'absolute',
  bottom: 0,
  right: 0,
});

const ListItem = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 120,
  flexDirection: 'column',
  '& .data': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

type RentalPaymentItemModuleProps = {
  payment: RentalPayment;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const RentalPaymentItemModule: React.FC<RentalPaymentItemModuleProps> = ({ payment, setAnchorEl }) => {
  const { setSelectedRentalPayment } = useRentalPayments();
  const [collapse, setCollapse] = useState(false);

  function handleClick() {
    setCollapse(!collapse);
    setSelectedRentalPayment(payment);
  }

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    setSelectedRentalPayment(payment);
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  }

  return (
    <>
      <ListItem onClick={() => handleClick()}>
        <div className="data">
          <Typography variant="body2" className="product-name">
            {payment.external_id}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {payment.formattedValue}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {payment.formattedPaidAt}
          </Typography>
        </div>

        <IconButtonStyled onClick={e => handleMoreClick(e)}>
          <MoreVert />
        </IconButtonStyled>
      </ListItem>
    </>
  );
};

export default RentalPaymentItemModule;
