import { TableTemplate } from 'types/tableTemplate';

const actionTableTemplate = [
  {
    id: 'select',
    description: 'Selecionar',
    originalId: 'select',
    width: 40,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'data',
    description: 'Data',
    originalId: 'formattedDate',
    width: 85,
  },
];

export const groupedExtractTableTemplate: TableTemplate[] = [
  {
    id: 'mes_ano',
    description: 'Mês/Ano',
    originalId: 'mes_ano',
    width: 75,
  },
  {
    id: 'descricao',
    description: 'Descrição',
    originalId: 'descricao',
    width: 330,
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'valor',
    dataType: 'number',
    width: 110,
  },
  {
    id: 'conta',
    description: 'Conta origem',
    originalId: 'conta',
    width: 260,
  },
  {
    id: 'conta_destino',
    description: 'Conta destino',
    originalId: 'conta_destino',
    width: 300,
  },
  {
    id: 'cod_contabil_origem',
    description: 'C.C.C.O',
    originalId: 'cod_contabil_origem',
    width: 90,
  },
  {
    id: 'cod_contabil_destino',
    description: 'C.C.C.D',
    originalId: 'cod_contabil_destino',
    width: 90,
  },
  {
    id: 'formattedType',
    description: 'Tipos',
    originalId: 'tipo_movimentacao',
    width: 100,
  },
  {
    id: 'id_movimentacao',
    description: 'Transação',
    originalId: 'id_movimentacao',
    width: 80,
  },
];

export const extractTableTemplate: TableTemplate[] = [...actionTableTemplate, ...groupedExtractTableTemplate];
