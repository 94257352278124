import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { ManagedSaleOnList } from '../types/managedSale';

export interface ManagedSalesContextValue {
  selectedRental: ManagedSaleOnList | null;
  setSelectedRental: Dispatch<SetStateAction<ManagedSaleOnList | null>>;
  handleStatus(): void;
  setReport: Dispatch<SetStateAction<boolean>>;
}

const Context = createContext<ManagedSalesContextValue>({} as ManagedSalesContextValue);
export const RentalProvider = Context.Provider;

export function useManagedSales(): ManagedSalesContextValue {
  return useContext(Context);
}
