import React from 'react';

import { AbcProductBranch } from 'types/abcProduct';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography } from '@mui/material';
import { useAbcProduct } from '../../../hooks/useAbcProduct';
import { useAbcProducts } from '../../../hooks/useAbcProducts';
import { MoreHoriz } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  text: {
    fontSize: 11,
  },
  subtitle: {
    fontWeight: 'bold',
  },
  subItem: {
    paddingLeft: 10,
  },
});

type BranchItemTableProps = {
  branch: AbcProductBranch;
};

const BranchItemTable: React.FC<BranchItemTableProps> = ({ branch }) => {
  const classes = styles();
  const { tableTemplate } = useTable();
  const { setOpenModalBranch } = useAbcProduct();
  const { setSelectedBranch } = useAbcProducts();

  function handleClick() {
    setSelectedBranch(branch);
    setOpenModalBranch(true);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <IconButton key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'formattedName' && branch.type === 'subtitle' ? (
            <div key={item.id}>
              <Typography className={classes.text} variant="body2">
                <strong>{branch.filial}</strong>
              </Typography>
            </div>
          ) : item.id === 'formattedName' ? (
            <div key={item.id}>
              <Typography className={`${classes.text} ${classes.subItem}`} variant="body2">
                {branch.formattedName}
              </Typography>
            </div>
          ) : (
            <div onClick={handleClick} key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography style={{ fontSize: 11 }} variant="body2">
                {branch[item.id]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default BranchItemTable;
