import React, { useState } from 'react';
import Dialog from 'components/dialogs/Dialog';
import { MenuItem, Select } from '@mui/material';
import { FinancialCard } from 'types/financialReportList';
import { FinancialCardFlag } from '../hooks/useFetchFinancialCard';

import { makeStyles } from '@mui/styles';
import { useDefaultExcelExport } from 'hooks/useDefaultExcelExport';
import ExcelButtonDownload from 'components/excel-button-download/ExcelButtonDownload';

const styles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 30,
  },
});

interface FinancialCardExportProps {
  cards: FinancialCard[];
  flags: FinancialCardFlag[];
  onExited(): void;
}

const FinancialCardExport: React.FC<FinancialCardExportProps> = ({ cards, flags, onExited }) => {
  const classes = styles();
  const [tab, setTab] = useState<'flag' | 'list'>('list');

  const { handleDownload: handleDownloadCards } = useDefaultExcelExport(tab === 'flag' ? flags : cards);

  return (
    <Dialog onExited={onExited} title="Exportar para Excel" maxWidth="sm" height="70vh">
      <div className={classes.container}>
        <Select fullWidth value={tab}>
          <MenuItem value="list" onClick={() => setTab('list')}>
            Cartões
          </MenuItem>

          <MenuItem value="flag" onClick={() => setTab('flag')}>
            Bandeiras
          </MenuItem>
        </Select>
      </div>

      <ExcelButtonDownload handleDownload={handleDownloadCards} />
    </Dialog>
  );
};

export default FinancialCardExport;
