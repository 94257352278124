import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useResellers } from '../hooks/useResellers';

type ResellersListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const ResellersListMenu: React.FC<ResellersListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { setDialogApproval, handleSincronizeAgent } = useResellers();

  function handleVerifyClick() {
    setDialogApproval(true);
    setAnchorEl(null);
  }

  function handleSincronizeClick() {
    handleSincronizeAgent();
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleVerifyClick}>Aprovar cadastro</MenuItem>
      <MenuItem onClick={handleSincronizeClick}>Sincronizar preposto</MenuItem>
    </Menu>
  );
};

export default ResellersListMenu;
