import React, { useEffect, useState } from 'react';
import { useCommercialBi } from '../../hooks/useCommercialBi';
import CommercialBiPaymentMethodListTable from './table/CommercialBiPaymentMethodListTable';
import useTableOrder from 'hooks/tableOrder';
import { CommercialBiPaymentMethod } from '../../types/commercial-bi';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { styled } from '@mui/material';

const Container = styled('div')({
  padding: 20,
  // backgroundColor: '#f5f5f5',
});

const CommercialBiPaymentMethods: React.FC = () => {
  const { data } = useCommercialBi();
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<CommercialBiPaymentMethod[]>([]);

  useEffect(() => {
    setFiltered(data.payment_methods);
  }, [data.payment_methods]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <PaginationProvider>
      <Container>
        <CommercialBiPaymentMethodListTable
          handleSort={handleSort}
          orderedIndex={orderedIndex}
          paymentMethods={filtered}
        />
        <Pagination count={filtered.length} />
      </Container>
    </PaginationProvider>
  );
};

export default CommercialBiPaymentMethods;
