import { createContext, useContext } from 'react';
import { StockFilter } from 'types/reports/stockFilter';

type WasteStockContextValue = {
  filter: StockFilter;
};

const WasteStockContext = createContext<WasteStockContextValue>({} as WasteStockContextValue);
export const WasteStockProvider = WasteStockContext.Provider;

export function useWasteStock(): WasteStockContextValue {
  const context = useContext(WasteStockContext);
  return context;
}
