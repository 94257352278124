import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { moneyFormat } from 'helpers/numberFormat';
import { TransferAccount } from 'types/transferAccount';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { TransferAccountFilterProps } from '../TransferAccountPage';
import { TotalizingAccount } from 'types/totalizingAccount';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function useFetchTransferAccount(filter: TransferAccountFilterProps) {
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<TransferAccount[]>([]);
  const [totalizingAccounts, setTotalizingAccounts] = useState<TotalizingAccount[]>([]);
  const { financialApi, isOpenedMenu, handleOpenMenu } = useApp();

  useEffect(() => {
    if (!financialApi) {
      return;
    }

    setLoading(true);

    financialApi
      .get('/getcontastotalizadoras', {
        params: {
          id_filial: '',
          filtro: '',
        },
      })
      .then(response => {
        if (response.data.MESSAGE) {
          setTotalizingAccounts([]);
          return;
        }
        setTotalizingAccounts(
          response.data.map(item => {
            item.formattedSaldo = moneyFormat(item.saldo);
            return item;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [financialApi]);

  const fetch = useCallback(() => {
    if (!financialApi) {
      return;
    }

    if (isOpenedMenu) handleOpenMenu();

    const formattedInitialDate = format(filter.initial_date, 'P', { locale: ptBR });
    const formattedFinalDate = format(filter.final_date, 'P', { locale: ptBR });

    setLoading(true);

    financialApi
      .get('/gettransferenciascontas', {
        params: {
          id_conta_origem: filter.originAccountId || '',
          id_filial: filter.branchId === 1001 ? '' : filter.branchId,
          id_conta_destino: filter.targetAccountId || '',
          data_fim: formattedFinalDate,
          data_ini: formattedInitialDate,
          observacao: filter.observation,
        },
      })
      .then(response => {
        if (response.data.MESSAGE) {
          setAccounts([]);
          return;
        }
        setAccounts(
          response.data.map(item => {
            item.formattedValue = moneyFormat(item.valor);
            item.date = new Date(parsePtBRDate(item.data));
            item.formattedDate = parsePtBRDate(item.data).toISOString();
            return item;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, financialApi, isOpenedMenu, handleOpenMenu]);

  return { accounts, totalizingAccounts, loading, fetch };
}
