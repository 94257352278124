import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { BillSetting } from 'types/billSetting';

type BillingContextValue = {
  selectedBill: BillSetting | null;
  setSelectedBill: Dispatch<SetStateAction<BillSetting | null>>;
};

const BillingContext = createContext<BillingContextValue>({} as BillingContextValue);
export const BillingProvider = BillingContext.Provider;

export function useBilling(): BillingContextValue {
  const context = useContext(BillingContext);
  return context;
}
