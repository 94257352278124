import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React from 'react';
import { useSelector } from 'store/selector';

const TransferAccountReportHeader: React.FC = () => {
  const now = format(new Date(), 'PPpp', { locale: ptBR });
  const user = useSelector(state => state.user);

  return (
    <div className="header">
      <div>
        <h6>Transferência entre contas</h6>
      </div>
      <div>
        <p>{user?.name}</p>
        <p>{now}</p>
      </div>
    </div>
  );
};

export default TransferAccountReportHeader;
