import React, { useMemo } from 'react';
import { externalTooltipHandler } from 'components/chart/externalTooltip';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { Pie } from 'react-chartjs-2';
import { GroupedUnity } from './table/GroupedUnityListTable';
import { SalesMethod } from 'types/salesReport';

interface UnityChartProps {
  units: GroupedUnity[];
  salesMethods: SalesMethod[];
}

const UnityChart: React.FC<UnityChartProps> = ({ units, salesMethods }) => {
  const pieData = useMemo(() => {
    return units.sort((a, b) => b.total - a.total).map(item => item.total);
  }, [units]);

  return (
    <div style={{ height: 560, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
      <Pie
        data={{
          labels: units
            .filter(unity => unity.percentual > 4)
            .sort((a, b) => b.total - a.total)
            .map(branch => [branch.unidade]),
          datasets: [
            {
              label: 'Total',
              data: units
                .filter(unity => unity.percentual > 4)
                .sort((a, b) => b.total - a.total)
                .map(branch => branch.total),
              borderWidth: 0,
            },
          ],
        }}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
              callbacks: {
                label: tooltip => moneyFormat(pieData[tooltip.dataIndex as number]),
              },
              external: externalTooltipHandler,
            },
            legend: {
              display: true,
              position: 'top',
            },
            colors: {
              enabled: true,
            },
            title: {
              text: 'Unidades mais atendidas',
              display: true,
              position: 'top',
              align: 'center',
            },
            datalabels: {
              display: true,
              color: '#fff',
              font: {
                size: 14,
                weight: 600,
              },
              textAlign: 'left',
              formatter(value, context) {
                return percentFormat((value / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100);
              },
            },
          },
          responsive: true,
        }}
      />
    </div>
  );
};

export default UnityChart;
