import React, { MouseEvent } from 'react';
import { AbcProductUnity } from 'types/abcProduct';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography, styled } from '@mui/material';
import { useAbcUnits } from '../../../hooks/useAbcUnits';
import { KeyboardArrowDown, MoreHoriz } from '@mui/icons-material';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

type UnityItemTableProps = {
  unity: AbcProductUnity;
  isSubgroup: boolean;
};

const UnityItemTable: React.FC<UnityItemTableProps> = ({ unity, isSubgroup }) => {
  const { tableTemplate } = useTable();
  const { setMoreUnity, moreUnity } = useAbcUnits();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setMoreUnity(moreUnity ? null : unity);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <CustomIconButton key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : item.id === 'more' && isSubgroup ? (
            <CustomIconButton key={item.id} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={moreUnity?.unidade === unity.unidade ? { transform: 'rotate(180deg)' } : undefined}
              />
            </CustomIconButton>
          ) : (
            <NumericData key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
              <Typography style={{ fontSize: 11 }} variant="body2">
                {unity[item.id as keyof AbcProductUnity]}
              </Typography>
            </NumericData>
          ),
        )}
    </>
  );
};

export default UnityItemTable;
