import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { TotalizingAccount } from 'types/totalizingAccount';
import { TotalizingAccountFilterProps } from '../TotalizingAccount';

interface TotalizingContextValue {
  filter: TotalizingAccountFilterProps;
  handleChangeFilter(index: keyof TotalizingAccountFilterProps, value: any): void;
  setIsReportOpen: Dispatch<SetStateAction<boolean>>;
  handleSearch(): void;
  loading: boolean;
  accounts: TotalizingAccount[];
  setSelectedAccount: Dispatch<SetStateAction<TotalizingAccount | null>>;
  setIsNewTotalizingAccount: Dispatch<SetStateAction<boolean>>;
}

const TotalizingAccountContext = createContext<TotalizingContextValue>({} as TotalizingContextValue);
export const TotalizingAccountConsumer = TotalizingAccountContext.Consumer;
export const TotalizingAccountProvider = TotalizingAccountContext.Provider;

export function useTotalizingAccounts() {
  return useContext(TotalizingAccountContext);
}
