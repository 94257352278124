import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Agent } from 'types/agent';

interface CustomerAgentContextValue {
  selectedAgent: Agent | null;
  setSelectedAgent: Dispatch<SetStateAction<Agent | null>>;
}

const CustomerAgentContext = createContext<CustomerAgentContextValue>({} as CustomerAgentContextValue);
export const CustomerAgentProvider = CustomerAgentContext.Provider;
export const CustomerAgentConsumer = CustomerAgentContext.Consumer;

export function useCustomerAgents(): CustomerAgentContextValue {
  const context = useContext(CustomerAgentContext);
  return context;
}
