import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { ProjectContact } from 'types/project';

export type ProductContextValue = {
  selectedContact: ProjectContact | null;
  setSelectedContact: Dispatch<SetStateAction<ProjectContact | null>>;
};

export const ContactsContext = createContext<ProductContextValue>({} as ProductContextValue);
export const ProductsContextConsumer = ContactsContext.Consumer;
export const ContactsContextProvider = ContactsContext.Provider;

export function useProjectContacts(): ProductContextValue {
  return useContext(ContactsContext);
}
