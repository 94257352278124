import { Button, Theme } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { format, parseISO, endOfDay, subDays, startOfDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useSearch from 'hooks/search';
import useTableOrder from 'hooks/tableOrder';
import React, { useEffect, useState, useCallback, FormEvent } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import ResellersFilterBox from './ResellersFilterBox';
import { resellersTableTemplate } from './resellersTableTemplate';
import { ResellersProvider } from './hooks/useResellers';
import ResellerListModule from './list/module/ResellerListModule';
import ResellerListTable from './list/table/ResellerListTable';
import ResellersActions from './ResellersActions';
import { Reseller, ResellerList } from 'types/reseller';
import ResellerAproval from './approval/ResellerApproval';
import { cnpjFormatter } from 'helpers/cnpjFormatter';
import Register from './register/Register';
import { useMessaging } from 'hooks/messaging';
import { useLocation } from 'react-router';
import Loading from 'components/loading/Loading';
import { AxiosResponse } from 'axios';

import { makeStyles } from '@mui/styles';
import ResellersMobileFilter from './ResellersMobileFilter';
import { useDisplayMode } from 'hooks/useDisplayMode';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const urlParams = {
  '/resellers': {
    status: 'waiting_analysis',
    type: 'store',
  },
  '/approved-resellers': {
    status: 'approved',
    type: 'store',
  },
  '/rejected-resellers': {
    status: 'rejected',
    type: 'store',
  },
  '/updated-resellers': {
    status: 'waiting_analysis',
    type: 'update',
  },
  '/updated-approved-resellers': {
    status: 'approved',
    type: 'update',
  },
  '/updated-rejected-resellers': {
    status: 'rejected',
    type: 'update',
  },
};
export interface ResellersFilter {
  initialDate: Date | null;
  finalDate: Date | null;
  searchValue: string;
  indexToSearch: string;
}

const initial_state = {
  initialDate: startOfDay(subDays(new Date(), 60)),
  finalDate: endOfDay(new Date()),
  searchValue: '',
  indexToSearch: 'company_name',
};

const Resellers: React.FC = () => {
  const classes = useStyles();
  const [filtered, setFiltered] = useState<Reseller[]>([]);
  const [selectedReseller, setSelectedReseller] = useState<null | Reseller>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [orderedIndex, sort] = useTableOrder();
  const [dialogApproval, setDialogApproval] = useState(false);
  const [newResellerDialog, setNewResellerDialog] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const messaging = useMessaging();
  const location = useLocation();
  const [status, setStatus] = useState('');
  const [type, setType] = useState('');
  const app = useApp();
  const search = useSearch();
  const [filter, setFilter] = useState<ResellersFilter>(initial_state);

  useEffect(() => {
    setFilter(filter => ({
      ...filter,
      searchValue: '',
    }));
  }, [location]);

  useEffect(() => {
    const pathname = location.pathname as keyof typeof urlParams;

    setStatus(urlParams[pathname].status);
    setType(urlParams[pathname].type);
  }, [location]);

  const handleFormatReseller = useCallback((reseller: ResellerList): ResellerList => {
    return {
      ...reseller,
      formattedCreatedAt: format(parseISO(reseller.created_at), 'PPp', { locale: ptBR }),
      street: reseller.address.street,
      number: reseller.address.street_number,
      neighborhood: reseller.address.neighborhood,
      complement: reseller.address.complement,
      city: reseller.address.city,
      state: reseller.address.state,
      company_name: reseller.company_name.toUpperCase(),
      company_trade_name: reseller.company_trade_name.toUpperCase(),
      partner_name: reseller.partner_name.toUpperCase(),
      formattedContacted: reseller.contacted ? 'Sim' : 'Não',
      formattedCnpj: cnpjFormatter(reseller.document),
      formattedApprovedAt: reseller.approved_at ? format(parseISO(reseller.approved_at), 'PP', { locale: ptBR }) : null,
      formattedMustContact: reseller.must_contact ? 'Sim' : 'Não',
      formattedUpdateRequiredAt: reseller.update_required_at
        ? format(parseISO(reseller.update_required_at), 'PP', { locale: ptBR })
        : null,
    };
  }, []);

  const onSearch = useCallback(
    (filter: ResellersFilter, resellers: ResellerList[]) => {
      const _resellers = search(filter.searchValue, filter.indexToSearch, resellers);
      setFiltered(_resellers);
    },
    [search],
  );

  const handleSearch = useCallback(
    (filter: ResellersFilter, e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      const source = getCancelTokenSource();
      let request = true;

      setLoading(true);

      api
        .get<ResellerList[]>('/resellers', {
          cancelToken: source.token,
          params: { status, type, ...filter },
        })
        .then(response => {
          if (request) {
            const _resellers = response.data.map(item => handleFormatReseller(item));

            onSearch(filter, _resellers);
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          if (request) setLoading(false);
        });

      return () => {
        if (request) request = false;
        source.cancel();
      };
    },
    [handleFormatReseller, status, type, onSearch],
  );

  useEffect(() => {
    handleSearch(initial_state);
  }, [handleSearch]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleRemoveFromList(resellerId: number) {
    setFiltered(resellers => resellers.filter(reseller => reseller.id !== resellerId));
  }

  function handleChangeFilter(index: keyof ResellersFilter, value: Date | null) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  async function handleSincronizeAgent() {
    if (!app.h2iApi) return;

    try {
      const customerResponse = await app.h2iApi.get(`/api/cliente`, {
        params: { cnpj: selectedReseller?.document, pw: '' },
      });
      const resellerResponse = customerResponse.data[0];

      const agentData = {
        nome: selectedReseller?.partner_name,
        cpf: selectedReseller?.partner_document,
        rg: selectedReseller?.partner_rg,
        documentos: [],
        telefone: selectedReseller?.partner_phone,
        cep: selectedReseller?.partner_address?.postal_code,
        endereco: selectedReseller?.partner_address?.street,
        numero_endereco: selectedReseller?.partner_address?.street_number,
        bairro: selectedReseller?.partner_address?.neighborhood,
        cidade: selectedReseller?.partner_address?.city,
        uf: selectedReseller?.partner_address?.state,
        rma: 'S',
        compra: 'S',
        retirada: 'S',
        ativo: 'S',
        socio: 'S',
        codigo_cliente: resellerResponse.codigo,
        cnpj_cpf_cadastro: resellerResponse.cnpj_cpf,
      };

      const agentDocumentData = {
        cpf_preposto: selectedReseller?.partner_document,
        link: selectedReseller?.partner_document_copy.document_url,
        tipo: 'D',
        id_cliente: 0,
      };

      app.h2iApi.post('/api/savepreposto', agentData).catch(err => console.error(err));
      app.h2iApi.post('/api/saveprepostodocumento', agentDocumentData).catch(err => console.error(err));

      messaging.handleOpen('Sincronização realizada');
    } catch (err) {
      console.error(err);
    }
  }

  function sendAgentDocuments() {
    const requests: Array<Promise<void | AxiosResponse<void>>> = [];

    filtered.forEach(_reseller => {
      const agentDocumentData = {
        cpf_preposto: _reseller.partner_document,
        link: _reseller.partner_document_copy.document_url,
        tipo: 'D',
        id_cliente: 0,
      };

      if (app.h2iApi)
        requests.push(
          app.h2iApi.post('/api/saveprepostodocumento', agentDocumentData).catch(err => console.error(err)),
        );
    });

    return requests;
  }

  function handleSyncronizeAgentDocuments() {
    setSaving(true);

    const requests = sendAgentDocuments();

    Promise.all(requests)
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  return (
    <ResellersProvider
      value={{
        selectedReseller,
        filter,
        setSelectedReseller,
        setDialogApproval,
        handleRemoveFromList,
        handleFormatReseller,
        handleChangeFilter,
        handleSincronizeAgent,
        handleSearch,
      }}
    >
      {saving && <Loading />}
      {showFilter && <ResellersMobileFilter handleSearch={handleSearch} onExited={() => setShowFilter(false)} />}
      {dialogApproval && <ResellerAproval onExited={() => setDialogApproval(false)} />}
      {newResellerDialog && <Register onExited={() => setNewResellerDialog(false)} />}
      <Appbar title="Site" ActionsComponent={<ResellersActions handleClick={() => setShowFilter(true)} />} />
      <PageHeaderActions
        title="Revendedores"
        description="Gestão dos revendedores"
        ActionComponent={
          <>
            <Button size="small" variant="contained" color="primary" onClick={() => setNewResellerDialog(true)}>
              Adicionar
            </Button>
            <Button size="small" variant="contained" color="primary" onClick={handleSyncronizeAgentDocuments}>
              Sincronizar documentos
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => window.open('/manual_cadastro.docx')}
            >
              Manual de aprovação
            </Button>
          </>
        }
      />
      <TableContainer tableTemplate={resellersTableTemplate}>
        <ResellersFilterBox handleSearch={handleSearch} setDisplayMode={setDisplayMode} displayMode={displayMode} />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum revendedor para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <ResellerListTable resellers={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <ResellerListModule resellers={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </ResellersProvider>
  );
};

export default Resellers;
