import React from 'react';
import DialogInput from 'components/dialogs/DialogInput';
import { Button, Typography } from '@mui/material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Search } from '@mui/icons-material';
import { JustificationQueryParams } from './hooks/useFetchRequirements';

type RequirementFilterMobileProps = {
  onExited(): void;
  onSearch(): void;
  filter: JustificationQueryParams;
  handleChange(index: keyof JustificationQueryParams, value: any): void;
};

const RequirementFilterMobile: React.FC<RequirementFilterMobileProps> = ({
  onExited,
  handleChange,
  onSearch,
  filter,
}) => {
  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <div style={{ display: 'grid', gap: 10, width: '100%' }}>
        <KeyboardDatePicker
          label="Data inicial"
          value={filter.data_ini}
          onChange={date => handleChange('data_ini', date)}
        />
        <KeyboardDatePicker
          label="Data Final"
          value={filter.data_fim}
          onChange={date => handleChange('data_fim', date)}
        />

        <Button type="submit" color="primary" variant="contained" onClick={onSearch}>
          <Typography style={{ marginRight: 5 }} color="inherit">
            Pesquisar
          </Typography>
          <Search fontSize="small" />
        </Button>
      </div>
    </DialogInput>
  );
};

export default RequirementFilterMobile;
