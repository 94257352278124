import { IconButton, ListItemButton, Typography, styled } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { MouseEvent, useState, Dispatch, SetStateAction } from 'react';
import { useProjectContacts } from '../../hooks/useProjectContacts';
import { ProjectContact } from 'types/project';

const IconButtonStyled = styled(IconButton)({
  position: 'absolute',
  bottom: 0,
  right: 0,
});

const ListItem = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 120,
  flexDirection: 'column',
  '& .data': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

type ContactItemModuleProps = {
  contact: ProjectContact;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const ContactItemModule: React.FC<ContactItemModuleProps> = ({ contact, setAnchorEl }) => {
  const { setSelectedContact } = useProjectContacts();
  const [collapse, setCollapse] = useState(false);

  function handleClick(contact: ProjectContact) {
    setCollapse(!collapse);
    setSelectedContact(contact);
  }

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>, contact: ProjectContact) {
    setSelectedContact(contact);
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  }

  return (
    <>
      <ListItem onClick={() => handleClick(contact)}>
        <div className="data">
          <Typography variant="body2" className="product-name">
            {contact.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {contact.phone}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {contact.role}
          </Typography>
        </div>

        <IconButtonStyled onClick={e => handleMoreClick(e, contact)}>
          <MoreVert />
        </IconButtonStyled>
      </ListItem>
    </>
  );
};

export default ContactItemModule;
