import { TableTemplate } from 'types/tableTemplate';

export const contactsTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 70,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'name',
    description: 'NOME',
    originalId: 'name',
    width: 400,
  },
  {
    id: 'role',
    description: 'CARGO',
    originalId: 'role',
    width: 200,
  },
  {
    id: 'phone',
    description: 'TELEFONE',
    originalId: 'phone',
    width: 200,
  },
];
