import { Button, Theme } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useSearch from 'hooks/search';
import useTableOrder from 'hooks/tableOrder';
import React, { useEffect, useState, useCallback } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import ResellersFilterBox from './CreditAnalysisQuestionsFilterBox';
import { resellersTableTemplate } from './creditAnalysisQuestionsTableTemplate';
import { CreditAnalysisQuestionsProvider } from './hooks/useCreditAnalysisQuestions';
import ResellerListModule from './list/module/CreditAnalysisQuestionListModule';
import ResellerListTable from './list/table/CreditAnalysisQuestionListTable';
import CreditAnalysisQuestionsActions from './CreditAnalysisQuestionsActions';
import { CreditAnalysisQuestion, CreditAnalysisQuestionList } from 'types/creditAnalysisQuestion';
import history from 'services/history';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const CreditAnalysisQuestions: React.FC = () => {
  const classes = useStyles();
  const [questions, setQuestions] = useState<CreditAnalysisQuestion[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<null | CreditAnalysisQuestion>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<CreditAnalysisQuestion[]>([]);
  const [indexToSearch, setIndexToSearch] = useState('id');
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const search = useSearch();
  const app = useApp();

  useEffect(() => {
    const f = search(searchValue, indexToSearch, questions);
    setFiltered(f);
  }, [questions, searchValue, search, indexToSearch]);

  const handleFormatList = useCallback((creditAnalysisQuestion: CreditAnalysisQuestion): CreditAnalysisQuestionList => {
    return {
      ...creditAnalysisQuestion,
      formattedCreatedAt: format(parseISO(creditAnalysisQuestion.created_at), 'PPp', { locale: ptBR }),
      formattedForwardOnAnswerNo: creditAnalysisQuestion.forward_on_answer_no ? 'Sim' : 'Não',
    };
  }, []);

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    setLoading(true);

    api
      .get<CreditAnalysisQuestionList[]>('/credit-analysis-questions', {
        cancelToken: source.token,
      })
      .then(response => {
        if (request) setQuestions(response.data.map(item => handleFormatList(item)));
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (request) setLoading(false);
      });

    return () => {
      if (request) request = false;
      source.cancel();
    };
  }, [handleFormatList]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <CreditAnalysisQuestionsProvider
      value={{
        questions,
        selectedQuestion,
        setSelectedQuestion,
      }}
    >
      <Appbar title="Site" ActionsComponent={<CreditAnalysisQuestionsActions />} />
      <PageHeaderActions
        title="Questões para análise de crédito"
        description="Gerencie as questões usadas para a solicitação de crédito por parte dos revendedores"
        ActionComponent={
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => history.push('/credit-analysis-question')}
          >
            Adicionar
          </Button>
        }
      />
      <TableContainer tableTemplate={resellersTableTemplate}>
        <ResellersFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          indexToSearch={indexToSearch}
          setIndexToSearch={setIndexToSearch}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum parceiro para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <ResellerListTable questions={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <ResellerListModule questions={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </CreditAnalysisQuestionsProvider>
  );
};

export default CreditAnalysisQuestions;
