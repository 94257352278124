import { RentalProductFormContent } from 'pages/rentals/types/rental';
import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export type RentalProductValidation = {
  id?: string;
  name?: string;
  value?: string;
  quantity?: string;
  commission?: string;
};

type UseRentalProductValidation = [
  RentalProductValidation,
  Dispatch<SetStateAction<RentalProductValidation>>,
  (product: RentalProductFormContent) => Promise<void>,
];

export function useRentalProductValidation(): UseRentalProductValidation {
  async function handleValidation(product: RentalProductFormContent) {
    const schema = yup.object().shape({
      commission: yup.string().required('obrigatório'),
      value: yup.string().required('o valor do produto é obrigatório'),
      quantity: yup.string().required('obrigatório'),
      id: yup.string().required('O código do produto é obrigatório').nullable(),
      name: yup.string().required('o nome do produto é obrigatório'),
    });

    console.log(product);

    try {
      await schema.validate(product);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<RentalProductValidation>({});
  return [validation, setValidation, handleValidation];
}
