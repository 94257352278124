import React, { useRef, useEffect } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { BillSetting } from 'types/billSetting';
import { BillSettingValidation } from './validation/useBillSettingValidation';

interface BillSettingFormProps {
  settings: BillSetting;
  handleChange(index: keyof BillSetting, value: any): void;
  validation: BillSettingValidation;
}

const BillSettingForm: React.FC<BillSettingFormProps> = ({ settings, handleChange, validation }) => {
  const inputs = {
    wallet: useRef<HTMLInputElement>(null),
    variation: useRef<HTMLInputElement>(null),
    cnab: useRef<HTMLInputElement>(null),
    bank_agency: useRef<HTMLInputElement>(null),
    bank_agency_dv: useRef<HTMLInputElement>(null),
    bank_account: useRef<HTMLInputElement>(null),
    bank_account_dv: useRef<HTMLInputElement>(null),
    beneficiary_document: useRef<HTMLInputElement>(null),
    beneficiary_name: useRef<HTMLInputElement>(null),
    beneficiary_postal_code: useRef<HTMLInputElement>(null),
    beneficiary_address: useRef<HTMLInputElement>(null),
    beneficiary_neighborhood: useRef<HTMLInputElement>(null),
    beneficiary_city: useRef<HTMLInputElement>(null),
    beneficiary_state: useRef<HTMLInputElement>(null),
    fine: useRef<HTMLInputElement>(null),
    fee: useRef<HTMLInputElement>(null),
    days_to_fee: useRef<HTMLInputElement>(null),
    bank_account_id: useRef<HTMLInputElement>(null),
    bank_customer_id: useRef<HTMLInputElement>(null),
    dispatch_id: useRef<HTMLInputElement>(null),
    payment_place: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    if (!inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <>
      <TextField
        select
        inputRef={inputs.cnab}
        error={!!validation.cnab}
        label="CNAB"
        placeholder="Informe a CNAB"
        helperText={validation.cnab}
        fullWidth
        margin="normal"
        value={settings.cnab}
        onChange={e => handleChange('cnab', e.target.value)}
        autoFocus
      >
        <MenuItem value="240">CNAB 240</MenuItem>
        <MenuItem value="400">CNAB 400</MenuItem>
      </TextField>

      <TextField
        inputRef={inputs.bank_customer_id}
        error={!!validation.bank_customer_id}
        label="Código do cliente"
        placeholder="Informe o código do cliente"
        helperText={validation.bank_customer_id ? validation.bank_customer_id : 'Código do cliente junto a banco'}
        fullWidth
        margin="normal"
        value={settings.bank_customer_id}
        onChange={e => handleChange('bank_customer_id', e.target.value)}
      />

      <TextField
        inputRef={inputs.bank_account_id}
        error={!!validation.bank_account_id}
        label="Número do convênio"
        placeholder="Número do convênio"
        helperText={validation.bank_account_id ? validation.bank_account_id : 'Número do convênio junto a banco'}
        fullWidth
        margin="normal"
        value={settings.bank_account_id}
        onChange={e => handleChange('bank_account_id', e.target.value)}
      />

      <TextField
        inputRef={inputs.wallet}
        error={!!validation.wallet}
        label="Carteira"
        placeholder="Informe a carteira"
        helperText={validation.wallet ? validation.wallet : 'Carteira para a emissão do boleto e remessa de cobrança'}
        fullWidth
        margin="normal"
        value={settings.wallet}
        onChange={e => handleChange('wallet', e.target.value)}
        autoFocus
        inputProps={{
          inputMode: 'numeric',
        }}
      />

      <TextField
        inputRef={inputs.variation}
        error={!!validation.variation}
        label="Variação"
        placeholder="Informe a variação"
        helperText={validation.variation ? validation.wallet : 'Variação para emissão da remessa'}
        fullWidth
        margin="normal"
        value={settings.variation}
        onChange={e => handleChange('variation', e.target.value)}
        autoFocus
        inputProps={{
          inputMode: 'numeric',
        }}
      />

      <TextField
        inputRef={inputs.bank_agency}
        error={!!validation.bank_agency}
        helperText={
          validation.bank_agency ? validation.bank_agency : 'Agência bancária para emissão do boleto, sem dígito'
        }
        label="Agência bancária"
        placeholder="Informe a agência bancária sem o dígito"
        fullWidth
        margin="normal"
        value={settings.bank_agency}
        onChange={e => handleChange('bank_agency', e.target.value)}
        inputProps={{
          inputMode: 'numeric',
        }}
      />

      <TextField
        inputRef={inputs.bank_agency_dv}
        error={!!validation.bank_agency_dv}
        label="Dígito agência bancária"
        placeholder="Informe o dígito da agência bancária"
        helperText={
          validation.bank_agency_dv ? validation.bank_agency_dv : 'Dígito da agência bancária para emissão para boleto'
        }
        fullWidth
        margin="normal"
        value={settings.bank_agency_dv}
        onChange={e => handleChange('bank_agency_dv', e.target.value)}
        inputProps={{
          inputMode: 'numeric',
        }}
      />

      <TextField
        inputRef={inputs.bank_account}
        error={!!validation.bank_account}
        label="Conta bancária"
        placeholder="Informe a conta bancária sem o dígito"
        helperText={
          validation.bank_account ? validation.bank_account : 'Conta bancária para emissão do boleto, sem dígito'
        }
        fullWidth
        margin="normal"
        value={settings.bank_account}
        onChange={e => handleChange('bank_account', e.target.value)}
        inputProps={{
          inputMode: 'numeric',
        }}
      />

      <TextField
        inputRef={inputs.bank_account_dv}
        error={!!validation.bank_account_dv}
        label="Dígito conta bancária"
        placeholder="Informe o dígito da conta bancária"
        helperText={
          validation.bank_account_dv ? validation.bank_account_dv : 'Dígito da conta bancária para emissão do boleto'
        }
        fullWidth
        margin="normal"
        value={settings.bank_account_dv}
        onChange={e => handleChange('bank_account_dv', e.target.value)}
        inputProps={{
          inputMode: 'numeric',
        }}
      />

      <TextField
        inputRef={inputs.beneficiary_name}
        error={!!validation.beneficiary_name}
        label="Nome do beneficiário"
        placeholder="Informe o nome do beneficiário"
        helperText={validation.beneficiary_name ? validation.beneficiary_name : 'Beneficiário que constará no boleto'}
        fullWidth
        margin="normal"
        value={settings.beneficiary_name}
        onChange={e => handleChange('beneficiary_name', e.target.value)}
      />

      <TextField
        inputRef={inputs.beneficiary_document}
        error={!!validation.beneficiary_document}
        label="Documento do beneficiário"
        placeholder="Informe o documento do beneficiário"
        helperText={
          validation.beneficiary_document ? validation.beneficiary_document : 'Documento que constará no boleto'
        }
        fullWidth
        margin="normal"
        value={settings.beneficiary_document}
        onChange={e => handleChange('beneficiary_document', e.target.value)}
      />

      <TextField
        inputRef={inputs.beneficiary_postal_code}
        error={!!validation.beneficiary_postal_code}
        label="CEP do beneficiário"
        placeholder="Informe o CEP"
        helperText={
          validation.beneficiary_postal_code ? validation.beneficiary_postal_code : 'CEP do endereço do beneficiário'
        }
        fullWidth
        margin="normal"
        value={settings.beneficiary_postal_code}
        onChange={e => handleChange('beneficiary_postal_code', e.target.value)}
        inputProps={{
          inputMode: 'numeric',
        }}
      />

      <TextField
        inputRef={inputs.beneficiary_address}
        error={!!validation.beneficiary_address}
        label="Endereço do beneficiário"
        placeholder="Informe a rua e o numero do endereço"
        helperText={
          validation.beneficiary_address ? validation.beneficiary_address : 'Rua e número do endereço do beneficiário'
        }
        fullWidth
        margin="normal"
        value={settings.beneficiary_address}
        onChange={e => handleChange('beneficiary_address', e.target.value)}
      />

      <TextField
        inputRef={inputs.beneficiary_neighborhood}
        error={!!validation.beneficiary_neighborhood}
        label="Bairro do beneficiário"
        placeholder="Informe o bairro"
        helperText={
          validation.beneficiary_neighborhood
            ? validation.beneficiary_neighborhood
            : 'Bairro do endereço do beneficiário'
        }
        fullWidth
        margin="normal"
        value={settings.beneficiary_neighborhood}
        onChange={e => handleChange('beneficiary_neighborhood', e.target.value)}
      />

      <TextField
        inputRef={inputs.beneficiary_city}
        error={!!validation.beneficiary_city}
        label="Cidade do beneficiário"
        placeholder="Informe o cidade"
        helperText={validation.beneficiary_city ? validation.beneficiary_city : 'Cidade do endereço do beneficiário'}
        fullWidth
        margin="normal"
        value={settings.beneficiary_city}
        onChange={e => handleChange('beneficiary_city', e.target.value)}
      />

      <TextField
        inputRef={inputs.beneficiary_state}
        error={!!validation.beneficiary_state}
        label="Estado do beneficiário"
        placeholder="Informe o estado"
        helperText={validation.beneficiary_state ? validation.beneficiary_state : 'Estado do endereço do beneficiário'}
        fullWidth
        margin="normal"
        value={settings.beneficiary_state}
        onChange={e => handleChange('beneficiary_state', e.target.value)}
      />

      <TextField
        inputRef={inputs.fine}
        error={!!validation.fine}
        label="Multa (%)"
        placeholder="Informe a multa por atraso"
        helperText={validation.fine ? validation.fine : 'Multa para atraso no pagamento do boleto'}
        fullWidth
        margin="normal"
        value={settings.fine}
        onChange={e => handleChange('fine', e.target.value)}
        inputProps={{
          inputMode: 'decimal',
          step: 0.01,
        }}
        type="number"
      />

      <TextField
        inputRef={inputs.fee}
        error={!!validation.fee}
        label="Juros (%)"
        placeholder="Informe o juros por atraso"
        helperText={validation.fee ? validation.fee : 'Juros para atraso no pagamento do boleto'}
        fullWidth
        margin="normal"
        value={settings.fee}
        onChange={e => handleChange('fee', e.target.value)}
        inputProps={{
          inputMode: 'decimal',
          step: 0.01,
        }}
        type="number"
      />

      <TextField
        inputRef={inputs.days_to_fee}
        error={!!validation.days_to_fee}
        label="Dias para cobrar juros"
        placeholder="Informe a quantidade de dias "
        helperText={
          validation.days_to_fee
            ? validation.days_to_fee
            : 'Dias para iniciar a cobrança de juros no atraso do pagamento do boleto'
        }
        fullWidth
        margin="normal"
        value={settings.days_to_fee}
        onChange={e => handleChange('days_to_fee', e.target.value)}
        inputProps={{
          inputMode: 'numeric',
          step: 1,
        }}
        type="number"
      />

      <TextField
        label="Demonstrativo"
        placeholder="Informe o texto demonstrativo"
        helperText="Texto para demonstrativo no boleto"
        fullWidth
        margin="normal"
        value={settings.demonstrative}
        onChange={e => handleChange('demonstrative', e.target.value)}
        multiline
        rows={3}
      />

      <TextField
        label="Instruções"
        placeholder="Informe o texto para instruções"
        helperText="Texto para instruções do boleto"
        fullWidth
        margin="normal"
        value={settings.instructions}
        onChange={e => handleChange('instructions', e.target.value)}
        multiline
        rows={3}
      />

      <TextField
        inputRef={inputs.payment_place}
        error={!!validation.payment_place}
        label="Local de pagamento"
        placeholder="Informe o local de pagamento"
        helperText="Local onde o boleto deve ser pago"
        fullWidth
        margin="normal"
        value={settings.payment_place}
        onChange={e => handleChange('payment_place', e.target.value)}
      />

      <TextField
        inputRef={inputs.dispatch_id}
        error={!!validation.dispatch_id}
        label="Sequência remessa"
        placeholder="Informe a sequência da remessa"
        helperText={validation.dispatch_id ? validation.dispatch_id : 'Sequência para envio de remessa de cobrança'}
        fullWidth
        margin="normal"
        value={settings.dispatch_id}
        onChange={e => handleChange('dispatch_id', e.target.value)}
        inputProps={{
          inputMode: 'numeric',
        }}
        type="number"
      />

      <button style={{ display: 'none' }} type="submit" />
    </>
  );
};

export default BillSettingForm;
