import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { BranchStockBalanceData } from 'types/reports/stockBalance';

import { makeStyles } from '@mui/styles';

type BranchItemModuleProps = {
  branch: BranchStockBalanceData;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
});

const BranchItemModule: React.FC<BranchItemModuleProps> = ({ branch }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem}>
      <Typography color="primary" variant="caption" gutterBottom>
        {branch.id_filial}
      </Typography>
      <Typography variant="body1">{branch.filial}</Typography>
      <div className="content">
        <Typography variant="body2" color="textSecondary">
          Estoque
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {branch.Estoque}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Reserva
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {branch.Reserva}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Custo total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {branch.formattedCustoTotal}
        </Typography>
      </div>
    </ListItem>
  );
};

export default BranchItemModule;
