import React from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Button, styled } from '@mui/material';
import { CourseStudentsQueryParams } from './CourseStudents';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '& .actions': {
    marginTop: 20,
    justifyContent: 'flex-end',
    display: 'flex',
  },
});

type CourseStudentsMobileFilterProps = {
  onExited(): void;
  queryParams: CourseStudentsQueryParams;
  handleQueryParamsChange(index: keyof CourseStudentsQueryParams, value: any): void;
};

const CourseStudentsMobileFilter: React.FC<CourseStudentsMobileFilterProps> = ({
  onExited,
  handleQueryParamsChange,
  queryParams,
}) => {
  return (
    <DialogInput onExited={onExited}>
      <Container>
        <KeyboardDatePicker
          label="Data inicial"
          value={queryParams.initial_date || null}
          onChange={date => handleQueryParamsChange('initial_date', date)}
          textFieldProps={{
            fullWidth: true,
            margin: 'normal',
          }}
        />

        <KeyboardDatePicker
          label="Data Final"
          value={queryParams.final_date || null}
          onChange={date => handleQueryParamsChange('final_date', date)}
          textFieldProps={{
            fullWidth: true,
            margin: 'normal',
          }}
        />

        <div className="actions">
          <DialogInputConsumer>
            {context => (
              <Button onClick={context.handleClose} color="primary" variant="contained">
                fechar
              </Button>
            )}
          </DialogInputConsumer>
        </div>
      </Container>
    </DialogInput>
  );
};

export default CourseStudentsMobileFilter;
