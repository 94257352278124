import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

const Container = styled('div')({
  margin: '0 auto',
  maxWidth: 1100,
  width: '100%',
});

export type RentalTabsOptions = 'main' | 'payments';

type RentalTabsProps = {
  handleChange(value: RentalTabsOptions): void;
  tab: RentalTabsOptions;
};

const RentalTabs: React.FC<RentalTabsProps> = ({ tab, handleChange }) => {
  return (
    <Container>
      <Tabs indicatorColor="primary" value={tab} onChange={(e, value) => handleChange(value)} variant="scrollable">
        <Tab label="Cliente e Produtos" value="main" />
        <Tab label="Pagamentos" value="payments" />
      </Tabs>
    </Container>
  );
};

export default RentalTabs;
