import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import { MdSearch } from 'react-icons/md';
import FilterBox from 'components/filter-box/FilterBox';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import { IconButton, InputAdornment, TextField } from '@mui/material';

interface MonthlyFilterBoxProps {
  displayMode: 'list' | 'module';
  setDisplayMode: Dispatch<SetStateAction<'list' | 'module'>>;
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
}

const MonthlyFilterBox: React.FC<MonthlyFilterBoxProps> = ({
  displayMode,
  setDisplayMode,
  searchText,
  handleSearchInputChange,
  handleSearchSubmit,
}) => {
  return (
    <FilterBox>
      <form onSubmit={handleSearchSubmit}>
        <TextField
          label="Pesquisar por produto"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={searchText}
          onChange={e => handleSearchInputChange(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <MdSearch color="#888" size={20} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default MonthlyFilterBox;
