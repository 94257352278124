import { useCallback } from 'react';
import { Waste, WasteOnList } from '../types/waste';
import { moneyFormat } from 'helpers/numberFormat';

export function useWasteFormatter() {
  const formatter = useCallback((waste: Waste): WasteOnList => {
    return {
      ...waste,
      formattedTotal: moneyFormat(waste.valor_total),
      produtos: waste.produtos
        ? waste.produtos.map(product => ({
            ...product,
            formattedValue: moneyFormat(product.valor_unitario),
            formattedTotal: moneyFormat(product.valor_unitario * product.qtd),
          }))
        : [],
    };
  }, []);

  return formatter;
}
