import React from 'react';
import { styled } from '@mui/material';
import PaginationProvider from 'hooks/pagination';
import { useDisplayMode } from 'hooks/useDisplayMode';
import TransferRequestsListTable from './table/TransferRequestsListTable';
import Pagination from 'components/pagination/Pagination';
import { transferRequestsTableTemplate } from './transferRequestsTableTemplate';
import TableContainer from 'components/table/TableContainer';
import { usePurchaseOrder } from '../../hooks/PurchaseOrderProvider';
import InsideLoading from 'components/loading/InsideLoading';
import NoData from 'components/no-data/NoData';

const Content = styled('div')({
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
});

const TransferRequests: React.FC = () => {
  const [displayMode] = useDisplayMode();
  const { transfers, loading } = usePurchaseOrder();

  return (
    <>
      {loading.transferRequests ? (
        <InsideLoading />
      ) : transfers.length === 0 ? (
        <NoData message="Conteúdo indisponível" />
      ) : (
        <PaginationProvider>
          <TableContainer tableTemplate={transferRequestsTableTemplate}>
            <Content>
              {displayMode === 'list' ? (
                <TransferRequestsListTable transfers={transfers} />
              ) : (
                <>
                  {/* <PurchaseOrderListTable orders={filtered} handleSort={handleSort} orderedIndex={orderedIndex} /> */}
                </>
              )}
              <Pagination count={transfers.length} />
            </Content>
          </TableContainer>
        </PaginationProvider>
      )}
    </>
  );
};

export default TransferRequests;
