import React, { MouseEvent, Dispatch, SetStateAction } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Compatibility } from 'types/compatibility';
import { useCompatibilities } from 'pages/compatibilities/hooks/useCompatibilities';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginRight: 20,
  },
});

type CompatibilityItemTableProps = {
  compatibility: Compatibility;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const CompatibilityItemTable: React.FC<CompatibilityItemTableProps> = ({ compatibility, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedCompatibility } = useCompatibilities();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    console.log(compatibility);
    setAnchorEl(event.currentTarget);
    setSelectedCompatibility(compatibility);
    event.stopPropagation();
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton onClick={handleMoreClick} key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography variant="body2">{compatibility[item.id]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default CompatibilityItemTable;
