import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Project } from 'types/project';

type ProjectsContextValue = {
  selectedProject: Project | null;
  setSelectedProject(project: Project | null): void;
  setShownPrintDialog: Dispatch<SetStateAction<boolean>>;
  shownPrintDialog: boolean;
};

const ProjectsContext = createContext<ProjectsContextValue>({} as ProjectsContextValue);
export const ProjectsProvider = ProjectsContext.Provider;

export function useProjects(): ProjectsContextValue {
  const context = useContext(ProjectsContext);
  return context;
}
