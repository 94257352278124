import { Typography, styled } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import React from 'react';
import { Justification } from 'types/justification';

interface JustificationDialogProps {
  onExited(): void;
  selectedJustification: Justification;
}

const Container = styled('table')({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #666',
  '& > h2, p, a': {
    border: '1px solid #666',
    padding: 5,
  },
});

const TableRow = styled('tr')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  '&.three': {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  '& > td': {
    padding: 5,
    border: '1px solid #666',
  },
});

const Justifications = styled('tr')({
  display: 'grid',
  gridTemplateColumns: '1fr 55px 70px',
});

const A = styled('a')({
  color: 'primary',
});

const JustificationDialog: React.FC<JustificationDialogProps> = ({ onExited, selectedJustification }) => {
  return (
    <Dialog title={`Justificativa de ${selectedJustification.funcionario}`} maxWidth="sm" onExited={onExited}>
      <Container>
        <Typography variant="h2" align="center" fontWeight={600} fontSize={16}>
          Justificativa de cartão ponto
        </Typography>

        <TableRow>
          <td>
            <Typography variant="subtitle2" fontSize={13}>
              Funcionário: <b>{selectedJustification.funcionario}</b>
            </Typography>
          </td>

          <td>
            <Typography variant="subtitle2" fontSize={13}>
              CPF: <b>{selectedJustification.cpf}</b>
            </Typography>
          </td>
        </TableRow>

        <TableRow className="three">
          <td>
            <Typography variant="subtitle2" fontSize={13}>
              Data: <b>{selectedJustification.formattedDate}</b>
            </Typography>
          </td>

          <td>
            <Typography variant="subtitle2" fontSize={13}>
              Horário: <b>{selectedJustification.formattedOcorrencia}</b>
            </Typography>
          </td>

          <td>
            <Typography variant="subtitle2" fontSize={13}>
              Filial: <b>{selectedJustification.filial}</b>
            </Typography>
          </td>
        </TableRow>

        <Typography variant="h2" align="center" fontWeight={600} fontSize={16}>
          Justificativas
        </Typography>

        <Justifications>
          <Typography variant="body2" fontWeight={600} fontSize={13}>
            Justificativa
          </Typography>

          <Typography variant="body2" fontWeight={600} fontSize={13}>
            Aceita
          </Typography>
          <Typography variant="body2" fontWeight={600} fontSize={13}>
            Lançada
          </Typography>

          <Typography variant="body2" fontSize={13}>
            {selectedJustification.justificativa}
          </Typography>

          <Typography variant="body2" fontSize={13}>
            {selectedJustification.aceita}
          </Typography>

          <Typography variant="body2" fontSize={13}>
            {selectedJustification.lancada}
          </Typography>
        </Justifications>

        {selectedJustification.link_arquivo && (
          <A href={selectedJustification.link_arquivo} target="_blank">
            Link da justificativa
          </A>
        )}
      </Container>
    </Dialog>
  );
};

export default JustificationDialog;
