import { TableTemplate } from 'types/tableTemplate';

export const segmentTableTemplate: TableTemplate[] = [
  {
    id: 'segmento',
    description: 'Segmento',
    originalId: 'segmento',
    width: 250,
  },
  {
    id: 'formattedCoust',
    description: 'Custo',
    originalId: 'custo',
    width: 80,
    dataType: 'number',
  },
  {
    id: 'qtd',
    description: 'QTD',
    originalId: 'qtd',
    width: 70,
    dataType: 'number',
  },
  {
    id: 'formattedMc',
    description: 'MC',
    originalId: 'mc',
    width: 70,
    dataType: 'number',
  },
  {
    id: 'formattedUnitary',
    description: 'Unitário',
    originalId: 'unitario',
    width: 85,
    dataType: 'number',
  },
  {
    id: 'formattedTotal',
    description: 'Total',
    originalId: 'total',
    width: 100,
    dataType: 'number',
  },
];
