import { parseISO } from 'date-fns';
import { ManagedSale } from '../types/managedSale';
import { RentalActions } from './types';
import { useReducer, Dispatch } from 'react';
import { moneyFormat } from 'helpers/numberFormat';

export const rentalInitialState: ManagedSale = {
  sequence: 1,
  end_at: null,
  customer_document_number: '',
  customer_name: '',
  active: true,
  created_at: new Date(),
  products: [],
  id: '',
  total: 0,
  commission_total: 0,
  type: 'managed_sale',
  financial_data: '',
  paid_at: null,
  start_at: null,
  external_id: '',
  payments: [],
};

export function rentalReducer(state: ManagedSale, action: RentalActions): ManagedSale {
  switch (action.type) {
    case 'SET_RENTAL': {
      return {
        ...action.rental,
        products: action.rental.products.map(product => ({
          ...product,
          formattedValue: moneyFormat(product.value),
          formattedCommissionValue: moneyFormat(product.commission_value),
        })),
        end_at: action.rental.end_at ? parseISO(action.rental.end_at as string) : null,
        start_at: action.rental.start_at ? parseISO(action.rental.start_at as string) : null,
        paid_at: action.rental.paid_at ? parseISO(action.rental.paid_at as string) : null,
      };
    }

    case 'CHANGE_RENTAL': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'ADD_RENTAL_PRODUCT': {
      const products = [
        ...state.products,
        {
          ...action.product,
          external_id: action.product.id,
          quantity: parseInt(action.product.quantity),
          value: parseFloat(action.product.value),
          commission_value: parseFloat(action.product.commission),
          formattedValue: moneyFormat(action.product.value),
          formattedCommissionValue: moneyFormat(action.product.commission),
        },
      ];

      const total = products.reduce((carry, product) => carry + product.quantity * product.value, 0);
      const commission_total = products.reduce((carry, product) => carry + product.commission_value, 0);

      return {
        ...state,
        products,
        total,
        commission_total,
      };
    }

    case 'REMOVE_RENTAL_PRODUCT': {
      const products = state.products.filter(product => product.external_id !== action.externalId);
      const total = products.reduce((carry, product) => carry + product.quantity * product.value, 0);
      const commission_total = products.reduce((carry, product) => carry + product.commission_value, 0);

      return {
        ...state,
        products,
        total,
        commission_total,
      };
    }

    default: {
      return state;
    }
  }
}

type UseRentalReducer = [ManagedSale, Dispatch<RentalActions>];

export function useRentalReducer(): UseRentalReducer {
  const [rental, dispatch] = useReducer(rentalReducer, rentalInitialState);

  return [rental, dispatch];
}
