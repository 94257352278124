import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography, styled } from '@mui/material';
import { useWastes } from 'pages/material-residues/waste/hooks/useWastes';
import { WasteOnList } from 'pages/material-residues/waste/types/waste';

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});
type WasteItemTableProps = {
  waste: WasteOnList;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const WasteItemTable: React.FC<WasteItemTableProps> = ({ waste, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const { setSelectedWaste } = useWastes();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    setSelectedWaste(waste);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <CustomIconButton onClick={handleMoreClick} key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : (
            <NumericData key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
              <Typography variant="body2">{waste[item.id as any]}</Typography>
            </NumericData>
          ),
        )}
    </>
  );
};

export default WasteItemTable;
