import React from 'react';
import { Typography, styled } from '@mui/material';
import { useExtracts } from './hooks/useExtracts';
import { moneyFormat } from 'helpers/numberFormat';

const Value = styled(Typography)({
  fontWeight: 600,
  textAlign: 'center',
  '&.negative': {
    color: '#f30000',
  },
  '&.positive': {
    color: 'green',
  },
});

const Box = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  padding: '10px 5px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1 / 3',
    '&.divided': {
      borderRight: '2px solid #ddd!important',
      gridColumn: '1 / 2',
    },
    '&.divided2': {
      gridColumn: '2 / 3',
    },
    '&.initialbalance': {
      borderRight: '2px solid #ddd!important',
      gridRow: '1 / 2',
      gridColumn: '1 / 2',
    },
    '&.finalbalance': {
      gridRow: '1 / 2',
      gridColumn: '2 / 3',
    },
  },
}));

const Totalizer = styled('div')<{ showTotals: boolean }>(({ theme, showTotals }) => ({
  backgroundColor: '#fff',
  gap: 5,
  display: 'grid',
  border: '2px solid #ddd',
  transition: 'all 0.2s ease',
  gridTemplateColumns: 'repeat(5, 1fr)',
  '& div:not(:last-child)': {
    borderRight: '2px solid #ddd',
  },
  [theme.breakpoints.down('sm')]: {
    height: showTotals ? 220 : 0,
    gap: 0,
    opacity: showTotals ? 1 : 0,
    '& div:not(:last-child)': {
      borderRight: 'none',
      borderBottom: '2px solid #ddd',
    },
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

const BankStatementTotals: React.FC = () => {
  const { showTotals, total } = useExtracts();

  return (
    <Totalizer showTotals={showTotals}>
      <Box className="initialbalance">
        <Typography align="center" color="#666">
          Saldo inicial (R$)
        </Typography>
        <Value align="center">{moneyFormat(total.saldoinicial)}</Value>
      </Box>

      <Box className="divided">
        <Typography align="center" color="#666">
          Débitos (R$)
        </Typography>
        <Value className={total.debito < 0 ? 'negative' : 'positive'}>{moneyFormat(total.debito)}</Value>
      </Box>

      <Box className="divided2">
        <Typography align="center" color="#666">
          Créditos (R$)
        </Typography>
        <Value className={total.credito < 0 ? 'negative' : 'positive'}>{moneyFormat(total.credito)}</Value>
      </Box>

      <Box className="finalbalance">
        <Typography align="center" color="#666">
          Saldo final (R$)
        </Typography>
        <Value className={total.saldofinal < 0 ? 'negative' : 'positive'}>{moneyFormat(total.saldofinal)}</Value>
      </Box>

      <Box>
        <Typography align="center" color="#666">
          Saldo atual (R$)
        </Typography>
        <Value className={total.saldocontaatual < 0 ? 'negative' : 'positive'}>
          {moneyFormat(total.saldocontaatual)}
        </Value>
      </Box>
    </Totalizer>
  );
};

export default BankStatementTotals;
