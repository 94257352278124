import { Rental } from 'pages/rentals/types/rental';
import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export type RentalValidation = {
  customer_name?: string;
  customer_document_number?: string;
};

type UseRentalValidation = [
  RentalValidation,
  Dispatch<SetStateAction<RentalValidation>>,
  (rental: Rental) => Promise<void>,
];

export function useRentalValidation(): UseRentalValidation {
  async function handleValidation(rental: Rental) {
    const schema = yup.object().shape({
      customer_document_number: yup.string().required('o documento do cliente é obrigatório'),
      customer_name: yup.string().required('o nome do cliente é obrigatório'),
    });

    try {
      await schema.validate(rental);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<RentalValidation>({});
  return [validation, setValidation, handleValidation];
}
