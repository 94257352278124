import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { IconButton, TextField, styled } from '@mui/material';
import { Product } from 'types/product';
import { useApp } from 'hooks/app';
import { WasteSelectProduct } from './WasteProducts';
import { useMessaging } from 'hooks/messaging';
import WasteProductsModal from '../../products/dialog/WasteProductsModal';
import ProductsAutoComplete from '../../products/auto-complete/ProductsAutoComplete';
import { WasteProductValidation } from '../../products/validation/wasteProductValidation';
import { Close, Done, Info } from '@mui/icons-material';

const Form = styled('form')(props => ({
  padding: '15px 0',
  display: 'grid',
  gridTemplateColumns: '110px 1fr 120px 120px 120px 75px',
  flex: 0,
  gap: 10,
  alignItems: 'start',
  justifyItems: 'center',
  marginBottom: 30,
  [props.theme.breakpoints.down('md')]: {
    gridTemplateColumns: '100px 1fr',
    gap: 5,
  },
}));

interface WasteProductFormProps {
  validation: WasteProductValidation;
  product: WasteSelectProduct;
  setValidation: Dispatch<SetStateAction<WasteProductValidation>>;
  onChange(index: keyof WasteSelectProduct, value: any): void;
  handleAdd(): void;
  handleOpenSerialNumber(): void;
  step: number;
}

const WasteProductForm: React.FC<WasteProductFormProps> = ({
  validation,
  onChange,
  product,
  setValidation,
  handleAdd,
  handleOpenSerialNumber,
  step,
}) => {
  const { h2iApi } = useApp();
  const { handleOpen } = useMessaging();
  const [openModal, setOpenModal] = useState(false);

  const inputs = {
    id: useRef<HTMLInputElement>(null),
    name: useRef<HTMLInputElement>(null),
    value: useRef<HTMLInputElement>(null),
    quantity: useRef<HTMLInputElement>(null),
    commission: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleSelectProduct(product: Product | null) {
    setOpenModal(false);
    onChange('product', product);
    onChange('value', product?.preco_maximo_reee || '');
    onChange('max_value', product?.preco_maximo_reee || '');
    onChange('id', product?.codigo_produto || '');
    onChange('name', product?.produto || '');
    inputs.quantity.current?.focus();
  }

  function handleSearchProduct() {
    if (!h2iApi) {
      return;
    }

    if (!product.id) {
      return;
    }

    h2iApi
      .get('/api/produtoTodos', { params: { id: product.id, reee: 'S' } })
      .then(response => {
        setValidation({});
        if (response.data[0].Mensagem) {
          handleOpen(response.data[0].Mensagem);
          handleSelectProduct(null);
          return;
        }
        const _product = response.data[0];
        onChange('product', _product);
        onChange('name', _product.produto);
        onChange('value', _product.preco_maximo_reee);
        onChange('max_value', _product.preco_maximo_reee);
        inputs.quantity.current?.focus();
      })
      .catch(err => console.error(err));
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'F2') {
      setOpenModal(true);
    }
  };

  return (
    <Form>
      {openModal && <WasteProductsModal handleSubmit={handleSelectProduct} onClose={() => setOpenModal(false)} />}
      {step === 1 ? (
        <>
          <TextField
            label="Código"
            placeholder="Código"
            autoFocus
            onKeyDown={handleKeyDown}
            value={product.id}
            onChange={event => onChange('id', event.target.value)}
            inputRef={inputs.id}
            error={!!validation.id}
            helperText={validation.id}
            onBlur={handleSearchProduct}
            fullWidth
            InputProps={{
              endAdornment: product.product ? (
                <IconButton size="small" onClick={() => handleSelectProduct(null)}>
                  <Close />
                </IconButton>
              ) : (
                <IconButton onClick={() => setOpenModal(true)}>
                  <Info fontSize="small" />
                </IconButton>
              ),
            }}
          />

          <ProductsAutoComplete
            disabled={false}
            handleAdd={handleAdd}
            label="Pesquisa de produtos"
            onSelect={product => handleSelectProduct(product)}
            placeholder="Digite o nome do produto"
            selected={product.product}
            helperText={validation.product}
            error={!!validation.product}
          />
        </>
      ) : (
        <>
          <TextField
            label="QTD"
            placeholder="QTD do produto"
            value={product.quantity}
            onChange={e => onChange('quantity', parseInt(e.target.value))}
            inputRef={inputs.quantity}
            error={!!validation.quantity}
            helperText={validation.quantity}
            type="number"
            fullWidth
            inputMode="numeric"
          />
          <TextField
            label="Valor R$"
            placeholder="Valor do produto"
            value={product.value}
            aria-valuemax={product.max_value}
            onChange={event => onChange('value', event.target.value)}
            inputRef={inputs.value}
            error={!!validation.value}
            helperText={validation.value}
            type="number"
            inputMode="decimal"
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleOpenSerialNumber}>
                  <Done />
                </IconButton>
              ),
            }}
          />
        </>
      )}
    </Form>
  );
};

export default WasteProductForm;
