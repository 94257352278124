import React, { ReactElement } from 'react';
import { IconButton, AppBar as NativeAppbar, Toolbar, Typography, styled } from '@mui/material';
import { useApp } from 'hooks/app';
import { SIDEBAR_WIDTH } from 'constants/constants';
import { ArrowBack, Menu } from '@mui/icons-material';

type AppbarProps = {
  title: string;
  ActionsComponent?: ReactElement;
  backAction?(): void;
  Tab?: ReactElement;
};

interface MakeStyleProps {
  ActionsComponent: boolean;
}

const AppBarTabsSpace = styled('div')(props => ({
  marginBottom: 45,
  [props.theme.breakpoints.down('md')]: {
    marginBottom: 48,
  },
}));

const CustomActions = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  gap: 7,
});

const StyledAppBar = styled(NativeAppbar)({
  boxShadow: 'none',
  zIndex: 1102,
  '@media print': {
    display: 'none',
  },
});

interface TabsAppbarProps {
  isopen: boolean;
}

const TabsAppBar = styled(NativeAppbar)<TabsAppbarProps>(({ theme, isopen }) => ({
  top: 50,
  [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
    top: 48,
  },
  [theme.breakpoints.up('lg')]: {
    backgroundColor: theme.palette.primary.light,
  },
  paddingLeft: isopen ? SIDEBAR_WIDTH : 0,
  transition: 'padding-left 400ms ease',
}));

const CustomToolbar = styled(Toolbar, { shouldForwardProp: prop => prop !== 'ActionsComponent' })<MakeStyleProps>(
  props => ({
    minHeight: '50px!important',
    display: props.ActionsComponent ? 'grid' : 'flex',
    justifyContent: props.ActionsComponent ? '' : 'space-between',
    gridTemplateColumns: '1fr 1fr',
    position: 'relative',
    [props.theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      display: 'flex',
    },
  }),
);

const Appbar: React.FC<AppbarProps> = ({ title, ActionsComponent, backAction, Tab }) => {
  const app = useApp();

  return (
    <>
      <StyledAppBar position="fixed">
        <CustomToolbar ActionsComponent={!!ActionsComponent}>
          <CustomActions>
            {backAction && app.isMobile && app.windowWidth < 960 ? (
              <IconButton onClick={backAction} color="inherit">
                <ArrowBack />
              </IconButton>
            ) : (
              <IconButton onClick={app.handleOpenMenu} color="inherit">
                <Menu />
              </IconButton>
            )}
            <div>
              <Typography color="inherit">{app.isMobile || app.windowWidth < 960 ? title : 'Grupo H2i'}</Typography>
            </div>
          </CustomActions>

          {ActionsComponent && <Actions>{ActionsComponent}</Actions>}
        </CustomToolbar>
      </StyledAppBar>

      {Tab && (
        <>
          <TabsAppBar isopen={app.isOpenedMenu && !app.isMobile && window.innerWidth > 960}>{Tab}</TabsAppBar>
          <AppBarTabsSpace />
        </>
      )}
    </>
  );
};

export default Appbar;
