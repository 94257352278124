import React from 'react';
import { Close } from '@mui/icons-material';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { NewInvoiceFavored } from '../../reducer/types';

interface PersonAutocompleteAdornmentProps {
  selected: NewInvoiceFavored | null;
  loading: boolean;
  clearSelected(): void;
}

const PersonAutocompleteAdornment: React.FC<PersonAutocompleteAdornmentProps> = ({
  selected,
  loading,
  clearSelected,
}) => {
  return (
    <InputAdornment position="end">
      {loading ? (
        <CircularProgress color="primary" size={24} />
      ) : (
        selected && (
          <IconButton onClick={clearSelected}>
            <Close />
          </IconButton>
        )
      )}
    </InputAdornment>
  );
};

export default PersonAutocompleteAdornment;
