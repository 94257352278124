import { Button, styled } from '@mui/material';
import SelectBranch from 'components/select-branches/SelectBranch';
import React from 'react';
import { useWaste } from '../../hooks/useWaste';

interface WasteSelectBranchProps {
  handleValidation(): void;
  disableButton: boolean;
}

const Content = styled('div')({
  gap: 15,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
});

const CustomButton = styled(Button)({
  alignSelf: 'center',
  justifySelf: 'end',
  maxWidth: 200,
});

const WasteSelectBranch: React.FC<WasteSelectBranchProps> = ({ disableButton, handleValidation }) => {
  const { handleChange, waste } = useWaste();
  return (
    <Content>
      <SelectBranch
        label="Filial"
        selectStyle={{ minWidth: 300 }}
        viewAllBranches={false}
        branch_id={waste.id_filial}
        handleChange={branchId => handleChange('id_filial', branchId)}
      />

      <CustomButton disabled={disableButton} variant="contained" onClick={handleValidation}>
        Avançar
      </CustomButton>
    </Content>
  );
};

export default WasteSelectBranch;
