import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useJustifications } from '../hooks/useJustifications';
import { useApp } from 'hooks/app';
import { Justification } from 'types/justification';

type JustificationListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  justification: Justification;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const JustificationListMenu: React.FC<JustificationListMenuProps> = ({ anchorEl, setAnchorEl, justification }) => {
  const { handleSearch, setLoading } = useJustifications();
  const { financialApi } = useApp();

  function handleAnalysisJustification() {
    if (!justification) return;

    setLoading(true);
    financialApi
      .post('/updaterhocorrencia', {
        ...justification,
        em_analise: 'S',
        data_ocorrencia: justification.formattedDate,
      })
      .then(() => handleSearch())
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  function handleDenyJustification() {
    if (!justification) return;

    setLoading(true);
    financialApi
      .post('/updaterhocorrencia', {
        ...justification,
        aceita: 'N',
        data_ocorrencia: justification.formattedDate,
      })
      .then(() => handleSearch())
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  function handleAcceptJustification() {
    if (!justification) return;

    setLoading(true);
    financialApi
      .post('/updaterhocorrencia', {
        ...justification,
        aceita: 'S',
        data_ocorrencia: justification.formattedDate,
      })
      .then(() => handleSearch())
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  function handleLaunchJustification() {
    if (!justification) return;

    setLoading(true);
    financialApi
      .post('/updaterhocorrencia', {
        ...justification,
        lancada: 'S',
        data_ocorrencia: justification.formattedDate,
      })
      .then(() => handleSearch())
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleAnalysisJustification}>Justificativa em análise</MenuItem>
      <MenuItem onClick={handleAcceptJustification}>Justificativa aceita</MenuItem>
      <MenuItem onClick={handleDenyJustification}>Justificativa negada</MenuItem>
      <MenuItem onClick={handleLaunchJustification}>Justificativa lançada</MenuItem>
    </Menu>
  );
};

export default JustificationListMenu;
