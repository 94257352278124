import { Button } from '@mui/material';
import React, { useState } from 'react';
import ModalNewDocument from './ModalNewDocument';
import OtherDocumentList from './OtherDocumentList';
import { useReseller } from '../../hooks/useReseller';
import NoData from 'components/no-data/NoData';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  newDocumentContainer: {
    display: 'flex',
  },
  actions: {
    marginBottom: 15,
    paddingBottom: 15,
    borderBottom: '1px solid #eee',
  },
});

const OtherDocuments: React.FC = () => {
  const classes = styles();
  const [modalNewDocument, setModalNewDocument] = useState(false);
  const { reseller } = useReseller();

  return (
    <div className={classes.container}>
      {modalNewDocument && <ModalNewDocument onExited={() => setModalNewDocument(false)} />}
      <div className={classes.actions}>
        <Button size="small" variant="contained" color="primary" onClick={() => setModalNewDocument(true)}>
          Adicionar
        </Button>
      </div>
      {reseller && reseller?.documents.length > 0 ? (
        <OtherDocumentList />
      ) : (
        <NoData message="Nenhum documento para mostrar" />
      )}
    </div>
  );
};

export default OtherDocuments;
