import React, { useEffect, useState } from 'react';
import { Button, Theme } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useSearch from 'hooks/search';
import useTableOrder from 'hooks/tableOrder';
import { getCancelTokenSource } from 'services/api';
import CustomersFilterBox from './AgentsFilterBox';
import { agentsTableTemplate } from './agentsTableTemplate';
import AgentListModule from './list/module/AgentListModule';
import AgentListTable from './list/table/AgentListTable';
import AgentsActions from './AgentsActions';
import { Agent } from 'types/agent';
import { AgentsProvider } from './hooks/useAgents';
import AgentsApprove from './approval/AgentsApproval';
import AccountAgentNew from './registration/new/AccountAgentNew';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const Agents: React.FC = () => {
  const classes = useStyles();

  const [agents, setAgents] = useState<Agent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<null | Agent>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Agent[]>([]);
  const [indexToSearch, setIndexToSearch] = useState('nome');
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const [dialogApprove, setDialogApprove] = useState(false);
  const [dialogNew, setDialogNew] = useState(false);

  const search = useSearch();
  const app = useApp();

  useEffect(() => {
    const f = search(searchValue, indexToSearch, agents);
    setFiltered(f);
  }, [agents, searchValue, search, indexToSearch]);

  useEffect(() => {
    if (!app.h2iApi) return;

    const source = getCancelTokenSource();
    let request = true;

    app.h2iApi
      .get<Agent[]>('/api/getprepostotodos', { cancelToken: source.token })
      .then(response => {
        const _agents = response.data;
        if (request)
          setAgents(
            _agents.map(agent => {
              agent.formattedDocumentSent = agent.documento_enviado === 'S' ? 'Sim' : 'Não';
              agent.formattedLetterSent = agent.carta_enviada === 'S' ? 'Sim' : 'Não';
              return agent;
            }),
          );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (request) setLoading(false);
      });

    return () => {
      if (request) request = false;
      source.cancel();
    };
  }, [app.h2iApi]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleRemoveFromList(agentId: number) {
    setAgents(agents => agents.filter(agent => agent.id !== agentId));
  }

  return (
    <AgentsProvider value={{ selectedAgent, setSelectedAgent, setDialogApprove, handleRemoveFromList }}>
      {dialogApprove && selectedAgent && (
        <AgentsApprove selectedAgent={selectedAgent} onExited={() => setDialogApprove(false)} />
      )}

      {dialogNew && <AccountAgentNew onExited={() => setDialogNew(false)} />}

      <Appbar title="Site" ActionsComponent={<AgentsActions />} />

      <PageHeaderActions
        title="Prepostos"
        description="Aprovação dos prepostos"
        ActionComponent={
          <>
            <Button variant="contained" color="primary" size="small" onClick={() => setDialogNew(true)}>
              Adicionar
            </Button>
          </>
        }
      />

      <TableContainer tableTemplate={agentsTableTemplate}>
        <CustomersFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          indexToSearch={indexToSearch}
          setIndexToSearch={setIndexToSearch}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum preposto para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <AgentListTable agents={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <AgentListModule agents={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </AgentsProvider>
  );
};

export default Agents;
