import React, { useState } from 'react';
import List from 'components/list/List';
import { Project } from 'types/project';
import ProjectItemModule from './ProjectItemModule';
import ProjectListMenu from '../ProjectListMenu';

type ProjectListModuleProps = {
  projects: Project[];
};

const ProjectListModule: React.FC<ProjectListModuleProps> = ({ projects }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <ProjectListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <List>
        {projects.map(project => (
          <ProjectItemModule key={project.id} project={project} setAnchorEl={setAnchorEl} />
        ))}
      </List>
    </>
  );
};

export default ProjectListModule;
