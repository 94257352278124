import React, { ReactNode } from 'react';
import { Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  branch: {
    gridColumn: '1 / 3',
    backgroundColor: '#fff',
    border: '1px solid #eee',
    display: 'grid',
    borderRadius: 16,
  },
  typo: {
    cursor: 'pointer',
    borderRadius: '16px 16px 0 0',
    backgroundColor: theme.palette.primary.light,
    borderBottom: '1px solid #666',
    padding: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#fff',
  },
  div: {
    gap: 15,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: 15,
      height: 1,
      backgroundColor: '#fff',
    },
  },
  row: {
    marginLeft: -15,
    transition: 'all 0.7s ease',
    transform: 'rotate(90deg)',
    '&.open': {
      transform: 'rotate(0deg)',
    },
  },
}));

interface BoxChartProps {
  open: boolean;
  title: string;
  handleClick(): void;
  subtitle?: string;
  children: ReactNode;
}

const BoxReport: React.FC<BoxChartProps> = ({ children, title, subtitle, open, handleClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.branch}>
      <div className={classes.typo} onClick={handleClick}>
        <div className={classes.div}>
          <span className={classes.icon}>
            <div></div>
            <div className={`${classes.row} ${open ? 'open' : ''}`}></div>
          </span>
          <Typography style={{ fontWeight: 'bold' }} color="inherit" variant="body2">
            {title}
          </Typography>
          {subtitle && (
            <Typography style={{ marginLeft: '5px', marginRight: '5px' }} color="inherit">
              |
            </Typography>
          )}
          <Typography color="inherit" variant="body2">
            {subtitle}
          </Typography>
        </div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default BoxReport;
