import React from 'react';
import { TextField, Typography, styled } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { Page } from 'types/page';

const Container = styled('div')(props => ({
  marginTop: 20,
  display: 'grid',
  gridTemplateColumns: '0.5fr 1.5fr',
  gap: '20px',
  [props.theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '0.7fr 1.3fr',
  },
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

type PageFormProps = {
  page: Page;
  handleChange(index: keyof Page, value: string): void;
};

const PageForm: React.FC<PageFormProps> = ({ page, handleChange }) => {
  return (
    <Container>
      <div>
        <TextField
          label="Page Id"
          placeholder="Informe o page id"
          fullWidth
          value={page.page_id || ''}
          onChange={e => handleChange('page_id', e.target.value)}
        />
        <TextField
          label="Título"
          placeholder="Informe o título da página"
          fullWidth
          value={page.title || ''}
          onChange={e => handleChange('title', e.target.value)}
          autoFocus
          margin="normal"
        />
        <TextField
          label="Descrição"
          placeholder="Informe o descrição da página"
          margin="normal"
          fullWidth
          value={page.description || ''}
          onChange={e => handleChange('description', e.target.value)}
          multiline
          rows={5}
        />
        <TextField
          label="URL"
          placeholder="Informe a URL da página"
          margin="normal"
          fullWidth
          value={page.url || ''}
          onChange={e => handleChange('url', e.target.value)}
        />
        <TextField
          label="Keywords"
          placeholder="Informe as keywords da página"
          margin="normal"
          fullWidth
          value={page.keywords || ''}
          onChange={e => handleChange('keywords', e.target.value)}
        />
      </div>
      <div>
        <Typography variant="body2" gutterBottom>
          Conteúdo do página
        </Typography>
        <Editor
          apiKey="7k6dn17wr0mhnufiwixs10z9kn86prqmhsmrzo2dvt8idhi8"
          initialValue={page.html || ''}
          init={{
            height: 700,
            menubar: true,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar: [
              'undo redo formatselect bold italic backcolor alignleft aligncenter alignright alignjustify bullist numlist outdent indent removeformat help code',
            ],
            language: 'pt_BR',
          }}
          onEditorChange={content => handleChange('html', content)}
        />
      </div>
    </Container>
  );
};

export default PageForm;
