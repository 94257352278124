import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export interface FilterOption {
  id: string;
  nome: string;
}

export interface FilterSidebar {
  id: string;
  titulo: string;
  parametro: string;
  opcoes: FilterOption[];
}

export interface SidebarFiltersContextValue {
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  filters: FilterSidebar[];
}

const Context = createContext<SidebarFiltersContextValue>({} as SidebarFiltersContextValue);
export const SidebarFiltersProvider = Context.Provider;

export function useSidebarFilters(): SidebarFiltersContextValue {
  return useContext(Context);
}
