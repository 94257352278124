import React, { useEffect, useRef, Dispatch, SetStateAction } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import { Button, IconButton, InputAdornment, MenuItem, TextField, Theme } from '@mui/material';
import { Clear, Search } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { RentalQueryParams } from './ManagedSales';
import { DisplayModeOptions } from 'hooks/useDisplayMode';

const useStyles = makeStyles<Theme>(theme => ({
  filter: {
    display: 'flex',
    flex: 1,
    justifyItems: 'start',
    alignItems: 'center',
    gap: 10,
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
    '& > .first-column': {
      display: 'flex',
      gap: 10,
      [theme.breakpoints.down('sm')]: {
        flex: 1,
      },
    },
    '& > .second-column': {
      display: 'grid',
      gridTemplateColumns: '150px 150px 150px 160px 160px',
      columnGap: 10,
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}));

type ManagedSalesFilterBoxProps = {
  queryParams: RentalQueryParams;
  handleQueryParamsChange(index: keyof RentalQueryParams, value: any): void;
  displayMode: DisplayModeOptions;
  setDisplayMode: Dispatch<SetStateAction<DisplayModeOptions>>;
  handleSubmit(): void;
  loading: boolean;
};

const ManagedSalesFilterBox: React.FC<ManagedSalesFilterBoxProps> = ({
  queryParams,
  handleQueryParamsChange,
  setDisplayMode,
  displayMode,
  handleSubmit,
  loading,
}) => {
  const classes = useStyles();
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, []);

  return (
    <FilterBox>
      <div className={classes.filter}>
        <div className="first-column">
          <TextField
            label="Pesquisa"
            placeholder="Nome do cliente"
            value={queryParams.term}
            onChange={e => handleQueryParamsChange('term', e.target.value)}
            autoFocus
            fullWidth
            InputProps={{
              endAdornment: queryParams.term.length ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => handleQueryParamsChange('term', '')}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ) : undefined,
            }}
          />
        </div>

        <div className="second-column">
          <TextField
            label="Financeiro"
            placeholder="Lançamento financeiro"
            value={queryParams.financial_data}
            onChange={e => handleQueryParamsChange('financial_data', e.target.value)}
            autoFocus
            fullWidth
            InputProps={{
              endAdornment: queryParams.financial_data.length ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => handleQueryParamsChange('financial_data', '')}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ) : undefined,
            }}
          />

          <TextField
            select
            value={queryParams.active}
            onChange={event => handleQueryParamsChange('active', event.target.value)}
            label="Status"
          >
            <MenuItem value="1">ativo</MenuItem>
            <MenuItem value="0">inativo</MenuItem>
          </TextField>
          <TextField
            select
            value={queryParams.is_paid}
            onChange={event => handleQueryParamsChange('is_paid', event.target.value)}
            label="Pago"
          >
            <MenuItem value="2">todos</MenuItem>
            <MenuItem value="1">sim</MenuItem>
            <MenuItem value="0">não</MenuItem>
          </TextField>
          <KeyboardDatePicker
            label="Data inicial"
            value={queryParams.initial_date || null}
            onChange={date => handleQueryParamsChange('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data Final"
            value={queryParams.final_date || null}
            onChange={date => handleQueryParamsChange('final_date', date)}
          />
        </div>
        <Button
          disabled={loading}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Search />}
        >
          Buscar
        </Button>
      </div>

      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default ManagedSalesFilterBox;
