import React, { useState } from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import ResellerItemModule from './ResellerItemModule';
import { Reseller } from 'types/reseller';
import ResellersListMenu from '../ResellersListMenu';

type ResellerListModuleProps = {
  resellers: Reseller[];
};

const ResellerListModule: React.FC<ResellerListModuleProps> = ({ resellers }) => {
  const { rowsPerPage, page } = usePagination();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <Grid container>
      <ResellersListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <Grid item xs={12}>
        <List>
          {resellers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(reseller => (
            <ResellerItemModule setAnchorEl={setAnchorEl} key={reseller.id} reseller={reseller} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ResellerListModule;
