import { IconButton, InputAdornment, MenuItem, TextField, styled } from '@mui/material';
import { Search } from '@mui/icons-material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { useTable } from 'components/table/hook/useTable';
import React, { FormEvent, useEffect, useRef } from 'react';
import { useResellers } from './hooks/useResellers';
import { ResellersFilter } from './Resellers';

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 1.2fr 1fr',
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
  '& > .search-params': {
    display: 'grid',
    alignItems: 'baseline',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    '& button': {
      padding: 0,
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

type ResellersFilterBoxProps = {
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
  handleSearch(filter: ResellersFilter, e?: FormEvent<HTMLFormElement>): void;
};

const ResellersFilterBox: React.FC<ResellersFilterBoxProps> = ({ setDisplayMode, displayMode, handleSearch }) => {
  const { tableTemplate } = useTable();
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const { handleChangeFilter, filter } = useResellers();

  useEffect(() => {
    inputSearchRef.current?.focus();
    inputSearchRef.current?.setSelectionRange(0, inputSearchRef.current.value.length);
  }, [filter.indexToSearch]);

  return (
    <FilterBox>
      <Filter onSubmit={e => handleSearch(filter, e)}>
        <TextField
          value={filter.indexToSearch}
          onChange={e => handleChangeFilter('indexToSearch', e.target.value)}
          select
          label="Pesquisar em"
        >
          {tableTemplate
            .filter(col => !col.notSearchable)
            .map(item => (
              <MenuItem key={item.id} value={item.originalId}>
                {item.description}
              </MenuItem>
            ))}
        </TextField>

        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate}
            onChange={date => handleChangeFilter('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.finalDate}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </div>
        <TextField
          inputRef={inputSearchRef}
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={filter.searchValue}
          onChange={e => handleChangeFilter('searchValue', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Filter>
      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default ResellersFilterBox;
