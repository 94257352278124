import React from 'react';

import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { AbcCustomer, AbcItemCustomer } from 'types/abcCustomer';
import { useAbcCustomers } from '../../../hooks/useAbcCustomers';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
});

type ItemCustomerItemTableProps = {
  product: AbcItemCustomer;
};

const ItemCustomerItemTable: React.FC<ItemCustomerItemTableProps> = ({ product }) => {
  const classes = styles();
  const { tableTemplate } = useTable();
  const { handleReprintSale } = useAbcCustomers();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <IconButton key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div
              onClick={() => handleReprintSale(product.id_filial, product.numero_venda)}
              key={item.id}
              className={item.dataType === 'number' ? 'numericData' : ''}
            >
              <Typography style={{ fontSize: 11 }} variant="body2">
                {product[item.id as keyof AbcCustomer]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default ItemCustomerItemTable;
