import React, { useEffect, useState, useCallback } from 'react';
import Dialog from 'components/dialogs/Dialog';
import { MenuItem, TextField, Typography } from '@mui/material';
import {
  BranchStockBalanceData,
  ResponsibleStockBalanceData,
  SegmentStockBalanceData,
  StockBalanceData,
  SubgroupStockBalanceData,
  UnityStockBalanceData,
} from 'types/reports/stockBalance';
import { useApp } from 'hooks/app';
import { StockFilter } from 'types/reports/stockFilter';
import { download } from 'helpers/download';
import { uuidv4 } from 'helpers/uuidv4';
import { api } from 'services/api';
import ExcelButtonDownload from 'components/excel-button-download/ExcelButtonDownload';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 30,
    gap: 30,
  },
  typography: {
    marginTop: 50,
  },
});

interface StockBalanceExportProps {
  onExited(): void;
  filter: StockFilter;
}

type Segment = 'item' | 'subgroup' | 'responsible' | 'branch' | 'stoppedStock' | 'segment' | 'unity';

const StockBalanceExport: React.FC<StockBalanceExportProps> = ({ onExited, filter }) => {
  const classes = styles();
  const { h2iApi } = useApp();
  const [tab, setTab] = useState<Segment>('item');
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<StockBalanceData[]>([]);
  const [branches, setBranches] = useState<BranchStockBalanceData[]>([]);
  const [stoppedStocks, setStoppedStocks] = useState<BranchStockBalanceData[]>([]);
  const [segments, setSegments] = useState<SegmentStockBalanceData[]>([]);
  const [units, setUnits] = useState<UnityStockBalanceData[]>([]);
  const [subgroups, setSubgroups] = useState<SubgroupStockBalanceData[]>([]);
  const [responsibles, setResponsibles] = useState<ResponsibleStockBalanceData[]>([]);

  const fetchExports = useCallback(
    async (tab: string) => {
      if (!h2iApi) {
        return;
      }

      setLoading(true);

      switch (tab) {
        case 'item': {
          h2iApi
            .get(`/api/saldoestoque`, {
              params: {
                id_filial: filter.branchId.join(',') || '',
                id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
                filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
                id_categoria: filter.categoryId.join(',') || '',
                id_subcategoria: filter.subcategoryId.join(',') || '',
                id_segmento: filter.segmentId.join(',') || '',
                id_unidade: filter.unityId.join(',') || '',
                id_fabricante: filter.manufacturerId.join(',') || '',
                id_subgrupo: filter.subgroupId.join(',') || '',
                responsavel: filter.made_by,
              },
            })
            .then(response => {
              if (response.data[0].result) {
                setItems([]);
                return;
              }
              setItems(response.data);
            })
            .finally(() => setLoading(false));
          break;
        }

        case 'subgroup': {
          await h2iApi
            .get(`/api/relestsubgrupo`, {
              params: {
                id_filial: filter.branchId.join(',') || '',
                id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
                filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
                id_categoria: filter.categoryId.join(',') || '',
                id_subcategoria: filter.subcategoryId.join(',') || '',
                id_segmento: filter.segmentId.join(',') || '',
                id_unidade: filter.unityId.join(',') || '',
                id_fabricante: filter.manufacturerId.join(','),
                id_subgrupo: filter.subgroupId.join(','),
                responsavel: filter.made_by,
              },
            })
            .then(response => {
              if (response.data.estoque.result) {
                setSubgroups([]);
                return;
              }

              setSubgroups(response.data.estoque);
            })
            .finally(() => setLoading(false));

          break;
        }

        case 'responsible': {
          await h2iApi
            .get(`/api/relestresponsavel`, {
              params: {
                id_filial: filter.branchId.join(',') || '',
                id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
                filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
                id_categoria: filter.categoryId.join(',') || '',
                id_subcategoria: filter.subcategoryId.join(',') || '',
                id_segmento: filter.segmentId.join(',') || '',
                id_unidade: filter.unityId.join(',') || '',
                id_fabricante: filter.manufacturerId.join(',') || '',
                id_subgrupo: filter.subgroupId.join(',') || '',
              },
            })
            .then(response => {
              if (response.data.estoque.result) {
                setResponsibles([]);
                return;
              }

              setResponsibles(response.data.estoque);
            })
            .finally(() => setLoading(false));

          break;
        }

        case 'branch': {
          await h2iApi
            .get(`/api/relestfilial`, {
              params: {
                id_filial: filter.branchId.join(',') || '',
                id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
                filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
                id_categoria: filter.categoryId.join(',') || '',
                id_subcategoria: filter.subcategoryId.join(',') || '',
                id_segmento: filter.segmentId.join(',') || '',
                id_unidade: filter.unityId.join(',') || '',
                id_fabricante: filter.manufacturerId.join(',') || '',
                id_subgrupo: filter.subgroupId.join(',') || '',
                responsavel: filter.made_by,
              },
            })
            .then(response => {
              if (response.data.filiais.result) {
                setBranches([]);
                return;
              }

              setBranches(response.data.filiais);
            })
            .finally(() => setLoading(false));

          break;
        }

        case 'stoppedStock': {
          await h2iApi
            .get(`/api/relestfilial`, {
              params: {
                id_filial: filter.branchId.join(',') || '',
                id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
                filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
                id_categoria: filter.categoryId.join(',') || '',
                id_subcategoria: filter.subcategoryId.join(',') || '',
                id_segmento: filter.segmentId.join(',') || '',
                id_unidade: filter.unityId.join(',') || '',
                sem_movimentacao: 'S',
                id_fabricante: filter.manufacturerId.join(',') || '',
                id_subgrupo: filter.subgroupId.join(',') || '',
                responsavel: filter.made_by,
              },
            })
            .then(response => {
              if (response.data.filiais.result) {
                setStoppedStocks([]);
                return;
              }

              setStoppedStocks(response.data.filiais);
            })
            .finally(() => setLoading(false));

          break;
        }

        case 'segment': {
          await h2iApi
            .get(`/api/relestsegmento`, {
              params: {
                id_filial: filter.branchId.join(',') || '',
                id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
                filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
                id_categoria: filter.categoryId.join(',') || '',
                id_subcategoria: filter.subcategoryId.join(',') || '',
                id_segmento: filter.segmentId.join(',') || '',
                id_unidade: filter.unityId.join(',') || '',
                id_fabricante: filter.manufacturerId.join(',') || '',
                id_subgrupo: filter.subgroupId.join(',') || '',
                responsavel: filter.made_by,
              },
            })
            .then(response => {
              if (response.data.result) {
                setSegments([]);
                return;
              }

              setSegments(response.data.segmento);
            })
            .finally(() => setLoading(false));

          break;
        }

        case 'unity': {
          await h2iApi
            .get(`/api/relestunidade`, {
              params: {
                id_filial: filter.branchId.join(',') || '',
                id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
                filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
                id_categoria: filter.categoryId.join(',') || '',
                id_subcategoria: filter.subcategoryId.join(',') || '',
                id_segmento: filter.segmentId.join(',') || '',
                id_unidade: filter.unityId.join(',') || '',
                id_fabricante: filter.manufacturerId.join(',') || '',
                id_subgrupo: filter.subgroupId.join(',') || '',
                responsavel: filter.made_by,
              },
            })
            .then(response => {
              if (response.data.result) {
                setUnits([]);
                return;
              }

              setUnits(response.data.segmento);
            })
            .finally(() => setLoading(false));

          break;
        }
      }
    },
    [h2iApi, filter],
  );

  useEffect(() => {
    fetchExports(tab);
  }, [fetchExports, tab]);

  async function handleDownload() {
    const mapping = {
      item: {
        route: '/excel-exports',
        data: items,
      },
      stoppedStock: {
        route: '/excel-exports',
        data: stoppedStocks,
      },
      branch: {
        route: '/excel-exports',
        data: branches,
      },
      subgroup: {
        route: '/excel-exports',
        data: subgroups,
      },
      segment: {
        route: '/excel-exports',
        data: segments,
      },
      unity: {
        route: '/excel-exports',
        data: units,
      },
      responsible: {
        route: '/excel-exports',
        data: responsibles,
      },
    };

    const resource = mapping[tab];

    try {
      const response = await api.post(resource.route, resource.data, { responseType: 'blob' });

      download(response.data, `${tab}-${uuidv4()}`);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Dialog onExited={onExited} title="Export para Excel" maxWidth="sm" height="60vh">
      <div className={classes.container}>
        <TextField select fullWidth value={tab} onChange={e => setTab(e.target.value as any)}>
          <MenuItem value="item">Item</MenuItem>
          <MenuItem value="stoppedStock">Sem movimentação</MenuItem>
          <MenuItem value="responsible">Responsável</MenuItem>
          <MenuItem value="branch">Filial</MenuItem>
          <MenuItem value="segment">Segmento</MenuItem>
          <MenuItem value="unity">Unidade</MenuItem>
          <MenuItem value="subgroup">Subgrupo</MenuItem>
        </TextField>

        {loading ? (
          <Typography className={classes.typography}>Carregando...</Typography>
        ) : (
          <ExcelButtonDownload handleDownload={handleDownload} />
        )}
      </div>
    </Dialog>
  );
};

export default StockBalanceExport;
