import React, { useEffect, useRef, useState } from 'react';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { EmailTemplate } from 'types/emailTemplate';
import { EmailTemplateValidation } from './validation/emailTemplateValidation';
import { Editor } from '@tinymce/tinymce-react';
import ImageSelector from 'components/image-selector/ImageSelector';
import { Search } from '@mui/icons-material';
import ImagePreview from 'components/image-preview/ImagePreview';

import { makeStyles } from '@mui/styles';

type EmailTemplateFormProps = {
  emailTemplate: EmailTemplate;
  handleChange(index: keyof EmailTemplate, value: any): void;
  validation: EmailTemplateValidation;
};

const useStyles = makeStyles({
  radioContainer: {
    marginTop: 20,
  },
  richTextContainer: {
    margin: '20px 0',
  },
  btnShowImage: {
    textTransform: 'none',
    padding: 0,
  },
});

const EmailTemplateForm: React.FC<EmailTemplateFormProps> = ({ emailTemplate, handleChange, validation }) => {
  const classes = useStyles();
  const [imageSelector, setImageSelector] = useState(false);
  const [imageSelector2, setImageSelector2] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<null | string>('');

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    subject: useRef<HTMLInputElement>(null),
    from: useRef<HTMLInputElement>(null),
    image1: useRef<HTMLInputElement>(null),
    image2: useRef<HTMLInputElement>(null),
    link1: useRef<HTMLInputElement>(null),
    link2: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      {!!selectedImageSrc && (
        <ImagePreview
          src={selectedImageSrc}
          description="Imagem da mensagem de e-mail"
          onExited={() => setSelectedImageSrc('')}
        />
      )}
      {imageSelector && (
        <ImageSelector
          handleSetImageId={image => handleChange('image1', image.imageUrl)}
          onExited={() => setImageSelector(false)}
        />
      )}
      {imageSelector2 && (
        <ImageSelector
          handleSetImageId={image => handleChange('image2', image.imageUrl)}
          onExited={() => setImageSelector2(false)}
        />
      )}
      <Grid item xs={12}>
        <TextField
          inputRef={inputs.name}
          error={!!validation.name}
          helperText={validation.name}
          label="Nome"
          placeholder="Digite o nome da mensagem"
          margin="normal"
          fullWidth
          value={emailTemplate.name || ''}
          onChange={e => handleChange('name', e.target.value)}
          autoFocus
        />
        <TextField
          inputRef={inputs.from}
          error={!!validation.from}
          helperText={validation.from}
          label="Remetente (e-mail)"
          placeholder="Digite o endereço de e-mail remetente"
          margin="normal"
          fullWidth
          value={emailTemplate.from || ''}
          onChange={e => handleChange('from', e.target.value)}
        />
        <TextField
          inputRef={inputs.subject}
          error={!!validation.subject}
          helperText={validation.subject}
          label="Assunto"
          placeholder="Digite o assunto da mensagem"
          margin="normal"
          fullWidth
          value={emailTemplate.subject || ''}
          onChange={e => handleChange('subject', e.target.value)}
        />
        <TextField
          multiline
          rows={5}
          label="Descrição"
          placeholder="Digite uma descrição para essa mensagem"
          margin="normal"
          fullWidth
          value={emailTemplate.description || ''}
          onChange={e => handleChange('description', e.target.value)}
        />
        <TextField
          inputRef={inputs.image1}
          label="Banner 1"
          placeholder="Escolha o banner 1"
          helperText="Banner 1 do corpo do e-mail, largura máxima 600px"
          margin="normal"
          fullWidth
          value={emailTemplate.image1 || ''}
          onChange={e => handleChange('image1', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setImageSelector(true)} size="small">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          inputRef={inputs.link1}
          error={!!validation.link1}
          helperText={validation.link1}
          label="Link banner 1"
          placeholder="Informe o link do banner 1"
          margin="normal"
          fullWidth
          value={emailTemplate.link1 || ''}
          onChange={e => handleChange('link1', e.target.value)}
        />
        <TextField
          inputRef={inputs.image2}
          label="Banner 2"
          placeholder="Escolha o banner 2"
          helperText="Banner 2 do corpo do e-mail, largura máxima 600px"
          margin="normal"
          fullWidth
          onChange={e => handleChange('image2', e.target.value)}
          value={emailTemplate.image2 || ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setImageSelector2(true)} size="small">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          inputRef={inputs.link2}
          label="Link banner 2"
          placeholder="Informe o link do banner 2"
          error={!!validation.link2}
          helperText={validation.link2}
          margin="normal"
          fullWidth
          value={emailTemplate.link2 || ''}
          onChange={e => handleChange('link2', e.target.value)}
        />
      </Grid>
      <div className={classes.richTextContainer}>
        <Editor
          apiKey="7k6dn17wr0mhnufiwixs10z9kn86prqmhsmrzo2dvt8idhi8"
          initialValue={emailTemplate.body || ''}
          init={{
            height: 700,
            menubar: true,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar: [
              'undo redo formatselect bold italic backcolor alignleft aligncenter alignright alignjustify bullist numlist outdent indent removeformat help code',
            ],
            language: 'pt_BR',
          }}
          onEditorChange={content => handleChange('body', content)}
        />
      </div>
      <button type="submit" style={{ display: 'none' }} />
    </>
  );
};

export default EmailTemplateForm;
