import React, { FormEvent } from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Button, styled } from '@mui/material';
import { Search } from '@mui/icons-material';
import { ResellersFilter } from './Resellers';
import { useResellers } from './hooks/useResellers';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '& .actions': {
    marginTop: 20,
    justifyContent: 'flex-end',
    display: 'flex',
    gap: 10,
  },
});

type ResellersMobileFilterProps = {
  onExited(): void;
  handleSearch(filter: ResellersFilter, e?: FormEvent<HTMLFormElement>): void;
};

const ResellersMobileFilter: React.FC<ResellersMobileFilterProps> = ({ onExited, handleSearch }) => {
  const { handleChangeFilter, filter } = useResellers();
  function handleSearchClick(closeModal: () => void) {
    handleSearch(filter);
    closeModal();
  }

  return (
    <DialogInput onExited={onExited}>
      <Container>
        <KeyboardDatePicker
          label="Data inicial"
          value={filter.initialDate || null}
          onChange={date => handleChangeFilter('initialDate', date)}
          textFieldProps={{
            fullWidth: true,
            margin: 'normal',
          }}
        />

        <KeyboardDatePicker
          label="Data Final"
          value={filter.finalDate || null}
          onChange={date => handleChangeFilter('finalDate', date)}
          textFieldProps={{
            fullWidth: true,
            margin: 'normal',
          }}
        />

        <div className="actions">
          <DialogInputConsumer>
            {context => (
              <>
                <Button variant="text" onClick={context.handleClose} color="primary">
                  fechar
                </Button>

                <Button
                  startIcon={<Search />}
                  onClick={() => handleSearchClick(context.handleClose)}
                  color="primary"
                  variant="contained"
                >
                  buscar
                </Button>
              </>
            )}
          </DialogInputConsumer>
        </div>
      </Container>
    </DialogInput>
  );
};

export default ResellersMobileFilter;
