import React from 'react';
import { OrderIndexData } from 'hooks/tableOrder';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import ManufacturerItemTable from './ManufacturerItemTable';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography, Grid } from '@mui/material';
import { AbcProductManufacturer } from 'types/abcProduct';
import { manufacturerTableTemplate } from '../../manufacturerTableTemplate';
import ManufacturerMoreMenu from '../ManufacturerMoreMenu';
import { useAbcManufacturers } from '../../../hooks/useAbcManufacturers';
import TableHeader from 'components/new-table/TableHeader';
import TableRow from 'components/new-table/TableRow';

type ManufacturerListTableProps = {
  manufacturers: AbcProductManufacturer[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  isSubgroup: boolean;
};

const ManufacturerListTable: React.FC<ManufacturerListTableProps> = ({
  manufacturers,
  handleSort,
  orderedIndex,
  isSubgroup,
}) => {
  const { moreManufacturer } = useAbcManufacturers();
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={manufacturerTableTemplate}>
          <TableContent>
            <TableHeader className={`${isSubgroup ? '' : 'headerSubgroup'}`}>
              {manufacturerTableTemplate.map(item => (
                <div
                  className={item.dataType === 'number' ? `numericData` : ''}
                  key={item.id}
                  onClick={() => handleSort(item.originalId)}
                >
                  <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </div>
              ))}
            </TableHeader>
            <TableBody>
              <div>
                {manufacturers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((manufacturer, index) => (
                    <>
                      <TableRow key={index}>
                        <ManufacturerItemTable isSubgroup={isSubgroup} manufacturer={manufacturer} />
                      </TableRow>
                      {moreManufacturer?.fabricante === manufacturer.fabricante && (
                        <ManufacturerMoreMenu manufacturer={manufacturer} />
                      )}
                    </>
                  ))}
              </div>
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ManufacturerListTable;
