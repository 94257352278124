import React, { MouseEvent } from 'react';
import { AbcProduct } from 'types/abcProduct';
import { useAbcProducts } from '../../hooks/useAbcProducts';
import { Typography, ListItem } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type AbcProductItemModuleProps = {
  product: AbcProduct;
};

const AbcProductItemModule: React.FC<AbcProductItemModuleProps> = ({ product }) => {
  const classes = useStyles();
  const { setSelectedProduct } = useAbcProducts();

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setSelectedProduct(product);
  }

  return (
    <>
      <ListItem button className={classes.listItem} onClick={() => handleClick}>
        <Typography variant="caption" color="primary">
          Código - {product.cod}
        </Typography>
        <Typography gutterBottom>Quantidade - {product.qtd}</Typography>
        <Typography gutterBottom>Unitário - {product.formattedUnitary}</Typography>
        <Typography gutterBottom>Valor total - {product.formattedTotal}</Typography>
        <Typography gutterBottom>SPIFF - {product.formattedSpiff}</Typography>
        <Typography gutterBottom>MC - {product.formattedMc}</Typography>
        <Typography gutterBottom>Custo - {product.formattedCoust}</Typography>
      </ListItem>
    </>
  );
};

export default AbcProductItemModule;
