import React, { HTMLAttributes, useState } from 'react';
import { Typography, Theme } from '@mui/material';
import { uuidv4 } from 'helpers/uuidv4';
import { Document } from 'types/document';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    textAlign: 'right',
  },
  link: {
    cursor: 'pointer',
  },
  linkDelete: {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
}));

interface UploadLinkProps extends HTMLAttributes<HTMLDivElement> {
  document: Document | null;
  handleSetDocument(document: Document | null): void;
}

const UploadLink: React.FC<UploadLinkProps> = ({ document, handleSetDocument, className, ...rest }) => {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const uuid = uuidv4();
  const messaging = useMessaging();

  function handleUploadFiles(files: FileList | null) {
    if (!files) return;

    const form = new FormData();
    Array.from(files).forEach((file, index) => {
      form.append(`files[${index}]`, file);
    });

    setSaving(true);

    api
      .post<Document[]>('/documents', form)
      .then(response => {
        const _document = response.data[0];
        handleSetDocument({
          ..._document,
          formattedSize: `${Math.ceil(_document.size / 1024)} KB`,
        });
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível carregar a imagem');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <div className={className ? `${classes.container} ${className}` : classes.container} {...rest}>
      {!document ? (
        <>
          {saving ? (
            <Typography variant="body2" color="textSecondary" align="right">
              carregando
            </Typography>
          ) : (
            <>
              <input
                type="file"
                name="photo"
                id={uuid}
                onChange={event => handleUploadFiles(event.target.files)}
                style={{ display: 'none' }}
                accept=".png, .jpg, .pdf, .jpeg"
              />
              <label htmlFor={uuid} className={classes.link}>
                <Typography variant="body2" color="primary">
                  upload
                </Typography>
              </label>
            </>
          )}
        </>
      ) : (
        <Typography variant="body2" className={classes.linkDelete} onClick={() => handleSetDocument(null)}>
          excluir
        </Typography>
      )}
    </div>
  );
};

export default UploadLink;
