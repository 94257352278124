import React, {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  FormEvent,
} from 'react';
import { AbcProduct, AbcProductBranch, AbcProductSegment, AbcProductSubgroup, AbcProductUnity } from 'types/abcProduct';
import { useApp } from 'hooks/app';
import { Sale } from '../modal/branch/SelectedBranchListMenu';
import { AbcProductFilterParams } from '../AbcProduct';
import { AbcProductData } from '../reducer/types';
import { useAbcProductReducer } from '../reducer/reducer';
import { useFetchAbcProducts } from './useFetchAbcProducts';
import {
  setBranches,
  setCategories,
  setCustomers,
  setManufactures,
  setProducts,
  setSegments,
  setSellers,
  setSubGroups,
  setUnits,
} from '../reducer/actions';

export type AbcProductTotalProps = {
  total: number;
  coust: number;
  mc: number;
  spiff: number;
  unitary: number;
  quantity: number;
};

type AbcProductsContextValue = {
  selectedSale: Sale | null;
  setSelectedSale: Dispatch<SetStateAction<Sale | null>>;
  selectedUnity: AbcProductUnity | null;
  setSelectedUnity(abcUnity: AbcProductUnity | null): void;
  selectedSegment: AbcProductSegment | null;
  setSelectedSegment(abcSegment: AbcProductSegment | null): void;
  selectedSubgroup: AbcProductSubgroup | null;
  setSelectedSubgroup(abcSubgroup: AbcProductSubgroup | null): void;
  selectedBranch: AbcProductBranch | null;
  setSelectedBranch(abcBranch: AbcProductBranch | null): void;
  selectedProduct: AbcProduct | null;
  setSelectedProduct(abcProduct: AbcProduct | null): void;
  displayMode: 'list' | 'module';
  setDisplayMode: Dispatch<SetStateAction<'list' | 'module'>>;
  searchText: string;
  handleSearch(searchValue: string): void;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(): void;
  loading: boolean;
  data: AbcProductData;
};

const AbcProductsContext = createContext<AbcProductsContextValue>({} as AbcProductsContextValue);

export function useAbcProducts(): AbcProductsContextValue {
  const context = useContext(AbcProductsContext);
  return context;
}

interface ProductProviderProps {
  filter: AbcProductFilterParams;
  children: React.ReactNode;
}

const ProductProvider: React.FC<ProductProviderProps> = ({ children, filter }) => {
  const { windowWidth, isMobile, isOpenedMenu, handleOpenMenu } = useApp();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(isMobile || windowWidth < 930 ? 'module' : 'list');
  const [data, dispatch] = useAbcProductReducer();
  const [searchText, setSearchText] = useState('');

  const [selectedSale, setSelectedSale] = useState<Sale | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<AbcProduct | null>(null);
  const [selectedUnity, setSelectedUnity] = useState<AbcProductUnity | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<AbcProductBranch | null>(null);
  const [selectedSegment, setSelectedSegment] = useState<AbcProductSegment | null>(null);
  const [selectedSubgroup, setSelectedSubgroup] = useState<AbcProductSubgroup | null>(null);

  const [fetch, loading] = useFetchAbcProducts({ filter, searchText });

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  const handleSearch = useCallback(async () => {
    const response = await fetch();

    if (!response) {
      return;
    }

    dispatch(setProducts(response.data.produtos, response.dataToCompare.produtos, filter.dates as any));
    dispatch(setCategories(response.data.categoria, response.dataToCompare.categoria, filter.dates as any));
    dispatch(setCustomers(response.data.cliente, response.dataToCompare.cliente, filter.dates as any));
    dispatch(setManufactures(response.data.fabricante, response.dataToCompare.fabricante, filter.dates as any));
    dispatch(setBranches(response.data.filial, response.dataToCompare.filial, filter.dates as any));
    dispatch(setSegments(response.data.segmento, response.dataToCompare.segmento, filter.dates as any));
    dispatch(setSubGroups(response.data.subgrupo, response.dataToCompare.subgrupo, filter.dates as any));
    dispatch(setUnits(response.data.unidade, response.dataToCompare.unidade, filter.dates as any));
    dispatch(setSellers(response.data.pedido_por, response.dataToCompare.pedido_por, filter.dates as any));
  }, [fetch, dispatch, filter]);

  function handleSearchSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    if (isOpenedMenu) {
      handleOpenMenu();
    }

    handleSearch();
  }

  function handleSearchInputChange(value: string) {
    setSearchText(value);
  }

  return (
    <AbcProductsContext.Provider
      value={{
        selectedUnity,
        selectedSegment,
        selectedSubgroup,
        selectedBranch,
        selectedProduct,
        displayMode,
        loading,
        searchText,
        selectedSale,
        data,
        setSelectedUnity,
        setSelectedSegment,
        setSelectedSubgroup,
        setSelectedSale,
        setSelectedBranch,
        setSelectedProduct,
        setDisplayMode,
        handleSearch,
        handleSearchInputChange,
        handleSearchSubmit,
      }}
    >
      {children}
    </AbcProductsContext.Provider>
  );
};

export default ProductProvider;
