import { Add, FilterList, Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';
import React from 'react';
import { useInvoices } from './hooks/useInvoices';

const Container = styled('div')(props => ({
  display: 'none',
  [props.theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

const InvoicesAppbarActions: React.FC = () => {
  const { setIsOpenFilterMore, setShowTotals, showTotals, type, setIsNewInvoiceOpen } = useInvoices();
  return (
    <Container>
      <IconButton onClick={() => setIsNewInvoiceOpen(true)} color="inherit">
        <Add />
      </IconButton>

      {type === 'TT' && (
        <IconButton onClick={() => setShowTotals(state => !state)} color="inherit">
          {showTotals ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      )}

      <IconButton onClick={() => setIsOpenFilterMore(true)} color="inherit">
        <FilterList />
      </IconButton>
    </Container>
  );
};

export default InvoicesAppbarActions;
