import React from 'react';
import { Typography, ListItem } from '@mui/material';
import { Email } from 'types/email';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

type ExportEmailItemModuleProps = {
  email: Email;
};

const ExportEmailItemModule: React.FC<ExportEmailItemModuleProps> = ({ email }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem}>
      <Typography gutterBottom>{email.nome}</Typography>
      <Typography variant="body2" color="textSecondary">
        {email.email}
      </Typography>
    </ListItem>
  );
};

export default ExportEmailItemModule;
