import React, { Dispatch, SetStateAction } from 'react';
import { useApp } from 'hooks/app';
import { IconButton, Tooltip } from '@mui/material';
import { Add, CalendarToday } from '@mui/icons-material';

type JustificationActionsProps = {
  loading: boolean;
  handleClick(): void;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const JustificationActions: React.FC<JustificationActionsProps> = ({ loading, setAnchorEl, handleClick }) => {
  const { windowWidth } = useApp();

  return (
    <>
      {windowWidth < 600 && (
        <>
          <IconButton disabled={loading} color="inherit" onClick={e => setAnchorEl(e.currentTarget)}>
            <CalendarToday />
          </IconButton>
          <IconButton color="inherit" onClick={handleClick}>
            <Tooltip title="Novo">
              <Add />
            </Tooltip>
          </IconButton>
        </>
      )}
    </>
  );
};

export default JustificationActions;
