import React from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import { Typography, Button, styled } from '@mui/material';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-around',
  height: 200,
});

const Actions = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-around',
});

interface UserDeleteConfirmProps {
  onExited(): void;
  handleDelete(): void;
}

const UserDeleteConfirm: React.FC<UserDeleteConfirmProps> = ({ onExited, handleDelete }) => {
  function handleClickYes(handleClose: () => void) {
    handleDelete();
    handleClose();
  }

  return (
    <DialogInput hideBackdrop={false} onExited={onExited} maxWidth="sm">
      <DialogInputConsumer>
        {context => (
          <Container>
            <Typography align="center">Deseja realmente excluir esse usuário?</Typography>
            <Actions>
              <Button color="primary" variant="text" onClick={context.handleClose}>
                Não
              </Button>
              <Button onClick={() => handleClickYes(context.handleClose)} variant="contained" color="primary">
                Sim, excluir
              </Button>
            </Actions>
          </Container>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default UserDeleteConfirm;
