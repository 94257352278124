import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { KeyboardArrowDown, MoreHoriz } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { OrderAuthorization } from 'types/orderAuthorization';
import { IconButton, Typography } from '@mui/material';
import { useOrdersAuthorization } from '../../hooks/useOrdersAuthorization';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
});

type OrderItemTableProps = {
  order: OrderAuthorization;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const OrderItemTable: React.FC<OrderItemTableProps> = ({ order, setAnchorEl }) => {
  const classes = useStyles();
  const { tableTemplate } = useTable();
  const { setSelectedOrder, setMore, more } = useOrdersAuthorization();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedOrder(order);
    setAnchorEl(e.currentTarget);
  }

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedOrder(order);
    setMore(more ? null : order);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'more' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClickMore}>
              <KeyboardArrowDown style={more?.id === order.id ? { transform: 'rotate(180deg)' } : undefined} />
            </IconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography variant="body2">{order[item.id]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default OrderItemTable;
