import React from 'react';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { Sale } from '../../pages/reports/commercial/abc/product/modal/branch/SelectedBranchListMenu';
import { Typography } from '@mui/material';
import { DoneRounded } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

interface SelectedSaleMobileProps {
  selectedSale: Sale;
}

const useStyles = makeStyles({
  container: {
    paddingTop: 15,
    display: 'flex',
    flexDirection: 'column',
    '& > p': {
      fontSize: 14,
      color: '#666',
    },
    '& > div > p': {
      fontSize: 14,
      color: '#666',
    },
    '& > div > div > p': {
      fontSize: 14,
      color: '#666',
    },
  },
  date: {
    margin: '20px 0',
    display: 'flex',
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    backgroundColor: '#eee',
  },
  items: {
    gap: 5,
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr 40px 90px)',
  },
  bold: {
    fontWeight: 'bold',
  },
  size: {
    fontSize: 16,
    marginTop: 10,
  },
  cep: {
    paddingLeft: 5,
    borderLeft: '3px solid #eee',
  },
  box: {
    marginTop: 15,
    borderTop: '2px solid #eee',
    paddingTop: 5,
  },
  due: {
    marginTop: 15,
    display: 'grid',
    gridTemplateColumns: '1fr 120px',
  },
  itemNumeric: {
    textAlign: 'end',
  },
  b: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  data: {
    marginTop: 15,
    borderTop: '2px solid #eee',
    paddingTop: 5,
    display: 'flex',
    flexDirection: 'column',
  },
});

const SelectedSaleMobile: React.FC<SelectedSaleMobileProps> = ({ selectedSale }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography>Venda nº {selectedSale.numero_venda}</Typography>
      <Typography>Nota nº {selectedSale.nota_fiscal}</Typography>

      <div className={classes.date}>
        <DoneRounded color="primary" />
        <Typography>Venda realizada em {selectedSale.data}</Typography>
      </div>

      <div className={classes.items}>
        <Typography className={classes.bold}>Itens</Typography>
        <Typography className={classes.bold}>QTD</Typography>
        <Typography className={`${classes.bold} ${classes.itemNumeric}`}>Preço</Typography>
        {selectedSale.itens.map((item, index) => (
          <>
            <Typography variant="caption" key={index}>
              • {item.produto}
            </Typography>
            <Typography variant="caption" key={index}>
              {item.qtd}
            </Typography>
            <Typography variant="subtitle2" className={classes.itemNumeric} key={item.id_produto}>
              {numberFormat(item.total)}
            </Typography>
          </>
        ))}
        <Typography className={`${classes.bold} ${classes.size}`}>Total</Typography>
        <Typography className={`${classes.bold} ${classes.size}`}></Typography>
        <Typography className={`${classes.bold} ${classes.size} ${classes.itemNumeric}`}>
          {moneyFormat(selectedSale.total)}
        </Typography>
      </div>

      <div className={classes.box}>
        <Typography variant="caption">Endereço</Typography>
        <Typography>
          {selectedSale.endereco.toUpperCase()}, {selectedSale.cidade}, {selectedSale.uf}.
        </Typography>
        <Typography className={classes.cep}>{selectedSale.cep}</Typography>
      </div>

      <div className={classes.box}>
        {selectedSale.pagamentos.map(payment => (
          <>
            <Typography variant="caption">Forma de pagamento</Typography>
            <Typography variant="body2">{payment.forma}</Typography>
            {payment.forma === 'BOLETO' ? (
              <div className={classes.due}>
                <Typography variant="body2">Vencimento</Typography>
                <Typography variant="body2" className={classes.itemNumeric}>
                  Valor
                </Typography>
                {payment.vencimentos &&
                  payment.vencimentos.map(due => (
                    <>
                      <Typography variant="body2">{due.vencimento}</Typography>
                      <Typography variant="body2" className={classes.itemNumeric}>
                        {moneyFormat(due.valor)}
                      </Typography>
                    </>
                  ))}
              </div>
            ) : (
              <div className={classes.due}>
                <Typography variant="body2">Parcelas</Typography>
                <Typography variant="body2" className={classes.itemNumeric}>
                  Valor
                </Typography>
                <Typography variant="body2">{payment.parcelas}</Typography>
                <Typography variant="body2" className={classes.itemNumeric}>
                  {moneyFormat(payment.valor)}
                </Typography>
              </div>
            )}
          </>
        ))}
      </div>

      <div className={classes.data}>
        <Typography className={classes.b} variant="caption">
          Cliente: <b>{selectedSale.cliente}</b>
        </Typography>
        <Typography className={classes.b} variant="caption">
          CNPJ: <b>{selectedSale.cnpj_cliente}</b>
        </Typography>
        <Typography className={classes.b} variant="caption">
          Pedido por: <b>{selectedSale.pedido_por}</b>
        </Typography>
        <Typography className={classes.b} variant="caption">
          Vendedor: <b>{selectedSale.vendedor}</b>
        </Typography>
        <Typography className={classes.b} variant="caption">
          Status: <b>{selectedSale.status}</b>
        </Typography>
      </div>
    </div>
  );
};

export default SelectedSaleMobile;
