import React, { useEffect, useRef } from 'react';
import { RegisterForm as RegisterFormType } from 'types/registerForm';
import { RegisterCompanyValidation } from './validation/registerCompanyValidation';
import { Address } from 'types/address';
import { TextField } from '@mui/material';
import CnpjInput from 'components/masked-input/CnpjInput';
import PhoneInput from 'components/masked-input/PhoneInput';

interface RegisterFormProps {
  register: RegisterFormType;
  handleChange(index: keyof RegisterFormType, value: any): void;
  validation: RegisterCompanyValidation;
  handleSetAddress(address: Address): void;
  handleAddressChange(index: keyof Address, value: any): void;
  disabledDocumentInput?: boolean;
}

const RegisterForm: React.FC<RegisterFormProps> = ({
  register,
  handleChange,
  validation,
  disabledDocumentInput = false,
}) => {
  const inputs = {
    document: useRef<HTMLInputElement>(null),
    ie: useRef<HTMLInputElement>(null),
    company_name: useRef<HTMLInputElement>(null),
    company_trade_name: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null),
    postal_code: useRef<HTMLInputElement>(null),
    street: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    inputs.document.current?.focus();
  }, [inputs.document]);

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <>
      <TextField
        inputRef={inputs.document}
        placeholder="informe seu CNPJ"
        label="CNPJ"
        value={register.document}
        onChange={e => handleChange('document', e.target.value)}
        error={!!validation.document}
        helperText={validation.document}
        inputMode="numeric"
        InputProps={{
          inputComponent: CnpjInput as any,
        }}
        disabled={disabledDocumentInput}
        margin="normal"
      />
      <TextField
        inputRef={inputs.ie}
        label="inscrição estadual"
        placeholder="informe a inscrição estadual"
        value={register.ie}
        onChange={e => handleChange('ie', e.target.value)}
        error={!!validation.ie}
        helperText={validation.ie ? validation.ie : 'Se não possuir, informar "isento"'}
        inputMode="numeric"
        margin="normal"
      />
      <TextField
        inputRef={inputs.company_name}
        label="razão social"
        placeholder="informe a razão social"
        value={register.company_name}
        onChange={e => handleChange('company_name', e.target.value)}
        error={!!validation.company_name}
        helperText={validation.company_name}
        autoCapitalize="words"
        margin="normal"
      />
      <TextField
        inputRef={inputs.company_trade_name}
        label="fantasia"
        placeholder="informe o nome fantasia"
        value={register.company_trade_name}
        onChange={e => handleChange('company_trade_name', e.target.value)}
        error={!!validation.company_trade_name}
        helperText={validation.company_trade_name}
        autoCapitalize="words"
        margin="normal"
      />
      <TextField
        inputRef={inputs.email}
        label="e-mail"
        placeholder="e-mail de contato"
        value={register.email}
        onChange={e => handleChange('email', e.target.value)}
        error={!!validation.email}
        helperText={validation.email}
        type="email"
        autoComplete="email"
        margin="normal"
      />
      <TextField
        inputRef={inputs.phone}
        label="telefone fixo"
        placeholder="telefone de contato"
        value={register.phone}
        onChange={e => handleChange('phone', e.target.value)}
        error={!!validation.phone}
        helperText={validation.phone}
        type="phone"
        autoComplete="phone"
        InputProps={{
          inputComponent: PhoneInput as any,
        }}
        inputMode="numeric"
        margin="normal"
      />
    </>
  );
};

export default RegisterForm;
