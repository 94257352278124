import React from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import { Extract } from 'types/extract';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { useExtracts } from 'pages/financial/bank-statement/hooks/useExtracts';

type GroupedBankStatementItemTableProps = {
  account: Extract;
};

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

const GroupedBankStatementItemTable: React.FC<GroupedBankStatementItemTableProps> = ({ account }) => {
  const { setInvoice, setExtract } = useExtracts();
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => item.id !== 'data')
        .map(item =>
          item.id === 'more' ? (
            <CustomIconButton key={item.id}>
              <KeyboardArrowDown />
            </CustomIconButton>
          ) : (
            <NumericData
              onClick={() =>
                account.tipo_movimentacao !== 'S'
                  ? setInvoice({ id: account.id_movimentacao, tipo: account.tipo_movimentacao })
                  : setExtract(account)
              }
              key={item.id}
              className={item.dataType === 'number' ? 'numericData' : undefined}
            >
              <Typography
                variant="body2"
                fontSize={12}
                fontWeight={item.originalId === 'formattedDate' || item.originalId === 'valor' ? 600 : 400}
                color={item.originalId === 'valor' ? (account[item.originalId] < 0 ? '#f30000' : 'green') : '#000'}
              >
                {account[item.id]}
              </Typography>
            </NumericData>
          ),
        )}
    </>
  );
};

export default GroupedBankStatementItemTable;
