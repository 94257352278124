import React, { useState } from 'react';
import { IconButton, Tooltip, Menu, MenuItem, styled } from '@mui/material';
import { Loupe, CloudUpload, Done, More, Send } from '@mui/icons-material';
import { useApp } from 'hooks/app';
import { Image } from 'types/image';

const Upload = styled('label')({
  display: 'inline-flex',
  padding: 12,
  verticalAlign: 'middle',
  cursor: 'pointer',
  borderRadius: '50%',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
});

type AiArtSelectorActionsProps = {
  selectedImage: Image | null;
  openImagePreview(): void;
  handleConfirmModifyImage(): void;
  handleConfirmImage(): void;
  handleUploadFiles(image: FileList | null): void;
  showConfirmAction: boolean;
};

const AiArtSelectorActions: React.FC<AiArtSelectorActionsProps> = ({
  selectedImage,
  openImagePreview,
  handleConfirmModifyImage,
  handleConfirmImage,
  handleUploadFiles,
  showConfirmAction,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const app = useApp();

  return selectedImage ? (
    <>
      {showConfirmAction && (
        <Tooltip title="Confirmar">
          <IconButton onClick={handleConfirmImage} color="inherit">
            <Done />
          </IconButton>
        </Tooltip>
      )}
      {!app.isMobile && (
        <>
          <Tooltip title="Ver imagem">
            <IconButton onClick={openImagePreview} color="inherit">
              <Loupe />
            </IconButton>
          </Tooltip>
          <Tooltip title="Criar variação de imagem">
            <IconButton onClick={handleConfirmModifyImage} color="inherit">
              <Send />
            </IconButton>
          </Tooltip>
        </>
      )}
      {app.isMobile && (
        <>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            <MenuItem onClick={openImagePreview}>Ver foto</MenuItem>
            <MenuItem onClick={handleConfirmModifyImage}>Criar variação de imagem</MenuItem>
          </Menu>
          <IconButton onClick={e => setAnchorEl(e.currentTarget)} color="inherit">
            <More />
          </IconButton>
        </>
      )}
    </>
  ) : (
    <>
      <input
        type="file"
        onChange={event => handleUploadFiles(event.target.files)}
        accept="image/*"
        id="new-photo"
        style={{ display: 'none' }}
        multiple
      />
      <Upload htmlFor="new-photo">
        <CloudUpload />
      </Upload>
    </>
  );
};

export default AiArtSelectorActions;
