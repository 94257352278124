import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<any, { background: string }>(props => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    background: props.background,
  },
}));

type NoDataProps = {
  message: string;
  background?: string;
};

const NoData: React.FC<NoDataProps> = ({ message, background = '#fff' }) => {
  const classes = useStyles({ background });

  return (
    <div className={classes.container}>
      <Typography color="textSecondary">{message}</Typography>
    </div>
  );
};

export default NoData;
