import React from 'react';
import { Typography, Grid } from '@mui/material';
import ProductItem from './ProductItem';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import history from 'services/history';
import { ProductList as ProductListType } from 'types/product';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import { productTableTemplate } from '../../productTableTemplate';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type ProductListProps = {
  products: ProductListType[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const ProductList: React.FC<ProductListProps> = ({ products, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();

  function handleClick(id?: number | string) {
    history.push(`/products/${id}`);
  }

  return (
    <Grid container>
      <TableContainer tableTemplate={productTableTemplate}>
        <TableContent>
          <TableHeader>
            {productTableTemplate.map(item => (
              <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
          </TableHeader>
          <TableBody>
            {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => (
              <TableRow
                style={{ opacity: parseFloat(product.Est) <= 0 ? 0.5 : 1 }}
                onClick={() => handleClick(product.codigo_produto)}
                key={product.codigo_produto}
              >
                <ProductItem product={product} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </Grid>
  );
};

export default ProductList;
