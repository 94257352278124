import React from 'react';
import { styled } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import SerialNumberListTable from './list/table/SerialNumberListTable';
import NoData from 'components/no-data/NoData';
import { WasteProduct } from 'pages/material-residues/waste/types/waste';

interface SerialNumbersDialogProps {
  onExited(): void;
  product: WasteProduct;
}

const Container = styled('div')({
  flex: 1,
  marginTop: 10,
  display: 'flex',
});

const SerialNumbersDialog: React.FC<SerialNumbersDialogProps> = ({ onExited, product }) => {
  return (
    <Dialog maxWidth="sm" height="70vh" onExited={onExited} title={`${product.id_produto} - ${product.produto}`}>
      <Container>
        {product.numeros_serie.length === 0 ? (
          <NoData message="Nenhum número de série adicionado." />
        ) : (
          <SerialNumberListTable serialNumbers={product.numeros_serie} />
        )}
      </Container>
    </Dialog>
  );
};

export default SerialNumbersDialog;
