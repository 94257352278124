import React, { useEffect, useState } from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { PurchaseOrderBuyerWithoutSale } from 'types/purchaseOrderItem';
import { moneyFormat } from 'helpers/numberFormat';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import NoData from 'components/no-data/NoData';

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  background: '#f17c27',
  color: '#fff',
  overflow: 'auto',
  maxHeight: 400,
});

const Row = styled('li')(({ theme }) => ({
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 6,
  gridTemplateColumns: '60px 120px 1fr 100px 100px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '60px 220px 100px 100px 100px',
  },
  '& .buyer-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  '& > .numericData': {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  '& > span': {
    gap: 5,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
  rowGap: 10,
});

interface BuyersWithoutSalesProps {
  data: PurchaseOrderBuyerWithoutSale[];
}

const BuyersWithoutSales: React.FC<BuyersWithoutSalesProps> = ({ data }) => {
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<PurchaseOrderBuyerWithoutSale[]>([]);

  useEffect(() => {
    setFiltered(
      data.map(item => ({
        ...item,
        formattedQuantity: parseInt(item.qtd_itens),
        formattedValue: moneyFormat(item.valor),
      })),
    );
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <Container>
      <Typography variant="subtitle2" align="center">
        Pedidos sem movimentação de vendas
      </Typography>
      {filtered.length > 0 ? (
        <List>
          <Row>
            <span>
              <Typography onClick={() => handleSort('id_produto')} variant="subtitle2">
                ID
              </Typography>
              {orderedIndex.index === 'id_produto' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
            <span>
              <Typography onClick={() => handleSort('comprador')} variant="subtitle2">
                Comprador
              </Typography>
              {orderedIndex.index === 'comprador' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('produto')} variant="subtitle2">
                Produto
              </Typography>
              {orderedIndex.index === 'produto' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography onClick={() => handleSort('valor')} align="right" variant="subtitle2">
                Valor
              </Typography>
              {orderedIndex.index === 'valor' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography onClick={() => handleSort('formattedQuantity')} variant="subtitle2" align="right">
                Qtd
              </Typography>
              {orderedIndex.index === 'formattedQuantity' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
          </Row>
          {filtered.map((buyer, index) => (
            <Row key={index}>
              <Typography variant="caption">{buyer.id_produto}</Typography>
              <Tooltip title={buyer.comprador}>
                <Typography className="buyer-name" variant="caption">
                  {buyer.comprador}
                </Typography>
              </Tooltip>
              <Tooltip title={buyer.produto}>
                <Typography className="buyer-name" variant="caption">
                  {buyer.produto}
                </Typography>
              </Tooltip>
              <Typography className="numericData" align="right" variant="caption">
                {buyer.formattedValue}
              </Typography>
              <Typography className="numericData" align="right" variant="caption">
                {buyer.qtd_itens}
              </Typography>
            </Row>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
    </Container>
  );
};

export default BuyersWithoutSales;
