import React from 'react';
import DialogInput from 'components/dialogs/DialogInput';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Theme, useTheme } from '@mui/material';
import { Search } from '@mui/icons-material';
import { StockFilter } from 'types/reports/stockFilter';
import InsideLoading from 'components/loading/InsideLoading';
import { makeStyles } from '@mui/styles';
import { useFetchMoreFilters } from 'components/filter-more/hooks/useFetchMoreFilters';

const useStyles = makeStyles({
  item: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    lineClamp: 1,
    WebkitBoxOrient: 'vertical',
    width: 350,
  },
  formControl: {
    minWidth: 120,
    maxWidth: 400,
  },
  container: {
    height: 500,
    display: 'grid',
    gap: 20,
    flex: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

type StockMoreFilterProps = {
  onExited(): void;
  onSearch(): void;
  filter: StockFilter;
  handleChangeFilter(index: keyof StockFilter, value: any): void;
};

function getStyles(name: number, personName: readonly number[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold,
    backgroundColor: personName.indexOf(name) === -1 ? '#fff' : '#cfffe6',
  };
}

const StockMoreFilter: React.FC<StockMoreFilterProps> = ({ onExited, onSearch, handleChangeFilter, filter }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { units, segments, subCategories, categories, loading, manufacturers, subGroups } = useFetchMoreFilters();

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <div className={classes.container}>
        {loading ? (
          <InsideLoading />
        ) : (
          <div className={classes.form}>
            <TextField
              label="Responsável"
              placeholder="Digite sua pesquisa"
              autoFocus
              value={filter.made_by}
              onChange={e => handleChangeFilter('made_by', e.target.value)}
            />

            <FormControl className={classes.formControl}>
              <InputLabel>unidades</InputLabel>
              <Select
                fullWidth
                value={filter.unityId}
                onChange={e => handleChangeFilter('unityId', e.target.value)}
                multiple
              >
                {units?.map(unity => (
                  <MenuItem key={unity.id} value={unity.id}>
                    {unity.unidade}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel>segmentos</InputLabel>
              <Select
                fullWidth
                value={filter.segmentId}
                onChange={e => handleChangeFilter('segmentId', e.target.value as any)}
                multiple
              >
                {segments?.map(segment => (
                  <MenuItem className={classes.item} key={segment.id} value={segment.id}>
                    {segment.segmento}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel>categorias</InputLabel>
              <Select
                fullWidth
                multiple
                value={filter.categoryId}
                onChange={e => handleChangeFilter('categoryId', e.target.value as any)}
              >
                {categories?.map(category => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.categoria}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel>subcategorias</InputLabel>
              <Select
                fullWidth
                label="subcategorias"
                value={filter.subcategoryId}
                onChange={e => handleChangeFilter('subcategoryId', e.target.value as any)}
                multiple
              >
                {subCategories.map(subcategory => (
                  <MenuItem key={subcategory.CODIGO_GRUPO} value={subcategory.CODIGO_GRUPO}>
                    {subcategory.NOME}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel>fabricantes</InputLabel>
              <Select
                fullWidth
                value={filter.manufacturerId}
                onChange={e => handleChangeFilter('manufacturerId', e.target.value)}
                multiple
              >
                {manufacturers?.map(manufacturer => (
                  <MenuItem
                    style={getStyles(manufacturer.id_fabricante, filter.manufacturerId, theme)}
                    key={manufacturer.id_fabricante}
                    value={manufacturer.id_fabricante}
                  >
                    {manufacturer.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel>Subgrupos</InputLabel>
              <Select
                fullWidth
                label="Subgrupos"
                value={filter.subgroupId}
                onChange={e => handleChangeFilter('subgroupId', e.target.value as any)}
                multiple
              >
                {subGroups.map(subgroup => (
                  <MenuItem
                    style={getStyles(subgroup.CODIGO_SUBGRUPO, filter.subgroupId, theme)}
                    key={subgroup.CODIGO_SUBGRUPO}
                    value={subgroup.CODIGO_SUBGRUPO}
                  >
                    {subgroup.NOME}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div>
              <Button
                endIcon={<Search fontSize="small" />}
                type="submit"
                color="primary"
                variant="contained"
                onClick={onSearch}
                fullWidth
              >
                Pesquisar
              </Button>
            </div>
          </div>
        )}
      </div>
    </DialogInput>
  );
};

export default StockMoreFilter;
