import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CourseStudentOnList } from 'pages/course-students/types/courseStudent';
import { useCourseStudents } from 'pages/course-students/hooks/useCourseStudents';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginRight: 20,
  },
});

type CourseStudentItemTableProps = {
  courseStudent: CourseStudentOnList;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const CourseStudentItemTable: React.FC<CourseStudentItemTableProps> = ({ courseStudent, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedCourseStudent } = useCourseStudents();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    setSelectedCourseStudent(courseStudent);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton onClick={handleMoreClick} key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography variant="body2">{courseStudent[item.id as any]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default CourseStudentItemTable;
