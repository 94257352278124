import { TableTemplate } from 'types/tableTemplate';

export const transferRequestsTableTemplate: TableTemplate[] = [
  {
    id: 'data_lanc',
    description: 'Lançamento',
    originalId: 'formattedReleaseDate',
    width: 120,
  },
  {
    id: 'Origem',
    description: 'Origem',
    originalId: 'Origem',
    width: 90,
  },
  {
    id: 'destino',
    description: 'Destino',
    originalId: 'destino',
    width: 90,
  },
  {
    id: 'usuario',
    description: 'Usuário',
    originalId: 'usuario',
    width: 170,
  },
  {
    id: 'comprador',
    description: 'Comprador',
    originalId: 'comprador',
    width: 120,
  },
  {
    id: 'id_produto',
    description: 'ID',
    originalId: 'id_produto',
    width: 60,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 180,
  },
  {
    id: 'qtd',
    description: 'QTD',
    originalId: 'formattedQuantity',
    width: 70,
  },
  {
    id: 'formattedTotalCoust',
    description: 'Custo Total',
    originalId: 'custo_total',
    width: 125,
    dataType: 'number',
  },
  {
    id: 'peso_total',
    description: 'Peso Total',
    originalId: 'formattedTotalWeight',
    width: 95,
    dataType: 'number',
  },
  {
    id: 'est_Origem',
    description: 'Est Origem',
    originalId: 'est_Origem',
    width: 105,
  },
  {
    id: 'est_destino',
    description: 'Est Destino',
    originalId: 'est_destino',
    width: 105,
  },
];
