import React from 'react';
import { Agent } from 'types/agent';
import { useAgent } from '../hooks/useAgent';
import { Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    rowGap: '20px',
    flexDirection: 'column',
  },
});

interface AccountAgentFormActiveProps {
  handleChange(index: keyof Agent, value: any): void;
}

const AccountAgentFormActive: React.FC<AccountAgentFormActiveProps> = ({ handleChange }) => {
  const classes = styles();

  const { agent } = useAgent();
  return (
    <div className={classes.container}>
      <FormControlLabel
        control={
          <Checkbox
            checked={agent.retirada === 'S'}
            onChange={e => handleChange('retirada', e.target.checked ? 'S' : 'N')}
            color="primary"
          />
        }
        label="retirada"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={agent.rma === 'S'}
            onChange={e => handleChange('rma', e.target.checked ? 'S' : 'N')}
            color="primary"
          />
        }
        label="rma"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={agent.compra === 'S'}
            onChange={e => handleChange('compra', e.target.checked ? 'S' : 'N')}
            color="primary"
          />
        }
        label="compra"
      />
    </div>
  );
};

export default AccountAgentFormActive;
