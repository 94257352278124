import React from 'react';
import { Tab, Tabs, Box } from '@mui/material';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface CustomerTabsProps {
  value: number;
  handleChange(value: number): void;
}

const CustomerTabs: React.FC<CustomerTabsProps> = ({ value, handleChange }) => {
  return (
    <div>
      <Box>
        <Tabs value={value} onChange={(e, value) => handleChange(value)} variant="scrollable">
          <Tab label="Principal" {...a11yProps(0)} />
          <Tab label="Endereço" {...a11yProps(1)} />
          <Tab label="Contato" {...a11yProps(2)} />
          <Tab label="Financeiro" {...a11yProps(3)} />
          <Tab label="Preposto" {...a11yProps(4)} />
          <Tab label="Observação" {...a11yProps(5)} />
          <Tab label="Documentos" {...a11yProps(6)} />
          <Tab label="Diversos" {...a11yProps(7)} />
        </Tabs>
      </Box>
    </div>
  );
};

export default CustomerTabs;
