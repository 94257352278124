import React, { ReactElement, ReactNode } from 'react';
import { BoxChart } from 'components/chart/box/BoxChart';

interface ContentChartProps {
  Icon?: ReactElement;
  title: string;
  subtitle?: string;
  children: ReactNode;
}

export const ContentChart: React.FC<ContentChartProps> = ({ children, title, subtitle, Icon }) => {
  return (
    <BoxChart Icon={Icon} subtitle={subtitle} title={title}>
      {children}
    </BoxChart>
  );
};
