import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import SegmentItemModule from './SegmentItemModule';
import { AbcProductSegment } from 'types/abcProduct';

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  columnGap: 100,
  flex: 1,
});

type SegmentListModuleProps = {
  segments: AbcProductSegment[];
};

const SegmentListModule: React.FC<SegmentListModuleProps> = ({ segments }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {segments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(segment => (
        <SegmentItemModule key={segment.cod} segment={segment} />
      ))}
    </CustomList>
  );
};

export default SegmentListModule;
