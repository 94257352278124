import React, { useEffect, useState, useCallback } from 'react';
import { useApp } from 'hooks/app';
import { api } from 'services/api';
import useSearch from 'hooks/search';
import { arraySort } from 'helpers/sort';
import useTableOrder from 'hooks/tableOrder';
import Appbar from 'components/appbar/Appbar';
import { Theme } from '@mui/material';
import NoData from 'components/no-data/NoData';
import { useMessaging } from 'hooks/messaging';
import Loading from 'components/loading/Loading';
import PaginationProvider from 'hooks/pagination';
import { moneyFormat } from 'helpers/numberFormat';
import { endOfDay, subDays, startOfDay } from 'date-fns';
import Pagination from 'components/pagination/Pagination';
import OrderListTable from '../list/table/OrderListTable';
import TableLoading from 'components/loading/TableLoading';
import OrderListModule from '../list/module/OrderListModule';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableContainer from 'components/table/TableContainer';
import { OrderAuthorization } from 'types/orderAuthorization';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import OrdersAuthorizationFilterBox from '../OrdersAuthorizationFilterBox';
import { OrdersAuthorizationProvider } from '../hooks/useOrdersAuthorization';
import SelectedOrderAuthorization from '../selected-order/SelectedOrderAuthorization';
import { OrdersAuthorizationTableTemplate } from '../ordersAuthorizationTableTemplate';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

export interface OrdersAuthorizationFilter {
  initialDate: Date;
  finalDate: Date;
}

const InvoiceCard: React.FC = () => {
  const classes = useStyles();
  const app = useApp();
  const search = useSearch();
  const messaging = useMessaging();
  const [dialog, setDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const [orders, setOrders] = useState<OrderAuthorization[]>([]);
  const [more, setMore] = useState<OrderAuthorization | null>(null);
  const [filtered, setFiltered] = useState<OrderAuthorization[]>([]);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [selectedOrder, setSelectedOrder] = useState<null | OrderAuthorization>(null);
  const [filter, setFilter] = useState<OrdersAuthorizationFilter>({
    initialDate: startOfDay(subDays(new Date(), 60)),
    finalDate: endOfDay(new Date()),
  });

  useEffect(() => {
    const _orders = search(searchValue, 'Cliente', orders);
    setFiltered(arraySort(orderedIndex.index, _orders, orderedIndex.direction));
  }, [searchValue, search, orderedIndex, orders]);

  const handleFormatOrder = useCallback((order: OrderAuthorization): OrderAuthorization => {
    return {
      ...order,
      formattedShipment: moneyFormat(order.valor_Frete),
      formattedValueProductTotal: moneyFormat(order.valor_total_prod),
      formattedValueTotal: moneyFormat(order.valor_total),
    };
  }, []);

  function handleUpdateList() {
    setLoading(true);

    if (!app.h2iApi) {
      return;
    }

    app.h2iApi
      .get('/api/getautfatcartao')
      .then(response => {
        setOrders(response.data.map(item => handleFormatOrder(item)));
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);

    if (!app.h2iApi) {
      return;
    }

    app.h2iApi
      .get('/api/getautfatcartao')
      .then(response => {
        setOrders(response.data.map(item => handleFormatOrder(item)));
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleFormatOrder, filter, app.h2iApi]);

  function handleDenyOrder(order: OrderAuthorization) {
    setSaving(true);
    api
      .post('/ordersAuthorization', order.id)
      .then(() => {
        messaging.handleOpen('Faturamento negado');
        handleUpdateList();
      })
      .finally(() => setSaving(false));
  }

  function handleApproveOrder(order: OrderAuthorization) {
    setSaving(true);

    if (!app.h2iApi) {
      return;
    }

    app.h2iApi
      .post(`/api/saveautfatcartao?id_pedido=${order.id}&id_filial=${order.id_filial}`)
      .then(() => {
        messaging.handleOpen('Pedido autorizado');
        handleUpdateList();
      })
      .finally(() => setSaving(false));
  }

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleChangeFilter(index: keyof OrdersAuthorizationFilter, value: Date | null) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <OrdersAuthorizationProvider
      value={{
        more,
        filter,
        setMore,
        setOrders,
        setDialog,
        selectedOrder,
        handleDenyOrder,
        setSelectedOrder,
        handleFormatOrder,
        handleChangeFilter,
        handleApproveOrder,
      }}
    >
      {dialog && <SelectedOrderAuthorization />}
      {saving && <Loading />}
      <Appbar title="Site" />
      <PageHeaderActions
        title="Faturamento de cartão"
        description="Gestão de pedidos por cartão aguardando aprovação"
      />
      <TableContainer tableTemplate={OrdersAuthorizationTableTemplate}>
        <OrdersAuthorizationFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum faturamento feito por cartão para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <OrderListTable orders={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <OrderListModule orders={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </OrdersAuthorizationProvider>
  );
};

export default InvoiceCard;
