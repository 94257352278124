import React from 'react';
import ResellerItem from './ResellerItem';
import { H2iReseller } from 'types/h2iReseller';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const styles = makeStyles<Theme>(theme => ({
  ul: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    flex: 1,
    margin: '10px 0 20px',
    alignItems: 'flex-start',
    '& li:not(:last-child)': {
      borderBottom: '1px solid #eee',
    },
    '& li': {
      padding: 20,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.05)',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '20px 10px',
      },
    },
  },
}));

interface ResellerListProps {
  resellers: H2iReseller[];
  onSelect(reseller: H2iReseller): void;
}

const ResellerList: React.FC<ResellerListProps> = ({ resellers, onSelect }) => {
  const classes = styles();

  return (
    <ul className={classes.ul}>
      {resellers.map(reseller => (
        <ResellerItem key={reseller.codigo} onSelect={onSelect} reseller={reseller} />
      ))}
    </ul>
  );
};

export default ResellerList;
