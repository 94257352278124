import React from 'react';

import Dialog from 'components/dialogs/Dialog';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSelector } from 'store/selector';
import { Branch } from 'types/branch';
import { moneyFormat } from 'helpers/numberFormat';
import { FinancialCard } from 'types/financialReportList';
import PrintData from 'components/print/PrintData';

import { makeStyles } from '@mui/styles';

type FinancialCardDataProps = {
  data: FinancialCard[];
  branch?: Branch | null;
  onExited(): void;
};

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1.5fr 2fr 2fr 2fr 1.4fr 1fr 1fr',
    marginBottom: 1,
    paddingBottom: 1,
  },
  headerInfo: {
    marginTop: 10,
  },
  rowP: {
    fontSize: '7pt',
    textAlign: 'end',
    marginRight: '1px',
  },
  rowPL: {
    fontSize: '7pt',
    textAlign: 'start',
  },
  rowPC: {
    fontSize: '7pt',
    textAlign: 'center',
  },
});

const FinancialCardData: React.FC<FinancialCardDataProps> = ({ data, branch, onExited }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);

  return (
    <Dialog fullScreen title="Cartões" onExited={onExited} ComponentActions={<PrintData />}>
      <div className="page">
        <div className="header">
          <div>
            <h6>Relatório de cartões</h6>
            {branch?.id === undefined ? <h6>Todas as filiais</h6> : <h6>Filial {branch?.nome}</h6>}
          </div>
          <div>
            <p>{user?.name}</p>
            <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
          </div>
        </div>
        <div className={`${classes.row} table-header`}>
          <p className={classes.rowPL}>Autorização</p>
          <p className={classes.rowPL}>Forma de Pag.</p>
          <p className={classes.rowPL}>QTD de parcelas</p>
          <p className={classes.rowPL}>Posição da parcela</p>
          <p className={classes.rowP}>VL Recebido</p>
          <p className={classes.rowP}>VL Desc.</p>
          <p className={classes.rowP}>Taxa</p>
        </div>
        {data
          .sort((a, b) => a.forma_pagamento.localeCompare(b.forma_pagamento))
          .map(item => (
            <div key={item.id} className={classes.row}>
              <p className={classes.rowPL}>{item.Autorizacao}</p>
              <p className={classes.rowPL}>{item.forma_pagamento}</p>
              <p className={classes.rowPL}>{item.parcela}</p>
              <p className={classes.rowPL}>{item.posicao_parcela}</p>
              <p className={classes.rowP}>{moneyFormat(item.vl_recebido)}</p>
              <p className={classes.rowP}>{moneyFormat(item.vl_desconto)}</p>
              <p className={classes.rowP}>{moneyFormat(item.taxa)}</p>
            </div>
          ))}
      </div>
    </Dialog>
  );
};

export default FinancialCardData;
