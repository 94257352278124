import React from 'react';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import ManufacturerItemTable from './ManufacturerItemTable';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography, alpha, Grid, Theme } from '@mui/material';
import { AbcProductManufacturer } from 'types/abcProduct';
import { manufacturerTableTemplate } from '../../manufacturerTableTemplate';
import ManufacturerMoreMenu from '../ManufacturerMoreMenu';
import { useAbcProducts } from '../../../hooks/useAbcProducts';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  header: {
    backgroundColor: '#eee',
  },
  headerSubgroup: {
    backgroundColor: '#d9f8e5',
  },
  tableRow: {
    '& > div:nth-child(even)': {
      backgroundColor: '#eee',
    },
  },
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
  selectedCustomer: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
  },
}));

type ManufacturerListTableProps = {
  manufacturers: AbcProductManufacturer[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  isSubgroup: boolean;
};

const ManufacturerListTable: React.FC<ManufacturerListTableProps> = ({
  manufacturers,
  handleSort,
  orderedIndex,
  isSubgroup,
}) => {
  const classes = useStyles();
  const { moreManufacturer } = useAbcProducts();
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={manufacturerTableTemplate}>
          <TableContent>
            <TableHeader className={`${isSubgroup ? classes.header : classes.headerSubgroup}`}>
              {manufacturerTableTemplate.map(item => (
                <div
                  className={
                    item.dataType === 'number'
                      ? `${classes.headerItem} ${classes.headerItemNumeric}`
                      : classes.headerItem
                  }
                  key={item.id}
                  onClick={() => handleSort(item.originalId)}
                >
                  <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </div>
              ))}
            </TableHeader>
            <TableBody>
              <div className={`${isSubgroup ? classes.tableRow : undefined}`}>
                {manufacturers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((manufacturer, index) => (
                    <>
                      <TableRow style={{ minHeight: 10, padding: 0 }} key={index}>
                        <ManufacturerItemTable isSubgroup={isSubgroup} manufacturer={manufacturer} />
                      </TableRow>
                      {moreManufacturer?.fabricante === manufacturer.fabricante && (
                        <ManufacturerMoreMenu manufacturer={manufacturer} />
                      )}
                    </>
                  ))}
              </div>
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ManufacturerListTable;
