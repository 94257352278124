import { useEffect, useState, Dispatch } from 'react';
import { api } from 'services/api';
import { ProjectActions } from '../../reducer/types';
import { setProject } from '../../reducer/action';

type UseFetchProject = [boolean, string];

export function useFetchProject(dispatch: Dispatch<ProjectActions>, id?: string): UseFetchProject {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!id) {
      return;
    }

    api
      .get(`/projects/${id}`)
      .then(response => {
        dispatch(setProject(response.data));
      })
      .catch(err => {
        console.error(err);
        setError('Aconteceu um erro ao carregar o projeto');
      })
      .finally(() => setLoading(false));
  }, [id, dispatch]);

  return [loading, error];
}
