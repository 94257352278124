import { useCallback, useEffect, useState } from 'react';
import { api } from 'services/api';
import { endOfDay, format, parseISO, startOfDay, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { SellerDashboardQueryParams } from '../SellerDashboard';

export function useFetchSellerDashboard() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [sellersItems, setSellersItems] = useState<[]>([]);
  const [dialogDate, setDialogDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateConfig, setDateConfig] = useState<number | null>(0);
  const [filter, setFilter] = useState<SellerDashboardQueryParams>({
    initialDate: new Date(),
    finalDate: new Date(),
    seller: '',
  });

  function getDescriptionPageHeader(): string {
    const formattedInitialDate = format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = format(filter.finalDate, 'P', { locale: ptBR });

    return `Você está visualizando informações de ${formattedInitialDate} à ${formattedFinalDate}`;
  }

  function handleDateConfig(days: number) {
    setDateConfig(days);
    if (days === 0) {
      const date = new Date();
      setFilter(filter => ({
        ...filter,
        initialDate: startOfDay(date),
      }));
      setFilter(filter => ({
        ...filter,
        finalDate: endOfDay(date),
      }));
    } else {
      const date = new Date();
      setFilter(filter => ({
        ...filter,
        finalDate: endOfDay(date),
      }));

      setFilter(filter => ({ ...filter, initialDate: startOfDay(subDays(date, days)) }));
    }
  }

  function handleConfirmCustomDates(initialDt: Date, finalDt: Date) {
    setFilter(filter => ({ ...filter, initialDate: initialDt }));

    setFilter(filter => ({
      ...filter,
      finalDate: finalDt,
    }));

    setDateConfig(null);
  }

  const fetch = useCallback((params: SellerDashboardQueryParams) => {
    setLoading(true);

    api
      .get('/dashboardvendedor', {
        params: { vendedor: params.seller, data_ini: params.initialDate, data_fim: params.finalDate },
      })
      .then(response => {
        setSellersItems(
          response.data.data.map(item => ({
            ...item,
            formattedUpdatedAt: format(parseISO(item.updated_at), 'P', { locale: ptBR }),
            formattedCreatedAt: format(parseISO(item.created_at), 'P', { locale: ptBR }),
          })),
        );
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetch(filter);
  }, [filter, fetch]);

  return {
    dialogDate,
    setDialogDate,
    sellersItems,
    setSellersItems,
    loading,
    filter,
    getDescriptionPageHeader,
    dateConfig,
    handleDateConfig,
    setAnchorEl,
    handleConfirmCustomDates,
    anchorEl,
  };
}
