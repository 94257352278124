import React, { useMemo } from 'react';
import { useApp } from 'hooks/app';
import { Pie } from 'react-chartjs-2';
import { CommissionCategory } from 'types/reports/commissionCategory';
import { ContentChart } from 'components/chart/box/ContentChart';
import { moneyFormat } from 'helpers/numberFormat';
import { externalTooltipHandler } from 'components/chart/externalTooltip';

interface ModalityChartProps {
  commissionCategory: CommissionCategory[];
}

export const ModalityChart: React.FC<ModalityChartProps> = ({ commissionCategory }) => {
  const app = useApp();
  const setCategory = new Set();

  const filterCategory = commissionCategory
    .filter(category => {
      const duplicatedItem = setCategory.has(category.categoria);
      setCategory.add(category.categoria);
      return !duplicatedItem;
    })
    .sort();

  const pieData = useMemo(() => {
    return filterCategory.map(item => item.vl_total);
  }, [filterCategory]);

  return (
    <ContentChart title="Categoria">
      <Pie
        height={app.isMobile && app.windowWidth < 960 ? 300 : 200}
        data={{
          labels: filterCategory.map(item => [item.categoria]),
          datasets: [
            {
              data: filterCategory.map(item => [item.vl_total]),
              backgroundColor: [
                'yellow',
                'red',
                'green',
                'blue',
                'rgba(54, 162, 235, 0.8)',
                'rgba(78, 217, 100, 0.8)',
                'rgba(57, 05, 68, 0.8)',
                'rgba(78, 89, 235, 0.8)',
                'rgba(37, 85, 192, 0.8)',
                'rgba(98, 99, 74, 0.8)',
                'rgba(65, 2, 78, 0.8)',
                'rgba(78, 95, 17, 0.8)',
                'rgba(87, 21, 0, 0.8)',
                'rgba(98, 178, 200, 0.8)',
                'rgba(77, 54, 35, 0.8)',
                'rgba(45, 254, 54, 0.8)',
                'rgba(216, 154, 25, 0.8)',
                'rgba(98, 25, 34, 0.8)',
                'rgba(32, 164, 65, 0.8)',
                'rgba(137, 133, 252, 0.8)',
                'rgba(100, 253, 65, 0.8)',
              ],
            },
          ],
        }}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
              callbacks: {
                label: tooltip => moneyFormat(pieData[tooltip.dataIndex as number]),
              },
              external: externalTooltipHandler,
            },
          },
          responsive: true,
        }}
      />
    </ContentChart>
  );
};
