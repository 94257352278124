import React from 'react';
import { Tab, Tabs as NativeTabs } from '@mui/material';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    // marginBottom: 15,
  },
});

export type CreditLimitRequestTabs =
  | 'limitRequest'
  | 'socialContract'
  | 'annualBalance'
  | 'quarterlyBalance'
  | 'twelveMonthsRevenue'
  | 'otherDocuments';

type TabsProps = {
  handleChange(value: CreditLimitRequestTabs): void;
  tab: CreditLimitRequestTabs;
};

const Tabs: React.FC<TabsProps> = ({ tab, handleChange }) => {
  const classes = styles();

  return (
    <NativeTabs
      className={classes.container}
      value={tab}
      onChange={(e, value) => handleChange(value)}
      variant="scrollable"
    >
      <Tab label="Solicitação" value="limitRequest" />
      <Tab label="Contrato social" value="socialContract" />
      <Tab label="Balanço anual" value="annualBalance" />
      <Tab label="Balancete trimestral" value="quarterlyBalance" />
      <Tab label="Faturamento" value="twelveMonthsRevenue" />
      <Tab label="Outros documentos" value="otherDocuments" />
    </NativeTabs>
  );
};

export default Tabs;
