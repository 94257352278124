import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import React, { useEffect, useState } from 'react';
import CompatibilitiesHeaderButtons from './CompatibilitiesHeaderButtons';
import { CompatibilityProvider } from './hooks/useCompatibilities';
import { Compatibility } from 'types/compatibility';
import useTableOrder from 'hooks/tableOrder';
import { useFetchCompatibilities } from './hooks/useFetchCompatibilities';
import { compatibilitiesTableTemplate } from './constants/compatibilitiesTableTemplate';
import TableContainer from 'components/table/TableContainer';
import PaginationProvider from 'hooks/pagination';
import ApiPagination from 'components/pagination/ApiPagination';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import CompatibilityListTable from './list/table/CompatibilityListTable';
import CompatibilityListModule from './list/module/CompatibilityListModule';
import CompatibilitiesFIlterBox from './CompatibilitiesFIlterBox';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

export type CompatibilitiesQueryParams = {
  rows: number;
  page: number;
  term: string | null;
  type: 'compatibility';
};

const initialQueryParams: CompatibilitiesQueryParams = {
  rows: 48,
  page: 0,
  term: null,
  type: 'compatibility',
};

let timer: NodeJS.Timeout;

const Compatibilities: React.FC = () => {
  const [selectedCompatibility, setSelectedCompatibility] = useState<Compatibility | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [queryParams, setQueryParams] = useState<CompatibilitiesQueryParams>(initialQueryParams);
  const { compatibilities, loading, total, fetch, setCompatibilities } = useFetchCompatibilities();
  const [filtered, setFiltered] = useState<Compatibility[]>([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetch(initialQueryParams);
  }, [fetch]);

  useEffect(() => {
    setFiltered(compatibilities);
  }, [compatibilities]);

  function handleQueryParamsChange(index: keyof CompatibilitiesQueryParams, value: string | number | null) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));

    const query = {
      ...queryParams,
      [index]: value,
    };

    clearTimeout(timer);

    timer = setTimeout(() => fetch(query), 500);
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleDelete() {
    if (!selectedCompatibility) {
      return;
    }

    setSaving(true);

    api
      .delete(`/compatibilities/${selectedCompatibility.id}`)
      .then(() => {
        setCompatibilities(state => state.filter(item => item.id !== selectedCompatibility.id));
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  return (
    <Container>
      {saving && <Loading />}

      <Appbar title="Compatibilidades" />

      <PageHeaderActions
        title="Compatibilidades"
        description="Gestão das compatibilidades de produtos"
        ActionComponent={<CompatibilitiesHeaderButtons />}
      />

      <CompatibilitiesFIlterBox
        queryParams={queryParams}
        handleQueryParamsChange={handleQueryParamsChange}
        setDisplayMode={setDisplayMode}
        displayMode={displayMode}
      />

      <CompatibilityProvider value={{ selectedCompatibility, setSelectedCompatibility, handleDelete }}>
        <TableContainer tableTemplate={compatibilitiesTableTemplate}>
          <PaginationProvider>
            {loading ? (
              displayMode === 'list' ? (
                <TableLoading />
              ) : (
                <ModuleLoading />
              )
            ) : filtered.length === 0 ? (
              <NoData message="Nenhuma compatibilidade para listar" />
            ) : (
              <div>
                {displayMode === 'list' ? (
                  <CompatibilityListTable
                    compatibilities={filtered}
                    handleSort={handleSort}
                    orderedIndex={orderedIndex}
                  />
                ) : (
                  displayMode === 'module' && <CompatibilityListModule compatibilities={filtered} />
                )}
              </div>
            )}

            <ApiPagination
              onChangePage={value => handleQueryParamsChange('page', value)}
              onChangeRowsPerPage={value => handleQueryParamsChange('rows', value)}
              count={total}
            />
          </PaginationProvider>
        </TableContainer>
      </CompatibilityProvider>
    </Container>
  );
};

export default Compatibilities;
