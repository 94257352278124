import { useEffect, useCallback, useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useMessaging } from 'hooks/messaging';
import { H2iOrder } from 'types/h2iOrder';

export function useFetchDavReviewDetail(id: string | undefined, branchId: string | undefined) {
  const { h2iApi } = useApp();
  const { handleOpen } = useMessaging();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<H2iOrder | null>(null);

  const fetch = useCallback(() => {
    if (!h2iApi) {
      setLoading(false);
      return;
    }

    setLoading(true);
    h2iApi
      .get('/api/getdav', {
        params: {
          id: id || '',
          id_filial: branchId || '',
        },
      })
      .then(response => {
        const _order: H2iOrder = response.data[0];

        const _date = _order.Data.split('/');
        const parseDate = new Date(parseInt(_date[2]), parseInt(_date[1]) - 1, parseInt(_date[0]));

        setOrder({
          ..._order,
          createdAt: parseDate,
          formattedCreatedAt: format(parseDate, 'PP', { locale: ptBR }),
          formattedValorFrete: moneyFormat(_order.valor_Frete),
          formattedValorTotal: moneyFormat(_order.valor_total),
          formattedValorTotalProdutos: moneyFormat(_order.valor_total_prod),
          modalidade_entrega: _order.modalidade_entrega.toLowerCase(),
          formattedPeso: numberFormat(
            _order.itens.reduce((sum, item) => sum + parseFloat(item.peso.replace(/,/g, '.')), 0).toString(),
          ),
          formattedPesoBruto: numberFormat(
            _order.itens.reduce((sum, item) => sum + parseFloat(item.pesobruto.replace(/,/g, '.')), 0).toString(),
          ),
          formattedValorDescontoGerente: moneyFormat(
            _order.itens.reduce((sum, item) => sum + item.valor_desc_gerente, 0),
          ),
          formattedValorDescontoVendedor: moneyFormat(
            _order.itens.reduce((sum, item) => sum + item.valor_desc_vendedor, 0),
          ),
          mc: _order.itens.reduce((sum, item) => sum + item.mc, 0),
          mcPercent: (_order.itens.reduce((sum, item) => sum + item.mc, 0) / _order.valor_total) * 100,
          formattedMc: percentFormat((_order.itens.reduce((sum, item) => sum + item.mc, 0) / _order.valor_total) * 100),
          formattedSpiff: moneyFormat(_order.itens.reduce((sum, item) => sum + item.spiff, 0)),
          itens: _order.itens.map(product => {
            product.formattedValorFrete = moneyFormat(product.valor_frete);
            product.formattedValorTotal = moneyFormat(product.valor_total);
            product.formattedValorUnitario = moneyFormat(product.valor_unitario);
            product.formattedMc = moneyFormat(product.mc);
            product.formattedSpiff = moneyFormat(product.spiff);
            product.formattedSpiffExtra = moneyFormat(product.spiff_extra);
            product.formattedDescontoGerente = moneyFormat(product.valor_desc_gerente);
            product.formattedDescontoVendedor = moneyFormat(product.valor_desc_vendedor);
            return product;
          }),
          pagamento: _order.pagamento.map(payment => {
            payment.formattedValor = moneyFormat(payment.valor);
            return payment;
          }),
        });
      })
      .catch(() => {
        setOrder(null);
        handleOpen('Ocorreu um erro ao pesquisar a DAV');
      })
      .finally(() => setLoading(false));
  }, [h2iApi, handleOpen, branchId, id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    loading,
    order,
    fetch,
    setOrder,
  };
}
