import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, Typography, ListItemButton, styled } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { ManagedSaleOnList } from 'pages/managed-sales/types/managedSale';
import { useManagedSales } from 'pages/managed-sales/hooks/useManagedSales';

const ListItemButtonStyled = styled(ListItemButton)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '80%',
    alignItems: 'center',
  },
  '& .project-id': {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  '& .description': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    lineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
});

const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  bottom: 5,
  right: 5,
});

type ManagedSaleItemModuleProps = {
  rental: ManagedSaleOnList;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const ManagedSaleItemModule: React.FC<ManagedSaleItemModuleProps> = ({ rental, setAnchorEl }) => {
  const { setSelectedRental } = useManagedSales();

  function handleClick() {
    setSelectedRental(rental);
  }

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    setSelectedRental(rental);
  }

  return (
    <ListItemButtonStyled onClick={() => handleClick()}>
      <Typography variant="caption" color="primary" gutterBottom>
        venda #{rental.sequence.toString().padStart(3, '0')}
      </Typography>

      <Typography variant="h6" gutterBottom>
        {rental.customer_name}
      </Typography>

      <Typography>{rental.formattedTotal}</Typography>

      <StyledIconButton onClick={handleMoreClick}>
        <MoreVert />
      </StyledIconButton>
    </ListItemButtonStyled>
  );
};

export default ManagedSaleItemModule;
