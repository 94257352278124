import { Menu, MenuItem } from '@mui/material';
import { useProject } from 'pages/projects/registration/hook/useProject';
import { removeProjectCost } from 'pages/projects/registration/reducer/action';
import React, { Dispatch, SetStateAction } from 'react';
import { useProjectCosts } from '../hooks/useProjectCosts';

type ProjectCostsListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const ProjectCostsListMenu: React.FC<ProjectCostsListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { dispatch } = useProject();
  const { selectedProjectCost } = useProjectCosts();

  function handleDeleteClick() {
    if (!selectedProjectCost) {
      return;
    }

    dispatch(removeProjectCost(selectedProjectCost.id));
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default ProjectCostsListMenu;
