import React, { useMemo } from 'react';
import { useApp } from 'hooks/app';
import { Bar } from 'react-chartjs-2';
import { moneyFormat } from 'helpers/numberFormat';
import { CommercialPayment } from 'types/commercialPayment';
import { externalTooltipHandler } from 'components/chart/externalTooltip';

interface PaymentsChartProps {
  payments: CommercialPayment[];
}

export const PaymentsChart: React.FC<PaymentsChartProps> = ({ payments }) => {
  const app = useApp();

  const pieData = useMemo(() => {
    return payments.sort((a, b) => b.total - a.total).map(item => item.total);
  }, [payments]);

  return (
    <Bar
      height={app.isMobile && app.windowWidth < 960 ? 300 : 150}
      data={{
        labels: payments.sort((a, b) => b.total - a.total).map(branch => [branch.forma]),
        datasets: [
          {
            label: 'Total',
            data: payments.sort((a, b) => b.total - a.total).map(branch => branch.total),
            backgroundColor: '#30519f',
            borderWidth: 1,
          },
        ],
      }}
      options={{
        plugins: {
          tooltip: {
            enabled: false,
            callbacks: {
              label: tooltip => moneyFormat(pieData[tooltip.dataIndex as number]),
            },
            external: externalTooltipHandler,
          },
        },
        responsive: true,
      }}
    />
  );
};
