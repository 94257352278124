import React from 'react';
import { usePermissionRules } from 'hooks/permissionRules';
import SidebarItem from '../SidebarItem';
import { MdGrade } from 'react-icons/md';
import { useApp } from 'hooks/app';

const Financials: React.FC = () => {
  const { windowWidth } = useApp();

  const { checkPermission } = usePermissionRules();
  return (
    <>
      {checkPermission('financial') && (
        <>
          <SidebarItem
            icon={<MdGrade />}
            text="Financeiro"
            to={windowWidth < 600 ? '/financial/home' : '/financial/resume'}
          />
        </>
      )}
    </>
  );
};

export default Financials;
