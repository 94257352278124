import React from 'react';
import Done from '@mui/icons-material/Done';
import { Tooltip, IconButton } from '@mui/material';

interface BillingSettingActionsProps {
  handleSubmit(): void;
}

const BillSettingActions: React.FC<BillingSettingActionsProps> = ({ handleSubmit }) => {
  return (
    <Tooltip title="Salvar">
      <IconButton color="inherit" onClick={handleSubmit}>
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default BillSettingActions;
