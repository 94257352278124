import React, { Dispatch, SetStateAction } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useFinancialTicket } from './hooks/useFinancialTicket';

type FinancialTicketMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const FinancialTicketMenu: React.FC<FinancialTicketMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedTicket } = useFinancialTicket();

  function printTicket() {
    window.open(selectedTicket?.url);

    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={printTicket}>Imprimir boleto</MenuItem>
    </Menu>
  );
};

export default FinancialTicketMenu;
