import { Dispatch, SetStateAction, useState } from 'react';
import { Cfop } from 'types/cfop';
import * as yup from 'yup';

export type FiscalCodeValidation = {
  name?: string;
  code?: string;
  cst_cofins?: string;
  cst_pis?: string;
};

type UseFiscalCodeValidation = [
  FiscalCodeValidation,
  Dispatch<SetStateAction<FiscalCodeValidation>>,
  (fiscalCode: Cfop) => Promise<void>,
];

export function useFiscalCodeValidation(): UseFiscalCodeValidation {
  async function handleValidation(fiscalCode: Cfop) {
    const schema = yup.object().shape({
      cst_pis: yup.string().required('Campo obrigatório'),
      cst_cofins: yup.string().required('Campo obrigatório'),
      code: yup.string().required('O code é obrigatório'),
      name: yup.string().required('O nome é obrigatório'),
    });

    try {
      await schema.validate(fiscalCode);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<FiscalCodeValidation>({});
  return [validation, setValidation, handleValidation];
}
