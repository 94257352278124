import { WasteProductFormContent } from 'pages/material-residues/waste/types/waste';
import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export type WasteProductValidation = {
  id?: string;
  product?: string;
  value?: string;
  quantity?: string;
};

type UseWasteProductValidation = [
  WasteProductValidation,
  Dispatch<SetStateAction<WasteProductValidation>>,
  (product: WasteProductFormContent) => Promise<void>,
];

export function useWasteProductValidation(maxValue: number): UseWasteProductValidation {
  async function handleValidation(product: WasteProductFormContent) {
    const schema = yup.object().shape({
      quantity: yup
        .string()
        .required('obrigatório')
        .test('quantityValidation', 'a quantidade precisa ser maior que 0', value => {
          if (!value) return false;
          if (isNaN(parseInt(value))) return false;

          if (parseInt(value) <= 0) return false;

          return true;
        })
        .nullable(),
      value: yup
        .string()
        .required('O valor do produto é obrigatório')
        .test('maxValueValidation', 'O valor está acima do preço máximo permitido', value => {
          if (parseFloat(value) > maxValue) return false;

          return true;
        }),
      id: yup.string().required('O código do produto é obrigatório').nullable(),
      product: yup.string().required('O nome do produto é obrigatório'),
    });

    try {
      await schema.validate(product);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<WasteProductValidation>({});
  return [validation, setValidation, handleValidation];
}
