import Dialog from 'components/dialogs/Dialog';
import React from 'react';

interface InvoiceAppropriationProps {
  onExited(): void;
}

const InvoiceAppropriation: React.FC<InvoiceAppropriationProps> = ({ onExited }) => {
  return (
    <Dialog height="70vh" maxWidth="sm" onExited={onExited} title="Apropriação">
      InvoiceAppropriation
    </Dialog>
  );
};

export default InvoiceAppropriation;
