import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import SellerItemModule from './SellerItemModule';
import { AbcProductSeller } from 'types/abcProduct';

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

type SellerListModuleProps = {
  sellers: AbcProductSeller[];
};

const SellerListModule: React.FC<SellerListModuleProps> = ({ sellers }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {sellers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((seller, index) => (
        <SellerItemModule key={index + 1} seller={seller} />
      ))}
    </CustomList>
  );
};

export default SellerListModule;
