import React, { useState, useEffect } from 'react';
import TableContainer from 'components/table/TableContainer';
import PaginationProvider from 'hooks/pagination';
import { useFetchCourseStudents } from './hooks/useFetchCourseStudents';
import { CourseStudentProvider } from './hooks/useCourseStudents';
import { CourseStudentOnList } from './types/courseStudent';
import Appbar from 'components/appbar/Appbar';
import FilterActions from 'components/filter-box/FilterActions';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { styled } from '@mui/material';
import CourseStudentsHeaderButtons from './CourseStudentsHeaderButtons';
import CourseStudentsFilterBox from './CourseStudentsFilterBox';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { courseStudentsTableTemplate } from './constants/courseStudentsTableTemplate';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import ApiPagination from 'components/pagination/ApiPagination';
import CourseStudentListTable from './list/table/CourseStudentListTable';
import CourseStudentListModule from './list/module/CourseStudentListModule';
import useTableOrder from 'hooks/tableOrder';
import CourseStudentsMobileFilter from './CourseStudentsMobileFilter';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

export interface CourseStudentsQueryParams {
  rows: number;
  page: number;
  initial_date: Date;
  final_date: Date;
  term: string;
}

const CourseStudents: React.FC = () => {
  const { courseStudents, queryParams, setQueryParams, loading, total, fetch } = useFetchCourseStudents();
  const [selectedCourseStudent, setSelectedCourseStudent] = useState<CourseStudentOnList | null>(null);
  const [mobileFilter, setMobileFilter] = useState(false);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [filtered, setFiltered] = useState<CourseStudentOnList[]>([]);
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(courseStudents);
  }, [courseStudents]);

  function handleQueryParamsChange(index: keyof CourseStudentsQueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleSubmit() {
    fetch(queryParams);
  }

  return (
    <CourseStudentProvider value={{ selectedCourseStudent, setSelectedCourseStudent }}>
      {mobileFilter && (
        <CourseStudentsMobileFilter
          handleQueryParamsChange={handleQueryParamsChange}
          queryParams={queryParams}
          onExited={() => setMobileFilter(false)}
        />
      )}

      <Appbar
        title="Participantes"
        ActionsComponent={<FilterActions handleOpenDialog={() => setMobileFilter(true)} />}
      />

      <PageHeaderActions
        title="Participantes"
        description="Gestão dos participantes de cursos"
        ActionComponent={<CourseStudentsHeaderButtons />}
      />

      <CourseStudentsFilterBox
        queryParams={queryParams}
        handleQueryParamsChange={handleQueryParamsChange}
        setDisplayMode={setDisplayMode}
        displayMode={displayMode}
        handleSubmit={handleSubmit}
        loading={loading}
      />

      <TableContainer tableTemplate={courseStudentsTableTemplate}>
        <PaginationProvider>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhum participante para mostrar" />
          ) : (
            <Container>
              {displayMode === 'list' ? (
                <CourseStudentListTable courseStudents={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <CourseStudentListModule courseStudents={filtered} />
              )}
            </Container>
          )}

          <ApiPagination
            onChangePage={value => handleQueryParamsChange('page', value)}
            onChangeRowsPerPage={value => handleQueryParamsChange('rows', value)}
            count={total}
          />
        </PaginationProvider>
      </TableContainer>
    </CourseStudentProvider>
  );
};

export default CourseStudents;
