import { Typography } from '@mui/material';
import { useReseller } from 'pages/resellers/hooks/useReseller';
import React from 'react';
import OtherDocumentItem from './OtherDocumentItem';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  list: {
    width: '100%',
    marginBottom: 15,
    '& > li': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '15px',
      padding: 0,
    },
    '& > .header': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '15px',
      marginBottom: 15,
    },
  },
});

const OtherDocumentList: React.FC = () => {
  const { reseller } = useReseller();
  const classes = styles();

  return (
    <ul className={classes.list}>
      <div className="header">
        <Typography variant="subtitle2">Descrição</Typography>
        <Typography variant="subtitle2">Ações</Typography>
      </div>
      {reseller?.documents.map(document => (
        <OtherDocumentItem document={document} key={document.id} />
      ))}
    </ul>
  );
};

export default OtherDocumentList;
