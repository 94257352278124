import React, { Dispatch, SetStateAction, MouseEvent } from 'react';
import { Typography, ListItemButton, styled, IconButton } from '@mui/material';
import history from 'services/history';
import { RelatedProduct } from 'pages/related-product/types/relatedProduct';
import { MoreVert } from '@mui/icons-material';

const ListItemButtonStyled = styled(ListItemButton)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '80%',
    alignItems: 'center',
  },
  '& .project-id': {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  '& .description': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    lineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
});

type RelatedProductItemModuleProps = {
  relatedProduct: RelatedProduct;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const RelatedProductItemModule: React.FC<RelatedProductItemModuleProps> = ({ relatedProduct, setAnchorEl }) => {
  function handleClick() {
    history.push(`/compatibilities/${relatedProduct.id}`);
  }

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <ListItemButtonStyled onClick={handleClick}>
      <Typography variant="caption" color="primary" gutterBottom>
        Compatibilidade {relatedProduct.id}
      </Typography>
      <Typography>{relatedProduct.description}</Typography>
      <IconButton onClick={handleMoreClick}>
        <MoreVert />
      </IconButton>
    </ListItemButtonStyled>
  );
};

export default RelatedProductItemModule;
