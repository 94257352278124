import { TableTemplate } from 'types/tableTemplate';

export const productTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: '',
    originalId: 'actions',
    width: 70,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'cfop',
    description: 'CFOP',
    originalId: 'cfop',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'codigo_produto',
    description: 'Cód',
    originalId: 'codigo_produto',
    width: 70,
  },
  {
    id: 'produto',
    description: 'Descrição',
    originalId: 'produto',
    width: 400,
  },
  {
    id: 'quantidade',
    description: 'Qtd',
    originalId: 'quantidade',
    width: 70,
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'valor',
    dataType: 'number',
    width: 160,
  },
  {
    id: 'formattedTotal',
    description: 'Valor total',
    originalId: 'valor_subtotal',
    dataType: 'number',
    width: 160,
  },
];
