import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useProjects } from '../hook/useProjects';

type ProjectListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const ProjectListMenu: React.FC<ProjectListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedProject, setShownPrintDialog } = useProjects();

  function handleDeleteClick() {
    if (!selectedProject) {
      return;
    }

    setShownPrintDialog(true);
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDeleteClick}>Imprimir</MenuItem>
    </Menu>
  );
};

export default ProjectListMenu;
