import { Dispatch, createContext, useContext } from 'react';
import { PurchaseOrder } from 'types/purchaseOrder';
import { SecondPurchaseOrderValidation } from '../steps/second/validation/secondPurchaseOrderValidation';
import { PurchaseOrderValidation } from '../../validation/purchaseOrderValidation';
import { H2iCustomer } from 'types/h2iCustomer';
import { PurchaseOrderActions } from '../../reducer/types';
import { ThirdPurchaseOrderValidation } from '../steps/third/validation/thirdPurchaseOrderValidation';
// import { ThirdPurchaseOrderValidation } from '../steps/third/validation/thirdPurchaseOrderValidation';

type PurchaseOrderContextValue = {
  step: number;
  inputs: any;
  validation: PurchaseOrderValidation;
  secondValidation: SecondPurchaseOrderValidation;
  thirdValidation: ThirdPurchaseOrderValidation;
  order: PurchaseOrder;
  handleChange(index: keyof PurchaseOrder, value: any): void;
  handleNext(): void;
  disabled: boolean;
  handleBack(): void;
  handleCustomerSelect(customer: H2iCustomer | null): void;
  reseller: H2iCustomer | null;
  dispatch: Dispatch<PurchaseOrderActions>;
  handleClickInformPayment(): void;
};

const PurchaseOrderContext = createContext<PurchaseOrderContextValue>({} as PurchaseOrderContextValue);
export const PurchaseOrderProvider = PurchaseOrderContext.Provider;

export function usePurchaseOrder(): PurchaseOrderContextValue {
  const context = useContext(PurchaseOrderContext);
  return context;
}
