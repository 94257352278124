import { List } from '@mui/material';
import React from 'react';
import { Bill } from 'types/bill';
import BillRemittanceItem from './BillRemittanceItem';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 5,
  },
});

interface BillRemittanceListProps {
  bills: Bill[];
}

const BillRemittanceList: React.FC<BillRemittanceListProps> = ({ bills }) => {
  const classes = styles();

  return (
    <List className={classes.list}>
      {bills.map(bill => (
        <BillRemittanceItem bill={bill} key={bill.id} />
      ))}
    </List>
  );
};

export default BillRemittanceList;
