import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import GroupedSegmentItemModule from './GroupedSegmentItemModule';
import { GroupedSegment } from '../table/GroupedSegmentListTable';

type GroupedSegmentListModuleProps = {
  groupedSegments: GroupedSegment[];
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

const GroupedSegmentListModule: React.FC<GroupedSegmentListModuleProps> = ({ groupedSegments }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {groupedSegments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(segment => (
        <GroupedSegmentItemModule key={segment.segmento} segment={segment} />
      ))}
    </CustomList>
  );
};

export default GroupedSegmentListModule;
