import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';
import { NewInvoice } from '../reducer/types';

export type NewInvoiceValidation = {
  payday?: string;
  favoredId?: string;
  value?: string;
  invoiceDescription?: string;
};

type UseNewInvoiceValidation = [
  NewInvoiceValidation,
  Dispatch<SetStateAction<NewInvoiceValidation>>,
  (invoice: NewInvoice, type: 'P' | 'N' | 'M') => Promise<void>,
];

export function useNewInvoiceValidation(): UseNewInvoiceValidation {
  function getMessage(type: 'P' | 'N' | 'M') {
    if (type === 'N') {
      return 'o valor deve ser negativo';
    }

    if (type === 'P') {
      return 'o valor deve ser positivo';
    }

    return 'o valor é obrigatório';
  }
  async function handleValidation(invoice: NewInvoice, type: 'P' | 'N' | 'M') {
    const schema = yup.object().shape({
      payday: yup.date().typeError('Vencimento é obrigátio').required('Vencimento é obrigátio'),
      value: yup
        .number()
        .typeError('informe um número valido')
        .test('valueType', getMessage(type), value => {
          if (!value) {
            return false;
          }

          if (type === 'N' && value > 0) {
            return false;
          }

          if (type === 'P' && value < 0) {
            return false;
          }

          return true;
        }),
      invoiceDescription: yup.string().required('O título é obrigatório'),
      favoredId: yup.number().typeError('O favorecido é obrigatório').required('O favorecido é obrigatório'),
    });

    try {
      await schema.validate(invoice);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }
      throw err;
    }
  }

  const [validation, setValidation] = useState<NewInvoiceValidation>({});
  return [validation, setValidation, handleValidation];
}
