import { ListItem, Typography } from '@mui/material';
import React from 'react';
import { CreditLimitRequest } from 'types/creditLimitRequest';
import { DocumentHistoryIndexes } from './DocumentHistories';

interface DocumentHistoryItemProps {
  creditLimitRequest: CreditLimitRequest;
  index: DocumentHistoryIndexes;
}

const DocumentHistoryItem: React.FC<DocumentHistoryItemProps> = ({ creditLimitRequest, index }) => {
  return (
    <ListItem disableGutters>
      <a target="_blank" rel="noopener noreferrer" href={creditLimitRequest[index]?.document_url}>
        {creditLimitRequest[index]?.original_filename}
      </a>
      <Typography>{creditLimitRequest[index]?.formattedCreatedAt}</Typography>
    </ListItem>
  );
};

export default DocumentHistoryItem;
