import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, Typography, ListItemButton, styled } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { WasteOnList } from 'pages/material-residues/waste/types/waste';
import { useWastes } from 'pages/material-residues/waste/hooks/useWastes';
import history from 'services/history';

const ListItemButtonStyled = styled(ListItemButton)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  '& .data': {
    display: 'grid',
    gridTemplateColumns: '90px 1fr',
  },
  '& .project-id': {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  '& .description': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    lineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
});

const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  bottom: 5,
  right: 5,
});

type WasteItemModuleProps = {
  waste: WasteOnList;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const WasteItemModule: React.FC<WasteItemModuleProps> = ({ waste, setAnchorEl }) => {
  const { setSelectedWaste } = useWastes();

  function handleClick() {
    history.push(`/waste/${waste.id}`);
  }

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    setSelectedWaste(waste);
  }

  return (
    <ListItemButtonStyled onClick={handleClick}>
      <div className="data">
        <Typography style={{ gridColumn: '1 / 3' }} color="primary" variant="caption" gutterBottom>
          REEE #{waste.id}
        </Typography>

        <Typography variant="body2" fontSize={12}>
          Nome:
        </Typography>
        <Typography variant="body2" fontSize={12}>
          {waste.nome}
        </Typography>

        <Typography variant="body2" fontSize={12}>
          CNPJ/CPF:
        </Typography>
        <Typography variant="body2" fontSize={12}>
          {waste.cnpj_cpf}
        </Typography>

        <Typography variant="body2" fontSize={12}>
          Filial:
        </Typography>
        <Typography variant="body2" fontSize={12}>
          {waste.filial}
        </Typography>

        <Typography variant="body2" fontSize={12}>
          Total:
        </Typography>
        <Typography fontWeight={600} variant="body2" fontSize={12}>
          {waste.formattedTotal}
        </Typography>
      </div>

      <StyledIconButton onClick={handleMoreClick}>
        <MoreVert />
      </StyledIconButton>
    </ListItemButtonStyled>
  );
};

export default WasteItemModule;
