import React from 'react';
import { Person } from '@mui/icons-material';
import { ListItemButton, styled, Typography } from '@mui/material';
import { User } from 'types/user';

const ListItemStyled = styled(ListItemButton)({
  alignItems: 'center',
  gap: 10,
  justifyContent: 'flex-start',
  '& svg': {
    color: '#ccc',
  },
});

interface UserAutoCompleteItemProps {
  user: User;
  selectedUsers: User[];
  handleSelect(user: User): void;
}

const UserAutoCompleteItem: React.FC<UserAutoCompleteItemProps> = ({ user, selectedUsers, handleSelect }) => {
  return (
    <ListItemStyled disabled={selectedUsers.map(user => user.id).includes(user.id)} onClick={() => handleSelect(user)}>
      <Person />
      <div>
        <Typography>{user.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {user.email}
        </Typography>
      </div>
    </ListItemStyled>
  );
};

export default UserAutoCompleteItem;
