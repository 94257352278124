import { TableTemplate } from 'types/tableTemplate';

export const SellersCommissionTableTemplate: TableTemplate[] = [
  {
    id: 'categoria',
    description: 'Categoria',
    originalId: 'categoria',
    width: 300,
  },
  {
    id: 'pedido_por',
    description: 'Pedido por',
    originalId: 'pedido_por',
    width: 300,
  },
  {
    id: 'vendedor',
    description: 'Vendedor',
    originalId: 'vendedor',
    width: 180,
  },
  {
    id: 'formattedCost',
    description: 'Custo',
    originalId: 'custo',
    width: 200,
    dataType: 'number',
  },
  {
    id: 'formattedPrice',
    description: 'Valor unitário',
    originalId: 'vl_unitário',
    width: 200,
    dataType: 'number',
  },
  {
    id: 'formattedTotal',
    description: 'Valor total',
    originalId: 'vl_total',
    width: 200,
    dataType: 'number',
  },
  {
    id: 'formattedMc',
    description: 'M.C',
    originalId: 'mc',
    width: 200,
    dataType: 'number',
  },
  {
    id: 'formattedSpiff',
    description: 'Spiff',
    originalId: 'spiff',
    dataType: 'number',
    width: 200,
  },
];
