import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import { Typography, styled } from '@mui/material';
import ExcelButtonDownload from 'components/excel-button-download/ExcelButtonDownload';
import { download } from 'helpers/download';
import { api } from 'services/api';
import { uuidv4 } from 'helpers/uuidv4';
import { InvoiceSummary } from 'types/summary/invoiceSummary';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: 30,
});

interface FinancialSummaryExportProps {
  data: InvoiceSummary[];
  onExited(): void;
}

const FinancialSummaryExport: React.FC<FinancialSummaryExportProps> = ({ data, onExited }) => {
  async function handleDownload() {
    try {
      const response = await api.post('/excel-exports', data, { responseType: 'blob' });

      download(response.data, `resumo-financeiro-${uuidv4()}`);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Dialog onExited={onExited} title="Export para Excel" maxWidth="sm" height="60vh">
      <Container>
        <Typography>Sua planilha está pronta.</Typography>
        <ExcelButtonDownload handleDownload={handleDownload} />
      </Container>
    </Dialog>
  );
};

export default FinancialSummaryExport;
