import { useCallback, useState, FormEvent } from 'react';
import { ptBR } from 'date-fns/locale';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/numberFormat';
import { format } from 'date-fns';
import history from 'services/history';
import { H2iOrder } from 'types/h2iOrder';
import { useApp } from 'hooks/app';
import { DavReviewFilterProps } from '../DavReviewPage';

export function useFetchDavReview(filter: DavReviewFilterProps) {
  const { h2iApi } = useApp();
  const [orders, setOrders] = useState<H2iOrder[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (!h2iApi) return;

      const formattedInitialDate = format(filter.initial_date, 'P', { locale: ptBR });
      const formattedFinalDate = format(filter.final_date, 'P', { locale: ptBR });

      setLoading(true);
      h2iApi
        .get('/api/getdav', {
          params: {
            id: filter.davNumber || '',
            id_filial: filter.branch_id || '',
            cliente: filter.davName.toUpperCase() || '',
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
          },
        })
        .then(response => {
          if (response.data.RESULT) {
            setOrders([]);
            return;
          }

          if (filter.davNumber) {
            history.push(`/review/dav/${response.data[0].id}/${response.data[0].id_filial}`);
            return;
          }

          const _orders: H2iOrder[] = response.data;

          setOrders(
            _orders
              .map(order => {
                const _date = order.Data.split('/');
                const parseDate = new Date(parseInt(_date[2]), parseInt(_date[1]) - 1, parseInt(_date[0]));
                order.createdAt = parseDate;
                order.formattedCreatedAt = format(parseDate, 'PP', { locale: ptBR });
                order.formattedValorFrete = moneyFormat(order.valor_Frete);
                order.formattedValorTotal = moneyFormat(order.valor_total);
                order.formattedValorTotalProdutos = moneyFormat(order.valor_total_prod);
                order.modalidade_entrega = order.modalidade_entrega.toLowerCase();
                order.formattedPeso = numberFormat(
                  order.itens.reduce((sum, item) => sum + parseFloat(item.peso.replace(/,/g, '.')), 0).toString(),
                );
                order.formattedPesoBruto = numberFormat(
                  order.itens.reduce((sum, item) => sum + parseFloat(item.pesobruto.replace(/,/g, '.')), 0).toString(),
                );
                order.formattedValorDescontoGerente = moneyFormat(
                  order.itens.reduce((sum, item) => sum + item.valor_desc_gerente, 0),
                );
                order.formattedValorDescontoVendedor = moneyFormat(
                  order.itens.reduce((sum, item) => sum + item.valor_desc_vendedor, 0),
                );
                order.formattedMc = percentFormat(
                  (order.itens.reduce((sum, item) => sum + item.mc, 0) / order.valor_total) * 100,
                );
                order.mc = (order.itens.reduce((sum, item) => sum + item.mc, 0) / order.valor_total) * 100;
                order.formattedSpiff = moneyFormat(order.itens.reduce((sum, item) => sum + item.spiff, 0));

                return order;
              })
              .sort((a, b) => {
                return a.id < b.id ? 1 : a.id > b.id ? -1 : 0;
              }),
          );
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi, filter],
  );

  return {
    handleSearch,
    loading,
    orders,
  };
}
