import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Dialog, { DialogConsumer } from 'components/dialogs/Dialog';
import InsideSaving from 'components/loading/InsideSaving';
import { useApp } from 'hooks/app';
import { useAgents } from '../hooks/useAgents';
import AgentsApprovalData from './AgensApproveData';
import AgentsRejectConfirm from '../AgentsRejectConfirm';
import { Agent } from 'types/agent';
import { AgentApprovalProvider } from '../hooks/useAgentApproval';
import { Document } from 'types/document';
import { useAgentApprovalValidation } from './validation/useAgentApprovalValidation';
import AgentsApprovalActions from './AgentsApprovalActions';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-around',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    borderTop: '1px solid #eee',
    backgroundColor: '#fff',
    height: 65,
    alignItems: 'center',
  },
  agentsData: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gap: '20px',
  },
});

interface AgentsApprovalProps {
  onExited(): void;
  selectedAgent: Agent;
}

const AgentsApproval: React.FC<AgentsApprovalProps> = ({ onExited, selectedAgent }) => {
  const classes = styles();
  const { handleRemoveFromList } = useAgents();
  const app = useApp();
  const [saving, setSaving] = useState(false);
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [rejectMessage, setRejectMessage] = useState('');
  const [agent, setAgent] = useState<Agent>(JSON.parse(JSON.stringify(selectedAgent)));
  const { h2iApi } = useApp();
  const [document, setDocument] = useState<Document | null>(null);
  const [letter, setLetter] = useState<Document | null>(null);
  const [validation, setValidation, validate] = useAgentApprovalValidation();

  useEffect(() => {
    const _letter = agent.documentos.find(doc => doc.tipo === 'C');

    if (_letter) {
      const newLetter: Document = {
        created_at: '',
        document_url: _letter.url,
        extension: '',
        filename: '',
        id: 0,
        original_filename: '',
        size: 0,
        formattedCreatedAt: '',
        formattedSize: '',
      };
      setLetter(newLetter);
    }

    const _document = agent.documentos.find(doc => doc.tipo === 'D');

    if (_document) {
      const newDocument: Document = {
        created_at: '',
        document_url: _document.url,
        extension: '',
        filename: '',
        id: 0,
        original_filename: '',
        size: 0,
        formattedCreatedAt: '',
        formattedSize: '',
      };
      setDocument(newDocument);
    }
  }, [agent.documentos]);

  useEffect(() => {
    if (!h2iApi) return;

    if (!letter?.size) return;

    const data = {
      cpf_preposto: agent?.cpf,
      link: letter?.document_url,
      tipo: 'C',
      id_cliente: agent?.id_cliente,
    };

    h2iApi.post('/api/saveprepostodocumento', data);
  }, [letter, h2iApi]); //eslint-disable-line

  useEffect(() => {
    if (!h2iApi) return;

    if (!document?.size) return;

    const data = {
      cpf_preposto: agent?.cpf,
      link: document?.document_url,
      tipo: 'd',
      id_cliente: agent?.id_cliente,
    };

    h2iApi.post('/api/saveprepostodocumento', data);
  }, [document, h2iApi]); //eslint-disable-line

  function handleChange(index: keyof Agent, value: any) {
    setAgent(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleAccept(handleCloseDialog: () => void) {
    if (!app.h2iApi) return;

    if (!letter || !document) return;

    setSaving(true);

    const data = {
      aprovado: 'S',
      id_filial: selectedAgent.id_filial,
      id: selectedAgent.id,
    };

    app.h2iApi
      .post('/api/AtualizaPrepostoSituacao', data)
      .then(() => {
        setSaving(false);
        handleRemoveFromList(selectedAgent.id);
        handleCloseDialog();
      })
      .catch(err => {
        console.error(err);
        setSaving(false);
      });
  }

  function handleValidation() {
    setValidation({});

    validate(agent)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    if (!h2iApi) return;

    setSaving(true);

    h2iApi
      .post('/api/updatepreposto', agent)
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleDecline(handleCloseDialog: () => void) {
    if (!app.h2iApi || !selectedAgent) return;

    setSaving(true);

    const data = {
      aprovado: 'N',
      id_filial: selectedAgent.id_filial,
      id: selectedAgent.id,
    };

    app.h2iApi
      .post('/api/AtualizaPrepostoSituacao', data)
      .then(() => {
        handleRemoveFromList(selectedAgent.id);
        handleCloseDialog();
        setSaving(false);
      })
      .catch(err => {
        console.error(err);
        setSaving(false);
      });
  }

  return (
    <AgentApprovalProvider value={{ agent, handleChange, letter, document, setLetter, setDocument, validation }}>
      <Dialog
        onExited={onExited}
        title="Aprovar proposto"
        maxWidth="sm"
        ComponentActions={<AgentsApprovalActions handleValidation={handleValidation} />}
      >
        {dialogConfirm && (
          <AgentsRejectConfirm
            rejectMessage={rejectMessage}
            setRejectMessage={setRejectMessage}
            handleDecline={handleDecline}
            onExited={() => setDialogConfirm(false)}
          />
        )}
        {saving && <InsideSaving />}
        <DialogConsumer>
          {context => (
            <div className={classes.container}>
              <AgentsApprovalData />
              <div className={classes.actions}>
                <Button color="primary" variant="text" onClick={() => setDialogConfirm(true)}>
                  Rejeitar
                </Button>
                <Button
                  disabled={!letter || !document}
                  onClick={() => handleAccept(context.handleClose)}
                  color="primary"
                  variant="contained"
                >
                  Aprovar
                </Button>
              </div>
            </div>
          )}
        </DialogConsumer>
      </Dialog>
    </AgentApprovalProvider>
  );
};

export default AgentsApproval;
