import React from 'react';
import DocumentUpload from 'components/document-upload/DocumentUpload';
import { styled } from '@mui/material';
import { useProject } from '../../hook/useProject';
import { Document } from 'types/document';
import { addProjectDocument, removeProjectDocument } from '../../reducer/action';

const Container = styled('div')({
  display: 'flex',
  marginTop: 20,
  gap: 10,
  flexWrap: 'wrap',
});

const DocumentItem = styled('div')(props => ({
  maxWidth: 295,
  flexShrink: 0,
  width: '100%',
  [props.theme.breakpoints.down('sm')]: {
    maxWidth: 185,
  },
}));

const ProjectDocuments: React.FC = () => {
  const { project, dispatch } = useProject();

  function handleAddDocument(document: Document | null) {
    if (!document) {
      return;
    }

    dispatch(addProjectDocument(document));
  }

  function handleRemoveDocument(documentId: number) {
    dispatch(removeProjectDocument(documentId));
  }

  return (
    <Container>
      {project.documents.map(document => (
        <DocumentItem key={document.id}>
          <DocumentUpload
            document={document}
            label="Projeto"
            handleRemoveImage={() => handleRemoveDocument(document.id)}
            handleSetImage={doc => handleAddDocument(doc)}
          />
        </DocumentItem>
      ))}

      <DocumentItem>
        <DocumentUpload
          document={null}
          label="Projeto"
          handleRemoveImage={() => handleRemoveDocument(0)}
          handleSetImage={doc => handleAddDocument(doc)}
        />
      </DocumentItem>
    </Container>
  );
};

export default ProjectDocuments;
