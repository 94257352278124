import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Manufacturer } from 'types/manufacturer';
import { PriceProtectionNew } from 'types/priceProtection';
import { ProductList } from 'types/product';
import { PriceProtectionValidation } from '../validation/priceProtectionValidation';

export interface PriceProtectionContextValue {
  selectedProduct: ProductList | null;
  setSelectedProduct: Dispatch<SetStateAction<ProductList | null>>;
  onSelect(manufacturer: Manufacturer | null): void;
  manufacturers: Manufacturer[];
  selectedManufacturer: Manufacturer | null;
  setSelectedManufacturer: Dispatch<SetStateAction<Manufacturer | null>>;
  step: number;
  priceData: PriceProtectionNew;
  handleChange(index: keyof PriceProtectionNew, value: any): void;
  validation: PriceProtectionValidation;
  handleSubmit(): void;
  handleBack(): void;
  loading: boolean;
}

const PriceProtectionContext = createContext<PriceProtectionContextValue>({} as PriceProtectionContextValue);
export const PriceProtectionProvider = PriceProtectionContext.Provider;
export const PriceProtectionConsumer = PriceProtectionContext.Consumer;

export function usePriceProtection(): PriceProtectionContextValue {
  const context = useContext(PriceProtectionContext);
  return context;
}
