import React from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import KeyboardDatePicker from '../../../components/pickers/DesktopDatePicker';
import { useBranches } from './hooks/useSaleActivity';

type SaleActivityReportFilterProps = {
  onExited(): void;
};

const SaleActivityReportFilter: React.FC<SaleActivityReportFilterProps> = ({ onExited }) => {
  const { filter, handleChangeFilter } = useBranches();

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <DialogInputConsumer>
        {({ handleClose }) => (
          <>
            <KeyboardDatePicker
              label="Data inicial"
              value={filter.initialDate}
              onChange={date => handleChangeFilter('initialDate', date)}
            />
            <KeyboardDatePicker
              label="Data Final"
              value={filter.finalDate}
              onChange={date => handleChangeFilter('finalDate', date)}
            />
          </>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default SaleActivityReportFilter;
