import React from 'react';
import { Typography, styled } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { SalesItem } from 'types/reports/sales';
import SalesItemTable from './SalesItemTable';
import { salesItemsTableTemplate } from '../salesItemsTableTemplate';
import TableContainer from 'components/table/TableContainer';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type SalesListTableProps = {
  sales: SalesItem[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const SalesListTable: React.FC<SalesListTableProps> = ({ sales, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <TableContainer tableTemplate={salesItemsTableTemplate}>
      <TableContent>
        <TableHeader>
          {salesItemsTableTemplate.map(item => (
            <HeaderItem
              className={item.dataType === 'number' ? 'numericData' : ''}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody>
          {sales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(sale => (
            <TableRow key={sale.codigo}>
              <SalesItemTable sale={sale} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default SalesListTable;
