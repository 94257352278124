import React from 'react';
import { Typography } from '@mui/material';
import { revenuesTableTemplate } from '../../revenuesTableTemplate';
import { Revenue } from 'types/revenue';

type RevenueItemTableProps = {
  revenue: Revenue;
};

const RevenueItemTable: React.FC<RevenueItemTableProps> = ({ revenue }) => {
  return (
    <>
      {revenuesTableTemplate.map(item => (
        <div key={item.id}>
          <Typography variant="body2">{revenue[item.id]}</Typography>
        </div>
      ))}
    </>
  );
};

export default RevenueItemTable;
