import React, { useEffect, useState } from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import NoData from 'components/no-data/NoData';
import { ShoppingSuggestion } from 'types/shoppingSuggestion';
import { usePagination } from 'hooks/pagination';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  background: '#fff',
  overflow: 'auto',
  maxHeight: 480,
});

const Row = styled('li')(({ theme }) => ({
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 6,
  gridTemplateColumns: '100px 220px 80px 1fr 75px 75px 75px 75px 80px 90px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 70px 1fr 70px 70px 70px 70px 70px 80px',
  },
  '&.header': {
    '& > span': {
      cursor: 'pointer',
      gap: 5,
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      '&.center': {
        justifyContent: 'center',
      },
    },
  },
  '& > h6': {
    gap: 5,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    '&.center': {
      justifyContent: 'center',
    },
  },
  '& .product-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
  rowGap: 10,
});

interface TopShoppingSuggestionProps {
  data: ShoppingSuggestion[];
}

const TopShoppingSuggestion: React.FC<TopShoppingSuggestionProps> = ({ data }) => {
  const [orderedIndex, sort] = useTableOrder();
  const { rowsPerPage, page } = usePagination();
  const [filtered, setFiltered] = useState<ShoppingSuggestion[]>([]);

  useEffect(() => {
    setFiltered(data);
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <Container>
      {filtered.length > 0 ? (
        <List>
          <Row className="header">
            <span>
              <Typography onClick={() => handleSort('filial')} variant="subtitle2">
                Filial
              </Typography>
              {orderedIndex.index === 'filial' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('subgrupo')} variant="subtitle2">
                Subgrupo
              </Typography>
              {orderedIndex.index === 'subgrupo' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('id_produto')} variant="subtitle2">
                Cod Prod
              </Typography>
              {orderedIndex.index === 'id_produto' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('produto')} variant="subtitle2">
                Produto
              </Typography>
              {orderedIndex.index === 'produto' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="center">
              <Typography onClick={() => handleSort('estoque')} variant="subtitle2">
                Estoque
              </Typography>
              {orderedIndex.index === 'estoque' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="center">
              <Typography onClick={() => handleSort('forecast')} variant="subtitle2">
                Forecast
              </Typography>
              {orderedIndex.index === 'forecast' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('pedidos')} variant="subtitle2">
                Pedidos
              </Typography>
              {orderedIndex.index === 'pedidos' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('sugestao')} variant="subtitle2">
                Sugestão
              </Typography>
              {orderedIndex.index === 'sugestao' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('formattedArrivalForecast')} variant="subtitle2">
                Previsão
              </Typography>
              {orderedIndex.index === 'formattedArrivalForecast' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="center">
              <Typography onClick={() => handleSort('dias_estoque')} variant="subtitle2">
                Dias Est
              </Typography>
              {orderedIndex.index === 'dias_estoque' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
          </Row>
          {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, index) => (
            <Row key={index}>
              <Tooltip title={product.filial}>
                <Typography className="product-name" variant="subtitle2">
                  {product.filial}
                </Typography>
              </Tooltip>
              <Tooltip title={product.subgrupo}>
                <Typography className="product-name" variant="subtitle2">
                  {product.subgrupo}
                </Typography>
              </Tooltip>
              <Typography variant="subtitle2">{product.id_produto}</Typography>
              <Tooltip title={product.produto}>
                <Typography className="product-name" variant="subtitle2">
                  {product.produto}
                </Typography>
              </Tooltip>
              <Typography variant="subtitle2" className="center">
                {product.estoque}
              </Typography>
              <Typography variant="subtitle2" className="center">
                {product.forecast}
              </Typography>
              <Typography color="primary" variant="subtitle2" className="center">
                {product.pedidos}
              </Typography>
              <Typography color="error" variant="subtitle2" className="center">
                {product.sugestao}
              </Typography>
              <Typography color="primary" variant="subtitle2" className="center">
                {product.previsao_chegada}
              </Typography>
              <Typography variant="subtitle2" className="center">
                {product.dias_estoque}
              </Typography>
            </Row>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
    </Container>
  );
};

export default TopShoppingSuggestion;
