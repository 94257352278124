import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { AbcProductSubgroup } from 'types/abcProduct';
import SubgroupItemModule from './SubgroupItemModule';

type SubgroupListModuleProps = {
  subgroups: AbcProductSubgroup[];
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

const SubgroupListModule: React.FC<SubgroupListModuleProps> = ({ subgroups }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {subgroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(subgroup => (
        <SubgroupItemModule key={subgroup.cod} subgroup={subgroup} />
      ))}
    </CustomList>
  );
};

export default SubgroupListModule;
