import React, { useState } from 'react';
import { Project } from 'types/project';
import ProjectItemTable from './ProjectItemTable';
import { usePagination } from 'hooks/pagination';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { Typography, styled } from '@mui/material';
import { useProjects } from 'pages/projects/hook/useProjects';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import history from 'services/history';
import ProjectListMenu from '../ProjectListMenu';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
  '&.headerItemNumeric': {
    justifyContent: 'flex-end',
  },
});
type ProjectListTableProps = {
  projects: Project[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const ProjectListTable: React.FC<ProjectListTableProps> = ({ projects, handleSort, orderedIndex }) => {
  const { tableTemplate } = useTable();
  const { setSelectedProject } = useProjects();
  const { rowsPerPage, page } = usePagination();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(project: Project) {
    setSelectedProject(project);
    history.push(`/projects/${project.id}`);
  }

  return (
    <>
      <ProjectListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <HeaderItem
              className={item.dataType === 'number' ? 'headerItemNumeric' : ''}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody>
          {projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(project => (
            <TableRow key={project.id} onClick={() => handleClick(project)}>
              <ProjectItemTable project={project} setAnchorEl={setAnchorEl} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default ProjectListTable;
