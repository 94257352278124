import React from 'react';
import { Typography, useTheme, Button } from '@mui/material';
import { VscError } from 'react-icons/vsc';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 500,
    width: '100%',
  },
});

type RegisterErrorProps = {
  errorMessage: string;
  handleRestart(): void;
};

const RegisterError: React.FC<RegisterErrorProps> = ({ errorMessage, handleRestart }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <VscError size={72} color={theme.palette.error.main} />
        <Typography>Não foi possível concluir o registro.</Typography>
        <Typography align="center" gutterBottom variant="h5">
          {errorMessage}.
        </Typography>
        <Button color="secondary" variant="contained" onClick={handleRestart}>
          tentar novamente
        </Button>
      </div>
    </div>
  );
};

export default RegisterError;
