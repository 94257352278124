import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';

import { ItemsSold } from 'types/reports/itemsSold';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
});

type ItemsSoldItemTableProps = {
  itemSold: ItemsSold;
};

const ItemsSoldItemTable: React.FC<ItemsSoldItemTableProps> = ({ itemSold }) => {
  const { tableTemplate } = useTable();
  const classes = styles();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography variant="body2">{itemSold[item.id as keyof ItemsSold]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default ItemsSoldItemTable;
