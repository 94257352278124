import { useApp } from 'hooks/app';
import useTableOrder, { OrderIndexData } from 'hooks/tableOrder';
import { Dispatch, SetStateAction, useCallback, useState, FormEvent, useEffect } from 'react';
import { getCancelTokenSource } from 'services/api';
import { H2iBuyer } from 'types/h2iBuyer';

type UseFetchBuyers = [
  (buyerId: number) => Promise<void>,
  (index: string) => void,
  (buyer: H2iBuyer) => H2iBuyer,
  boolean,
  boolean,
  OrderIndexData,
  Dispatch<SetStateAction<H2iBuyer[]>>,
  Dispatch<SetStateAction<string>>,
  string,
  H2iBuyer[],
  () => void,
];

let timer: NodeJS.Timeout;

export function useFetchBuyers(): UseFetchBuyers {
  const [saving, setSaving] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { h2iApi } = useApp();
  const [buyers, setBuyers] = useState<H2iBuyer[]>([]);
  const [filteredBuyers, setFilteredBuyers] = useState<H2iBuyer[]>([]);

  useEffect(() => {
    setFilteredBuyers(buyers);
  }, [buyers]);

  const fetchBuyer = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (!h2iApi) return;
      const source = getCancelTokenSource();
      let request = true;
      if (searchText.length > 0 && searchText.length < 3) return;

      clearTimeout(timer);

      timer = setTimeout(() => {
        setLoading(true);

        h2iApi
          .get(`/api/getcompradores?filtro=${searchText}`, {
            cancelToken: source.token,
          })
          .then(response => {
            if (request) {
              if (response.data.RESULT) {
                setBuyers([]);
                return;
              }
              const _buyers = response.data.compradores.map(buyer => {
                return buyer;
              });

              setBuyers(_buyers);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            if (request) setLoading(false);
          });
      }, 500);

      return () => {
        if (request) request = false;
        source.cancel();
      };
    },
    [h2iApi, searchText],
  );

  async function handleBuyerDelete(buyerId: number) {
    function deleteBuyer(): Promise<void> {
      return new Promise(resolve => {
        setTimeout(() => {
          console.log('excluindo...');
          resolve();
        }, 2000);
      });
    }

    try {
      setSaving(true);
      await deleteBuyer();
      setBuyers(state => state.filter(buyer => buyer.id !== buyerId));
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false);
    }
  }

  function handleSort(index: string) {
    const filtered = sort(index, filteredBuyers);
    setFilteredBuyers(filtered);
  }

  function handleFormatBuyer(buyer: H2iBuyer): H2iBuyer {
    console.log(buyer);
    return buyer;
  }

  return [
    handleBuyerDelete,
    handleSort,
    handleFormatBuyer,
    loading,
    saving,
    orderedIndex,
    setBuyers,
    setSearchText,
    searchText,
    filteredBuyers,
    fetchBuyer,
  ];
}
