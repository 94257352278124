import React, { useState } from 'react';
import { Button, CircularProgress, IconButton, MenuItem, TextField, Typography, styled } from '@mui/material';
import { useProject } from '../../hook/useProject';
import { projectStatusMapping } from '../../reducer/reducer';
import { ArrowBack } from '@mui/icons-material';
import { ProjectStatusOptions } from 'types/project';

const Container = styled('div')({
  marginTop: 20,
  display: 'flex',
  flexDirection: 'column',
});

const Buttons = styled('div')({
  display: 'flex',
  gap: 10,
  justifyContent: 'end',
  marginTop: 10,
  '& button': {
    width: 180,
  },
});

const CurrentStatus = styled('div')({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  border: '1px solid #ccc',
  padding: 15,
  backgroundColor: '#eee',
  justifyContent: 'space-between',
  '& > .current-status': {
    display: 'flex',
    gap: 10,
  },
});

interface ProjectStatusProps {
  handleCancelStatus(): Promise<void>;
  handleUpdateStatus(status: ProjectStatusOptions): Promise<void>;
}

const ProjectStatus: React.FC<ProjectStatusProps> = ({ handleCancelStatus, handleUpdateStatus }) => {
  const { project, handleChange } = useProject();
  const [isEditing, setIsEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<ProjectStatusOptions>(
    project.current_status === 'cancelled' ? 'accepted' : project.current_status,
  );

  function handleConfirmStatus() {
    setSaving(true);

    handleUpdateStatus(currentStatus)
      .then(() => {
        setIsEditing(false);
        handleChange('current_status', currentStatus);
        handleChange('formattedCurrentStatus', projectStatusMapping[currentStatus]);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleCancel() {
    setCancelling(true);

    handleCancelStatus()
      .then(() => {
        setIsEditing(false);
        handleChange('current_status', 'cancelled');
        handleChange('formattedCurrentStatus', projectStatusMapping.cancelled);
      })
      .finally(() => {
        setCancelling(false);
      });
  }

  return (
    <Container>
      {!isEditing ? (
        <CurrentStatus>
          <div className="current-status">
            <Typography>
              <strong>Status:</strong>
            </Typography>
            <Typography>{project.formattedCurrentStatus}</Typography>
          </div>

          <Button size="small" color="primary" variant="contained" onClick={() => setIsEditing(true)}>
            alterar
          </Button>
        </CurrentStatus>
      ) : (
        <div>
          <IconButton onClick={() => setIsEditing(false)}>
            <ArrowBack />
          </IconButton>

          <TextField
            select
            label="Novo status para o projeto"
            fullWidth
            margin="normal"
            value={currentStatus}
            onChange={e => setCurrentStatus(e.target.value as ProjectStatusOptions)}
          >
            <MenuItem value="accepted">Aceito</MenuItem>
            <MenuItem value="paused">Projeto pausado</MenuItem>
            <MenuItem value="waiting_visit">Aguardando visita</MenuItem>
            <MenuItem value="material_arrival">Previsão de chegada do material</MenuItem>
            <MenuItem value="waiting_quotation">Aguardando cotação</MenuItem>
            <MenuItem value="quotation_sent">Cotação enviada</MenuItem>
          </TextField>

          <Buttons>
            <Button disabled={cancelling} size="small" variant="contained" color="error" onClick={handleCancel}>
              {cancelling ? <CircularProgress color="primary" size={20} /> : 'cancelar projeto'}
            </Button>
            <Button disabled={saving} size="small" variant="contained" color="primary" onClick={handleConfirmStatus}>
              {saving ? <CircularProgress color="primary" size={20} /> : 'confirmar status'}
            </Button>
          </Buttons>
        </div>
      )}
    </Container>
  );
};

export default ProjectStatus;
