import { TableTemplate } from 'types/tableTemplate';

export const productTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: '',
    originalId: 'actions',
    width: 70,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'id_produto',
    description: 'ID',
    originalId: 'id_produto',
    width: 50,
  },
  {
    id: 'produto',
    description: 'Nome',
    originalId: 'produto',
    width: 400,
  },
  {
    id: 'qtd',
    description: 'Qtd',
    originalId: 'qtd',
    width: 70,
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'valor',
    width: 90,
  },
  {
    id: 'formattedTotal',
    description: 'Valor total',
    originalId: 'total',
    width: 120,
  },
  {
    id: 'formattedSerialNumbers',
    description: 'Nº Série',
    originalId: 'formattedSerialNumbers',
    width: 140,
  },
];
