import { Settings } from 'types/settings';

export const SETTINGS_CHANGE = 'SETTINGS_CHANGE';
export const SET_SETTINGS = 'SET_SETTINGS';

export type SettingsIndexes = keyof Settings;

interface SettingsChangeAction {
  type: typeof SETTINGS_CHANGE;
  index: SettingsIndexes;
  value: any;
}

interface SetSettingsAction {
  type: typeof SET_SETTINGS;
  settings: Settings;
}

export type SettingsActionsType = SettingsChangeAction | SetSettingsAction;
