import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import CustomerItemModule from './CustomerItemModule';
import { AbcProductCustomer } from 'types/abcProduct';

type CustomerListModuleProps = {
  customers: AbcProductCustomer[];
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

const CustomerListModule: React.FC<CustomerListModuleProps> = ({ customers }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((customer, index) => (
        <CustomerItemModule key={index + 1} customer={customer} />
      ))}
    </CustomList>
  );
};

export default CustomerListModule;
