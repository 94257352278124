import React, { Dispatch, SetStateAction, useState } from 'react';
import { useApp } from 'hooks/app';
import { StoreProduct } from './SelectedBranch';
import { Menu, MenuItem } from '@mui/material';
import { useAbcProducts } from '../../hooks/useAbcProducts';
import Loading from 'components/loading/Loading';

type SelectedBranchListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  sale: StoreProduct;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

export interface SaleDue {
  numero_venda: number;
  vencimento: string;
  valor: number;
}

export interface SalePayment {
  forma: string;
  id_forma: number;
  numero_venda: number;
  parcelas: number;
  valor: number;
  vencimentos: SaleDue[];
}

export interface SaleItem {
  id_produto: number;
  numero_venda: number;
  produto: string;
  qtd: string;
  peso: number;
  total: number;
  unitario: number;
}

export interface Sale {
  bandeira: string;
  cep: string;
  cidade: string;
  cliente: string;
  cnpj_cliente: string;
  cnpj_filial: string;
  data: string;
  email: string;
  endereco: string;
  filial: string;
  id_cliente: string;
  itens: SaleItem[];
  nota_fiscal: string;
  numero_dav: number;
  numero_venda: number;
  pagamentos: SalePayment[];
  pedido_por: string;
  status: string;
  total: number;
  uf: string;
  vendedor: string;
}

const SelectedBranchListMenu: React.FC<SelectedBranchListMenuProps> = ({ anchorEl, setAnchorEl, sale }) => {
  const { h2iApi } = useApp();
  const [loading, setLoading] = useState(false);
  const { setSelectedSale, selectedBranch } = useAbcProducts();

  function handleReprintSale() {
    setAnchorEl(null);

    if (!h2iApi) {
      return;
    }

    setLoading(true);

    h2iApi
      .get(`/api/imprimevenda`, {
        params: {
          id_filial: selectedBranch?.id_filial,
          numero_venda: sale.numero_venda,
        },
      })
      .then(response => setSelectedSale(response.data[0].venda[0]))
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  return (
    <>
      {loading && <Loading />}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleReprintSale}>Reimprimir venda de {sale.cliente}</MenuItem>
      </Menu>
    </>
  );
};

export default SelectedBranchListMenu;
