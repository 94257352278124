import React, { useEffect, useState } from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { AgingBuyer } from 'types/aging';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  maxHeight: 550,
});

const Row = styled('li')(({ theme }) => ({
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 6,
  gridTemplateColumns: '220px 130px 120px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '120px 130px 110px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  '& > .numericData': {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  '& > span': {
    gap: 5,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  '& .buyer-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
  rowGap: 10,
});

interface TopAgingBuyersProps {
  data: AgingBuyer[];
}

const TopAgingBuyers: React.FC<TopAgingBuyersProps> = ({ data }) => {
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<AgingBuyer[]>([]);

  useEffect(() => {
    setFiltered(
      data.map(item => ({
        ...item,
        formattedStock: parseInt(item.estoque),
        formattedTotalCoust: moneyFormat(item.custo_total),
      })),
    );
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <Container>
      <List>
        <Row>
          <span>
            <Typography onClick={() => handleSort('comprador')} variant="subtitle2">
              Comprador
            </Typography>
            {orderedIndex.index === 'comprador' && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </span>

          <span className="numericData">
            <Typography onClick={() => handleSort('custo_total')} align="right" variant="subtitle2">
              Custo Total
            </Typography>
            {orderedIndex.index === 'custo_total' && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </span>

          <span>
            <Typography onClick={() => handleSort('formattedStock')} variant="subtitle2" align="right">
              Estoque
            </Typography>
            {orderedIndex.index === 'formattedStock' && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </span>
        </Row>
        {filtered.map((aging, index) => (
          <Row key={index}>
            <Tooltip title={aging.comprador}>
              <Typography className="buyer-name" variant="caption">
                {aging.comprador}
              </Typography>
            </Tooltip>
            <Typography className="numericData" align="right" variant="caption">
              {aging.formattedTotalCoust}
            </Typography>
            <Typography align="right" variant="caption">
              {aging.estoque}
            </Typography>
          </Row>
        ))}
      </List>
    </Container>
  );
};

export default TopAgingBuyers;
