import { useApp } from 'hooks/app';
import { useEffect, useState } from 'react';
import { Manufacturer } from 'types/manufacturer';
import { MonthlyCategory } from 'types/monthlyComparison';
import { ProductGroup, ProductSubGroup } from 'types/product';

export interface SegmentFilter {
  id: number;
  segmento: string;
}

export interface UnityFilter {
  id: number;
  unidade: string;
}

export type UseFetchStockFilters = {
  units: UnityFilter[];
  segments: SegmentFilter[];
  categories: MonthlyCategory[];
  subCategories: ProductGroup[];
  subGroups: ProductSubGroup[];
  manufacturers: Manufacturer[];
  loading: boolean;
};

export function useFetchMoreFilters(): UseFetchStockFilters {
  const [units, setUnits] = useState<UnityFilter[]>([]);
  const [segments, setSegments] = useState<SegmentFilter[]>([]);
  const [categories, setCategories] = useState<MonthlyCategory[]>([]);
  const [subCategories, setSubCategories] = useState<ProductGroup[]>([]);
  const [subGroups, setSubGroups] = useState<ProductSubGroup[]>([]);
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [loading, setLoading] = useState(true);
  const { h2iApi } = useApp();

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    const segments = h2iApi.get('/api/produto_segmentos');
    const units = h2iApi.get('/api/produto_unidades');
    const subCategories = h2iApi.get('/api/grupo');
    const categories = h2iApi.get('/api/produtocategorias');
    const subgroups = h2iApi.get('/api/subgrupo');
    const manufacturers = h2iApi.get('/api/getfabricantes');

    Promise.all([segments, units, subCategories, categories, subgroups, manufacturers])
      .then(([segmentsRes, unitsRes, subCategoriesRes, categoriesRes, subgroupsRes, manufacturersRes]) => {
        setUnits(unitsRes.data);
        setSegments(segmentsRes.data);
        setSubCategories(subCategoriesRes.data);
        setCategories(categoriesRes.data);
        setSubGroups(subgroupsRes.data.RESULT[0]);
        setManufacturers(manufacturersRes.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [h2iApi]);

  return {
    units,
    subCategories,
    segments,
    categories,
    subGroups,
    manufacturers,
    loading,
  };
}
