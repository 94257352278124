import React from 'react';
import { CircularProgress, InputAdornment } from '@mui/material';
import { User } from 'types/user';

interface UserAutoCompleteAdornmentProps {
  selectedUsers: User[];
  loading: boolean;
  clearSelected(): void;
}

const UserAutoCompleteAdornment: React.FC<UserAutoCompleteAdornmentProps> = ({
  selectedUsers,
  loading,
  clearSelected,
}) => {
  return <InputAdornment position="end">{loading && <CircularProgress color="primary" size={24} />}</InputAdornment>;
};

export default UserAutoCompleteAdornment;
