import { TableTemplate } from 'types/tableTemplate';

export const customerTableTemplate: TableTemplate[] = [
  {
    id: 'cliente',
    description: 'Cliente',
    originalId: 'cliente',
    width: 300,
  },
  {
    id: 'cnpj_cpf_cliente',
    description: 'CNPJ/CPF',
    originalId: 'cnpj_cpf_cliente',
    width: 160,
  },
  {
    id: 'atividade',
    description: 'Atividade',
    originalId: 'atividade',
    width: 180,
  },
  {
    id: 'cidade',
    description: 'Cidade',
    originalId: 'cidade',
    width: 120,
  },
  {
    id: 'email',
    description: 'E-mail',
    originalId: 'email',
    width: 250,
  },
  {
    id: 'telefone',
    description: 'Telefone',
    originalId: 'telefone',
    width: 100,
  },
];
