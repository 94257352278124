import React from 'react';
import { useCommercialBi } from '../hooks/useCommercialBi';
import { Tooltip, Typography, styled } from '@mui/material';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  background: '#fff',
  flex: 1,
});

const Row = styled('li')({
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 6,
  gridTemplateColumns: '200px 1fr 1fr',
  '& .segment-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

const List = styled('ul')({
  display: 'grid',
  rowGap: 10,
});

const TopPaymentMethods: React.FC = () => {
  const { data } = useCommercialBi();

  const paymentMethods = data.payment_methods
    .sort((a, b) => {
      if (a.percentual < b.percentual) {
        return 1;
      }

      if (a.percentual > b.percentual) {
        return -1;
      }

      return 0;
    })
    .slice(0, 10);

  return (
    <Container>
      <List>
        <Row>
          <Typography variant="subtitle2">Forma de pagamento</Typography>
          <Typography align="right" variant="subtitle2">
            Valor
          </Typography>
          <Typography variant="subtitle2" align="right">
            %
          </Typography>
        </Row>
        {paymentMethods.map(method => (
          <Row key={method.forma}>
            <Tooltip title={method.forma}>
              <Typography className="segment-name" variant="caption">
                {method.forma}
              </Typography>
            </Tooltip>
            <Typography align="right" variant="caption">
              {method.formattedValue}
            </Typography>
            <Typography align="right" variant="caption">
              {method.formattedPercentual}
            </Typography>
          </Row>
        ))}
      </List>
    </Container>
  );
};

export default TopPaymentMethods;
