import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import CategoryItemModule from './CategoryItemModule';
import { AbcProductCategory } from 'types/abcProduct';

type CategoryListModuleProps = {
  categories: AbcProductCategory[];
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

const CategoryListModule: React.FC<CategoryListModuleProps> = ({ categories }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {categories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(category => (
        <CategoryItemModule key={category.cod} category={category} />
      ))}
    </CustomList>
  );
};

export default CategoryListModule;
