import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import parseISO from 'date-fns/parseISO';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { api } from 'services/api';
import { CreditLimitRequest } from 'types/creditLimitRequest';

type UseLoadCreditLimitRequest = [CreditLimitRequest[], Dispatch<SetStateAction<CreditLimitRequest[]>>];

export function useLoadCreditLimitRequests(
  selectedCreditLimitRequest: CreditLimitRequest | null,
): UseLoadCreditLimitRequest {
  const [creditLimitRequests, setCreditLimitRequests] = useState<CreditLimitRequest[]>([]);

  useEffect(() => {
    if (!selectedCreditLimitRequest) return;

    const document = selectedCreditLimitRequest.document;

    api
      .get<CreditLimitRequest[]>(`/resellers/${document}/limitRequests`)
      .then(response => {
        const filtered = response.data.filter(item => item.id !== selectedCreditLimitRequest.id);
        setCreditLimitRequests(
          filtered.map(limitRequest => ({
            ...limitRequest,
            social_contract: limitRequest.social_contract
              ? {
                  ...limitRequest.social_contract,
                  formattedCreatedAt: format(parseISO(limitRequest.social_contract.created_at), 'PPp', {
                    locale: ptBR,
                  }),
                }
              : null,
            annual_balance: limitRequest.annual_balance
              ? {
                  ...limitRequest.annual_balance,
                  formattedCreatedAt: format(parseISO(limitRequest.annual_balance.created_at), 'PPp', { locale: ptBR }),
                }
              : null,
            quarterly_balance: limitRequest.quarterly_balance
              ? {
                  ...limitRequest.quarterly_balance,
                  formattedCreatedAt: format(parseISO(limitRequest.quarterly_balance.created_at), 'PPp', {
                    locale: ptBR,
                  }),
                }
              : null,
            twelve_months_revenue: limitRequest.twelve_months_revenue
              ? {
                  ...limitRequest.twelve_months_revenue,
                  formattedCreatedAt: format(parseISO(limitRequest.twelve_months_revenue.created_at), 'PPp', {
                    locale: ptBR,
                  }),
                }
              : null,
            guarantee_letter: limitRequest.guarantee_letter
              ? {
                  ...limitRequest.guarantee_letter,
                  formattedCreatedAt: format(parseISO(limitRequest.guarantee_letter.created_at), 'PPp', {
                    locale: ptBR,
                  }),
                }
              : null,
          })),
        );
      })
      .catch(err => console.error(err));
  }, [selectedCreditLimitRequest]);

  return [creditLimitRequests, setCreditLimitRequests];
}
