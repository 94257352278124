import React, { useCallback, useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import styles from './autocomplete.module.css';
import { Autocomplete } from '@mui/lab';
import { useApp } from 'hooks/app';
import { NewInvoiceFavored } from '../../reducer/types';

interface AutocompleteFromProps {
  onSelect(person: NewInvoiceFavored | null): void;
  label: string;
  placeholder: string;
  favored: NewInvoiceFavored | null;
  helperText: string;
  error: boolean;
  endpoint: string;
  handleKeyDown(e, currentInput: string): void;
}

const PersonAutocomplete: React.FC<AutocompleteFromProps> = ({
  onSelect,
  label,
  placeholder,
  favored,
  helperText,
  error,
  endpoint,
  handleKeyDown,
}) => {
  const { financialApi } = useApp();
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState<NewInvoiceFavored[]>([]);
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (error) {
      input.current?.focus();
    }
  }, [error]);

  const handleSelectReseller = useCallback(
    (person: NewInvoiceFavored | null) => {
      onSelect(person);
    },
    [onSelect],
  );

  const fetchRecords = useCallback(
    async (term: string) => {
      setLoading(true);

      financialApi
        ?.get(`${endpoint}filtro=${term}`)
        .then(response => {
          setRecords(response.data);
        })
        .finally(() => setLoading(false))
        .catch(err => console.error(err));
    },
    [financialApi, endpoint],
  );

  useEffect(() => {
    fetchRecords('');
  }, [fetchRecords]);

  return (
    <Autocomplete
      classes={{ inputRoot: styles.inputRoot }}
      id="person-autocomplete"
      options={records}
      value={favored}
      onChange={(event, value) => handleSelectReseller(value)}
      fullWidth
      getOptionLabel={reseller => `${reseller.nome}`}
      autoComplete
      disabled={loading}
      renderInput={params => (
        <TextField
          {...params}
          inputRef={input}
          onKeyDown={e => handleKeyDown(e, 'monthYear')}
          label={label}
          placeholder={placeholder}
          margin="none"
          error={error}
          fullWidth
          helperText={helperText}
        />
      )}
    />
  );
};

export default PersonAutocomplete;
