import { createContext, useContext } from 'react';
import { PromotionalBanner } from 'types/promotionalBanners';

type PromotionalBannersContextValue = {
  selectedBanner: PromotionalBanner | null;
  setSelectedBanner(banner: PromotionalBanner | null): void;
};

const PromotionalBannerContext = createContext<PromotionalBannersContextValue>({} as PromotionalBannersContextValue);
export const PromotionalBannersProvider = PromotionalBannerContext.Provider;

export function usePromotionalBanners(): PromotionalBannersContextValue {
  const context = useContext(PromotionalBannerContext);
  return context;
}
