import React, { useState, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import useTableOrder from 'hooks/tableOrder';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { styled } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import { useFinancial } from '../hooks/useFinancial';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { ExtractProvider } from './hooks/useExtracts';
import { useFetchExtracts } from './hooks/useFetchExtracts';
import BankStatementFilterBox from './BankStatementFilterBox';
import PageHeaderButtons from './PageHeaderButtons';
import BankStatementReport from './report/BankStatementReport';
import BankStatementListTable from './list/table/BankStatementListTable';
import BankStatementListModule from './list/module/BankStatementListModule';
import { Extract } from 'types/extract';
import { subDays } from 'date-fns';
import NewTransferAccount from '../transfer-account/registration/new/NewTransferAccount';
import NewInvoice from 'pages/moviment/query/registration/NewInvoice';
import { useMessaging } from 'hooks/messaging';
import { invoiceTypes } from 'pages/moviment/query/Invoices';
import EditInvoice from 'pages/moviment/query/registration/edit/EditInvoice';
import { useBranches } from 'providers/BranchesProviders';
import BankStatementActions from './BankStatementActions';
import BankStatementTotals from './BankStatementTotal';
import BankStatementFilterMobile from './BankStatementFilterMobile';
import GroupedBankStatementListTable from './list/table/grouped/GroupedBankStatementListTable';
import GroupedBankStatementListModule from './list/module/grouped/GroupedBankStatementListModule';
import PageHeader from 'components/page-header/PageHeader';
import PageFooterButtons from './PageFooterButtons';
import BankStatementAppropriation from './BankStatementAppropriation';

export interface BankStatementFilterProps {
  movimentation: string;
  ignorePeriod: string;
  sortBy: string;
  month_year: null | Date;
  accountId: number[];
  targetAccount: string;
  initial_date: Date;
  final_date: Date;
  branchId: number;
  type: 'T' | 'DT' | 'FP' | 'D' | 'TT' | 'S';
}

const filterInitialState: BankStatementFilterProps = {
  movimentation: '',
  ignorePeriod: 'N',
  targetAccount: '',
  month_year: null,
  branchId: 0,
  initial_date: subDays(new Date(), 7),
  final_date: new Date(),
  accountId: [],
  type: 'TT',
  sortBy: 'name',
};

const Content = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& p': {
    fontSize: '0.75rem!important',
  },
  '& span': {
    fontSize: '0.75rem!important',
  },
  '& label': {
    fontSize: '0.75rem!important',
  },
  '& .MuiInputBase-root': {
    fontSize: '0.75rem!important',
    '& .MuiInputAdornment-root': {
      marginRight: 3,
      marginLeft: 3,
    },
  },
  '& .MuiButtonBase-root': {
    fontSize: '0.75rem!important',
  },
});

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const BankStatement: React.FC = () => {
  const { handleBack } = useFinancial();
  const { handleOpen } = useMessaging();
  const [dialogFilter, setDialogFilter] = useState(false);
  const { accountingBranches } = useBranches();
  const [selectedExtracts, setSelectedExtracts] = useState<Extract[]>([]);
  const [displayMode] = useDisplayMode();
  const [appropriationDialog, setAppropriationDialog] = useState(false);
  const [filtered, setFiltered] = useState<Extract[]>([]);
  const [, setExtract] = useState<Extract | null>(null);
  const [showTotals, setShowTotals] = useState(true);
  const [defaultView, setDefaultView] = useState(true);
  const [isNewExpense, setIsNewExpense] = useState(false);
  const [isNewTransferOpen, setIsNewTransferOpen] = useState(false);
  const [invoice, setInvoice] = useState<{ id: number; tipo: string } | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const [filter, setFilter] = useState<BankStatementFilterProps>(filterInitialState);
  const { extracts, loading, fetch, totalizingAccounts, total, loadingTotalizingAccount } = useFetchExtracts(filter);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setFiltered(extracts);
  }, [extracts]);

  useEffect(() => {
    handleChangeFilter('accountId', []);
  }, [filter.branchId]);

  function handleChangeFilter(index: keyof BankStatementFilterProps, value: any) {
    setFilter(filter => ({
      ...filter,
      [index]: value,
    }));
  }

  useEffect(() => {
    if (accountingBranches[0]) {
      handleChangeFilter('branchId', accountingBranches[0].id);
    }
  }, [accountingBranches]);

  function handleSearch() {
    fetch();
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function onSubmit() {
    setIsNewTransferOpen(false);
    fetch();
  }

  function onSubmitExpense() {
    if (!invoice) {
      return;
    }

    handleOpen(`${invoiceTypes[invoice.tipo]} criada com sucesso.`);

    setInvoice(null);
  }

  function onSaveAppropriation() {
    setAppropriationDialog(false);
    setSelectedExtracts([]);
    fetch();
  }

  function onSearch() {
    setDialogFilter(false);
    fetch();
  }

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedExtracts([]);
    } else {
      setSelectedExtracts(extracts.filter(item => item.apropriado === 'N'));
    }
    setSelectAll(!selectAll);
  };

  const handleToggle = (extract: Extract) => {
    if (extract.apropriado === 'S') {
      handleOpen('Não é possível selecionar um registro já apropriado');
      return;
    }
    if (selectedExtracts.some(_selectedExtract => _selectedExtract.id_movimentacao === extract.id_movimentacao)) {
      setSelectedExtracts(
        selectedExtracts.filter(_selectedExtract => _selectedExtract.id_movimentacao !== extract.id_movimentacao),
      );
    } else {
      setSelectedExtracts([...selectedExtracts, extract]);
    }
  };

  return (
    <ExtractProvider
      value={{
        extracts: filtered,
        total,
        loading,
        setExtract,
        loadingTotalizingAccount,
        setIsReportOpen,
        handleChangeFilter,
        handleSearch,
        filter,
        totalizingAccounts,
        setIsNewTransferOpen,
        setIsNewExpense,
        setInvoice,
        showTotals,
        setShowTotals,
        setDefaultView,
        defaultView,
        handleSelectAll,
        handleToggle,
        selectAll,
        selectedExtracts,
        setAppropriationDialog,
      }}
    >
      {dialogFilter && <BankStatementFilterMobile onSearch={onSearch} onExited={() => setDialogFilter(false)} />}

      {isReportOpen && <BankStatementReport onExited={() => setIsReportOpen(false)} />}

      {isNewTransferOpen && (
        <NewTransferAccount
          branchId={filter.branchId}
          onSubmit={onSubmit}
          onExited={() => setIsNewTransferOpen(false)}
        />
      )}

      {isNewExpense && (
        <NewInvoice handleUpdateList={onSubmitExpense} type="D" onExited={() => setIsNewExpense(false)} />
      )}

      {appropriationDialog && (
        <BankStatementAppropriation onSave={onSaveAppropriation} onExited={() => setAppropriationDialog(false)} />
      )}

      {invoice && (
        <EditInvoice
          id={invoice.id}
          handleUpdateList={onSubmitExpense}
          type={invoice.tipo}
          onExited={() => setInvoice(null)}
        />
      )}

      <Container>
        <Appbar
          ActionsComponent={<BankStatementActions handleDialogFilter={() => setDialogFilter(true)} />}
          title="Extrato de contas"
          backAction={handleBack}
        />
        <PageHeader title="Extrato de contas" />

        <PageHeaderButtons />

        <BankStatementTotals />

        <BankStatementFilterBox />
      </Container>

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filtered.length === 0 ? (
        <NoData message="Extrato não disponível" />
      ) : (
        <PaginationProvider>
          <Content>
            {displayMode === 'list' ? (
              defaultView ? (
                <BankStatementListTable extracts={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <GroupedBankStatementListTable
                  extracts={filtered}
                  handleSort={handleSort}
                  orderedIndex={orderedIndex}
                />
              )
            ) : displayMode === 'module' && defaultView ? (
              <BankStatementListModule extracts={filtered} />
            ) : (
              <GroupedBankStatementListModule extracts={filtered} />
            )}
            <Actions>
              <PageFooterButtons />
              <Pagination count={filtered.length} />
            </Actions>
          </Content>
        </PaginationProvider>
      )}
    </ExtractProvider>
  );
};

export default BankStatement;
