import React, { useCallback, useMemo, useState } from 'react';
import { usePurchaseOrder } from '../../hooks/PurchaseOrderProvider';
import { styled } from '@mui/material';
import InsideLoading from 'components/loading/InsideLoading';
import TopShoppingSuggestion from './TopShoppingSuggestion';
import ShoppingSuggestionTabs from './ShoppingSuggestionTabs';
import TopShoppingSuggestionAggregated from './TopShoppingSuggestionAggregated';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import SuggestionSelectedProduct from './SuggestionSelectedProduct';
import ShoppingSuggestionFilter from './ShoppingSuggestionFilter';

const Container = styled('div')({
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  rowGap: 10,
  flex: 1,
  '& svg': {
    fontSize: 12,
  },
});

const Content = styled('div')({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr',
});

export interface AggregatedProduct {
  id_subgrupo: string;
  subgrupo: string;
  sugestao_total: number;
  estoque_total: number;
  forecast_total: number;
  id_produto: number;
  produto: string;
  [key: string]: string | number;
}

const ShoppingSuggestionPage: React.FC = () => {
  const { suggestions, loading } = usePurchaseOrder();
  const [subgroupId, setSubgroupId] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<AggregatedProduct | null>(null);
  const [tab, setTab] = useState('all');
  const branches = useMemo(() => {
    return ['POC', 'JUA', ' FS', ' LF', 'VDC', 'BRA', 'ALA', 'PAF', 'SAJ', 'IRC'];
  }, []);

  const calculateAggregatedData = useCallback((): AggregatedProduct[] => {
    return Object.values(
      suggestions.reduce((acc, item) => {
        const { id_produto, produto, filial } = item;

        const key = id_produto;

        if (!acc[key]) {
          acc[key] = {
            id_subgrupo: item.id_subgrupo,
            subgrupo: item.subgrupo,
            id_produto,
            produto,
            sugestao_total: 0,
            estoque_total: 0,
            forecast_total: 0,
            ...Object.fromEntries(branches.map(filial => [`est_${filial.toLowerCase()}`, 0])),
            ...Object.fromEntries(branches.map(filial => [`sug_${filial.toLowerCase()}`, 0])),
          };
        }

        acc[key].sugestao_total += Number(item.sugestao);
        acc[key].estoque_total += Number(item.estoque);
        acc[key].forecast_total += Number(item.forecast);
        acc[key][`est_${filial.slice(-3).toLowerCase()}`] = parseFloat(item.estoque.toString());
        acc[key][`sug_${filial.slice(-3).toLowerCase()}`] = parseFloat(item.sugestao.toString());

        return acc;
      }, {} as { [key: string]: AggregatedProduct }),
    );
  }, [branches, suggestions]);

  return (
    <Container>
      <ShoppingSuggestionTabs handleChange={value => setTab(value)} value={tab} />
      <ShoppingSuggestionFilter
        products={calculateAggregatedData()}
        subgroupId={subgroupId}
        onChange={value => setSubgroupId(value)}
      />
      {selectedProduct && (
        <PaginationProvider>
          <SuggestionSelectedProduct
            branches={branches}
            products={calculateAggregatedData().filter(item => item.id_subgrupo === selectedProduct.id_subgrupo)}
            onExited={() => setSelectedProduct(null)}
            selectedProduct={selectedProduct}
          />

          <Pagination
            count={calculateAggregatedData().filter(item => item.id_subgrupo === selectedProduct.id_subgrupo).length}
          />
        </PaginationProvider>
      )}
      {loading.shoppingSuggestion ? (
        <InsideLoading />
      ) : (
        <Content>
          {tab === 'all' ? (
            <PaginationProvider>
              <TopShoppingSuggestion
                data={subgroupId ? suggestions.filter(item => item.id_subgrupo === subgroupId) : suggestions}
              />
              <Pagination
                count={
                  subgroupId ? suggestions.filter(item => item.id_subgrupo === subgroupId).length : suggestions.length
                }
              />
            </PaginationProvider>
          ) : (
            <PaginationProvider>
              <TopShoppingSuggestionAggregated
                setSelectedProduct={setSelectedProduct}
                branches={branches}
                data={
                  subgroupId
                    ? calculateAggregatedData().filter(item => item.id_subgrupo === subgroupId)
                    : calculateAggregatedData()
                }
              />

              <Pagination
                count={
                  subgroupId
                    ? calculateAggregatedData().filter(item => item.id_subgrupo === subgroupId).length
                    : calculateAggregatedData().length
                }
              />
            </PaginationProvider>
          )}
        </Content>
      )}
    </Container>
  );
};

export default ShoppingSuggestionPage;
