import { Typography, Theme } from '@mui/material';
import React from 'react';
import { useCreditLimitRequest } from '../../hooks/useCreditLimitRequest';
import Reference from './Reference';
import { makeStyles } from '@mui/styles';

const styles = makeStyles<Theme>(theme => ({
  ul: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    rowGap: '15px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr ',
    },
  },
  container: {
    paddingTop: 15,
  },
}));

const References: React.FC = () => {
  const classes = styles();
  const { creditLimitRequest } = useCreditLimitRequest();

  return (
    <div className={classes.container}>
      <Typography color="textSecondary" variant="caption">
        Referências
      </Typography>
      <ul className={classes.ul}>
        {creditLimitRequest?.references.map(reference => (
          <Reference reference={reference} key={reference.id} />
        ))}
      </ul>
    </div>
  );
};

export default References;
