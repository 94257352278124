import React from 'react';
import { ListItemIcon, ListItemText, ListItemButton, styled } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

interface StyledListItemButtonProps {
  nested: number;
}

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  '& > svg': {
    fontSize: 22,
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  '& > span': {
    fontSize: 13,
  },
}));

const StyledListItemButton = styled(ListItemButton)<StyledListItemButtonProps>(({ theme, nested }) => ({
  paddingLeft: nested ? theme.spacing(nested) : 16,
  paddingTop: 4,
  paddingBottom: 4,
  borderLeft: '2px solid transparent',
  minHeight: 30,
  color: theme.palette.primary.contrastText,
}));

interface SidebarItemProps {
  text: string;
  icon: React.ReactElement;
  opened: boolean;
  onClick: () => void;
  nested?: number;
}

const SidebarItemExpendable: React.FC<SidebarItemProps> = ({ icon, text, opened, onClick, nested = 0 }) => {
  return (
    <StyledListItemButton nested={nested} onClick={onClick}>
      <StyledListItemIcon color="inherit">{icon}</StyledListItemIcon>
      <StyledListItemText primary={text} />
      {opened ? <ExpandLess color="inherit" /> : <ExpandMore color="inherit" />}
    </StyledListItemButton>
  );
};

export default SidebarItemExpendable;
