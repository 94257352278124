import { WasteProduct } from 'pages/material-residues/waste/types/waste';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export type ProductContextValue = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  selectedProduct: WasteProduct | null;
  setSelectedProduct: Dispatch<SetStateAction<WasteProduct | null>>;
};

export const ProductsContext = createContext<ProductContextValue>({} as ProductContextValue);
export const ProductsContextConsumer = ProductsContext.Consumer;
export const ProductsContextProvider = ProductsContext.Provider;

export function useProducts(): ProductContextValue {
  return useContext(ProductsContext);
}
