import { TableTemplate } from 'types/tableTemplate';

export const saleActivityTableTemplate: TableTemplate[] = [
  {
    id: 'atividade',
    description: 'Atividade',
    originalId: 'atividade',
    width: 300,
  },
  {
    id: 'qtd',
    description: 'Quantidade',
    originalId: 'qtd',
    width: 80,
  },
  {
    id: 'formattedCost',
    description: 'Custo',
    originalId: 'custo',
    width: 200,
    dataType: 'number',
  },
  {
    id: 'formattedTotal',
    description: 'Valor total',
    originalId: 'vl_total',
    width: 200,
    dataType: 'number',
  },
  {
    id: 'formattedMc',
    description: 'M.C',
    originalId: 'mc',
    width: 200,
    dataType: 'number',
  },
  {
    id: 'formattedSpiff',
    description: 'Spiff',
    originalId: 'spiff',
    dataType: 'number',
    width: 200,
  },
];
