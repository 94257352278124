import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { CreditLimitRequest } from 'types/creditLimitRequest';
import { CreditLimitRequestsFilter } from '../CreditLimitRequests';

type CreditLimitRequestsContextValue = {
  selectedCreditLimitRequest: CreditLimitRequest | null;
  setSelectedCreditLimitRequest(reseller: CreditLimitRequest | null): void;
  setCreditLimitRequests: Dispatch<SetStateAction<CreditLimitRequest[]>>;
  filter: CreditLimitRequestsFilter;
  handleChangeFilter(key: keyof CreditLimitRequestsFilter, value: Date | null): void;
  handleRemoveFromList(creditLimitRequestId: string): void;
  handleFormatList(creditLimitRequest: CreditLimitRequest): void;
  handleUpdateList(creditLimitRequest: CreditLimitRequest): void;
};

const CreditLimitRequestsContext = createContext<CreditLimitRequestsContextValue>(
  {} as CreditLimitRequestsContextValue,
);
export const CreditLimitRequestsProvider = CreditLimitRequestsContext.Provider;

export function useCreditLimitRequests(): CreditLimitRequestsContextValue {
  const context = useContext(CreditLimitRequestsContext);
  return context;
}
