import { TableTemplate } from 'types/tableTemplate';

export const wastesTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'Ações',
    originalId: 'actions',
    width: 70,
  },
  {
    id: 'cnpj_cpf',
    description: 'Documento',
    originalId: 'cnpj_cpf',
    width: 140,
  },
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 140,
  },
  {
    id: 'nome',
    description: 'Nome',
    originalId: 'nome',
    width: 400,
  },
  {
    id: 'formattedTotal',
    description: 'Total',
    originalId: 'valor_total',
    width: 150,
    dataType: 'number',
  },
  {
    id: 'cidade',
    description: 'Cidade',
    originalId: 'cidade',
    width: 150,
  },
];
