import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useApp } from 'hooks/app';
import { ptBR } from 'date-fns/locale';
import useTableOrder from 'hooks/tableOrder';
import NoData from 'components/no-data/NoData';
import InsideLoading from 'components/loading/InsideLoading';
import { AbcProductBranch } from 'types/abcProduct';
import Pagination from 'components/pagination/Pagination';
import PaginationProvider from 'hooks/pagination';
import { useAbcCustomer } from '../../hooks/useAbcCustomer';
import { AbcCustomer } from 'types/abcCustomer';
import CustomerListTable from '../../list/table/CustomerListTable';
import { moneyFormat } from 'helpers/numberFormat';
import { makeStyles } from '@mui/styles';

interface BranchMoreMenuProps {
  branch: AbcProductBranch;
}

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      margin: 0,
      minHeight: 0,
      overflow: 'visible',
    },
  },
});

const BranchMoreMenu: React.FC<BranchMoreMenuProps> = ({ branch }) => {
  const classes = useStyles();
  const { h2iApi } = useApp();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const { filter, searchValue } = useAbcCustomer();
  const [customers, setCustomers] = useState<AbcCustomer[]>([]);
  const [filtered, setFiltered] = useState<AbcCustomer[]>([]);

  useEffect(() => {
    setFiltered(customers);
  }, [customers]);

  function handleSort(index: string) {
    const filteredS = sort(index, filtered);
    setFiltered(filteredS);
  }

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    h2iApi
      .get('/api/relcurvaabccliente', {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: branch.id_filial,
          id_subgrupo: filter.subGroupIds.join(',') || '',
          cliente: searchValue,
          tipo_venda: filter.typeSale,
          id_categoria: filter.categoryIds.join(','),
          id_subcategoria: filter.subCategoryIds.join(','),
          pedido_por: filter.madeBy,
          id_segmento: filter.segmentIds.join(','),
          id_unidade: filter.unitIds.join(','),
          id_fabricante: filter.manufacturerIds.join(','),
        },
      })
      .then(response => {
        setCustomers(
          response.data.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            return item;
          }),
        );
      })
      .finally(() => setLoading(false));
  }, [h2iApi, filter, branch, searchValue]);

  return (
    <PaginationProvider>
      <div className={classes.div}>
        {loading ? (
          <InsideLoading />
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum cliente encontrado." />
        ) : (
          <>
            <CustomerListTable customers={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
            <Pagination count={filtered.length} />
          </>
        )}
      </div>
    </PaginationProvider>
  );
};

export default BranchMoreMenu;
