import React, { useState, useEffect } from 'react';
import Dialog from 'components/dialogs/Dialog';
import { Bill } from 'types/bill';
import { api } from 'services/api';
import InsideLoading from 'components/loading/InsideLoading';
import BillRemittanceActions from './BillRemittanceActions';
import BillRemittanceList from './BillRemittanceList';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/numberFormat';
import { format, parseISO } from 'date-fns';
import { BillRemittanceProvider } from './hooks/useBillRemitance';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import useSearch from 'hooks/search';
import InsideSaving from 'components/loading/InsideSaving';
import { Remittance } from 'types/remittance';
import BillRemittanceDownload from './BillRemittanceDownload';
import NoData from 'components/no-data/NoData';
import { BillSetting } from 'types/billSetting';

interface BillRemittanceProps {
  onExited(): void;
}

const BillRemittance: React.FC<BillRemittanceProps> = ({ onExited }) => {
  const [bills, setBills] = useState<Bill[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [selectedBills, setSelectedBills] = useState<Bill[]>([]);
  const [filtered, setFiltered] = useState<Bill[]>([]);
  const [textSearch, setTextSearch] = useState('');
  const [validation, setValidation] = useState('');
  const [bankBilletSettingId, setBankBilletSettingId] = useState(1);
  const [billets, setBillets] = useState<BillSetting[]>([]);
  const search = useSearch();
  const [remittance, setRemittance] = useState<Remittance | null>(null);

  useEffect(() => {
    api
      .get<BillSetting[]>('/bank-billet-settings')
      .then(response => setBillets(response.data))
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    api
      .get<Bill[]>('/bankBilletsRemittance', { params: { bank_billet_setting_id: bankBilletSettingId } })
      .then(response => {
        const _bills = response.data.map(bill => {
          bill.formattedCreatedAt = format(parseISO(bill.created_at), 'PPp', { locale: ptBR });
          bill.formattedCanceledAt = bill.canceled_at
            ? format(parseISO(bill.canceled_at), 'PPp', { locale: ptBR })
            : '';
          bill.beneficiaryName = bill.beneficiary.company_name;
          bill.payerName = bill.payer.company_name;
          bill.formattedValue = moneyFormat(bill.value);
          bill.formattedPayday = format(parseISO(bill.payday), 'PP', { locale: ptBR });
          return bill;
        });
        setBills(_bills);
        setSelectedBills(_bills);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [bankBilletSettingId]);

  useEffect(() => {
    setFiltered(search(textSearch, 'document_number', bills));
  }, [bills, search, textSearch]);

  function handleSubmit() {
    setSaving(true);

    if (!bankBilletSettingId) {
      setValidation('É necessário escolher um layout');
      return;
    }

    const ids = selectedBills.map(bill => bill.id);

    api
      .post('/bankBilletRemittances', {
        ids,
        bank_billet_setting_id: bankBilletSettingId,
      })
      .then(response => {
        setRemittance(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleSelect(bill: Bill) {
    const exist = selectedBills.some(_bill => _bill.id === bill.id);

    if (exist) {
      setSelectedBills(state => state.filter(_bill => _bill.id !== bill.id));
      return;
    }

    setSelectedBills(state => [...state, bill]);
  }

  function handleSelectAll() {
    setSelectedBills(filtered);
  }

  function handleRemoveSelectedAll() {
    setSelectedBills([]);
  }

  return (
    <BillRemittanceProvider value={{ bills, selectedBills, handleSelect, handleSelectAll, handleRemoveSelectedAll }}>
      <Dialog
        title="Selecionar títulos"
        onExited={onExited}
        maxWidth="sm"
        ComponentActions={<BillRemittanceActions handleSubmit={handleSubmit} />}
      >
        <div style={{ display: 'flex', gap: 5 }}>
          <TextField
            label="Pesquisa"
            fullWidth
            placeholder="Pesquisar boletos..."
            margin="normal"
            value={textSearch}
            onChange={e => setTextSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            select
            fullWidth
            margin="normal"
            error={!!validation}
            helperText={validation}
            placeholder="Selecionar layout..."
            value={bankBilletSettingId}
            onChange={e => setBankBilletSettingId(parseInt(e.target.value))}
            label="Layout"
          >
            {billets.map(bill => (
              <MenuItem key={bill.id} value={bill.id}>
                {bill.bank_name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {saving && <InsideSaving />}
        {loading ? (
          <InsideLoading />
        ) : remittance ? (
          <BillRemittanceDownload remittance={remittance} />
        ) : bills.length === 0 ? (
          <NoData message="Nenhum boleto para mostrar" />
        ) : (
          <BillRemittanceList bills={filtered} />
        )}
      </Dialog>
    </BillRemittanceProvider>
  );
};

export default BillRemittance;
