import React from 'react';

import Dialog from 'components/dialogs/Dialog';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSelector } from 'store/selector';
import { Branch } from 'types/branch';
import { moneyFormat } from 'helpers/numberFormat';
import { FinancialTicket } from 'types/financialReportList';
import PrintData from 'components/print/PrintData';

import { makeStyles } from '@mui/styles';

type FinancialTicketDataProps = {
  data: FinancialTicket[];
  branch: Branch | null;
  onExited(): void;
};

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '60px 70px 5fr 130px 1fr 100px 80px 80px 90px',
    marginBottom: 1,
    paddingBottom: 1,
  },
  headerInfo: {
    marginTop: 10,
  },
  rowP: {
    fontSize: '7pt',
    textAlign: 'end',
  },
  rowPL: {
    fontSize: '7pt',
    textAlign: 'start',
  },
  rowPC: {
    fontSize: '7pt',
    textAlign: 'center',
  },
});

const FinancialTicketData: React.FC<FinancialTicketDataProps> = ({ data, branch, onExited }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);

  return (
    <Dialog fullScreen title="Boletos" onExited={onExited} ComponentActions={<PrintData />}>
      <div className="page">
        <div className="header">
          <div>
            <h6>Relatório de boletos</h6>
            {branch?.id === undefined ? <h6>Todas as filiais</h6> : <h6>Filial {branch?.nome}</h6>}
          </div>
          <div>
            <p>{user?.name}</p>
            <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
          </div>
        </div>
        <div className={`${classes.row} table-header`}>
          <p className={classes.rowPL}>ID</p>
          <p className={classes.rowPL}>Filial</p>
          <p className={classes.rowPL}>Cliente</p>
          <p className={classes.rowPL}>Lançamento</p>
          <p className={classes.rowPL}>Pagamento</p>
          <p className={classes.rowP}>Valor</p>
          <p className={classes.rowP}>Valor total</p>
          <p className={classes.rowP}>Multa</p>
          <p className={classes.rowP}>Juros</p>
        </div>
        {data.map(item => (
          <div key={item.id} className={classes.row}>
            <p className={classes.rowPL}>{item.id}</p>
            <p className={classes.rowPL}>{item.filial}</p>
            <p className={classes.rowPL}>{item.cliente}</p>
            <p className={classes.rowPL}>{item.data_lanc}</p>
            <p className={classes.rowPL}>{item.data_pag}</p>
            <p className={classes.rowP}>{moneyFormat(item.valor)}</p>
            <p className={classes.rowP}>{moneyFormat(item.multa)}</p>
            <p className={classes.rowP}>{moneyFormat(item.juros)}</p>
            <p className={classes.rowP}>{moneyFormat(item.valor_total)}</p>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default FinancialTicketData;
