import React, { useState } from 'react';
import { Typography } from '@mui/material';
import CostItemTable from './CostItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { makeStyles } from '@mui/styles';
import { costsTableTemplate } from '../costsTableTemplate';
import { ProjectCost } from 'types/project';
import ProjectCostsListMenu from '../ProjectCostsListMenu';
import { useProjectCosts } from '../../hooks/useProjectCosts';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type CostListTableProps = {
  costs: ProjectCost[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const CostListTable: React.FC<CostListTableProps> = ({ costs, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { setSelectedProjectCost } = useProjectCosts();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  function handleClick(cost: ProjectCost) {
    setSelectedProjectCost(cost);
  }

  return (
    <>
      <ProjectCostsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <TableContainer tableTemplate={costsTableTemplate}>
        <TableContent>
          <TableHeader>
            {costsTableTemplate.map(item => (
              <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
          </TableHeader>
          <TableBody>
            {costs.map(cost => (
              <TableRow onClick={() => handleClick(cost)} key={cost.id}>
                <CostItemTable setAnchorEl={setAnchorEl} cost={cost} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default CostListTable;
