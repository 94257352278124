import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { DisplaySettings, Print } from '@mui/icons-material';

interface PrintDataProps {
  changeView?(): void;
}

const PrintData: React.FC<PrintDataProps> = ({ changeView }) => {
  return (
    <>
      {changeView && (
        <Tooltip title="Trocar visualização">
          <IconButton color="inherit" onClick={changeView}>
            <DisplaySettings />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Imprimir">
        <IconButton color="inherit" onClick={() => window.print()}>
          <Print />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default PrintData;
