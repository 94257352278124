import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import React, { useState } from 'react';
import { InvoicesResumeProvider } from './hooks/useFinancialResume';
import { useFetchFinancialResume } from './hooks/useFetchFinancialResume';
import InsideLoading from 'components/loading/InsideLoading';
import InvoicesResumeFilterBox from './filter/FinancialResumeFilterBox';
import InvoicesResumeReport from './report/FinancialResumeReport';
import PageHeader from 'components/page-header/PageHeader';
import NoData from 'components/no-data/NoData';
import { useFinancialResumeTotal } from './hooks/useFinancialResumeTotal';
import FinancialResumeTotals from './report/FinancialResumeTotals';
import GroupedResumeReport from './report/GroupedResumeReport';
import { useFinancial } from 'pages/financial/hooks/useFinancial';
import FinancialResumeMobileFilter from './filter/FinancialResumeMobileFilter';
import FinancialAppbarActions from './FinancialAppbarActions';
import { useApp } from 'hooks/app';
import FinancialResumeMobileReport from './report/FinancialResumeMobileReport';

const Container = styled('div')(props => ({
  display: 'flex',
  flex: 1,
  backgroundColor: '#eee',
  padding: 20,
  gap: 10,
  [props.theme.breakpoints.down('md')]: {
    display: 'grid',
    padding: 0,
    backgroundColor: 'inherit',
  },
}));

export interface FinancialResumeFilterProps {
  initialDate: Date;
  finalDate: Date;
  branchId: number;
  year: Date;
}

const filterInitialState = {
  initialDate: new Date(),
  finalDate: new Date(),
  branchId: 1001,
  year: new Date(),
};

export type Ordination = {
  field: 'classificacao' | 'filial' | 'valor';
  direction: 'asc' | 'desc';
};

const FinancialResume: React.FC = () => {
  const { handleBack } = useFinancial();
  const [filter, setFilter] = useState<FinancialResumeFilterProps>(filterInitialState);
  const [ordination, setOrdination] = useState<Ordination>({ field: 'valor', direction: 'asc' });
  const { financialResume, groupedTitles, loading, fetch, report } = useFetchFinancialResume(filter, ordination);
  const totals = useFinancialResumeTotal(financialResume, loading);
  const [isOpenFilterMore, setIsOpenFilterMore] = useState(false);
  const { isMobile } = useApp();

  function handleSubmit(initialDate: Date, finalDate: Date) {
    fetch(initialDate, finalDate);
  }

  function handleFilterChange(index: keyof FinancialResumeFilterProps, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <InvoicesResumeProvider
      value={{
        handleFilterChange,
        groupedTitles,
        filter,
        financialResume,
        handleSubmit,
        loading,
        totals,
        report,
        ordination,
        setOrdination,
        setIsOpenFilterMore,
        isOpenFilterMore,
      }}
    >
      <Appbar title="Resumo por classificação" backAction={handleBack} ActionsComponent={<FinancialAppbarActions />} />

      <PageHeader title="Resumo por classificação" />

      <InvoicesResumeFilterBox />

      {isOpenFilterMore && <FinancialResumeMobileFilter onExited={() => setIsOpenFilterMore(false)} />}

      <Container>
        {loading ? (
          <InsideLoading />
        ) : report.length === 0 ? (
          <NoData background="inherit" message="nada para mostrar" />
        ) : (
          <>
            <FinancialResumeTotals />

            {isMobile ? <FinancialResumeMobileReport /> : <InvoicesResumeReport />}

            <div style={{ flex: 1 }}>
              <GroupedResumeReport />
            </div>
          </>
        )}
      </Container>
    </InvoicesResumeProvider>
  );
};

export default FinancialResume;
