import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Justification } from 'types/justification';

interface JustificationsContext {
  selectedJustification: Justification | null;
  setSelectedJustification: Dispatch<SetStateAction<Justification | null>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  handleSearch(): void;
}

const Context = createContext<JustificationsContext>({} as JustificationsContext);
export const JustificationsProvider = Context.Provider;

export function useJustifications(): JustificationsContext {
  return useContext(Context);
}
