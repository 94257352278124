import React, { Dispatch, SetStateAction } from 'react';
import { Menu, MenuItem } from '@mui/material';

type FinancialCardMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const FinancialCardMenu: React.FC<FinancialCardMenuProps> = ({ anchorEl, setAnchorEl }) => {
  function printTicket() {
    window.open('');

    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={printTicket}>Imprimir boleto</MenuItem>
    </Menu>
  );
};

export default FinancialCardMenu;
