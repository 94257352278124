import React, { useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import InsideLoading from 'components/loading/InsideLoading';
import NoData from 'components/no-data/NoData';
import { endOfDay, startOfDay } from 'date-fns';
import { FinancialSummaryProvider } from './hooks/useFinancialSummary';
import FinancialSummaryFilterBox from './FinancialSummaryFilterBox';
import { useFetchFinancialSummary } from './hooks/useFetchSummary';
import { useInvoicesTotal } from 'pages/moviment/query/hooks/useInvoicesTotal';
import FinancialSummaryTotal from './FinancialSummaryTotal';
import SummaryInvoices from './title/SummaryInvoices';
import SummaryExpenses from './expenses/SummaryExpenses';
import { IconButton, styled } from '@mui/material';
import SummaryRevenues from './revenue/SummaryRevenues';
import SummaryPersonnel from './personnel/SummaryPersonnel';
import { InvoiceSummary } from 'types/summary/invoiceSummary';
import SummaryResultPage from './result/SummaryResult';
import { SummaryResult } from 'types/summary/summaryResult';
import { useFinancial } from 'pages/financial/hooks/useFinancial';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export interface FinancialSummaryFilterParams {
  initial_date: Date | null;
  final_date: Date | null;
  month_year: Date | null;
  branchIds: number[];
  company: string;
}

const filterInitialState = {
  initial_date: startOfDay(new Date()),
  final_date: endOfDay(new Date()),
  month_year: null,
  branchIds: [],
  company: 'all',
};

const Container = styled('div')({
  gap: 15,
  display: 'flex',
  flexDirection: 'column',
});

const FinancialSummary: React.FC = () => {
  const { handleBack } = useFinancial();
  const [showTotals, setShowTotals] = useState(false);
  const [filter, setFilter] = useState<FinancialSummaryFilterParams>(filterInitialState);
  const [invoices, loading, fetch] = useFetchFinancialSummary(filter);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceSummary | null>(null);
  const [selectedResult, setSelectedResult] = useState<SummaryResult | null>(null);
  const invoicesTotal = useInvoicesTotal(invoices, loading);

  function handleSearch() {
    fetch();
  }

  function handleChangeFilter(index: keyof FinancialSummaryFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <FinancialSummaryProvider
      value={{
        filter,
        showTotals,
        handleChangeFilter,
        handleSearch,
        loading,
        invoicesTotal,
        setSelectedInvoice,
        selectedInvoice,
        selectedResult,
        setSelectedResult,
      }}
    >
      <Appbar
        backAction={handleBack}
        title="Resumo financeiro"
        ActionsComponent={
          <IconButton color="inherit" onClick={() => setShowTotals(!showTotals)}>
            {showTotals ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        }
      />

      <PageHeader title="Resumo financeiro" />

      <FinancialSummaryFilterBox />
      <FinancialSummaryTotal />
      {loading ? (
        <InsideLoading />
      ) : invoices.length === 0 ? (
        <NoData message="Nada para mostrar" />
      ) : (
        <Container>
          <SummaryInvoices />

          <SummaryExpenses />

          <SummaryRevenues />

          <SummaryPersonnel />

          <SummaryResultPage />
        </Container>
      )}
    </FinancialSummaryProvider>
  );
};

export default FinancialSummary;
