import axios, { AxiosResponse } from 'axios';

type ViaCepResponse = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
  erro?: string;
};

const baseUrl = 'https://viacep.com.br/ws/';

function getUrl(cep: string) {
  return `${baseUrl}${cep}/json/`;
}

function postalCodeSearch(cep: string): Promise<AxiosResponse<ViaCepResponse>> {
  return axios.get(getUrl(cep));
}

export { postalCodeSearch };
