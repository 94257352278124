import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Project } from 'types/project';
import { MoreHoriz } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { useProjects } from 'pages/projects/hook/useProjects';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
});

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
  width: 350,
});

type ProjectItemTableProps = {
  project: Project;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const ProjectItemTable: React.FC<ProjectItemTableProps> = ({ project, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const { setSelectedProject } = useProjects();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    setSelectedProject(project);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <CustomIconButton onClick={handleMoreClick} key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
              <Tooltip title={project[item.id]}>
                <Description variant="caption">{project[item.id as any]}</Description>
              </Tooltip>
            </div>
          ),
        )}
    </>
  );
};

export default ProjectItemTable;
