import { TableTemplate } from 'types/tableTemplate';

export const cardTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 80,
  },
  {
    id: 'bandeira',
    description: 'Bandeira',
    originalId: 'bandeira',
    width: 110,
  },
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 110,
  },
  {
    id: 'Autorizacao',
    description: 'Autorização',
    originalId: 'Autorizacao',
    width: 90,
  },
  {
    id: 'numero_venda',
    description: 'Número venda',
    originalId: 'numero_venda',
    width: 90,
  },
  {
    id: 'data_lanc',
    description: 'Data lanc',
    originalId: 'data_lanc',
    width: 120,
  },
  {
    id: 'data_venc',
    description: 'Data venc',
    originalId: 'data_venc',
    width: 120,
  },
  {
    id: 'forma_pagamento',
    description: 'Forma Pag.',
    originalId: 'forma_pagamento',
    width: 120,
  },
  {
    id: 'formattedValue',
    description: 'VL recebido',
    originalId: 'vl_recebido',
    width: 80,
  },
  {
    id: 'formattedDiscount',
    description: 'VL Desc.',
    originalId: 'vl_desconto',
    width: 80,
  },
  {
    id: 'formattedRate',
    description: 'Taxa',
    originalId: 'taxa',
    width: 60,
  },
  {
    id: 'parcela',
    description: 'Qtd de parcelas',
    originalId: 'parcela',
    width: 100,
  },
  {
    id: 'posicao_parcela',
    description: 'Posição parcela',
    originalId: 'posicao_parcela',
    width: 100,
  },
];
