import React from 'react';
import { List, styled } from '@mui/material';
import { AbcProduct } from 'types/abcProduct';
import { usePagination } from 'hooks/pagination';
import AbcProductItemModule from './AbcProductItemModule';

type AbcProductListModuleProps = {
  products: AbcProduct[];
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

const AbcProductListModule: React.FC<AbcProductListModuleProps> = ({ products }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => (
        <AbcProductItemModule key={product.cod} product={product} />
      ))}
    </CustomList>
  );
};

export default AbcProductListModule;
