import React, { DragEvent, useState } from 'react';
import AddPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Zoom, IconButton, Typography, Button, CircularProgress, Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageSelector from 'components/image-selector/ImageSelector';
import { PartnerValidation } from './validation/partnerValidation';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import { Partner } from 'types/partner';
import { makeStyles } from '@mui/styles';

interface MakeStyleProps {
  error: boolean;
}

const useStyles = makeStyles<Theme, MakeStyleProps>(theme => ({
  image: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#eee',
  },
  imageContainer: ({ error }: { error: boolean }) => ({
    display: 'flex',
    width: '100%',
    height: 200,
    border: `2px dashed ${error ? theme.palette.error.main : alpha(theme.palette.primary.main, 0.2)}`,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginTop: 30,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
  }),
  inDraggableZone: {
    border: `3px dashed ${theme.palette.primary.main}`,
    opacity: 0.5,
  },
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    '& > svg': {
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
  },
  label: {
    display: 'flex',
    cursor: 'pointer',
    minWidth: 110,
  },
  icon: {
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  loading: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: 'rgba(250, 250, 250, 1)',
  },
}));

type PartnerImageFormProps = {
  partner: Partner;
  handleChange(index: string, value: any): void;
  validation: PartnerValidation;
};

const PartnerImageForm: React.FC<PartnerImageFormProps> = ({ partner, handleChange, validation }) => {
  const [imageSelector, setImageSelector] = useState(false);
  const classes = useStyles({ error: !!validation.image });
  const [selected, setSelected] = useState(false);
  const [dragIn, setDragIn] = useState(false);
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();

  function handleUploadFiles(files: FileList | null) {
    if (!files) return;

    const form = new FormData();
    Array.from(files).forEach((file, index) => {
      form.append(`files[${index}]`, file);
    });

    setSaving(true);

    api
      .post('/images', form)
      .then(response => {
        handleChange('image', response.data[0]);
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível carregar a imagem');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDropFile(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleUploadFiles(files);
    setDragIn(false);
  }

  function handleDragEnter(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  function handleDragLeave(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(false);
  }

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  return (
    <>
      {imageSelector && (
        <ImageSelector
          onExited={() => setImageSelector(false)}
          handleSetImageId={image => handleChange('image', image)}
        />
      )}
      {!partner.image ? (
        <>
          <div
            className={dragIn ? `${classes.imageContainer} ${classes.inDraggableZone}` : classes.imageContainer}
            onDrop={e => handleDropFile(e)}
            onDragLeave={e => handleDragLeave(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragOver={e => handleDragOver(e)}
            draggable
          >
            {saving ? (
              <CircularProgress />
            ) : (
              <IconButton size="medium" onClick={() => setImageSelector(true)}>
                <AddPhotoIcon color="primary" />
              </IconButton>
            )}
          </div>
        </>
      ) : (
        <div className={classes.imageContainer} onClick={() => setSelected(selected => !selected)}>
          <Zoom in={selected}>
            <div className={classes.imageWrapper}>
              <CheckCircleIcon className={classes.icon} color="primary" />
              <Button size="small" variant="contained" color="primary" onClick={() => handleChange('image', null)}>
                Remover
              </Button>
            </div>
          </Zoom>
          <img alt="Foto do produto" className={classes.image} src={partner.image.imageUrl} />
        </div>
      )}
      {validation.image ? (
        <Typography variant="caption" color="error">
          O logo do parceiro é obrigatório
        </Typography>
      ) : (
        <Typography variant="caption" color="primary" display="block">
          Logo do parceiro, imagem de 180 x 50
        </Typography>
      )}
    </>
  );
};

export default PartnerImageForm;
