import React, { useState } from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import CustomerItemModule from './CustomerItemModule';
import CustomersListMenu from '../CustomersListMenu';
import { Customer } from 'types/customers';

type CustomerListModuleProps = {
  customers: Customer[];
};

const CustomerListModule: React.FC<CustomerListModuleProps> = ({ customers }) => {
  const { rowsPerPage, page } = usePagination();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <Grid container>
      <CustomersListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <Grid item xs={12}>
        <List>
          {customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(customer => (
            <CustomerItemModule setAnchorEl={setAnchorEl} key={customer.id} customer={customer} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default CustomerListModule;
