import React, { useEffect, useRef, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from '@mui/material';
import { BannerValidation } from './validation/bannerValidation';
import ImageSelector from 'components/image-selector/ImageSelector';
import ImagePreview from 'components/image-preview/ImagePreview';
import BannerImageForm from './BannerImageForm';
import { Banner } from 'types/banner';
import { api } from 'services/api';
import { Page } from 'types/page';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  switch: {
    marginTop: 20,
  },
  positionContainer: {
    margin: '30px 0 0',
  },
  container: {
    marginBottom: 200,
  },
});

type BannerFormProps = {
  banner: Banner;
  handleChange(index: keyof Banner, value: any): void;
  validation: BannerValidation;
};

const BannerForm: React.FC<BannerFormProps> = ({ banner, handleChange, validation }) => {
  const classes = useStyles();

  const [imageSelector, setImageSelector] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<null | string>('');
  const [pages, setPages] = useState<Page[]>([]);

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    link: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  useEffect(() => {
    api.get('/pages').then(response => {
      setPages(response.data);
    });
  }, []);

  return (
    <>
      {!!selectedImageSrc && (
        <ImagePreview
          src={selectedImageSrc}
          description="Imagem da mensagem de e-mail"
          onExited={() => setSelectedImageSrc('')}
        />
      )}
      {imageSelector && (
        <ImageSelector
          handleSetImageId={image => handleChange('image', image)}
          onExited={() => setImageSelector(false)}
        />
      )}
      <Grid item xs={12} xl={4} lg={4} md={6} className={classes.container}>
        <TextField
          inputRef={inputs.name}
          error={!!validation.name}
          helperText={validation.name}
          label="Título"
          placeholder="Digite um título para o banner"
          margin="normal"
          fullWidth
          value={banner.name}
          onChange={e => handleChange('name', e.target.value)}
          autoFocus
        />

        <TextField
          select
          label="Página destino"
          placeholder="Página destino"
          value={banner.link}
          onChange={e => handleChange('link', e.target.value)}
          fullWidth
          margin="normal"
        >
          {pages.map(page => (
            <MenuItem value={page.url} key={page.id}>
              {page.title}
            </MenuItem>
          ))}
        </TextField>

        <BannerImageForm validation={validation} banner={banner} handleChange={handleChange} />

        <div className={classes.positionContainer}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Posição na pagina inicial</FormLabel>
            <RadioGroup name="gender1" value={banner.position} onChange={e => handleChange('position', e.target.value)}>
              <FormControlLabel value="top" control={<Radio color="primary" />} label="Parte superior" />
              <FormControlLabel value="bottom" control={<Radio color="primary" />} label="Parte inferior" />
            </RadioGroup>
          </FormControl>
        </div>

        <FormControlLabel
          label="Ativo"
          className={classes.switch}
          control={
            <Switch
              color="primary"
              onChange={event => handleChange('active', event.target.checked)}
              checked={banner.active}
            />
          }
        />

        <button type="submit" style={{ display: 'none' }} />
      </Grid>
    </>
  );
};

export default BannerForm;
