import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import BuyerItemModule from './BuyerItemModule';
import { H2iBuyer } from 'types/h2iBuyer';

type BuyerListModuleProps = {
  buyers: H2iBuyer[];
};

const BuyerListModule: React.FC<BuyerListModuleProps> = ({ buyers }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {buyers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(buyer => (
            <BuyerItemModule key={buyer.id} buyer={buyer} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default BuyerListModule;
