import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, IconButton } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { Agent } from 'types/agent';
import { useCustomerAgents } from '../hook/useCustomerAgents';
import { makeStyles } from '@mui/styles';

interface CustomerAgentTableItemProps {
  agent: Agent;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
}

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

const CustomerAgentTableItem: React.FC<CustomerAgentTableItemProps> = ({ agent, setAnchorEl }) => {
  const { setSelectedAgent } = useCustomerAgents();
  const classes = styles();

  function handleButtonClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedAgent(agent);
  }

  return (
    <>
      <IconButton onClick={handleButtonClick} className={classes.iconButton}>
        <MoreHoriz />
      </IconButton>

      <Typography>{agent.nome}</Typography>
      <Typography>{agent.cpf}</Typography>
      <Typography>{agent.rg}</Typography>
      <Typography>{agent.cep}</Typography>
      <Typography>{agent.endereco}</Typography>
      <Typography>{agent.cidade}</Typography>
      <Typography>{agent.telefone}</Typography>
      <Typography>{agent.email}</Typography>
    </>
  );
};

export default CustomerAgentTableItem;
