import { download } from 'helpers/download';
import { uuidv4 } from 'helpers/uuidv4';
import { useState } from 'react';
import { api } from 'services/api';

type UseDefaultExcelExport = {
  handleDownload(): Promise<void>;
  isDownloading: boolean;
};

export function useDefaultExcelExport(data: any): UseDefaultExcelExport {
  const [isDownloading, setIsDownloading] = useState(false);

  async function handleDownload() {
    setIsDownloading(true);

    try {
      const response = await api.post('/excel-exports', data, { responseType: 'blob' });

      download(response.data, uuidv4());
    } catch (err) {
      console.error(err);
    } finally {
      setIsDownloading(false);
    }
  }

  return {
    handleDownload,
    isDownloading,
  };
}
