import { TableTemplate } from 'types/tableTemplate';

export const expensesTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'id_filial',
    description: 'ID Filial',
    originalId: 'id_filial',
    width: 80,
  },
  {
    id: 'conta_contabil',
    description: 'Cód Contábil',
    originalId: 'conta_contabil',
    width: 120,
  },
  {
    id: 'nome',
    description: 'Unidade',
    originalId: 'nome',
    width: 350,
  },
];
