import React, { useEffect, useState, FormEvent } from 'react';

import { useSelector } from 'store/selector';
import Appbar from 'components/appbar/Appbar';
import { useAuth } from 'hooks/auth';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import AccountForm from './AccountForm';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import { Image } from 'types/image';
import AccountActions from './AccountActions';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/modules/user/actions';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const styles = makeStyles<Theme>(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '300px 1fr',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

export type LocalUser = {
  id: number | string;
  name: string;
  username: string;
  email: string;
  image: Image | null;
  isImageSelected: boolean;
};

export type UserValidation = {
  username?: string;
  email?: string;
  name?: string;
};

const Account: React.FC = () => {
  const classes = styles();
  const auth = useAuth();
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const messaging = useMessaging();
  const [validation, setValidation] = useState<UserValidation>({});
  const dispatch = useDispatch();
  const [localUser, setLocalUser] = useState<LocalUser>({
    username: '',
    email: '',
    name: '',
    image: null,
    isImageSelected: false,
    id: '',
  });

  useEffect(() => {
    if (!user) return;
    setLocalUser({
      ...user,
      isImageSelected: false,
    });
  }, [user]);

  function handleChange(index: string, value: any) {
    setLocalUser({
      ...localUser,
      [index]: value,
    });
  }

  function handleResetEmail() {
    setLoading(true);

    api
      .post('/password/email', { email: localUser.email })
      .then(() => {
        messaging.handleOpen(`Uma mensagem de e-mail foi enviada para ${localUser.email}`);
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Aconteceu um erro. Não foi possível enviar e-mail');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
      email: yup.string().email('Informe um e-mail válido').required('Informe o email'),
      username: yup.string().required('O nome de usuário é obrigatório'),
    });

    schema
      .validate(user)
      .then(() => {
        handleSubmit();
        setValidation({});
      })
      .catch((err: yup.ValidationError) => {
        if (err instanceof yup.ValidationError) {
          setValidation({
            [err.path as string]: err.message,
          });
        }

        throw err;
      });
  }

  function handleSubmit() {
    setLoading(true);

    api
      .put(`/users/${localUser.id}`, localUser)
      .then(response => {
        messaging.handleOpen('Salvo');
        dispatch(setUser(response.data));
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar o usuário');
      });
  }

  return (
    <>
      {(auth.loggingOff || loading) && <Loading />}
      <Appbar title="Minha conta" ActionsComponent={<AccountActions handleSubmit={handleValidation} />} />
      {!user ? (
        <InsideLoading />
      ) : (
        <div>
          <PageHeader title={`Olá ${user.name}`} description="Aqui você alterar os dados da sua conta" />
          <div className={classes.container}>
            <AccountForm
              validation={validation}
              localUser={localUser}
              handleChange={handleChange}
              handleResetEmail={handleResetEmail}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Account;
