import React from 'react';
import { Typography, styled } from '@mui/material';
import { TransferAccount } from 'types/transferAccount';
import { accountsTableTemplate } from '../../accountsTableTemplate';

type TransferAccountItemTableProps = {
  account: TransferAccount;
};

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

const TransferAccountItemTable: React.FC<TransferAccountItemTableProps> = ({ account }) => {
  return (
    <>
      {accountsTableTemplate.map(item => (
        <NumericData key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
          <Typography variant="caption">{account[item.id]}</Typography>
        </NumericData>
      ))}
    </>
  );
};

export default TransferAccountItemTable;
