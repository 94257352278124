import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Typography, Theme } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import { AbcProductUnity } from 'types/abcProduct';
import { makeStyles } from '@mui/styles';
import { arraySort } from 'helpers/sort';
import { externalTooltipHandler } from 'components/chart/externalTooltip';

interface UnityChartProps {
  formattedTotalChart: AbcProductUnity[];
}

const useStyles = makeStyles<Theme>(theme => ({
  containerChart: {
    gap: 30,
    display: 'grid',
    padding: '15px 0',
    position: 'relative',
    gridTemplateColumns: '300px 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  contentChart: {},
  legend: {
    display: 'flex',
    flexDirection: 'column',
  },
  total: {
    color: '#008000',
  },
  item: {
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    '&.marginTop': {
      marginTop: 5,
    },
  },
}));

const UnityChart: React.FC<UnityChartProps> = ({ formattedTotalChart }) => {
  const classes = useStyles();
  const pieData = useMemo(() => {
    return formattedTotalChart
      .filter(item => item.type !== 'subtitle')
      .sort((a, b) => b.total - a.total)
      .map(item => item.total);
  }, [formattedTotalChart]);

  const sorted = arraySort('unidade', formattedTotalChart, 'asc');

  return (
    <div className={classes.containerChart}>
      <div className={classes.legend}>
        <Typography variant="caption">
          <b>Legenda</b>
        </Typography>
        {sorted.map(item => (
          <Typography
            className={`${classes.item} ${item.type === 'subtitle' && 'marginTop'}`}
            variant="subtitle2"
            key={item.uuid}
          >
            {item.type === 'subtitle' && (
              <>
                {item.unidade} <br />
              </>
            )}
            {item.formattedName} <b className={classes.total}>{item.formattedTotal}</b>
          </Typography>
        ))}
      </div>
      <div className={classes.contentChart}>
        <Bar
          data={{
            labels: formattedTotalChart
              .filter(item => item.type !== 'subtitle')
              .sort((a, b) => b.total - a.total)
              .map(item => `${item.unidade} - ${item.formattedName}`),
            datasets: [
              {
                label: 'Unidades',
                data: formattedTotalChart
                  .filter(item => item.type !== 'subtitle')
                  .sort((a, b) => b.total - a.total)
                  .map(item => item.total),
                stack: 'combined',
                backgroundColor: '#30519f',
                borderWidth: 1,
              },
            ],
          }}
          options={{
            plugins: {
              tooltip: {
                enabled: false,
                callbacks: {
                  label: tooltip => moneyFormat(pieData[tooltip.dataIndex as number]),
                },
                external: externalTooltipHandler,
              },
            },
            responsive: true,
          }}
        />
      </div>
    </div>
  );
};

export default UnityChart;
