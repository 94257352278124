import React, { FormEvent, useEffect, useState, ReactNode } from 'react';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import InsideSaving from 'components/loading/InsideSaving';
import Appbar from 'components/appbar/Appbar';
import CompatibilityNewAppbarButtons from './RelatedProductNewAppbarButtons';
import { styled } from '@mui/material';
import { RelatedProductProvider } from '../hook/useRelatedProduct';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import history from 'services/history';
import { TAB_CONTENT_WITH_FULL_WIDTH } from 'pages/projects/constants/tabContentWithFullWidth';
import { Compatibility } from 'types/compatibility';
import { uuidv4 } from 'helpers/uuidv4';
import CompatibilityTab, { RelatedProductTabValues } from './tabs/RelatedProductTab';
import { useRelatedProductValidation } from '../validation/relatedProductValidation';
import CompatibilityProducts from '../form/products/RelatedProductItems';
import CompatibilityMain from '../form/CompatibilityMain';
import { RelatedProduct } from 'pages/related-product/types/relatedProduct';

type ContentProps = {
  maxWidth: string | number;
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Content = styled('div')<ContentProps>(props => ({
  display: 'flex',
  borderTop: '1px solid #eee',
  flexDirection: 'column',
  flex: 1,
  maxWidth: props.maxWidth,
  marginTop: 20,
  marginBottom: 200,
}));

export const relatedProductInitialState: RelatedProduct = {
  id: uuidv4(),
  description: '',
  products: [],
  type: 'related',
};

const RelatedProductNew: React.FC = () => {
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState<RelatedProductTabValues>('main');
  const [validation, setValidation, validate] = useRelatedProductValidation();
  const [relatedProduct, setRelatedProduct] = useState(relatedProductInitialState);

  useEffect(() => {
    const main = ['description'];

    const [key] = Object.keys(validation);

    if (!key) {
      return;
    }

    if (main.includes(key)) {
      setTab('main');
      return;
    }

    setTab('products');
  }, [validation]);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});

    if (!relatedProduct) {
      return;
    }

    validate(relatedProduct)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.error(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    const data = {
      description: relatedProduct.description,
      product_ids: relatedProduct.products.map(product => product.id),
      type: 'related',
    };

    api
      .post('/compatibilities', data)
      .then(() => {
        messaging.handleOpen('Produto similar criado com sucesso!');
        history.push('/related-products');
      })
      .catch(err => {
        console.error(err);
        setSaving(false);
      });
  }

  function handleChange(index: keyof Compatibility, value: any) {
    setRelatedProduct(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleTabsRendering(): ReactNode {
    const tabContents: Record<RelatedProductTabValues, ReactNode> = {
      main: <CompatibilityMain validation={validation} />,
      products: <CompatibilityProducts />,
    };

    return tabContents[tab];
  }

  return (
    <RelatedProductProvider value={{ relatedProduct, handleChange, setRelatedProduct }}>
      <Appbar
        title="Novo produto similar"
        Tab={<CompatibilityTab tab={tab} handleChange={tab => setTab(tab)} />}
        ActionsComponent={<CompatibilityNewAppbarButtons handleValidation={handleValidation} />}
      />

      {saving && <InsideSaving />}

      <Container>
        <PageHeaderActions
          backUrl="/related-products"
          title="Novo produto similar"
          description="Cadastro de produtos"
        />

        <Content maxWidth={TAB_CONTENT_WITH_FULL_WIDTH.includes(tab) ? '100%' : 600}>{handleTabsRendering()}</Content>
      </Container>
    </RelatedProductProvider>
  );
};

export default RelatedProductNew;
