import React, { useCallback, useEffect, useState } from 'react';
import { Button, styled } from '@mui/material';
import { AppropriationDateProvider } from './hooks/useApproprationDate';
import Appbar from 'components/appbar/Appbar';
import { Add } from '@mui/icons-material';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'hooks/app';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import TableContainer from 'components/table/TableContainer';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { AppropriationDate } from 'types/appropriationDate';
import { appropriationDateTableTemplate } from './appropriationDateTableTemplate';
import AppropriationDateFilterBox from './AppropriationDateFilterBox';
import NewAppropriationDate from './register/new/NewAppropriationDate';
import UpdateAppropriationDate from './register/update/UpdateAppropriationDate';
import AppropriationDateListTable from './list/table/AppropriationDateListTable';
import AppropriationDateListModule from './list/module/AppropriationDateListModule';
import { useFinancial } from 'pages/financial/hooks/useFinancial';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const CustomIconButton = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export type AppropriationDateParams = {
  filtro: string;
};

const paramsInitialValue: AppropriationDateParams = {
  filtro: '',
};

const AppropriationDatePage: React.FC = () => {
  const { handleBack } = useFinancial();
  const { financialApi, isMobile, windowWidth } = useApp();
  const [loading, setLoading] = useState(true);
  const [orderedIndex, sort] = useTableOrder();
  const [dates, setDates] = useState<AppropriationDate[]>([]);
  const [filtered, setFiltered] = useState<AppropriationDate[]>([]);
  const [dialogNewDate, setDialogNewDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState<null | AppropriationDate>(null);
  const [params, setParams] = useState<AppropriationDateParams>(paramsInitialValue);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');

  function handleChangeParams(index: keyof AppropriationDateParams, value: any) {
    setParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  const loadAppropriationDates = useCallback(() => {
    setLoading(true);

    financialApi
      .get('/getdataapropriacao')
      .then(response => {
        if (response.data.MESSAGE) {
          setDates([]);
          return;
        }
        setDates(
          response.data.map(item => {
            item.data = item.data.replace(/\\/g, '');
            return item;
          }),
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [financialApi]);

  useEffect(() => {
    loadAppropriationDates();
  }, [loadAppropriationDates]);

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function onExited() {
    setSelectedDate(null);
    setDialogNewDate(false);
    loadAppropriationDates();
  }

  useEffect(() => {
    setFiltered(dates);
  }, [dates]);

  return (
    <AppropriationDateProvider value={{ selectedDate, setSelectedDate }}>
      {dialogNewDate && <NewAppropriationDate onExited={onExited} />}
      {selectedDate && <UpdateAppropriationDate onExited={onExited} />}

      <Appbar
        backAction={handleBack}
        title="Data de Apropriação"
        ActionsComponent={
          <CustomIconButton color="inherit" onClick={() => setDialogNewDate(true)}>
            <Add />
          </CustomIconButton>
        }
      />

      <PageHeaderActions
        title="Cadastro Data de Apropriação"
        ActionComponent={
          <Button
            startIcon={<Add />}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setDialogNewDate(true)}
          >
            Novo
          </Button>
        }
      />

      <TableContainer tableTemplate={appropriationDateTableTemplate}>
        <AppropriationDateFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          loadAppropriationDates={loadAppropriationDates}
          params={params}
          handleChangeParams={handleChangeParams}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum registro encontrado" />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <AppropriationDateListTable dates={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <AppropriationDateListModule dates={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          </PaginationProvider>
        )}
      </TableContainer>
    </AppropriationDateProvider>
  );
};

export default AppropriationDatePage;
