import { api } from 'services/api';
import { useEffect, useState } from 'react';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { CourseStudent } from '../types/courseStudent';
import { rentalInitialState } from '../registration/reducer/reducer';

type UseFetchCourseStudent = [CourseStudent, boolean];

export function useFetchCourseStudent(id?: string): UseFetchCourseStudent {
  const [loading, setLoading] = useState(true);
  const { showErrorDialog } = useErrorHandler();
  const [courseStudent, setCourseStudent] = useState<CourseStudent>(rentalInitialState);

  useEffect(() => {
    api
      .get(`/course-students/${id}`)
      .then(response => {
        setCourseStudent(response.data);
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível recuperar a locação',
        });
      })
      .finally(() => setLoading(false));
  }, [id, showErrorDialog]);

  return [courseStudent, loading];
}
