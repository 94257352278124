import React from 'react';
import { List } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import ManufacturerItemModule from './ManufacturerItemModule';
import { AbcProductManufacturer } from 'types/abcProduct';
import { makeStyles } from '@mui/styles';

type ManufacturerListModuleProps = {
  manufacturers: AbcProductManufacturer[];
};

const styles = makeStyles({
  list: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: 100,
    flex: 1,
  },
});

const ManufacturerListModule: React.FC<ManufacturerListModuleProps> = ({ manufacturers }) => {
  const { rowsPerPage, page } = usePagination();
  const classes = styles();

  return (
    <List className={classes.list}>
      {manufacturers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((manufacturer, index) => (
        <ManufacturerItemModule key={index + 1} manufacturer={manufacturer} />
      ))}
    </List>
  );
};

export default ManufacturerListModule;
