import React, { useState } from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import { Button, TextField } from '@mui/material';
import DocumentUpload from 'components/document-upload/DocumentUpload';
import { useCreditLimitRequest } from 'pages/credit-limit-requests/hooks/useCreditLimitRequest';
import { NewCreditLimitRequestDocument } from 'types/creditLimitRequest';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    margin: '30px 0',
  },
});

interface ModaNewDocumentProps {
  onExited(): void;
}
const ModalNewDocument: React.FC<ModaNewDocumentProps> = ({ onExited }) => {
  const classes = styles();

  const { handleAddDocument } = useCreditLimitRequest();
  const [creditLimitRequestDocument, setCreditLimitRequestDocument] = useState<NewCreditLimitRequestDocument>({
    description: '',
    document: null,
  });

  function handleChange(index: keyof NewCreditLimitRequestDocument, value: any) {
    setCreditLimitRequestDocument(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleConfirm(handleModalClose: () => void) {
    handleModalClose();
    handleAddDocument(creditLimitRequestDocument);
  }

  return (
    <DialogInput onExited={onExited} maxWidth="sm">
      <DialogInputConsumer>
        {context => (
          <>
            <TextField
              label="Descrição"
              placeholder="Descrição do arquivo"
              margin="normal"
              value={creditLimitRequestDocument.description}
              onChange={e => handleChange('description', e.target.value)}
              fullWidth
            />
            <DocumentUpload
              handleRemoveImage={() => handleChange('document', null)}
              label="Novo documento"
              document={creditLimitRequestDocument.document}
              handleSetImage={document => handleChange('document', document)}
              className={classes.container}
            />
            <Button
              disabled={!creditLimitRequestDocument.document || !creditLimitRequestDocument.description}
              variant="contained"
              color="primary"
              onClick={() => handleConfirm(context.handleClose)}
            >
              Confirmar
            </Button>
          </>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default ModalNewDocument;
