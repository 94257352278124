import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { Sale } from '../../pages/reports/commercial/abc/product/modal/branch/SelectedBranchListMenu';
import { Typography } from '@mui/material';
import { useApp } from 'hooks/app';
import SelectedSaleMobile from './SelectedSaleMobile';

import { makeStyles } from '@mui/styles';

interface SelectedSaleProps {
  onExited(): void;
  selectedSale: Sale;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px dashed #666',
    borderRight: '1px dashed #666',
    '& > div:nth-child(even)': {
      background: '#eefcee',
    },
  },
  row: {
    flex: 1,
    gap: 30,
    padding: 5,
    display: 'flex',
  },
  rowMoreItems: {
    flex: 1,
    padding: 5,
    display: 'flex',
    justifyContent: 'space-between',
  },
  rowItem: {
    display: 'flex',
    gap: 30,
  },
  divider: {
    height: 25,
    display: 'grid',
    padding: '0px 5px',
    alignContent: 'center',
  },
  dividerItem: {
    borderTop: '1px dashed #666',
  },
  grid: {
    display: 'grid',
    padding: '0px 5px',
    gridTemplateColumns: '60px 3fr 60px 0.6fr 0.6fr',
  },
  itemNumeric: {
    textAlign: 'end',
  },
  dividerItemSum: {
    borderTop: '1px dashed #666',
    width: '50%',
    justifySelf: 'end',
  },
  grid2: {
    display: 'grid',
    padding: '0px 5px',
    gridTemplateColumns: '3fr 1fr 1fr',
  },
  grid2Subgroup: {
    display: 'grid',
    padding: '0px 5px',
    gridTemplateColumns: '2fr 1fr 0.5fr 1fr',
  },
  borderLeft: {
    left: -10,
    height: '100%',
    display: 'grid',
    position: 'absolute',
  },
  borderRight: {
    right: -10,
    height: '100%',
    display: 'grid',
    position: 'absolute',
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: 50,
    backgroundColor: '#eee',
    border: '1px solid #666',
  },
  position: {
    position: 'relative',
    padding: '0 10px',
  },
});

const SelectedSale: React.FC<SelectedSaleProps> = ({ onExited, selectedSale }) => {
  const classes = useStyles();
  const { isMobile, windowWidth } = useApp();

  return (
    <Dialog onExited={onExited} maxWidth="md" title="Detalhe da venda">
      {windowWidth > 960 && !isMobile ? (
        <div className={classes.position}>
          <div className={classes.borderLeft}>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
          </div>
          <div className={classes.borderRight}>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
            <div className={classes.circle}></div>
          </div>
          <div className={classes.container}>
            <div className={classes.row}>
              <Typography>Unidade:</Typography>
              <Typography>{selectedSale.filial}</Typography>
            </div>

            <div className={classes.row}>
              <Typography>Cliente:</Typography>
              <Typography>{selectedSale.cliente}</Typography>
            </div>

            <div className={classes.row}>
              <Typography>Cnpj Cpf:</Typography>
              <Typography>{selectedSale.cnpj_cliente}</Typography>
            </div>

            <div className={classes.divider}>
              <div className={classes.dividerItem}></div>
            </div>

            <div className={classes.rowMoreItems}>
              <div className={classes.rowItem}>
                <Typography>CEP:</Typography>
                <Typography>{selectedSale.cep}</Typography>
              </div>

              <div className={classes.rowItem}>
                <Typography>Endereço:</Typography>
                <Typography>{selectedSale.endereco}</Typography>
              </div>

              <div className={classes.rowItem}>
                <Typography>UF:</Typography>
                <Typography>{selectedSale.uf}</Typography>
              </div>
            </div>

            <div className={classes.divider}>
              <div className={classes.dividerItem}></div>
            </div>

            <div className={classes.rowMoreItems}>
              <div className={classes.rowItem}>
                <Typography>Data Ped:</Typography>
                <Typography>{selectedSale.data}</Typography>
              </div>

              <div className={classes.rowItem}>
                <Typography>Vendedor:</Typography>
                <Typography>{selectedSale.vendedor}</Typography>
              </div>
            </div>

            <div className={classes.row}>
              <Typography>Pedido Por:</Typography>
              <Typography>{selectedSale.pedido_por}</Typography>
            </div>

            <div className={classes.rowMoreItems}>
              <div className={classes.rowItem}>
                <Typography>Nº Venda:</Typography>
                <Typography>{selectedSale.numero_venda}</Typography>
              </div>

              <div className={classes.rowItem}>
                <Typography>Nota Fiscal:</Typography>
                <Typography>{selectedSale.nota_fiscal}</Typography>
              </div>
            </div>

            <div className={classes.row}>
              <Typography>Nº DAV:</Typography>
              <Typography>{selectedSale.numero_dav}</Typography>
            </div>

            <div className={classes.divider}>
              <div className={classes.dividerItem}></div>
            </div>

            <div className={classes.grid}>
              <Typography>Cod</Typography>
              <Typography>Produto</Typography>
              <Typography>Qtd</Typography>
              <Typography className={classes.itemNumeric}>Vlr Unit.</Typography>
              <Typography className={classes.itemNumeric}>Vlr Total</Typography>
            </div>

            {selectedSale.itens.map((item, index) => (
              <div className={classes.grid} key={index}>
                <Typography>{item.id_produto}</Typography>
                <Typography>{item.produto}</Typography>
                <Typography>{item.qtd}</Typography>
                <Typography className={classes.itemNumeric}>{moneyFormat(item.unitario)}</Typography>
                <Typography className={classes.itemNumeric}>{moneyFormat(item.total)}</Typography>
              </div>
            ))}

            <div className={classes.grid}>
              <Typography></Typography>
              <Typography></Typography>
              <Typography></Typography>
              <Typography></Typography>
              <div className={classes.divider}>
                <div className={classes.dividerItemSum}></div>
              </div>
            </div>

            <div className={classes.grid}>
              <Typography></Typography>
              <Typography></Typography>
              <Typography></Typography>
              <Typography></Typography>
              <Typography className={classes.itemNumeric}>{moneyFormat(selectedSale.total)}</Typography>
            </div>

            <div className={classes.divider}>
              <div className={classes.dividerItem}></div>
            </div>

            <div className={classes.grid2}>
              <Typography>Forma de Pagamento</Typography>
              <Typography>Parcelas</Typography>
              <Typography className={classes.itemNumeric}>Valor</Typography>
            </div>

            {selectedSale.pagamentos &&
              selectedSale.pagamentos.map((item, index) => (
                <div key={index}>
                  <div className={classes.grid2}>
                    <Typography>{item.forma}</Typography>
                    <Typography>{item.parcelas}</Typography>
                    <Typography className={classes.itemNumeric}>{moneyFormat(item.valor)}</Typography>
                  </div>
                  {item.vencimentos && (
                    <>
                      <div className={classes.grid2Subgroup}>
                        <Typography></Typography>
                        <Typography>Vencimento</Typography>
                        <Typography className={classes.itemNumeric}>Valor</Typography>
                        <Typography></Typography>
                      </div>

                      <div className={classes.grid2Subgroup}>
                        {item.vencimentos.map(due => (
                          <>
                            <Typography></Typography>
                            <Typography>{due.vencimento}</Typography>
                            <Typography className={classes.itemNumeric}>{moneyFormat(due.valor)}</Typography>
                            <Typography></Typography>
                          </>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              ))}

            <div className={classes.grid2}>
              <Typography></Typography>
              <Typography></Typography>
              <div className={classes.divider}>
                <div className={classes.dividerItemSum}></div>
              </div>
            </div>

            <div className={classes.grid2}>
              <Typography></Typography>
              <Typography></Typography>
              <Typography className={classes.itemNumeric}>{moneyFormat(selectedSale.total)}</Typography>
            </div>

            <div className={classes.row}>
              <Typography>Peso Bruto:</Typography>
              <Typography>{numberFormat(selectedSale.itens.reduce((sum, item) => sum + item.peso, 0))}kg</Typography>
            </div>

            <div className={classes.divider}>
              <div className={classes.dividerItem}></div>
            </div>

            <div className={classes.row}>
              <Typography>Status:</Typography>
              <Typography>{selectedSale.status}</Typography>
            </div>
          </div>
        </div>
      ) : (
        <SelectedSaleMobile selectedSale={selectedSale} />
      )}
    </Dialog>
  );
};

export default SelectedSale;
