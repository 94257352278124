import { useEffect, useState, useCallback } from 'react';
import { api } from 'services/api';
import { compatibilityInitialState } from '../new/CompatibilityNew';
import { useApp } from 'hooks/app';
import { Product } from 'types/product';

export function useFetchCompatibility(id?: string) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [compatibility, setCompatibility] = useState(compatibilityInitialState);
  const { h2iApi } = useApp();

  const fetchProducts = useCallback(
    async (ids: string[]): Promise<Product[]> => {
      if (!h2iApi) {
        return [];
      }

      const response = await h2iApi?.get(`/api/produtoTodos`, { params: { ids: ids.join(',') } });

      return response.data;
    },
    [h2iApi],
  );

  const fetchCompatibility = useCallback(
    async (id: string) => {
      const compatibilitiyResponse = await api.get(`/compatibilities/${id}`);

      const products = await fetchProducts(compatibilitiyResponse.data.product_ids);

      return {
        ...compatibilitiyResponse.data,
        products: products.map(product => ({
          id: `${product.codigo_produto}`,
          name: product.produto,
        })),
      };
    },
    [fetchProducts],
  );

  const fetch = useCallback(
    (id: string) => {
      setLoading(true);

      fetchCompatibility(id)
        .then(response => setCompatibility(response))
        .catch(err => {
          console.log(err);
          setError(`Não foi possível recuperar a compatibilidade`);
        })
        .finally(() => setLoading(false));
    },
    [fetchCompatibility],
  );

  useEffect(() => {
    if (!id) {
      return;
    }

    fetch(id);
  }, [id, fetch]);

  return {
    compatibility,
    setCompatibility,
    error,
    loading,
    fetch,
  };
}
