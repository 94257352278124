import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react';
import { H2iCustomer } from 'types/h2iCustomer';
import { Checkbox, FormControlLabel, TextField, Typography, styled } from '@mui/material';
import { ProjectValidation } from 'pages/projects/validation/projectValidation';
import ResellerAutoComplete from 'pages/projects/auto-complete/ResellerAutoComplete';
import { useProject } from '../../hook/useProject';
import { moneyFormat } from 'helpers/numberFormat';
import ProjectStatus from './ProjectStatus';
import { ProjectStatusOptions } from 'types/project';

const Container = styled('div')({
  gap: 10,
  marginTop: 20,
  display: 'flex',
  flexDirection: 'column',
});

const TotalContent = styled('div')({
  backgroundColor: '#eee',
  border: '1px solid #ccc',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  padding: 20,
  marginTop: 10,
});

const RegisterContainer = styled('div')({
  display: 'flex',
  gap: 15,
  border: '1px solid #ddd',
  padding: 15,
  marginTop: 15,
});

interface ProjectMainProps {
  reseller: H2iCustomer | null;
  setReseller: Dispatch<SetStateAction<H2iCustomer | null>>;
  validation: ProjectValidation;
  handleCancelStatus(): Promise<void>;
  handleUpdateStatus(status: ProjectStatusOptions): Promise<void>;
  action?: 'updating' | 'inserting';
}

const ProjectMain: React.FC<ProjectMainProps> = ({
  reseller,
  setReseller,
  validation,
  handleCancelStatus,
  handleUpdateStatus,
  action = 'inserting',
}) => {
  const { project, handleChange } = useProject();

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
    value: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  const totals = useMemo(() => {
    const total = project.products.reduce((carry, product) => carry + product.product_value * product.quantity, 0);
    return {
      total,
      formattedTotal: moneyFormat(total),
    };
  }, [project.products]);

  function handleCustomerSelect(customer: H2iCustomer) {
    setReseller(customer);
    handleChange('document_number', customer?.cnpj_cpf ?? null);
    if (customer) {
      handleChange('customer_name', customer?.nome ?? null);
    }
  }

  return (
    <Container>
      <div>
        <ResellerAutoComplete
          onSelect={handleCustomerSelect}
          label="Selecione um cliente"
          error={!!validation.customer_name}
          helperText={validation.customer_name || ''}
          placeholder="Digite o nome do cliente para pesquisar"
          customer={reseller}
        />
      </div>

      <TextField
        label="Nome do projeto"
        placeholder="Digite o nome do projeto"
        multiline
        fullWidth
        error={!!validation.name}
        value={project?.name}
        helperText={validation.name}
        onChange={e => handleChange('name', e.target.value)}
        margin="normal"
        inputRef={inputs.name}
      />

      <TextField
        label="Descrição"
        placeholder="Digite a descrição do projeto"
        rows={8}
        multiline
        fullWidth
        error={!!validation.description}
        value={project?.description}
        helperText={validation.description}
        onChange={e => handleChange('description', e.target.value)}
        margin="normal"
        inputRef={inputs.description}
      />

      <TextField
        label="Valor do serviço (R$)"
        placeholder="Informe o valor do serviço"
        margin="normal"
        onChange={event => handleChange('value', event.target.value)}
        fullWidth
        inputMode="decimal"
        type="number"
        value={project.value || ''}
        helperText={validation.value}
        error={!!validation.value}
        inputRef={inputs.value}
      />

      <TotalContent>
        <Typography>
          <strong>Total dos produtos</strong>
        </Typography>
        <Typography>{totals.formattedTotal}</Typography>
      </TotalContent>

      {action === 'updating' && (
        <ProjectStatus handleCancelStatus={handleCancelStatus} handleUpdateStatus={handleUpdateStatus} />
      )}

      <RegisterContainer>
        <TextField
          label="Número RO"
          placeholder="Informe o RO do projeto"
          margin="normal"
          onChange={event => handleChange('ro_number', event.target.value)}
          fullWidth
          value={project.ro_number || ''}
        />

        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={project.is_registered}
                onChange={e => handleChange('is_registered', e.target.checked)}
                color="primary"
              />
            }
            label="Registrado"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={project.is_approved}
                onChange={e => handleChange('is_approved', e.target.checked)}
                color="primary"
              />
            }
            label="Aprovado"
          />
        </div>
      </RegisterContainer>
    </Container>
  );
};

export default ProjectMain;
