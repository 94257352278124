import React, { useEffect } from 'react';
import Print from 'components/print/Print';
import { useAgent } from '../hooks/useAgent';
import { PrintConsumer } from 'components/print/hook/usePrint';
import { FiPrinter, FiX } from 'react-icons/fi';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    textAlign: 'justify',
    padding: '1.5cm',
    '& p': {
      lineHeight: '0.7cm',
      fontSize: 14,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    top: 10,
    right: 10,
    position: 'absolute',
    '@media print': {
      display: 'none',
    },
  },
});

interface AccountAgentLetterProps {
  onExited(): void;
}

const AccountAgentLetter: React.FC<AccountAgentLetterProps> = ({ onExited }) => {
  const classes = styles();

  const { agent, reseller } = useAgent();

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 500);
  }, [reseller]);

  return (
    <Print onExited={onExited}>
      <PrintConsumer>
        {context => (
          <div className={classes.container}>
            <div className={classes.header}>
              <IconButton onClick={window.print}>
                <FiPrinter size={20} />
              </IconButton>
              <IconButton onClick={context.handleClose}>
                <FiX size={20} />
              </IconButton>
            </div>
            <p style={{ marginBottom: '0.7cm' }}>
              <strong>CARTA DE PREPOSIÇÃO</strong>
            </p>
            <p style={{ marginBottom: '0.7cm' }}>
              <strong>PREPONENTE:</strong>
              {` ${reseller?.nome}, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob nº ${reseller?.cnpj_cpf}, com sede na ${reseller?.endereco}, nº ${reseller?.endereco}, BAIRRO ${reseller?.bairro}, CIDADE ${reseller?.cidade}, ESTADO ${reseller?.uf}.`}
            </p>
            <p style={{ marginBottom: '0.7cm' }}>
              <strong>PREPOSTO:</strong>
              {` ${agent.nome}, brasileiro, portador do RG n. ${agent.rg}, inscrito no CPF/MF sob o n. ${agent.cpf}, residente e domiciliado à ${agent.endereco}, nº ${agent.numero_endereco}, BAIRRO ${agent.bairro}, CIDADE ${agent.cidade}, ESTADO ${agent.uf}.`}
            </p>
            <p
              style={{ marginBottom: '3cm' }}
            >{`O Preposto acima identificado está autorizado a comparecer e representar a PREPONENTE perante a empresa ELTECNO DISTRIBUICÃO DE INFORMÁTICA LTDA, sociedade empresária limitada, sendo a matriz situada à Rua Itaji, N° 324 Lote 11, Pitangueiras, Lauro de Freitas-BA, CEP: 42700-000, registrada na junta comercial do Estado da Bahia N° 2920354827-7, em 26/11/2010, e atos subseqüentes, inscrita no  CNPJ/MF sob N° 07.482.781/0001-47,  podendo , para tanto , efetuar comprar, pedir, retirar mercadoria e receber produtos, praticando, enfim, todos os atos necessários para o cumprimento das finalidades e que esta autorização se destina, sempre em nome e sob a responsabilidade da PREPONENTE, que se responsabilizará por todos os atos praticados pelo PREPOSTO na utilização dos poderes conferidos por esta carta.`}</p>
            <p style={{ textAlign: 'center' }}>____________________________________________________</p>
            <p style={{ textAlign: 'center' }}>CNPJ/MF: {reseller?.cnpj_cpf}</p>
          </div>
        )}
      </PrintConsumer>
    </Print>
  );
};

export default AccountAgentLetter;
