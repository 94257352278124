import { createContext, useContext } from 'react';
import { AbcSegmentsFilterParams } from '../CurveAbcSegment';

type AbcSegmentContextValue = {
  searchValue: string;
  filter: AbcSegmentsFilterParams;
  handleChangeFilter(index: keyof AbcSegmentsFilterParams, value: any): void;
};

const AbcSegmentContext = createContext<AbcSegmentContextValue>({} as AbcSegmentContextValue);
export const AbcSegmentProvider = AbcSegmentContext.Provider;

export function useAbcSegment(): AbcSegmentContextValue {
  const context = useContext(AbcSegmentContext);
  return context;
}
