import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { BillSetting } from 'types/billSetting';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type BillItemTableProps = {
  bill: BillSetting;
};

const BillItemTable: React.FC<BillItemTableProps> = ({ bill }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'url' ? (
            <a href={bill[item.id]} key={item.id} id={item.id} target="blank" style={{ fontSize: 14 }}>
              {bill[item.id as keyof BillSetting]}
            </a>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{bill[item.id as keyof BillSetting]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default BillItemTable;
