import { Typography, styled } from '@mui/material';
import React from 'react';

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: 'repeat(2, 1fr)',
}));

const Box = styled('div')({
  '&.rows': {
    gridRow: '1 / 3',
  },
  '&.columnsrows': {
    gridColumn: '1 / 3',
  },
  minHeight: 100,
  backgroundColor: '#eee',
  border: '2px solid #ddd',
  padding: 10,
});

const SellerDashboardContent: React.FC = () => {
  return (
    <Content>
      <Box className="rows">
        <Typography>Segmentos</Typography>
      </Box>

      <Box>
        <Typography>Clientes atendidos</Typography>
      </Box>

      <Box>
        <Typography>Vendas por fabricante</Typography>
      </Box>

      <Box className="columnsrows">
        <Typography>Clientes em potenciais</Typography>
      </Box>

      <Box>
        <Typography>Outras informações</Typography>
      </Box>
    </Content>
  );
};

export default SellerDashboardContent;
