import { createContext, useContext } from 'react';
import { ItemsSold } from 'types/reports/itemsSold';
import { ItemsSoldFilter } from './../ItemsSoldReport';

type BranchesContextValue = {
  selectedItemSold: ItemsSold | null;
  setSelectedItemSold(partner: ItemsSold | null): void;
  handleChangeFilter(index: keyof ItemsSoldFilter, value: any): void;
  filter: ItemsSoldFilter;
};

const BranchesContext = createContext<BranchesContextValue>({} as BranchesContextValue);
export const BranchesProvider = BranchesContext.Provider;

export function useBranches(): BranchesContextValue {
  const context = useContext(BranchesContext);
  return context;
}
