import React, { useEffect, useState } from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { PaymentFlow } from 'types/paymentFlow';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import NoData from 'components/no-data/NoData';

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  background: '#f9f7f2',
  overflow: 'auto',
  maxHeight: 550,
});

const Row = styled('li')(({ theme }) => ({
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 6,
  gridTemplateColumns: '120px 120px 120px',
  transition: 'all 0.3s ease',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  '& > .numericData': {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  '& > span': {
    gap: 5,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
  rowGap: 10,
});

interface TopPaymentsProps {
  data: PaymentFlow[];
}

const TopPayments: React.FC<TopPaymentsProps> = ({ data }) => {
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<PaymentFlow[]>([]);

  useEffect(() => {
    setFiltered(data);
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <Container>
      <Typography variant="subtitle2" align="center">
        Pagamentos a vencer
      </Typography>
      {filtered.length > 0 ? (
        <List>
          <Row>
            <span>
              <Typography onClick={() => handleSort('formattedDayOfWeek')} variant="subtitle2">
                Dia
              </Typography>
              {orderedIndex.index === 'formattedDayOfWeek' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('formattedDueDate')} variant="subtitle2">
                Vencimento
              </Typography>
              {orderedIndex.index === 'formattedDueDate' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography onClick={() => handleSort('valor')} align="right" variant="subtitle2">
                Valor
              </Typography>
              {orderedIndex.index === 'valor' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
          </Row>
          {filtered.map((item, index) => (
            <Row className="content" key={index}>
              <Tooltip title={item.formattedDayOfWeek}>
                <Typography className="item-name" variant="caption">
                  {item.formattedDayOfWeek}
                </Typography>
              </Tooltip>
              <Tooltip title={item.vencimento}>
                <Typography className="item-name" variant="caption">
                  {item.vencimento}
                </Typography>
              </Tooltip>
              <Typography className="numericData" align="right" variant="caption">
                {item.formattedValue}
              </Typography>
            </Row>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
    </Container>
  );
};

export default TopPayments;
