import React, { useState, useEffect, DragEvent, useCallback, FormEvent } from 'react';
import Dialog from 'components/dialogs/Dialog';
import { Button, CircularProgress, MenuItem, TextField, styled } from '@mui/material';
import { api } from 'services/api';
import ImagePreview from 'components/image-preview/ImagePreview';
import { useMessaging } from 'hooks/messaging';
import { Image } from 'types/image';
import { LaravelPaginator } from 'types/laravelPaginator';
import AiArtSelectorActions from './AiArtSelectorActions';
import { crawlerApi } from 'services/crawlerApi';
import AiArtSelectorList from './AiArtSelectorList';

const Loading = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: 'rgba(250,250,250,0.5)',
  zIndex: 1110,
});

const DraggableZone = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  '&.inDraggableZone': {
    border: `3px dashed ${theme.palette.primary.main}`,
    opacity: 0.5,
  },
}));

let handleClose: () => void;

type AiArtSelectorProps = {
  handleSetImageId?(image: Image): void;
  onExited(): void;
  title?: string;
  hideBackdrop?: boolean;
};

const Form = styled('form')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
  '& > button': {
    alignSelf: 'end',
  },
});

const BoxImage = styled('a')({
  boxShadow: `1px 1px 6px 1px #30519f`,
  transition: 'all ease 0.15s',
  '&:hover': {
    transform: 'scale(0.95)',
  },
  '& > img': {
    width: 280,
    height: 280,
  },
});

const Grid = styled('div')({
  gap: 5,
  display: 'grid',
  justifyItems: 'center',
  gridTemplateColumns: 'repeat(2, 1fr)',
});

interface ModifyImage {
  url: string;
}

const AiArtSelector: React.FC<AiArtSelectorProps> = ({
  handleSetImageId: onSetImageId,
  onExited,
  title,
  hideBackdrop = false,
}) => {
  const [prompt, setPrompt] = useState({
    size: '256x256',
    images_quantity: 2,
  });
  const [modifyImages, setModifyImages] = useState<ModifyImage[]>([]);
  const [images, setImages] = useState<Image[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const messaging = useMessaging();
  const [dragIn, setDragIn] = useState(false);
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState(1);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const fetchImages = useCallback(() => {
    api
      .get<LaravelPaginator<Image[]>>('/images', { params: { rows: 25, page } })
      .then(response => {
        setImages(state => [...state, ...response.data.data]);
        setLastPage(response.data.last_page);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  function handleSetSelectedImage(image: Image, _handleClose: () => void) {
    setSelectedImage(selectedImage?.id === image.id ? null : image);
    handleClose = _handleClose;
  }

  function handleConfirmImage() {
    if (selectedImage && onSetImageId) onSetImageId(selectedImage);
    if (handleClose) handleClose();
  }

  function handleConfirmModifyImage() {
    if (!selectedImage) {
      return;
    }
    setStep(2);
  }

  function handleSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setSaving(true);
    crawlerApi
      .post(`/image-variations`, { ...prompt, url: selectedImage?.imageUrl })
      .then(response => {
        if (response.data.error) {
          messaging.handleOpen(response.data.error.message);
          return;
        }
        setModifyImages(response.data);
        setSuccess(true);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleUploadFiles(files: FileList | null) {
    if (!files) return;

    const form = new FormData();
    Array.from(files).forEach((file, index) => {
      form.append(`files[${index}]`, file);
    });

    form.append('is_public', '1');

    setSaving(true);

    api
      .post('/images', form)
      .then(response => {
        setImages(oldImages => [...response.data, ...oldImages]);
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível carregar a imagem');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDropFile(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleUploadFiles(files);
    setDragIn(false);
  }

  function handleDragEnter(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  function handleDragLeave(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(false);
  }

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  function handleScroll() {
    const nativeDialogElement = document.getElementById('native-dialog');

    if (!nativeDialogElement) {
      return;
    }

    const clientHeight = nativeDialogElement.clientHeight;
    const scrollTop = nativeDialogElement.scrollTop;
    const scrollHeight = nativeDialogElement.scrollHeight;

    if (scrollTop + clientHeight !== scrollHeight) {
      return;
    }

    setPage(state => {
      if (state + 1 === lastPage) {
        return state;
      }

      return state + 1;
    });
  }

  return (
    <Dialog
      title={title || 'Selecione a imagem'}
      onExited={onExited}
      hideBackdrop={hideBackdrop}
      onScroll={handleScroll}
      ComponentActions={
        <>
          {step === 1 && (
            <AiArtSelectorActions
              selectedImage={selectedImage}
              handleConfirmModifyImage={handleConfirmModifyImage}
              openImagePreview={() => setImagePreview(true)}
              handleConfirmImage={handleConfirmImage}
              handleUploadFiles={handleUploadFiles}
              showConfirmAction={!!onSetImageId}
            />
          )}
        </>
      }
    >
      {loading || saving ? (
        <Loading>
          <CircularProgress color="primary" />
        </Loading>
      ) : step === 1 ? (
        <>
          {imagePreview && (
            <ImagePreview
              description={`Imagem ${selectedImage?.id}`}
              onExited={() => setImagePreview(false)}
              src={selectedImage ? selectedImage.imageUrl : ''}
            />
          )}
          <DraggableZone
            className={dragIn ? 'inDraggableZone' : ''}
            onDrop={e => handleDropFile(e)}
            onDragLeave={e => handleDragLeave(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragOver={e => handleDragOver(e)}
            draggable
          >
            <AiArtSelectorList
              images={images}
              handleSetSelectedImage={handleSetSelectedImage}
              selectedImageId={selectedImage?.id}
            />
          </DraggableZone>
        </>
      ) : (
        <>
          {success ? (
            <Grid>
              {modifyImages.map((img, index) => (
                <BoxImage href={img.url} target="_blank" key={index}>
                  <img src={img.url} />
                </BoxImage>
              ))}
            </Grid>
          ) : (
            <Form onSubmit={handleSubmit}>
              <TextField
                select
                autoFocus
                value={prompt.size}
                disabled={loading}
                label="selecione o tamanho"
                onChange={e =>
                  setPrompt(prompt => ({
                    ...prompt,
                    size: e.target.value,
                  }))
                }
              >
                <MenuItem value="1024x1024">1024x1024</MenuItem>
                <MenuItem value="512x512">512x512</MenuItem>
                <MenuItem value="256x256">256x256</MenuItem>
              </TextField>
              <TextField
                select
                autoFocus
                value={prompt.images_quantity}
                disabled={loading}
                label="selecione a quantidade"
                onChange={e =>
                  setPrompt(prompt => ({
                    ...prompt,
                    images_quantity: parseInt(e.target.value),
                  }))
                }
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </TextField>

              <Button type="submit" variant="contained">
                criar
              </Button>
            </Form>
          )}
        </>
      )}
    </Dialog>
  );
};

export default AiArtSelector;
