import React, { useState, useEffect, useCallback } from 'react';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import { Typography } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import PaginationProvider from 'hooks/pagination';
import { moneyFormat } from 'helpers/numberFormat';
import Pagination from 'components/pagination/Pagination';
import { useAbcProducts } from '../../hooks/useAbcProducts';
import InsideSaving from 'components/loading/InsideSaving';
import SelectedBranchListTable from './list/table/SelectedBranchListTable';
import { useAbcProduct } from '../../hooks/useAbcProduct';
import SelectedBranchListMenu from './SelectedBranchListMenu';

interface SelectedBranchProps {
  onExited(): void;
}

export interface StoreProduct {
  cliente: string;
  custo: number;
  data: string;
  id_produto: number;
  mc: number;
  id_filial: number;
  numero_venda: number;
  pedido_por: string;
  produto: string;
  qtd: number;
  spiff: number;
  subgrupo: string;
  total: number;
  unitario: number;
  vendedor: string;
  verba_custo: number;
}

const SelectedBranch: React.FC<SelectedBranchProps> = ({ onExited }) => {
  const { h2iApi } = useApp();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const { searchValue, filter } = useAbcProduct();
  const { selectedProduct, selectedBranch } = useAbcProducts();
  const [products, setProducts] = useState<StoreProduct[]>([]);
  const [product, setProduct] = useState<StoreProduct | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filteredProducts, setFilteredProducts] = useState<StoreProduct[]>([]);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  const handleSearch = useCallback(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    h2iApi
      .get('/api/relcurvaabcfiliaisitens', {
        params: {
          data_ini: filter.dates.formattedInitialDate,
          data_fim: filter.dates.formattedFinalDate,
          id_produto: selectedProduct?.cod,
          id_filial: selectedBranch?.id_filial,
          id_subgrupo: filter.productId || '',
          filtro: searchValue,
          tipo_venda: filter.typeSale,
          id_categoria: filter.categoryIds.join(','),
          id_subcategoria: filter.subCategoryIds.join(','),
          pedido_por: filter.madeBy,
          id_segmento: filter.segmentIds.join(','),
          id_unidade: filter.unitIds.join(','),
          id_fabricante: filter.manufacturerIds.join(','),
        },
      })
      .then(response => {
        const _products = response.data.map(item => {
          item.formattedTotal = moneyFormat(item.total);
          item.formattedCoust = moneyFormat(item.custo);
          item.formattedMc = moneyFormat(item.mc);
          item.formattedSpiff = moneyFormat(item.spiff);
          item.formattedUnitary = moneyFormat(item.unitario);

          return item;
        });

        setProducts(_products);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, h2iApi, searchValue, selectedBranch, selectedProduct]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  function handleSort(index: string) {
    const filtered = sort(index, filteredProducts);
    setFilteredProducts(filtered);
  }

  return (
    <Dialog onExited={onExited} maxWidth="lg" title={selectedBranch?.filial}>
      {loading ? (
        <InsideSaving />
      ) : (
        <div style={{ display: 'grid', gap: 10 }}>
          {product && <SelectedBranchListMenu sale={product} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />}
          <Typography>
            <b>{selectedProduct?.produto}</b>
          </Typography>
          <PaginationProvider>
            <SelectedBranchListTable
              setProduct={setProduct}
              setAnchorEl={setAnchorEl}
              products={filteredProducts}
              handleSort={handleSort}
              orderedIndex={orderedIndex}
            />
            <Pagination count={filteredProducts.length} />
          </PaginationProvider>
        </div>
      )}
    </Dialog>
  );
};

export default SelectedBranch;
