import { Dispatch, SetStateAction, useState } from 'react';
import { Document } from 'types/document';
import * as yup from 'yup';

export interface AgentLetterValidation {
  letter?: string;
}

type UseAgentLetterValidation = [
  AgentLetterValidation,
  Dispatch<SetStateAction<AgentLetterValidation>>,
  (document: Document | null) => Promise<void>,
];

export function useAgentLetterValidation(): UseAgentLetterValidation {
  async function handleValidation(document: Document | null) {
    const schema = yup.object().typeError('A carta assinada é obrigatória').required('A carta assinada é obrigatória');

    try {
      await schema.validate(document);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<AgentLetterValidation>({});
  return [validation, setValidation, handleValidation];
}
