import React from 'react';
import { ListItemButton, Typography, styled } from '@mui/material';
import { Revenue } from 'types/revenue';
import { useRevenues } from '../../hooks/useRevenues';

const StyledListItem = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 120,
  justifyContent: 'space-between',
  flexDirection: 'column',
});

interface RevenueItemModuleProps {
  revenue: Revenue;
}

const RevenueItemModule: React.FC<RevenueItemModuleProps> = ({ revenue }) => {
  const { setSelectedRevenue } = useRevenues();

  return (
    <StyledListItem onClick={() => setSelectedRevenue(revenue)}>
      <Typography color="primary" variant="caption" gutterBottom>
        {revenue.nome}
      </Typography>

      <Typography variant="caption">Filial {revenue.id_filial}</Typography>
      <Typography variant="caption">Tipo {revenue.formattedType}</Typography>

      <Typography color="primary" variant="caption" gutterBottom>
        Cód Contábil {revenue.conta_contabil}
      </Typography>
    </StyledListItem>
  );
};

export default RevenueItemModule;
