import React from 'react';
import { RegisterForm } from 'types/registerForm';
import DocumentUpload from 'components/document-upload/DocumentUpload';
import { RegisterFormPartnerDocumentValidation } from './validation/registerFormPartnerDocumentValidation';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  imageUploader: {
    marginBottom: 15,
  },
});

interface RegisterFormPartnerDocumentProps {
  register: RegisterForm;
  handleChange(index: keyof RegisterForm, value: any): void;
  validation: RegisterFormPartnerDocumentValidation;
}

const RegisterFormPartnerDocument: React.FC<RegisterFormPartnerDocumentProps> = ({
  register,
  handleChange,
  validation,
}) => {
  const classes = styles();

  return (
    <DocumentUpload
      handleRemoveImage={() => handleChange('partner_document_copy', null)}
      handleSetImage={image => handleChange('partner_document_copy', image)}
      validationError={validation.partner_document_copy}
      document={register.partner_document_copy}
      label="cópia de documento com foto, (RG ou CNH)"
      className={classes.imageUploader}
    />
  );
};

export default RegisterFormPartnerDocument;
