import React, { useState, FormEvent } from 'react';
import Appbar from 'components/appbar/Appbar';
import { Grid } from '@mui/material';
import PageHeader from 'components/page-header/PageHeader';
import * as yup from 'yup';
import ProfileForm from './ProfileForm';
import history from 'services/history';
import ProfileActions from './ProfileActions';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import Loading from 'components/loading/Loading';
import { Profile as ProfileType } from 'types/profile';

export type ProfileValidation = {
  name?: string;
};

const Profile: React.FC = () => {
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);
  const [profile, setProfile] = useState<ProfileType>({
    name: '',
    users: false,
    profiles: false,
    settings: false,
    dashboard: false,
    reports: false,
    registrations: false,
    stock: false,
    trade: false,
    financial: false,
    purchasing: false,
    taxes: false,
    product_registration: false,
  });
  const [validation, setValidation] = useState<ProfileValidation>({});

  function handleChange(index: string, value: any) {
    setProfile(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
    });

    schema
      .validate(profile)
      .then(() => {
        handleSubmit();
        setValidation({});
      })
      .catch(err => {
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);
    api
      .post('/profiles', profile)
      .then(() => {
        setSaving(false);
        messaging.handleOpen('Salvo');
        history.push('/profiles');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar');
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Novo perfil"
        backAction={() => history.push('/profiles')}
        ActionsComponent={<ProfileActions handleSubmit={handleValidation} />}
      />
      <Grid container>
        <PageHeader title="Novo perfil" />
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={4} xl={4} md={6}>
          <form onSubmit={handleValidation}>
            <ProfileForm validation={validation} handleChange={handleChange} profile={profile} />
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
