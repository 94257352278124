import React from 'react';
import SidebarItem from '../SidebarItem';
import { Collapse } from '@mui/material';
import { useSidebar } from '../hook/useSidebar';
import { MdDone, MdGrade, MdNote, MdPeople } from 'react-icons/md';
import { usePermissionRules } from 'hooks/permissionRules';
import SidebarItemExpendable from '../SidebarItemExpendable';
import { VscProject } from 'react-icons/vsc';
import { CardGiftcard, LocalLibrary } from '@mui/icons-material';
import { FaSun } from 'react-icons/fa';

const Commercial: React.FC = () => {
  const { checkPermission } = usePermissionRules();
  const { handleCollapseClick, collapsible } = useSidebar();

  return (
    <>
      {checkPermission('commercial') && (
        <>
          <SidebarItemExpendable
            onClick={() => handleCollapseClick('commercial')}
            icon={<MdPeople />}
            text="Comercial"
            opened={collapsible.commercial}
          />
          <Collapse in={collapsible.commercial}>
            {checkPermission('commercial.order_authorization') && (
              <>
                <SidebarItem
                  to="/order-authorization/bill"
                  icon={<MdDone />}
                  text="Autorização de faturamento Boleto"
                  nested={5}
                />
                <SidebarItem
                  to="/order-authorization/card"
                  icon={<MdDone />}
                  text="Autorização de faturamento cartão"
                  nested={5}
                />
              </>
            )}
            {checkPermission('commercial.review.dav') && (
              <SidebarItem to="/review/dav" icon={<MdNote />} text="Análise de DAV" nested={5} />
            )}

            {checkPermission('commercial.projects') && (
              <SidebarItem nested={5} to="/projects" icon={<VscProject />} text="Projeto" />
            )}
            {checkPermission('commercial.rentals') && (
              <SidebarItem nested={5} to="/rentals" icon={<FaSun />} text="Locações" />
            )}
            {checkPermission('commercial.managed_sale') && (
              <SidebarItem nested={5} to="/managed-sales" icon={<CardGiftcard />} text="Venda agenciada" />
            )}

            {checkPermission('commercial.courses') && (
              <>
                <SidebarItemExpendable
                  onClick={() => handleCollapseClick('trade', false)}
                  icon={<MdGrade />}
                  text="Cursos"
                  nested={5}
                  opened={collapsible.trade}
                />
                <Collapse in={collapsible.trade}>
                  <SidebarItem nested={8} to="/courses" icon={<LocalLibrary />} text="Cursos" />
                  <SidebarItem nested={8} to="/course-students" icon={<FaSun />} text="Participantes" />
                </Collapse>
              </>
            )}
          </Collapse>
        </>
      )}
    </>
  );
};

export default Commercial;
