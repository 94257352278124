import React from 'react';
import { QueryParams } from 'types/queryParams';
import DialogInput from 'components/dialogs/DialogInput';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';

type ProjectDateFilterProps = {
  onExited(): void;
  queryParams: QueryParams;
  handleQueryParamsChange(index: keyof QueryParams, value: any): void;
};

const ProjectDateFilter: React.FC<ProjectDateFilterProps> = ({ onExited, handleQueryParamsChange, queryParams }) => {
  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <KeyboardDatePicker
        label="Data inicial"
        value={queryParams.initial_date}
        onChange={date => handleQueryParamsChange('initial_date', date)}
      />
      <KeyboardDatePicker
        label="Data Final"
        value={queryParams.final_date}
        onChange={date => handleQueryParamsChange('final_date', date)}
      />
    </DialogInput>
  );
};

export default ProjectDateFilter;
