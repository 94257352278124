import { IconButton, InputAdornment, TextField, styled } from '@mui/material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import { DisplayModeOptions } from 'hooks/useDisplayMode';
import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import { MdSearch } from 'react-icons/md';

interface BuyerFilterBoxProps {
  onSubmit(e?: FormEvent<HTMLFormElement>);
  handleChangeText(value: string): void;
  searchText: string;
  displayMode: DisplayModeOptions;
  setDisplayMode: Dispatch<SetStateAction<DisplayModeOptions>>;
}

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 10,
  flex: 0.5,
  [theme.breakpoints.down('md')]: {
    flex: 1,
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    flex: 1,
  },
}));

const BuyerFilterBox: React.FC<BuyerFilterBoxProps> = ({
  onSubmit,
  handleChangeText,
  setDisplayMode,
  displayMode,
  searchText,
}) => {
  return (
    <FilterBox>
      <Filter onSubmit={onSubmit}>
        <TextField
          label="Pesquisar"
          placeholder="Pesquisar"
          autoFocus
          value={searchText}
          onChange={e => handleChangeText(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <MdSearch size={20} color="#888" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Filter>
      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default BuyerFilterBox;
