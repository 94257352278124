import React from 'react';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import CustomerItemTable from './CustomerItemTable';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { Typography, alpha, Grid, Theme } from '@mui/material';
import { AbcProductCustomer } from 'types/abcProduct';
import { customerTableTemplate } from '../../customerTableTemplate';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  header: {
    backgroundColor: '#eee',
  },
  headerSubgroup: {
    backgroundColor: '#d9f8e5',
  },
  tableRow: {
    '& > div:nth-child(even)': {
      backgroundColor: '#eee',
    },
  },
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
  selectedCustomer: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
  },
}));

type CustomerListTableProps = {
  customers: AbcProductCustomer[];
};

const CustomerListTable: React.FC<CustomerListTableProps> = ({ customers }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={customerTableTemplate}>
          <TableContent>
            <TableHeader className={classes.header}>
              {customerTableTemplate.map(item => (
                <div
                  className={
                    item.dataType === 'number'
                      ? `${classes.headerItem} ${classes.headerItemNumeric}`
                      : classes.headerItem
                  }
                  key={item.id}
                >
                  <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                    {item.description}
                  </Typography>
                </div>
              ))}
            </TableHeader>
            <TableBody>
              <div className={classes.tableRow}>
                {customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(customer => (
                  <TableRow style={{ minHeight: 10, padding: 0 }} key={customer.uuid}>
                    <CustomerItemTable customer={customer} />
                  </TableRow>
                ))}
              </div>
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default CustomerListTable;
