import React, { FormEvent, useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import StatusActions from './AppBannerActions';
import AppBannerForm from '../AppBannerForm';
import { useAppBannerValidation } from '../validation/appBannerValidation';
import { AppBanner } from 'types/appBanner';

const AppBannerNew: React.FC = () => {
  const [banner, setBanner] = useState<AppBanner>({} as AppBanner);
  const [validation, setValidation, validate] = useAppBannerValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();

  const handleChange = useCallback((index: keyof AppBanner, value: any) => {
    setBanner(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(banner)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/banners', banner)
      .then(() => {
        setSaving(false);
        history.push('/config/app/banners');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="H2i App"
        ActionsComponent={<StatusActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/config/app/banners')}
      />
      <PageHeader title="Novo banner" />
      <Grid container>
        <form onSubmit={handleValidation}>
          <AppBannerForm handleChange={handleChange} banner={banner} validation={validation} />
        </form>
      </Grid>
    </>
  );
};

export default AppBannerNew;
