import React, { MouseEvent } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { EmailTemplate } from 'types/emailTemplate';
import { MoreHoriz } from '@mui/icons-material';
import { useEmailTemplates } from 'pages/email-templates/hooks/useEmailTemplates';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type EmailTemplateItemTableProps = {
  emailTemplate: EmailTemplate;
  setAnchorEl(el: HTMLButtonElement): void;
};

const EmailTemplateItemTable: React.FC<EmailTemplateItemTableProps> = ({ emailTemplate, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedEmailTemplate } = useEmailTemplates();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedEmailTemplate(emailTemplate);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{emailTemplate[item.id as keyof EmailTemplate]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default EmailTemplateItemTable;
