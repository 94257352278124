import React, { useEffect, useRef } from 'react';
import { TextField, styled } from '@mui/material';
import { useRelatedProduct } from '../hook/useRelatedProduct';
import { RelatedProductValidation } from '../validation/relatedProductValidation';

const Container = styled('div')({
  gap: 10,
  marginTop: 20,
  display: 'flex',
  flexDirection: 'column',
});

interface CompatibilityMainProps {
  validation: RelatedProductValidation;
  action?: 'updating' | 'inserting';
}

const CompatibilityMain: React.FC<CompatibilityMainProps> = ({ validation, action = 'inserting' }) => {
  const { relatedProduct, handleChange } = useRelatedProduct();

  const inputs = {
    description: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Container>
      <TextField
        label="Descrição"
        placeholder="Digite a descrição do projeto"
        rows={8}
        multiline
        fullWidth
        error={!!validation.description}
        value={relatedProduct?.description}
        helperText={validation.description}
        onChange={e => handleChange('description', e.target.value)}
        margin="normal"
        inputRef={inputs.description}
      />
    </Container>
  );
};

export default CompatibilityMain;
