import React from 'react';
import { styled } from '@mui/material';
import { LineAxis } from '@mui/icons-material';
import TopProductSegments from './TopProductSegments';
import TopPaymentMethods from './TopPaymentMethods';
import BranchesChart from './charts/BranchesChart';
import AccountTypesChart from './charts/AccountTypeChart';
import { useCommercialBi } from '../hooks/useCommercialBi';

interface BlockProps {
  backgroundColor?: string;
}

const Col = styled('div')(props => ({
  display: 'flex',
  flex: 1,
  [props.theme.breakpoints.down('sm')]: {
    display: 'grid',
  },
}));

const Row = styled('div')(props => ({
  display: 'flex',
  columnGap: 10,
  [props.theme.breakpoints.down('sm')]: {
    display: 'grid',
  },
}));

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  background: '#f5f5f5',
  flex: 1,
  rowGap: 15,
});

const Block = styled('div')<BlockProps>(props => ({
  borderTop: '1px solid #eee',
  borderBottom: '1px solid #eee',
  borderRight: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  flex: 1,
  '& .title': {
    background: props.backgroundColor ?? 'transparent',
    fontSize: 14,
    borderBottom: '1px solid #f5f5f5',
    padding: '20px 20px 8px 20px',
  },
  '& .main': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 20,
  },
}));

const CommercialBiDashboard: React.FC = () => {
  const { data, isBiLoading } = useCommercialBi();

  return (
    <Container>
      <Row>
        <Col>
          <Block>
            <span className="title">Fat bruto</span>
            <div className="main">
              <span className="value">{data.formattedGrossRevenue}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>

          <Block>
            <span className="title">Fat líquido</span>
            <div className="main">
              <span className="value">{data.formattedRevenue}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>

          <Block>
            <span className="title">MC</span>
            <div className="main">
              <span className="value">{data.formattedMc}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>

          <Block>
            <span className="title">Ticket médio</span>
            <div className="main">
              <span className="value">{data.formattedAverageTicket}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>
        </Col>
        <Col>
          <Block>
            <span className="title">Estoque</span>
            <div className="main">
              <span className="value">{data.formattedStockValue}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>

          <Block>
            <span className="title">Pedidos</span>
            <div className="main">
              <span className="value">{data.formattedOrdersValue}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>

          <Block>
            <span className="title">Another one</span>
            <div className="main">
              <span className="value"></span>
              <LineAxis color="secondary" />
            </div>
          </Block>
        </Col>
      </Row>

      <Row>
        <TopProductSegments />
        <TopPaymentMethods />
        {!isBiLoading && (
          <>
            <BranchesChart />
            <AccountTypesChart />
          </>
        )}
      </Row>
    </Container>
  );
};

export default CommercialBiDashboard;
