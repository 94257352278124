import React, { MouseEvent, Dispatch, SetStateAction } from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import { contactsTableTemplate } from '../contactsTableTemplate';
import { ProjectContact } from 'types/project';
import { MoreHoriz } from '@mui/icons-material';
import { useProjectContacts } from '../../hooks/useProjectContacts';

const IconButtonStyled = styled(IconButton)({
  justifySelf: 'flex-start',
  padding: 5,
});

type ContactItemTableProps = {
  contact: ProjectContact;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const ContactItemTable: React.FC<ContactItemTableProps> = ({ contact, setAnchorEl }) => {
  const { setSelectedContact } = useProjectContacts();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedContact(contact);
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      {contactsTableTemplate.map(item =>
        item.id === 'actions' ? (
          <IconButtonStyled onClick={handleClick} size="small" key={item.id}>
            <MoreHoriz key={item.id} />
          </IconButtonStyled>
        ) : (
          <div key={item.id}>
            <Typography variant="body2">{contact[item.id]}</Typography>
          </div>
        ),
      )}
    </>
  );
};

export default ContactItemTable;
