import React from 'react';
import CustomerDocument from './CustomerDocument';
import { Typography, styled } from '@mui/material';
import { useCustomer } from '../../hooks/useCustomer';

const Documents = styled('div')({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  background: '#fff',
  height: '100%',
  textAlign: 'center',
  verticalAlign: 'middle',
  marginTop: '150px',
});

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
});

const CustomerDocuments: React.FC = () => {
  const { customer, registerType } = useCustomer();

  return (
    <Container>
      {registerType === 'edit' ? (
        <>
          {customer?.link_contrato_social !== 'N' && customer?.link_contrato_social !== '' ? (
            <CustomerDocument index="link_contrato_social" linkText="Contrato Social" />
          ) : (
            <Typography variant="body1" color="error">
              Não enviado contrato social
            </Typography>
          )}
          {customer?.link_declaracao !== 'N' && customer?.link_declaracao !== '' ? (
            <CustomerDocument index="link_declaracao" linkText="Declaração de autônomo" />
          ) : (
            <Typography variant="body1" color="error">
              Não enviado declaração de autônomo
            </Typography>
          )}
          {customer?.link_documento !== 'N' && customer?.link_documento !== '' ? (
            <CustomerDocument index="link_documento" linkText="Documento com foto" />
          ) : (
            <Typography variant="body1" color="error">
              Não enviado documento com foto
            </Typography>
          )}
          {customer?.link_residencia !== 'N' && customer?.link_residencia !== '' ? (
            <CustomerDocument index="link_residencia" linkText="Comprovante de Residência" />
          ) : (
            <Typography variant="body1" color="error">
              Não enviado comprovante de residência
            </Typography>
          )}
          {customer?.link_selfie !== 'N' && customer?.link_selfie !== '' ? (
            <CustomerDocument index="link_selfie" linkText="Selfie" />
          ) : (
            <Typography variant="body1" color="error">
              Não enviado selfie
            </Typography>
          )}
        </>
      ) : (
        <Documents>Não há documentos para exibir</Documents>
      )}
    </Container>
  );
};

export default CustomerDocuments;
