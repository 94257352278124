import React, { useEffect, useRef } from 'react';
import { TextField, Grid } from '@mui/material';
import MenuItems from '../MenuItems';
import PostalCodeInput from 'components/masked-input/PostalCodeInput';
import { useCustomer } from '../../hooks/useCustomer';
import { CustomerAddressValidation } from '../validation/CustomerAddressValidation';
import InsideLoading from 'components/loading/InsideLoading';

interface CustomerAddressProps {
  validation: CustomerAddressValidation;
  loading: boolean;
}

const CustomerAddress: React.FC<CustomerAddressProps> = ({ validation, loading }) => {
  const { customer, handleChange } = useCustomer();
  const inputs = {
    cep: useRef<HTMLInputElement>(null),
    endereco: useRef<HTMLInputElement>(null),
    bairro: useRef<HTMLInputElement>(null),
    cidade: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Grid container spacing={0}>
      <Grid container direction={'row'} spacing={1}>
        <Grid item xs={3} md={3}>
          <TextField
            inputRef={inputs.cep}
            label="CEP"
            autoFocus
            placeholder="CEP"
            margin="normal"
            value={customer?.cep}
            error={!!validation.cep}
            helperText={validation.cep}
            disabled={loading}
            InputProps={{
              inputComponent: PostalCodeInput as any,
            }}
            inputMode="numeric"
            onChange={e => handleChange('cep', e.target.value)}
            type="text"
          />
        </Grid>
        {!validation.cep && (
          <Grid item xs={9} md={9}>
            <TextField
              inputRef={inputs.endereco}
              fullWidth
              label="Endereço"
              placeholder="Endereço"
              margin="normal"
              value={customer?.endereco}
              error={!!validation.endereco}
              helperText={validation.endereco}
              onChange={e => handleChange('endereco', e.target.value)}
              type="text"
              inputProps={{ style: { textTransform: 'uppercase' } }}
            />
          </Grid>
        )}
      </Grid>
      {!validation.cep && (
        <>
          <Grid container direction={'row'} spacing={1}>
            <Grid item xs={3} md={3}>
              <TextField
                inputRef={inputs.bairro}
                label="Bairro"
                placeholder="Bairro"
                margin="normal"
                value={customer?.bairro}
                error={!!validation.bairro}
                helperText={validation.bairro}
                onChange={e => handleChange('bairro', e.target.value)}
                type="text"
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />
            </Grid>
            <Grid item xs={9} md={9}>
              <TextField
                fullWidth
                label="Complemento"
                placeholder="Complemento"
                margin="normal"
                value={customer?.complemento}
                onChange={e => handleChange('complemento', e.target.value)}
                type="text"
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />
            </Grid>
          </Grid>
          <Grid container direction={'row'} spacing={1}>
            <Grid item xs={3} md={3}>
              <MenuItems />
            </Grid>
            <Grid item xs={9} md={9}>
              <TextField
                inputRef={inputs.cidade}
                fullWidth
                label="Cidade"
                placeholder="Cidade"
                margin="normal"
                value={customer?.cidade}
                error={!!validation.cidade}
                helperText={validation.cidade}
                onChange={e => handleChange('cidade', e.target.value)}
                type="text"
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />
            </Grid>
          </Grid>
        </>
      )}
      {loading && <InsideLoading />}
    </Grid>
  );
};

export default CustomerAddress;
