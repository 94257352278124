import React from 'react';
import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { useFetchViews } from './hooks/useFetchViews';
import { styled } from '@mui/material';
import { useAbcSegments } from './hooks/useAbcSegments';
import Pagination from 'components/pagination/Pagination';
import { AbcSegmentProvider } from './hooks/useAbcSegment';
import SegmentListTable from './segment/list/table/SegmentListTable';
import SegmentListModule from './segment/list/module/SegmentListModule';
import { AbcSegmentsFilterParams } from './CurveAbcSegment';
import FilterMore from 'components/filter-more/FilterMore';
import AbcSegmentExport from './export/AbcSegmentExport';
import AbcSegmentDateFilter from './AbcSegmentDateFilter';
import AbcSegmentFilterBox from './AbcSegmentFilterBox';
import AbcSegmentTotal from './total/AbcSegmentTotal';
import InsideLoading from 'components/loading/InsideLoading';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

interface AbcSegmentPageProps {
  filter: AbcSegmentsFilterParams;
  handleChangeFilter(index: keyof AbcSegmentsFilterParams, value: any): void;
}

const AbcSegmentPage: React.FC<AbcSegmentPageProps> = ({ filter, handleChangeFilter }) => {
  const {
    filteredSegments,
    handleSortSegment,
    displayMode,
    searchText,
    handleSearch,
    handleSearchInputChange,
    handleSearchSubmit,
    loading,
    orderedIndex,
    formattedTotal,
  } = useAbcSegments();

  const [
    handleCloseDialogDesktop,
    handleCloseDialog,
    dialogDate,
    excelExport,
    dialogDateDesktop,
    setExcelExport,
    setDialogDate,
    setDialogDateDesktop,
  ] = useFetchViews(handleSearch, searchText);

  return (
    <AbcSegmentProvider value={{ filter, handleChangeFilter, searchValue: searchText }}>
      {dialogDate && (
        <AbcSegmentDateFilter
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          onSearch={handleCloseDialog}
          onExited={() => setDialogDate(false)}
        />
      )}

      {dialogDateDesktop && (
        <FilterMore
          onSearch={handleCloseDialogDesktop}
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          onExited={() => setDialogDateDesktop(false)}
        />
      )}

      {excelExport && <AbcSegmentExport segments={filteredSegments} onExited={() => setExcelExport(false)} />}

      <Appbar title="Curva Abc de Segmento" />

      <AbcSegmentTotal formattedTotal={formattedTotal} />

      <AbcSegmentFilterBox
        filter={filter}
        disabled={filteredSegments.length === 0}
        handleOpenExcel={() => setExcelExport(true)}
        handleOpenDialog={() => setDialogDate(true)}
        handleOpenDialogDesktop={() => setDialogDateDesktop(true)}
        searchText={searchText}
        handleSearchInputChange={handleSearchInputChange}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
      />

      {loading ? (
        <InsideLoading />
      ) : filteredSegments.length === 0 ? (
        <NoData message="Nada para mostrar" />
      ) : (
        <PaginationProvider>
          <Container>
            {filteredSegments.length === 0 ? (
              <NoData message="Nenhum segmento encontrado." />
            ) : displayMode === 'list' ? (
              <SegmentListTable
                isSubgroup
                segments={filteredSegments}
                handleSort={handleSortSegment}
                orderedIndex={orderedIndex}
              />
            ) : (
              <SegmentListModule segments={filteredSegments} />
            )}
            <Pagination count={filteredSegments.length} />
          </Container>
        </PaginationProvider>
      )}
    </AbcSegmentProvider>
  );
};

export default AbcSegmentPage;
