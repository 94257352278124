import { styled } from '@mui/material';
import NoData from 'components/no-data/NoData';
import { useProject } from 'pages/projects/registration/hook/useProject';
import React, { useEffect, useState, useMemo } from 'react';
import { ProjectActionContextProvider } from './hooks/useProjectActions';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import ActionListTable from './list/table/ActionListTable';
import ActionListModule from './list/module/ActionListModule';
import ProjectContactForm from './form/ProjectActionForm';
import { addProjectAction } from 'pages/projects/registration/reducer/action';
import { ProjectAction, ProjectActionFormContent } from 'types/project';
import { useProjectActionValidation } from './validation/projectActionValidation';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const ProjectActions: React.FC = () => {
  const { isMobile, windowWidth } = useApp();
  const { project, dispatch } = useProject();
  const [filteredCosts, setFilteredCosts] = useState<ProjectAction[]>([]);
  const [selectedProjectAction, setSelectedProjectAction] = useState<ProjectAction | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const displayMode = useMemo(() => (isMobile || windowWidth < 930 ? 'module' : 'list'), [isMobile, windowWidth]);
  const [validation, setValidation, validate] = useProjectActionValidation();

  useEffect(() => {
    setFilteredCosts(project.actions);
  }, [project.actions]);

  function handleSort(index: string) {
    const _contacts = sort(index, filteredCosts);
    setFilteredCosts(_contacts);
  }

  async function handleValidation(data: ProjectActionFormContent) {
    setValidation({});

    await validate(data);
    handleSubmit(data);
  }

  function handleSubmit(data: ProjectActionFormContent) {
    dispatch(addProjectAction(data));
  }

  return (
    <Container>
      <ProjectContactForm validation={validation} handleSubmit={handleValidation} />

      {filteredCosts.length === 0 ? (
        <NoData message="Nenhuma ação para mostrar" />
      ) : (
        <ProjectActionContextProvider value={{ selectedProjectAction, setSelectedProjectAction }}>
          {displayMode === 'list' ? (
            <ActionListTable actions={filteredCosts} handleSort={handleSort} orderedIndex={orderedIndex} />
          ) : (
            <ActionListModule actions={filteredCosts} />
          )}
        </ProjectActionContextProvider>
      )}
    </Container>
  );
};

export default ProjectActions;
