import React from 'react';
import { ListItemButton, Typography, styled } from '@mui/material';
import { ProductList } from 'types/product';

type WasteProductItemModuleProps = {
  product: ProductList;
  handleSelectProduct(product: ProductList | null): void;
  selectedProduct: ProductList | null;
};

const ImageWrapper = styled('div')({
  width: 50,
  height: 50,
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
  flexShrink: 0,
});

const Image = styled('img')({
  width: '100%',
  objectFit: 'contain',
});

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 60,
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: 5,
  '& .data': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& > .product-name': {
      // maxWidth: '80%',
    },
  },
});

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: 5,
  '& p, span': {
    fontSize: 12,
  },
  '&.selected': {
    '& p, span': {
      color: '#fff',
    },
  },
});

const WasteProductItemModule: React.FC<WasteProductItemModuleProps> = ({
  selectedProduct,
  handleSelectProduct,
  product,
}) => {
  function handleClick() {
    if (selectedProduct?.codigo_produto === product.codigo_produto) {
      handleSelectProduct(null);
    } else {
      handleSelectProduct(product);
    }
  }

  return (
    <>
      <CustomListItemButton
        style={{
          opacity:
            selectedProduct?.codigo_produto === product.codigo_produto ? 1 : parseFloat(product.est) <= 0 ? 0.5 : 1,
          backgroundColor: selectedProduct?.codigo_produto === product.codigo_produto ? '#30519f' : '#fff',
        }}
        onClick={handleClick}
      >
        <Row className={selectedProduct?.codigo_produto === product.codigo_produto ? 'selected' : ''}>
          <ImageWrapper>
            <Image src={product.foto_principal} alt="Foto produto" />
          </ImageWrapper>
          <div className="data">
            <Typography variant="caption" gutterBottom>
              {product.codigo_produto} | {product.subgrupo}
            </Typography>
            <Typography variant="body2" className="product-name">
              {product.produto}
            </Typography>
          </div>
        </Row>
      </CustomListItemButton>
    </>
  );
};

export default WasteProductItemModule;
