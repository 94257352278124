import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { useApp } from 'hooks/app';

export type BillTabValues = 'description' | 'settings';

type BillTabProps = {
  handleChange(value: BillTabValues): void;
  tab: BillTabValues;
};

const BillTab: React.FC<BillTabProps> = ({ tab, handleChange }) => {
  const app = useApp();

  return (
    <Tabs value={tab} onChange={(e, value) => handleChange(value)} variant={app.isMobile ? 'scrollable' : 'standard'}>
      <Tab label="Descrição" value="description" />
      <Tab label="Configurações" value="settings" />
    </Tabs>
  );
};

export default BillTab;
