import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Project } from 'types/project';
import { useProjects } from 'pages/projects/hook/useProjects';
import { IconButton, Typography, ListItemButton, styled } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

const ListItemButtonStyled = styled(ListItemButton)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '80%',
    alignItems: 'center',
  },
  '& .project-id': {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  '& .description': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    lineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
});

const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  bottom: 5,
  right: 5,
});

type ProjectItemModuleProps = {
  project: Project;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const ProjectItemModule: React.FC<ProjectItemModuleProps> = ({ project, setAnchorEl }) => {
  const { setSelectedProject } = useProjects();

  function handleClick(project: Project) {
    setSelectedProject(project);
  }

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    setSelectedProject(project);
  }

  return (
    <ListItemButtonStyled onClick={() => handleClick(project)}>
      <Typography className="project-id" variant="h6">
        {project.id}
      </Typography>
      <Typography className="description" variant="caption" gutterBottom>
        {project.description}
      </Typography>

      <Typography variant="body2">
        Total: <b>{project.formattedValue}</b>
      </Typography>
      <Typography variant="body2">
        Feito em <b>{project.formattedCreatedAt}</b>
      </Typography>
      <Typography variant="body2">
        Última atualização: <b>{project.formattedUpdatedAt}</b>
      </Typography>
      <Typography variant="body2">
        Status atual: <b>{project.formattedCurrentStatus}</b>
      </Typography>

      <StyledIconButton onClick={handleMoreClick}>
        <MoreVert />
      </StyledIconButton>
    </ListItemButtonStyled>
  );
};

export default ProjectItemModule;
