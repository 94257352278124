import React from 'react';
import InvoiceModuleItem from './InvoiceModuleItem';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import { Invoice } from 'types/invoices';

type InvoiceModuleListProps = {
  invoices: Invoice[];
};

const InvoiceModuleList: React.FC<InvoiceModuleListProps> = ({ invoices }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <List>
      {invoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(invoice => (
        <InvoiceModuleItem key={invoice.id} invoice={invoice} />
      ))}
    </List>
  );
};

export default InvoiceModuleList;
