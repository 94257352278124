import React, { useState, useContext, useCallback, ReactElement, ReactNode } from 'react';
import {
  Dialog as NativeDialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Theme,
  styled,
  AppBar as NativeAppbar,
  Tooltip,
} from '@mui/material';
import { useApp } from 'hooks/app';
import { makeStyles } from '@mui/styles';
import { SIDEBAR_WIDTH } from 'constants/constants';
import { Close } from '@mui/icons-material';

interface MakeStyleProps {
  backgroundColor: string;
  height: number | string;
  isMobile: boolean;
  title: boolean;
}

interface StyledAppbarProps {
  headerbackground: string;
}

interface ContentProps {
  tab: boolean;
  existstitle: boolean;
}

const useStyles = makeStyles<Theme, MakeStyleProps>(theme => ({
  root: {
    paddingRight: '0!important',
  },
  paper: ({ backgroundColor, height }: { backgroundColor: string; height: string | number }) => ({
    position: 'relative',
    backgroundColor: backgroundColor || '#fff',
    [theme.breakpoints.up('md')]: {
      height,
    },
  }),
}));

const Grow = styled('div')({
  flexGrow: 1,
});

const Content = styled('div')<ContentProps>(props => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginTop: props.tab ? 104 : props.existstitle ? 64 : 0,
  paddingTop: 15,
  paddingLeft: 15,
  paddingRight: 15,
  paddingBottom: props.existstitle ? 25 : 15,
  overflowY: 'auto',
  [props.theme.breakpoints.down('md')]: {
    marginTop: props.tab ? 104 : 56,
  },
  [props.theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
    marginTop: props.tab ? 94 : 48,
  },
  '@media print': {
    padding: 0,
    margin: 0,
  },
}));

const StyledAppbar = styled(AppBar)<StyledAppbarProps>(({ theme, headerbackground }) => ({
  position: 'absolute',
  marginBottom: 45,
  backgroundColor: headerbackground || theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    position: 'fixed',
  },
  '@media print': {
    display: 'none',
  },
}));

type DialogContextData = {
  handleClose(): void;
};

export const DialogContext = React.createContext<DialogContextData>({} as DialogContextData);

export const DialogConsumer = DialogContext.Consumer;

export function useDialog(): DialogContextData {
  const context = useContext(DialogContext);
  return context;
}

type DialogProps = {
  onExited(): void;
  title?: string;
  titleColor?: string;
  ComponentActions?: ReactElement;
  backgroundColor?: string;
  headerbackground?: string;
  hideBackdrop?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  height?: string | number;
  fullScreen?: boolean;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  onScroll?: () => void;
  children: ReactNode;
  Tab?: ReactElement;
  contentStyle?: React.CSSProperties;
};

interface TabsAppbarProps {
  isOpen: boolean;
}

const TabsAppBar = styled(NativeAppbar)<TabsAppbarProps>(({ theme, isOpen }) => ({
  position: 'absolute',
  top: 64,
  [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
    top: 48,
  },
  [theme.breakpoints.up('lg')]: {
    backgroundColor: theme.palette.primary.light,
  },
  paddingLeft: isOpen ? SIDEBAR_WIDTH : 0,
  transition: 'padding-left 400ms ease',
}));

const Dialog: React.FC<DialogProps> = ({
  onExited,
  title,
  ComponentActions,
  children,
  backgroundColor = '#fff',
  titleColor = '#fff',
  headerbackground = '',
  hideBackdrop = false,
  maxWidth = 'md',
  height = '100vh',
  fullScreen,
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  onScroll,
  contentStyle,
  Tab,
}) => {
  const [open, setOpen] = useState(true);
  const app = useApp();
  const styleProps = { backgroundColor, title: !!title, isMobile: app.isMobile, height };
  const classes = useStyles(styleProps);

  const handleClose = useCallback(
    (reason?: string) => {
      if (reason === 'backdropClick' && disableBackdropClick) {
        return;
      }

      setOpen(false);
    },
    [disableBackdropClick],
  );

  return (
    <NativeDialog
      classes={{ root: classes.root, paper: classes.paper }}
      hideBackdrop={hideBackdrop}
      open={open}
      onClose={(event, reason) => handleClose(reason)}
      fullWidth
      fullScreen={fullScreen || app.isMobile || app.windowWidth < 960}
      maxWidth={maxWidth}
      disableEscapeKeyDown={disableEscapeKeyDown}
      TransitionProps={{
        onExited,
      }}
    >
      {title && (
        <StyledAppbar headerbackground={headerbackground}>
          <Toolbar>
            <Tooltip title="Fechar">
              <IconButton style={{ color: titleColor }} onClick={() => handleClose()}>
                <Close />
              </IconButton>
            </Tooltip>
            <Typography fontWeight={500} color={titleColor} noWrap>
              {title}
            </Typography>
            <Grow />
            <DialogContext.Provider value={{ handleClose }}>
              <div>{ComponentActions}</div>
            </DialogContext.Provider>
          </Toolbar>
          {Tab && <TabsAppBar isOpen={app.isOpenedMenu && !app.isMobile && window.innerWidth > 960}>{Tab}</TabsAppBar>}
        </StyledAppbar>
      )}
      <DialogContext.Provider value={{ handleClose }}>
        <Content existstitle={!!title} tab={!!Tab} style={contentStyle} id="native-dialog" onScroll={onScroll}>
          {children}
        </Content>
      </DialogContext.Provider>
    </NativeDialog>
  );
};

export default Dialog;
