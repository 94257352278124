import { styled } from '@mui/material';
import React, { FormEvent, ReactNode, useState } from 'react';
import CommercialBiMainFilter from './CommercialBiMainFilter';
import { Branch } from 'types/branch';
import CommercialBiTabs, { TabValues } from './tabs/CommercialBiTabs';
import CommercialBiCities from './lists/cities/CommercialBiCities';
import CommercialBiDashboard from './dashboard/CommercialBiDashboard';
import CommercialBiSegments from './lists/product-segments/CommercialBiSegments';
import CommercialBiPaymentMethods from './lists/payment-methods/CommercialBiPaymentMethods';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  rowGap: 10,
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  borderTop: '1px solid #eee',
  flex: 1,
});

const CommercialBiMain: React.FC = () => {
  const [, setSelectedBranch] = useState<Branch | null>(null);
  const [tab, setTab] = useState<TabValues>('dashboard');

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
  }

  function getContent(): ReactNode {
    const mapping = {
      dashboard: <CommercialBiDashboard />,
      cities: <CommercialBiCities />,
      segments: <CommercialBiSegments />,
      paymentMethods: <CommercialBiPaymentMethods />,
    };

    return mapping[tab];
  }

  return (
    <Container>
      <CommercialBiMainFilter setSelectedBranch={setSelectedBranch} handleSubmit={handleSubmit} />
      <CommercialBiTabs tab={tab} handleChange={tab => setTab(tab)} />
      <Content>{getContent()}</Content>
    </Container>
  );
};

export default CommercialBiMain;
