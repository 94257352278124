import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { CommissionCategoryProvider } from './hooks/useCommissionCategory';
import CommissionCategoriesReportFilter from './CommissionCategoryReportFilter';
import CommissionCategoriesReportData from './CommissionCategoryReportData';
import CommissionCategoriesListTable from './list/table/CommissionCategoryListTable';
import CommissionCategoriesListModule from './list/module/CommissionCategoryListModule';
import { moneyFormat, numberFormat } from '../../../helpers/numberFormat';
import { useApp } from '../../../hooks/app';
import useTableOrder from '../../../hooks/tableOrder';
import { CommissionCategory } from '../../../types/reports/commissionCategory';
import Appbar from '../../../components/appbar/Appbar';
import FilterBox from '../../../components/filter-box/FilterBox';
import KeyboardDatePicker from '../../../components/pickers/DesktopDatePicker';
import TableLoading from '../../../components/loading/TableLoading';
import DisplayModeButtons from '../../../components/display-buttons/DisplayModeButtons';
import TableContainer from '../../../components/table/TableContainer';
import PaginationProvider from '../../../hooks/pagination';
import Pagination from '../../../components/pagination/Pagination';
import ModuleLoading from '../../../components/loading/ModuleLoading';
import NoData from '../../../components/no-data/NoData';
import { MdSearch } from 'react-icons/md';
import CommissionCategoryTab, { CommissionCategoryTabValues } from './CommissionCategoryTab';
import CommissionSellersListTable from './list/table/CommissionSellersListTable';
import CommissionSellersListModule from './list/module/CommissionSellersListModule';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { commissionCategoriesTableTemplate } from './template/CommissionCategoryTableTemplate';
import { SellersCommissionTableTemplate } from './template/SellersCommissionTableTemplate';
import CommissionSellersReportData from './CommissionSellersReportData';
import FilterActions from 'components/filter-box/FilterActions';
import SelectBranch from 'components/select-branches/SelectBranch';
import { useSelector } from 'store/selector';
import { makeStyles } from '@mui/styles';

export interface CommissionCategoryFilter {
  initialDate: Date | null;
  finalDate: Date | null;
  branchId: number;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '150px 200px 1fr',
    columnGap: 10,
    flex: 1,
    '& > .search-params': {
      marginLeft: 5,
      display: 'grid',
      gridTemplateColumns: '200px 150px 200px',
      columnGap: 10,
    },
  },
  select: {
    marginTop: '16px',
  },
  iconButton: {
    marginTop: '10px',
    maxWidth: '40px',
    maxHeight: '40px',
    marginLeft: '-50px',
  },
  searchParams: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    verticalAlign: 'middle',
    marginTop: '150px',
  },
});

const CommissionCategoryReport: React.FC = () => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const { h2iApi, windowWidth, isMobile } = useApp();
  const [tab, setTab] = useState<CommissionCategoryTabValues>('by_seller');
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(isMobile || windowWidth < 930 ? 'module' : 'list');
  const [loading, setLoading] = useState(false);
  const [dialogDate, setDialogDate] = useState(false);
  const [shownReport, setShownReport] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [searchText, setSearchText] = useState('');
  const [selectedBranch, setSelectedBranch] = useState<any>(0);
  const [commissionCategories, setCommissionCategories] = useState<CommissionCategory[]>([]);
  const [filterdCommissionCategories, setFilteredCommissionCategories] = useState<CommissionCategory[]>([]);
  const [selectedCommissionCategory, setSelectedCommissionCategory] = useState<CommissionCategory | null>(null);
  const [filter, setFilter] = useState<CommissionCategoryFilter>({
    initialDate: startOfDay(subDays(new Date(), 30)),
    finalDate: endOfDay(new Date()),
    branchId:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
  });

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  useEffect(() => {
    setFilteredCommissionCategories(commissionCategories);
  }, [commissionCategories]);

  function handleChangeFilter(index: keyof CommissionCategoryFilter, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string) {
    const filtered = sort(index, filterdCommissionCategories);
    setFilteredCommissionCategories(filtered);
  }

  function handleSearchSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    handleSearch(searchText);
  }

  const handleSearch = useCallback(
    (searchValue?: string) => {
      if (!h2iApi) return;

      const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
      const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

      setLoading(true);

      h2iApi
        .get(`/api/relcomissaocategoria`, {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.branchId || '',
            filtro: searchValue,
            tipo: tab === 'by_seller' ? 'V' : 'C',
          },
        })
        .then(response => {
          if (!response.data.relcomissao) {
            setCommissionCategories([]);
            return;
          }

          const _commissionCategories = response.data.relcomissao.map(item => {
            item.formattedTotal = moneyFormat(item.vl_total);
            item.formattedPrice = moneyFormat(item.vl_unitario);
            item.formattedCost = moneyFormat(item.custo);
            item.formattedMc = numberFormat(item.mc);
            item.formattedSpiff = numberFormat(item.spiff);

            return item;
          });

          setCommissionCategories(_commissionCategories);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi, filter.initialDate, filter.finalDate, filter.branchId, tab],
  );

  function handleSearchInputChange(value: string) {
    setSearchText(value);
  }

  return (
    <CommissionCategoryProvider
      value={{
        selectedCommissionCategory,
        setSelectedCommissionCategory,
        filter,
        handleChangeFilter,
      }}
    >
      {dialogDate && <CommissionCategoriesReportFilter onExited={() => setDialogDate(false)} />}

      {shownReport && tab === 'by_category' ? (
        <CommissionCategoriesReportData
          data={commissionCategories}
          branch={selectedBranch}
          onExited={() => setShownReport(false)}
        />
      ) : (
        shownReport &&
        tab === 'by_seller' && (
          <CommissionSellersReportData
            data={commissionCategories}
            branch={selectedBranch}
            onExited={() => setShownReport(false)}
          />
        )
      )}
      <Appbar
        title="Itens vendidos"
        Tab={<CommissionCategoryTab tab={tab} handleChange={tab => setTab(tab)} />}
        ActionsComponent={
          <>
            {windowWidth < 960 && isMobile && (
              <Button color="primary" variant="contained" onClick={() => setShownReport(true)}>
                Imprimir
              </Button>
            )}
            <FilterActions handleOpenDialog={() => setDialogDate(true)} />
          </>
        }
      />
      <PageHeaderActions
        title={`Relação de comissão dos vendedores por ${tab === 'by_seller' ? 'vendedor' : 'categoria'}`}
        description={`Gerencie a comissão dos vendedores ${tab === 'by_seller' ? '' : 'por categoria'}`}
        ActionComponent={
          <Button color="primary" variant="contained" onClick={() => setShownReport(true)}>
            Imprimir
          </Button>
        }
      />
      <FilterBox>
        <div className={classes.filter}>
          <SelectBranch
            branch_id={filter.branchId}
            handleChange={e => handleChangeFilter('branchId', e)}
            setBranch={branch => setSelectedBranch(branch)}
          />

          <div className="search-params">
            {displayMode === 'list' ? (
              <>
                <KeyboardDatePicker
                  label="Data inicial"
                  value={filter.initialDate}
                  onChange={date => handleChangeFilter('initialDate', date)}
                />
                <KeyboardDatePicker
                  label="Data Final"
                  value={filter.finalDate}
                  onChange={date => handleChangeFilter('finalDate', date)}
                />
                <form onSubmit={handleSearchSubmit}>
                  <TextField
                    label="Pesquisar"
                    placeholder="Digite sua pesquisa"
                    autoFocus
                    value={searchText}
                    onChange={e => handleSearchInputChange(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton type="submit">
                            <MdSearch color="#888" size={20} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </>
            ) : (
              <form onSubmit={handleSearchSubmit}>
                <TextField
                  label="Pesquisar"
                  placeholder="Digite sua pesquisa"
                  autoFocus
                  value={searchText}
                  onChange={e => handleSearchInputChange(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton type="submit">
                          <MdSearch color="#888" size={20} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            )}
          </div>
        </div>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </FilterBox>
      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filterdCommissionCategories.length === 0 ? (
        <NoData message="Nenhum item para mostrar" />
      ) : (
        <TableContainer
          tableTemplate={tab === 'by_seller' ? SellersCommissionTableTemplate : commissionCategoriesTableTemplate}
        >
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <>
                  {tab === 'by_category' ? (
                    <CommissionCategoriesListTable
                      commissionCategories={filterdCommissionCategories}
                      handleSort={handleSort}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    <CommissionSellersListTable
                      sellersCommission={filterdCommissionCategories}
                      handleSort={handleSort}
                      orderedIndex={orderedIndex}
                    />
                  )}
                </>
              ) : (
                <>
                  {tab === 'by_category' ? (
                    <CommissionCategoriesListModule commissionCategories={filterdCommissionCategories} />
                  ) : (
                    <CommissionSellersListModule sellersCommission={filterdCommissionCategories} />
                  )}
                </>
              )}
              <Pagination count={filterdCommissionCategories.length} />
            </div>
          </PaginationProvider>
        </TableContainer>
      )}
    </CommissionCategoryProvider>
  );
};

export default CommissionCategoryReport;
