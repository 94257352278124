import React from 'react';
import { ListItemIcon, ListItemText, ListItemButton, styled } from '@mui/material';

const StyledListItemButton = styled(ListItemButton)(props => ({
  paddingLeft: 16,
  borderLeft: '2px solid transparent',
  minHeight: 40,
  paddingTop: 4,
  paddingBottom: 4,
  color: props.theme.palette.primary.contrastText,
}));

const StyledListItemIcon = styled(ListItemIcon)(props => ({
  color: props.theme.palette.primary.contrastText,
  '& > svg': {
    fontSize: 22,
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  '& > span': {
    fontSize: 13,
  },
}));

interface SidebarItemProps {
  text: string;
  icon: React.ReactElement;
  handleClick(): void;
}

const SidebarBackAction: React.FC<SidebarItemProps> = ({ icon, text, handleClick }) => {
  return (
    <StyledListItemButton onClick={handleClick}>
      <StyledListItemIcon color="inherit">{icon}</StyledListItemIcon>
      <StyledListItemText color="inherit" primary={text} />
    </StyledListItemButton>
  );
};

export default SidebarBackAction;
