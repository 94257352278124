import React, { useRef } from 'react';
import { usePurchaseOrder } from '../../hook/usePurchaseOrder';
import { MenuItem, TextField, styled } from '@mui/material';

const Grid = styled('div')({
  rowGap: 15,
  columnGap: 10,
  display: 'grid',
  gridTemplateColumns: '0.3fr 1fr',
});

const PurchaseOrderThirdStep: React.FC = () => {
  const { order, handleChange, thirdValidation } = usePurchaseOrder();

  const inputs = {
    cfop: useRef<HTMLInputElement>(null),
  };

  return (
    <Grid>
      <TextField
        disabled={order.id_filial === 0}
        inputRef={inputs.cfop}
        error={!!thirdValidation.cfop}
        helperText={thirdValidation.cfop}
        label="CFOP"
        autoFocus
        placeholder="CFOP"
        type="number"
        inputMode="numeric"
        value={order.cfop || ''}
        onChange={e => handleChange('cfop', e.target.value)}
      />

      <TextField
        disabled={order.id_filial === 0}
        label="Descrição"
        placeholder="Descrição"
        value={order.descricao_cfop}
        onChange={e => handleChange('descricao_cfop', e.target.value)}
      />
      <TextField
        disabled={order.id_filial === 0}
        select
        label="Modelo NF"
        value={order.modelo_nf}
        onChange={e => handleChange('modelo_nf', e.target.value)}
      >
        <MenuItem value={'N'}>NF-e</MenuItem>
        <MenuItem value={'M'}>Manual</MenuItem>
      </TextField>

      <TextField
        disabled={order.id_filial === 0}
        label="Chave Nfe"
        placeholder="Chave Nfe"
        value={order.chave_nfe}
        type="number"
        inputMode="numeric"
        onChange={e => handleChange('chave_nfe', e.target.value)}
      />
      <TextField
        disabled={order.id_filial === 0}
        label="Série"
        placeholder="Série"
        value={order.serie_nfe || ''}
        type="number"
        inputMode="numeric"
        onChange={e => handleChange('serie_nfe', e.target.value)}
      />

      <TextField
        disabled={order.id_filial === 0}
        label="Nº Nota"
        placeholder="Nº Nota"
        value={order.notafiscal || ''}
        type="number"
        inputMode="numeric"
        onChange={e => handleChange('notafiscal', e.target.value)}
      />
    </Grid>
  );
};

export default PurchaseOrderThirdStep;
