import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { Reseller } from 'types/reseller';
import { useResellers } from 'pages/resellers/hooks/useResellers';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type ResellerItemTableProps = {
  reseller: Reseller;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const ResellerItemTable: React.FC<ResellerItemTableProps> = ({ reseller, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedReseller } = useResellers();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedReseller(reseller);
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{reseller[item.id]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default ResellerItemTable;
