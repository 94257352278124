import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useCompatibilities } from '../hooks/useCompatibilities';

type CompatibilityListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const CompatibilityListMenu: React.FC<CompatibilityListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { handleDelete } = useCompatibilities();

  function handleDeleteClick() {
    handleDelete();
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default CompatibilityListMenu;
