import { Menu, MenuItem } from '@mui/material';
import { useProject } from 'pages/projects/registration/hook/useProject';
import { removeProjectProduct } from 'pages/projects/registration/reducer/action';
import React, { Dispatch, SetStateAction } from 'react';
import { useProjectProducts } from '../hooks/useProducts';

type ProjectProductsListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const ProjectProductsListMenu: React.FC<ProjectProductsListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { dispatch } = useProject();
  const { selectedProduct } = useProjectProducts();

  function handleDeleteClick() {
    if (!selectedProduct) {
      return;
    }

    dispatch(removeProjectProduct(selectedProduct.id));
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default ProjectProductsListMenu;
