import React, { useState } from 'react';
import AbcProductPage, { AbcProductFilterParams } from './AbcProduct';
import ProductProvider from './hooks/useAbcProducts';
import { endOfDay, startOfDay } from 'date-fns';
import { useSelector } from 'store/selector';

const CurveAbc: React.FC = () => {
  const user = useSelector(state => state.user);
  const [filter, setFilter] = useState<AbcProductFilterParams>({
    initialDate: startOfDay(new Date()),
    finalDate: endOfDay(new Date()),
    selectedBranchId:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
    productId: 0,
    typeSale: 'T',
    categoryIds: [],
    subCategoryIds: [],
    madeBy: '',
    segmentIds: [],
    unitIds: [],
    subGroupIds: [],
    manufacturerIds: [],
  });

  function handleChangeFilter(index: keyof AbcProductFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <ProductProvider filter={filter}>
      <AbcProductPage handleChangeFilter={handleChangeFilter} filter={filter} />
    </ProductProvider>
  );
};

export default CurveAbc;
