import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useCompatibilityProducts } from '../hooks/useProducts';
import { useCompatibility } from 'pages/compatibilities/registration/hook/useCompatibility';

type ProjectProductsListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const ProjectProductsListMenu: React.FC<ProjectProductsListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedProduct } = useCompatibilityProducts();
  const { setCompatibility } = useCompatibility();

  function handleDeleteClick() {
    if (!selectedProduct) {
      return;
    }

    setCompatibility(state => ({
      ...state,
      products: state.products.filter(product => product.id !== selectedProduct.id),
    }));
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default ProjectProductsListMenu;
