import React, { useState, useEffect } from 'react';
import { Typography, styled } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { useApp } from 'hooks/app';
import { format, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { AbcProductSegment } from 'types/abcProduct';
import { moneyFormat } from 'helpers/numberFormat';
import SegmentListTable from 'pages/reports/commercial/abc/product/segment/list/table/SegmentListTable';
import useTableOrder from 'hooks/tableOrder';
import PaginationProvider from 'hooks/pagination';
import NoData from 'components/no-data/NoData';
import SegmentListModule from 'pages/reports/commercial/abc/product/segment/list/module/SegmentListModule';
import { segmentTableTemplate } from '../template/segmentTableTemplate';
import InsideLoading from 'components/loading/InsideLoading';
import { useDavReviewDetail } from './hooks/useDavReviewDetail';

const Content = styled('div')({
  marginTop: 20,
  display: 'none',
  rowGap: '10px',
  '&.open': {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
});

const Container = styled('div')(({ theme }) => ({
  margin: '40px 0',
  borderLeft: `3px solid ${theme.palette.secondary.main}`,
  borderRadius: 6,
  paddingLeft: 10,
}));

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 15,
  cursor: 'pointer',
  paddingBottom: 5,
  borderBottom: '1px dashed #666',
});

const CustomArrowDownward = styled(ArrowDownward)({
  fontSize: 18,
  transform: 'rotate(0deg)',
  transition: 'all 0.3s ease',
  '&.open': {
    transform: 'rotate(180deg)',
  },
});

const Box = styled('div')({
  display: 'flex',
  textAlign: 'center',
  padding: '5px 15px',
  flexDirection: 'column',
  border: '2px dashed #30519f',
});

const Flex = styled('div')({
  gap: 10,
  display: 'flex',
  justifyContent: 'center',
});

const DavReviewDetailHistoric: React.FC = () => {
  const { h2iApi } = useApp();
  const [loading, setLoading] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const { order, displayMode } = useDavReviewDetail();
  const [segments, setSegments] = useState<AbcProductSegment[]>([]);
  const [filtered, setFiltered] = useState<AbcProductSegment[]>([]);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    mc: 0,
    total: 0,
  });

  useEffect(() => {
    setFiltered(segments);
  }, [segments]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  useEffect(() => {
    if (!h2iApi || !order) {
      return;
    }

    setLoading(true);

    const formattedInitialDate = format(subDays(new Date(), 180), 'P', { locale: ptBR });
    const formattedFinalDate = format(new Date(), 'P', { locale: ptBR });

    h2iApi
      .get('/api/relcurvaabcsegmento', {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          cnpj: order.Cnpj,
        },
      })
      .then(response => {
        const _segments: AbcProductSegment[] = response.data.map(item => {
          item.formattedTotal = moneyFormat(item.total);
          item.formattedCoust = moneyFormat(item.custo);
          item.formattedMc = moneyFormat(item.mc);
          item.formattedSpiff = moneyFormat(item.spiff);
          item.formattedUnitary = moneyFormat(item.unitario);

          return item;
        });

        setValues({
          mc: response.data.reduce((sum, item) => sum + item.mc, 0),
          total: response.data.reduce((sum, item) => sum + item.total, 0),
        });

        setSegments(_segments.sort((a, b) => b.total - a.total));
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [order, h2iApi]);

  return (
    <>
      {order && (
        <Container>
          <Row onClick={() => setOpen(!open)}>
            <Typography fontSize={20}>histórico 6 meses</Typography>
            <CustomArrowDownward className={open ? 'open' : undefined} />
          </Row>
          <Content className={open ? 'open' : undefined}>
            {loading ? (
              <InsideLoading />
            ) : (
              <>
                <PaginationProvider>
                  {filtered.length === 0 ? (
                    <NoData message="Nenhum segmento encontrado." />
                  ) : displayMode === 'list' ? (
                    <SegmentListTable
                      tableTemplate={segmentTableTemplate}
                      useMinHeight={false}
                      isSubgroup={false}
                      segments={filtered}
                      handleSort={handleSort}
                      orderedIndex={orderedIndex}
                    />
                  ) : (
                    <SegmentListModule segments={filtered} />
                  )}
                </PaginationProvider>
                <div>
                  <Typography variant="body2" align="center" color="primary" marginY={2} fontWeight={600}>
                    totalizador
                  </Typography>

                  <Flex>
                    <Box>
                      <Typography variant="caption">MC</Typography>
                      <Typography variant="caption">{moneyFormat(values.mc)}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption">TOTAL</Typography>
                      <Typography variant="caption">{moneyFormat(values.total)}</Typography>
                    </Box>
                  </Flex>
                </div>
              </>
            )}
          </Content>
        </Container>
      )}
    </>
  );
};

export default DavReviewDetailHistoric;
