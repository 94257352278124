import { useApp } from 'hooks/app';
import { FormEvent, useState } from 'react';
import { H2iBuyer } from 'types/h2iBuyer';
import { BuyerValidation, useBuyerValidation } from '../validation/BuyerValidation';
import * as yup from 'yup';

export const initialState: H2iBuyer = {
  id: 0,
  nome: '',
  ativo: 'S',
  cpf: '',
};

type UseFetchNewBuyer = [
  (index: keyof H2iBuyer, value: any) => void,
  (e?: FormEvent<HTMLFormElement>) => void,
  H2iBuyer | null,
  BuyerValidation,
  boolean,
];

export function useFetchNewBuyer(onSave: () => void): UseFetchNewBuyer {
  const [buyer, setBuyer] = useState<H2iBuyer>(initialState);
  const [saving, setSaving] = useState(false);
  const { h2iApi } = useApp();
  const [validation, setValidation, validate] = useBuyerValidation();

  function handleChange(index: keyof H2iBuyer, value: any) {
    setBuyer(buyer => ({
      ...buyer,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    if (!buyer) return;

    e?.preventDefault();

    setValidation({});

    validate(buyer)
      .then(handleSubmit)
      .catch((err: yup.ValidationError) => {
        if (err instanceof yup.ValidationError) {
          setValidation({
            [err.path as string]: err.message,
          });
        }
      });
  }

  function handleSubmit() {
    if (!h2iApi) return;
    setSaving(true);

    h2iApi
      .post(`api/savecompradores`, { ...buyer, id: '' })
      .then(response => {
        console.log(response);
        onSave();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return [handleChange, handleValidation, buyer, validation, saving];
}
