import React from 'react';
import { AbcProductSegment } from 'types/abcProduct';
import { Typography, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type SegmentItemModuleProps = {
  segment: AbcProductSegment;
};

const SegmentItemModule: React.FC<SegmentItemModuleProps> = ({ segment }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem}>
      <Typography variant="caption" color="primary">
        {segment.segmento}
      </Typography>
      <Typography variant="caption" color="primary">
        Valor total - {segment.formattedTotal}
      </Typography>
      <Typography variant="caption" color="primary">
        Valor custo - {segment.formattedCoust}
      </Typography>
      <Typography variant="caption" color="primary">
        Valor unitário - {segment.formattedUnitary}
      </Typography>
      <Typography variant="caption" color="primary">
        QTD - {segment.qtd}
      </Typography>
    </ListItem>
  );
};

export default SegmentItemModule;
