import React from 'react';
import { Typography, styled } from '@mui/material';
import TotalizingItemTable from './TotalizingItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { TotalizingAccount } from 'types/totalizingAccount';
import { useTotalizingAccounts } from '../../hooks/useTotalizingAccount';
import { accountsTableTemplate } from '../../accountsTableTemplate';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

interface TotalizingListTableProps {
  accounts: TotalizingAccount[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const TotalizingListTable: React.FC<TotalizingListTableProps> = ({ accounts, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();
  const { setSelectedAccount } = useTotalizingAccounts();

  return (
    <TableContainer tableTemplate={accountsTableTemplate}>
      <TableContent>
        <TableHeader>
          {accountsTableTemplate.map(item => (
            <HeaderItem key={item.id} onClick={() => handleSort(item.originalId)}>
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>

              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody useMaxHeight={false}>
          {accounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(account => (
            <TableRow onClick={() => setSelectedAccount(account)} key={account.id}>
              <TotalizingItemTable account={account} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default TotalizingListTable;
