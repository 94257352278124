import React from 'react';
import { Collapse, List } from '@mui/material';
import { AssuredWorkload, Diversity1, Gavel, Newspaper, Share } from '@mui/icons-material';
import { usePermissionRules } from 'hooks/permissionRules';
import { useSidebar } from '../hook/useSidebar';
import SidebarItemExpendable from '../SidebarItemExpendable';
import SidebarItemMore from '../SidebarItemMore';
import SidebarItem from '../SidebarItem';
import { AiFillEnvironment } from 'react-icons/ai';
import { FaChartArea } from 'react-icons/fa';

const Governance: React.FC = () => {
  const { checkPermission } = usePermissionRules();
  const { handleCollapseClick, collapsible, setShownUnity } = useSidebar();
  return (
    <>
      {checkPermission('governance') && (
        <>
          <SidebarItemExpendable
            onClick={() => handleCollapseClick('governance')}
            text="Governança"
            opened={collapsible.governance}
            icon={<Gavel />}
          />
          <Collapse in={collapsible.governance}>
            <List disablePadding>
              <SidebarItem nested={5} to="/governance/environment" icon={<AiFillEnvironment />} text="Environment" />

              <SidebarItem nested={5} to="/governance/chart" icon={<FaChartArea />} text="Organograma" />

              <SidebarItem nested={5} to="/governance/data-sharing" icon={<Share />} text="Data Sharing" />

              {checkPermission('governance_units') && (
                <SidebarItemMore
                  icon={<AssuredWorkload />}
                  handleClick={() => setShownUnity(true)}
                  text="Unidades"
                  nested={5}
                />
              )}

              <SidebarItem nested={5} to="/governance/rh" icon={<Diversity1 />} text="RH e DP" />

              <SidebarItem nested={5} to="/governance/new" icon={<Newspaper />} text="News" />
            </List>
          </Collapse>
        </>
      )}
    </>
  );
};

export default Governance;
