import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, IconButton, ListItemButton, styled } from '@mui/material';
import { Reseller } from 'types/reseller';
import { MoreHoriz } from '@mui/icons-material';
import { useResellers } from 'pages/resellers/hooks/useResellers';

type ResellerItemModuleProps = {
  reseller: Reseller;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff!important',
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '80%',
    alignItems: 'center',
  },
});

const CustomIconButton = styled(IconButton)({
  position: 'absolute',
  bottom: 0,
  right: 0,
});

const ResellerItemModule: React.FC<ResellerItemModuleProps> = ({ reseller, setAnchorEl }) => {
  const { setSelectedReseller, setDialogApproval } = useResellers();

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedReseller(reseller);
  }

  function handleResellerClick() {
    setSelectedReseller(reseller);
    setDialogApproval(true);
  }

  return (
    <CustomListItemButton onClick={handleResellerClick}>
      <Typography variant="caption" color="primary">
        Revendedor {reseller.id}
      </Typography>
      <Typography gutterBottom fontWeight={500} fontSize={12}>
        {reseller.company_name}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Criado em {reseller.formattedCreatedAt}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Solicitado atualização em {reseller.formattedUpdateRequiredAt}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {reseller.formattedCnpj}
      </Typography>
      <CustomIconButton size="small" onClick={e => handleMoreClick(e)}>
        <MoreHoriz />
      </CustomIconButton>
    </CustomListItemButton>
  );
};

export default ResellerItemModule;
