import React from 'react';
import PeriodItemTable from './PeriodItemTable';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import TableHeader from 'components/table/TableHeader';
import { MonthlyPeriod } from 'types/monthlyComparison';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography, Grid } from '@mui/material';
import { periodTableTemplate } from '../../periodTableTemplate';
import { useMonthlyComparison } from 'pages/reports/monthly-comparison/hooks/useMonthlyComparison';
import PeriodMoreMenu from '../PeriodMoreMenu';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  header: {
    backgroundColor: '#eee',
  },
  headerSubgroup: {
    backgroundColor: '#d9f8e5',
  },
  tableRow: {
    '& > div:nth-child(even)': {
      backgroundColor: '#eee',
    },
  },
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
});

type PeriodListTableProps = {
  periods: MonthlyPeriod[];
  handleSort(index: string, _sort: string): void;
  orderedIndex: OrderIndexData;
  isSubgroup: boolean;
};

const PeriodListTable: React.FC<PeriodListTableProps> = ({ periods, handleSort, orderedIndex, isSubgroup }) => {
  const classes = useStyles();
  const { morePeriod } = useMonthlyComparison();
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={periodTableTemplate}>
          <TableContent>
            <TableHeader className={`${isSubgroup ? classes.header : classes.headerSubgroup}`}>
              {periodTableTemplate.map(item => (
                <div
                  className={
                    item.dataType === 'number'
                      ? `${classes.headerItem} ${classes.headerItemNumeric}`
                      : classes.headerItem
                  }
                  key={item.id}
                  onClick={() => handleSort(item.originalId, 'period')}
                >
                  <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </div>
              ))}
            </TableHeader>
            <TableBody>
              <div className={`${isSubgroup ? classes.tableRow : undefined}`}>
                {periods.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(period => (
                  <>
                    <TableRow key={period.mes_ano}>
                      <PeriodItemTable isSubgroup={isSubgroup} period={period} />
                    </TableRow>
                    {isSubgroup && morePeriod?.mes_ano === period.mes_ano && (
                      <PeriodMoreMenu key={period.mes_ano} month={period.mes} />
                    )}
                  </>
                ))}
              </div>
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PeriodListTable;
