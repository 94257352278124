import { Dispatch, SetStateAction, useState } from 'react';
import { RegisterForm } from 'types/registerForm';
import * as yup from 'yup';

export interface RegisterProofResidenceValidation {
  partner_proof_residence_copy?: string;
}

interface RegisterProofResidenceValidationOptions {
  imagesRequired: boolean;
}

type UseRegisterProofResidenceValidation = [
  RegisterProofResidenceValidation,
  Dispatch<SetStateAction<RegisterProofResidenceValidation>>,
  (register: RegisterForm, options?: RegisterProofResidenceValidationOptions) => Promise<void>,
];

export function useRegisterProofResidenceValidation(): UseRegisterProofResidenceValidation {
  async function handleValidation(
    register: RegisterForm,
    options: RegisterProofResidenceValidationOptions = { imagesRequired: true },
  ) {
    const schema = yup.object().shape({
      partner_proof_residence_copy: yup
        .object()
        .nullable()
        .test('proofResidenceTest', 'cópia do comprovante de residência é obrigatório', value => {
          if (options.imagesRequired && !value) return false;
          return true;
        }),
    });

    try {
      await schema.validate(register);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<RegisterProofResidenceValidation>({});
  return [validation, setValidation, handleValidation];
}
