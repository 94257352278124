import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';

import { FinancialTicket } from 'types/financialReportList';
import { useFinancialTicket } from '../../hooks/useFinancialTicket';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
});

type TicketItemTableProps = {
  ticket: FinancialTicket;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const TicketItemTable: React.FC<TicketItemTableProps> = ({ ticket, setAnchorEl }) => {
  const classes = styles();
  const { setSelectedTicket } = useFinancialTicket();
  const { tableTemplate } = useTable();

  function handleButtonClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedTicket(ticket);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <IconButton key={item.id} onClick={handleButtonClick} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography variant="body2">{ticket[item.id]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default TicketItemTable;
