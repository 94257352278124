import React, { useState } from 'react';
import useTableOrder from 'hooks/tableOrder';

import MoreListTable from './item-more/MoreListTable';
import TableContainer from 'components/table/TableContainer';
import MoreItemTabs, { MoreTabs } from '../tabs/MoreItemTabs';
import { OrderAuthorization } from 'types/orderAuthorization';
import { MoreTableTemplate } from './item-more/moreTableTemplate';
import { useOrdersAuthorization } from '../hooks/useOrdersAuthorization';
import PaymentListTable from './payment-more/PaymentListTable';
import { PaymentTableTemplate } from './payment-more/paymentTableTemplate';

import { makeStyles } from '@mui/styles';

interface OrdersAuthorizationMoreMenuProps {
  order: OrderAuthorization;
}

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      margin: 0,
      minHeight: 0,
      overflow: 'visible',
    },
  },
});

const OrdersAuthorizationMoreMenu: React.FC<OrdersAuthorizationMoreMenuProps> = ({ order }) => {
  const classes = useStyles();
  const [orderedIndex] = useTableOrder();
  const { more } = useOrdersAuthorization();
  const [tab, setTab] = useState<MoreTabs>('items');

  return (
    <TableContainer tableTemplate={tab === 'items' ? MoreTableTemplate : PaymentTableTemplate}>
      {more && more.id === order.id && (
        <div className={classes.div}>
          <MoreItemTabs tab={tab} handleChange={tab => setTab(tab)} />

          {tab === 'items' ? (
            <MoreListTable orderedIndex={orderedIndex} items={more.itens} />
          ) : (
            <PaymentListTable orderedIndex={orderedIndex} payments={more.pagamento} />
          )}
        </div>
      )}
    </TableContainer>
  );
};

export default OrdersAuthorizationMoreMenu;
