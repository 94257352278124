import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { NewInvoice, NewInvoiceActions, NewInvoiceFavored } from '../reducer/types';
import { TotalizingAccount } from 'types/totalizingAccount';

export interface NewInvoiceContextValue {
  dispatch: Dispatch<NewInvoiceActions>;
  invoice: NewInvoice;
  favored: NewInvoiceFavored | null;
  setFavored: Dispatch<SetStateAction<NewInvoiceFavored | null>>;
  accounts: TotalizingAccount[];
}

const NewInvoiceContext = createContext<NewInvoiceContextValue>({} as NewInvoiceContextValue);
export const NewInvoiceProvider = NewInvoiceContext.Provider;
export const NewInvoiceConsumer = NewInvoiceContext.Consumer;

export function useNewInvoice(): NewInvoiceContextValue {
  return useContext(NewInvoiceContext);
}
