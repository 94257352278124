import React, { MouseEvent, Dispatch, SetStateAction } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RelatedProduct } from 'pages/related-product/types/relatedProduct';
import { useRelatedProducts } from 'pages/related-product/hooks/useRelatedProducts';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginRight: 20,
  },
});

type RelatedProductItemTableProps = {
  relatedProduct: RelatedProduct;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const RelatedProductItemTable: React.FC<RelatedProductItemTableProps> = ({ relatedProduct, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedRelatedProduct } = useRelatedProducts();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setSelectedRelatedProduct(relatedProduct);
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton onClick={handleMoreClick} key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography variant="body2">{relatedProduct[item.id]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default RelatedProductItemTable;
