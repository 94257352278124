import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'services/api';
import history from 'services/history';
import SiteBannerActions from './SiteBannerActions';
import SiteBannerForm from '../SiteBannerForm';
import { useSiteBannerValidation } from '../validation/siteBannerValidation';
import { SliderBanner } from 'types/sliderBanner';

const SiteBannerUpdate: React.FC = () => {
  const [banner, setBanner] = useState<SliderBanner>({} as SliderBanner);
  const [validation, setValidation, validate] = useSiteBannerValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/site-banners/${id}`)
      .then(response => {
        setBanner(response.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function handleChange(index: keyof SliderBanner, value: any) {
    setBanner(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(banner)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/site-banners/${id}`, banner)
      .then(() => {
        setSaving(false);
        history.push('/site/slider-banners');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete(`/site-banners/${id}`)
      .then(() => {
        setSaving(false);
        history.push('/site/slider-banners');
        handleOpen('Excluído');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Site"
        ActionsComponent={
          <SiteBannerActions
            saving={saving}
            loading={loading}
            handleSubmit={handleValidation}
            handleDelete={handleDelete}
          />
        }
        backAction={() => history.push('/site/slider-banners')}
      />
      <PageHeader title="Editar slider" backUrl="/site/slider-banners" />
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          <form onSubmit={handleValidation}>
            <SiteBannerForm handleChange={handleChange} banner={banner} validation={validation} />
          </form>
        </Grid>
      )}
    </>
  );
};

export default SiteBannerUpdate;
