import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { FinancialTicket } from 'types/financialReportList';
import { FinancialTicketFilterParams } from '../FinancialTicketReport';

export type SalesReportTotal = {
  total: string;
  feesTotal: string;
  value: string;
  trafficTicket: string;
};

export type UseFetchFinancialTicketReport = [
  (searchValue: string) => void,
  FinancialTicket[],
  boolean,
  SalesReportTotal,
];

export function useFetchFinancialTicketReport(filter: FinancialTicketFilterParams): UseFetchFinancialTicketReport {
  const [formattedTotal, setFormattedTotal] = useState<SalesReportTotal>({
    value: '',
    feesTotal: '',
    total: '',
    trafficTicket: '',
  });
  const [tickets, setTickets] = useState<FinancialTicket[]>([]);
  const { h2iApi } = useApp();
  const [loading, setLoading] = useState(false);

  const handleSearch = useCallback(
    (searchValue: string) => {
      if (!h2iApi) return;

      setLoading(true);

      const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
      const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

      h2iApi
        .get(`/api/relboletos`, {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.branchId || '',
            filtro: searchValue,
            tipo_pesquisa: filter.typeDate,
            situacao: filter.situation,
          },
        })
        .then(response => {
          const _response: FinancialTicket[] = response.data.relboleto;
          if (!response) {
            setTickets([]);
            return;
          }

          const _tickets = _response.map(item => {
            item.formattedPayDate = item.data_pag ? new Date(item.data_pag.split('/').reverse().join('/')) : null;

            const dueDate = new Date(item.vencimento.split('/').reverse().join('/'));
            const releaseDate = new Date(item.data_lanc.split('/').reverse().join('/'));

            item.formattedTotal = moneyFormat(item.valor_total);
            item.formattedValue = moneyFormat(item.valor);
            item.formattedTrafficTicket = moneyFormat(item.multa);
            item.formattedFees = moneyFormat(item.juros);
            item.formattedDueDate = dueDate;
            item.formattedReleaseDate = releaseDate;

            return item;
          });

          setTickets(_tickets);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi, filter],
  );

  useEffect(() => {
    const total = tickets.reduce((sum, item) => sum + item.valor_total, 0);
    const fees = tickets.reduce((sum, item) => sum + item.juros, 0);
    const value = tickets.reduce((sum, item) => sum + item.valor, 0);
    const trafficTicket = tickets.reduce((sum, item) => sum + item.multa, 0);
    setFormattedTotal({
      total: moneyFormat(total),
      value: moneyFormat(value),
      feesTotal: moneyFormat(fees),
      trafficTicket: moneyFormat(trafficTicket),
    });
  }, [tickets]);

  return [handleSearch, tickets, loading, formattedTotal];
}
