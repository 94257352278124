import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import React, { useEffect, useState } from 'react';
import CompatibilitiesHeaderButtons from './RelatedProductsHeaderButtons';
import { RelatedProductProvider } from './hooks/useRelatedProducts';
import useTableOrder from 'hooks/tableOrder';
import TableContainer from 'components/table/TableContainer';
import PaginationProvider from 'hooks/pagination';
import ApiPagination from 'components/pagination/ApiPagination';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import CompatibilityListTable from './list/table/RelatedProductListTable';
import CompatibilityListModule from './list/module/RelatedProductListModule';
import CompatibilitiesFIlterBox from './RelatedProductsFIlterBox';
import { useFetchRelatedProducts } from './hooks/useFetchRelatedProducts';
import { relatedProductsTableTemplate } from './constants/relatedProductsTableTemplate';
import { RelatedProduct } from './types/relatedProduct';
import { api } from 'services/api';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

export type RelatedProductsQueryParams = {
  rows: number;
  page: number;
  term: string | null;
};

const initialQueryParams = {
  rows: 48,
  page: 0,
  term: null,
  type: 'related',
};

let timer: NodeJS.Timeout;

const RelatedProducts: React.FC = () => {
  const [selectedRelatedProduct, setSelectedRelatedProduct] = useState<RelatedProduct | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [queryParams, setQueryParams] = useState<RelatedProductsQueryParams>(initialQueryParams);
  const { relatedProducts, loading, total, fetch, setRelatedProducts } = useFetchRelatedProducts();
  const [filtered, setFiltered] = useState<RelatedProduct[]>([]);
  const [, setSaving] = useState(false);

  useEffect(() => {
    fetch(initialQueryParams);
  }, [fetch]);

  useEffect(() => {
    setFiltered(relatedProducts);
  }, [relatedProducts]);

  function handleQueryParamsChange(index: keyof RelatedProductsQueryParams, value: string | number | null) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));

    const query = {
      ...queryParams,
      [index]: value,
    };

    clearTimeout(timer);

    timer = setTimeout(() => fetch(query), 500);
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleDelete() {
    if (!selectedRelatedProduct) {
      return;
    }

    setSaving(true);

    api
      .delete(`/compatibilities/${selectedRelatedProduct.id}`)
      .then(() => {
        setRelatedProducts(state => state.filter(item => item.id !== selectedRelatedProduct.id));
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  return (
    <Container>
      <Appbar title="Produtos similares" />

      <PageHeaderActions
        title="Produtos similares"
        description="Gestão da configuração dos produtos similares"
        ActionComponent={<CompatibilitiesHeaderButtons />}
      />

      <CompatibilitiesFIlterBox
        queryParams={queryParams}
        handleQueryParamsChange={handleQueryParamsChange}
        setDisplayMode={setDisplayMode}
        displayMode={displayMode}
      />

      <RelatedProductProvider value={{ selectedRelatedProduct, setSelectedRelatedProduct, handleDelete }}>
        <TableContainer tableTemplate={relatedProductsTableTemplate}>
          <PaginationProvider>
            {loading ? (
              displayMode === 'list' ? (
                <TableLoading />
              ) : (
                <ModuleLoading />
              )
            ) : filtered.length === 0 ? (
              <NoData message="Nenhum configuração de produto para listar" />
            ) : (
              <div>
                {displayMode === 'list' ? (
                  <CompatibilityListTable
                    relatedProducts={filtered}
                    handleSort={handleSort}
                    orderedIndex={orderedIndex}
                  />
                ) : (
                  displayMode === 'module' && <CompatibilityListModule relatedProducts={filtered} />
                )}
              </div>
            )}

            <ApiPagination
              onChangePage={value => handleQueryParamsChange('page', value)}
              onChangeRowsPerPage={value => handleQueryParamsChange('rows', value)}
              count={total}
            />
          </PaginationProvider>
        </TableContainer>
      </RelatedProductProvider>
    </Container>
  );
};

export default RelatedProducts;
