import { Typography, Theme } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import history from 'services/history';
import { EmailTemplate } from 'types/emailTemplate';
import EmailTemplateActions from '../EmailTemplateActions';
import EmailTemplateForm from '../EmailTemplateForm';
import { useStatusValidation } from '../validation/emailTemplateValidation';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '600px 1fr',
    gap: '30px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  rendered: {
    padding: '20px 20px 0',
    marginBottom: 20,
    border: '2px dashed #eee',
    backgroundColor: '#fff',
    position: 'relative',
  },
  form: {
    maxWidth: 800,
    minWidth: 300,
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: 'rgba(250, 250, 250, 0.5)',
  },
}));

let timer: NodeJS.Timeout;

const EmailTemplateNew: React.FC = () => {
  const classes = useStyles();

  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>({ image1: '', image2: '' } as EmailTemplate);
  const [validation, setValidation, validate] = useStatusValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const [rendered, setRendered] = useState('');
  const [rendering, setRendering] = useState(false);

  useEffect(() => {
    clearTimeout(timer);
    const cancelToken = getCancelTokenSource();
    let request = false;
    if (request) cancelToken.cancel();

    timer = setTimeout(() => {
      setRendering(true);
      request = true;

      api
        .post(`/email-templates/render`, emailTemplate, { cancelToken: cancelToken.token })
        .then(response => {
          setRendered(response.data);
          setRendering(false);
        })
        .finally(() => {
          request = false;
        });
    }, 500);

    return () => {
      if (request) cancelToken.cancel();
    };
  }, [emailTemplate.body, emailTemplate.image1, emailTemplate.image2]); // eslint-disable-line

  const markedup = useMemo(
    () => ({
      __html: rendered,
    }),
    [rendered],
  );

  function handleChange(index: keyof EmailTemplate, value: any) {
    setEmailTemplate(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(emailTemplate)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post(`/email-templates`, emailTemplate)
      .then(() => {
        setSaving(false);
        history.push('/email-templates');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Configurações"
        ActionsComponent={<EmailTemplateActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/email-templates')}
      />
      <PageHeader title="Editar template de e-mail" />
      <div className={classes.container}>
        <form onSubmit={handleValidation}>
          <EmailTemplateForm handleChange={handleChange} emailTemplate={emailTemplate} validation={validation} />
        </form>
        <div className={classes.rendered}>
          {rendering && <div className={classes.loading} />}
          <Typography align="center" gutterBottom variant="body2">
            Pré-visualização
          </Typography>
          <div dangerouslySetInnerHTML={markedup} />
        </div>
      </div>
    </>
  );
};

export default EmailTemplateNew;
