import React from 'react';
import { Checkbox, FormControlLabel, Typography, styled } from '@mui/material';
import { usePurchaseOrder } from '../hook/usePurchaseOrder';

const Container = styled('div')({
  gap: 15,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  '& > div, & > label': {
    padding: '5px 10px',
  },
  '& > div': {
    alignItems: 'center',
    display: 'grid',
    border: '1px solid #ddd',
    '& .MuiFormControl-root': {
      marginTop: '0!important',
      marginBottom: '0!important',
    },
  },
});

const Title = styled('div')({
  marginTop: -15,
  '& > p': {
    maxWidth: 100,
    padding: '0px 10px',
    backgroundColor: '#fff',
  },
});

const Row = styled('div')({
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  '& > div': {
    gridColumn: '1/5',
  },
  '& .MuiFormControl-root': {
    marginTop: '0!important',
    marginBottom: '0!important',
  },
});

const PurchaseOrderCalculationForm: React.FC = () => {
  const { order, handleChange, disabled } = usePurchaseOrder();

  return (
    <Container style={{ opacity: !disabled ? 1 : 0.5 }}>
      <Row>
        <Title>
          <Typography align="center" variant="body2" color="textSecondary">
            Frete
          </Typography>
        </Title>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={order.soma_frete_bcicms === 'S'}
              onChange={e => handleChange('soma_frete_bcicms', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Soma Frete a base de ICMS"
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={order.soma_frete_total === 'S'}
              onChange={e => handleChange('soma_frete_total', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Soma Frete Total NF"
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={order.soma_frete_bst === 'S'}
              onChange={e => handleChange('soma_frete_bst', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Soma Frete Base ST"
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={order.reduz_base_frete === 'S'}
              onChange={e => handleChange('reduz_base_frete', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Reduz ICMS Frete"
        />
      </Row>

      <Row>
        <Title>
          <Typography align="center" variant="body2" color="textSecondary">
            Despesas
          </Typography>
        </Title>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={order.soma_desp_bcicms === 'S'}
              onChange={e => handleChange('soma_desp_bcicms', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Soma Outras despesas a base de ICMS"
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={order.soma_desp_bcst === 'S'}
              onChange={e => handleChange('soma_desp_bcst', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Soma Outras despesas a base ST"
        />
      </Row>

      <Row>
        <Title>
          <Typography align="center" variant="body2" color="textSecondary">
            IPI
          </Typography>
        </Title>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={order.soma_ipi_bcicms === 'S'}
              onChange={e => handleChange('soma_ipi_bcicms', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Soma IPI a Base de ICMS"
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={order.soma_ipi_bcst === 'S'}
              onChange={e => handleChange('soma_ipi_bcst', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Soma IPI a Base ST"
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={order.soma_ipi_frete === 'S'}
              onChange={e => handleChange('soma_ipi_frete', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Soma IPI ao Frete"
        />
      </Row>

      <Row>
        <Title>
          <Typography align="center" variant="body2" color="textSecondary">
            Desconto
          </Typography>
        </Title>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={order.soma_desconto_bcicms === 'S'}
              onChange={e => handleChange('soma_desconto_bcicms', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Soma Desconto a Base de ICMS"
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={order.soma_desconto_bcst === 'S'}
              onChange={e => handleChange('soma_desconto_bcst', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Soma Desconto a Base ST"
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={order.soma_desconto_ipi === 'S'}
              onChange={e => handleChange('soma_desconto_ipi', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Considera Desconto P/ IPI"
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={order.rateio_desconto === 'S'}
              onChange={e => handleChange('rateio_desconto', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Rateio de frete para os itens"
        />
      </Row>

      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={order.calcula_imposto === 'S'}
            onChange={e => handleChange('calcula_imposto', e.target.checked ? 'S' : 'N')}
            color="primary"
          />
        }
        label="Calcula Impostos"
      />
    </Container>
  );
};

export default PurchaseOrderCalculationForm;
