import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import PrintData from 'components/print/PrintData';
import { styled } from '@mui/material';
import TransferAccountReportHeader from './TransferAccountReportHeader';
import { useTransferAccounts } from '../hooks/useTransferAccount';

const ColumnPL = styled('p')({
  fontSize: '10pt',
  textAlign: 'start',
});

const Row = styled('div')({
  display: 'grid',
  gridTemplateColumns: '50px 1fr 1fr 130px 1fr',
  marginBottom: 1,
  paddingBottom: 1,
});

interface TransferAccountReportProps {
  onExited(): void;
}

const TransferAccountReport: React.FC<TransferAccountReportProps> = ({ onExited }) => {
  const { accounts: data } = useTransferAccounts();

  return (
    <Dialog fullScreen title="Transferência entre contas" onExited={onExited} ComponentActions={<PrintData />}>
      <div className="page">
        <TransferAccountReportHeader />
        <Row className="table-header">
          <ColumnPL>ID</ColumnPL>
          <ColumnPL>Filial</ColumnPL>
          <ColumnPL>Nome</ColumnPL>
          <ColumnPL>Saldo</ColumnPL>
          <ColumnPL>Data início</ColumnPL>
        </Row>

        {data.map(item => (
          <Row key={item.id}>
            <ColumnPL>{item.id}</ColumnPL>
          </Row>
        ))}
      </div>
    </Dialog>
  );
};

export default TransferAccountReport;
