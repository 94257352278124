import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography } from '@mui/material';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import React from 'react';
import { InvoiceSummary } from 'types/summary/invoiceSummary';
import SummaryItemInvoice from './SummaryItemInvoice';
import TableHeader from 'components/new-table/TableHeader';
import TableRow from 'components/new-table/TableRow';
import { useFinancialSummary } from '../../hooks/useFinancialSummary';
import SummaryTitles from '../titles/SummaryTitles';

type SummaryListInvoicesProps = {
  invoices: InvoiceSummary[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const SummaryListInvoices: React.FC<SummaryListInvoicesProps> = ({ handleSort, invoices, orderedIndex }) => {
  const { selectedInvoice } = useFinancialSummary();
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();

  return (
    <TableContent>
      <TableHeader smtemplatecolumns="50px 1fr 1fr">
        {tableTemplate.map(item => (
          <div
            className={item.dataType === 'number' ? 'numericData' : undefined}
            key={item.id}
            onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
          >
            <Typography fontSize={16} variant="subtitle2" color="#000">
              {item.description}
            </Typography>
            {orderedIndex.index === item.originalId && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </div>
        ))}
      </TableHeader>
      <TableBody>
        {invoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(invoice => (
          <span key={invoice.id_filial}>
            <TableRow smtemplatecolumns="50px 1fr 1fr">
              <SummaryItemInvoice data={invoice} />
            </TableRow>
            {selectedInvoice?.id_filial === invoice.id_filial && <SummaryTitles selectedInvoice={invoice} />}
          </span>
        ))}
      </TableBody>
    </TableContent>
  );
};

export default SummaryListInvoices;
