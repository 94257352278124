import Dialog from 'components/dialogs/Dialog';
import { useApp } from 'hooks/app';
import React, { FormEvent, useState } from 'react';
import { useRevenueValidation } from '../../validation/revenueValidation';
import { Revenue } from 'types/revenue';
import RevenueForm from '../RevenueForm';
import InsideSaving from 'components/loading/InsideSaving';
import { Button, styled } from '@mui/material';
import { useMessaging } from 'hooks/messaging';
import { useRevenues } from '../../hooks/useRevenues';

interface NewRevenueProps {
  onExited(): void;
  onSubmit(): void;
}

const Form = styled('form')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const NewRevenue: React.FC<NewRevenueProps> = ({ onExited, onSubmit }) => {
  const { financialApi } = useApp();
  const { filter } = useRevenues();
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);
  const [revenue, setRevenue] = useState<Revenue>({
    tipo_valores: 'M',
    id: 0,
    id_filial: filter.branchId,
    nome: '',
    conta_contabil: '',
  });
  const [validation, setValidation, validate] = useRevenueValidation();

  function handleValidation(e?: FormEvent<HTMLFormElement | HTMLButtonElement>) {
    e?.preventDefault();

    setValidation({});

    validate(revenue)
      .then(handleSubmit)
      .catch(error => {
        console.log(error);
        handleOpen('Não foi possível salvar a receita');
      });
  }

  function handleSubmit() {
    setSaving(true);

    financialApi
      .post('/savecadastroreceita', revenue)
      .then(onSubmit)
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleChange(index: keyof Revenue, value: any) {
    setRevenue(revenue => ({
      ...revenue,
      [index]: value,
    }));
  }

  return (
    <Dialog
      maxWidth="sm"
      height="70vh"
      onExited={onExited}
      title="Nova classificação de receita"
      ComponentActions={
        <Button onClick={handleValidation} variant="contained">
          Salvar
        </Button>
      }
    >
      {saving && <InsideSaving />}
      <Form onSubmit={handleValidation}>
        <RevenueForm revenue={revenue} validation={validation} handleChange={handleChange} />
      </Form>
    </Dialog>
  );
};

export default NewRevenue;
