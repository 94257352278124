import InsideLoading from 'components/loading/InsideLoading';
import { moneyFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useMessaging } from 'hooks/messaging';
import { useDisplayMode } from 'hooks/useDisplayMode';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { Product, ProductList } from 'types/product';
import WasteProductListTable from './list/table/WasteProductListTable';
import WasteProductListModule from './list/module/WasteProductListModule';
import useTableOrder from 'hooks/tableOrder';
import PaginationProvider from 'hooks/pagination';
import { IconButton, Tooltip } from '@mui/material';
import { Done } from '@mui/icons-material';
import Dialog from 'components/dialogs/Dialog';
import WasteProductsModalFilter from './WasteProductsModalFilter';
import NoData from 'components/no-data/NoData';

interface WasteProductsModalProps {
  onClose(): void;
  reee?: boolean;
  handleSubmit(product: Product): void;
}

let timer: NodeJS.Timeout;

const WasteProductsModal: React.FC<WasteProductsModalProps> = ({ onClose, handleSubmit, reee = true }) => {
  const { h2iApi } = useApp();
  const { handleOpen } = useMessaging();
  const [searchText, setSearchText] = useState('');
  const [products, setProducts] = useState<ProductList[]>([]);
  const [filtered, setFiltered] = useState<ProductList[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ProductList | null>(null);
  const [displayMode] = useDisplayMode();
  const [loading, setLoading] = useState(false);
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(products);
  }, [products]);

  const handleSearch = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (!h2iApi) {
        return;
      }

      setLoading(true);

      h2iApi
        .get('/api/produtoTodos', { params: { reee: reee ? 'S' : 'N', produto: searchText.toUpperCase() } })
        .then(response => {
          if (response.data[0].Mensagem) {
            handleOpen(response.data[0].Mensagem);
            return;
          }
          const p = response.data.map((item: ProductList) => {
            item.formattedEst = parseInt(item.est);
            item.formattedReeePrice = reee ? moneyFormat(item.preco_maximo_reee) : moneyFormat(item.custo);
            item.formattedPrice = reee ? item.preco_maximo_reee : item.custo;
            return item;
          });

          setProducts(p);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi, handleOpen, reee, searchText],
  );

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleChange(value: string) {
    setSearchText(value);

    clearTimeout(timer);

    timer = setTimeout(() => {
      handleSearch();
    }, 500);
  }

  return (
    <Dialog
      fullScreen
      title="Selecione o produto"
      ComponentActions={
        <>
          {selectedProduct && (
            <Tooltip title="Salvar">
              <IconButton color="inherit" onClick={() => handleSubmit(selectedProduct)}>
                <Done />
              </IconButton>
            </Tooltip>
          )}
        </>
      }
      onExited={onClose}
    >
      <WasteProductsModalFilter
        disabled={loading}
        handleSearch={handleSearch}
        searchText={searchText}
        onChange={handleChange}
      />
      <PaginationProvider>
        {loading ? (
          <InsideLoading />
        ) : filtered.length > 0 ? (
          displayMode === 'list' ? (
            <WasteProductListTable
              handleSubmit={handleSubmit}
              selectedProduct={selectedProduct}
              handleSelectProduct={product => setSelectedProduct(product)}
              handleSort={handleSort}
              orderedIndex={orderedIndex}
              products={filtered}
            />
          ) : (
            <WasteProductListModule
              selectedProduct={selectedProduct}
              handleSelectProduct={product => setSelectedProduct(product)}
              products={filtered}
            />
          )
        ) : (
          <NoData message="Nenhum produto encontrado" />
        )}
      </PaginationProvider>
    </Dialog>
  );
};

export default WasteProductsModal;
