import React, { useMemo } from 'react';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { Pie } from 'react-chartjs-2';
import { useCommercialBi } from '../../hooks/useCommercialBi';
import { styled } from '@mui/material';

const Container = styled('div')({
  height: 500,
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#fff',
  border: '1px solid #eee',
  padding: 20,
});

const BranchesChart: React.FC = () => {
  const { data } = useCommercialBi();

  const branchesValues = useMemo(() => {
    return data.branches.sort((a, b) => b.valor - a.valor).map(item => item.valor);
  }, [data.branches]);

  const labels = useMemo(() => {
    return data.branches.sort((a, b) => b.valor - a.valor).map(branch => branch.nome);
  }, [data.branches]);

  return (
    <Container>
      <Pie
        height={300}
        data={{
          labels,
          datasets: [
            {
              label: 'Total',
              data: branchesValues,
              borderWidth: 0,
            },
          ],
        }}
        options={{
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                label: tooltip => moneyFormat(branchesValues[tooltip.dataIndex as number]),
              },
              titleFont: {
                size: 10,
              },
            },
            legend: {
              display: true,
              position: 'bottom',
            },
            colors: {
              enabled: true,
            },
            title: {
              text: 'Filiais',
              display: true,
              position: 'top',
              align: 'center',
              color: '#000',
            },
            datalabels: {
              display: true,
              color: '#fff',
              font: {
                size: 10,
                weight: 400,
              },
              textAlign: 'right',
              formatter: value => percentFormat(value),
            },
          },
          responsive: true,
        }}
      />
    </Container>
  );
};

export default BranchesChart;
