import React, { useEffect, useState } from 'react';
import { useApp } from 'hooks/app';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import useTableOrder from 'hooks/tableOrder';

import NoData from 'components/no-data/NoData';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { useAbcProduct } from '../../hooks/useAbcProduct';
import InsideLoading from 'components/loading/InsideLoading';
import { AbcProduct, AbcProductManufacturer } from 'types/abcProduct';
import AbcProductListTable from '../../list/table/AbcProductListTable';
import { useAbcProducts } from '../../hooks/useAbcProducts';
import Pagination from 'components/pagination/Pagination';
import PaginationProvider from 'hooks/pagination';

import { makeStyles } from '@mui/styles';

interface ManufacturerMoreMenuProps {
  manufacturer: AbcProductManufacturer;
}

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      margin: 0,
      minHeight: 0,
      overflow: 'visible',
    },
  },
});

const ManufacturerMoreMenu: React.FC<ManufacturerMoreMenuProps> = ({ manufacturer }) => {
  const classes = useStyles();
  const { h2iApi } = useApp();
  const { formattedTotal } = useAbcProducts();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const { filter, searchValue } = useAbcProduct();
  const [products, setProducts] = useState<AbcProduct[]>([]);
  const [filtered, setFiltered] = useState<AbcProduct[]>([]);

  useEffect(() => {
    setFiltered(products);
  }, [products]);

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    h2iApi
      .get('/api/relcurvaabcitens', {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.selectedBranchId === 0 ? '' : filter.selectedBranchId,
          id_subgrupo: filter.subGroupIds.join(',') || '',
          filtro: searchValue,
          tipo_venda: filter.typeSale,
          id_categoria: filter.categoryIds.join(','),
          id_subcategoria: filter.subCategoryIds.join(','),
          pedido_por: filter.madeBy,
          id_segmento: filter.segmentIds.join(','),
          id_unidade: filter.unitIds.join(','),
          id_fabricante: manufacturer.id,
        },
      })
      .then(response => {
        const _products = response.data.map(item => {
          item.formattedTotal = moneyFormat(item.total);
          item.formattedCoust = moneyFormat(item.custo);
          item.formattedMc = moneyFormat(item.mc);
          item.formattedSpiff = moneyFormat(item.spiff);
          item.formattedUnitary = moneyFormat(item.unitario);
          item.formattedBilling = percentFormat((item.total / formattedTotal.total) * 100);
          item.billing = (item.total / formattedTotal.total) * 100;

          return item;
        });

        setProducts(_products);
      })
      .finally(() => setLoading(false));
  }, [h2iApi, filter, manufacturer, searchValue, formattedTotal.total]);

  function handleSort(index: string) {
    const filteredB = sort(index, filtered);
    setFiltered(filteredB);
  }

  return (
    <PaginationProvider>
      <div className={classes.div}>
        {loading ? (
          <InsideLoading />
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum item encontrado." />
        ) : (
          <AbcProductListTable
            isSubgroup={false}
            products={filtered}
            handleSort={handleSort}
            orderedIndex={orderedIndex}
          />
        )}
        <Pagination count={filtered.length} />
      </div>
    </PaginationProvider>
  );
};

export default ManufacturerMoreMenu;
