import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import PrintData from 'components/print/PrintData';
import { styled } from '@mui/material';
import { useRevenues } from '../hooks/useRevenues';
import RevenuesReportHeader from './RevenuesReportHeader';

const ColumnPL = styled('p')({
  fontSize: '10pt',
  textAlign: 'start',
});

const Row = styled('div')({
  display: 'grid',
  gridTemplateColumns: '50px 80px 100px 130px 1fr ',
  marginBottom: 1,
  paddingBottom: 1,
});

interface RevenuesReportProps {
  onExited(): void;
}

const RevenuesReport: React.FC<RevenuesReportProps> = ({ onExited }) => {
  const { revenues: data } = useRevenues();

  return (
    <Dialog fullScreen title="Boletos" onExited={onExited} ComponentActions={<PrintData />}>
      <div className="page">
        <RevenuesReportHeader />
        <Row className="table-header">
          <ColumnPL>ID</ColumnPL>
          <ColumnPL>ID Filial</ColumnPL>
          <ColumnPL>Cod Contábil</ColumnPL>
          <ColumnPL>Tipo Valores</ColumnPL>
          <ColumnPL>Nome</ColumnPL>
        </Row>

        {data.map(item => (
          <Row key={item.id}>
            <ColumnPL>{item.id}</ColumnPL>
            <ColumnPL>{item.id_filial}</ColumnPL>
            <ColumnPL>{item.conta_contabil}</ColumnPL>
            <ColumnPL>{item.formattedType}</ColumnPL>
            <ColumnPL>{item.nome}</ColumnPL>
          </Row>
        ))}
      </div>
    </Dialog>
  );
};

export default RevenuesReport;
