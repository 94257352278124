import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useMemo } from 'react';
import { useSelector } from 'store/selector';
import { useExtracts } from '../hooks/useExtracts';
import { useBranches } from 'providers/BranchesProviders';
import { styled } from '@mui/material';

const Title = styled('div')({
  paddingTop: '10px',
  borderTop: '1px solid',
  textAlign: 'center',
  margin: '10px 0',
});

const Header = styled('div')({
  display: 'grid',
});

const Flex = styled('div')({
  gap: 5,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const BankStatementReportHeader: React.FC = () => {
  const now = format(new Date(), 'PPpp', { locale: ptBR });
  const user = useSelector(state => state.user);
  const { filter, totalizingAccounts } = useExtracts();
  const { financialBranches } = useBranches();

  const selectedBranch = useMemo(
    () => financialBranches.find(branch => branch.id === filter.branchId),
    [filter, financialBranches],
  );

  const accounts = totalizingAccounts.filter(account => filter.accountId.includes(account.id));

  const getDescriptionDate = (): string => {
    if (filter.ignorePeriod === 'S') return 'todo o período';
    return `${format(filter.initial_date, 'P', { locale: ptBR })} até ${format(filter.final_date, 'P', {
      locale: ptBR,
    })}`;
  };

  return (
    <Header>
      <Flex>
        <div>
          {!selectedBranch ? <h6>Todas as filiais</h6> : <h6>Filial {selectedBranch.nome}</h6>}
          <Flex>
            {accounts.length ? accounts.map(item => <p key={item.id}>{item.nome}</p>) : <h6>Todas as contas</h6>}
          </Flex>
        </div>
        <div>
          <p>{user?.name}</p>
          <p>{now}</p>
        </div>
      </Flex>

      <Title>
        <h6>Resumo do extrato de {getDescriptionDate()}</h6>
      </Title>
    </Header>
  );
};

export default BankStatementReportHeader;
