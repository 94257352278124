import { Typography } from '@mui/material';
import React from 'react';
import { CreditLimitRequestReference } from 'types/creditLimitRequest';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

interface ReferenceProps {
  reference: CreditLimitRequestReference;
}

const Reference: React.FC<ReferenceProps> = ({ reference }) => {
  const classes = styles();

  return (
    <li className={classes.container}>
      <Typography color="secondary" variant="caption">
        {reference.type === 'bank' ? `Referência bancária` : 'Referência de fornecedor'}
      </Typography>
      <Typography>{reference.company_name}</Typography>
      <Typography variant="body2" color="textSecondary">
        {reference.contact_person_name}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {reference.contact_person_phone}
      </Typography>
    </li>
  );
};

export default Reference;
