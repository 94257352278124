import { TableTemplate } from 'types/tableTemplate';

export const flagTableTemplate: TableTemplate[] = [
  {
    id: 'bandeira',
    description: 'Bandeira',
    originalId: 'bandeira',
    width: 250,
  },
  {
    id: 'formattedValueGross',
    description: 'VL Bruto',
    originalId: 'valor_bruto',
    width: 110,
    dataType: 'number',
  },
  {
    id: 'formattedValueLiquid',
    description: 'VL Líquido',
    originalId: 'valor_liquido',
    width: 110,
    dataType: 'number',
  },
  {
    id: 'formattedValueDiscount',
    description: 'VL Desc.',
    originalId: 'valor_desconto',
    width: 110,
    dataType: 'number',
  },
];
