import React from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { Sales } from 'types/reports/sales';
import { SalesTableTemplate } from '../../SalesTableTemplate';

type SalesItemTableProps = {
  sale: Sales;
};

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const SalesItemTable: React.FC<SalesItemTableProps> = ({ sale }) => {
  return (
    <>
      {SalesTableTemplate.filter(item => !item.notShow).map(item => (
        <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
          <Tooltip title={sale[item.id]}>
            <Description style={{ fontSize: 11 }} variant="body2">
              {sale[item.id]}
            </Description>
          </Tooltip>
        </div>
      ))}
    </>
  );
};

export default SalesItemTable;
