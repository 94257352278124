import React from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import { MenuItem, TextField, styled } from '@mui/material';
import { AggregatedProduct } from './ShoppingSuggestion';

const Filter = styled('div')({
  display: 'flex',
  maxWidth: 400,
  minWidth: 300,
});

interface ShoppingSuggestionFilterProps {
  subgroupId: string;
  onChange(value: string): void;
  products: AggregatedProduct[];
}

const ShoppingSuggestionFilter: React.FC<ShoppingSuggestionFilterProps> = ({ subgroupId, onChange, products }) => {
  return (
    <FilterBox>
      <Filter>
        <TextField
          select
          fullWidth
          placeholder="Subgrupo"
          label="Selecione um subgrupo"
          value={subgroupId}
          onChange={e => onChange(e.target.value)}
        >
          <MenuItem value="">Nenhum filtro</MenuItem>
          {products.map(item => (
            <MenuItem key={item.id_subgrupo} value={item.id_subgrupo}>
              {item.subgrupo}
            </MenuItem>
          ))}
        </TextField>
      </Filter>
    </FilterBox>
  );
};

export default ShoppingSuggestionFilter;
