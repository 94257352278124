import Dialog from 'components/dialogs/Dialog';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React from 'react';
import { useSelector } from 'store/selector';
import { Branch } from 'types/branch';
import { StockBalanceData } from 'types/reports/stockBalance';
import StockBalanceReportActions from '../StockBalanceReportActions';
import { makeStyles } from '@mui/styles';

type StockBalanceReportDataProps = {
  data: StockBalanceData[];
  branches: Branch[];
  onExited(): void;
};

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '80px 0.9fr 80px 100px 100px',
    marginBottom: 5,
    paddingBottom: 3,
    borderBottom: '1px solid #ccc',
  },
  headerInfo: {
    marginTop: 10,
  },
});

const StockBalanceReportData: React.FC<StockBalanceReportDataProps> = ({ data, branches, onExited }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);

  return (
    <Dialog fullScreen title="Saldo de estoque" onExited={onExited} ComponentActions={<StockBalanceReportActions />}>
      <div className="page">
        <div className="header">
          <div>
            <h6>Relatório de saldo de estoque</h6>
            <h6>Filial {branches.map(branch => branch.nome).join(',')}</h6>
          </div>
          <div>
            <p>{user?.name}</p>
            <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
          </div>
        </div>
        <div className={`${classes.row} table-header`}>
          <p>Código</p>
          <p>Descrição</p>
          <p>Qtd</p>
          <p>Custo</p>
          <p>Total</p>
        </div>
        {data.map(item => (
          <div key={item.CodProd} className={classes.row}>
            <p>{item.CodProd}</p>
            <p>{item.Produto}</p>
            <p>{item.formattedEstoque}</p>
            <p>{item.formattedCusto}</p>
            <p>{item.formattedCustoTotal}</p>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default StockBalanceReportData;
