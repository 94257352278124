import React from 'react';
import UnityItemModule from './UnityItemModule';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { UnityStockBalanceData } from 'types/reports/stockBalance';

type UnityListModuleProps = {
  units: UnityStockBalanceData[];
};

const UnityListModule: React.FC<UnityListModuleProps> = ({ units }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {units.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(unity => (
            <UnityItemModule key={unity.id_unidade} unity={unity} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default UnityListModule;
