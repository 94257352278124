import { Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { useCreditLimitRequest } from '../../hooks/useCreditLimitRequest';
import React from 'react';
import CreditLimitRequestDocuments from './CreditLimitRequestDocuments';
import References from './References';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    marginBottom: 65,
  },
  formControlLabel: {
    marginTop: 20,
  },
  companyDocumentRow: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
    gap: 15,
  },
  limitValues: {
    display: 'flex',
    gap: '15px',
  },
});

const LimitRequest: React.FC = () => {
  const { creditLimitRequest, handleChange } = useCreditLimitRequest();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.companyDocumentRow}>
        <div>
          <Typography color="textSecondary" variant="caption">
            CNPJ
          </Typography>
          <Typography>{creditLimitRequest?.document}</Typography>
        </div>
        <div>
          <Typography color="textSecondary" variant="caption">
            IE
          </Typography>
          <Typography>{creditLimitRequest?.reseller?.ie}</Typography>
        </div>
      </div>
      <div>
        <Typography color="textSecondary" variant="caption">
          Cliente
        </Typography>
        <Typography>
          {creditLimitRequest?.company_id} - {creditLimitRequest?.company_name}
        </Typography>
      </div>
      <div>
        <Typography variant="caption" color="textSecondary">
          Localização
        </Typography>
        <Typography>
          {creditLimitRequest?.reseller?.address.street}, {creditLimitRequest?.reseller?.address.street_number}
        </Typography>
        <Typography>{creditLimitRequest?.reseller?.address.neighborhood}</Typography>
        <Typography>
          {creditLimitRequest?.reseller?.address.city}, {creditLimitRequest?.reseller?.address.postal_code}
        </Typography>
      </div>

      <References />
      <CreditLimitRequestDocuments />
      <div>
        <div className={classes.limitValues}>
          <TextField
            label="Limite solicitado (R$)"
            placeholder="Informe o valor do limite"
            margin="normal"
            defaultValue={creditLimitRequest?.limit_value_requested || ''}
            autoFocus
            disabled
            inputProps={{
              inputMode: 'decimal',
            }}
          />
          <TextField
            label="Sugestão de limite (R$)"
            placeholder="Informe o valor do limite"
            margin="normal"
            value={creditLimitRequest?.limit_value || ''}
            onChange={e => handleChange('limit_value', e.target.value)}
            autoFocus
            type="number"
            inputProps={{
              inputMode: 'decimal',
            }}
          />
        </div>
        <TextField
          label="Observação"
          margin="normal"
          value={creditLimitRequest?.observation || ''}
          onChange={e => handleChange('observation', e.target.value)}
          fullWidth
          rows={5}
          multiline
        />
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <Checkbox
              checked={creditLimitRequest?.must_contact}
              onChange={e => handleChange('must_contact', e.target.checked)}
              color="primary"
            />
          }
          label="Contatar"
        />
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <Checkbox
              checked={creditLimitRequest?.contacted}
              onChange={e => handleChange('contacted', e.target.checked)}
              color="primary"
            />
          }
          label="Cliente contatado"
        />
      </div>
    </div>
  );
};

export default LimitRequest;
