import React from 'react';
import { Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { CommercialBiCity } from 'pages/bi/commercial/types/commercial-bi';

type CommercialBiCityItemTableProps = {
  city: CommercialBiCity;
};

const CommercialBiCityItemTable: React.FC<CommercialBiCityItemTableProps> = ({ city }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id}>
            <Typography variant="body2">{city[item.id]}</Typography>
          </div>
        ))}
    </>
  );
};

export default CommercialBiCityItemTable;
