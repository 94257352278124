import { Profile } from 'types/profile';

export const USER_CHANGE = 'USER_CHANGE';
export const SET_USER = 'SET_USER';
export const SET_PROFILE = 'SET_PROFILE';

export interface User {
  name: string;
  username: string;
  email: string;
  document_number: string;
  profile_id?: number;
  user_profile_id?: number;
  profile?: Profile;
  mobile_phone?: string;
  fixed_phone?: string;
  role?: string;
  branches: string;
  password?: string;
  password_confirmation?: string;
  active: true;
  formattedActive: string;
}

type UserChangeAction = {
  type: typeof USER_CHANGE;
  value: string;
  index: string;
};

type SetUserAction = {
  type: typeof SET_USER;
  user: User;
};

type SetUsetProfile = {
  type: typeof SET_PROFILE;
  profile: Profile;
};

export type UserActionsType = UserChangeAction | SetUserAction | SetUsetProfile;
