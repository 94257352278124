import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import history from 'services/history';
import { Page } from 'types/page';

type PageItemModuleProps = {
  page: Page;
};

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '80%',
    alignItems: 'center',
  },
});

const PageItemModule: React.FC<PageItemModuleProps> = ({ page }) => {
  return (
    <CustomListItemButton onClick={() => history.push(`/site/page/${page.id}`)}>
      <Typography variant="caption" color="primary">
        page {page.id}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {page.title}
      </Typography>
      <Typography variant="body2">{page.description}</Typography>
      <Typography variant="body2">{page.keywords}</Typography>
    </CustomListItemButton>
  );
};

export default PageItemModule;
