import React, { MouseEvent } from 'react';
import { IconButton, Typography } from '@mui/material';
import { ResponsibleStockBalanceData } from 'types/reports/stockBalance';
import { responsibleStockTableTemplate } from '../responsibleStockTableTemplate';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useStockBalance } from '../../hooks/useStockBalance';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  quantityData: {
    display: 'flex',
    justifyContent: 'center',
  },
});

type ResponsibleItemTableProps = {
  responsible: ResponsibleStockBalanceData;
  isSubgroup: boolean;
};

const ResponsibleItemTable: React.FC<ResponsibleItemTableProps> = ({ responsible, isSubgroup }) => {
  const classes = styles();
  const { setSelectedResponsible, setMoreResponsible, moreResponsible } = useStockBalance();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedResponsible(responsible);
    setMoreResponsible(moreResponsible ? null : responsible);
  }

  return (
    <>
      {responsibleStockTableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'more' && isSubgroup ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={
                  moreResponsible?.id_responsavel === responsible.id_responsavel
                    ? { transform: 'rotate(180deg)' }
                    : undefined
                }
              />
            </IconButton>
          ) : (
            <div
              key={item.id}
              className={
                item.dataType === 'number'
                  ? classes.numericData
                  : item.dataType === 'quantity'
                  ? classes.quantityData
                  : undefined
              }
            >
              <Typography style={{ fontSize: 11 }} variant="body2">
                {responsible[item.id as keyof ResponsibleStockBalanceData]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default ResponsibleItemTable;
