import Appbar from 'components/appbar/Appbar';
import React, { useEffect, useState, useCallback, FormEvent } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, Typography, Theme } from '@mui/material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import { MdSearch } from 'react-icons/md';
import { useApp } from 'hooks/app';
import { ProductList } from 'types/product';
import ModuleProductList from './list/module/ProductList';
import TableProductList from './list/table/ProductList';
import useTableOrder from 'hooks/tableOrder';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/numberFormat';
import ImagePreview from 'components/image-preview/ImagePreview';
import { ProductContextProvider, ProductContextValue } from './productContext';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { useProductSearch } from 'providers/ProductSearchProvider';
import { makeStyles } from '@mui/styles';
import PageHeader from 'components/page-header/PageHeader';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  noProduct: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    background: '#fff',
    marginTop: 10,
  },
  filter: {
    display: 'grid',
    alignItems: 'baseline',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      flex: 1,
    },
    '& > .searchParams': {
      marginLeft: 20,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
        gridTemplateColumns: '1fr',
        flex: 1,
      },
    },
  },
}));

let timer: any;

export type ProductsSearchParams = {
  ean: string;
  stock: boolean;
};

const Products: React.FC = () => {
  const classes = useStyles();
  const { h2iApi, windowWidth, isMobile } = useApp();
  const [searchParams, setSearchParams] = useState<ProductsSearchParams>({
    ean: '',
    stock: false,
  });
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(isMobile || windowWidth < 930 ? 'module' : 'list');
  const [products, setProducts] = useState<ProductList[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<ProductList[]>([]);
  const [loading, setLoading] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [imagePreview, setImagePreview] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductList | null>(null);
  const { search, setSearch } = useProductSearch();

  const contextValue: ProductContextValue = {
    handleSelectProduct: value => setSelectedProduct(value),
    openImagePreview: () => setImagePreview(true),
  };

  const handleSearch = useCallback(
    (e?: FormEvent) => {
      e?.preventDefault();

      if (!h2iApi) return;

      setLoading(true);

      h2iApi
        .get(`/api/produtoTodos?produto=${search.toUpperCase()}${searchParams.stock ? '&ESTOQUE=1' : '&ESTOQUE=0'}`)
        .then(response => {
          const p = response.data.map((item: ProductList) => {
            item.formattedCusto = moneyFormat(item.custo);
            item.formattedMC = numberFormat(item.mc);
            item.formattedPercentualMC = percentFormat(item['mc%']);
            item.formattedMargem = percentFormat(item.margem);
            item.formattedP2PJ = numberFormat(item.p2pj);
            item.formattedP2BR = numberFormat(item.p2br);
            item.formattedPromoPJ = item.promopj ? 'Sim' : 'Não';
            item.formattedDestaque = item.destaque ? 'Sim' : 'Não';
            item.formattedPmc = numberFormat(item.pmc);
            item.formattedPmd = numberFormat(item.pmd);
            item.formattedPp = numberFormat(item.pp);
            return item;
          });

          setProducts(p);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [h2iApi, search, searchParams.stock],
  );

  useEffect(() => {
    clearTimeout(timer);

    if (search.length <= 2) return;

    timer = setTimeout(handleSearch, 500);
  }, [search, handleSearch]);

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  useEffect(() => setFilteredProducts(products), [products]);

  function handleSetSearchParams(index: string, value: any) {
    setSearchParams({
      ...searchParams,
      [index]: value,
    });
  }

  function handleSort(index: string) {
    const p = sort(index, filteredProducts);
    setFilteredProducts(p);
  }

  return (
    <>
      {imagePreview && selectedProduct && (
        <ImagePreview
          src={selectedProduct.foto_principal}
          description={selectedProduct.produto}
          onExited={() => setImagePreview(false)}
        />
      )}
      <Appbar title="Cadastros" />
      <PageHeader title="Produtos" description="Gerencie os produtos" />
      <FilterBox>
        <div className={classes.filter}>
          <form onSubmit={handleSearch}>
            <TextField
              label="Pesquisar"
              placeholder="Digite sua pesquisa"
              autoFocus
              value={search}
              onChange={e => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit">
                      <MdSearch color="#888" size={20} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
          <div className="searchParams">
            <FormControlLabel
              control={
                <Checkbox
                  disabled={loading}
                  checked={searchParams.stock}
                  onChange={e => handleSetSearchParams('stock', e.target.checked)}
                  color="primary"
                />
              }
              label="Estoque"
            />
          </div>
        </div>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </FilterBox>
      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filteredProducts.length === 0 ? (
        <div className={classes.noProduct}>
          <Typography color="textSecondary" variant="body2">
            Nenhum produto para mostrar
          </Typography>
        </div>
      ) : (
        <ProductContextProvider value={contextValue}>
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <TableProductList products={filteredProducts} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <ModuleProductList products={filteredProducts} />
              )}
              <Pagination count={filteredProducts.length} />
            </div>
          </PaginationProvider>
        </ProductContextProvider>
      )}
    </>
  );
};

export default Products;
