import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import ActivityItemModule from './ActivityItemModule';
import { ActivityMethod } from 'types/salesReport';

type ActivityListModuleProps = {
  activities: ActivityMethod[];
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

const ActivityListModule: React.FC<ActivityListModuleProps> = ({ activities }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {activities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(activity => (
        <ActivityItemModule key={activity.atividade} activity={activity} />
      ))}
    </CustomList>
  );
};

export default ActivityListModule;
