import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import EmailTemplateSendTest from './EmailTemplateSendTest';

type EmailTemplateListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl(el: HTMLButtonElement | null): void;
};

const EmailTemplateListMenu: React.FC<EmailTemplateListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const [dialogEmail, setDialogEmail] = useState(false);

  function handleOpenDialogEmail() {
    setAnchorEl(null);
    setDialogEmail(true);
  }

  return (
    <>
      {dialogEmail && <EmailTemplateSendTest onExited={() => setDialogEmail(false)} />}
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleOpenDialogEmail}>Enviar e-mail teste</MenuItem>
      </Menu>
    </>
  );
};

export default EmailTemplateListMenu;
