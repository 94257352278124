import React from 'react';
import { useFinancialResume } from '../hooks/useFinancialResume';
import { Typography, styled } from '@mui/material';

const Layout = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: 10,
  boxShadow: '2px 4px 10px 5px #ccc',
  backgroundColor: '#fff',
});

const Container = styled('div')({
  display: 'grid',
  rowGap: 5,
});

const Row = styled('div')({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  gap: 10,
  borderTop: '1px solid #888',
  padding: '5px 20px',
  '& > .align-right': {
    textAlign: 'end',
  },
  '& p': {
    fontSize: 11,
  },
});

const TypeContent = styled('div')({
  display: 'grid',
  '& > .subtitle': {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    padding: 10,
  },
  '& > .items': {
    '& div:last-child': {
      borderBottom: '1px solid #888',
    },
  },
  borderTop: '1px solid #888',
  borderLeft: '1px solid #888',
  borderRight: '1px solid #888',
});

const FinancialResumeMobileReport: React.FC = () => {
  const { report } = useFinancialResume();

  return (
    <Layout>
      <Container>
        {report.map(subtitle => (
          <TypeContent key={subtitle.type}>
            <div className="subtitle">
              <Typography variant="body2">
                <strong>Classificação</strong>
              </Typography>
              <Typography variant="body2" align="right">
                <strong>Valores</strong>
              </Typography>
            </div>

            <div className="items">
              {subtitle.items.map(item => (
                <Row key={item.classificacao}>
                  <p>{item.classificacao}</p>
                  <p className="align-right">{item.formattedValue}</p>
                </Row>
              ))}
            </div>
          </TypeContent>
        ))}
      </Container>
    </Layout>
  );
};

export default FinancialResumeMobileReport;
