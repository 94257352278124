import React, { useState, useEffect, DragEvent, useCallback } from 'react';
import Dialog from 'components/dialogs/Dialog';
import ImageSelectorList from './ImageSelectorList';
import { CircularProgress, Theme } from '@mui/material';

import { api } from 'services/api';
import ImagePreview from 'components/image-preview/ImagePreview';
import ImageSelectorActions from './ImageSelectorActions';
import { useMessaging } from 'hooks/messaging';
import { Image } from 'types/image';
import { LaravelPaginator } from 'types/laravelPaginator';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(250,250,250,0.5)',
    zIndex: 1110,
  },
  draggableZone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  inDraggableZone: {
    border: `3px dashed ${theme.palette.primary.main}`,
    opacity: 0.5,
  },
}));

let handleClose: () => void;

type ImageSelectorProps = {
  handleSetImageId?(image: Image): void;
  onExited(): void;
  title?: string;
  hideBackdrop?: boolean;
};

const ImageSelector: React.FC<ImageSelectorProps> = ({
  handleSetImageId: onSetImageId,
  onExited,
  title,
  hideBackdrop = false,
}) => {
  const [images, setImages] = useState<Image[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const messaging = useMessaging();
  const classes = useStyles();
  const [dragIn, setDragIn] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const fetchImages = useCallback(() => {
    api
      .get<LaravelPaginator<Image[]>>('/images', { params: { rows: 25, page } })
      .then(response => {
        setImages(state => [...state, ...response.data.data]);
        setLastPage(response.data.last_page);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  function handleSetSelectedImage(image: Image, _handleClose: () => void) {
    setSelectedImage(selectedImage?.id === image.id ? null : image);
    handleClose = _handleClose;
  }

  function handleConfirmImage() {
    if (selectedImage && onSetImageId) onSetImageId(selectedImage);
    if (handleClose) handleClose();
  }

  function handleImageDelete() {
    if (!selectedImage) return;

    setSaving(true);
    api
      .delete(`/images/${selectedImage.id}`)
      .then(() => {
        setImages(images.filter(image => image.id !== selectedImage.id));
        setSelectedImage(null);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleUploadFiles(files: FileList | null) {
    if (!files) return;

    const form = new FormData();
    Array.from(files).forEach((file, index) => {
      form.append(`files[${index}]`, file);
    });

    form.append('is_public', '1');

    setSaving(true);

    api
      .post('/images', form)
      .then(response => {
        setImages(oldImages => [...response.data, ...oldImages]);
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível carregar a imagem');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDropFile(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleUploadFiles(files);
    setDragIn(false);
  }

  function handleDragEnter(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  function handleDragLeave(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(false);
  }

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  async function handleCopyImageUrl() {
    if (!selectedImage) return;
    const text = selectedImage.imageUrl;
    await navigator.clipboard.writeText(text);
    messaging.handleOpen('Endereço da imagem copiado');
  }

  function handleScroll() {
    const nativeDialogElement = document.getElementById('native-dialog');

    if (!nativeDialogElement) {
      return;
    }

    const clientHeight = nativeDialogElement.clientHeight;
    const scrollTop = nativeDialogElement.scrollTop;
    const scrollHeight = nativeDialogElement.scrollHeight;

    if (scrollTop + clientHeight !== scrollHeight) {
      return;
    }

    setPage(state => {
      if (state + 1 === lastPage) {
        return state;
      }

      return state + 1;
    });
  }

  return (
    <Dialog
      title={title || 'Selecione a imagem'}
      onExited={onExited}
      hideBackdrop={hideBackdrop}
      onScroll={handleScroll}
      ComponentActions={
        <ImageSelectorActions
          selectedImage={selectedImage}
          handleImageDelete={handleImageDelete}
          openImagePreview={() => setImagePreview(true)}
          handleConfirmImage={handleConfirmImage}
          handleUploadFiles={handleUploadFiles}
          handleCopyImageUrl={handleCopyImageUrl}
          showConfirmAction={!!onSetImageId}
        />
      }
    >
      {saving && (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      )}
      {imagePreview && (
        <ImagePreview
          description={`Imagem ${selectedImage?.id}`}
          onExited={() => setImagePreview(false)}
          src={selectedImage ? selectedImage.imageUrl : ''}
        />
      )}
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div
          className={dragIn ? `${classes.draggableZone} ${classes.inDraggableZone}` : classes.draggableZone}
          onDrop={e => handleDropFile(e)}
          onDragLeave={e => handleDragLeave(e)}
          onDragEnter={e => handleDragEnter(e)}
          onDragOver={e => handleDragOver(e)}
          draggable
        >
          <ImageSelectorList
            images={images}
            handleSetSelectedImage={handleSetSelectedImage}
            selectedImageId={selectedImage?.id}
          />
        </div>
      )}
    </Dialog>
  );
};

export default ImageSelector;
