import { TableTemplate } from 'types/tableTemplate';

const actionTableTemplate = {
  id: 'actions',
  description: '',
  originalId: 'actions',
  width: 50,
};

export const justificationTableTemplate: TableTemplate[] = [
  {
    id: 'formattedDate',
    description: 'Data',
    originalId: 'data_ocorrencia',
    width: 100,
  },
  {
    id: 'funcionario',
    description: 'Nome',
    originalId: 'funcionario',
    width: 300,
  },
  {
    id: 'cpf',
    description: 'CPF',
    originalId: 'cpf',
    width: 120,
  },
  {
    id: 'justificativa',
    description: 'Justificativa',
    originalId: 'justificativa',
    width: 300,
  },
  {
    id: 'link_arquivo',
    description: 'Arquivo',
    originalId: 'link_arquivo',
    width: 300,
  },
  {
    id: 'em_analise',
    description: 'Em análise',
    originalId: 'em_analise',
    width: 100,
  },
  {
    id: 'lancada',
    description: 'Lançada',
    originalId: 'lancada',
    width: 100,
  },
  {
    id: 'aceita',
    description: 'Aceita',
    originalId: 'aceita',
    width: 100,
  },
];

export const justificationActionTableTemplate: TableTemplate[] = [actionTableTemplate, ...justificationTableTemplate];
