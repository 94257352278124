import React, { FormEvent } from 'react';
import { MdSearch } from 'react-icons/md';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  styled,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { FaFileExcel } from 'react-icons/fa';
import { useApp } from 'hooks/app';
import SelectBranch from 'components/select-branches/SelectBranch';
import { usePermissionRules } from 'hooks/permissionRules';
import { AbcUnitsFilterParams } from './CurveAbcUnity';

const Filter = styled('div')(({ theme }) => ({
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '160px 130px 1fr 0.8fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
  '& > .search-params': {
    columnGap: 10,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Actions = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const CustomFormControl = styled(FormControl)({
  height: '100%',
});

const CustomIconButton = styled(IconButton)({
  display: 'block',
});

interface AbcUnityFilterBoxProps {
  filter: AbcUnitsFilterParams;
  handleChangeFilter(index: keyof AbcUnitsFilterParams, value: any): void;
  handleOpenDialog(): void;
  handleOpenDialogDesktop(): void;
  handleOpenExcel(): void;
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
  disabled: boolean;
}

const AbcUnityFilterBox: React.FC<AbcUnityFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  searchText,
  handleSearchInputChange,
  handleSearchSubmit,
  handleOpenDialogDesktop,
  handleOpenDialog,
  handleOpenExcel,
  disabled,
}) => {
  const { isMobile, windowWidth } = useApp();
  const { checkPermission } = usePermissionRules();

  return (
    <FilterBox>
      <Filter>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />

        <CustomFormControl>
          <InputLabel>Tipo de venda</InputLabel>
          <Select
            fullWidth
            label="tipo de venda"
            value={filter.typeSale}
            onChange={e => handleChangeFilter('typeSale', e.target.value)}
          >
            <MenuItem value="T">Todas</MenuItem>
            <MenuItem value="C">Coligadas</MenuItem>
            <MenuItem value="V">Vendas</MenuItem>
          </Select>
        </CustomFormControl>

        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate}
            onChange={date => handleChangeFilter('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.finalDate}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </div>
        <form onSubmit={handleSearchSubmit}>
          <TextField
            label="Unidade"
            placeholder="Unidade"
            autoFocus
            value={searchText}
            onChange={e => handleSearchInputChange(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <MdSearch color="#888" size={20} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </Filter>

      <Actions>
        <Tooltip title="Outros filtros">
          <CustomIconButton
            onClick={isMobile || windowWidth < 1280 ? handleOpenDialog : handleOpenDialogDesktop}
            color="inherit"
          >
            <FilterList />
          </CustomIconButton>
        </Tooltip>

        {checkPermission('general.excel_export_button') && (
          <Tooltip title="Exportar excel">
            <IconButton disabled={disabled} onClick={handleOpenExcel} style={{ color: disabled ? '#eee' : 'green' }}>
              <FaFileExcel />
            </IconButton>
          </Tooltip>
        )}
      </Actions>
    </FilterBox>
  );
};

export default AbcUnityFilterBox;
