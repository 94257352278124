import React, { MouseEvent } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { CreditAnalysisQuestion } from 'types/creditAnalysisQuestion';
import { useCreditAnalysisQuestions } from 'pages/credit-analysis-questions/hooks/useCreditAnalysisQuestions';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type CreditAnalysisQuestionItemTableProps = {
  question: CreditAnalysisQuestion;
};

const CreditAnalysisQuestionItemTable: React.FC<CreditAnalysisQuestionItemTableProps> = ({ question }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedQuestion } = useCreditAnalysisQuestions();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedQuestion(question);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{question[item.id as keyof CreditAnalysisQuestion]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default CreditAnalysisQuestionItemTable;
