import React from 'react';
import { IconButton, InputAdornment, MenuItem, TextField, Tooltip, styled } from '@mui/material';
import { CreateImageRequestSizeEnum } from 'openai';
import { MdSearch } from 'react-icons/md';
import { PromptProps } from './AiArt';

interface AiArtInputProps {
  prompt: PromptProps;
  loading: boolean;
  handleChange(index: keyof PromptProps, value: any): void;
}

const Container = styled('div')(props => ({
  border: `2px solid ${props.theme.palette.primary.main}`,
  padding: '10px 40px 10px 10px',
  gridTemplateColumns: '1fr 1fr',
  borderRadius: '0.375rem',
  display: 'grid',
  gap: 5,
}));

const AiArtInput: React.FC<AiArtInputProps> = ({ loading, prompt, handleChange }) => {
  return (
    <Container>
      <TextField
        select
        autoFocus
        value={prompt.size}
        disabled={loading}
        label="selecione o tamanho"
        onChange={e => handleChange('size', e.target.value as CreateImageRequestSizeEnum)}
      >
        <MenuItem value="1024x1024">1024x1024</MenuItem>
        <MenuItem value="512x512">512x512</MenuItem>
        <MenuItem value="256x256">256x256</MenuItem>
      </TextField>

      <TextField
        select
        autoFocus
        value={prompt.style}
        disabled={loading}
        label="estilo do desenho"
        onChange={e => handleChange('style', e.target.value)}
      >
        <MenuItem value="imagem">arte tradicional</MenuItem>
        <MenuItem value="arte digital">arte digital</MenuItem>
        <MenuItem value="desenho a lapis">desenho a lápis</MenuItem>
        <MenuItem value="pintura a óleo">pintura a óleo</MenuItem>
      </TextField>

      <TextField
        autoFocus
        disabled={loading}
        label="características"
        multiline
        style={{ gridColumn: 'span 2' }}
        minRows={2}
        placeholder="ex.: com explosão de fundo"
        value={prompt.characteristics}
        helperText="ex.: com uma piscina contendo um flamingo"
        onChange={e => handleChange('characteristics', e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Pesquisar">
                <IconButton disabled={loading} type="submit">
                  <MdSearch color="#888" size={20} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </Container>
  );
};

export default AiArtInput;
