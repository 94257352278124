import { createContext, useContext } from 'react';

interface FinancialContextValue {
  handleBack(): void;
}

const FinancialContext = createContext<FinancialContextValue>({} as FinancialContextValue);
export const FinancialConsumer = FinancialContext.Consumer;
export const FinancialProvider = FinancialContext.Provider;

export function useFinancial() {
  return useContext(FinancialContext);
}
