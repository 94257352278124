import { createContext, useContext } from 'react';
import { AbcCustomersFilterParams } from '../CurveAbcCustomer';

type AbcCustomerContextValue = {
  searchValue: string;
  filter: AbcCustomersFilterParams;
  handleChangeFilter(index: keyof AbcCustomersFilterParams, value: any): void;
};

const AbcCustomerContext = createContext<AbcCustomerContextValue>({} as AbcCustomerContextValue);
export const AbcCustomerProvider = AbcCustomerContext.Provider;

export function useAbcCustomer(): AbcCustomerContextValue {
  const context = useContext(AbcCustomerContext);
  return context;
}
