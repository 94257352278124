import { TableTemplate } from 'types/tableTemplate';

export const groupedSaleTableTemplate: TableTemplate[] = [
  {
    id: 'pedido_por',
    description: 'Pedido por',
    originalId: 'pedido_por',
    width: 280,
  },
  {
    id: 'formattedTicketMedio',
    description: 'Ticket médio',
    originalId: 'ticket_medio',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'qtd',
    description: 'Qtd',
    originalId: 'qtd',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'formattedPercentual',
    description: '%',
    originalId: 'percentual',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'formattedMc',
    description: 'MC',
    originalId: 'mc',
    width: 150,
    dataType: 'number',
  },
  {
    id: 'formattedSpiff',
    description: 'Spiff',
    originalId: 'spiff',
    width: 150,
    dataType: 'number',
  },
  {
    id: 'formattedTotal',
    description: 'Vl Total',
    originalId: 'vl_total',
    width: 150,
    dataType: 'number',
  },
];
