import React from 'react';
import { useTable } from 'components/table/hook/useTable';
import { AbcCustomerWithoutSale } from 'types/abcCustomer';
import { makeStyles } from '@mui/styles';
import { IconButton, Typography } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
});

type CustomerItemTableProps = {
  customer: AbcCustomerWithoutSale;
};

const CustomerItemTable: React.FC<CustomerItemTableProps> = ({ customer }) => {
  const classes = styles();
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <IconButton key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography style={{ fontSize: 11 }} variant="body2">
                {customer[item.id as keyof AbcCustomerWithoutSale]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default CustomerItemTable;
