import React, { useState } from 'react';
import { Typography, styled } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { useDavReviewDetail } from './hooks/useDavReviewDetail';

const Ul = styled('ul')({
  marginTop: 20,
  display: 'none',
  rowGap: '10px',
  '&.open': {
    display: 'grid',
    gridTemplateColumns: '120px 1fr',
  },
});

const Container = styled('div')(({ theme }) => ({
  margin: '40px 0',
  borderLeft: `3px solid ${theme.palette.primary.main}`,
  borderRadius: 6,
  paddingLeft: 10,
}));

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 15,
  cursor: 'pointer',
  paddingBottom: 5,
  borderBottom: '1px dashed #666',
});

const CustomArrowDownward = styled(ArrowDownward)({
  fontSize: 18,
  transform: 'rotate(0deg)',
  transition: 'all 0.3s ease',
  '&.open': {
    transform: 'rotate(180deg)',
  },
});

const DavReviewDetailCustomer: React.FC = () => {
  const { order } = useDavReviewDetail();
  const [open, setOpen] = useState(false);

  function documentType(): string {
    if (order?.Cnpj.replace(/\D/g, '').length === 14) {
      return 'cnpj';
    } else {
      return 'cpf';
    }
  }

  return (
    <>
      {order && (
        <Container>
          <Row onClick={() => setOpen(!open)}>
            <Typography fontSize={20}>cliente</Typography>
            <CustomArrowDownward className={open ? 'open' : undefined} />
          </Row>
          <Ul className={open ? 'open' : undefined}>
            <Typography fontSize={14}>cliente</Typography>
            <Typography fontSize={14}>{order.cliente.nome}</Typography>
            <Typography fontSize={14}>{documentType()} / ie</Typography>
            <Typography fontSize={14}>
              {order.Cnpj} / {order.cliente.rg_ie}
            </Typography>
            <Typography fontSize={14}>atividade</Typography>
            <Typography fontSize={14}>{order.atividade}</Typography>
            <Typography fontSize={14}>endereço</Typography>
            <Typography fontSize={14}>
              {order.endereco}, {order.cliente.cidade}, {order.cliente.uf}
            </Typography>
            <Typography fontSize={14}>bairro</Typography>
            <Typography fontSize={14}>{order.bairro}</Typography>
            <Typography fontSize={14}>telefone</Typography>
            <Typography fontSize={14}>{order.telefone}</Typography>
            <Typography fontSize={14}>e-mail</Typography>
            <Typography fontSize={14}>{order.email}</Typography>
            <Typography fontSize={14}>prazo:</Typography>
            <Typography fontSize={14}>
              boleto: {order.cliente.prazo_boleto} - informática: {order.cliente.prazo_boleto_informatica}
            </Typography>
          </Ul>
        </Container>
      )}
    </>
  );
};

export default DavReviewDetailCustomer;
