import React, { MouseEvent, Dispatch, SetStateAction } from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import { productTableTemplate } from '../productTableTemplate';
import { MoreHoriz } from '@mui/icons-material';
import { useRelatedProductItems } from '../../hooks/useProducts';
import { RelatedProductItem } from 'pages/related-product/types/relatedProductItem';

const IconButtonStyled = styled(IconButton)({
  justifySelf: 'flex-start',
  padding: 5,
});

type ProductItemTableProps = {
  product: RelatedProductItem;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const ProductItemTable: React.FC<ProductItemTableProps> = ({ product, setAnchorEl }) => {
  const { setSelectedProduct } = useRelatedProductItems();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedProduct(product);
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      {productTableTemplate.map(item =>
        item.id === 'actions' ? (
          <IconButtonStyled onClick={handleClick} size="small" key={item.id}>
            <MoreHoriz key={item.id} />
          </IconButtonStyled>
        ) : (
          <div key={item.id}>
            <Typography variant="body2">{product[item.id]}</Typography>
          </div>
        ),
      )}
    </>
  );
};

export default ProductItemTable;
