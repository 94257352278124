import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Done, MoreVert } from '@mui/icons-material';
import { useBillRemittance } from './hooks/useBillRemitance';

interface BillRemittanceActionsProps {
  handleSubmit(): void;
}

const BillRemittanceActions: React.FC<BillRemittanceActionsProps> = ({ handleSubmit }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { handleSelectAll, handleRemoveSelectedAll, selectedBills, bills } = useBillRemittance();

  function handleSelectAllClick() {
    setAnchorEl(null);
    handleSelectAll();
  }

  function handleRemoveSelectedAllClick() {
    setAnchorEl(null);
    handleRemoveSelectedAll();
  }

  return (
    <>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleSelectAllClick}>Selecionar todos</MenuItem>
        <MenuItem onClick={handleRemoveSelectedAllClick}>Desmarcar todos</MenuItem>
      </Menu>
      <Tooltip title="Confirmar remessa">
        <IconButton color="inherit" onClick={handleSubmit} disabled={selectedBills.length === 0}>
          <Done />
        </IconButton>
      </Tooltip>
      <Tooltip title="Mais opções">
        <IconButton disabled={bills.length === 0} color="inherit" onClick={e => setAnchorEl(e.currentTarget)}>
          <MoreVert />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default BillRemittanceActions;
