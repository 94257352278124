import Dialog from 'components/dialogs/Dialog';
import React, { useState } from 'react';
import ProductSearchForm from './ProductSearchForm';
import { useProductsFetch } from '../hooks/useProductsFetch';
import InsideLoading from 'components/loading/InsideLoading';

interface ProductSearchProps {
  onExited(): void;
}

let timer: NodeJS.Timeout;

const ProductSearch: React.FC<ProductSearchProps> = ({ onExited }) => {
  const [fetcher, products, loading] = useProductsFetch();
  const [searchText, setSearchText] = useState('');

  function handleSearch(value: string) {
    setSearchText(value);

    clearTimeout(timer);

    if (searchText.length < 2) {
      return;
    }

    timer = setTimeout(() => {
      fetcher(value.toUpperCase());
    }, 500);
  }

  return (
    <Dialog height="90vh" maxWidth="sm" onExited={onExited} title="Pesquisa de produtos">
      <ProductSearchForm searchText={searchText} handleSearch={handleSearch} />

      <div>
        {loading ? <InsideLoading /> : products.map(product => <p key={product.codigo_produto}>{product.produto}</p>)}
      </div>
    </Dialog>
  );
};

export default ProductSearch;
