import React from 'react';
import { Typography } from '@mui/material';
import { ProductEditPriceQuantity } from 'types/product';
import { useTable } from 'components/table/hook/useTable';

type ProductQuantityPriceProps = {
  productPriceQuantity: ProductEditPriceQuantity;
};

const ProductQuantityPrice: React.FC<ProductQuantityPriceProps> = ({ productPriceQuantity }) => {
  const { tableTemplate } = useTable();
  return (
    <>
      {tableTemplate.map(item => (
        <div key={item.id}>
          <Typography variant="body2">{productPriceQuantity[item.id]}</Typography>
        </div>
      ))}
    </>
  );
};

export default ProductQuantityPrice;
