import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import Appbar from 'components/appbar/Appbar';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import NoData from 'components/no-data/NoData';
import TableContainer from 'components/table/TableContainer';
import PaginationProvider from 'hooks/pagination';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { ptBR } from 'date-fns/locale';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { SellerCommissionProvider } from './hooks/useSellerCommission';
import { SellerCommission } from 'types/reports/sellerCommission';
import { MdSearch } from 'react-icons/md';
import SellersCommissionReportFilter from './SellerCommissionReportFilter';
import SellersCommissionReportData from './SellerCommissionReportData';
import { sellersCommissionTableTemplate } from './sellerCommissionTableTemplate';
import SellersCommissionListModule from './list/module/SellersCommissionListModule';
import SellersCommissionListTable from './list/table/SellersCommissionListTable';
import FilterActions from 'components/filter-box/FilterActions';
import SelectBranch from 'components/select-branches/SelectBranch';
import { useSelector } from 'store/selector';
import { makeStyles } from '@mui/styles';

export interface SellerCommissionFilter {
  initialDate: Date | null;
  finalDate: Date | null;
  branchId: number;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '150px 200px 1fr',
    columnGap: 10,
    flex: 1,
    '& > .search-params': {
      marginLeft: 5,
      display: 'grid',
      gridTemplateColumns: '200px 150px 200px',
      columnGap: 10,
    },
  },
  select: {
    marginTop: '16px',
  },
  iconButton: {
    marginTop: '10px',
    maxWidth: '40px',
    maxHeight: '40px',
    marginLeft: '-50px',
  },
  searchParams: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    verticalAlign: 'middle',
    marginTop: '150px',
  },
});

const SellerCommissionReport: React.FC = () => {
  const classes = useStyles();
  const { h2iApi, windowWidth, isMobile } = useApp();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(isMobile || windowWidth < 930 ? 'module' : 'list');
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [dialogDate, setDialogDate] = useState(false);
  const [shownReport, setShownReport] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [searchText, setSearchText] = useState('');
  const [selectedBranch, setSelectedBranch] = useState<any>(0);
  const [sellersCommission, setSellersCommission] = useState<SellerCommission[]>([]);
  const [filterdSellersCommission, setFilteredSellerCommission] = useState<SellerCommission[]>([]);
  const [selectedSellerCommission, setSelectedSellerCommission] = useState<SellerCommission | null>(null);
  const [filter, setFilter] = useState<SellerCommissionFilter>({
    initialDate: startOfDay(subDays(new Date(), 30)),
    finalDate: endOfDay(new Date()),
    branchId:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
  });

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  useEffect(() => {
    setFilteredSellerCommission(sellersCommission);
  }, [sellersCommission]);

  function handleChangeFilter(index: keyof SellerCommissionFilter, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string) {
    const filtered = sort(index, filterdSellersCommission);
    setFilteredSellerCommission(filtered);
  }

  function handleSearchSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    handleSearch(searchText);
  }

  const handleSearch = useCallback(
    (searchValue: string) => {
      if (!h2iApi) return;

      const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
      const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

      setLoading(true);

      h2iApi
        .get(`/api/relcomissaovendedor`, {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.branchId || '',
            filtro: searchValue,
          },
        })
        .then(response => {
          if (!response.data.relcomissao) {
            setSellersCommission([]);
            return;
          }

          const _sellersCommission = response.data.relcomissao.map(item => {
            item.formattedTotal = moneyFormat(item.vl_total);
            item.formattedPrice = moneyFormat(item.vl_unitario);
            item.formattedCost = moneyFormat(item.custo);
            item.formattedMc = numberFormat(item.mc);
            item.formattedSpiff = numberFormat(item.spiff);

            return item;
          });

          setSellersCommission(_sellersCommission);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi, filter],
  );

  function handleSearchInputChange(value: string) {
    setSearchText(value);
  }

  return (
    <SellerCommissionProvider
      value={{
        selectedSellerCommission,
        setSelectedSellerCommission,
        filter,
        handleChangeFilter,
      }}
    >
      {dialogDate && <SellersCommissionReportFilter onExited={() => setDialogDate(false)} />}

      {shownReport && (
        <SellersCommissionReportData
          data={sellersCommission}
          branch={selectedBranch}
          onExited={() => setShownReport(false)}
        />
      )}
      <Appbar
        title="Itens vendidos"
        ActionsComponent={
          <>
            {windowWidth < 960 && isMobile && (
              <Button color="primary" variant="contained" onClick={() => setShownReport(true)}>
                Imprimir
              </Button>
            )}
            <FilterActions handleOpenDialog={() => setDialogDate(true)} />
          </>
        }
      />
      <PageHeaderActions
        title="Relação de comissão dos vendedores"
        description="Gerencie a comissão dos vendedores"
        ActionComponent={
          <Button color="primary" variant="contained" onClick={() => setShownReport(true)}>
            Imprimir
          </Button>
        }
      />
      <FilterBox>
        <div className={classes.filter}>
          <SelectBranch
            branch_id={filter.branchId}
            handleChange={e => handleChangeFilter('branchId', e)}
            setBranch={branch => setSelectedBranch(branch)}
          />

          <div className="search-params">
            {displayMode === 'list' ? (
              <>
                <KeyboardDatePicker
                  label="Data inicial"
                  value={filter.initialDate}
                  onChange={date => handleChangeFilter('initialDate', date)}
                />
                <KeyboardDatePicker
                  label="Data Final"
                  value={filter.finalDate}
                  onChange={date => handleChangeFilter('finalDate', date)}
                />
                <form onSubmit={handleSearchSubmit}>
                  <TextField
                    label="Pesquisar"
                    placeholder="Digite sua pesquisa"
                    autoFocus
                    value={searchText}
                    onChange={e => handleSearchInputChange(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton type="submit">
                            <MdSearch color="#888" size={20} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </>
            ) : (
              <>
                <form onSubmit={handleSearchSubmit}>
                  <TextField
                    label="Pesquisar"
                    placeholder="Pesquisa"
                    autoFocus
                    value={searchText}
                    onChange={e => handleSearchInputChange(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton type="submit">
                            <MdSearch color="#888" size={20} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </>
            )}
          </div>
        </div>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </FilterBox>
      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filterdSellersCommission.length === 0 ? (
        <NoData message="Nenhum item para mostrar" />
      ) : (
        <TableContainer tableTemplate={sellersCommissionTableTemplate}>
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <SellersCommissionListTable
                  sellersCommission={filterdSellersCommission}
                  handleSort={handleSort}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <SellersCommissionListModule sellersCommission={filterdSellersCommission} />
              )}
              <Pagination count={filterdSellersCommission.length} />
            </div>
          </PaginationProvider>
        </TableContainer>
      )}
    </SellerCommissionProvider>
  );
};

export default SellerCommissionReport;
