import React from 'react';
import DialogInput from 'components/dialogs/DialogInput';
import KeyboardDatePicker from '../../../components/pickers/DesktopDatePicker';
import { useSales } from './hooks/useSales';
import { Button } from '@mui/material';
import { Search } from '@mui/icons-material';

type SalesReportFilterProps = {
  onExited(): void;
  handleSearchSubmit(event: React.FormEvent<HTMLFormElement>): void;
};

const SalesReportFilter: React.FC<SalesReportFilterProps> = ({ onExited, handleSearchSubmit }) => {
  const { filter, handleChangeFilter } = useSales();

  function onSearch(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    onExited();

    handleSearchSubmit(event);
  }

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <form onSubmit={onSearch} style={{ display: 'flex', flexDirection: 'column', gap: 15, flex: 1 }}>
        <KeyboardDatePicker
          label="Data inicial"
          value={filter.initialDate}
          onChange={date => handleChangeFilter('initialDate', date)}
        />
        <KeyboardDatePicker
          label="Data Final"
          value={filter.finalDate}
          onChange={date => handleChangeFilter('finalDate', date)}
        />

        <Button variant="contained" type="submit" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </form>
    </DialogInput>
  );
};

export default SalesReportFilter;
