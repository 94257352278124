import React, { useMemo } from 'react';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { Pie } from 'react-chartjs-2';
import { useCommercialBi } from '../../hooks/useCommercialBi';
import { styled } from '@mui/material';

const Container = styled('div')({
  height: 500,
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#fff',
  border: '1px solid #eee',
  padding: 20,
});

const AccountTypesChart: React.FC = () => {
  const { data } = useCommercialBi();

  const values = useMemo(() => {
    return data.account_types.sort((a, b) => b.value - a.value).map(item => item.value);
  }, [data.account_types]);

  const labels = useMemo(() => {
    return data.account_types.sort((a, b) => b.value - a.value).map(branch => branch.type);
  }, [data.account_types]);

  return (
    <Container>
      <Pie
        data={{
          labels,
          datasets: [
            {
              label: 'Total',
              data: values,
              borderWidth: 0,
            },
          ],
        }}
        options={{
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                label: tooltip => moneyFormat(values[tooltip.dataIndex as number]),
              },
              titleFont: {
                size: 10,
              },
            },
            legend: {
              display: true,
              position: 'bottom',
            },
            colors: {
              enabled: true,
            },
            title: {
              text: 'Tipo de Conta',
              display: true,
              position: 'top',
              align: 'center',
              color: '#000',
            },
            datalabels: {
              display: true,
              color: '#fff',
              font: {
                size: 10,
                weight: 400,
              },
              textAlign: 'right',
              formatter: value => percentFormat(value),
            },
          },
          responsive: true,
        }}
      />
    </Container>
  );
};

export default AccountTypesChart;
