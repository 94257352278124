import React, { FormEvent } from 'react';
import { MdSearch } from 'react-icons/md';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Theme,
  FormControl,
  InputLabel,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { FaFileExcel } from 'react-icons/fa';
import { useApp } from 'hooks/app';
import SelectBranch from 'components/select-branches/SelectBranch';
import { usePermissionRules } from 'hooks/permissionRules';
import { AbcCustomersFilterParams } from './CurveAbcCustomer';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  filter: {
    gap: 10,
    flex: 1,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '0.4fr 0.4fr 1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
    '& > .search-params': {
      gap: 10,
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  select: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  button: {
    display: 'block',
  },
}));

interface AbcCustomerFilterBoxProps {
  filter: AbcCustomersFilterParams;
  handleChangeFilter(index: keyof AbcCustomersFilterParams, value: any): void;
  handleOpenDialog(): void;
  handleOpenDialogDesktop(): void;
  handleOpenExcel(): void;
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
  disabled: boolean;
}

const AbcCustomerFilterBox: React.FC<AbcCustomerFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  searchText,
  handleSearchInputChange,
  handleSearchSubmit,
  handleOpenDialogDesktop,
  handleOpenDialog,
  handleOpenExcel,
  disabled,
}) => {
  const classes = useStyles();
  const { isMobile, windowWidth } = useApp();
  const { checkPermission } = usePermissionRules();

  return (
    <FilterBox>
      <div className={classes.filter}>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />

        <FormControl style={{ height: '100%' }}>
          <InputLabel>Tipo de venda</InputLabel>
          <Select
            fullWidth
            label="tipo de venda"
            value={filter.typeSale}
            onChange={e => handleChangeFilter('typeSale', e.target.value)}
          >
            <MenuItem value="T">Todas</MenuItem>
            <MenuItem value="C">Coligadas</MenuItem>
            <MenuItem value="V">Vendas</MenuItem>
          </Select>
        </FormControl>

        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate}
            onChange={date => handleChangeFilter('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.finalDate}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </div>
        <form onSubmit={handleSearchSubmit}>
          <TextField
            label="Cliente"
            placeholder="Cliente"
            autoFocus
            value={searchText}
            onChange={e => handleSearchInputChange(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <MdSearch color="#888" size={20} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </div>
      <Tooltip title="Outros filtros">
        <IconButton
          className={classes.button}
          onClick={isMobile || windowWidth < 1280 ? handleOpenDialog : handleOpenDialogDesktop}
          color="inherit"
        >
          <FilterList />
        </IconButton>
      </Tooltip>

      {checkPermission('general.excel_export_button') && (
        <Tooltip title="Exportar excel">
          <IconButton disabled={disabled} onClick={handleOpenExcel} style={{ color: disabled ? '#eee' : 'green' }}>
            <FaFileExcel />
          </IconButton>
        </Tooltip>
      )}
    </FilterBox>
  );
};

export default AbcCustomerFilterBox;
