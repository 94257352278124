import { styled } from '@mui/material';
import NoData from 'components/no-data/NoData';
import { useProject } from 'pages/projects/registration/hook/useProject';
import React, { useEffect, useState, useMemo } from 'react';
import { ProjectProduct, ProjectProductFormContent } from 'types/project';
import { ProductsContextProvider } from './hooks/useProducts';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import ProductListTable from './list/table/ProductListTable';
import ProductListModule from './list/module/ProductListModule';
import ProductSearch from './search/ProductSearch';
import ProjectProductForm from './ProjectProductForm';
import { addProjectProduct } from 'pages/projects/registration/reducer/action';
import { useProjectProductValidation } from './validation/projectProductValidation';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const ProjectProducts: React.FC = () => {
  const { isMobile, windowWidth } = useApp();
  const { project, isProductSearchOpen, setIsProductSearchOpen, dispatch } = useProject();
  const [filteredProducts, setFilteredProducts] = useState<ProjectProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ProjectProduct | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const displayMode = useMemo(() => (isMobile || windowWidth < 930 ? 'module' : 'list'), [isMobile, windowWidth]);
  const [validation, setValidation, validate] = useProjectProductValidation();

  useEffect(() => {
    setFilteredProducts(project.products);
  }, [project.products]);

  function handleSort(index: string) {
    const p = sort(index, filteredProducts);
    setFilteredProducts(p);
  }

  async function handleValidation(data: ProjectProductFormContent) {
    setValidation({});

    await validate(data);
    handleSubmit(data);
  }

  function handleSubmit(data: ProjectProductFormContent) {
    dispatch(addProjectProduct(data));
  }

  return (
    <Container>
      {isProductSearchOpen && <ProductSearch onExited={() => setIsProductSearchOpen(false)} />}

      <ProjectProductForm validation={validation} handleSubmit={handleValidation} />

      {filteredProducts.length === 0 ? (
        <NoData message="Nenhum produto para mostrar." />
      ) : (
        <ProductsContextProvider value={{ selectedProduct, setSelectedProduct }}>
          {displayMode === 'list' ? (
            <ProductListTable products={filteredProducts} handleSort={handleSort} orderedIndex={orderedIndex} />
          ) : (
            <ProductListModule products={filteredProducts} />
          )}
        </ProductsContextProvider>
      )}
    </Container>
  );
};

export default ProjectProducts;
