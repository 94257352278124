import React, { useState, FormEvent, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import SettingsActions from './SettingsActions';
import { useSettingsReducer } from './reducer/reducer';
import * as yup from 'yup';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import SettingsForm from './SettingsForm';
import { settingsChange, setSettings } from './reducer/actions';
import { SettingsIndexes } from './reducer/types';
import Loading from 'components/loading/Loading';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store/selector';
import { setSettings as reduxSetSettings } from 'store/modules/settings/actions';
import { EmailTemplate } from 'types/emailTemplate';

export type SettingsValidation = {
  api_host?: string;
};

const Settings: React.FC = () => {
  const [validation, setValidation] = useState<SettingsValidation>({});
  const [saving, setSaving] = useState(false);
  const [settings, dispatch] = useSettingsReducer();
  const messaging = useMessaging();
  const reduxDispatch = useDispatch();
  const reduxSettings = useSelector(state => state.settings);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);

  useEffect(() => {
    api.get('/email-templates').then(response => {
      setEmailTemplates(response.data);
    });
  }, []);

  useEffect(() => {
    if (!reduxSettings) return;
    dispatch(setSettings(reduxSettings));
  }, [dispatch, reduxSettings]);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    const schema = yup.object().shape({
      api_host: yup.string().url('Informe uma URL válida').required('A URL da API é obrigatória'),
    });

    schema
      .validate(settings)
      .then(() => {
        handleSubmit();
        setValidation({});
      })
      .catch((err: yup.ValidationError) => {
        if (err instanceof yup.ValidationError) {
          setValidation({
            [err.path as string]: err.message,
          });
        }
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/settings/update`, settings)
      .then(response => {
        messaging.handleOpen('Salvo');
        reduxDispatch(reduxSetSettings(response.data));
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleChange(index: SettingsIndexes, value: any) {
    dispatch(settingsChange(index, value));
  }

  return (
    <>
      <Appbar title="Configurações" ActionsComponent={<SettingsActions handleSubmit={handleValidation} />} />
      {saving && <Loading />}
      <PageHeader title="Painel de Controle" description="Configurações do sistema" />
      <form onSubmit={handleValidation}>
        <SettingsForm
          emailTemplates={emailTemplates}
          validation={validation}
          handleChange={handleChange}
          settings={settings}
        />
      </form>
    </>
  );
};

export default Settings;
