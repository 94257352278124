import { Grid, TextField, Theme } from '@mui/material';
import ImageUpload from 'components/image-upload/ImageUpload';
import React from 'react';
import { useProduct } from '../../Product';
import { makeStyles } from '@mui/styles';

const styles = makeStyles<Theme>(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '350px 350px',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  imageUploadContainer: {
    marginTop: 19,
  },
}));

const ProductImages: React.FC = () => {
  const { product, validation, handleChange } = useProduct();
  const classes = styles();

  return (
    <>
      <Grid container>
        <Grid item xs={12} xl={6} lg={6} md={9}>
          <TextField
            label="Link Youtube"
            placeholder="Informe o link do video no YouTube para incorporação"
            margin="normal"
            fullWidth
            onChange={e => handleChange('link_youtube', e.target.value)}
            value={product.link_youtube}
            helperText="Copiar o endereço da barra de endereço do navegador"
          />
        </Grid>
      </Grid>
      <div className={classes.container}>
        <ImageUpload
          validationError={validation.foto_principal}
          label="Foto principal"
          handleSetImage={image => handleChange('main_image', image)}
          handleRemoveImage={() => handleChange('main_image', null)}
          image={product.main_image}
          className={classes.imageUploadContainer}
        />
        <ImageUpload
          label="Foto 1"
          handleSetImage={image => handleChange('image1', image)}
          handleRemoveImage={() => handleChange('image1', null)}
          image={product.image1}
          className={classes.imageUploadContainer}
        />
        <ImageUpload
          validationError={validation.foto_principal}
          label="Foto 2"
          handleSetImage={image => handleChange('image2', image)}
          handleRemoveImage={() => handleChange('image2', null)}
          image={product.image2}
          className={classes.imageUploadContainer}
        />
        <ImageUpload
          validationError={validation.foto_principal}
          label="Foto 3"
          handleSetImage={image => handleChange('image3', image)}
          handleRemoveImage={() => handleChange('image3', null)}
          image={product.image3}
          className={classes.imageUploadContainer}
        />
      </div>
    </>
  );
};

export default ProductImages;
