import { TableTemplate } from 'types/tableTemplate';

export const costsTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 70,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'description',
    description: 'DESCRIÇÃO',
    originalId: 'description',
    width: 400,
  },
  {
    id: 'formattedValue',
    description: 'VALOR R$',
    originalId: 'value',
    width: 200,
  },
  {
    id: 'formattedReferenceDate',
    description: 'DATA DE REFERÊNCIA',
    originalId: 'formattedReferenceDate',
    width: 200,
  },
];
