import React from 'react';
import ProductItem from './ProductItemModule';
import List from 'components/list/List';
import { WasteProduct } from 'pages/material-residues/waste/types/waste';
import { useProducts } from '../../hooks/useProducts';
import WasteProductsListMenu from '../WasteProductsListMenu';

type ProductListModuleProps = {
  products: WasteProduct[];
};

const ProductListModule: React.FC<ProductListModuleProps> = ({ products }) => {
  const { setAnchorEl, anchorEl, setSelectedProduct } = useProducts();

  function onExited() {
    setSelectedProduct(null);
    setAnchorEl(null);
  }

  return (
    <List>
      <WasteProductsListMenu anchorEl={anchorEl} onExited={onExited} />

      {products.map((product, index) => (
        <ProductItem key={index} product={product} />
      ))}
    </List>
  );
};

export default ProductListModule;
