import React, { Dispatch, SetStateAction } from 'react';
import DocumentUpload from 'components/document-upload/DocumentUpload';
import { Document } from 'types/document';

interface AccountAgentDocumentUploadProps {
  setDocument: Dispatch<SetStateAction<Document | null>>;
  validation?: string;
  document: Document | null;
  disabled?: boolean;
}

const AccountAgentDocumentUpload: React.FC<AccountAgentDocumentUploadProps> = ({
  setDocument,
  validation,
  document,
  disabled,
}) => {
  return (
    <div>
      <DocumentUpload
        label="documento do preposto"
        handleRemoveImage={() => setDocument(null)}
        handleSetImage={document => setDocument(document)}
        validationError={validation}
        document={document}
        disabled={disabled}
      />
    </div>
  );
};

export default AccountAgentDocumentUpload;
