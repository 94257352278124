import React, { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, FormControlLabel, MenuItem, TextField, Typography } from '@mui/material';
import { Seller } from 'types/seller';
import { useApp } from 'hooks/app';
import { useSelector } from 'store/selector';
import { useReseller } from '../hooks/useReseller';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {},
  formControlLabel: {
    marginTop: 20,
  },
  buttonEditSeller: {
    marginTop: 7,
  },
  actions: {
    marginTop: 20,
    '& button:first-child': {
      marginRight: 10,
    },
  },
});

const ResellerApprovalContact: React.FC = () => {
  const classes = useStyles();
  const [sellers, setSellers] = useState<Seller[]>([]);
  const { h2iApi } = useApp();
  const [branchId, setBranchId] = useState<number | null>(null);
  const branches = useSelector(state => state.branches);
  const [sellerId, setSellerId] = useState<number | null>(null);
  const [loadingSellers, setLoadingSellers] = useState(false);
  const [editingSeller, setEditingSeller] = useState(false);
  const { handleChange: resellerHandleChange, reseller } = useReseller();

  const seller = useMemo(() => {
    return sellers.find(seller => seller.codigo === sellerId);
  }, [sellerId, sellers]);

  useEffect(() => {
    if (!h2iApi) return;

    if (!branchId) return;

    setLoadingSellers(true);

    h2iApi
      .get('/api/whatsappvendedor', { params: { id_filial: branchId } })
      .then(response => {
        setSellers(response.data.RESULT[0]);
      })
      .catch(err => console.error(err))
      .finally(() => setLoadingSellers(false));
  }, [branchId, h2iApi]);

  function handleConfirmSeller() {
    resellerHandleChange('seller_name', seller?.nome);
    setEditingSeller(false);
  }

  return (
    <div className={classes.container}>
      {editingSeller ? (
        <>
          <Typography variant="body2" color="textSecondary">
            Informe a filial e vendedor responsável pelo contato
          </Typography>
          <TextField
            select
            label="Filial do vendedor"
            fullWidth
            margin="normal"
            value={branchId || ''}
            onChange={e => setBranchId(parseInt(e.target.value))}
          >
            {branches.map(branch => (
              <MenuItem key={branch.id} value={branch.id}>
                {branch.cidade}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            disabled={loadingSellers}
            label="Vendedor"
            fullWidth
            margin="normal"
            value={sellerId || ''}
            onChange={e => setSellerId(parseInt(e.target.value))}
          >
            {sellers.map(seller => (
              <MenuItem key={seller.codigo} value={seller.codigo}>
                {seller.nome}
              </MenuItem>
            ))}
          </TextField>
          <div className={classes.actions}>
            <Button onClick={() => setEditingSeller(false)} variant="text" size="small" color="primary">
              Cancelar
            </Button>
            <Button onClick={handleConfirmSeller} variant="contained" size="small" color="primary">
              Confirmar
            </Button>
          </div>
        </>
      ) : (
        <div>
          <Typography color="textSecondary" variant="caption">
            Vendedor
          </Typography>
          <Typography>{reseller?.seller_name || 'Nenhum'}</Typography>
          <Button
            className={classes.buttonEditSeller}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setEditingSeller(state => !state)}
          >
            {!editingSeller ? 'escolher outro vendedor' : 'Cancelar'}
          </Button>
        </div>
      )}
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            checked={reseller?.must_contact}
            onChange={e => resellerHandleChange('must_contact', e.target.checked)}
            color="primary"
          />
        }
        label="Contatar"
      />
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            checked={reseller?.contacted}
            onChange={e => resellerHandleChange('contacted', e.target.checked)}
            color="primary"
          />
        }
        label="Cliente contatado"
      />
    </div>
  );
};

export default ResellerApprovalContact;
