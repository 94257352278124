import React from 'react';
import { Icon, ListItemButton, Typography, styled } from '@mui/material';
import { Extract } from 'types/extract';
import { useExtracts } from '../../../hooks/useExtracts';
import { Balance, ThumbDown, ThumbUp } from '@mui/icons-material';

const StyledListItem = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 90,
  justifyContent: 'space-between',
  width: '100%',
  gap: 10,
  '& > span': {
    width: '100%',
  },
});

const Column = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const Flex = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: 10,
  flex: 1,
  '&.bottom': {
    justifyContent: 'initial',
  },
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  flex: 1,
  gridTemplateColumns: '36px 1fr',
  gap: 10,
});

interface GroupedBankStatementItemModuleProps {
  extract: Extract;
}

const GroupedBankStatementItemModule: React.FC<GroupedBankStatementItemModuleProps> = ({ extract }) => {
  const { setInvoice, setExtract } = useExtracts();

  return (
    <StyledListItem
      onClick={() =>
        extract.tipo_movimentacao !== 'S'
          ? setInvoice({ id: extract.id_movimentacao, tipo: extract.tipo_movimentacao })
          : setExtract(extract)
      }
    >
      <Flex>
        <Grid>
          {extract.tipo_movimentacao === 'D' ? (
            <Icon fontSize="large">
              <ThumbDown color="error" />
            </Icon>
          ) : extract.tipo_movimentacao === 'D' ? (
            <Icon>
              <ThumbUp color="success" />
            </Icon>
          ) : (
            <Icon>
              <Balance />
            </Icon>
          )}

          <Column>
            <Flex>
              <Typography fontSize={12} variant="subtitle1">
                {extract.descricao}
              </Typography>

              <Typography align="right" variant="caption">
                {extract.filial}
              </Typography>
            </Flex>

            <Flex>
              <Typography
                color={
                  extract.tipo_movimentacao === 'R'
                    ? 'green'
                    : extract.tipo_movimentacao === 'D'
                    ? '#f30000'
                    : 'primary'
                }
                align="left"
                variant="caption"
                fontWeight={600}
              >
                {extract.formattedType}
              </Typography>

              <Typography
                color={extract.valor < 0 ? '#f30000' : 'green'}
                align="right"
                minWidth={100}
                fontSize={16}
                fontWeight={600}
                variant="caption"
              >
                {extract.formattedValue}
              </Typography>
            </Flex>
          </Column>
        </Grid>
      </Flex>

      <Column>
        <Flex className="bottom">
          <Typography variant="caption">Origem:</Typography>

          <Typography align="left" variant="caption" color="primary">
            {extract.conta}
          </Typography>
        </Flex>

        <Flex className="bottom">
          <Typography variant="caption">Destino:</Typography>

          <Typography align="left" variant="caption" color="warning">
            {extract.conta_destino}
          </Typography>
        </Flex>
      </Column>
    </StyledListItem>
  );
};

export default GroupedBankStatementItemModule;
