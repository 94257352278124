import React from 'react';
import { H2iOrder } from 'types/h2iOrder';
import { styled } from '@mui/material';
import DavReviewItemModule from './DavReviewItemModule';
import { usePagination } from 'hooks/pagination';

const Ul = styled('ul')({
  marginTop: 20,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '10px',
});

interface DavReviewListModuleProps {
  orders: H2iOrder[];
}

const DavReviewListModule: React.FC<DavReviewListModuleProps> = ({ orders }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Ul>
      {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(order => (
        <DavReviewItemModule key={order.id} order={order} />
      ))}
    </Ul>
  );
};

export default DavReviewListModule;
