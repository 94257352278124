import { RentalActions } from 'pages/rentals/reducer/types';
import { Rental } from 'pages/rentals/types/rental';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

interface RentalContext {
  rental: Rental;
  dispatch: Dispatch<RentalActions>;
  handleChange(index: keyof Rental, value: any): void;
  percentage: string;
  setPercentage: Dispatch<SetStateAction<string>>;
}

const Context = createContext<RentalContext>({} as RentalContext);
export const RentalProvider = Context.Provider;

export function useRental(): RentalContext {
  return useContext(Context);
}
