import { createContext, useContext } from 'react';
import { AbcUnitsFilterParams } from '../CurveAbcUnity';

type AbcUnityContextValue = {
  searchValue: string;
  filter: AbcUnitsFilterParams;
  handleChangeFilter(index: keyof AbcUnitsFilterParams, value: any): void;
};

const AbcUnityContext = createContext<AbcUnityContextValue>({} as AbcUnityContextValue);
export const AbcUnityProvider = AbcUnityContext.Provider;

export function useAbcUnity(): AbcUnityContextValue {
  const context = useContext(AbcUnityContext);
  return context;
}
