import { TableTemplate } from 'types/tableTemplate';

export const periodTableTemplate: TableTemplate[] = [
  {
    id: 'more',
    description: '',
    originalId: 'more',
    width: 100,
    notSearchable: true,
    notFilterable: true,
  },
  {
    id: 'mes_extenso',
    description: 'Mês',
    originalId: 'mes_extenso',
    width: 100,
  },
  {
    id: 'mes_ano',
    description: 'Ano',
    originalId: 'mes_ano',
    width: 100,
  },
  {
    id: 'formattedCoust',
    description: 'Custo',
    originalId: 'custo',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'formattedTotal',
    description: 'Total',
    originalId: 'total',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'formattedSpiff',
    description: 'Spiff',
    originalId: 'spiff',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'formattedMc',
    description: 'MC',
    originalId: 'mc',
    width: 120,
    dataType: 'number',
  },
];
