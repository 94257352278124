import React from 'react';
import { Tab, Tabs } from '@mui/material';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    // marginBottom: 15,
  },
});

export type MoreTabs = 'payments' | 'items';

type TabsProps = {
  handleChange(value: MoreTabs): void;
  tab: MoreTabs;
};

const MoreItemTabs: React.FC<TabsProps> = ({ tab, handleChange }) => {
  const classes = styles();

  return (
    <Tabs className={classes.container} value={tab} onChange={(e, value) => handleChange(value)} variant="scrollable">
      <Tab label="Itens" value="items" />
      <Tab label="Pagamento" value="payments" />
    </Tabs>
  );
};

export default MoreItemTabs;
