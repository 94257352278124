import React from 'react';
import { AbcProductManufacturer } from 'types/abcProduct';
import { Typography, styled, ListItemButton } from '@mui/material';

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
});

type ManufacturerItemModuleProps = {
  manufacturer: AbcProductManufacturer;
};

const ManufacturerItemModule: React.FC<ManufacturerItemModuleProps> = ({ manufacturer }) => {
  return (
    <>
      <CustomListItemButton>
        <Typography variant="caption" color="primary">
          Fabricante - {manufacturer.fabricante}
        </Typography>
        <Typography gutterBottom>Quantidade - {manufacturer.qtd}</Typography>
        <Typography gutterBottom>Unitário - {manufacturer.formattedUnitary}</Typography>
        <Typography gutterBottom>Valor total - {manufacturer.formattedTotal}</Typography>
        <Typography gutterBottom>SPIFF - {manufacturer.formattedSpiff}</Typography>
        <Typography gutterBottom>MC - {manufacturer.formattedMc}</Typography>
        <Typography gutterBottom>Custo - {manufacturer.formattedCoust}</Typography>
      </CustomListItemButton>
    </>
  );
};

export default ManufacturerItemModule;
