import { TableTemplate } from 'types/tableTemplate';

export const projectsTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'Ações',
    originalId: 'actions',
    width: 70,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 70,
  },
  {
    id: 'document_number',
    description: 'CNPJ',
    originalId: 'document_number',
    width: 120,
  },
  {
    id: 'customer_name',
    description: 'Cliente',
    originalId: 'customer_name',
    width: 400,
  },
  {
    id: 'name',
    description: 'Nome',
    originalId: 'name',
    width: 200,
  },
  {
    id: 'description',
    description: 'Descrição',
    originalId: 'description',
    width: 300,
  },
  {
    id: 'ro_number',
    description: 'Número RO',
    originalId: 'ro_number',
    width: 110,
  },
  {
    id: 'formattedIsRegistered',
    description: 'Registrado',
    originalId: 'is_registered',
    width: 100,
  },
  {
    id: 'formattedIsApproved',
    description: 'Aprovado',
    originalId: 'is_approved',
    width: 100,
  },
  {
    id: 'formattedCurrentStatus',
    description: 'Status atual',
    originalId: 'formattedCurrentStatus',
    width: 100,
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'formattedValue',
    dataType: 'number',
    width: 90,
  },
  {
    id: 'formattedCreatedAt',
    description: 'Feito em',
    originalId: 'formattedCreatedAt',
    width: 100,
  },
  {
    id: 'formattedUpdatedAt',
    description: 'Atualizado em',
    originalId: 'formattedUpdatedAt',
    width: 100,
  },
];
