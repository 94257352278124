import React, { useState } from 'react';
import CostItemModule from './CostItemModule';
import List from 'components/list/List';
import { ProjectCost } from 'types/project';
import ProjectCostsListMenu from '../ProjectCostsListMenu';

type CostListModuleProps = {
  costs: ProjectCost[];
};

const CostListModule: React.FC<CostListModuleProps> = ({ costs }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <>
      <ProjectCostsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <List>
        {costs.map(cost => (
          <CostItemModule setAnchorEl={setAnchorEl} key={cost.id} cost={cost} />
        ))}
      </List>
    </>
  );
};

export default CostListModule;
