import React, { FormEvent, useEffect, useState } from 'react';
import { openai } from 'services/openAi';
import { ImagesResponseDataInner } from 'openai';
import { Button, styled } from '@mui/material';
import { useApp } from 'hooks/app';
import AiArtHelperMobile from './AiArtHelperMobile';
import AiArtInputMobile from './AiArtInputMobile';
import { PromptProps } from '../AiArt';
import AiArtOutputMobile from './AiArtOutputMobile';

const initial_value: PromptProps = {
  referencial_art: '',
  size: '1024x1024',
  style: 'imagem',
  characteristics: '',
};

const Form = styled('form')({
  flex: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const CustomButtonNext = styled(Button)({
  position: 'absolute',
  bottom: -20,
  left: 0,
  right: 0,
  margin: '0px 20px',
});

const CustomButtonBack = styled(Button)({
  position: 'absolute',
  bottom: 20,
  left: 0,
  right: 0,
  margin: '0px 20px',
});

const AiArtMobile: React.FC = () => {
  const [step, setStep] = useState(1);
  const [useDallE, setUseDallE] = useState(false);
  const { handleOpenMenu, isOpenedMenu } = useApp();
  const [searchText, setSearchText] = useState('');
  const [loadingChat, setLoadingChat] = useState(false);
  const [lastSearchText, setLastSearchText] = useState('');
  const [response, setResponse] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<ImagesResponseDataInner[]>([]);
  const [prompt, setPrompt] = useState<PromptProps>(initial_value);

  useEffect(() => {
    setText(`${prompt.characteristics}${prompt.style === 'imagem' ? '' : ', ' + prompt.style}`);
  }, [prompt]);

  function handleBack() {
    setStep(step => step - 1);
  }

  function handleNext() {
    if (step === 2 && images.length === 0) {
      return;
    }
    setStep(step => step + 1);
  }

  function getButtonText() {
    const texts = {
      1: 'continuar',
      2: 'continuar',
      3: 'continuar',
    };

    return texts[step as keyof typeof texts];
  }

  function handleChange(index: keyof PromptProps, value: any) {
    setPrompt(prompt => ({
      ...prompt,
      [index]: value,
    }));
  }

  async function handleSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setLoading(true);

    if (isOpenedMenu) {
      handleOpenMenu();
    }

    await openai
      .createImage({
        prompt: text,
        n: 4,
        size: prompt.size,
      })
      .then(response => setImages(response.data.data))
      .catch(err => console.error(err))
      .finally(() => {
        handleNext();
        setLoading(false);
      });
  }

  async function handleSearch(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setLastSearchText(searchText + ' para ser usada no dall-e');
    setLoadingChat(true);

    openai
      .createChatCompletion({
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: useDallE ? searchText + ' para ser usada no dall-e' : searchText }],
      })
      .then(response => {
        const message = response.data.choices[0].message;

        if (message?.content) {
          setResponse(message?.content.replace(/\n/g, '<br>'));
        }
      })
      .catch(err => setResponse(`<p style="color: red">Falha na requisição. \n${err}</p>`))
      .finally(() => {
        setLoadingChat(false);
      });
  }

  return (
    <>
      {step === 1 ? (
        <Form onSubmit={handleSearch}>
          <AiArtHelperMobile
            useDallE={useDallE}
            setUseDallE={setUseDallE}
            lastSearchText={lastSearchText}
            response={response}
            loading={loadingChat}
            text={searchText}
            setText={setSearchText}
          />
        </Form>
      ) : step === 2 ? (
        <Form onSubmit={handleSubmit}>
          <AiArtInputMobile prompt={prompt} handleChange={handleChange} loading={loading} />
        </Form>
      ) : (
        <AiArtOutputMobile images={images} />
      )}

      {step !== 1 && (
        <CustomButtonBack variant="contained" onClick={handleBack}>
          voltar
        </CustomButtonBack>
      )}

      {step !== 3 && (
        <CustomButtonNext disabled={step === 2 && images.length === 0} variant="contained" onClick={handleNext}>
          {getButtonText()}
        </CustomButtonNext>
      )}
    </>
  );
};

export default AiArtMobile;
