import { Button, IconButton, TextField, styled } from '@mui/material';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Product } from 'types/product';
import ProductsAutoComplete from 'pages/material-residues/waste/registration/products/auto-complete/ProductsAutoComplete';
import WasteProductsModal from 'pages/material-residues/waste/registration/products/dialog/WasteProductsModal';
import { useApp } from 'hooks/app';
import { useMessaging } from 'hooks/messaging';
import { PurchaseOrderProductValidation } from './validation/purchaseOrderProductValidation';
import { PurchaseOrderSelectProduct } from './PurchaseOrderProducts';
import { Info } from '@mui/icons-material';

interface PurchaseOrderProductFormProps {
  disabled: boolean;
  handleAddProduct(e?: FormEvent<HTMLFormElement>): Promise<void>;
  validation: PurchaseOrderProductValidation;
  product: PurchaseOrderSelectProduct;
  onChange(index: keyof PurchaseOrderSelectProduct, value: any): void;
}

const Form = styled('form')(({ theme }) => ({
  display: 'grid',
  gap: 15,
  alignItems: 'center',
  flex: 0,
  gridTemplateColumns: '0.2fr 1fr 0.4fr 0.2fr 0.2fr 0.2fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const PurchaseOrderProductForm: React.FC<PurchaseOrderProductFormProps> = ({
  product,
  handleAddProduct,
  onChange,
  validation,
  disabled,
}) => {
  const { h2iApi, windowWidth } = useApp();
  const { handleOpen } = useMessaging();
  const [openModal, setOpenModal] = useState(false);

  const inputs = {
    quantity: useRef<HTMLInputElement>(null),
    value: useRef<HTMLInputElement>(null),
    product: useRef<HTMLInputElement>(null),
    productId: useRef<HTMLInputElement>(null),
    cfop: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    inputs.productId.current?.focus();
  }, [disabled, inputs.productId]);

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleSelectProduct(product: Product | null) {
    setOpenModal(false);
    onChange('product', product);
    onChange('value', product?.custo || '');
    onChange('productId', product?.codigo_produto || '');
    onChange('name', product?.produto || '');
    inputs.value.current?.focus();
  }

  function handleSearchProduct() {
    if (!h2iApi) {
      return;
    }

    if (!product.productId) {
      return;
    }

    h2iApi
      .get('/api/produtoTodos', { params: { id: product.productId } })
      .then(response => {
        if (response.data[0].Mensagem) {
          handleOpen(response.data[0].Mensagem);
          handleSelectProduct(null);
          return;
        }
        const _product = response.data[0];
        onChange('product', _product);
        onChange('name', _product.produto);
        onChange('value', _product.custo);
        onChange('productId', _product.codigo_produto);
        inputs.value.current?.focus();
      })
      .catch(err => console.error(err));
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'F2') {
      setOpenModal(true);
    }
  };

  return (
    <Form onSubmit={handleAddProduct}>
      {openModal && (
        <WasteProductsModal reee={false} handleSubmit={handleSelectProduct} onClose={() => setOpenModal(false)} />
      )}
      <TextField
        disabled={disabled}
        autoFocus
        label="Cód Prod"
        placeholder="Cód Prod"
        value={product.productId || ''}
        onChange={e => onChange('productId', e.target.value)}
        onKeyDown={handleKeyDown}
        error={!!validation.productId}
        helperText={validation.productId}
        onBlur={handleSearchProduct}
        fullWidth
        inputRef={inputs.productId}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => setOpenModal(true)}>
              <Info />
            </IconButton>
          ),
        }}
      />

      <ProductsAutoComplete
        style={{ gridColumn: windowWidth < 600 ? '1/3' : '' }}
        disabled={disabled}
        reee={false}
        label="Pesquisa de produtos"
        onSelect={product => handleSelectProduct(product)}
        placeholder="Digite o nome do produto"
        selected={product.product}
        helperText={validation.product}
        error={!!validation.product}
      />

      <TextField
        inputRef={inputs.value}
        disabled={disabled}
        autoFocus
        label="Valor R$"
        placeholder="Valor do produto"
        inputMode="decimal"
        value={product.value || ''}
        onChange={e => onChange('value', e.target.value)}
        helperText={validation.value}
        error={!!validation.value}
      />

      <TextField
        inputRef={inputs.quantity}
        disabled={disabled}
        label="Qtd"
        placeholder="Qtd"
        value={product.quantity || ''}
        onChange={e => onChange('quantity', e.target.value)}
        helperText={validation.quantity}
        error={!!validation.quantity}
      />

      <TextField
        inputRef={inputs.cfop}
        disabled={disabled}
        label="CFOP"
        placeholder="CFOP"
        value={product.cfop || ''}
        onChange={e => onChange('cfop', e.target.value)}
        helperText={validation.cfop}
        error={!!validation.cfop}
      />

      <Button type="submit" disabled={disabled} variant="contained">
        Adicionar
      </Button>
    </Form>
  );
};

export default PurchaseOrderProductForm;
