import React, { FormEvent, useState } from 'react';
import Dialog from 'components/dialogs/Dialog';
import { IconButton, InputAdornment, TextField, Typography, styled } from '@mui/material';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import { Close, Done, MoreHoriz } from '@mui/icons-material';
import * as yup from 'yup';
import { PurchaseOrder } from 'types/purchaseOrder';
import { usePurchaseOrder } from '../hook/usePurchaseOrder';
import { addPurchaseOrderPayment, removePurchaseOrderPaymentAction } from '../../reducer/actions';
import { useMessaging } from 'hooks/messaging';
import { addDays } from 'date-fns';
import { moneyFormat } from 'helpers/numberFormat';

interface PurchaseOrderPaymentProps {
  onExited(): void;
  type: 'update' | 'new';
  order: PurchaseOrder;
}

const Container = styled('form')({
  display: 'grid',
  flex: 0,
  gap: 15,
  gridTemplateColumns: '1fr 1fr',
  padding: '10px 0',
});

const Content = styled('div')({
  display: 'flex',
  marginTop: 20,
  flexDirection: 'column',
});

const Subtotal = styled('div')({
  display: 'flex',
  padding: 10,
  gap: 5,
  flexDirection: 'column',
  flex: 1,
  marginTop: 20,
  alignSelf: 'end',
  '& span:last-child': {
    paddingTop: 10,
    borderTop: '1px solid #ddd',
  },
});

const Span = styled('span')({
  display: 'flex',
  gap: 15,
  justifyContent: 'space-between',
});

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '60px 1fr 1fr 40px',
  gap: 5,
  borderBottom: '1px solid #ddd',
  '& div:not(:last-child)': {
    borderRight: '1px solid #ddd',
  },
});

const Box = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '0 5px',
  height: '100%',
  '& > p': {
    fontSize: 13,
  },
  '&.numericData': {
    justifyContent: 'end',
  },
});

interface PaymentValidation {
  date?: string;
  value?: string;
}

const PurchaseOrderPayment: React.FC<PurchaseOrderPaymentProps> = ({ type, onExited, order }) => {
  const [payment, setPayment] = useState({
    value: 0,
    date: new Date(),
  });
  const { dispatch } = usePurchaseOrder();
  const { handleOpen } = useMessaging();
  const [validation, setValidation] = useState<PaymentValidation>({});

  function handleSubmitPayment(date: Date, value: number) {
    if (parseFloat(value.toString()) + parseFloat((order.valor_pago || 0).toString()) > order.valor_total_pedido) {
      handleOpen('O valor informado excede o valor da compra');

      return;
    }

    dispatch(addPurchaseOrderPayment({ date, value, id: 0 }));

    setPayment(payment => ({
      date: addDays(payment.date, 30),
      value: 0,
    }));
  }

  function handleChange(index: string, value: any) {
    setPayment(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    const schema = yup.object().shape({
      value: yup
        .string()
        .required('O valor é obrigatório')
        .test('valueValidation', 'O valor precisa ser maior que 0', value => {
          if (!value) return false;
          if (parseFloat(value) <= 0) return false;

          return true;
        }),
      date: yup.date().required('Informe a data').typeError('Informe uma data válida'),
    });

    schema
      .validate(payment)
      .then(() => {
        handleSubmitPayment(payment.date, payment.value);
        setValidation({});
      })
      .catch((err: yup.ValidationError) => {
        if (err instanceof yup.ValidationError) {
          setValidation({
            [err.path as string]: err.message,
          });
        }

        throw err;
      });
  }

  function handleRemovePayment(index: number) {
    dispatch(removePurchaseOrderPaymentAction(index, type));
  }

  return (
    <Dialog title="Informe o pagamento" height="70vh" maxWidth="sm" onExited={onExited}>
      <Container onSubmit={handleValidation}>
        <DesktopDatePicker
          textFieldProps={{
            error: !!validation.date,
            helperText: validation.date,
          }}
          value={payment.date}
          onChange={date => handleChange('date', date)}
          label="Data de pagamento"
        />
        <TextField
          value={payment.value || ''}
          onChange={e => handleChange('value', e.target.value)}
          label="Valor (R$)"
          error={!!validation.value}
          helperText={validation.value}
          placeholder="Informe o valor do pagamento (R$)"
          inputMode="numeric"
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <Done />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Container>

      {order.pagamentos.length > 0 && (
        <Content>
          <Grid>
            <Box>
              <Typography fontWeight={600}>ID</Typography>
            </Box>
            <Box>
              <Typography fontWeight={600}>Data</Typography>
            </Box>
            <Box className="numericData">
              <Typography fontWeight={600}>Valor</Typography>
            </Box>
            <Box>
              <IconButton size="small" disabled>
                <MoreHoriz />
              </IconButton>
            </Box>
          </Grid>
          {order.pagamentos
            .filter(item => item.cancelado !== 'S')
            .map((item, index) => (
              <Grid key={item.id}>
                <Box>
                  <Typography>{index + 1}</Typography>
                </Box>
                <Box>
                  <Typography>{item.vencimento}</Typography>
                </Box>
                <Box className="numericData">
                  <Typography>{item.formattedValue}</Typography>
                </Box>
                <Box>
                  <IconButton disabled={item.cancelado === 'S'} size="small" onClick={() => handleRemovePayment(index)}>
                    <Close />
                  </IconButton>
                </Box>
              </Grid>
            ))}
        </Content>
      )}

      <Subtotal>
        <Span>
          <Typography fontSize={12} variant="caption">
            PAGO
          </Typography>
          <Typography fontWeight={600}>{moneyFormat(order.valor_pago)}</Typography>
        </Span>
        <Span>
          <Typography fontSize={12} variant="caption">
            SUBTOTAL
          </Typography>
          <Typography fontWeight={600}> {moneyFormat(order.valor_total_pedido)}</Typography>
        </Span>
        {order.valor_pago !== undefined && (
          <Span>
            <Typography fontSize={12} variant="caption">
              RESTANTE
            </Typography>
            <Typography fontWeight={600}>{moneyFormat(order.valor_total_pedido - order.valor_pago)}</Typography>
          </Span>
        )}
      </Subtotal>
    </Dialog>
  );
};

export default PurchaseOrderPayment;
