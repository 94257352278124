import React from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SidebarItem from '../SidebarItem';
import { useApp } from 'hooks/app';
import { SIDEBAR_WIDTH } from 'constants/constants';
import { useSelector } from 'store/selector';
import SidebarBackAction from '../SidebarBackAction';
import { MapsHomeWork } from '@mui/icons-material';

const Container = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#272c3a',
  zIndex: 2,
  transform: `translateX(-${SIDEBAR_WIDTH}px)`,
  transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  height: 50,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 5,
}));

type UnitySidebarProps = {
  show: boolean;
  handleClose(): void;
};

const UnitySidebar: React.FC<UnitySidebarProps> = ({ show, handleClose }) => {
  const user = useSelector(state => state.user);
  const app = useApp();

  return (
    <Container style={{ transform: show ? 'translateX(0)' : undefined }}>
      <DrawerHeader>
        <IconButton color="inherit" onClick={handleClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography color="inherit" variant="h6">
          Controle de usuário
        </Typography>
      </DrawerHeader>
      {user && (
        <>
          {!app.isMobile && app.windowWidth > 960 && (
            <SidebarBackAction handleClick={handleClose} icon={<ArrowBackIcon />} text="voltar" />
          )}

          <SidebarItem to="/governance/unity" icon={<MapsHomeWork />} text="Unidade XX" />
        </>
      )}
    </Container>
  );
};

export default UnitySidebar;
