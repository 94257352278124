import React from 'react';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import { Typography } from '@mui/material';
import { useProduct } from '../../../Product';
import ProductQuantityPrice from './ProductQuantityPrice';
import TableRow from 'components/table/TableRow';
import { productQuantityPriceTableTemplate } from './productQuantityPriceTableTemplate';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';

const ProductQuantityPrices: React.FC = () => {
  const { product } = useProduct();
  return (
    <TableContainer tableTemplate={productQuantityPriceTableTemplate}>
      <TableContent>
        <TableHeader>
          {productQuantityPriceTableTemplate.map(item => (
            <div key={item.id}>
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {product.precoquantidade.map(p => (
            <TableRow key={p.VALOR_PF}>
              <ProductQuantityPrice productPriceQuantity={p} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default ProductQuantityPrices;
