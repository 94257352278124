import { AbcProductCustomer, ComparedAbcProductCustomer } from 'types/abcProduct';
import { AbcProductDates } from '../types';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { formatDates } from '../helpers/formatDates';

type HandleCompareCustomersPayload = {
  customers: AbcProductCustomer[];
  toCompare: AbcProductCustomer[];
  dates: AbcProductDates;
  total: number;
};

type FormatPayload = {
  customers: AbcProductCustomer[];
  initialDate: string;
  finalDate: string;
  total: number;
};

export function handleCompareCustomers({
  customers,
  toCompare,
  dates,
  total,
}: HandleCompareCustomersPayload): AbcProductCustomer[] {
  const _dates = formatDates(dates);

  const _customers = format({ customers, initialDate: _dates.initialDate, finalDate: _dates.finalDate, total });

  const _toCompare = format({
    customers: toCompare,
    initialDate: _dates.toCompareInitialDate,
    finalDate: _dates.toCompareFinalDate,
    total,
  });

  return merge(_customers, _toCompare);
}

function format(payload: FormatPayload): ComparedAbcProductCustomer[] {
  return payload.customers.map(item => ({
    ...item,
    formattedTotal: moneyFormat(item.total),
    formattedCoust: moneyFormat(item.custo),
    formattedMc: moneyFormat(item.mc),
    formattedSpiff: moneyFormat(item.spiff),
    formattedUnitary: moneyFormat(item.unitario),
    formattedBilling: percentFormat((item.total / payload.total) * 100),
    billing: (item.total / payload.total) * 100,
    formattedName: `${payload.initialDate} - ${payload.finalDate}`,
  }));
}

function merge(customers: AbcProductCustomer[], toCompare: AbcProductCustomer[]): ComparedAbcProductCustomer[] {
  const merged = [...customers, ...toCompare];

  const singleNames = new Set(merged.map(item => item.cliente));

  const names = [...singleNames].map(item => ({
    cliente: item,
    type: 'subtitle',
  })) as AbcProductCustomer[];

  return [...names, ...merged];
}
