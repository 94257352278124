import { Button, Grid, InputAdornment, TextField, styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useSearch from 'hooks/search';
import useTableOrder from 'hooks/tableOrder';
import React, { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { api } from 'services/api';
import history from 'services/history';
import { Cfop } from 'types/cfop';
import FiscalCodeActions from './FiscalCodeActions';
import FiscalCodeListTable from './list/table/FiscalCodeListTable';
import FiscalCodeListModule from './list/module/FiscalCodeListModule';
import { usePermissionRules } from 'hooks/permissionRules';
import ApiPagination from 'components/pagination/ApiPagination';
import { Paginated } from 'types/paginated';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const FiscalCode: React.FC = () => {
  const app = useApp();
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const [total, setTotal] = useState(0);
  const search = useSearch();
  const { checkPermission } = usePermissionRules();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(true);
  const [fiscalCodes, setFiscalCodes] = useState<Cfop[]>([]);
  const [filtered, setFiltered] = useState<Cfop[]>([]);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(
    app.isMobile || app.windowWidth < 930 ? 'module' : 'list',
  );

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  useEffect(() => {
    setFiltered(fiscalCodes);
  }, [fiscalCodes]);

  useEffect(() => {
    setLoading(true);
    api
      .get<Paginated<Cfop[]>>('/cfop', {
        params: { page: page + 1, rows },
      })
      .then(_response => {
        const response = _response.data;
        setFiscalCodes(
          response.data.map(item => ({
            ...item,
            formattedHasIcms: item.has_icms ? 'Sim' : 'Não',
            formattedHasPisCofins: item.has_pis_cofins ? 'Sim' : 'Não',
            formattedHasStock: item.has_stock ? 'Sim' : 'Não',
            formattedReferenceInvoice: item.must_reference_invoice ? 'Sim' : 'Não',
          })),
        );
        setTotal(response.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, rows]);

  function handleSearch(searchValue: string) {
    if (searchValue) {
      const _fiscalCodes = search(searchValue, 'CFOP', fiscalCodes);
      setFiltered(_fiscalCodes);
      return;
    }

    setFiltered(fiscalCodes);
  }

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <>
      <Appbar title="CFOP" ActionsComponent={<FiscalCodeActions />} />
      <PageHeaderActions
        title="CFOP"
        description="Gestão dos CFOP cadastrados"
        ActionComponent={
          <>
            {checkPermission('registrations.cfop.new') && (
              <Button color="primary" variant="contained" size="small" onClick={() => history.push('/cfop/new')}>
                Adicionar
              </Button>
            )}
          </>
        }
      />
      <FilterBox>
        <Grid item xs={app.isMobile || app.windowWidth < 960 ? 12 : 4}>
          <TextField
            fullWidth
            label="Pesquisar"
            placeholder="Digite sua pesquisa"
            autoFocus
            onChange={e => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdSearch size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </FilterBox>
      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filtered.length === 0 ? (
        <NoData message="Nenhum CFOP cadastrado" />
      ) : (
        <PaginationProvider>
          <Container>
            {displayMode === 'list' ? (
              <FiscalCodeListTable fiscalCodes={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
            ) : (
              <FiscalCodeListModule fiscalCodes={filtered} />
            )}
            <ApiPagination
              onChangePage={value => setPage(value)}
              onChangeRowsPerPage={value => setRows(value)}
              count={total}
            />
          </Container>
        </PaginationProvider>
      )}
    </>
  );
};

export default FiscalCode;
