import { Dispatch, SetStateAction, useState } from 'react';
import { EmailTemplate } from 'types/emailTemplate';
import * as yup from 'yup';

export type EmailTemplateValidation = {
  name?: string;
  body?: string;
  subject?: string;
  from?: string;
  image1?: string;
  image2?: string;
  link1?: string;
  link2?: string;
};

type UseEmailTemplateValidation = [
  EmailTemplateValidation,
  Dispatch<SetStateAction<EmailTemplateValidation>>,
  (emailTemplate: EmailTemplate) => Promise<void>,
];

export function useStatusValidation(): UseEmailTemplateValidation {
  async function handleValidation(emailTemplate: EmailTemplate) {
    const schema = yup.object().shape({
      body: yup.string().required('O corpo da mensagem é obrigatório'),
      image1: yup.string().url('Informe um endereço válido').nullable(),
      link1: yup.string().url('Informe um endereço válido').nullable(),
      image2: yup.string().url('Informe um endereço válido').nullable(),
      link2: yup.string().url('Informe um endereço válido').nullable(),
      subject: yup.string().required('O assunto da mensagem obrigatório'),
      from: yup
        .string()
        .email('Informe um e-mail válido')
        .required('A descrição é obrigatória')
        .test('validEmailDomain', 'O endereço de email deve ser do dominio @grupoh2i.com.br', value => {
          if (!value) return false;
          if (!value.includes('@grupoh2i.com.br')) return false;
          return true;
        }),
      name: yup.string().required('O nome da mensagem é obrigatória'),
    });

    try {
      await schema.validate(emailTemplate);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<EmailTemplateValidation>({});
  return [validation, setValidation, handleValidation];
}
