import React from 'react';
import { IconButton, Theme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import history from 'services/history';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  button: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const CoursesActions: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.button}>
      <IconButton className={classes.button} color="inherit" onClick={() => history.push('/site/course')}>
        <AddIcon />
      </IconButton>
    </div>
  );
};

export default CoursesActions;
