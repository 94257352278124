import React, { FormEvent, useEffect, useState, useCallback, ReactNode } from 'react';
import { useApp } from 'hooks/app';
import { useMessaging } from 'hooks/messaging';
import { H2iCustomer } from 'types/h2iCustomer';
import ProjectTab, { ProjectTabValues } from '../tab/ProjectTab';
import { useProjectValidation } from '../../validation/projectValidation';
import { api } from 'services/api';
import InsideSaving from 'components/loading/InsideSaving';
import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ProjectUpdateAppbarButtons from './ProjectUpdateAppbarButtons';
import PageHeader from 'components/page-header/PageHeader';
import ProjectMain from '../tab/content/ProjectMain';
import ProjectProducts from '../tab/content/products/ProjectProducts';
import ProjectShipment from '../tab/content/ProjectShipment';
import ProjectDocuments from '../tab/content/ProjectDocuments';
import ProjectContacts from '../tab/content/contacts/ProjectContacts';
import ProjectObservation from '../tab/content/ProjectObservation';
import { useParams } from 'react-router-dom';
import { useFetchProject } from './hook/useFetchProject';
import { useProjectReducer } from '../reducer/reducer';
import { projectChange } from '../reducer/action';
import { Project, ProjectStatusOptions } from 'types/project';
import InsideLoading from 'components/loading/InsideLoading';
import { ProjectProvider } from '../hook/useProject';
import ProjectCosts from '../tab/content/costs/ProjectCosts';
import ProjectStatus from '../tab/content/ProjectStatus';
import ProjectActions from '../tab/content/actions/ProjectActions';
import { TAB_CONTENT_WITH_FULL_WIDTH } from 'pages/projects/constants/tabContentWithFullWidth';
import history from 'services/history';

type ContentProps = {
  maxWidth: string | number;
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Content = styled('div')<ContentProps>(props => ({
  display: 'flex',
  borderTop: '1px solid #eee',
  flexDirection: 'column',
  flex: 1,
  maxWidth: props.maxWidth,
  marginTop: 20,
  marginBottom: 200,
}));

const ProjectUpdate: React.FC = () => {
  const { h2iApi } = useApp();
  const messaging = useMessaging();
  const [project, dispatch] = useProjectReducer();
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState<ProjectTabValues>('info');
  const [reseller, setReseller] = useState<H2iCustomer | null>(null);
  const [validation, setValidation, validate] = useProjectValidation();
  const { id } = useParams<{ id: string }>();
  const [loading, error] = useFetchProject(dispatch, id);
  const [isProductSearchOpen, setIsProductSearchOpen] = useState(false);

  console.log(project);

  useEffect(() => {
    const main = ['name', 'value', 'description', 'document_number'];

    const [key] = Object.keys(validation);

    if (!key) {
      return;
    }

    if (main.includes(key)) {
      setTab('info');
      return;
    }

    setTab('shipment');
  }, [validation]);

  useEffect(() => {}, [project.current_status, dispatch]);

  const loadReseller = useCallback(() => {
    if (!h2iApi || !project.document_number) {
      return;
    }

    h2iApi
      .get(`/api/getclientescadastrados?cnpj=${project.document_number}`)
      .then(response => {
        setReseller(response.data[0]);
      })
      .catch(err => console.error(err));
  }, [h2iApi, project.document_number]);

  useEffect(() => {
    loadReseller();
  }, [loadReseller]);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});

    if (!project) {
      return;
    }

    validate(project)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.error(err);
      });
  }

  async function handleUpdateStatus(status: ProjectStatusOptions) {
    if (!project) {
      return;
    }

    await api
      .post(`/projects/${project?.id}/status`, { status })
      .catch(err => messaging.handleOpen(err.response?.data.error || 'Não foi possível alterar o status'));
  }

  async function handleCancelStatus() {
    if (!project) {
      return;
    }

    await api
      .post(`/projects/${project.id}/cancel`)
      .catch(err => messaging.handleOpen(err.response?.data.error || 'Não foi possível alterar o status'));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/projects/${project?.id}`, project)
      .then(() => {
        messaging.handleOpen('Projeto atualizado com sucesso!');
        history.push('/projects');
      })
      .catch(err => {
        setSaving(false);
        console.error(err);
      });
  }

  function handleChange(index: keyof Project, value: any) {
    dispatch(projectChange(index, value));
  }

  function handleTabsRendering(): ReactNode {
    const tabContents: Record<ProjectTabValues, ReactNode> = {
      info: (
        <ProjectMain
          setReseller={setReseller}
          reseller={reseller}
          validation={validation}
          handleCancelStatus={handleCancelStatus}
          handleUpdateStatus={handleUpdateStatus}
          action="updating"
        />
      ),
      products: <ProjectProducts />,
      status: <ProjectStatus handleCancelStatus={handleCancelStatus} handleUpdateStatus={handleUpdateStatus} />,
      shipment: <ProjectShipment validation={validation} />,
      documents: <ProjectDocuments />,
      costs: <ProjectCosts />,
      contacts: <ProjectContacts />,
      observation: <ProjectObservation />,
      actions: <ProjectActions />,
    };

    return tabContents[tab];
  }

  return (
    <ProjectProvider value={{ project, dispatch, handleChange, isProductSearchOpen, setIsProductSearchOpen }}>
      <Container>
        <Appbar
          title="Novo projeto"
          Tab={<ProjectTab tab={tab} handleChange={tab => setTab(tab)} />}
          ActionsComponent={<ProjectUpdateAppbarButtons handleValidation={handleValidation} />}
        />

        <PageHeader backUrl="/projects" title="Editar projeto" description="Atualização de projeto de energia solar" />

        {saving && <InsideSaving />}

        {loading ? (
          <InsideLoading />
        ) : error ? (
          <span>{error}</span>
        ) : (
          <Content maxWidth={TAB_CONTENT_WITH_FULL_WIDTH.includes(tab) ? '100%' : 600}>{handleTabsRendering()}</Content>
        )}
      </Container>
    </ProjectProvider>
  );
};

export default ProjectUpdate;
