import { Done } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React, { FormEvent } from 'react';

interface PurchaseOrderActionsProps {
  handleValidation(sentBy: string, e?: FormEvent<HTMLFormElement> | undefined);
}

const PurchaseOrderActions: React.FC<PurchaseOrderActionsProps> = ({ handleValidation }) => {
  return (
    <IconButton onClick={e => handleValidation('button')} color="inherit" type="submit">
      <Tooltip title="Salvar">
        <Done />
      </Tooltip>
    </IconButton>
  );
};

export default PurchaseOrderActions;
