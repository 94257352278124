import { TableTemplate } from 'types/tableTemplate';

export const productPriceTableTemplate: TableTemplate[] = [
  {
    id: 'codigo_produto',
    description: 'Cod Prod',
    originalId: 'codigo_produto',
    width: 70,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 450,
  },
  {
    id: 'formattedCusto',
    description: 'Custo',
    originalId: 'custo',
    width: 80,
  },
  {
    id: 'formattedEst',
    description: 'Estoque',
    originalId: 'formattedEst',
    width: 80,
  },
  {
    id: 'formattedStockValue',
    description: 'Valor Estoque',
    originalId: 'formattedStockValue',
    width: 110,
  },
];
