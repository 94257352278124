import { useCallback, useMemo } from 'react';
import { Invoice } from 'types/invoices';

export interface UseInvoicesTotal {
  expense: number;
  revenue: number;
  personnel: number;
  invoice: number;
}

export function useInvoicesTotal(invoices: Invoice[], loading: boolean): UseInvoicesTotal {
  const sumByType = useCallback(
    (type: string) =>
      invoices.filter(invoice => invoice.tipo === type).reduce((sum, invoice) => sum + invoice.valor, 0),
    [invoices],
  );

  const expense = useMemo(() => sumByType('D'), [sumByType]);
  const revenue = useMemo(() => sumByType('R'), [sumByType]);
  const personnel = useMemo(() => sumByType('F'), [sumByType]);
  const invoice = useMemo(() => sumByType('T'), [sumByType]);

  return {
    expense: loading ? 0 : expense,
    revenue: loading ? 0 : revenue,
    personnel: loading ? 0 : personnel,
    invoice: loading ? 0 : invoice,
  };
}
