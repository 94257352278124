import { IconButton, styled } from '@mui/material';
import React from 'react';
import { Add, Print } from '@mui/icons-material';
import { useRevenues } from './hooks/useRevenues';

const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const RevenuesActions: React.FC = () => {
  const { setIsReportOpen, setIsNewRevenueOpen } = useRevenues();

  return (
    <Container>
      <IconButton onClick={() => setIsNewRevenueOpen(true)} color="inherit">
        <Add />
      </IconButton>
      <IconButton color="inherit" onClick={() => setIsReportOpen(true)}>
        <Print />
      </IconButton>
    </Container>
  );
};

export default RevenuesActions;
