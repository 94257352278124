import React from 'react';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import { Sales } from 'types/reports/sales';
import { Typography, styled } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import GroupedSaleItemTable from './GroupedSaleItemTable';
import { groupedSaleTableTemplate } from '../../groupedSaleTableTemplate';
import TableContainer from 'components/table/TableContainer';

export interface GroupedSale {
  pedido_por: string;
  vl_total: number;
  spiff: number;
  mc: number;
  qtd: number;
  percentual: number;
  ticket_medio: number;
  formattedTicketMedio: string;
  formattedTotal: string;
  formattedMc: string;
  formattedSpiff: string;
  formattedPercentual: string;
  vendas: Sales[];
}

interface GroupedSaleListTableProps {
  groupedSales: GroupedSale[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

const GroupedSaleListTable: React.FC<GroupedSaleListTableProps> = ({ handleSort, orderedIndex, groupedSales }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <TableContainer tableTemplate={groupedSaleTableTemplate}>
      <div>
        <TableContent useMinHeight={false}>
          <TableHeader>
            {groupedSaleTableTemplate.map(item => (
              <HeaderItem
                className={item.dataType === 'number' ? 'numericData' : ''}
                key={item.id}
                onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </HeaderItem>
            ))}
          </TableHeader>
          <TableBody useMaxHeight={true}>
            {groupedSales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(sale => (
              <TableRow style={{ minHeight: 20, padding: 0 }} key={sale.pedido_por}>
                <GroupedSaleItemTable sale={sale} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </div>
    </TableContainer>
  );
};

export default GroupedSaleListTable;
