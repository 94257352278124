import { useCallback, useState } from 'react';
import { api } from 'services/api';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { RelatedProduct } from '../types/relatedProduct';
import { RelatedProductsQueryParams } from '../RelatedProducts';

export function useFetchRelatedProducts() {
  const [relatedProducts, setRelatedProducts] = useState<RelatedProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [total, setTotal] = useState(0);

  const fetch = useCallback((params: RelatedProductsQueryParams) => {
    setLoading(true);

    api
      .get('/compatibilities', { params: { ...params, page: params.page + 1 } })
      .then(response => {
        setRelatedProducts(
          response.data.data.map(item => ({
            ...item,
            formattedUpdatedAt: format(parseISO(item.updated_at), 'P', { locale: ptBR }),
            formattedCreatedAt: format(parseISO(item.created_at), 'P', { locale: ptBR }),
          })),
        );
        setTotal(response.data.total);
      })
      .catch(err => {
        console.error(err);
        setError('Aconteceu um erro ao carregar a lista de compatibilidade ' + err.message);
      })
      .finally(() => setLoading(false));
  }, []);

  return {
    fetch,
    relatedProducts,
    setRelatedProducts,
    loading,
    error,
    total,
  };
}
