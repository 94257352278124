import React, { useEffect, useRef, useState } from 'react';
import { postalCodeSearch } from 'services/postalCodeSearch';
import MaskedInput from 'react-text-mask';
import { Agent, AgentAddress } from 'types/agent';
import { AgentAddressValidation } from './validation/useAgentAddressValidation';
import { TextField } from '@mui/material';
import PostalCodeInput from 'components/masked-input/PostalCodeInput';

let timer: NodeJS.Timeout;

interface AccountAgentFormAddressProps {
  agent: Agent;
  handleChange(index: keyof Agent, value: any): void;
  validation: AgentAddressValidation;
  handleSetAddress(agentAddress: AgentAddress): void;
}

const AccountAgentFormAddress: React.FC<AccountAgentFormAddressProps> = ({
  agent,
  handleChange,
  validation,
  handleSetAddress,
}) => {
  const [postalCode, setPostalCode] = useState(agent.cep);
  const [loading, setLoading] = useState(false);
  const [postalCodeValidation, setPostalCodeValidation] = useState({
    error: !agent.cep,
    message: '',
    hasData: !!agent.cep,
  });

  const inputs = {
    cep: useRef<MaskedInput>(null),
    endereco: useRef<HTMLInputElement>(null),
    numero_endereco: useRef<HTMLInputElement>(null),
    bairro: useRef<HTMLInputElement>(null),
    cidade: useRef<HTMLInputElement>(null),
    uf: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    inputs.cep.current?.inputElement?.focus();
  }, [inputs.cep]);

  useEffect(() => {
    if (!postalCodeValidation.error && postalCodeValidation.hasData) inputs.numero_endereco.current?.focus();
  }, [postalCodeValidation]); //eslint-disable-line

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    if (key === 'cep') {
      inputs.cep.current?.inputElement.focus();
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleChangeCep(value: string) {
    setPostalCode(value);
    setPostalCodeValidation({ error: false, message: '', hasData: false });

    const newPostalCode = value.replace(/\D/g, '');

    clearTimeout(timer);

    if (newPostalCode.length === 0) return false;

    if (newPostalCode.length < 8) {
      setPostalCodeValidation({
        error: true,
        message: 'CEP inválido',
        hasData: false,
      });
    }

    if (newPostalCode.length === 8)
      timer = setTimeout(() => {
        setLoading(true);
        postalCodeSearch(newPostalCode)
          .then(response => {
            if (response.data.erro) {
              setPostalCodeValidation({
                error: true,
                message: 'CEP inexistente',
                hasData: false,
              });
              return;
            }
            const { data } = response;
            setPostalCodeValidation({ error: false, message: '', hasData: true });
            handleSetAddress({
              cep: data.cep.replace(/\D/g, ''),
              endereco: data.logradouro,
              numero_endereco: '',
              uf: data.uf,
              cidade: data.localidade,
              bairro: data.bairro,
            });
          })
          .catch(err => {
            setPostalCodeValidation({
              error: true,
              message: err.message,
              hasData: false,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }, 500);
  }

  return (
    <div>
      <TextField
        inputRef={inputs.cep}
        value={postalCode}
        onChange={e => handleChangeCep(e.target.value)}
        placeholder="CEP"
        label="CEP"
        error={postalCodeValidation.error || !!validation.cep}
        helperText={postalCodeValidation.message || validation.cep}
        disabled={loading}
        InputProps={{
          inputComponent: PostalCodeInput as any,
        }}
        margin="normal"
        fullWidth
        autoFocus
      />
      {!postalCodeValidation.error && postalCodeValidation.hasData && (
        <>
          <TextField
            inputRef={inputs.endereco}
            placeholder="Endereço"
            label="Endereço"
            value={agent.endereco}
            onChange={e => handleChange('endereco', e.target.value)}
            error={!!validation.endereco}
            helperText={validation.endereco}
            margin="normal"
            fullWidth
          />

          <TextField
            inputRef={inputs.numero_endereco}
            placeholder="Número"
            label="Número"
            value={agent.numero_endereco}
            onChange={e => handleChange('numero_endereco', e.target.value)}
            error={!!validation.numero_endereco}
            helperText={validation.numero_endereco}
            margin="normal"
            fullWidth
          />

          <TextField
            inputRef={inputs.bairro}
            placeholder="Bairro"
            label="Bairro"
            value={agent.bairro}
            onChange={e => handleChange('bairro', e.target.value)}
            error={!!validation.bairro}
            helperText={validation.bairro}
            margin="normal"
            fullWidth
          />

          <TextField
            inputRef={inputs.cidade}
            placeholder="Cidade"
            label="Cidade"
            value={agent.cidade}
            error={!!validation.cidade}
            helperText={validation.cidade}
            onChange={e => handleChange('cidade', e.target.value)}
            disabled
            margin="normal"
            fullWidth
          />

          <TextField
            inputRef={inputs.uf}
            placeholder="Estado"
            label="Estado"
            value={agent.uf}
            error={!!validation.uf}
            helperText={validation.uf}
            onChange={e => handleChange('uf', e.target.value)}
            disabled
            margin="normal"
            fullWidth
          />
        </>
      )}
    </div>
  );
};

export default AccountAgentFormAddress;
