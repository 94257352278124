import { TableTemplate } from 'types/tableTemplate';

export const ACTIONS_TABLE_TEMPLATE: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 70,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'action',
    description: 'AÇÃO',
    originalId: 'action',
    width: 150,
  },
  {
    id: 'full_name',
    description: 'NOME',
    originalId: 'full_name',
    width: 300,
  },
  {
    id: 'observation',
    description: 'OBSERVAÇÃO',
    originalId: 'observation',
    width: 400,
  },
  {
    id: 'formattedReferenceDate',
    description: 'DATA DE REFERÊNCIA',
    originalId: 'formattedReferenceDate',
    width: 200,
  },
];
