import React from 'react';
import { Button, styled } from '@mui/material';
import ExcelButtonDownload from 'components/excel-button-download/ExcelButtonDownload';
import { useDefaultExcelExport } from 'hooks/useDefaultExcelExport';
import { Add, Print } from '@mui/icons-material';
import { useTransferAccounts } from './hooks/useTransferAccount';

const PageHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 10,
  gap: 10,
});

const CustomButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const Actions = styled('div')(({ theme }) => ({
  justifyContent: 'space-between',
  display: 'flex',
  gap: 10,
  '& > div': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const PageHeaderButtons: React.FC = () => {
  const { accounts, setIsReportOpen, setIsNewTransferAccount } = useTransferAccounts();

  const { handleDownload } = useDefaultExcelExport(accounts);

  function handleReportClick() {
    setIsReportOpen(true);
  }

  return (
    <PageHeader>
      <Actions>
        <div>
          <ExcelButtonDownload handleDownload={handleDownload} size="small" marginTop={0} disabled={!accounts.length} />
          <CustomButton
            disabled={!accounts.length}
            startIcon={<Print />}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleReportClick}
          >
            Imprimir
          </CustomButton>
        </div>

        <CustomButton
          onClick={() => setIsNewTransferAccount(true)}
          startIcon={<Add />}
          variant="contained"
          color="primary"
          size="small"
        >
          Novo
        </CustomButton>
      </Actions>
    </PageHeader>
  );
};

export default PageHeaderButtons;
