import { useCallback } from 'react';
import { CourseStudent, CourseStudentOnList } from '../types/courseStudent';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const paymentTypeOptions = {
  credit_card: 'Cartão de crédito',
  pix: 'PIX',
};

export function useCourseStudentFormatter() {
  const formatDate = useCallback((date: string | Date | null): string => {
    if (!date) {
      return '';
    }

    if (date instanceof Date) {
      return format(date, 'P', { locale: ptBR });
    }

    return format(parseISO(date), 'P', { locale: ptBR });
  }, []);

  const formatter = useCallback(
    (rental: CourseStudent): CourseStudentOnList => {
      return {
        ...rental,
        formattedCreatedAt: formatDate(rental.created_at),
        formattedIsApproved: rental.is_approved ? 'Sim' : 'Não',
        formattedIsPaid: rental.is_paid ? 'Sim' : 'Não',
        formattedPaymentType: paymentTypeOptions[rental.payment_type],
      };
    },
    [formatDate],
  );

  return formatter;
}
