import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import { MdSearch } from 'react-icons/md';
import FilterBox from 'components/filter-box/FilterBox';
import { FinancialTicketFilterParams } from './FinancialTicketReport';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import { IconButton, InputAdornment, MenuItem, TextField, styled } from '@mui/material';
import SelectBranch from 'components/select-branches/SelectBranch';

const Filter = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '0.6fr 0.6fr 0.6fr 1fr 1fr',
  gap: 10,
  flex: 1,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
  '& > .search-params': {
    marginLeft: 5,
    display: 'grid',
    columnGap: 10,
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  '& p': {
    fontSize: '0.75rem!important',
  },
  '& span': {
    fontSize: '0.75rem!important',
  },
  '& label': {
    fontSize: '0.75rem!important',
  },
  '& .MuiInputBase-root': {
    fontSize: '0.75rem!important',
    '& .MuiInputAdornment-root': {
      marginRight: 3,
      marginLeft: 3,
    },
  },
  '& .MuiButtonBase-root': {
    fontSize: '0.75rem!important',
  },
}));

const Select = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

interface FinancialTicketFilterBoxProps {
  filter: FinancialTicketFilterParams;
  displayMode: 'list' | 'module';
  setDisplayMode: Dispatch<SetStateAction<'list' | 'module'>>;
  handleChangeFilter(index: keyof FinancialTicketFilterParams, value: any): void;
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
}

const FinancialTicketFilterBox: React.FC<FinancialTicketFilterBoxProps> = ({
  filter,
  displayMode,
  setDisplayMode,
  handleChangeFilter,
  searchText,
  handleSearchInputChange,
  handleSearchSubmit,
}) => {
  return (
    <FilterBox>
      <Filter>
        <SelectBranch branch_id={filter.branchId} handleChange={e => handleChangeFilter('branchId', e)} />

        <Select
          value={filter.typeDate}
          onChange={e => handleChangeFilter('typeDate', e.target.value)}
          fullWidth
          select
          label="Tipo da data"
        >
          <MenuItem value={'V'}>Vencimento</MenuItem>
          <MenuItem value={'L'}>Lançamento</MenuItem>
        </Select>
        <Select
          value={filter.situation}
          onChange={e => handleChangeFilter('situation', e.target.value)}
          fullWidth
          select
          label="Situação"
        >
          <MenuItem value={'A'}>Apropriados</MenuItem>
          <MenuItem value={'N'}>Não apropriados</MenuItem>
          <MenuItem value={'T'}>Todos</MenuItem>
        </Select>
        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate}
            onChange={date => handleChangeFilter('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.finalDate}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </div>
        <form onSubmit={handleSearchSubmit}>
          <TextField
            label="Pesquisar"
            placeholder="Digite sua pesquisa"
            autoFocus
            value={searchText}
            onChange={e => handleSearchInputChange(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <MdSearch color="#888" size={20} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </Filter>
      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default FinancialTicketFilterBox;
