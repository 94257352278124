import React from 'react';
import { Button, Typography } from '@mui/material';
import { Order } from './SalesReport';
import ArroWBackIcon from '@mui/icons-material/ArrowBack';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 7,
  },
  order: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0',
  },
  items: {
    margin: '20px 0',
    '& > .item': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '10px 0',
      maxWidth: 900,
      alignItems: 'center',
      borderBottom: '1px solid #eee',
      paddingBottom: 10,
    },
  },
  action: {
    display: 'flex',
  },
});

type SalesReportOrderProps = {
  selectedOrder: Order;
  setSelectedOrder(order: null): void;
};

const SalesReportOrder: React.FC<SalesReportOrderProps> = ({ setSelectedOrder, selectedOrder }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.action}>
        <Button startIcon={<ArroWBackIcon />} onClick={() => setSelectedOrder(null)}>
          Voltar
        </Button>
      </div>
      <div className={classes.order}>
        <Typography variant="body2" color="primary" gutterBottom>
          Venda n° {selectedOrder.id} - {selectedOrder.data} {selectedOrder.hora}
        </Typography>
        <Typography variant="h6">{selectedOrder.cliente}</Typography>
        <Typography>{selectedOrder.Cnpj}</Typography>
        <div className={classes.items}>
          <Typography>Produtos</Typography>
          {selectedOrder.itens.map(item => (
            <div key={item.cod_produ} className="item">
              <div>
                <Typography variant="body2">{item.produto}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {item.QTD}x {item.formattedValorUnitario}
                </Typography>
              </div>
              <Typography>{item.formattedValorTotal}</Typography>
            </div>
          ))}
        </div>
        <Typography variant="h6">Total {selectedOrder.formattedValorTotal}</Typography>
      </div>
    </>
  );
};

export default SalesReportOrder;
