import Dialog from 'components/dialogs/Dialog';
import { useApp } from 'hooks/app';
import React, { FormEvent, useState, SetStateAction, Dispatch } from 'react';
import { useTotalizingAccountValidation } from '../../validation/totalizingAccountValidation';
import { TotalizingAccount } from 'types/totalizingAccount';
import TotalizingAccountForm from '../TotalizingAccountForm';
import InsideSaving from 'components/loading/InsideSaving';
import { Button, styled } from '@mui/material';
import { useMessaging } from 'hooks/messaging';

interface EditTotalizingAccountProps {
  onExited(): void;
  onSubmit(): void;
  selectedAccount: TotalizingAccount;
  setSelectedAccount: Dispatch<SetStateAction<TotalizingAccount | null>>;
}

const Form = styled('form')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const EditTotalizingAccount: React.FC<EditTotalizingAccountProps> = ({
  onExited,
  onSubmit,
  selectedAccount,
  setSelectedAccount,
}) => {
  const { financialApi } = useApp();
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);
  const [validation, setValidation, validate] = useTotalizingAccountValidation();

  function handleValidation(e?: FormEvent<HTMLFormElement | HTMLButtonElement>) {
    e?.preventDefault();

    setValidation({});

    validate(selectedAccount)
      .then(handleSubmit)
      .catch(error => {
        console.log(error);
        handleOpen('Não foi possível salvar a despesa');
      });
  }

  function handleSubmit() {
    setSaving(true);

    financialApi
      .post('/savecontatotalizadora', {
        ...selectedAccount,
        saldo: parseFloat(selectedAccount.saldo.toString()),
      })
      .then(onSubmit)
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleChange(index: keyof TotalizingAccount, value: any) {
    setSelectedAccount(totalizingAccount =>
      totalizingAccount
        ? {
            ...totalizingAccount,
            [index]: value,
          }
        : null,
    );
  }

  return (
    <Dialog
      maxWidth="sm"
      height="70vh"
      onExited={onExited}
      title="editar conta totalizadora"
      ComponentActions={
        <Button onClick={handleValidation} variant="contained">
          Salvar
        </Button>
      }
    >
      {saving && <InsideSaving />}
      <Form onSubmit={handleValidation}>
        <TotalizingAccountForm account={selectedAccount} validation={validation} handleChange={handleChange} />
      </Form>
    </Dialog>
  );
};

export default EditTotalizingAccount;
