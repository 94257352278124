import React from 'react';

import { AbcProductSegment } from 'types/abcProduct';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
    '& > span > svg': {
      transition: 'transform ease-in 0.3s',
    },
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  text: {
    fontSize: 11,
  },
  subtitle: {
    fontWeight: 'bold',
  },
  subItem: {
    paddingLeft: 10,
  },
});

type SegmentItemTableProps = {
  segment: AbcProductSegment;
};

const SegmentItemTable: React.FC<SegmentItemTableProps> = ({ segment }) => {
  const classes = styles();
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <IconButton key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'formattedName' && segment.type === 'subtitle' ? (
            <div key={item.id}>
              <Typography className={classes.text} variant="body2">
                <strong>{segment.segmento}</strong>
              </Typography>
            </div>
          ) : item.id === 'formattedName' ? (
            <div key={item.id}>
              <Typography className={`${classes.text} ${classes.subItem}`} variant="body2">
                {segment.formattedName}
              </Typography>
            </div>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Typography style={{ fontSize: 11 }} variant="body2">
                {segment[item.id as keyof AbcProductSegment]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default SegmentItemTable;
