import { TableTemplate } from 'types/tableTemplate';

export const dashboardPaymentTableTemplate: TableTemplate[] = [
  {
    id: 'forma',
    description: 'Forma',
    originalId: 'forma',
    width: 250,
  },
  {
    id: 'formattedTotal',
    description: 'Total',
    originalId: 'total',
    width: 120,
    dataType: 'number',
  },
];
