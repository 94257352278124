import React, { useEffect, useState } from 'react';
import { AggregatedProduct } from './ShoppingSuggestion';
import Dialog from 'components/dialogs/Dialog';
import { Tooltip, Typography, styled } from '@mui/material';
import NoData from 'components/no-data/NoData';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';

interface SuggestionSelectedProductProps {
  onExited(): void;
  selectedProduct: AggregatedProduct;
  products: AggregatedProduct[];
  branches: string[];
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  overflow: 'auto',
  zoom: '90%',
});

const TR = styled('tr')(({ theme }) => ({
  cursor: 'pointer',
  display: 'grid',
  columnGap: 5,
  borderBottom: '1px solid #eee',
  gridTemplateColumns: '50px 1fr 70px 70px 70px 65px 65px 65px 65px 65px 65px 65px 65px 65px 65px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '50px 220px 70px 70px 70px 65px 65px 65px 65px 65px 65px 65px 65px 65px 65px',
  },
  '&.header': {
    '& > span': {
      cursor: 'pointer',
      gap: 5,
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      '&.center': {
        justifyContent: 'center',
      },
    },
  },
  '& > h6': {
    padding: 5,
    gap: 5,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    '&.center': {
      justifyContent: 'center',
    },
    '&:not(:last-child)': {
      borderRight: '1px solid #eee',
    },
  },
  '& > span': {
    '& > h6': {
      padding: 5,
      gap: 5,
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      '&.center': {
        justifyContent: 'center',
      },
    },
    '&:not(:last-child)': {
      borderRight: '1px solid #eee',
    },
  },
  '& .product-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('table')({
  display: 'grid',
  paddingBottom: 20,
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 5,
  '& > span, h6': {
    fontSize: 12,
  },
});

const SuggestionSelectedProduct: React.FC<SuggestionSelectedProductProps> = ({
  onExited,
  selectedProduct,
  products,
  branches,
}) => {
  const { rowsPerPage, page } = usePagination();
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<AggregatedProduct[]>([]);

  useEffect(() => {
    setFiltered(products);
  }, [products]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <Dialog onExited={onExited} maxWidth="lg" title={`Lista de Produtos - ${selectedProduct.subgrupo}`}>
      <Container>
        {products.length > 0 ? (
          <List>
            <TR className="header">
              <span>
                <Column onClick={() => handleSort('id_produto')}>
                  <Typography align="center" variant="subtitle2">
                    Cod
                  </Typography>
                  <Typography align="center" variant="subtitle2">
                    Prod
                  </Typography>
                </Column>
                {orderedIndex.index === 'id_produto' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </span>

              <span>
                <Typography onClick={() => handleSort('produto')} variant="subtitle2">
                  Produto
                </Typography>
                {orderedIndex.index === 'produto' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </span>

              <span className="center">
                <Column onClick={() => handleSort('estoque_total')}>
                  <Typography color="primary" align="center" variant="subtitle2">
                    Est
                  </Typography>
                  <Typography color="primary" align="center" variant="subtitle2">
                    Total
                  </Typography>
                </Column>
                {orderedIndex.index === 'estoque_total' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </span>

              <span className="center">
                <Column onClick={() => handleSort('forecast_total')}>
                  <Typography color="primary" align="center" variant="subtitle2">
                    For
                  </Typography>
                  <Typography color="primary" align="center" variant="subtitle2">
                    Total
                  </Typography>
                </Column>
                {orderedIndex.index === 'forecast_total' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </span>

              <span className="center">
                <Column onClick={() => handleSort('sugestao_total')}>
                  <Typography color="primary" align="center" variant="subtitle2">
                    Sug
                  </Typography>
                  <Typography color="primary" align="center" variant="subtitle2">
                    Total
                  </Typography>
                </Column>
                {orderedIndex.index === 'sugestao_total' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </span>

              {branches.map(branch => (
                <span key={branch} className="center">
                  <Column onClick={() => handleSort(`est_${branch.toLowerCase()}`)}>
                    <Typography align="center" variant="subtitle2">
                      Est
                    </Typography>
                    <Typography align="center" variant="subtitle2">
                      {branch}
                    </Typography>
                  </Column>
                  {orderedIndex.index === `est_${branch.toLowerCase()}` && (
                    <>
                      {orderedIndex.direction === 'asc' ? (
                        <ArrowUpward color="primary" />
                      ) : (
                        <ArrowDownward color="primary" />
                      )}
                    </>
                  )}
                </span>
              ))}
            </TR>

            {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, index) => (
              <TR key={index}>
                <Typography variant="subtitle2">{product.id_produto}</Typography>
                <Tooltip title={product.produto}>
                  <Typography className="product-name" variant="subtitle2">
                    {product.produto}
                  </Typography>
                </Tooltip>

                <Typography color="primary" variant="subtitle2" className="center">
                  {product.estoque_total}
                </Typography>
                <Typography color="primary" variant="subtitle2" className="center">
                  {product.forecast_total}
                </Typography>
                <Typography color="primary" variant="subtitle2" className="center">
                  {product.sugestao_total}
                </Typography>
                {branches.map(branch => (
                  <Typography key={branch} className="center" variant="subtitle2">
                    {product[`est_${branch.toLowerCase()}`]}
                  </Typography>
                ))}
              </TR>
            ))}
          </List>
        ) : (
          <NoData message="Nenhum produto encontrado para o subgrupo selecionado" />
        )}
      </Container>
    </Dialog>
  );
};

export default SuggestionSelectedProduct;
