import { Done } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

interface RentalNewAppButtonsProps {
  handleValidation(): void;
}

const RentalNewAppbarButtons: React.FC<RentalNewAppButtonsProps> = ({ handleValidation }) => {
  return (
    <IconButton color="inherit" onClick={handleValidation}>
      <Done />
    </IconButton>
  );
};

export default RentalNewAppbarButtons;
