import React, { useState, MouseEvent } from 'react';
import { Typography } from '@mui/material';
import AccountAgentLetter from './AccountAgentLetter';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    height: 215,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  button: {
    marginTop: 20,
  },
});

const AccountAgentDownloadLetter: React.FC = () => {
  const classes = styles();
  const [letter, setLetter] = useState(false);

  function handleSetLetter(e: MouseEvent<HTMLAnchorElement>, value: boolean) {
    e.preventDefault();
    setLetter(value);
  }

  return (
    <div className={classes.container}>
      {letter && <AccountAgentLetter onExited={() => setLetter(false)} />}
      <Typography gutterBottom>Agora, você precisa imprimir a carta de preposição.</Typography>
      <Typography gutterBottom>Depois de impresso, assine e nos envie no próximo passo.</Typography>
      <a href="/" className={classes.button} onClick={e => handleSetLetter(e, true)}>
        imprimir carta de preposição
      </a>
    </div>
  );
};

export default AccountAgentDownloadLetter;
