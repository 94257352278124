import { IconButton, Tooltip } from '@mui/material';
import { Print } from '@mui/icons-material';
import React from 'react';

const StockBalanceReportActions: React.FC = () => {
  return (
    <Tooltip title="Imprimir">
      <IconButton color="inherit" onClick={() => window.print()}>
        <Print />
      </IconButton>
    </Tooltip>
  );
};

export default StockBalanceReportActions;
