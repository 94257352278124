import React from 'react';
import { List } from '@mui/material';
import { usePagination } from 'hooks/pagination';

import { SellerCommission } from 'types/reports/sellerCommission';
import SellersCommissionItemModule from './SellersCommissionItemModule';

import { makeStyles } from '@mui/styles';

type SellersCommissionListModuleProps = {
  sellersCommission: SellerCommission[];
};

const styles = makeStyles({
  list: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: 100,
    flex: 1,
  },
});

const SellersCommissionListModule: React.FC<SellersCommissionListModuleProps> = ({ sellersCommission }) => {
  const { rowsPerPage, page } = usePagination();
  const classes = styles();

  return (
    <List className={classes.list}>
      {sellersCommission.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(sellerCommission => (
        <SellersCommissionItemModule key={sellerCommission.pedido_por} sellerCommission={sellerCommission} />
      ))}
    </List>
  );
};

export default SellersCommissionListModule;
