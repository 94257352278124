import { Dispatch, SetStateAction, useState } from 'react';
import { SliderBanner } from 'types/sliderBanner';
import * as yup from 'yup';

export type SiteBannerValidation = {
  name?: string;
  title?: string;
  subtitle?: string;
  image?: string;
  mobile_image?: string;
  link?: string;
  order?: string;
};

type UseSiteBannerValidation = [
  SiteBannerValidation,
  Dispatch<SetStateAction<SiteBannerValidation>>,
  (banner: SliderBanner) => Promise<void>,
];

export function useSiteBannerValidation(): UseSiteBannerValidation {
  async function handleValidation(banner: SliderBanner) {
    const schema = yup.object().shape({
      mobile_image: yup.object().required('O mobile banner é obrigatório').nullable(),
      image: yup.object().required('O banner é obrigatório').nullable(),
      order: yup.string().required('A ordem é obrigatória'),
      link: yup.string().url('Informe um link válido').required('O link é obrigatório'),
      subtitle: yup.string().required('A descrição é obrigatória'),
      title: yup.string().required('A descrição é obrigatória'),
      name: yup.string().required('O nome do banner é obrigatória'),
    });

    try {
      await schema.validate(banner);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<SiteBannerValidation>({});
  return [validation, setValidation, handleValidation];
}
