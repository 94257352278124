import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useRelatedProducts } from '../hooks/useRelatedProducts';

type RelatedProductListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const RelatedProductListMenu: React.FC<RelatedProductListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { handleDelete } = useRelatedProducts();

  function handleDeleteClick() {
    handleDelete();

    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default RelatedProductListMenu;
