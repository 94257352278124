import { TableTemplate } from 'types/tableTemplate';

export const resultTableTemplate: TableTemplate[] = [
  {
    id: 'action',
    description: '',
    originalId: 'action',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 160,
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'valor',
    width: 150,
    dataType: 'number',
  },
];
