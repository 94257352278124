import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'services/api';
import history from 'services/history';
import TemplateActions from './TemplateActions';
import TemplateForm from '../TemplateForm';
import { useTemplateValidation } from '../validation/templateValidation';
import { Template } from 'types/template';

const TemplateUpdate: React.FC = () => {
  const [template, setTemplate] = useState<Template>({} as Template);
  const [validation, setValidation, validate] = useTemplateValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/cardTemplates/${id}`)
      .then(response => {
        setTemplate(response.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function handleChange(index: keyof Template, value: any) {
    if (index === 'image') {
      setTemplate(state => ({
        ...state,
        image: value,
        image_id: value ? value.id : null,
      }));
      return;
    }

    setTemplate(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(template)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/cardTemplates/${id}`, template)
      .then(() => {
        setSaving(false);
        history.push('/site/templates');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete(`/cardTemplates/${id}`)
      .then(() => {
        setSaving(false);
        history.push('/site/templates');
        handleOpen('Excluído');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Template"
        ActionsComponent={
          <TemplateActions
            saving={saving}
            loading={loading}
            handleSubmit={handleValidation}
            handleDelete={handleDelete}
          />
        }
        backAction={() => history.push('/site/templates')}
      />
      <PageHeader title="Editar template" backUrl="/site/templates" />
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          <form onSubmit={handleValidation}>
            <TemplateForm handleChange={handleChange} template={template} validation={validation} />
          </form>
        </Grid>
      )}
    </>
  );
};

export default TemplateUpdate;
