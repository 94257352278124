import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Category } from 'types/category';

interface CategoryContextValue {
  selectedCategory: Category | null;
  setSelectedCategory: Dispatch<SetStateAction<Category | null>>;
}

const CategoryContext = createContext<CategoryContextValue>({} as CategoryContextValue);
export const CategoryConsumer = CategoryContext.Consumer;
export const CategoryProvider = CategoryContext.Provider;

export function useCategory(): CategoryContextValue {
  return useContext(CategoryContext);
}
