import { useApp } from 'hooks/app';
import { Dispatch, SetStateAction, useState } from 'react';
import { CfopH2i } from 'types/cfop';
import { PurchaseOrderProductFormContent } from 'types/purchaseOrder';
import * as yup from 'yup';

export type PurchaseOrderProductValidation = {
  productId?: string;
  product?: string;
  value?: string;
  quantity?: string;
  cfop?: string;
};

type UsePurchaseOrderProductValidation = [
  PurchaseOrderProductValidation,
  Dispatch<SetStateAction<PurchaseOrderProductValidation>>,
  (product: PurchaseOrderProductFormContent) => Promise<void>,
];

export function usePurchaseOrderProductValidation(): UsePurchaseOrderProductValidation {
  const { h2iApi } = useApp();
  async function handleValidation(product: PurchaseOrderProductFormContent) {
    const schema = yup.object().shape({
      cfop: yup
        .string()
        .required('O CFOP do produto é obrigatório')
        .test('cfopValidation', 'CFOP inválido', async value => {
          if (!value) return false;

          if (value.length !== 4) return false;

          if (!h2iApi) return false;

          const response = await h2iApi.get('/api/getcfop');
          const _response: CfopH2i[] = response.data.cfop;

          if (!_response) return false;

          const cfops = _response.map(item => item.cfop.replace(',', ''));

          if (cfops.includes(value)) {
            return true;
          } else {
            return false;
          }
        }),
      quantity: yup
        .string()
        .required('obrigatório')
        .test('quantityValidation', 'a quantidade precisa ser maior que 0', value => {
          if (!value) return false;
          if (isNaN(parseInt(value))) return false;

          if (parseInt(value) <= 0) return false;

          return true;
        })
        .nullable(),
      value: yup.string().required('O valor do produto é obrigatório'),
      productId: yup.string().required('O código do produto é obrigatório').nullable(),
      product: yup.string().required('O nome do produto é obrigatório'),
    });

    try {
      await schema.validate(product);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<PurchaseOrderProductValidation>({});
  return [validation, setValidation, handleValidation];
}
