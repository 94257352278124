import { createContext, useContext } from 'react';
import { Page } from 'types/page';

type PagesContextValue = {
  selectedPage: Page | null;
  setSelectedPage(banner: Page | null): void;
};

const PagesContext = createContext<PagesContextValue>({} as PagesContextValue);
export const PagesProvider = PagesContext.Provider;

export function usePages(): PagesContextValue {
  const context = useContext(PagesContext);
  return context;
}
