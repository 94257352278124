import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import PageHeader from 'components/page-header/PageHeader';
import React from 'react';

const ConstructionPage: React.FC = () => {
  return (
    <div>
      <Appbar title="Página em construção" />
      <PageHeader title="Página em construção" />

      <NoData message="Em breve essa página estará disponível" />
    </div>
  );
};

export default ConstructionPage;
