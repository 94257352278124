import React from 'react';
import { List, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import PaymentItemModule from './PaymentItemModule';
import { PaymentMethod } from 'types/salesReport';

type PaymentMethodListModuleProps = {
  payments: PaymentMethod[];
};

const CustomList = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  gap: 5,
  flex: 1,
});

const PaymentMethodListModule: React.FC<PaymentMethodListModuleProps> = ({ payments }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <CustomList>
      {payments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(payment => (
        <PaymentItemModule key={payment.forma} payment={payment} />
      ))}
    </CustomList>
  );
};

export default PaymentMethodListModule;
