import { createContext, useContext } from 'react';
import { CreditLimitRequest, NewCreditLimitRequestDocument } from 'types/creditLimitRequest';

type CreditLimitRequestContextValue = {
  creditLimitRequest: CreditLimitRequest | null;
  handleChange(index: keyof CreditLimitRequest, value: any): void;
  limitRequests: CreditLimitRequest[];
  handleAddDocument(document: NewCreditLimitRequestDocument): void;
  handleDeleteDocument(documentId: number): void;
};

const CreditLimitRequestContext = createContext<CreditLimitRequestContextValue>({} as CreditLimitRequestContextValue);
export const CreditLimitRequestProvider = CreditLimitRequestContext.Provider;

export function useCreditLimitRequest(): CreditLimitRequestContextValue {
  const context = useContext(CreditLimitRequestContext);
  return context;
}
