import React from 'react';
import { AbcProductSubgroup } from 'types/abcProduct';
import { Typography, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type SubgroupItemModuleProps = {
  subgroup: AbcProductSubgroup;
};

const SubgroupItemModule: React.FC<SubgroupItemModuleProps> = ({ subgroup }) => {
  const classes = useStyles();

  return (
    <>
      <ListItem button className={classes.listItem}>
        <Typography variant="caption" color="primary">
          Código - {subgroup.cod}
        </Typography>
        <Typography gutterBottom>Quantidade - {subgroup.qtd}</Typography>
        <Typography gutterBottom>Unitário - {subgroup.formattedUnitary}</Typography>
        <Typography gutterBottom>Valor total - {subgroup.formattedTotal}</Typography>
        <Typography gutterBottom>SPIFF - {subgroup.formattedSpiff}</Typography>
        <Typography gutterBottom>MC - {subgroup.formattedMc}</Typography>
        <Typography gutterBottom>Custo - {subgroup.formattedCoust}</Typography>
      </ListItem>
    </>
  );
};

export default SubgroupItemModule;
