import { Typography, styled } from '@mui/material';
import NoData from 'components/no-data/NoData';
import React, { useEffect, useState, useMemo } from 'react';
import { RentalPaymentProvider } from './hooks/useRentalPayment';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import CostListTable from './list/table/RentaPaymentListTable';
import CostListModule from './list/module/RentalPaymentListModule';
import ProjectContactForm from './RentalPaymentsForm';
import { useRentalPaymentValidation } from './validation/rentalPaymentValidation';
import { RentalPaymentFormContent, RentalPayment } from 'pages/rentals/types/rental';

import { addRentalPaymentAction } from 'pages/rentals/reducer/actions';
import { useRental } from '../hooks/useRental';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: 30,
  '& .subtitle': {
    marginBottom: 30,
  },
});

const RentalPayments: React.FC = () => {
  const { isMobile, windowWidth } = useApp();
  const { rental, dispatch } = useRental();
  const [filteredCosts, setFilteredCosts] = useState<RentalPayment[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const displayMode = useMemo(() => (isMobile || windowWidth < 930 ? 'module' : 'list'), [isMobile, windowWidth]);
  const [validation, setValidation, validate] = useRentalPaymentValidation();
  const [selectedRentalPayment, setSelectedRentalPayment] = useState<RentalPayment | null>(null);

  useEffect(() => {
    setFilteredCosts(rental.payments);
  }, [rental.payments]);

  function handleSort(index: string) {
    const _contacts = sort(index, filteredCosts);
    setFilteredCosts(_contacts);
  }

  async function handleValidation(data: RentalPaymentFormContent) {
    setValidation({});

    await validate(data);
    handleSubmit(data);
  }

  function handleSubmit(data: RentalPaymentFormContent) {
    dispatch(addRentalPaymentAction(data));
  }

  return (
    <Container>
      <Typography color="primary" className="subtitle" variant="h6">
        Pagamentos
      </Typography>

      <ProjectContactForm validation={validation} handleSubmit={handleValidation} />

      {filteredCosts.length === 0 ? (
        <NoData message="Nenhum custo para mostrar" />
      ) : (
        <RentalPaymentProvider value={{ selectedRentalPayment, setSelectedRentalPayment }}>
          {displayMode === 'list' ? (
            <CostListTable payments={filteredCosts} handleSort={handleSort} orderedIndex={orderedIndex} />
          ) : (
            <CostListModule payments={filteredCosts} />
          )}
        </RentalPaymentProvider>
      )}
    </Container>
  );
};

export default RentalPayments;
