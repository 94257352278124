import React from 'react';
import { useTable } from 'components/table/hook/useTable';
import { Tooltip, Typography, styled } from '@mui/material';
import { StockItemList } from 'types/stockItem';

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
  width: 350,
});

interface StockItemsItemTableProps {
  data: StockItemList;
}

const StockItemsItemTable: React.FC<StockItemsItemTableProps> = ({ data }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div
            key={item.id}
            className={
              item.dataType === 'number' ? 'numericData' : item.dataType === 'quantity' ? 'quantity' : undefined
            }
          >
            <Tooltip title={data[item.id]}>
              <Description variant="caption">{data[item.id]}</Description>
            </Tooltip>
          </div>
        ))}
    </>
  );
};

export default StockItemsItemTable;
