import { Settings } from 'types/settings';
import { SettingsActionsType, SET_SETTINGS } from './types';

const INITIAL_STATE: Settings | null = null;

export default function settingsReducer(state = INITIAL_STATE, action: SettingsActionsType): Settings | null {
  switch (action.type) {
    case SET_SETTINGS: {
      return action.settings;
    }

    default: {
      return state;
    }
  }
}
