import React, { Dispatch, SetStateAction } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import { MdSearch } from 'react-icons/md';
import SelectBranch from 'components/select-branches/SelectBranch';

import { makeStyles } from '@mui/styles';

interface ReprintSaleFilterBoxProps {
  branchId: number;
  setBranchId: Dispatch<SetStateAction<number>>;
  setSaleId: Dispatch<SetStateAction<number | null>>;
  saleId: number | null;
  handleSearchSubmit(): void;
}

const useStyles = makeStyles({
  filter: {
    gap: 10,
    flex: 1,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1.3fr',
  },
  select: {
    height: '100%',
  },
});

const ReprintSaleFilterBox: React.FC<ReprintSaleFilterBoxProps> = ({
  branchId,
  saleId,
  setBranchId,
  setSaleId,
  handleSearchSubmit,
}) => {
  const classes = useStyles();

  return (
    <FilterBox>
      <div className={classes.filter}>
        <SelectBranch branch_id={branchId} handleChange={e => setBranchId(e)} />

        <form onSubmit={handleSearchSubmit}>
          <TextField
            label="Número da venda"
            placeholder="Digite o nº da venda"
            autoFocus
            value={saleId}
            onChange={e => setSaleId(parseInt(e.target.value))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <MdSearch color="#888" size={20} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              inputMode: 'numeric',
              step: 1,
            }}
            type="number"
          />
        </form>
      </div>
    </FilterBox>
  );
};

export default ReprintSaleFilterBox;
