import React from 'react';
import { IconButton, TextField } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import { AppropriationDateParams } from './AppropriationDate';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  filter: {
    display: 'flex',
    alignItems: 'baseline',
  },
  actions: {
    display: 'flex',
  },
});

type AppropriationDateFilterBoxProps = {
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
  loadAppropriationDates(): void;
  params: AppropriationDateParams;
  handleChangeParams(index: keyof AppropriationDateParams, value: any): void;
};

const AppropriationDateFilterBox: React.FC<AppropriationDateFilterBoxProps> = ({
  setDisplayMode,
  displayMode,
  loadAppropriationDates,
  params,
  handleChangeParams,
}) => {
  const classes = useStyles();

  return (
    <FilterBox>
      <div className={classes.filter}>
        <TextField
          label="Filtro"
          fullWidth
          margin="none"
          autoFocus
          value={params.filtro}
          onChange={e => handleChangeParams('filtro', e.target.value)}
        />
      </div>
      <div className={classes.actions}>
        <IconButton onClick={loadAppropriationDates}>
          <Refresh color="primary" />
        </IconButton>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </div>
    </FilterBox>
  );
};

export default AppropriationDateFilterBox;
