import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

const CustomTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  '& button': {
    color: '#fff',
    textTransform: 'none',
  },
}));

interface SalesReportTabsProps {
  tab: string;
  onChange(tab: string): void;
  disabled: boolean;
}

const SalesReportTabs: React.FC<SalesReportTabsProps> = ({ onChange, tab, disabled }) => {
  return (
    <CustomTabs value={tab} onChange={(e, value) => onChange(value)}>
      <Tab value="report" label="Relação" />
      <Tab value="dashboard" label="Dashboard" />
    </CustomTabs>
  );
};

export default SalesReportTabs;
