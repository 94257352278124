import { format } from 'date-fns';
import { AbcProductDates } from '../types';
import ptBR from 'date-fns/locale/pt-BR';

interface FormattedDatesToCompare {
  initialDate: string;
  finalDate: string;
  toCompareInitialDate: string;
  toCompareFinalDate: string;
}

export function formatDates(dates: AbcProductDates): FormattedDatesToCompare {
  const initialDate = format(dates.initialDate, 'P', { locale: ptBR });
  const finalDate = format(dates.finalDate, 'P', { locale: ptBR });

  const toCompareInitialDate = format(dates.initialDateToCompare, 'P', { locale: ptBR });
  const toCompareFinalDate = format(dates.finalDateToCompare, 'P', { locale: ptBR });

  return {
    initialDate,
    finalDate,
    toCompareInitialDate,
    toCompareFinalDate,
  };
}
