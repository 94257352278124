import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useWaste } from '../../hooks/useWaste';
import { removeWasteProductAction } from 'pages/material-residues/waste/reducer/actions';
import { useProducts } from '../hooks/useProducts';

type WasteProductsListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  onExited(): void;
};

const WasteProductsListMenu: React.FC<WasteProductsListMenuProps> = ({ anchorEl, onExited }) => {
  const { selectedProduct } = useProducts();
  const { dispatch } = useWaste();

  function handleDeleteClick() {
    if (!selectedProduct) {
      return;
    }

    dispatch(removeWasteProductAction(selectedProduct.id));
    onExited();
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onExited}>
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default WasteProductsListMenu;
