import React from 'react';
import { usePurchaseOrder } from '../hook/usePurchaseOrder';
import PurchaseOrderThirdStep from './third/PurchaseOrderThirdStep';
import PurchaseOrderSecondStep from './second/PurchaseOrderSecondStep';
import PurchaseOrderFirstStep from './first/PurchaseOrderFirstStep';
import { Typography } from '@mui/material';
import PurchaseOrderFourthStep from './fourth/PurchaseOrderFourthStep';
import PurchaseOrderFifthStep from './fifth/PurchaseOrderFifthStep';

const StepsPurchaseOrder: React.FC = () => {
  const { step } = usePurchaseOrder();

  return (
    <>
      <Typography variant="caption">Etapa {step}</Typography>
      {step === 1 ? (
        <PurchaseOrderFirstStep />
      ) : step === 2 ? (
        <PurchaseOrderSecondStep />
      ) : step === 3 ? (
        <PurchaseOrderThirdStep />
      ) : step === 4 ? (
        <PurchaseOrderFourthStep />
      ) : (
        <PurchaseOrderFifthStep type="new" />
      )}
    </>
  );
};

export default StepsPurchaseOrder;
