import { TableTemplate } from 'types/tableTemplate';

export const promotionalBannersTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
    notFilterable: true,
  },
  {
    id: 'name',
    description: 'NOME',
    originalId: 'name',
    width: 200,
  },

  {
    id: 'formattedActive',
    description: 'ATIVO',
    originalId: 'formattedActive',
    width: 100,
  },

  {
    id: 'link',
    description: 'LINK',
    originalId: 'link',
    width: 450,
  },

  {
    id: 'formattedCreatedAt',
    description: 'DATA',
    originalId: 'formattedCreatedAt',
    width: 150,
    notSearchable: true,
  },
];
