import React, { FormEvent, useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import CourseForm from '../CourseForm';
import { useCourseValidation } from '../validation/courseValidation';
import PartnerActions from './CourseActions';
import { Course } from 'types/course';

const CourseNew: React.FC = () => {
  const [course, setCourse] = useState<Course>({
    ...({} as Course),
    active: true,
    happen_at: new Date(),
  });
  const [validation, setValidation, validate] = useCourseValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();

  const handleChange = useCallback((index: keyof Course, value: any) => {
    setCourse(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(course)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/courses', course)
      .then(() => {
        setSaving(false);
        history.push('/courses');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Site"
        ActionsComponent={<PartnerActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/courses')}
      />

      <PageHeader title="Novo curso" backUrl="/courses" />

      <Grid container>
        <form onSubmit={handleValidation}>
          <CourseForm handleChange={handleChange} course={course} validation={validation} />
        </form>
      </Grid>
    </>
  );
};

export default CourseNew;
