import { Waste, WasteProductFormContent } from '../types/waste';
import { WasteActions } from './types';

export function changeWasteAction(index: keyof Waste, value: any): WasteActions {
  return {
    type: 'CHANGE_WASTE',
    index,
    value,
  };
}

export function setWasteAction(waste: Waste): WasteActions {
  return {
    type: 'SET_WASTE',
    waste,
  };
}

export function addWasteProduct(product: WasteProductFormContent): WasteActions {
  return {
    type: 'ADD_WASTE_PRODUCT',
    product,
  };
}

export function removeWasteProductAction(id: number): WasteActions {
  return {
    type: 'REMOVE_WASTE_PRODUCT',
    id,
  };
}
