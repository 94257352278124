import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Typography, styled } from '@mui/material';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import TransferRequestsItemTable from './TransferRequestsItemTable';
import { TransferRequest } from 'types/transferRequest';
import { transferRequestsTableTemplate } from '../transferRequestsTableTemplate';
import useTableOrder from 'hooks/tableOrder';

interface TransferRequestsListTableProps {
  transfers: TransferRequest[];
}

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

const TransferRequestsListTable: React.FC<TransferRequestsListTableProps> = ({ transfers }) => {
  const { rowsPerPage, page } = usePagination();
  const [filtered, setFiltered] = useState<TransferRequest[]>([]);
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(transfers);
  }, [transfers]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <TableContent useMinHeight={false}>
      <TableHeader>
        {transferRequestsTableTemplate.map(item => (
          <HeaderItem
            key={item.id}
            className={item.dataType === 'number' ? 'numericData' : undefined}
            onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
          >
            <Typography variant="caption" color="primary">
              {item.description}
            </Typography>
            {orderedIndex.index === item.originalId && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </HeaderItem>
        ))}
      </TableHeader>
      <TableBody useMaxHeight maxHeight={400}>
        {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((transfer, index) => (
          <TableRow key={index}>
            <TransferRequestsItemTable transfer={transfer} />
          </TableRow>
        ))}
      </TableBody>
    </TableContent>
  );
};

export default TransferRequestsListTable;
