import React, { useEffect, useRef } from 'react';
import { Grid, TextField } from '@mui/material';
import { BranchValidation } from './validation/branchValidation';
import { Branch } from 'types/siteBranch';
import PhoneInput from 'components/masked-input/PhoneInput';

type BranchFormProps = {
  branch: Branch;
  handleChange(index: keyof Branch, value: any): void;
  validation: BranchValidation;
};

const BranchForm: React.FC<BranchFormProps> = ({ branch, handleChange, validation }) => {
  const inputs = {
    name: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    address: useRef<HTMLInputElement>(null),
    embed_map: useRef<HTMLInputElement>(null),
    latitude: useRef<HTMLInputElement>(null),
    longitude: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      <Grid item xs={12} xl={4} lg={4} md={6}>
        <TextField
          inputRef={inputs.name}
          error={!!validation.name}
          helperText={validation.name}
          label="Nome"
          placeholder="Digite um nome para o banner"
          margin="normal"
          fullWidth
          value={branch.name}
          onChange={e => handleChange('name', e.target.value)}
          autoFocus
        />
        <TextField
          inputRef={inputs.phone}
          error={!!validation.phone}
          helperText={validation.phone}
          label="Telefone"
          placeholder="Digite o número do telefone"
          margin="normal"
          fullWidth
          value={branch.phone}
          onChange={e => handleChange('phone', e.target.value)}
          InputProps={{
            inputComponent: PhoneInput as any,
          }}
        />
        <TextField
          inputRef={inputs.email}
          error={!!validation.email}
          helperText={validation.email}
          label="E-mail"
          placeholder="Digite o e-mail de contato"
          margin="normal"
          fullWidth
          value={branch.email}
          onChange={e => handleChange('email', e.target.value)}
        />
        <TextField
          inputRef={inputs.address}
          error={!!validation.address}
          helperText={validation.address}
          label="Endereço"
          placeholder="Digite o endereço da filial"
          margin="normal"
          fullWidth
          value={branch.address}
          onChange={e => handleChange('address', e.target.value)}
        />
        <TextField
          inputRef={inputs.latitude}
          error={!!validation.latitude}
          helperText={
            'Encontre a latitude e longitude em https://www.latlong.net/convert-address-to-lat-long.html' ||
            validation.latitude
          }
          label="Latitude"
          placeholder="Informe a latitude"
          margin="normal"
          fullWidth
          value={branch.latitude}
          onChange={e => handleChange('latitude', e.target.value)}
        />
        <TextField
          inputRef={inputs.longitude}
          error={!!validation.longitude}
          helperText={
            'Encontre a latitude e longitude em https://www.latlong.net/convert-address-to-lat-long.html' ||
            validation.longitude
          }
          label="Longitude"
          placeholder="Informe a longitude"
          margin="normal"
          fullWidth
          value={branch.longitude}
          onChange={e => handleChange('longitude', e.target.value)}
        />
        <TextField
          inputRef={inputs.embed_map}
          error={!!validation.embed_map}
          helperText={validation.embed_map}
          label="Mapa"
          placeholder="Código do mapa"
          margin="normal"
          fullWidth
          value={branch.embed_map}
          onChange={e => handleChange('embed_map', e.target.value)}
        />
        <button type="submit" style={{ display: 'none' }} />
      </Grid>
    </>
  );
};

export default BranchForm;
