import React, { useState } from 'react';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';
import { useFinancialResume } from '../hooks/useFinancialResume';
import { endOfMonth, setMonth, setYear, startOfMonth } from 'date-fns';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';

const FilterBox = styled('div')(props => ({
  display: 'flex',
  padding: 20,
  backgroundColor: '#fff',
  marginBottom: 15,
  marginTop: 15,
  gap: 20,
  flexDirection: 'row',
  flexWrap: 'wrap',
  [props.theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    gap: 0,
  },
}));

const Filter = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '200px minmax(50px,200px) minmax(100px,200px) minmax(100px,200px)',
  gap: 10,
  [theme.breakpoints.down('md')]: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .hide-on-mobile': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Actions = styled('div')(props => ({
  display: 'flex',
  alignItems: 'flex-end',
}));

const ButtonGroup = styled('div')(props => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: 5,
  '& button': {
    borderColor: `${props.theme.palette.primary.light}!important`,
    width: 50,
  },
}));

const months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];

const FinancialResumeFilterBox: React.FC = () => {
  const { filter, handleFilterChange, loading, handleSubmit, ordination, setOrdination } = useFinancialResume();
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);

  function setDates(month: number) {
    const initialDate = setYear(startOfMonth(setMonth(new Date(), month)), filter.year.getFullYear());
    const finalDate = setYear(endOfMonth(setMonth(new Date(), month)), filter.year.getFullYear());

    handleSubmit(initialDate, finalDate);
  }

  const actions = {
    jan: () => setDates(0),
    fev: () => setDates(1),
    mar: () => setDates(2),
    abr: () => setDates(3),
    mai: () => setDates(4),
    jun: () => setDates(5),
    jul: () => setDates(6),
    ago: () => setDates(7),
    set: () => setDates(8),
    out: () => setDates(9),
    nov: () => setDates(10),
    dez: () => setDates(11),
  };

  function handleChangeDates(month: keyof typeof actions) {
    actions[month]();
    setSelectedMonth(month);
  }

  return (
    <FilterBox>
      <Filter>
        <SelectOthersBranch
          margin="dense"
          branch_id={filter.branchId}
          handleChange={value => handleFilterChange('branchId', value)}
          className="hide-on-mobile"
        />

        <DesktopDatePicker
          label="Ano"
          views={['year']}
          value={filter.year}
          className="hide-on-mobile"
          onChange={date => handleFilterChange('year', date)}
          localeText={{
            clearButtonLabel: 'Limpar',
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />

        <TextField
          select
          label="Ordernação"
          value={ordination.field}
          onChange={event => setOrdination(state => ({ ...state, field: event.target.value as any }))}
          fullWidth
          className="hide-on-mobile"
        >
          <MenuItem value="classificacao">Classificação</MenuItem>
          <MenuItem value="valor">Valor</MenuItem>
        </TextField>

        <TextField
          select
          label="Direção"
          value={ordination.direction}
          onChange={event => setOrdination(state => ({ ...state, direction: event.target.value as any }))}
          fullWidth
          className="hide-on-mobile"
        >
          <MenuItem value="asc">Menores primeiro</MenuItem>
          <MenuItem value="desc">Maiores primeiro</MenuItem>
        </TextField>
      </Filter>
      <Actions>
        <ButtonGroup>
          {months.map(month => (
            <Button
              color={month === selectedMonth ? 'primary' : undefined}
              variant={month === selectedMonth ? 'contained' : 'outlined'}
              onClick={() => handleChangeDates(month as keyof typeof actions)}
              disabled={loading}
              key={month}
            >
              {month}
            </Button>
          ))}
        </ButtonGroup>
      </Actions>
    </FilterBox>
  );
};

export default FinancialResumeFilterBox;
