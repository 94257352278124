import React from 'react';
import { Typography } from '@mui/material';
import ProductItemTable from './ProductItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { makeStyles } from '@mui/styles';
import { productTableTemplate } from '../productTableTemplate';
import { useProducts } from '../../hooks/useProducts';
import WasteProductsListMenu from '../WasteProductsListMenu';
import { WasteProduct } from 'pages/material-residues/waste/types/waste';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type ProductListTableProps = {
  products: WasteProduct[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const ProductListTable: React.FC<ProductListTableProps> = ({ products, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { setSelectedProduct, anchorEl, setAnchorEl } = useProducts();

  function handleClick(product: WasteProduct) {
    setSelectedProduct(product);
  }

  function onExited() {
    setSelectedProduct(null);
    setAnchorEl(null);
  }

  return (
    <>
      <WasteProductsListMenu anchorEl={anchorEl} onExited={onExited} />
      <TableContainer tableTemplate={productTableTemplate}>
        <TableContent useMinHeight={false}>
          <TableHeader>
            {productTableTemplate.map(item => (
              <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
          </TableHeader>
          <TableBody>
            {products.map((product, index) => (
              <TableRow onClick={() => handleClick(product)} key={index}>
                <ProductItemTable product={product} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default ProductListTable;
