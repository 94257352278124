import React, { useCallback, useEffect, useState } from 'react';
import { Button, styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import TableContainer from 'components/table/TableContainer';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import { api } from 'services/api';
import history from 'services/history';
import { Template } from 'types/template';
import { TemplatesProvider } from './hooks/useTemplates';
import TemplateActions from './TemplateActions';
import TemplateFilterBox from './TemplateFilterBox';
import { templatesTableTemplate } from './templatesTableTemplate';
import TemplateListTable from './list/table/TemplateListTable';
import TemplateListModule from './list/module/TemplateListModule';
import ApiPagination from 'components/pagination/ApiPagination';
import { Paginated } from 'types/paginated';
import TemplateCardMenu from './TemplateCardMenu';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export interface QueryParams {
  term: string;
}

const queryParamsInitialValue: QueryParams = {
  term: '',
};

let timer: NodeJS.Timeout;

const Templates: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const [total, setTotal] = useState(0);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<null | Template>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Template[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const app = useApp();
  const [queryParams, setQueryParams] = useState<QueryParams>(queryParamsInitialValue);

  const fetchBanners = useCallback(
    (query?: QueryParams) => {
      setLoading(true);
      api
        .get<Paginated<Template[]>>('/cardTemplates', {
          params: { ...query, page: page + 1, rows },
        })
        .then(_response => {
          const response = _response.data;
          setTemplates(
            response.data.map(item => {
              item.formattedCreatedAt = format(parseISO(item.created_at || ''), 'PPp', { locale: ptBR });
              item.formattedActive = item.is_active ? 'Sim' : 'Não';
              return item;
            }),
          );
          setTotal(response.total);
        })
        .catch(err => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    },
    [page, rows],
  );

  useEffect(() => {
    setFiltered(templates);
  }, [templates]);

  useEffect(() => {
    fetchBanners(queryParamsInitialValue);
  }, [fetchBanners]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleQueryParamsChange(index: keyof QueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));

    const query = {
      ...queryParams,
      [index]: value,
    };

    clearTimeout(timer);

    if (index !== 'term') {
      fetchBanners(query);
      return;
    }

    if (index === 'term' && value.lenght < 4) {
      return;
    }

    timer = setTimeout(() => fetchBanners(query), 500);
  }

  return (
    <TemplatesProvider value={{ selectedTemplate, setSelectedTemplate }}>
      <Appbar title="Templates" ActionsComponent={<TemplateActions />} />
      <PageHeaderActions
        title="Templates"
        description="Gestão dos templates"
        ActionComponent={
          <>
            <Button size="small" variant="contained" color="primary" onClick={() => history.push('/site/template/new')}>
              Adicionar
            </Button>
          </>
        }
      />
      <TableContainer tableTemplate={templatesTableTemplate}>
        <TemplateFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          handleQueryParamsChange={handleQueryParamsChange}
          queryParams={queryParams}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum template para mostrar" />
        ) : (
          <PaginationProvider>
            <Container>
              <TemplateCardMenu handleSearch={fetchBanners} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
              {displayMode === 'list' ? (
                <TemplateListTable
                  setAnchorEl={setAnchorEl}
                  templates={filtered}
                  handleSort={handleSort}
                  orderedIndex={orderedIndex}
                />
              ) : (
                displayMode === 'module' && <TemplateListModule setAnchorEl={setAnchorEl} templates={filtered} />
              )}
              <ApiPagination
                onChangePage={value => setPage(value)}
                onChangeRowsPerPage={value => setRows(value)}
                count={total}
              />
            </Container>
          </PaginationProvider>
        )}
      </TableContainer>
    </TemplatesProvider>
  );
};

export default Templates;
