import { Button, Theme } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { endOfMonth, format, parseISO, startOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import React, { useCallback, useEffect, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import history from 'services/history';
import { coursesTableTemplate } from './coursesTableTemplate';
import { CoursesProvider } from './hooks/useCourses';
import CourseListModule from './list/module/CourseListModule';
import CourseListTable from './list/table/CourseListTable';
import PartnersActions from './CoursesActions';
import { Course } from 'types/course';
import { moneyFormat } from 'helpers/numberFormat';
import { makeStyles } from '@mui/styles';
import CoursesFilterBox from './CoursesFilterBox';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { Paginated } from 'types/paginated';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

export interface CoursesQueryParams {
  rows: number;
  page: number;
  initial_date: Date;
  final_date: Date;
  term: string;
}

const paramsInitialState: CoursesQueryParams = {
  rows: 20,
  page: 1,
  initial_date: startOfMonth(new Date()),
  final_date: endOfMonth(new Date()),
  term: '',
};

const Courses: React.FC = () => {
  const classes = useStyles();
  const [courses, setCourses] = useState<Course[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<null | Course>(null);
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState<Course[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [params, setParams] = useState<CoursesQueryParams>(paramsInitialState);
  const [displayMode, setDisplayMode] = useDisplayMode();

  const fetch = useCallback(() => {
    const source = getCancelTokenSource();
    const request = true;

    setLoading(true);

    api
      .get<Paginated<Course[]>>('/courses', { cancelToken: source.token, params })
      .then(response => {
        if (request)
          setCourses(
            response.data.data.map(item => {
              item.formattedCreatedAt = format(parseISO(item.created_at), 'P', { locale: ptBR });
              item.formattedActive = item.active ? 'Sim' : 'Não';
              item.formattedPrice = moneyFormat(item.price);
              item.formattedHappenAt =
                typeof item.happen_at === 'string'
                  ? format(new Date(item.happen_at), 'P', { locale: ptBR })
                  : undefined;
              return item;
            }),
          );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (request) setLoading(false);
      });
  }, [params]);

  useEffect(() => {
    setFiltered(courses);
  }, [courses]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleParamsChange(index: keyof CoursesQueryParams, value: any) {
    setParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSubmit() {
    fetch();
  }

  return (
    <CoursesProvider value={{ selectedCourse, setSelectedCourse }}>
      <Appbar title="Site" ActionsComponent={<PartnersActions />} />
      <PageHeaderActions
        title="Cursos"
        description="Gestão dos cursos"
        ActionComponent={
          <>
            <Button size="small" variant="contained" color="primary" onClick={() => history.push('/courses/new')}>
              Adicionar
            </Button>
          </>
        }
      />
      <TableContainer tableTemplate={coursesTableTemplate}>
        <CoursesFilterBox
          queryParams={params}
          handleQueryParamsChange={handleParamsChange}
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          handleSubmit={handleSubmit}
          loading={loading}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum curso para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <CourseListTable courses={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <CourseListModule courses={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </CoursesProvider>
  );
};

export default Courses;
