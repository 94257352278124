import React from 'react';
import { ListItemButton, Typography, styled } from '@mui/material';
import { Expense } from 'types/expense';
import { useExpenses } from '../../hooks/useExpenses';

const StyledListItem = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 120,
  justifyContent: 'space-between',
  flexDirection: 'column',
});

interface ExpenseItemModuleProps {
  expense: Expense;
}

const ExpenseItemModule: React.FC<ExpenseItemModuleProps> = ({ expense }) => {
  const { setSelectedExpense } = useExpenses();

  return (
    <StyledListItem onClick={() => setSelectedExpense(expense)}>
      <Typography color="primary" variant="caption" gutterBottom>
        {expense.nome}
      </Typography>

      <Typography color="primary" variant="caption" gutterBottom>
        Filial {expense.id_filial}
      </Typography>
      <Typography color="primary" variant="caption" gutterBottom>
        Cód Contábil {expense.conta_contabil}
      </Typography>
    </StyledListItem>
  );
};

export default ExpenseItemModule;
