import React, { useEffect, useRef, Dispatch, SetStateAction } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import { Button, IconButton, InputAdornment, TextField, Theme } from '@mui/material';
import { Clear, Search } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { CourseStudentsQueryParams } from './CourseStudents';
import { DisplayModeOptions } from 'hooks/useDisplayMode';

const useStyles = makeStyles<Theme>(theme => ({
  filter: {
    display: 'grid',
    gridTemplateColumns: '400px 1fr',
    columnGap: 10,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      flex: 1,
    },
    '& > .first-column': {
      display: 'flex',
      gap: 10,
    },
    '& > .second-column': {
      display: 'grid',
      gridTemplateColumns: '150px 150px 100px',
      columnGap: 10,
      alignItems: 'baseline',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}));

type CourseStudentsFilterBoxProps = {
  queryParams: CourseStudentsQueryParams;
  handleQueryParamsChange(index: keyof CourseStudentsQueryParams, value: any): void;
  displayMode: DisplayModeOptions;
  setDisplayMode: Dispatch<SetStateAction<DisplayModeOptions>>;
  handleSubmit(): void;
  loading: boolean;
};

const CourseStudentsFilterBox: React.FC<CourseStudentsFilterBoxProps> = ({
  queryParams,
  handleQueryParamsChange,
  setDisplayMode,
  displayMode,
  handleSubmit,
  loading,
}) => {
  const classes = useStyles();
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, []);

  return (
    <FilterBox>
      <div className={classes.filter}>
        <div className="first-column">
          <TextField
            label="Pesquisa"
            placeholder="Nome do cliente"
            value={queryParams.term}
            onChange={e => handleQueryParamsChange('term', e.target.value)}
            autoFocus
            fullWidth
            InputProps={{
              endAdornment: queryParams.term.length ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => handleQueryParamsChange('term', '')}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ) : undefined,
            }}
          />
        </div>

        <div className="second-column">
          <KeyboardDatePicker
            label="Data inicial"
            value={queryParams.initial_date || null}
            onChange={date => handleQueryParamsChange('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data Final"
            value={queryParams.final_date || null}
            onChange={date => handleQueryParamsChange('final_date', date)}
          />

          <Button
            disabled={loading}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Search />}
          >
            buscar
          </Button>
        </div>
      </div>

      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default CourseStudentsFilterBox;
