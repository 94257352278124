import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import PaginationProvider from 'hooks/pagination';
import { useDisplayMode } from 'hooks/useDisplayMode';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { StockItemList } from 'types/stockItem';
import NoData from 'components/no-data/NoData';
import { stockItemsTableTemplate } from './stockItemsTableTemplate';
import StockItemsListTable from './table/StockItemsListTable';
import useTableOrder from 'hooks/tableOrder';

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

interface StockItemsProps {
  items: StockItemList[];
}

const StockItems: React.FC<StockItemsProps> = ({ items }) => {
  const [displayMode] = useDisplayMode();
  const [filtered, setFiltered] = useState<StockItemList[]>([]);
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(items);
  }, [items]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <>
      {filtered.length === 0 ? (
        <NoData message="Conteúdo indisponível" />
      ) : (
        <PaginationProvider>
          <TableContainer tableTemplate={stockItemsTableTemplate}>
            <Content>
              {displayMode === 'list' ? (
                <StockItemsListTable items={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <>
                  {/* <PurchaseOrderListTable orders={filtered} handleSort={handleSort} orderedIndex={orderedIndex} /> */}
                </>
              )}
              <Pagination count={items.length} />
            </Content>
          </TableContainer>
        </PaginationProvider>
      )}
    </>
  );
};

export default StockItems;
