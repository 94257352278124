import { TableTemplate } from 'types/tableTemplate';

export const davReviewTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 70,
  },
  {
    id: 'Data',
    description: 'Data',
    originalId: 'Data',
    width: 100,
  },
  {
    id: 'cliente',
    description: 'Cliente',
    originalId: 'cliente',
    width: 400,
  },
  {
    id: 'Cnpj',
    description: 'Cnpj',
    originalId: 'Cnpj',
    width: 140,
  },
  {
    id: 'telefone',
    description: 'Telefone',
    originalId: 'telefone',
    width: 130,
  },
  {
    id: 'email',
    description: 'E-mail',
    originalId: 'email',
    width: 265,
  },
  {
    id: 'formattedValorTotal',
    description: 'Valor Total',
    originalId: 'valor_total',
    width: 100,
  },
  {
    id: 'formattedMc',
    description: 'MC',
    originalId: 'mc',
    width: 100,
  },
];
