import React, { useState } from 'react';
import { Button, Typography, styled } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { numberFormat } from 'helpers/numberFormat';
import { usePermissionRules } from 'hooks/permissionRules';
import { useDavReviewDetail } from './hooks/useDavReviewDetail';

const Container = styled('div')(({ theme }) => ({
  margin: '40px 0',
  borderLeft: `3px solid ${theme.palette.error.main}`,
  borderRadius: 6,
  paddingLeft: 10,
}));

const Li = styled('li')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  borderBottom: '1px dashed #eee',
  paddingBottom: 10,
  marginBottom: 10,
  flexDirection: 'column',
});

const Ul = styled('ul')({
  marginTop: 20,
  display: 'none',
  '&.open': {
    display: 'grid',
  },
});

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 15,
  cursor: 'pointer',
  paddingBottom: 5,
  borderBottom: '1px dashed #666',
});

const CustomArrowDownward = styled(ArrowDownward)({
  fontSize: 18,
  transform: 'rotate(0deg)',
  transition: 'all 0.3s ease',
  '&.open': {
    transform: 'rotate(180deg)',
  },
});

const ProductTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  gap: 5,
}));

const Span = styled('span')({
  display: 'none',
  '&.open': {
    marginTop: 10,
    display: 'grid',
    gridTemplateColumns: '160px 1fr',
  },
});

const ProductTitle = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  display: 'grid',
  justifyContent: 'space-between',
  gap: 15,
  gridTemplateColumns: '500px 70px 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '230px 70px 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '400px 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Actions = styled('div')({
  display: 'flex',
  gap: 5,
});

const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: 'lowercase',
  marginTop: 5,
  maxHeight: 30,
  borderRadius: 6,
  maxWidth: 200,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: 150,
  },
}));

const CustomLink = styled('a')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const CustomTotalMobile = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const DavReviewDetailProducts: React.FC = () => {
  const { checkPermission } = usePermissionRules();
  const { order } = useDavReviewDetail();
  const [open, setOpen] = useState(false);
  const [openValue, setOpenValue] = useState('');

  return (
    <Container>
      <Row onClick={() => setOpen(!open)}>
        <Typography fontSize={20}>produtos</Typography>
        <CustomArrowDownward className={open ? 'open' : undefined} />
      </Row>
      <Ul className={open ? 'open' : undefined}>
        {order?.itens.map(product => (
          <Li key={product.cod_prod}>
            <ProductTitle onClick={() => setOpenValue(openValue === product.cod_prod ? '' : product.cod_prod)}>
              <ProductTypography variant="body2">
                {product.QTD}x {product.produto}
                <CustomArrowDownward className={openValue === product.cod_prod ? 'open' : undefined} />
              </ProductTypography>
              <CustomLink target="_blank" href={`https://h2i.com.br/produtos/${product.cod_prod}`}>
                <Typography color="#666" fontSize={9} variant="caption">
                  ir até o produto
                </Typography>
              </CustomLink>
              <Typography fontSize={24}>{product.formattedValorTotal}</Typography>
            </ProductTitle>
            <Span className={openValue === product.cod_prod ? 'open' : undefined}>
              <Typography variant="caption" color="#666">
                spiff vendedor
              </Typography>
              <Typography variant="caption" color="#666">
                {product.formattedSpiff}
              </Typography>
              <Typography variant="caption" color="#666">
                spiff fornecedor
              </Typography>
              <Typography variant="caption" color="#666">
                {product.formattedSpiffExtra}
              </Typography>
              <Typography variant="caption" color="#666">
                mc
              </Typography>
              <Typography variant="caption" color="#666">
                {product.formattedMc}
              </Typography>
              <Typography variant="caption" color="#666">
                unitário
              </Typography>
              <Typography variant="caption" color="#666">
                {product.formattedValorUnitario}
              </Typography>
              <Typography variant="caption" color="#666">
                desc gerente
              </Typography>
              <Typography variant="caption" color="#666">
                {product.formattedDescontoGerente}
              </Typography>
              <Typography variant="caption" color="#666">
                desc vendedor
              </Typography>
              <Typography variant="caption" color="#666">
                {product.formattedDescontoVendedor}
              </Typography>
              <Typography variant="caption" color="#666">
                peso
              </Typography>
              <Typography variant="caption" color="#666">
                {numberFormat(product.peso.replace(/,/g, '.'))}
              </Typography>
              <Typography variant="caption" color="#666">
                max parcelas boleto
              </Typography>
              <Typography variant="caption" color="#666">
                {product.max_parcelas_boleto}
              </Typography>
              <Typography variant="caption" color="#666">
                max parcelas cartão
              </Typography>
              <Typography variant="caption" color="#666">
                {product.max_parcelas_cartao}
              </Typography>
              <CustomTotalMobile variant="body2" fontWeight={500} color="#000">
                total - {product.formattedValorTotal}
              </CustomTotalMobile>
            </Span>
            <Actions>
              {checkPermission('commercial.review.dav.discount') && (
                <CustomButton disabled color="error" variant="outlined">
                  desconto
                </CustomButton>
              )}
              <CustomButton disabled color="error" variant="contained">
                alterar
              </CustomButton>
            </Actions>
          </Li>
        ))}
      </Ul>
    </Container>
  );
};

export default DavReviewDetailProducts;
