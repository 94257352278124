import React from 'react';
import { Tab, Tabs as NativeTabs } from '@mui/material';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    // marginBottom: 15,
  },
});

export type OrderTabs = 'payment' | 'items';

type TabsProps = {
  handleChange(value: OrderTabs): void;
  tab: OrderTabs;
};

const Tabs: React.FC<TabsProps> = ({ tab, handleChange }) => {
  const classes = styles();

  return (
    <NativeTabs
      className={classes.container}
      value={tab}
      onChange={(e, value) => handleChange(value)}
      variant="scrollable"
    >
      <Tab label="Itens" value="items" />
      <Tab label="Pagamento" value="payment" />
    </NativeTabs>
  );
};

export default Tabs;
