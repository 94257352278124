import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Customer } from 'types/customers';

type CustomersContextValue = {
  selectedCustomer: Customer | null;
  setSelectedCustomer: Dispatch<SetStateAction<Customer | null>>;
};

const CustomersContext = createContext<CustomersContextValue>({} as CustomersContextValue);
export const CustomersProvider = CustomersContext.Provider;

export function useCustomers(): CustomersContextValue {
  const context = useContext(CustomersContext);
  return context;
}
