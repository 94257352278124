import React from 'react';
import { Checkbox, FormControlLabel, IconButton, MenuItem, TextField, styled } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { BillParams } from './Bills';
import { useBills } from './hooks/useBills';
import { BillSetting } from 'types/billSetting';

const Filter = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'baseline',
  gridTemplateColumns: '0.5fr 1fr',
  columnGap: 10,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '0.4fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    width: '100%',
  },
  '& > .search-params': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

type BillsFilterBoxProps = {
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
  loadBills(): void;
  billets: BillSetting[];
  params: BillParams;
  handleChangeParams(index: keyof BillParams, value: any): void;
};

const BillsFilterBox: React.FC<BillsFilterBoxProps> = ({
  setDisplayMode,
  displayMode,
  loadBills,
  params,
  handleChangeParams,
  billets,
}) => {
  const { loading } = useBills();

  return (
    <FilterBox>
      <Filter>
        <TextField
          select
          label="Layout"
          placeholder="Informe a identificação do banco"
          fullWidth
          margin="normal"
          autoFocus
          value={params.bank_billet_setting_id}
          onChange={e => handleChangeParams('bank_billet_setting_id', e.target.value)}
        >
          {billets.map(bill => (
            <MenuItem key={bill.id} value={bill.id}>
              {bill.bank_name}
            </MenuItem>
          ))}
        </TextField>
        <div className="search-params">
          <KeyboardDatePicker
            label="Emissão inicial"
            value={params.initial_date}
            onChange={date => handleChangeParams('initial_date', date)}
            textFieldProps={{ margin: 'none' }}
          />
          <KeyboardDatePicker
            label="Emissão Final"
            value={params.final_date}
            onChange={date => handleChangeParams('final_date', date)}
            textFieldProps={{ margin: 'none' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                checked={params.sent}
                onChange={e => handleChangeParams('sent', e.target.checked)}
                color="primary"
              />
            }
            label="Não transmitidos"
          />
        </div>
      </Filter>
      <Actions>
        <IconButton onClick={loadBills}>
          <Refresh color="primary" />
        </IconButton>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </Actions>
    </FilterBox>
  );
};

export default BillsFilterBox;
