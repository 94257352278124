import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';
import { Address } from '../types/address';

export interface CustomerAddressValidation {
  cep?: string;
  endereco?: string;
  bairro?: string;
  cidade?: string;
  uf?: string;
}

type UseCustomerAddressValidation = [
  CustomerAddressValidation,
  Dispatch<SetStateAction<CustomerAddressValidation>>,
  (address: Address) => Promise<void>,
];

export function useCustomerAddressValidation(): UseCustomerAddressValidation {
  async function handleCustomerAddressValidation(address: Address) {
    const schema = yup.object().shape({
      endereco: yup.string().required('O endereço é obrigatório'),
      bairro: yup.string().required('O bairro é obrigatório'),
      cidade: yup.string().required('A cidade é obrigatória'),
      uf: yup.string().required('A UF é obrigatória'),
      cep: yup.string().required('O CEP é obrigatório'),
    });

    try {
      await schema.validate(address);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setCustomerAddressValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [customerAddressValidation, setCustomerAddressValidation] = useState<CustomerAddressValidation>({});
  return [customerAddressValidation, setCustomerAddressValidation, handleCustomerAddressValidation];
}
