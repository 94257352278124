import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import TotalizingItemModule from './TotalizingItemModule';
import { TotalizingAccount } from 'types/totalizingAccount';

type TotalizingListModuleProps = {
  accounts: TotalizingAccount[];
};

const TotalizingListModule: React.FC<TotalizingListModuleProps> = ({ accounts }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <List>
      {accounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(account => (
        <TotalizingItemModule key={account.id} account={account} />
      ))}
    </List>
  );
};

export default TotalizingListModule;
