import { Typography } from '@mui/material';
import React from 'react';
import CreditLimitRequestDocument from './CreditLimitRequestDocument';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'grid',
    flex: 1,
    margin: '15px 0',
    maxWidth: 500,
  },
});

const CreditLimitRequestDocuments: React.FC = () => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <Typography color="textSecondary" variant="caption">
        Documentos
      </Typography>
      <CreditLimitRequestDocument
        index="social_contract"
        description="Contrato social (Última alteração consolidada)"
      />
      <CreditLimitRequestDocument index="annual_balance" description="Balanço do ano anterior" />
      <CreditLimitRequestDocument index="quarterly_balance" description="Balancete do trimestre anterior" />
      <CreditLimitRequestDocument
        index="twelve_months_revenue"
        description="Relação de faturamento dos últimos 12 meses"
      />
      <CreditLimitRequestDocument index="guarantee_letter" description="Carta de fiança" />
    </div>
  );
};

export default CreditLimitRequestDocuments;
