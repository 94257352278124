import React from 'react';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography } from '@mui/material';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { usePagination } from 'hooks/pagination';
import TableHeader from 'components/new-table/TableHeader';
import TableRow from 'components/new-table/TableRow';
import { Invoice } from 'types/invoices';
import { OrderIndexData } from 'hooks/tableOrder';
import SummaryItemTitle from './SummaryItemTitle';

type SummaryListTitlesProps = {
  titles: Invoice[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const headerStyle: React.CSSProperties = {
  paddingLeft: 60,
  backgroundColor: '#eee',
};

const SummaryListTitles: React.FC<SummaryListTitlesProps> = ({ titles, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();

  return (
    <TableContent>
      <TableHeader style={headerStyle}>
        {tableTemplate.map(item => (
          <div
            className={item.dataType === 'number' ? 'numericData' : undefined}
            key={item.id}
            onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
          >
            <Typography fontSize={12} variant="subtitle2" color="#000">
              {item.description}
            </Typography>
            {orderedIndex.index === item.originalId && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </div>
        ))}
      </TableHeader>
      <TableBody>
        {titles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(title => (
          <TableRow style={{ paddingLeft: 60 }} key={title.id}>
            <SummaryItemTitle data={title} />
          </TableRow>
        ))}
      </TableBody>
    </TableContent>
  );
};

export default SummaryListTitles;
