import React, { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { Button, TextField, styled } from '@mui/material';

const Container = styled('div')({
  display: 'flex',
  gap: 20,
  flexDirection: 'column',
  cursor: 'text',
  position: 'relative',
  margin: '40px auto',
  width: '100%',
  maxWidth: 800,
});

const CustomTextField = styled(TextField)({
  padding: '10px 40px 10px 10px',
  backgroundColor: '#d0d2d5',
  borderRadius: '0.375rem',
  '& > div > textarea': {
    color: '#000',
    fontSize: 12,
    fontWeight: 'bold',
  },
  '& > div:before': {
    borderBottom: 'none!important',
  },
  '& > div:after': {
    borderBottom: 'none!important',
  },
});

const Action = styled('div')({
  display: 'flex',
  gap: 10,
});

const CustomButtonSubmit = styled(Button)({
  flex: 1,
  borderRadius: 6,
  backgroundColor: '#000',
  '&:hover': {
    backgroundColor: '#666',
  },
});

const CustomButtonCancel = styled(Button)({
  flex: 1,
  borderRadius: 6,
  borderColor: '#000',
  color: '#000',
  '&:hover': {
    backgroundColor: '#fff',
    borderColor: '#666',
  },
});

interface AiChatInputProps {
  text: string;
  loading: boolean;
  setText: Dispatch<SetStateAction<string>>;
}

const AiChatInput: React.FC<AiChatInputProps> = ({ text, setText, loading }) => {
  const input = useRef<HTMLInputElement>(null);

  const onFocus = useCallback(() => {
    input.current?.focus();
  }, []);

  return (
    <Container onClick={onFocus}>
      <CustomTextField
        disabled={loading}
        color="warning"
        fullWidth
        multiline
        autoFocus
        placeholder="Envie a mensagem"
        ref={input}
        value={text}
        onChange={e => setText(e.target.value)}
      />

      <Action>
        <CustomButtonSubmit type="submit" variant="contained">
          Gerar
        </CustomButtonSubmit>
        <CustomButtonCancel variant="outlined">Cancelar</CustomButtonCancel>
      </Action>
    </Container>
  );
};

export default AiChatInput;
