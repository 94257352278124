import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';
import { TotalizingAccount } from 'types/totalizingAccount';

export interface TotalizingAccountValidation {
  conta_contabil?: string;
  saldo?: string;
  nome?: string;
}

type UseTotalizingAccountValidation = [
  TotalizingAccountValidation,
  Dispatch<SetStateAction<TotalizingAccountValidation>>,
  (totalizingAccount: TotalizingAccount) => Promise<void>,
];

export function useTotalizingAccountValidation(): UseTotalizingAccountValidation {
  async function handleTotalizingAccountValidation(totalizingAccount: TotalizingAccount) {
    const schema = yup.object().shape({
      conta_contabil: yup.string().nullable(),
      saldo: yup.string().required('O saldo é obrigatório'),
      nome: yup.string().required('O nome é obrigatório'),
    });

    try {
      await schema.validate(totalizingAccount);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setTotalizingAccountValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [totalizingAccountValidation, setTotalizingAccountValidation] = useState<TotalizingAccountValidation>({});
  return [totalizingAccountValidation, setTotalizingAccountValidation, handleTotalizingAccountValidation];
}
