import React, { MouseEvent } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { PromotionalBanner } from 'types/promotionalBanners';
import { usePromotionalBanners } from 'pages/promotional-banners/hooks/usePromotionalBanners';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type PromotionalBannerItemTableProps = {
  banner: PromotionalBanner;
};

const PromotionalBannerItemTable: React.FC<PromotionalBannerItemTableProps> = ({ banner }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedBanner } = usePromotionalBanners();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedBanner(banner);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{banner[item.id]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default PromotionalBannerItemTable;
