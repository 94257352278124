import React, { FormEvent, useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import PartnerForm from '../PartnerForm';
import { usePartnerValidation } from '../validation/partnerValidation';
import { Partner } from 'types/partner';
import PartnerActions from './PartnerActions';

const PartnerNew: React.FC = () => {
  const [partner, setPartner] = useState<Partner>({} as Partner);
  const [validation, setValidation, validate] = usePartnerValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();

  const handleChange = useCallback((index: keyof Partner, value: any) => {
    setPartner(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(partner)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/partners', partner)
      .then(() => {
        setSaving(false);
        history.push('/site/partners');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Site"
        ActionsComponent={<PartnerActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/site/partners')}
      />
      <PageHeader title="Novo parceiro" />
      <Grid container>
        <form onSubmit={handleValidation}>
          <PartnerForm handleChange={handleChange} partner={partner} validation={validation} />
        </form>
      </Grid>
    </>
  );
};

export default PartnerNew;
