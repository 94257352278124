import Dialog from 'components/dialogs/Dialog';
import React, { useEffect, useState } from 'react';
import { useExtracts } from './hooks/useExtracts';
import { IconButton, MenuItem, TextField, Tooltip, Typography, styled } from '@mui/material';
import { Done } from '@mui/icons-material';
import { useApp } from 'hooks/app';
import { AppropriationDate } from 'types/appropriationDate';
import { useSelector } from 'store/selector';
import { useMessaging } from 'hooks/messaging';
import InsideSaving from 'components/loading/InsideSaving';
import Loading from 'components/loading/Loading';
import ConfirmPassword from 'components/confirm-password/ConfirmPassword';

interface BankStatementAppropriationProps {
  onExited(): void;
  onSave(): void;
}

const List = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '60px 1fr 110px 95px',
  borderBottom: '1px solid #eee',
  '& > span:not(:last-child)': {
    borderRight: '1px solid #eee',
  },
  '& > span': {
    padding: 5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&.numericData': {
      textAlign: 'end',
    },
  },
  [theme.breakpoints.down('sm')]: {
    zoom: '80%',
  },
}));

const Container = styled('div')({
  gap: 10,
  padding: 5,
  display: 'flex',
  flexDirection: 'column',
});

const BankStatementAppropriation: React.FC<BankStatementAppropriationProps> = ({ onExited, onSave: handleSave }) => {
  const { selectedExtracts } = useExtracts();
  const [date, setDate] = useState('');
  const { handleOpen } = useMessaging();
  const [showConfirmAction, setShowConfirmAction] = useState(false);
  const [validation, setValidation] = useState('');
  const { financialApi } = useApp();
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dates, setDates] = useState<AppropriationDate[]>([]);

  useEffect(() => {
    setLoading(true);
    financialApi
      .get('/getdataapropriacao')
      .then(response => {
        if (response.data.MESSAGE) {
          setDates([]);
          return;
        }
        setDates(
          response.data.map(item => {
            item.data = item.data.replace(/\\/g, '');
            return item;
          }),
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [financialApi]);

  function onSave() {
    if (!user) return;

    setSaving(true);

    const appropriations = selectedExtracts.map(item => ({
      id: item.id_movimentacao,
      tipo: item.tipo_movimentacao,
    }));

    const params = {
      usuario: user.name,
      data_apropriacao: date,
      apropriacao: appropriations,
    };

    financialApi
      .post('/saveapropriacao', params)
      .then(() => {
        handleOpen('Operação concluída');
        handleSave();
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleValidation() {
    setValidation('');
    if (!date) {
      setValidation('A data é obrigatória');
      return;
    }

    setShowConfirmAction(true);
  }

  return (
    <Dialog
      height="70vh"
      maxWidth="sm"
      onExited={onExited}
      title="Apropriação"
      ComponentActions={
        <Tooltip title="Salvar">
          <IconButton color="inherit" onClick={handleValidation}>
            <Done />
          </IconButton>
        </Tooltip>
      }
    >
      <Container>
        {showConfirmAction && <ConfirmPassword onExited={() => setShowConfirmAction(false)} onValidate={onSave} />}
        {loading && <Loading />}
        {saving && <InsideSaving />}
        <TextField
          style={{ maxWidth: 200 }}
          helperText={validation}
          error={!!validation}
          value={date}
          onChange={e => setDate(e.target.value)}
          label="Selecione a data"
          placeholder="Data de apropriação"
          select
        >
          <MenuItem value="">Nenhuma data selecionada</MenuItem>
          {dates.map(date => (
            <MenuItem key={date.id} value={date.data}>
              {date.data}
            </MenuItem>
          ))}
        </TextField>
        <div>
          <List>
            <span>
              <Typography variant="subtitle2" fontWeight={600} fontSize={12}>
                ID
              </Typography>
            </span>

            <span>
              <Typography variant="subtitle2" fontWeight={600} fontSize={12}>
                Descrição
              </Typography>
            </span>

            <span className="numericData">
              <Typography variant="subtitle2" fontWeight={600} fontSize={12}>
                Valor
              </Typography>
            </span>

            <span>
              <Typography variant="subtitle2" fontWeight={600} fontSize={12}>
                Tipo
              </Typography>
            </span>
          </List>
          {selectedExtracts.map((item, index) => (
            <List key={index}>
              <span>
                <Typography variant="subtitle2" fontSize={13}>
                  {item.id_movimentacao}
                </Typography>
              </span>

              <span className="item-name">
                <Tooltip title={item.descricao}>
                  <Typography variant="subtitle2" fontSize={13}>
                    {item.descricao}
                  </Typography>
                </Tooltip>
              </span>

              <span className="numericData">
                <Typography variant="subtitle2" fontSize={13}>
                  {item.formattedValue}
                </Typography>
              </span>

              <span>
                <Typography variant="subtitle2" fontSize={13}>
                  {item.formattedType}
                </Typography>
              </span>
            </List>
          ))}
        </div>
      </Container>
    </Dialog>
  );
};

export default BankStatementAppropriation;
