import React from 'react';
import ManufacturerAutoComplete from '../auto-complete/ManufacturerAutoComplete';

const PriceProtectionStepOne: React.FC = () => {
  return (
    <div>
      <ManufacturerAutoComplete />
    </div>
  );
};

export default PriceProtectionStepOne;
