import { Dispatch, SetStateAction, useState } from 'react';
import { Course } from 'types/course';
import * as yup from 'yup';

export type CourseValidation = {
  name?: string;
  image?: string;
  description?: string;
  active?: string;
  price?: string;
  url?: string;
};

type UseCourseValidation = [
  CourseValidation,
  Dispatch<SetStateAction<CourseValidation>>,
  (course: Course) => Promise<void>,
];

export function useCourseValidation(): UseCourseValidation {
  async function handleValidation(course: Course) {
    const schema = yup.object().shape({
      image: yup.object().required('O image do curso é obrigatória'),
      happen_at: yup.string().nullable(),
      price: yup.string().required('O preço é obrigatório'),
      description: yup.string().required('A descrição é obrigatória'),
      name: yup.string().required('O nome do curso é obrigatório'),
    });

    try {
      await schema.validate(course);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<CourseValidation>({});
  return [validation, setValidation, handleValidation];
}
