import React from 'react';
import { Box, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { usePermissionRules } from 'hooks/permissionRules';
import { useCustomer } from '../../hooks/useCustomer';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  grid: {
    marginTop: '-30px',
  },
});

const CustomerFinancial: React.FC = () => {
  const { customer, handleChange } = useCustomer();
  const { checkPermission } = usePermissionRules();
  const classes = styles();

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid container direction={'row'} spacing={1}>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              label="Prazo boleto"
              placeholder="Prazo boleto"
              margin="normal"
              value={customer?.prazo_boleto}
              onChange={e => handleChange('prazo_boleto', e.target.value)}
              type="number"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              label="Prazo boleto informática"
              placeholder="Prazo boleto informática"
              margin="normal"
              value={customer?.prazo_boleto_informatica}
              onChange={e => handleChange('prazo_boleto_informatica', e.target.value)}
              type="number"
            />
          </Grid>
          {checkPermission('registrations.customers.commission') && (
            <>
              <Grid item xs={6} md={3}>
                <TextField
                  fullWidth
                  label="Comissão"
                  placeholder="Comissão"
                  margin="normal"
                  value={customer?.comissao}
                  onChange={e => handleChange('comissao', e.target.value)}
                  type="number"
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  fullWidth
                  label="Comissão revendedor"
                  placeholder="Comissão revendedor"
                  margin="normal"
                  value={customer?.comissao_representante}
                  onChange={e => handleChange('comissao_representante', e.target.value)}
                  type="number"
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid container direction={'row'} spacing={1}>
          <Grid item xs={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customer?.cartao === 'S'}
                  onChange={e => handleChange('cartao', e.target.checked ? 'S' : 'N')}
                  color="primary"
                />
              }
              label="Cartão"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customer?.carteira === 'S'}
                  onChange={e => handleChange('carteira', e.target.checked ? 'S' : 'N')}
                  color="primary"
                />
              }
              label="Carteira"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customer?.deposito === 'S'}
                  onChange={e => handleChange('deposito', e.target.checked ? 'S' : 'N')}
                  color="primary"
                />
              }
              label="Depósito"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customer?.os === 'S'}
                  onChange={e => handleChange('os', e.target.checked ? 'S' : 'N')}
                  color="primary"
                />
              }
              label="OS"
            />
          </Grid>
        </Grid>
        <Grid container direction={'row'} spacing={1}>
          <Grid item xs={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customer?.dinheiro === 'S'}
                  onChange={e => handleChange('dinheiro', e.target.checked ? 'S' : 'N')}
                  color="primary"
                />
              }
              label="Dinheiro"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customer?.credito === 'S'}
                  onChange={e => handleChange('credito', e.target.checked ? 'S' : 'N')}
                  color="primary"
                />
              }
              label="Crédito"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customer?.boleto === 'S'}
                  onChange={e => handleChange('boleto', e.target.checked ? 'S' : 'N')}
                  color="primary"
                />
              }
              label="Boleto"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customer?.cheque === 'S'}
                  onChange={e => handleChange('cheque', e.target.checked ? 'S' : 'N')}
                  color="primary"
                />
              }
              label="Cheque"
            />
          </Grid>
          <Grid container direction={'row'} spacing={1}>
            <Grid className={classes.grid} item xs={12} md={6}>
              <TextField
                fullWidth
                label="Valor Carta de fiança"
                placeholder="Valor Carta de fiança"
                margin="normal"
                value={customer?.valor_carta_fianca}
                onChange={e => handleChange('valor_carta_fianca', e.target.value)}
                type="number"
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={customer?.carta_fianca === 'S'}
                    onChange={e => handleChange('carta_fianca', e.target.checked ? 'S' : 'N')}
                    color="primary"
                  />
                }
                label="Carta de fiança"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerFinancial;
