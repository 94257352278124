import React, { useMemo } from 'react';
import { externalTooltipHandler } from 'components/chart/externalTooltip';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { SalesMethod } from 'types/salesReport';
import { Pie } from 'react-chartjs-2';

interface ModalityChartProps {
  salesMethods: SalesMethod[];
}

const ModalityChart: React.FC<ModalityChartProps> = ({ salesMethods }) => {
  const pieData = useMemo(() => {
    return salesMethods.sort((a, b) => b.valor - a.valor).map(item => item.valor);
  }, [salesMethods]);

  return (
    <div style={{ height: 560, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
      <Pie
        data={{
          labels: salesMethods.sort((a, b) => b.valor - a.valor).map(branch => [branch.modalidade]),
          datasets: [
            {
              label: 'Total',
              data: salesMethods.sort((a, b) => b.valor - a.valor).map(branch => branch.valor),
              borderWidth: 1,
            },
          ],
        }}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
              callbacks: {
                label: tooltip => moneyFormat(pieData[tooltip.dataIndex as number]),
              },
              external: externalTooltipHandler,
            },
            legend: {
              display: true,
              position: 'right',
            },
            colors: {
              enabled: true,
            },
            datalabels: {
              display: true,
              color: '#fff',
              font: {
                size: 9,
                weight: 600,
              },
              textAlign: 'left',
              formatter(value, context) {
                return `${salesMethods.sort((a, b) => b.valor - a.valor)[context.dataIndex].modalidade} ${percentFormat(
                  (value / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100,
                )}`;
              },
            },
          },
          responsive: true,
        }}
      />
    </div>
  );
};

export default ModalityChart;
