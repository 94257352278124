import { RentalPayment } from 'pages/rentals/types/rental';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export type RentalPaymentsContext = {
  selectedRentalPayment: RentalPayment | null;
  setSelectedRentalPayment: Dispatch<SetStateAction<RentalPayment | null>>;
};

const Context = createContext<RentalPaymentsContext>({} as RentalPaymentsContext);
export const RentalPaymentConsumer = Context.Consumer;
export const RentalPaymentProvider = Context.Provider;

export function useRentalPayments(): RentalPaymentsContext {
  return useContext(Context);
}
