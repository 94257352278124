import React from 'react';
import { Typography } from '@mui/material';
import { expensesTableTemplate } from '../../expensesTableTemplate';
import { Expense } from 'types/expense';

type ExpenseItemTableProps = {
  expense: Expense;
};

const ExpenseItemTable: React.FC<ExpenseItemTableProps> = ({ expense }) => {
  return (
    <>
      {expensesTableTemplate.map(item => (
        <div key={item.id}>
          <Typography variant="body2">{expense[item.id]}</Typography>
        </div>
      ))}
    </>
  );
};

export default ExpenseItemTable;
