import { useApp } from 'hooks/app';
import { H2iBuyer } from 'types/h2iBuyer';
import { FormEvent, useState } from 'react';
import { useBuyers } from 'pages/buyers/hooks/useBuyers';
import { BuyerValidation, useBuyerValidation } from '../validation/BuyerValidation';
import * as yup from 'yup';

type UseFetchEditBuyer = [
  (index: keyof H2iBuyer, value: any) => void,
  (e?: FormEvent<HTMLFormElement>) => void,
  H2iBuyer | null,
  BuyerValidation,
  boolean,
];

export function useFetchEditBuyer(onSave: () => void): UseFetchEditBuyer {
  const { selectedBuyer } = useBuyers();
  const [buyer, setBuyer] = useState<H2iBuyer | null>(JSON.parse(JSON.stringify(selectedBuyer)));
  const [saving, setSaving] = useState(false);
  const { h2iApi } = useApp();
  const [validation, setValidation, validate] = useBuyerValidation();

  function handleChange(index: keyof H2iBuyer, value: any) {
    setBuyer(buyer =>
      buyer
        ? {
            ...buyer,
            [index]: value,
          }
        : null,
    );
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    if (!buyer) return;
    e?.preventDefault();
    setValidation({});

    validate(buyer)
      .then(handleSubmit)
      .catch((err: yup.ValidationError) => {
        if (err instanceof yup.ValidationError) {
          setValidation({
            [err.path as string]: err.message,
          });
        }
      });
  }

  function handleSubmit() {
    if (!h2iApi) return;
    setSaving(true);

    h2iApi
      .post(`api/savecompradores/${buyer?.id}`, buyer)
      .then(response => {
        console.log(response);
        onSave();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setSaving(false));
  }

  return [handleChange, handleValidation, buyer, validation, saving];
}
