import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import { productTableTemplate } from '../productTableTemplate';
import { MoreHoriz } from '@mui/icons-material';
import { PurchaseOrderProduct } from 'types/purchaseOrder';

const IconButtonStyled = styled(IconButton)({
  justifySelf: 'flex-start',
  padding: 5,
});

type ProductItemTableProps = {
  product: PurchaseOrderProduct;
  handleClick(): void;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const ProductItemTable: React.FC<ProductItemTableProps> = ({ product, setAnchorEl, handleClick }) => {
  function onClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    handleClick();
  }

  return (
    <>
      {productTableTemplate.map(item =>
        item.id === 'actions' ? (
          <IconButtonStyled onClick={event => onClick(event)} size="small" key={item.id}>
            <MoreHoriz key={item.id} />
          </IconButtonStyled>
        ) : (
          <div className={item.dataType === 'number' ? 'numericData' : undefined} key={item.id}>
            <Typography variant="body2">{product[item.id]}</Typography>
          </div>
        ),
      )}
    </>
  );
};

export default ProductItemTable;
