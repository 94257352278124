import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Delete, Done } from '@mui/icons-material';

type CourseActionsProps = {
  handleSubmit(): void;
  handleDelete(): void;
  loading: boolean;
  saving: boolean;
};

const CourseActions: React.FC<CourseActionsProps> = ({ handleDelete, handleSubmit, loading, saving }) => {
  return (
    <>
      <Tooltip title="Excluir">
        <span>
          <IconButton disabled={saving || loading} color="inherit" onClick={handleDelete}>
            <Delete />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Salvar">
        <span>
          <IconButton disabled={saving || loading} color="inherit" onClick={handleSubmit}>
            <Done />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default CourseActions;
