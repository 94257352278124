import React, { useEffect, useRef, useState } from 'react';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { usePurchaseOrder } from './hook/usePurchaseOrder';
import CpfCnpjInput from 'components/masked-input/CpfCnpjInput';
import SelectBranch from 'components/select-branches/SelectBranch';
import ResellerAutoComplete from 'pages/projects/auto-complete/ResellerAutoComplete';
import ImportInvoiceXML from './dialog/ImportInvoiceXML';
import { useSelector } from 'store/selector';
import { XmlDocument } from 'types/xmlDocument';

const Container = styled('div')({
  gap: 15,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  '& > div': {
    gap: 15,
    alignItems: 'center',
    display: 'grid',
  },
});

const Row1 = styled('div')(({ theme }) => ({
  gridTemplateColumns: '100px 185px 300px 1fr 182px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '0.7fr 1.5fr 2fr 1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
    '& .xml': {
      gridColumn: '1 / 2',
      width: '100%',
      gridRow: '1/2',
    },
    '& .payment': {
      gridColumn: '2 / 4',
      gridRow: '1/2',
    },
  },
  '& > div > p': {
    marginTop: 0,
  },
  '& > button': {
    justifySelf: 'end',
    textTransform: 'none',
    height: '100%',
  },
}));

const Row2 = styled('div')(({ theme }) => ({
  gridTemplateColumns: '170px 1fr 0.5fr 0.5fr',
  '& .MuiFormControl-root': {
    marginTop: '0!important',
    marginBottom: '0!important',
  },
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '0.8fr 0.8fr 0.4fr 0.4fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Row3 = styled('div')(({ theme }) => ({
  gridTemplateColumns: '100px 0.72fr 0.3fr 0.8fr 0.3fr 0.2fr 0.5fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
}));

const Row4 = styled('div')(({ theme }) => ({
  gridTemplateColumns: '0.3fr 0.3fr 0.3fr 0.3fr 1fr 0.3fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
}));

const PurchaseOrderForm: React.FC = () => {
  const { order, validation, handleChange, reseller, handleCustomerSelect, handleClickInformPayment } =
    usePurchaseOrder();
  const branches = useSelector(state => state.branches);
  const [showImportXML, setShowImportXML] = useState(false);

  const inputs = {
    cfop: useRef<HTMLInputElement>(null),
    cnpj: useRef<HTMLInputElement>(null),
    cliente: useRef<HTMLInputElement>(null),
    date_new_forecast: useRef<HTMLInputElement>(null),
    date_forecast: useRef<HTMLInputElement>(null),
    id_filial: useRef<HTMLSelectElement>(null),
  };

  function onSelectBranch() {
    inputs.cnpj.current?.focus();
  }

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  function onXmlLoaded(response: XmlDocument) {
    console.log(response);
    const branch = branches.find(branch => branch.cnpj === response.dest.CNPJ);
    const typeShipping = parseFloat(response.total.ICMSTot.vFrete) > 0 ? 'd' : 'c';

    handleChange('id_filial', branch?.id || '');
    handleChange('chave_nfe', response['@attributes'].Id.split(/\D+/).join(''));
    handleChange('notafiscal', response.ide.nNF);
    handleChange('serie_nfe', response.ide.serie);
    handleChange('valor_desconto', response.total.ICMSTot.vDesc);
    handleChange('valor_despesa', response.total.ICMSTot.vOutro);
    handleChange('valor_frete', response.total.ICMSTot.vFrete);
    handleChange('frete_por_conta', response.transp.modFrete);
    handleChange('tipo_frete', typeShipping);
    handleChange('cnpj', response.dest.CNPJ);

    handleChange('base_icms', response.total.ICMSTot.vBC);
    handleChange('base_st', response.total.ICMSTot.vBCST);
    handleChange('codigo_filial', branch?.id || '');
    handleChange('modelo_serie', response.ide.serie);
  }

  return (
    <Container>
      {showImportXML && <ImportInvoiceXML onXmlLoaded={onXmlLoaded} onExited={() => setShowImportXML(false)} />}
      <Row1>
        <TextField
          disabled
          label="Pedido"
          placeholder="Pedido"
          value={order?.codigo_pedido || ''}
          onChange={e => handleChange('codigo_pedido', e.target.value)}
        />

        <KeyboardDatePicker
          disabled
          label="Data Cadastro"
          value={order.date}
          onChange={date => handleChange('date', date)}
        />

        <SelectBranch
          label="Informe a filial"
          inputRef={inputs.id_filial}
          error={!!validation.id_filial}
          helperText={validation.id_filial}
          viewAllBranches={false}
          branch_id={order.id_filial}
          onSelect={onSelectBranch}
          handleChange={e => handleChange('id_filial', e)}
        />

        <Button className="xml" onClick={() => setShowImportXML(true)} variant="outlined">
          Informar XML
        </Button>
        <Button
          className="payment"
          disabled={!order.itens.length}
          variant="contained"
          onClick={handleClickInformPayment}
        >
          Informar pagamento
        </Button>
      </Row1>
      <Row2>
        <TextField
          autoFocus
          disabled={order.id_filial === 0}
          inputRef={inputs.cnpj}
          error={!!validation.cnpj}
          helperText={validation.cnpj}
          label="Cnpj / Cpf"
          placeholder="Cnpj / Cpf"
          inputMode="numeric"
          InputProps={{
            inputComponent: CpfCnpjInput as any,
          }}
          value={order.cnpj}
          onChange={e => handleChange('cnpj', e.target.value)}
        />

        <ResellerAutoComplete
          branchId={order.id_filial}
          onSelect={handleCustomerSelect}
          label="Selecione um cliente"
          error={!!validation.cliente}
          helperText={validation.cliente || ''}
          placeholder="Digite o nome do cliente para pesquisar"
          customer={reseller}
          disabled={order.id_filial === 0}
        />

        <KeyboardDatePicker
          disabled={order.id_filial === 0}
          inputRef={inputs.date_forecast}
          textFieldProps={{
            margin: 'none',
            helperText: validation.date_forecast,
            error: !!validation.date_forecast,
          }}
          autoFocus
          label="Previsão"
          value={order.date_forecast}
          onChange={date => handleChange('date_forecast', date)}
        />

        <KeyboardDatePicker
          disabled={order.id_filial === 0}
          inputRef={inputs.date_new_forecast}
          textFieldProps={{
            margin: 'none',
            helperText: validation.date_new_forecast,
            error: !!validation.date_new_forecast,
          }}
          label="Nova Previsão"
          value={order.date_new_forecast}
          onChange={date => handleChange('date_new_forecast', date)}
        />
      </Row2>

      <Row3>
        <TextField
          disabled={order.id_filial === 0}
          inputRef={inputs.cfop}
          error={!!validation.cfop}
          helperText={validation.cfop}
          label="CFOP"
          placeholder="CFOP"
          type="number"
          inputMode="numeric"
          value={order.cfop || ''}
          onChange={e => handleChange('cfop', e.target.value)}
        />

        <TextField
          disabled={order.id_filial === 0}
          label="Descrição"
          placeholder="Descrição"
          value={order.descricao_cfop}
          onChange={e => handleChange('descricao_cfop', e.target.value)}
        />

        <TextField
          disabled={order.id_filial === 0}
          select
          label="Modelo NF"
          value={order.modelo_nf}
          onChange={e => handleChange('modelo_nf', e.target.value)}
        >
          <MenuItem value={'N'}>NF-e</MenuItem>
          <MenuItem value={'M'}>Manual</MenuItem>
        </TextField>

        <TextField
          disabled={order.id_filial === 0}
          label="Chave Nfe"
          placeholder="Chave Nfe"
          value={order.chave_nfe}
          type="number"
          inputMode="numeric"
          onChange={e => handleChange('chave_nfe', e.target.value)}
        />

        <TextField
          disabled={order.id_filial === 0}
          label="Nº Nota"
          placeholder="Nº Nota"
          value={order.notafiscal || ''}
          type="number"
          inputMode="numeric"
          onChange={e => handleChange('notafiscal', e.target.value)}
        />

        <TextField
          disabled={order.id_filial === 0}
          label="Série"
          placeholder="Série"
          value={order.serie_nfe || ''}
          type="number"
          inputMode="numeric"
          onChange={e => handleChange('serie_nfe', e.target.value)}
        />

        <KeyboardDatePicker
          disabled={order.id_filial === 0}
          label="Data Emissão"
          value={order.date_issue}
          onChange={date => handleChange('date_issue', date)}
        />
      </Row3>

      <Row4>
        <TextField
          disabled={order.id_filial === 0}
          label="Valor do Desconto"
          placeholder="Valor do Desconto"
          value={order.valor_desconto || ''}
          type="number"
          inputMode="decimal"
          onChange={e => handleChange('valor_desconto', e.target.value)}
        />

        <TextField
          disabled={order.id_filial === 0}
          label="Valor despesas"
          placeholder="Valor despesas"
          value={order.valor_despesa || ''}
          type="number"
          inputMode="decimal"
          onChange={e => handleChange('valor_despesa', e.target.value)}
        />

        <TextField
          disabled={order.id_filial === 0}
          label="Valor do Frete"
          placeholder="Valor do Frete"
          value={order.valor_frete || ''}
          type="number"
          inputMode="decimal"
          onChange={e => handleChange('valor_frete', e.target.value)}
        />

        <TextField
          disabled={order.id_filial === 0}
          select
          label="Frete Por Conta"
          value={order.frete_por_conta}
          onChange={e => handleChange('frete_por_conta', e.target.value)}
        >
          <MenuItem value={0}>0 - do emitente</MenuItem>
          <MenuItem value={1}>1 - do destinatário/remetente</MenuItem>
          <MenuItem value={2}>2 - de terceiros</MenuItem>
          <MenuItem value={9}>9 - sem frete</MenuItem>
        </TextField>

        <TextField
          disabled={order.id_filial === 0}
          select
          label="Tipo de Frete"
          value={order.tipo_frete}
          onChange={e => handleChange('tipo_frete', e.target.value)}
        >
          <MenuItem value={'d'}>Destacado na Nota</MenuItem>
          <MenuItem value={'c'}>Conhecimento</MenuItem>
        </TextField>

        <Button type="submit" variant="contained" disabled={order.id_filial === 0}>
          Processar
        </Button>
      </Row4>
    </Container>
  );
};

export default PurchaseOrderForm;
