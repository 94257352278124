import React, { Dispatch, SetStateAction } from 'react';
import DocumentUpload from 'components/document-upload/DocumentUpload';
import { Document } from 'types/document';

interface AccountAgentLetterUploadProps {
  setLetter: Dispatch<SetStateAction<Document | null>>;
  validation?: string;
  letter: Document | null;
  disabled?: boolean;
}

const AccountAgentLetterUpload: React.FC<AccountAgentLetterUploadProps> = ({
  validation,
  setLetter,
  letter,
  disabled,
}) => {
  return (
    <div>
      <DocumentUpload
        label="carta assinada"
        handleRemoveImage={() => setLetter(null)}
        handleSetImage={document => setLetter(document)}
        validationError={validation}
        document={letter}
        disabled={disabled}
      />
    </div>
  );
};

export default AccountAgentLetterUpload;
