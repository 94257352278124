import { TableTemplate } from 'types/tableTemplate';

export const courseStudentsTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'Ações',
    originalId: 'actions',
    width: 70,
  },
  {
    id: 'student_document_number',
    description: 'CNPJ/CPF',
    originalId: 'document_number',
    width: 150,
  },
  {
    id: 'student_name',
    description: 'Cliente',
    originalId: 'student_name',
    width: 400,
  },
  {
    id: 'formattedIsPaid',
    description: 'Pago',
    originalId: 'formattedIsPaid',
    width: 100,
  },
  {
    id: 'formattedIsApproved',
    description: 'Aprovado',
    originalId: 'formattedIsApproved',
    width: 150,
  },
  {
    id: 'payment_code',
    description: 'Número de identificação',
    originalId: 'payment_code',
    width: 200,
  },
  {
    id: 'formattedPaymentType',
    description: 'Forma de pagamento',
    originalId: 'formattedPaymentType',
    width: 200,
  },
  {
    id: 'formattedCreatedAt',
    description: 'Criado em',
    originalId: 'formattedCreatedAt',
    width: 150,
  },
];
