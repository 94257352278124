import React from 'react';
import { Typography, styled, ListItemButton, Icon } from '@mui/material';
import { useBuyers } from 'pages/buyers/hooks/useBuyers';
import { H2iBuyer } from 'types/h2iBuyer';
import { DisplaySettings, Person, Tag } from '@mui/icons-material';

const CustomListItemButton = styled(ListItemButton)({
  rowGap: 10,
  columnGap: 20,
  display: 'grid',
  gridTemplateColumns: '40px 1fr',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
});

type BuyerItemModuleProps = {
  buyer: H2iBuyer;
};

const BuyerItemModule: React.FC<BuyerItemModuleProps> = ({ buyer }) => {
  const { setSelectedBuyer, setEditBuyerDialog } = useBuyers();

  function handleClick() {
    setSelectedBuyer(buyer);
    setEditBuyerDialog(true);
  }

  return (
    <CustomListItemButton onClick={handleClick}>
      <Icon>
        <Tag />
      </Icon>
      <Typography variant="caption" color="primary">
        {buyer.id}
      </Typography>
      <Icon>
        <Person />
      </Icon>
      <Typography gutterBottom>{buyer.nome}</Typography>
      <Icon>
        <DisplaySettings />
      </Icon>
      <Typography variant="body2" color="textSecondary">
        Ativo: {buyer.ativo}
      </Typography>
    </CustomListItemButton>
  );
};

export default BuyerItemModule;
