import React, { MouseEvent, useState } from 'react';
import { IconButton, Typography, ListItemButton, styled } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { Justification } from 'types/justification';
import { useJustifications } from '../../hooks/useJustifications';
import JustificationListMenu from '../JustificationListMenu';

const ListItemButtonStyled = styled(ListItemButton)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Flex = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: 10,
  flex: 1,
  '&.bottom': {
    justifyContent: 'initial',
  },
});

const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  bottom: 5,
  right: 5,
});

const Link = styled('a')(({ theme }) => ({
  padding: '5px 10px',
  color: '#fff',
  borderRadius: 6,
  fontSize: 12,
  backgroundColor: theme.palette.primary.main,
  '&:active': {
    color: '#fff!important',
  },
}));

type JustificationItemModuleProps = {
  justification: Justification;
};

const JustificationItemModule: React.FC<JustificationItemModuleProps> = ({ justification }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { setSelectedJustification } = useJustifications();

  function handleClick() {
    setSelectedJustification(justification);
  }

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      <ListItemButtonStyled onClick={handleClick}>
        <Flex>
          <Typography variant="caption" color="primary" gutterBottom>
            Justificativa #{justification.id}
          </Typography>
          <Link target="_blank" href={justification.link_arquivo}>
            Abrir justificativa
          </Link>
        </Flex>

        <Flex className="bottom">
          <Typography variant="caption">Funcionário:</Typography>

          <Typography align="left" variant="caption">
            {justification.funcionario}
          </Typography>
        </Flex>

        <Flex className="bottom">
          <Typography variant="caption">CPF:</Typography>

          <Typography align="left" variant="caption">
            {justification.cpf}
          </Typography>
        </Flex>

        <Flex className="bottom">
          <Typography variant="caption">Data ocorrência:</Typography>

          <Typography align="left" variant="caption">
            {justification.formattedDate}
          </Typography>
        </Flex>

        <Flex className="bottom">
          <Typography variant="caption">Justificativa:</Typography>

          <Typography align="left" variant="caption">
            {justification.justificativa}
          </Typography>
        </Flex>

        <Flex className="bottom">
          <Typography variant="caption">Aceita:</Typography>

          <Typography align="left" variant="caption">
            {justification.aceita}
          </Typography>
        </Flex>

        <Flex className="bottom">
          <Typography variant="caption">Lançada:</Typography>

          <Typography align="left" variant="caption">
            {justification.lancada}
          </Typography>
        </Flex>

        <StyledIconButton onClick={handleMoreClick}>
          <MoreVert />
        </StyledIconButton>
      </ListItemButtonStyled>

      <JustificationListMenu
        key={justification.id}
        justification={justification}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
};

export default JustificationItemModule;
