import { RentalActions } from 'pages/managed-sales/reducer/types';
import { ManagedSale } from 'pages/managed-sales/types/managedSale';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

interface ManagedSaleContext {
  rental: ManagedSale;
  dispatch: Dispatch<RentalActions>;
  handleChange(index: keyof ManagedSale, value: any): void;
  percentage: string;
  setPercentage: Dispatch<SetStateAction<string>>;
}

const Context = createContext<ManagedSaleContext>({} as ManagedSaleContext);
export const RentalProvider = Context.Provider;

export function useManagedSale(): ManagedSaleContext {
  return useContext(Context);
}
