import { Menu, MenuItem } from '@mui/material';
import { useProject } from 'pages/projects/registration/hook/useProject';
import { removeProjectContact } from 'pages/projects/registration/reducer/action';
import React, { Dispatch, SetStateAction } from 'react';
import { useProjectContacts } from '../hooks/useProjectContacts';

type ProjectContactsListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const ProjectContactsListMenu: React.FC<ProjectContactsListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { dispatch } = useProject();
  const { selectedContact } = useProjectContacts();

  function handleDeleteClick() {
    if (!selectedContact) {
      return;
    }

    dispatch(removeProjectContact(selectedContact.id));
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default ProjectContactsListMenu;
