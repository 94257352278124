import React from 'react';
import { Theme } from '@mui/material';
import { SalesReportTotal } from './hooks/useFetchFinancialCard';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    padding: 15,
    marginTop: 15,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    position: 'relative',
    border: '2px solid #eee',
    borderRadius: '8px 8px 0 0',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  total: {
    gap: 15,
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 0.7fr)',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  typography: {
    top: -10,
    left: 15,
    position: 'absolute',
    backgroundColor: '#fafafa',
  },
  column: {
    gap: 15,
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      maxWidth: '80%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .paid': {
        color: '#28a745',
        fontWeight: 'bold',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
  },
  row: {
    height: 35,
    marginBottom: 7,
  },
  containerLoading: {
    marginTop: 10,
    backgroundColor: '#fff',
    flex: 1,
  },
}));

interface SalesCardReportTotalProps {
  formattedTotal: SalesReportTotal;
}

const SalesCardReportTotal: React.FC<SalesCardReportTotalProps> = ({ formattedTotal }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.total}>
        <div className={classes.column}>
          <div>
            <span className="label">Valor bruto</span>
            <span className="paid">{moneyFormat(formattedTotal.gross)}</span>
          </div>
          <div>
            <span className="label">Valor recebido</span>
            <span className="paid">{moneyFormat(formattedTotal.received)}</span>
          </div>
        </div>

        <div className={classes.column}>
          <div>
            <span className="label">Valor desconto</span>
            <span className="paid">{moneyFormat(formattedTotal.discount)}</span>
          </div>
          <div>
            <span className="label">Taxa média</span>
            <span className="paid">{percentFormat(formattedTotal.tax)}</span>
          </div>
        </div>

        <div className={classes.column}>
          <div>
            <span className="label">Crédito/Parc</span>
            <span className="paid">{moneyFormat(formattedTotal.credit)}</span>
          </div>
          <div>
            <span className="label">Débito</span>
            <span className="paid">{moneyFormat(formattedTotal.debit)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesCardReportTotal;
