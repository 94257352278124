import React from 'react';
import ProductItem from './ProductItem';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { ProductList as ProductListType } from 'types/product';
import { usePagination } from 'hooks/pagination';

type ProductListProps = {
  products: ProductListType[];
};

const ProductList: React.FC<ProductListProps> = ({ products }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => (
            <ProductItem key={product.codigo_produto} product={product} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ProductList;
