import React, { useMemo } from 'react';
import Dialog from 'components/dialogs/Dialog';
import { IconButton, TextField, Tooltip, Typography, styled } from '@mui/material';
import { Done } from '@mui/icons-material';
import { PurchaseOrderSelectProduct } from '../product/PurchaseOrderProducts';
import { useApp } from 'hooks/app';

interface PurchaseOrderProductProps {
  onExited(): void;
  onSubmit(): void;
  handleChange(index: keyof PurchaseOrderSelectProduct, value: any): void;
  product: PurchaseOrderSelectProduct;
}

const Container = styled('div')({
  display: 'grid',
  gap: 15,
  padding: '10px 0',
});

const HeaderContent = styled('div')({
  position: 'absolute',
  top: -15,
  left: 5,
  '& > p': {
    padding: 5,
    fontSize: 12,
    backgroundColor: '#fff',
    fontWeight: 'bold',
  },
});

const Row1 = styled('div')(({ theme }) => ({
  gridTemplateColumns: '70px 140px 1fr',
  alignItems: 'center',
  padding: '0 10px',
  display: 'grid',
  rowGap: 15,
  columnGap: 5,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '70px 1fr',
    '& .description': {
      gridColumn: '1/3',
    },
  },
}));

const Row2 = styled('div')(({ theme }) => ({
  gridTemplateColumns: '90px 90px 15px 90px 15px 90px 15px 1fr 15px 1fr',
  alignItems: 'center',
  padding: '25px 10px',
  position: 'relative',
  border: '1px solid #eee',
  textAlign: 'center',
  display: 'grid',
  columnGap: 5,
  rowGap: 15,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    '& > .description': {
      display: 'none',
    },
  },
}));

const Row3 = styled('div')(({ theme }) => ({
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  alignItems: 'center',
  padding: '25px 10px',
  position: 'relative',
  border: '1px solid #eee',
  textAlign: 'center',
  display: 'grid',
  rowGap: 15,
  columnGap: 5,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Row4 = styled('div')(({ theme }) => ({
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  alignItems: 'center',
  padding: '0 10px',
  display: 'grid',
  rowGap: 15,
  columnGap: 5,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Row5 = styled('div')(({ theme }) => ({
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  alignItems: 'center',
  padding: '0 10px',
  display: 'grid',
  rowGap: 15,
  columnGap: 5,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
}));

const PurchaseOrderProduct: React.FC<PurchaseOrderProductProps> = ({ onExited, onSubmit, handleChange, product }) => {
  const { windowWidth } = useApp();
  const total = useMemo(() => {
    const totalProduct = product.value * product.quantity;

    const valueIPI = (totalProduct * product.ipi) / 100;

    const discount = product.discount;

    return totalProduct + valueIPI - discount;
  }, [product]);

  return (
    <Dialog
      title="Produto"
      height="70vh"
      maxWidth="md"
      onExited={onExited}
      ComponentActions={
        <Tooltip title="Salvar">
          <IconButton onClick={onSubmit} color="inherit">
            <Done />
          </IconButton>
        </Tooltip>
      }
    >
      <Container>
        <Row1>
          <TextField disabled placeholder="Código" label="Código" value={product.productId} />
          <TextField disabled placeholder="Código Barra" label="Código Barra" value={product.product?.codigo_barra} />
          <TextField
            className="description"
            disabled
            placeholder="Descrição"
            label="Descrição"
            value={product.product?.produto}
          />
        </Row1>

        <Row2>
          <HeaderContent>
            <Typography>Valores</Typography>
          </HeaderContent>

          <TextField
            value={product.product?.est || ''}
            disabled
            label="Estoque"
            placeholder="Informe a quantidade em estoque"
          />

          <TextField
            disabled
            value={product.quantity || ''}
            label="Qtd"
            placeholder="Qtd"
            inputMode="decimal"
            type="number"
          />

          <Typography className="description">x</Typography>

          <TextField
            value={product.value || ''}
            onChange={e => handleChange('value', e.target.value)}
            label="Vl Unit"
            placeholder="Vl Unit"
            inputMode="decimal"
            type="number"
          />

          <Typography className="description">+</Typography>

          <TextField
            value={product.ipi || ''}
            onChange={e => handleChange('ipi', e.target.value)}
            label="IPI %"
            placeholder="IPI %"
            inputMode="decimal"
            type="number"
          />

          <Typography className="description">-</Typography>

          <TextField
            value={product.discount || ''}
            onChange={e => handleChange('discount', e.target.value)}
            label="Desconto"
            placeholder="Desconto"
            inputMode="decimal"
            type="number"
          />

          <Typography className="description">=</Typography>

          <TextField disabled value={total} label="Vl Total" placeholder="Vl Total" inputMode="decimal" type="number" />
        </Row2>

        <Row3>
          <HeaderContent>
            <Typography>Impostos</Typography>
          </HeaderContent>

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="Origem"
            placeholder="Origem"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="CST Icms"
            placeholder="CST Icms"
            inputMode="decimal"
            type="number"
          />

          <TextField
            disabled
            style={{ gridColumn: windowWidth >= 660 ? '3/6' : '' }}
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
          />

          <TextField
            disabled
            style={{ gridColumn: windowWidth >= 660 ? '6/7' : '' }}
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="NCM"
            placeholder="NCM"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="Icms"
            placeholder="Icms"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="Red Icms"
            placeholder="Red Icms"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="Aliq. ST"
            placeholder="Aliq. ST"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="Aliq. MVA"
            placeholder="Aliq. MVA"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="Base ST Custo"
            placeholder="Base ST Custo"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="Valor ST Custo"
            placeholder="Valor ST Custo"
            inputMode="decimal"
            type="number"
          />
        </Row3>

        <Row4>
          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="BC do ICMS"
            placeholder="Base de Cálculo do ICMS"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="Valor do ICMS"
            placeholder="Valor do ICMS"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="BC ICMS Subst."
            placeholder="Base de Cálculo ICMS Subst."
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="Valor do ICMS Subst."
            placeholder="Valor do ICMS Subst."
            inputMode="decimal"
            type="number"
          />
        </Row4>

        <Row5>
          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="BC do PIS"
            placeholder="Base de Cálculo do PIS"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="Valor do PIS"
            placeholder="Valor do PIS"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="BC do COFINS"
            placeholder="Base de Cálculo do COFINS"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="Valor do COFINS"
            placeholder="Valor do COFINS"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="Base de IPI"
            placeholder="Base de IPI"
            inputMode="decimal"
            type="number"
          />

          <TextField
            value={product.id || ''}
            onChange={e => handleChange('id', e.target.value)}
            label="Valor do IPI"
            placeholder="Valor do IPI"
            inputMode="decimal"
            type="number"
          />
        </Row5>
      </Container>
    </Dialog>
  );
};

export default PurchaseOrderProduct;
