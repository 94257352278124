import { cnpjValidator } from 'helpers/cnpjValidator';
import { cpfValidator } from 'helpers/cpfValidator';
import { Dispatch, SetStateAction, useState } from 'react';
import { H2iCustomer } from 'types/h2iCustomer';
import * as yup from 'yup';

export interface CustomerInfosValidation {
  nome?: string;
  cnpj_cpf?: string;
  codigo_atividade?: number;
  atividade?: string;
  fantasia?: string;
  tipo_pessoa?: string;
  tipo?: string;
}

type UseCustomerInfosValidation = [
  CustomerInfosValidation,
  Dispatch<SetStateAction<CustomerInfosValidation>>,
  (customer: H2iCustomer) => Promise<void>,
];

export function useCustomerInfosValidation(): UseCustomerInfosValidation {
  async function handleValidation(customer: H2iCustomer) {
    const schema = yup.object().shape({
      codigo_atividade: yup
        .string()
        .required('A atividade é obrigatória')
        .test('numberInitial', 'Selecione uma atividade', value => {
          if (parseInt(value) === 0) return false;
          return parseInt(value) !== 0;
        })
        .nonNullable(),
      fantasia: yup.string().required('O nome fantasia é obrigatório'),
      nome: yup.string().required('O nome é obrigatório'),
      cnpj_cpf: yup
        .string()
        .transform((value, originalValue) => {
          return originalValue ? originalValue.replace(/\D/g, '') : '';
        })
        .required(`CNPJ ou CPF é obrigatório`)
        .test('docValidation', `documento inválido`, value => {
          if (!value) return false;

          const cnpj = cnpjValidator(value);
          const cpf = cpfValidator(value);

          return cnpj || cpf;
        }),
      tipo_pessoa: yup.string().required('O tipo pessoa é obrigatório'),
      tipo: yup.string().required('O tipo é obrigatório'),
    });

    try {
      await schema.validate(customer);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setCustomerInfoValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [customerInfoValidation, setCustomerInfoValidation] = useState<CustomerInfosValidation>({});
  return [customerInfoValidation, setCustomerInfoValidation, handleValidation];
}
