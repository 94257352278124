import { Settings } from 'types/settings';

export const SET_SETTINGS = 'settings@SET_SETTINGS';

interface SetSettingsAction {
  type: typeof SET_SETTINGS;
  settings: Settings;
}

export type SettingsActionsType = SetSettingsAction;
