import React from 'react';
import { Button, ButtonGroup, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';
import { useApp } from 'hooks/app';
import { useSellout } from './hooks/useSellout';
import { endOfMonth, setMonth, setYear, startOfMonth } from 'date-fns';

const StyledFilter = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '200px 1fr',
  gap: 10,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
    flex: 1,
  },
  [theme.breakpoints.down('sm')]: {
    flex: 1,
    display: 'flex',
  },
}));

const ListTypeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const Actions = styled('div')(props => ({
  display: 'flex',
  gap: 10,
  justifyContent: 'end',
  [props.theme.breakpoints.down('sm')]: {
    '& .button-filters': {
      display: 'none',
    },
  },
}));

interface SelloutFilterBoxProps {
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
}

const months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];

const SelloutFilterBox: React.FC<SelloutFilterBoxProps> = ({ displayMode, setDisplayMode }) => {
  const { filter, handleChangeFilter, handleSubmit, loading } = useSellout();
  const { isMobile } = useApp();

  function setDates(month: number) {
    const initialDate = setYear(startOfMonth(setMonth(new Date(), month)), filter.year.getFullYear());
    const finalDate = setYear(endOfMonth(setMonth(new Date(), month)), filter.year.getFullYear());

    handleSubmit(initialDate, finalDate);
  }

  const actions = {
    jan: () => setDates(0),
    fev: () => setDates(1),
    mar: () => setDates(2),
    abr: () => setDates(3),
    mai: () => setDates(4),
    jun: () => setDates(5),
    jul: () => setDates(6),
    ago: () => setDates(7),
    set: () => setDates(8),
    out: () => setDates(9),
    nov: () => setDates(10),
    dez: () => setDates(11),
  };

  function handleChangeDates(month: keyof typeof actions) {
    actions[month]();
  }

  return (
    <FilterBox>
      <StyledFilter>
        <SelectOthersBranch
          branch_id={filter.branch_id}
          handleChange={value => handleChangeFilter('branch_id', value)}
          fullWidth={isMobile}
        />
        <Actions>
          <ButtonGroup disableElevation variant="outlined">
            {months.map((month, index) => (
              <Button
                variant={index === actions[index] ? 'contained' : 'outlined'}
                onClick={() => handleChangeDates(month as keyof typeof actions)}
                disabled={loading}
                key={month}
              >
                {month}
              </Button>
            ))}
          </ButtonGroup>
        </Actions>
      </StyledFilter>
      <ListTypeContainer>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </ListTypeContainer>
    </FilterBox>
  );
};

export default SelloutFilterBox;
