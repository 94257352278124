import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export type InvoicePaymentValidation = {
  totalizingAccountId?: number;
};

type UseInvoicePaymentValidation = [
  InvoicePaymentValidation,
  Dispatch<SetStateAction<InvoicePaymentValidation>>,
  (invoice: InvoicePaymentValidation) => Promise<void>,
];

export function useInvoicePaymentValidation(): UseInvoicePaymentValidation {
  async function handleValidation(payment: InvoicePaymentValidation) {
    const schema = yup.object().shape({
      totalizingAccountId: yup
        .number()
        .required('A conta totalizadora é obrigatória')
        .test('accountValidation', 'A conta totalizadora é obrigatória', value => {
          if (value === 0) return false;
          if (!value) return false;

          return true;
        }),
    });

    try {
      await schema.validate(payment);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<InvoicePaymentValidation>({});
  return [validation, setValidation, handleValidation];
}
