import { TableTemplate } from 'types/tableTemplate';

export const wasteProductTableTemplate: TableTemplate[] = [
  {
    id: 'codigo_produto',
    description: 'ID',
    originalId: 'codigo_produto',
    width: 80,
  },
  {
    id: 'codigo_barra',
    description: 'Cód de Barras',
    originalId: 'codigo_barra',
    width: 160,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 400,
  },
  {
    id: 'formattedEst',
    description: 'Est',
    originalId: 'formattedEst',
    width: 100,
  },
  {
    id: 'formattedReeePrice',
    description: 'Preço',
    originalId: 'formattedPrice',
    width: 100,
    dataType: 'number',
  },
];
