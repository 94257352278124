import { createContext, useContext } from 'react';
import { FinancialTicket } from 'types/financialReportList';
import { FinancialTicketFilterParams } from '../FinancialTicketReport';

type FinancialTicketContextValue = {
  selectedTicket: FinancialTicket | null;
  setSelectedTicket(partner: FinancialTicket | null): void;
  handleChangeFilter(index: keyof FinancialTicketFilterParams, value: any): void;
  filter: FinancialTicketFilterParams;
};

const FinancialTicketContext = createContext<FinancialTicketContextValue>({} as FinancialTicketContextValue);
export const FinancialTicketProvider = FinancialTicketContext.Provider;

export function useFinancialTicket(): FinancialTicketContextValue {
  const context = useContext(FinancialTicketContext);
  return context;
}
