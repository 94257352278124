import { TableTemplate } from 'types/tableTemplate';

export const buyerTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 100,
  },
  {
    id: 'nome',
    description: 'Nome',
    originalId: 'nome',
    width: 250,
  },
  {
    id: 'ativo',
    description: 'Ativo',
    originalId: 'ativo',
    width: 100,
  },
];
