import { TableTemplate } from 'types/tableTemplate';

export const billsTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 80,
  },
  {
    id: 'bank_id',
    description: 'CÓDIGO',
    originalId: 'bank_id',
    width: 150,
  },
  {
    id: 'bank_name',
    description: 'DESCRIÇÃO',
    originalId: 'bank_name',
    width: 250,
  },
  {
    id: 'formattedActive',
    description: 'ATIVO',
    originalId: 'formattedActive',
    width: 100,
  },
  {
    id: 'beneficiary_name',
    description: 'BENEFICIÁRIO',
    originalId: 'beneficiary_name',
    width: 400,
  },
  {
    id: 'dispatch_id',
    description: 'Nº REMESSA',
    originalId: 'dispatch_id',
    width: 300,
  },
];
