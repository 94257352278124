import React, { useMemo } from 'react';
import { externalTooltipHandler } from 'components/chart/externalTooltip';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { PaymentMethod, SalesMethod } from 'types/salesReport';
import { Bar } from 'react-chartjs-2';

interface PaymentChartProps {
  methods: PaymentMethod[];
  salesMethods: SalesMethod[];
}

const PaymentChart: React.FC<PaymentChartProps> = ({ methods, salesMethods }) => {
  const pieData = useMemo(() => {
    return methods
      .sort((a, b) => b.valor - a.valor)
      .filter(item => item.percentual >= 1)
      .map(item => item.valor);
  }, [methods]);

  return (
    <div style={{ height: 560, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
      <Bar
        data={{
          labels: methods
            .sort((a, b) => b.valor - a.valor)
            .filter(item => item.percentual >= 1)
            .map(branch => [branch.forma]),
          datasets: [
            {
              label: 'Total',
              data: methods
                .sort((a, b) => b.valor - a.valor)
                .filter(item => item.percentual >= 1)
                .map(branch => branch.valor),
              barPercentage: 1,
              categoryPercentage: 0.8,
            },
          ],
        }}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
              callbacks: {
                label: tooltip => moneyFormat(pieData[tooltip.dataIndex as number]),
              },
              external: externalTooltipHandler,
            },
            title: {
              text: 'Formas de pagamento (maior que 1%)',
              display: true,
              position: 'top',
              align: 'center',
            },
            legend: {
              display: false,
            },
            datalabels: {
              display: true,
              color: '#30519f',
              font: {
                size: 9,
                weight: 600,
              },
              textAlign: 'center',
              formatter(value, context) {
                return percentFormat((value / salesMethods.reduce((sum, item) => sum + item.valor, 0)) * 100);
              },
            },
          },
          responsive: true,
        }}
      />
    </div>
  );
};

export default PaymentChart;
