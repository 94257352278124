import { RelatedProduct } from 'pages/related-product/types/relatedProduct';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

type RelatedProductContextValue = {
  relatedProduct: RelatedProduct;
  handleChange(index: keyof RelatedProduct, value: any): void;
  setRelatedProduct: Dispatch<SetStateAction<RelatedProduct>>;
};

const RelatedProductContext = createContext<RelatedProductContextValue>({} as RelatedProductContextValue);
export const RelatedProductProvider = RelatedProductContext.Provider;

export function useRelatedProduct(): RelatedProductContextValue {
  return useContext(RelatedProductContext);
}
