import { Waste } from 'pages/material-residues/waste/types/waste';
import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export type WasteValidation = {
  id_filial?: string;
  nome?: string;
  cnpj_cpf?: string;
};

type UseWasteValidation = [WasteValidation, Dispatch<SetStateAction<WasteValidation>>, (waste: Waste) => Promise<void>];

export function useWasteValidation(): UseWasteValidation {
  async function handleValidation(waste: Waste) {
    const schema = yup.object().shape({
      nome: yup.string().required('O nome do cliente é obrigatório'),
      cnpj_cpf: yup.string().required('O documento do cliente é obrigatório'),
      id_filial: yup
        .string()
        .required('A filial é obrigatória')
        .test('branchValidation', 'A filial é obrigatória', value => {
          if (!value) return false;

          if (parseInt(value) === 0) return false;

          return true;
        }),
    });

    try {
      await schema.validate(waste);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<WasteValidation>({});
  return [validation, setValidation, handleValidation];
}
