import React from 'react';
import { User } from 'types/user';
import { Typography, styled, ListItemButton } from '@mui/material';
import history from 'services/history';
type UserItemProps = {
  user: User;
};

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  '& > .data': {
    display: 'flex',
    flexDirection: 'column',
  },
});

const ImageWrapper = styled('div')(props => ({
  display: 'flex',
  height: 70,
  width: 70,
  overflow: 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  border: `2px solid ${props.theme.palette.secondary.main}`,
}));

const UserItem: React.FC<UserItemProps> = ({ user }) => {
  function handleClick(id: number | string) {
    history.push(`/user/${id}`);
  }

  return (
    <CustomListItemButton onClick={() => handleClick(user.id)}>
      <div className="data">
        <Typography gutterBottom color="primary" variant="caption">
          ID {user.id}
        </Typography>
        <Typography variant="h6">{user.name}</Typography>
        <Typography variant="caption" color="textSecondary">
          {user.username}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {user.email}
        </Typography>
      </div>
      {user.image && (
        <ImageWrapper>
          <img style={{ width: '100%' }} src={user.image.imageThumbUrl} alt={user.name} />
        </ImageWrapper>
      )}
    </CustomListItemButton>
  );
};

export default UserItem;
