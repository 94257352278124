import React, { useState } from 'react';
import { Typography, Avatar, Theme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import SidebarItem from './SidebarItem';
import { useApp } from 'hooks/app';
import { SIDEBAR_WIDTH } from 'constants/constants';
import { useSelector } from 'store/selector';
import { usePermissionRules } from 'hooks/permissionRules';
import AppSidebar from './app/AppSidebar';
import SiteSidebar from './site/SiteSidebar';
import ImageSelector from 'components/image-selector/ImageSelector';
import UserControlSidebar from './user/UserControlSidebar';
import Registration from './collapsible/Registration';
import { SidebarProvider } from './hook/useSidebar';
import Financials from './collapsible/Financials';
import Settings from './collapsible/Settings';
import Dashboard from './collapsible/Dashboard';
import Commercial from './collapsible/Commercial';
import Shopping from './collapsible/Shopping';
import { makeStyles } from '@mui/styles';
import Ai from './collapsible/Ai';
import { Home, Report } from '@mui/icons-material';
import UnitySidebar from './unity/UnitySidebar';
import Governance from './collapsible/Governance';
import Rh from './collapsible/Rh';
import Waste from './collapsible/Waste';
import Bi from './collapsible/Bi';

const useStyles = makeStyles<Theme>(theme => ({
  drawerPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: SIDEBAR_WIDTH,
    backgroundColor: '#272c3a !important',
    '@media print': {
      display: 'none',
    },
  },
  drawerHeader: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
  },
  avatar: {
    width: 30,
    height: 30,
    border: `2px solid ${theme.palette.primary.light}`,
  },
  logout: {
    position: 'absolute',
    bottom: 0,
  },
  account: {
    minHeight: 90,
    flex: 0,
  },
  content: {
    transition: 'opacity 0.4s ease',
    opacity: 1,
  },
}));

export type Collapsible = {
  ai: boolean;
  waste: boolean;
  rh: boolean;
  governance: boolean;
  moviment: boolean;
  settings: boolean;
  commercial: boolean;
  shopping: boolean;
  registration: boolean;
  trade: boolean;
  customers: boolean;
  updatedCustomers: boolean;
  charge: boolean;
  creditAnalysis: boolean;
  site: boolean;
  dashboard: boolean;
  courses: boolean;
  bi: boolean;
};

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const app = useApp();
  const { checkPermission } = usePermissionRules();
  const [shownApp, setShownApp] = useState(false);
  const [shownSite, setShownSite] = useState(false);
  const [shownUserControl, setShownUserControl] = useState(false);
  const [collapsible, setCollapsible] = useState<Collapsible>({
    ai: false,
    rh: false,
    settings: false,
    registration: false,
    trade: false,
    customers: false,
    charge: false,
    updatedCustomers: false,
    creditAnalysis: false,
    site: false,
    dashboard: false,
    shopping: false,
    commercial: false,
    moviment: false,
    governance: false,
    courses: false,
    waste: false,
    bi: false,
  });
  const [imageManager, setImageManager] = useState(false);
  const [shownUnity, setShownUnity] = useState(false);

  function handleCollapseClick(index: keyof Collapsible, closeOthers = true): void {
    if (closeOthers) {
      const keys = Object.keys(collapsible);

      let otherValues: Collapsible = {} as Collapsible;
      keys.forEach(key => {
        otherValues = {
          ...otherValues,
          [key]: false,
        };
      });

      setCollapsible({
        ...otherValues,
        [index]: !collapsible[index],
      });
      return;
    }

    setCollapsible({
      ...collapsible,
      [index]: !collapsible[index],
    });
  }

  function getShownSidebarOptions() {
    return shownApp || shownSite;
  }

  return (
    <Drawer
      variant={app.isMobile || app.windowWidth < 1280 ? 'temporary' : 'persistent'}
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      open={app.isOpenedMenu}
      onClose={app.handleOpenMenu}
    >
      {imageManager && <ImageSelector onExited={() => setImageManager(false)} />}

      <AppSidebar show={shownApp} handleClose={() => setShownApp(false)} />
      <SiteSidebar show={shownSite} handleClose={() => setShownSite(false)} />
      <UserControlSidebar show={shownUserControl} handleClose={() => setShownUserControl(false)} />
      <UnitySidebar show={shownUnity} handleClose={() => setShownUnity(false)} />

      <div className={classes.content} style={getShownSidebarOptions() ? { display: 'none' } : undefined}>
        <div className={classes.drawerHeader}>
          <Typography variant="h6">Grupo H2i</Typography>
        </div>

        {user && (
          <SidebarProvider
            value={{
              handleCollapseClick,
              collapsible,
              setImageManager,
              setShownUnity,
              setShownSite,
              setShownUserControl,
            }}
          >
            <SidebarItem to="/" icon={<Home />} text="Início" />

            {checkPermission('dashboard') && <Dashboard />}

            <Registration />

            <Commercial />

            <Shopping />

            <Financials />

            <Rh />

            <Bi />

            {checkPermission('reports') && (
              <SidebarItem
                to={app.windowWidth < 600 ? '/reports/home' : '/reports/resume'}
                icon={<Report />}
                text="Relatórios"
              />
            )}

            <Ai />

            <Waste />

            <Governance />

            <Settings />
          </SidebarProvider>
        )}
      </div>
      <SidebarItem
        className={classes.account}
        to="/account"
        icon={
          user ? (
            <>
              {user.image ? (
                <Avatar className={classes.avatar} src={user.image.imageThumbUrl} />
              ) : (
                <Avatar className={classes.avatar}>{user.name[0]}</Avatar>
              )}
            </>
          ) : (
            <Avatar className={classes.avatar}>U</Avatar>
          )
        }
        text={user ? user.name : 'Carregando...'}
      />
    </Drawer>
  );
};

export default Sidebar;
