import React from 'react';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import { FilterList, Search } from '@mui/icons-material';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';
import { useInvoices } from '../hooks/useInvoices';
import { useApp } from 'hooks/app';

const CustomTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const Container = styled('div')(({ theme }) => ({
  gap: 10,
  gridTemplateColumns: '1fr 100px',
  display: 'grid',
  flex: 1,
  '& p': {
    fontSize: '0.75rem!important',
  },
  '& span': {
    fontSize: '0.75rem!important',
  },
  '& label': {
    fontSize: '0.75rem!important',
  },
  '& .MuiInputBase-root': {
    fontSize: '0.75rem!important',
    '& .MuiInputAdornment-root': {
      marginRight: 3,
      marginLeft: 3,
    },
  },
  '& .MuiButtonBase-root': {
    fontSize: '0.75rem!important',
  },
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  [theme.breakpoints.down('lg')]: {
    gap: 10,
    alignItems: 'baseline',
    flexDirection: 'row',
  },
}));

const StyledFilter = styled('div')(({ theme }) => ({
  flex: 1,
  gap: 10,
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 220px 1fr)',
  '&.top': {
    alignItems: 'center',
  },
  '&.bottom': {
    gridTemplateColumns: 'repeat(1, 110px 1fr)',
    alignItems: 'baseline',
  },
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}));

const Actions = styled('div')(props => ({
  display: 'flex',
  gap: 10,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '& > button': {
    width: '100%',
  },
  [props.theme.breakpoints.down('lg')]: {
    flexDirection: 'row',
  },
  [props.theme.breakpoints.down('sm')]: {
    '& .button-filters': {
      display: 'none',
    },
  },
}));

const DatePickerContainer = styled('div')(props => ({
  display: 'grid',
  alignItems: 'baseline',
  gap: 10,
  '&.bottom': {
    gridTemplateColumns: 'repeat(1, 2fr 2fr 110px 110px 125px)',
  },
  '&.top': {
    gridTemplateColumns: 'repeat(1, 110px 1fr 110px 135px 135px 125px)',
  },
  [props.theme.breakpoints.down('lg')]: {
    display: 'none',
  },
  '&.none': {
    gridTemplateColumns: 'repeat(1, 1fr 100px 135px 135px 125px)',
  },
}));

interface InvoicesFilterBoxProps {
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
}

const InvoicesFilterBox: React.FC<InvoicesFilterBoxProps> = ({ displayMode, setDisplayMode }) => {
  const { filter, handleChangeFilter, handleSearch, loading, accounts, setIsOpenFilterMore, type } = useInvoices();
  const { isMobile } = useApp();

  return (
    <FilterBox>
      <Container>
        <StyledContainer>
          <StyledFilter className="top">
            <SelectOthersBranch
              branch_id={filter.branch_id}
              handleChange={value => handleChangeFilter('branch_id', value)}
              fullWidth={isMobile}
            />
            <DatePickerContainer className={type === 'TT' ? 'top' : 'none'}>
              {type === 'TT' && (
                <CustomTextField
                  value={filter.type}
                  onChange={e => handleChangeFilter('type', e.target.value)}
                  select
                  label="Tipo"
                >
                  <MenuItem value="TT">Todos</MenuItem>
                  <MenuItem value="D">Despesas</MenuItem>
                  <MenuItem value="T">Títulos</MenuItem>
                  <MenuItem value="F">Folha de Pagamento</MenuItem>
                  <MenuItem value="DT">Títulos e Despesas</MenuItem>
                </CustomTextField>
              )}

              <CustomTextField
                label="Título / Classificação"
                placeholder="Título / Classificação"
                autoFocus
                value={filter.classification}
                onChange={e => handleChangeFilter('classification', e.target.value)}
              />

              <CustomTextField
                value={filter.is_appropriated}
                onChange={e => handleChangeFilter('is_appropriated', e.target.value)}
                select
                label="Apropriado"
              >
                <MenuItem value="T">Todos</MenuItem>
                <MenuItem value="N">Não Apropriado</MenuItem>
                <MenuItem value="A">Apropriados</MenuItem>
              </CustomTextField>

              <DesktopDatePicker
                label="Data inicial"
                value={filter.initial_date}
                maxDate={filter.final_date}
                onChange={date => handleChangeFilter('initial_date', date)}
              />

              <DesktopDatePicker
                label="Data Final"
                value={filter.final_date}
                minDate={filter.initial_date}
                onChange={date => handleChangeFilter('final_date', date)}
              />

              <DesktopDatePicker
                label="Mês/Ano"
                value={filter.month_year}
                onChange={date => handleChangeFilter('month_year', date)}
                format="MMMM-yyyy"
                views={['month', 'year']}
                localeText={{
                  clearButtonLabel: 'Limpar',
                }}
                slotProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
              />
            </DatePickerContainer>
          </StyledFilter>

          <StyledFilter className="bottom">
            <CustomTextField
              label="CT Informado"
              placeholder="CT Informado"
              value={filter.informedAccount}
              onChange={e => handleChangeFilter('informedAccount', e.target.value)}
              select
            >
              <MenuItem value="T">Todos</MenuItem>
              <MenuItem value="S">Sim</MenuItem>
              <MenuItem value="N">Não</MenuItem>
            </CustomTextField>

            <DatePickerContainer className="bottom">
              <CustomTextField
                fullWidth
                margin="normal"
                value={filter.totalizingAccountId}
                onChange={e => handleChangeFilter('totalizingAccountId', e.target.value)}
                select
                label="Conta totalizadora"
              >
                <MenuItem value={0}>Nenhuma conta selecionada</MenuItem>
                {accounts.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nome} - {item.formattedSaldo}
                  </MenuItem>
                ))}
              </CustomTextField>

              <CustomTextField
                label="Fornecedor / Despesa"
                placeholder="Fornecedor / Despesa"
                autoFocus
                value={filter.supplier}
                onChange={e => handleChangeFilter('supplier', e.target.value)}
                margin="none"
              />

              <CustomTextField
                fullWidth
                margin="normal"
                value={filter.is_paid}
                onChange={e => handleChangeFilter('is_paid', e.target.value)}
                select
                label="Pago"
              >
                <MenuItem value="T">Todos</MenuItem>
                <MenuItem value="S">Pago</MenuItem>
                <MenuItem value="N">Não Pago</MenuItem>
              </CustomTextField>

              <CustomTextField
                label="Nº Nota"
                placeholder="Nº Nota"
                value={filter.invoice_number}
                onChange={e => handleChangeFilter('invoice_number', e.target.value)}
                margin="normal"
              />

              <DesktopDatePicker
                label="Data Apropriação"
                value={filter.appropriation_date}
                onChange={date => handleChangeFilter('appropriation_date', date)}
                textFieldProps={{
                  margin: 'normal',
                }}
              />
            </DatePickerContainer>
          </StyledFilter>
        </StyledContainer>

        <Actions>
          <Button
            onClick={() => setIsOpenFilterMore(true)}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<FilterList />}
            className="button-filters"
          >
            Filtros
          </Button>

          <Button
            disabled={loading}
            onClick={handleSearch}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Search />}
          >
            Buscar
          </Button>
        </Actions>
      </Container>
    </FilterBox>
  );
};

export default InvoicesFilterBox;
