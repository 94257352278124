import React from 'react';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import { Search } from '@mui/icons-material';
import { useTransferAccounts } from './hooks/useTransferAccount';
import Loading from 'components/loading/Loading';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';

const StyledFilter = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 155px 155px 1fr 1fr 1fr 100px',
  flex: 1,
  gap: 5,
  [theme.breakpoints.down('lg')]: {
    gap: 15,
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  '& p': {
    fontSize: '0.75rem!important',
  },
  '& span': {
    fontSize: '0.75rem!important',
  },
  '& label': {
    fontSize: '0.75rem!important',
  },
  '& .MuiInputBase-root': {
    fontSize: '0.75rem!important',
    '& .MuiInputAdornment-root': {
      marginRight: 3,
      marginLeft: 3,
    },
  },
  '& .MuiButtonBase-root': {
    fontSize: '0.75rem!important',
  },
}));

const ListTypeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 10,
  justifyContent: 'end',
  [theme.breakpoints.down('lg')]: {
    gridColumn: '1 / 4',
  },
  [theme.breakpoints.down('md')]: {
    gridColumn: '1 / 3',
  },
}));

interface TransferAccountFilterBoxProps {
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
}

const TransferAccountFilterBox: React.FC<TransferAccountFilterBoxProps> = ({ displayMode, setDisplayMode }) => {
  const { filter, handleChangeFilter, handleSearch, loading, totalizingAccounts } = useTransferAccounts();

  return (
    <FilterBox>
      <StyledFilter>
        <SelectOthersBranch
          fullWidth
          branch_id={filter.branchId}
          handleChange={branchId => handleChangeFilter('branchId', branchId)}
        />

        <DesktopDatePicker
          label="Data inicial"
          value={filter.initial_date}
          onChange={date => handleChangeFilter('initial_date', date)}
        />
        <DesktopDatePicker
          minDate={filter.initial_date}
          label="Data final"
          value={filter.final_date}
          onChange={date => handleChangeFilter('final_date', date)}
        />
        <TextField
          select
          value={filter.originAccountId}
          placeholder="Selecione a conta de saída"
          label="Conta de saída (origem)"
          onChange={e => handleChangeFilter('originAccountId', e.target.value)}
        >
          <MenuItem value={0}>Nenhuma conta selecionada</MenuItem>

          {!totalizingAccounts ? (
            <Loading />
          ) : (
            totalizingAccounts.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))
          )}
        </TextField>

        <TextField
          select
          value={filter.targetAccountId}
          placeholder="Selecione a conta de entrada"
          label="Conta de entrada (destino)"
          onChange={e => handleChangeFilter('targetAccountId', e.target.value)}
        >
          <MenuItem value={0}>Nenhuma conta selecionada</MenuItem>

          {!totalizingAccounts ? (
            <Loading />
          ) : (
            totalizingAccounts.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))
          )}
        </TextField>

        <TextField
          value={filter.observation}
          onChange={e => handleChangeFilter('observation', e.target.value)}
          placeholder="Pesquise pela observação"
          label="Observação"
        />

        <Actions>
          <Button
            disabled={loading}
            onClick={handleSearch}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Search />}
          >
            Buscar
          </Button>
        </Actions>
      </StyledFilter>
      <ListTypeContainer>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </ListTypeContainer>
    </FilterBox>
  );
};

export default TransferAccountFilterBox;
