import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Justification } from 'types/justification';
import { JustificationValidation } from '../validation/justificationValidation';
import { Document } from 'types/document';

interface JustificationContext {
  justification: Justification;
  validation: JustificationValidation;
  handleChange(index: keyof Justification, value: any): void;
  document: Document | null;
  setDocument: Dispatch<SetStateAction<Document | null>>;
}

const Context = createContext<JustificationContext>({} as JustificationContext);
export const JustificationProvider = Context.Provider;

export function useJustification(): JustificationContext {
  return useContext(Context);
}
