import React, { useState, FormEvent, useEffect, useRef } from 'react';
import { IconButton, TextField, styled } from '@mui/material';
import { RentalPaymentValidation } from './validation/rentalPaymentValidation';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import { RentalPaymentFormContent } from 'pages/rentals/types/rental';
import { Done } from '@mui/icons-material';

const Form = styled('form')(props => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 0,
  gap: 10,
  alignItems: 'baseline',
  marginBottom: 30,
  '& > button': {
    flexShrink: 0,
  },
  [props.theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
  },
}));

interface RentalPaymentsFormProps {
  handleSubmit(data: RentalPaymentFormContent): Promise<void>;
  validation: RentalPaymentValidation;
}

const RentalPaymentsForm: React.FC<RentalPaymentsFormProps> = ({ handleSubmit: defaultHandleSubmit, validation }) => {
  const [description, setDescription] = useState('');
  const [value, setValue] = useState('');
  const [referenceDate, setReferenceDate] = useState<Date | null>(new Date());

  const inputs = {
    description: useRef<HTMLInputElement>(null),
    value: useRef<HTMLInputElement>(null),
    referenceDate: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    defaultHandleSubmit({
      externalId: description,
      value,
      paidAt: referenceDate as Date,
    })
      .then(() => {
        setDescription('');
        setValue('');
        setReferenceDate(new Date());
        inputs.description.current?.focus();
      })
      .catch(err => console.error(err));
  }

  return (
    <Form onSubmit={event => handleSubmit(event)}>
      <TextField
        label="Número de identificação"
        placeholder="Número de identificação"
        margin="normal"
        autoFocus
        value={description}
        onChange={event => setDescription(event.target.value)}
        inputRef={inputs.description}
        error={!!validation.description}
        helperText={validation.description}
        fullWidth
      />

      <TextField
        label="Valor R$"
        placeholder="Valor R$"
        margin="normal"
        value={value}
        onChange={event => setValue(event.target.value)}
        inputRef={inputs.value}
        error={!!validation.value}
        helperText={validation.value}
        fullWidth
        type="number"
        inputMode="decimal"
      />

      <DesktopDatePicker
        label="Data de referência"
        value={referenceDate}
        onChange={date => setReferenceDate(date)}
        inputRef={inputs.referenceDate}
        textFieldProps={{
          fullWidth: true,
          margin: 'normal',
          error: !!validation.referenceDate,
          helperText: validation.referenceDate,
          placeholder: 'Cargo do contato',
        }}
      />

      <IconButton type="submit" color="primary">
        <Done />
      </IconButton>
    </Form>
  );
};

export default RentalPaymentsForm;
