import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import { Typography } from '@mui/material';
import { Email } from 'types/email';
import ExcelButtonDownload from 'components/excel-button-download/ExcelButtonDownload';
import { download } from 'helpers/download';
import { api } from 'services/api';
import { uuidv4 } from 'helpers/uuidv4';

import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 30,
  },
});

interface EmailExportProps {
  data: Email[];
  onExited(): void;
}

const EmailExport: React.FC<EmailExportProps> = ({ data, onExited }) => {
  const classes = styles();

  async function handleDownload() {
    try {
      const response = await api.post('/excel-exports', data, { responseType: 'blob' });

      download(response.data, `email-${uuidv4()}`);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Dialog onExited={onExited} title="Export para Excel" maxWidth="sm" height="60vh">
      <div className={classes.container}>
        <Typography>Sua planilha está pronta.</Typography>
        <ExcelButtonDownload handleDownload={handleDownload} />
      </div>
    </Dialog>
  );
};

export default EmailExport;
